import * as React from 'react';

import { Typography } from "~app/ui-kit/Typography";
import { getScheduleTime } from '~lib/localeUtils/dates';

import * as css from './TimeRange.module.css'

interface Props {
  startAt: string;
  endAt: string;
}

const TimeRange$ = (props: Props) => {
  const renderText = `${ getScheduleTime(props.startAt) } - ${ getScheduleTime(props.endAt) }`

  return (
    <div className={ css.timeRangeWrapper }>
      <Typography className={ css.timeColor } variant={ "subtitle1_medium" }>
        { renderText }
      </Typography>
    </div>
  );
};

export const TimeRange = TimeRange$
