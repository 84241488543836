import * as cn from 'classnames';
import { compact } from 'lodash';
import * as React from 'react';

import SceneItem from '~components/Scene/typings';
import { useConfig } from '~global';
import BannerBig from '~typings/BannerBig';
import BannerMiddle from '~typings/BannerMiddle';
import ItemObject from '~typings/ItemObject';
import { RatingTypeCaseSensitive } from '~typings/Rating';

import { getCertificationRatingIcon, getRatings, RatingParsed } from './helpers';
import * as styles from './styles.module.css';

type Props = Readonly<{
  item: SceneItem | BannerBig | BannerMiddle;
  /** Дополнительный класс для компонента */
  className?: string;
}>;


const RatingsUI: React.FC<Props> = ({ item, className }: Props) => {
  const { smartTV: { ratingSystem } } = useConfig();
  if (!item || item.object === ItemObject.ProgramEvent) { return null; }

  const ratingsObjects = getRatings(item);

  const sortRatingArr = (arr, sort) => {
    return arr.sort(function(element, element2) {
      let i1 = sort.indexOf(element.id),
          i2 = sort.indexOf(element2.id);
      return i1 < 0 ? 1 : i2 < 0 ? -1 : i1 - i2;
    })
  }

  const sortedArray = sortRatingArr(ratingsObjects, [
    RatingTypeCaseSensitive.Kinopoisk,
    RatingTypeCaseSensitive.IMDB,
    RatingTypeCaseSensitive.Users,
  ]);

  const ratings = [...sortedArray, getCertificationRatingIcon(item, ratingSystem)];

  if (!ratings || ratings.length === 0) { return null; }

  const renderRating = (rating: RatingParsed | null) => {
    if (!rating || rating.value === 0) { return null; }
    const isCertificationRating = rating.id === 'certification';

    const icon = !isCertificationRating
      ? <div className={ cn(styles.ratingIcon, styles[rating.id.toLowerCase()]) }>{ rating.icon }</div>
      : null

    return (
      <div key={ rating.id } className={ styles.ratingItem }>
        { icon }
        <div className={ cn(styles.ratingText, { [styles.certRating]: isCertificationRating }) }>
          { rating.value }
        </div>
      </div>
    );
  };

  return (
    <div className={ cn(className, styles.ratings) }>
      { compact(ratings.map(renderRating)) }
    </div>
  );
};


export default React.memo(RatingsUI);
