import { AuthState } from "~components/AuthStepScreen";
import {
  ACCOUNT_SECURITY_ENTER_PIN_CODE,
  AUTH_SCREEN_CONFIRM_CALL_DESCR,
  AUTH_SCREEN_CONFIRM_CALL_FOOTER,
  AUTH_SCREEN_CONFIRM_EMAIL_DESCR,
  AUTH_SCREEN_CONFIRM_PHONE_DESCR,
  AUTH_SCREEN_CREDENTIALS_DESCR,
  AUTH_SCREEN_FORGOT_PASS_CALL_DESCR,
  AUTH_SCREEN_FORGOT_PASS_CALL_FOOTER,
  AUTH_SCREEN_FORGOT_PASS_EMAIL_DESCR,
  AUTH_SCREEN_FORGOT_PASS_NEW,
  AUTH_SCREEN_FORGOT_PASS_PHONE_DESCR,
  AUTH_SCREEN_FORGOT_PASS_PHONE_OR_EMAIL,
  AUTH_SCREEN_RESET_PASS_HEADER,
  PASSWORD_HAS_SENDED_WITH_EMAIL,
  PASSWORD_HAS_SENDED_WITH_PHONE,
  PASSWORD_RECOVERY_SMS_OR_EMAIL_DESCR,
  REGISTRATION,
  REGISTRATION_ENTER_EMAIL,
  REGISTRATION_ENTER_PHONE,
  REGISTRATION_ENTER_PHONE_OR_EMAIL,
  REGISTRATION_PASSWORD_RESTRICTION,
  SECURITY_CODE,
  SIGN_IN,
  SIGN_IN_IMPLICIT_DESCR,
  SIGN_IN_IMPLICIT_HEADER,
  SIGN_IN_TEXT,
} from '~localization';
import SmartTVConfig from '~typings/SmartTVConfig';


const getAuthLabels = (intl, smartTV: SmartTVConfig, isImplicitFlow: boolean): {
  [key: string]: string | ((authState: AuthState) => string) | undefined
} => ({
  signInHeader: intl.formatMessage({ id: isImplicitFlow ? SIGN_IN_IMPLICIT_HEADER : SIGN_IN }),
  signUpHeader: intl.formatMessage({ id: REGISTRATION }),
  resetPasswordHeader: intl.formatMessage({ id: AUTH_SCREEN_RESET_PASS_HEADER }),

  codeAuthDescr: intl.formatMessage(
    { id: SIGN_IN_TEXT },
    { site: smartTV.baseSiteUrl }
  ),
  signInUserPageDescr: isImplicitFlow ? intl.formatMessage({ id: SIGN_IN_IMPLICIT_DESCR }) : undefined,
  signInPasswPageDescr: undefined,
  regUsernameDescr: intl.formatMessage({ id: REGISTRATION_ENTER_PHONE_OR_EMAIL }),
  regUsernamePhoneDescr: intl.formatMessage({ id: REGISTRATION_ENTER_PHONE }),
  regUsernameEmailDescr: intl.formatMessage({ id: REGISTRATION_ENTER_EMAIL }),
  regPassDescr: intl.formatMessage(
    { id: REGISTRATION_PASSWORD_RESTRICTION },
    { num: smartTV.minPasswordLength },
  ),
  confirmPhoneDescr: ({ username }) => intl.formatMessage(
    { id: AUTH_SCREEN_CONFIRM_PHONE_DESCR },
    { userName: username }
  ),
  confirmEmailDescr: ({ username }) => intl.formatMessage(
    { id: AUTH_SCREEN_CONFIRM_EMAIL_DESCR },
    { email: username }
  ),
  confirmCallDescr: intl.formatMessage({ id: AUTH_SCREEN_CONFIRM_CALL_DESCR }),
  confirmCallFooter: intl.formatMessage({ id: AUTH_SCREEN_CONFIRM_CALL_FOOTER }),
  securityCodeHeader: intl.formatMessage({ id: SECURITY_CODE }),
  securityCodeDescr: intl.formatMessage({ id: ACCOUNT_SECURITY_ENTER_PIN_CODE }),
  resetPassCallFooter: intl.formatMessage({ id: AUTH_SCREEN_FORGOT_PASS_CALL_FOOTER }),
  credentialsDescr: intl.formatMessage({ id: AUTH_SCREEN_CREDENTIALS_DESCR }),
  forgotPassDescr: intl.formatMessage({
    id: PASSWORD_RECOVERY_SMS_OR_EMAIL_DESCR
  }),
  forgotEmailPassDescr: intl.formatMessage(
    { id: AUTH_SCREEN_FORGOT_PASS_EMAIL_DESCR },
    { projectTitle: smartTV.projectTitle }
  ),
  forgotPhonePassDescr: intl.formatMessage(
    { id: AUTH_SCREEN_FORGOT_PASS_PHONE_DESCR },
    { projectTitle: smartTV.projectTitle }
  ),
  forgotPhoneOrEmailPassDescr: intl.formatMessage(
    { id: AUTH_SCREEN_FORGOT_PASS_PHONE_OR_EMAIL },
    { projectTitle: smartTV.projectTitle }
  ),
  newPasswordDescr: intl.formatMessage(
    { id: AUTH_SCREEN_FORGOT_PASS_NEW },
    { len: smartTV.minPasswordLength }
  ),
  forgotPassCallDescr: intl.formatMessage({ id: AUTH_SCREEN_FORGOT_PASS_CALL_DESCR }),
  forgotPassSmsDescr: (authState: AuthState) => intl.formatMessage(
    { id: PASSWORD_HAS_SENDED_WITH_PHONE },
    { phone: authState.username }
  ),
  forgotPassEmailDescr: (authState: AuthState) => intl.formatMessage(
    { id: PASSWORD_HAS_SENDED_WITH_EMAIL },
    { email: authState.username }
  ),
});

export { getAuthLabels };
