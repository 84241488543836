import cn from 'classnames';
import * as React from 'react';
import { InjectedIntl,injectIntl } from 'react-intl';

import { CurrentEventProgress } from '~components/CurrentEventProgress';
import { useApp } from '~components/Provider/App';
import { CatchupStatus, getCatchupStatus } from '~lib/eventsHelper';
import { getScheduleTime, getShortDate } from '~lib/localeUtils/dates';
import { ON_AIR_DATE, RECORD_DATE } from '~localization';
import Event from '~typings/Event';

import * as style from './styles.module.css';



type Props = Readonly<{
  event: Event;
  intl: InjectedIntl;
}>;


const ProgramEventInfoEvent: React.FC<Props> = ({ event, intl }: Props) => {
  const { language } = useApp();
  const { channel } = event;
  const [catchupStatus] = React.useMemo(
    () => getCatchupStatus(event),
    [event],
  );
  const renderName = React.useMemo(() => (
    <div
      className={ style.currentEventName }
    >
      {
        catchupStatus === CatchupStatus.OnAir ?
          `${getScheduleTime(event.start_at)}, ${event.name}`
          : event.name
      }
    </div>
  ), [catchupStatus, event?.id]);
  const renderChannelName = React.useMemo(() => {
    if (!event) { return null; }

    let text = channel?.name;

    if (catchupStatus === CatchupStatus.Catchup) {
      text = intl.formatMessage({ id: RECORD_DATE }, {
        date: getShortDate(event.start_at, language),
        time: getScheduleTime(event.start_at),
      });
    }
    if (catchupStatus === CatchupStatus.Future) {
      text = intl.formatMessage({ id: ON_AIR_DATE }, {
        date: getShortDate(event.start_at, language),
        time: getScheduleTime(event.start_at),
      });
    }

    return (
      <div
        className={ style.channelName }
      >
        { text }
      </div>
    );
  }, [event?.id, catchupStatus]);

  return (
    <div
      className={
        cn(style.wrapper)
      }
    >
      <div
        className={ style.eventWrapper }
      >
        { renderChannelName }
        {
          (catchupStatus === CatchupStatus.OnAir) ?
            <CurrentEventProgress
              className={ style.currentEventProgressLine }
              startAt={ event.start_at }
              endAt={ event.end_at }
            />
            :
            null
        }
        { renderName }
      </div>
    </div>
  );
};


export default React.memo(injectIntl(ProgramEventInfoEvent));
