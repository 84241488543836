import * as React from 'react';

import useNavigation from '~stores/Navigation';


type Props = Readonly<{
  isAllowRotate: boolean;
  index: number;
  onChangeIndex: (index: number) => void;
}>;


const useBannerRotation = (props: Props) => {
  const getFocused = useNavigation(state => state.getFocused);
  const focused = getFocused();
  const timeout = React.useRef<any | null>(null);
  const handleScrollToNextBanner = (index, count) => {
    let newIndex = (index + 1);

    if (newIndex > (count - 1)) {
      newIndex = 0;
    }

    props.onChangeIndex(newIndex);
  };
  const handleScrollTick = (index, itemsCount) => {
    timeout.current = setTimeout(() => {
      handleScrollToNextBanner(index, itemsCount);
    }, 7000);
  };
  const clear = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };

  React.useEffect(() => {
    if (props.isAllowRotate && focused && !isNaN(parseInt(focused.itemsCount + '', 10))) {
      handleScrollTick(props.index, focused.itemsCount);
    } else if (!props.isAllowRotate) {
      clear();
    }

    return clear;
  }, [props.isAllowRotate, props.index, focused?.itemsCount]);
};


export default useBannerRotation;
