import { clone, isNumber, throttle } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { changeSliderIndexAnimationMS } from '~app/variables';
import useNavigationByKeys from '~hooks/useNavigation';
import {
  findFirstEnabledLine,
  findNavigatebleLineIndex,
  getFocusedLineFromURL,
  getFocusedLineIndex,
  getFocusOn,
  setFocusOn,
} from '~hooks/useSpatialNavigation/helpers';
import NavigationDirection from '~typings/NavigationDirection';


export type NavigationLine = {
  maxIndex?: number;
  focusedIndex?: number;
  isFocused?: boolean;
};

type Props = Readonly<{
  navigationItems: Array<NavigationLine>;
  allowNavigation: boolean;
  throttleTimeInMS?: number;
  onLeave?: (direction: NavigationDirection) => void;
}>;

export type State = Readonly<{
  focusOn: number;
  focusedIndex: Array<number>;
  handleKeyNavigate: (direction: NavigationDirection) => void;
}>;


const Y_FIELD_NAME = 'y';
const THROTTLE_OPTIONS = { trailing: false };

const useSpatialNavigation = (props: Props): State => {
  const { throttleTimeInMS = changeSliderIndexAnimationMS } = props;
  const history = useHistory();
  const isMounted = React.useRef<boolean>(true);
  const focusedIndexREF = React.useRef<Array<number>>(
    props.navigationItems.map(({ focusedIndex = 0 }) => focusedIndex)
  );
  const [
    focusedIndex,
    setFocusedIndex,
  ] = React.useState<Array<number>>(()=>props.navigationItems.map(({ focusedIndex = 0 }) => focusedIndex));

  const onUp = () => {
    const focusOn = getFocusOn(history, props.navigationItems);

    if (props.onLeave) {
      const nextIndex = (focusOn - 1);

      if (nextIndex < 0) {
        props.onLeave(NavigationDirection.Up);

        return;
      }
    }

    const navigatebleLineIndex = findNavigatebleLineIndex(focusOn, NavigationDirection.Up, props.navigationItems);

    setFocusOn(history, (navigatebleLineIndex !== null) ? navigatebleLineIndex : focusOn);
  };
  const onDown = () => {
    const focusOn = getFocusOn(history, props.navigationItems);

    if (props.onLeave) {
      const nextIndex = (focusOn + 1);
      if (nextIndex > (props.navigationItems.length - 1)) {
        props.onLeave(NavigationDirection.Down);

        return;
      }
    }

    const navigatebleLineIndex = findNavigatebleLineIndex(focusOn, NavigationDirection.Down, props.navigationItems);

    setFocusOn(history, (navigatebleLineIndex !== null) ? navigatebleLineIndex : focusOn);
  };
  const onLeft = () => {
    // console.log(props.navigationItems, focusedIndexREF.current);
    const focusOn = getFocusOn(history, props.navigationItems);
    const nextIndex = (focusedIndexREF.current[focusOn] - 1);

    if (props.onLeave && nextIndex < 0) {
      props.onLeave(NavigationDirection.Left);

      return;
    }

    const focusedIndexNewVal = clone(focusedIndexREF.current);
    focusedIndexNewVal[focusOn] = Math.max(nextIndex, 0);
    setFocusedIndex(focusedIndexNewVal);
    focusedIndexREF.current = focusedIndexNewVal;
  };
  const onRight = () => {
    const focusOn = getFocusOn(history, props.navigationItems);
    const maxIndex = props.navigationItems[focusOn]?.maxIndex || 0;
    const nextIndex = (focusedIndexREF.current[focusOn] + 1);

    if (props.onLeave && nextIndex > maxIndex) {
      props.onLeave(NavigationDirection.Right);

      return;
    }

    const focusedIndexNewVal = clone(focusedIndexREF.current);
    focusedIndexNewVal[focusOn] = Math.min(nextIndex, (maxIndex || 0));
    setFocusedIndex(focusedIndexNewVal);
    focusedIndexREF.current = focusedIndexNewVal;
  };

  const update = [props.navigationItems, props.onLeave];

  const throttledHandleUp = React
    .useCallback(throttle(onUp, throttleTimeInMS, THROTTLE_OPTIONS), update);
  const throttledHandleDown = React
    .useCallback(throttle(onDown, throttleTimeInMS, THROTTLE_OPTIONS), update);
  const throttledHandleLeft = React
    .useCallback(throttle(onLeft, throttleTimeInMS, THROTTLE_OPTIONS), update);
  const throttledHandleRight = React
    .useCallback(throttle(onRight, throttleTimeInMS, THROTTLE_OPTIONS), update);

  const handleNavigate = {
    [NavigationDirection.Up]: throttledHandleUp,
    [NavigationDirection.Right]: throttledHandleRight,
    [NavigationDirection.Down]: throttledHandleDown,
    [NavigationDirection.Left]: throttledHandleLeft,
  };

  const handleKeyNavigate = (direction): void => {
    handleNavigate[direction]();
  };

  useNavigationByKeys({
    isMounted: (isMounted.current && props.allowNavigation && props.navigationItems.length !== 0),
    onNavigation: handleKeyNavigate,
  }, [focusedIndex, props.navigationItems, props.allowNavigation, props.onLeave]);

  React.useEffect(() => {
    const focusedLineIndex = getFocusedLineIndex(props.navigationItems);

    if (focusedLineIndex !== -1) {
      setFocusOn(history, focusedLineIndex);
    }

    return () => { isMounted.current = false; };
  }, []);

  React.useEffect(() => {
    if (!props.allowNavigation) { return; }

    const focusOn = getFocusOn(history, props.navigationItems);

    if (focusOn !== getFocusedLineFromURL(history)) {
      setFocusOn(history, focusOn);

      return;
    }

    if (!isNumber(props.navigationItems[focusOn]?.maxIndex)) {
      const newFocusOn = findFirstEnabledLine(props.navigationItems);

      if (newFocusOn !== null) {
        setFocusOn(history, newFocusOn);

        return;
      }
    }

    const focusedIndexNewVal = props.navigationItems
      .map((line, index: number) => {
        if (line.isFocused === true) { setFocusOn(history, index); }

        return (isNumber(line.focusedIndex) ? line.focusedIndex : (focusedIndex[index] || 0));
      });

    setFocusedIndex(focusedIndexNewVal);
    focusedIndexREF.current = focusedIndexNewVal;
  }, [props.navigationItems]);

  const focusOn = getFocusOn(history, props.navigationItems);

  return React.useMemo(() => ({
    focusOn,
    focusedIndex,
    handleKeyNavigate,
  }), [focusOn, focusedIndex]);
};


export {
  Y_FIELD_NAME,
}

export default useSpatialNavigation;
