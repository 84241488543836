import * as cn from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { BlockTitle } from '~components/BlockTitle';
import { Card } from '~components/Card';
import SceneManagerFetcher from '~components/Scene/SceneManagerFetcher';
import { useCardConfig, useConfig } from '~global';
import { maxSliderSizeDefault } from '~hooks/fetch/constants';
import { useRecommendationsForMediaItem } from '~hooks/fetch/useRecommendations/useRecommendationMediaItem';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import { RECOMMENDED, RECOMMENDED_FOR_YOU } from '~localization';
import Genres from '~typings/Genre';
import Movie from '~typings/Movie';
import Series from '~typings/Series';
import { getSliderPropsForCards,SliderOffsetableVirtual } from '~ui/SliderOffsetable';

import * as styles from './styles.module.css';

type Props = Readonly<{
  item: Movie | Series;
  genres?: Genres[];
  isFocused: boolean;
  focusedItemIndex: number;
  setNavLimit: (limit: number) => void;
  onForceFocus: (idx: number)=> void
}>;

const RecommendationsNewCards: React.FC<Props> = (props: Props) => {
  const { maxSliderSize, recommenderEngineEnable } = useConfig((c) => c.smartTV);
  const limitForRecommendations = maxSliderSize || maxSliderSizeDefault;
  const { error, data: recommendationsData } = useRecommendationsForMediaItem({
    object: props.item.object,
    id: props.item.id,
    limit: limitForRecommendations,
    genres: props.genres?.map(({ id }) => id).join(',') || '',
  });


  const actualSliderSize = recommendationsData?.data.length ?? 0;

  React.useEffect(() => {
    if (props.setNavLimit) {
      //установка актуального количества итемов в навигацию
      props.setNavLimit(actualSliderSize);
    }
  }, [actualSliderSize]);

  const cardConfig = useCardConfig('recommendations_movies');

  const mediaItem =
    props.isFocused && recommendationsData?.data[props.focusedItemIndex]?.id
      ? {
          id: recommendationsData.data[props.focusedItemIndex].id,
          object: recommendationsData.data[props.focusedItemIndex].object,
        }
      : {
          id: props.item.id,
          object: props.item.object,
        };

        const renderCard = React.useCallback((index: number) => {
          const item = recommendationsData!.data[index];
          return <Card
            key={ item.id }
            item={ item }
            cardConfig={ cardConfig }
            isFocused={ props.isFocused && props.focusedItemIndex === index }
            emitForceFocusOnHover
            onForceFocus={ () => props.onForceFocus(index) }
          />;
        }, [cardConfig, props.isFocused, props.focusedItemIndex, props.onForceFocus, recommendationsData?.data]);

  if (
    !recommendationsData?.data ||
    error !== null ||
    recommendationsData?.meta.pagination.total === 0
  ) {
    return null;
  }

  const style: React.CSSProperties = {};

  // Тут костыль, который был скопирован со старых рекомендаций
  // Нарушен DOM флоу, и через такие стили подгоняется блок рекомендаций
  // Нужно будет переделать

    style.transform = `translate3d(0, ${valueInPixelsByWidth(7.8125)}px, 0)`;
    style.WebkitTransform = `translate3d(0, ${valueInPixelsByWidth(7.8125)}px, 0)`;

  if (props.isFocused) {
    style.transform = `translate3d(0, ${valueInPixelsByWidth(1.171875)}px, 0)`;
    style.WebkitTransform = `translate3d(0, ${valueInPixelsByWidth(1.171875)}px, 0)`;
  }




  return (
    <div
      className={ cn(styles.recommendationsWrapper) }
      style={ style }
    >
      <SceneManagerFetcher
        withInfo
        id={ mediaItem.id }
        object={ mediaItem.object }
      />
      <BlockTitle
        isFocusedBlock={ props.isFocused }
        isVisible
        isFadeOut={ false }
        blockTitleClassName={ styles.blockTitleOnNewcards }
      >
        <FormattedMessage id={ recommenderEngineEnable ? RECOMMENDED : RECOMMENDED_FOR_YOU } />
      </BlockTitle>
      <SliderOffsetableVirtual
        focusedIndex={ props.focusedItemIndex }
        childsCount={ recommendationsData.data.length }
        { ...getSliderPropsForCards(cardConfig.layout) }
        renderChild={ renderCard }
      />
    </div>
  );
};

export default React.memo(RecommendationsNewCards);
