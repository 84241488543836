import { FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import ApiClient from '~lib/ApiClient';
import { fetchRetryer,FetchRetryerParams } from '~lib/fetch';
import ResponseMany from '~typings/ResponseMany';
import { CardConfig,CardConfigByScreen, CardConfigLayout, ScreenCardConfig } from '~typings/ScreenCardConfigs';


const getDefaultCardConfigFallback = (): CardConfig => {
  return {
    disable_text: false,
    id: 'default',
    layout: CardConfigLayout.Horizontal,
    object: 'card_config'
  }
}

export const fetchScreenCardConfigs = async (
  client: ApiClient,
  retryParams: FetchRetryerParams,
): Promise<CardConfigByScreen> => {

  const { data } = await fetchRetryer(
    () => client.get<ResponseMany<ScreenCardConfig[]>>(fetchURLs[FetchType.ScreenCardConfigs]),
    retryParams,
  );

  const defaultCardConfig = data.find((config) => config.screen === 'default');

  const dataByScreen: CardConfigByScreen = {
    default: defaultCardConfig?.card_config || getDefaultCardConfigFallback(),
  };
  data.forEach((screenConfig) => {
    dataByScreen[screenConfig.screen] = screenConfig.card_config;
  });

  return dataByScreen;
};
