import { QueryKey, useQuery, useQueryClient } from "react-query";

import Image from '../../../typings/Image'

async function getIconLink(cacheData: string | undefined, iconImg: Image | undefined) {
  if (cacheData) return cacheData;

  if(!iconImg) return;

  const iconImage = iconImg.url_template.replace('_{width}x{height}{crop}', '');
  const data = await fetch(iconImage);
  const response = await data.text();

  return response;
}

export default function useNavMenuFetch(iconImg: Image | undefined, pageid: string) {
  const queryClient = useQueryClient();
  const queryKey: QueryKey = ['page_icon', pageid];
  const cacheData = queryClient.getQueryData<string>(queryKey);

  return useQuery({
    queryKey,
    queryFn: () => getIconLink(cacheData, iconImg),
    enabled: !!iconImg,
  });
}
