import { getCachedBanners } from '~hooks/fetch/useBanners/useBanners';
import { getCachedBanners as getCachedBannersV4 } from '~hooks/fetch/useBannersV4/useBanners';
import { getCardColItemsFirstPageFromCache } from '~hooks/fetch/useCardCollectionsItems';
import { getCachedChannel } from '~hooks/fetch/useChannel';
import { getCachedCollectionItems } from '~hooks/fetch/useCollectionItems';
import { getContinueWatchingFirstPageFromCache } from '~hooks/fetch/useContinueWatching/useContinueWatchingV3';
import { getFavoritesFirstPageFromCache } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { getRecsFirstPageFromCache } from '~hooks/fetch/useRecommendations/useRecommendations';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import { PageFocusHistory } from '~stores/Navigation/index';
import AppConfig from '~typings/AppConfig';
import { Block, BlockType } from '~typings/Block';
import ContinueWatchingType from '~typings/ContinueWatchingType';
import { FavoriteType } from '~typings/FavoriteType';
import ResponseMany from '~typings/ResponseMany';


const getIndex = (focusHistory: PageFocusHistory, pageID: string, blockID: string): number => {
  try {
    return focusHistory[pageID][blockID]?.index || 0;
  } catch (ignore) {
    return 0;
  }
};


const getSizeIfAllDownloaded = (total: number | undefined, max: number | undefined, range: number | undefined) =>
  total ?
    max && range && total > max + range ?
      max + 1
      : total
    : null;

const getSizeForLimitedItems = (
  isAllDownloaded: boolean,
  downloadedCount?: number,
  max?: number,
  range?: number,
) => {
  if (!downloadedCount) {
    return null;
  }

  if (!max || !range) {
    return isAllDownloaded ? downloadedCount : downloadedCount + 1;
  }

  if (downloadedCount > max + range) {
    return max + 1;
  }

  return downloadedCount + 1;
};

const getNavigationSize = <T>(
  firstPage: ResponseMany<T[]> | undefined,
  max?: number,
  range?: number,
) => {
  const count = firstPage?.data.length || 0;
  const isAllItemsDownloaded = Boolean(count) && firstPage?.meta.pagination.total === count;

  if (isAllItemsDownloaded) {
    return getSizeIfAllDownloaded(count, max, range);
  }

  return getSizeForLimitedItems(isAllItemsDownloaded, count, max, range);
};


const getBlockItemsCount = (
  currentPageID: string,
  config: AppConfig,
  block?: Block | null,
  sliderSize?: [number, number],
): number | null => {
  if (block === undefined || block === null || !block) {
    return null;
  }
  const [max, range] = sliderSize || EMPTY_ARRAY;

  switch (block.object) {
    case BlockType.BannersBlock: {
      return getCachedBanners(currentPageID)?.meta.pagination.total || null;
    }
    case BlockType.BigBannersBlock: {
      return getCachedBannersV4(block.id)?.meta.pagination.total || null;
    }
    case BlockType.MiddleBannersBlock: {
      return getCachedBannersV4(block.id)?.meta.pagination.total || null;
    }
    case BlockType.FavoriteMoviesBlock: {
      return getNavigationSize(
        getFavoritesFirstPageFromCache({
          config,
          types: [FavoriteType.Movies],
        }),
        max,
        range,
      );
    }
    case BlockType.FavoriteChannelsBlock: {
      return getNavigationSize(
        getFavoritesFirstPageFromCache({ config, types: [FavoriteType.Channels] }),
        max,
        range,
      );
    }
    case BlockType.FavoriteAudioShowBlock: {
      return getNavigationSize(
        getFavoritesFirstPageFromCache({ config, types: [FavoriteType.AudioShows] }),
        max,
        range,
      );
    }
    case BlockType.FavoriteSeriesBlock: {
      return getNavigationSize(
        getFavoritesFirstPageFromCache({ config, types: [FavoriteType.Series] }),
        max,
        range,
      );
    }
    case BlockType.FavoritesBlock: {
      return getNavigationSize(
        getFavoritesFirstPageFromCache({
          config,
          types: (block.resource_types || []) as FavoriteType[],
        }),
        max,
        range,
      );
    }
    case BlockType.RecommendationsBlock: {
      return getNavigationSize(getRecsFirstPageFromCache(config, block.recommendation_context), max, range)
    }
    case BlockType.ContinueWatchingBlock: {
      return getNavigationSize(
        getContinueWatchingFirstPageFromCache({
          config,
          queryParams: {
            types: block.resource_types as ContinueWatchingType[] | undefined
          }
        }),
        max, range,
      );
    }
    case BlockType.CollectionBlock: {
      if (!block.collection?.id) {
        return null;
      }

      return getNavigationSize(getCachedCollectionItems(block.collection?.id), max, range);
    }
    case BlockType.CardCollectionBlock: {
      if (!block.card_collection?.id) {
        return null;
      }

      return getNavigationSize(getCardColItemsFirstPageFromCache(config, {
        cardCollectionID: block.card_collection.id
      }), max, range);
    }
    case BlockType.CurrentProgramLineBlock: {
      if (!block.channel_slug) {
        return null;
      }

      return ((getCachedChannel(block.channel_slug) !== null) ? 1 : 0);
    }
    case BlockType.FiltersBlock: {
      return (block.search_filters_group?.items.length || null);
    }
    case BlockType.ExpandableCardColletion: {
      // должно вернуть хоть что-то чтобы отобразить блок, но по факту блок делится
      // на 3 разных блока с разным кол-вом элементом
      return 1
    }
    default:
      return null;
  }
};

export {
  getBlockItemsCount,
  getIndex,
  getSizeIfAllDownloaded,
};
