// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--nxR99 {\r\n  position: relative;\r\n  width: 100vw;\r\n}\r\n\r\n.linesWrapper--ppN8T {\r\n  margin-top: 45.83333333333333vh;\r\n}\r\n\r\n.descriptionWrapper--WRUlL {\r\n  margin-left: 5.3125vw;\r\n}\r\n\r\n.mediaItemBarWrapper--DAR4L {\r\n  margin-left: 6.875000000000001vw;\r\n}\r\n\r\n.buttonWrapper--bvPtg {\r\n  margin-right: 1.563vw;\r\n  display: inline-block;\r\n  vertical-align: top;\r\n}\r\n\r\n.mediaItemBar--KOQ6u {\r\n  display: -webkit-box;\r\n  display: -webkit-flex;\r\n  display: -moz-box;\r\n  display: flex;\r\n  margin-top: 2.65625vw;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/app/components/PromocodeActivation/screens/PromocodeRentPlanConfirm/PromocodeRentPlanResourcePage/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".container {\r\n  position: relative;\r\n  width: 100vw;\r\n}\r\n\r\n.linesWrapper {\r\n  margin-top: 45.83333333333333vh;\r\n}\r\n\r\n.descriptionWrapper {\r\n  margin-left: 5.3125vw;\r\n}\r\n\r\n.mediaItemBarWrapper {\r\n  margin-left: 6.875000000000001vw;\r\n}\r\n\r\n.buttonWrapper {\r\n  margin-right: 1.563vw;\r\n  display: inline-block;\r\n  vertical-align: top;\r\n}\r\n\r\n.mediaItemBar {\r\n  display: flex;\r\n  margin-top: 2.65625vw;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var container = "container--nxR99";
export var linesWrapper = "linesWrapper--ppN8T";
export var descriptionWrapper = "descriptionWrapper--WRUlL";
export var mediaItemBarWrapper = "mediaItemBarWrapper--DAR4L";
export var buttonWrapper = "buttonWrapper--bvPtg";
export var mediaItemBar = "mediaItemBar--KOQ6u";
export default ___CSS_LOADER_EXPORT___;
