import { QueryKey } from 'react-query';

import { queryCache } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';

// Функция удаляет кеш react-query, который имеет отношение к
// устаревшим accessToken (например, если был сменён профиль)
const deleteCacheWithOldAccessTokens = (key: QueryKey, accessTokenIndex: number): void => {
  try {
    const { accessToken } = getAuthData();

    if (accessToken) {
      const cachedQueries = queryCache.findAll(key);
      cachedQueries.forEach((query) => {
        const queryKey = query.queryKey;
        const cachedAccessTokenInQuery = queryKey[accessTokenIndex] || null;
        if (cachedAccessTokenInQuery !== accessToken) {
          queryCache.remove(query);
        }
      });
    }
  } catch (ignore) {}
};

export default deleteCacheWithOldAccessTokens;
