import { adc_02, gw_11 } from 'customization-project-name/colors';
import { useMemo } from 'react';
import { InjectedIntl } from 'react-intl';

import { useAnimationContext } from '~components/Provider/Animation';
import { FREE } from '~lib/localizator/src/dictionaries';
import { Nullable } from '~lib/type-utils/utils';
import { TSimageShape } from '~typings/Card';
import Distribution from '~typings/Distribution';
import Episode from '~typings/Episode';
import Series from '~typings/Series';
import { PropsTS } from '~ui/CardV2/Horizontal/HorizontalCard';

import { getHorizontalCardImageParams, useCardImage } from '../hooks';

export type MakeCardPropsParams = {
  episode: Episode;
  episodeProgress?: Nullable<number>;
  seriesCertificationRatings: Series['certification_ratings'];
  intl: InjectedIntl;
};

export const useCardPropsFromEpisode = ({
  episode,
  episodeProgress,
  seriesCertificationRatings,
  intl,
}: MakeCardPropsParams): Omit<PropsTS, 'isFocused'> => {

  const { cardImageType, widthVW, heightVH, type } = getHorizontalCardImageParams(TSimageShape.undefined);

  const imageSource = useCardImage({
    cardImages: episode.images,
    isCardInappropriate: seriesCertificationRatings?.[0]?.may_be_inappropriate || false,
    cardImageType,
    widthVW,
    heightVH,
  });

  const image = useMemo(() => (imageSource ? { type, url: imageSource } : null), [imageSource]);

  const tag = seriesCertificationRatings?.[0]?.tag;

  const { isAnimationEnabled } = useAnimationContext();

  return useMemo(() => ({
    episodeNumber: String(episode.number),
    progress: episodeProgress,
    name: episode.name,
    label: episode.distribution === Distribution.Free ? {
      text: intl.formatMessage({ id: FREE }),
      textColor: gw_11,
      backgroundColor: adc_02,
    } : null,
    certificationTag: tag,
    modification: null,
    image,
    showCatalogHeader: false,
    showBottomTextBlock: true,
    isAnimationEnabled
  }), [episode, tag, image, isAnimationEnabled, episodeProgress]);
};
