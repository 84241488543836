import { FocuserClickHandler } from "@focuser";
import * as React from 'react';
import { InjectedIntl,injectIntl } from 'react-intl';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';

import { TEXT_BY_BAR_ID } from '../Description/bar/item.helpers';
import { BarItem } from '../Description/bar/typings';



type Props = {
  barItem: BarItem;
  isFocused: boolean;
  onClick?: (item: BarItem) => void;
  intl: InjectedIntl;
};

const CommonButton: React.FC<Props> = ({ barItem, isFocused, onClick, intl }) => {
  const handleClick: FocuserClickHandler = (event) => {
    event.stop()
    event.stopNativeEvent()
    onClick?.(barItem);
  };

  const text = TEXT_BY_BAR_ID[barItem];

  return (
    <PrimaryButtonFocuser
      isFocused={ isFocused }
      onClick={ handleClick }
      noNeedForceFocus
    >
      { text ? intl.formatMessage({ id: text }) : null }
    </PrimaryButtonFocuser>
  );
};


export default injectIntl(CommonButton);
