import { useCallback } from 'react';

import { ActionType as MenuActionType, useMenuAction } from '~components/Provider/Menu';
import { useMenu } from '~components/Provider/Menu';
/**
 * Хук делает более удобным открытие меню
 */
export const useMenuOpener = () => {
  const menuAction = useMenuAction();
  const openMenu = useCallback(() => {
    menuAction({
      type: MenuActionType.ChangeMenuState,
      isMenuOpened: true,
    });
  }, [menuAction]);

  return {
    openMenu,
  };
};

/**
 * Хук показывает, открыто ли меню
 */
export const useIsMenuOpen = ()=> {
  return useMenu().isMenuOpened;
}
