import Episode from '~typings/Episode';
import ItemObject from '~typings/ItemObject';


const setPlayingEpisode = (episode: Episode, matchPage, history, playerSetItem): boolean => {
  try {
    if (matchPage) {
      history.replace(`${matchPage.url}/media-item/${ItemObject.Episode}/${episode.id}/player?player=true`);
    } else {
      history.replace(`/media-item/${ItemObject.Episode}/${episode.id}/player?player=true`);
    }

    playerSetItem(episode);

    return true;
  } catch (ignore) {
    return false;
  }
};

export {
  setPlayingEpisode,
};
