import React, { Dispatch, SetStateAction } from "react";

import { MemoizedButton } from "~components/SearchPage/keyBoard/buttons/button0";
import { KeyBoardKey, KeyBoardType } from "~components/SearchPage/keyBoard/keyboardLayouts/type0";

import * as css from './styles.module.css'

interface Props {
  isFocused: boolean;
  buttonLine: KeyBoardKey[]
  buttonLineIndex: number
  keyBoardType: KeyBoardType
  activeKey: KeyBoardKey
  query: string
  setQuery: (newQuery: string) => void;
  keyBoard: string
  setKeyBoard: Dispatch<SetStateAction<string>>
  handleSearchButtonClick?: () => void
}

export function ButtonLine({
                             isFocused,
                             buttonLine,
                             buttonLineIndex,
                             keyBoardType,
                             activeKey,
                             query,
                             setQuery,
                             keyBoard,
                             setKeyBoard,
                             handleSearchButtonClick,
                           }: Props) {
  return (
    <div className={ css.keyBoardRow }>
      {
        buttonLine.map((button, buttonIndex) => {
          const coords = activeKey.position.split('-')

          const focused = (coords[0] === buttonLineIndex.toString()) && (coords[1] === buttonIndex.toString())

          return <MemoizedButton key={ keyBoardType + buttonIndex }
                                 buttonIsFocused={ isFocused && focused }
                                 button={ button }
                                 keyBoardType={ keyBoardType }
                                 query={ query }
                                 setQuery={ setQuery }
                                 keyBoard={ keyBoard }
                                 setKeyBoard={ setKeyBoard }
                                 handleSearchButtonClick={ handleSearchButtonClick }
          />
        })
      }
    </div>
  )
}
