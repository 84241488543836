import useStreamAccess from '~hooks/fetch/useStreamAccess';
import ItemObject from '~typings/ItemObject';
import { ExpandedPlayerAccess } from '~typings/PlayerAccess';


const useAccess = (itemID?: string, itemObject?: ItemObject): ExpandedPlayerAccess | null => {
  const { data: access } = useStreamAccess({
    itemID: (itemID || null),
    itemObject: (itemObject || null),
  });

  const accessData = access?.data?.[0];
  return accessData ? accessData : null;
}

export default useAccess;
