import * as React from 'react';


type Props = {
  className?: string;
};

/* eslint-disable max-len */
const ErrorIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg className={ className } viewBox="0 0 70 70" fill="none">
      <line x1="4" y1="4" x2="66" y2="66" stroke="currentColor" strokeWidth="4" />
      <line x1="66" y1="4" x2="4" y2="66" stroke="currentColor" strokeWidth="4" />
    </svg>
  );
};


export default ErrorIcon;
