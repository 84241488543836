import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { Typography } from '~app/ui-kit/Typography';
import { ActionType, useAppAction } from '~components/Provider/App';
import { getAuthData } from '~hooks/fetch/useAccount';
import { HERE_YOU_CAN_ACTIVATE_A_PROMOCODE, I_HAVE_A_PROMOCODE, PROMOCODE_ACTIVATION } from '~lib/localizator/src/dictionaries';

import * as styles from './styles.modules.css';


export const PromoCodeTab: React.FC = () => {
  const history = useHistory();
  const { accessToken } = getAuthData();
  const appAction = useAppAction();

  const handleClickPromocodeActivateBtn = (evt) => {
    evt.stop();
    evt.stopNativeEvent();
    if(accessToken) {
      return history.push('/promo');
    } 
  
    appAction({
      type: ActionType.SetAuthRedirectUrl,
      payload: { authRedirectUrl: '/promo'},
    });
    
    history.push('/auth')
  }

  return (
    <>
      <Typography variant='h5_bold' className={ styles.title }>
        <FormattedMessage id={ PROMOCODE_ACTIVATION }/>
      </Typography>
      
      <Typography variant='subtitle1_medium' className={ styles.descr }>
        <FormattedMessage id={ HERE_YOU_CAN_ACTIVATE_A_PROMOCODE }/>
      </Typography>

      <PrimaryButtonFocuser
        isFocused
        onClick={ handleClickPromocodeActivateBtn }
        noNeedForceFocus
        emitForceFocusOnHover
      >
        <FormattedMessage id={ I_HAVE_A_PROMOCODE }/>
      </PrimaryButtonFocuser>
    </>
  );
};

