import * as React from 'react';

import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { ActionType as MenuActionType, useMenuAction } from '~components/Provider/Menu';
import Page from '~typings/Page';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isMenuOpened: boolean;
  page: Page;
}>;


const PageNotFound: React.FC<Props> = (props: Props) => {
  const appAction = useAppAction();
  const menuAction = useMenuAction();
  const { isLaunchingDone } = useApp();

  React.useEffect(() => {
    if (!isLaunchingDone) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: { isLaunchingDone: true },
      });
    }
  }, [isLaunchingDone]);

  React.useEffect(() => {
    if (!props.isMenuOpened) {
      menuAction({
        type: MenuActionType.ChangeMenuState,
        isMenuOpened: true,
      });
    }
  }, [props.isMenuOpened]);

  return (
    <div
      className={ styles.pageNotFound }
    >
      { `Page "${props.page.uid}:${props.page.object}" Not Defined` }
    </div>
  );
};


export default PageNotFound;
