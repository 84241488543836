import * as React from 'react';

import { LIMIT } from "~hooks/fetch/constants";


type Props = Readonly<{
  total: number;
  currentPage: number;
  currentCount: number;
  focusedIndex: number;
  limit?: number;
  onNextPage: (page: number) => void;
}>;

const usePagination = (props: Props) => {

  React.useEffect(() => {
    const limit  = props.limit || LIMIT;

    if (
      props.total !== props.currentCount
      && props.focusedIndex > (props.currentCount - limit / 4)
      && props.focusedIndex < props.total
    ) {
      props.onNextPage(Math.ceil((props.focusedIndex + limit / 4) / limit) - 1);
    }
  }, [props.total, props.limit, props.currentCount, props.focusedIndex]);
};


type Props2 = Readonly<{
  total: number;
  currentCount: number;
  focusedIndex: number;
  numberInLine: number;
  setState: (number) => void;
  state: any;
}>;

const usePagination2 = (props: Props2) => {
  /**
   * все изменения уже внесены в RST-200 это временное решение для KPI beeline
   */
  const { currentCount, focusedIndex, numberInLine, state, setState, total } = props

  React.useEffect(() => {
    const currentLine = Math.floor(focusedIndex / numberInLine) + 1
    const currentCountLines = Math.floor(currentCount / numberInLine)
    const totalCountLines = Math.floor(total / numberInLine)
    const isNeedToFetch = currentCountLines - currentLine
    if (isNeedToFetch <= 3) {
      if (totalCountLines - currentCountLines !== 0) {
        setState(state + 1)
      }
    }
  }, [focusedIndex, total, currentCount])
};

type PaginationByItemsProps = Readonly<{
  /**
   * Кол-во элементов, которые уже загружены
   * items.data.length обычно
   */
  currentFetchedCount: number;
  /**
   * На какой строке сейчас находится фокус (счет ведется от нуля, 0,1,2...)
   */
  focusedLineIdx: number;
  /**
   * Кол-во элементов на одной строке
   */
  elementsPerLine: number;
  /**
   * Минимальное кол-во линий на экране
   * То есть, если при открытии на экране `3` линии, то сюда нужно передать `3`
   * Передавать нужно только положительные числа
   */
  minimalLinesOnScreen: number;
  /**
   * Коллбэк будет вызываться, когда нужны будут новые данные
   * Проверка на наличие новых данных нужно реализовать самостоятельно
   * Например (псевдокод)
   * ```fetchMoreData: ()=> {
   *  if(isBackendHasMoreData && notFetchingNewData){
   *   fetchNewData();
   *  }
   * }```
   */
  fetchMoreData: ()=> void;
  /**
   * Массив зависимостей, который добавляется в useEffect (через spread оператор `...[]`)
   * Служит для того, чтобы 'пинговать' пагинацию
   * Например нам нужно чтобы пагинация не работала, пока идет какая-то загрузка `isLoading`
   * Тогда добавляем в updates это поле, и когда `isLoading` поменяется, пагинация пересчитается
   * ```
   * {
   *  updates: [isLoading]
   * }
   * ```
   */
  updates?: any[];
}>;

const usePaginationByItems = (props: PaginationByItemsProps) => {
  const { focusedLineIdx, elementsPerLine, currentFetchedCount, fetchMoreData, minimalLinesOnScreen } = props

  React.useEffect(() => {

    // Кол-во элементов, которое обязательно должно быть в памяти, вне зависимости от того, на какой строке находимся
    const minimalCacheItemsCount = (elementsPerLine * minimalLinesOnScreen);
    // Кол-во элементов, которое должно быть в памяти, учитывая строку, на которой мы сейчас
    const itemsCountNeedToBeInMemory = ((focusedLineIdx + 1) * elementsPerLine) + minimalCacheItemsCount;
    if(itemsCountNeedToBeInMemory > currentFetchedCount){
      fetchMoreData();
    }
  }, [focusedLineIdx, currentFetchedCount, elementsPerLine, minimalLinesOnScreen, ...(props.updates ?? [])])
};


type OneLinePagination = Readonly<{
  /**
   * Кол-во элементов, которые уже загружены
   * items.data.length обычно
   */
  currentFetchedCount: PaginationByItemsProps['currentFetchedCount'];
  /**
   * На каком элементе сейчас  (счет ведется от нуля, 0,1,2...)
   */
  focusedElemIdx: number;
  /**
   * Минимальное кол-во элементов, которое должно быть загружено заранее
   * То есть, если при открытии на экране вмещается `5`, то сюда нужно передать `5`
   */
  minimalPrefetchedCount: number;
  /**
   * Коллбэк будет вызываться, когда нужны будут новые данные
   * Проверка на наличие новых данных нужно реализовать самостоятельно
   * Например (псевдокод)
   * ```fetchMoreData: ()=> {
   *  if(isBackendHasMoreData && notFetchingNewData){
   *   fetchNewData();
   *  }
   * }```
   */
  fetchMoreData: PaginationByItemsProps['fetchMoreData'];
  /**
   * Массив зависимостей, который добавляется в useEffect (через spread оператор `...[]`)
   * Служит для того, чтобы 'пинговать' пагинацию
   * Например нам нужно чтобы пагинация не работала, пока идет какая-то загрузка `isLoading`
   * Тогда добавляем в updates это поле, и когда `isLoading` поменяется, пагинация пересчитается
   * ```
   * {
   *  updates: [isLoading]
   * }
   * ```
   */
  updates?: PaginationByItemsProps['updates'];
}>;

/**
 * Используется для пагинации на одной строке
 * (то есть, когда пагинация нужна в горизонтальном слайдере)
 */
const usePaginationOneLine = (props: OneLinePagination) => {
  const { focusedElemIdx, currentFetchedCount, fetchMoreData, minimalPrefetchedCount, updates } = props
  return usePaginationByItems({
    elementsPerLine: 1,
    currentFetchedCount,
    focusedLineIdx : focusedElemIdx,
    minimalLinesOnScreen: minimalPrefetchedCount,
    updates,
    fetchMoreData
  });
};

export default usePagination;

export { usePagination2, usePaginationByItems, usePaginationOneLine }
