// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainWrapper--ZOG5p {\n  /* 36px */\n  padding-top: 2.8125vw;\n}\n\n.sliderWrapper--EZOlO {\n  /* 652px */\n  height: 50.9375vw;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/screens/EPGPage/components/EPGChannels/EPGChannels.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".mainWrapper {\n  /* 36px */\n  padding-top: 2.8125vw;\n}\n\n.sliderWrapper {\n  /* 652px */\n  height: 50.9375vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var mainWrapper = "mainWrapper--ZOG5p";
export var sliderWrapper = "sliderWrapper--EZOlO";
export default ___CSS_LOADER_EXPORT___;
