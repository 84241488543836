import { useEffect } from 'react';
import React from 'react';
import { InfiniteData, useInfiniteQuery, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient, useConfig } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getAuthData } from '~hooks/fetch/useAccount';
import {
  getDropdownFiltersOptions,
  getQuickFiltersString,
  SearchFilters,
} from '~hooks/fetch/useSearch/useSearch.helpers'
import ApiClient from '~lib/ApiClient';
import deleteCacheWithOldAccessTokens from '~lib/ReactQuery/deleteCacheWithOldAccessTokens';
import AppConfig from '~typings/AppConfig';
import Card from '~typings/Card';
import ResponseMany from '~typings/ResponseMany';

import { CCIKeys, PREFIX_OF_A_COMPOSITE_KEY } from '../useCCI/CCIKeys';
import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '../utils';

const FIRST_PAGE = 0;
type QueryKeyParams = {
  accessToken: string | null,
  cardCollectionID?: string,
  filters: SearchFilters,
  limit: number,
}




/**
 * @deprecated, используй методы из `useCCI`
 */
type FetchParams = Readonly<{
  page: number;
  limit: number;
  client: ApiClient;
  cardCollectionID?: string;
  cardConfigID?: string;
  filters?: SearchFilters;
  accessToken?: string | null;
}>;

/**
 * @deprecated, используй методы из `useCCI`
 */
const fetch = async (params: FetchParams): Promise<ResponseMany<Card[]>> => {
  let options = {
    ...fetchOptionsBasic[FetchType.CardCollectionsItems],
    'filter[collection_id_eq]': params.cardCollectionID,
    'page[offset]': params.page * params.limit,
    'page[limit]': params.limit,
  };

  if (params.accessToken) {
    options['access_token'] = params.accessToken;
  }

  if (params.filters?.quickFilters) {
    options['filter[quick_filter_ids_include]'] = getQuickFiltersString(params.filters.quickFilters);
  }
  if (params.filters?.dropdownFilters) {
    options = {
      ...options,
      ...getDropdownFiltersOptions(params.filters.dropdownFilters),
    }
  }

  deleteCacheWithOldAccessTokens(CCIKeys.PREFIX, 1);

  const response = await params.client.get(
    fetchURLs[FetchType.CardCollectionsItems],
    options,
  );

  return ({
    ...response,
    data: response.data.map((item: Card) => ({
      ...item,
      card_config_id: params.cardConfigID,
    }))
  });
};

/**
 * @deprecated, используй методы из `useCCI`
 */
const getCCIFirstPageHookParams = (client: ApiClient, config: AppConfig, queryParams: CardColItemsQueryParams )=>{
  const {
    cardCollectionID,
    cardConfigID,
    filters,
    enabled = true
  } = queryParams;
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(config);

  const keyParams : QueryKeyParams = {
    accessToken,
    cardCollectionID,
    filters: filters ?? {},
    limit,
  }

  return ({
  queryKey: CCIKeys.firstNormalPage(keyParams),
  queryFn: async () => {
    const infinityCCIPagesFromQuery = queryClient.getQueryData<InfiniteData<ResponseMany<Card[]>>>(
      CCIKeys.infinityPages(keyParams),
    );

    const firstPageFromInfinityPages = infinityCCIPagesFromQuery?.pages[0];

    if(firstPageFromInfinityPages){
      return firstPageFromInfinityPages;
    }

    const infinityCCIPagesReq = await queryClient.fetchInfiniteQuery({
      queryKey: CCIKeys.infinityPages(keyParams),
      queryFn: () =>
        fetch({
          client,
          accessToken,
          page: FIRST_PAGE,
          limit,
          cardConfigID,
          cardCollectionID,
          filters,
        }),
    });

    return infinityCCIPagesReq.pages[0];
  },
  enabled: Boolean(cardCollectionID) && enabled,
});
}



type CardColItemsQueryParams = {
  cardCollectionID?: string,
  cardConfigID?: string;
  filters?: SearchFilters,
  /**
   * Активен ли запрос, по дефолту = true
   * @default true
   */
  enabled?: boolean;
}

/**
 * @deprecated, используй методы из `useCCI`
 */
const getCardColItemsFirstPageFromCache = (config: AppConfig, queryParams: CardColItemsQueryParams) => {
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(config);
  const keyParams : QueryKeyParams = {
    accessToken,
    cardCollectionID: queryParams.cardCollectionID,
    filters: queryParams.filters ?? {},
    limit,
  }
  return queryClient.getQueryData<ResponseMany<Card[]>>(
    CCIKeys.firstNormalPage(keyParams),
  );
};

/**
 * @deprecated, используй методы из `useCCI`
 */
const getCardColInfinityItemsCount = (config: AppConfig, queryParams: CardColItemsQueryParams) => {
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(config);
  const keyParams : QueryKeyParams = {
    accessToken,
    cardCollectionID: queryParams.cardCollectionID,
    filters: queryParams.filters ?? {},
    limit,
  }
  return queryClient
    .getQueryData<InfiniteData<ResponseMany<Card[]>>>(CCIKeys.infinityPages(keyParams))
    ?.pages.reduce((acc, page) => acc + page.data.length, 0);
};

/**
 * @deprecated, используй методы из `useCCI`
 */
const useInfinityCardColItems = (
  {
    cardCollectionID,
    cardConfigID,
    filters = {},
    enabled = true
  }: CardColItemsQueryParams
) => {
  const client = useClient();
  const { accessToken } = getAuthData();
  const config = useConfig();
  const limit = getLimitFromConfig(config);

  const query = useInfiniteQuery<ResponseMany<Card[]>>({
  queryKey: CCIKeys.infinityPages({
    accessToken,
    cardCollectionID,
    filters,
    limit,
  }),
  queryFn: ({ pageParam = 0 }) =>
    fetch({
      client,
      accessToken,
      page: pageParam,
      limit,
      cardConfigID,
      cardCollectionID,
      filters,
    }),
  getNextPageParam: getNextPageForInfinityPagination,
  enabled: Boolean(cardCollectionID) && enabled,
});

const parsedData = React.useMemo(() => {
  return query.data?.pages && reducePaginatedPages(query.data.pages);
}, [query.data?.pages]);

  return { query, parsedData, isExist: Boolean(parsedData?.meta.pagination.total) };
};

/**
 * @deprecated, используй методы из `useCCI`
 */
const useCardColItemsFirstPage = (queryParams: CardColItemsQueryParams) => {
  const client = useClient();
  const config = useConfig();
  const hookParams = getCCIFirstPageHookParams(client, config, queryParams);
  return useQuery<ResponseMany<Card[]>>(hookParams);
};


/**
 *
 * @deprecated, используй методы из `useCCI`
 *
 * Хук сам автоматически загружает все страницы
 */
const useAutoInfinityCardColItems = (params: CardColItemsQueryParams) => {
  const { query, parsedData } = useInfinityCardColItems(params);

  useEffect(() => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage();
    }
  }, [query]);

  return {
    query,
    parsedData,
  };
};


export {
  getCardColInfinityItemsCount,
  getCardColItemsFirstPageFromCache,
  getCCIFirstPageHookParams,
  PREFIX_OF_A_COMPOSITE_KEY,
  useAutoInfinityCardColItems,
  useCardColItemsFirstPage,
  useInfinityCardColItems,
};
