import * as cn from 'classnames';
import * as React from 'react';

import CurrentEventInfo from '~components/CurrentEventInfo/components';
import MediaItemHeader from '~components/MediaItemHeader';
import { ProgramEventInfo } from '~components/ProgramEventInfo';
import SceneItem from '~components/Scene/typings';
import { isCardChannel, isCardProgramEvent, isChannel, isProgramEvent } from '~lib/objectType';
import Card from '~typings/Card';
import Channel from '~typings/Channel';
import Event from '~typings/Event';
import ItemObject from '~typings/ItemObject';

import * as styles from './styles.module.css';


type Props = Readonly<{
  item: SceneItem;
  isPlayerPageWithDelay: boolean;
  isPlayerInSearchURL: boolean;
  isMediaItemPageMode: boolean;
}>;


const MediaItemText: React.FC<Props> = (props: Props) => {
  const isVisible = (
    !(props.isPlayerPageWithDelay || props.isPlayerInSearchURL)
    && !(props.isMediaItemPageMode && props.item?.object === ItemObject.News)
  );

  return (
    <div className={ cn(styles.mediaItemDescription, { [styles.mediaItemDescriptionHide]: !isVisible }) }>
      {
        (isChannel(props.item) || isCardChannel(props.item))
          ? <CurrentEventInfo isBigSize item={ props.item as Channel | Card } />
          : (isProgramEvent(props.item) || isCardProgramEvent(props.item))
            ? <ProgramEventInfo event={ props.item as Event | Card } />
            : <MediaItemHeader item={ props.item } withFullInfo={ props.isMediaItemPageMode } />
      }
    </div>
  );
};


export default MediaItemText;
