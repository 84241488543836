/* eslint-disable max-len */
import * as React from 'react';


const IconChannels: React.FC = () => (
  <svg
    className="playerIcon"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.058,22.522a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H11.152a1,1,0,0,1-1-1h0a1,1,0,0,1,1-1Zm-12.571-2a2.367,2.367,0,0,1-2.366-2.366l.064-8.2A2.367,2.367,0,0,1,8.552,7.588l15.206-.047a2.33,2.33,0,0,1,2.3,2.387l.007,8.178A2.366,2.366,0,0,1,23.7,20.475Z"
      fill="currentColor"
    />
  </svg>
);


export default React.memo(IconChannels);
