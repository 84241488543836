import * as React from 'react';


const useDelayedChange = <T>(value: T, delayTimeInMs?: number): T => {
  const [delayedValue, setDelayedValue] = React.useState<T>(value);

  React.useEffect(() => {
    const delayTimer = setTimeout(() => {
      setDelayedValue(value);
    }, delayTimeInMs || 0);

    return () => clearTimeout(delayTimer);
  }, [value]);

  return delayedValue;
};


export default useDelayedChange;
