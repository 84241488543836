import focuser, { FocusableBlockProps, FocuserKeyHandler } from '@focuser';
import * as cn from 'classnames';
import React from 'react';

import { Typography } from '~app/ui-kit/Typography';
import { BigBannerBlock } from '~components/BigBannerBlock';
import { MiddleBannerBlock } from '~components/MiddleBannerBlock';
import { useApp } from '~components/Provider/App';
import { useGlobalSceneBlocker } from '~components/Scene/globalSceneDisabler';
import { useDispatchLaunchDone } from '~hooks/useDispatchLaunchDone';
import { useDropSceneItem } from '~hooks/useDropSceneItem';
import { useToggle } from '~hooks/useToggle';
import { Block } from '~typings/Block';
import CardCollection from '~typings/CardCollection';
import DropdownFilter from '~typings/DropdownFilter';
import QuickFilter from '~typings/QuickFilter';

import {
  useCanFocusOnDescription,
  useCardColContentNavigation,
  useCardColContentScroller,
  useChannelsPreparer,
} from './CardCollectionContent.hooks';
import * as styles from './CardCollectionContent.module.css';
import { CardColContentGridWithFilters } from './components/CardColContentGridWithFilters';
import { DescriptionShowPopup } from './components/DescriptionShowPopup';

interface Props {
  cardCollection?: CardCollection
  middleBannerBlock?: Block;
  bigBannerBlock?: Block;
  filters?: (QuickFilter | DropdownFilter)[];
  returnButtonType: FocusableBlockProps['returnButtonType'];
  onKeyReturn: FocuserKeyHandler;
}

const CardCollectionConten$: React.FC<Props> = ({
  cardCollection,
  middleBannerBlock,
  bigBannerBlock,
  filters,
  returnButtonType,
  onKeyReturn: onKeyRetuyrn
 }) => {
  useGlobalSceneBlocker();
  useDropSceneItem();
  useDispatchLaunchDone();
  const { isLaunchingDone } = useApp();

  const [isDescPopupOpen, { turnOn: openDescrPopup, turnOff: closeDescPopup }] = useToggle(false);

  const isDescriptionExist = !!cardCollection?.description;
  const isFiltersExist = !!filters?.length;

  useChannelsPreparer(cardCollection?.id);

  const {
    focuserProps,
    setIsDescriptionReady,
    readyChilds,
    focusOn,
    setFocusOn,
    setIsContentReady,
    setFocusOnContent,
  } = useCardColContentNavigation({ hasBanner: !!(bigBannerBlock || middleBannerBlock) });

  const handleContentReadyToFocus = () => {
    setIsContentReady(true);
    if (!readyChilds[focusOn]) {
      setFocusOn('content');
    }
  };

  const handleDescriptionReadyToFocus = () => {
    setIsDescriptionReady(true);
    if (!readyChilds['banners']) {
      setFocusOn('description');
    }
  };

  const { descriptionTextRef } = useCanFocusOnDescription({
    canCheck: !!isLaunchingDone,
    onReady: handleDescriptionReadyToFocus,
  });

  const { pageRef, afterBannersRef, scrollToGrid } = useCardColContentScroller({
    showBanners: focusOn === 'banners',
  });

  const renderBanner = () => {
    if (!bigBannerBlock && !middleBannerBlock) {
      return null;
    }

    if (bigBannerBlock) {
      return (
        <focuser.FocusableBlock
          className={ cn(styles.bigBanner, {
            [styles.bigBannerFocused] : focusOn === 'banners',
          }) }
          isFocused={ focusOn === 'banners' }
        >
          <BigBannerBlock blockId={ bigBannerBlock.id } />
        </focuser.FocusableBlock>
      );
    }

    return (
      <focuser.FocusableBlock
        className={ styles.middleBanner }
        isFocused={ focusOn === 'banners' }
      >
        <MiddleBannerBlock blockId={ middleBannerBlock!.id } />
      </focuser.FocusableBlock>
    );
  };

  const renderTitle = (cardCollectionName: string) => {
    if (!cardCollectionName) {
      return null;
    }

    if (focusOn === 'banners') {
      return (
        <Typography
          variant="h5_medium"
          className={ cn(styles.smallTitle, {
            [styles.smallTitleWithMargin] : isDescriptionExist || isFiltersExist,
          }) }
        >
          {cardCollectionName}
        </Typography>
      );
    }

    return (
      <Typography
        variant="h3_medium"
        className={ cn(styles.bigTitle, {
          [styles.bigTitleWithMargin] : isDescriptionExist || isFiltersExist,
        }) }
      >
        {cardCollectionName}
      </Typography>
    );
  };

  const renderDescription = (descriptionText: string) => {
    if (!descriptionText) {
      return null;
    }

    return (
      <focuser.FocusableBlock
        isFocused={ focusOn === 'description' }
        className={ cn(styles.descriptionBlock, {
          [styles.descriptionBlockWithMargin] : isFiltersExist,
        }) }
        focusedClassName={ styles.descriptionBlockFocused }
        onForceFocus={ () => setFocusOn('description') }
        emitForceFocusOnHover={ readyChilds['description'] }
        onClick={ (e) => {
          e.stop();
          if (readyChilds['description']) {
            openDescrPopup();
          }
        } }
        isLastBlock
      >
        <Typography
          textRef={ descriptionTextRef }
          variant="subtitle1_medium"
          className={ styles.descriptionText }
        >
          {descriptionText}
        </Typography>
      </focuser.FocusableBlock>
    );
  };

  const renderTitleAndDescription = () => {
    if (!cardCollection) {
      return null;
    }

    return (
      <div className={ styles.titleBlock }>
        {renderTitle(cardCollection.name)}
        {renderDescription(cardCollection.description)}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <focuser.FocusableBlock
        isFocused={ focusOn === 'content' }
        onForceFocus={ () => setFocusOn('content') }
      >
        <CardColContentGridWithFilters
          filters={ filters }
          cardCollectionId={ cardCollection?.id }
          cardConfig={ cardCollection?.card_config }
          onReady={ handleContentReadyToFocus }
          onScrollToGrid={ scrollToGrid }
          onFocusRestore={ setFocusOnContent }
        />
      </focuser.FocusableBlock>
    );
  };

  return (
    <>
      <focuser.FocusableBlock
        { ...focuserProps }
        className={ cn(styles.mainWrapper, {
          [styles.mainWrapperWhenNotBanners]: focusOn !== 'banners',
        }) }
        noNeedForceFocus
        forwardRef={ pageRef }
        returnButtonType={ returnButtonType }
        onKeyReturn={ onKeyRetuyrn }
        isForceFocusEnabledInBranch={ focusOn !== 'banners' }
      >
        {renderBanner()}
        <div ref={ afterBannersRef } />
        {renderTitleAndDescription()}
        {renderContent()}
      </focuser.FocusableBlock>
      {isDescPopupOpen && (
        <DescriptionShowPopup
          name={ cardCollection?.name }
          description={ cardCollection?.description }
          onBack={ closeDescPopup }
        />
      )}
    </>
  );
};

/**
 * Компонент, который отображает коллекцию, с фильтрами и с баннерами
 */
export const CardCollectionContent = React.memo(CardCollectionConten$)
