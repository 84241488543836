import { compact,get } from 'lodash';

import ItemObject from '~typings/ItemObject';
import ManyItemType from '~typings/ManyItemType';


const DESCRIPTION_MAX_LEN = 1250;

const getGenres = (item: ManyItemType): string[] => (
  get(item, 'genres') && get(item, 'genres').map(({ name }) => name)
  || (item.object === ItemObject.Episode && item?.series?.genres.map(({ name }) => name))
  || []
);

const getMeta = (item: ManyItemType) => {
  const genres = getGenres(item).join(', ');
  const productionYear = get(item, 'production_year');
  const countries = (
    get(item, 'countries')
    || (item.object === ItemObject.Episode && item?.series?.countries)
    || []
  ).map(({ name }) => name).join(', ');
  const duration = get(item, 'duration') ?
    `${Math.round(get(item, 'duration') / 60)} мин.`
    :
    null;

  return [
    compact([productionYear, countries]).join(', '),
    compact([duration, genres]).join(', '),
  ];
};

export {
  DESCRIPTION_MAX_LEN,
  getGenres,
  getMeta,
};
