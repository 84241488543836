import * as React from 'react';

import { useApp } from '~components/Provider/App';
import { useScene } from '~components/Provider/Scene';
import MediaItemText from '~components/Scene/MediaItemText';
import useCurrentEvent from '~hooks/useCurrentEvent';
import useDelayedChange from '~hooks/useDelayedChange';
import useLocationSearch from '~hooks/useLocationSearch';
import useMediaItemPageInfo from '~hooks/useMediaItemPage';
import useSceneMediaItemPageInfo from '~hooks/useSceneMediaItemPage';
import { isChannel } from '~lib/objectType';
import ItemObject from '~typings/ItemObject';

import BackgroundImage from './BackgroundImage/BackgroundImage';
import MaskedSceneMediaItem from './MaskedSceneMediaItem/MaskedSceneMediaItem';
import * as styles from './styles.module.css';


const SceneWithHooks: React.FC = () => {
  const { isLaunchingDone } = useApp();
  const { isMediaItemPageMode } = useMediaItemPageInfo();
  const { isSceneMediaItemPageMode } = useSceneMediaItemPageInfo();
  const { item, withInfo, isChangeItemInProgress, changeSceneMediaItem } = useScene();
  const isPlayerInSearchURL = !!useLocationSearch()?.player;
  const isPlayerPageWithDelay = useDelayedChange<boolean>(isPlayerInSearchURL, 500);
  const isChannelItem = (item !== null && (isChannel(item)));
  const currentEvent = useCurrentEvent(!isChangeItemInProgress && isChannelItem ? item?.id : undefined);

  const mediaItemForIMG = React.useMemo(()=>{
    if(!item){
      return;
    }

    if (isChannelItem) {
      if (currentEvent?.images?.length === 0) {
        return item;
      }
      if (currentEvent?.channel_id === item.id) {
        return currentEvent;
      }
    }

    if (item.object === ItemObject.Episode) {
      return item.series || item;
    }

    if (item.object === ItemObject.Part) {
      return item.audio_show;
    }
    return item;
  }, [
    item?.id,
    isChannelItem,
    currentEvent?.id,
  ]);

  React.useEffect(() => {
    if (!isLaunchingDone && item !== null) {
      changeSceneMediaItem(null, true);
    }
  }, [isLaunchingDone]);

  if (!isLaunchingDone) {
    return null;
  }

  const isFullScreenImage = (item?.object === ItemObject.Banner);


  return (
    <div className={ styles.sceneMediaItemWrapper }>
      { mediaItemForIMG && <MaskedSceneMediaItem mediaItem={ mediaItemForIMG } />}
      {
        <BackgroundImage
          isBanner={ isFullScreenImage }
          isMediaItem={ !isFullScreenImage && !isMediaItemPageMode && !isSceneMediaItemPageMode }
          isMediaItemPage={ !isFullScreenImage && (isSceneMediaItemPageMode || isMediaItemPageMode) }
        />
      }
      {item && withInfo && (
        <MediaItemText
          item={ item }
          isPlayerPageWithDelay={ isPlayerPageWithDelay }
          isPlayerInSearchURL={ isPlayerInSearchURL }
          isMediaItemPageMode={ isMediaItemPageMode }
        />
      )}
    </div>
  );
};


export default SceneWithHooks;
