import * as cn from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { BlockTitle } from '~components/BlockTitle';
import SceneManagerFetcher from '~components/Scene/SceneManagerFetcher';
import Slider from '~components/Slider';
import { useConfig } from '~global';
import { maxSliderSizeDefault } from "~hooks/fetch/constants";
import {
  useRecommendationsForMediaItem,
} from '~hooks/fetch/useRecommendations/useRecommendationMediaItem';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import { RECOMMENDED, RECOMMENDED_FOR_YOU } from '~localization';
import Genres from '~typings/Genre';
import ImageType from '~typings/ImageType';
import Movie from '~typings/Movie';
import Series from '~typings/Series';

import * as styles from './styles.module.css';


type Props = Readonly<{
  item: Movie | Series;
  genres?: Genres[];
  isFocused: boolean;
  focusedItemIndex: number;
  setNavLimit: (limit: number) => void;
}>;


const Recommendations: React.FC<Props> = (props: Props) => {
  const { maxSliderSize, recommenderEngineEnable } = useConfig(c=>c.smartTV);
  const limitForRecommendations = maxSliderSize || maxSliderSizeDefault;

  const { error, data: recommendationsData } = useRecommendationsForMediaItem({
    object: props.item.object,
    id: props.item.id,
    limit: limitForRecommendations,
    genres: props.genres?.map(({ id }) => id).join(',') || '',
  });

  const actualSliderSize = (recommendationsData?.data.length) ?? 0;

  React.useEffect(()=>{
    if (props.setNavLimit) {
      //установка актуального количества итемов в навигацию
      props.setNavLimit(actualSliderSize);
    }
  }, [actualSliderSize]);

  const mediaItem = (props.isFocused && recommendationsData?.data[props.focusedItemIndex]?.id) ?
    ({
      id: recommendationsData.data[props.focusedItemIndex].id,
      object: recommendationsData.data[props.focusedItemIndex].object,
    })
    :
    ({
      id: props.item.id,
      object: props.item.object,
    });

  if (!recommendationsData?.data || error !== null || recommendationsData?.meta.pagination.total === 0) {
    return null;
  }

  const style: React.CSSProperties = {};
    style.transform = `translate3d(0, ${ valueInPixelsByWidth(7.8125) }px, 0)`;
    style.WebkitTransform = `translate3d(0, ${ valueInPixelsByWidth(7.8125) }px, 0)`;

  if (props.isFocused) {
    style.transform = `translate3d(0, ${ valueInPixelsByWidth(1.171875) }px, 0)`;
    style.WebkitTransform = `translate3d(0, ${ valueInPixelsByWidth(1.171875) }px, 0)`;
  }

  return (
    <div
      className={ cn(styles.recommendationsWrapper) }
      style={ style }
    >
      <SceneManagerFetcher
        withInfo
        id={ mediaItem.id }
        object={ mediaItem.object }
      />
      <BlockTitle
        isFocusedBlock={ props.isFocused }
        isVisible
        isFadeOut={ false }
        blockTitleClassName={ styles.blockTitle }
      >
        <FormattedMessage id={ recommenderEngineEnable ? RECOMMENDED : RECOMMENDED_FOR_YOU } />
      </BlockTitle>
      <div>
        <Slider
          dimension={ ImageType.POSTER }
          items={ recommendationsData }
          isFocusedBlock={ props.isFocused }
          focusedIndex={ props.focusedItemIndex }
          disableHorizontalNavigation
        />
      </div>
    </div>
  );
};


export default React.memo(Recommendations);
