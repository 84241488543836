import cn from 'classnames';
import * as React from 'react';
import { InjectedIntl,injectIntl } from 'react-intl';

import { useConfig } from '~app/global';
import { AuthActionType, useAuthMutation } from '~hooks/fetch/useAccount';
import useRegistrationMutation, { RegistrationResult } from '~hooks/fetch/useAccount/useRegistrationMutation';
import { REGISTRATION_NOTIFY_ME_ABOUT_NEWS_PROMO } from '~localization';
import NavigationDirection from '~typings/NavigationDirection';
import { CheckboxButton } from '~ui/CheckboxButton';

import { getMessageByError } from '../helpers';
import PasswordField from './PasswordField';
import * as styles from './styles.module.css';


enum PageFocusOn {
  Password = 'passford_focus',
  NotificationsCb = 'cb_focus',
}

type PreviewCredentialsProps = Readonly<{
  username: string;
  password: string;
  isSubmitted: boolean;
  isFocused: boolean;

  onSuccess: () => void;
  onProcessingStatus: (isProcessing: boolean) => void;
  onError?: (error) => void;
  onLeave: (NavigationDirection) => void;
  className: string;
  intl: InjectedIntl;
}>;

const PreviewCredentials: React.FC<PreviewCredentialsProps> = (props: PreviewCredentialsProps) => {

  const notificationsConfig = useConfig((c) => c.startup.login_form.notifications_checkbox);
  const isNotificationEnabled = notificationsConfig?.notifications_checkbox_enable;

  const [allowNotifications, setAllowNotifications] = React.useState(() =>
    isNotificationEnabled ? notificationsConfig.default_value : false,
  );

  const [focusOn, setFocusOn] = React.useState<PageFocusOn>(() =>
    isNotificationEnabled ? PageFocusOn.NotificationsCb : PageFocusOn.Password,
  );

  const { mutate: signUp } = useRegistrationMutation();
  const { mutate: signIn } = useAuthMutation();


  const errorHandler = (error) => {
    if (!props.onError) return;
    const messageText = getMessageByError(error, props.intl.formatMessage);
    props.onError(messageText);
  };

  const registrationSuccessHandler = (data: RegistrationResult) => {
    if (data.data?.confirmed) {
      props.onProcessingStatus(true);

      signIn({
        type: AuthActionType.AuthByPassword,
        payload: { username: props.username, password: props.password }
      }, {
        onSuccess: () => props.onSuccess(),
        onError: errorHandler,
        onSettled: () => props.onProcessingStatus(false),
      });
    } else {
      props.onSuccess();
    }
  };

  const passwordFocusLeaveHandle = (direction) => {
    if (direction != NavigationDirection.Down) {
      return;
    }
    if(isNotificationEnabled){
      setFocusOn(PageFocusOn.NotificationsCb);
    } else {
      props.onLeave(direction);
    }
  }

  const cbFocusLeaveHandle = (direction) => {
    if (direction === NavigationDirection.Up) {
      setFocusOn(PageFocusOn.Password);
    } else if (direction === NavigationDirection.Down) {
      props.onLeave(direction);
    }
  }

  React.useEffect(() => {
    if (!props.isSubmitted) return;

    props.onProcessingStatus(true);

    signUp(
      {
        username: props.username,
        password: props.password,
        allowNotifications: isNotificationEnabled ? allowNotifications : false,
      },
      {
        onSuccess: registrationSuccessHandler,
        onError: errorHandler,
        onSettled: () => props.onProcessingStatus(false),
      },
    );

  }, [
    props.isSubmitted,
    props.username,
    props.password,
    props.onSuccess,
    props.onProcessingStatus,
  ]);

  const handleToggleAllowNotifications = () => setAllowNotifications(val => !val);

  return (
    <div className={ cn(styles.wrapper, props.className) }>
      <div className={ styles.input }>{props.username}</div>
      <PasswordField
        password={ props.password }
        isFocused={ props.isFocused && focusOn === PageFocusOn.Password }
        onLeave={ passwordFocusLeaveHandle }
      />

      {isNotificationEnabled ? (
        <div
          className={ cn(styles.checkboxBlock, {
            [styles.focused]: props.isFocused && focusOn === PageFocusOn.NotificationsCb,
          }) }
        >
          <CheckboxButton
            isChecked={ allowNotifications }
            isFocused={ props.isFocused && focusOn === PageFocusOn.NotificationsCb }
            onClick={ handleToggleAllowNotifications }
            onLeave={ cbFocusLeaveHandle }
          />
          {props.intl.formatMessage({ id: REGISTRATION_NOTIFY_ME_ABOUT_NEWS_PROMO })}
        </div>
      ) : null}
    </div>
  );
};


export default injectIntl(PreviewCredentials);
