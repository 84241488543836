// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".name--esZYm {\n  width: 75%;\n  max-height: 3.8281250000000004vw;\n  white-space: nowrap;\n  font-weight: bold;\n  font-size: 3.125vw;\n  color: rgba(243, 243, 247, 1);\n  text-shadow: 1.2px 1.6px 0 rgba(23, 23, 27, 1);\n  overflow: hidden;\n  text-overflow: ellipsis;\n  -webkit-transition: 300ms ease-in-out;\n  transition: 300ms ease-in-out;\n  line-height: 3.8093749999999997vw;\n}\n\n.fullHD .name--esZYm {\n  text-shadow: 1.8px 2.4px 0 rgba(23, 23, 27, 1);\n}\n\n.name--esZYm.fullDescription--SvJd0 {\n  white-space: normal;\n  max-height: 11.40625vw;\n  -webkit-transition: 300ms ease-in-out;\n  transition: 300ms ease-in-out;\n}\n", "",{"version":3,"sources":["webpack://./src/app/ui/SceneItemName/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gCAAgC;EAChC,mBAAmB;EACnB,iBAAmB;EACnB,kBAAqB;EACrB,6BAAa;EACb,8CAAiC;EACjC,gBAAgB;EAChB,uBAAuB;EACvB,qCAA6B;EAA7B,6BAA6B;EAC7B,iCAAiC;AACnC;;AAEA;EACE,8CAAiC;AACnC;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,qCAA6B;EAA7B,6BAA6B;AAC/B","sourcesContent":[".name {\n  width: 75%;\n  max-height: 3.8281250000000004vw;\n  white-space: nowrap;\n  font-weight: $fw_00;\n  font-size: $fontH3_vw;\n  color: $gw_10;\n  text-shadow: 1.2px 1.6px 0 $gw_00;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  transition: 300ms ease-in-out;\n  line-height: 3.8093749999999997vw;\n}\n\n:global(.fullHD) .name {\n  text-shadow: 1.8px 2.4px 0 $gw_00;\n}\n\n.name.fullDescription {\n  white-space: normal;\n  max-height: 11.40625vw;\n  transition: 300ms ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
export var name = "name--esZYm";
export var fullDescription = "fullDescription--SvJd0";
export default ___CSS_LOADER_EXPORT___;
