import { throttle } from 'lodash';
import * as React from 'react';


type Props = Readonly<{
  duration: number;
  currentTime: number;
}>;

enum RewindDirection {
  BACK = -1,
  FORWARD = 1,
}


const REWIND_GAP = 10;
const NUMBER_OF_IDENTICAL_GAPS = 10
const REWIND_LOOP_DURATION = 200;
const REWIND_KEY_DOWN_THROTTLE = 50;
const THROTTLE_OPTIONS = { leading: true, trailing: false };

const useSeek = (props: Props) => {
  const currentTime = React.useRef<number>(props.currentTime);
  const isRewindMode = React.useRef<boolean>(false);
  const [rMode, setRMode] = React.useState<boolean>(false);
  const rewindDirection = React.useRef<RewindDirection | null>(null);
  const rewindTime = React.useRef<number | null>(null);
  const [rTime, setRTime] = React.useState<number | null>(null);
  const rewindLoopTickTimeout = React.useRef<number | undefined>(undefined);
  const rewindLoopStopTimeout = React.useRef<number | undefined>(undefined);
  const rewindLoopTickCount = React.useRef<number>(0);

  // End of rewind mode
  const rewindLoopStop = () => {
    window.clearTimeout(rewindLoopTickTimeout.current);
    window.clearTimeout(rewindLoopStopTimeout.current);

    rewindLoopTickCount.current = 0;
    rewindLoopStopTimeout.current = undefined;
    rewindLoopTickTimeout.current = undefined;
    isRewindMode.current = false;
    setRMode(false);
    rewindDirection.current = null;
    handleKeyRight.cancel();
    handleKeyLeft.cancel();
  };
  const rewindLoopTick = () => {
    if (rewindDirection.current) {
      rewindLoopTickCount.current += 1;
      isRewindMode.current = true;
      setRMode(true);

      const rewindLoopGap = Math.ceil(rewindLoopTickCount.current / NUMBER_OF_IDENTICAL_GAPS) * REWIND_GAP;
      let time = (rewindTime.current !== null) ?
        rewindTime.current + (rewindLoopGap * rewindDirection.current)
        :
        currentTime.current + (rewindLoopGap * rewindDirection.current);
      time = Math.max(time, 0);
      time = Math.min(time, props.duration);
      rewindTime.current = time;
      setRTime(rewindTime.current);

      rewindLoopStopTimeout.current = window.setTimeout(rewindLoopStop, REWIND_LOOP_DURATION);
      rewindLoopTickTimeout.current = window.setTimeout(rewindLoopTick, REWIND_LOOP_DURATION);
    }
  };
  // Rewind mode is saved, cancel delayed cancellation of rewind mode
  const rewindLoopContinue = () => {
    window.clearTimeout(rewindLoopStopTimeout.current);

    rewindLoopStopTimeout.current = undefined;
  };
  // Another step in rewind
  const rewindStep = () => {
    if (!isRewindMode.current) {
      isRewindMode.current = true;
      setRMode(true);

      rewindLoopTick();
    } else {
      rewindLoopContinue();
    }
  };
  const handleKeyLeft = React.useCallback(throttle(() => {
    if (rewindDirection.current === RewindDirection.FORWARD) {
      handleKeyRight.cancel();
    }

    rewindDirection.current = RewindDirection.BACK;
    rewindStep();
  }, REWIND_KEY_DOWN_THROTTLE, THROTTLE_OPTIONS), []);
  const handleKeyRight = React.useCallback(throttle(() => {
    if (rewindDirection.current === RewindDirection.BACK) {
      handleKeyLeft.cancel();
    }

    rewindDirection.current = RewindDirection.FORWARD;
    rewindStep();
  }, REWIND_KEY_DOWN_THROTTLE, THROTTLE_OPTIONS), []);

  React.useEffect(() => {
    currentTime.current = props.currentTime;
  }, [props.currentTime]);
  // Resetting the current rewind time after rewinding ends
  React.useEffect(() => {
    let timeout: any = null;

    if (!isRewindMode.current) {
      timeout = setTimeout(() => {
        rewindTime.current = null;
        setRTime(rewindTime.current);
      }, 1000);
    }

    return () => { if (timeout) { clearTimeout(timeout); } }
  }, [isRewindMode.current]);

  return ({
    isRewindMode: rMode,
    rewindTime: rTime,
    handleKeyLeft,
    handleKeyRight,
  });
};


export default useSeek;
