import * as cn from 'classnames';
import { backgroundImageBanner } from 'customization-project-name/assets';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useScene } from '~components/Provider/Scene';
import BannerSceneMediaItem from '~components/Scene/BannerSceneMediaItem';
import Slider from '~components/Slider/component';
import { useBannersV4 } from '~hooks/fetch/useBannersV4/useBanners';
import useDeepLink from '~hooks/useDeepLink';
import useDelayedChange from "~hooks/useDelayedChange";
import getScreenHeight from "~lib/screen/height";
import getScreenWidth from "~lib/screen/width";
import useNavigation from '~stores/Navigation';
import BannerBig from '~typings/BannerBig';
import { Block } from '~typings/Block';
import ImageType from '~typings/ImageType';
import BannerTextBlock from '~ui/BannerV4TextBlock/TextBlock';

import defaultMask from '../Scene/images/mask.png';
import * as styles from './styles.module.css';


type Props = Readonly<{
  blockIndex: number;
  block: Block;
  isFocusedBlock: boolean;
  isPopupOpened: boolean;
  isVisible?: boolean;
  focusedItemIndex: number;
}>;


/**
 * @deprecated, используй BigBannerBlock
 */
export default function BlockBannersBig(props: Props) {
  const scene = useScene();
  const history = useHistory();
  const { urlFromDeepLink } = useDeepLink();
  const focusedBlockIndex = useNavigation(state => state.getFocusedBlockIndex());
  const screenWidth = getScreenWidth();
  const screenHeight = getScreenHeight();


  React.useEffect(() => {
    if (props.isFocusedBlock) {
      scene.changeSceneMediaItem(null, false, true);
    }
  }, [props.isFocusedBlock]);

  const { data: items } = useBannersV4(props.block.id);
  const focusedItem = items && items.data[Math.max(props.focusedItemIndex, 0)];
  const delayedFocusedItem = useDelayedChange(focusedItem, 400);

  if (!items) {
    return null;
  }

  const handleClick = (bannerBig) => {
    const deepLink = (bannerBig as BannerBig).deeplink;

    if (deepLink) {
      const URL = urlFromDeepLink(deepLink);
      if (URL) {
        history.push(URL);
      }
    }
  };

  /*  const handleClickOnCurrentBanner = () => {
      if (!props.isPopupOpened && focusedItem) {
        handleClick(focusedItem);
      }
    };*/

  return (
    <div
      className={ cn(styles.wrapper, {
        [styles.focused]: props.isFocusedBlock,
        [styles.firstBlock]: (props.blockIndex === 0),
      }) }
    >
      {
        props.isVisible
        && <>
          {
            focusedItem
            && <>
                  <BannerSceneMediaItem mediaItem={ focusedItem } />
              {
                delayedFocusedItem && focusedItem.id === delayedFocusedItem.id
                && <BannerTextBlock
                      blockIndex={ props.blockIndex }
                      focusedBlockIndex={ focusedBlockIndex }
                      isFocused={ !props.isPopupOpened && props.isFocusedBlock }
                      item={ delayedFocusedItem }
                      onClick={ handleClick }
                  />
              }
              </>
          }
          {/*
          <div className={ styles.mask } onClick={ handleClickOnCurrentBanner }/>
*/ }
              <img
                  src={ backgroundImageBanner || defaultMask }
                  width={ screenWidth }
                  height={ screenHeight }
                  className={ styles.mask }
                  alt=""
              />
              <Slider
                  className={ styles.dottedSlider }
                  dimension={ ImageType.BannerFullScreen }
                  items={ items }
                  isFocusedBlock={ !props.isPopupOpened && props.isFocusedBlock }
                  isVisible={ props.isVisible }
                  focusedIndex={ props.focusedItemIndex }
                  onDotClick={ handleClick }
              />
          </>
      }
    </div>
  );
};

