// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iconsBar--yQtC4 {\n  position: absolute;\n  right: 0;\n  left: 0;\n  bottom: 3.4375000000000004vw;\n  width: 100%;\n  text-align: center;\n}\n\n.iconsBarItem--oyjL5 {\n  display: inline-block;\n  vertical-align: middle;\n  margin: 0 0.625vw;\n  color: rgba(243, 243, 247, 1);\n  -webkit-border-radius: 2.5vw;\n          border-radius: 2.5vw;\n}\n\n.iconsBarItem--oyjL5.focused--izPRr,\n.pointerEnabled .iconsBarItem--oyjL5:hover {\n  background-color: rgba(253, 216, 53, 1);\n  color: rgba(23, 23, 27, 1);\n}\n\n.playerIcon {\n  display: inline-block;\n  width: 2.5vw;\n  height: 2.5vw;\n  vertical-align: middle;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/ScenePlayer/Player/controls/IconsBar/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,4BAA4B;EAC5B,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,iBAAiB;EACjB,6BAAa;EACb,4BAAoB;UAApB,oBAAoB;AACtB;;AAEA;;EAEE,uCAAwB;EACxB,0BAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".iconsBar {\n  position: absolute;\n  right: 0;\n  left: 0;\n  bottom: 3.4375000000000004vw;\n  width: 100%;\n  text-align: center;\n}\n\n.iconsBarItem {\n  display: inline-block;\n  vertical-align: middle;\n  margin: 0 0.625vw;\n  color: $gw_10;\n  border-radius: 2.5vw;\n}\n\n.iconsBarItem.focused,\n:global(.pointerEnabled) .iconsBarItem:hover {\n  background-color: $ac_00;\n  color: $gw_00;\n}\n\n:global(.playerIcon) {\n  display: inline-block;\n  width: 2.5vw;\n  height: 2.5vw;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
export var iconsBar = "iconsBar--yQtC4";
export var iconsBarItem = "iconsBarItem--oyjL5";
export var focused = "focused--izPRr";
export default ___CSS_LOADER_EXPORT___;
