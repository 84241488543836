import React from 'react';
import { useInfiniteQuery } from 'react-query';

import { useClient, useConfig } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import Payment from '~typings/Payment';
import ResponseMany from '~typings/ResponseMany';

import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '../utils';


const PREFIX_OF_A_COMPOSITE_KEY = 'payment_history';

const getKey = (limit: number, accessToken: string | null) => [PREFIX_OF_A_COMPOSITE_KEY, limit, accessToken];


const fetch = async ({client, accessToken, page, limit}) => {
  const params = {
    access_token: accessToken,
    sort: '-created_at',
    'page[offset]': (page * limit),
    'page[limit]': limit,
  }

  return await client.get(
    '/v1/payment_history',
    params
  );
};


const usePaymentHistory = () => {
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(useConfig());
  const queryKey = getKey(limit, accessToken);
  
  const client = useClient();

  const query = useInfiniteQuery<ResponseMany<Payment[]>>({
    queryFn: ({ pageParam = 0 }) => fetch({client, accessToken, page: pageParam, limit}),
    queryKey,
    getNextPageParam: getNextPageForInfinityPagination,
    enabled: Boolean(accessToken),
    cacheTime: 5 * 60 * 1000 // 5 минут
  });

  const parsedPages = React.useMemo(() => {
    return query.data?.pages && reducePaginatedPages(query.data.pages);
  }, [query.data?.pages]);

  return {
    parsedData: parsedPages?.data ?? EMPTY_ARRAY,
    query,
    isLoading: query.isLoading
  }
};


export {
  getKey,
  PREFIX_OF_A_COMPOSITE_KEY,
};

export default usePaymentHistory;
