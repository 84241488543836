import focuser from '@focuser';
import * as React from 'react';

import { Typography } from '~app/ui-kit/Typography';
import { useAnimationContext } from '~components/Provider/Animation';
import ScrollBar from '~components/ScrollBar';
import { useScrollFocuser } from '~hooks/useScroll/useScrollFocuser';
import Person from '~typings/Person';

import * as styles from './styles.module.css';

type Props = {
  text: string;
  onBack: () => void;
  person: Person;
  isFocused: boolean;
}

export const ActorDescriptionPopup = ({
  text, 
  onBack, 
  person, 
  isFocused
}: Props): React.ReactElement | null => {
  const { isAnimationEnabled } = useAnimationContext();
  const animationDuration = isAnimationEnabled ? 200 : 0;
  
  const handleBack = React.useCallback((evt) => {
    if (onBack) {
      evt.stop();
      evt.stopNativeEvent();
      onBack();
    }
  }, []);

  const {
    wrapperRef,
    contentRef,
    scrollPercent,
    isScrollAvailable,
    onKeyDown,
    onKeyUp,
    isPointerDownAvailable,
    isPointerUpAvailable,
  } = useScrollFocuser({animationDurationInMS: animationDuration});

  return (
    <focuser.FocusableBlock 
      isFocused={ isFocused }
      className={ styles.wrapper }
      onKeyReturn={ handleBack }
      returnButtonType={ 'back' }
      forwardRef={ wrapperRef }
      onKeyDown={ onKeyDown }
      onKeyUp={ onKeyUp }
      isPointerDownAvailable={ isPointerDownAvailable }
      isPointerUpAvailable={ isPointerUpAvailable }
      noNeedForceFocus
      isLastBlock
    >
      <div
        className={ styles.content }
        ref={ contentRef }
      >
        <Typography variant={ 'h3_bold' } className={ styles.personName }>{person.full_name}</Typography>
        <Typography variant={ 'subtitle1_bold' }>{person.original_name}</Typography>
        <Typography variant={ 'subtitle1_medium' } className={ styles.text }>
          <div dangerouslySetInnerHTML={ { __html: text } } />
        </Typography>
      </div>
      { isScrollAvailable
        ? <ScrollBar
            position={ scrollPercent }
            className={ styles.scrollbar }
            isFocused
          />
        : null
      }
    </focuser.FocusableBlock>
  )
};
