/* eslint-disable max-len */
import * as React from 'react';

type Props = Readonly<{
  className?: string;
}>;

const IconEyeOff: React.FC<Props> = (props: Props) => (
  <svg
    className={ props.className }
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 58 32"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h58v32H0V0z" fill="none" />
    <path
      className="pathHighlighted"
      d="M40.7 27.3c-.2 0-.5-.1-.7-.3L16.7 6.4c-.4-.4-.5-1-.1-1.4.4-.4 1-.5 1.4-.1l23.3 20.6c.4.4.5 1 .1 1.4-.2.3-.5.4-.7.4z"
    />
    <path
      className="pathHighlighted"
      d="M29 10c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6m0-2c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"
    />
    <path
      className="pathHighlighted"
      d="M29 7.4c10.3 0 17.9 8.6 17.9 8.6s-8.1 8.6-17.9 8.6S11.1 16 11.1 16 18.7 7.4 29 7.4m0-2c-11 0-19.1 8.9-19.4 9.2L8.4 16l1.3 1.3c.4.4 8.8 9.2 19.4 9.2s19-8.8 19.4-9.2l1.3-1.3-1.2-1.4c-.5-.3-8.6-9.2-19.6-9.2z"
    />
  </svg>
);


export default IconEyeOff;
