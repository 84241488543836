import * as React from 'react';
import { InjectedIntl,injectIntl } from 'react-intl';

import InputWithKeyboard, { FocusOn } from '~components/InputWithKeyboard';
import { AuthActionType, useAuthMutation, useConfirmMutation, usePasswordCodeMutation } from '~hooks/fetch/useAccount';
import { newFieldValue } from '~lib/keyboard';
import { RESTORE_INVALID_CODE, SECURITY_CODE } from '~localization';
import { KeyBoardKey, KeyBoardType } from '~typings/Keyboard';

import { getMessageByError } from '../helpers';
import { AuthType } from '../types';

type ConfirmCodeFormProps = Readonly<{
  authType: AuthType;
  username: string;
  password?: string;
  isFocused?: boolean;

  onSuccess: (code?: string) => void;
  onError: (errorMessage: string, error?: any) => void;
  onProcessingStatus: (isProcessing: boolean) => void;
  onLeave: (NavigationDirection) => void;

  intl: InjectedIntl;
}>;

const ConfirmCodeForm: React.FC<ConfirmCodeFormProps> = (props: ConfirmCodeFormProps) => {
  const [ code, setCode ] = React.useState<string>('');
  const { mutate: signIn } = useAuthMutation();
  const { mutate: confirmRegistration } = useConfirmMutation();
  const { validatePassCode } = usePasswordCodeMutation();

  const handleValueChange = (key: KeyBoardKey) => {
    setCode(oldValue => newFieldValue(oldValue, key))
  };

  const handleClearClick = () => {
    setCode('');
  };

  const onError = (error) => {
    if (!props.onError) return;
    const messageText = getMessageByError(error, props.intl.formatMessage);
    props.onError(messageText, error);
  };

  const onConfirmSuccess = () => {
    props.onProcessingStatus(true);
    signIn({
      type: AuthActionType.AuthByPassword,
      payload: { username: props.username, password: props.password }
    }, {
      onSuccess: () => props.onSuccess(),
      onError,
      onSettled: () => props.onProcessingStatus(false),
    });
  };

  const handleFormSubmit = () => {
    if (props.authType === AuthType.SignUp) {
      confirmRegistration({username: props.username, code}, {
        onSuccess: onConfirmSuccess,
        onError,
      });
    } else if (props.authType === AuthType.PasswordReset) {
      validatePassCode({
        username: props.username,
        code
      }, {
        onError,
        onSuccess(req) {
          if (req.data?.valid === true) {
            props.onSuccess(code);
          } else {
            props.onError?.(
              props.intl.formatMessage({ id: RESTORE_INVALID_CODE })
            );
          }
        }
      });
    }
  };

  return (
    <InputWithKeyboard
      inputValue={ code }
      focusOn={ props.isFocused ? FocusOn.KeyBoard : null }
      keyBoardType={ KeyBoardType.Number }
      placeholder={ props.intl.formatMessage({ id: SECURITY_CODE }) }
      onLeave={ props.onLeave }
      onChange={ handleValueChange }
      onClear={ handleClearClick }
      onSubmit={ handleFormSubmit }
    />
  );
};


export default injectIntl(ConfirmCodeForm);
