import { omit } from 'lodash';
import * as moment from 'moment';
import { useEffect,useRef } from 'react';
import { util as shakaUtil } from 'shaka-player';

import { usePlayerState } from '~components/Provider/Player/component.helpers';
import { Events } from '~lib/player/common';
import { IPlayer } from '~lib/player/typings';
import uuid from '~lib/uuid';
import DeviceInfo from '~typings/DeviceInfo';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Stream from '~typings/Stream';

import send from './send';

type Props = {
  player: IPlayer | null;
  stream: Stream;
  mediaItem: PlayerMediaItem;
  device: DeviceInfo;
};

type ValueOf<T> = T[keyof T];

/**
 * Logger
 * 100501 to turn on
 */

const LOGGED_EVENT = [
  Events.CAN_PLAY,
  Events.SUSPEND,
  Events.LOADED_METADATA,
  Events.PAUSE,
  Events.PLAY,
  Events.PLAYING,
  Events.SEEKING,
  Events.SEEKED,
  Events.WAITING,
  Events.BUFFERING_START,
  Events.BUFFERING_COMPLETE,
];

const useLogger = ({ player, stream, mediaItem, device }: Props) => {
  const { logger } = usePlayerState();
  const previousEvent = useRef<ValueOf<typeof Events> | null>(null);
  const logged = useRef(true);
  const startTime = useRef<number>(moment().valueOf());
  const log = useRef<object>({});

  const reset = () => {
    log.current = {};
  };

  const start = () => {
    reset();
    startTime.current = moment().valueOf();
  };

  const sendLog = (url: string | null) => {
    const formData  = new FormData();

    /**
     * TODO: непонятно почему тут `name` используется, нужно будет поправить
     */
    formData.append(name as unknown as string, JSON.stringify(device));
    formData.append(name as unknown as string, JSON.stringify(mediaItem));
    formData.append(name as unknown as string, JSON.stringify(stream));
    formData.append(name as unknown as string, JSON.stringify(log.current));
    send(url || 'http://logger', formData);
  };

  const cleanStats = (properties) => {
    if (properties && properties.stats) {
      return {
        ...properties,
        stats: omit(properties.stats, ['stateHistory', 'switchHistory']),
      };
    }

    return properties;
  }

  const handleTimeUpdate = () => {
    if (!player) { return null; }

    const properties = player.getProperties();

    if (properties && previousEvent.current !== Events.TIME_UPDATE) {
      if (logged.current) {
        logged.current = false;
      }

      log.current = {
        ...log.current,
        [`${moment().format('HH:mm:ss.SSS')}: ${Events.TIME_UPDATE}`]: cleanStats(properties),
      };
      if (logger) {
        /* eslint-disable-next-line no-console */
        console.log(Events.TIME_UPDATE, properties);
      }
      previousEvent.current = Events.TIME_UPDATE;
    }
  };
  const handleEvent = (e) => {
    if (e) {
      if (e?.current?.events) {
        e.current.events = {
          ...e.current.events,
          [`${moment().format('HH:mm:ss.SSS')}: ${Events.EVENT}`]: e,
        };
      }
      if (logger) {
        /* eslint-disable-next-line no-console */
        console.log(Events.EVENT, e);
      }
    }
  };
  const handleError = (err) => {
    if (!player) { return; }

    if (err instanceof shakaUtil.Error) {
      /* eslint-disable-next-line no-console */
      console.log('shakaUtil', err);
    } else {
      /* eslint-disable-next-line no-console */
      console.log('err', err);
    }

    log.current = {
      ...log.current,
      [`${moment().format('hh:mm:ss.SSS')}: ${Events.ERROR}`]: player.getProperties(),
    };
    if (logger) {
      /* eslint-disable-next-line no-console */
      console.log(Events.ERROR, log.current);
      sendLog(null);
    }
    sendLog(`http://rpc.spr.spbtv.com/logs/submit/?hash=${uuid()}&did=${device.deviceId}`);
    // sendLog(`http://m.spbtv.com/ru/smart-tv/tv/support/hash/${hash}/sf/$STOREFRONT$/ver/$APPVERSION$/$USER$`);
    logged.current = true;
  };
  const handleLog = () => {
    if (!player) { return; }

    if (!logged.current) {
      log.current = {
        ...log.current,
        [`${moment().format('hh:mm:ss.SSS')}: ${Events.LOG}`]: player.getProperties(),
      };
      if (logger) {
        /* eslint-disable-next-line no-console */
        console.log(Events.LOG, log);

        sendLog(null);
        sendLog(`http://rpc.spr.spbtv.com/logs/submit/?hash=${uuid()}&did=${device.deviceId}`);
      }
      logged.current = true;
    }
  };
  const handleEnded = () => {
    if (!player) { return null; }

    if (previousEvent.current !== Events.ENDED) {
      log.current = {
        ...log.current,
        [`${moment().format('hh:mm:ss.SSS')}: ${Events.ENDED}`]: player.getProperties()
      };
      if (logger) {
        /* eslint-disable-next-line no-console */
        console.log(Events.ENDED, log);
      }
      previousEvent.current = Events.ENDED;
    }
  };

  useEffect(() => {
    if (!player || !stream) { return; }

    start();

    if (!player) { return; }

    if (logger) {
      /* eslint-disable-next-line no-console */
      console.log(`Player is: ${player?.type}`);
    }

    LOGGED_EVENT.forEach(event => {
      player.on(event, () => {
        if (previousEvent.current !== event) {
          log.current = {
            ...log.current,
            [`${moment().format('HH:mm:ss.SSS')}: ${event}`]: cleanStats(player.getProperties()),
          };
          if (logger) {
            /* eslint-disable-next-line no-console */
            console.log(event);
          }
          previousEvent.current = event;
        }
      });
    });

    player.on(Events.TIME_UPDATE, handleTimeUpdate);
    player.on(Events.EVENT, handleEvent);
    player.on(Events.ERROR, handleError);
    player.on(Events.LOG, handleLog);
    player.on(Events.ENDED, handleEnded);

    return () => {
      reset();

      if (!player) { return; }

      player.removeListener(Events.TIME_UPDATE, handleTimeUpdate);
      player.removeListener(Events.EVENT, handleEvent);
      player.removeListener(Events.ERROR, handleError);
      player.removeListener(Events.LOG, handleLog);
      player.removeListener(Events.ENDED, handleEnded);
    };
  }, [player, stream]);
};


export default useLogger;
