import { find } from 'lodash';

import AudioTrack from '~typings/AudioTrack';
import PlayerType from '~typings/PlayerType';
import VideoTrack from '~typings/VideoTrack';

import { createHTMLVideoElement, prepareTimeShiftUrl, resetHTMLVideoElement } from '../common';
import getSourceTypeAttribute from '../getSourceTypeAttribute';
import handleSourceError from '../handleSourceError';
import { HTMLVideoElWithTracks, IPlayer, PlayerEventSubscribe, PlayerLoadParameters,Props } from '../typings';


const nativePlayer = ({ videoContainer, event }: Props): IPlayer => {
  let videoElement: HTMLVideoElWithTracks | null = null;

  const dispose = () => {
    try {
      if (videoContainer && videoElement) {
        videoContainer.removeChild(videoElement);
        videoElement = null;
      }
    } catch (ignore) {

    }
  };
  const getCurrentTime = (): number => videoElement?.currentTime || 0;
  const getDuration = (): number => videoElement?.duration || 0;

  async function reset() {
    resetHTMLVideoElement(videoContainer, videoElement);
    videoElement = null;
  }

  async function load(parameters: PlayerLoadParameters) {
    await reset();

    const playerUrl = prepareTimeShiftUrl(parameters.stream.url, parameters.timeShiftParams);

    videoElement = createHTMLVideoElement(event);
    videoContainer?.appendChild(videoElement);

    const source = document.createElement('source');
    source.setAttribute('src', playerUrl);

    const sourceType = getSourceTypeAttribute({ url: parameters.stream.url, drm: parameters.stream.drm });
    if (sourceType) {
      source.setAttribute('type', sourceType);
    }

    source.addEventListener('error', err => handleSourceError(event, err));

    videoElement.appendChild(source);
  }

  const mute = () => {};
  const on: PlayerEventSubscribe = (target, listener) => event.on(target, listener);

  const removeListener: PlayerEventSubscribe = (target, listener) => {
    event.removeListener(target, listener);
  };

  const play = (): void => {
    videoElement?.play();
  };

  const pause = () => {
    videoElement?.pause();
  };

  const seekTo = (timePosition: number) => {
    if (videoElement?.currentTime !== undefined) {
      videoElement.currentTime = timePosition;
    }
  };

  const stop = () => {
    reset();
  };

  const isAutoQualityEnabled = () => false;
  const setAutoQuality = () => {};

  const changeAudioTrack = (audioTrack: AudioTrack) => {
    if (videoElement?.audioTracks) {
      try {
        for (var index = 0; index < videoElement?.audioTracks.length; index += 1) {
          videoElement.audioTracks[index].enabled = (index === audioTrack.index);
        }
      } catch (ignore) {
        // ignore
      }
    }
  };
  const changeVideoTrack = (videoTrack: VideoTrack) => {
    if (videoElement?.videoTracks) {
      try {
        for (var index = 0; index < videoElement?.videoTracks.length; index += 1) {
          videoElement.videoTracks[index].selected = (index === videoTrack.index);
        }
      } catch (ignore) {
        // ignore
      }
    }
  };
  const getAudioTracks = () => {
    const audioTracks: AudioTrack[] = [];

    if (videoElement?.audioTracks && videoElement.videoTracks) {
      try {
        for (var index = 0; index < videoElement?.videoTracks.length; index += 1) {
          audioTracks.push({
            index,
            isSelected: videoElement.audioTracks[index].enabled,
            name: (videoElement.audioTracks[index].language || videoElement.audioTracks[index].label).replace('`', ''),
          });
        }
      } catch (ignore) {
        // ignore
      }
    }

    return audioTracks;
  };
  const getVideoTracks = () => {
    const videoTracks: VideoTrack[] = [];

    if (videoElement?.videoTracks) {
      try {
        for (var index = 0; index < videoElement?.videoTracks.length; index += 1) {
          videoTracks.push({
            index,
            isSelected: videoElement.videoTracks[index].selected,
            name: (videoElement.videoTracks[index].language || videoElement.videoTracks[index].label).replace('`', ''),
          });
        }
      } catch (ignore) {
        // ignore
      }
    }

    return videoTracks;
  };
  const getSelectedAudioTrack = () => {
    find(getAudioTracks(), { isSelected: true });
  };
  const getSelectedVideoTrack = () => {
    find(getVideoTracks(), { isSelected: true });
  };

  const getProperties = () => {
    if (!videoElement) { return null; }

    return {
      videoPlaybackQuality: videoElement?.getVideoPlaybackQuality ?
        videoElement.getVideoPlaybackQuality() : null,
    };
  };

  return {
    type: PlayerType.Native,

    dispose,
    getCurrentTime,
    getDuration,
    load,
    mute,
    on,
    removeListener,
    pause,
    play,
    reset,
    seekTo,
    stop,

    isAutoQualityEnabled,
    setAutoQuality,

    changeAudioTrack,
    changeVideoTrack,
    getAudioTracks,
    getVideoTracks,
    getSelectedAudioTrack,
    getSelectedVideoTrack,

    getProperties,
  };
};


export default nativePlayer;
