import focuser, { FocuserKeyHandler } from '@focuser';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import { getBarsItems, Props as BarsItemsProps } from '~components/MediaItemPage/utils';
import { useApp } from '~components/Provider/App';
import { PlayerAccessReason } from '~components/Provider/Player/component.helpers';
import { useFavoriteItem } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { useVote } from '~hooks/fetch/useVote';
import {
  useWatchProgress,
} from '~hooks/fetch/useWatchProgress/useWatchProgress';
import * as URLSearchManager from '~lib/URLQueryStringManager';
import AudioShow from '~typings/AudioShow';
import AudioShowPart from '~typings/AudioShowPart';
import Episode from '~typings/Episode';
import ItemObject from '~typings/ItemObject';
import Movie from '~typings/Movie';
import { ExpandedPlayerAccess } from '~typings/PlayerAccess';
import Series from '~typings/Series';

import BarItemFocuser from './BarItemFocuser';
import * as styles from './MediaItemBarFocuser.module.css';
import { getFavoriteType } from './utils';

export type Props = {
  onReady: (canNavigate: boolean) => void;
  access: ExpandedPlayerAccess;
  item: Movie | Series | AudioShow;
  watchProgressItem?: Movie | Episode | AudioShowPart;
  onClick: (item: BarItem) => void;
  /**
   * Только для сериалов
   */
  seasonsCount?: BarsItemsProps['seasonsCount'];
}

export const MediaItemBarFocuser: React.FC<Props> = ({
  onReady,
  access,
  onClick,
  item,
  watchProgressItem,
  seasonsCount,
}) => {
  const history = useHistory();
  const { isAuthorized } = useApp();

  const favoriteItemQuery = useFavoriteItem(
    getFavoriteType(item.object),
    item.id,
  );
  const isInFavorites = Boolean(favoriteItemQuery.data);
  const { data: watchProgressResp } = useWatchProgress(
    watchProgressItem?.object,
    watchProgressItem?.id,
  );
  const watchProgress = watchProgressResp?.[0];
  const { data: vote } = useVote(item.object, item.id);
  const notPurchased = access?.reason === PlayerAccessReason.NotPurchased;
  const promoCode = URLSearchManager.getField(history.location.search, 'promo_code');
  const watchInProgress = (watchProgress?.percents_watched || 0) > 0 && !watchProgress?.completed;
  
  const barItems = React.useMemo(
    () =>
      getBarsItems({
        access,
        isAuthorized,
        hasTrailer: !!item.trailer,
        object: item.object,
        isInFavorites,
        watchInProgress,
        vote: vote?.data?.action,
        notPurchased,
        hasPromo: notPurchased && !!promoCode,
        seasonsCount: item.object === ItemObject.Series ? seasonsCount : undefined,
        allParts: item.object === ItemObject.AudioShow 
          ? Boolean(item.parts.length > 1) 
          : undefined
      }),
    [
      isAuthorized,
      !!item.trailer,
      item.object,
      isInFavorites,
      notPurchased,
      watchProgress,
      vote?.data,
      access,
    ],
  );

  React.useEffect(() => {
    onReady(barItems.length > 0);
  }, [barItems.length]);

  const [focusedIdx, setFocusedIdx] = useState<number>(0);

  const onKeyRight: FocuserKeyHandler = (ev) => {
    if (focusedIdx >= barItems.length - 1) return;
    setFocusedIdx(focusedIdx + 1);
    ev.stop();
  };

  const onKeyLeft: FocuserKeyHandler = (ev) => {
    if (focusedIdx === 0) return;
    setFocusedIdx(focusedIdx - 1);
    ev.stop();
  };

  return (
    <focuser.FocusableBlock
      isFocused
      onKeyRight={ onKeyRight }
      onKeyLeft={ onKeyLeft }
      isPointerLeftAvailable={ focusedIdx > 0 }
      isPointerRightAvailable={ focusedIdx < barItems.length - 1 }
      className={ styles.mediaItemBar }
      noNeedForceFocus
    >
      {barItems.map((barItem, index) => (
        <focuser.FocusableBlock
          key={ barItem }
          isFocused={ index === focusedIdx }
          onForceFocus={ () => setFocusedIdx(index) }
          emitForceFocusOnHover
        >
          {({ isFocused }) => (
            <BarItemFocuser
              mediaItem={ item }
              item={ barItem }
              isFocused={ isFocused }
              onClick={ onClick }
            />
          )}
        </focuser.FocusableBlock>
      ))}
    </focuser.FocusableBlock>
  );
};
