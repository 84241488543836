import React from "react";

import * as css from "../styles.module.css";

interface Props {
  seasonEpisodeNum: string
  isSeries: boolean
  genres?: string
}

const LocalComponent = ({ genres, seasonEpisodeNum, isSeries }: Props) => {
  return (
    <>
      {
        //сначала проверяем не сериал ли это, если нет то это фильм, в любом случаем выводим/не выводим всю линию
        isSeries
          ? <div className={ css.vodThirdLine }>
            { seasonEpisodeNum }
          </div>
          : genres && <div className={ css.vodThirdLine }>{ genres }</div>
      }
    </>
  )
}

export const SeasonOrGenres = LocalComponent