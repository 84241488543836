import { filter, findIndex } from 'lodash';

import { KeyBoardKey } from '~typings/Keyboard';


const haveEnabledCells = (line: KeyBoardKey[]): boolean => {
  return (
    filter(line, (key: KeyBoardKey) => !key.disabled).length !== 0
  );
};

const getClosestEnabledHorizontalIndex = (line: KeyBoardKey[], x): number => {
  for (let i = 1; i < line.length; i += 1) {
    if (line[x + 1] && !line[x + 1].disabled) {
      return (x + 1);
    }
    if (line[x - 1] && !line[x - 1].disabled) {
      return (x - 1);
    }
  }

  return findIndex(line, ({ disabled }) => !disabled);
};

export {
  getClosestEnabledHorizontalIndex,
  haveEnabledCells,
};
