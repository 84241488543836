import { useQueries, UseQueryOptions } from 'react-query';

import { useClient, useConfig } from '~global';
import { getLimitFromConfig } from "~hooks/fetch/utils";
import { BlockType } from '~typings/Block';
import ContinueWatchingType from '~typings/ContinueWatchingType';
import { FavoriteType } from '~typings/FavoriteType';

import { fetch as fetchBanners, getBannersKey, } from '../useBanners/useBanners';
import { fetch as fetchBannersV4, getBannersKey as getBannersV4Key, } from '../useBannersV4/useBanners';
import { fetch as fetchCardCollection, getKey as getCardCollectionKey } from '../useCardCollection'
import { getCCIFirstPageHookParams } from '../useCardCollectionsItems';
import { fetch as fetchChannel, getChannelKey, } from '../useChannel';
import { fetch as fetchCollectionItems, getKey as getCollectionItemsKey, } from '../useCollectionItems';
import {
  getContinueWatchingFirstPageHookParams,
} from '../useContinueWatching/useContinueWatchingV3';
import { getFavoritesFirstPageHookParams } from '../useFavorites/useFavoritesV3';
import {
  getRecsFirstPageQueryParams,
} from '../useRecommendations/useRecommendations';

type Block = {
  object: BlockType;
  blockID?: string;
  cardConfigID?: string;
  pageId?: string;
  collectionID?: string;
  cardCollectionID?: string;
  slug?: string;
  resource_types?: FavoriteType[] | ContinueWatchingType[];
  recommendation_context?: string;
};

const useBlocksItems = (blocks: ReadonlyArray<Block>) => {
  const client = useClient();
  const config = useConfig();
  const queries: UseQueryOptions[] = [];
  const limit = getLimitFromConfig(useConfig());

  blocks.forEach((block: Block) => {
    switch (block.object) {
      case BlockType.BannersBlock: {
        queries.push({
          queryKey: getBannersKey(block.pageId),
          queryFn: () => fetchBanners(block.pageId, client),
          enabled: !!block.pageId,
        });
        break;
      }
      case BlockType.BigBannersBlock:
      case BlockType.MiddleBannersBlock: {
        queries.push({
          queryKey: getBannersV4Key(block.blockID),
          queryFn: () => fetchBannersV4(block.blockID, client, limit),
          enabled: !!block.blockID,
        });
        break;
      }
      case BlockType.CollectionBlock: {
        if (!block.collectionID) {
          break;
        }
        const queryKey = getCollectionItemsKey(block.collectionID, undefined, 0);
        queries.push({
          queryKey,
          queryFn: () => fetchCollectionItems({
            page: 0,
            queryKey,
            collectionID: block.collectionID,
            filters: undefined,
            client,
          }),
          enabled: !!block.collectionID,
          keepPreviousData: true,
        });
        break;
      }
      case BlockType.CardCollectionBlock: {
        if (!block.cardCollectionID) {
          break;
        }

        queries.push(getCCIFirstPageHookParams(client, config, {
          cardCollectionID: block.cardCollectionID,
          cardConfigID: block?.cardConfigID,
        }));
        break;
      }
      case BlockType.ContinueWatchingBlock: {

          queries.push(
            getContinueWatchingFirstPageHookParams({
              client,
              config,
              queryParams: {
                types: block.resource_types as ContinueWatchingType[],
              },
            }),
          );

        break;
      }
      case BlockType.RecommendationsBlock: {
        queries.push(getRecsFirstPageQueryParams(client, block.recommendation_context, config));
        break;
      }
      case BlockType.CurrentProgramLineBlock: {
        queries.push({
          queryKey: getChannelKey(block.slug),
          queryFn: () => fetchChannel(client, { slug: block.slug }),
          enabled: !!block.slug,
        });
        break;
      }
      case BlockType.FavoriteChannelsBlock: {
        queries.push(getFavoritesFirstPageHookParams({
          config,
          client,
          queryParams: {
            types: [FavoriteType.Channels]
          },
        }));
        break;
      }
      case BlockType.FavoriteMoviesBlock: {
        queries.push(getFavoritesFirstPageHookParams({
          config,
          client,
          queryParams: {
            types: [FavoriteType.Movies]
          },
        }));
        break;
      }
      case BlockType.FavoriteSeriesBlock: {
        queries.push(getFavoritesFirstPageHookParams({
          config,
          client,
          queryParams: {
            types: [FavoriteType.Series]
          },
        }));
        break;
      }
      case BlockType.FavoriteAudioShowBlock: {
        queries.push(getFavoritesFirstPageHookParams({
          config,
          client,
          queryParams: {
            types: [FavoriteType.AudioShows]
          },
        }));
        break;
      }
      case BlockType.FavoritesBlock: {
        queries.push(getFavoritesFirstPageHookParams({
          config,
          client,
          queryParams: {
            types: (block.resource_types || []) as FavoriteType[]
          },
        }));
        break;
      }
      case BlockType.ExpandableCardColletion: {
        if (!block.cardCollectionID) break;

        if (block && block.cardCollectionID) {
          const queryKey = getCardCollectionKey(block.cardCollectionID);
          queries.push({
            queryKey,
            // @ts-ignore
            queryFn: () => fetchCardCollection(block.cardCollectionID, client),
            enabled: !!block.cardCollectionID,
            keepPreviousData: true,
          })
        }

        if (block && block.cardCollectionID) {
          queries.push(getCCIFirstPageHookParams(client, config, {
            cardCollectionID: block.cardCollectionID,
            cardConfigID: block?.cardConfigID,
          }));
        }
        break;
      }
      default:
        return;
    }
  });

  return {
    blocksItems: useQueries(queries),
  } as const;
};


export default useBlocksItems;
