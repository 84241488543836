import { QueryKey, useQuery, useQueryClient } from 'react-query';

import { useClient } from '~global';
import expand from '~hooks/fetch/expand';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import ResponseMany from '~typings/ResponseMany';

import { getAuthData } from '../useAccount';


const getTopMatch = async (query, cacheData, client) => {
  if (cacheData) { return cacheData; }

  let url = `/v1/search/top_match`;
  const options = {
    query,
    'expand[channel]': expand.channel,
    'expand[movie]': expand.movie,
    'expand[series]': expand.series,
    'expand[program_event]': expand.program_event,
    'filter[resource_type_in]': 'channels,program_events,movies,series',
    access_token: getAuthData().accessToken,
  };

  return await client.get(url, options);
};

function useTopMatch(query?: string) {
  const queryKey: QueryKey = ['search_top_match', query];
  const client = useClient();

  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData(queryKey);

  return useQuery<ResponseMany<PlayerMediaItem[]>>({
    queryKey,
    queryFn: () => getTopMatch(query, cacheData, client),
    enabled: !!query && query.length > 0,
    notifyOnChangeProps: ['data', 'isLoading'],
  });
}


export default useTopMatch;
