import * as cn from 'classnames';
import * as React from 'react';

import ImageType from '~typings/ImageType';

import * as styles from '../styles.module.css';


type Props = Readonly<{
  src: string;
  isChannelCardWide?: boolean;
}>;


const ChannelContentImageLogo: React.FC<Props> = (props: Props) => (
  <div
    className={
      cn(styles.image, {
        [styles.channelCardWide]: props.isChannelCardWide,
        [styles[ImageType.LOGO]]: !props.isChannelCardWide,
      })
    }
    style={ {
      backgroundImage: `url("${props.src}")`,
    } }
  />
);


export default React.memo(ChannelContentImageLogo);
