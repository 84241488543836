import * as React from 'react';

import IconBase from './IconBase';
import * as styles from './styles.module.css';


type Props = {
  Icon: React.FC;
  className?: string;
};

const IconMediaItem: React.FC<Props> = ({ Icon, className }) => {
  return (
    <div className={ className }>
      <IconBase
        Icon={ Icon }
        classNames={ { wrapper: styles.primaryRound } }
      />
    </div>
  );
};


export default IconMediaItem;
