/* eslint-disable max-len */
import * as React from 'react';

type Props = Readonly<{
  className?: string;
}>;

const IcoBackSpace: React.FC<Props> = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="currentColor" className={ props.className }>
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M9.13804 7C8.56808 7 8.02519 7.24318 7.64572 7.66847L0.507679 15.6685C-0.169226 16.4271 -0.169227 17.5729 0.507679 18.3315L7.64572 26.3315C8.02519 26.7568 8.56808 27 9.13804 27H31.9901C33.0947 27 33.9901 26.1046 33.9901 25V9C33.9901 7.89543 33.0947 7 31.9901 7H9.13804ZM23.4477 12.5521C23.815 12.9193 23.815 13.5148 23.4477 13.882L20.3298 16.9999L23.4478 20.1179C23.815 20.4851 23.8151 21.0806 23.4478 21.4478C23.0805 21.8151 22.4851 21.8151 22.1178 21.4478L18.9999 18.3299L15.882 21.4477C15.5148 21.815 14.9193 21.815 14.5521 21.4477C14.1848 21.0805 14.1848 20.485 14.5521 20.1178L17.6699 16.9999L14.5521 13.8821C14.1849 13.5149 14.1849 12.9194 14.5521 12.5522C14.9194 12.1849 15.5148 12.1849 15.8821 12.5522L18.9999 15.67L22.1178 12.5521C22.485 12.1848 23.0805 12.1848 23.4477 12.5521Z"
    />
  </svg>
);


export default IcoBackSpace;
