import * as React from 'react';

import BestPriceButton from '~components/MediaItemPage/Buttons/BestPriceButton';
import CommonButton from '~components/MediaItemPage/Buttons/CommonButton';
import PromoPriceButton from '~components/MediaItemPage/Buttons/PromoPriceButton';
import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import AudioShow from '~typings/AudioShow';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Series from '~typings/Series';

import DefaultBarItem from './DefaultBarItem';
import * as styles from './styles.module.css';

type Props = Readonly<{
  mediaItem: PlayerMediaItem | AudioShow | Series | undefined | null;
  items: Array<BarItem>;
  isFocused: boolean;
  focusedIndex: number;
  onClick: (item: BarItem) => void;
}>;

/**
 * @deprecated, используй MediaItemBarFocuser
 */
const MediaItemBar: React.FC<Props> = (props: Props) => {
  const wrapperDIV = (item: BarItem, button: React.ReactNode) => (
    <div
      key={ item }
      className={ styles.buttonWrapper }
    >
      { button }
    </div>
  );

  const render = (item: BarItem, index: number) => {
    const isFocused = (props.isFocused && props.focusedIndex === index);

    switch (item) {
      case BarItem.PromoPrice:
        return wrapperDIV(
          item,
          <PromoPriceButton
            key={ item }
            item={ props.mediaItem }
            isFocused={ isFocused }
          />
        );
      case BarItem.BestPrice:
        return wrapperDIV(
          item,
          <BestPriceButton
            item={ props.mediaItem }
            isFocused={ isFocused }
          />,
        );
      case BarItem.Trailer:
      case BarItem.Play:
      case BarItem.PlayContinue:
          return wrapperDIV(
            item,
            <CommonButton
              barItem={ item }
              isFocused={ isFocused }
              onClick={ props.onClick }
            />,
          )

      default:
        return (
          <DefaultBarItem
            key={ item }
            item={ item }
            isFocused={ isFocused }
            onClick={ props.onClick }
          />
        );
    }
  };

  return (
    <div className={ styles.mediaItemBar }>
      { props.items.map(render) }
    </div>
  );
};


export default React.memo(MediaItemBar);
