// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAllBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+ysAdDAAAAMXRSTlMAiEQc3QnDFuDwA/ZMz/TTgQHAYHuGzG+2JproKjDWXZaoa7juEvs9j42+oLBIEXI8noezKAAAAoBJREFUWMO1V+t2okAMDiCAIICV4w1WWKuCFt31/V+uTgaplUnAcjp/RJIJuX5JAIhjZFq1SYLrNUjCSssMeOVMi3x0fTqj/P+05/VL3Lpdy4gvPa7bWnAlT6DZHdfd7fiBfxxay6UVfnu1dbn76fzOeLAivfmarUfW4U6Zr+j7M69m8hctVe2FXxO9GaX+uuZ409UM57eaYa00w3yXVGdCqzhxJM+7qbh/krSjyTnJLCXXqcXlyu/vJl1hnuykDs9WSPudVXeirKQZ6yf/48uwV8YbITJ/i0WK8XN6VoyBOnjpgwMwf3Zt/f+Ik7atQD/Mv9ywRZ0U/sMkdhSexAvbJsmQ7wj9BcAR6+KerhqymZSAjSof0A2a/LMPCAMAkM1XpgNW9x6fY8x/eE0AYF3EiF+IP+r6QZKlJOmIUQLlCvor4NPKSVoBALl4WDBMJzVtIWh5reaBgDpMsIoAT4FRIwCDNrP2FCEALEE0IBM/ESfgL0GMBDGTWURgGCwF8YMg6jKXKvFDoX3FCbClfRuR1MAJyCnqGPM8EUBCsXwIASVFFcCSgMcEoUOACEMAgmXJCqBCJF3MCyh7CGBNKJuSo01gnXhk6uTuRDaMEStAhpFNpKjVANqJxKZyzAmoU5ktJlv0hT1fTGw5s6cuZxZQ2GmsBhQW0rjTQBoHqu6/uR+7HaDKwbqmmARasM40FlNObRe+sTCtzZYDUdrR2pjmitYlbkdzZdp7caN45872Tg8YYGczGzoHDHrEoQa15xFn8JA1fMwbPmgOH3WbYbv84bA9fNx/XDjOP1o4hq88fZeu9DfXvuGLJ+IDvfruBy3fxfQl0JXr/y0sXrLh1v9P3CW4z6Hy+doAAAAASUVORK5CYII=", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--Bvsog {\n  padding: 1.5625vw 0 1.5625vw 3.125vw;\n  background-size: 2.34375vw;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: left center;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/NetworkManager/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,yDAA2vC;EAC3vC,4BAA4B;EAC5B,gCAAgC;AAClC","sourcesContent":[".text {\n  padding: 1.5625vw 0 1.5625vw 3.125vw;\n  background-size: 2.34375vw;\n  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAllBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+ysAdDAAAAMXRSTlMAiEQc3QnDFuDwA/ZMz/TTgQHAYHuGzG+2JproKjDWXZaoa7juEvs9j42+oLBIEXI8noezKAAAAoBJREFUWMO1V+t2okAMDiCAIICV4w1WWKuCFt31/V+uTgaplUnAcjp/RJIJuX5JAIhjZFq1SYLrNUjCSssMeOVMi3x0fTqj/P+05/VL3Lpdy4gvPa7bWnAlT6DZHdfd7fiBfxxay6UVfnu1dbn76fzOeLAivfmarUfW4U6Zr+j7M69m8hctVe2FXxO9GaX+uuZ409UM57eaYa00w3yXVGdCqzhxJM+7qbh/krSjyTnJLCXXqcXlyu/vJl1hnuykDs9WSPudVXeirKQZ6yf/48uwV8YbITJ/i0WK8XN6VoyBOnjpgwMwf3Zt/f+Ik7atQD/Mv9ywRZ0U/sMkdhSexAvbJsmQ7wj9BcAR6+KerhqymZSAjSof0A2a/LMPCAMAkM1XpgNW9x6fY8x/eE0AYF3EiF+IP+r6QZKlJOmIUQLlCvor4NPKSVoBALl4WDBMJzVtIWh5reaBgDpMsIoAT4FRIwCDNrP2FCEALEE0IBM/ESfgL0GMBDGTWURgGCwF8YMg6jKXKvFDoX3FCbClfRuR1MAJyCnqGPM8EUBCsXwIASVFFcCSgMcEoUOACEMAgmXJCqBCJF3MCyh7CGBNKJuSo01gnXhk6uTuRDaMEStAhpFNpKjVANqJxKZyzAmoU5ktJlv0hT1fTGw5s6cuZxZQ2GmsBhQW0rjTQBoHqu6/uR+7HaDKwbqmmARasM40FlNObRe+sTCtzZYDUdrR2pjmitYlbkdzZdp7caN45872Tg8YYGczGzoHDHrEoQa15xFn8JA1fMwbPmgOH3WbYbv84bA9fNx/XDjOP1o4hq88fZeu9DfXvuGLJ+IDvfruBy3fxfQl0JXr/y0sXrLh1v9P3CW4z6Hy+doAAAAASUVORK5CYII=');\n  background-repeat: no-repeat;\n  background-position: left center;\n}\n"],"sourceRoot":""}]);
// Exports
export var text = "text--Bvsog";
export default ___CSS_LOADER_EXPORT___;
