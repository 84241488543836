import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import { useApp } from '~components/Provider/App';
import { CatchupStatus,getCatchupStatus } from '~lib/eventsHelper';
import { getScheduleTime,getShortDate } from '~lib/localeUtils/dates';
import { ON_AIR_DATE,RECORD_DATE } from '~localization';
import Event from '~typings/Event';

import * as styles from './styles.module.css';


type Props = Readonly<{
  event: Event;
  intl: InjectedIntl;
}>;


const ProgramEventText: React.FC<Props> = ({ event, intl }: Props) => {
  const [text, setText] = React.useState<string | null>(null);
  const { language } = useApp();
  const [catchupStatus] = React.useMemo(
    () => getCatchupStatus(event),
    [event],
  );

  React.useEffect(() => {
    let newText: string | null = null;
    if (catchupStatus === CatchupStatus.Catchup) {
      newText = intl.formatMessage({ id: RECORD_DATE }, {
        date: getShortDate(event.start_at, language),
        time: getScheduleTime(event.start_at),
      });
    }
    if (catchupStatus === CatchupStatus.Future) {
      newText = intl.formatMessage({ id: ON_AIR_DATE }, {
        date: getShortDate(event.start_at, language),
        time: getScheduleTime(event.start_at),
      });
    }
    setText(newText);
  }, [event.id, catchupStatus]);

  if (!text) { return null; }

  return (
    <span
      className={ styles.programEventText }
    >
      { text }
    </span>
  );
};


export default ProgramEventText;
