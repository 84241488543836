import * as React from 'react';


type Props = {
  className?: string;
};

/* eslint-disable max-len */
const CardIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg className={ className } viewBox="0 0 160.43 159.4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M82.1,94.14H18.35v7.25H82.1V94.14Zm-27,13.25H18.35v6.5H55.1v-6.5Zm97.31-79.3H8a8,8,0,0,0-8,8v87.18a8,8,0,0,0,8,8H152.41a8,8,0,0,0,8-8V36.11A8,8,0,0,0,152.41,28.09ZM8,36.09H152.41l0,0v10H8Zm144.39,87.22L8,123.29V67.89H152.42Z"
        fill="currentColor"
      />
    </svg>
  );
};


export default CardIcon;
