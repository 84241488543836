import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory,useParams } from 'react-router-dom';

import PinCodePopup from '~components/PinCodePopup';
import { useAccount } from '~hooks/fetch/useAccount';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import {
  ARE_YOU_WANT_ANSUBSCRIBE,
  NO,
  SUBSCRIPTION_IS_NOT_CANCELABLE,
  YES,
} from '~localization';
import Subscription from '~typings/Subscription';
import Button from '~ui/button';
import { Popup } from '~ui/Popup';

import * as styles from './styles.module.css';

type Props = Readonly<{
  subscriptions?: Subscription[];
  intl: InjectedIntl;
}>;


const Unsubscribe: React.FC<Props> = ({ subscriptions, intl }) => {
  const history = useHistory();
  const { data: accountInfo } = useAccount();
  const { subscriptionID } = useParams<{ subscriptionID: string }>();
  const [isPinCodePopup, showPinCodePopup] = React.useState<boolean>(false)
  const [isCancellable, setIsCancellable] = React.useState<boolean>(false)
  const [text, setText] = React.useState<string>('')

  const navigationItems = React.useRef([
    { maxIndex: 1 }, // Buttons
  ]);
  const navigationState = useSpatialNavigation({
    allowNavigation: !isPinCodePopup,
    navigationItems: navigationItems.current,
  });
  const focusIndex = navigationState.focusedIndex[0];

  React.useEffect(() => {
    const subscription = (subscriptions || []).find((x) => (x?.id === subscriptionID));

    if (subscription) {
      setIsCancellable(!!subscription.cancellable);
      setText(
        subscription.cancellable ?
          ARE_YOU_WANT_ANSUBSCRIBE
          :
          SUBSCRIPTION_IS_NOT_CANCELABLE
      );
    }
  }, [subscriptions]);


  const handleBack = () => {
    history.goBack();
  };

  const handleClosePopup = () => {
    showPinCodePopup(false)
  };

  const handleUnsubscribeButton = () => {
    if (accountInfo?.security_pin) {
      showPinCodePopup(true);
    } else {
      history.replace(`/products/unsubscribe/${subscriptionID}/delete`);
    }
  };

  const handleValidPinCode = () => {
    history.replace(`/products/unsubscribe/${subscriptionID}/delete`);
  };

  return (
    <Popup>
      <div className={ styles.contentWrapper }>
        <div className={ styles.info }>
          {
            text ? intl.formatMessage({ id: text }) : '...'
          }
        </div>
        <div className={ cn(styles.buttonWrapper, styles.centeredWrapper) }>
          <Button
            isFocused={ !isPinCodePopup && (!isCancellable || focusIndex === 0) }
            className={ cn(styles.button, {
              [styles.isFocused]: !isPinCodePopup && (!isCancellable || focusIndex === 0),
            }) }
            onClick={ handleBack }
          >
            {
              intl.formatMessage({ id: NO })
            }
          </Button>
          <Button
            isFocused={ !isPinCodePopup && !!isCancellable && focusIndex === 1 }
            className={ cn(styles.button, {
              [styles.isFocused]: !isPinCodePopup && !!isCancellable && focusIndex === 1,
            }) }
            isDisabled={ !isCancellable }
            onClick={ handleUnsubscribeButton }
          >
            {
              intl.formatMessage({ id: YES })
            }
          </Button>
        </div>
        {
          isPinCodePopup ? (
            <PinCodePopup
              onSuccess={ handleValidPinCode }
              onBack={ handleClosePopup }
            />
          ) : null
        }
        </div>
    </Popup>
  );
};


export default injectIntl(Unsubscribe);
