import * as moment from 'moment';
import { Moment } from 'moment';

import Language from '../../typings/Language';


moment.defineLocale('uz-lat', {
  months : 'Yanvar_Fevral_Mart_Aprel_May_Iyun_Iyul_Avgust_Sentabr_Oktabr_Noyabr_Dekabr'.split('_'),
  monthsShort : 'Yan_Fev_Mar_Apr_May_Iyun_Iyul_Avg_Sen_Okt_Noy_Dek'.split('_'),
  weekdays : 'Yakshanba_Dushanba_Seshanba_Chorshanba_Payshanba_Juma_Shanba'.split('_'),
  weekdaysShort : 'Yak_Dush_Sesh_Chor_Pay_Jum_Shan'.split('_'),
  weekdaysMin : 'Ya_Du_Se_Cho_Pa_Ju_Sha'.split('_'),
  longDateFormat : {
      LT : 'HH:mm',
      LTS : 'HH:mm:ss',
      L : 'DD/MM/YYYY',
      LL : 'D MMMM YYYY',
      LLL : 'D MMMM YYYY HH:mm',
      LLLL : 'D MMMM YYYY, dddd HH:mm'
  },
  calendar : {
      sameDay : '[Bugun soat] LT [da]',
      nextDay : '[Ertaga] LT [da]',
      nextWeek : 'dddd [kuni soat] LT [da]',
      lastDay : '[Kecha soat] LT [da]',
      lastWeek : '[O\'tgan] dddd [kuni soat] LT [da]',
      sameElse : 'L'
  },
  relativeTime : {
      future : 'Yaqin %s ichida',
      past : 'Bir necha %s oldin',
      s : 'soniya',
      ss : '%d soniya',
      m : 'bir daqiqa',
      mm : '%d daqiqa',
      h : 'bir soat',
      hh : '%d soat',
      d : 'bir kun',
      dd : '%d kun',
      M : 'bir oy',
      MM : '%d oy',
      y : 'bir yil',
      yy : '%d yil'
  },
  week : {
      dow : 1, // Monday is the first day of the week.
      doy : 7  // The week that contains Jan 7th is the first week of the year.
  }
});

export const shortDate = 'DD.MM.YYYY';
export const persianShortDate = 'jDD.jMM.jYYYY';
export const fullDay = 'dd., DD MMMM';
export const fullDate = 'D MMMM YYYY';
export const persianFullDate = 'jD jMMMM jYYYY';
export const scheduleDay = 'dd, DD MMMM';
export const dayOfMonth = 'DD MMMM';
export const scheduleTime = 'HH:mm';
export const scheduleHours = 'HH';
export const scheduleMinutes = 'mm';
export const persianSheduleDay = 'dddd, jM/jD';
export const persianDayOfMonth = 'jM/jD';

export const LOCALES = {
  [Language.En]: moment().locale('en_GB'),
  [Language.Ru]: moment().locale('ru_RU'),
  [Language.Kk]: moment().locale('kk_KZ'),
  [Language.Fa]: moment().locale('fa_IR'),
  [Language.Zh]: moment().locale('zh-cn'),
  [Language.Uz]: moment().locale('uz-lat'),
};

const dateFromLocale = (date: Date | moment.Moment | string = new Date(), locale = Language.En) =>
  moment(date).locale(LOCALES[locale]);

export const getScheduleDate = function getScheduleDate(date, locale) {
  const format = locale === Language.Fa ? persianSheduleDay : scheduleDay;
  
  return dateFromLocale(date, locale).format(format);
};

export const getFullDate = function getFullDate(date, locale) {
  const format = locale === Language.Fa ? persianSheduleDay : fullDay;
  return dateFromLocale(date, locale).format(format);
};

export const getFormatFullDate = function getFormatFullDate(date, locale) {
  const format = locale === Language.Fa ? persianFullDate : fullDate;
  return dateFromLocale(date, locale).format(format);
};

export const getShortDate = function getShortDate(date, locale) {
  const format = locale === Language.Fa ? persianShortDate : shortDate;
  return dateFromLocale(date, locale).format(format);
};

export const getDate = function getDate(date, locale) {
  const format = locale === Language.Fa ? persianDayOfMonth : dayOfMonth;
  return dateFromLocale(date, locale).format(format);
};

export const getScheduleTime = (date, locale = Language.En) =>
  dateFromLocale(date, locale).format(scheduleTime);

export const getScheduleHours = (date, locale) =>
  dateFromLocale(date, locale).format(scheduleHours);

export const getScheduleMinutes = (date, locale) =>
  dateFromLocale(date, locale).format(scheduleMinutes);

export const getMatchDate = (date, locale) => dateFromLocale(date, locale).format('LLL');

export function getMonth(date, locale) {
  const format = locale === Language.Fa ? 'jMMMM' : 'MMMM';

  return dateFromLocale(date, locale).format(format);
}

export function getDay(date, locale) {
  const format = locale === Language.Fa ? 'jD' : 'D';

  return dateFromLocale(date, locale).format(format);
}

const PERSIAN_NUMBERS = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

export const persianNumbersToArabic = (persianNumbers) => {
  let res = '';

  // eslint-disable-next-line
  for (const num of persianNumbers) {
    const arabicNumber = PERSIAN_NUMBERS.indexOf(num);

    if (arabicNumber !== -1) {
      res += arabicNumber;
    }
  }

  return parseInt(res, 10);
};

export const persianYearToArabic = (persianYear) => persianNumbersToArabic(persianYear) + 622;

export function formatYear(year, locale, fromLocale) {
  if (fromLocale === 'fa') {
    return persianYearToArabic(year);
  }

  const date = moment(new Date(year, 1, 0, 0, 0, 0, 0));
  const format = locale === Language.Fa ? 'jYYYY' : 'YYYY';

  return dateFromLocale(date, locale).format(format);
}

export const makeWeekDays = (): Moment[] => {
  const result = [];

  for (let idx = 7; idx > 0; idx -= 1) {
    // @ts-ignore
    result.push(moment().subtract(idx, 'days'));
  }
  for (let idx = 0; idx <= 7; idx += 1) {
    // @ts-ignore
    result.push(moment().add(idx, 'days'));
  }

  return result;
};

export const isToday = (date) => moment().isSame(moment(date), 'day');
export const isSameDate = (day) => (date) => moment(day).isSame(moment(date), 'day');
