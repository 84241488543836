import { useMutation } from 'react-query';

import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';
import { UsernameAvailabilityType } from '~typings/UsernameAvailabilityType';


export type UsernameAvailabilityResult = Readonly<{
  data: {
    available: boolean;
    format: string;
    object: 'username_availability';
    type: UsernameAvailabilityType;
    restricted: boolean;
    message: string;
  };
  meta: any;
}>;;

const fetch = async (
  client: ApiClient,
  username: string,
): Promise<UsernameAvailabilityResult> => {
  return await client.get(
    '/v1/users/username_availability.json',
    {
      username,
    },
  );
}

export const useUsernameAvailabilityMutation = () => {
  const client = useClient();

  return useMutation((
    { username }: { username: string }
  ) => (
    fetch(client, username)
  ), {
    onError: _error => {
      // console.log(_error); //eslint-disable-line
    }
  });
}

export default useUsernameAvailabilityMutation;
