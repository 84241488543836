/* eslint-disable max-len */
import * as React from 'react';

import * as css from './styles.module.css'


type Props = {
  width?: string
  height?: string
  text?: string
}

const AgeIcon = ({ width = '3.125vw', height = '2.7777777777777777vh', text }: Props) => (
  <div style={ { width, height } } className={ css.ageIcon }>
    { text }
  </div>
);


export { AgeIcon };
