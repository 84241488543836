import * as React from 'react';

import getScreenHeight from '~lib/screen/height';


type Props = Readonly<{
  className?: string;
  height?: number;
  animationDurationInMS?: number;
  scrollToNode?: HTMLDivElement | null;
  children: any;
}>;


const AutoScrollableArea: React.FC<Props> = (props: Props) => {
  const {
    height: visibleHeight = getScreenHeight(),
  } = props;
  const scrollableAreaRef = React.useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (scrollableAreaRef.current) {
        if (props.scrollToNode) {
          const { top: containerTop } = scrollableAreaRef.current.getBoundingClientRect();
          const { top, height } = props.scrollToNode.getBoundingClientRect();
          const targetTop = Math.max(
            Math.abs(
              (containerTop - top)) - ((visibleHeight / 2) - (height / 6)
            ),
            0
          );

          scrollableAreaRef.current.style.transform = `translate3d(0, ${-targetTop}px, 0)`;
          // @ts-ignore
          scrollableAreaRef.current.style.WebkitTransform = `translate3d(0, ${-targetTop}px, 0)`;
        } else {
          scrollableAreaRef.current.style.transform = 'translate3d(0, 0, 0)';
          // @ts-ignore
          scrollableAreaRef.current.style.WebkitTransform = 'translate3d(0, 0, 0)';
        }
      }
    }, 200);

    return (
      () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      }
    );
  }, [props.scrollToNode]);

  return (
    <div
      ref={ scrollableAreaRef }
      className={ props.className }
    >
      { props.children }
    </div>
  );
};


export default AutoScrollableArea;
