import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import BlockPage from '~components/BlockPage/component';
import PageNotFound from '~components/Pag4404/component';
import { useMenu } from '~components/Provider/Menu';
import { useLoadedPages, usePageById } from '~hooks/fetch/usePages/usePages';
import { PageInfo } from '~hooks/usePageInfo';
import goToDefaultPage from '~lib/goToDefaultPage';
import { EPGPage } from '~newapp/screens/EPGPage';
import { SingleCardCollectionPage } from '~newapp/screens/SingleCardCollectionPage';
import PersonalScreen from '~screens/PersonalScreen/routes';
import PageObject from '~typings/PageObject';

type Props = Readonly<{
  pageInfo: PageInfo;
}>;

const PageSwitcher: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const menu = useMenu();
  const isPopupOpened = (menu.isMenuOpened || menu.isPopupOpened);
  const { pageId } = useParams<{ pageId: string }>();
  const pages = useLoadedPages();
  const page = usePageById(pageId);


  // If the current page is unknown, then we redirect to the default page
  React.useEffect(() => {
    if (pages.length > 0 && !page) {
      goToDefaultPage(pages, history);
    }
  }, [page, pages.length]);

  if (!page) {
    return null;
  }

  if(page.object === PageObject.SearchPage) {
    history.push('/search')
  }

  if (page.object === PageObject.SubscribePage) {
    history.push('/products')
  }

  if (page.object === PageObject.SingleCardCollectionPage) {
    return <SingleCardCollectionPage page={ page } key={ page['id'] }/>
  }

  if (
    page.object === PageObject.ContentPage
    || page.object === PageObject.MainPage
    || page.object === PageObject.CompetitionPage
  ) {
    return <BlockPage page={ page } pageInfo={ props.pageInfo } isPopupOpened={ isPopupOpened }/>
  }

  if (page.object === PageObject.PersonalPage) {
    return <PersonalScreen/>
  }

  if (page.object === PageObject.EpgPage) {
    return <EPGPage/>
  }

  return <PageNotFound isMenuOpened={ menu.isMenuOpened } page={ page }/>
};


export default React.memo(PageSwitcher);
