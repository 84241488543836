import cn from 'classnames';
import * as React from 'react';

import ErrorIcon from '~ui/ErrorText/icon';

import * as styles from './styles.module.css';


type Props = Readonly<{
  headerText?: string | null;
  descriptionText?: string | null;
  errorText?: string | null;
  children: React.ReactNode | React.ReactNode[];
  footer?: React.ReactNode  | React.ReactNode[];
  footerAdditionalClassname?: string;
}>;


const FieldSet: React.FC<Props> = (props: Props) => {

  const renderHeaderText = React.useMemo(() => {
    if (!props.headerText) { return null; }

    return (
      <div
        className={ styles.header }
      >
        { props.headerText }
      </div>
    );
  }, [props.headerText]);

  const renderDescriptionText = React.useMemo(() => {
    if (!props.descriptionText) { return null; }

    return (
      <div
        className={ styles.descriptionText }
      >
        { props.descriptionText }
      </div>
    );
  }, [props.descriptionText]);

  const renderErrorText = React.useMemo(() => {
    if (!props.errorText) { return null; }

    return (
      <div
        className={ styles.errorWrapper }
      >
        <ErrorIcon className={ styles.icon } />
        <span className={ styles.errorText }>
          { props.errorText }
        </span>
      </div>
    );
  }, [props.errorText]);

  const renderFooter = React.useMemo(() => {
    if (!props.footer) { return null; }

    return (
      <div className={ cn(styles.footer, props.footerAdditionalClassname) }>
        { props.footer }
      </div>
    );
  }, [props.footer]);

  return (
    <>
      { renderHeaderText }
      <div className={ styles.headerBlock }>
        { renderErrorText }
        { !props.errorText ? renderDescriptionText : null }
      </div>
      <div className={ styles.layoutWrapper }>
        { props.children }
      </div>
      { renderFooter }
    </>
  );
};


/**
 * @deprecated нужно перейти на KeyboardFieldSet
 */
export default React.memo(FieldSet);
