import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ActionType,useAppAction } from '~components/Provider/App';
import { ActionType as MenuActionType, useMenu, useMenuAction } from '~components/Provider/Menu';
import { LOGIN_OR_REGISTER } from '~localization';
import useSideBarNavigation, { FOCUS_ON } from '~stores/SideBarNavigation';
import NavigationDirection from '~typings/NavigationDirection';
import Button from '~ui/button';

import * as styles from './styles.module.css';

type Props = {
  id: string;
  intl: InjectedIntl;
};

const PersonalPageUnauthorized = ({ id, intl }: Props) => {
  const history = useHistory();
  const appAction = useAppAction();
  const { isPopupOpened, isMenuOpened } = useMenu();
  const menuAction = useMenuAction();
  const focusOn = useSideBarNavigation(st => st.getFocusOn());
  const setFocusOn = useSideBarNavigation(st => st.setFocusOn);

  const onLeaveHandler = (direction: NavigationDirection) => {
    switch (direction) {
      case NavigationDirection.Up: {
        menuAction({
          type: MenuActionType.ChangeMenuState,
          isMenuOpened: !isMenuOpened,
        });
        break;
      }
      case NavigationDirection.Left: {
        setFocusOn(FOCUS_ON.LEFT_MENU);
        break;
      }
      default:
          break;
    }
  };

  const onAuthBtnClick = () => {
    appAction({
      type: ActionType.SetAuthRedirectUrl,
      payload: { authRedirectUrl: history.location.pathname },
    });

    history.replace('/auth');
  };

  return (
    <>
      <div className={ styles.message }>
        { intl.formatMessage({ id }) }
      </div>
      <Button
        className={ styles.button }
        onClick={ onAuthBtnClick }
        onLeave={ onLeaveHandler }
        isFocused={ !isPopupOpened && !isMenuOpened && focusOn === FOCUS_ON.CONTENT }
      >
        {
          intl.formatMessage({ id: LOGIN_OR_REGISTER })
        }
      </Button>
    </>
  );
};


export default injectIntl(PersonalPageUnauthorized);
