import Language from '~typings/Language';

export const LanguageToTextMap: { [key in Language]: string } = {
  [Language.Ru]: 'Русский',
  [Language.En]: 'English',
  [Language.Kk]: 'Қазақ тілі',
  [Language.Fa]: 'فا',
  [Language.Uz]: `O'zbek`,
  [Language.Zh]: 'Zh',
  [Language.Ky]: 'Кыргызча'
};
