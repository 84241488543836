// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title--lG7yD {\n  -webkit-line-clamp: 2;\n}\n\n.subtitle--OTGgv {\n  -webkit-line-clamp: 3;\n}\n\n.title--lG7yD, .subtitle--OTGgv {\n  color: rgba(223, 223, 228, 1);\n\n  /* 20px */\n  margin-bottom: 1.5625vw;\n  word-break: break-word;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MiddleBannerBlock/components/MiddleBannerContent/MiddleBannerContent.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,6BAAa;;EAEb,SAAS;EACT,uBAAuB;EACvB,sBAAsB;EACtB,oBAAoB;EACpB,4BAA4B;EAC5B,gBAAgB;AAClB","sourcesContent":[".title {\n  -webkit-line-clamp: 2;\n}\n\n.subtitle {\n  -webkit-line-clamp: 3;\n}\n\n.title, .subtitle {\n  color: $gw_08;\n\n  /* 20px */\n  margin-bottom: 1.5625vw;\n  word-break: break-word;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export var title = "title--lG7yD";
export var subtitle = "subtitle--OTGgv";
export default ___CSS_LOADER_EXPORT___;
