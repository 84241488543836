import cn from 'classnames';
import React from 'react';

import * as styles from './Separator.module.css';

export type Props = {
  className?: string;
};

export const Separator: React.FC<Props> = ({ className }) => {
  return <div className={ cn(styles.separator, className) } />;
};
