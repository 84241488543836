import { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { isAllowedMenuItem } from '~components/NavMenu/component.helpers';
import { useConfig } from '~global';
import { getPageByUID, getPageIdByDeepLink } from '~hooks/fetch/usePages/getPages';
import { getDeepLinkURL, getObjectAndID, isCollection, isMediaItem, isPage, isProduct } from '~lib/deepLinkResolver';
import Card, { Modification } from '~typings/Card';
import ItemObject from '~typings/ItemObject';


export default function useDeepLink() {
  const { smartTV: { profileSection } } = useConfig();
  const matchPage = useRouteMatch('/page/:id');

  const urlFromDeepLink = useCallback((deepLink: string, modification?: Card['modification']): string | null => {

    const deepLinkURL = getDeepLinkURL(deepLink);

    if (deepLinkURL === null) {
      return null;
    }

    const deeplinkOnPage = getPageByUID(deepLinkURL);

    if (deeplinkOnPage !== undefined && isAllowedMenuItem(deeplinkOnPage.object)) {
      return `/page/${ deeplinkOnPage.id }`;
    }
    else if (isMediaItem(deepLinkURL)) { // If Deep Link on MediaItem Page
      const item = getObjectAndID(deepLinkURL);
      let player = false;

      if ((modification && [Modification.CURRENT, Modification.CATCHUP].includes(modification))
        || item.object === ItemObject.Channel) {
        player = true;
      }

      if (modification && [Modification.FUTURE, Modification.PAST].includes(modification)) {
        if (matchPage?.url) {
          return `${ matchPage.url }/media-item-details/${ item.object }/${ item.id }`;
        }
        else {
          return `/media-item-details/${ item.object }/${ item.id }`;
        }
      }

      if (matchPage?.url) {
        return `${ matchPage.url }/media-item/${ item.object }/${ item.id }${ player ? '/player?player=true' : '' }`;
      }
      else {
        return `/media-item/${ item.object }/${ item.id }${ player ? '/player?player=true' : '' }`;
      }
    }
    else if (isProduct(deepLinkURL)) {
      return `/${ deepLinkURL }`
    }
    else if (isCollection(deepLinkURL) && profileSection.subscriptions) {
      const link = deepLinkURL.split('/')
      return `/card_collection/${ link[1] }`
    }
    else if (isPage(deepLinkURL)) {
      const targetPage = getPageIdByDeepLink(deepLinkURL)

      if (targetPage === 'error') {
        return `/error`
      }
      return `/page/${ targetPage }`
    }

    return null;
  }, [profileSection.subscriptions, matchPage?.url]);

  return { urlFromDeepLink }
};
