import {
  KeyBoardKey,
  KeyBoardKeyType,
} from '~typings/Keyboard';


const keyboardNumber: KeyBoardKey[][] = [
  [
    { type: KeyBoardKeyType.Char, id: 1, },
    { type: KeyBoardKeyType.Char, id: 2, },
    { type: KeyBoardKeyType.Char, id: 3, },
    { type: KeyBoardKeyType.Char, id: 4, },
    { type: KeyBoardKeyType.Char, id: 5, },
    { type: KeyBoardKeyType.Char, id: 6, },
    { type: KeyBoardKeyType.Char, id: 7, },
    { type: KeyBoardKeyType.Char, id: 8, },
    { type: KeyBoardKeyType.Char, id: 9, },
    { type: KeyBoardKeyType.Char, id: 0, },
    { type: KeyBoardKeyType.Space, disabled: true },
    { type: KeyBoardKeyType.Backspace, },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'q', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'w', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'e', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'r', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 't', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'y', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'u', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'i', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'o', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'p', disabled: true, },
    { type: KeyBoardKeyType.Char, id: '@', disabled: true, },
    { type: KeyBoardKeyType.SpecialChars, id: '*#$', disabled: true, },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'a', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 's', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'd', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'f', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'g', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'h', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'j', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'k', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'l', disabled: true, },
    { type: KeyBoardKeyType.Char, id: '_', disabled: true, },
    { type: KeyBoardKeyType.Char, id: '+', disabled: true, },
    { type: KeyBoardKeyType.Char, id: '.com', disabled: true, },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'z', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'x', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'c', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'v', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'b', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'n', disabled: true, },
    { type: KeyBoardKeyType.Char, id: 'm', disabled: true, },
    { type: KeyBoardKeyType.Char, id: ',', disabled: true, },
    { type: KeyBoardKeyType.Char, id: '.', disabled: true, },
    { type: KeyBoardKeyType.Char, id: '-', disabled: true, },
    { type: KeyBoardKeyType.Char, id: '.ru', disabled: true, },
    { type: KeyBoardKeyType.Close, id: 'OK', },
  ],
];


export default keyboardNumber;
