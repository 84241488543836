import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  useInfinityRecommendations,
} from '~hooks/fetch/useRecommendations/useRecommendations';
import { usePaginationByItems } from '~hooks/usePagination';
import { getField } from '~lib/URLQueryStringManager';
import { RECOMMENDED_FOR_YOU } from '~localization';
import useNavigationOnLayer from '~stores/LayerNavigation';

import CollectionPageWrapper from './Wrapper';

const SLIDES_COUNT_PER_LINE = 4;
const MINIMAL_LINES_ON_SCREEN = 3;

const RecommendationsPage = () => {
  const layerID = 'collection_page-recommendations';
  const focusedIndex = useNavigationOnLayer(state => state.getFocusedIndexOnLayer(layerID));
  const history = useHistory();
  const { parsedData: recItems, query } = useInfinityRecommendations();
  usePaginationByItems({
    currentFetchedCount: recItems?.data.length ?? 0,
    focusedLineIdx: Math.floor(focusedIndex / SLIDES_COUNT_PER_LINE),
    elementsPerLine: SLIDES_COUNT_PER_LINE,
    minimalLinesOnScreen: MINIMAL_LINES_ON_SCREEN,
    fetchMoreData: ()=> {
      if(query.hasNextPage && !query.isFetchingNextPage){
        query.fetchNextPage();
      }
    }
  })

  const title = getField(history.location.search, 'blockTitle');

  return (
    <CollectionPageWrapper
      layerID={ layerID }
      title={ title || <FormattedMessage id={ RECOMMENDED_FOR_YOU } /> }
      items={ recItems }
      showAsSeries
      showOnlyFetchedItems
      slidesCountPerLine={ SLIDES_COUNT_PER_LINE }
    />
  );
};


export default RecommendationsPage;
