import { useCallback, useEffect, useMemo, useState } from 'react';
import create, { UseStore } from 'zustand';

import { getInfinityProgramEventsFromCache } from '~hooks/fetch/useProgramEvents/useProgramEvents';
import { useLocationSearchState } from '~hooks/useLocationSearchState';
import Channel from '~typings/Channel';
import ProgramEvent from '~typings/Event';

import { ChannelsFocusedState, EventsListFocusedState } from '../../EPGPageCommon.types';
import { SliderImperativeRefType } from '../EPGChannels';
import { FocusRememberStateInHistory, GlobalFocusedState, RestoredFocusState } from './EPGPageLoaded.types';

type UseChildrenFocusStoreProps = {
  initialState?: GlobalFocusedState;
  channels: Channel[];
  onChannelsChange: () => void;
};

export const useChildrenFocusStore = ({
  channels,
  onChannelsChange,
  initialState,
}: UseChildrenFocusStoreProps) => {
  const [useChildrenFocusStore] = useState(() => {
    return create<GlobalFocusedState>(() => initialState ||({
      channelsState: {
        focusedIdx: 0,
        focusedChannel: channels[0],
      },
      eventsState: null,
    }));
  });

  const state = useChildrenFocusStore();

  const eventsSliderState = state.eventsState;
  const setEventsSliderState = useCallback((state: EventsListFocusedState) => {
    useChildrenFocusStore.setState((prev) => ({
      eventsState: state,
      channelsState: prev.channelsState,
    }));
  }, []);

  const channelsSliderState = state.channelsState;
  const setChannelsSliderState = useCallback(
    (state: ChannelsFocusedState) => {
      onChannelsChange();
      useChildrenFocusStore.setState({
        eventsState: null,
        channelsState: state,
      });
    },
    [onChannelsChange],
  );

  return {
    eventsSliderState,
    setEventsSliderState,
    channelsSliderState,
    setChannelsSliderState,
    store: useChildrenFocusStore,
  };
};

const FOCUS_REMEMBER_FIELD_NAME = 'epg_focus_remember';

type UseRememberFocusProps = {
  globalFocusedStore: UseStore<GlobalFocusedState>;
  channelsSliderRef: SliderImperativeRefType;
};
export const useRememberFocusState = ({ globalFocusedStore, channelsSliderRef }: UseRememberFocusProps) => {
  const { setNewState: rememberInUrl } = useLocationSearchState<FocusRememberStateInHistory>({
    pathInSearch: FOCUS_REMEMBER_FIELD_NAME,
  });

  const beforeChannelLeave = useCallback((channel: Channel) => {
    rememberInUrl({
      type: 'forChannels',
      channelId: channel.id,
      channelsOffset: channelsSliderRef.current?.getOffsetY() || null,
    });
  }, []);

  type beforeEventListLeaveProps = {
    event: ProgramEvent;
    pagePivotDate: string;
  };
  const beforeEventListLeave = useCallback(
    ({ event, pagePivotDate }: beforeEventListLeaveProps) => {
      rememberInUrl({
        type: 'forEventsList',
        pagePivotDate: pagePivotDate,
        channelId: globalFocusedStore.getState().channelsState.focusedChannel.id,
        programId: event.id,
        channelsOffset: channelsSliderRef.current?.getOffsetY() || null,
      });
    },
    [],
  );

  const beforeDetailsLeave = useCallback(() => {
    const channel = globalFocusedStore.getState().channelsState.focusedChannel;
    const eventListFocusedState = globalFocusedStore.getState().eventsState;
    if (!eventListFocusedState) {
      rememberInUrl({
        type: 'forEventDetails',
        subType: 'forChannel',
        channelId: channel.id,
        channelsOffset: channelsSliderRef.current?.getOffsetY() || null,
      });
      return;
    }

    const event = eventListFocusedState.focusedEvent;
    const pagePivotDate = eventListFocusedState.focusedPage.pivotDate;
    rememberInUrl({
      type: 'forEventDetails',
      subType: 'forProgram',
      pagePivotDate: pagePivotDate,
      channelId: channel.id,
      programId: event.id,
      channelsOffset: channelsSliderRef.current?.getOffsetY() || null,
    });
  }, []);



  return {
    beforeChannelLeave,
    beforeEventListLeave,
    beforeDetailsLeave,
  };
};

type UseRestoreFocusProps = {
  channels: Channel[];
};

export const useRestoreFocusState = ({ channels }: UseRestoreFocusProps) => {
  const { parsedState, clearState } = useLocationSearchState<FocusRememberStateInHistory>({
    pathInSearch: FOCUS_REMEMBER_FIELD_NAME,
  });

  const restoredState: RestoredFocusState | null = useMemo(() => {
    if (!parsedState) {
      return null;
    }
    const getGlobalFocusedState = () => {
      switch (parsedState.type) {
        case 'forChannels':
          return recoverStateForChannel(channels, parsedState.channelId);
        case 'forEventsList':
          return recoverStateForEventList(channels, parsedState);
        case 'forEventDetails':
          switch (parsedState.subType) {
            case 'forChannel':
              return recoverStateForDetailsByChannels(channels, parsedState.channelId);
            case 'forProgram':
              return recoverStateForDetailsByPrograms(channels, parsedState);
          }
      }
    };

    const getFocusOn = () => {
      switch (parsedState.type) {
        case 'forChannels':
          return 'channels';
        case 'forEventsList':
          return 'events';
        case 'forEventDetails':
          return 'details';
      }
    };

    const globalFocusedState = getGlobalFocusedState();

    if (!globalFocusedState) {
      return null;
    }

    return {
      globalFocusedState: globalFocusedState,
      epgFocusOn: getFocusOn(),
      channelsSliderOffset: parsedState.channelsOffset,
    } satisfies RestoredFocusState;
  }, []);

  useEffect(() => {
    clearState();
  }, []);

  return restoredState;
};

const recoverStateForChannel = (
  channels: Channel[],
  channelId: string,
): GlobalFocusedState | null => {
  const channelIdx = channels.findIndex((c) => c.id === channelId);
  if (!channels[channelIdx]) {
    return null;
  }
  return {
    channelsState: {
      focusedChannel: channels[channelIdx],
      focusedIdx: channelIdx,
    },
    eventsState: null,
  };
};

const recoverStateForEventList = (
  channels: Channel[],
  params: {
    pagePivotDate: string;
    channelId: string;
    programId: string;
  },
): GlobalFocusedState | null => {
  const eventPages = getInfinityProgramEventsFromCache(params.channelId);

  if (!eventPages?.length) {
    return null;
  }

  const channelStateNotParsed = recoverStateForChannel(channels, params.channelId);
  if (!channelStateNotParsed) {
    return null;
  }

  const eventPage = eventPages.find((p) => p.pivotDate === params.pagePivotDate);

  if (!eventPage) {
    return null;
  }

  const eventIdx = eventPage.programEvents.findIndex((e) => e.id === params.programId);
  if (eventIdx === -1) {
    return null;
  }

  return {
    channelsState: channelStateNotParsed.channelsState,
    eventsState: {
      focusedPage: eventPage,
      focusedEvent: eventPage.programEvents[eventIdx],
      focusedIdxInPage: eventIdx,
    },
  };
};

const recoverStateForDetailsByPrograms = (
  channels: Channel[],
  params: {
    pagePivotDate: string;
    channelId: string;
    programId: string;
  },
) => {
  return recoverStateForEventList(channels, params);
};

const recoverStateForDetailsByChannels = (channels: Channel[], channelId: string) => {
  return recoverStateForChannel(channels, channelId);
};
