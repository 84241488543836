import * as React from "react";
import { useState } from "react";

import { Filters } from "~components/Filters";
import { ID, SearchPageBlocks } from "~components/SearchPage/SearchPage";
import useNavigationByKeys from "~hooks/useNavigation";
import NavigationDirection from "~typings/NavigationDirection";


interface Props {
  searchFilters
  isFocused
  isFiltersActive
  switchNavigationBlock
  setFiltersPosition
}

export function SearchFilters(props: Props) {
  const { searchFilters, isFiltersActive, switchNavigationBlock, setFiltersPosition } = props
  const [position, setPosition] = useState(0)

  const handleKeyNavigate = (direction): void => {
    if (!props.isFocused) {
      return
    }
    switch (direction) {
      case NavigationDirection.Up: {
        switchNavigationBlock(direction, SearchPageBlocks.SearchFilters)
        return;
      }
      case NavigationDirection.Down: {
        switchNavigationBlock(direction, SearchPageBlocks.SearchFilters)
        return;
      }
      case NavigationDirection.Right: {
        if (isFiltersActive) {
          if (position < searchFilters.length) {
            setPosition(position + 1)
            setFiltersPosition(position + 1)
          }
        }
        else {
          if (position < searchFilters.length - 1) {
            setPosition(position + 1)
            setFiltersPosition(position + 1)
          }
        }

        return;
      }
      case NavigationDirection.Left: {

        if (position !== 0) {
          setPosition(position - 1)
          setFiltersPosition(position - 1)
        }
        else {
          switchNavigationBlock(direction, SearchPageBlocks.SearchFilters)
        }
        return;
      }
      default: {
        return;
      }
    }
  }


  useNavigationByKeys({
    isMounted: props.isFocused,
    onNavigation: handleKeyNavigate,
  }, [props.isFocused]);


  return <Filters
    ID={ ID }
    filterGroup={ searchFilters }
    isFocused={ props.isFocused }
    focusedIndex={ position }
    withReset = { isFiltersActive }
  />
}