import * as React from 'react';

import { FocuserKeyboard, FocuserKeyboardAction, HideBtnState } from '~components/FocuserKeyboard';
import { PinInput } from '~ui/PinInput';

import * as styles from './styles.module.css';

type Props = Readonly<{
  inputValue: string;
  /**
   * Ввод нового значения пина
   */
  onInput: (value: string) => void;

  /**
   * Коллбэк при нажатии на кнопку backspace
   */
  onBackspace: () => void;
}>;

export const PinWithKeyboard: React.FC<Props> = ({ inputValue, onBackspace, onInput }: Props) => {
  const [hideBtnState, setHideBtnState] = React.useState<HideBtnState>('hidden');

  const keyboardActionHandler = React.useCallback((key: FocuserKeyboardAction) => {
    switch (key) {
      case 'backspace':
        onBackspace();
        return;
      case 'hide':
        setHideBtnState('hidden');
        return;
      case 'show':
        setHideBtnState('shown');
        return;
    }
  }, []);

  const inputHandler = React.useCallback(
    (value: string) => {
      if (!isNaN(Number(value))) {
        onInput(value);
      }
    },
    [onInput],
  );

  return (
    <>
      <PinInput
        className={ styles.input }
        value={ inputValue }
        showPinCode={ hideBtnState === 'shown' }
      />
      <FocuserKeyboard
        initialKeyboardLayout='numbers'
        hideBtnState={ hideBtnState }
        isConfirmBtnActive={ false }
        onInput={ inputHandler }
        onAction={ keyboardActionHandler }
      />
    </>
  );
};
