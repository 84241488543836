// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".About--mmEuU {\n  /* 10px */\n  padding-top: 0.78125vw;\n}\n\n.AboutInfoFields--trRVD {\n  /* 30px */\n  margin-top: 2.34375vw;\n\n  /* 60px */\n  margin-bottom: 4.6875vw;\n}\n\n.logo--B2HXE {\n  /* 340px */\n  width: 26.5625vw;\n\n  /* 37.85px */\n  height: 2.95703125vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/About/About.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,qBAAqB;;EAErB,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,gBAAgB;;EAEhB,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":[".About {\n  /* 10px */\n  padding-top: 0.78125vw;\n}\n\n.AboutInfoFields {\n  /* 30px */\n  margin-top: 2.34375vw;\n\n  /* 60px */\n  margin-bottom: 4.6875vw;\n}\n\n.logo {\n  /* 340px */\n  width: 26.5625vw;\n\n  /* 37.85px */\n  height: 2.95703125vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var About = "About--mmEuU";
export var AboutInfoFields = "AboutInfoFields--trRVD";
export var logo = "logo--B2HXE";
export default ___CSS_LOADER_EXPORT___;
