enum DRMType {
  AdobeDRM = 'adobedrm',
  FairPlay = 'fairplay',
  PlayReady = 'playready',
  SPbTVCas = 'spbtvcas',
  VeriMatrix = 'verimatrix',
  WideVine = 'widevine',
}


type DrmAdobe = Readonly<{
  type: DRMType.AdobeDRM;
}>;


type DrmFairplay = Readonly<{
  type: DRMType.FairPlay;
  certificate_url: string;
  ksm_url: string;
  encode_base64: boolean;
  ksm_apienum: 'megogo' | 'ezdrm';
}>;


type DrmPlayready = Readonly<{
  type: DRMType.PlayReady;
  license_server: string;
  drm_asset_id?: never;
}>;


type DrmSpbtvcas = Readonly<{
  type: DRMType.SPbTVCas;
}>;


type DrmVerimatrix = Readonly<{
  type: DRMType.VeriMatrix;
}>;


type DrmWidevine = Readonly<{
  type: DRMType.WideVine;
  license_server: string;
  drm_asset_id?: string;
}>;


type Drm = Readonly<DrmAdobe | DrmFairplay | DrmPlayready | DrmSpbtvcas | DrmVerimatrix | DrmWidevine>;


export {
  DRMType,
}

export default Drm;
