import * as React from 'react';

import { usePlatform } from '~components/Provider/Platform';
import { UserDevice } from '~typings/Devices';

import { convertOSName } from '../../screens/AccountScreen/components/Tabs/Devices/utils';
import * as styles from './DeviceLine.module.css';
import AndroidIcon from './images/AndroidIcon';
import IphoneIcon from './images/IphoneIcon';
import SmartTVIcon from './images/SmartTVIcon';
import TabletIcon from './images/Tablet';

type Props = {
  userDevice: UserDevice;
  /**
   * Кнопка, которая появляется в правом углу
   */
  button: React.ReactNode;
};

export const DeviceLine: React.FC<Props> = React.memo((props) => {
  const userDevice = props.userDevice;
  const { platform } = usePlatform();
  const isCurrentDevice = platform.getDeviceInfo().deviceId === userDevice.device.device_id;

  const renderImgForType = (type: string, osName: string) => {
    if (type === 'smarttv') {
      return (
        <div className={ styles.deviceIcon }>
          <SmartTVIcon className={ styles.deviceIconBigDevice } />
        </div>
      );
    }

    if (type === 'mobile') {
      if (osName === 'android') {
        return (
          <div className={ styles.deviceIcon }>
            <AndroidIcon className={ styles.deviceIconMobile } />
          </div>
        );
      }
      return (
        <div className={ styles.deviceIconMobile }>
          <IphoneIcon className={ styles.deviceIconMobile } />
        </div>
      );
    }

    if (type === 'tablet') {
      return (
        <div className={ styles.deviceIcon }>
          <TabletIcon className={ styles.deviceIconBigDevice } />
        </div>
      );
    }
  };

  return (
    <div className={ styles.device }>
      {renderImgForType(userDevice.device.type, userDevice.device.os_name)}
      <div className={ styles.deviceInfoAndButtonWrapper }>
        <div className={ styles.textBlock }>
          <div className={ styles.deviceName }>{userDevice.name}</div>
          {isCurrentDevice ? (
            <div className={ styles.deviceOSWrap }>
              <div className={ styles.currentDeviceIcon }></div>
              <div className={ styles.deviceOS }>{userDevice.device.os_name}</div>
            </div>
          ) : (
            <div className={ styles.deviceOS }>{convertOSName(userDevice.device.os_name)}</div>
          )}
        </div>
        {props.button}
      </div>
    </div>
  );
});

DeviceLine.displayName = 'DeviceLine';
