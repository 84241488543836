import * as React from 'react';


type Props = {
  className?: string;
};

/* eslint-disable max-len */
const ArrowIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg className={ className } viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1012 19.8182C10.7208 19.4178 10.7371 18.7848 11.1375 18.4045L11.8625 17.7157C12.2629 17.3353 12.8958 17.3515 13.2762 17.7519L24 29.0401L34.7237 17.752C35.1041 17.3515 35.7371 17.3353 36.1375 17.7157L36.8625 18.4045C37.2629 18.7848 37.2791 19.4178 36.8987 19.8182L24.725 32.6326C24.3308 33.0476 23.6693 33.0476 23.275 32.6326L11.1012 19.8182Z"
        fill="currentColor"
      />
    </svg>
  );
};


export default ArrowIcon;
