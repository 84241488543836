import * as cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { setFocusOn } from '~hooks/useSpatialNavigation/helpers';
import { isCatchupAvailable } from '~lib/eventsHelper';
import { getEPGEventHeight } from '~lib/SizesInPX';
import { usePlayerStore } from "~stores/Player";
import Blackout from '~typings/Blackout';
import Channel from '~typings/Channel';
import Event from '~typings/Event';
import ItemObject from '~typings/ItemObject';

import EPGPageEventRender from './EventRender';
import * as styles from './styles.module.css';


type Props = Readonly<{
  channel?: Channel;
  blackouts?: Blackout[];
  isCurrentEvent?: boolean;
  isActive?: boolean;
  isVisible?: boolean;
  isFocused?: boolean;
  isSingleEvent?: boolean;
  item: Event;
  index?: number;
  focusIndex?: number;
  archivedPrograms?: string[] | [] | undefined;
  onClick?: (event: Event, isCatchup: boolean, isCurrentEvent: boolean) => void;
}>;


const EPGPageEvent: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const playerState = usePlayerState();
  const { setPlayList } = usePlayerStateActions()
  const setCurrentProgram = usePlayerStore(state => state.setCurrentProgram)
  const setIsLive = usePlayerStore(state => state.setIsLive)
  const isPast = (props.item && (new Date(props.item.end_at) < new Date()));

  const isCatchup = (
    props.item && !!(
      isPast
      && props.channel?.catchup_availability.available
      && isCatchupAvailable(props.item, props.channel, props.blackouts)
    )
  );

  const handleClick = () => {
    if (props.item) {
      const startDate = new Date(props.item["start_at"]).getTime()
      const endDate = new Date(props.item["end_at"]).getTime()
      const curDate = new Date().getTime()

      if (curDate >= startDate && curDate <= endDate) {
        setIsLive(true)
      } else {
        setIsLive(false)
        setCurrentProgram([props.item])
      }
    }

    if (isCatchup && props.archivedPrograms) {
      setPlayList(props.archivedPrograms)
    }

    if (props.onClick) {
      props.onClick(props.item, isCatchup, !!props.isCurrentEvent);
    }
    else {
      if (props.index !== undefined) {
        setFocusOn(history, props.index);
      }

      if (isCatchup && playerState.item === null && props.item) {
        history.push(`/media-item/${ ItemObject.ProgramEvent }/${ props.item.id }/player?player=true`);
      }
      else if (props.isCurrentEvent) {
        history.push(`/media-item/${ ItemObject.Channel }/${ props.channel?.id }?player=true`);
      }
    }
  };

  const style: React.CSSProperties = {};

  const isAbsolutePosition = !isNaN(parseInt(props.index + ''));

  if (isAbsolutePosition) {
    style.top = `${ getEPGEventHeight() * (props.index as number) }px`;
  }

  const className = cn(styles.event, {
    [styles.absolutePosition]: isAbsolutePosition,
    [styles.active]: props.isActive,
    [styles.pastEvent]: isPast,
    [styles.currentEvent]: props.isCurrentEvent,
    [styles.focused]: props.isFocused,
    [styles.singleEvent]: props.isSingleEvent,
    'focusedNavigationNode': props.isFocused,
  });

  const properties = {
    style,
    className,
    onClick: handleClick,
  };

  const children = props.isVisible &&
      <EPGPageEventRender
        // key={ props.item.id }
          channel={ props.channel }
          blackouts={ props.blackouts }
          isCurrentEvent={ !!props.isCurrentEvent }
          isSingleEvent={ props.isSingleEvent }
          item={ props.item }
      />;

  return React.useMemo(() => (
      React.createElement(
        'div',
        properties,
        children,
      )
    ),
    [props.isCurrentEvent, props.isFocused, props.isVisible]
  );
};


export default React.memo(EPGPageEvent);
