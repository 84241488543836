import * as cn from 'classnames';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from "react-router-dom";

import { SimpleButton } from '~app/ui-kit/SimpleButton';
import escapeForRegex from '~lib/escapeForRegex';
import * as URLSearchManager from "~lib/URLQueryStringManager";

import * as styles from '../styles.module.css';


type Props = {
  value: string;
  query: string;
  isFocused: boolean;
  setQuery: (newQuery: string)=> void;
  setActiveSuggestion: Dispatch<SetStateAction<number>>
}

const SuggestionItem = (props: Props) => {
  const { value, query, setQuery, isFocused, setActiveSuggestion } = props
  const regexp = new RegExp(escapeForRegex(query), 'i');
  const result = value.replace(regexp,
    `<span class=${ styles.match }>$&</span>`,
  );
  const history = useHistory();

  const handleOnClick = () => {
    setQuery(value.toLowerCase());
    setActiveSuggestion(0)
    URLSearchManager.set({ query: encodeURI(value.toLowerCase()) }, history);
  }

  return (
    <div
      className={ cn(styles.name, { 'focusedNavigationNode': isFocused }) }
      onClick={ handleOnClick }
    >
      <SimpleButton isFocused={ isFocused } />
      <span dangerouslySetInnerHTML={ { __html: result } } />
    </div>
  );
};


export const MemoizedSuggestionItem = React.memo(SuggestionItem)
