import { ContinueWatchingType } from '~typings/ContinueWatchingType';
import ItemObject from '~typings/ItemObject';


const continueWatchingTypeByItemObject = {
  [ItemObject.Movie]: ContinueWatchingType.Movies,
  [ItemObject.Channel]: ContinueWatchingType.Channels,
  [ItemObject.AudioShow]: ContinueWatchingType.Parts,
  [ItemObject.Part]: ContinueWatchingType.Parts,
  [ItemObject.Series]: ContinueWatchingType.Episodes,
  [ItemObject.Episode]: ContinueWatchingType.Episodes,
};


export { continueWatchingTypeByItemObject };
