import * as React from 'react';
import { animated } from 'react-spring';

import { useScene } from '~components/Provider/Scene';
import ScrollBar from '~components/ScrollBar';
import useScroll from '~hooks/useScroll/useScroll';
import getImageSRC from '~lib/MediaItemImageSRC';
import preparingText from '~lib/preparingText';
import usePointer from '~stores/Pointer';
import ImageType from '~typings/ImageType';
import NavigationDirection from '~typings/NavigationDirection';
import News from '~typings/News';
import { Image } from '~ui/Image';

import NewsHeader from './NewsHeader';
import * as styles from './styles.module.css';


type Props = Readonly<{
  withFullInfo?: boolean;
  item: News;
}>;


const NewsPage: React.FC<Props> = (props: Props) => {
  const scene = useScene();
  const contentBlockRef = React.useRef<HTMLDivElement>(null);
  const {
    isScrollAvailable,
    scrollStyle,
    scrollPos,
  } = useScroll({ contentBlockRef, screenHeight: 600 });
  const flush = usePointer(state => state.flush);

  const imageUrl = React.useMemo(() => (
      getImageSRC({
        mediaItem: props.item,
        dimension: ImageType.BANNER_WIDE,
        width: 780,
        height: 438,
      })
  ), [props.item.id]);

  React.useEffect(() => {
    scene.changeSceneMediaItem(null, false, true);
    flush([
      NavigationDirection.Left,
      NavigationDirection.Right,
      NavigationDirection.Up,
      NavigationDirection.Down,
    ])
  }, []);

  return (
    <div className={ styles.wrapper }>
      <animated.div
        className={ styles.content }
        style={ scrollStyle }
        ref={ contentBlockRef }
      >
        {
          imageUrl ? (
              <Image
                src={ imageUrl }
                imageClassName={ styles.image }
                durationFadeIn={ 250 }
              />
          ) : null
        }
        <NewsHeader item={ props.item } />
        <div
          className={ styles.text }
          dangerouslySetInnerHTML={ { __html: preparingText(props.item.body) } }
        />
      </animated.div>
      {
        isScrollAvailable ? (
          <ScrollBar
            position={ scrollPos }
            className={ styles.scrollbar }
            isFocused
          />
        ) : null
      }
    </div>
  );
};


export default React.memo(NewsPage);
