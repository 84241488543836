import * as cn from 'classnames';
import * as React from 'react';

import { ActionType, useEnvAction } from '~components/Provider/Env';

import { cleanPathname } from './DevManager.helper';
import * as styles from './styles.module.css';

type AddApiProps = {
  focused: boolean;
}


const AddApi: React.FC<AddApiProps> = ({ focused }) => {
  const envAction = useEnvAction();
  const ref = React.useRef<HTMLInputElement>(null);
  const [editMode, setEditMode] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        ref.current?.focus();
      }, 100);
    }
  }, [ref.current]);

  const handleSubmit = () => {
    const value = ref.current?.value;

    if (value) {
      envAction({
        type: ActionType.AddAPI,
        api: `https://${cleanPathname(value)}`,
      });
    }

    setEditMode(false);
  };

  return (
    <div
      className={ cn(styles.item, {
        focusedNavigationNode: focused,
      }) }
      style={ {
        opacity: focused ? 1 : 0.5,
      } }
      onClick={ () => setEditMode(true) }
    >
      {
        editMode ? (
          <form
            onSubmit={ handleSubmit }
          >
            <input
              className={ styles.edit }
              ref={ ref }
              type="text"
              placeholder="Enter new API..."
            />
          </form>
        ) : '+ add API'
      }
    </div>
  )
};


export default AddApi;
