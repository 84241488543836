import focuser from '@focuser';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import ScrollableSliderBlock from '~components/Slider/scrollable';
import { useInfinityCastMembers } from '~hooks/fetch/useCastMembers';
import { usePaginationOneLine } from '~hooks/usePagination';
import { isChannel } from '~lib/objectType';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import { ROLE_ACTOR } from '~localization';
import CastMember from '~typings/CastMember';

import { CommonDetailItem } from '../types';
import ActorItem from './ActorItem/components';
import * as styles from './styles.module.css';

type Props = {
  item: CommonDetailItem;
};

export const ActorsBlockFocuser: React.FC<Props> = (props: Props): React.ReactElement | null => {
  const [slideWidth] = React.useState(() => valueInPixelsByWidth(14.688));
  const { query: castMembersQuery, parsedData: castMembers } = useInfinityCastMembers(
    isChannel(props.item) ? undefined : props.item.id,
  );

  const history = useHistory();

  const handleClick = (actor: CastMember) => {
    history.push(`/people/${actor.person.id}`);
  };


  const actors = React.useMemo(
    () => castMembers.filter((item) => item.role === 'actor'),
    [castMembers],
  );

  const [focusedIdx, setFocusedIdx] = React.useState(0);

  usePaginationOneLine({
    currentFetchedCount: actors.length,
    focusedElemIdx: focusedIdx,
    minimalPrefetchedCount: 10,
    updates: [castMembersQuery],
    fetchMoreData: () => {
      if (!castMembersQuery.isFetchingNextPage && castMembersQuery.hasNextPage) {
        castMembersQuery.fetchNextPage();
      }
    },
  });

  if (!actors.length) {
    return null;
  }

  return (
    <>
      <p className={ styles.title }>
        <FormattedMessage id={ ROLE_ACTOR } />
      </p>
      <ScrollableSliderBlock
        focusedIndex={ focusedIdx }
        slideWidth={ slideWidth }
      >
        <focuser.FocusableBlock
          className={ styles.blockWrap }
          isFocused
          onKeyLeft={ (ev) => {
            if (focusedIdx > 0) {
              setFocusedIdx(focusedIdx - 1);
              ev.stop();
            }
          } }
          onKeyRight={ (ev) => {
            if (focusedIdx < actors.length - 1) {
              setFocusedIdx(focusedIdx + 1);
              ev.stop();
            }
          } }
        >
          {actors.map((actor, i) => {
            return (
              <focuser.FocusableBlock
                isFocused={ focusedIdx === i }
                key={ i }
                onClick={ () => handleClick(actor) }
                className={ styles.actorFocuser }
                isLastBlock
              >
                {({ isFocused }) => (
                  <ActorItem
                    actor={ actor }
                    isFocused={ isFocused }
                  />
                )}
              </focuser.FocusableBlock>
            );
          })}
        </focuser.FocusableBlock>
      </ScrollableSliderBlock>
    </>
  );
};
