import cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';


export type Props = {
  name: string;
  isFocused: boolean;
};


const MediaItemName: React.FC<Props> = (props: Props) => (
  props.isFocused ? (
    <div className={ cn(styles.name, styles.focusedName) } >
      { props.name }
    </div>
  ) : (
    <div className={ cn(styles.name) } >
      { props.name }
    </div>
  )
);


export default React.memo(MediaItemName);
