// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".videoContainer--C1u6M {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background: transparent;\n  opacity: 1;\n}\n\n.playerRoot--b4s_7 {\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  width: 100%;\n  height: 100%;\n  z-index: 2;\n}\n\n.playerRoot--b4s_7.hidden--W8cwD {\n  display: none;\n}\n\n.loader--zOCfu {\n  font-size: 3em;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/blocks/playerPopcorn/Player/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;;EAEZ,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,MAAM;EACN,OAAO;;EAEP,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".videoContainer {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background: transparent;\n  opacity: 1;\n}\n\n.playerRoot {\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  width: 100%;\n  height: 100%;\n  z-index: 2;\n}\n\n.playerRoot.hidden {\n  display: none;\n}\n\n.loader {\n  font-size: 3em;\n}\n"],"sourceRoot":""}]);
// Exports
export var videoContainer = "videoContainer--C1u6M";
export var playerRoot = "playerRoot--b4s_7";
export var hidden = "hidden--W8cwD";
export var loader = "loader--zOCfu";
export default ___CSS_LOADER_EXPORT___;
