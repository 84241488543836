import focuser, { FocuserKeyHandler, useFocuserThrottledHandler } from '@focuser';
import * as React from 'react';
import { useCallback, useState } from 'react';

import { Feature, hasFeature } from '~app/global';
import { RecommendationsNewCards,RecommendationsOldCards } from '~components/Recommendations';
import Movie from '~typings/Movie';
import Series from '~typings/Series';


export interface Props {
  item: Movie | Series;
  onReady: (canNavigate: boolean) => void;
  className?: string;
}

export const RecommendationsFocuser: React.FC<Props> = ({ item, onReady, className }) => {
  const [focusedIdx, setFocusedIdx] = useState<number>(0);

  const [navLimit, setNavLimit] = useState<number>(0);

  const onKeyRight: FocuserKeyHandler = (ev) => {
    if (focusedIdx >= navLimit - 1) return;
    setTimeout(() => {
      setFocusedIdx(focusedIdx + 1);
    }, 0);
    ev.stop();
  };

  const onKeyLeft: FocuserKeyHandler = (ev) => {
    if (focusedIdx === 0) return;
    setTimeout(() => {
      setFocusedIdx(focusedIdx - 1);
    }, 0);
    ev.stop();
  };


  const onKeyLeftThrottled = useFocuserThrottledHandler(onKeyLeft);
  const onKeyRightThrottled = useFocuserThrottledHandler(onKeyRight);


  const handleRecsNav = useCallback(
    (num: number) => {
      onReady(num > 0);
      setNavLimit(num);
    },
    [onReady],
  );

  return (
    <focuser.FocusableBlock
      className={ className }
      onKeyRight={ onKeyRightThrottled }
      onKeyLeft={ onKeyLeftThrottled }
      isPointerLeftAvailable={ focusedIdx > 0 }
      isPointerRightAvailable={ focusedIdx < navLimit - 1 }
      isFocused
      noNeedForceFocus
      isForceFocusEnabledInBranch={ false }
    >
      {({ isFocused }) => (
        hasFeature(Feature.NewCards) ? (
          <RecommendationsNewCards
            item={ item }
            genres={ item.genres }
            isFocused={ isFocused }
            focusedItemIndex={ focusedIdx }
            setNavLimit={ handleRecsNav }
            onForceFocus={ setFocusedIdx }
          />
        ) :
        <RecommendationsOldCards
          item={ item }
          genres={ item.genres }
          isFocused={ isFocused }
          focusedItemIndex={ focusedIdx }
          setNavLimit={ handleRecsNav }
        />
      )}
    </focuser.FocusableBlock>
  );
};
