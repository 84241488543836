import { useQuery } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import AudioShowPart from '~typings/AudioShowPart';
import ResponseMany from '~typings/ResponseMany';


const PREFIX_OF_A_COMPOSITE_KEY = 'last_viewed_part';

type AudioShowPartsResponse = ResponseMany<AudioShowPart[]>;

const lastViewedPartKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,
  withOneAudioShowId: (accessToken: string | null, audioShowId?: string) => [
    lastViewedPartKeys.PREFIX,
    accessToken,
    audioShowId || 'empty-show-id',
  ],
};

const fetch = async (
  client: ApiClient,
  accessToken: string | null,
  audioShowId?: string,
): Promise<AudioShowPartsResponse> => {
  if (!audioShowId) {
    throw new Error(`audioShowId is missing`);
  }

  return client.get('/v1/user/next_parts.json', {
    access_token: accessToken,
    'filter[audio_show_id_in]': audioShowId,
    'expand[part]': `images,video_file`,
  });
};


export const useLastViewedAudioPart = (audioShowId?: string) => {
  const { accessToken } = getAuthData();
  const queryKey = lastViewedPartKeys.withOneAudioShowId(accessToken, audioShowId)
  const client = useClient();
  const query =  useQuery<AudioShowPartsResponse>({
    queryKey,
    queryFn: () => fetch(client, accessToken, audioShowId),
    enabled: Boolean(accessToken && audioShowId),
    cacheTime: 0 // Кэш не нужен, данные часто нужно перезагружать
  });


  return {
    query,
    nextPart: query.data?.data[0]
  }
};
