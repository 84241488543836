import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';

import { SearchPageBlocks } from "~components/SearchPage/SearchPage";
import useNavigationByKeys from "~hooks/useNavigation";
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import NavigationDirection from "~typings/NavigationDirection";

import * as styles from '../styles.module.css';
import { MemoizedSuggestionItem } from './SuggestionItem';


type Props = {
  isLoadingSuggestions: boolean;
  query: string;
  uniqSuggestions: { value: string }[]
  setQuery: (newQuery: string)=>void;
  isFocused: any
  switchNavigationBlock: (arg1: any, arg2: any) => void;
  setSuggestionPosition: Dispatch<SetStateAction<number>>
};

const SuggestionBlock = (props: Props) => {
  const {
    isLoadingSuggestions,
    query,
    uniqSuggestions,
    setQuery,
    isFocused,
    switchNavigationBlock,
    setSuggestionPosition,
  } = props

  const [activeSuggestion, setActiveSuggestion] = useState(0)

  const handleKeyNavigate = (direction): void => {
    if (!isFocused) {
      return
    }
    switch (direction) {
      case NavigationDirection.Up: {
        if (activeSuggestion === 0) {
          switchNavigationBlock(direction, SearchPageBlocks.Suggestions)
        }
        else {
          setActiveSuggestion(activeSuggestion - 1)
          setSuggestionPosition(activeSuggestion - 1)
        }
        break
      }
      case NavigationDirection.Right: {
        switchNavigationBlock(direction, SearchPageBlocks.Suggestions)
        break
      }
      case NavigationDirection.Down: {
        if (activeSuggestion < uniqSuggestions.length - 1) {
          setActiveSuggestion(activeSuggestion + 1)
          setSuggestionPosition(activeSuggestion + 1)
        }
        break;
      }
      case NavigationDirection.Left: {
        switchNavigationBlock(direction, SearchPageBlocks.Suggestions)
        break;
      }
    }
  }

  useNavigationByKeys({
    isMounted: isFocused,
    onNavigation: handleKeyNavigate,
  }, [isFocused, activeSuggestion]);

  return <>
    {
      isLoadingSuggestions
        ? (new Array(Math.floor(valueInPixelsByWidth(3.515625))))
          .fill(0)
          .map((_, i) => (
            <div key={ `suggestion-${ i }` } className={ styles.placeholder } />
          ))
        : null
    }

    {
      !isLoadingSuggestions
      && uniqSuggestions
        .map(({ value }, idx) => {

            return <MemoizedSuggestionItem
              key={ value }
              value={ value }
              query={ query }
              isFocused={ isFocused && activeSuggestion === idx }
              setQuery={ setQuery }
              setActiveSuggestion={ setActiveSuggestion }
            />
          }
        )
    }
  </>
};


export { SuggestionBlock }
