import Subscription from '~typings/Subscription';

export const isSubscriptionCancelable = (subscription?: Subscription) => {
  if (!subscription) {
    return false;
  }

  if (subscription.status === 'active' && !subscription.cancellable) {
    return false;
  }

  if (subscription.status !== 'active' && subscription.access_granted && subscription.expires_at) {
    return false;
  }

  return true;
};

export const SUBSCRIPTIONS_FOCUSED_SEARCH_PARAM = 'subscriptionsSearchparam';
