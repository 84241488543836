import * as React from 'react';
import { InjectedIntl,injectIntl } from 'react-intl';

import { useConfig } from '~global';
import {
  AuthActionType,
  PhoneCallConfirmationResult,
  PhoneCallPasswordResetMutationResult,
  useAuthMutation,
  usePhoneCallConfirmMutation,
  usePhoneCallPasswordResetMutation,
} from '~hooks/fetch/useAccount';
import { useInterval } from '~hooks/useInterval';

import { getMessageByError } from '../helpers';
import { formatPhoneNumber } from './helpers';
import * as styles from './styles.module.css';

export enum PhoneConfirmType {
  Registration = 'registration',
  PasswordReset = 'password_reset'
}

type PhoneConfirmationBlockProps = Readonly<{
  confirmType: PhoneConfirmType;
  username: string;
  password: string;

  onSuccess: () => void;
  onError: (errorMessage: string, error: any) => void;
  onProcessingStatus: (isProcessing: boolean) => void;

  intl: InjectedIntl;
}>;

const PhoneCallConfirmBlock: React.FC<PhoneConfirmationBlockProps> = (
  { confirmType, ...props }: PhoneConfirmationBlockProps
) => {
  const { startup: { login_form: { confirmation } } } = useConfig();
  const { mutate: signIn } = useAuthMutation();
  const { mutate: checkConfirmStatus } = usePhoneCallConfirmMutation();
  const { mutate: checkResetPasswordStatus } = usePhoneCallPasswordResetMutation();
  const phoneNumber = formatPhoneNumber(confirmation.phone_confirmation_number);



  const onError = (error) => {
    if (!props.onError) return;
      const messageText = getMessageByError(error, props.intl.formatMessage);
      props.onError(messageText, error);
  };

  const onConfirmSuccess = (responce: PhoneCallConfirmationResult | PhoneCallPasswordResetMutationResult) => {
    if (
      confirmType === PhoneConfirmType.Registration &&
      (responce as PhoneCallConfirmationResult).data?.confirmed !== true ||

      confirmType === PhoneConfirmType.PasswordReset &&
      (responce as PhoneCallPasswordResetMutationResult).data?.allowed !== true
    ) {
      return;
    }

    props.onProcessingStatus(true);

    if (confirmType === PhoneConfirmType.PasswordReset) {
      return props.onSuccess();
    }

    signIn({
      type: AuthActionType.AuthByPassword,
      payload: { username: props.username, password: props.password }
    }, {
      onSuccess: () => props.onSuccess(),
      onError,
      onSettled: () => props.onProcessingStatus(false),
    });
  };

  useInterval(() => {
      if (confirmType === PhoneConfirmType.Registration) {
        checkConfirmStatus(
          { username: props.username },
          {
            onSuccess: onConfirmSuccess,
            onError,
          },
        );
      } else {
        checkResetPasswordStatus(
          { username: props.username },
          {
            onSuccess: onConfirmSuccess,
            onError,
          },
        );
      }
  }, 5000);

  React.useEffect(() => () => props.onProcessingStatus(false), []);

  return (
    <div className={ styles.phoneNumber }>
      { phoneNumber }
    </div>
  );
};


export default injectIntl(PhoneCallConfirmBlock);
