import { get } from 'lodash';

import { DRMType } from '~typings/Drm';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Protocol from '~typings/Protocol';


const getRightHolder = (item: PlayerMediaItem) => {
  return (item.object === ItemObject.Episode) ?
    get(item, 'series.right_holder.name')
    :
    get(item, 'right_holder.name');
}

const getItemName = (item: PlayerMediaItem) => {
  return (item.object === ItemObject.Episode) ?
    get(item, 'series.name', '').toLowerCase()
    :
    get(item, 'name', '').toLowerCase();
}

const getAllowedProtocols = (item: PlayerMediaItem): Protocol[] | null => {
  if (
    item.object === ItemObject.Movie
    || item.object === ItemObject.Episode
  ) {
    return item.video_file.allowed_protocols;
  }

  if (item.object === ItemObject.Channel) {
    return item.live_stream.allowed_protocols;
  }

  return null;
};

const getAllowedDRMs = (item: PlayerMediaItem): DRMType[] => {
  if (
    item.object === ItemObject.Movie
    || item.object === ItemObject.Episode
    || item.object === ItemObject.ExtraVideo
  ) {
    return item.video_file?.allowed_drms || [DRMType.SPbTVCas, DRMType.PlayReady];
  }

  if (item.object === ItemObject.Channel) {
    return item.live_stream.allowed_drms || [DRMType.SPbTVCas, DRMType.PlayReady];
  }
  if (item.object === ItemObject.ProgramEvent) {
    return [DRMType.SPbTVCas, DRMType.PlayReady];
  }

  return [DRMType.SPbTVCas, DRMType.PlayReady];
};


export {
  getAllowedDRMs,
  getAllowedProtocols,
  getItemName,
  getRightHolder,
}
