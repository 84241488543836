import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import shallow from 'zustand/shallow';

import HorizontalPointerNavigation from '~components/PointerManager/HorizontalPointerNavigation';
import useScrollable, { ScrollDirection } from '~hooks/useScrollable/useScrollable';
import { getOpenedPopupName, setPopupState } from '~lib/PopupState';
import { getSceneWidth } from '~lib/SizesInPX';
import useFilters from '~stores/Filters';
import useNavigationOnLayer from "~stores/LayerNavigation";
import usePointer from '~stores/Pointer';
import DropdownFilter, { Option } from '~typings/DropdownFilter';
import QuickFilter from '~typings/QuickFilter';
import { PilsButton } from '~ui/PilsButton';

import FilterDropdown from './FilterDropdown';
import * as styles from './styles.module.css';


type Props = {
  filterGroup?: (QuickFilter | DropdownFilter)[];
  isFocused: boolean;
  focusedIndex: number;
  ID: string;
  withReset?: boolean;
  intl: InjectedIntl;
  onHover?: (arg: number) => void;
  onClick?: () => void;
};

/**
 * @deprecated, используй FiltersLine
 */
const Filters: React.FC<Props> = (props) => {
  const { filterGroup, isFocused, focusedIndex, withReset } = props;
  const selectedFilters = useFilters(state => ({
    quickFilters: state.quickFilters?.[props.ID],
    dropdownFilters: state.dropdownFilters?.[props.ID],
  }), shallow);
  const setSelectedFilters = useFilters(state => state.setFilters);
  const history = useHistory();
  const isPopupNotOpened = !getOpenedPopupName(history);
  const [dropdown, setDropdown] = React.useState<DropdownFilter | null>(null);
  // const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const pointerEnabled = usePointer(state => state.pointerEnabled);

  const setFocusPosition = useNavigationOnLayer(state => state.setFocusPosition);
  const focusOn = useNavigationOnLayer(React.useCallback(
    state => (state.focusHistory[props.ID] || {})?.focusOn ?? 0,
    [props.ID],
  ));


  React.useEffect(() => {
    if (isPopupNotOpened) {
      setDropdown(null);
    }
  }, [isPopupNotOpened]);

  // const handleHover = React.useCallback((idx: number) => {
  //   if (pointerEnabled && !isHovered) {
  //     setIsHovered(true);
  //     if (typeof props.onHover === 'function') {
  //       props.onHover(idx);
  //     }
  //   }
  // }, [pointerEnabled, focusedIndex, props.onHover, isHovered]);
  // const handleLeave = React.useCallback(() => {
  //   if (pointerEnabled && isHovered) {
  //     setIsHovered(false);
  //   }
  // }, [pointerEnabled, isHovered]);

  const handleQuickFilter = (filter: QuickFilter) => {
    setSelectedFilters(props.ID, filter);
    setFocusPosition(props.ID, [focusOn, 0]);
  }

  const handleDropdown = (filter: DropdownFilter) => {
    setDropdown(filter);
    setPopupState(history, 'dropdown');
    setFocusPosition(props.ID, [focusOn, 0]);
  }

  const handleDropdownClose = (options: Option[]) => {
    if (dropdown) {
      setSelectedFilters(props.ID, {
        ...dropdown,
        options,
      });
    }
    setDropdown(null);
    setPopupState(history, null);
  }

  const dropdownName = React.useCallback((filter: DropdownFilter) => {
    const filterInSelected = (selectedFilters?.dropdownFilters || [])
      .find(x => x?.id === filter.id);
    if (
      filterInSelected
      && filter.api_param.includes('year')
    ) {
      return filterInSelected.options[0].name;
    }

    return filter.name;
  }, [selectedFilters?.dropdownFilters]);

  const [ref, scrollable] = useScrollable({
    focusedIndex,
    wrapperSize: getSceneWidth(),
    direction: ScrollDirection.X,
  });

  const selectedFilterById = React.useCallback((id) => {
    if (selectedFilters?.dropdownFilters) {
      return selectedFilters?.dropdownFilters.find(filter => id === filter.id)?.options
    }
  }, [selectedFilters])

  return (
    <>
      <div ref={ ref } className={ styles.filters }>
        {
          withReset && (selectedFilters.quickFilters !== undefined || selectedFilters.dropdownFilters !== undefined)
            ? <PilsButton
              onClick={ () => setSelectedFilters(props.ID) }
              // onHover={ () => handleHover(0) }
              // onLeave={ handleLeave }
              isChecked={ !selectedFilters?.quickFilters && !selectedFilters?.dropdownFilters }
              isFocused={ isFocused && focusedIndex === 0 }
              className={ styles.closeButton }
              isCloseButton
            />
            : null
        }
        {
          (filterGroup || []).map((filter: QuickFilter | DropdownFilter, idx) =>
            filter.object === 'quick_filter'
              ? <PilsButton
                key={ filter.id }
                isChecked={ !!(selectedFilters?.quickFilters || []).find(x => x?.id === filter.id) }
                onClick={ () => handleQuickFilter(filter) }
                // onHover={ () => handleHover(idx + (withReset ? 1 : 0)) }
                // onLeave={ handleLeave }
                isFocused={ !dropdown
                  && isFocused
                  && idx + (withReset && (selectedFilters.quickFilters !== undefined
                    || selectedFilters.dropdownFilters !== undefined) ? 1 : 0) === focusedIndex
                }
                className={ styles.pils }
              >
                { filter.name }
              </PilsButton>
              : <PilsButton
                key={ filter.id }
                isDropdown
                filterId={ filter.id }
                selectedFilters={ selectedFilterById(filter.id) }
                isChecked={ !!(selectedFilters?.dropdownFilters || []).find(x => x?.id === filter.id) }
                onClick={ () => handleDropdown(filter) }
                // onHover={ () => handleHover(idx + (withReset ? 1 : 0)) }
                // onLeave={ handleLeave }
                isFocused={ !dropdown && isFocused
                  && idx + (withReset && (selectedFilters.quickFilters !== undefined
                    || selectedFilters.dropdownFilters !== undefined) ? 1 : 0) === focusedIndex }
                className={ styles.pils }
              >
                { dropdownName(filter) }
              </PilsButton>
          )
        }
      </div>
      {
        pointerEnabled && isFocused && scrollable
          ? <HorizontalPointerNavigation
            isLeftEnabled={ focusedIndex > 0 }
            isRightEnabled={ focusedIndex < (filterGroup || []).length - (withReset ? 0 : 1) }
            classNames={ { left: styles.pointerLeft, right: styles.pointerRight } }
          />
          : null
      }
      {
        dropdown
          ? <FilterDropdown
            dropdown={ dropdown }
            onClose={ handleDropdownClose }
            selectedFilters={ selectedFilters?.dropdownFilters }
          />
          : null
      }
    </>
  );
};

/**
 * @deprecated, используй FiltersLine
 */
export default React.memo(injectIntl(Filters));
