import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Fieldset from '~components/Fieldset';
import LightLoader from '~components/LightLoader';
import CatchBack from '~components/PageManager/CatchBack';
import { ActionType, useAppAction } from '~components/Provider/App';
import URLVariableState from '~lib/URLVariableState';
import { PROMOCODE_ACTIVATION } from '~localization';

import PromocodeContentList from './screens/PromocodeContentList';
import PromocodeInputForm from './screens/PromocodeInputForm';
import PromocodeRentPlanConfirm from './screens/PromocodeRentPlanConfirm/PromocodeRentPlanConfirm';
import PromocodeRentPlanPage from './screens/PromocodeRentPlanPage';
import PromocodeSubscrField from './screens/PromocodeSubscrField';
import PromocodeSubscrConfirm from './screens/PromocodeSubscribeConfirm/PromocodeSubscrConfirm';
import PromocodeSuccessPage from './screens/PromocodeSuccess'
import usePromocodeHelper from './usePromocodeHelper';

export enum PromocodeActivationPages {
  PromocodeInput = 'promocode_input',
  SubscriptionsField = 'subscriptions_field',
  ProductsList = 'products_list',
  SuccessPage = 'success_page',
  SubscriptionConfirm = 'subscription_confirm',
  RentPlan = 'rent_plan',
  RentPlanConfirm = 'rent_plan_confirm',
}

export type PromocodeSuccessData = {
  isSingleRentPlan?: boolean;
  isSingleProduct?: boolean;
};

type Props = Readonly<{
  page: PromocodeActivationPages;
  onSuccess?: (params?: PromocodeSuccessData) => void;
  onReset?: () => void;
  intl: InjectedIntl;
}>;

const PromocodeActivation: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const appAction = useAppAction();

  const [isProcessing, setProcessingStatus] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string|null|undefined>();
  const promocode = React.useRef(URLVariableState<string>(history, 'promo_code', '')).current;
  const promocodeValue = promocode.value();
  const productId = React.useRef(URLVariableState<string|undefined>(history, 'productId', undefined)).current;
  const rentPlanId = React.useRef(URLVariableState<string|undefined>(history, 'rentPlanId', undefined)).current;
  const {
    data: promoData,
    checkAndFetch: checkAndFetchPromo,
    reset: resetPromo,
    errorMessage: promoError,
    isLoading: isPromoLoading
  } = usePromocodeHelper(props.intl);

  const isLoaderVisible = isPromoLoading || isProcessing;

  let contentComponent;

  React.useEffect(() => {
    appAction({
      type: ActionType.SetIsLaunchingDone,
      payload: { isLaunchingDone: true },
    });

    (async () => {
      if (promocodeValue) {
        const promo = await checkAndFetchPromo(promocodeValue);
        if (
          promo?.promocode?.available !== true ||
          (!promo?.products?.length && !promo?.rentPlans?.length)
        ) {
          backToStartPage();
        }
      }
    })();
  }, []);

  React.useEffect(() => {
    resetPromo();
    setErrorMessage(null);

    if (props.page === PromocodeActivationPages.PromocodeInput) {
      if (productId.value()) productId.set(undefined);
      if (rentPlanId.value()) rentPlanId.set(undefined);
    }
  }, [props.page]);

  const successHandler = async (newPromocode?: string, contentType?: string) => {
    if (!props.onSuccess) return;

    if (props.page === PromocodeActivationPages.ProductsList) {
      if(contentType && contentType === 'base') {
        return props.onSuccess({ isSingleProduct: true});
      } else {
        return props.onSuccess({ isSingleRentPlan: true });
      }
    }

    if (props.page === PromocodeActivationPages.SubscriptionConfirm) {
      return props.onSuccess();
    }

    if(props.page === PromocodeActivationPages.RentPlanConfirm) {
      return props.onSuccess();
    }

    const promo = await checkAndFetchPromo(newPromocode ?? promocodeValue);

    if (promo?.promocode?.available !== true) return;

    if (newPromocode) {
      promocode.set(newPromocode);
    }

    if (promo?.rentPlans?.length) {
      const isSingleRentPlan = promo.rentPlans.length === 1;
      if (isSingleRentPlan) {
        rentPlanId.set(promo.rentPlans[0].id);
      }
      return props.onSuccess();
    } else if (promo?.products?.length) {
      const isSingleProduct = promo.products.length === 1;
      if (isSingleProduct) {
        productId.set(promo.products[0].id);
      }
      return props.onSuccess({ isSingleProduct });
    }
  };

  const backToStartPage = () => {
    if (props.onReset) {
      props.onReset();
    }
    return null;
  }

  switch(props.page) {
    case PromocodeActivationPages.PromocodeInput: {
      contentComponent = (
        <PromocodeInputForm
          promocode={ promocodeValue }
          onSubmit={ successHandler }
        />
      );
      break;
    }
    case PromocodeActivationPages.SubscriptionsField: {
      if (!promocodeValue) return backToStartPage();
      contentComponent = (
        <PromocodeSubscrField
          promocode={ promocodeValue }
          onClick={ successHandler }
        />
      );
      break;
    }
    case PromocodeActivationPages.ProductsList: {
      if (!promocodeValue) return backToStartPage();
      contentComponent = promoData ? (
        <PromocodeContentList
          promocode={ promocodeValue }
          promo={ promoData }
          onSelect={ (id: string, type: string) => {
            if (type === 'base') {
              productId.set(id);
            } else {
              rentPlanId.set(id);
            }
            successHandler(promocodeValue, type);
          } }
        />
      ) : null;
      break;
    }
    case PromocodeActivationPages.SubscriptionConfirm: {
      const productIdValue = productId.value();
      if (!promocodeValue || !productIdValue) return backToStartPage();
      contentComponent = promoData ? (
        <PromocodeSubscrConfirm
          promocode={ promocodeValue }
          productId={ productIdValue }
          promo={ promoData }
          onSuccess={ successHandler }
          onError={ setErrorMessage }
          isProcessing={ isProcessing }
          setProcessingStatus={ setProcessingStatus }
          needToReset={ backToStartPage }
          // setSelectedPlan={ setSelectedPlan }
        />
      ) : null;
      break;
    }
    case PromocodeActivationPages.RentPlan: {
      const rentPlanIdValue = rentPlanId.value();
      if (!promocodeValue || !rentPlanIdValue) return backToStartPage();
      contentComponent = (
        <PromocodeRentPlanPage
          rentPlanId={ rentPlanIdValue || '' }
          promo={ promoData }
        />
      );
      break;
    }
    case PromocodeActivationPages.RentPlanConfirm: {
      const rentPlanIdValue = rentPlanId.value();
      if (!promocodeValue || !rentPlanIdValue) return backToStartPage();
      contentComponent = promoData ? (
        <PromocodeRentPlanConfirm
          promocode={ promocodeValue }
          promo={ promoData }
          rentPlanId={ rentPlanIdValue }
          isProcessing={ isProcessing }
          onSuccess={ successHandler }
          onError={ setErrorMessage }
          setProcessingStatus={ setProcessingStatus }
        />
      ) : null
      break;
    }
    case PromocodeActivationPages.SuccessPage: {
      contentComponent = (
        <PromocodeSuccessPage
          onSuccess={ successHandler }
        />
      );
      break;
    }
    default:
      return backToStartPage();
  };

  const handleBack =() => {
    if (isProcessing) {
      setProcessingStatus(false);
      return;
    }

    if (props.page === PromocodeActivationPages.SuccessPage) {
      return;
    }

    history.goBack();
  };

  return (
    <div>
      { isLoaderVisible && <LightLoader /> }
      <CatchBack
        action={ handleBack }
        triggers={ [isProcessing, props.page] }
      />
      {
        (props.page === PromocodeActivationPages.RentPlan
        || props.page === PromocodeActivationPages.SubscriptionConfirm
        || props.page === PromocodeActivationPages.SuccessPage
        || props.page === PromocodeActivationPages.ProductsList
        || props.page === PromocodeActivationPages.RentPlanConfirm)
        ?
          <>
            { contentComponent }
          </>
        :
          <Fieldset
            headerText={ props.intl.formatMessage({ id: PROMOCODE_ACTIVATION }) }
            errorText={ promoError ?? errorMessage }
          >
            { contentComponent }
          </Fieldset>
      }
    </div>
  );
}

export default injectIntl(PromocodeActivation);
