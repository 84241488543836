import cn from "classnames";
import React, { useLayoutEffect, useMemo, useRef } from "react";

import { FocuserContext, useFocuserContext, WrongNestingContext } from "./context";
import { FOCUSABLE_DATA_ATTRIBUTE,FOCUSABLE_DATA_ATTRIBUTES } from "./data-attributes";
import {
  AvailablePointerDirections,
  CustomEventCallbacks,
  useCustomEventsHandler,
  useErrorsLogger,
  useForceFocusEmitter,
  usePointerEventEmitter,
} from './hooks';

type ChildrenProps = {
  /**
   * Сфокусирован ли блок
   * Будет `true`, когда `props.isFocused === true`, и в конексте тоже `previousContext.isFocused === true`
   */
  isFocused: boolean;
};

export type FocusableBlockProps = CustomEventCallbacks & AvailablePointerDirections & {
  /**
   * Дочерние блокиб (тут можно передавать любые компоненты,
   * в том числе другие FocusableBlock-и)
   */
  children?: React.ReactNode | ((props: ChildrenProps) => React.ReactNode);
  /**
   * Сфкоусирован ли блок
   * @default false
   */
  isFocused: boolean;
  /**
   * Возможен ли force-focus по ветке, которая начинается с этого блока
   * @default true
   */
  isForceFocusEnabledInBranch?: boolean;
  /**
   * Управлять ли указателями в этой ветке, которая начинается с этого блока
   * @default true
   */
  isPointerEnabledInBranch?: boolean;
  /**
   * Если в ветке требуется force-focus, но этот блок не нуждается в нем,
   * то этот параметр должен быть `true` вместо передачи `isForceFocusEnabledInBranch`
   */
  noNeedForceFocus?: boolean;
  /**
   * Класс CSS, который будет применяться к блоку
   */
  className?: string;
  /**
   * Класс CSS, который будет применяться к блоку, когда блок сфокусирован
   */
  focusedClassName?: string;

  /**
   * Стиль CSS, который будет применяться к блоку
   */
  style?: React.CSSProperties;

  /**
   * Пропсы для lastElement-а в ветке
   */

  /**
   * Последний ли блок в ветке
   * Если да, то контекст дальше не будет создаваться
   */
  isLastBlock?: boolean;

  /**
   * Создавать событие forceFocus при наведении мышкой на блок
   */
  emitForceFocusOnHover?: boolean;

  /**
   * Если нужно иметь доступ к div элементу внутри блока, то можно передать ref
   */
  forwardRef?: React.MutableRefObject<HTMLElement | null>
};

export const FocusableBlock: React.FC<FocusableBlockProps> = (props) => {
  const blockRef = useRef<HTMLDivElement>(null);

  const previousContext = useFocuserContext();
  const isCurrentBlockFocused = Boolean(previousContext.isFocused && props.isFocused);
  const isCurrentBlockForceFocusEnabled = Boolean(
    previousContext.isForceFocusEnabled && props.isForceFocusEnabledInBranch !== false,
  );
  const isCurrentBlockPointerEnabled = Boolean(
    previousContext.isPointerEnabled && props.isPointerEnabledInBranch !== false,
  );


  const emitForceFocus = Boolean(props.emitForceFocusOnHover) && isCurrentBlockForceFocusEnabled;

  // TODO: Переделать NODE_ENV на другую переменную, когда будет
  if(process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useErrorsLogger({
      ...props,
      ref: blockRef,
      noNeedForceFocus: props.noNeedForceFocus,
      isCurrentBlockForceFocusEnabled,
    });
  }

  useCustomEventsHandler(props, {
    htmlElementRef: blockRef,
    isFocusedFromProps: props.isFocused,
    isFocusedFinnally: isCurrentBlockFocused,
  });

  useForceFocusEmitter({
    htmlElementRef: blockRef,
    emitForceFocus
  });

  usePointerEventEmitter({
    ...props,
    isCurrentBlockFocused,
    isCurrentBlockPointerEnabled,
  });


  useLayoutEffect(()=> {
    if(props.forwardRef && blockRef.current){
      props.forwardRef.current = blockRef.current
    }
  }, [blockRef.current]);

  const nextContext: FocuserContext = useMemo(
    () => ({
      isFocused: isCurrentBlockFocused,
      isForceFocusEnabled: isCurrentBlockForceFocusEnabled,
      isPointerEnabled: isCurrentBlockPointerEnabled,
    }),
    [isCurrentBlockFocused, isCurrentBlockForceFocusEnabled, isCurrentBlockPointerEnabled]
  );

  const dataAttributes: FOCUSABLE_DATA_ATTRIBUTES = {
    [FOCUSABLE_DATA_ATTRIBUTE]: isCurrentBlockFocused ? 'true' : 'false',
  };


  const renderChildren = () => {
    if (typeof props.children === 'function') {
      return props.children({
        isFocused: isCurrentBlockFocused,
      });
    }
    return props.children;
  };


  const renderNext = () => {
    if (props.isLastBlock) {
      return renderChildren();
    }
    return <FocuserContext.Provider value={ nextContext }>{renderChildren()}</FocuserContext.Provider>;
  };



  const renderDiv = () => (
    <div
      className={ cn(
        props.className,
        props.focusedClassName && {
          [props.focusedClassName]: isCurrentBlockFocused,
        },
      ) }
      style={ props.style }
      ref={ blockRef }
      { ...dataAttributes }
    >
      { renderNext() }
    </div>
  );

  // TODO: Переделать NODE_ENV на другую переменную, когда будет
  if (process.env.NODE_ENV === 'development') {
    if (props.isLastBlock) {
      return (
        <WrongNestingContext.Provider value={ true }>{renderDiv()}</WrongNestingContext.Provider>
      );
    }
  }

  return renderDiv();
};
