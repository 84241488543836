import cn from 'classnames';
import * as React from 'react';

import usePassword from '~hooks/usePassword';
import NavigationDirection from '~typings/NavigationDirection';
import IconEyeOff from '~ui/Icon/icons/IconEyeOff';
import IconEyeOn from '~ui/Icon/icons/IconEyeOn';
import { NavigationHelper } from '~ui/NavigationHelper';

import * as styles from './styles.module.css';

type Props = Readonly<{
  password,
  isFocused,
  onLeave: (dir: NavigationDirection) => void;
}>;

const PasswordField: React.FC<Props> = (props: Props) => {
  const [isShowingPassword, setIsShowingPassword] = React.useState(false);
  const password = usePassword({ password: props.password, isShowingPassword })

  const handleEyeClick = () => {
    setIsShowingPassword(!isShowingPassword);
  };

  const EyeIcon = isShowingPassword ? IconEyeOff : IconEyeOn;

  return (
    <div
      className={ cn(styles.input, {
        [ styles.passwordHide ]: !isShowingPassword,
        focusedNavigationNode: props.isFocused
      }) }
      onClick={ handleEyeClick }
    >
      { props.isFocused && (
        <NavigationHelper
          isFocused={ props.isFocused }
          onLeave={ props.onLeave }
        />
      )}

      { password }
      <EyeIcon
        className={ cn(
          styles.passwordEyeIcon,
          { [styles.focused]: props.isFocused }
        ) }
      />
    </div>
  )
}

export default PasswordField;
