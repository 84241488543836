import * as cn from 'classnames';
import { throttle } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { ActionType, useMenu, useMenuAction } from '~components/Provider/Menu';
import useNavigation, { pageWeComeFromTS } from "~stores/Navigation";
import useNavMenu from '~stores/NavMenu';
import usePointer from '~stores/Pointer';
import Image from '~typings/Image'

import type { LineFocusInfo } from '../component.helpers';
import * as restLineCSS from './styles.module.css'

interface Props {
  id: string;
  title?: string;
  link?: string;
  isFocused?: boolean;
  isActive?: boolean;
  isAuthorized: boolean | null;
  onClick?: (coordinates: LineFocusInfo) => void;
  index: number;
  lineIndex: number;
  image: Image | undefined;
}

const LineItem = (props: Props) => {
  const { isMenuOpened, isPopupOpened } = useMenu();
  const menuAction = useMenuAction();
  const history = useHistory();
  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const setHovered = useNavMenu(state => state.setHovered);
  const setPageWeComeFrom = useNavigation(state => state.setPageWeComeFrom)

  const handleHover = React.useCallback(throttle<any>(() => {
    if (pointerEnabled) {
      if (!isMenuOpened && !isPopupOpened) {
        menuAction({
          type: ActionType.ChangeMenuState,
          isMenuOpened: true,
        });
      }

      setHovered(true);
    }
  }, 200, { leading: true }), [isMenuOpened, isPopupOpened, pointerEnabled]);

  const handleClick = React.useCallback(() => {
    if (props.link) {
      menuAction({
        type: ActionType.ChangeMenuState,
        isMenuOpened: false,
      });

      setPageWeComeFrom(pageWeComeFromTS.menu)

      history.push(props.link);
    }
  }, [props.link, props.index, props.lineIndex]);

  return (
    <div
      className={ cn(restLineCSS.LineItem, {
        focusedNavigationNode: props.isFocused,
        [restLineCSS.lineItemFocused]: props.isFocused,
      }) }
      onClick={ handleClick }
      onMouseOverCapture={ handleHover }
    >
      { props.title }
    </div>
  );
};


export const RestLineItem = React.memo(LineItem);
