import { useEffect } from 'react';
import create from 'zustand';

import { useApp } from '~components/Provider/App';
import { usePageById } from '~hooks/fetch/usePages/usePages';
import { useSetStore } from "~hooks/useSetStore";
import useNavigation from '~stores/Navigation';


type Props = Readonly<{
  accessToken: string | null;
  pageID: string | null;
}>;


const usePageManagerHelper = (props: Props) => {
  const { isLaunchingDone } = useApp();
  const currentPageID = useNavigation(state => state.currentPage?.id);
  const setCurrentPage = useNavigation(state => state.setCurrentPage);

  useSetStore();

  const nextPage = usePageById(props.pageID);

  useEffect(() => {
    if (isLaunchingDone && currentPageID !== props.pageID) {
      if (nextPage) {
        setCurrentPage(nextPage);
      }
    }
  }, [isLaunchingDone, currentPageID, props.pageID, nextPage]);
};


type PageManagerPointersBlock = {
  blockedCount: number;
  increment: () => void;
  decrement: () => void;
}

const usePageManagerPointersBlockState = create<PageManagerPointersBlock>((set) => ({
  blockedCount: 0,
  increment: () => set((state) => ({ blockedCount: state.blockedCount + 1 })),
  decrement: () => set((state) => ({ blockedCount: state.blockedCount - 1 })),
}));


export const useIsPageManagerPointersBlocked = () => {
  const isBlocked = usePageManagerPointersBlockState(s=>s.blockedCount > 0);
  return isBlocked;
}

/**
 * Хук отключает логику в `PageManager`, а при размонтировании включает.
 *
 * Еще в старой навигации есть `CatchBack`, который выставляет кнопку `возврата` как `назад`.
 * Этот хук также отключи и эту логику.
 *
 * @warning
 * Кнопки возврата нужно выставить самостоятельно через `returnButtonType` в focuser-е.
 */
export const usePageManagerPointersBlocker = (isBlocked = true) => {
  useEffect(() => {
    if (!isBlocked) {
      return;
    }
    usePageManagerPointersBlockState.getState().increment();
    return () => usePageManagerPointersBlockState.getState().decrement();
  }, [isBlocked]);
};


export default usePageManagerHelper;
