import * as React from 'react';

import SideBarItem from '~components/SideBar/item';
import usePointerNavigation from '~hooks/usePointerNavigation/usePointerNavigation';
import MenuItem from '~typings/MenuItem';

import * as styles from './styles.module.css';


type Props = Readonly<{
  items: MenuItem[];
  isFocused: boolean;
  activeIndex: number | null;
  focusedIndex: number;
}>;


/**
 * @deprecated
 * старый компонент,используй `SidebarWithTabs`
 */
const SideBar: React.FC<Props> = (props: Props) => {
  usePointerNavigation({
    allowNavigation: props.isFocused,
    itemsCount: props.items.length,
    focusOn: props.focusedIndex,
  });

  return (
    <ul className={ styles.menu2 }>
      {
        props.items.map((item, index) => (
          <SideBarItem
            key={ item.id }
            index={ index }
            item={ item }
            isActive={ (props.isFocused && props.activeIndex === index) }
            isFocused={ (props.focusedIndex === index) }
          />
        ))
      }
    </ul>
  );
};


export default SideBar;
