// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".block--L884F {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  width: 100%;\n}\n\n.block--L884F .text--guRdp {\n    /* 6px 0 */\n    margin: 0.46875vw 0;\n    color: rgba(255, 85, 85, 1);\n  }\n\n.block--L884F .textWrap--FsYtd {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  }\n\n.icon--ZzfLi {\n  /* 28.8px */\n  width: 2.25vw;\n\n  /* 28.8px */\n  height: 2.25vw;\n\n  -webkit-flex-shrink: 0;\n\n          flex-shrink: 0;\n  color: rgba(255, 85, 85, 1);\n\n  /* 16px */\n  margin-right: 1.25vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/ui-kit/WarningTextlineBlock/WarningTextlineBlock.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,WAAW;AAab;;AAXE;IACE,UAAU;IACV,mBAAmB;IACnB,2BAAc;EAChB;;AAEA;IACE,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;EACrB;;AAGF;EACE,WAAW;EACX,aAAa;;EAEb,WAAW;EACX,cAAc;;EAEd,sBAAc;;UAAd,cAAc;EACd,2BAAc;;EAEd,SAAS;EACT,oBAAoB;AACtB","sourcesContent":[".block {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n\n  .text {\n    /* 6px 0 */\n    margin: 0.46875vw 0;\n    color: $adc_01;\n  }\n\n  .textWrap {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  }\n}\n\n.icon {\n  /* 28.8px */\n  width: 2.25vw;\n\n  /* 28.8px */\n  height: 2.25vw;\n\n  flex-shrink: 0;\n  color: $adc_01;\n\n  /* 16px */\n  margin-right: 1.25vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var block = "block--L884F";
export var text = "text--guRdp";
export var textWrap = "textWrap--FsYtd";
export var icon = "icon--ZzfLi";
export default ___CSS_LOADER_EXPORT___;
