import * as React from 'react';

import usePrevious from '~hooks/usePrevious';
import LineDelayScrollFade from '~ui/LineDelayScroll/fade';
import LineDelayScrollSlide from '~ui/LineDelayScroll/slide';


type Props = Readonly<{
  lines: any[];
  focusedLine: number;
  showAlert?: boolean;
}>;


const LineDelayScroll: React.FC<Props> = (props: Props) => {
  const { showAlert = false } = props
  const prevFocusedLine = usePrevious(props.focusedLine);
  const isDirectionUp: boolean = (prevFocusedLine > props.focusedLine);

  return (
    <>
      {
        props.lines.map((line: any, index: number) => (
          <LineDelayScrollFade
            key={ index }
            index={ index }
            isDirectionUp={ isDirectionUp }
            focusedLine={ props.focusedLine }
          >
            <LineDelayScrollSlide
              showAlert={ showAlert }
              index={ index }
              isDirectionUp={ isDirectionUp }
              focusedLine={ props.focusedLine }
              prevFocusedLine={ prevFocusedLine }
            >
              { line }
            </LineDelayScrollSlide>
          </LineDelayScrollFade>
        ))
      }
    </>
  );
};


export default React.memo(LineDelayScroll);
