import * as cn from 'classnames';
import * as React from 'react';

import usePassword, { hiddenChar } from '~hooks/usePassword';

import * as styles from './styles.module.css';

type Props = Readonly<{
  value: string;
  className?: string;
  showPinCode: boolean;
}>;

export const PinInput: React.FC<Props> = ({ value, className, showPinCode }: Props) => {
  const codeValue = usePassword({ password: value, isShowingPassword: showPinCode });
  const pin = '****'.split('').map((_, i) => codeValue[i]);
  const currentCursorIdx = value.length;

  const renderCharContent = (char: string, index: number) => {
    if (index === currentCursorIdx) {
      return <div className={ styles.cursor } />;
    }

    if (char === hiddenChar) {
      return <div className={ styles.hiddenChar } />;
    }

    if (char) {
      return char;
    }

    return '';
  };

  return (
    <div className={ cn(styles.input, className) }>
      {pin.map((char, index) => (
        <div
          className={ styles.char }
          key={ `pinchar_${index}` }
        >
          {renderCharContent(char, index)}
        </div>
      ))}
    </div>
  );
};
