

import { DataItem } from '~typings/DataItem';
import ImageType from '~typings/ImageType';
import ItemObject from '~typings/ItemObject';
import MediaItemDimension from '~typings/MediaItemDimension';
import SeeAll from '~typings/SeeAll';


type Props = Readonly<{
  top?: number;
  left?: number;
  imageURL: string | null;
  item: DataItem | SeeAll | undefined;
  dimension: MediaItemDimension;
}>;


const getMediaItemWrapperStyles = (props: Props): React.CSSProperties => {
  const isLogoChannelItem = (
    props?.item?.object === ItemObject.Channel
    && (
      props.dimension === ImageType.LOGO
      || props.dimension === ImageType.LOGO_SMALL
    )
  );
  // const isChannelCardWide = (
  //   props?.item?.object === ItemObject.Channel
  //   && props.dimension === ImageType.CHANNEL_CARD_WIDE
  // );
  const result: React.CSSProperties = {};

  (!isLogoChannelItem && props.imageURL) && (result.backgroundImage = `url("${props.imageURL}")`);
  // (!isChannelCardWide) && (result.backgroundSize = '102%');
  result.left = `${props.left || 0}px`;
  result.top = `${props.top || 0}px`;
  // const translate = `translate3d(${
  //   props.left ? `${props.left}px` : 0
  // }, ${
  //   props.top ? `${props.top}px` : 0
  // }, 0)`;
  //
  // result.transform = translate;
  // result.WebkitTransform = translate;

  return result;
};


export default getMediaItemWrapperStyles;
