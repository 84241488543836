import { truncate } from 'lodash';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useIsFetching } from 'react-query';

import ExpiredText from '~components/ProductItem/Texts/ExpiredText';
import TrialFreePeriodAndPriceText from "~components/ProductItem/Texts/TrialFreePeriodAndPriceText";
import { PREFIX_OF_A_COMPOSITE_KEY as suscriptionsKey } from '~hooks/fetch/useSubscriptions';
import getMinPrice from '~lib/product/getMinPrice';
import {
  ALREADY_AVAILABLE,
  BUY_ACTION,
  CHOICE_SUBSCRIBTION,
  FROM,
  PRICE_PERIOD,
  SUBSCRIPTION_NO_ACCESS_GRANTED,
  TRY_FOR_FREE,
} from '~localization';
import EverGreenPhase from '~typings/EvergreenPhase';
import Product from '~typings/Product';
import Subscription from '~typings/Subscription';

import * as styles from './styles.module.css';


type Props = {
  product: Product;
  productSubscription: Subscription | undefined;
  intl: InjectedIntl;
};

const BestPrice: React.FC<Props> = ({ product, productSubscription, intl }) => {
  const minPriceWithPeriod = React.useMemo(() => getMinPrice(product), [product]);
  const hasOnlyPlan = (product.plans.length === 1);
  const [text, setText] = React.useState<string | null>(null);
  const expiresAt = productSubscription?.expires_at;
  const isActive = productSubscription?.status === 'active';
  const accessGranted = productSubscription?.access_granted;
  const autorenewable = productSubscription?.autorenew.autorenewable;
  const isLoadingSubscribtions = useIsFetching(suscriptionsKey);
  const isExpired = (isActive && accessGranted && !autorenewable && expiresAt)
    || (!isActive && accessGranted && expiresAt);

  React.useEffect(() => {
    if (minPriceWithPeriod === 'free') {
      setText(intl.formatMessage({ id: TRY_FOR_FREE }));
    } else if (minPriceWithPeriod !== null) {
      setText(`${!hasOnlyPlan ? `${intl.formatMessage({ id: FROM })} ` : ''}${
        intl.formatMessage({ id: PRICE_PERIOD }, {
          price: minPriceWithPeriod.money.formatted,
            durationValue: minPriceWithPeriod.period.value,
            durationUnit: minPriceWithPeriod.period.unit,
        })
      }`);
    } else if (!hasOnlyPlan) {
      setText(intl.formatMessage({ id: CHOICE_SUBSCRIBTION }));
    } else {
      setText(intl.formatMessage({ id: BUY_ACTION }));
    }
  }, [minPriceWithPeriod]);

  const renderDescription = () => {
    if (isActive && !productSubscription?.access_granted) {
      return (
        <span className={ styles.warning }>
          {
            truncate(
              intl.formatMessage({ id: SUBSCRIPTION_NO_ACCESS_GRANTED }),
              {
                length: 48,
                separator: '. ',
                omission: '',
              },
            )
          }
        </span>
      );
    }

    if (isActive && autorenewable && accessGranted && expiresAt) {
      return (
        <>
          <TrialFreePeriodAndPriceText
            isTrialPeriod={ (productSubscription?.phase?.type === 'trial') }
            price={ {
              value: productSubscription?.plan.eligible_phase?.billing.price.formatted,
              duration: (productSubscription?.plan.eligible_phase?.access_period.value || undefined),
              unit: (productSubscription?.plan.eligible_phase?.access_period.unit || undefined),
            } }
            autoReNew={ productSubscription?.autorenew }
          />
        </>
      );
    }

    if (isExpired) {
      return <ExpiredText
        phase={ productSubscription?.plan.eligible_phase as EverGreenPhase }
        expiresAt={ expiresAt }
      />;
    }

    if (!isActive) { return null; }

    return (
      <TrialFreePeriodAndPriceText
        isTrialPeriod={ (productSubscription?.phase?.type === 'trial') }
        price={ {
          value: productSubscription?.plan.eligible_phase?.billing.price.formatted,
          duration: (productSubscription?.plan.eligible_phase?.access_period.value || undefined),
          unit: (productSubscription?.plan.eligible_phase?.access_period.unit || undefined),
        } }
        autoReNew={ productSubscription?.autorenew }
      />
    );
  };

  return (
    <>
      <div className={ styles.bestPrice }>
        {
          isLoadingSubscribtions ?
            '…'
            : isActive || isExpired ?
              intl.formatMessage({ id: ALREADY_AVAILABLE })
              :
              text
        }
      </div>
      { productSubscription ? (
        <div className={ styles.description }>
          { renderDescription() }
        </div>
      ) : null }
    </>
  );
};


export default injectIntl(BestPrice);
