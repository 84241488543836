import * as moment from 'moment';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import {
  ACTIVE_RENT_AVAILABLE,
  EST_PURCHASED,
  EXPIRED_PURCHASES,
  TVOD_PURCHASED,
} from '~localization';
import { ExpandedReasonObj } from '~typings/PlayerAccess';

type Props = {
  reasonObject?: ExpandedReasonObj;
  intl: InjectedIntl;
};

const ExpiresTime: React.FC<Props> = ({ reasonObject, intl }) => {
  if (!reasonObject) {
    return null;
  }

  if (reasonObject['object'] === 'subscription') {
    return null;
  }

  const purchase = reasonObject;
  const rentPlan = purchase.rent_plan;
  const rentPlanAccessPeriod = rentPlan.access_period;

  const accessDurationUnit = rentPlanAccessPeriod.unit || 'hours';
  const durationUnit = rentPlan.duration.unit || 'days';

  if (accessDurationUnit === 'unlimited' || durationUnit === 'unlimited') {
    return <>{intl.formatMessage({ id: EST_PURCHASED })}</>;
  }

  const diffFromNowDuration = moment(purchase.expires_at!).diff(moment(), durationUnit);
  const diffFromNowAccess = moment(purchase.expires_at!).diff(moment(), accessDurationUnit);

  if (purchase.status === 'active') {
    if (diffFromNowAccess <= 0) {
      return <>{intl.formatMessage({ id: EXPIRED_PURCHASES })}</>;
    }

    return (
      <>
        {intl.formatMessage(
          { id: ACTIVE_RENT_AVAILABLE },
          {
            accessDurationValue: Math.abs(diffFromNowAccess),
            accessDurationUnit,
          },
        )}
      </>
    );
  }

  if (diffFromNowDuration <= 0) {
    return <>{intl.formatMessage({ id: EXPIRED_PURCHASES })}</>;
  }

  return (
    <>
      {intl.formatMessage(
        { id: TVOD_PURCHASED },
        {
          durationValue: Math.abs(diffFromNowDuration),
          durationUnit,
          accessDurationValue: rentPlanAccessPeriod.value || 0,
          accessDurationUnit,
        },
      )}
    </>
  );
};

export default injectIntl(ExpiresTime);
