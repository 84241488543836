import { useConfig } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import { getCachedBanners } from '~hooks/fetch/useBanners/useBanners';
import { getCachedBanners as getCachedBannersV4 } from '~hooks/fetch/useBannersV4/useBanners';
import { getCardColItemsFirstPageFromCache } from '~hooks/fetch/useCardCollectionsItems';
import { getCachedChannel } from '~hooks/fetch/useChannel';
import { getCachedCollectionItems } from '~hooks/fetch/useCollectionItems';
import { getContinueWatchingFirstPageFromCache } from '~hooks/fetch/useContinueWatching/useContinueWatchingV3';
import { getFavoritesFirstPageFromCache } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { getRecsFirstPageFromCache } from '~hooks/fetch/useRecommendations/useRecommendations';
import useNavigation from '~stores/Navigation';
import { Block, BlockType } from '~typings/Block';
import Channel from '~typings/Channel';
import ContinueWatchingType from '~typings/ContinueWatchingType';
import { MediaItemType } from '~typings/DataItem';
import { FavoriteType } from '~typings/FavoriteType';
import Page from '~typings/Page';
import ResponseMany from '~typings/ResponseMany';
import ResponseSingle from '~typings/ResponseSingle';

import { allowedBlocks } from './index.helpers';


type DataType =
  | ResponseMany<MediaItemType[]>
  | ResponseSingle<Channel>;

export type ItemsByBlockID = {
  [blockID in string]?: DataType | null;
}

type Result = Readonly<{
  itemsByBlockID: ItemsByBlockID;
}>;


const useCachedBlocksItems = (paramsPage?: Page): Result => {
  const itemsByBlockID: ItemsByBlockID = {};

  const currentPage = useNavigation(state => state.currentPage);
  const { accessToken } = getAuthData();
  const config = useConfig();
  const page = paramsPage || currentPage;
  const blocksAllowed = allowedBlocks(!!accessToken, (page?.blocks || []));

  blocksAllowed.map((block: Block) => {
    switch (block.object) {
      case BlockType.BannersBlock: {
        const items = getCachedBanners(page?.id);

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.BigBannersBlock: {
        const items = getCachedBannersV4(block.id);

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.MiddleBannersBlock: {
        const items = getCachedBannersV4(block.id);

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.CollectionBlock: {
        const items = getCachedCollectionItems(block.collection?.id);

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.CardCollectionBlock: {
        if (block.card_collection?.id) {
          const items = getCardColItemsFirstPageFromCache(config, {
            cardCollectionID: block.card_collection?.id,
          });

          if (items && items.data.length !== 0) {
            itemsByBlockID[block.id] = items;
          }
        }

        break;
      }
      case BlockType.ContinueWatchingBlock: {
        const items = getContinueWatchingFirstPageFromCache({
          config,
          queryParams: {
            types: block.resource_types as ContinueWatchingType[] | undefined
          }
        });

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.RecommendationsBlock: {
        const items = getRecsFirstPageFromCache(config);

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.CurrentProgramLineBlock: {
        const items = getCachedChannel(block.channel_slug);

        if (items !== null) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.FavoriteChannelsBlock: {
        const items = getFavoritesFirstPageFromCache({
          config, types: [FavoriteType.Channels]
        });

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.FavoriteMoviesBlock: {
        const items = getFavoritesFirstPageFromCache({
          config, types: [FavoriteType.Movies]
        });

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.FavoriteAudioShowBlock: {
        const items = getFavoritesFirstPageFromCache({
          config, types: [FavoriteType.AudioShows]
        });

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.FavoriteSeriesBlock: {
        const items = getFavoritesFirstPageFromCache({
          config, types: [FavoriteType.Series]
        });

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
      case BlockType.FavoritesBlock: {
        const items = getFavoritesFirstPageFromCache({
          config, types: (block.resource_types || []) as FavoriteType[]
        });

        if (items && items.data.length !== 0) {
          itemsByBlockID[block.id] = items;
        }

        break;
      }
    }
  });

  return {
    itemsByBlockID: itemsByBlockID,
  };
};


export default useCachedBlocksItems;
