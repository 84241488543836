import focuser from '@focuser';
import cn from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import { useOldNavigationBlocker } from '~hooks/useOldNavigationBlocker';
import {
  CANCEL,
  DO_YOU_WANT_TO_START_WATCHING,
  PLAYBACK,
  RENT_REMAINING_TIME_WARNING,
} from '~lib/localizator/src/dictionaries';
import { PickByExpand } from '~lib/type-utils';
import ItemObject from '~typings/ItemObject';
import RentPlan from '~typings/RentPlan';

import * as styles from './RentActivationWarning.module.css';

export const PATH_IN_SEARCH = 'RENT_ACTIVATION_WARNING';

export type RentPlanWithAccessPeriod = PickByExpand<RentPlan, 'duration,access_period'>;

export type Props = {
  resourceType: ItemObject.Movie | ItemObject.Series | ItemObject.AudioShow;
  rentPlan: RentPlanWithAccessPeriod;
  purchaseExpiresAt: string;
  onContinue: () => void;
  onCancel: () => void;
};
export const RentActivationWarning = injectIntl<Props>(
  ({ resourceType, onContinue, onCancel, intl, rentPlan, purchaseExpiresAt }) => {
    const [focusOn, setFocusOn] = React.useState<'cancel' | 'continue'>('continue');

    useOldNavigationBlocker();

    const durationUnit = useMemo(() => {
      if (rentPlan.duration.unit === 'unlimited') {
        // `unlimited` тут не может быть
        return 'days';
      }
      return rentPlan.duration.unit || 'days';
    }, [rentPlan]);

    const diffFromNowDuration = moment(purchaseExpiresAt).diff(moment(), durationUnit);

    return (
      <focuser.FocusableBlock
        className={ styles.screen }
        isFocused
        onKeyReturn={ (e) => {
          e.stopNativeEvent();
          onCancel();
        } }
        onKeyRight={ () => setFocusOn('cancel') }
        onKeyLeft={ () => setFocusOn('continue') }
        noNeedForceFocus
      >
        <div className={ styles.label }>
          {intl.formatMessage({ id: DO_YOU_WANT_TO_START_WATCHING })}
        </div>
        <div className={ styles.label }>
          {intl.formatMessage(
            { id: RENT_REMAINING_TIME_WARNING },
            {
              durationValue: Math.abs(diffFromNowDuration),
              durationUnit: rentPlan.duration.unit || 'days',
              accessDurationValue: rentPlan.access_period.value || 0,
              accessDurationUnit: rentPlan.access_period.unit || 'hours',
              resourceType: resourceType
            },
          )}
        </div>
        <div className={ styles.buttons }>
          <focuser.FocusableBlock
            className={ cn(styles.button, {
              [styles.buttonActive]: focusOn === 'continue',
            }) }
            isFocused={ focusOn === 'continue' }
            onForceFocus={ () => setFocusOn('continue') }
            onClick={ (e) => {
              e.stopNativeEvent();
              onContinue();
            } }
            isLastBlock
            emitForceFocusOnHover
          >
            {intl.formatMessage({ id: PLAYBACK })}
          </focuser.FocusableBlock>
          <focuser.FocusableBlock
            className={ cn(styles.button, {
              [styles.buttonActive]: focusOn === 'cancel',
            }) }
            isFocused={ focusOn === 'cancel' }
            onForceFocus={ () => setFocusOn('cancel') }
            onClick={ (e) => {
              e.stopNativeEvent();
              onCancel();
            } }
            emitForceFocusOnHover
          >
            {intl.formatMessage({ id: CANCEL })}
          </focuser.FocusableBlock>
        </div>
      </focuser.FocusableBlock>
    );
  },
);

RentActivationWarning.displayName = 'RentActivationWarning';
