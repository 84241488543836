import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { switchSliderAnimationMS } from '~app/variables';
import { useDebounceWithIntermediateValueNull } from '~components/Slider/items/items.helpers';
import useCurrentEvent from '~hooks/useCurrentEvent';
import { getScheduleTime } from "~lib/localeUtils/dates";
import { NO_EPG } from '~localization';
import Channel from '~typings/Channel';

import CurrentEventInfoRender from './render';

const DEBOUNCE_VALUE = switchSliderAnimationMS + 200;


type Props = Readonly<{
  item: Channel;
  withAnimation?: boolean;
  isHidden?: boolean;
  isBigSize?: boolean;
  isFullSize?: boolean;
  withIcon?: boolean;
  fadeDuration?: number;
  fadeDelay?: number;
  intl: InjectedIntl;
}>;


const CurrentEventInfoChannel: React.FC<Props> = (props: Props) => {
  const channelID = props.item.id;
  const delayedChannelId = useDebounceWithIntermediateValueNull(channelID, DEBOUNCE_VALUE);
  const currentEvent = useCurrentEvent(delayedChannelId || undefined);

  const currentEventName = React.useMemo((): string | undefined => {
    if (currentEvent?.channel_id === channelID) {
      return (
        `${getScheduleTime(currentEvent.start_at)} ${currentEvent.name}`
      );
    }

    if (currentEvent === undefined) {
      return (
        props.intl.formatMessage({ id: NO_EPG })
      );
    }

    return undefined;
  }, [currentEvent, channelID]);

  return (
    <CurrentEventInfoRender
      name={ props.item.name }
      eventName={ currentEventName }
      startAt={ currentEvent?.start_at }
      endAt={ currentEvent?.end_at }
      withAnimation={ props.withAnimation }
      isHidden={ props.isHidden }
      isBigSize={ props.isBigSize }
      isFullSize={ props.isFullSize }
      withIcon={ props.withIcon }
      fadeDuration={ props.fadeDuration }
      fadeDelay={ props.fadeDelay }
    />
  );
};


export default injectIntl(React.memo(CurrentEventInfoChannel));
