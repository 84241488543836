import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { useClient, useConfig } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { useAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import Channel from '~typings/Channel';
import ResponseMany from '~typings/ResponseMany';

import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '../utils';

export const PREFIX_OF_A_COMPOSITE_KEY = 'channels';

const channelsKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,
  infinityPages: (limit: number)=> [channelsKeys.PREFIX, 'infinity-pages', limit],
}


const channelsFetchFn = async (
  client: ApiClient,
  accessToken: string | null,
  limit: number,
  offset = 0,
): Promise<ResponseMany<Channel[]>> => {

  return await client.get(fetchURLs[FetchType.Channels], {
    access_token: accessToken,
    ...fetchOptionsBasic[FetchType.Channels],
    'page[offset]': offset,
    'page[limit]': limit,
  });
};


export const useInfinityChannels = () => {
  const client = useClient();
  const limit = getLimitFromConfig(useConfig());
  const accessToken = useAuthData()?.accessToken;
  const query = useInfiniteQuery<ResponseMany<Channel[]>>({
    queryKey: channelsKeys.infinityPages(limit),
    queryFn: ({ pageParam = 0 }) =>
      channelsFetchFn(
        client,
        accessToken,
        limit,
        pageParam * limit,
      ),
    getNextPageParam: getNextPageForInfinityPagination,
  });

  const parsedData: ResponseMany<Channel[]> | undefined = useMemo(() => {
    return query.data?.pages && reducePaginatedPages(query.data.pages);
  }, [query.data?.pages]);

  return {
    query,
    parsedData,
  };
};

/**
 * Хук сам автоматически загружает все каналы
 */
export const useAutoInfinityChannels = () => {
  const { query, parsedData } = useInfinityChannels();

  useEffect(() => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage();
    }
  }, [query]);

  return {
    data: parsedData,
    isLoading: Boolean(query.isFetchingNextPage || query.hasNextPage || query.isLoading),
  };
};

