import * as cn from 'classnames';
import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  MARKER_FEATURED,
  MARKER_FREE,
  MARKER_NEW,
  MARKER_POPULAR,
  MARKER_SOON,
  MARKER_SUBSCRIPTION,
} from '~localization';
import ImageType from '~typings/ImageType';
import MarkerEnum from '~typings/Marker';
import MediaItemDimension from '~typings/MediaItemDimension';

import * as styles from './styles.module.css';


export type Props = {
  markers?: MarkerEnum[];
  className?: string;
  dimension?: MediaItemDimension;
};

const titles = {
  featured: MARKER_FEATURED,
  free: MARKER_FREE,
  new: MARKER_NEW,
  popular: MARKER_POPULAR,
  soon: MARKER_SOON,
  subscription: MARKER_SUBSCRIPTION,
};

export const Marker: React.FC<Props> = ({ markers = [], className, dimension }) => {
  if (markers.length === 0 || !titles[markers[0]]) { return null; }

  const renderText = (txt) => (
    <span
      className={
        cn(
          styles.marker,
          className,
          get(styles, `${markers[0]}Marker`, ''), {
            [styles.poster]: dimension === ImageType.POSTER,
          }
        ) }
    >
      { txt }
    </span>
  );

  return (
    <FormattedMessage
      id={ titles[markers[0]] }
    >
      { renderText }
    </FormattedMessage>
  );
};


export default React.memo(Marker);
