import * as React from 'react';

import Slider from '~components/Slider';
import { useRecommendationsFirstPage } from '~hooks/fetch/useRecommendations/useRecommendations';
import { Block, BlockType } from '~typings/Block';
import ItemObject from '~typings/ItemObject';
import MediaItemDimension from '~typings/MediaItemDimension';


type Props = Readonly<{
  block?: Block;
  dimension: MediaItemDimension;
  isFocusedBlock: boolean;
  isPopupOpened: boolean;
  isVisible: boolean;
  focusedItemIndex: number;
  withName?: boolean;
}>


const BlockRecommendations: React.FC<Props> = (props: Props) => {
  const {data: items} = useRecommendationsFirstPage(props.block?.recommendation_context)
  return (
    <Slider
      dimension={ props.dimension }
      items={ items }
      isFocusedBlock={ !props.isPopupOpened && props.isFocusedBlock }
      isVisible={ props.isVisible }
      focusedIndex={ props.focusedItemIndex }
      withName={ props.withName }
      withGenres={ props.withName }
      deeplink={ `/${ ItemObject.Collections }/${ BlockType.RecommendationsBlock }?blockTitle=${ props.block?.title }` }
    />
  );
};


export default React.memo(BlockRecommendations);
