// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iconPlay--SGtAh {\n  width: 0.9375vw;\n  height: 1.09375vw;\n  margin-top: 0.703125vw;\n  margin-left: 0.15625vw;\n}\n\n.iconTrailer--w48Dn {\n  width: 1.7187500000000002vw;\n  height: 1.40625vw;\n  margin-top: 0.546875vw;\n}\n\n.iconFavorite--rtRZ9,\n.iconLike--quYie,\n.iconDisLike--6TngO {\n  width: 3.438vw;\n  height: 3.438vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemPage/Description/bar/icons/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;;;EAGE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".iconPlay {\n  width: 0.9375vw;\n  height: 1.09375vw;\n  margin-top: 0.703125vw;\n  margin-left: 0.15625vw;\n}\n\n.iconTrailer {\n  width: 1.7187500000000002vw;\n  height: 1.40625vw;\n  margin-top: 0.546875vw;\n}\n\n.iconFavorite,\n.iconLike,\n.iconDisLike {\n  width: 3.438vw;\n  height: 3.438vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var iconPlay = "iconPlay--SGtAh";
export var iconTrailer = "iconTrailer--w48Dn";
export var iconFavorite = "iconFavorite--rtRZ9";
export var iconLike = "iconLike--quYie";
export var iconDisLike = "iconDisLike--6TngO";
export default ___CSS_LOADER_EXPORT___;
