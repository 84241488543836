export type FeatureFlags = { [key in Feature]?: boolean };


/**
 * Фича-флаги
 * Добавлять так:
 * ключ => как мы хотим видеть фича-флаг в коде
 * Значение => как фича-флаг записан в конфиге
 */
export enum Feature {
  /**
   * Новые карточки в слайдерах
   */
  NewCards = 'newCards',
  ShowRknPlate = 'showRknPlate',
}
