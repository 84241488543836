export enum Apis {
  SPBTVProd = 'https://api.spbtv.com',
  SPBTVDev = 'https://dev-api.spbtv.com',
  SPBTVDev4 = 'https://dev4-api.spbtv.com',
  SPBTVIntProd = 'https://api-tv.spbtv.com',
  SPBTVIntDev = 'https://dev-api-tv.spbtv.com',
  PopcornProd = 'https://api.beepopcorn.ru',
  BUZProd = 'https://api.beelinetv.uz',
  BUZDev = 'https://dev-api.beelinetv.uz',
  QSportProd = 'https://api.qsporttv.com',
  QSportDev = 'https://dev-api.qsporttv.com',
  BeeProd = 'https://api.beetv.kz',
  BeeDev = 'https://dev-api.beetv.kz',
  EterraProd = 'https://api.eterra-journal.ru',
  EterraDev = 'https://dev-api.eterra-journal.ru',
  DantserProd = 'https://api.dantsertv.ru',
  DantserDev = 'https://dev-api.dantsertv.ru',
  UkmushProd = ' https://api.ukmush.tv',
  UkmushDev = 'https://dev-api-beekg.spbtv.com',
  UMovieProd = 'https://api.umovie.uz',
  UMovieDev = 'https://dev-api.umovie.uz',
}
