// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--Y0TaD {\n  display: block;\n  text-align: center;\n  width: 52.34375vw;\n}\n\n.agreementText--MJ6dw {\n  font-size: 1.7187500000000002vw;\n  margin-bottom: 5.078125vw;\n}\n\n.agreementBlock--wLHmg {\n  margin-top: 5.078125vw;\n  color: rgba(202, 202, 208, 1);\n  font-size: 1.25vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/PolicyConfirmationPopup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,+BAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,6BAAa;EACb,iBAAuB;AACzB","sourcesContent":[".wrapper {\n  display: block;\n  text-align: center;\n  width: 52.34375vw;\n}\n\n.agreementText {\n  font-size: $fontH6_vw;\n  margin-bottom: 5.078125vw;\n}\n\n.agreementBlock {\n  margin-top: 5.078125vw;\n  color: $gw_07;\n  font-size: $fontBody_vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = "wrapper--Y0TaD";
export var agreementText = "agreementText--MJ6dw";
export var agreementBlock = "agreementBlock--wLHmg";
export default ___CSS_LOADER_EXPORT___;
