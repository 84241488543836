import { FOCUSABLE_DATA_ATTRIBUTE } from '@focuser/data-attributes';
import {
  FOCUSER_CLICK_EVENT_NAME,
  FOCUSER_FORCE_FOCUS_EVENT_NAME,
  FOCUSER_KEY_EVENT_NAME,
  FOCUSER_POINTER_EVENT_NAME,
  FocuserClickEventDetail,
  FocuserForceFocusEventDetail,
  FocuserKeyEventDetail,
  FocuserPointerEventDetail,
} from '@focuser/events';
import { getRootIsolatedDiv } from '@focuser/isolation/utils';
import { debounce } from 'lodash';
import * as React from 'react';

import HotKey from '~typings/HotKey';
import KeyCodes from '~typings/KeyCodes';
import NavigationDirection from '~typings/NavigationDirection';

const POINTER_EVENT_DEBOUNCE_TIME = 50;

export type KeyNames = Readonly<{ [key: number]: HotKey }>;

export const revertKeyCodes = (keyCodes: KeyCodes): KeyNames => {
  const result = {};

  Object.keys(keyCodes).forEach((key) => {
    // @ts-ignore
    const value = keyCodes[key];

    if (Array.isArray(value)) {
      value.forEach((valueItem) => {
        // @ts-ignore
        result[valueItem] = key;
      });
    } else {
      // @ts-ignore
      result[value] = key;
    }
  });

  return result;
};

export const simulateClickOnNode = (node: Element, nativeEvent: Event, isPhysicalMouseClick: boolean) => {
  const event = new CustomEvent<FocuserClickEventDetail>(FOCUSER_CLICK_EVENT_NAME, {
    bubbles: true,
    cancelable: true,
    detail: {
      type: FOCUSER_CLICK_EVENT_NAME,
      keyCode: HotKey.KEY_ENTER,
      stop: () => {
        event.stopPropagation();
      },
      stopNativeEvent: () => {
        nativeEvent.stopImmediatePropagation();
      },
      nativeEvent,
      isPhysicalMouseClick
    },
  });
  node.dispatchEvent(event);
};

export const simulateFocusEventOnNode = (node: Element, nativeEvent: Event) => {
  const event = new CustomEvent<FocuserForceFocusEventDetail>(FOCUSER_FORCE_FOCUS_EVENT_NAME, {
    bubbles: true,
    cancelable: true,
    detail: {
      type: FOCUSER_FORCE_FOCUS_EVENT_NAME,
      stop: () => {
        event.stopImmediatePropagation();
      },
      stopNativeEvent: () => {
        nativeEvent.stopImmediatePropagation();
      },
      nativeEvent
    },
  });

  node.dispatchEvent(event);
};

export const simulatePointerEventOnNode = (node: Element) => {

  const availableDirections = new Set<NavigationDirection>()

  const event = new CustomEvent<FocuserPointerEventDetail>(FOCUSER_POINTER_EVENT_NAME, {
    bubbles: true,
    cancelable: true,
    detail: {
      type: FOCUSER_POINTER_EVENT_NAME,
      addDirection: (direction: NavigationDirection) => {
        availableDirections.add(direction)
      },
      getDirections: () => availableDirections,
      isBlockedByIsolation: false,
    },
  });

  node.dispatchEvent(event);
};

export const simulatePointerEventOnInnerMost = debounce(() => {
  const deepestFocusedComponent = getInnerMostComponent();
  if (!deepestFocusedComponent) {
    return;
  }

  simulatePointerEventOnNode(deepestFocusedComponent);
}, POINTER_EVENT_DEBOUNCE_TIME);

export const simulateKeyEventOnNode = (node: Element, keyCode: HotKey, nativeEvent: Event) => {
  const event = new CustomEvent<FocuserKeyEventDetail>(FOCUSER_KEY_EVENT_NAME, {
    bubbles: true,
    cancelable: true,
    detail: {
      type: FOCUSER_KEY_EVENT_NAME,
      keyCode,
      stop: () => {
        event.stopPropagation();
      },
      stopNativeEvent: () => {
        nativeEvent.stopImmediatePropagation();
      },
      nativeEvent
    },
  });

  node.dispatchEvent(event);
};

export const handleStopClick = (event: MouseEvent) => {
  event.stopPropagation();
  event.preventDefault();
};

type OnKeyDownProps = Readonly<{
  keyNames: KeyNames;
  prevKeyName: React.MutableRefObject<string | null>;
  keyDownEvent: KeyboardEvent;
}>;

export const onKeyDown = (properties: OnKeyDownProps) => {
  const keyCode = properties.keyDownEvent.keyCode;
  const keyName = properties.keyNames[keyCode];

  if (!keyName) {
    return;
  }

  if (keyName === HotKey.KEY_ENTER || keyName === HotKey.KEY_RETURN) {
    if (properties.prevKeyName.current === keyName) {
      return;
    }

    properties.prevKeyName.current = keyName;
  }

  applyHotKeyOrClickOnInnerMost(keyName, properties.keyDownEvent);
};

export const applyHotKeyOrClickOnInnerMost = (hotKey: HotKey, nativeEvent: Event) => {
  if (hotKey === HotKey.KEY_ENTER) {
    applyClickOnInnerMost(nativeEvent);
    return;
  }

  applyHotKeyOnInnerMost(hotKey, nativeEvent);
}


export const applyHotKeyOnInnerMost = (hotKey: HotKey, nativeEvent: Event) => {
  const deepestFocusedComponent = getInnerMostComponent();
  if (!deepestFocusedComponent) {
    return;
  }

  simulateKeyEventOnNode(deepestFocusedComponent, hotKey, nativeEvent);
};

export const applyClickOnInnerMost = (nativeEvent: Event) => {
  const deepestFocusedComponent = getInnerMostComponent();
  if (!deepestFocusedComponent) {
    return;
  }
  simulateClickOnNode(deepestFocusedComponent, nativeEvent, false);
};

const getInnerMostComponent = () => {
  const root = getFocuserRootNode();

  if (!root) {
    return;
  }

  const focusedComponents = Array.from(
    root.querySelectorAll(`div[${FOCUSABLE_DATA_ATTRIBUTE}="true"]`),
  ).filter((node) => !node.querySelectorAll(`div[${FOCUSABLE_DATA_ATTRIBUTE}="true"]`).length);

  if (process.env.NODE_ENV === 'development') {
    if (focusedComponents.length > 1) {
      console.error(
        'FOCUSER: Должен быть только один сфокусированный компонент на каждом уровне вложенности',
        '(эти компоненты имеют фокус одновременно =>)',
        focusedComponents,
      );
    }
  }

  const deepestFocusedComponent = focusedComponents[0];

  if (!deepestFocusedComponent) {
    return null;
  }

  return deepestFocusedComponent;
};

export const getFocuserRootNode = () => {
  const div = getRootIsolatedDiv();
  return div || document;
};
