/* eslint-disable max-len */
import React from 'react';

interface Props {
  className?: string;
}

export const DownArrowIcon: React.FC<Props> = React.memo((props) => {
  return (
    <svg
      className={ props.className }
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3585 9.69279C24.1568 8.83723 25.511 8.82306 26.3272 9.66172L26.4219 9.75903C27.1838 10.542 27.1938 11.7838 26.4447 12.5789L15.9865 23.6781C15.588 24.1011 14.9165 24.1082 14.5091 23.6937L3.58526 12.5801C2.8077 11.789 2.80453 10.5241 3.57811 9.72914L3.68441 9.61991C4.48514 8.79709 5.80821 8.79282 6.61427 9.61046L15.256 18.3764L23.3585 9.69279Z"
        fill="currentColor"
      />
    </svg>
  );
});

DownArrowIcon.displayName = 'DownArrowIcon';
