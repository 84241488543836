// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".label--MA0bj {\n  margin-bottom: 0.938vw;\n  margin-top: 2.344vw;\n  font-size: 1.5625vw;\n  font-weight: 700;\n  color: rgba(255, 255, 255, 1);\n}\n\n.text--_HFbK {\n  font-size: 1.5625vw;\n  font-weight: 500;\n  line-height: 1.4;\n  color: rgba(223, 223, 228, 1) !important;\n}\n\n.infoTitle--Tnp_U {\n  font-size: 1.875vw;\n  font-weight: 500;\n  margin-top: 2.344vw;\n  margin-bottom: 2.344vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemDetails/DetailsList/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,mBAAuB;EACvB,gBAAkB;EAClB,6BAAa;AACf;;AAEA;EACE,mBAAuB;EACvB,gBAAkB;EAClB,gBAAgB;EAChB,wCAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,gBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":[".label {\n  margin-bottom: 0.938vw;\n  margin-top: 2.344vw;\n  font-size: $fontSub1_vw;\n  font-weight: $fw_7;\n  color: $gw_11;\n}\n\n.text {\n  font-size: $fontSub1_vw;\n  font-weight: $fw_5;\n  line-height: 1.4;\n  color: $gw_08 !important;\n}\n\n.infoTitle {\n  font-size: 1.875vw;\n  font-weight: $fw_5;\n  margin-top: 2.344vw;\n  margin-bottom: 2.344vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var label = "label--MA0bj";
export var text = "text--_HFbK";
export var infoTitle = "infoTitle--Tnp_U";
export default ___CSS_LOADER_EXPORT___;
