import focuser, { FocuserKeyHandler } from '@focuser';
import React from 'react';

import { usePageManagerPointersBlocker } from '~components/PageManager';
import { useApp } from '~components/Provider/App';
import { useGlobalSceneBlocker } from '~components/Scene/globalSceneDisabler';
import { useDispatchLaunchDone } from '~hooks/useDispatchLaunchDone';
import { useDropSceneItem } from '~hooks/useDropSceneItem';
import { useIsMenuOpen,useMenuOpener } from '~hooks/useMenuOpener';
import { useOldNavigationBlocker } from '~hooks/useOldNavigationBlocker';
import { SpinnerFullScreenIsolated } from '~newapp/globalBlocks/spinner';

import { EPGPageLoaded } from './components/EPGPageLoaded';
import { useAllChannels } from './EPGPage.hooks';

export type Props = {};

const EPGPage$: React.FC<Props> = () => {
  const { openMenu } = useMenuOpener();
  const allowNavigation = !useIsMenuOpen();

  /**
   * Отключаем legacy навигацию, и сцену
   */
  useOldNavigationBlocker(allowNavigation);
  usePageManagerPointersBlocker(allowNavigation);
  useGlobalSceneBlocker();
  useDropSceneItem();
  useDispatchLaunchDone();
  const isLaunchingDone = useApp().isLaunchingDone;

  const channelsQuery = useAllChannels();

  const onUpOrReturn: FocuserKeyHandler = (e) => {
    e.stop();
    openMenu();
  };

  if (channelsQuery.isLoading || !isLaunchingDone) {
    return (
      <SpinnerFullScreenIsolated
        returnButtonType="menu"
        onKeyUp={ onUpOrReturn }
        onKeyReturn={ onUpOrReturn }
      />
    );
  }

  return (
    <focuser.FocusableBlock
      isFocused={ allowNavigation }
      onKeyUp={ onUpOrReturn }
      onKeyReturn={ onUpOrReturn }
      returnButtonType={ 'menu' }
      noNeedForceFocus
    >
      <EPGPageLoaded channels={ channelsQuery.channels } />
    </focuser.FocusableBlock>
  );
};

/**
 * EPG страница, в которой отображаются все каналы, их программы и описание
 */
export const EPGPage = React.memo(EPGPage$);
