import create, { SetState } from 'zustand';

import DropdownFilter from '~typings/DropdownFilter';
import QuickFilter from '~typings/QuickFilter';


export interface State {
  quickFilters: {
    [key: string]: QuickFilter[];
  } | undefined;
  dropdownFilters: {
    [key: string]: DropdownFilter[];
  } | undefined;
  setFilters: (pageId: string, filter?: (QuickFilter | DropdownFilter)) => void;
  resetFilters: () => void;
}

const useFilters = create<State>(
  (set: SetState<State>) => ({
    quickFilters: undefined,
    dropdownFilters: undefined,
    resetFilters: () => set({ quickFilters: undefined, dropdownFilters: undefined }),
    setFilters:
      (pageId: string, filter?: (QuickFilter | DropdownFilter)) => set((draft: State) => {
        if (!filter) {
          draft.quickFilters = undefined;
          draft.dropdownFilters = undefined;
        } else if (filter.object === 'quick_filter') {
          const quickFilters = (draft.quickFilters?.[pageId] || [])
            .find(x => x?.id === filter.id) ?
              (draft.quickFilters?.[pageId] || []).filter(x => x?.id !== filter.id)
              : [...(draft.quickFilters?.[pageId] || []), filter];

          if (draft.quickFilters?.[pageId]) {
            draft.quickFilters[pageId] = quickFilters;
          } else {
            draft.quickFilters = {
              ...draft.quickFilters,
              [pageId]: quickFilters,
            };
          }
        } else if (filter.object === 'dropdown_filter') {
          const dropdownFilters =
              !(draft.dropdownFilters?.[pageId] || []).find(x => x?.id === filter.id)
              && filter.options.length !== 0 ?
                [...(draft.dropdownFilters?.[pageId] || []), filter]
                : filter.options.length !== 0 ?
                  (draft.dropdownFilters?.[pageId] || []).map(x => x.id === filter.id ? filter : x)
                  : (draft.dropdownFilters?.[pageId] || []).filter(x => x?.id !== filter.id);

          if (draft.dropdownFilters?.[pageId]) {
            draft.dropdownFilters[pageId] = dropdownFilters;
          } else {
            draft.dropdownFilters = {
              ...draft.dropdownFilters,
              [pageId]: dropdownFilters,
            };
          }
        }
      }),
  })
);


export default useFilters;
