import focuser from '@focuser';
import React, { useEffect } from 'react';

import { ActionType as AppActionType, useAppAction } from '~components/Provider/App';
import { ActionType as MenuActionType,useMenu, useMenuAction } from '~components/Provider/Menu';
import usePointer from '~stores/Pointer';

import { SimpleSlider } from './components/SimpleSlider';
import * as styles from './FocuserTestScreen.module.css'
import { SimpleSliderList } from './SimpleSliderList';


/**
 * Тестовый компонент для `focuser`-а
 */
export const FocuserTestScreen = () => {
  const [focusedIdx, setFocusedIdx] = React.useState(0);
  const applicationAction = useAppAction();

  const pointer = usePointer();
  useEffect(() => {
    pointer.setPointerEnabled(true);
    applicationAction({
      type: AppActionType.SetIsLaunchingDone,
      payload: { isLaunchingDone: true },
    });

  }, []);


  const menuAction = useMenuAction();
  const { isMenuOpened } = useMenu();


  return (
    <focuser.FocusableBlock className={ styles.focuserTestScreen }
    isFocused={ !isMenuOpened }
    onKeyUp={ ()=> menuAction({ type: MenuActionType.ChangeMenuState, isMenuOpened: true }) }
    isForceFocusEnabledInBranch={ !isMenuOpened }
    noNeedForceFocus
    >
      <focuser.FocusableBlock
        isFocused={ focusedIdx === 0 }
        onKeyRight={ () => setFocusedIdx(1) }
        onForceFocus={ () => setFocusedIdx(0) }
        isPointerRightAvailable= { focusedIdx === 0 }
      >
        <SimpleSliderList />
      </focuser.FocusableBlock>
      <focuser.FocusableBlock
        isFocused={ focusedIdx === 1 }
        onKeyLeft={ () => setFocusedIdx(0) }
        onForceFocus={ () => setFocusedIdx(1) }
        isPointerLeftAvailable= { focusedIdx === 1 }
      >
        <SimpleSlider isVertical />
      </focuser.FocusableBlock>
    </focuser.FocusableBlock>
  );
};
