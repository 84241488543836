import * as React from 'react';

import IconMediaItem from './IconMediaItem';
import NewsIcon from './icons/News';


type Props = {
  className?: string;
};

const IconMediaItemNews: React.FC<Props> = ({ className }) => {
  return (
    <IconMediaItem
      Icon={ NewsIcon }
      className={ className }
    />
  );
};


export default IconMediaItemNews;
