import { useEffect } from 'react';

import useHotKeysStore from '~stores/HotKeys';

/**
 * Хук блокирует старую навигацию при монтировании компонента
 *
 * @warning Использовать этот хук можно только на страницах/компонентах,
 * где навигация полностью сделана на focuser-е
 */
export const useOldNavigationBlocker = (isBlocked = true) => {
  useEffect(() => {
    if (!isBlocked) {
      return;
    }
    useHotKeysStore.getState().blockNavigation();
    return () => {
      useHotKeysStore.getState().unblockNavigation();
    };
  }, [isBlocked]);
};
