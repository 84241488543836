/* eslint-disable max-len */
import * as React from 'react';


const SearchIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 58 32"
  >
    <path d="M0 0h58v32H0V0z" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="pathHighlighted"
      d="M27.5538 7.02723C23.3901 7.02723 20.0272 10.3763 20.0272 14.4923C20.0272 18.6082 23.3901 21.9574 27.5538 21.9574C31.7176 21.9574 35.0804 18.6082 35.0804 14.4923C35.0804 10.3763 31.7176 7.02723 27.5538 7.02723ZM18 14.4923C18 9.24295 22.2843 5 27.5538 5C32.8233 5 37.1077 9.24295 37.1077 14.4923C37.1077 16.7873 36.2888 18.8899 34.9266 20.5297L40 25.5665L38.5717 27.0052L33.4732 21.9434C31.8448 23.222 29.7878 23.9846 27.5538 23.9846C22.2843 23.9846 18 19.7416 18 14.4923Z"
    />

  </svg>
);


export default SearchIcon;
