import focuser, { FocuserKeyHandler } from '@focuser';
import React  from 'react';

import * as styles from './SimpleSlider.module.css';

type TestBlock = {
  id: string;
  title: string;
  description: string;
}

const testBlocks: TestBlock[] = [
  {
    id: '1',
    title: 'Test 1',
    description: 'Test 1'
  },
  {
    id: '2',
    title: 'Test 2',
    description: 'Test 2'
  },
  {
    id: '3',
    title: 'Test 3',
    description: 'Test 3'
  },
  {
    id: '4',
    title: 'Test 4',
    description: 'Test 4'
  },
]


export type SimpleSliderProps = {
  isVertical?: boolean;
}
export const SimpleSlider: React.FC<SimpleSliderProps> = ({isVertical}) => {

 const [focusedIdx, setFocusedIdx] = React.useState(0);


const handleRight: FocuserKeyHandler = (event) => {
  if (focusedIdx < testBlocks.length - 1) {
    setFocusedIdx(focusedIdx + 1);
    event.stop();
  }
};

const handleLeft: FocuserKeyHandler = (event) => {
  if(focusedIdx > 0) {
    setFocusedIdx((focusedIdx - 1));
    event.stop();
  }
}

const handlers = isVertical
  ? { onKeyUp: handleLeft, onKeyDown: handleRight }
  : { onKeyLeft: handleLeft, onKeyRight: handleRight };


  return (
    <focuser.FocusableBlock
      isFocused
      className={ isVertical ? styles.SimpleSliderVertical : styles.SimpleSlider }
      { ...handlers }
      isPointerRightAvailable={ !isVertical &&  focusedIdx < testBlocks.length - 1 }
      isPointerLeftAvailable={ !isVertical &&  focusedIdx > 0 }
      isPointerDownAvailable={ isVertical &&  focusedIdx < testBlocks.length - 1 }
      isPointerUpAvailable={ isVertical &&  focusedIdx > 0 }
      noNeedForceFocus
    >
      {testBlocks.map((block, idx) => {
        return (
          <focuser.FocusableBlock
            key={ block.id }
            isFocused={ idx === focusedIdx }
            onForceFocus={ () => setFocusedIdx(idx) }
            isLastBlock
            emitForceFocusOnHover
          >
            {({ isFocused }) => (
              <div
                className={
                  isFocused
                    ? styles.oneBlockFocused
                    : idx === focusedIdx
                      ? styles.oneBlockPreFocused
                      : styles.oneBlock
                }
              >
                <h1>{block.title}</h1>
                <p>{block.description}</p>
              </div>
            )}
          </focuser.FocusableBlock>
        );
      })}
    </focuser.FocusableBlock>
  );
}
