import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import TrialFreePeriodAndPriceText from "~components/ProductItem/Texts/TrialFreePeriodAndPriceText";
import {
  SUBSCRIPTION_CANCELLATION_DESCRIPTION,
} from '~localization';
import EverGreenPhase from '~typings/EvergreenPhase';
import TrialPhase from '~typings/TrialPhase';


type Props = {
  trialPhase: TrialPhase | undefined;
  evergreenPhase: EverGreenPhase | undefined;
  intl: InjectedIntl;
};

const TrialDescription = ({ trialPhase, evergreenPhase, intl }: Props) => {
  return <>
    {
      (trialPhase) ? (
        <>
          <TrialFreePeriodAndPriceText
            isTrialPeriod
            price={ {
              value: evergreenPhase?.billing?.price.formatted,
              duration: (evergreenPhase?.access_period?.value || undefined),
              unit: (evergreenPhase?.access_period?.unit || undefined),
            } }
          />
        </>
      ) : (
        intl.formatMessage({ id: SUBSCRIPTION_CANCELLATION_DESCRIPTION })
      )
    }
  </>
};


export default TrialDescription;
