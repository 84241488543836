/* eslint-disable max-len */
import React from 'react';

type Props = {
  size?: number;
};

const Attention$ = ({ size = 2.34375 }: Props) => {
  return (
      <svg
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={ {
          width: `${size}vw`,
          height: `${size}vw`,
        } }
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 27C21.6274 27 27 21.6274 27 15C27 8.37258 21.6274 3 15 3C8.37258 3 3 8.37258 3 15C3 21.6274 8.37258 27 15 27ZM13.3335 10.0001C13.3335 9.07959 14.0797 8.3334 15.0002 8.3334C15.9206 8.3334 16.6668 9.07959 16.6668 10.0001V16.0001C16.6668 16.9205 15.9206 17.6667 15.0002 17.6667C14.0797 17.6667 13.3335 16.9205 13.3335 16.0001V10.0001ZM16.6667 20.6666C16.6667 21.587 15.9205 22.3332 15.0001 22.3332C14.0796 22.3332 13.3334 21.587 13.3334 20.6666C13.3334 19.7461 14.0796 18.9999 15.0001 18.9999C15.9205 18.9999 16.6667 19.7461 16.6667 20.6666Z"
          fill="white"
        />
      </svg>
  );
};

export const Attention = Attention$;
