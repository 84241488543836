import focuser, { useFocuserContext } from '@focuser';
import cn from 'classnames';
import React from 'react';

import { SpinnerIcon } from '~newapp/globalBlocks/spinner';

import * as commonStyles from './../../MiddleBannerCommon.module.css';
import * as styles from './MiddleBannerLoading.module.css';

export const MiddleBannerLoading: React.FC = () => {
  const { isFocused } = useFocuserContext();

  return (
    <focuser.FocusableBlock
      isFocused
      isLastBlock
      emitForceFocusOnHover
      noNeedForceFocus
      className={ cn(
        commonStyles.mainWrapper,
        {
          [commonStyles.focused]: isFocused,
        },
        styles.centered,
      ) }
    >
      <SpinnerIcon />
    </focuser.FocusableBlock>
  );
};
