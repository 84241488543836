import * as cn from 'classnames';
import * as React from 'react';

import { CatchupStatus } from '~lib/eventsHelper';
import { IconMediaItemPlay } from '~ui/Icon';
import IconBase from '~ui/Icon/IconBase';
import CatchupIcon from '~ui/Icon/icons/Catchup';
import FutureIcon from '~ui/Icon/icons/FutureEvent';
import * as iconStyles from '~ui/Icon/styles.module.css';


type Props = {
  isFocused: boolean;
  className: string;
  catchupStatus: CatchupStatus;
};

const IconCatchup: React.FC<Props> = ({ isFocused, className, catchupStatus }) => {
  let Icon: any = null;

  if (catchupStatus === CatchupStatus.OnAir && isFocused) {
    Icon = IconMediaItemPlay;
  }
  if (catchupStatus === CatchupStatus.Future) {
    Icon = FutureIcon;
  }
  if (catchupStatus === CatchupStatus.Catchup) {
    Icon = CatchupIcon;
  }

  if (!Icon) { return null; }

  return (
    <div className={ className }>
      <IconBase
        Icon={ Icon }
        classNames={ {
          icon: iconStyles.icon,
          wrapper: cn({
            [iconStyles.primaryRound]: isFocused,
            [iconStyles.active]: !isFocused && catchupStatus !== CatchupStatus.OnAir,
          }),
        } }
      />
    </div>
  );
};


export default IconCatchup;
