/* eslint-disable max-len */
import * as React from 'react';


type Props = Readonly<{
  className?: string;
}>;


const CatchupIcon: React.FC<Props> = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.89 18.98"
    className={ props.className }
  >
    <path d="M11.38 7.85l3 1.8-3 1.83V7.85m-1.4-2.48v8.61l7.08-4.34-7.08-4.27z" fill="currentColor" />
    <path d="M12.39 0a9.5 9.5 0 0 0-9.48 9.33L.99 7.41l-1 1 3.61 3.61 3.59-3.59-1-1-1.9 1.9a8.09 8.09 0 1 1 8.08 8.25 8 8 0 0 1-5-1.76l-.87 1.1a9.38 9.38 0 0 0 5.9 2.06A9.49 9.49 0 0 0 12.39 0z" fill="currentColor" />
  </svg>
);


export default React.memo(CatchupIcon);
