// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainWrapper--yPwi3 {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n}\n\n.iconWrapper--Onznc {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  margin-right: 1.5625vw;\n}\n\n.icon--oyuKh {\n  margin-right: 0.78125vw;\n}\n\n.iconText--FUmJ3 {\n  font-weight: 500;\n  font-size: 1.40625vw;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/blocks/ratingLine/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;AACrB;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAkB;EAClB,oBAAuB;AACzB","sourcesContent":[".mainWrapper {\n  display: flex;\n  align-items: center;\n}\n\n.iconWrapper {\n  display: flex;\n  align-items: center;\n  margin-right: 1.5625vw;\n}\n\n.icon {\n  margin-right: 0.78125vw;\n}\n\n.iconText {\n  font-weight: $fw_5;\n  font-size: $fontSub2_vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var mainWrapper = "mainWrapper--yPwi3";
export var iconWrapper = "iconWrapper--Onznc";
export var icon = "icon--oyuKh";
export var iconText = "iconText--FUmJ3";
export default ___CSS_LOADER_EXPORT___;
