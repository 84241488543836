// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--ylkqq {\n  font-size: 3.125vw;\n  font-weight: bold;\n  color: rgba(255, 255, 255, 1);\n  margin: 1.25vw 0;\n}\n\n.origName--yQLzm {\n  color: rgba(202, 202, 208, 1);\n  font-size: 1.5625vw;\n  font-weight: 700;\n  margin-bottom: 2.34375vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemDetails/DefaultHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAqB;EACrB,iBAAmB;EACnB,6BAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,6BAAa;EACb,mBAAuB;EACvB,gBAAkB;EAClB,wBAAwB;AAC1B","sourcesContent":[".header {\n  font-size: $fontH3_vw;\n  font-weight: $fw_00;\n  color: $gw_11;\n  margin: 1.25vw 0;\n}\n\n.origName {\n  color: $gw_07;\n  font-size: $fontSub1_vw;\n  font-weight: $fw_7;\n  margin-bottom: 2.34375vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var header = "header--ylkqq";
export var origName = "origName--yQLzm";
export default ___CSS_LOADER_EXPORT___;
