import currentByStartAtAndEndAt from '~lib/currentByStartAtAndEndAt';
import CardMutation, { CardMutationState } from '~typings/CardMutation';


const getCurrentEventMutation = (cardMutation?: CardMutation): CardMutationState | undefined => {
  if (!cardMutation || !cardMutation.states) { return undefined; }

  return (
    (currentByStartAtAndEndAt(cardMutation?.states) as CardMutationState | undefined)
    || undefined
  );
}


export default getCurrentEventMutation;
