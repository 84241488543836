import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { BaseProps as IconBtnBaseProps,IconBtn } from './IconBtnHOC';

type BaseProps = Omit<IconBtnBaseProps, 'className'> &
  Omit<FocusableBlockProps, 'className' | 'isLastBlock' | 'children'> & {
    focuserClassName?: string;
    buttonClassName?: string;
  };

const IconBtnFocuser: React.FC<BaseProps> = ({
  isFocused,
  isDisabled,
  focuserClassName,
  buttonClassName,
  Icon,
  size,
  ...focuserProps
}) => {
  return (
    <focuser.FocusableBlock
      { ...focuserProps }
      isFocused={ isFocused }
      className={ focuserClassName }
      onClick={ isDisabled ? undefined : focuserProps.onClick }
      emitForceFocusOnHover={ isDisabled ? false : focuserProps.emitForceFocusOnHover }
      isLastBlock
    >
      {({ isFocused }) => (
        <IconBtn
          className={ buttonClassName }
          isFocused={ isFocused }
          isDisabled={ isDisabled }
          Icon={ Icon }
          size={ size }
        ></IconBtn>
      )}
    </focuser.FocusableBlock>
  );
};

export type IconBtnFocuserProps = Omit<BaseProps, 'Icon' | 'size'>;

export const IconBtnFocuserHOC = (Icon: BaseProps['Icon'], size: BaseProps['size']) => {
  const IconBtnFocuserHOC = (props: IconBtnFocuserProps) => (
    <IconBtnFocuser
      { ...props }
      Icon={ Icon }
      size={ size }
    />
  );

  return IconBtnFocuserHOC;
};
