import { isNumber } from 'lodash';

import { NavigationLine, Y_FIELD_NAME } from '~hooks/useSpatialNavigation/index';
import * as URLSearchManager from '~lib/URLQueryStringManager';
import NavigationDirection from '~typings/NavigationDirection';


const findNavigatebleLineIndex = (
  focusOn: number,
  direction: NavigationDirection.Up | NavigationDirection.Down,
  navigationItems: NavigationLine[],
): number | null => {
  const maxLimit = Math.max(navigationItems.length - 1, 0);
  const movedFocusOn = (direction === NavigationDirection.Up) ?
    Math.max((focusOn - 1), 0)
    :
    Math.min((focusOn + 1), maxLimit);

  if (isNumber(navigationItems[movedFocusOn]?.maxIndex)) {
    return movedFocusOn;
  }

  if (movedFocusOn === 0 || movedFocusOn === maxLimit) { return null; }

  return findNavigatebleLineIndex(movedFocusOn, direction, navigationItems);
};

const findFirstEnabledLine = (navigationItems: NavigationLine[]): number | null => {
  return findNavigatebleLineIndex(0, NavigationDirection.Down, navigationItems);
};


const getFocusedLineFromURL = (history): number | null => (
  (isNumber(URLSearchManager.getField(history.location.search, Y_FIELD_NAME))) ?
    URLSearchManager.getField(history.location.search, Y_FIELD_NAME)
    :
    null
);

const setFocusOn = (history, value: number | string | null) => {
  URLSearchManager.set({ [Y_FIELD_NAME]: value }, history);
};

const getFocusedLineIndex = (navigationItems: NavigationLine[]) => (
  navigationItems.findIndex((item) => item.isFocused)
);

const getFocusOn = (history, navigationItems: NavigationLine[]): number => {
  const focusedLineIndex = getFocusedLineIndex(navigationItems);
  const focusedLineFromURL = getFocusedLineFromURL(history);
  const focusedLine = isNumber(focusedLineFromURL) ?
    focusedLineFromURL
    :
    (
      (focusedLineIndex !== -1) ?
        focusedLineIndex
        :
        1
    );

  if (!isNumber(navigationItems[focusedLine]?.maxIndex)) {
    const newFocusOn = findFirstEnabledLine(navigationItems);

    if (newFocusOn !== null) {
      return newFocusOn;
    }
  }

  return focusedLine;
};


export {
  findFirstEnabledLine,
  findNavigatebleLineIndex,
  getFocusedLineFromURL,
  getFocusedLineIndex,
  getFocusOn,
  setFocusOn,
};
