import * as React from 'react';



export interface State {
  collectionID: string | undefined;
}

export enum ActionType {
  ChangeCollectionID,
}

export interface Action {
  type: ActionType;
  collectionID: State['collectionID'];
}

type Dispatch = (action: Action) => void;


export const ChannelsContext = React.createContext<State>({} as State);
export const ChannelsDispatchContext = React.createContext<Dispatch | undefined>(undefined);

export const useChannels = () => {
  const context = React.useContext(ChannelsContext);

  if (context === undefined) {
    throw new Error('useChannels must be used within a ChannelsContext')
  }
  return context;
};
export const useChannelsAction = () => {
  const context = React.useContext(ChannelsDispatchContext);

  if (context === undefined) {
    throw new Error('useChannels must be used within a ChannelsContext')
  }
  return context;
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ChangeCollectionID: {
      return ({
        ...state,
        collectionID: action.collectionID,
      });
    }
    default:
      return state;
  }
};
