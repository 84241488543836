/* eslint-disable max-len */
import React from 'react';

interface Props {
  className?: string;
}

export const FilterResetIcon: React.FC<Props> = React.memo((props) => {
  return (
    <svg
      className={ props.className }
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5335 6.71911C20.1555 6.09708 20.1555 5.08856 19.5335 4.46653C18.9115 3.84449 17.903 3.84449 17.2809 4.46653L12 9.74743L6.71935 4.46676C6.09732 3.84473 5.0888 3.84473 4.46677 4.46676C3.84473 5.0888 3.84473 6.09731 4.46677 6.71935L9.74743 12L4.46665 17.2808C3.84461 17.9028 3.84461 18.9113 4.46665 19.5334C5.08868 20.1554 6.0972 20.1554 6.71923 19.5334L12 14.2526L17.281 19.5336C17.9031 20.1556 18.9116 20.1556 19.5336 19.5336C20.1557 18.9116 20.1557 17.9031 19.5336 17.281L14.2526 12L19.5335 6.71911Z"
        fill="currentColor"
      />
    </svg>
  );
});

FilterResetIcon.displayName = 'FilterResetIcon';
