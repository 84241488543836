/* eslint-disable max-len */
import { ac_00, gw_00, gw_02, gw_11 } from "customization-project-name/colors";
import * as React from 'react';

type Props = {
  width?: string
  height?: string
  isFocused: boolean
}

const IconChipsInfo = ({ width = '6.111111111111111vh', height = '6.111111111111111vh', isFocused }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={ isFocused ? ac_00 : gw_02 } />
      <path
        d="M22 33.3867C23.5463 33.3867 24.8 32.1331 24.8 30.5867V20.5067C24.8 18.9603 23.5463 17.7067 22 17.7067C20.4536 17.7067 19.2 18.9603 19.2 20.5067V30.5867C19.2 32.1331 20.4536 33.3867 22 33.3867Z"
        fill={ isFocused ? gw_00 : gw_11 } />
      <path
        d="M22 9.8667C20.4536 9.8667 19.2 11.1203 19.2 12.6667C19.2 14.2131 20.4536 15.4667 22 15.4667C23.5463 15.4667 24.8 14.2131 24.8 12.6667C24.8 11.1203 23.5463 9.8667 22 9.8667Z"
        fill={ isFocused ? gw_00 : gw_11 } />
    </svg>
  </div>
);


export { IconChipsInfo };
