import * as cn from 'classnames';
import { throttle } from 'lodash';
import * as React from 'react';
import { InjectedIntl } from "react-intl";
import { useHistory } from 'react-router-dom';

import NavMenuItemIcon from '~components/NavMenu/line/rootLine/itemIcon';
import { ActionType, useMenu, useMenuAction } from '~components/Provider/Menu';
import useNavMenuFetch from "~hooks/fetch/useNavMenu";
import { ACCOUNT } from '~localization';
import useNavigation, { pageWeComeFromTS } from "~stores/Navigation";
import useNavMenu from '~stores/NavMenu';
import usePointer from '~stores/Pointer';
import Image from '~typings/Image'
import MenuIcon from '~typings/MenuIcon';

import type { LineFocusInfo } from '../component.helpers';
import * as rootCSS from './styles.module.css'


interface Props {
  id: string;
  icon?: MenuIcon;
  title?: string;
  link?: string;
  isFocused?: boolean;
  isActive?: boolean;
  isAuthorized: boolean | null;
  onClick: (coordinates: LineFocusInfo) => void;
  index: number;
  lineIndex: number;
  image: Image | undefined;
  intl: InjectedIntl;
}


const NavMenuItem: React.FC<Props> = (props: Props) => {
  const { isMenuOpened, isPopupOpened } = useMenu();
  const menuAction = useMenuAction();
  const history = useHistory();
  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const setHovered = useNavMenu(state => state.setHovered);
  const { data } = useNavMenuFetch(props.image, props.id)
  const setPageWeComeFrom = useNavigation(state => state.setPageWeComeFrom)

  const handleHover = React.useCallback(throttle<any>(() => {
    if (pointerEnabled) {
      if (!isMenuOpened && !isPopupOpened) {
        menuAction({
          type: ActionType.ChangeMenuState,
          isMenuOpened: true,
        });
      }

      setHovered(true);
    }
  }, 200, { leading: true }), [isMenuOpened, isPopupOpened, pointerEnabled]);

  const handleClick = React.useCallback(() => {
    if (props.link) {
      menuAction({
        type: ActionType.ChangeMenuState,
        isMenuOpened: false,
      });

      setPageWeComeFrom(pageWeComeFromTS.menu)

      history.push(props.link);
    }
    else {
      props.onClick({
        x: props.index,
        lineIndex: props.lineIndex,
      });
    }
  }, [props.link, props.index, props.lineIndex]);

  const isIcon = (
    props.icon !== undefined ||
    props.image !== undefined ||
    props.lineIndex === 0
  );

  const icon = isIcon && <NavMenuItemIcon
      image={ data }
      icon={ props.icon }
      isAuthorized={ props.isAuthorized }
      isFocused={ props.isFocused }
  />;

  const accountTitle = props.icon === MenuIcon.Account

  return (
    <div
      className={ cn(rootCSS.rootLineItem, {
        focusedNavigationNode: props.isFocused,
        [rootCSS.rootLineItemFocused]: props.isFocused,
      }) }
      onClick={ handleClick }
      onMouseOverCapture={ handleHover }
    >
      <div className={ rootCSS.iconWrapper }>{ icon }</div>
      <div className={ rootCSS.titleWrapper }>
        { accountTitle ? props.intl.formatMessage({ id: ACCOUNT }) : props.title }
      </div>
    </div>
  );
};

export const RootLineItem = React.memo(NavMenuItem);
