import { useMutation } from 'react-query';

import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';


export type PasswordCodeResult = Readonly<{
  data: null;
  errors?: {
    object: 'error';
    code: 'invalid_username';
  }[];
  meta: {
    status: number;
  };
}>;

export type PasswordCodeValidityResult = Readonly<{
  data?: {
    valid: boolean;
    object: 'password_reset_code_validity';
  };
  errors?: {
    object: 'error';
    code: 'invalid_username' | 'invalid_reset_password_code' | 'too_many_requests';
  }[];
  meta: {
    status: number;
  };
}>;


const sendCodeReq = async (
  client: ApiClient,
  username: string,
): Promise<PasswordCodeResult> => client.post(
  '/v1/users/passwords/codes',
  {
    username,
  },
);

const validityCodeReq = async (
  client: ApiClient,
  username: string,
  code: string
): Promise<PasswordCodeValidityResult> => client.get(
  '/v1/users/passwords/codes/validity',
  {
    username,
    code
  },
);

export const usePasswordCodeMutation = () => {
  const client = useClient();

  const { mutate: sendPassCode } = useMutation(({ username }: { username: string }) => (
    sendCodeReq(client, username)
  ));

  const { mutate: validatePassCode } = useMutation(({ username, code }: { username: string, code: string }) => (
    validityCodeReq(client, username, code)
  ));

  return { sendPassCode, validatePassCode };
}


export default usePasswordCodeMutation;
