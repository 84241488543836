import { findIndex } from 'lodash';

import Episode from '~typings/Episode';
import Season from '~typings/Season';


const getSeasonByEpisodeID = (seasons?: Season[], episodeID?: string): Season | null => {
  if (!seasons || !episodeID) return null;

  for (let i = 0; i < seasons.length; i += 1) {
    if (seasons[i].episodes?.find(({ id }) => (id === episodeID))) {
      return seasons[i];
    }
  }

  return null;
};

const getSeasonIndexByID = (seasons?: Season[], seasonID?: string): number => {
  if (!seasons || !seasonID) return 0;

  return (
    findIndex(seasons, { id: seasonID }) || 0
  );
};


const getEpisodeIndexByID = (episodes?: Episode[], episodeID?: string): number => {
  if (!episodes || !episodeID) return 0;

  return (
    findIndex(episodes, { id: episodeID }) || 0
  );
};


export {
  getEpisodeIndexByID,
  getSeasonByEpisodeID,
  getSeasonIndexByID,
};
