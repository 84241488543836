import { useEffect } from 'react';

export type useOffsetChangerProps = {
  focusedIndex: number;
  animationDurationInMS: number;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
  updates?: unknown[];
  sliderType: 'vertical' | 'horizontal';
};
/**
 * Хук добавляет offset к слайдеру в зависимости от индекса активного слайда
 */
export const useOffsetChanger = ({
  focusedIndex,
  animationDurationInMS,
  sliderRef,
  sliderType,
  updates = [],
}: useOffsetChangerProps) => {
  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    if(sliderType === 'vertical') {
      updateOffsetForVerticalSlider({
        focusedIndex,
        slider: sliderRef.current,
        animationDurationInMS,
      });
    return;
    }

    updateOffsetForHorizontalSlider({
      focusedIndex,
      slider: sliderRef.current,
      animationDurationInMS,
    });
  }, [focusedIndex, animationDurationInMS, ...updates]);
};

type UpdateOffsetArgs = {
  focusedIndex: number;
  animationDurationInMS: number;
  slider: HTMLElement;
};


const updateOffsetForHorizontalSlider = ({
  focusedIndex,
  slider,
  animationDurationInMS,
}: UpdateOffsetArgs) => {
  const childrenArray = Array.from(slider.children);
  const childRect = childrenArray?.[focusedIndex]?.getBoundingClientRect();

  if (!childRect) {
    return;
  }

  const { left: sliderLeft } = slider.getBoundingClientRect();
  const { left: childLeft } = childRect;
  const offset = Math.abs(sliderLeft - childLeft);

  slider.style.transitionDuration = `${animationDurationInMS}ms`;
  slider.style.transform = `translate3d(${-offset}px, 0, 0)`;
  slider.style.webkitTransform = `translate3d(${-offset}px, 0, 0)`;
};



export const updateOffsetForVerticalSlider = ({
  focusedIndex,
  slider,
  animationDurationInMS,
}: UpdateOffsetArgs) => {
  const childrenArray = Array.from(slider.children);
  const childRect = childrenArray?.[focusedIndex]?.getBoundingClientRect();

  if (!childRect) {
    return;
  }

  const { top: sliderTop } = slider.getBoundingClientRect();
  const { top: childTop } = childRect;
  const offset = Math.abs(sliderTop - childTop);

  slider.style.transitionDuration = `${animationDurationInMS}ms`;
  slider.style.transform = `translate3d(0, ${-offset}px, 0)`;
  slider.style.webkitTransform = `translate3d(0, ${-offset}px, 0)`;
};
