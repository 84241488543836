import * as React from 'react';
import { useHistory } from 'react-router-dom';

import CatchBack from '~components/PageManager/CatchBack';
import { ActionType,useApp, useAppAction } from '~components/Provider/App';
import { useEnv } from '~components/Provider/Env';
import { queryCache } from '~global';
import { logout } from '~hooks/fetch/useAccount';
import goWithReload from '~lib/goWithReload';

import FlagSwitcher from './FlagSwitcher';
import * as styles from './styles.module.css';


const DevPage: React.FC = () => {
  const history = useHistory();
  const env = useEnv();
  const { isIPTVOn } = useApp();
  let prevApi = React.useRef(env.api).current;
  let prevIptv = React.useRef(isIPTVOn).current;
  const applicationAction = useAppAction();

  React.useEffect(() => {
    applicationAction({
      type: ActionType.SetIsLaunchingDone,
      payload: { isLaunchingDone: true },
    })
  }, []);

  const handleBack = () => {
    if (prevApi !== env.api || prevIptv !== isIPTVOn) {
      logout();
      queryCache.clear();

      setTimeout(() => {
        goWithReload(history, '/');
      }, 100);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <CatchBack action={ handleBack } triggers={ [prevApi, env.api, prevIptv, isIPTVOn] } />
      <div className={ styles.container }>
        <FlagSwitcher />
      </div>
    </>
  );
};


export default React.memo(DevPage);
