import * as cn from 'classnames';
import * as React from 'react';

import EMPTY_ARRAY from '~lib/constants/emptyArray';
import getImageSRC from '~lib/MediaItemImageSRC';
import Channel from '~typings/Channel';
import ImageType from '~typings/ImageType';
import { hasChannelCardWide } from '~ui/MediaItem/helpers';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isActive: boolean;
  item?: Channel;
  onClick: () => void;
}>;


const EPGPageChannelItem: React.FC<Props> = (props: Props) => {
  const withChannelCardWide = hasChannelCardWide(props.item?.images || EMPTY_ARRAY);
  const style: React.CSSProperties = {};

  if (withChannelCardWide) {
    const backgroundURL = getImageSRC({
      dimension: ImageType.CHANNEL_CARD_WIDE,
      mediaItem: props.item,
      width: 177,
      height: 102,
      isNeedToCrop: true,
      // isDoubleQuality: true,
    });

    style.backgroundImage = `url(${backgroundURL})`;
  }

  const renderChannelLogo = () => {
    const logoURL = getImageSRC({
      dimension: ImageType.LOGO,
      mediaItem: props.item,
      // width: 164,
      // height: 92,
    });

    return (
      React.createElement(
        'div',
        {
          className: styles.channelLogo,
          style: { backgroundImage: `url('${logoURL}')` },
        },
      )
    );
  };

  const properties = {
    className: cn(styles.channel, {
      [styles.active]: props.isActive,
      'focusedNavigationNode': props.isActive,
    }),
    style,
    onClick: props.onClick,
  };

  return (
    React.createElement(
      'div',
      properties,
      !withChannelCardWide && renderChannelLogo(),
      props.item?.position || '',
    )
  );
};


export default React.memo(EPGPageChannelItem);
