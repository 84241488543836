import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';

import CollectionPageWrapper from '~components/CollectionPage/Wrapper';
import { ResourceType,useInfinityProductsItems } from '~hooks/fetch/useProductsItems/useProductsItems';
import { usePaginationByItems } from '~hooks/usePagination';
import useFilters from '~stores/Filters';
import useNavigationOnLayer from '~stores/LayerNavigation';
import ImageType from '~typings/ImageType';
import Product from '~typings/Product';



type Props = {
  id: string;
  product?: Product;
  resourceTypes: ResourceType[];
  intl: InjectedIntl;
};

const MINIMAL_LINES_ON_SCREEN = 3;

const ProductCollectionPage= ({ resourceTypes, product, id, intl }: Props) => {
  const layerID = `product_collection-${id}`;
  const focusedIndex = useNavigationOnLayer(state => state.getFocusedIndexOnLayer(layerID));
  const { productID } = useParams<{ productID: string }>();

  const selectedFilters = useFilters(state => ({
    quickFilters: state.quickFilters?.[layerID],
    dropdownFilters: state.dropdownFilters?.[layerID],
  }), shallow);

  const resourceTypeHasChannels = resourceTypes.includes(ResourceType.Channels)

  const SLIDES_COUNT_PER_LINE = resourceTypeHasChannels ? 4 : 6;
  const { query, parsedItems: items } = useInfinityProductsItems({
    productID,
    resourceTypes,
    filters: selectedFilters,
  });

  const fetchedCount = (items?.data.length || 0);

  usePaginationByItems({
    currentFetchedCount: fetchedCount,
    focusedLineIdx: Math.floor(focusedIndex / SLIDES_COUNT_PER_LINE),
    elementsPerLine: SLIDES_COUNT_PER_LINE,
    minimalLinesOnScreen: MINIMAL_LINES_ON_SCREEN,
    fetchMoreData: ()=> {
      if(query.hasNextPage && !query.isFetchingNextPage){
        query.fetchNextPage();
      }
    }
  });

  return (
    <CollectionPageWrapper
      layerID={ layerID }
      title={ <>{ intl.formatMessage({ id }) }</> }
      items={ items }
      dimension={ resourceTypeHasChannels ? ImageType.BANNER : ImageType.POSTER }
      filters={ product?.search_filters_group?.items }
      isLoading={ query.isLoading }
      showOnlyFetchedItems
      slidesCountPerLine={ SLIDES_COUNT_PER_LINE }
    />
  );
};


export default injectIntl(ProductCollectionPage);
