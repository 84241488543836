import cn from 'classnames';
import React from 'react';

import { Nullable } from '~lib/type-utils/utils';
import { ProgressBar } from '~newapp/ui-kit/Other/ProgressBar';

import { CardCommonImage } from '../components/CardCommonImage';
import { CardSimpleLabel } from '../types';
import * as css from './styles.module.css';

export interface PropsTS {
  /**
   * Сфокусирована ли карточка (влияет на отображение)
   */
  isFocused: boolean;
  /**
   * Показывать ли блок текста внизу карточки
   */
  showBottomTextBlock?: boolean;
  /**
   * Прогресс карточки, будет отображен, даже если отрицательное значение
   */
  progress?: Nullable<number>;
  /**
   * Иконка провайдера, будет отображена в углу карточки
   */
  contentProviderIconUrl?: Nullable<string>;
  /**
   * Главное наименование карточки
   */
  name?: Nullable<string>;
  /**
   * Дополнительное наименование карточки
   */
  additionalName?: Nullable<string>;
  /**
   *  Тег карточки, будет отображен в углу карточки
   */
  label?: Nullable<CardSimpleLabel>;
  /**
   * Ссылка на изображение
   */
  imageUrl?: Nullable<string>;
  /**
   * Дисклеймер возраста, передавать как строку, например "16+"
   */
  certificationTag?: Nullable<string>;

  /**
   * Включена ли анимация
   *
   * По умолчанию `true`
   */
  isAnimationEnabled?: boolean;
}

export const SquareCard = React.memo((props: PropsTS)=>{
  const {
    isFocused,
    contentProviderIconUrl,
    showBottomTextBlock,
    progress,
    additionalName,
    name,
    label,
    certificationTag,
    imageUrl,
    isAnimationEnabled = true,
  } = props;

  const progressExist = typeof progress === 'number';

  return (
    <div className={ cn(css.card, {
      [css.cardAnimated]: isAnimationEnabled,
    }) }>
      <div className={ css.imageContainer }>
        <div
          className={ cn(
            css.imageContent,
            isFocused ? css.imageContentFocus : css.imageContentUnfocus,
          ) }
        >
          {imageUrl && <CardCommonImage
            imageUrl={ imageUrl }
          />}
          <div className={ css.imageContentInnerIcons }>
            {label && !progressExist && (
              <div
                className={ cn(css.label, css.textOverflow, {
                  [css.labelFocused]: isFocused,
                }) }
                style={ {
                  backgroundColor: label.backgroundColor ?? '#000',
                  color: label.textColor ?? '#fff',
                } }
              >
                {label.text.toUpperCase() || ''}
              </div>
            )}
            {progressExist ? <div className={ css.progress }><ProgressBar progress={ progress } /></div> : null}
            {contentProviderIconUrl && (
              <div
                className={ css.contentProviderIcon }
                style={ { backgroundImage: `url("${contentProviderIconUrl}")` } }
              ></div>
            )}
            {certificationTag && (
              <div className={ css.certTagWrapper }>
                <div className={ css.certTag }>{certificationTag}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showBottomTextBlock && (
        <div className={ css.textContainer }>
          {name && (
            <div
              className={ cn(css.textOverflow, css.name, {
                [css.nameFocus]: isFocused,
              }) }
            >
              { name }
            </div>
          )}
          {additionalName && (
            <div
              className={ cn(css.textOverflow, css.addName, {
                [css.addNameFocus]: isFocused,
              }) }
            >
              {additionalName}
            </div>
          )}
        </div>
      )}
    </div>
  );
});


SquareCard.displayName = 'SquareCard';
