import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ActionType as ChannelsActionType, useChannelsAction } from '~components/Provider/Channels';
import { ActionType, useMenu, useMenuAction } from '~components/Provider/Menu';
import { useMainPageID } from '~hooks/fetch/usePages/usePages';
import { PageInfo } from '~hooks/usePageInfo';
import { getOpenedPopupName, setPopupState } from '~lib/PopupState';
import { isChannelURL } from '~lib/url';
import useNavigation from '~stores/Navigation';

function useHandleBack(pageInfo: PageInfo) {
  const { isMenuOpened, isMenuAppeared, isExitOpened } = useMenu();
  const menuAction = useMenuAction();
  const history = useHistory();
  const channelsActions = useChannelsAction();
  const {
    pageID,
    isAuthPage,
    isProfileSelectPage,
    isCollectionPage,
    isAllowedMenuRender,
    isBuildInPage,
    isSearchPage,
  } = pageInfo;

  const isPopupOpened = !!getOpenedPopupName(history);
  const mainPageID = useMainPageID() || null;
  const isMainPage = (pageID === mainPageID);
  const isChannel = isChannelURL(history.location.pathname);
  const getFocused = useNavigation(state => state.getFocused);
  const setFocusedBlockIndex = useNavigation(state => state.setFocusedBlockIndex);
  const focusedBlockIndex = useNavigation(state => state.getFocusedBlockIndex());
  const setFirstBlock = useNavigation(state => state.setFirstBlock);

  const handleBackUpdate = [
    focusedBlockIndex,
    isAllowedMenuRender,
    isAuthPage,
    isChannel,
    isCollectionPage,
    isExitOpened,
    isMainPage,
    isMenuAppeared,
    isMenuOpened,
    isPopupOpened,
    isProfileSelectPage,
    isBuildInPage,
    isSearchPage,
  ];

  const handleCloseExitPopup = () => {
    menuAction({
      type: ActionType.ChangeExitState,
      isExitOpened: false,
    });

    menuAction({
      type: ActionType.ChangeMenuState,
      isMenuOpened: (pageID !== 'geo-restrict'),
    });
  };

  const handleBack = useCallback(() => {
    if (isExitOpened) {
      handleCloseExitPopup();

      return;
    }

    if (isPopupOpened) {
      setPopupState(history, null);

      return;
    }

    if (isAllowedMenuRender) {
      if ((getFocused()?.index ?? 0) > 0) {
        setFocusedBlockIndex(0);

        return;
      }


      if (focusedBlockIndex > 0) {
        setFirstBlock();

        return;
      }

      if (isMainPage && isMenuOpened) {
        menuAction({
          type: ActionType.ChangeMenuState,
          isMenuOpened: false,
        });

        return;
      } else {
        menuAction({
          type: ActionType.ChangeMenuState,
          isMenuOpened: !isMenuOpened,
        });
        return;
      }
    } else if (!isProfileSelectPage && !isAuthPage && !isSearchPage) {
      if (isChannel) {
        channelsActions({ type: ChannelsActionType.ChangeCollectionID, collectionID: undefined });
      }
      if (!isCollectionPage && !isBuildInPage) {
        history.goBack();
      }

      return;
    }
  }, handleBackUpdate);

  return {
    handleBack,
    handleBackUpdate,
    handleCloseExitPopup,
  } as const;
}


export default useHandleBack;
