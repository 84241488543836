import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import useSpatialNavigation from '~hooks/useSpatialNavigation';
import { NO_AVAILABLE_SUBSCRIPTIONS } from '~localization';
import NavigationDirection from '~typings/NavigationDirection';
import Product from '~typings/Product';
import Subscription from '~typings/Subscription';

import ProductItem from './ProductItem';
import * as styles from './styles.module.css';


type Props = {
  products: Product[] | null;
  subscriptions?: Subscription[] | undefined;
  resourceID: string;
  focusOnMenu: boolean;
  isFetched: boolean;
  onMenu: (arg?: boolean) => void;
  url: string | null | undefined;
};

const ProductsList: React.FC<Props> = ({
  url,
  subscriptions,
  products,
  focusOnMenu,
  isFetched,
  onMenu,
}) => {
  const history = useHistory();
  const navigationItems = React.useRef([
    { maxIndex: 0 }, // Groups
  ]);
  const navigationState = useSpatialNavigation({
    allowNavigation: !focusOnMenu,
    navigationItems: navigationItems.current,
    onLeave: (direction) => {
      if (direction === NavigationDirection.Up) {
        onMenu();
      }
    }
  });
  const focusIndex = navigationState.focusedIndex[0];

  React.useEffect(() => {
    navigationItems.current[0] = { maxIndex: (products?.length ?? 1) - 1 };
  }, [products?.length])

  const handleProductSelect = (productID: string) => {
    if (!url) { return; }

    history.replace(`${url}/subscriptions/${productID}`);
  };

  React.useEffect(() => {
    if (products?.length === 1) {
      history.replace(`${url}/subscriptions/${products[0].id}`);
    }
  }, [url, products]);

  if (!products || !isFetched) { return null; }

  return (
    <div className={ styles.planWrapper }>
      {
        products.length === 0 ? (
          <div className={ styles.plan }>
            <FormattedMessage id={ NO_AVAILABLE_SUBSCRIPTIONS } />
          </div>
        ) : (
          products.map((product, idx) => (
            <ProductItem
              key={ product.id }
              product={ product }
              subscriptions={ subscriptions }
              onClick={ handleProductSelect }
              isFocused={ !focusOnMenu && idx === focusIndex }
            />
          ))
        )
      }
    </div>
  )
};


export default ProductsList;
