import * as React from 'react';

import { IconBar } from '~components/ScenePlayer/Player/controls/usePlayerControlsNavigation';

import IconsBarItem from './item';
import * as styles from './styles.module.css';


type Props = Readonly<{
  focusedIndex?: number;
  icons: IconBar[];
  onClick: (item: IconBar) => void;
}>;


const IconsBar: React.FC<Props> = (props) => {
  return (
    <div
      className={ styles.iconsBar }
    >
      {
        props.icons.map((icon, index) => (
          <IconsBarItem
            key={ icon }
            icon={ icon }
            isFocused={ !!(props.focusedIndex !== undefined && props.focusedIndex === index) }
            onClick={ props.onClick }
          />
        ))
      }
    </div>
  )
};


export default React.memo(IconsBar);
