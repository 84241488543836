import * as cn from 'classnames';
import customization from 'customization-project-name/base-domain.json';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { switchSliderAnimationMS } from '~app/variables.js';
import { CurrentEventProgress } from '~components/CurrentEventProgress';
import { useApp } from '~components/Provider/App';
import SceneItem from '~components/Scene/typings';
import { useDebounceWithIntermediateValueNull } from '~components/Slider/items/items.helpers';
import useFetchCurrentEvent from '~hooks/fetch/useCurrentEvent/useCurrentEvent';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import { CatchupStatus,getCatchupStatus } from '~lib/eventsHelper';
import { getScheduleTime,getShortDate } from '~lib/localeUtils/dates';
import getImageSRC from '~lib/MediaItemImageSRC';
import { ON_AIR_DATE,RECORD_DATE, SEE_ALL_ITEMS } from '~localization';
import { DataItem } from '~typings/DataItem';
import ItemObject from '~typings/ItemObject';
import SeeAll from "~typings/SeeAll";
import Series from '~typings/Series';
import { Label } from '~ui/Label';
import { Marker } from '~ui/Marker';
import getMediaItemWrapperStyles from '~ui/MediaItem/getCalculateStyles';
import { ProgressBar } from '~ui/ProgressBar';

import { MediaItemProps } from './component';
import MediaItemContent from './content';
import MediaItemGenres from './genres';
import MediaItemName from './name';
import RenderIcon from './renderIcon';
import * as styles from './styles.module.css';


const DEBOUNCE_VALUE = switchSliderAnimationMS + 200;


const MediaItem: React.FC<MediaItemProps> = (props: MediaItemProps) => {
  const matchPage = useRouteMatch('/page/:id');
  const { language } = useApp();
  const history = useHistory();
  const asSeries = (
    (props.showAsSeries === true && props.item?.object === ItemObject.Episode)
    || props.item?.object === ItemObject.Series
  );
  const imageURL = getImageSRC({
    dimension: props.dimension,
    mediaItem: (asSeries && props.item?.object === ItemObject.Episode && props.item.series) ?
      props.item.series as Series
      :
      (props.item?.object === ItemObject.SeeAll) ? undefined : props.item as SceneItem,
    isNeedToCrop: true,
    isDoubleQuality: true,
  });
  const progress = (props.withProgress) ?
    props?.percentWatched ?? 0
    :
    undefined;
  const isProgramEvent = props.item?.object === ItemObject.ProgramEvent;
  const isChannel = props.item?.object === ItemObject.Channel;
  const [catchupStatus] = (props.item?.object === ItemObject.ProgramEvent) ?
    getCatchupStatus(props.item)
    :
    [CatchupStatus.Unknown, 0];
  const delayedChannelId = useDebounceWithIntermediateValueNull(
    isChannel && props.withCurrentEvent ? props.item?.id : null,
    DEBOUNCE_VALUE,
  );
  const { data: currentEvent, isFetched } = useFetchCurrentEvent(delayedChannelId || undefined);

  const handleClick = () => {
    if (props.item && props.isFocused) {
      if (props.item.object === ItemObject.SeeAll) {
        history.push(props.item.deeplink);
        return;
      }

      if (props.onClick) {
        props.onClick(props.item);

        if (
          (
            props.item.object === ItemObject.Episode ||
            props.item.object === ItemObject.Part
          ) ||
          props.forceOnClick
        ) {
          return;
        }
      }

      if (props.item.object === ItemObject.ProgramEvent) {
        if (catchupStatus === CatchupStatus.Catchup) {
          history.push(`/media-item/${ItemObject.ProgramEvent}/${props.item.id}/player?player=true`);
          return;
        } else if (catchupStatus === CatchupStatus.OnAir) {
          history.push(`/media-item/${ItemObject.Channel}/${props.item.channel_id}?player=true`);
          return;
        } else if (catchupStatus === CatchupStatus.Future) {
          history.push(`/media-item-details/${ItemObject.ProgramEvent}/${props.item.id}`);
          return;
        }
      }

      if (matchPage) {
        history.push(`${matchPage.url}/media-item/${props.item.object}/${props.item.id}${
          props.item.object === ItemObject.Channel ? '?player=true' : ''
        }`);
      } else {
        history.push(`/media-item/${props.item.object}/${props.item.id}${
          props.item.object === ItemObject.Channel ? '?player=true' : ''
        }`);
      }
    }
  };
  
  const renderRaitingCards = (str: string | null) => {
    if(customization === 'spbtv.com') {
      if((props.item as any)?.certification_ratings) {
        if(str?.includes("394x592") || str?.includes("328x492")) {
          return (
            <div className={ cn(styles.ratingText, styles.certRating) }>
                {(props.item as any).certification_ratings[0]?.tag}
            </div>
          )
        } else {
          return (
          <div className={ cn(styles.ratingText, styles.certRating, styles.ratingTextHorizontal) }>
            {(props.item as any).certification_ratings[0]?.tag}
          </div>
          )
        }
      } 
    } else {
      return null;
    }
      
  }

  const sortedCards = renderRaitingCards(imageURL);

  const renderProgress = (!props.isFocused || props.withCurrentEvent) ? (
  (
    (
      props.item?.object === ItemObject.ProgramEvent
      && catchupStatus === CatchupStatus.OnAir
      ) || (
        props.item?.object === ItemObject.Channel
        && currentEvent?.data[0]
      )
  ) ?
    <CurrentEventProgress
      startAt={
        props.item?.object === ItemObject.Channel ?
          currentEvent?.data?.[0].start_at
          :
          props.item.start_at
      }
      endAt={
        props.item?.object === ItemObject.Channel ?
          currentEvent?.data?.[0].end_at
          :
          props.item.end_at
      }
      className={ styles.currentEventProgress }
      progressClassName={ styles.progressLine }
    />
    : (typeof progress !== 'undefined') ?
      <ProgressBar progress={ progress } />
      : null
  ) : null;
  const renderMarkers = (item: DataItem) => (
    (((item as any)?.markers ?? EMPTY_ARRAY).length !== 0) ?
      <Marker
        className={ styles.marker }
        markers={ (item as any)?.markers }
        dimension={ props.dimension }
      />
      :
      null
  );
  const renderLabels = (item: DataItem) => (
    (((item as any)?.external_catalog?.images ?? EMPTY_ARRAY).length !== 0) ?
      <Label
        className={ styles.label }
        images={ (props.item as any).external_catalog.images }
      />
      :
      null
  );
  const renderSeeAll = (item?: SeeAll) => (
    (item && item.object === ItemObject.SeeAll) ?
      <>
        {
          React.createElement(
            'div',
            {
              key: item.id,
              className: cn(styles.content, {
                [styles.isFocused]: props.isFocused,
              }),
            },
          )
        }
        <div className={ styles.seeAll }>
          { props.intl.formatMessage({ id: SEE_ALL_ITEMS }) }
        </div>
      </>
      :
      null
  );
  const renderName = (item: DataItem) => {
    const itemName = (isChannel && props.withCurrentEvent) ?
        !isFetched ?
          '…'
          : currentEvent?.data?.[0]?.name ?? (item as any)?.name ?? ''
        : (item as any)?.name ?? '';

    return (
      (itemName.length) ?
        !props.isFocused ?
          <MediaItemName name={ itemName } isFocused={ false }/>
          : <MediaItemName name={ itemName } isFocused={ true }/>
        : null
    );
  };
  const renderGenres = (item: DataItem) => {
    if (item && item.object === ItemObject.ProgramEvent) {
      return (
        <div className={ styles.genre }>
          {
            catchupStatus === CatchupStatus.Catchup ?
              props.intl.formatMessage({ id: RECORD_DATE }, {
                date: getShortDate(item.start_at, language),
                time: getScheduleTime(item.start_at),
              })
              : catchupStatus === CatchupStatus.Future ?
              props.intl.formatMessage({ id: ON_AIR_DATE }, {
                date: getShortDate(item.start_at, language),
                time: getScheduleTime(item.start_at),
              })
              : catchupStatus === CatchupStatus.OnAir ?
                getScheduleTime(item.start_at)
                :
                null
          }
        </div>
      );
    }

    if (props.item?.object === ItemObject.Channel && props.withCurrentEvent) {
      if (!isFetched) {
        return <div className={ styles.genre }>…</div>;
      } else if (currentEvent?.data?.[0]) {
        return (
          <div className={ styles.genre }>
            { props.item?.name ?? '' }
          </div>
        );
      }
    }

    const itemGenres = (item as any)?.genres ?? EMPTY_ARRAY;

    return (
      (itemGenres.length) ?
        !props.isFocused ?
          <MediaItemGenres genres={ itemGenres } isFocused={ false } />
          : <MediaItemGenres genres={ itemGenres } isFocused={ true } />
        : null
    );
  };
  const content = (
    item: DataItem,
    renderIcon: JSX.Element | null,
    renderName: JSX.Element | null,
    renderGenres: JSX.Element | null,
  ) => {
    if (renderIcon || renderName || renderGenres) {
      return (
        React.createElement(
          'div',
          {
            key: item.id,
            className: cn(styles.content, {
              [styles.isFocused]: props.isFocused || !!props.alwaysShowName,
              [styles.isProgramEvent]: isProgramEvent && catchupStatus !== CatchupStatus.OnAir,
            }),
          },
          renderIcon,
          renderName,
          renderGenres,
        )
      );
    }

    return null;
  };
  const renderContent = (item?: SceneItem): JSX.Element | null => (
    (item) ?
      (
        <>
          { renderProgress }
          <MediaItemContent
            item={ item }
            imageURL={ imageURL }
            dimension={ props.dimension }
            showAsSeries={ asSeries }
            isFocused={ props.isFocused }
          />
          {renderMarkers(item)}
          {renderLabels(item)}
          {sortedCards}
          {
            content(
              item,
              RenderIcon({
                item,
                catchupStatus,
                isFocused: props.isFocused,
              }),
              props.withName ? renderName(item) : null,
              props.withGenres ? renderGenres(item) : null,
            )
          }
        </>
      )
      :
      null
  );

  const style = getMediaItemWrapperStyles({
    imageURL,
    top: props.top,
    left: props.left,
    item: props.item,
    dimension: props.dimension,
  });
  const properties = {
    key: props.itemKey,
    style: style,
    className: props.className,
    onClick: handleClick,
  };

  return (
    React.createElement(
      'div',
      properties,
        (props.item?.object === ItemObject.SeeAll) ?
          renderSeeAll(props.item)
          :
          renderContent(props.item as SceneItem | undefined),
    )
  );
};


export default React.memo(MediaItem);
