import {
  ACCOUNT_LABEL_PASSWORD,
  ACCOUNT_LABEL_USERNAME,
} from '~localization';
import { AccountField as Field } from '~typings/AccountInfo';


export const getFieldName = (field: Field, intl): string | undefined => {
  const FIELDS: { [key in Field]: string } = {
    [Field.Username]: intl.formatMessage({ id: ACCOUNT_LABEL_USERNAME }),
    [Field.Login]: 'IPTV Login',
    [Field.Password]: intl.formatMessage({ id: ACCOUNT_LABEL_PASSWORD }),
  };

  return (FIELDS[field] || undefined);
};
