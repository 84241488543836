import React from 'react';

import { useApp } from '~components/Provider/App';
import { useEnv } from '~components/Provider/Env';
import { useNetwork } from '~global';
import { api as projectApi } from '~lib/env-utils';

import { cleanPathname } from './DevManager.helper';
import { useDevUtilsOpener } from './DevManager.hooks';

const DevManager$: React.FC = () => {
  const env = useEnv();
  const { isIPTVOn } = useApp();
  const IsIPTVSupported = useNetwork().iptv_supported;

  useDevUtilsOpener();

  if (projectApi !== env.api || (IsIPTVSupported && isIPTVOn === false)) {
    return (
      <div
        style={ {
          position: 'fixed',
          top: 0,
          right: 0,
          color: 'red',
          padding: '10px',
          zIndex: 999999,
        } }
      >
        {cleanPathname(projectApi) !== cleanPathname(env.api) && cleanPathname(env.api)}
        {IsIPTVSupported && (
          <>
            <span>{' | IPTV: '}</span>
            <span>{isIPTVOn ? 'ON' : 'OFF'}</span>
          </>
        )}
      </div>
    );
  }

  return null;
};

export const DevManager = React.memo(DevManager$);
