import serviceInfoByLang from 'customization-project-name/service-info';
import { get } from 'lodash';
import * as React from 'react';

import { ActionType, useApp, useAppAction } from '~components/Provider/App';


const TermsScreen: React.FC = () => {
  const [text, setText] = React.useState<string | null>(null);
  const { language, isLaunchingDone } = useApp();
  const appAction = useAppAction();
  const htmlBundle = get(serviceInfoByLang, `terms.${language}`);
  const handleBundleLoad = (htmlContent) => {
    setText(htmlContent.default);
  };

  React.useEffect(() => {
    if (isLaunchingDone) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: { isLaunchingDone: false },
      });
    }
  }, []);

  React.useEffect(() => {
    htmlBundle().then(handleBundleLoad);
  }, [htmlBundle]);

  React.useEffect(() => {
    if (text !== null && !isLaunchingDone) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: { isLaunchingDone: true },
      });
    }
  }, [text, isLaunchingDone]);

  if (text === null) { return null; }

  return (
    <div dangerouslySetInnerHTML={ { __html: text } } />
  );
};


export default TermsScreen;
