import Enviroment from '~typings/Enviroment';
import PlatformName from '~typings/PlatformName';
import ProjectName from '~typings/ProjectName';


type Props = Readonly<{
  project: ProjectName;
  platform: PlatformName;
  env: Enviroment;
  inPackage: boolean;
}>;

const buildAppId = ({ project, platform, env, inPackage = false }: Props): string =>
  `${project}-${platform}-${env}${inPackage ? '-package' : ''}`;

export default buildAppId;
