import cn from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { NAV_LEGEND_PLAY_CHANNEL } from '~localization';

import * as styles from './styles.module.css';


type Props = Readonly<{
  className?: string
}>

const WatchButton:React.FC<Props> = (props: Props) => (
  <div className={ cn(styles.watchButton, props.className) }>
    <FormattedMessage id={ NAV_LEGEND_PLAY_CHANNEL } />
  </div>
);

export default WatchButton;
