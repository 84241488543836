import cn from 'classnames';
import * as React from 'react';

import Genres from '~typings/Genre';

import * as styles from './styles.module.css';


export type Props = {
  genres: Genres[];
  isFocused: boolean;
};


const MediaItemGenres: React.FC<Props> = (props: Props) => (
  props.isFocused ? (
    <div className={ cn(styles.genre, styles.focusedGenre) }>
      { props.genres.map(genre => genre.name).join(', ') }
    </div>
  ) : (
    <div className={ styles.genre }>
      { props.genres.map(genre => genre.name).join(', ') }
    </div>
  )
  
);


export default React.memo(MediaItemGenres);
