import { QueryKey, useQuery } from 'react-query';
import Queue from 'spbtv-promise-queue';

import { queryClient } from '~global';
import { useClient, useNetwork } from '~global';
import ProgramEvent from '~typings/Event';
import ResponseMany from '~typings/ResponseMany';

/**
 * @deprecated, устаревшая ручка для получения текущего события
 */
const PREFIX_OF_A_COMPOSITE_KEY = 'current_event';
const queue = new Queue(1, Infinity);


type ProgramEvents = ResponseMany<ProgramEvent[]>;

type Options = {
  channel_id: string;
  event_counts: number;
  region_uid?: string;
};

const getCurrentEventKey = (channelID?: string| null): QueryKey =>[
  PREFIX_OF_A_COMPOSITE_KEY,
  channelID,
];

/**
 * @deprecated, устаревшая ручка для получения текущего события
 */
const getCachedCurrentEvent = (channelID?: string | null) => {
  const queryKey = getCurrentEventKey(channelID);

  return queryClient.getQueryData<ProgramEvents>(queryKey);
};

/**
 * @deprecated, устаревшая ручка для получения текущего события
 */
const fetch = async (channelID, regionUID, eventCounts, client) => {
  const url = '/v2/epg/upcoming_program_events.json';

  const options: Options = {
    channel_id: channelID,
    event_counts: eventCounts,
  };

  if (regionUID) {
    options.region_uid = regionUID;
  }

  return queue.add(() => new Promise((resolve, reject) => {
    client
      .get(url, options)
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
  }));
};


/**
 * @deprecated, устаревшая ручка для получения текущего события
 */
const useFetchCurrentEvent = (channelId?: string | null, eventCounts = 1) => {
  const queryKey = getCurrentEventKey(channelId);
  const client = useClient();
  const network = useNetwork();

  return useQuery<ProgramEvents>({
    queryKey,
    queryFn: () => fetch(channelId, network.region_uid, eventCounts, client),
    enabled: !!channelId,
    structuralSharing: true
  });
};

export {
  getCachedCurrentEvent,
  PREFIX_OF_A_COMPOSITE_KEY,
};

export default useFetchCurrentEvent;
