import { useEffect, useMemo, useRef, useState } from 'react';

import { getAuthData } from '~hooks/fetch/useAccount';
import useBlocksItems from '~hooks/fetch/useBlocksItems/useBlocksItems';
import usePrevious from '~hooks/usePrevious';
import { Block, BlockType } from '~typings/Block';
import { DataItem } from '~typings/DataItem';
import Page from '~typings/Page';
import PageObject from '~typings/PageObject';
import ResponseMany from '~typings/ResponseMany';
import ResponseSingle from '~typings/ResponseSingle';
import SearchFiltersGroup from '~typings/SearchFiltersGroup';

import { allowedBlocks, getPreparedBlockToFetch } from './index.helpers';


type DataType = ResponseMany<DataItem[]> | ResponseSingle<DataItem>;

export type ItemsByBlockID = {
  [blockID in string]?: DataType | SearchFiltersGroup | null;
}

type Result = Readonly<{
  isFetchingDone: boolean;
  itemsByBlockID: ItemsByBlockID;
  blocks: Block[];
}>;


const useBlocks = (page?: Page): Result => {
  const { accessToken } = getAuthData();
  const itemsByBlockID = useRef<ItemsByBlockID>({});
  const [isFetchingDone, setIsFetchingDone] = useState<boolean>(false);
  const [isAllowCheck, setIsAllowCheck] = useState<boolean>(false);

  const previousPageID = usePrevious(page?.id);
  const blocksAllowed = allowedBlocks(!!accessToken, (page?.blocks || []));

  const blocksToFetch = useMemo(() => {
    setIsAllowCheck(false);

    setTimeout(() => {
      setIsAllowCheck(true)
    }, 400);

    return getPreparedBlockToFetch(blocksAllowed, page?.id);
  }, [page?.id]);

  const { blocksItems } = useBlocksItems(blocksToFetch);

  useEffect(() => {
    if (page && isAllowCheck) {
      if (
        page.object === PageObject.SingleCollectionPage
        || page.object === PageObject.SingleCardCollectionPage
        || page.object === PageObject.ContentPage
      ) {
        if (blocksItems.every(x => !x.isLoading)) {
          itemsByBlockID.current = {};
          const filterBlockIndex = blocksAllowed.findIndex(x => x.object === BlockType.FiltersBlock);

          const itemsWithFilters = filterBlockIndex >= 0
            ? [
              ...blocksItems.slice(0, filterBlockIndex),
              null,
              ...blocksItems.slice(filterBlockIndex),
            ]
            : blocksItems;

          blocksAllowed.forEach((block, idx) => {
            const data = (itemsWithFilters?.[idx]?.data as DataType)?.data;
            if (Array.isArray(data) ? data.length > 0 : data) {
              itemsByBlockID.current[block.id] = itemsWithFilters?.[idx]?.data as DataType;
            }
            if (block.object === BlockType.FiltersBlock) {
              itemsByBlockID.current[block.id] = block.search_filters_group;
            }
          });

          setIsFetchingDone(true);
        }

        else {
          setIsFetchingDone(false);
        }
      }
      else {
        setIsFetchingDone(true);
      }
    }
  }, [blocksItems, page?.id, isAllowCheck]);

  useEffect(() => () => {
    setIsFetchingDone(false);
  }, [page?.id]);

  const allowedBlocksWithItemsIDs = Object.keys(itemsByBlockID.current);

  const blocks = blocksAllowed.filter((block: Block) => allowedBlocksWithItemsIDs.includes(block.id));

  return ({
    isFetchingDone: (
      (page?.id !== previousPageID)
        ? false
        : isFetchingDone
    ),
    itemsByBlockID: itemsByBlockID.current,
    blocks,
  });
};


export default useBlocks;
