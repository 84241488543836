import * as cn from 'classnames';
import * as React from 'react';

import { progressInPercent } from '~lib/eventsHelper';

import CurrentEventProgressRender from './progress';
import * as styles from './styles.module.css';


type Props = Readonly<{
  className?: string;
  progressClassName?: string;
  startAt?: string;
  endAt?: string;
}>;


const CurrentEventProgress: React.FC<Props> = (props: Props) => {
  const [progress, setProgress] = React.useState<number>(
    (props.startAt && props.endAt) ?
      progressInPercent(props.startAt, props.endAt, new Date())
      :
      0
  );
  const timeout = React.useRef<any>(null);
  const handleClearTimeout = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };
  const handleChangeProgress = React.useCallback((startAt: string, endAt: string) => {
    handleClearTimeout();

    setProgress(progressInPercent(startAt, endAt, new Date()));

    timeout.current = setTimeout(() => {
      handleChangeProgress(startAt, endAt);
    }, 5000);
  }, []);

  React.useEffect(() => handleClearTimeout, []);

  React.useEffect(() => {
    if (props.startAt && props.endAt) {
      handleChangeProgress(props.startAt, props.endAt);
    } else {
      setProgress(0);
    }

    return handleClearTimeout;
  }, [props.startAt, props.endAt]);

  const properties = {
    className: cn(styles.currentEvent, props.className),
  };

  return (
    React.createElement(
      'div',
      properties,
      (progress !== 0) &&
        CurrentEventProgressRender({
          progress,
          progressClassName: props.progressClassName,
        })

    )
  );
};


export default CurrentEventProgress;
