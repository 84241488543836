import * as React from 'react';

import Fade from '~ui/Effects/Fade';


type Props = Readonly<{
  index: number;
  focusedLine: number;
  isDirectionUp: boolean;
  children: any;
}>;


const FADE_DURATION = 150;
const FADE_DELAY = 350;

const LineDelayScrollFade: React.FC<Props> = (props: Props) => {
  const fadeIsVisible = (props.focusedLine <= props.index);
  const fadeDelay = (props.isDirectionUp && fadeIsVisible) ? FADE_DELAY : 0;

  return (
    <Fade
      isVisible={ fadeIsVisible }
      duration={ FADE_DURATION }
      delay={ fadeDelay }
    >
      { props.children }
    </Fade>
  );
};


export default React.memo(LineDelayScrollFade);
