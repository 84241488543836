import * as cn from 'classnames';
import { throttle } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useApp } from '~components/Provider/App';
import { useFavoriteItem, useToggleFavorite } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { favoriteTypeByItemObject } from '~hooks/fetch/useFavorites/useFavoritesV3.helpers';
import useWeel from '~hooks/useScroll/useWeel';
import { setFocusOn } from '~hooks/useSpatialNavigation/helpers';
import useSwitchChannels from '~hooks/useSwitchChannel';
import useSwitchChannelByRemoteDigitCode from '~hooks/useSwitchChannelByRemoteDigitCode';
import { getOpenedPopupName, setPopupState } from '~lib/PopupState';
import * as css from "~newapp/blocks/playerPopcorn/Player/controls/styles.module.css";
import Episode from '~typings/Episode';
import FavoriteType from '~typings/FavoriteType';
import ItemObject from '~typings/ItemObject';
import NavigationDirection from '~typings/NavigationDirection';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Stream from '~typings/Stream';

import PlayerPopup from '../../Player/controls/Popup';
import RemoteDigitCode from '../../Player/controls/RemoteDigitCode';
import AudioShowScene from '../../Player/controls/Scene/AudioShow';
import { isChannel, isEpisode, isProgramEvent } from '../../Player/utils';
import { IPlayer } from '../../playerLib/typings';
import { PlayerState, PlayerStatus } from '../useHandleEvents';
import IconsBar from './IconsBar';
import { ItemInfoRouter } from './ItemInfo';
import NextEpisode from './NextEpisode/NextEpisode';
import PlayPauseProgressBar from './PlayPauseProgressBar';
import SkipIntervalsButton from './SkipIntervalsButton/SkipIntervalsButton';
import * as styles from './styles.module.css';
import SubTitle from './Subtitle';
import useCurrentTime from './useCurrentTime';
import usePlayerControlsNavigation, {
  getIconsBarFocusedItemIndex,
  IconBar,
  PlayerControl,
  PlayerLine,
} from './usePlayerControlsNavigation';
import usePlayerHotKeysPausePlayStop from './usePlayerHotKeysPausePlayStop';
import usePlayerControlsIsVisible from './useVisible'
import useWatchProgressUpdate from './useWatchProgressUpdate';


type Props = Readonly<{
  player: IPlayer;
  item: PlayerMediaItem;
  state: PlayerState;
  durationInSec: number;
  stream: Stream;
  getCurrentTimeInSec: () => number;
  onClickPlayPause: () => void;
  onSeek: (seekTo: number) => void;
}>;


const PlayerControls: React.FC<Props> = (props) => {
  const history = useHistory();
  const [isVisibleGoToAir, setIsVisibleGoToAir] = React.useState<boolean>(false);
  const popupName: IconBar | null = getOpenedPopupName(history);
  const isVisiblePopup = !!getOpenedPopupName(history);
  const { isAuthorized } = useApp();
  const { state } = props;
  const isPaused = React.useMemo(() => (state.status === PlayerStatus.Pause), [state.status]);

  const skipIntervalAndNextContentArr = props.stream.chapters.filter(
    (item) => item.kind === 'next_content' || item.kind === 'skip_interval');

  const {
    isVisible: playerControlsIsVisible,
    handleShowControls,
    isSeeking,
  } = usePlayerControlsIsVisible(state.status, isVisiblePopup);

  const isVisible = React.useMemo(() => (
    (playerControlsIsVisible || isPaused)
  ), [playerControlsIsVisible, isPaused]);
  const isVisibleMediaItemInfo = React.useMemo(() => (
    (
      isVisible && (!isVisiblePopup || popupName === IconBar.EPG)
    )
    || (popupName === IconBar.EPG)
  ), [isVisible, popupName, isVisiblePopup]);
  const {
    mutate: toggleFavorite,
    isLoading: isLoadingToggleFavorite,
  } = useToggleFavorite();
  const itemID = props.item.object === ItemObject.Episode ?
    props.item.series?.id || props.item.id
    : props.item.object === ItemObject.Part ?
      props.item.audio_show?.id || props.item.id
      : props.item.id;

  const favoriteItemQuery = useFavoriteItem(favoriteTypeByItemObject[props.item.object], props.item.id);
  const isInFavorites = Boolean(favoriteItemQuery.data);


  const currentEvent = isChannel(props.item)
  const { iconBar, controls, navState } = usePlayerControlsNavigation({
    isAuthorized,
    isInFavorites,
    item: props.item,
    allowNavigation: isVisible,
    isVisibleGoToAir,
    isHasInfo: currentEvent,
    duration: props.durationInSec,
  });

  const currentTimeInSec = useCurrentTime(props.getCurrentTimeInSec);
  const isChannelOrProgramEvent = React.useMemo(() => (
    (isChannel(props.item) || isProgramEvent(props.item))
  ), [props.item.object]);

  const handleControlsVisibility = () => {
    handleShowControls(true);
  };

  useWeel(handleControlsVisibility, [handleControlsVisibility]);

  const handleBarClick = React.useCallback((barItem: IconBar) => {
    if (
      [IconBar.FavoriteToggleOff, IconBar.FavoriteToggle].includes(barItem)
      && !isLoadingToggleFavorite
      && Object.keys(FavoriteType)
        .some(key => FavoriteType[key] === favoriteTypeByItemObject[props.item.object])
    ) {
      toggleFavorite({ itemID, isInFavorites, type: favoriteTypeByItemObject[props.item.object] });
    }
    else if (barItem === IconBar.EPG) {
      setFocusOn(history, null);
      setPopupState(history, IconBar.EPG);
    }
    else if (barItem === IconBar.EpisodesList) {
      setFocusOn(history, null);
      setPopupState(history, IconBar.EpisodesList);
    }
    else if (barItem === IconBar.ChannelsList) {
      setFocusOn(history, null);
      setPopupState(history, IconBar.ChannelsList);
    }
    else if (barItem === IconBar.Info) {
      setFocusOn(history, null);
      setPopupState(history, IconBar.Info);
    }
    else if (barItem === IconBar.AudioTracks) {
      setPopupState(history, IconBar.AudioTracks);
    }
    else if (barItem === IconBar.TextTracks) {
      setPopupState(history, IconBar.TextTracks);
    }
    else if (barItem === IconBar.VideoTracks) {
      setPopupState(history, IconBar.VideoTracks);
    }
  }, [itemID, props.item.object, isInFavorites, isLoadingToggleFavorite]);

  const handleIsVisibleGoToAir = React.useCallback((isVisible: boolean) => {
    setIsVisibleGoToAir(isVisible);
  }, []);

  const handleHidePlayerPopup = React.useCallback(() => {
    setPopupState(history, null);
  }, []);

  usePlayerHotKeysPausePlayStop({
    player: props.player,
    onClickPlayPause: props.onClickPlayPause,
  });
  useSwitchChannels({
    isMounted: isChannelOrProgramEvent,
    popupName,
    onClosePopup: handleHidePlayerPopup,
  });
  const remoteDigitCode = useSwitchChannelByRemoteDigitCode({
    isMounted: isChannelOrProgramEvent,
    onClosePopup: handleHidePlayerPopup,
  });
  useWatchProgressUpdate({
    item: props.item,
    durationInSec: props.durationInSec,
    currentTimeInSec,
  })

  const handleEnter = React.useCallback(throttle<any>(() => {
    if (navState.focusOn === PlayerLine.IconBar) {
      navState.handleKeyNavigate(NavigationDirection.Up);
    }
    if (!isVisible) {
      handleShowControls(false, true);
    }
  }, 400, { leading: false }), [navState.focusOn, handleShowControls, isVisible]);
  const handleLeave = React.useCallback(throttle<any>(() => {
    if (isVisible) {
      handleShowControls(false, false);
    }
  }, 400, { leading: false }), [handleShowControls, isVisible]);

  const isLoading = (
    state.status === PlayerStatus.Loading ||
    state.status === PlayerStatus.Waiting ||
    state.status === PlayerStatus.Seeking ||
    isSeeking
  );
  // console.log('state.status:', state.status, { isLoading, isSeeking });
  // const renderLoader = React.useMemo(() => (
  //   <PlayerLoader
  //     className={ cn(styles.loader, {
  //       [styles.hidden]: !isLoading,
  //     }) }
  //   />
  // ), [isLoading]);
  const renderIconsBar = () => {
    if (isChannel(props.item) && currentEvent === null) {
      return null;
    }

    if (controls.includes(PlayerControl.IconBar)) {
      return (
        <IconsBar
          focusedIndex={
            (isVisible && (navState.focusOn === PlayerLine.IconBar))
              ? getIconsBarFocusedItemIndex(navState.focusedIndex)
              : undefined
          }
          icons={ iconBar }
          onClick={ handleBarClick }
        />
      );
    }

    return null;
  };


  const renderSkipIntervalAndNextContent = React.useCallback(() => {
    if (skipIntervalAndNextContentArr.length !== 0) {
      return (
        skipIntervalAndNextContentArr.map((interval, index) => {
            const { begin_timestamp, button_text, kind } = interval;
            if ((currentTimeInSec > interval.begin_timestamp && currentTimeInSec < interval.begin_timestamp + 10)) {
              if (kind === 'skip_interval' || kind === 'next_content') {
                const hasDuplicateTime = skipIntervalAndNextContentArr.some(
                  (btn, i) => i !== index && btn.begin_timestamp === begin_timestamp
                );
                if (hasDuplicateTime) {
                  ////// Найдена другой интервал('next_content') с таким же begin_timestamp
                  ////// как и у интервала 'skip_interval'. Отображаем текст этого интервала.
                  if (kind === 'skip_interval') {
                    return (
                      <SkipIntervalsButton
                        buttonText={ button_text ?? '' }
                        key={ index }
                        isFocused={ !isVisible || navState.focusOn === PlayerLine.SkipButton }
                        focusedIndex={ navState.focusedIndex[0] }
                      />
                    )
                  }
                  else {
                    ////// Для кнопок типа 'next_content' не отображаем текст.
                    return null;
                  }
                }
                else {
                  ////// Нет других кнопок с таким же begin_timestamp
                  return (
                    <SkipIntervalsButton
                      key={ index }
                      buttonText={ button_text ?? '' }
                      isFocused={ !isVisible || navState.focusOn === PlayerLine.SkipButton }
                      focusedIndex={ navState.focusedIndex[0] }
                    />
                  )
                }
              }
            }
          }
        )
      )
    }
    return null;
  }, [currentTimeInSec, isVisible, navState]);

  const handleClickSkipIntervalAll = React.useCallback(throttle<any>(() => {
      return (
        skipIntervalAndNextContentArr.map((interval) => {
          if (currentTimeInSec > interval.begin_timestamp
            && currentTimeInSec < interval.end_timestamp
          ) {
            props.onSeek(interval.end_timestamp);
          }
        })
      )
    }, 400, { leading: false }),
    [currentTimeInSec, props.durationInSec]
  )

  const renderControls = (isVisiblePopup)
    ?
    <>
      <PlayerPopup
        player={ props.player }
        item={ props.item }
        popupName={ popupName }
        isVisiblePopup={ isVisiblePopup }
        onClose={ handleHidePlayerPopup }
      />
    </>
    : <div
      className={ cn(styles.playerControlsWrapper, styles.opacityHidden, {
        [styles.opacityShow]: isVisible,
      }) }
      onClick={ handleControlsVisibility }
    >
      <div
        className={ styles.playerControls }
        onMouseOverCapture={ handleEnter }
        onMouseLeave={ handleLeave }
      >
        <RemoteDigitCode channelCode={ remoteDigitCode } />
        <div className={ css.playerControls2 }>
          {
            (controls.includes(PlayerControl.PlayPauseProgressBar)) &&
              <PlayPauseProgressBar
                  item={ props.item }
                  playerState={ state.status }
                  isLoading={ isLoading }
                  isFocused={ ((navState.focusOn === PlayerLine.PlayPauseProgressBar) && isVisible) }
                  isFocusedGoToAir={ (isVisible && (navState.focusOn === PlayerLine.OnAir)) }
                  durationInSec={ props.durationInSec }
                  currentTimeInSec={ currentTimeInSec }
                  onChangeIsVisibleGoToAir={ handleIsVisibleGoToAir }
                  onClick={ props.onClickPlayPause }
                  onSeek={ props.onSeek }
                  stream={ props.stream }
              />
          }
        </div>
        { renderIconsBar() }
      </div>
    </div>


  return (
    <>
      { (props.item.object === ItemObject.Part) && <AudioShowScene item={ props.item } /> }
      {/*  renderLoader  */ }
      {
        isEpisode(props.item) ?
          (
            <NextEpisode
              player={ props.player }
              isFocused={ (navState.focusOn === PlayerLine.NextEpisode) }
              focusedIndex={ navState.focusedIndex[0] }
              currentEpisode={ props.item as Episode }
              currentTimeInSec={ currentTimeInSec }
              durationInSec={ props.durationInSec }
              onChangeIsVisibleGoToAir={ handleIsVisibleGoToAir }
            />
          )
          :
          null
      }
      <div onClick={ handleClickSkipIntervalAll }>
        { renderSkipIntervalAndNextContent() }
      </div>
      { renderControls }
      <SubTitle currentTimeInSec={ currentTimeInSec } isVisible={ isVisible } />
      <div className={ cn(styles.opacityHidden, { [styles.opacityShow]: isVisibleMediaItemInfo, }) }>
        <ItemInfoRouter item={ props.item } />
      </div>
    </>
  );
};


export default React.memo(PlayerControls);
