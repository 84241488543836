/* eslint-disable max-len */
import { gw_11 } from 'customization-project-name/colors';
import * as React from 'react';

const Icon18plus = () => (
  <svg viewBox="0 0 86 35" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.668 34.5615C17.5205 34.5615 19.4297 32.7197 19.4297 29.8672V6.35059C19.4297 3.09375 17.4082 1.02734 13.9717 1.02734C12.1299 1.02734 10.4902 1.20703 8.60352 2.46484L2.71875 6.39551C1.46094 7.24902 0.921875 8.32715 0.921875 9.65234C0.921875 11.5391 2.29199 12.8643 4.06641 12.8643C4.96484 12.8643 5.48145 12.6846 6.29004 12.123L9.72656 9.74219H9.90625V29.8672C9.90625 32.7197 11.8154 34.5615 14.668 34.5615Z"
      fill={ gw_11 }
    />
    <path d="M40.0039 34.8984C48.6064 34.8984 54.5361 31.0352 54.5361 25.2402C54.5361 20.748 51.0771 17.7607 46.4053 17.0195V16.8398C50.4258 16.0537 53.3457 13.2236 53.3457 9.65234C53.3457 4.44141 47.9551 0.69043 40.0039 0.69043C32.0527 0.69043 26.6621 4.44141 26.6621 9.62988C26.6621 13.2686 29.582 16.0537 33.6475 16.8398V17.0195C28.9756 17.7607 25.4941 20.7256 25.4941 25.2178C25.4941 31.0352 31.3564 34.8984 40.0039 34.8984ZM40.0039 14.2568C37.7129 14.2568 36.0732 12.7744 36.0732 10.6182C36.0732 8.46191 37.7354 7.00195 40.0039 7.00195C42.2725 7.00195 43.957 8.46191 43.957 10.6182C43.957 12.7744 42.2949 14.2568 40.0039 14.2568ZM40.0039 28.5645C37.3311 28.5645 35.5342 26.8574 35.5342 24.5215C35.5342 22.2305 37.3311 20.5684 40.0039 20.5684C42.6768 20.5684 44.4736 22.2305 44.4736 24.5215C44.4736 26.8574 42.6768 28.5645 40.0039 28.5645Z"
      fill={ gw_11 }
    />
    <path d="M72.123 34C74.6836 34 76.166 32.4277 76.166 29.6875V24.7012H80.9053C83.5332 24.7012 85.0605 23.2637 85.0605 20.793C85.0605 18.3223 83.5557 16.8848 80.9502 16.8848H76.166V11.876C76.166 9.13574 74.6836 7.56348 72.123 7.56348C69.585 7.56348 68.0801 9.13574 68.0801 11.876V16.8848H63.2734C60.668 16.8848 59.1855 18.3223 59.1855 20.793C59.1855 23.2637 60.668 24.7012 63.2734 24.7012H68.0801V29.665C68.0801 32.4277 69.5625 34 72.123 34Z"
      fill={ gw_11 }
    />
  </svg>
);

export default Icon18plus;