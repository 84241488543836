import * as cn from 'classnames';
import * as React from 'react';

import Episode from '~typings/Episode';

import * as styles from '../styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  episode?: Episode;
  showAsSeries?: boolean;
}>;


const SeriesMediaItemContent: React.FC<Props> = (props: Props) => (
  <div
    className={ cn({
      [styles.showAsSeries]: !!props.showAsSeries,
      [styles.showAsSeriesFocused]: (!!props.showAsSeries && props.isFocused),
    }) }
  >
    {
      (!props.showAsSeries && props.episode) ?
        <div
          className={ styles.episodeNumber }
        >
          { props.episode.number }
        </div>
        :
        null
    }
  </div>
);


export default React.memo(SeriesMediaItemContent);
