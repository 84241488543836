import { FBtn, KeyboardLayout } from '../types';
import { KeyboardEngLowerLayout, KeyboardEngUpperLayout } from './KeyboardEngLayouts';
import { KeyboardNumbersLayout } from './KeyboardNumbersLayout';
import { KeyboardRusLowerLayout, KeyboardRusUpperLayout } from './KeyboardRusLayouts';
import { KeyboardSymbolsLayout } from './KeyboardSymbolsLayout';

export const getKeyboardButtonsByLayout = (layout: KeyboardLayout) => {
  const layoutMap: Record<KeyboardLayout, FBtn[]> = {
    eng_uppercase: KeyboardEngUpperLayout,
    eng_lowercase: KeyboardEngLowerLayout,
    ru_uppercase: KeyboardRusUpperLayout,
    ru_lowercase: KeyboardRusLowerLayout,
    symbols: KeyboardSymbolsLayout,
    numbers: KeyboardNumbersLayout,
  };
  return layoutMap[layout];
};
