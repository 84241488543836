import { InjectedIntl } from 'react-intl';

import { getSeasonByEpisodeID } from '~lib/Series';
import { EPISODE_NUM, SEASON_NUM } from '~localization';
import ProgramEvent from '~typings/Event';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';


const getSmallText = (item: PlayerMediaItem, intl: InjectedIntl): string | null => {
  if (item.object === ItemObject.Channel) {
    return item.name;
  }

  if (item.object === ItemObject.ProgramEvent && item.channel && item.channel.name) {
    return item.channel.name;
  }

  if (item.object === ItemObject.Episode) {
    const season = getSeasonByEpisodeID(item.series?.seasons, item.id);
    const seasonText = (season) ?
      ', ' + intl.formatMessage(
        { id: SEASON_NUM },
        { num: season.number },
      )
      :
      '';
    const episodeText = intl.formatMessage(
      { id: EPISODE_NUM },
      { num: item.number },
    )

    return `${episodeText}${seasonText}`;
  }

  // if (item.object !== ItemObject.CompetitionHighlight) {
  //   return getGenres(item as Movie | Episode | Event).join(', ');
  // }

  return null;
};

const getBigText = (item: PlayerMediaItem, currentEvent: ProgramEvent | null): string | null => {
  if (item.object === ItemObject.Channel && currentEvent) {
    return currentEvent.name;
  }
  if (item.object === ItemObject.ProgramEvent) {
    return item.name;
  }
  if (item.object === ItemObject.Movie) {
    return item.name;
  }
  if (item.object === ItemObject.Episode) {
    const seriesName = item.series?.name ? `${item.series?.name}: ` : '';

    return `${seriesName}${item.name}`;
  }
  // if (item.object === ItemObject.CompetitionHighlight) {
  //   return item.title;
  // }

  return null;
};


export {
  getBigText,
  getSmallText,
};
