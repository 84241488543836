import { adc_02, gw_11 } from 'customization-project-name/colors';
import { useMemo } from 'react';
import { InjectedIntl } from 'react-intl';

import { useAnimationContext } from '~components/Provider/Animation';
import { FREE } from '~lib/localizator/src/dictionaries';
import { Nullable } from '~lib/type-utils/utils';
import AudioShowPart from '~typings/AudioShowPart';
import { TSimageShape } from '~typings/Card';
import Distribution from '~typings/Distribution';
import Series from '~typings/Series';
import { PropsTS } from '~ui/CardV2/Square/SquareCard';

import { getHorizontalCardImageParams, useCardImage } from '../hooks';

export type MakeCardPropsParams = {
  part: AudioShowPart;
  partProgress?: Nullable<number>;
  audioshowCertificationRatings: Series['certification_ratings'];
  intl: InjectedIntl;
};

export const useCardPropsFromPart = ({
  part,
  partProgress,
  audioshowCertificationRatings,
  intl,
}: MakeCardPropsParams): Omit<PropsTS, 'isFocused'> => {

  const { cardImageType, widthVW, heightVH } = getHorizontalCardImageParams(TSimageShape.square);
  
  const imageSource = useCardImage({
    cardImages: part.images,
    isCardInappropriate: audioshowCertificationRatings?.[0]?.may_be_inappropriate || false,
    cardImageType,
    widthVW,
    heightVH,
  });

  const tag = audioshowCertificationRatings?.[0]?.tag;

  const { isAnimationEnabled } = useAnimationContext();

  return useMemo(() => ({
    showBottomTextBlock: true,
    progress: partProgress,
    contentProviderIconUrl: null,
    name: `${part.number}. ${part.name}`,
    additionalName: null,
    label: part.distribution === Distribution.Free ? {
      text: intl.formatMessage({ id: FREE }),
      textColor: gw_11,
      backgroundColor: adc_02,
      } : null,
    imageUrl: imageSource,
    certificationTag: tag,
    isAnimationEnabled,
  }), [part, tag, imageSource, isAnimationEnabled, partProgress]);
};
