import ItemObject from "~typings/ItemObject";
import PlayerMediaItem from "~typings/PlayerMediaItem";

export interface useGetDataResult {
  imdb: number | undefined
  kinopoisk: number | undefined
  spbtv: number | undefined
  age: string | undefined
  genres: string | undefined
  seasonNum: number | undefined
  episodeNum: number | undefined
  title: string | undefined
  description: string | undefined
}

/* хук просто дергает инфу из итема и возвращает ее для рендера в понятном виде
сделано для эпизодов и фильмов
 */
const getDescriptionFields = (item: PlayerMediaItem): useGetDataResult => {

  if (item.object === ItemObject.Episode) {
    //тут будут эпизоды
    //номера сезона нет в эпизоде поэтому придется попотеть
    //фильтруем сезоны с айдишником эпизода, понимаем в каком сезоне данный эпизод
    let seasonNumber: number | undefined = undefined

    if (item.series?.seasons) {
      for (let i = 0; i < item.series?.seasons.length; i++) {
        const episode = item.series?.seasons[i].episodes.find(el => el.id === item.id)

        if (episode) {
          seasonNumber = item.series?.seasons[i].number
          break
        }
      }
    }

    const genresMap = item.series?.genres.map(el => el.name).join(', ')

    return {
      imdb: item.series?.ratings.find(el => el.source === 'imdb')?.value,
      kinopoisk: item.series?.ratings.find(el => el.source === 'kinopoisk')?.value,
      spbtv: item.series?.ratings.find(el => el.source === "users")?.value,
      age: item.series?.certification_ratings[0].tag,
      genres: genresMap,
      seasonNum: seasonNumber,
      episodeNum: item.number,
      title: item.series?.name,
      description: item.series?.description
    }
  }
  else if (item.object === ItemObject.Movie) {
    // в остальных случаях это будет фильм
    const genresMap = item.genres.map(el => el.name).join(', ')
    return {
      imdb: item.ratings.find(el => el.source === 'imdb')?.value,
      kinopoisk: item.ratings.find(el => el.source === 'kinopoisk')?.value,
      spbtv: item.ratings.find(el => el.source === "users")?.value,
      age: item.certification_ratings[0].tag,
      genres: genresMap,
      seasonNum: undefined,
      episodeNum: undefined,
      title: item.name,
      description: item.description,
    }
  }

  return {
    imdb: undefined,
    kinopoisk: undefined,
    spbtv: undefined,
    age: undefined,
    genres: undefined,
    seasonNum: undefined,
    episodeNum: undefined,
    title: undefined,
    description: undefined
  }
}

export { getDescriptionFields }