import * as React from 'react';

import { api } from '~lib/env-utils';
import { persistContext, reHydrate, Storage } from '~lib/reHydrate';

import {
  EnvContext,
  EnvDispatchContext,
  reducer,
  State,
} from './component.helpers';


const STORAGE_KEY = 'env';
const PERSISTENT_PROPS = ['apis', 'api'];

const EnvProvider = ({ children }: {
  children?: React.ReactNode;
}): JSX.Element => {
  const reHydratedProps = reHydrate(Storage.SessionStorage, STORAGE_KEY, PERSISTENT_PROPS);
  const initialState = {
    apis: [],
    api,
    iptv: null,
    ...reHydratedProps,
  };
  const [state, dispatch] = React.useReducer(
    reducer,
    initialState,
  );

  React.useEffect(() => {
    persistContext<State>(Storage.SessionStorage, STORAGE_KEY, PERSISTENT_PROPS, state);
  }, [state]);

  return (
    <EnvContext.Provider
      value={ state }
    >
      <EnvDispatchContext.Provider
        value={ dispatch }
      >
        { children }
      </EnvDispatchContext.Provider>
    </EnvContext.Provider>
  );
};


EnvProvider.displayName = 'EnvProvider';


export default React.memo(EnvProvider);
