import focuser from '@focuser';
import React from 'react';

import Popup, { Props as PopupProps } from './Popup';

export type Props = PopupProps & {
  focuserClassName?: string;
}
/**
 * Popup` и изоляция для focuser-а
 */
export const PopupIsolated: React.FC<Props> = (props) => {
  const { children, focuserClassName, ...rest } = props;
  return (
    <Popup { ...rest }>
      <focuser.Isolation className={ focuserClassName }>{children}</focuser.Isolation>
    </Popup>
  );
};
