import {
  QueryCache,
  QueryClient,
} from 'react-query';

export const ReactQueryCache = new QueryCache();
export const ReactQueryClient = new QueryClient({
  queryCache: ReactQueryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ['data', 'error'],
      retry: 1,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});
