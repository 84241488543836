import * as React from 'react';

import { usePlatform } from '~components/Provider/Platform';
import usePrevious from '~hooks/usePrevious';
import HotKey from '~typings/HotKey';

import { PlayerStatus } from '../useHandleEvents';


const VISIBLE_TIME_MS = 3000;

const usePlayerControlsIsVisible = (playerStatus: PlayerStatus, isVisiblePopup: boolean) => {
  const isMounted = React.useRef(true);
  const timeout: any = React.useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const [isSeeking, setIsSeeking] = React.useState(false);
  const prevPlayerStatus = usePrevious<PlayerStatus | null>(playerStatus);
  const { platform } = usePlatform();
  const keyCodes = platform.getKeyCodes();

  const handleShowControls = React.useCallback((isNeedHideAfterTimeOut: boolean, visibility = true) => {
    setIsVisible(visibility && !isVisiblePopup);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (isNeedHideAfterTimeOut) {
      timeout.current = setTimeout(() => {
        if (isMounted.current && isNeedHideAfterTimeOut) {
          setIsVisible(false);
        } else if (timeout.current) {
          clearTimeout(timeout.current);
        }
      }, VISIBLE_TIME_MS);
    }
  }, [isVisiblePopup]);
  const handleKeyDown = ({ keyCode }: KeyboardEvent) => {
    // Ignore Back Button
    if (keyCode !== keyCodes[HotKey.KEY_RETURN]) {
      handleShowControls(!isSeeking);
    }
  };

  React.useEffect(() => () => {
    isMounted.current = false;
  }, []);
  React.useEffect(() => {
    if (!isVisiblePopup) {
      window.addEventListener<'keydown'>('keydown', handleKeyDown);

      return (
        () => {
          window.removeEventListener<'keydown'>('keydown', handleKeyDown);

          if (timeout.current) {
            clearTimeout(timeout.current);
          }
        }
      );
    } else {
      setIsVisible(false);
    }
  }, [isVisiblePopup]);
  React.useEffect(() => {
    if (
      prevPlayerStatus === PlayerStatus.Seeking
      || playerStatus === PlayerStatus.Seeking
    ) {
      setIsSeeking(true);
    }

    if (
      isSeeking
      // && prevPlayerStatus === PlayerStatus.Play
      && playerStatus === PlayerStatus.Play
    ) {
      setIsSeeking(false);
      handleShowControls(true);
    }
  }, [isSeeking, prevPlayerStatus, playerStatus]);
  React.useEffect(() => {
    if (isSeeking) {
      handleShowControls(false);
    }
  }, [isSeeking]);

  return React.useMemo(
    () => ({
      isVisible,
      handleShowControls,
      isSeeking,
    } as const),
    [
      isVisible,
      isSeeking,
      handleShowControls,
    ],
  );
};


export default usePlayerControlsIsVisible;
