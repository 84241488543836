/* eslint-disable max-len */
import * as React from 'react';


const IconSubtitles: React.FC = () => (
  <svg
    className="playerIcon"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.752,9.208a.04.04,0,0,1,.04.04V21.169a.039.039,0,0,1-.04.039H17.963l-.586.586L15.792,23.38l-1.586-1.586-.586-.586H6.831a.038.038,0,0,1-.039-.039V9.248a.039.039,0,0,1,.039-.04H24.752m0-2H6.831a2.039,2.039,0,0,0-2.039,2.04V21.169a2.039,2.039,0,0,0,2.039,2.039h5.961l3,3,3-3h5.96a2.039,2.039,0,0,0,2.04-2.039V9.248a2.04,2.04,0,0,0-2.04-2.04Zm-8.96,6a2,2,0,1,0,2,2A2,2,0,0,0,15.792,13.208Zm5,0a2,2,0,1,0,2,2A2,2,0,0,0,20.792,13.208Zm-10,0a2,2,0,1,0,2,2A2,2,0,0,0,10.792,13.208Z"
      fill="currentColor"
    />
  </svg>
);


export default React.memo(IconSubtitles);
