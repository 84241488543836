// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".progressBarWrapper--UJjn7 {\r\n  width: 100%;\r\n\r\n  /* 4px */\r\n  height: 0.313vw;\r\n  background-color: rgba(58, 58, 66, 1);\r\n  -webkit-border-radius: 0.15625vw;\r\n          border-radius: 0.15625vw;\r\n}\r\n\r\n.progressBar--kcorn {\r\n  height: 100%;\r\n  background-color: rgba(253, 216, 53, 1);\r\n  -webkit-border-radius: 0.15625vw;\r\n          border-radius: 0.15625vw;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/ViewStatistics/StatsItem/ProgressLine/styles.modules.css"],"names":[],"mappings":"AAAA;EACE,WAAW;;EAEX,QAAQ;EACR,eAAe;EACf,qCAAwB;EACxB,gCAAwB;UAAxB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,uCAAwB;EACxB,gCAAwB;UAAxB,wBAAwB;AAC1B","sourcesContent":[".progressBarWrapper {\r\n  width: 100%;\r\n\r\n  /* 4px */\r\n  height: 0.313vw;\r\n  background-color: $gw_03;\r\n  border-radius: 0.15625vw;\r\n}\r\n\r\n.progressBar {\r\n  height: 100%;\r\n  background-color: $ac_00;\r\n  border-radius: 0.15625vw;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var progressBarWrapper = "progressBarWrapper--UJjn7";
export var progressBar = "progressBar--kcorn";
export default ___CSS_LOADER_EXPORT___;
