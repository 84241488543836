import cn from 'classnames';
import * as React from 'react';

import { WarningTextlineBlock } from '~app/ui-kit/WarningTextlineBlock';
import { Nullable } from '~lib/type-utils/utils';

import * as styles from './styles.module.css';

type Props = Readonly<{
  headerText?: Nullable<string>;
  descriptionText?: Nullable<string>;
  errorText?: Nullable<string>;
  children: React.ReactNode | React.ReactNode[];
  /**
   * Какой тип клавиатуры будем использовать
   * Влияет не отступ от secondaryContent
   * @default 'password'
   */
  forKeyboardType?: 'pincode' | 'password';
}>;

/**
 * Это UI компонент, который пришел на замену компонента FieldSet
 * По сути это каркас страницы, на котором должна показываться клавиатура
 */
export const KeyboardFieldSet: React.FC<Props> = React.memo((props: Props) => {
  const renderHeader = () => {
    if (!props.headerText) {
      return null;
    }

    return <div className={ styles.headerContent }>{props.headerText}</div>;
  };

  const renderDescriptionText = () => {
    if (!props.descriptionText) {
      return null;
    }

    return <div className={ styles.descriptionText }>{props.descriptionText}</div>;
  };

  const renderErrorText = () => {
    if (!props.errorText) {
      return null;
    }

    return (
      <WarningTextlineBlock
        className={ styles.errorBlock }
        errorText={ props.errorText }
      />
    );
  };

  const renderErrorOrDescription = () => {
    if (props.errorText) {
      return renderErrorText();
    }

    if (props.descriptionText) {
      return renderDescriptionText();
    }
    return null;
  };

  return (
    <div className={ styles.globalWrapper }>
      {renderHeader()}
      <div className={ styles.contentWrapper }>
      <div className={ cn(styles.secondaryContent, {
        [styles.secondaryContentForPin]: props.forKeyboardType === 'pincode',
      }) }>{renderErrorOrDescription()}</div>
      <div className={ styles.mainContent }>{props.children}</div>
      </div>
    </div>
  );
});

KeyboardFieldSet.displayName = 'KeyboardFieldSet';
