import * as React from 'react';
import { useParams } from 'react-router-dom';

import useInvoice from '~hooks/fetch/usePayments/useInvoice';
import Product from '~typings/Product';

import InvocePage from './InvocePage';


type Props = Readonly<{
  products: Product[] | null;
  url: string | null | undefined;
  focusOnMenu: boolean;
  onMenu: (arg?: boolean) => void;
}>;

const ProductInvoce: React.FC<Props> = ({ products, url, onMenu, focusOnMenu }) => {
  const { planID } = useParams<{ planID: string }>();
  const { mutate, data: invoice } = useInvoice();
  const plan = (products || [])
    .find(pr => pr.plans.find(plan => plan.id === planID))?.plans.find(plan => plan.id === planID);

  React.useEffect(() => {
    if (planID && plan) {
      //пока сделан хардкод, т.к пока контент можно купить только через мобильного оператора
      //т.е paymentMethod === 'operator'
      //когда добавится покупка через банковскую карту - нужно будет дополнить/переделать условие
      mutate({ planID, paymentMethod: 'operator' });
    }
  }, [planID, plan]);

  return (
    <InvocePage
      plan={ plan }
      invoice={ invoice?.data }
      onMenu={ onMenu }
      focusOnMenu={ focusOnMenu }
      url={ url }
    />
  );
};


export default ProductInvoce;
