import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useClient, useDeviceToken } from '~global';
import { getAuthData, logout } from '~hooks/fetch/useAccount';
import {
  isAccessTokenInvalid,
  isClientObsolete,
  isClientOutdatedApi,
  isDeviceTokenInvalid,
  isInvalidClientID,
} from '~lib/ApiClient/error-checkers';

import {
  createInvalidAccessTokenErrorHandler,
} from './invalid-token-handler';


const useClientApiErrorCatcher = () => {
  const history = useHistory();
  const client = useClient();
  const { accessToken } = getAuthData();
  const deviceToken = useDeviceToken();

  const createErrorHandler = (client) => {
    return (error, requestParams) => {
      if (isAccessTokenInvalid(error)) {
        const inValidTokenErrorHandler = createInvalidAccessTokenErrorHandler(client, logout);

        return inValidTokenErrorHandler(error, requestParams);
      } else if (isClientObsolete(error)) {
        history.push('/error/api-client?type=obsolete');
      } else if (isClientOutdatedApi(error)) {
        history.push('/error/api-client?type=outdated-api');
      } else if (isDeviceTokenInvalid(error)) {
        history.push('/error/api-client?type=device-token-invalid');
      } else if (isInvalidClientID(error)) {
        history.replace('/error/api-client?type=invalid-client-id');
      }

      throw error;
    };
  };

  React.useEffect(() => {
    client.setErrorHandler(createErrorHandler(client));
  }, [deviceToken, accessToken]);
};


export default useClientApiErrorCatcher;
