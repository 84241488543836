const scriptsPaths = require('platform-name/files.js');
const {gw_00} = require("customization-project-name/colors");
const {splashScreenLogotypeWidth} = require('customization-project-name/settings.json');

const styleBody = {
    padding: 0,
    margin: 0,
    'font-size': '1.563vw',
    overflow: 'hidden',
    width: '100vw',
    height: '100vh',
    'background-color': gw_00,
};


const styleLoader = {
    //этот лоадер сейчас невидимый поэтому стили особо не важны
    width: '4.688vw',
    height: '4.688vw',
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '20vh',
    margin: 'auto',
};

const styleLogotype = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: splashScreenLogotypeWidth || 'auto',
    margin: 'auto',
};

const styleMainLoader = {
    'z-index': 2,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    'background-color': gw_00,
};


/* eslint-disable max-len */

function createInlineStyle(styles) {
    return Object.keys(styles)
        .map((key) => {
            return `${key}: ${styles[key]}`;
        })
        .join('; ');
}

function renderOrsayObjectsBody() {
    if (__PLATFORM__ !== __PLATFORM_NAME_ORSAY__) {
        return '';
    }

    return `
  <object id="pluginObjectNNavi" border="0" classid="clsid:SAMSUNG-INFOLINK-NNAVI" class="samsung-hidden-object"></object>
  <object id="pluginObjectTVMW" border="0" classid="clsid:SAMSUNG-INFOLINK-TVMW" class="samsung-hidden-object"></object>
  <object id='pluginNetwork' border="0" classid="clsid:SAMSUNG-INFOLINK-NETWORK" class="samsung-hidden-object"></object>
  <object id="pluginObjectVideo" border="0" classid="clsid:SAMSUNG-INFOLINK-SEF" class="samsung-player"></object>
  `;
}

const logotypeSRC = `${__APP_DOMAIN__}/splash-screen/logotype.png?version=${__BUILD_VERSION__}`;
const spinner = `${__APP_DOMAIN__}/splash-screen/spinner.gif?version=${__BUILD_VERSION__}`;

function renderMainLoader() {
    return `<div id="main-loader" style="${createInlineStyle(styleMainLoader)}">
    <img src="${logotypeSRC}" style="${createInlineStyle(styleLogotype)}">
    <img src="${spinner}" style="${createInlineStyle(styleLoader)}">
  </div>`;
}

function renderScriptLink(filePath) {
    return `<script src="${filePath}" crossorigin="true"></script>`;
}

function renderScriptLinks(jsFiles) {
    return jsFiles.map(renderScriptLink).join('\n');
}

function renderStyleLink(filePath) {
    return `<link rel="stylesheet" type="text/css" href="${filePath}">`;
}

function renderStyleLinks(cssFiles) {
    return cssFiles.map(renderStyleLink).join('\n');
}

function renderStyles(htmlWebpackPluginData) {
    const {
        htmlWebpackPlugin: {
            files: {css},
        },
    } = htmlWebpackPluginData;
    return renderStyleLinks(css);
}

function renderBuildInfo() {
    return `
  <!-- PLATFORM: ${__PLATFORM__} -->
  <!-- PROJECT_NAME: ${__PROJECT_NAME__} -->
  `;
}

function renderBodyStyles() {
    const styles = Object.assign({}, styleBody);

    return createInlineStyle(styles);
}

function renderMainScriptInline(htmlWebpackPluginData) {
    const {
        compilation,
        htmlWebpackPlugin: {files},
    } = htmlWebpackPluginData;

    const jsFile = files.js[0];

    const fileContent = compilation.assets[jsFile.substr(files.publicPath.length)].source();
    return `<script>${fileContent}</script>`;
}

function renderManifestInline(htmlWebpackPluginData) {
    const {
        compilation,
        htmlWebpackPlugin: {files},
    } = htmlWebpackPluginData;

    const manifestFile = files.js.find((name) => name.includes('manifest'));
    if (!manifestFile) {
        return '';
    }

    const fileContent = compilation.assets[manifestFile.substr(files.publicPath.length)].source();
    return `<script>${fileContent}</script>`;
}

function renderPlatformScripts() {
    return `
    ${renderOrsayObjectsBody()}
    ${renderScriptLinks(scriptsPaths)}
  `;
}

module.exports = {
    logotypeSRC,
    spinner,
    renderBodyStyles,
    renderMainLoader,
    renderScriptLinks,
    renderStyles,
    renderBuildInfo,
    renderMainScriptInline,
    renderManifestInline,
    renderPlatformScripts,
};
