import focuser, { FocuserKeyHandler, useFocuserThrottledHandler } from '@focuser';
import React, { useCallback } from 'react';

import { PartCardFocuser } from '~components/Card/PartCard/PartCardFocuser';
import AudioShow from '~typings/AudioShow';
import AudioShowPart from '~typings/AudioShowPart';
import { CardConfigLayout } from '~typings/ScreenCardConfigs';
import { getSliderPropsForCards,SliderOffsetableVirtual } from '~ui/SliderOffsetable';

import { useWatchProgressForParts } from './utils';

export interface Props {
  audioshow: AudioShow;
  initialPartIdx: number;
  onPartPlay: (part: AudioShowPart) => void;
}

export const PartsSlider = React.memo<Props>((props) => {
  const { audioshow, initialPartIdx, onPartPlay } = props;
  const parts = (audioshow.parts || []);

  const [focusedIdx, setFocusedIdx] = React.useState(initialPartIdx);
  const { watchProgressForFocusedPart } = useWatchProgressForParts(parts, focusedIdx);

  const onLeft: FocuserKeyHandler = (e) => {
    if (focusedIdx > 0) {
      e.stop();
      setTimeout(() => setFocusedIdx(focusedIdx - 1), 0);
    }
  };
  const onRight: FocuserKeyHandler = (e) => {
    if (focusedIdx < parts.length - 1) {
      e.stop();
      setTimeout(() => setFocusedIdx(focusedIdx + 1), 0);
    }
  };

  const  onKeyLeftThrottled = useFocuserThrottledHandler(onLeft);
  const  onKeyRightThrottled = useFocuserThrottledHandler(onRight);

  const renderPart = useCallback((i: number) => {
    const part = parts[i];
   
    return (
      <PartCardFocuser
        key={ i }
        onClick={ () => onPartPlay(part) }
        part={ part }
        partProgress={ i === focusedIdx ? watchProgressForFocusedPart : undefined }
        audioshowCertificationRatings={ audioshow.certification_ratings }
        isFocused={ i === focusedIdx }
        onForceFocus={ () => setFocusedIdx(i) }
        emitForceFocusOnHover
      />
    );
  }, [focusedIdx, parts, onPartPlay, watchProgressForFocusedPart]);



  return (
    <focuser.FocusableBlock
      isFocused
      onKeyLeft={ onKeyLeftThrottled }
      onKeyRight={ onKeyRightThrottled }
      isPointerLeftAvailable={ focusedIdx > 0 }
      isPointerRightAvailable={ focusedIdx < parts.length - 1 }
      noNeedForceFocus
    >
      <SliderOffsetableVirtual
        focusedIndex={ focusedIdx }
        childsCount={ parts.length }
        renderChild={ renderPart }
        { ...getSliderPropsForCards(CardConfigLayout.Square) }
      />
    </focuser.FocusableBlock>
  );
});

PartsSlider.displayName = 'PartsSlider';
