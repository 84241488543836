import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useFavoritesInfinity } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { usePaginationByItems } from '~hooks/usePagination';
import { getField } from '~lib/URLQueryStringManager';
import { HEADER_FAVORITE_CHANNELS } from '~localization';
import useNavigationOnLayer from '~stores/LayerNavigation';

import CollectionPageWrapper from './Wrapper';

const SLIDES_COUNT_PER_LINE = 4;
const MINIMAL_LINES_ON_SCREEN = 3;

const FavoritesPage = () => {
  const history = useHistory();
  const types = (getField(history.location.search, 'types') || '').split(',');
  const layerID = `collection_page-favorite_${types.join(',')}`;
  const focusedIndex = useNavigationOnLayer(state => state.getFocusedIndexOnLayer(layerID));

  const { query, parsedData: items } = useFavoritesInfinity({
    types
  })

  usePaginationByItems({
    currentFetchedCount: items?.data.length || 0,
    focusedLineIdx: Math.floor(focusedIndex / SLIDES_COUNT_PER_LINE),
    elementsPerLine: SLIDES_COUNT_PER_LINE,
    minimalLinesOnScreen: MINIMAL_LINES_ON_SCREEN,
    fetchMoreData: ()=> {
      if(query.hasNextPage && !query.isFetchingNextPage){
        query.fetchNextPage();
      }
    }
  });

  const title = getField(history.location.search, 'blockTitle');

  return (
    <CollectionPageWrapper
      layerID={ layerID }
      title={ title || <FormattedMessage id={ HEADER_FAVORITE_CHANNELS } /> }
      items={ items }
      showOnlyFetchedItems
      slidesCountPerLine={ SLIDES_COUNT_PER_LINE }
    />
  );
};


export default FavoritesPage;
