import { find } from 'lodash';
import * as React from 'react';
import { InjectedIntl,injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { usePromocode } from '~hooks/fetch/usePromocode/usePromocodeMutation';
import useBestPrice from '~hooks/fetch/usePurchases/useBestPrice';
import * as URLSearchManager from '~lib/URLQueryStringManager';
import { BY_WITH_PROMO } from '~localization';
import AudioShow from '~typings/AudioShow';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import RentPlan from '~typings/RentPlan';
import Series from '~typings/Series';


type Props = {
  item: PlayerMediaItem | AudioShow | Series | undefined | null;
  isFocused: boolean;
  intl: InjectedIntl;
};

const PromoPriceButton: React.FC<Props> = ({ item, isFocused, intl }) => {
  const history = useHistory();
  const rentPlanId = URLSearchManager.getField(history.location.search, 'rentPlanId');
  const promocode = URLSearchManager.getField(history.location.search, 'promo_code');
  const { data, isLoading } = usePromocode(promocode);
  const rentPlan = (find(data?.rentPlans, { id: rentPlanId }) ?? {}) as RentPlan;
  const promoPhase = find((rentPlan.phases || []), ['type', 'promo']);

  const getPlanType = () => {
    if(rentPlan.type === 'EST') {
      return 'EST';
    } else {
      return 'TVOD,EST';
    }
  }

  const plan = getPlanType();
  const bestPrice = useBestPrice(item?.id, plan)

  if (!item) { return null; }

  const handleClick = () => {
    history.push(`/media-item/${item.object}/${item.id}/promo?promo_code=${promocode}&rentPlanId=${
      rentPlanId
    }`);
  };

  return (
    <PrimaryButtonFocuser
      isFocused={ isFocused }
      isDisabled={ isLoading }
      onClick={ handleClick }
      noNeedForceFocus
    >
         {bestPrice
          ? intl.formatMessage(
              { id: BY_WITH_PROMO },
              {
                price: promoPhase?.billing.price.formatted,
                fullPrice: bestPrice?.data?.data?.[0]?.billing_price?.money.formatted,
              },
            )
          : null}
    </PrimaryButtonFocuser>
  );
};


export default injectIntl(PromoPriceButton);
