import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Product from '~typings/Product';
import Subscription from '~typings/Subscription';

import CardProceed from './CardProceed'
import PaymentProceed from './PaymentProceed';
import ProductInvoicePage from './ProductInvoicePage';
import ProductPlanManager from './ProductPlanManager';


type Props = {
  product: Product;
  subscriptions?: Subscription[];
};

const ProductPlanRouts: React.FC<Props> = ({ product, subscriptions }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={ `${path}/:planID/invoice/proceed_card_payment` }>
        <CardProceed/>
      </Route>
      <Route
        path={ `${path}/:planID/invoice/:invoiceID` }
      >
        <PaymentProceed
          product={ product }
        />
      </Route>
      <Route
        path={ `${path}/:planID/invoice` }
      >
        <ProductInvoicePage
          product={ product }
        />
      </Route>
      <Route
        path={ `${path}/:planID` }
      >
        <ProductPlanManager
          subscriptions={ subscriptions }
          product={ product }
        />
      </Route>
      <Route path={ path }>
        <ProductPlanManager
          subscriptions={ subscriptions }
          product={ product }
        />
      </Route>
    </Switch>
  )
};


export default ProductPlanRouts;
