import focuser from '@focuser';
import * as React from 'react';
import { useState } from "react";

import { doesNeedShowWarningForRent, RentStartWarningStateForAudioShow } from '~components/MediaItemPage/utils';
import { useMenu } from '~components/Provider/Menu';
import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import useAccess from '~hooks/useAccess/useAccess';
import useLocationSearch from '~hooks/useLocationSearch';
import AudioShow from '~typings/AudioShow';
import AudioShowPart from '~typings/AudioShowPart';
import ItemObject from '~typings/ItemObject';

import { RentActivationWarning } from '../RentActivationWarning';
import { AudioShowFrontContent } from './AudioShowFrontContent/AudioShowFrontContent';
import { AudioShowPartSelect } from './AudioShowPartSelect/AudioShowPartSelect';
import * as styles from './styles.module.css';


type Props =
  | {
      item?: undefined;
      part: AudioShowPart;
    }
  | {
      item: AudioShow;
      part?: undefined;
    }
  | {
      item: AudioShow;
      part: AudioShowPart;
    };


const AudioShowPage: React.FC<Props> = (props: Props) => {

  const [contentToShow, setContentToShow] = React.useState<'front' | 'parts'>(
    props.part?.id ? 'parts' : 'front',
  );

  const [rentActivationWarningState, setRentActivationWarningState] =
    useState<RentStartWarningStateForAudioShow>({ isOpen: false });

  const audioShow = props.item || props.part.audio_show;

  const audioShowID = audioShow.id;

  const { setItem: playerSetItem } = usePlayerStateActions();
  const playerState = usePlayerState();
  const menu = useMenu();
  const access = useAccess(audioShowID, ItemObject.AudioShow);

  const allowNavigation = (
    !useLocationSearch()?.player && !menu.isPopupOpened && !playerState.item && !rentActivationWarningState.isOpen
  );

  const handleSetPlayerItem = (part?: AudioShowPart) => {
    if (part) {
      playerSetItem(part);
    }
  };

  const handlePartPlay = (part: AudioShowPart) => {
    if (doesNeedShowWarningForRent(access)) {
      setRentActivationWarningState({
        isOpen: true,
        rentPlan: access.reason_object.rent_plan,
        purchaseExpiresAt: access.reason_object.expires_at,
        part: part,
      });
      return;
    }
    handleSetPlayerItem(part);
  };

  if(rentActivationWarningState.isOpen) {
    return (
      <RentActivationWarning
        rentPlan={ rentActivationWarningState.rentPlan }
        purchaseExpiresAt={ rentActivationWarningState.purchaseExpiresAt }
        resourceType={ ItemObject.AudioShow }
        onCancel={ () => {
          setRentActivationWarningState({ isOpen: false });
        } }
        onContinue={ () => {
          setRentActivationWarningState({ isOpen: false });
          handleSetPlayerItem(rentActivationWarningState.part);
        } }
      />
    );
  }

  const renderInFocuser = (content: JSX.Element) => {
    return <focuser.FocusableBlock isFocused={ allowNavigation } noNeedForceFocus>
      {content}
    </focuser.FocusableBlock>
  }

  if (contentToShow === 'parts') {
    return renderInFocuser(
      <div className={ styles.partSelect }>
        <AudioShowPartSelect
          onBack={ () => setContentToShow('front') }
          audioshow={ props.item! }
          selectedPartID={ props.part?.id }
          onPartPlay={ handlePartPlay }
        />
      </div>
    );
  }

  return renderInFocuser(
    <AudioShowFrontContent
      item={ props.item! }
      onOpenParts={ () => setContentToShow('parts') }
      onPartPlay={ handlePartPlay }
    />
  );
};


export default React.memo(AudioShowPage);
