import * as React from 'react';

import getImageSRC from '~lib/MediaItemImageSRC';
import { getSceneItemHeight, getSceneItemWidth } from '~lib/SizesInPX';
import ImageType from '~typings/ImageType';
import Product from '~typings/Product';
import Fade from '~ui/Effects/Fade';
import { Image } from '~ui/Image';

import BackgroundImage from './BackgroundImage/BackgroundImage';
import * as styles from './styles.module.css';


type Props = Readonly<{
  product: Product;
}>;


const SceneProductItem: React.FC<Props> = ({ product }) => {
  if (!product) { return null; }

  const width = getSceneItemWidth();
  const height = getSceneItemHeight();
  const imageUrl = product ? getImageSRC({
    mediaItem: product,
    dimension: ImageType.PROMO_BANNER_WIDE,
    width,
    height,
  }) : null;

  return (
    <Fade
      isVisible={ !!imageUrl }
    >
      <Image
        className={ styles.image }
        src={ imageUrl }
        durationFadeIn={ 250 }
      />
      <BackgroundImage
        isMediaItem
      />
    </Fade>
  );
};


export default React.memo(SceneProductItem);
