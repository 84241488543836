import {
  SelectedDropdownFilter,
  SelectedFilters,
  SelectedQuickFilter,
} from '~typings/SelectedFilters';

const isQuickFilter = (
  selectedFilter: SelectedQuickFilter | SelectedDropdownFilter,
): selectedFilter is SelectedQuickFilter => {
  return selectedFilter.type === 'quick_filter';
};

const isDropdownFilter = (
  selectedFilter: SelectedQuickFilter | SelectedDropdownFilter,
): selectedFilter is SelectedDropdownFilter => {
  return selectedFilter.type === 'dropdown_filter';
};

export const makeCacheKeyFromSelectedFilters = (selectedFilters: SelectedFilters) => {
  const filters = Object.values(selectedFilters);
  const quickFiltersString = filters
    .filter(isQuickFilter)
    .map((x) => x.filter.id)
    .sort()
    .join(',');

  const dropdownFiltersString = filters
    .filter(isDropdownFilter)
    .map((x) => `${x.filter.api_param}:${x.selectedOptions.map((x) => x.value).join(',')}`)
    .sort()
    .join(',');

  return `${quickFiltersString}${dropdownFiltersString}`;
};

export const getQuickFiltersRequestParams = (selectedFilters: SelectedFilters) => {
  const filters = Object.values(selectedFilters).filter(isQuickFilter);
  const values = filters.map((x) => x.filter.slug).join(',');

  if (values.length) {
    return { 'filter[quick_filter_ids_include]': values };
  }

  return {};
};

export const getDropdownFiltersRequestParams = (
  selectedFilters: SelectedFilters,
): Record<string, string> => {
  const filters = Object.values(selectedFilters).filter(isDropdownFilter);
  const valueObj: Record<string, string> = {};

  filters.forEach((selected) => {
    valueObj[`filter[${selected.filter.api_param}]`] = selected.selectedOptions
      .map((x) => x.value)
      .join(',');
  });

  return valueObj;
};
