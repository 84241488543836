import Period from '~typings/Period';


const getTimeShiftDurationInSeconds = (period: Period): number => {
  const { unit, value } = period;

  if (value) {
    if (unit === 'minutes') { return value * 60; }
    if (unit === 'hours') { return value * 3600; }
    if (unit === 'days') { return value * 3600 * 24; }
    if (unit === 'months') { return value * 3600 * 24 * 30; }
  }

  return 0;
};


export {
  getTimeShiftDurationInSeconds,
};
