import { flatten } from 'lodash';

import { PriceWithPeriod } from '~lib/product/getMinPrice';
import BillingPrice from "~typings/BillingPrice";
import DiscountPhase from '~typings/DiscountPhase';
import EvergreenPhase, {EverGreenPhaseWithRecurringBilling} from '~typings/EvergreenPhase';
import Plan from '~typings/Plan';
import PromoPhase from '~typings/PromoPhase';
import TrialPhase from '~typings/TrialPhase';


const getPlanMinPrice = (plans: Plan[]): (PriceWithPeriod | 'free' | null) => {
  if (!plans) { return null; }

  const eligiblePhases: Array<
    TrialPhase | DiscountPhase | PromoPhase | EvergreenPhase | null
    > = plans.map((plan: Plan) => plan.eligible_phase);
  const trialEligiblePhases: TrialPhase | undefined = eligiblePhases
    .find(phase => phase?.type === 'trial') as TrialPhase || undefined;

  if (trialEligiblePhases) { return 'free'; }

  const everGreenEligiblePhases = eligiblePhases.filter(
    (phase) => (
      phase?.type === 'evergreen' && phase.billing.type === 'recurring'
    )
  ) as EverGreenPhaseWithRecurringBilling[] || [];

  const pricesWithPeriod: PriceWithPeriod[] = flatten(
    everGreenEligiblePhases.map(
      ({ billing }) => billing.prices.map(
        (price: BillingPrice) => ({
          money: price.money,
          period: (billing?.period || undefined),
        })
      )
    )
  );

  if (!pricesWithPeriod || pricesWithPeriod.length === 0) { return null; }

  return pricesWithPeriod
    .reduce((min: PriceWithPeriod, priceWithPeriod: PriceWithPeriod) => {
      if (!min) { return priceWithPeriod; }

      if (min.money.value > priceWithPeriod.money.value) {
        return priceWithPeriod;
      }

      return min;
    });
};


export default getPlanMinPrice;

