import cn from 'classnames';
import * as React from 'react';

import IconBase from './IconBase';
import * as styles from './styles.module.css';


type Props = {
  Icon: React.FC;
  isFocused?: boolean;
  className?: string;
};

const IconTextItem: React.FC<Props> = ({ Icon, isFocused, className }) => {
  return (
    <IconBase
      Icon={ Icon }
      classNames={ {
        wrapper: cn(
          styles.primaryRound,
          className,
          { [styles.inactive]: !isFocused }
        )
      } }
    />
  );
};


export default IconTextItem;
