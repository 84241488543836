import * as cn from 'classnames';
import * as React from 'react';

import CheckIcon from '~ui/Icon/icons/Check';
import { NavigationHelper } from '~ui/NavigationHelper';

import * as styles from './styles.module.css';

type CheckboxButtonProps = {
  isChecked: boolean;
  isFocused: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  onLeave?: (NavigationDirection) => void;
  children?: any;
  className?: string;
};

/**
 * @deprecated
 * Используй src/newapp/ui-kit/Table_items/Account_items/CheckboxLine
 */
const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  isChecked,
  isFocused,
  isDisabled,
  children,
  onClick,
  onLeave,
  className
}) => {
  return (
    <>
      {
        (isFocused && onLeave) ?
          <NavigationHelper
            isFocused={ isFocused }
            onLeave={ onLeave }
          />
          :
          null
      }
      <div
        className={ cn(styles.wrapper, className, {
          'focusedNavigationNode': isFocused,
          [styles.focused]: isFocused,
          [styles.disabled]: isDisabled,
        }) }
        onClick={ onClick }
      >
        <span
          className={ cn(styles.iconWrapper, {
            [styles.iconWrapperFocused]: isFocused,
            [styles.iconWrapperChecked]: isChecked,
          }) }
        >
        <CheckIcon
          isFocused={ isFocused }
          className={ cn(styles.icon, {
            [styles.checked]: isChecked,
          }) }
        />
        </span>
        {children}
      </div>
    </>
  );
};

export default CheckboxButton;
