
const formatRegexp = /(\d)\((\d+)\)(\d{3})(\d{2})(\d{2})/;

export const formatPhoneNumber = (number?: string): string | null => {
  if (!number) return null;
  const [regexpResult, countryCode, cityCode, pg1, pg2, pg3] = formatRegexp.exec(number) ?? [];
  if (regexpResult) {
    return `${countryCode} (${cityCode}) ${pg1}-${pg2}-${pg3}`
  }
  return number;
};
