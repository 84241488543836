import { useMemo, useRef } from 'react';

export function usePreviousMemoized<T>(value: T) {
  const previous = useRef(value);

  const prev = useMemo(() => {
    return previous.current;
  }, [value]);

  previous.current = value;

  return prev;
}
