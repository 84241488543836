import * as React from 'react';

import { Modification } from "~typings/Card";
import { AnnounceIcon } from "~ui/Icon/icons/Announce";
import { CatchUpIcon } from "~ui/Icon/icons/CatchUp2";

type Props = {
  modification?: Modification | null;
  isFocused: boolean
};

export function CardPlayIcons(props: Props) {
  switch (props.modification) {
    case Modification.FUTURE:
    case Modification.ANNOUNCEMENT_MOD:
      return <AnnounceIcon isFocused={ props.isFocused } />
    case Modification.CATCHUP:
      return <CatchUpIcon isFocused={ props.isFocused } />
    default:
      return null
  }
}
