import * as cn from 'classnames';
import { throttle } from 'lodash';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { PlayButtonState } from '~components/ScenePlayer/Player/controls/PlayPauseButton';
import { LIVE } from '~localization';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Stream from '~typings/Stream';

import RewindPointer from './RewindPointer';
import * as styles from './styles.module.css';
import { formatTimeLeft, getPercents } from './utils';


type Props = Readonly<{
  isLive?: boolean;
  isLoading: boolean;
  isFocused: boolean;
  durationInSec: number;
  currentTimeInSec: number;
  onSeek: (seekTo: number) => void;
  onKeyPressedToSeek: (seekTo: PlayButtonState.SeekToBackward | PlayButtonState.SeekToForward) => void;
  stream?: Stream;
  isSeeking?: boolean;
  intl: InjectedIntl;
  status?: any;
  item?: PlayerMediaItem;
}>;

const getTimeLeftText = (
  intl: InjectedIntl,
  duration: number,
  currentTime: number,
  isLive?: boolean,
): string => {
  if (isLive) {
    if (
      (currentTime + 2) >= duration
    ) {
      return intl.formatMessage({ id: LIVE });
    }
    else {
      return formatTimeLeft(duration, currentTime);
    }
  }

  return formatTimeLeft(duration, currentTime);
};


const ProgressBarView: React.FC<Props> = (props: Props) => {
  const progressBarWrapper = React.useRef<HTMLDivElement | null>(null);
  const progressBar = React.useRef<HTMLDivElement | null>(null);
  const progressRef = React.useRef<HTMLDivElement | null>(null);
  const timeLeftRef = React.useRef<HTMLSpanElement | null>(null);
  const percentsToSeek = React.useRef<number | null>(null);
  const percentsWatched = React.useMemo(
    () => Math.round(getPercents(props.durationInSec, props.currentTimeInSec)),
    [props.durationInSec, props.currentTimeInSec],
  );
  const audioshowObject = props.item?.object === 'part';
  const chapters = props.stream?.chapters.filter((chapter) => chapter.kind === 'chapter');
  const [rewindTime, setRewindTime] = React.useState<number | null>(null);

  const handlePointerMove = React.useCallback(
    throttle<any>((event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (progressBar.current) {
        //props.durationInSec время всего фильма в секундах, например 3584
        //pos текущая позиция над прогресс баром в пикселях от 0 до максимальной ширины прогресса 907
        //percentFromWidth считаем в процентах pos от ширины прогресса
        //rewindTo считаем сколько секунд составляет percentFromWidth от максимальной длительности фильма
        //в итоге мы должны получить значение в секундах для перемотки например 1425/3584
        const { width, left } = progressBar.current.getBoundingClientRect();
        const pos = Math.max(0, Math.min(event.clientX - left, width))
        const percentFromWidth = (pos / width) * 100
        const rewindTo = props.durationInSec * (percentFromWidth / 100)
        setRewindTime(rewindTo);
      }
    }, 200, { leading: false }),
    [props.durationInSec],
  );
  const handlePointerClick = React.useCallback(
    throttle<any>((event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (progressBar.current) {
        const { width, left } = progressBar.current.getBoundingClientRect();
        const pos = Math.max(0, Math.min(event.clientX - left, width))
        const percentFromWidth = (pos / width) * 100
        const rewindTo = props.durationInSec * (percentFromWidth / 100)
        setRewindTime(rewindTo);
        setRewindTime(rewindTo);
        props.onSeek(rewindTo);
        props.onKeyPressedToSeek(
          props.currentTimeInSec < rewindTo
            ? PlayButtonState.SeekToForward
            : PlayButtonState.SeekToBackward
        );
      }
    }, 400, { leading: false }),
    [props.currentTimeInSec, props.durationInSec],
  );
  const handlePointerLeave = React.useCallback(
    throttle<any>(() => {
      if (rewindTime === null) {
        return;
      }
      percentsToSeek.current = null;
      setRewindTime(null);
    }, 400, { leading: false }),
    [rewindTime],
  );
  const handlePressedToSeek = React.useCallback(
    (seekTo) => {
      setRewindTime(null);
      props.onKeyPressedToSeek(seekTo);
    },
    [],
  );

  ////// настройки отображения прогресс бара
  React.useEffect(() => {
    if (progressRef.current) {
      progressRef.current!.style.width = `${ percentsWatched }%`;
    }
  }, [percentsWatched, progressRef.current]);
  //////

  ///// настройка отображения времени в формате 00:00:00,
  ///// в обратном порядке(показывает сколько времени осталось до конца контента)
  React.useEffect(() => {
    const timeLeftText = getTimeLeftText(
      props.intl,
      props.durationInSec,
      props.currentTimeInSec,
      props.isLive,
    );

    if (timeLeftRef.current) {
      timeLeftRef.current!.innerText = timeLeftText;
    }
  }, [
    props.intl,
    props.durationInSec,
    props.currentTimeInSec,
    props.isLive,
  ]);
  /////

  React.useEffect(() => {
    if (rewindTime === null || props.isFocused) {
      return;
    }
    percentsToSeek.current = null;
    setRewindTime(null);
  }, [props.isFocused]);

  return (
    <>
      <div
        className={ cn(styles.progressBarWrapper, {
          [styles.focused]: props.isFocused,
        }) }
      >
        <div
          ref={ progressBarWrapper }
          className={ styles.progressBar2 }
          onMouseMove={ handlePointerMove }
          onMouseLeave={ handlePointerLeave }
          onClick={ handlePointerClick }
        >
          <div className={ styles.progressBar } ref={ progressBar }>
            <div ref={ progressRef } className={ styles.watched } />
            <RewindPointer
              isLoading={ props.isLoading }
              isFocused={ props.isFocused }
              durationInSec={ props.durationInSec }
              currentTimeInSec={ props.currentTimeInSec }
              percentsWatched={ percentsWatched }
              onSeek={ props.onSeek }
              rewindTime={ rewindTime }
              onKeyPressedToSeek={ handlePressedToSeek }
              stream={ props.stream }
              audioshowObject={ audioshowObject }
            />
            { chapters && chapters?.length !== 0 &&
              chapters.map((chapter, idx) => {
                return (
                  <div
                    key={ idx }
                    className={ styles.chapterMark }
                    style={ {
                      left: `${ (chapter.begin_timestamp / props.durationInSec) * 100 }%`,
                      backgroundColor: props.currentTimeInSec < chapter.begin_timestamp
                        ? 'rgba(255, 255, 255, 0.8)'
                        : 'rgba(90, 90, 90, 1)',
                    } }
                  />
                )
              })
            }
          </div>
        </div>
        <span ref={ timeLeftRef } className={ styles.time } />
      </div>
    </>
  );
};

export default React.memo(injectIntl(ProgressBarView));
