import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { channelName } from '~components/CurrentEventInfo/styles.module.css';
import SceneItem from '~components/Scene/typings';
import { getSmallText } from '~components/ScenePlayer/Player/controls/PlayerMediaItemShortInfo/utils';
import useMediaItemPageInfo from '~hooks/useMediaItemPage';
import ItemObject from '~typings/ItemObject';

import Progress from './Progress';
import RatingsUI from './Ratings';
import * as styles from './styles.module.css';


type Props = Readonly<{
  item: SceneItem;
  className?: string;
  classNames?: {
    progress?: string;
  };
  isContinueWatchingBlock: boolean;
  intl: InjectedIntl;
}>;

const RatingsBlock = ({ item, className, classNames, isContinueWatchingBlock, intl }: Props) => {
  const { isMediaItemPageMode } = useMediaItemPageInfo();

  const object = item.object === ItemObject.Card ? item.resource_type : item.object;
  if (
    isContinueWatchingBlock && !isMediaItemPageMode
    && (
      object === ItemObject.Movie
      || object === ItemObject.Channel
      || object === ItemObject.Episode
      || object === ItemObject.Part
    )
  ) {
    const id = item.object === ItemObject.Card ? item.resource_id : item.id;
    if (item.object === ItemObject.Episode) {
      const episodeAndSeasonText = getSmallText(item, intl);

      return (
        <>
          {
            episodeAndSeasonText &&
              (
                <div
                  className={ cn(channelName, styles.episodeAndSeasonText) }
                >
                  { episodeAndSeasonText }
                </div>
              )
          }
          <Progress
            id={ id }
            object={ object }
            className={ classNames?.progress }
          />
        </>
      );
    }

    return (
      <Progress
        id={ id }
        object={ object }
        className={ classNames?.progress }
      />
    );
  }

  return (
    <RatingsUI
      item={ item }
      className={ className }
    />
  );
};


export default injectIntl(RatingsBlock);
