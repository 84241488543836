import { useEffect } from 'react';


const useWeel = (
  cb: (this: HTMLElement, ev: WheelEvent) => any,
  update: any[] = [],
) => {
  useEffect(() => {
    if (update[0]) {
      document.documentElement.addEventListener('wheel', cb);
    } else {
      document.documentElement.removeEventListener('wheel', cb);
    }

    return () => {
      document.documentElement.removeEventListener('wheel', cb);
    }
  }, update)
};


export default useWeel;
