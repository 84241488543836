import AudioShow from '~typings/AudioShow';
import AudioShowPart from '~typings/AudioShowPart';
import Card from '~typings/Card';
import Channel from '~typings/Channel';
import { AnyItem, DataItem, DataItemWithCard } from '~typings/DataItem';
import Episode from '~typings/Episode';
import Event from '~typings/Event';
import ItemObject from '~typings/ItemObject';
import Movie from '~typings/Movie';
import Series from '~typings/Series';


const getResourceObjectType = (item: Exclude<DataItem, DataItemWithCard>): ItemObject => {
  if (item.object === ItemObject.Card) {
    return item.resource_type;
  }

  return item.object;
};

const isTheDesiredObject = (object: ItemObject, item?: AnyItem): boolean => {
  try {
    if (!item) {
      return false;
    } else if (isCard(item) && item.resource_type === object) {
      return true;
    }

    return false;
  } catch (ignore) {
    return false;
  }
};

const isCard = (item?: AnyItem): item is Card => (item?.object === ItemObject.Card);

const isCardChannel = (item?: AnyItem): item is Card => isTheDesiredObject(ItemObject.Channel, item);

const isCardAudioShow = (item?: AnyItem): item is Card => isTheDesiredObject(ItemObject.AudioShow, item);

const isChannel = (item?: AnyItem): item is Channel => (item?.object === ItemObject.Channel);

const isCardProgramEvent = (item?: AnyItem): item is Card => isTheDesiredObject(ItemObject.ProgramEvent, item);

const isProgramEvent = (item?: AnyItem): item is Event => (item?.object === ItemObject.ProgramEvent);

const isCardSeries = (item?: AnyItem): item is Card => isTheDesiredObject(ItemObject.Series, item);

const isSeries = (item?: AnyItem): item is Series => (item?.object === ItemObject.Series);

const isMovie = (item?: AnyItem): item is Movie => (item?.object === ItemObject.Movie);

const isAudioShow = (item?: AnyItem): item is AudioShow => (item?.object === ItemObject.AudioShow);

const isPart = (item?: AnyItem): item is AudioShowPart => (item?.object === ItemObject.Part);

const isCardEpisode = (item?: AnyItem): item is Card => isTheDesiredObject(ItemObject.Episode, item);

const isEpisode = (item?: AnyItem): item is Episode => (item?.object === ItemObject.Episode);

const isCardMovie = (item?: AnyItem): item is Card => isTheDesiredObject(ItemObject.Movie, item);

const isCardNews = (item?: AnyItem): item is Card => isTheDesiredObject(ItemObject.News, item);


export {
  getResourceObjectType,
  isAudioShow,
  isCard,
  isCardAudioShow,
  isCardChannel,
  isCardEpisode,
  isCardMovie,
  isCardNews,
  isCardProgramEvent,
  isCardSeries,
  isChannel,
  isEpisode,
  isMovie,
  isPart,
  isProgramEvent,
  isSeries,
};
