import * as moment from 'moment';

import CardMutation, { CardMutationState } from '~typings/CardMutation';


const getProgramEventMutation = (cardMutation?: CardMutation): CardMutationState | undefined => {
  if (!cardMutation || !cardMutation.states) { return undefined; }

  const current = moment(new Date());

  return cardMutation.states.find(({ start_at, end_at }) => {
    const startAt = moment(start_at);
    const endAt = end_at ? moment(end_at) : moment();

    return current.isBetween(
      startAt, endAt
    );
  });
};


export default getProgramEventMutation;
