import * as React from 'react';

import { useReadyVitalStore } from '~app/global';
import KeyCodes from '~typings/KeyCodes';
import Platform from '~typings/Platform';


type PlatformContext = {
  platform: Platform;
  keyCodes: KeyCodes;
};

const PlatformContext = React.createContext<PlatformContext>({} as PlatformContext);

export const usePlatform = () => React.useContext(PlatformContext);

export const PlatformProvider = React.memo(({ children }: { children?: React.ReactNode }) => {
  const platformParams = useReadyVitalStore(state => state.platformParams);

  return <PlatformContext.Provider value={ platformParams }>{children}</PlatformContext.Provider>;
});

PlatformProvider.displayName = 'PlatformProvider';
