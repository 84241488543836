import * as React from "react";


function CrossIcon(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 21 21" fill="none" { ...props }>
      <path
        d="M1.5 1.5L10.5 10.5M10.5 10.5L19.5 1.5M10.5 10.5L1.5 19.5M10.5 10.5L19.5 19.5"
        stroke="currentColor"
        strokeWidth="4"
      />
    </svg>
  );
}


export default React.memo(CrossIcon);
