// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button--OdTpW {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n\n  /* 36px */\n  width: 2.8125vw;\n\n  /* 36px */\n  height: 2.8125vw;\n  -webkit-border-radius: 50%;\n          border-radius: 50%;\n  background-color: rgba(38, 38, 42, 1);\n  color: rgba(255, 255, 255, 1);\n}\n\n.focused--gy2Un {\n  background-color: rgba(253, 216, 53, 1);\n  color: rgba(23, 23, 27, 1);\n}\n\n\n.icon--MLsam {\n  /* 24px */\n  width: 1.875vw;\n\n  /* 24px */\n  height: 1.875vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/ui-kit/ResetButton/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;;EAEnB,SAAS;EACT,eAAe;;EAEf,SAAS;EACT,gBAAgB;EAChB,0BAAkB;UAAlB,kBAAkB;EAClB,qCAAwB;EACxB,6BAAa;AACf;;AAEA;EACE,uCAAwB;EACxB,0BAAa;AACf;;;AAGA;EACE,SAAS;EACT,cAAc;;EAEd,SAAS;EACT,eAAe;AACjB","sourcesContent":[".button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  /* 36px */\n  width: 2.8125vw;\n\n  /* 36px */\n  height: 2.8125vw;\n  border-radius: 50%;\n  background-color: $gw_02;\n  color: $w_100;\n}\n\n.focused {\n  background-color: $ac_00;\n  color: $gw_00;\n}\n\n\n.icon {\n  /* 24px */\n  width: 1.875vw;\n\n  /* 24px */\n  height: 1.875vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var button = "button--OdTpW";
export var focused = "focused--gy2Un";
export var icon = "icon--MLsam";
export default ___CSS_LOADER_EXPORT___;
