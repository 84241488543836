/* globals window */
import * as React from 'react';
import { IntlProvider } from 'react-intl';

import { useApp } from '~components/Provider/App';
import Language from '~typings/Language';
import Localization from '~typings/Localization';


type MessagesByLocale = Readonly<
  {
    [key in Language]?: Localization;
  }
  >;

type Props = Readonly<{
  messagesByLocale: MessagesByLocale;
  language: Language;
}>;

export const LocalizationProvider : React.FC<Props> = React.memo((props): JSX.Element => {
  const { language } = props

  const messages = props.messagesByLocale[language];

  React.useEffect(() => {
    window.document.documentElement.setAttribute('lang', language);
  }, [language]);

  return (
    <IntlProvider
      locale={ language }
      messages={ messages }
    >
      {props.children}
    </IntlProvider>
  );
});

LocalizationProvider.displayName = 'LocalizationProvider';

export const LocalizationProviderWithApp: React.FC<Omit<Props, 'language'>> = ((props): JSX.Element => {
  const { language } = useApp();
  return (
    <LocalizationProvider
      language={ language }
      messagesByLocale={ props.messagesByLocale }
    >
      {props.children}
    </LocalizationProvider>
  );
});
