import React from 'react';

import * as styles from './styles.modules.css';

type Props = {
  percentageWatch: number;
}

export const ProgressLine: React.FC<Props> = ({ percentageWatch }) => {
  
  return (
    <div className={ styles.progressBarWrapper }>
       <div className={ styles.progressBar } style={ { width: `${ Math.min(Math.max(percentageWatch, 0), 100) }%` } } />
    </div>
  )
}