import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import { useViewSummary } from '~hooks/fetch/useAccount/useViewSummary';
import { VIEW_SUMMARY } from '~lib/localizator/src/dictionaries';
import { SpinnerFullScreenIsolated } from '~newapp/globalBlocks/spinner';

import { StatsItem } from './StatsItem/StatsItem';
import * as styles from './styles.modules.css';
import { PreparedPart } from './types';


export const ViewStatistics: React.FC = () => {
  const { isLoading, data } = useViewSummary()
  const statistic = data?.data

  const { preparedArray, allSeconds } = React.useMemo(() => {
    const emptyArr: PreparedPart[] = []

    for (const key in statistic) {
      emptyArr.push({ title: key, stats: statistic[key] })
    }

    const preparedArray = emptyArr.filter(el => el.title !== 'object')
    const allSeconds = preparedArray.reduce((accum, el) => {
      accum += el.stats.duration
      return accum
    }, 0)
    
    return {
      preparedArray,
      allSeconds
    }
  }, [statistic])

  if(isLoading) {
    return <SpinnerFullScreenIsolated />
  }

  return (
    <div className={ styles.contentWrapper }>
      <Typography variant='h5_bold' className={ styles.title }>
        <FormattedMessage id={ VIEW_SUMMARY }/>
      </Typography>

      <div className={ styles.itemsContainer }>
        { preparedArray.map((item, index) => {
            return (
              <StatsItem
                key={ index }
                allSeconds={ allSeconds }
                item={ item } 
              />   
            )
          })
        }
      </div>
    </div>
  )
}
