/* eslint-disable max-len */
import * as React from 'react';

type Props = {
  width?: string
  height?: string
  color: string
}

const ForButtonsRewindLeft = ({ width = '2.34375vw', height = '4.166666666666666vh', color }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 30 30" fill={ color } xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6888 7.24069L0.86099 13.5586C-0.0309674 14.132 -0.0625865 15.4246 0.800269 16.0409L10.6281 23.0608C11.6209 23.7699 12.9999 23.0602 12.9999 21.8402V8.50245C12.9999 7.3157 11.687 6.59894 10.6888 7.24069Z" />
      <path
        d="M24.689 7.24069L14.8612 13.5586C13.9693 14.132 13.9377 15.4246 14.8005 16.0409L24.6283 23.0608C25.6211 23.7699 27.0002 23.0602 27.0002 21.8402V8.50245C27.0002 7.3157 25.6873 6.59894 24.689 7.24069Z" />
    </svg>
  </div>
);


export { ForButtonsRewindLeft };
