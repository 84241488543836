import focuser from '@focuser';
import cn from 'classnames';
import React from 'react';

import { SpinnerIcon } from '~newapp/globalBlocks/spinner';

import * as commonStyles from '../../BigBannerCommon.module.css';
import * as styles from './BigBannerLoading.module.css';

export const BigBannerLoading = () => (
  <focuser.FocusableBlock
    isFocused
    isLastBlock
    emitForceFocusOnHover
    noNeedForceFocus
    className={ cn(commonStyles.mainWrapperWidthAndHeight, styles.centered) }
  >
    <SpinnerIcon />
  </focuser.FocusableBlock>
);
