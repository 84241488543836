import { KeyBoardKey, KeyBoardKeyType } from "~typings/Keyboard";

export const newFieldValue = (value: string | number, key: KeyBoardKey, maxLen: number = Infinity): string => {
  const isStringValue = value.toString();
  if (key.type === KeyBoardKeyType.Backspace) {
    return isStringValue.slice(0, -1);
  }
  
  if (isStringValue.length >= maxLen) {
    return isStringValue.substr(0, maxLen);
  }

  if (key.type === KeyBoardKeyType.Space) {
    return `${isStringValue} `;
  } 

  return `${isStringValue}${key.id}`;
};
