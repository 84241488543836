import * as cn from 'classnames';
import * as React from 'react';

import { useUpdatableImage } from '~hooks/useUpdatableImage';
import { fillImageTemplateBySizes } from '~lib/image';
import Avatar from '~typings/Avatar';
import NavigationDirection from '~typings/NavigationDirection';
import Profile from '~typings/Profile';
import { IconBase } from '~ui/Icon';
import { NavigationHelper } from '~ui/NavigationHelper';

import defaultImage from './defaultProfileImage.svg';
import * as styles from './styles.module.css';

type IconProps = {
  className?: string;
};

type Props = Readonly<{
  className?: string;
  profile?: Profile;
  avatar?: Avatar | null;
  isFocused?: boolean;
  width?: number;
  height?: number;
  OverflowIcon?: React.FC<IconProps>;
  overflowIconClassName?: string;
  isOnEditProfilePage?: boolean;
  onClick?: (profileId?: string) => void;
  onLeave?: (direction: NavigationDirection) => void;
  disableHover?: boolean;
}>;


const ProfileImage: React.FC<Props> = (props: Props) => {
  const {
    profile,
    avatar,
    width = 240,
    height = 240,
    OverflowIcon,
    overflowIconClassName,
    disableHover
  } = props;

  const curAvatar = profile?.avatar ?? avatar;
  const imgUrlTemplate = curAvatar?.image?.url_template;

  const urlTemplatePrepared = imgUrlTemplate ?
    fillImageTemplateBySizes(imgUrlTemplate, width, height)
    : null;

  const { data, error } = useUpdatableImage({ url: urlTemplatePrepared });

  const finalUrl = React.useMemo(() => {

    if(!urlTemplatePrepared){
      return defaultImage;
    }

    if(error){
      return defaultImage;
    }

    return data;
  }, [data, error, urlTemplatePrepared]);


  const clickHandler = () => {
    if (props.onClick) {
      props.onClick(props.profile?.id)
    }
  };

  return (
    <>
      {
        (props.onLeave && props.isFocused) && (
          <NavigationHelper
            isFocused={ props.isFocused }
            onLeave={ props.onLeave }
          />
        )
      }
      <div
        onClick={ clickHandler }
        style={ finalUrl ? { backgroundImage: `url(${finalUrl})` } : undefined }
        className={ cn(
          styles.profileImage,
          props.className, {
            [styles.focused]: props.isFocused && !props.isOnEditProfilePage,
            [styles.focusedInEditPage]: props.isFocused,
            focusedNavigationNode: props.isFocused,
            [styles.disableHover]: disableHover
          }) }
      >
        {
          (!!OverflowIcon) && (
            <IconBase
              Icon={ OverflowIcon }
              classNames={ { wrapper: cn(styles.icon, overflowIconClassName) } }
            />
          )
        }
      </div>
    </>
  );
};


export default ProfileImage;
