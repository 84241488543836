import * as cn from 'classnames';
import * as React from 'react';

import ImageType from '~typings/ImageType';

import * as styles from '../styles.module.css';


type Props = Readonly<{
  src: string;
}>;


const ChannelContentImagePreview: React.FC<Props> = (props: Props) => (
  <div
    className={ cn(styles.image, styles[ImageType.PREVIEW]) }
    style={ {
      backgroundImage: `url("${props.src}")`,
    } }
  />
);


export default React.memo(ChannelContentImagePreview);
