import { FormattedMessage } from 'react-intl';

import { ERROR_HAS_OCCURRED,TOO_MANY_REQUESTS } from '~localization';

interface IntlFormatMessageFunction {
  (messageDescriptor: FormattedMessage.MessageDescriptor, values?: {[key: string]: any }): string
}

const ErrorMessageIds = {
  too_many_requests: TOO_MANY_REQUESTS,
  pin_is_not_set: undefined // TODO
}

const getMessageByError = (error, intlFormatFn: IntlFormatMessageFunction) => {
  const errorCode = error?.errors?.[0]?.code;
  const messageId = ErrorMessageIds[errorCode] ?? ERROR_HAS_OCCURRED;

  return intlFormatFn({id: messageId});
};

export {
  getMessageByError,
};
