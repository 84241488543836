import * as React from 'react';
import { useRef } from 'react';

import { Events } from '~lib/player/common';
import { IPlayer } from '~lib/player/typings';
import VideoError from "~lib/player/VideoError";


type Props = Readonly<{
  player: IPlayer;
  onCanPlay: () => void;
  onEnded: () => void;
  onError: (error: any) => void;
}>;

export enum PlayerStatus {
  LoadedMetaData = 'loadedMetaData',
  CanPlay = 'canPlay',
  Pause = 'pause',
  Play = 'play',
  Loading = 'loading',
  Seeking = 'seeking',
  Waiting = 'waiting',
  TrackChanged = 'trackChanged',
}

export type PlayerState = Readonly<{
  status: PlayerStatus;
}>;


const useHandleEvents = (props: Props): PlayerState => {
  const isMounted = useRef(false);
  const [status, setStatus] = React.useState<PlayerStatus>(PlayerStatus.Loading);
  const { player } = props;
  const handlePlayerLoadedMetaData = () => {
    if (isMounted.current) {
      setStatus(PlayerStatus.LoadedMetaData);
    }
  };
  const setStatusPlay = () => {
    if (isMounted.current) {
      setStatus(PlayerStatus.Play);
    }
  };
  const setStatusPause = () => {
    if (isMounted.current) {
      setStatus(PlayerStatus.Pause);
    }
  };
  const setStatusWaiting = () => {
    if (isMounted.current) {
      setStatus(PlayerStatus.Waiting);
    }
  };
  const setStatusTrackChanged = () => {
    if (isMounted.current) {
      setStatus(PlayerStatus.TrackChanged);
      setTimeout(() => {
        if (isMounted.current) {
          setStatusPlay();
        }
      }, 100);
    }
  };
  const handlePlayerCanPlay = (): void => {
    setStatus(PlayerStatus.CanPlay);

    if (status !== PlayerStatus.Play) {
      props.onCanPlay();
    }

    player.play();
  };
  const handlePlayerError = (error: VideoError): void => {
    player.reset();
    props.onError(error);
  };
  const handlePlayerEnded = () => {
    props.onEnded();
  };
  const handleSeeking = () => {
    if (isMounted.current) {
      setStatus(PlayerStatus.Seeking);
    }
  };

  React.useEffect(() => {
    isMounted.current = true;
    player.on(Events.LOADED_METADATA, handlePlayerLoadedMetaData);
    player.on(Events.CAN_PLAY, handlePlayerCanPlay);
    player.on(Events.PAUSE, setStatusPause);
    player.on(Events.PLAY, setStatusPlay);
    player.on(Events.PLAYING, setStatusPlay);
    player.on(Events.WAITING, setStatusWaiting);
    player.on(Events.SEEKING, handleSeeking);
    player.on(Events.SEEKED, setStatusPlay);
    // TODO: какая-то лажа: если раскомментить, то плеер думает, что стрим поставлен на паузу и сейчас не лайв
    player.on(Events.TRACK_CHANGED, setStatusTrackChanged);
    // player.on(Events.EVENT, ({ type }) => { console.log('EVENT', type); });
    player.on(Events.ERROR, handlePlayerError);
    player.on(Events.ENDED, handlePlayerEnded);

    return () => {
      setStatus(PlayerStatus.Loading);
      isMounted.current = false;

      player.removeListener(Events.LOADED_METADATA, handlePlayerLoadedMetaData);
      player.removeListener(Events.CAN_PLAY, handlePlayerCanPlay);
      player.removeListener(Events.PAUSE, setStatusPause);
      player.removeListener(Events.PLAY, setStatusPlay);
      player.removeListener(Events.PLAYING, setStatusPlay);
      player.removeListener(Events.WAITING, setStatusWaiting);
      player.removeListener(Events.SEEKING, handleSeeking);
      player.removeListener(Events.SEEKED, setStatusPlay);
      player.removeListener(Events.TRACK_CHANGED, setStatusTrackChanged);
      player.removeListener(Events.ERROR, handlePlayerError);
      player.removeListener(Events.ENDED, handlePlayerEnded);
    };
  }, []);

  return React.useMemo(() => ({
    status,
  }), [status]);
};


export default useHandleEvents;
