import { gw_04 } from 'customization-project-name/colors'
import * as React from 'react';

type Props = {
  className?: string;
};

/* eslint-disable max-len */
const SmartTVIcon: React.FC<Props> = ({ className = '' }) => (
  <svg className={ className } viewBox="0 0 70 50" xmlns="http://www.w3.org/2000/svg">
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M48.2071 45.9333C48.6272 45.9333 48.9772 46.2834 48.9772 46.7035V48.3664C48.9772 48.7865 48.6272 49.1366 48.2071 49.1366H18.4321C18.012 49.1366 17.6619 48.7865 17.6619 48.3664V46.7035C17.6619 46.2834 18.012 45.9333 18.4321 45.9333H48.2071ZM56.2941 15.8083C56.2941 14.2854 58.5346 12.1324 62.4031 11.9398C62.8757 11.9223 63.5409 11.9223 64.3636 11.9398V3.76527C64.3636 3.29265 63.9785 2.92506 63.5234 2.92506H3.09827C2.64316 2.92506 2.25806 3.31015 2.25806 3.76527V38.4764C2.25806 38.9491 2.64316 39.3166 3.09827 39.3166H56.2941V15.8083ZM57.7819 16.5785C57.7819 14.653 60.3551 13.2701 63.891 13.2701C64.5911 13.2701 65.2388 13.3227 65.8515 13.4277V2.27739C65.8515 1.80478 65.4664 1.43718 65.0112 1.43718H1.6104C1.15529 1.43718 0.770193 1.82228 0.770193 2.27739V39.9643C0.770193 40.4369 1.15529 40.8045 1.6104 40.8045H57.7994V16.5785H57.7819ZM66.6217 13.5852C68.6697 14.0928 70 15.1956 70 16.5785V45.8283C70 47.7538 67.4268 49.1366 63.891 49.1366C60.3551 49.1366 57.7819 47.7362 57.7819 45.8283V41.5922H1.6104C0.717679 41.5922 0 40.8745 0 39.9818V2.27739C0 1.38467 0.717679 0.666992 1.6104 0.666992H65.0288C65.9215 0.666992 66.6392 1.38467 66.6392 2.27739V13.5852H66.6217ZM63.891 21.6897C66.2716 21.6897 68.197 23.6152 68.197 25.9958C68.197 28.3764 66.2716 30.3019 63.891 30.3019C61.5104 30.3019 59.5849 28.3764 59.5849 25.9958C59.5849 23.6152 61.5104 21.6897 63.891 21.6897ZM63.891 22.4599C61.948 22.4599 60.3551 24.0353 60.3551 25.9958C60.3551 27.9563 61.9305 29.5317 63.891 29.5317C65.8515 29.5317 67.4269 27.9563 67.4269 25.9958C67.4269 24.0353 65.8515 22.4599 63.891 22.4599Z" 
      fill={ gw_04 }
    />
  </svg>
);


export default SmartTVIcon;