// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--kv4B_ {\n  position: relative;\n}\n\n.wrapper--kv4B_.focused--HgO0f {\n  top: -24vw;\n}\n\n.wrapper--kv4B_.firstBlock--x2D5z {\n  top: -27vw;\n}\n\n.mask--pJF6z {\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  height: 100vh;\n}\n\n.dottedSlider--kGkrS {\n  position: relative;\n  top: -30.6vw;\n}\n\n.bannerMiddle--TMfCq {\n  position: absolute;\n  z-index: 1;\n  top: 0;\n  right: 0;\n  left: 0;\n  width: 100%;\n  height: 18.75vw;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n.bannerMiddle--TMfCq.focused--HgO0f {\n  top: -15vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/BlockBannersV4/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,OAAO;EACP,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,MAAM;EACN,QAAQ;EACR,OAAO;EACP,WAAW;EACX,eAA4C;EAC5C,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.wrapper.focused {\n  top: -24vw;\n}\n\n.wrapper.firstBlock {\n  top: -27vw;\n}\n\n.mask {\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  height: 100vh;\n}\n\n.dottedSlider {\n  position: relative;\n  top: -30.6vw;\n}\n\n.bannerMiddle {\n  position: absolute;\n  z-index: 1;\n  top: 0;\n  right: 0;\n  left: 0;\n  width: 100%;\n  height: $(blockHeightBannerFullScreenInVW)vw;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n.bannerMiddle.focused {\n  top: -15vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = "wrapper--kv4B_";
export var focused = "focused--HgO0f";
export var firstBlock = "firstBlock--x2D5z";
export var mask = "mask--pJF6z";
export var dottedSlider = "dottedSlider--kGkrS";
export var bannerMiddle = "bannerMiddle--TMfCq";
export default ___CSS_LOADER_EXPORT___;
