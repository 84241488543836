import * as cn from 'classnames';
import * as React from 'react';

import RatingsUI from '~components/RatingsBlock/Ratings';
import BannerBig from '~typings/BannerBig';
import BannerMiddle from '~typings/BannerMiddle';
import ItemObject from '~typings/ItemObject';
import Button from '~ui/button';
import SceneItemName from '~ui/SceneItemName';

import * as styles from './styles.module.css';


type Props = Readonly<{
  blockIndex: number;
  focusedBlockIndex: number;
  isFocused: boolean;
  item: BannerBig | BannerMiddle;
  onClick?: (banner: BannerBig | BannerMiddle) => void;
}>;


const BannerTextBlock: React.FC<Props> = (props: Props) => {
  const isBannerMiddle = (props.item.object === ItemObject.MiddleBanner);
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.item);
    }
  };
  const renderBigBannerContent = () => (
    <>
      <RatingsUI className={ styles.ratingsBlock } item={ props.item }/>
      {
        (props.item.big_screen_text_block.title) &&
          <SceneItemName
            className={ styles.name }
            name={ props.item.big_screen_text_block.title }
            withFullInfo={ false }
          />
      }
      {
        (props.item.big_screen_text_block.other_data) &&
          <div className={ styles.otherData }>
            { props.item.big_screen_text_block.other_data }
          </div>
      }
      {
        (props.item.big_screen_text_block.subtitle) &&
          <>
            <div className={ styles.subTitleWrapper }>
              <div className={ styles.subTitle }>
                { props.item.big_screen_text_block.subtitle }
              </div>
            </div>
            <br />
          </>
      }
    </>
  );
  const renderMiddleBannerContent = () => (
    <>
      {
        (props.item.big_screen_text_block.title) &&
          <div className={ styles.h2 }>
            { props.item.big_screen_text_block.title }
          </div>
      }
      {
        (props.item.big_screen_text_block.subtitle) &&
          <div className={ styles.p }>
            { props.item.big_screen_text_block.subtitle }
          </div>
      }
    </>
  );
  const renderButtonText = (): JSX.Element | null => {
    if (
      props.item.big_screen_text_block.button_text &&
      props.item.big_screen_text_block.button_text.length !== 0
    ) {
      return (
        <Button
          isFocused={ props.isFocused }
          className={ styles.button }
          onClick={ handleClick }
        >
          { props.item.big_screen_text_block.button_text }
        </Button>
      );
    }

    return null;
  };

  return (
    <div
      className={ cn(styles.textBlock, {
        [styles.bannerMiddle]: isBannerMiddle,
        [styles.focused]: (props.blockIndex === props.focusedBlockIndex),
      }) }
    >
      <div
        className={ cn(styles.text, props.item.text_position) }
      >
        {
          (isBannerMiddle)
            ? renderMiddleBannerContent()
            : renderBigBannerContent()
        }
        { renderButtonText() }
      </div>
    </div>
  );
};


export default BannerTextBlock;
