import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { COMPONENT_TESTING_PAGE_PATH } from './ComponentTestingPage.utils';
import { ComponentTestingPagePicker } from './ComponentTestingPagePicker';
import { TestCase } from './TestableComponentsList';

export const ComponentTestingPage$ = () => {
  const [selectedTestCase, setSelectedTestCase] = React.useState<TestCase | null>(null);
  const history = useHistory();
  const match = useRouteMatch<{ testCaseName: string }>(`${COMPONENT_TESTING_PAGE_PATH}/:testCaseName`);

  const handleSelect = useCallback((testCase: TestCase) => {
    setSelectedTestCase(testCase);
    history.push(`${COMPONENT_TESTING_PAGE_PATH}/${testCase.name}`);
  }, []);

  if (!selectedTestCase || !match) {
    return <ComponentTestingPagePicker onSelect={ handleSelect } />;
  }

  return <selectedTestCase.component />;
};

/**
 * Компонент для тестирования других компонентов на телевизоре
 */
export const ComponentTestingPage = React.memo(ComponentTestingPage$);
