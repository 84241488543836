import { useCallback, useMemo, useState } from 'react';

/**
 *
 * Это фабрика хука, то есть сначала нужно создать хук, а потом использовать
 * Помогает в тех родительских компонентах, где переход с одного ребенка на другой требует проверки,
 * готов ли ребенок принять фокус.
 * Например на странице MoviePage есть три ребенка: Description, MediaItemBar, Recommendations
 * Но при нажатии вниз с MediaItemBar, надо перейти на Recommendations, но только если рекомендации доступны
 * Пример использования можно посмотреть в компоненте MoviePage
 *
 * makeReadyChildsMapperHook принимает объект с ключами, и порядок важен!
 * Например
 *
  ```ts
  makeReadyChildsMapperHook({
    description: false,
    bar: false,
    recs: false,
  })
  ```
  означает, что первый ребенок - Description, второй - MediaItemBar, третий - Recommendations
  (то есть так, как отображаться должно на странице)
 *
 */
export const makeReadyChildsMapperHook = <S extends string>(childsOfComponent: {
  [key in S]: boolean;
}) => {
  type ChildsLowerCase = Lowercase<S>;

  const passedChildKeys = Object.keys(childsOfComponent) as S[];
  const lowerChildKeys = passedChildKeys.map((key) => key.toLowerCase()) as Lowercase<S>[];

  const initialValues: Record<Lowercase<S>, boolean> = passedChildKeys.reduce(
    (acc, key) => {
      const lowerKey = key.toLowerCase() as Lowercase<S>;
      acc[lowerKey] = childsOfComponent[key];
      return acc;
    },
    {} as Record<Lowercase<S>, boolean>,
  );

  type setIsChildReadyKeys = `setIs${Capitalize<ChildsLowerCase>}Ready`;

  type HookReturnType = {
    nextChildToNavigate: ChildsLowerCase | undefined;
    prevChildToNavigate: ChildsLowerCase | undefined;
    updateChildReadyStatus: (key: ChildsLowerCase, value: boolean) => void;
    focusOn: ChildsLowerCase;
    setFocusOn: React.Dispatch<React.SetStateAction<Lowercase<S>>>;
    readyChilds: Record<ChildsLowerCase, boolean>;
  } & {
    [key in setIsChildReadyKeys]: (value: boolean) => void;
  };

  /**
   *
   * @param initialFocus, если передать `null`, то по умолчанию,
   * начальный фокус будет первом активном ребенком
   */
  return (initialFocus: ChildsLowerCase | null ): HookReturnType => {

    // Начальное значение выставляется либо переданное, а если оно не передано,
    // то либо первый активный, либо просто первый
    const [focusOn, setFocusOn] = useState<ChildsLowerCase>(
      initialFocus ?? lowerChildKeys.find((key) => initialValues[key]) ?? lowerChildKeys[0]!,
    );

    const [readyChilds, setReadyChilds] = useState(initialValues);

    const updateChildReadyStatus = useCallback((key: ChildsLowerCase, value: boolean) => {
      setReadyChilds((prev) => prev[key] === value ? prev : ({ ...prev, [key]: value }));
    }, []);

    const nextChildToNavigate = useMemo(() => {
      const currentChildIdx = lowerChildKeys.indexOf(focusOn);

      const nextChilds = lowerChildKeys.slice(currentChildIdx + 1);
      const nextChild = nextChilds.find((child) => readyChilds[child]);
      return nextChild;
    }, [focusOn, readyChilds]);

    const prevChildToNavigate = useMemo(() => {
      const currentChildIdx = lowerChildKeys.indexOf(focusOn);

      const prevChilds = lowerChildKeys.slice(0, currentChildIdx);
      const prevChild = prevChilds
        .slice()
        .reverse()
        .find((child) => readyChilds[child]);

      return prevChild;
    }, [focusOn, readyChilds]);

    const setReadyCallbacks = useMemo(() => {
      const callbacks = lowerChildKeys.reduce(
        (acc, key) => {
          acc[`setIs${capitalizeFirstLetter(key)}Ready`] = (value: boolean) => {
            updateChildReadyStatus(key, value);
          };
          return acc;
        },
        {} as Record<setIsChildReadyKeys, (value: boolean) => void>,
      );

      return callbacks;
    }, []);

    return {
      updateChildReadyStatus,
      nextChildToNavigate,
      prevChildToNavigate,
      focusOn,
      setFocusOn,
      readyChilds,
      ...setReadyCallbacks,
    };
  };
};


function capitalizeFirstLetter<T extends string>(string: T): Capitalize<T> {
  return (string.charAt(0).toUpperCase() + string.slice(1)) as Capitalize<T>;
}
