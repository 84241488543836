import { debounce, isEqual } from 'lodash';
import * as React from 'react';

import { useApp } from '~components/Provider/App';
import { useMenu } from '~components/Provider/Menu';
import { useIsPagesFetched } from '~hooks/fetch/usePages/usePages';
import useHotKey from '~hooks/useHotKey';
import usePageInfo from '~hooks/usePageInfo';
import usePointer from '~stores/Pointer';
import HotKey from '~typings/HotKey';
import PointerNavigation from '~typings/PointerNavigation';

import { useIsPageManagerPointersBlocked } from './PageManager.helper';


type Props = Readonly<{
  action: () => void;
  triggers: any[];
}>;

const CatchBack: React.FC<Props> = (props: Props): null => {
  const { isLaunchingDone } = useApp();
  const isPagesFetched = useIsPagesFetched();
  const { isMenuAppeared, isPopupOpened } = useMenu();
  const { isProfileSelectPage } = usePageInfo();
  const isPageManagerPointersBlocked = useIsPageManagerPointersBlocked();

  useHotKey({
    isMounted: true,
    keyName: HotKey.KEY_RETURN,
    action: debounce(props.action, 200),
  }, props.triggers);

  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const availableBackButton = usePointer(state =>
    !state.directions[PointerNavigation.Menu]
    && !state.directions[PointerNavigation.Close]
    && !isMenuAppeared && !!isLaunchingDone && isPagesFetched && !isProfileSelectPage
    || isPopupOpened
  );
  const updateDirections = usePointer(state => state.updateDirections);

  React.useEffect(() => {
    if (pointerEnabled && !isPageManagerPointersBlocked) {
      updateDirections({
        [PointerNavigation.Back]: availableBackButton,
      });
    }
  }, [
    pointerEnabled,
    availableBackButton,
    updateDirections,
    isPageManagerPointersBlocked,
  ]);

  return null;
};


export default React.memo(CatchBack, (p: Props, n: Props) => (
  isEqual(p.triggers, n.triggers)
));
