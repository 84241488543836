/* eslint-disable max-len */
import { ac_00, gw_00, gw_02, gw_04, gw_11 } from 'customization-project-name/colors';
import * as React from 'react';

import { iconFavorite } from './styles.module.css';

export const IconFavoriteBase: React.FC<{ isFocused: boolean; isPressed: boolean }> = ({
  isFocused,
  isPressed,
}) => (
  <svg
    className={ iconFavorite }
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="22"
      cy="22"
      r="22"
      fill={ isFocused ? ac_00 : isPressed ? gw_11 : gw_02 }
    />
    <path
      d="M14.5352 14.667C14.5352 13.5624 15.4306 12.667 16.5352 12.667H27.4685C28.5731 12.667 29.4685 13.5624 29.4685 14.667V31.4597C29.4685 32.3846 28.3208 32.8138 27.7139 32.1159L22.7564 26.4148C22.3578 25.9564 21.6459 25.9564 21.2472 26.4148L16.2898 32.1159C15.6829 32.8138 14.5352 32.3846 14.5352 31.4597V14.667Z"
      fill={ isFocused ? gw_00 : isPressed ? gw_04 : gw_11 }
    />
  </svg>
);
