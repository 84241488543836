import { format as formatURL, parse as parseURI } from 'url';

import { Player } from '~typings/Player';

export default class WebOSPlayerNativeDRM {
  clientId: null;
  drmClientType: null;
  isDrmClientLoaded: boolean;
  drmConfig: {
    DeviceID?: string;
    StreamID?: string;
    ClientIP?: string;
    ContentURL?: string;
    LicenseServer?: string;
    type?: string;
    Player?: Player;
    AssetID?: string;
    DRMAckServerURL?: string;
    DRMHeartBeatURL?: string;
    DRMHeartBeatPeriod?: string;
    UserData?: string;
    Portal?: string;
    StoreFront?: string;
    BandwidthCheckURL?: string;
    BandwidthCheckInterval?: string;
  } | null;
  play: ((...args: any[]) => void) | null;
  url: string | null;
  onError: ((...args: any[]) => void) | null;

  constructor() {
    this.clientId = null;
    this.drmClientType = null;
    this.isDrmClientLoaded = false;
    this.drmConfig = null;
    this.play = null;
    this.url = null;
    this.onError = null;
    window.addEventListener('unload', () => {
      this.unloadDrmClient();
    });
  }
  unloadDrmClient = () => {
    console.log('START unloadDrmClient'); // eslint-disable-line
    return new Promise((resolve) => {
      if (this.isDrmClientLoaded) {
        webOS.service.request('luna://com.webos.service.drm', {
          method: 'unload',
          parameters: { clientId: this.clientId },
          onSuccess: (result) => {
            this.isDrmClientLoaded = false;
            // console.log('SUCCESS unloadDrmClient');
            resolve(result);
          },
          onFailure: (result) => {
            console.error(
              '[Device_Webos_Player] unloadDrmClient failed. [' +
                result.errorCode +
                '] ' +
                result.errorText,
            );
            console.log('FAIL unloadDrmClient'); // eslint-disable-line
            // Do something for error handling
            throw new Error(
              'Player.unloadDrmClient onFailure: ' +
                '[' +
                result.errorCode +
                '] ' +
                result.errorText,
            );
          },
        });
      }
    });
  };

  loadDrmClient = (drmType) => {
    this.drmClientType = drmType;
    const parameters = {
      drmType,
      appId: webOS.fetchAppId(),
    };
    console.log('loadDrmClient parameters:', parameters); // eslint-disable-line

    return new Promise((resolve) => {
      webOS.service.request('luna://com.webos.service.drm', {
        method: 'load',
        parameters,
        onSuccess: (result) => {
          console.log('[Device_Webos_Player] loadDrmClient successful.:' + JSON.stringify(result)); // eslint-disable-line
          this.clientId = result.clientId;
          this.isDrmClientLoaded = true;
          resolve(result);
        },
        onFailure: (result) => {
          console.error(
            '[Device_Webos_Player] loadDrmClient failed. [' +
              result.errorCode +
              '] ' +
              result.errorText,
          );
          throw new Error(
            'Player.loadDrmClient onFailure: ' + '[' + result.errorCode + '] ' + result.errorText,
          ); // Do something for error handling
        },
      });
    });
  };

  drmClient = (drmType) => {
    this.loadDrmClient(drmType)
      .then(() => {
        this.sendDrm(this.clientId);
      })
      .catch((err) => {
        this.onError?.(-1, 'drmClient', err);
      });
  };
  /**
   * Subscribe Licensing Error
   *
   * @private
   * @param {String} clientId
   * @param {String} msgId
   */
  subscribeLicensingError = (clientId, msgId) => {
    webOS.service.request('luna://com.webos.service.drm', {
      method: 'getRightsError',
      parameters: {
        clientId: clientId,
        subscribe: true,
      },
      onSuccess: (result) => {
        // Subscription Callback
        const contentId = result.contentId;
        console.log('errorState: ' + result.errorState); // eslint-disable-line
        if (contentId === msgId) {
          if (0 === result.errorState) {
            console.error('No license');
            // Do something for error handling
          } else if (1 === result.errorState) {
            console.error('Invalid license');
            // Do something for error handling
          }
        }
      },
      onFailure: (result) => {
        console.error(
          'Player.subscribeLicensingError onFailure: ' +
            '[' +
            result.errorCode +
            '] ' +
            result.errorText,
        );
      },
    });
  };
  sendDrmMessage = (clientId) => {
    return new Promise((resolve) => {
      // let msgId = '';
      let msgType = '';
      let drmSystemId = '';
      let msg = '';

      const drmType = this.drmConfig?.type;
      if (drmType === 'PLAYREADY') {
        msgType = 'application/vnd.ms-playready.initiator+xml'; // Message type of DRM system
        drmSystemId = 'urn:dvb:casystemid:19219'; // Unique ID of DRM system

        const drmOption = this.drmConfig || {};
        // var customData = typeof(drmOption.CustomData) !== 'undefined' ? drmOption.CustomData : '';
        const licenseServer =
          typeof drmOption.LicenseServer !== 'undefined' ? drmOption.LicenseServer : '';
        console.log('drmOption.LicenseServer:', drmOption.LicenseServer); // eslint-disable-line
        console.log('licenseServer:', licenseServer); // eslint-disable-line

        // Message for PlayReady
        msg = '<?xml version="1.0" encoding="utf-8"?>';
        msg += '<PlayReadyInitiator xmlns="http://schemas.microsoft.com/DRM/2007/03/protocols/">';
        msg += '<LicenseServerUriOverride>';
        msg += '<LA_URL>' + licenseServer + '</LA_URL>';
        msg += '</LicenseServerUriOverride>';
        msg += '</PlayReadyInitiator>';
      } else if (drmType === 'WIDEVINE') {
        msgType = 'application/widevine+xml'; // Message type for widevine 'xml'
        drmSystemId = 'urn:dvb:casystemid:19156'; // Unique ID of DRM system

        const drmOption = this.drmConfig || {};
        const ContentURL = typeof drmOption.ContentURL !== 'undefined' ? drmOption.ContentURL : '';
        let DRMServerURL =
          typeof drmOption.LicenseServer !== 'undefined' ? drmOption.LicenseServer : '';
        const DeviceID = typeof drmOption.DeviceID !== 'undefined' ? drmOption.DeviceID : '';
        const StreamID = typeof drmOption.StreamID !== 'undefined' ? drmOption.StreamID : '';
        const ClientIP = typeof drmOption.ClientIP !== 'undefined' ? drmOption.ClientIP : '';
        const DRMAckServerURL =
          typeof drmOption.DRMAckServerURL !== 'undefined' ? drmOption.DRMAckServerURL : '';
        const DRMHeartBeatURL =
          typeof drmOption.DRMHeartBeatURL !== 'undefined' ? drmOption.DRMHeartBeatURL : '';
        const DRMHeartBeatPeriod =
          typeof drmOption.DRMHeartBeatPeriod !== 'undefined' ? drmOption.DRMHeartBeatPeriod : '';
        const UserData = typeof drmOption.UserData !== 'undefined' ? drmOption.UserData : '';
        const Portal = typeof drmOption.Portal !== 'undefined' ? drmOption.Portal : '';
        const StoreFront = typeof drmOption.StoreFront !== 'undefined' ? drmOption.StoreFront : '';
        const BandwidthCheckURL =
          typeof drmOption.BandwidthCheckURL !== 'undefined' ? drmOption.BandwidthCheckURL : '';
        const BandwidthCheckInterval =
          typeof drmOption.BandwidthCheckInterval !== 'undefined'
            ? drmOption.BandwidthCheckInterval
            : '';

        if (DRMServerURL && typeof drmOption.Player !== 'undefined') {
          if (drmOption.Player.type === 'ivi') {
            const url = parseURI(DRMServerURL, true);

            if (drmOption.Player.content_id) {
              url.query.content_id = drmOption.Player.content_id;
            }
            url.query.asset = drmOption.AssetID;
            if (drmOption.Player.app_version) {
              url.query.app_version = `${drmOption.Player.app_version}`;
            }
            if (drmOption.Player.session) {
              url.query.session = drmOption.Player.session;
            }

            DRMServerURL = formatURL(url);
          }
        }

        console.log('[Device_Webos_Player] DRMServerURL', DRMServerURL); // eslint-disable-line

        // Message for Widevine
        msg = '<?xml version="1.0" encoding="utf-8"?>';
        msg +=
          '<WidevineCredentialsInfo xmlns="http://www.smarttv-alliance.org/DRM/widevine/2012/protocols/">';
        msg += '<ContentURL>' + ContentURL + '</ContentURL>';
        msg += '<DeviceID>' + DeviceID + '</DeviceID>';
        msg += '<StreamID>' + StreamID + '</StreamID>';
        msg += '<ClientIP>' + ClientIP + '</ClientIP>';
        msg += '<DRMServerURL>' + DRMServerURL + '</DRMServerURL>';
        msg += '<DRMAckServerURL>' + DRMAckServerURL + '</DRMAckServerURL>';
        msg += '<DRMHeartBeatURL>' + DRMHeartBeatURL + '</DRMHeartBeatURL>';
        msg += '<DRMHeartBeatPeriod>' + DRMHeartBeatPeriod + '</DRMHeartBeatPeriod>';
        msg += '<UserData>' + UserData + '</UserData>';
        msg += '<Portal>' + Portal + '</Portal>';
        msg += '<StoreFront>' + StoreFront + '</StoreFront>';
        msg += '<BandwidthCheckURL>' + BandwidthCheckURL + '</BandwidthCheckURL>';
        msg += '<BandwidthCheckInterval>' + BandwidthCheckInterval + '</BandwidthCheckInterval>';
        msg += '</WidevineCredentialsInfo>';
      }

      const parameters = {
        msg,
        msgType,
        drmSystemId,
        clientId: this.clientId,
      };
      console.log('sendDrmMessage parameters:', parameters); // eslint-disable-line
      webOS.service.request('luna://com.webos.service.drm', {
        method: 'sendDrmMessage',
        parameters,
        onSuccess: (result) => {
          const { msgId, resultCode } = result;
          console.log('sendDrmMessage result:', result); // eslint-disable-line
          if (drmType === 'PLAYREADY') {
            // console.log("sendDrmMessage Success: Message ID: " + msgId);
            // const msgId = result.msgId;                  // only for PlayReady
            // const resultCode = result.resultCode || '';  // only for PlayReady

            if (resultCode === 0) {
              resolve(result);
            } else {
              this.subscribeLicensingError(clientId, msgId);
              // Do Handling DRM message error
              throw new Error(`Player.sendDrmMessage onSuccess: [${resultCode}] DRM message error`);
            }
          } else if (drmType === 'WIDEVINE') {
            resolve(result);
          }
        },
        onFailure: (result) => {
          if (drmType === 'PLAYREADY') {
            // TODO: Разобраться с непонятной переменной msgId
            this.subscribeLicensingError(clientId, '');
            // this.subscribeLicensingError(clientId, msgId);
          }
          // Do something for error handling
          throw new Error(`Player.sendDrmMessage onFailure: [${result.errorCode}] result.errorText`);
        },
      });
    });
  };
  sendDrm = (clientId) => {
    this.sendDrmMessage(clientId)
      .then(() => {
        // var drmType = this.drmConfig.type;
        // var options = {};
        // if(drmType === 'PLAYREADY') {
        //   options.option = {};
        //   options.option.drm = {};
        //   options.option.drm.type = 'playready';
        //   options.option.drm.clientId = this.clientId;
        // }
        // if(drmType === 'WIDEVINE') {
        //   options.mediaTransportType = 'WIDEVINE';
        //   options.option = {};
        //   options.option.drm = {};
        //   options.option.drm.type = 'widevine';
        //   options.option.drm.clientId = this.clientId;
        // }
        // this.play(this.url, escape(JSON.stringify(options)));
        this.play?.(this.clientId);
      })
      .catch((err) => {
        this.onError?.(-1, 'sendDrmMessage', err);
      });
  };
  setPlay = (handler) => {
    this.play = handler;
  };
  setOnError = (handler) => {
    this.onError = handler;
  };
  setDRMConfig = (
    type: string,
    LicenseServer: string,
    StreamID: string,
    ClientIP: string,
    ContentURL: string,
    DeviceID?: string,
    Player?: Player,
    AssetID?: string,
  ) => {
    this.drmConfig = {
      DeviceID,
      StreamID,
      ClientIP,
      ContentURL,
      LicenseServer,
      type: type.toUpperCase(),
      Player,
      AssetID,
    };

    console.log('this.drmConfig:', this.drmConfig); // eslint-disable-line
  };
}
