/* eslint-disable max-len */
import React from 'react';

interface Props {
  className?: string;
}

export const DeleteIcon: React.FC<Props> = React.memo(({className}) => {
  return (
    <svg 
      viewBox="0 0 30 30" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={ className }
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M13.212 3.87868C13.7746 3.31607 14.5377 3 15.3333 3C16.129 3 16.892 3.31607 17.4546 3.87868C17.774 4.19808 18.014 4.5821 18.1617 5H23C23.5523 5 24 5.44772 24 6C24 6.55228 23.5523 7 23 7H17.3333L13.3333 7L7 7C6.44772 7 6 6.55228 6 6C6 5.44772 6.44772 5 7 5H12.5049C12.6526 4.5821 12.8926 4.19808 13.212 3.87868ZM12.0508 9H8C7.44772 9 7 9.44772 7 10V24C7 25.6569 8.34315 27 10 27H20C21.6569 27 23 25.6569 23 24V10C23 9.44772 22.5523 9 22 9H18L18 9.00392V22.2353C18 22.7876 17.5523 23.2353 17 23.2353C16.4477 23.2353 16 22.7876 16 22.2353V9.00392L16 9H14.0508L14.0508 9.00392V22.2353C14.0508 22.7876 13.6031 23.2353 13.0508 23.2353C12.4985 23.2353 12.0508 22.7876 12.0508 22.2353V9.00392L12.0508 9Z" 
        fill="currentColor"
      />
    </svg>
  );
});

DeleteIcon.displayName = 'DeleteIcon';
