import * as cn from 'classnames';
import { find } from 'lodash';
import * as React from 'react';

import { fillImageTemplateBySizes } from '~lib/image';
import Image from '~typings/Image';
import ImageType from '~typings/ImageType';

import * as styles from './styles.module.css';


const MAX_HEIGHT = 63;

type Props = {
  images?: Image[];
  image?: Image;
  className?: string;
};

const Label: React.FC<Props> = (props: Props) => {
  const image = props.image || find(props.images, ['type', ImageType.LOGO]);

  if (!image) { return null; }

  const {
    original_width: originalWidth,
    original_height: originalHeight,
    url_template: urlTemplate,
  } = image;
  const width = Math.floor(originalWidth * (MAX_HEIGHT / originalHeight));
  const imgSrc = fillImageTemplateBySizes(urlTemplate, width, MAX_HEIGHT, true);
  const properties = {
    className: cn(styles.label, props.className),
    style: {
      backgroundImage: `url(${imgSrc})`,
    },
  };

  return (
    React.createElement(
      'div',
      properties,
    )
  );
};

export default Label;
