import React from "react";

import { SpinnerJS } from "~newapp/globalBlocks/spinner/js/spinnerJS";

import * as css from './styles.module.css'


const SpinnerSearchCSS = () => {
  return <SpinnerJS className={ css.searchSpinner } />
}

export { SpinnerSearchCSS }
