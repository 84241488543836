import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { useApp } from '~components/Provider/App';
import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import CheckBoxList from '~components/ScenePlayer/Player/controls/Popup/CheckBoxList';
import languageTranslate from '~lib/languageTranslations';
import { IPlayer } from '~lib/player/typings';
import { PLAYER_POPUP_AUDIO_LANGUAGE } from '~localization';


type Props = Readonly<{
  player: IPlayer;
  onClose: () => void;
  intl: InjectedIntl;
}>;


const AudioTracksPopup: React.FC<Props> = (props: Props) => {
  const { language } = useApp();
  const { audioTracks } = usePlayerState();
  const { setAudioTracks, setAudioLanguage } = usePlayerStateActions();

  const handleChangeAudioTrack = (audioTrackIndex) => {
    if (audioTracks[audioTrackIndex]) {
      // Changing the audio track in the player
      props.player.changeAudioTrack(audioTracks[audioTrackIndex]);

      // Assigning a new value to the local store
      setAudioLanguage(audioTracks[audioTrackIndex].name);

      // Change the selected audio track in the list
      setAudioTracks(audioTracks.map((audioTrack, index) => ({
        ...audioTrack,
        isSelected: (audioTrackIndex == index),
      })));
    }

    props.onClose();
  };

  const items = audioTracks.map(({ index, name, isSelected, data }) => {

    let newName

    if (data && data.role && data.role.length > 0) {
      newName = languageTranslate(name, language) + ` (${ data.role })`
    }
    else {
      newName = languageTranslate(name, language)
    }

    return ({
      index,
      name: newName,
      isChecked: !!isSelected,
    });
  });

  return (
    <CheckBoxList
      header={ props.intl.formatMessage({ id: PLAYER_POPUP_AUDIO_LANGUAGE }) }
      items={ items }
      onClick={ handleChangeAudioTrack }
    />
  );
};


export default injectIntl(AudioTracksPopup);
