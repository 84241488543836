// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sliderScrollableWrapper--TPj16 {\n  position: relative;\n}\n\n.sliderScrollableWrapper--TPj16.maybeAnimated--olpzy {\n  will-change: transform;\n}\n\n.sliderDotsWrapper--yAgef {\n  position: absolute;\n  top: 9.795vw;\n  right: 0;\n  left: 0;\n  text-align: center;\n}\n\n", "",{"version":3,"sources":["webpack://./src/app/components/Slider/scrollable/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,QAAQ;EACR,OAAO;EACP,kBAAkB;AACpB","sourcesContent":[".sliderScrollableWrapper {\n  position: relative;\n}\n\n.sliderScrollableWrapper.maybeAnimated {\n  will-change: transform;\n}\n\n.sliderDotsWrapper {\n  position: absolute;\n  top: 9.795vw;\n  right: 0;\n  left: 0;\n  text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
export var sliderScrollableWrapper = "sliderScrollableWrapper--TPj16";
export var maybeAnimated = "maybeAnimated--olpzy";
export var sliderDotsWrapper = "sliderDotsWrapper--yAgef";
export default ___CSS_LOADER_EXPORT___;
