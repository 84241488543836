import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { usePlayerState } from "~components/Provider/Player";
import usePlayerStateActions from "~components/Provider/Player/actions";
import { Events } from '~lib/player/common';
import { IPlayer } from '~lib/player/typings';
import { getEpisodeIndexByID, getSeasonByEpisodeID } from '~lib/Series';
import { NEXT_EPISODE } from '~localization';
import Episode from '~typings/Episode';
import ItemObject from '~typings/ItemObject';
import Button from '~ui/button';

import { setPlayingEpisode } from './helpers';
import * as styles from './styles.module.css';


type Props = Readonly<{
  player: IPlayer;
  isFocused: boolean;
  focusedIndex: number | undefined;
  currentEpisode: Episode;
  durationInSec: number;
  currentTimeInSec: number;
  onChangeIsVisibleGoToAir: (isVisible: boolean) => void;
  intl: InjectedIntl;
}>;


const NextEpisode: React.FC<Props> = (props: Props) => {
  const { setItem: playerSetItem } = usePlayerStateActions();
  const playerState = usePlayerState();
  const diff = Math.ceil(props.durationInSec - props.currentTimeInSec);
  const history = useHistory();
  const matchPage = useRouteMatch('/:page/:id');
  const { series: { seasons } = {} } = props.currentEpisode;
  const currentSeason = getSeasonByEpisodeID(seasons, props.currentEpisode.id);
  const currentEpisodeIndex = getEpisodeIndexByID(currentSeason?.episodes, props.currentEpisode.id);
  const nextEpisode = currentSeason?.episodes?.[currentEpisodeIndex + 1];
  if (nextEpisode && playerState.item?.object === ItemObject.Episode && playerState.item.series) {
    nextEpisode.series = playerState.item.series;
  }

  React.useEffect(() => {
    props.onChangeIsVisibleGoToAir(!!nextEpisode && diff <= 10);
  }, [diff, nextEpisode]);

  const handleClickNext = React.useCallback(() => {
    if (nextEpisode) {
      if (!setPlayingEpisode(nextEpisode, matchPage, history, playerSetItem)) {
        handleClickBack();
      }
    }
  }, [matchPage, nextEpisode]);

  const handleClickBack = () => {
    history.goBack();
  };

  const handlePlayerEnded = React.useCallback(() => {
    if (nextEpisode) {
      handleClickNext();
    } else {
      handleClickBack();
    }
  }, [nextEpisode]);

  React.useEffect(() => {
    props.player.on(Events.ENDED, handlePlayerEnded);

    return () => {
      props.player.removeListener(Events.ENDED, handlePlayerEnded);
    };
  }, [props.player, handlePlayerEnded, nextEpisode]);

  if (props.durationInSec === 0) { return null; }

  if (
    !nextEpisode
    || props.durationInSec - props.currentTimeInSec > 10
  ) { return null; }

  return (
    <div className={ styles.nextEpisodeWrapper }>
      <Button
        isFocused={ props.isFocused && props.focusedIndex === 0 }
        className={ styles.nextEpisode }
        onClick={ handleClickNext }
      >
        { props.intl.formatMessage({ id: NEXT_EPISODE }, {
          countDown: diff,
        }) }
      </Button>
    </div>
  );
};


export default React.memo(injectIntl(NextEpisode));
