import focuser, { FocuserKeyHandler, useFocuserContext, useFocuserThrottledHandler } from '@focuser';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import usePaymentHistory from '~hooks/fetch/usePayments/usePaymentHistory';
import { usePaginationOneLine } from '~hooks/usePagination';
import { PAYMENT_HISTORY_EMPTY } from '~localization';
import { SpinnerFullScreenIsolated } from '~newapp/globalBlocks/spinner';
import { SliderVerticableVirtual } from '~ui/SliderVerticable';

import { PaymentItem } from './PaymentItem/PaymentItem';
import * as styles from './styles.modules.css';


export const Payments: React.FC = () => {
  const { query: paymentsQuery, parsedData: payments, isLoading } = usePaymentHistory();
  const { isFocused } = useFocuserContext();

  const [focusedItem, setFocusedItem] = React.useState(0);

  usePaginationOneLine({
    currentFetchedCount: payments.length,
    focusedElemIdx: focusedItem,
    minimalPrefetchedCount: 10,
    updates: [paymentsQuery],
    fetchMoreData: () => {
      if (!paymentsQuery.isFetchingNextPage && paymentsQuery.hasNextPage) {
        paymentsQuery.fetchNextPage();
      }
    },
  });

  const handleUp: FocuserKeyHandler = (evt) => {
    if(focusedItem > 0) {
      setFocusedItem(focusedItem - 1);
      evt.stop();
    }
  }

  const handleDown: FocuserKeyHandler = (evt) => {
    if(focusedItem !== (payments.length - 1)) {
      setFocusedItem(focusedItem + 1);
      evt.stop();
    }
  }

  const onKeyUpThrottled = useFocuserThrottledHandler(handleUp);
  const onKeyDownThrottled = useFocuserThrottledHandler(handleDown);

  const renderPayment = React.useCallback((i: number) => {
    const payment = payments[i];

    return (
      <PaymentItem
        key={ payment.updated_at }
        payment={ payment }
        isFocused={ isFocused && i === focusedItem }
        index={ i }
        setFocusedPurchase={ setFocusedItem }
      />
    );
  }, [focusedItem, payments, isFocused]);

  if(isLoading) {
    return (
      <SpinnerFullScreenIsolated/>
    )
  }

  if(!payments.length) {
    return (
      <Typography variant="h6_medium">
        <FormattedMessage id={ PAYMENT_HISTORY_EMPTY } />
      </Typography>
    );
  }


  return (
    <focuser.FocusableBlock
      isFocused
      onKeyDown={ onKeyDownThrottled }
      onKeyUp={ onKeyUpThrottled }
      noNeedForceFocus
    >
      <SliderVerticableVirtual
        wrapperClassName={ styles.paymentSlider }
        focusedIndex={ focusedItem }
        childsCount={ payments.length }
        renderChild={ renderPayment }
        itemHeight={ 10.972 }
        maxInDOM={ 16 }
      />
    </focuser.FocusableBlock>
  );
};

