/* eslint-disable max-len */
import * as React from 'react';


const IcoLowerCase: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 58 32"
    style={ { marginTop: '0.65vh' } }
  >
    <path d="M0 0h58v32H0V0z" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="pathHighlighted"
      d="M21.3 25h15.5c.7 0 1.3.6 1.3 1.3v.5c0 .7-.6 1.3-1.3 1.3H21.3c-.7 0-1.3-.6-1.3-1.3v-.5c0-.7.6-1.3 1.3-1.3z"
    />
    <path
      className="pathHighlighted"
      d="M37.6 14h-4.5c-.6 0-1.1.5-1.1 1.1V21h-6v-5.9c0-.6-.5-1.1-1.1-1.1h-4.5L29 6.6l8.6 7.4zm-20.9.5c-.3.3-.4.8-.1 1.2.2.2.4.3.6.3H24v5.7c0 .7.6 1.3 1.3 1.3h7.4c.7 0 1.3-.6 1.3-1.3V16h6.8c.5 0 .8-.4.8-.8 0-.2-.1-.5-.3-.6L29 4 16.7 14.5z"
    />
  </svg>
);


export default IcoLowerCase;
