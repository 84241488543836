import Protocol from '~typings/Protocol';


const getProtocolByStreamURL = (streamURL): Protocol | null => {
  if (streamURL.includes('.mp4')) {return Protocol.MP4; }
  if (streamURL.includes('.wvm')) {return Protocol.WVM; }
  if (streamURL.includes('.mpd')) {return Protocol.DASH; }
  if (streamURL.includes('.m3u8')) {return Protocol.HLS; }
  if (streamURL.includes('.isml') || streamURL.includes('.ism')) {return Protocol.MSS; }

  return null;
};


export default getProtocolByStreamURL;
