export const RET_PATH_SEARCH_FIELD = 'RET_PATH_SEARCH_FIELD';

export type RetPathParams = {
  retPath: string;
  searchParamsToProxy?: string;
}

export type DeviceLinkingCallbacks = {
  onSuccessLinking: RetPathParams;
  afterContinueOnLinkingLimitPage: RetPathParams
};
