import * as cn from 'classnames';
import * as React from 'react';

import useHandleItemClick from '~components/SideBarLayout/useHandleItemClick';
import MenuItem from '~typings/MenuItem';

import * as styles from './styles.module.css';


type Props = Readonly<{
  index: number;
  item: MenuItem;
  isActive: boolean;
  isFocused: boolean;
}>;


const SideBarItem: React.FC<Props> = (props: Props) => {
  const [handleItemClick] = useHandleItemClick();

  const handleClick = () => {
    if (props.item.link) {
      handleItemClick({
        link: props.item.link,
        index: props.index,
        isFocusOnSideBar: true,
      });
    }
  };

  return (
    <li
      className={ cn(styles.menuItem, {
        [styles.active]: props.isActive,
        [styles.focused]: props.isFocused,
      }) }
      onClick={ handleClick }
    >
      <div
        className={ cn(styles.text, {
          'focusedNavigationNode': props.isActive,
        }) }
      >
        { props.item.title }
      </div>
    </li>
  );
};


export default SideBarItem;
