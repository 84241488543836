import { ac_00, gw_00, gw_11 } from "customization-project-name/colors";
import React from "react";

interface Props {
  isFocused: boolean;
}

/* eslint-disable max-len */
export function CatchUpIcon(props: Props) {
  // TODO: прикрутить цвета кастомок

  if (props.isFocused) {
    return (
      <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30.3906" cy="30.833" r="30" fill={ ac_00 } />
        <path
          d="M44.7922 30.8331C44.7922 38.7771 38.3362 45.2331 30.3922 45.2331C29.0482 45.2331 27.7042 45.0411 26.4322 44.6811C25.7842 44.5011 25.4242 43.8291 25.6042 43.1931C25.7842 42.5571 26.4562 42.1851 27.0922 42.3771C28.1602 42.6771 29.2642 42.8331 30.3922 42.8331C37.0042 42.8331 42.3922 37.4451 42.3922 30.8331C42.3922 24.2211 37.0042 18.8331 30.3922 18.8331C23.7802 18.8331 18.3922 24.2211 18.3922 30.8331C18.3922 33.7611 19.4722 36.5811 21.3922 38.7531V35.7411C21.3922 35.0811 21.9322 34.5411 22.5922 34.5411C23.2522 34.5411 23.7922 35.0811 23.7922 35.7411V41.1411C23.7922 41.8011 23.2522 42.3411 22.5922 42.3411H17.1922C16.5322 42.3411 15.9922 41.8011 15.9922 41.1411C15.9922 40.4811 16.5322 39.9411 17.1922 39.9411H19.2442C17.1562 37.3851 15.9922 34.1691 15.9922 30.8331C15.9922 22.8891 22.4482 16.4331 30.3922 16.4331C38.3362 16.4331 44.7922 22.8891 44.7922 30.8331ZM36.3562 30.8331C36.3562 30.4371 36.1642 30.1011 35.8402 29.8971L28.4602 25.3251C28.2802 25.2171 28.0762 25.1571 27.8842 25.1571C27.2722 25.1571 26.7682 25.6491 26.7682 26.2611V35.3811C26.7682 35.9931 27.2722 36.4971 27.8842 36.4971C28.0762 36.4971 28.2802 36.4491 28.4602 36.3291L35.8402 31.7571C36.1642 31.5651 36.3562 31.2051 36.3562 30.8211V30.8331Z"
          fill={ gw_00 }/>
      </svg>
    )
  }

  return (
    <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.4016 23.0001C41.4016 33.1508 33.1522 41.4001 23.0016 41.4001C21.2842 41.4001 19.5669 41.1548 17.9416 40.6948C17.1136 40.4648 16.6536 39.6061 16.8836 38.7934C17.1136 37.9808 17.9722 37.5054 18.7849 37.7508C20.1496 38.1341 21.5602 38.3334 23.0016 38.3334C31.4502 38.3334 38.3349 31.4488 38.3349 23.0001C38.3349 14.5514 31.4502 7.66676 23.0016 7.66676C14.5529 7.66676 7.66823 14.5514 7.66823 23.0001C7.66823 26.7414 9.04823 30.3448 11.5016 33.1201V29.2714C11.5016 28.4281 12.1916 27.7381 13.0349 27.7381C13.8782 27.7381 14.5682 28.4281 14.5682 29.2714V36.1714C14.5682 37.0148 13.8782 37.7048 13.0349 37.7048H6.1349C5.29156 37.7048 4.60156 37.0148 4.60156 36.1714C4.60156 35.3281 5.29156 34.6381 6.1349 34.6381H8.7569C6.0889 31.3721 4.60156 27.2628 4.60156 23.0001C4.60156 12.8494 12.8509 4.6001 23.0016 4.6001C33.1522 4.6001 41.4016 12.8494 41.4016 23.0001ZM30.6222 23.0001C30.6222 22.4941 30.3769 22.0648 29.9629 21.8041L20.5329 15.9621C20.3029 15.8241 20.0422 15.7474 19.7969 15.7474C19.0149 15.7474 18.3709 16.3761 18.3709 17.1581V28.8114C18.3709 29.5934 19.0149 30.2374 19.7969 30.2374C20.0422 30.2374 20.3029 30.1761 20.5329 30.0228L29.9629 24.1808C30.3769 23.9354 30.6222 23.4754 30.6222 22.9848V23.0001Z"
        fill={ gw_11 }/>
    </svg>
  )

}
