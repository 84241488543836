// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sliderWrapper--tp9kP {\r\n  margin-bottom: 4.6875vw;\r\n  padding-left: 0.2vw;\r\n  width: 86.15vw;\r\n}\r\n\r\n.sliderTitle--UXpcZ {\r\n  margin-left: 0.9375vw;\r\n  margin-bottom: 1.5625vw;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/app/components/ActorPage/ActorPageSliders/ProgramEventSlider/styles.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":[".sliderWrapper {\r\n  margin-bottom: 4.6875vw;\r\n  padding-left: 0.2vw;\r\n  width: 86.15vw;\r\n}\r\n\r\n.sliderTitle {\r\n  margin-left: 0.9375vw;\r\n  margin-bottom: 1.5625vw;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var sliderWrapper = "sliderWrapper--tp9kP";
export var sliderTitle = "sliderTitle--UXpcZ";
export default ___CSS_LOADER_EXPORT___;
