import type { History } from 'history';
import { stringify } from 'query-string';
import React from 'react';
import { useHistory } from 'react-router-dom';

import useLocationSearch from '~hooks/useLocationSearch';

import { DeviceLinkingCallbacks, RET_PATH_SEARCH_FIELD, RetPathParams } from './DeviceLinkingTypes';

export const useRetPathFromLinking = () => {
  const history = useHistory();
  const search = useLocationSearch();
  const parsedReturnParams: DeviceLinkingCallbacks | undefined = React.useMemo(() => {
    const encodedField = search[RET_PATH_SEARCH_FIELD];

    if (!encodedField) {
      return;
    }

    try {
      return JSON.parse(encodedField as string);
    } catch {
      return;
    }
  }, [search]);


  const gotoRetPath = (retPathParams: RetPathParams | undefined) => {
    if (!retPathParams) {
      history.replace('/');
      return;
    }

    const { retPath, searchParamsToProxy } = retPathParams;

    if (!searchParamsToProxy) {
      history.replace(retPath);
      return;
    }

    history.replace({
      pathname: retPath,
      search: searchParamsToProxy,
    });
  }



  const goToAfterSuccesLinking = () => {
    gotoRetPath(parsedReturnParams?.onSuccessLinking)
  }

  const goToAfterContinueOnLinkingLimitPage = () => {
    gotoRetPath(parsedReturnParams?.afterContinueOnLinkingLimitPage)
  }



  return { goToAfterSuccesLinking, goToAfterContinueOnLinkingLimitPage };
};



export const openDeviceLinkingPage = (history: History, callbacks: DeviceLinkingCallbacks) => {
  history.replace({
    pathname: `/auth/device-linking`,
    search: stringify({
      [RET_PATH_SEARCH_FIELD]: JSON.stringify(callbacks),
    }),
  });
};
