import create, { GetState, SetState } from 'zustand';

export const FOCUS_ON = {
  LEFT_MENU: 'LEFT_MENU',
  CONTENT: 'CONTENT',
  RIGHT_MENU: 'RIGHT_MENU',
} as const;

export type FocusOn = keyof typeof FOCUS_ON;

export interface State {
  navigationOnLayout: string | null;
  layouts: { [key: string]: FocusOn };
  editMode: boolean;
  getNavigationOnLayout: () => State['navigationOnLayout'];
  setNavigationOnLayout: (layoutID: string | null) => void;
  getFocusOn: () => FocusOn;
  setFocusOn: (focusOn: FocusOn) => void;
  getEditMode: () => boolean;
  setEditMode: (editMode: boolean) => void;
}

const useSideBarNavigation = create<State>((set: SetState<State>, get: GetState<State>) => ({
    navigationOnLayout: null,
    layouts: {},
    editMode: false,

    getNavigationOnLayout: () => get().navigationOnLayout,
    setNavigationOnLayout: (layoutID: string | null) => set((draft: State) => {
      draft.navigationOnLayout = layoutID;

      if (layoutID) {
        // draft.layouts[layoutID] = FOCUS_ON.LEFT_MENU;
        draft.editMode = false;
      }
    }),
    getFocusOn: () => {
      const navigationOnLayout = get().navigationOnLayout;

      return navigationOnLayout ?
        get().layouts[navigationOnLayout] || FOCUS_ON.LEFT_MENU
        : FOCUS_ON.LEFT_MENU;
    },
    setFocusOn: (focusOn: FocusOn) => set((draft: State) => {
      const navigationOnLayout = get().navigationOnLayout;

      if (navigationOnLayout) {
        draft.layouts[navigationOnLayout] = focusOn;
      }
    }),
    getEditMode: () => get().editMode,
    setEditMode: (editMode: boolean) => set((draft: State) => {
      draft.editMode = editMode;
    }),
  })
);


export default useSideBarNavigation;
