import * as React from 'react';

import { persistContext, reHydrate, Storage } from '~lib/reHydrate';

import {
  INITIAL_STATE,
  PlayerContext,
  PlayerDispatchContext,
  reducer,
  State,
} from './component.helpers';

export const PREFERRED_AUDIO_LANGUAGE_KEY = 'audioLanguage';
const PLAYER_STORAGE_KEY = 'player';
const PERSISTENT_SS_PROPS = ['logger'];

const PlayerProvider = ({ children }: {
  children?: React.ReactNode;
}): JSX.Element => {
  const reHydratedProps = {
    ...reHydrate(Storage.LocalStorage, PLAYER_STORAGE_KEY, [PREFERRED_AUDIO_LANGUAGE_KEY]),
    ...reHydrate(Storage.SessionStorage, PLAYER_STORAGE_KEY, PERSISTENT_SS_PROPS)
  };

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      ...INITIAL_STATE,
      ...reHydratedProps,
    },
  );

  React.useEffect(() => {
    persistContext<State>(Storage.LocalStorage, PLAYER_STORAGE_KEY, [PREFERRED_AUDIO_LANGUAGE_KEY], state);
    persistContext<State>(Storage.SessionStorage, PLAYER_STORAGE_KEY, PERSISTENT_SS_PROPS, state);
  }, [state]);

  return (
    <PlayerContext.Provider
      value={ state }
    >
      <PlayerDispatchContext.Provider
        value={ dispatch }
      >
        { children }
      </PlayerDispatchContext.Provider>
    </PlayerContext.Provider>
  );
};


PlayerProvider.displayName = 'PlayerProvider';


export default React.memo(PlayerProvider);
