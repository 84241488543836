import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import usePlayerStateActions from '~components/Provider/Player/actions';
import { useAutoInfinityBlackouts } from '~hooks/fetch/useBlackouts/useBlackouts';
import useChannelBySlug from '~hooks/fetch/useChannel';
import { useTodayEventsForProgram } from '~hooks/fetch/useProgramEvents/useProgramEvents';
import { getEventStatus } from '~lib/datetime-utils';
import { isCatchupAvailable } from '~lib/eventsHelper';
import { SEARCH_NOT_FOUND } from '~localization';
import Event from '~typings/Event';
import { EventStatus } from '~typings/EventStatus';
import NavigationDirection from '~typings/NavigationDirection';

import ProgramEventStatusBlock from '../ProgramEventStatusBlock';
import { filterEvents } from './helpers';
import * as styles from './styles.module.css';


const MAX_LINES = 3;

type Props = Readonly<{
  onLeave: (direction: NavigationDirection) => void;
  event: Event;
  showCatchups: boolean;
  isFocused: boolean;
  focusedIndex: number;
  onCalculatedEventsCount: (count: number) => void;
  intl: InjectedIntl;
}>;

const EventProgramList: React.FC<Props> = (props: Props): React.ReactElement | null => {
  const { data: { data: programEvents } = {} } = useTodayEventsForProgram(
    props.event.channel_id,
    props.event.program_id
  );
  const { data: { data: channel } = {} } = useChannelBySlug(props.event.channel_id);
  const { data } = useAutoInfinityBlackouts(props.event.channel_id);
  const blackouts = data?.data;

  //const { data: { data: blackouts } = {} } = useBlackouts(props.event.channel_id);
  const { setPlayList } = usePlayerStateActions()

  const filteredEvents = filterEvents({
    withoutEventID: props.event.id,
    maxCount: MAX_LINES,
    onlyCatchups: props.showCatchups,
    events: programEvents,
    channel,
    blackouts
  });

  /**
   * Добавляем "eventListItem" к id, для того чтобы отличить запуск архивных
   * из ТВ программы. Можно улучшить.
   */
  const setArchivedEvents = React.useCallback(() => {
    if (filteredEvents.length > 0) {
      const filteredEventsIds: string[] = filteredEvents.map(event => `${event.id} eventListItem`)
      setPlayList(filteredEventsIds);
    }
  }, [filteredEvents])

  let eventsCount = 0;
  if (channel) {
    for (let i = 0; i < filteredEvents.length; i += 1) {
      const event = filteredEvents[i];
      const eventStatus = getEventStatus(event);
      const isLive = eventStatus === EventStatus.Now;
      const isPast = eventStatus === EventStatus.InThePast;
      const isCatchup = event && (
        isPast
        && channel.catchup_availability.available
        && isCatchupAvailable(event, channel, blackouts)
      );

      if (isLive || isCatchup) {
        eventsCount += 1;
      }
    }
  }

  React.useEffect(() => {
    props.onCalculatedEventsCount(eventsCount);
  }, [eventsCount]);

  if (!programEvents || !channel || !blackouts) return null;

  if (filteredEvents.length === 0) {
    return (
      <>{ props.intl.formatMessage({ id: SEARCH_NOT_FOUND }) }</>
    );
  }

  return (
    <>
      {
        filteredEvents.map((event, index) => (
          <div className={ styles.eventLine } key={ `ProgamList_Line${index}` }>
            <div className={ styles.eventName }> { event.name } </div>
            {
              filteredEvents.map((event, id) => (
                <div className={ styles.eventLine } key={ `ProgamList_Line${id}` }>
                  <div className={ styles.eventName }> {event.name} </div>
                  <ProgramEventStatusBlock
                    event={ event }
                    channel={ channel }
                    blackouts={ blackouts }
                    isFocused={ props.isFocused && props.focusedIndex === index }
                    setArchivedEvents={ setArchivedEvents }
                  />
                </div>
              ))
            }
          </div>
        ))
      }
    </>
  );
};

export default injectIntl(EventProgramList);
