import { useNetwork } from '~global';
import { useAccount } from '~hooks/fetch/useAccount';


export enum UserType {
  OTT = 'OTTUser',
  IPTV = 'IPTVUser',
  IPTVOTT = 'IPTVandOTTUser',
}


const useUserType = (): UserType | null => {
  const network = useNetwork();
  const { data: accountInfo } = useAccount();

  if (!accountInfo) { return null; }

  if (!network.iptv_supported) {
    return UserType.OTT;
  }

  if (accountInfo?.login !== null) {
    if (accountInfo?.username) {
      return UserType.IPTVOTT;
    }

    return UserType.IPTV;
  }

  return UserType.OTT;
};


export default useUserType;
