import focuser from "@focuser";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";

import { fillImageTemplateBySizes } from "~lib/image";
import { valueInPixelsByWidth } from "~lib/SizesInPX";
import Channel from "~typings/Channel";

import { sortByPriority } from "../utils/utils";
import { Card } from "./Card/Card";
import { CardInfo } from "./CardInfo/CardInfo";
import * as css from "./ChannelBlock.module.css";


type Props = {
  channel: Channel
  isFocused: boolean
  setFocused: Dispatch<SetStateAction<number>>
  index: number
}

const ChannelBlock$ = ({ channel, isFocused, setFocused, index}: Props) => {
  const history = useHistory()
  const priorityArr = ["card_16_9", "channel_card_wide", "channel_card", "card_4_3", "logo", "card_icon"]
  const imageArr = sortByPriority(channel.images, priorityArr)
  const imageUrlTemplate = imageArr[0]

  const width = valueInPixelsByWidth(17.1875)
  const height = valueInPixelsByWidth(9.765625)
  const imageUrl = fillImageTemplateBySizes(imageUrlTemplate.url_template, width, height)

  const handleCLick = () => {
      history.replace(`/media-item/${channel.object}/${channel.id}?player=true`);
  }

  return (
    <focuser.FocusableBlock
      isFocused={ isFocused }
      onForceFocus={ () => setFocused(index) }
      onClick={ handleCLick }
      emitForceFocusOnHover
    >
    <div className={ css.slide }>
      <Card isFocused={ isFocused } imageUrl={ imageUrl } />
      <CardInfo channel={ channel } />
    </div>
    </focuser.FocusableBlock>
  )
}

export const ChannelBlock = React.memo(ChannelBlock$);
