import { useMemo } from 'react';
import { useQueries, useQuery, UseQueryOptions } from 'react-query';

import { useClient, useConfig } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getAuthData } from '~hooks/fetch/useAccount';
import ResponseMany from '~typings/ResponseMany';
import Subscription from '~typings/Subscription';

import { getLimitFromConfig } from '../utils';

const PREFIX_OF_A_COMPOSITE_KEY = 'subscriptions';

const subscriptionsFetchFn = async (client, accessToken, limit: number, offset = 0) => {
  return await client.get(
    fetchURLs[FetchType.Subscription],
    {
      ...fetchOptionsBasic[FetchType.Subscription],
      access_token: accessToken,
      'page[offset]': offset,
      'page[limit]': limit,
    }
  );
}

const useSubscriptionsFirstPage = () => {
  /**
   * хук делает запрос с max_per_page на первую страницу  чтобы получить total
   * работает только в связке с useAllSubscriptions
   */
  const { accessToken } = getAuthData();
  const client = useClient();
  const limit = getLimitFromConfig(useConfig());

  return useQuery<ResponseMany<Subscription[]>>({
    queryKey: [PREFIX_OF_A_COMPOSITE_KEY, accessToken, 0],
    queryFn: () => subscriptionsFetchFn(client, accessToken, limit),
    enabled: !!accessToken,
    staleTime: 0,
    cacheTime: 0,
    notifyOnChangeProps: 'tracked',
  });
}

const useSubscriptionsRestPages = (data: ResponseMany<Subscription[]> | undefined) => {
  /**
   * хук делает запросы на все остальные страницы учитывая запрос на первую страницу
   * работает только в связке с useAllSubscriptions
   */
  const { accessToken } = getAuthData();
  const client = useClient();
  const limit = getLimitFromConfig(useConfig());

  const queries: UseQueryOptions[] = []
  if (data) {
    const total = data.meta.pagination.total
    const iterations = Math.ceil(total / limit)
    for (let i = 1; i < iterations; i++) {
      const offset = i * limit
      const queryKey = [PREFIX_OF_A_COMPOSITE_KEY, accessToken, i]
      queries.push({
        queryKey: queryKey,
        queryFn: () => subscriptionsFetchFn(client, accessToken, limit, offset),
        notifyOnChangeProps: 'tracked',
      })
    }
  }

  return {
    subscriptions: useQueries(queries),
  } as const;
}

const useAllSubscriptions = () => {
  /**
   * хук запрашивает все итемы с v1/subscriptions разбивая один запрос на несколько учитывая max_per_page
   */
  const { data, isLoading: isLoadingFirst, isFetchedAfterMount: isFetchedAfterMountFirst } = useSubscriptionsFirstPage()
  const queryArray = useSubscriptionsRestPages(data).subscriptions
  const isLoadingRest = queryArray.some(query => query.isLoading)
  const isFetchedAfterMountRest = queryArray.some(query => query.isFetchedAfterMount);
  const subscriptions = useMemo(()=>  queryArray.reduce((total, query) => {
      const items = query.data as ResponseMany<Subscription[]>
      if (items && items.data) {
        total = [...total, ...items.data]
      }
      return total
    }, data?.data || []), [queryArray, data?.data]);

  return {
    isLoading: isLoadingFirst || isLoadingRest,
    isFetchedAfterMount: isFetchedAfterMountFirst || isFetchedAfterMountRest,
    subscriptions
  }
}


export {
  PREFIX_OF_A_COMPOSITE_KEY,
  useAllSubscriptions
};
