import * as React from 'react';


type Props = Readonly<{
  focusedIndex: number;
  slideWidth: number;
  children: any;
}>;


export const ScrollableSliderBlock: React.FC<Props> = (props: Props) => {
  const transform = `translate3d(-${props.focusedIndex * props.slideWidth}px, 0, 0)`;

  return (
    <div
      style={ {
        transform,
        WebkitTransform: transform,
      } }
    >
      { props.children }
    </div>
  );
};


export default React.memo(ScrollableSliderBlock);
