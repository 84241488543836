/* eslint-disable max-len */
import * as React from 'react';


const IconPlay: React.FC = () => (
  <svg
    className="playerIconPlay"
    viewBox="0 0 28 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 0C.9 0 0 .9 0 2v28c0 1.1.9 2 2 2 .4 0 .7-.1 1-.3l24-14c.9-.5 1.3-1.6.8-2.6 0 0 0-.1-.1-.1-.2-.3-.4-.5-.7-.7L3 .3C2.7.1 2.4 0 2 0z"
    />
  </svg>
);


export default IconPlay;
