import settings from 'customization-project-name/settings.json';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ActionType, useAppAction } from '~components/Provider/App';
import { NavigationDirection } from '~components/Provider/KeyDownHandler/utils';
import { CONTINUE, NAVIGATION_BACK, PLAYER_ERROR_NOT_AUTH } from '~localization';
import Button from '~ui/button';

import * as styles from './styles.module.css';

enum FocusOn {
  SignInButton = 'SignInButton',
  BackButton = 'BackButton',
}

const AccessErrorDialog: React.FC = () => {
  const history = useHistory();
  const appAction = useAppAction();
  const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.SignInButton);

  const onLeaveHandler = (direction: NavigationDirection) => {
    if (
      direction === NavigationDirection.Right
      ) {
      setFocusOn(FocusOn.BackButton);
    } else if (
      direction === NavigationDirection.Left
    ) {
      setFocusOn(FocusOn.SignInButton);
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const onAuthBtnClick = () => {
    appAction({
      type: ActionType.SetAuthRedirectUrl,
      payload: { authRedirectUrl: history.location.pathname },
    });

    history.replace('/auth');
  };

  return (
    <>
      <div className={ styles.messageText }>
        <FormattedMessage id={ PLAYER_ERROR_NOT_AUTH } values={ { site: settings.baseSiteUrl } } />
      </div>

      <Button
        onClick={ onAuthBtnClick }
        className={ styles.button }
        onLeave={ onLeaveHandler }
        isFocused={ focusOn === FocusOn.SignInButton }
      >
        <FormattedMessage id={ CONTINUE } />
      </Button>
      <Button
        onClick={ handleBack }
        onLeave={ onLeaveHandler }
        isFocused={ focusOn === FocusOn.BackButton }
      >
        <FormattedMessage id={ NAVIGATION_BACK } />
      </Button>
    </>
  );
};


export default AccessErrorDialog;
