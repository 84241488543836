import { MOBILE_PAYMENT } from '~localization'
import PaymentMethod from '~typings/PaymentMethod';

const definePaymentMethodTranslation = (intl, { payment_method_type }) => {
  switch (payment_method_type) {
    case PaymentMethod.Operator:
      return intl.formatMessage({ id: MOBILE_PAYMENT });
    case PaymentMethod.NewCard:
    case PaymentMethod.a1NewCard:
      return 'Карта';
    default:
      return null
  }
};

export {
  definePaymentMethodTranslation
}
