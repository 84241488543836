import Conviva, { ConvivaMetadata } from '@convivainc/conviva-js-coresdk';
import * as moment from 'moment';
import React, { useRef } from 'react';

import { version } from '~lib/env-utils';
import { Events } from '~lib/player/common';
import { getSeasonByEpisodeID } from '~lib/Series';


const useConvivialAnalytics = ({ item, stream, player, activeProfileId }) => {
  const videoAnalytics = React.useMemo(() => {
    return Conviva.Analytics.buildVideoAnalytics()
  }, [])

  // const started = useRef(false);
  const bufferingTime = React.useRef(0);
  const genreList = !item.series
    ? item?.genres?.map((genre) => genre.name).join(', ')
    : item?.series?.genres?.map((genre) => genre.name).join(', ') ?? '';
  const season = getSeasonByEpisodeID(item.series?.seasons, item.id);
  const assetName = !item.series
    ? `[${ item.id }] ${ item?.name }`
    : `[${ item.id }] ${ item.series.name } ${ season?.name } Эпизод ${ item.number }`;
  const categoryType = !item.series ? item?.object : item.series.object;
  const spentTimer = useRef<number>(moment().valueOf());

  const resetSpentTimer = () => {
    spentTimer.current = moment().valueOf();
  };
  const isBuffering = useRef(false);

  let metrics: ConvivaMetadata = {
    [Conviva.Constants
      .FRAMEWORK_NAME]: `smarttv (${ stream?.analytics_v2?.additional_parameters?.application_id })`,
    [Conviva.Constants.FRAMEWORK_VERSION]: version,
    [Conviva.Constants.VIEWER_ID]: activeProfileId,
    [Conviva.Constants.PLAYER_NAME]: `spbtv `,
    [Conviva.Constants.ASSET_NAME]: assetName,
    [Conviva.Constants.STREAM_URL]: stream?.url,
    [Conviva.Constants.IS_LIVE]: item?.object === 'channel' ? 'LIVE' : 'VOD', //
    ['c3.cm.contentType']:
      item?.object === 'channel'
        ? 'Live-Linear'
        : item?.object === 'program_event'
          ? 'Catchup'
          : 'VOD', // Acceptable values: "Live", "Live-Linear", "DVR", "Catchup", "VOD"
    [Conviva.Constants.DURATION]: item?.duration ?? '',
    // [Conviva.Constants.Playback.PLAY_HEAD_TIME]: '1',
    // [Conviva.Constants.Playback.SEEK_STARTED]: '1',
    // [Conviva.Constants.Playback.SEEK_ENDED]: '1',
    // [Conviva.Constants.Playback.DROPPED_FRAMES_COUNT]: '',
    // [Conviva.Constants.Playback.CDN_IP]: '1',
    // [Conviva.Constants.Playback.RENDERED_FRAMERATE]: '1',
    ['c3.cm.categoryType']: categoryType,
    ['c3.cm.genreList']: genreList,
    ['c3.app.version']: version,
  };

  if (item.series) {
    metrics['c3.cm.seasonNumber'] = `${ season?.number }` ?? null
    metrics['c3.cm.episodeNumber'] = item.series ? `${ item.number }` : ''
  }

  const handleBufferingStart = () => {
    // if (!started.current) { return; }
    if (!isBuffering.current) {
      isBuffering.current = true;
      videoAnalytics?.reportPlaybackMetric(
        Conviva.Constants.Playback.PLAYER_STATE,
        Conviva.Constants.PlayerState.BUFFERING,
      );
    }
    resetSpentTimer();
  };

  const handleSeekingTime = () => {
    const offset = player?.getCurrentTime()

    videoAnalytics?.reportPlaybackMetric(
      Conviva.Constants.Playback.SEEK_STARTED,
      offset,
    );

    videoAnalytics?.reportPlaybackMetric(
      Conviva.Constants.Playback.PLAYER_STATE,
      Conviva.Constants.PlayerState.BUFFERING,
    );

    resetSpentTimer();
  }

  const handleSeekingComplete = () => {
    videoAnalytics?.reportPlaybackMetric(Conviva.Constants.Playback.SEEK_ENDED);

    videoAnalytics?.reportPlaybackMetric(
      Conviva.Constants.Playback.PLAYER_STATE,
      Conviva.Constants.PlayerState.PLAYING,
    );

  }

  const handleBufferingComplete = () => {
    if (isBuffering.current) {
      bufferingTime.current = mathSpentTime(bufferingTime.current);
      videoAnalytics?.reportPlaybackMetric(
        Conviva.Constants.Playback.BUFFER_LENGTH,
        bufferingTime.current,
      );
      isBuffering.current = false;
      videoAnalytics?.reportPlaybackMetric(
        Conviva.Constants.Playback.PLAYER_STATE,
        Conviva.Constants.PlayerState.PLAYING,
      );
    }
  };

  const handlePlay = () => {
    videoAnalytics?.reportPlaybackMetric(
      Conviva.Constants.Playback.PLAYER_STATE,
      Conviva.Constants.PlayerState.PLAYING,
    );
  };

  const handlePause = () => {
    videoAnalytics?.reportPlaybackMetric(
      Conviva.Constants.Playback.PLAYER_STATE,
      Conviva.Constants.PlayerState.PAUSED,
    );
  };

  const handleBitrateChanged = () => {
    const stats = player?.getSelectedVideoTrack()
    const bitrate = stats?.bitrate / 1000;
    const fps = stats?.fps;
    const tick = stats?.tick;

    videoAnalytics?.reportPlaybackMetric(
      Conviva.Constants.Playback.BITRATE,
      bitrate, // in kbps
    )
    if (fps && tick >= 3) {
      videoAnalytics?.reportPlaybackMetric(
        Conviva.Constants.Playback.RENDERED_FRAMERATE,
        fps
      )
    }

  };

  const mathSpentTime = (param: number) => {
    const result = param + (moment().valueOf() - spentTimer.current);
    resetSpentTimer();
    return result;
  };

  function handlePlayerLoadError() {
    videoAnalytics.reportPlaybackFailed('Player Load Error');
  }

  function handleBuffering(buffering) {
    if (buffering) {
      videoAnalytics?.reportPlaybackMetric(
        Conviva.Constants.Playback.PLAYER_STATE,
        Conviva.Constants.PlayerState.BUFFERING,
      );
    }
    else {
      videoAnalytics?.reportPlaybackMetric(
        Conviva.Constants.Playback.PLAYER_STATE,
        Conviva.Constants.PlayerState.PLAYING,
      );
    }
  }

  function handlePlayerPlaybackError() {
    videoAnalytics.reportPlaybackError('Player playback Error');
  }


  React.useEffect(() => {
    if (videoAnalytics) {
      videoAnalytics?.reportPlaybackRequested(metrics);
    }

    return () => videoAnalytics?.reportPlaybackEnded();
  }, [videoAnalytics]);

  React.useEffect(() => {
    if (!player) return;
    // @ts-ignore
    player.on(Events.PLAY, handlePlay);
    player.on(Events.PAUSE, handlePause);
    player.on(Events.SEEKED, handleSeekingComplete);
    player.on(Events.SEEKING, handleSeekingTime);
    player.on(Events.BUFFERING_START, handleBufferingStart);
    player.on(Events.BUFFERING_COMPLETE, handleBufferingComplete);
    player.on(Events.TRACK_CHANGED, handleBitrateChanged);
    player.on(Events.ERROR, handlePlayerLoadError); // error on player load
    player.on(Events.BUFFERING, handleBuffering)
    player.on(Events.PLAYER_ERROR, handlePlayerPlaybackError) // error while playing

    return () => {
      // @ts-ignore
      player.removeListener(Events.PLAY, handlePlay);
      player.removeListener(Events.PAUSE, handlePause);
      player.removeListener(Events.SEEKED, handleSeekingComplete);
      player.removeListener(Events.SEEKING, handleSeekingTime);
      player.removeListener(Events.BUFFERING_START, handleBufferingStart);
      player.removeListener(Events.BUFFERING_COMPLETE, handleBufferingComplete);
      player.removeListener(Events.TRACK_CHANGED, handleBitrateChanged);
      player.removeListener(Events.ERROR, handlePlayerLoadError)
      player.removeListener(Events.BUFFERING, handleBuffering)
      player.removeListener(Events.PLAYER_ERROR, handlePlayerPlaybackError)
    };
  }, [player]);
};

export default useConvivialAnalytics;
