export enum AccountField {
  Username = 'username',
  Login = 'login',
  Password = 'password',
}

interface AccountInfo {
  parental_control: boolean;
  parental_control_flow_passed: boolean;
  security_pin: boolean;
  email: string | null;
  allow_notifications: boolean;
  address_country: string | null;
  address_postcode: string | null;
  address_street: string | null;
  address_city: string | null;
  [AccountField.Username]: string | null;
  [AccountField.Login]: string | null;
  object: 'account';
}

export default AccountInfo;

