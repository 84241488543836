import { PlatformClass } from 'platform-name/index';

import Platform from '~typings/Platform';


export const initPlatformParams = async () => {
  const platform = new PlatformClass({
    scriptsPaths: [],
  }) as Platform;
  await platform.init().catch(err=> {
    // eslint-disable-next-line no-console
    console.error('platform init error', err);
  });
  const keyCodes = platform.getKeyCodes();

  return {
    platform,
    keyCodes,
  }
}

