import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InjectedIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

import { useConfig } from '~app/global';
import { useApp } from '~components/Provider/App';
import usePaymentHistory from '~hooks/fetch/usePayments/usePaymentHistory';
import { useProfiles } from '~hooks/fetch/useProfiles';
import usePurchases from '~hooks/fetch/usePurchases/usePurchases';
import { useIsQuestionsAvailable } from '~hooks/fetch/useQuestions';
import { useGetDevice } from '~hooks/useGetDevices';

import { ACCOUNT_PATH_SCHEME } from './constants';
import { AccountTab, AccountTabId } from './types';
import { getAccountTabs } from './utils';

const useIsActiveProfileKids = () => {
  const { activeProfileId } = useApp();
  const { data: profiles } = useProfiles();

  const activeProfile = useMemo(
    () => profiles?.find(({ id }) => id === activeProfileId),
    [profiles, activeProfileId],
  );

  return Boolean(activeProfile?.kids);
};

type useAvailableTabsProps = {
  intl: InjectedIntl;
};

/**
 * Хук возвращает доступные табы
 *
 * isTabsLoading - флаг загрузки,
 * если он перешел в `true`, то обрабно уже не перейдет, это конечное состояние
 */
export const useAvailableTabs = (props: useAvailableTabsProps) => {
  const isKidsProfile = useIsActiveProfileKids();
  const { isAuthorized } = useApp();
  const profileSection = useConfig((c) => c.smartTV.profileSection);
  const { data: devices, isLoading: isDevicesLoading } = useGetDevice(profileSection.devices);
  const { parsedData: purchases, isLoading: isPurchasesLoading } = usePurchases();
  const { parsedData: payments, isLoading: isPaymentLoading } = usePaymentHistory();

  const {
    isLoading: isQuestionsLoading,
    isAvailable: isQuestionsAvailable,
  } = useIsQuestionsAvailable();

  const availableTabs = useMemo(
    () =>
      getAccountTabs({
        intl: props.intl,
        isKidsProfile,
        profileSection,
        isAuthorized: Boolean(isAuthorized),
        isDevicesLoaded: Boolean(devices),
        isQuestionsLoaded: Boolean(isQuestionsAvailable),
        purchases: purchases,
        isPurchasesLoaded: Boolean(purchases),
        payments: payments,
        isPaymentsLoaded: Boolean(payments),
      }),
    [
      isKidsProfile, 
      isAuthorized, 
      profileSection, 
      props.intl, 
      devices, 
      isQuestionsAvailable, 
      purchases, 
      payments
    ],
  );

  const [isTabsLoading, setIsTabsLoading] = useState(true);

  useEffect(() => {
    if(!isQuestionsLoading && !isDevicesLoading && !isPurchasesLoading && !isPaymentLoading) {
      setIsTabsLoading(false);
    }
  }, [isQuestionsLoading, isDevicesLoading, isPurchasesLoading, isPaymentLoading]);




  return {
    availableTabs,
    isTabsLoading
  };
};

/**
 * Хук возвращает активный таб из ссылки.
 * Но перед этим проверяет, является ли активный таб доступным
 */
export const useActiveTabId = (availableTabs: AccountTab[]): AccountTabId | undefined => {
  const matchItemObject = useRouteMatch<{ tabId?: string }>(ACCOUNT_PATH_SCHEME);
  const tabId = matchItemObject?.params.tabId;

  const activeTabInAvailable = useMemo(
    () => tabId ? availableTabs.find((tab) => tab.id === tabId) : undefined,
    [availableTabs, tabId],
  );

  return activeTabInAvailable?.id
};


export const useAccountNavigation = ({
  activeTabId,
  isTabsLoading,
}: {
  activeTabId: AccountTabId | undefined;
  isTabsLoading: boolean;
}) => {
  const [focusOn, setFocusOn] = useState<'sidebar' | 'tabs'>('sidebar');

  const setFocusOnSidebar = useCallback(() => {
    setFocusOn('sidebar');
  }, []);

  const setFocusOnTabs = useCallback(() => {
    if(activeTabId === 'stats') {
      return
    }
    setFocusOn('tabs');
  }, [activeTabId]);

  const isInitialFocusPassed = useRef(false);
  useEffect(() => {
    if (isInitialFocusPassed.current) {
      return;
    }

    if (isTabsLoading) {
      return;
    }

    isInitialFocusPassed.current = true;
    if (activeTabId) {
      setFocusOnTabs();
    }
  }, [isTabsLoading, setFocusOnSidebar]);

  return {
    focusOn,
    setFocusOnSidebar,
    setFocusOnTabs,
  };
};
