/* eslint-disable max-len */
import enumValuesToArray from '~lib/EnumValuesToArray';
import CollectionType from '~typings/CollectionType';

import expand from './expand';


enum FetchType {
  AudioShow = 'audio_show',
  Part = 'part',
  Banners = 'banners',
  BannersV4 = 'bannersV4',
  Blackouts = 'Blackouts',
  CardCollections = 'card_collections',
  CardCollectionsItems = 'card_collections_items',
  CardMutations = 'card_mutations',
  Channels = 'channels',
  Collection = 'collection',
  CollectionItems = 'collection_items',
  ContinueWatching = 'continue_watching',
  ContinueWatchingV3 = 'continue_watching_v3',
  FavoriteChannels = 'favorite_channels',
  FavoriteMovies = 'favorite_movies',
  FavoritesV1 = 'favoritesV1',
  FavoritesV3 = 'favoritesV3',
  Movies = 'movies',
  PagesV4 = 'pagesV4',
  Products = 'products',
  ProductsItems = 'products_items',
  Questions = 'questions',
  Recommendations = 'recommendations',
  RentPlans = 'rent_plans',
  RentPlanCards = 'rent_plan_cards',
  Series = 'series',
  Episode = 'episode',
  Subscription = 'subscription',
  SubscriptionPlans = 'subscription_plans',
  WatchProgress = 'watch_progress',
  ScreenCardConfigs = 'screen_card_configs',
}

type FetchURL = Readonly<{
  [key in FetchType]: string;
}>;

type FetchOptions = Readonly<{
  [key in FetchType]: object;
}>;

const fetchURLs: FetchURL = {
  [FetchType.AudioShow]: '/v5/audio_shows',
  [FetchType.Part]: '/v1/parts',
  [FetchType.Banners]: '/v1/banners',
  [FetchType.BannersV4]: '/v4/banners',
  [FetchType.Blackouts]: '/v1/blackouts',
  [FetchType.CardCollections]: '/v3/card_collections',
  [FetchType.CardCollectionsItems]: '/v3/card_collection_items',
  [FetchType.CardMutations]: '/v3/card_mutations',
  [FetchType.Channels]: '/v5/channels',
  [FetchType.Collection]: '/v1/collections',
  [FetchType.CollectionItems]: '/v1/collection_items',
  [FetchType.ContinueWatching]: '/v1/user/continue_watching',
  [FetchType.ContinueWatchingV3]: '/v3/user/continue_watching',
  [FetchType.FavoriteChannels]: '/v1/favorites/channels',
  [FetchType.FavoriteMovies]: '/v1/favorites/movies',
  [FetchType.FavoritesV1]: '/v1/favorites',
  [FetchType.FavoritesV3]: '/v3/favorites',
  [FetchType.Movies]: '/v5/movies',
  [FetchType.PagesV4]: '/v4/pages',
  [FetchType.Products]: '/v1/subscriptions/products',
  [FetchType.ProductsItems]: '/v3/subscriptions/products_items',
  [FetchType.Questions]: '/v1/faq/questions',
  [FetchType.Recommendations]: '/v1/user/recommendations',
  [FetchType.RentPlans]: '/v3/purchases/rent_plans.json',
  [FetchType.RentPlanCards]: '/v3/rent_plan_cards',
  [FetchType.Series]: '/v5/series',
  [FetchType.Episode]: '/v1/episodes',
  [FetchType.Subscription]: '/v1/subscriptions',
  [FetchType.SubscriptionPlans]: 'v1/subscriptions/plans',
  [FetchType.WatchProgress]: '/v1/user/watch_progress',
  [FetchType.ScreenCardConfigs]: '/v1/screen_card_configs',
};

const fetchOptionsBasic: FetchOptions = {
  [FetchType.PagesV4]: {
    sort: 'relevance',
    'filter[type_in]': `built_in_page,main_page,content_page,single_card_collection_page,competition_page,custom_page,personal_page,search_page,subscribe_page`,
  },
  [FetchType.Banners]: {
    'expand[banner]': expand.banner,
  },
  [FetchType.BannersV4]: {
    sort: 'relevance',
    // 'expand[middle_banner]': 'images',
    // 'expand[big_banner]': 'banner_video.video_file,images',
  },
  [FetchType.Collection]: {
    sort: 'relevance',
    'filter[type_in]': enumValuesToArray(CollectionType).join(','),
    [`expand[${ CollectionType.Channels }]`]: 'search_filters_group.items',
    [`expand[${ CollectionType.ChannelsLogo }]`]: 'search_filters_group.items',
    [`expand[${ CollectionType.Movies }]`]: 'search_filters_group.items',
    [`expand[${ CollectionType.MoviesPoster }]`]: 'search_filters_group.items',
    [`expand[${ CollectionType.Series }]`]: 'search_filters_group.items',
    [`expand[${ CollectionType.ProgramEvents }]`]: 'search_filters_group.items',
    [`expand[${ CollectionType.ProgramProgramEvents }]`]: 'search_filters_group.items',
    [`expand[${ CollectionType.News }]`]: 'search_filters_group.items',
  },
  [FetchType.CollectionItems]: {
    sort: 'relevance',
    'expand[collections]': expand.collections,
    'expand[competition_match]': expand.competition_match,
    'expand[competition_highlight]': expand.competition_highlight,
    'expand[program_event]': expand.program_event,
    'expand[channel]': expand.channel,
    'expand[movie]': expand.movie,
    'expand[series]': 'images,genres,certification_ratings,external_catalog,external_catalog.images,studios,studios.images,ratings,countries,language,languages,right_holder',
    'expand[news]': expand.news,
    'expand[program]': expand.program,
    'expand[audio_show]': expand.audio_show,
  },
  [FetchType.CardCollections]: {
    sort: 'relevance',
  },
  [FetchType.CardCollectionsItems]: {
    sort: 'relevance',
  },
  [FetchType.CardMutations]: {
    sort: 'relevance',
  },
  [FetchType.FavoriteMovies]: {
    'expand[favorite]': 'resource,resource.certification_ratings,resource.genres,resource.genres.images,resource.images,resource.language,resource.countries,resource.ratings,resource.teaser,resource.teaser.images,resource.trailer,resource.trailer.video_file,resource.external_catalog,resource.external_catalog.images,resource.right_holder,resource.video_file,resource.studios,resource.studios.images',
  },
  [FetchType.FavoriteChannels]: {
    'expand[favorite]': 'resource,resource.genres,resource.genres.images,resource.images,resource.iptv_live_preview,resource.certification_ratings,resource.live_preview,resource.live_stream,resource.catchup_availability,resource.timeshift_availability',
  },
  [FetchType.FavoritesV1]: {},
  [FetchType.FavoritesV3]: {
    sort: '-updated_at',
  },
  [FetchType.Recommendations]: {
    'filter[resource_type_in]': 'movies',
    'expand[movie]': expand.movie,
    'expand[series]': expand.series,
  },
  [FetchType.ContinueWatching]: {
    sort: '-updated_at',
    'filter[resource_type_in]': 'movies,episodes',
    'expand[movie]': expand.movie,
    'expand[episode]': expand.episode,
  },
  [FetchType.ContinueWatchingV3]: {
    sort: '-updated_at',
  },
  [FetchType.WatchProgress]: {
    sort: '-updated_at',
  },
  [FetchType.Products]: {
    sort: 'relevance',
    'filter[type_in]': 'base',
    'expand[product]': 'plans,plans.phases,plans.phases.promos,plans.eligible_phase,included_add_on_products,product_features,product_features.images,images,search_filters_group.items',
  },
  [FetchType.ProductsItems]: {
    sort: 'relevance',
    'card_config_preset': 'channel_logo',
  },
  [FetchType.Subscription]: {
    'expand[subscription]': 'plan,plan.product,plan.eligible_phase,plan.phases,plan.phases.promos,phase,autorenew',
  },
  [FetchType.SubscriptionPlans]: {
    'expand[plan]': expand.plan,
  },
  [FetchType.RentPlans]: {
    'expand[est_plan]': 'phases.billing_period,phases.duration,phases.recurring_price,phases.promos',
    'expand[tvod_plan]': 'phases.billing_period,phases.duration,phases.recurring_price,phases.promos',
  },
  [FetchType.RentPlanCards]: {},
  [FetchType.Channels]: {
    sort: 'relevance',
  },
  [FetchType.Movies]: {
    sort: 'relevance',
  },
  [FetchType.Series]: {
    sort: 'relevance',
  },
  [FetchType.Episode]: {},
  [FetchType.AudioShow]: {},
  [FetchType.Part]: {},
  [FetchType.Blackouts]: {},
  [FetchType.Questions]: {
    sort: 'relevance',
    'filter[platforms_include]': 'smart_tv'
  },
  [FetchType.ScreenCardConfigs]: {},
};

const fetchURL = (fetchType: FetchType): string => fetchURLs[fetchType];

const fetchQuery = (fetchType: FetchType, additionalFetchOptions: object): object => ({
  ...fetchOptionsBasic[fetchType],
  ...additionalFetchOptions,
});

export { fetchOptionsBasic, fetchQuery, FetchType, fetchURL, fetchURLs };
