enum PaymentMethod {
  Operator = 'operator',
  PromoCode = 'promo_code',
  Existing_card = 'existing_card',
  NewCard = 'new_card',
  Cash = 'cash',
  a1NewCard = 'a1_new_card',
  a1Existing_card = 'a1_existing_card',
  External = 'external',
  ItunesInApp = 'itunes_in_app',
  AndroidInApp = 'android_in_app',
  MellatNewCard = 'mellat_new_card',
};


export default PaymentMethod;
