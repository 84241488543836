// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sliderWrapper--Dif_F {\r\n  margin-bottom: 4.6875vw;\r\n  padding-left: 0.2vw;\r\n  width: 86.15vw;\r\n}\r\n\r\n.sliderTitle--vSXoI {\r\n  margin-left: 0.9375vw;\r\n  margin-bottom: 1.5625vw;\r\n}\r\n\r\n.slider--mAH3h {\r\n  display: -webkit-box;\r\n  display: -webkit-flex;\r\n  display: -moz-box;\r\n  display: flex;\r\n  -webkit-box-align: start;\r\n  -webkit-align-items: start;\r\n     -moz-box-align: start;\r\n          align-items: start;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/app/components/ActorPage/ActorPageSliders/MoviesAndSeriasSlider/styles.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAkB;EAAlB,0BAAkB;KAAlB,qBAAkB;UAAlB,kBAAkB;AACpB","sourcesContent":[".sliderWrapper {\r\n  margin-bottom: 4.6875vw;\r\n  padding-left: 0.2vw;\r\n  width: 86.15vw;\r\n}\r\n\r\n.sliderTitle {\r\n  margin-left: 0.9375vw;\r\n  margin-bottom: 1.5625vw;\r\n}\r\n\r\n.slider {\r\n  display: flex;\r\n  align-items: start;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var sliderWrapper = "sliderWrapper--Dif_F";
export var sliderTitle = "sliderTitle--vSXoI";
export var slider = "slider--mAH3h";
export default ___CSS_LOADER_EXPORT___;
