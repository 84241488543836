import { FocuserClickHandler, FocuserKeyHandler } from '@focuser';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useConfig } from '~app/global';
import { useAccount,useNotificationsMutation } from '~hooks/fetch/useAccount';

export const useControlNavigation = ({
  isNotificationEditable,
}: {
  isNotificationEditable: boolean;
}) => {
  const [focusedOn, setFocusOn] = useState<'password' | 'checkbox' | 'logout'>('password');
  const handleUp: FocuserKeyHandler = (ev) => {
    if (focusedOn === 'password') {
      return;
    }

    ev.stop();

    if (focusedOn === 'logout' && isNotificationEditable) {
      setFocusOn('checkbox');
      return;
    }

    setFocusOn('password');
  };

  const handleDown: FocuserKeyHandler = (ev) => {
    if (focusedOn === 'logout') {
      return;
    }

    ev.stop();

    if (focusedOn === 'password' && isNotificationEditable) {
      setFocusOn('checkbox');
      return;
    }

    setFocusOn('logout');
  };

  const { setFocusOnLogout, setFocusOnPassword, setFocusOnCheckbox } = useMemo(
    () => ({
      setFocusOnLogout: () => setFocusOn('logout'),
      setFocusOnPassword: () => setFocusOn('password'),
      setFocusOnCheckbox: () => setFocusOn('checkbox'),
    }),
    [],
  );

  return {
    focusedOn,
    handleUp,
    handleDown,
    setFocusOnLogout,
    setFocusOnPassword,
    setFocusOnCheckbox,
  };
};

export const useIsNotificationEditable = () => {
  return useConfig((c) =>
    Boolean(c.startup.login_form.notifications_checkbox?.notifications_checkbox_enable),
  );
};

export const useControlHandlers = () => {
  const { data: accountData } = useAccount();
  const { mutate: changeNotifications, isLoading: isNotificationLoading } =
    useNotificationsMutation();

  const history = useHistory();

  const handleCheckboxChange = () => {
    if (isNotificationLoading) {
      return;
    }

    changeNotifications(!accountData?.allow_notifications);
  };



  const handlePasswordChange: FocuserClickHandler = (ev) => {
    ev.stop();
    ev.stopNativeEvent();
    history.push('/account-edit/password');
  };

  return {
    handleCheckboxChange,
    handlePasswordChange,
  };
};
