import { flatMap } from 'lodash';
import { InfiniteData } from 'react-query';
import { QueryState } from 'react-query/types/core/query';

import SceneItem from '~components/Scene/typings';
import { queryCache } from '~global';
import { PREFIX_OF_A_COMPOSITE_KEY as cardCollectionsItemsKey } from '~hooks/fetch/useCardCollectionsItems/index';
import {
  PREFIX_OF_A_COMPOSITE_KEY as channelsKey
} from '~hooks/fetch/useChannels';
import { PREFIX_OF_A_COMPOSITE_KEY as collectionItemsKey } from '~hooks/fetch/useCollectionItems';
import { PREFIX_OF_A_COMPOSITE_KEY as continueWatchingKey } from '~hooks/fetch/useContinueWatching/useContinueWatchingV3';
import { PREFIX_OF_A_COMPOSITE_KEY as favoritesKey } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { PREFIX_OF_A_COMPOSITE_KEY as mediaItemKey } from '~hooks/fetch/useMediaItem';
import { PREFIX_OF_A_COMPOSITE_KEY as recommendationsKey } from '~hooks/fetch/useRecommendations/useRecommendations';
import { DataItem } from '~typings/DataItem';
import ItemObject from '~typings/ItemObject';
import ResponseMany from '~typings/ResponseMany';
import ResponseSingle from '~typings/ResponseSingle';


const findMediaItem = (id?: string | null, object?: ItemObject, skipCards?: boolean): SceneItem | null => {
  if (!id || !object) { return null; }

  try {
    const keysToFind: (string[] | string[][])[] = [
      [recommendationsKey],
      [favoritesKey],
      [continueWatchingKey],
      [channelsKey],
    ];

    if (!skipCards) {
      // тут `unshift`, потому что перед рефакторингом этой фукнции ключ был вначале
      keysToFind.unshift([cardCollectionsItemsKey]);
    }

    if(object !== ItemObject.Series){ //In the collection_items series loaded without an expanded of seasons
      keysToFind.push([[collectionItemsKey]]);
    }

    type CommonDataType = ResponseMany<SceneItem[]>;

    for (const key of keysToFind) {
      const queriesWithItems = queryCache.findAll(key);
      for (const query of queriesWithItems) {
        const queryState = query.state as
          | QueryState<CommonDataType, any>
          | QueryState<InfiniteData<CommonDataType>, any>;

        const cachedData = queryState.data;
        if (!cachedData) {
          continue;
        }

        const itemsArray =
          'data' in cachedData ? cachedData.data : flatMap(cachedData.pages, (data) => data.data);
        const item = itemsArray.find((item) => item.id === id && item.object === object);
        if (item) {
          return item;
        }
      }
    }

    const queriesMediaItem = queryCache.findAll([mediaItemKey]);
    for (let i = 0; i < queriesMediaItem.length; i += 1) {
      const item = queriesMediaItem[i].state.data as ResponseSingle<DataItem> | undefined;


      if (
        item && item.data &&
        // @ts-ignore
        (item.data.id === id || item.data?.slug === id) &&
        item.data.object === object
      ) { return item.data as SceneItem; }
    }
    /**
     * дальше проверка programEventsKey
     * отключили чтобы корректно работал ивенты при кейсе
     * EPG -> кетчап -> ивенты -> кетчап
     */
    //const queriesProgramEvents = queryCache.findAll([programEventsKey]);
    //console.log('15', )
    // for (let i = 0; i < queriesProgramEvents.length; i += 1) {
    //   const cached = queriesProgramEvents[i].state as QueryState<ResponseMany<ProgramEvent[]>, any>;
    //   const item = (cached?.data?.data || EMPTY_ARRAY)
    //     .find((item: ProgramEvent) => (item.id === id && item.object === object));
    //   console.log('16', )
    //   console.log('item',item)
    //   if (item) { return item; }
    // }

  } catch (ignore) {
    return null;
  }

  return null;
};


export default findMediaItem;
