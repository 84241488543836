export enum VoteAction {
  Up = 'up',
  Down = 'down'
}

interface Vote {
  object: 'vote';
  action: VoteAction;
};

export default Vote;
