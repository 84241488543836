const isApiError = checker => err =>
  err && err.code === 'API_CLIENT_ERROR_API' && err.errors.some(checker);

const createApiErrorCheckerByCode = code => isApiError(err => err && err.code === code);

const isInvalidClientID = createApiErrorCheckerByCode('invalid_client_id');
const isAccessTokenInvalid = createApiErrorCheckerByCode('invalid_access_token');
const isClientObsolete = createApiErrorCheckerByCode('obsolete_client');
const isClientOutdatedApi = createApiErrorCheckerByCode('outdated_api');
const isDeviceTokenInvalid = createApiErrorCheckerByCode('invalid_device_token');
const isDeviceNotLinked = createApiErrorCheckerByCode('device_not_linked');
const isStreamNotFound = createApiErrorCheckerByCode('stream_not_found');
const isServiceUnavailableInCountry = createApiErrorCheckerByCode('service_unavailable_in_country');
const isNotPurchased = createApiErrorCheckerByCode('not_purchased');
const isConcurrentViewsLimitReached = createApiErrorCheckerByCode('concurrent_views_limit_reached');

const isClientCodeNotActivated = createApiErrorCheckerByCode('not_activated');
const isClientCodeExpired = createApiErrorCheckerByCode('expired');
const isClientCodeInvalid = createApiErrorCheckerByCode('invalid_code');

const isClientNotConfirmed = createApiErrorCheckerByCode('not_confirmed');

export {
  createApiErrorCheckerByCode,
  isAccessTokenInvalid,
  isApiError,
  isClientCodeExpired,
  isClientCodeInvalid,
  isClientCodeNotActivated,
  isClientNotConfirmed,
  isClientObsolete,
  isClientOutdatedApi,
  isConcurrentViewsLimitReached,
  isDeviceNotLinked,
  isDeviceTokenInvalid,
  isInvalidClientID,
  isNotPurchased,
  isServiceUnavailableInCountry,
  isStreamNotFound,
};
