import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useCountryAvailable } from '~hooks/fetch/useCountryAvailability/useCountryAvailable';


// Компонент делает запросы, и проверяет, доступна ли страна.
// Если страна недоступна, то переадресуем на страницу с ошибкой, где показываем говорим о недоступности
const GeoRestrictListener: React.FC = () => {
  const history = useHistory();

  const { data: availableData } = useCountryAvailable();

  React.useEffect(() => {
    if (availableData?.data.available === false) {
      history.replace('/error/geo-restrict');
    }
  }, [availableData?.data.available]);

  return null;
};

export default GeoRestrictListener;
