import * as React from 'react';
import { useHistory } from 'react-router-dom';

import EPGPageChannelItem from '~components/EPG/Channels/ChannelItem';
import CurrentEvent from '~components/EPG/Channels/CurrentEvent';
import Channel from '~typings/Channel';


type Props = Readonly<{
  withCurrentEvent?: boolean;
  isNeedFetchEvents?: boolean;
  isActive: boolean;
  channel: Channel | undefined;
  onClick?: (channel: Channel) => void;
}>;


const EPGPageChannel: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const handleClick = React.useCallback(() => {
    if (!props.channel) { return; }

    if (props.onClick) {
      props.onClick(props.channel);
    } else {
      history.push(`/media-item/${props.channel.object}/${props.channel.id}?player=true`);
    }
  }, []);

  return (
    <>
      <EPGPageChannelItem
        isActive={ props.isActive }
        item={ props.channel }
        onClick={ handleClick }
      />
      {
        props.withCurrentEvent && props.channel?.id ?
          <CurrentEvent
            isNeedFetchEvents={ props.isNeedFetchEvents }
            channelID={ props.channel?.id }
            onClick={ handleClick }
          />
          : null
      }
    </>
  );
};


export default React.memo(EPGPageChannel);
