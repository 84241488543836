import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';

import isAllowedSinglePageMediaItem from '~lib/allowedMediaItem';
import ItemObject from '~typings/ItemObject';


type Props = Readonly<{
  isSceneMediaItemPageMode: boolean;
  mediaItemPage: {
    id: string | undefined;
    object: ItemObject | undefined;
  };
}>;


const useSceneMediaItemPageInfo = (): Props => {
  const matchItemObject = useRouteMatch<{
    itemID: string, itemObject: ItemObject
  }>('/page/:pageId/media-item/:itemObject/:itemID');
  const isSceneMediaItemPageMode = !!(
    matchItemObject?.params?.itemObject
    && isAllowedSinglePageMediaItem(matchItemObject.params.itemObject)
    && matchItemObject?.params?.itemID
  );
  const mediaItemPage = {
    id: matchItemObject?.params?.itemID as string | undefined,
    object: matchItemObject?.params?.itemObject as ItemObject | undefined,
  };

  return React.useMemo(() => ({
    mediaItemPage,
    isSceneMediaItemPageMode,
  }), [mediaItemPage, isSceneMediaItemPageMode]);
};


export default useSceneMediaItemPageInfo;
