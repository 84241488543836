import * as React from 'react';

import useHotKey from '~hooks/useHotKey';
import HotKey from '~typings/HotKey';
import NavigationDirection from '~typings/NavigationDirection';


type Props = Readonly<{
  isMounted: boolean;
  onNavigation: (direction: NavigationDirection) => void;
}>;


const useNavigationByKeys = (props: Props, update: any[]): void => {
  const handleUpdate = [props.onNavigation];

  const handleUp = React.useCallback((): void => {
    props.onNavigation(NavigationDirection.Up);
  }, handleUpdate);

  const handleRight = React.useCallback((): void => {
    props.onNavigation(NavigationDirection.Right);
  }, handleUpdate);

  const handleDown = React.useCallback((): void => {
    props.onNavigation(NavigationDirection.Down);
  }, handleUpdate);

  const handleLeft = React.useCallback((): void => {
    props.onNavigation(NavigationDirection.Left);
  }, handleUpdate);

  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_UP,
    action: handleUp,
  }, update);

  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_RIGHT,
    action: handleRight,
  }, update);

  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_DOWN,
    action: handleDown,
  }, update);

  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_LEFT,
    action: handleLeft,
  }, update);
}


export default useNavigationByKeys;
