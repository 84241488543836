import { useApp } from '~components/Provider/App/component.helpers';
import { useMenu } from '~components/Provider/Menu/component.helpers';
import useIsPlayerPage from '~hooks/useIsPlayerPage';
import useSceneMediaItemPageInfo from '~hooks/useSceneMediaItemPage';

export const useAllowNavigation = () => {
  const { isMenuOpened, isPopupOpened } = useMenu();
  const { isLaunchingDone } = useApp();
  const isPlayerPage = useIsPlayerPage();
  const { isSceneMediaItemPageMode } = useSceneMediaItemPageInfo();
  const allowNavigation = Boolean(
    !(isMenuOpened || isPopupOpened || isSceneMediaItemPageMode || isPlayerPage)
    && isLaunchingDone
  );

  return {
    isMenuOpened,
    isPopupOpened,
    isLaunchingDone,
    isSceneMediaItemPageMode,
    isPlayerPage,
    allowNavigation,
  } as const;
};
