import * as React from 'react';

import usePrevious from '~hooks/usePrevious';


type Props = Readonly<{
  password: string;
  isShowingPassword: boolean;
}>;

export const hiddenChar = '•';
const replaceChars = (str: string): string => (
  str.split('').map(() => hiddenChar).join('')
);

const usePassword = (props: Props): string => {
  const [password, setPassword] = React.useState('');
  const oldPassword = usePrevious<string>(props.password);
  const handleHideAllCars = (str: string | number) => {
    const parsedString = str.toString();
    setPassword(replaceChars(parsedString.substring(0, parsedString.length)));
  };
  const handleShowLastChar = (str: string) => {
    const length = str.length;
    const hiddenChars = replaceChars(str.substring(0, (length - 1)));

    setPassword(`${hiddenChars}${str.substring((length - 1), length)}`);
  };

  React.useEffect(() => {
    if ((oldPassword || '').length < props.password.length) {
      handleShowLastChar(props.password);

      const timer = setTimeout(() => {
        handleHideAllCars(props.password);
      }, 500);

      return (
        () => {
          if (timer) {
            clearTimeout(timer);
          }
        }
      );
    } else {
      handleHideAllCars(props.password);
    }
  }, [props.password]);

  if (props.isShowingPassword) { return props.password; }

  return password;
};


export {
  replaceChars,
};

export default usePassword;
