// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notFoundMessage--yFxKV {\n  width: 100%;\n  /* 184px */\n  margin-top: 14.375vw;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n}\n\n.text--AvsoS {\n  color: rgba(223, 223, 228, 1);\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/CardCollectionGrid/components/CardCollectionNotFound/CardCollectionNotFound.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,UAAU;EACV,oBAAoB;EACpB,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;AACrB;;AAEA;EACE,6BAAa;AACf","sourcesContent":[".notFoundMessage {\n  width: 100%;\n  /* 184px */\n  margin-top: 14.375vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.text {\n  color: $gw_08;\n}\n"],"sourceRoot":""}]);
// Exports
export var notFoundMessage = "notFoundMessage--yFxKV";
export var text = "text--AvsoS";
export default ___CSS_LOADER_EXPORT___;
