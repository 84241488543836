// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttonDelete--VMm9S {\n  margin-left: 38vw;\n}\n\n.footerAdditionalClassname--sAbUd {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  width: 100vw;\n  left: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/Devices/DeviceEdit/DeviceEdit.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,YAAY;EACZ,OAAO;AACT","sourcesContent":[".buttonDelete {\n  margin-left: 38vw;\n}\n\n.footerAdditionalClassname {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100vw;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var buttonDelete = "buttonDelete--VMm9S";
export var footerAdditionalClassname = "footerAdditionalClassname--sAbUd";
export default ___CSS_LOADER_EXPORT___;
