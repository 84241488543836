import { useCallback, useEffect, useMemo, useRef } from 'react';

import { usePlayerStore } from "~stores/Player";
import TimeShiftAvailability from '~typings/TimeshiftAvailability';

import { getTimeShiftDurationInSeconds } from './utils';


type Props = Readonly<{
  isPlaying: boolean;
  timeShift: TimeShiftAvailability;
  currentTime: number;
  streamStartedTime: Date;
  onSeek: (newTime: number) => void;
}>;

type Result = Readonly<{
  secondsMissing: number;
  duration: number;
  setSecondsMissing: (value: number) => void;
}>;


const useTimeShiftState = (props: Props): Result => {
  const secondsMissing = usePlayerStore(state => state.missingSeconds)
  const setSecondsMissing = usePlayerStore(state => state.setMissingSeconds)
  const plusOne = usePlayerStore(state => state.setPlusOne)
  //const [secondsMissing, setSecondsMissing] = useState<number>(0);
  const duration = useMemo(() => (
    getTimeShiftDurationInSeconds(props.timeShift.period)
  ), [props.timeShift.period]);
  const timeShiftTimer = useRef<number | undefined>(undefined);


  const incrementMissingSecond = () => {
    plusOne()
  };

  const stopTimeShiftTimer = useCallback(() => {
    if (timeShiftTimer.current) {
      window.clearInterval(timeShiftTimer.current);
      timeShiftTimer.current = undefined;
    }
  }, []);

  const startTimeShiftTimer = useCallback(() => {
    if (!props.isPlaying) {
      if (!timeShiftTimer.current) {
        timeShiftTimer.current = window.setInterval(() => {
          if (duration <= secondsMissing || props.isPlaying) {
            stopTimeShiftTimer();
          }
          else {
            incrementMissingSecond();
          }
        }, 1000);
      }
    }
    else {
      stopTimeShiftTimer();
    }
  }, [secondsMissing, duration, timeShiftTimer.current, props.isPlaying]);

  useEffect(() => {
    return stopTimeShiftTimer;
  }, []);

  useEffect(() => {
    stopTimeShiftTimer();

    startTimeShiftTimer();
  }, [props.isPlaying]);

  return ({
    setSecondsMissing,
    secondsMissing,
    duration,
  });
};


export default useTimeShiftState;
