// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorInfo--fnjgY {\n  position: absolute;\n  opacity: 0.5;\n  left: 0;\n  top: 0;\n}\n\n.errorMessage--Gite4 {\n  position: absolute;\n  left: 0;\n  top: 0;\n  z-index: 2;\n  width: 100%;\n  height: 100%;\n}\n\n.wrapper--CGiYP {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/ErrorCrashFallback/ErrorCrashScreen.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,MAAM;AACR;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,UAAU;EACV,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd","sourcesContent":[".errorInfo {\n  position: absolute;\n  opacity: 0.5;\n  left: 0;\n  top: 0;\n}\n\n.errorMessage {\n  position: absolute;\n  left: 0;\n  top: 0;\n  z-index: 2;\n  width: 100%;\n  height: 100%;\n}\n\n.wrapper {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export var errorInfo = "errorInfo--fnjgY";
export var errorMessage = "errorMessage--Gite4";
export var wrapper = "wrapper--CGiYP";
export default ___CSS_LOADER_EXPORT___;
