// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actorBlock--yVkt3 {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n}\n\n.blockWrap--fBLog {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: start;\n  -webkit-align-items: flex-start;\n     -moz-box-align: start;\n          align-items: flex-start;\n  margin-top: 1.563vw;\n  height: 18vw;\n  margin-bottom: 4vw;\n}\n\n.actorFocuser--q5R3x {\n  -webkit-flex-shrink: 0;\n          flex-shrink: 0;\n}\n\n.focused--e_lBo {\n  border: solid 4px rgba(243, 243, 247, 1);\n}\n\n.title--PuNq3 {\n  font-size: 1.875vw;\n  font-weight: 500;\n  margin-bottom: 1.563vw;\n  margin-top: 3.906vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemDetails/ActorsBlockFocuser/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;AACf;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,sBAAc;UAAd,cAAc;AAChB;;AAEA;EACE,wCAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,gBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".actorBlock {\n  display: flex;\n}\n\n.blockWrap {\n  display: flex;\n  align-items: flex-start;\n  margin-top: 1.563vw;\n  height: 18vw;\n  margin-bottom: 4vw;\n}\n\n.actorFocuser {\n  flex-shrink: 0;\n}\n\n.focused {\n  border: solid 4px $gw_10;\n}\n\n.title {\n  font-size: 1.875vw;\n  font-weight: $fw_5;\n  margin-bottom: 1.563vw;\n  margin-top: 3.906vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var actorBlock = "actorBlock--yVkt3";
export var blockWrap = "blockWrap--fBLog";
export var actorFocuser = "actorFocuser--q5R3x";
export var focused = "focused--e_lBo";
export var title = "title--PuNq3";
export default ___CSS_LOADER_EXPORT___;
