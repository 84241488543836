import { memoize } from 'lodash';
import { useEffect, useMemo, useReducer } from 'react';

import {
  circleImageInHorizontalHeightVh,
  circleImageInHorizontalWidthVw,
  horizontalImageHeightVh,
  horizontalImageWidthVw,
  squareImageInHorizontalHeightVh,
  squareImageInHorizontalWidthVw,
} from '~app/variables';
import { useAccount, useAuthData } from '~hooks/fetch/useAccount';
import { useUpdatableImage } from '~hooks/useUpdatableImage';
import { fillImageTemplateBySizes } from '~lib/image';
import getScreenHeight from '~lib/screen/height';
import getScreenWidth from '~lib/screen/width';
import { Nullable } from '~lib/type-utils/utils';
import Card, { Modification, TSimageShape } from '~typings/Card';
import { CardMutationState } from '~typings/CardMutation';
import Image, { CardImageType } from '~typings/Image';
import ItemObject from '~typings/ItemObject';
import { CardConfig } from '~typings/ScreenCardConfigs';

import { CardSimpleLabel } from '../../ui/CardV2/types';
import { getProgressFromTwoDates } from './utils';

export const useNeedToBlurCardImage = (isCardInappropriate: boolean) => {
  const { data: accountInfo } = useAccount();
  const { accessToken } = useAuthData();

  return !accessToken || accountInfo?.parental_control ? isCardInappropriate : false;
};


export const useGetFieldsFromCard = (card: Card, cardConfig: CardConfig) => {
  return useMemo(() => getFieldsFromCard(card, cardConfig), [card, cardConfig]);
}

/**
 * Возвращает поля для отображения в карточке
 */
const getFieldsFromCard = (card: Card, cardConfig: CardConfig) => {
  // Номер эпизода в левом нижнем углу и ярлычок сверху над картинкой
  const episodeExist = card?.resource_type === ItemObject.Episode;

  const episodeNumberParsed = Number(card?.additional_name?.split(' ')?.[3] ?? '');

  const episodeNumber =
    episodeExist && !isNaN(episodeNumberParsed) && episodeNumberParsed > 0
      ? String(episodeNumberParsed)
      : null;

  const catalogHeaderExist = card?.catalog_header;

  // иконка по центру
  const playIconExist =
    card.modification === Modification.FUTURE ||
    card.modification === Modification.ANNOUNCEMENT_MOD ||
    card.modification === Modification.CATCHUP;

  const modification = playIconExist ? card.modification : null;

  //

  // блок с заголовками под картинкой
  const showBottomTextBlock = !cardConfig.disable_text && !!(card.name || card.additional_name);

  const contentProviderIconUrl =
    fillImageTemplateBySizes(
      card?.content_icon?.url_template || card?.card_icon?.url_template,
      60,
      60,
      true,
    ) || undefined;

  const label: Nullable<CardSimpleLabel> = (() => {
    const unparsedLabel = card.labels?.[0];
    if (!unparsedLabel) {
      return null;
    }
    return {
      text: unparsedLabel.name,
      textColor: unparsedLabel.text_color,
      backgroundColor: unparsedLabel.bg_color,
    };
  })();

  return {
    episodeNumber,
    catalogHeaderExist,
    playIconExist,
    showBottomTextBlock,
    contentProviderIconUrl,
    modification,
    label,
  };
};

/**
 * Хук считает прогресс
 * Если есть захардкоженный прогресс, то вернет его
 * Если нет, то вернет прогресс посчитав из мутации
 */
export const useDynamicProgress = ({
  cardMutationState,
  hardcodedProgress,
}: {
  cardMutationState?: Nullable<CardMutationState>;
  hardcodedProgress?: number;
}) => {
  const [forceUpdateCounter, forceUpdate] = useReducer((c) => c + 1, 0);

  const parsedProgress = useMemo(() => {
    if (hardcodedProgress !== undefined) {
      return hardcodedProgress;
    }

    if (!cardMutationState) {
      return;
    }

    if (cardMutationState.disable_progress_line) {
      return;
    }

    if (!cardMutationState.end_at || !cardMutationState.start_at) {
      return;
    }

    const startAt = new Date(cardMutationState.start_at);
    const endAt = new Date(cardMutationState.end_at);
    if (isNaN(startAt.getTime()) || isNaN(endAt.getTime())) {
      return;
    }

    return getProgressFromTwoDates(startAt, endAt);
  }, [
    cardMutationState?.start_at,
    cardMutationState?.end_at,
    hardcodedProgress,
    forceUpdateCounter,
  ]);

  useEffect(() => {
    if (
      hardcodedProgress !== undefined ||
      parsedProgress === undefined ||
      !cardMutationState?.end_at
    ) {
      return;
    }
    const start = new Date(cardMutationState.start_at).getTime();
    const end = new Date(cardMutationState.end_at).getTime();
    if (isNaN(start) || isNaN(end)) {
      return;
    }

    const timeoutTime = (end - start) / 100 + 1000;

    const timeout = setTimeout(() => {
      forceUpdate();
    }, timeoutTime);

    return () => clearTimeout(timeout);
  }, [parsedProgress, hardcodedProgress]);

  return parsedProgress;
};

/**
 * Возращает картинку для карточки, которая обновляется
 * Сначала предзагружает картинку
 */
export const useCardImage = ({
  cardImages,
  cardImageType,
  isCardInappropriate,
  widthVW,
  heightVH,
}: {
  cardImages: Image[];
  cardImageType: CardImageType;
  isCardInappropriate: boolean;
  widthVW: number;
  heightVH: number;
}) => {
  const needToBlurImage = useNeedToBlurCardImage(isCardInappropriate);

  const imageToShow = useMemo(() => {
    const image = cardImages.find((el) => el.type === cardImageType);
    if (!image?.url_template) return null;

    const width = Math.ceil((getScreenWidth() * widthVW) / 100); // результат в пикселях
    const height = Math.ceil((getScreenHeight() * heightVH) / 100); // результат в пикселях

    const preparedUrl =  fillImageTemplateBySizes(image.url_template, width, height, true, needToBlurImage);

    return {
      interval: image.update_interval,
      url: preparedUrl,
    };

  }, [cardImages, cardImageType, widthVW, heightVH, needToBlurImage]);


  const {
    data: preloadedUrl
  }= useUpdatableImage({
    url: imageToShow?.url,
    interval: imageToShow?.interval ? imageToShow.interval * 1000 : null,
  });

  return preloadedUrl;

};


export const getHorizontalCardImageParams = memoize((imageShape: TSimageShape)=> {
    switch (imageShape) {
      case TSimageShape.square:
        return {
          type: 'square' as const,
          widthVW: squareImageInHorizontalWidthVw,
          heightVH : squareImageInHorizontalHeightVh,
          cardImageType: CardImageType.Card1x1
        }
      case TSimageShape.circle:
        return {
          type: 'circle' as const,
          widthVW: circleImageInHorizontalWidthVw,
          heightVH : circleImageInHorizontalHeightVh,
          cardImageType: CardImageType.Card1x1
        }

        case TSimageShape.undefined:
        default:
        return {
          type: 'full' as const,
          widthVW: horizontalImageWidthVw,
          heightVH : horizontalImageHeightVh,
          cardImageType: CardImageType.Card16x9
        }
    }
  });
