import React from 'react';

import * as styles from './icons.module.css';

export const SpaceIcon = () => {
  return (
    <svg
      className={ styles.icon }
      width="28"
      height="9"
      viewBox="0 0 28 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.399902 0.133301H2.9499V5.26129H25.0499V0.133301H27.5999V8.06664H0.399902V0.133301Z"
        fill="currentColor"
      />
    </svg>
  );
};
