import { util as shakaUtil } from 'shaka-player';

import { Events } from '../common';
import { VastErrors } from '../VastErrors';
import VideoError from '../VideoError';


const onError = (event, err) => {
  if (err instanceof shakaUtil.Error) {
    // TODO: Add err.code Description from https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html
    // const vastError = getVastError({ shakaError: err });
    event.emit(
      Events.ERROR,
      { shakaError: err }
      // new VideoError({ nativeError: err, vastError, message: err.message }),
    );
    return;
  }

  if (!err || !err.target || !err.target.error || !err.target.error.code) {
    const message = `unknown error: ${JSON.stringify(err)}`;
    event.emit(
      Events.ERROR,
      new VideoError({ nativeError: err, message, vastError: VastErrors.UNDEFINED_ERROR }),
    );
  }
};


export default onError;
