import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ActionType, useAppAction } from '~components/Provider/App';
import { NavigationDirection } from '~components/Provider/KeyDownHandler/utils';
import { PlayerAccessReason } from '~components/Provider/Player/component.helpers';
import { useConfig } from '~global';
import { CONTINUE, NAVIGATION_BACK } from '~localization';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import PlayerAccessError from '~typings/PlayerStatus';
import Button from '~ui/button';

import { accessErrorMessageIds } from './helpers';
import * as styles from './styles.module.css';


enum FocusOn {
  SignInButton = 'SignInButton',
  SignUpButton = 'SignUpButton',
}

type Props = Readonly<{
  access: PlayerAccessError | PlayerAccessReason;
  item?: PlayerMediaItem | null;
  onClose?: () => void;
  intl: InjectedIntl;
}>;


const PlayerAccessErrorDialog: React.FC<Props> = (props: Props) => {
  const {
    smartTV: {
      baseSiteUrl,
    },
  } = useConfig();
  const history = useHistory();
  const appAction = useAppAction();
  const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.SignInButton);

  const onLeaveHandler = React.useCallback((direction: NavigationDirection) => {
    if (
      focusOn === FocusOn.SignInButton &&
      direction === NavigationDirection.Right
    ) {
      setFocusOn(FocusOn.SignUpButton);
    } else if (
      focusOn === FocusOn.SignUpButton &&
      direction === NavigationDirection.Left
    ) {
      setFocusOn(FocusOn.SignInButton);
    }
  }, [focusOn]);
  const handleBack = React.useCallback(() => {
    if (props.onClose) {
      return props.onClose();
    }

    history.goBack();
  }, [props.onClose]);

  const onAuthBtnClick = () => {
    appAction({
      type: ActionType.SetAuthRedirectUrl,
      payload: { authRedirectUrl: history.location.pathname },
    });

    history.replace('/auth');
  };

  const buttons = props.access === PlayerAccessError.NotAuthorized ? (
    <>
      <Button
        onClick={ onAuthBtnClick }
        className={ styles.button }
        onLeave={ onLeaveHandler }
        isFocused={ focusOn === FocusOn.SignInButton }
      >
        { props.intl.formatMessage({ id: CONTINUE }) }
      </Button>
      <Button
        onClick={ handleBack }
        className={ styles.button }
        onLeave={ onLeaveHandler }
        isFocused={ focusOn === FocusOn.SignUpButton }
      >
        { props.intl.formatMessage({ id: NAVIGATION_BACK }) }
      </Button>
    </>
  ) : (
    <Button onClick={ handleBack } isFocused>
      { props.intl.formatMessage({ id: NAVIGATION_BACK }) }
    </Button>
  );

  const messageId = accessErrorMessageIds[props.access];

  const renderMessageText = React.useMemo(() => {
    let text: PlayerAccessError | PlayerAccessReason | string | null = null;

    if (messageId) {
      text = props.intl.formatMessage(
        { id: messageId },
        { site: baseSiteUrl },
      );
    }

    return (
      <div
        className={ styles.messageText }
      >
        { text }
      </div>
    );
  }, [baseSiteUrl, messageId, props.access]);

  return (
    <>
      { renderMessageText }
      { buttons }
    </>
  );
};


export default React.memo(injectIntl(PlayerAccessErrorDialog));
