// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link--l1YWO {\n  display: inline-block;\n  border-bottom: 1px solid;\n}\n\n.fullHD .link--l1YWO {\n  border-width: 2px;\n}\n\n.link--l1YWO.focused--udNa3,\n.pointerEnabled .link--l1YWO:hover {\n  color: rgba(253, 216, 53, 1);\n}\n", "",{"version":3,"sources":["webpack://./src/app/ui/Link/styles.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,4BAAa;AACf","sourcesContent":[".link {\n  display: inline-block;\n  border-bottom: 1px solid;\n}\n\n:global(.fullHD) .link {\n  border-width: 2px;\n}\n\n.link.focused,\n:global(.pointerEnabled) .link:hover {\n  color: $ac_00;\n}\n"],"sourceRoot":""}]);
// Exports
export var link = "link--l1YWO";
export var focused = "focused--udNa3";
export default ___CSS_LOADER_EXPORT___;
