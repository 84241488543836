import * as cn from 'classnames';
import * as React from 'react';

import { NavigationHelper } from '~ui/NavigationHelper';

import { PrimaryButton } from './PrimaryButton';


type Props = Readonly<{
  isFocused: boolean;
  isDisabled?: boolean;
  className?: string;
  onClick?: (e?: React.SyntheticEvent) => void;
  onLeave?: (NavigationDirection) => void;
  children: any;
}>;

// Временный вариант пока используется старая навигация
export const PrimaryButtonWrap: React.FC<Props> = (props: Props) => {
  const clickHandler = (e) => {
    if (!props.isDisabled && props.onClick) {
      props.onClick(e);
    }
  }

  return (
    <>
      { !!props.onLeave && props.isFocused && (
        <NavigationHelper
          isFocused={ props.isFocused }
          onLeave={ props.onLeave }
        />
      ) }
      <div onClick={ clickHandler } className={ cn({'focusedNavigationNode': props.isFocused}) }>
        <PrimaryButton
          isFocused={ props.isFocused }
          isDisabled={ props.isDisabled }
          content={ props.children }
          className={ props.className }
        />
      </div>
    </>
  );
};
