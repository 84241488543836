import { FocusableBlock } from '@focuser/block';
import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';

import { useSuggestions } from '~hooks/fetch/useSearch';

import { KeyBoard } from './keyBoard/keyboard';
import { KeyBoardLayout, KeyBoardType } from './keyBoard/keyboardLayouts/type0';
import * as styles from './styles.module.css';
import { SuggestionBlock } from './suggestions/SuggestionBlock';

export interface Props {
  searchTypes: string;
  query: string;
  updateQueryFromKeyboard: (newQuery: string) => void;
  updateQueryFromSuggestion: (newQuery: string) => void;
  handleSearchButtonClick: () => void;
}

export const LeftBlock: React.FC<Props> = ({
  searchTypes,
  query,
  handleSearchButtonClick,
  updateQueryFromKeyboard,
  updateQueryFromSuggestion,
}) => {
  const [focusedBlock, setFocusedBlock] = React.useState<'keyboard' | 'suggestions'>('keyboard');

  const { data: suggestions, isLoading: isLoadingSuggestions } = useSuggestions(query, searchTypes);
  const uniqSuggestions = useMemo(() => {
    if (query.length === 0) {
      return [];
    }
    return uniqBy<{ value: string }>(suggestions, 'value')
      .filter((s) => s.value.toLowerCase() !== query.toLowerCase())
      .slice(0, 4);
  }, [suggestions, query]);

  return (
    <FocusableBlock
      isFocused
      noNeedForceFocus
    >
      <FocusableBlock
        isFocused={ focusedBlock === 'keyboard' }
        onKeyDown={ (e) => {
          if (uniqSuggestions.length > 0) {
            setFocusedBlock('suggestions');
            e.stop();
          }
        } }
        onForceFocus={ () => setFocusedBlock('keyboard') }
        isPointerDownAvailable={ uniqSuggestions.length > 0 }
      >
        <KeyBoard
          keyBoardType={ KeyBoardType.type0 }
          keyBoardLayout={ KeyBoardLayout.Ru }
          handleSearchButtonClick={ handleSearchButtonClick }
          query={ query }
          setQuery={ updateQueryFromKeyboard }
        />
      </FocusableBlock>
      <FocusableBlock
        isFocused={ focusedBlock === 'suggestions' }
        onKeyUp={ (e) => {
          setFocusedBlock('keyboard');
          e.stop();
        } }
        onForceFocus={ () => setFocusedBlock('suggestions') }
        isPointerUpAvailable
      >
        <div className={ styles.suggestionsMargin }>
          <SuggestionBlock
            isLoadingSuggestions={ isLoadingSuggestions }
            query={ query }
            uniqSuggestions={ uniqSuggestions }
            setQuery={ (newQuery) => {
              updateQueryFromSuggestion(newQuery);
              setFocusedBlock('keyboard');
            } }
          />
        </div>
      </FocusableBlock>
    </FocusableBlock>
  );
};
