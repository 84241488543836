import * as cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';


type Props = Readonly<{
  className?: string;
  name: string;
  withFullInfo: boolean;
}>;


const SceneItemName: React.FC<Props> = (props: Props) => (
  <div
    className={
      cn(styles.name, props.className, {
        [styles.fullDescription]: props.withFullInfo,
      })
    }
  >
    { props.name }
  </div>
);


export default SceneItemName;
