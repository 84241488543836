import { FocuserKeyHandler } from '@focuser';
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';

import EMPTY_ARRAY from '~lib/constants/emptyArray';
import { Nullable } from '~lib/type-utils/utils';

export interface Props {
  blockHeight?: number;
  heightUpdate: any[];
  scrollStepPx?: number;
}

const DEFAULT_STEP_DIVIDER = 5;

export interface useScrollFocuserProps {
  /**
   * Массив обновлений, которые оповещают этот хук о том, что изменилась высота блока, и нужно пересчитать
   */
  heightUpdates?: unknown[];
  /**
   * Шаг скролла в пикселях
   * По умолчанию считается как высота wrapper-а, разделенная на 5
   */
  scrollStepPx?: number;

  /**
   * Сколько должна длится анимация перелистывания
   * @default 200
   */
  animationDurationInMS?: Nullable<number>;
}

export const useScrollFocuser = ({
  heightUpdates = EMPTY_ARRAY,
  scrollStepPx: scrollStepPxFromProps,
  animationDurationInMS = 200,
} : useScrollFocuserProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [contentHeight, setContentHeight] = React.useState(0);
  const [wrapperHeight, setWrapperHeight] = React.useState(0);

  const [offsetPx, setOffsetPx] = React.useState(0);


  /**
   * Запоминаем высоту блоков
   */
  useEffect(() => {
    setContentHeight(contentRef.current?.clientHeight ?? 0);
    setWrapperHeight(wrapperRef.current?.clientHeight ?? 0);
  }, [...heightUpdates]);


  /**
   * Максимальное смещение, которое может получиться блок
   */
  const maxOffsetPx = useMemo(() => {
    return Math.max(0, contentHeight - wrapperHeight);
  }, [contentHeight, wrapperHeight]);

  /**
   * Шаг скролла в пикселях
   */
  const scrollStep = useMemo(() => {
    return scrollStepPxFromProps ?? wrapperHeight / DEFAULT_STEP_DIVIDER;
  }, [scrollStepPxFromProps, wrapperHeight]);

  /**
   * Стили для блока, который нужно скроллить
   */
  const scrollStyle = useMemo(() => {
    return {
      transform: `translate3d(0, -${offsetPx}px, 0)`,
      WebkitTransform: `translate3d(0, -${offsetPx}px, 0)`,
    };
  }, [offsetPx]);

  /**
   * Процент смещения
   * От 0 до 1
   */
  const scrollPercent = useMemo(() => {
    if (!maxOffsetPx) return 0;
    return Math.min(Math.max(offsetPx / maxOffsetPx, 0), 1);
  }, [offsetPx, maxOffsetPx]);

  // При смене стилей блока, нужно обновить смещение
  useLayoutEffect(() => {
    if (!contentRef.current) return;
    contentRef.current.style.transform = scrollStyle.transform;
    contentRef.current.style.webkitTransform = scrollStyle.WebkitTransform;
  }, [scrollStyle]);

  // Если maxOffset обновился, то нужно проверить текущее смещение
  useLayoutEffect(() => {
    if (offsetPx > maxOffsetPx) {
      setOffsetPx(maxOffsetPx);
    }
  }, [maxOffsetPx]);

  useLayoutEffect(() => {
    if (!contentRef.current) {
      return;
    };

    if(!animationDurationInMS) {
      contentRef.current.style.removeProperty('transitionDuration');
      return;
    }
    contentRef.current.style.transitionDuration = `${animationDurationInMS}ms`;
  }, [animationDurationInMS]);

  const onKeyUp: FocuserKeyHandler = (e) => {
    if (offsetPx <= 0) {
      return;
    }

    setOffsetPx(Math.max(0, offsetPx - scrollStep));
    e.stop();
  };

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (offsetPx >= maxOffsetPx) {
      return;
    }

    setOffsetPx(Math.min(maxOffsetPx, offsetPx + scrollStep));
    e.stop();
  };

  return {
    /**
     * ref для wrapper-а, в котором будет содержаться контент
     * Его высота будет областью прокрутки
     */
    wrapperRef,
    /**
     * ref для элемента, который будет скролиться.
     * Этот элемент будет получать стили. И именно он будет двигаться вверх/вниз.
     * Это должен быть блок контента
     */
    contentRef,
    /**
     * Можно ли показывать Pointer наверх, для фокусера
     */
    isPointerDownAvailable: offsetPx < maxOffsetPx,
    /**
     * Можно ли показывать Pointer вниз, для фокусера
     */
    isPointerUpAvailable: offsetPx > 0,
    /**
     * Хендлер кнопки вниз, для фокусера
     */
    onKeyDown,
    /**
     * Хендлер кнопки вверх, для фокусера
     */
    onKeyUp,
    /**
     * Текущий процент прокрутки, от 0 до 1
     */
    scrollPercent,
    /**
     *  Доступен ли скролл. Если нет, то нет смысла показывать стрелки, скроллбар
     */
    isScrollAvailable: maxOffsetPx > 0,
  };
};
