// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkboxLine--tdcJN {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n     -moz-box-pack: start;\n          justify-content: flex-start;\n  cursor: pointer;\n  width: 100%;\n}\n\n.checkbox--RFBa4 {\n  /* 20px */\n  margin-right: 1.5625vw;\n}\n\n.text--CeZIx {\n  color: rgba(223, 223, 228, 1);\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/ui-kit/Table_items/Account_items/CheckboxLine/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,uBAA2B;EAA3B,mCAA2B;KAA3B,oBAA2B;UAA3B,2BAA2B;EAC3B,eAAe;EACf,WAAW;AACb;;AAEA;EACE,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,6BAAa;AACf","sourcesContent":[".checkboxLine {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  cursor: pointer;\n  width: 100%;\n}\n\n.checkbox {\n  /* 20px */\n  margin-right: 1.5625vw;\n}\n\n.text {\n  color: $gw_08;\n}\n"],"sourceRoot":""}]);
// Exports
export var checkboxLine = "checkboxLine--tdcJN";
export var checkbox = "checkbox--RFBa4";
export var text = "text--CeZIx";
export default ___CSS_LOADER_EXPORT___;
