import focuser, { useFocuserContext } from '@focuser';
import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import { useInfinityProgramEvents } from '~hooks/fetch/useProgramEvents/useProgramEvents';
import { useCurrentEventInInfinity } from '~hooks/useCurrentEventInInfinity';
import { CHANNEL_FLIPPER_NO_SCHEDULE } from '~lib/localizator/src/dictionaries';
import { EPGItemWrapper } from '~newapp/ui/EPGItemWrapper';
import Blackout from '~typings/Blackout';
import Channel from '~typings/Channel';
import ProgramEvent from '~typings/Event';

import { EventsListFocusedState } from '../../EPGPageCommon.types';
import { EPGDayHeader } from './components/EPGDayHeader';
import { EPGEventsListSlider } from './components/EPGEventsListSlider';
import { EPGEventsSkeleton } from './components/EPGEventsSkeleton';
import { EPGStickyDayHeader } from './components/EPGStickyDayHeader';
import {
  SliderRenderArrItem,
  useEPGEventsListNavigation,
  useEPGEventsListOnClick,
  useEPGEventsListPagination,
  useEPGEventsSlider,
  useRenderState,
} from './EPGEventsList.hooks';
import * as styles from './EPGEventsList.module.css';

export type Props = {
  channel: Channel;
  blackouts?: Blackout[];
  isEventsReady: boolean;
  handleEventsIsReady: () => void;
  focusedState: EventsListFocusedState | null;
  setFocusedState: (state: EventsListFocusedState) => void;
  beforeLeave: (params: { event: ProgramEvent; pagePivotDate: string }) => void;
  intl: InjectedIntl;
};

const EPGEventsList$: React.FC<Props> = ({
  channel,
  handleEventsIsReady,
  isEventsReady,
  focusedState,
  setFocusedState,
  blackouts,
  beforeLeave,
  intl
}) => {

  const query = useInfinityProgramEvents(channel.id);

  const currentEvent = useCurrentEventInInfinity(query.data?.pages);

  const focuserProps = useEPGEventsListNavigation({
    pages: query.data?.pages,
    currentEvent,
    focusedState,
    setFocusedState
  });

  useEPGEventsListPagination({
    query,
    focusedState,
    handleEventsIsReady,
    isEventsReady
  });

  const { isFocused } = useFocuserContext();

  const renderChild = (child: SliderRenderArrItem, idx: number) => {
    if (child.object === 'programEventDateTitle') {
      return (
        <EPGDayHeader
          key={ child.pivotDate }
          className={ styles.weekDayHeader }
          pivotRawDate={ child.pivotRawDate }
          weekDayDataId={ child.pivotDate }
        />
      );
    }

    const event = child.event;
    return (
      <EPGItemWrapper
        key={ event.id }
        event={ event }
        isFocused={ isFocused && focusedState?.focusedEvent.id === event.id }
        channel={ channel }
        blackouts={ blackouts }
        className={ styles.epgItem }
        dataIdx={ idx }
      />
    );
  };

  const sliderProps = useEPGEventsSlider({
    pages: query.data?.pages,
    focusedState,
    renderChild,
  });

  const onClick = useEPGEventsListOnClick({
    focusedIdxForSlider: sliderProps.focusedIdxForSlider,
    renderArrForSlider: sliderProps.renderArrForSlider,
    blackouts: blackouts,
    channel,
    currentEvent,
    beforeLeave
  })

  const renderState = useRenderState({
    query,
    isEventsReady,
    focusedIdxForSlider: sliderProps.focusedIdxForSlider,
  });

  if (renderState === 'not-ready') {
    return <EPGEventsSkeleton channel={ channel } />;
  }

  const renderSlider = () => {
    return (
      <>
        {focusedState?.focusedPage.pivotRawDate && (
          <EPGStickyDayHeader
            className={ styles.stickyWeekDayHeader }
            focusedState={ focusedState }
            pages={ query.data?.pages }
          />
        )}
        <EPGEventsListSlider
          wrapperClassName={ styles.sliderWrapper }
          sliderClassName={ styles.slider }
          focusedIndex={ sliderProps.focusedIdxForSlider! }
          childsCount={ sliderProps.totalCount }
          renderChild={ sliderProps.renderSliderChild }
          stickSlideFromUpVW={ 12.65625 }
          maxInDOM={ 14 }
        />
      </>
    );
  };

  const renderEmptyList = () => {
    return (
      <Typography
        variant={ 'h5_bold' }
        className={ styles.emptyListMessage }
      >
        {intl.formatMessage({ id: CHANNEL_FLIPPER_NO_SCHEDULE })}
      </Typography>
    );
  };

  return (
    <focuser.FocusableBlock
      isFocused
      noNeedForceFocus
      className={ styles.mainWrapper }
      onClick={ onClick }
      isLastBlock
      { ...focuserProps }
    >
      <Typography
        variant={ 'h3_bold' }
        className={ styles.channelName }
      >
        {channel.name}
      </Typography>
      {renderState === 'no-data' ? renderEmptyList() : renderSlider()}
    </focuser.FocusableBlock>
  );
};

/**
 * Центральный блок с программами в EPG странице
 */
export const EPGEventsList = React.memo(injectIntl(EPGEventsList$));
