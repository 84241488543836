// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actorBlock--dLqsi {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n}\n\n.blockWrap--Obh9Z {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  margin-top: 1.563vw;\n  height: 18vw;\n  margin-bottom: 4vw;\n}\n\n.focused--CRCIO {\n  border: solid 4px rgba(243, 243, 247, 1);\n}\n\n.title--zJGmU {\n  font-size: 1.8vw;\n  font-weight: 500;\n  margin-top: 3.906vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemDetails/ActorsBlock/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;AACf;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,wCAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,gBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".actorBlock {\n  display: flex;\n}\n\n.blockWrap {\n  display: flex;\n  align-items: center;\n  margin-top: 1.563vw;\n  height: 18vw;\n  margin-bottom: 4vw;\n}\n\n.focused {\n  border: solid 4px $gw_10;\n}\n\n.title {\n  font-size: 1.8vw;\n  font-weight: $fw_5;\n  margin-top: 3.906vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var actorBlock = "actorBlock--dLqsi";
export var blockWrap = "blockWrap--Obh9Z";
export var focused = "focused--CRCIO";
export var title = "title--zJGmU";
export default ___CSS_LOADER_EXPORT___;
