import { useMutation } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import Operator from '~typings/Operator';
import ResponseSingle from '~typings/ResponseSingle';


const fetch = async (client, accessToken, invoiceID) => {
  if (!accessToken || !invoiceID) { return undefined; }

  return await client.post(
    '/v1/payments/promo_code',
    {
      access_token: accessToken,
      'invoice_id': invoiceID,
    }
  );
};


const usePromocodePayment = () => {
  const { accessToken } = getAuthData();

  const client = useClient();

  return useMutation<ResponseSingle<Operator>, void, string>(
    (invoiceID) => fetch(client, accessToken, invoiceID),
  );
};


export default usePromocodePayment;
