import React from 'react';

import { SpinnerJS } from '~newapp/globalBlocks/spinner/js/spinnerJS';

import * as css from './styles.module.css';

const SpinnerFullScreen = () => {
  return (
    <div className={ css.fullScreenSpinnerWrapper }>
      <SpinnerJS className={ css.fullScreenSpinner } />
    </div>
  );
};

export { SpinnerFullScreen };
