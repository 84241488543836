// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox--tSTv5 {\n  /* 30px */\n  width: 2.34375vw;\n\n  /* 30px */\n  height: 2.34375vw;\n  color: rgba(38, 38, 42, 1);\n  cursor: pointer;\n}\n\n.checked--RDzLD {\n  color: rgba(255, 255, 255, 1);\n}\n\n.focused--hBh2X {\n color: rgba(253, 216, 53, 1);\n}\n\n.focused--hBh2X.checked--RDzLD {\n  color: rgba(253, 216, 53, 1);\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/ui-kit/Other/Checkbox/styles.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gBAAgB;;EAEhB,SAAS;EACT,iBAAiB;EACjB,0BAAa;EACb,eAAe;AACjB;;AAEA;EACE,6BAAa;AACf;;AAEA;CACC,4BAAa;AACd;;AAEA;EACE,4BAAa;AACf","sourcesContent":[".checkbox {\n  /* 30px */\n  width: 2.34375vw;\n\n  /* 30px */\n  height: 2.34375vw;\n  color: $gw_02;\n  cursor: pointer;\n}\n\n.checked {\n  color: $gw_11;\n}\n\n.focused {\n color: $ac_00;\n}\n\n.focused.checked {\n  color: $ac_00;\n}\n"],"sourceRoot":""}]);
// Exports
export var checkbox = "checkbox--tSTv5";
export var checked = "checked--RDzLD";
export var focused = "focused--hBh2X";
export default ___CSS_LOADER_EXPORT___;
