import { findIndex } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { parse as parseURI } from 'url';

import PinCodePopup from '~components/PinCodePopup';
import ProfileEditorPopup from '~components/ProfileEditorPopup';
import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { AuthActionType, useAccount, useAuthMutation } from '~hooks/fetch/useAccount';
import { useMainPageID } from '~hooks/fetch/usePages/usePages';
import { useProfiles } from '~hooks/fetch/useProfiles';
import Profile from '~typings/Profile';

import ProfileLimitAlert from './ProfileLimitAlert';
import ProfileScrollWrapper from './ProfileScrollWrapper';


type Props = {
  isEditMode?: boolean;
};


const ProfileSwitcher: React.FC<Props> = (props) => {
  const {activeProfileId, authRedirectUrl} = useApp();
  const {data: accountInfo} = useAccount();
  const appAction = useAppAction();
  const history = useHistory();
  const mainPageID = useMainPageID();
  const profilesItems: Profile[] | undefined = useProfiles().data;
  const {mutate: mutateAuth} = useAuthMutation();
  const currentIndex = findIndex(profilesItems, ['id', activeProfileId]);

  const [editableProfileId, setEditableProfileId] = React.useState<string | null>(null);
  const [isShowPinPopup, setShowPinPopup] = React.useState<boolean>(false);
  const [ profileLimitPopupOpened,  setProfileLimitPopupOpened] = React.useState<boolean>(false);
  const selectedProfileIdRef = React.useRef<string>();

  const handleSwitchToProfile = async (newProfileId: string) => {
    await mutateAuth({
      type: AuthActionType.AuthSameAccount,
      payload: {userId: newProfileId},
    }, {
      onSuccess: () => {
        appAction({
          type: ActionType.SetActiveProfileId,
          payload: {activeProfileId: newProfileId},
        });
        appAction({
          type: ActionType.SetIsLaunchingDone,
          payload: {isLaunchingDone: false},
        });

        if (authRedirectUrl) {
          appAction({
            type: ActionType.SetAuthRedirectUrl,
            payload: {authRedirectUrl: null},
          });

          const parsed = parseURI(authRedirectUrl);

          history.replace({
            pathname: parsed.pathname || authRedirectUrl,
            search: (parsed.search) ? `${ parsed.search }&redirect=auth` : 'redirect=auth',
          });
        } else {
          history.replace(mainPageID ? `/page/${ mainPageID }` : '/');
        }

        setShowPinPopup(false);
      },
    });
  };

  const completeAction = () => {
    const selectedProfileId = selectedProfileIdRef.current;
    selectedProfileIdRef.current = undefined;

    if (!selectedProfileId) {
      setEditableProfileId(null);
      setShowPinPopup(false);
    } else if (props.isEditMode) {
      setEditableProfileId(selectedProfileId);
      setShowPinPopup(false);
    } else {
      handleSwitchToProfile(selectedProfileId);
    }
  };

  const handleProfileSelect = (selectedIndex: number) => {
    const currentProfile: Profile | undefined = profilesItems ? profilesItems[currentIndex] : undefined;
    const selectedProfile: Profile | undefined = profilesItems ? profilesItems[selectedIndex] : undefined;

    selectedProfileIdRef.current = selectedProfile?.id;

    if (
      accountInfo && accountInfo.parental_control && accountInfo.security_pin &&
      (!activeProfileId || currentProfile?.kids) && !selectedProfile?.kids
    ) {
      setShowPinPopup(true);
    } else {
      completeAction();
    }
  };

  const handleAddNewProfile = () => {
    if(profilesItems && profilesItems.length >= 5) {
      return setProfileLimitPopupOpened(true);
    }
    setEditableProfileId(''); 
  };

  // Automatic profile selection if it is the only one
  React.useEffect(() => {
    if (!!accountInfo && profilesItems && profilesItems.length === 1) {
      handleProfileSelect(0);
    }
  }, [accountInfo, profilesItems]);

  if(profileLimitPopupOpened) { 
    return (
      <ProfileLimitAlert 
        onClick={ (evt) => {
          evt.stop();
          evt.stopNativeEvent();
          setProfileLimitPopupOpened(false)
        } }
      />
    )
  }

  if(editableProfileId !== null) {
    return (
      <ProfileEditorPopup
        profileId={ editableProfileId }
        onBack={ () => setEditableProfileId(null) }
        onDelete={ () => setEditableProfileId(null) }
      />
    )
  }

  if(isShowPinPopup) {
    return (
      <PinCodePopup
        onSuccess={ completeAction }
        onBack={ () => setShowPinPopup(false) }
      />
    )
  }

  return (
    <ProfileScrollWrapper
      items={ profilesItems }
      onProfileItemClick={ handleProfileSelect }
      onCreateNewProfileClick={ handleAddNewProfile }
    />
  );
};


export default React.memo(ProfileSwitcher);
