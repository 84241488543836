import * as cn from 'classnames';
import { Moment } from 'moment';
import * as React from 'react';

import EPGPageEventsListScrollWrapper from '~components/EPG/Events/EventsScroll';
import EPGPageNavigation from '~components/EPG/navigation';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import usePointer from '~stores/Pointer';
import NavigationDirection from '~typings/NavigationDirection';

import EPGWeekDay from './day';
import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  activeIndex: number;
  items: Moment[];
  onSelect: (index: number) => void;
  onLeave: (direction: NavigationDirection) => void;
}>;


const EPGWeekDays: React.FC<Props> = (props: Props) => {
  // show/hide pointer navigation
  const isPointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);
  const flush = usePointer(state => state.flush);

  React.useEffect(() => () => {
    flush([
      NavigationDirection.Up,
      NavigationDirection.Right,
      NavigationDirection.Down,
      NavigationDirection.Left,
    ]);
  }, [])
  React.useEffect(() => {
    if (props.isFocused && isPointerEnabled) {
      updateDirections({
        [NavigationDirection.Up]: (props.activeIndex !== 0),
        [NavigationDirection.Down]: (props.activeIndex < (props.items.length - 1)),
        [NavigationDirection.Left]: false,
        [NavigationDirection.Right]: true,
      });
    }
  }, [
    props.items.length,
    props.activeIndex,
    props.isFocused,
    isPointerEnabled,
  ]);
  const [focusedIndex, setFocusedIndex] = React.useState(() => props.activeIndex);
  const handleDateChanged = React.useCallback((index) => {
    props.onSelect(index);
    setFocusedIndex(index);
  }, []);

  const isFocused = (index: number): boolean => (props.isFocused && focusedIndex === index);
  const isActive = (index: number): boolean => (props.activeIndex === index);
  const renderItem = (item, index) => (
    <EPGWeekDay
      key={ item.format('x') }
      isFocused={ isFocused(index) }
      isActive={ isActive(index) }
      item={ item }
      index={ index }
      onClick={ handleDateChanged }
    />
  );
  const renderItems = props.items.map(renderItem);
  const renderNavigation = props.isFocused &&
    <EPGPageNavigation
      isFocused
      initialFocusIndex={ focusedIndex }
      total={ props.items.length }
      throttleTimeInMS={ 300 }
      onLeave={ props.onLeave }
      onIndexChange={ setFocusedIndex }
    />;
  const renderContent = React.createElement(
    'div',
    {
      className: cn(styles.weekDays, {
        [styles.weekDaysFocused]: props.isFocused,
      } )
    },
    <EPGPageEventsListScrollWrapper
      focusedIndex={ focusedIndex }
      offsetShift={  valueInPixelsByWidth(20.078) }
    >
      { renderItems }
    </EPGPageEventsListScrollWrapper>
  );

  return (
    <>
      { renderNavigation }
      { renderContent }
    </>
  );
};


export default React.memo(EPGWeekDays);
