// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".message--wJSET {\n  font-size: 1.40625vw;\n  width: 53.438vw;\n}\n\n.button--Yi04A {\n  font-size: 1.25vw;\n  margin-top: 3.125vw;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/PersonalPage/PersonalPageUnauthorized/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,iBAAuB;EACvB,mBAAmB;EACnB,0BAAkB;EAAlB,uBAAkB;EAAlB,kBAAkB;AACpB","sourcesContent":[".message {\n  font-size: $fontSub2_vw;\n  width: 53.438vw;\n}\n\n.button {\n  font-size: $fontBody_vw;\n  margin-top: 3.125vw;\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
export var message = "message--wJSET";
export var button = "button--Yi04A";
export default ___CSS_LOADER_EXPORT___;
