import React from 'react';

import { SpinnerJS } from '~newapp/globalBlocks/spinner/js/spinnerJS';

import * as css from './styles.module.css';

const SpinnerIcon = () => {
  return (
      <SpinnerJS className={ css.spinnerIcon } />
  );
};

export { SpinnerIcon };
