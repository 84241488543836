import { FocuserClickHandler, FocuserKeyHandler } from '@focuser';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Props as DropdownSelectPopupProps } from '~components/DropdownSelectPopup';
import { useLocationSearchState } from '~hooks/useLocationSearchState';
import DropdownFilter, { Option } from '~typings/DropdownFilter';
import QuickFilter from '~typings/QuickFilter';
import {
  SelectedDropdownFilter,
  SelectedFilters,
  SelectedQuickFilter,
} from '~typings/SelectedFilters';

import { AnyFilter, DropdownPopupState, DropdownPoupOpenState } from './FiltersLine.types';

type UseFiltersLineNavigationProps = {
  isResetBtnActive: boolean;
  filters: AnyFilter[];
};

export const useFiltersLineNavigation = ({
  isResetBtnActive,
  filters,
}: UseFiltersLineNavigationProps) => {
  const [focusOn, setFocusOn] = useState<'reset' | 'pick'>(isResetBtnActive ? 'reset' : 'pick');
  const [focusedIdx, setFocusedIdx] = useState(0);

  useEffect(() => {
    if (focusOn === 'reset') {
      if (!isResetBtnActive) {
        setFocusOn('pick');
        return;
      }
    }

    if (focusedIdx > filters.length - 1) {
      setFocusedIdx(Math.max(0, filters.length - 1));
    }
  }, [filters]);

  const sliderFocusedIdx = focusOn === 'reset' ? 0 : focusedIdx + 1;

  const onKeyRight: FocuserKeyHandler = (e) => {
    if (focusOn === 'reset') {
      e.stop();
      setFocusOn('pick');
      return;
    }

    if (focusedIdx >= filters.length - 1) return;
    setFocusedIdx(focusedIdx + 1);
    e.stop();
  };

  const onKeyLeft: FocuserKeyHandler = (e) => {
    if (focusOn === 'reset') {
      return;
    }

    if (focusedIdx > 0) {
      setFocusedIdx(focusedIdx - 1);
      e.stop();
      return;
    }

    if (isResetBtnActive) {
      setFocusOn('reset');
      e.stop();
      return;
    }
  };

  const isPointerLeftAvailable = focusedIdx > 0;
  const isPointerRightAvailable = focusedIdx < filters.length - 1;

  return {
    sliderFocusedIdx,
    setFocusOn,
    focusOn,
    focusedIdx,
    setFocusedIdx,
    focuserProps: {
      onKeyLeft,
      onKeyRight,
      isPointerLeftAvailable,
      isPointerRightAvailable,
    },
  };
};

type UseFiltersLineHandlersProps = {
  onChangeFilters: (filters: SelectedFilters) => void;
  selectedFilters: SelectedFilters;
  onDropdownPopupOpen: (newState: DropdownPoupOpenState) => void;
};
export const useFiltersLineHandlers = ({
  onChangeFilters,
  selectedFilters,
  onDropdownPopupOpen,
}: UseFiltersLineHandlersProps) => {
  const onToggleQuickFilter = useCallback(
    (filter: QuickFilter) => {
      const isSelected = selectedFilters[filter.id];
      if (isSelected) {
        const copy = { ...selectedFilters };
        delete copy[filter.id];
        onChangeFilters(copy);
        return;
      }

      onChangeFilters({
        ...selectedFilters,
        [filter.id]: {
          type: filter.object,
          filter,
        },
      });
    },
    [onChangeFilters, selectedFilters],
  );

  const onSelectDropdownFilter = useCallback(
    (filter: DropdownFilter, selectedOptions: DropdownFilter['options']) => {
      if (selectedOptions.length === 0) {
        const copy = { ...selectedFilters };
        delete copy[filter.id];
        onChangeFilters(copy);
        return;
      }

      onChangeFilters({
        ...selectedFilters,
        [filter.id]: {
          type: filter.object,
          filter,
          selectedOptions,
        },
      });
    },
    [onChangeFilters, selectedFilters],
  );

  const makeOnclickForFilterButton = (
    filter: QuickFilter | DropdownFilter,
  ): FocuserClickHandler => {
    return (e) => {
      e.stop();

      if (filter.object === 'quick_filter') {
        onToggleQuickFilter(filter);
      } else {
        onDropdownPopupOpen({
          isOpen: true,
          filter,
        });
      }
    };
  };

  return {
    onToggleQuickFilter,
    onSelectDropdownFilter,
    makeOnclickForFilterButton,
  };
};

export type UseFiltersLineDropdownPoupProps = {
  dropdownPopupState: DropdownPopupState;
  setDropdownPopupState: (newState: DropdownPopupState) => void;
  selectedFilters: SelectedFilters;
  onSelectDropdownFilter: (
    filter: DropdownFilter,
    selectedOptions: DropdownFilter['options'],
  ) => void;
};
export const useFiltersLineDropdownPoup = ({
  dropdownPopupState,
  setDropdownPopupState,
  selectedFilters,
  onSelectDropdownFilter,
}: UseFiltersLineDropdownPoupProps) => {
  const dropdownSelectPopupProps: DropdownSelectPopupProps<Option['value']> | null = useMemo(() => {
    if (!dropdownPopupState.isOpen) {
      return null;
    }

    const closeDropdownPopup = () => {
      setDropdownPopupState({ isOpen: false });
    };
    const filter = dropdownPopupState.filter;
    const filterInSelected = selectedFilters[filter.id] as SelectedDropdownFilter | undefined;

    return {
      header: filter.name,
      options: getOptionsForDropdownFilter(filter, selectedFilters),
      initialSelectedOptions: filterInSelected?.selectedOptions || [],
      isMultiselect: filter.multiselect,
      onApply: (selectedOptions) => {
        closeDropdownPopup();
        onSelectDropdownFilter(filter, selectedOptions);
      },
      onCancel: closeDropdownPopup,
    };
  }, [dropdownPopupState, selectedFilters, onSelectDropdownFilter]);

  return {
    dropdownSelectPopupProps,
  };
};

/**
 * Метод возвращает опции, которые можно выбрать в попапе с фильтрами.
 * Если фильтр для годов, то оставляет в нем только доступные года.
 * Для других фильтров возвращает все опеции
 */
const getOptionsForDropdownFilter = (filter: DropdownFilter, selectedFilters: SelectedFilters) => {
  if (filter.api_param === 'year_gteq') {
    // Выбран фильтр "Year from", фильтруем только возможные года
    // Находим выбранный фильтр "Year to"
    const yearToInSelected = Object.values(selectedFilters).find(
      (f): f is SelectedDropdownFilter =>
        f.type === 'dropdown_filter' && f.filter.api_param === 'year_lteq',
    );
    // Если нет, то возвращаем все возможные года
    if (!yearToInSelected) {
      return filter.options;
    }

    // Иначе фильтруем только до выбранного года
    const yearTo = parseInt(yearToInSelected.selectedOptions[0]?.value, 10) || Infinity;

    return filter.options.filter((option) => parseInt(option.value, 10) <= yearTo);
  }

  // Дальше тоже самое, но наоборот
  if (filter.api_param === 'year_lteq') {
    const yearFromInSelected = Object.values(selectedFilters).find(
      (f): f is SelectedDropdownFilter =>
        f.type === 'dropdown_filter' && f.filter.api_param === 'year_gteq',
    );

    if (!yearFromInSelected) {
      return filter.options;
    }

    const yearTo = parseInt(yearFromInSelected.selectedOptions[0]?.value, 10) || Infinity;

    return filter.options.filter((option) => parseInt(option.value, 10) >= yearTo);
  }

  return filter.options;
};

/**
 * Метод возвращает имя для кнопки, которая будет показана в линии.
 * Если это год - покажет выбрнный год.
 * Иначе покажет имя фильтра
 */
export const getFilterName = (
  filter: QuickFilter | DropdownFilter,
  filterInSelected: SelectedDropdownFilter | SelectedQuickFilter | undefined,
) => {
  if (
    filter.object === 'quick_filter' ||
    filterInSelected?.type === 'quick_filter' ||
    !filterInSelected
  ) {
    return filter.name;
  }

  if (filter.api_param === 'year_lteq' || filter.api_param === 'year_gteq') {
    const year = filterInSelected.selectedOptions[0]?.name;
    if (year) {
      return year;
    }
  }

  return filter.name;
};

type RememberedFilters = {
  [key: string]:
    | {
        type: SelectedQuickFilter['type'];
      }
    | {
        type: SelectedDropdownFilter['type'];
        selectedOptions: SelectedDropdownFilter['selectedOptions'];
      };
};

const REMEMBERE_FILTERS_PATH = 'remembered_filters_of_filters_line';

/**
 * Хук который запоминает выбранные фильтры в url
 * */
export const useRememberFilters = (selectedFilters: SelectedFilters) => {

  const {
    setNewState: rememberFilters,
  } = useLocationSearchState<RememberedFilters>({
    pathInSearch: REMEMBERE_FILTERS_PATH,
  });

  // Запоминание фильтров в url-е
  useEffect(() => {
    const filtersToRemember: RememberedFilters = {};

    Object.entries(selectedFilters).forEach(([key, value]) => {
      if (value.type === 'quick_filter') {
        filtersToRemember[key] = {
          type: value.type,
        };
        return;
      }
      filtersToRemember[key] = {
        type: value.type,
        selectedOptions: value.selectedOptions,
      };
    });

    rememberFilters(selectedFilters);
  }, [selectedFilters]);
};

/**
 * Хук, который возвращает запомненные фильтры из url
 */
export const useRestoreFilters = (filters: (QuickFilter | DropdownFilter)[] | undefined) => {
  const {
    parsedState: rememberedFilters,
    clearState: clearRememberedFilters,
  } = useLocationSearchState<RememberedFilters>({
    pathInSearch: REMEMBERE_FILTERS_PATH,
  });

  const restoredFilters = useMemo(() => {
    if (!rememberedFilters) {
      return;
    }

    if (!filters?.length) {
      return;
    }

    const restoredFilters: SelectedFilters = {};

    Object.entries(rememberedFilters).forEach(([key, value]) => {
      if (value.type === 'quick_filter') {
        const quickFilter = filters.find((filter) => filter.id === key) as QuickFilter | undefined;
        if (!quickFilter) {
          return;
        }
        restoredFilters[key] = {
          type: value.type,
          filter: quickFilter,
        };
        return;
      }

      const dropdownFilter = filters.find((filter) => filter.id === key) as
        | DropdownFilter
        | undefined;

      if (!dropdownFilter) {
        return;
      }

      const optioNames = value.selectedOptions.map((x) => x.name);

      restoredFilters[key] = {
        type: value.type,
        filter: dropdownFilter,
        selectedOptions: dropdownFilter.options.filter((option) =>
          optioNames.includes(option.name),
        ),
      };
    });

    return restoredFilters;
  }, []);

  // Восстановление фильтров из url-а
  useEffect(() => {
    if (!rememberedFilters) {
      return;
    }

    clearRememberedFilters();
  }, []);

  return restoredFilters;
};
