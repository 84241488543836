import focuser, { FocuserKeyHandler } from '@focuser';
import * as React from 'react';

import NavigationDirection from '~typings/NavigationDirection';

import RadioListItem from './RadioListItem';

type Props = Readonly<{
  items: string[],
  isFocused: boolean;
  selectedId?: number;
  ignoreOpenedPopup?: boolean;
  onSelect: (id: number) => void;
  onLeave?: (dir: NavigationDirection) => void;
  className?: string;
}>;

export const RadioList: React.FC<Props> = (props: Props) => {
  const [focusedIndex, setFocusedIndex] = React.useState<number>(props.selectedId ?? 0);

  const handleClickDown: FocuserKeyHandler = (evt) => {
    if (focusedIndex >= props.items.length - 1) return;
    setFocusedIndex(focusedIndex + 1);
    evt.stop();
  }

  const handleClickUp: FocuserKeyHandler = (evt) => {
    if (focusedIndex === 0) return;
    setFocusedIndex(focusedIndex - 1);
    evt.stop();
  }

  return (
    <focuser.FocusableBlock
      isFocused
      className={ props.className }
      onKeyDown={ handleClickDown }
      onKeyUp={ handleClickUp }
    >
      {
        props.items.map((item, id) => (
          <focuser.FocusableBlock
            isFocused={ props.isFocused && focusedIndex === id }
            onClick={ () => props.onSelect(id) }
            isLastBlock
            key={ `select_list_item_${id}` }
          >
            {({ isFocused }) => (
              <RadioListItem
                item={ { name: item, isChecked: id === props.selectedId } }
                isFocused={ isFocused }
              />
            )}
          </focuser.FocusableBlock>
        ))
      }
    </focuser.FocusableBlock>
  )
};
