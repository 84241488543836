import * as React from 'react';
import { useHistory } from 'react-router-dom';

import useIsNeedRestartApp from '~hooks/useIsNeedRestartApp';


const RestartAppListener: React.FC = () => {
  const history = useHistory();
  const needRestartApp = useIsNeedRestartApp();

  React.useEffect(() => {
    if (needRestartApp) {
      history.length = 0;
      history.push('/');

      document.location.reload();
    }
  }, [needRestartApp]);

  return null;
};

export default RestartAppListener;
