// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup--H25rq {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n     -moz-box-orient: vertical;\n     -moz-box-direction: normal;\n          flex-direction: column;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n\n  /* 124px 110px 200px 110px */\n  padding: 9.6875vw 8.59375vw 15.625vw 8.59375vw;\n}\n\n\n.title--DFw8R {\n  /* 77px */\n  margin-bottom: 6.015625vw;\n  font-weight: 500;\n  font-size: 1.875vw;\n  color: rgba(255, 255, 255, 1);\n  line-height: 2.2859375vw;\n}\n\n.error--spOZj {\n  color: rgba(255, 85, 85, 1);\n}\n\n", "",{"version":3,"sources":["webpack://./src/app/components/InputWithPopup/popups/TextInputPopup.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,4BAAsB;EAAtB,6BAAsB;EAAtB,8BAAsB;KAAtB,yBAAsB;KAAtB,0BAAsB;UAAtB,sBAAsB;EACtB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;;EAEnB,4BAA4B;EAC5B,8CAA8C;AAChD;;;AAGA;EACE,SAAS;EACT,yBAAyB;EACzB,gBAAkB;EAClB,kBAAqB;EACrB,6BAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,2BAAc;AAChB","sourcesContent":[".popup {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  /* 124px 110px 200px 110px */\n  padding: 9.6875vw 8.59375vw 15.625vw 8.59375vw;\n}\n\n\n.title {\n  /* 77px */\n  margin-bottom: 6.015625vw;\n  font-weight: $fw_5;\n  font-size: $fontH5_vw;\n  color: $gw_11;\n  line-height: 2.2859375vw;\n}\n\n.error {\n  color: $adc_01;\n}\n\n"],"sourceRoot":""}]);
// Exports
export var popup = "popup--H25rq";
export var title = "title--DFw8R";
export var error = "error--spOZj";
export default ___CSS_LOADER_EXPORT___;
