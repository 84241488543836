import { useApp } from '~components/Provider/App';
import { usePlayerState } from '~components/Provider/Player';


type StreamVariables = Readonly<{
  language: string;
}>;


const usePrepareStreamVariables = (): StreamVariables => {
  const { language: appLanguage } = useApp();
  const { audioLanguage } = usePlayerState();

  return ({
    language: (audioLanguage || appLanguage),
  });
};


export {
  usePrepareStreamVariables,
};
