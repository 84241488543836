import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import { WHAT_IS_AN_SUBSCRIPTION } from '~localization';
import Button from '~ui/button';

import * as styles from './styles.module.css';


type Props = {
  isFocused: boolean;
  handleGoToProduct: () => void;
  intl: InjectedIntl;
}

const WhatInButton: React.FC<Props> = (props) => {
  return (
    <Button
      isFocused={ props.isFocused }
      className={ cn(styles.button, {
        [styles.isFocused]: props.isFocused,
      }) }
      onClick={ props.handleGoToProduct }
    >
      { props.intl.formatMessage({ id: WHAT_IS_AN_SUBSCRIPTION }) }
    </Button>
  );
};


export default WhatInButton;
