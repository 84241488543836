import { get } from 'lodash';
import * as React from 'react';

import { ErrorFallbackProps } from './types';

export const ErrorInfo: React.FC<ErrorFallbackProps> = (props) => {
  const message = get(props, 'error.message');
  const stack = get(props, 'error.stack');
  const info = get(props, 'info.componentStack');

  return (
    <div>
      <h3>Error</h3>
      <div>
        {message && <div>{message}</div>}
        {stack && <div>{stack}</div>}
        {info && (
          <div>
            <em>{info}</em>
          </div>
        )}
      </div>
    </div>
  );
};
