import { QueryKey } from 'react-query';

import { useClient } from '~global';
import { LIMIT } from '~hooks/fetch/constants';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import {
  getCachedDataAllPagedItems,
  getCachedDataPagedItems,
  getLastFetchedPagePagedItems,
  usePagedItems,
} from '~hooks/fetch/template';
import { getAuthData } from '~hooks/fetch/useAccount';
import {
  getDropdownFiltersOptions,
  getFiltersKeyString,
  getQuickFiltersString,
  SearchFilters,
} from '~hooks/fetch/useSearch/useSearch.helpers'
import ApiClient from '~lib/ApiClient';
import deleteCacheWithOldAccessTokens from "~lib/ReactQuery/deleteCacheWithOldAccessTokens";
import { DataItem } from '~typings/DataItem';
import ResponseMany from '~typings/ResponseMany';

type FetchParams = Readonly<{
  page: number;
  queryKey: QueryKey;
  client: ApiClient;
  collectionID?: string;
  filters?: SearchFilters;
}>;

const PREFIX_OF_A_COMPOSITE_KEY = 'collection_items';

const getKeyWithoutPage = (collectionID?: string, filters: SearchFilters = {}): QueryKey => ([
  PREFIX_OF_A_COMPOSITE_KEY,
  getAuthData().accessToken,
  collectionID,
  getFiltersKeyString(filters),
]);

const getKeyWithoutAccessToken = (): QueryKey => ([
  PREFIX_OF_A_COMPOSITE_KEY,
]);

const getKey = (collectionID?: string, filters: SearchFilters = {}, page: number = 0): QueryKey => ([
  getKeyWithoutPage(collectionID, filters), page
]);

const getCachedCollectionItems = (collectionID: string, filters: SearchFilters = {}) => (
  getCachedDataAllPagedItems<DataItem>(getKeyWithoutPage(collectionID, filters))
);

const getLastFetchedPage = (collectionID: string, filters?: SearchFilters): number => (
  getLastFetchedPagePagedItems<DataItem>(getKeyWithoutPage(collectionID, filters))
);

const fetch = async (params: FetchParams): Promise<ResponseMany<DataItem[]>> => {
  const cacheData = getCachedDataPagedItems<DataItem>(params.queryKey);

  if (cacheData) { return cacheData; }

  let options = {
    ...fetchOptionsBasic[FetchType.CollectionItems],
    'filter[collection_id_eq]': params.collectionID,
    'page[offset]': (params.page * LIMIT),
    'page[limit]': LIMIT,
    access_token: getAuthData().accessToken,
  };

  if (params.filters?.quickFilters) {
    options['filter[quick_filter_ids_include]'] = getQuickFiltersString(params.filters.quickFilters);
  }
  if (params.filters?.dropdownFilters) {
    options = {
      ...options,
      ...getDropdownFiltersOptions(params.filters.dropdownFilters),
    }
  }

  deleteCacheWithOldAccessTokens(getKeyWithoutAccessToken(), 1);

  return await params.client.get(
    fetchURLs[FetchType.CollectionItems],
    options,
  );
};

const useCollectionItems = (collectionID?: string, filters: SearchFilters = {}, pg = 0) => {
  const client = useClient();
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetch({
    page,
    client,
    queryKey,
    collectionID,
    filters,
  });

  return usePagedItems<DataItem>({
    page: pg,
    fetch: fetchTemplate,
    queryKey: getKey(collectionID, filters, pg),
    config: {
      enabled: !!collectionID,
      notifyOnChangeProps: ['data', 'isLoading'],
    },
  });
};


export {
  fetch,
  getCachedCollectionItems,
  getKey,
  getKeyWithoutPage,
  getLastFetchedPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};

export default useCollectionItems;
