import { useMutation } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import Invoice from '~typings/Invoice';
import ResponseSingle from '~typings/ResponseSingle';


const fetch = async ({ client, accessToken, planID, resourceID, planType, paymentMethodType, promocode }) => {
  if (!accessToken || !planID) { return undefined; }

  return await client.post(
    '/v3/payments/invoices',
    {
      access_token: accessToken,
      'plan_id': planID,
      'payment_method_type': paymentMethodType,
      'resource_id': resourceID,
      'plan_type': planType,
      'promo_code': promocode,
    }
  );
};

type Args = {
  planID: string;
  resourceID?: string;
  planType: string;
  promocode?: string;
  paymentMethodType?: string;
};

const useRentInvoice = () => {
  const { accessToken } = getAuthData();

  const client = useClient();

  return useMutation<ResponseSingle<Invoice>, void, Args>(
    ({ planID, resourceID, planType, paymentMethodType, promocode }) =>
      fetch({ client, accessToken, planID, resourceID, planType, paymentMethodType, promocode }),
  );
};


export default useRentInvoice;
