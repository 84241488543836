import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { getInitialFocusedIndex } from "~components/EPG/Channels/component";
import EPGPageChannelsRender from '~components/EPG/Channels/Render';
import useVerticalList from "~components/EPG/VerticalScroll/useVerticalList";
import { usePlayerState } from '~components/Provider/Player';
import useFetchCurrentEvent, { getCachedCurrentEvent } from '~hooks/fetch/useCurrentEvent/useCurrentEvent';
import useDelayedChange from '~hooks/useDelayedChange';
import useSwitchChannelsList from '~hooks/useSwitchChannel/channels';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import usePointer from '~stores/Pointer';
import ProgramEvent from '~typings/Event';
import NavigationDirection from '~typings/NavigationDirection';


type Props = Readonly<{
  isFocused: boolean;
  channelID: string;
  onChangeCurrentEvent: (event: ProgramEvent | null) => void;
  onLeave: (direction: NavigationDirection) => void;
  onClose: () => void;
}>;


const ChannelsWithCurrentEvent: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { item } = usePlayerState();
  const channels = useSwitchChannelsList() || EMPTY_ARRAY;
  const [initialFocusedIndex] = React.useState<number>(
    Math.max(getInitialFocusedIndex(props.channelID, props.isFocused, history, channels), 0)
  );
  const total = channels.length;
  const [focusedIndex, setFocusedIndex] = React.useState<number>(initialFocusedIndex);
  const rightFocusedIndex = (total + (focusedIndex % total)) % total;
  const delayedFocusedIndex = useDelayedChange<number | null>(rightFocusedIndex, 800);
  const focusedChannelID = React.useMemo(() => channels[rightFocusedIndex]?.id, [rightFocusedIndex]);
  const isNeedFetchEvents = (focusedChannelID && rightFocusedIndex === delayedFocusedIndex);
  const currentEvent = getCachedCurrentEvent(focusedChannelID);
  const delayedCurrentEvent = useDelayedChange(currentEvent?.data[0], 1000);
  // show/hide pointer navigation
  const isPointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);
  const flush = usePointer(state => state.flush);

  const handleClick = (channel) => {
    props.onClose();

    if (item?.id !== channel.id) {
      history.replace(`/media-item/${channel.object}/${channel.id}?player=true`);
    }
  };

  useFetchCurrentEvent(isNeedFetchEvents ? focusedChannelID : undefined);

  React.useEffect(() => () => {
    flush([
      NavigationDirection.Up,
      NavigationDirection.Right,
      NavigationDirection.Down,
      NavigationDirection.Left,
    ])
  }, []);
  React.useEffect(() => {
    props.onChangeCurrentEvent(delayedCurrentEvent || null);
  }, [delayedCurrentEvent?.id]);

  React.useEffect(() => {
    if (props.isFocused && isPointerEnabled) {
      updateDirections({
        [NavigationDirection.Up]: channels.length >= 12 ? true : (focusedIndex !== 0),
        [NavigationDirection.Down]: channels.length >= 12 ? true : (focusedIndex < (channels.length - 1)),
        [NavigationDirection.Left]: false,
        [NavigationDirection.Right]: true,
      });
    }
  }, [
    channels.length,
    focusedIndex,
    props.isFocused,
    isPointerEnabled,
  ]);

  useVerticalList({
    total: channels.length >= 12 ? Infinity : channels.length,
    minIndex: channels.length >= 12 ? -Infinity : 0,
    initialFocusedIndex,
    allowNavigation: props.isFocused,
    onIndexChange: setFocusedIndex,
    onLeave: props.onLeave,
    
  });

  return React.useMemo(() => (
    <EPGPageChannelsRender
      withCurrentEvent
      isNeedFetchEvents={ !!isNeedFetchEvents }
      isFocused={ props.isFocused }
      focusedIndex={ focusedIndex }
      items={ channels }
      total={ channels.length }
      onClick={ handleClick }

      // onLeave={ props.onLeave }
      // onIndexChange={ setFocusedIndex }
    />
  ), [
    !!isNeedFetchEvents,
    props.isFocused,
    focusedIndex,
    (channels || []).length,
  ]);
};


export default React.memo(ChannelsWithCurrentEvent, (p: Props, n: Props) => (
  n.channelID !== p.channelID
  && n.isFocused !== p.isFocused
));
