import { version } from '~lib/env-utils';


const getAppVersion = (): string => {
  const regExp = /^\?version=(.+)&/g;

  try {
    const appVersionFromURL = regExp.exec(document.location.search);

    if (
      appVersionFromURL
      && Array.isArray(appVersionFromURL)
      && appVersionFromURL.length > 1
      && appVersionFromURL[1]
      && appVersionFromURL[1]['version']
    ) {
      return appVersionFromURL[1]['version'];
    } else {
      return version
    }
  } catch (ignore) {
    return version;
  }
};


export default getAppVersion;
