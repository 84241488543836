import React from "react";

import { SpinnerJS } from "~newapp/globalBlocks/spinner/js/spinnerJS";

import * as css from './styles.module.css'

const SpinnerGlobalCSS = () => {
  return <SpinnerJS className={ css.globalSpinner }/>
}

export { SpinnerGlobalCSS }
