import * as cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';

type Props = Readonly<{
  position: number;
  isFocused?: boolean;
  thumbHeight?: number;
  className?: string;
  animationDurationInMS?: number;
}>;

const defaultProps = {
  position: 0,
  thumbHeight: 32,
};

const ScrollBar: React.FC<Props> = (props: Props) => {
  props = {
    ...defaultProps,
    ...props
  };

  const { isFocused = true } = props;

  const trackRef = React.useRef<HTMLDivElement>(null);
  const trackHeight = trackRef.current?.getBoundingClientRect().height || 0;
  const position = Math.max(
    props.position * (trackHeight - (props.thumbHeight || 0)),
    0,
  );

  const thumbTransitionDuration = props.animationDurationInMS ? {
    transitionDuration: `${props.animationDurationInMS}ms`,
    WebkitTransitionDuration: `${props.animationDurationInMS}ms`,
  } : {};

  const thumbStyle = {
    height: `${props.thumbHeight || 0}px`,
    transform: `translate3d(0, ${position}px, 0)`,
    WebkitTransform: `translate3d(0, ${position}px, 0)`,
    ...thumbTransitionDuration,
  };

  return (
    <div className={ cn([styles.scrollbar, props.className]) }>
      <div className={ styles.track } ref={ trackRef }>
        <div className={ cn(styles.thumb, { [styles.available]: isFocused }) } style={ thumbStyle } />
      </div>
    </div>
  )
};

export default ScrollBar;
