import * as React from 'react';

import { useApp } from '~components/Provider/App';
import useBlocks from '~hooks/useBlocks';
import { PageInfo } from '~hooks/usePageInfo';
import Page from '~typings/Page';

import BlockPageWithHooks from './componentWithHooks';
import useBlockPageManager from './Manager';

type Props = Readonly<{
  page: Page;
  pageInfo: PageInfo;
  isPopupOpened: boolean;
}>;

const BlockPage: React.FC<Props> = (props: Props) => {
  const { page } = props;
  const { isLaunchingDone } = useApp();
  const { isFetchingDone, blocks } = useBlocks(page);

  useBlockPageManager({ page, blocks, isFetchingDone });

  if (!isLaunchingDone || !isFetchingDone) { return null; }

  if (!blocks || blocks.length === 0) { return null; }

  return (
    <BlockPageWithHooks
      page={ props.page }
      pageInfo={ props.pageInfo }
      blocks={ blocks }
      isPopupOpened={ props.isPopupOpened }
    />
  )
};

export default BlockPage;
