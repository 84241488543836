import PlayerAccessError from '~typings/PlayerStatus';

import { Events } from '../common';
import { VastErrors } from '../VastErrors';
import VideoError from '../VideoError';


const onError = (event, err) => {
  if (err === PlayerAccessError.CannotPlayStream) {
    event.emit(
      Events.ERROR,
      PlayerAccessError.CannotPlayStream,
    );

    return;
  }

  const nativeError = { nativeError: err.target.error };

  switch (err.target.error.code) {
    case err.target.error.MEDIA_ERR_ABORTED: {
      const message = 'You aborted the video playback.';
      const vastError = VastErrors.PROBLEM_DISPLAYING_MEDIA_FILE;
      event.emit(
        Events.ERROR,
        new VideoError({ ...nativeError, message, vastError }),
      );
      break;
    }

    case err.target.error.MEDIA_ERR_NETWORK: {
      const message = 'PLAYER_ERROR_CONNECTION_FAILED';
      event.emit(
        Events.ERROR,
        new VideoError({ ...nativeError, message, vastError: VastErrors.TRAFFICKING_ERROR }),
      );
      break;
    }

    case err.target.error.MEDIA_ERR_DECODE: {
      const message = 'The video playback was aborted due to a corruption ' +
        'problem or because the video used features your browser did not support.';
      const vastError = VastErrors.PROBLEM_DISPLAYING_MEDIA_FILE;

      event.emit(
        Events.ERROR,
        new VideoError({ ...nativeError, message, vastError }),
      );
      break;
    }

    case err.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED: {
      const message = 'The video could not be loaded, either because ' +
        'the server or network failed or because the format is not supported.';

      event.emit(
        Events.ERROR,
        new VideoError({ ...nativeError, message, vastError: VastErrors.TRAFFICKING_ERROR }),
      );
      break;
    }

    default:
      event.emit(
        Events.ERROR, new VideoError({
          nativeError: err, message: `unknown error: ${err}`, vastError: VastErrors.UNDEFINED_ERROR,
        }));
  }
};


export default onError;
