import * as React from 'react';
import { InjectedIntl,injectIntl } from 'react-intl';

import InputWithKeyboard, { FocusOn } from '~components/InputWithKeyboard';
import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { useConfig } from '~global';
import { AuthActionType, PinActionType, useAuthMutation, useResetPasswordMutation, useSecurityPinMutation } from '~hooks/fetch/useAccount';
import usePassword from '~hooks/usePassword';
import { newFieldValue } from '~lib/keyboard';
import { AUTH_ENTER_PASSWORD_TO_LOGIN, INVALID_PASSWORD_FORMAT } from '~localization';
import { KeyBoardKey, KeyBoardType } from '~typings/Keyboard';
import NavigationDirection from '~typings/NavigationDirection';

import { getMessageByError } from '../helpers';
import { AuthType } from '../types';


type PasswordFormProps = Readonly<{
  username: string;
  isFocused?: boolean;
  authType: AuthType;
  code?: string;

  onSuccess: (password?: string) => void;
  onError?: (errorMessage: string, error?: any) => void;
  onProcessingStatus: (isProcessing: boolean) => void;
  onLeave: (direction: NavigationDirection) => void;

  intl: InjectedIntl;
}>;

const PasswordForm: React.FC<PasswordFormProps> = (props: PasswordFormProps) => {
  const { isNeedToResetPin } = useApp();
  const appAction = useAppAction();
  const { mutate: securityPinAction } = useSecurityPinMutation();
  const { mutateAsync: mutateAuth } = useAuthMutation();
  const { mutateAsync: changePassword } = useResetPasswordMutation();
  const { smartTV: { minPasswordLength } } = useConfig();
  const [ password, setPassword ] = React.useState<string>('');
  const [isShowingPassword, setIsShowingPassword] = React.useState(false);
  const escapedPassword = usePassword({
    password,
    isShowingPassword,
  });

  const handleValueChange = React.useCallback((key: KeyBoardKey) => {
    setPassword(oldValue => newFieldValue(oldValue, key))
  }, []);

  const handleClearClick = React.useCallback(() => {
    setPassword('');
  }, []);

  const handleEyeClick = React.useCallback(() => {
    setIsShowingPassword(!isShowingPassword);
  }, [isShowingPassword]);

  const resetPassComplete = (password) => {
    if (!isNeedToResetPin) {
      return props.onSuccess(password);
    }

    securityPinAction({
      type: PinActionType.Removal,
      payload: { password }
    }, {
      onSettled: () => {
        appAction({
          type: ActionType.SetNeedToResetPin,
          payload: { isNeedToResetPin: false }
        });
        props.onSuccess(password);
      }
    });
  }

  const handleFormSubmit = async () => {
    if (password.length < minPasswordLength) {
      if (props.onError) return props.onError(
        props.intl.formatMessage({ id: INVALID_PASSWORD_FORMAT })
      );
    }

    if (
      props.authType === AuthType.SignIn ||
      props.authType === AuthType.PasswordReset
    ) {
      props.onProcessingStatus(true);

      if (props.authType === AuthType.PasswordReset) {
        const resp = await changePassword({
          username: props.username,
          password,
          code: props.code
         }, {
           onError: (error) => {
            if (!props.onError) return;
            const messageText = getMessageByError(error, props.intl.formatMessage);
            props.onError(messageText, error);
           }
         });

        if (resp.meta.status !== 200) return;
      }

      await mutateAuth({
        type: AuthActionType.AuthByPassword,
        payload: { username: props.username, password }
      }, {
        onSuccess: () => resetPassComplete(password),
        onError: (error) => {
          if (!props.onError) return;
          const messageText = getMessageByError(error, props.intl.formatMessage);
          props.onError(messageText, error);
        },
        onSettled: () => props.onProcessingStatus(false),
      });
    } else if (props.authType === AuthType.SignUp) {
      props.onSuccess(password);
    }
  };

  return (
    <InputWithKeyboard
      inputValue={ escapedPassword }
      focusOn={ props.isFocused ? FocusOn.KeyBoard : null }
      placeholder={ props.intl.formatMessage({ id: AUTH_ENTER_PASSWORD_TO_LOGIN }) }
      keyBoardType={ KeyBoardType.Password }
      onLeave={ props.onLeave }
      onChange={ handleValueChange }
      onClear={ handleClearClick }
      onSubmit={ handleFormSubmit }
      onEyeClick={ handleEyeClick }
    />
  );
};


export default injectIntl(PasswordForm);
