import { useMutation } from 'react-query';

import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';


export type ConfirmationsResult = Readonly<{
  data?: null;
  errors?: {
    object: 'error';
    code: 'invalid_confirmation_code';
  }[];
  meta: {
    status: number;
  };
}>;

const fetch = async (
  client: ApiClient,
  username: string,
  code: string,
): Promise<ConfirmationsResult> => client.post(
  '/v1/users/confirmations.json',
  {
    username,
    code,
  },
);

export const useConfirmMutation = () => {
  const client = useClient();

  return useMutation(({ username, code }: { username: string, code: string }) => (
    fetch(client, username, code)
  ));
}

export default useConfirmMutation;
