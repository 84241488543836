import settings from 'customization-project-name/settings.json';

import buildAppId from '~lib/buildAppId';
import Enviroment from '~typings/Enviroment';

const API = window.__PROJECT__?.API || settings.apiProduction;
const ENV = window.__PROJECT__?.ENV || Enviroment.Production;
const version = window.__PROJECT__?.VERSION || '0.0.1';

const api: string =
  (API === Enviroment.Production && settings.apiProduction) ||
  (API === Enviroment.Development && settings.apiDevelopment) ||
  API;
const appId = buildAppId({
  project: __PROJECT_NAME__,
  platform: __PLATFORM__,
  env: ENV,
  inPackage: true,
});

const googleAnalyticsId = ENV === Enviroment.Production ? settings.googleIdProduction : settings.googleIdDevelopment;

export { api, appId, ENV as env, googleAnalyticsId,version };
