import * as cn from 'classnames';
import * as React from 'react';

import IconBackward from './icons/backward';
import IconForward from './icons/forward';
import IconPause from './icons/pause';
import IconPlay from './icons/play';
import * as style from './styles.module.css';


export enum PlayButtonState {
  Play = 'play',
  Pause = 'pause',
  SeekToForward = 'seek_to_forward',
  SeekToBackward = 'seek_to_backward',
}


type Props = Readonly<{
  state: PlayButtonState;
  isFocused: boolean;
  onClick: () => void;
}>;


const PlayPauseButton: React.FC<Props> = (props: Props): JSX.Element => {
  const renderIcon = React.useMemo(() => {
    switch (props.state) {
      case PlayButtonState.Pause: {
        return (<IconPlay />);
      }
      case PlayButtonState.Play: {
        return (<IconPause />);
      }
      case PlayButtonState.SeekToForward: {
        return (<IconForward />);
      }
      case PlayButtonState.SeekToBackward: {
        return (<IconBackward />);
      }
      default: return null;
    }
  }, [props.state]);

  return (
    <div
      className={ cn(style.playPauseButton, {
        [style.focused]: props.isFocused,
        'focusedNavigationNode': props.isFocused,
      }) }
      onClick={ props.onClick }
    >
      { renderIcon }
    </div>
  );
};


export default React.memo(PlayPauseButton);
