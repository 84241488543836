import { FocuserClickHandler, FocuserKeyHandler } from '@focuser';
import { useFocuserTimeoutHandler } from '@focuser/helpers';
import { useEffect, useMemo, useState } from 'react';

import { SearchFilters } from '~hooks/fetch/useSearch/useSearch.helpers';
import { useLocationSearchState } from '~hooks/useLocationSearchState';
import CardType from '~typings/Card';

import { GRID_FOCUSED_SEARCH_PARAM, ROW_IDX } from './CardCollectionGrid.utils';

export type FocusedState = {
  columnIdx: number;
  rowIdx: number;
};

export type UseDropdownSelectNavigationProps = {
  cardsByChunks: CardType[][];
  selectedFilters?: SearchFilters;
  restoreFocusFromUrl?: boolean;
  beforeFocusRestore?: (focusedStateToBe: FocusedState)=> void
};

const UP_DOWN_DELAY = 100;

export const useGridNavigation = ({
  cardsByChunks,
  selectedFilters,
  restoreFocusFromUrl,
  beforeFocusRestore,
}: UseDropdownSelectNavigationProps) => {
  const [focusedState, setFocusedState] = useState<FocusedState>({ columnIdx: 0, rowIdx: 0 });

  useEffect(() => {
    setFocusedState({ columnIdx: 0, rowIdx: 0 });
  }, [selectedFilters]);

  const { parsedState, clearState, setNewState } = useLocationSearchState<FocusedState>({
    pathInSearch: GRID_FOCUSED_SEARCH_PARAM,
  });

  useEffect(() => {
    if (!restoreFocusFromUrl || !parsedState) {
      return;
    }
    if (cardsByChunks[parsedState.rowIdx]?.[parsedState.columnIdx]) {
      beforeFocusRestore?.(parsedState);
      setFocusedState(parsedState);
    }
    clearState();
  }, []);



  const nextStateOnDown = useMemo(() => {
    const { columnIdx, rowIdx } = focusedState;

    // На последней строке некуда больше идти
    if (rowIdx >= cardsByChunks.length - 1) {
      return null;
    }

    // Следующая строка
    const nextRowIdx = rowIdx + 1;
    // Кол-во карточек в следующей строке
    const nextRowCardsCount = cardsByChunks[rowIdx + 1]?.length;

    // Индекс карточки в следующей строке
    const nextColumnIdx = Math.min(columnIdx, nextRowCardsCount - 1);

    return { rowIdx: nextRowIdx, columnIdx: nextColumnIdx };
  }, [focusedState, cardsByChunks]);

  const nextStateOnUp = useMemo(() => {
    const { columnIdx, rowIdx } = focusedState;
    if (rowIdx > 0) {
      return { columnIdx, rowIdx: rowIdx - 1 };
    }
    return null;
  }, [focusedState]);

  const nextStateOnRight = useMemo(() => {
    const { rowIdx, columnIdx } = focusedState;
    if (columnIdx < cardsByChunks[rowIdx]?.length - 1) {
      return { columnIdx: columnIdx + 1, rowIdx };
    }
    return null;
  }, [focusedState, cardsByChunks]);

  const nextStateOnLeft = useMemo(() => {
    const { rowIdx, columnIdx } = focusedState;
    if (columnIdx <= 0) {
      return null;
    }
    return {
      columnIdx: columnIdx - 1,
      rowIdx,
    };
  }, [focusedState]);

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (!nextStateOnDown) {
      return;
    }
    e.stop();
    setTimeout(() => setFocusedState(nextStateOnDown), 0);
  };

  const onKeyUp: FocuserKeyHandler = (e) => {
    if (!nextStateOnUp) {
      return;
    }
    e.stop();
    setTimeout(() => setFocusedState(nextStateOnUp), 0);
  };

  const onKeyRight: FocuserKeyHandler = (e) => {
    if (!nextStateOnRight) {
      return;
    }
    setFocusedState(nextStateOnRight);
    e.stop();
  };

  const onKeyLeft: FocuserKeyHandler = (e) => {
    if (!nextStateOnLeft) {
      return;
    }
    setFocusedState(nextStateOnLeft);
    e.stop();
  };

  const onKeyReturn: FocuserKeyHandler = (e) => {
    if (focusedState.rowIdx > 1) {
      e.stop();
      setTimeout(() => setFocusedState({ columnIdx: 0, rowIdx: 0 }), 0);
    }
  };

  const isPointerUpAvailable = Boolean(nextStateOnUp);

  const isPointerDownAvailable = Boolean(nextStateOnDown);

  const isPointerRightAvailable = Boolean(nextStateOnRight);

  const isPointerLeftAvailable = Boolean(nextStateOnLeft);

  const onKeyUpThrottled = useFocuserTimeoutHandler(onKeyUp, UP_DOWN_DELAY);
  const onKeyDownThrottled = useFocuserTimeoutHandler(onKeyDown, UP_DOWN_DELAY);
  const isOnFirstRow = focusedState.rowIdx === 0
  const isOnLastRow = focusedState.rowIdx === cardsByChunks.length - 1;


  return {
    focusedState,
    rememberFocusedStateInUrl: setNewState,
    setFocusedState,
    isPointerUpAvailable,
    isPointerDownAvailable,
    isPointerRightAvailable,
    isPointerLeftAvailable,
    onKeyDown: onKeyDownThrottled,
    onKeyUp: onKeyUpThrottled,
    onKeyRight,
    onKeyLeft,
    onKeyReturn,
    returnButtonType: focusedState.rowIdx > 1 ? ('back' as const) : undefined,
    isOnFirstRow,
    isOnLastRow
  };
};

type UseFocuserClikcHandlerProps = {
  focusedState: FocusedState;
  cardsByChunks: CardType[][];
  clickHandler: (card: CardType) => void;
};

export const useFocuserClickHandler = ({
  focusedState,
  cardsByChunks,
  clickHandler,
}: UseFocuserClikcHandlerProps) => {
  const handleRemoteClick = () => {
    const clickedCard = cardsByChunks[focusedState.rowIdx]?.[focusedState.columnIdx];
    if (clickedCard) {
      clickHandler(clickedCard);
    }
  };

  const handleMouseClick = (e: MouseEvent) => {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    let clickedCardIdx = null as null | {
      rowIdx: number;
      columnIdx: number;
    };
    let currentEl = e.target;

    while (!clickedCardIdx) {
      if (!currentEl) {
        break;
      }

      const parent = currentEl.parentElement;

      if (!parent) {
        break;
      }

      if (parent.dataset[ROW_IDX]) {
        const rowIdx = Number(parent.dataset[ROW_IDX]);
        const columnIdx = Array.from(parent.children).indexOf(currentEl);
        clickedCardIdx = {
          rowIdx,
          columnIdx,
        };
        break;
      }
      currentEl = parent;
    }

    if (!clickedCardIdx) {
      return;
    }

    if (isNaN(clickedCardIdx.rowIdx) || clickedCardIdx.columnIdx < 0) {
      return;
    }

    const clickedCard = cardsByChunks[clickedCardIdx.rowIdx]?.[clickedCardIdx.columnIdx];

    if (clickedCard) {
      clickHandler(clickedCard);
    }
  };

  return ((e) => {
    if (e.isPhysicalMouseClick) {
      handleMouseClick(e.nativeEvent as MouseEvent);
    } else {
      handleRemoteClick();
    }
  }) as FocuserClickHandler;
};
