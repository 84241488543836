import create, { SetState } from 'zustand';

export interface State {
  focusHistory: {
    [key: string]: {
      [key: string]: {
        [key: string]: any
      }
    }
  },
  setFocusHistory: (pageId: string, blockId: string, update) => void
}

export const NewLayerNavigation = create<State>(
  (set: SetState<State>) => ({
    focusHistory: {},
    setFocusHistory: (pageId, blockId, update) => set(store => {
      if (!store.focusHistory.hasOwnProperty(pageId)) {
        store.focusHistory[pageId] = {}
      }
      if (!store.focusHistory[pageId].hasOwnProperty(blockId)) {
        store.focusHistory[pageId][blockId] = {}
      }
      store.focusHistory[pageId][blockId] = { ...store.focusHistory[pageId][blockId], ...update }
    }),
  })
);