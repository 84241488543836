import { get, isArray } from 'lodash';
import { xml2json } from 'xml-js';

import { ManifestParser } from '~lib/player/utils/manifest-parser';


const parseQualityLevelItem = (item) => {
  const attributes = get(item, '_attributes', null);

  if (attributes !== null) {
    const width = parseInt(get(attributes, 'MaxWidth', ''), 10);
    const height = parseInt(get(attributes, 'MaxHeight', ''), 10);
    const bitrate = parseInt(get(attributes, 'Bitrate', ''), 10);

    return ({
      attributes: {
        RESOLUTION: {
          width: !isNaN(width) ? width : null,
          height: !isNaN(height) ? height : null,
        },
        BANDWIDTH: !isNaN(bitrate) ? bitrate : null,
      },
    });
  }

  return null;
};

const parseManifestMSS = (manifestText: string): ManifestParser | null => {
  try {
    const manifestObj = JSON.parse(xml2json(manifestText, { compact: true }));
    const playlists: any[] = [];
    (
      get(manifestObj, 'SmoothStreamingMedia.StreamIndex', [])
        .filter((item) => (get(item, '_attributes.Type', '').toLowerCase() === 'video')) || []
    ).forEach((rootItem) => {
      const items = get(rootItem, 'QualityLevel', []);

      if (isArray(items)) {
        items.forEach((item) => {
          const parsed = parseQualityLevelItem(item);
          if (parsed !== null) {
            playlists.push(parsed);
          }
        });
      } else if (typeof items === 'object') {
        const parsed = parseQualityLevelItem(items);
        if (parsed !== null) {
          playlists.push(parsed);
        }
      }
    });

    return ({
      playlists,
    });
  } catch (ignore) {
    // ignore
    // console.log('ignore:', ignore);
    return null;
  }
};


export default parseManifestMSS;
