import {flatten, includes, map, some, startsWith, union} from 'lodash';

import ItemObject from '~typings/ItemObject';


const getAllowedLinks = ({pages, deepLinks}): string[] => {
  const allowedLinks = union(deepLinks, flatten(map(pages, ({uid, subpages, object}) => {
    const contentPage = (includes(['content_page', 'navigation_page'], object)) ?
                        'content-page/' : '';

    if (subpages && subpages.length) {
      return [
        `${contentPage}${uid}`,
        ...map(subpages, ({uid: subpageUid}) => (`${contentPage}${uid}/${subpageUid}`)),
      ];
    }

    return `${contentPage}${uid}`;
  })));

  return allowedLinks;
};

const getDeepLinkURL = (deeplink: string | null): string | null => (
  deeplink !== null
  ? deeplink.slice(deeplink.indexOf('//') + 2, deeplink.length)
  : null
);

const isBannerSupported = ({banner, allowedLinks}): boolean => {
  if (!banner || !banner.targets) {
    return false;
  }

  const {deeplink} = banner;

  if (deeplink === null) {
    return true;
  }

  const url = getDeepLinkURL(deeplink);

  if (url === null) {
    return false;
  }

  return some(allowedLinks, pattern => includes(pattern, url) || startsWith(url, pattern));
};

const deepLinkToItemObject = {
  channels: ItemObject.Channel,
  movies: ItemObject.Movie,
  series: ItemObject.Series,
  episodes: ItemObject.Episode,
  news: ItemObject.News,
  'audio-shows': ItemObject.AudioShow,
  audio_shows: ItemObject.AudioShow,
  program_events: ItemObject.ProgramEvent,
  parts: ItemObject.Part,
};

const getObjectAndID = (url: string): { id: string; object: ItemObject } => ({
  id: url.split('/')[1] as string,
  object: deepLinkToItemObject[url.split('/')[0]],
});


const isCollection = (url: string): boolean => {
  const probablyObject = (url.split('/')[0] || '');
  return (probablyObject === ItemObject.Collections);
};

const isMediaItem = (url: string): boolean => {
  const probablyObject = (url.split('/')[0] || '');
  return (Object.keys(deepLinkToItemObject).includes(probablyObject));
};

const isProduct = (url: string): boolean => {
  const probablyObject = (url.split('/')[0] || '');
  return (probablyObject === 'products');
};

function isPage(deepLink: string): boolean {
  const isPage = deepLink.split('/')
  return isPage[0] === 'pages'
}

export {
  getAllowedLinks,
  getDeepLinkURL,
  getObjectAndID,
  isBannerSupported,
  isCollection,
  isMediaItem,
  isPage,
  isProduct};
