import { throttle } from 'lodash';
import * as React from 'react';

import useNavigationByKeys from '~hooks/useNavigation';
import NavigationDirection from '~typings/NavigationDirection';


export type Props = {
  isAllowedNavigation: boolean;
  throttleWaitValue: number;
  onNavigate: (direction: NavigationDirection) => void;
};

const immediateTime = 100;

const useDirectionalNavigation: React.FC<Props> = (props: Props, update: any[]) => {
  const {
    onNavigate = () => {},
    throttleWaitValue,
  } = props;
  const isImmediateNavigation = React.useRef<boolean>(true);

  const throttledHandler = React
    .useCallback(throttle(onNavigate, throttleWaitValue, { trailing: false }), update);

  const immediateHandler = React
    .useCallback(throttle(onNavigate, immediateTime, { trailing: false }), update);

  const handleKeyNavigate = (direction): void => {
    if (props.isAllowedNavigation) {
      if (isImmediateNavigation.current) {
        immediateHandler(direction);
      } else {
        throttledHandler(direction);
      }

      isImmediateNavigation.current = false;
    }
  };


  useNavigationByKeys({
    isMounted: props.isAllowedNavigation,
    onNavigation: handleKeyNavigate,
  }, [props.isAllowedNavigation, ...update]);

  React.useEffect(() => {
    let timeout: any | null = null;

    const handleKeyUp = () => {
      isImmediateNavigation.current = true;

      timeout = setTimeout(() => {
        isImmediateNavigation.current = false;
      }, throttleWaitValue);
    };

    window.addEventListener('keyup', handleKeyUp);

    return (() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      window.removeEventListener('keyup', handleKeyUp);
    });
  }, []);

  return null;
};


export default useDirectionalNavigation;
