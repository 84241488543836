enum PlayerType {
  HTML5 = 'html5',
  Native = 'native',
  Shaka = 'shaka',
  Tizen = 'tizen',
  Webos = 'webos',
  Dash = 'dash',
  Hls = 'hls',
}


export default PlayerType;
