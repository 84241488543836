import { QueryKey,useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import deleteCacheWithOldAccessTokens from "~lib/ReactQuery/deleteCacheWithOldAccessTokens";
import Collection from '~typings/Collection';
import ResponseSingle from '~typings/ResponseSingle';


type SingleCollection = ResponseSingle<Collection>;

const PREFIX_OF_A_COMPOSITE_KEY = 'collections';

const getKey = (collectionID: string): QueryKey => ([
  PREFIX_OF_A_COMPOSITE_KEY,
  getAuthData().accessToken,
  collectionID,
]);

const getKeyWithoutAccessToken = (): QueryKey => ([
  PREFIX_OF_A_COMPOSITE_KEY,
]);


const getCachedCollection = (collectionID: string) => (
  queryClient.getQueryData<SingleCollection>(getKey(collectionID))
);


const fetch = async (collectionID: string, client: ApiClient) => {
  const cacheData = getCachedCollection(collectionID);

  if (cacheData) { return cacheData; }

  return await client.get(
    `${fetchURLs[FetchType.Collection]}/${collectionID}`,
    {
      ...fetchOptionsBasic[FetchType.Collection],
      access_token: getAuthData().accessToken,
    }
  );
};


const useCollection = (collectionID: string) => {
  const queryKey = getKey(collectionID);
  const client = useClient();

  deleteCacheWithOldAccessTokens(getKeyWithoutAccessToken(), 1);

  return useQuery<SingleCollection>({
    queryKey,
    queryFn: () => fetch(collectionID, client),
    enabled: !!collectionID,
  });
};


export {
  getCachedCollection,
  getKey,
  PREFIX_OF_A_COMPOSITE_KEY,
};

export default useCollection;
