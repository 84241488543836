// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".imageBackground--yMCJ9 {\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n.imageFull--rm5QF {\n  width: 100%;\n  height: 100%;\n}\n\n.imageWrapper--QXpTY {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  background-color: rgba(223, 223, 228, 1);\n}\n\n", "",{"version":3,"sources":["webpack://./src/app/ui/CardV2/components/CardCommonImage/CardCommonImage.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,wCAAwB;AAC1B","sourcesContent":[".imageBackground {\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n.imageFull {\n  width: 100%;\n  height: 100%;\n}\n\n.imageWrapper {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: $gw_08;\n}\n\n"],"sourceRoot":""}]);
// Exports
export var imageBackground = "imageBackground--yMCJ9";
export var imageFull = "imageFull--rm5QF";
export var imageWrapper = "imageWrapper--QXpTY";
export default ___CSS_LOADER_EXPORT___;
