import focuser, { FocuserKeyHandler } from '@focuser';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Typography } from '~app/ui-kit/Typography';
import {DeviceLine} from '~components/DeviceLine';
import {useGetDevice} from '~hooks/useGetDevices';
import { useLocationSearchState } from '~hooks/useLocationSearchState';
import { DEVICES_NOT_FOUND } from '~lib/localizator/src/dictionaries';
import { EditButtonFocuser } from '~newapp/ui-kit/Controls/Buttons/Icon_btn/Big_icon_btn/EditButton';
import { SliderVerticable } from '~ui/SliderVerticable';

import * as styles from './Devices.module.css';
import { DEVICE_IDX_PATH_IN_SEARCH } from './utils';

export const Devices = injectIntl(({intl}) => {

  const history = useHistory();
  const { data: devices = [] } = useGetDevice();
  const {
    parsedState: focusedIdxFromSearch,
    clearState: clearFocusedIdxInSearch,
    setNewState: setFocusedIdxInSearch,
  } = useLocationSearchState<number>({ pathInSearch: DEVICE_IDX_PATH_IN_SEARCH });

  const [focusedIdx, setFocusedIdx] = React.useState(focusedIdxFromSearch ?? 0);

  useEffect(() => {
    clearFocusedIdxInSearch();
  }, []);



  const handleUp: FocuserKeyHandler = (e) => {
    if (focusedIdx > 0) {
      setFocusedIdx(focusedIdx - 1);
      e.stop();
    }
  };

  const handleDown: FocuserKeyHandler = (e) => {
    if (focusedIdx < devices.length - 1) {
      setFocusedIdx(focusedIdx + 1);
      e.stop();
    }
  };



  const handleClick = (deviceId: string) => {
    setFocusedIdxInSearch(focusedIdx);
    history.push(`/device-edit/${deviceId}`);
  };

  if (!devices.length) {
    return (
      <Typography variant="h6_medium">{intl.formatMessage({ id: DEVICES_NOT_FOUND })}</Typography>
    );
  }

  return (
    <focuser.FocusableBlock
      isFocused
      noNeedForceFocus
      onKeyUp={ handleUp }
      onKeyDown={ handleDown }
      isPointerDownAvailable={ focusedIdx < devices.length - 1 }
      isPointerUpAvailable={ focusedIdx > 0 }
    >
      <SliderVerticable
        wrapperClassName={ styles.devicesSlider }
        focusedIndex={ focusedIdx }
      >
        {devices.map((userDevice, idx) => (
          <DeviceLine
            key={ userDevice.device.device_id }
            userDevice={ userDevice }
            button={
              <EditButtonFocuser
                isFocused={ focusedIdx === idx }
                emitForceFocusOnHover
                onForceFocus={ () => setFocusedIdx(idx) }
                onClick={ () => handleClick(userDevice.device.device_id) }
              />
            }
          />
        ))}
      </SliderVerticable>
    </focuser.FocusableBlock>
  );
});
