import * as React from 'react';

import { ResParsedManifest, TextTrack } from '~hooks/fetch/useTrackList';
import { ManifestParser } from '~lib/player/utils/manifest-parser';
import AudioTrack from '~typings/AudioTrack';
import Distribution from '~typings/Distribution';
import ItemObject from '~typings/ItemObject';
import { PrimitivePlayerAccess } from '~typings/PlayerAccess';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import PlayerAccessError from '~typings/PlayerStatus';
import Stream from '~typings/Stream';
import VideoTrack from '~typings/VideoTrack';


export enum PlayerAccessReason {
  Subscribed = 'subscribed',
  Rented = 'rented',
  NotPurchased = 'not_purchased',
  NotAuthorized = 'not_authorized',
  SubOnHold = 'subscription_on_hold',
  RestrictedByGeo = 'restricted_by_geo',
}

export enum ConfirmationStatus {
  WithoutConfirmation = 'withoutConfrim',
  PinCodeConfirmation = 'pinCodeConfirm',
  PolicyConfirmation = 'policyConfirm',
  AgeConfrimation = 'ageConfirm',
  NewPinCodeFlow = 'newPinCodeFlow'
}

export enum ActionType {
  SetItem,
  SetAudioTracks,
  SetAudioLanguage,
  SetParsedManifest,
  SetSubTitle,
  SetVideoTracks,
  Flush,
  SetAccess,
  SetError,
  SetStream,
  SetConfrimationRequired,
  SetLogger,
  SetPlayList
}

export interface Action {
  type: ActionType;
  item?: PlayerMediaItem;
  audioTracks?: AudioTrack[];
  audioLanguage?: string;
  subTitleTrack?: number;
  parsed?: ResParsedManifest;
  videoTracks?: VideoTrack[];
  access?: PlayerAccessError | PlayerAccessReason | null;
  error?: any;
  stream?: Stream | null;
  confirmationRequired?: ConfirmationStatus | null;
  logger?: boolean;
  playList?: string[] | [];
  fetchResponse?: any;
}

export type State = Readonly<{
  item: PlayerMediaItem | null;
  audioTracks: AudioTrack[];
  audioLanguage?: string;
  textTracks: TextTrack[];
  parsedManifest: ManifestParser | null;
  subTitleTrack?: TextTrack;
  videoTracks: VideoTrack[];
  access: PlayerAccessError | PlayerAccessReason | null;
  error: any;
  stream: Stream | null;
  confirmationRequired: ConfirmationStatus | null;
  logger: boolean;
  playList?: string[] | [];
  fetchResponse?: any;
}>;

export type Dispatch = (action: Action) => void;


export const INITIAL_STATE = {
  item: null,
  audioTracks: [],
  parsedManifest: null,
  textTracks: [],
  videoTracks: [],
  stream: null,
  access: null,
  error: null,
  confirmationRequired: null,
  logger: false,
  playList: [],
  fetchResponse: undefined,
};

export const PlayerContext = React.createContext<State>({} as State);
export const PlayerDispatchContext = React.createContext<Dispatch | undefined>(undefined);

export const usePlayerState = () => {
  const context = React.useContext(PlayerContext);

  if (context === undefined) {
    throw new Error('usePlayer must be used within a PlayerProvider')
  }
  return context;
};

export const usePlayerAction = () => {
  const context = React.useContext(PlayerDispatchContext);

  if (context === undefined) {
    throw new Error('usePlayerAction must be used within a PlayerProvider')
  }
  return context;
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SetItem: {
      if (action.item) {
        return ({
          ...INITIAL_STATE,
          audioLanguage: state.audioLanguage,
          logger: state.logger,
          item: action.item,
          playList: state.playList
        });
      }

      return state;
    }
    case ActionType.SetAudioTracks: {
      if (action.audioTracks) {
        return ({
          ...state,
          audioTracks: action.audioTracks,
        });
      }

      return state;
    }
    case ActionType.SetAudioLanguage: {
      if (action.audioLanguage) {
        return ({
          ...state,
          audioLanguage: action.audioLanguage,
        });
      }

      return state;
    }
    case ActionType.SetParsedManifest: {
      if (action.parsed) {
        return ({
          ...state,
          textTracks: action.parsed.trackList,
          parsedManifest: action.parsed.parsedManifest,
          fetchResponse: action.parsed.fetchResponse
        });
      }

      return state;
    }
    case ActionType.SetSubTitle: {
      if (state.textTracks.length > 0) {
        return ({
          ...state,
          subTitleTrack: action.subTitleTrack !== undefined ?
            state.textTracks[action.subTitleTrack]
            : undefined,
        });
      }

      return state;
    }
    case ActionType.SetVideoTracks: {
      if (action.videoTracks) {
        return ({
          ...state,
          videoTracks: action.videoTracks,
        });
      }

      return state;
    }
    case ActionType.Flush: {
      return ({
        ...INITIAL_STATE,
        audioLanguage: state.audioLanguage,
        logger: state.logger,
      });
    }
    case ActionType.SetAccess: {
      if (action.access !== undefined) {
        return ({
          ...state,
          access: action.access,
        });
      }

      return state;
    }
    case ActionType.SetError: {
      if (action.error !== undefined) {
        return ({
          ...state,
          error: action.error,
        });
      }

      return state;
    }
    case ActionType.SetStream: {
      if (action.stream !== undefined) {
        return ({
          ...state,
          stream: action.stream,
        });
      }

      return state;
    }
    case ActionType.SetConfrimationRequired: {
      if (action.confirmationRequired !== undefined) {
        return ({
          ...state,
          confirmationRequired: action.confirmationRequired,
        });
      }

      return state;
    }
    case ActionType.SetLogger: {
      if (action.logger !== undefined) {
        return ({
          ...state,
          logger: action.logger,
        });
      }

      return state;
    }
    case ActionType.SetPlayList: {
      if (action.playList) {
        return ({
          ...state,
          playList: action.playList,
        });
      }
      return state
    }
    default:
      return state;
  }
};


export const isItFreeContent = (item: PlayerMediaItem | null): boolean => {
  if (!item) return true;

  if (item.object === ItemObject.ProgramEvent) {
    return item.channel?.distribution === Distribution.Free;
  }

  if (
    item.object === ItemObject.Movie ||
    item.object === ItemObject.Channel ||
    item.object === ItemObject.Episode
  ) {
    return item.distribution === Distribution.Free;
  }

  return true;
}

export const resolvePlayerAccessStatus = (access: PrimitivePlayerAccess): PlayerAccessError | PlayerAccessReason => {
  if (access.allowed) {
    return PlayerAccessError.AccessAllowed;
  }
  if (access.reason === PlayerAccessReason.Rented) {
    return PlayerAccessReason.Rented;
  }
  if (access.reason === PlayerAccessReason.Subscribed) {
    return PlayerAccessReason.Subscribed;
  }
  if (access.reason === PlayerAccessReason.NotPurchased) {
    return PlayerAccessReason.NotPurchased;
  }
  if (access.reason === PlayerAccessReason.SubOnHold) {
    return PlayerAccessReason.SubOnHold
  }
  return PlayerAccessError.ErrorAccessDisallowed;
};

