import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import PageSwitcher from '~components/PageSwitcher/component';
import { PageInfo } from '~hooks/usePageInfo';


type Props = Readonly<{
  pageInfo: PageInfo;
}>;


const PageScreen$ = (props: Props) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={ path }>
        <div>404</div>
      </Route>
      <Route path={ `${ path }/:pageId` }>
        <PageSwitcher pageInfo={ props.pageInfo } />
      </Route>
    </Switch>
  );
};

export const PageScreen = PageScreen$
