import { QueryKey, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import deleteCacheWithOldAccessTokens from '~lib/ReactQuery/deleteCacheWithOldAccessTokens';
import CardCollection from '~typings/CardCollection';
import ResponseSingle from '~typings/ResponseSingle';


type SingleCardCollection = ResponseSingle<CardCollection>;

const PREFIX_OF_A_COMPOSITE_KEY = 'card_collection';

const getKey = (collectionID: string): QueryKey => ([
  PREFIX_OF_A_COMPOSITE_KEY,
  getAuthData().accessToken,
  collectionID,
]);

const getKeyWithoutAccessToken = (): QueryKey => ([
  PREFIX_OF_A_COMPOSITE_KEY,
]);


const getCachedCardCollection = (cardCollectionID: string) => (
  queryClient.getQueryData<SingleCardCollection>(getKey(cardCollectionID))
);


const fetch = async (cardCollectionID: string, client: ApiClient) => {
  deleteCacheWithOldAccessTokens(getKeyWithoutAccessToken(), 1);
  return await client.get(
    `${ fetchURLs[FetchType.CardCollections] }/${ cardCollectionID }`,
    {
      ...fetchOptionsBasic[FetchType.CardCollections],
      access_token: getAuthData().accessToken,
      'expand[card_collection]': 'search_filters_group.items'
    }
  );
};


const useCardCollection = (cardCollectionID?: string, enabled = true) => {
  const queryKey = getKey(cardCollectionID || '');
  const client = useClient();

  return useQuery<SingleCardCollection>({
    queryKey,
    queryFn: () => fetch(cardCollectionID!, client),
    enabled: !!cardCollectionID && enabled,
  });
};


export {
  fetch,
  getCachedCardCollection,
  getKey,
  PREFIX_OF_A_COMPOSITE_KEY,
};

export default useCardCollection;
