// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filters--ZiEPU {\n  position: relative;\n  width: 86.25vw;\n  height: 2.5vw;\n  will-change: transform;\n  -webkit-transition: -webkit-transform 500ms linear;\n  transition: -webkit-transform 500ms linear;\n  transition: transform 500ms linear;\n  transition: transform 500ms linear, -webkit-transform 500ms linear;\n}\n\n.filtersTiled--ZyeUg {\n  position: relative;\n  width: 86.25vw;\n  height: 2.5vw;\n  will-change: transform;\n  -webkit-transition: -webkit-transform 500ms linear;\n  transition: -webkit-transform 500ms linear;\n  transition: transform 500ms linear;\n  transition: transform 500ms linear, -webkit-transform 500ms linear;\n  margin-left: 0.781vw;\n  margin-bottom: 2.188vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/BlockFilters/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,kDAAyD;EAAzD,0CAAyD;EAAzD,kCAAyD;EAAzD,kEAAyD;AAC3D;;AAEA;EACE,kBAAkB;EAClB,cAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,kDAAyD;EAAzD,0CAAyD;EAAzD,kCAAyD;EAAzD,kEAAyD;EACzD,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":[".filters {\n  position: relative;\n  width: $(sceneWidthInVW)vw;\n  height: 2.5vw;\n  will-change: transform;\n  transition: transform $(switchSliderAnimationMS)ms linear;\n}\n\n.filtersTiled {\n  position: relative;\n  width: $(sceneWidthInVW)vw;\n  height: 2.5vw;\n  will-change: transform;\n  transition: transform $(switchSliderAnimationMS)ms linear;\n  margin-left: 0.781vw;\n  margin-bottom: 2.188vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var filters = "filters--ZiEPU";
export var filtersTiled = "filtersTiled--ZyeUg";
export default ___CSS_LOADER_EXPORT___;
