import * as cn from 'classnames';
import * as React from 'react';

import SceneItem from '~components/Scene/typings';
import { getBackgroundImageUrl } from '~lib/MediaItemImageSRC';
import { isCard, isCardChannel } from '~lib/objectType';
import { getSceneItemHeight, getSceneItemWidth } from '~lib/SizesInPX';
import ItemObject from "~typings/ItemObject";
import BlurableBaseImage from '~ui/Image/BlurableBaseImage';
import { container } from '~ui/Image/styles.module.css';

import * as styles from '../styles.module.css';
import { MaskedSceneMediaItemMutable } from './components/MaskedSceneMediaItemMutable';


type Props = Readonly<{
  mediaItem: SceneItem;
}>;


const MaskedSceneMediaItem: React.FC<Props> = ({ mediaItem }) => {
  const width = getSceneItemWidth();
  const height = getSceneItemHeight();

  const renderImage = (imageUrl: string) => (
    <div className={ cn(container, styles.image) }>
      <BlurableBaseImage
        width={ width }
        height={ height }
        src={ `${imageUrl}?${isCardChannel(mediaItem) ? '?' + Math.random() : ''}` }
      />
    </div>
  );

  if (isCard(mediaItem) && mediaItem.mutable) {
    return <MaskedSceneMediaItemMutable mediaItem={ mediaItem } renderImage={ renderImage } />;
  }

  if (isCard(mediaItem) && mediaItem.resource_type === ItemObject.SmallBanner) {
    return null;
  }

  const imageUrl = getBackgroundImageUrl({
    mediaItem,
    width,
    height,
  });

  if (!imageUrl) {
    return null;
  }

  return renderImage(imageUrl);
};


export default React.memo(MaskedSceneMediaItem);
