import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';

import FlagSwitcher from '~components/DevManager/FlagSwitcher';
import { EnvProvider } from '~components/Provider/Env';
import KeyDownHandler from '~components/Provider/KeyDownHandler';
import PlayerProvider from '~components/Provider/Player/component';

import * as styles from './DevPageInError.module.css';
import { FakeAppProvider } from './FakeAppProvider';

/**
 * Страница выбора апишки в режиме разработчика
 * Сделана специально для ErrorCrashScreen, с нужными провайдерами
 */
export const DevPageInError = () => {
  return (
    <EnvProvider>
      <PlayerProvider>
        <BrowserRouter basename="/">
          <FakeAppProvider>
            <KeyDownHandler>
              <div className={ styles.devPage }>
                <FlagSwitcher />
              </div>
            </KeyDownHandler>
          </FakeAppProvider>
        </BrowserRouter>
      </PlayerProvider>
    </EnvProvider>
  );
};
