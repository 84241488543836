import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import InputForm from '~components/InputForm';
import { PROMO_CODE_PAGE_HEADER } from '~localization';
import { KeyBoardType } from '~typings/Keyboard';


type Props = Readonly<{
  promocode?: string;
  onSubmit: (promocode: string) => void;
  intl: InjectedIntl;
}>;

const PromocodeInputForm: React.FC<Props> = (props: Props) => {
  return (
    <InputForm
      defaultValue={ props.promocode }
      keyboardType={ KeyBoardType.Promocode }
      placeholder={ props.intl.formatMessage({id: PROMO_CODE_PAGE_HEADER}) }
      onDone={ props.onSubmit }
      isFocused
    />
  );
};

export default injectIntl(PromocodeInputForm);
