import { queryClient } from "~app/global";
import expand from '~hooks/fetch/expand';
import ApiClient from "~lib/ApiClient";
import { makeSplitRequestByLimit } from "~lib/fetch";
import Episode from "~typings/Episode";
import ItemObject from "~typings/ItemObject";
import ResponseSingle from "~typings/ResponseSingle";
import Series from "~typings/Series";

import { fetchURLs } from "../fetch-parameters";
import { getMediaItemKey } from "./mediaItemKeys";


/**
 * Функция с запросом определенного эпизода
 * В ответе запроса у эпизода есть поле .series(все данные по сериалу), обогащаем его данными всех эпизодов
 * с помощью функции fetchSeriesWithSeasonEpisodes
 * Данная функция нужна, чтобы при переходе с блока "Продолжить просмотр" на главной странице,
 * в объекте сериала у нас были данные всех эпизодов всех сезонов
 */
export const fetchEpisodeWithSeriesSeasons = async (
  client: ApiClient,
  limit: number,
  accessToken: string | null,
  episodeId: string) => {
    const episodeResponse: ResponseSingle<Episode> =  await client.get(
      `${ fetchURLs[ItemObject.Episode] }/${ episodeId }`,
      {
        access_token: accessToken,
        [`expand[${ ItemObject.Episode }]`]: `images,video_file,season,series`,
      }
    );

    const episode = episodeResponse.data;
    const seriesId = episode.series!.id;

    const seriesResponce = await queryClient.fetchQuery({
      queryKey: getMediaItemKey(accessToken, seriesId, ItemObject.Series),
      queryFn: () => fetchSeriesWithSeasonEpisodes(client, limit, accessToken, seriesId),
    });

    episode.series = seriesResponce.data;

    return episodeResponse;
}


export const fetchSeriesWithSeasonEpisodes = async (
  client: ApiClient,
  limit: number,
  accessToken: string | null,
  seriesId: string) => {
    const seriesResponse: ResponseSingle<Series> =  await client.get(
      `${ fetchURLs[ItemObject.Series] }/${ seriesId }`,
      { access_token: accessToken }
    );

    const series = seriesResponse.data;

    series.seasons = await Promise.all(
      series.seasons.map(async (season) => {
        const episodedResponse = await fetchListEpisodes(client, limit, season.id, accessToken);
        episodedResponse.data.forEach((episode) => {
          episode.series = series;
        });
        season.episodes = episodedResponse.data;
        return season;
      }),
    );

    return seriesResponse;
}


const fetchListEpisodes = async (
  client: ApiClient,
  limit: number,
  season_id: string,
  accessToken: string | null,
) => {
  const params = {
    'filter[season_id_in]': season_id,
    'expand[episode]': expand.episodes,
    sort: 'number',
    access_token: accessToken,
  };

  return queryClient.fetchQuery({
    queryKey: getMediaItemKey(accessToken, season_id, 'episodes-list'),
    queryFn: () =>
      makeSplitRequestByLimit<Episode[]>({
        client,
        url: `/v1/episodes`,
        params,
        limit,
      }),
      cacheTime: 5 * 60 * 1000 // 5 минут
  });
};
