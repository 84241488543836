import * as React from 'react';

import { ActionType, useAppAction } from '~components/Provider/App';
import { SearchPage } from '~components/SearchPage';
import { usePageByType } from '~hooks/fetch/usePages/usePages';
import usePointer from "~stores/Pointer";
import PageObject from '~typings/PageObject';


const SearchScreen: React.FC = () => {
  const page = usePageByType(PageObject.SearchPage)
  const applicationAction = useAppAction();
  const setPointerEnabled = usePointer(state => state.setPointerEnabled);

  React.useEffect(() => {
    if(!page){
      return;
    }
    applicationAction({
      type: ActionType.SetIsLaunchingDone,
      payload: { isLaunchingDone: true },
    });
    setPointerEnabled(true)
  }, [page]);

  if (!page) {
    return null
  }

  return <SearchPage page={ page } />;
};


export default SearchScreen;
