// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--qoPC3 {\n  /* 35px 88px 0 88px */\n  margin: 2.734375vw 6.875vw 0 6.875vw;\n}\n\n.topLine--bIsDu {\n  margin-bottom: 4.6875vw;\n}\n\n.sidebarAndTabWrapper--SBpGp {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n     -moz-box-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: start;\n  -webkit-align-items: flex-start;\n     -moz-box-align: start;\n          align-items: flex-start;\n}\n\n.sidebar--RnAlx {\n  margin-right: 7.34375vw;\n  max-height: 41.25vw;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/AccountScreen.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,uBAA2B;EAA3B,mCAA2B;KAA3B,oBAA2B;UAA3B,2BAA2B;EAC3B,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".container {\n  /* 35px 88px 0 88px */\n  margin: 2.734375vw 6.875vw 0 6.875vw;\n}\n\n.topLine {\n  margin-bottom: 4.6875vw;\n}\n\n.sidebarAndTabWrapper {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n}\n\n.sidebar {\n  margin-right: 7.34375vw;\n  max-height: 41.25vw;\n  display: flex;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "container--qoPC3";
export var topLine = "topLine--bIsDu";
export var sidebarAndTabWrapper = "sidebarAndTabWrapper--SBpGp";
export var sidebar = "sidebar--RnAlx";
export default ___CSS_LOADER_EXPORT___;
