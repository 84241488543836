import { FocuserKeyHandler } from '@focuser';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { usePageManagerPointersBlocker } from '~components/PageManager';
import {
  ActionType as channelsReducerAction,
  useChannelsAction,
} from '~components/Provider/Channels';
import { useIsMenuOpen, useMenuOpener } from '~hooks/useMenuOpener';
import { useOldNavigationBlocker } from '~hooks/useOldNavigationBlocker';
import { makeReadyChildsMapperHook } from '~lib/focuserUtils/readyChildsMapper';
import useNavigation, { pageWeComeFromTS } from '~stores/Navigation';

/**
 * Костыль, который остался после пересоздания страницы SingleCardCollectionPage
 *
 * Подготавливает данные для страницы каналов
 */
export const useChannelsPreparer = (cardCollectionId?: string) => {
  useEffect(() => {
    useNavigation.getState().setPageWeComeFrom(pageWeComeFromTS.cardCollection);
  }, []);

  const channelsReducerDispatch = useChannelsAction();
  useEffect(() => {
    channelsReducerDispatch({
      type: channelsReducerAction.ChangeCollectionID,
      collectionID: cardCollectionId,
    });
  }, [cardCollectionId]);
};

type UseCardColContentNavigationProps = {
  hasBanner: boolean;
};

export const useCardColContentNavigation = ({ hasBanner }: UseCardColContentNavigationProps) => {
  const { openMenu } = useMenuOpener();
  const allowNavigation = !useIsMenuOpen();
  useOldNavigationBlocker(allowNavigation);
  usePageManagerPointersBlocker(allowNavigation);

  const [useReadyChildsHook] = useState(() =>
    makeReadyChildsMapperHook({
      banners: hasBanner,
      description: false,
      content: false,
    }),
  );

  const {
    setFocusOn,
    focusOn,
    setIsDescriptionReady,
    nextChildToNavigate,
    prevChildToNavigate,
    setIsContentReady,
    readyChilds,
  } = useReadyChildsHook(null);

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (nextChildToNavigate) {
      setFocusOn(nextChildToNavigate);
      e.stop();
    }
  };

  const onKeyUp: FocuserKeyHandler = (e) => {
    e.stop();
    if (prevChildToNavigate) {
      setFocusOn(prevChildToNavigate);
      return;
    }

    openMenu();
  };

  const setFocusOnContent = useCallback(() => {
    setFocusOn('content');
  }, []);

  return {
    focuserProps: {
      onKeyDown,
      onKeyUp,
      isPointerUpAvailable: !!prevChildToNavigate,
      isPointerDownAvailable: !!nextChildToNavigate,
      isFocused: allowNavigation,
    },
    readyChilds,
    focusOn,
    setFocusOn,
    setIsDescriptionReady,
    setIsContentReady,
    setFocusOnContent,
  };
};

type UseCanFocusOnDescriptionProps = {
  canCheck: boolean;
  onReady: () => void;
};
/**
 * Хук проверяет, можно ли фокусироваться на описании.
 *
 * Если можно, то вызывает метод setIsDescriptionReady со значением true
 *
 * Возвращаемый ref `descriptionTextRef` нужно повесить на текст описания
 */
export const useCanFocusOnDescription = ({ canCheck, onReady }: UseCanFocusOnDescriptionProps) => {
  const isPassedRef = useRef(false);

  const descriptionTextRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (isPassedRef.current || !descriptionTextRef.current || !canCheck) {
      return;
    }
    // Если описание длинное, позволяем на него фокусироваться
    if (descriptionTextRef.current.scrollHeight > descriptionTextRef.current.clientHeight) {
      onReady();
      isPassedRef.current = true;
    }
  }, [canCheck]);

  return {
    descriptionTextRef,
  };
};

type UseBannersScrollerProps = {
  showBanners: boolean;
};
/**
 * Хук скроллит баннеры наверх
 *
 * pageRef нужно повесить на блок, который нужно проскролить (например на всю страницу)
 *
 * afterBannersRef нужно повесить на блок, который идет после баннеров, чтобы до него скролить всю страницу
 *
 */
export const useCardColContentScroller = ({ showBanners }: UseBannersScrollerProps) => {
  const pageRef = useRef<HTMLDivElement>(null);
  const afterBannersRef = useRef<HTMLDivElement>(null);

  const scrollPageTo = useCallback((page: HTMLDivElement, toElement: HTMLDivElement) => {
    const pageRect = page.getBoundingClientRect();
    const toElementRect = toElement.getBoundingClientRect();
    const offsetToChild = Math.abs(pageRect.top - toElementRect.top);
    const offset = Math.max(offsetToChild, 0);
    page.style.transform = `translate3d(0, ${-offset}px, 0)`;
    page.style.webkitTransform = `translate3d(0, ${-offset}px, 0)`;
    return;
  }, []);

  const scrollPageToTop = useCallback((page: HTMLDivElement) => {
    page.style.transform = `translate3d(0, 0, 0)`;
    page.style.webkitTransform = `translate3d(0, 0, 0)`;
  }, []);

  useLayoutEffect(() => {
    if (!pageRef.current || !afterBannersRef.current) {
      return;
    }

    if (!showBanners) {
      scrollPageTo(pageRef.current, afterBannersRef.current);
    } else {
      scrollPageToTop(pageRef.current);
    }
  }, [showBanners]);

  const scrollToGrid = useCallback((gridElement: HTMLDivElement | null) => {
    if (!pageRef.current || !afterBannersRef.current) {
      return;
    }
    if (gridElement) {
      pageRef.current.style.marginTop = '0';
      scrollPageTo(pageRef.current, gridElement);
    } else {
      pageRef.current.style.removeProperty('margin-top');
      scrollPageTo(pageRef.current, afterBannersRef.current);
    }
  }, []);

  return {
    pageRef,
    afterBannersRef,
    scrollToGrid,
  };
};
