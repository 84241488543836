/* eslint-disable max-len */
import * as React from 'react';
import { createPortal } from 'react-dom';
import { setLogger } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import usePortal from '~hooks/usePortal';

const panelStyle: React.CSSProperties = {
  fontSize: '16px',
  width: '950px',
  borderLeft: '1px solid rgb(63, 78, 96)',
};

const RQueryDevtools = () => {

  // TODO: Переделать NODE_ENV на другую переменную, когда будет
  if(process.env.NODE_ENV !== 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return null
  }

  // выкидываем через портал RQ DEV TOOLS чтобы не было проблем со стилями
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const target = usePortal('RQDEVTOOLS');

  // Custom logger
  setLogger({
    log: console.log, // eslint-disable-line
    warn: console.warn,
    error: console.error,
  });

  return createPortal(<ReactQueryDevtools initialIsOpen={ false } panelProps={ { style: panelStyle } }/>, target)
};

export default RQueryDevtools;
