import ApiClient from '~lib/ApiClient';
import { fetchRetryer,FetchRetryerParams } from '~lib/fetch';
import { NetworkInfo } from '~typings/Network';
import ResponseSingle from '~typings/ResponseSingle';

export const fetch = async (client: ApiClient, retryParams: FetchRetryerParams) : Promise<NetworkInfo> => {
  const {data} =  await fetchRetryer(
    () => client.get<ResponseSingle<NetworkInfo>>('/v1/networks'),
    retryParams,
  );

  return data
};
