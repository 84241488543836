// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onlyTiledBlockOnPage--iK_9y {\n  margin-top: -14.84375vw;\n}\n\n.filters--Dd57s {\n  position: relative;\n  margin: 0 -0.9375vw;\n  height: 4.25vw;\n}\n\n.loaderWrapper--cBrfp {\n  position: relative;\n  margin-top: 3.90625vw;\n}\n\n.noResults--j8K1e {\n  margin-top: 3.125vw;\n  width: 100%;\n  font-size: 1.7187500000000002vw;\n  text-align: center;\n  position: relative;\n}\n\n.button--hFh2L {\n  margin-top: 1.953125vw;\n  font-size: 1.25vw;\n  text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/BlockCollection/styles.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,+BAAqB;EACrB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,iBAAuB;EACvB,yBAAyB;AAC3B","sourcesContent":[".onlyTiledBlockOnPage {\n  margin-top: -14.84375vw;\n}\n\n.filters {\n  position: relative;\n  margin: 0 -0.9375vw;\n  height: 4.25vw;\n}\n\n.loaderWrapper {\n  position: relative;\n  margin-top: 3.90625vw;\n}\n\n.noResults {\n  margin-top: 3.125vw;\n  width: 100%;\n  font-size: $fontH6_vw;\n  text-align: center;\n  position: relative;\n}\n\n.button {\n  margin-top: 1.953125vw;\n  font-size: $fontBody_vw;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
export var onlyTiledBlockOnPage = "onlyTiledBlockOnPage--iK_9y";
export var filters = "filters--Dd57s";
export var loaderWrapper = "loaderWrapper--cBrfp";
export var noResults = "noResults--j8K1e";
export var button = "button--hFh2L";
export default ___CSS_LOADER_EXPORT___;
