import * as cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import * as styles from './styles.module.css';


type ResetButtonProps = {
  focused: boolean;
};


const ResetButton: React.FC<ResetButtonProps> = ({ focused }) => {
  const history = useHistory();

  const handleClick = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();

    setTimeout(() => {
      history.replace('/');
      document.location.reload();
    }, 100);
  };

  return (
    <div
      className={ cn(styles.item, {
        focusedNavigationNode: focused,
      }) }
      style={ {
        opacity: focused ? 1 : 0.5,
      } }
      onClick={ handleClick }
    >
      Reset
    </div>
  );
};


export default ResetButton;
