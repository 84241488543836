import { QueryKey,useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient, useConfig } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import ApiClient from '~lib/ApiClient';
import { makeSplitRequestByLimit } from '~lib/fetch';
import deleteCacheWithOldAccessTokens from '~lib/ReactQuery/deleteCacheWithOldAccessTokens';
import Product from '~typings/Product';
import ResponseSingle from '~typings/ResponseSingle';

import { getAuthData } from '../useAccount';
import { getLimitFromConfig } from '../utils';


type Params = {
  productID?: string;
  promoID?: string;
  resourceID?: string;
  enabled?: boolean;
};

type FetchParams = Params & {
  client: ApiClient;
  limit: number;
};

const PREFIX_OF_A_COMPOSITE_KEY = 'products';

const getKeyWithoutAccessToken = (ID?: string): QueryKey => [PREFIX_OF_A_COMPOSITE_KEY, ID];
const getKey = (ID?: string): QueryKey => [PREFIX_OF_A_COMPOSITE_KEY, ID, getAuthData().accessToken];

const getCachedProducts = (ID?: string) => {
  const queryKey = getKey(ID);
  const cachedData = queryClient.getQueryData<ResponseSingle<Product[]>>(queryKey);

  return cachedData;
};


const fetch = async ({ productID, promoID, resourceID, client, limit }: FetchParams) : Promise<any> => {
  if (productID) {
    const cacheData = getCachedProducts();
    const product = (cacheData?.data || []).filter(({ id }) => (id === productID));

    if (product) { return ({ data: product }); }
  }

  const cacheData = getCachedProducts(productID || promoID || resourceID);

  if (cacheData) { return cacheData; }

  const options = {
    ...fetchOptionsBasic[FetchType.Products],
    ...(productID && {
      'filter[id_in]': productID,
    }),
    ...(promoID && {
      catalog_promo_id: promoID,
    }),
    ...(resourceID && {
      'filter[resource_id_in]': resourceID,
    }),
    access_token: getAuthData().accessToken,
  };


  return await makeSplitRequestByLimit<Product[]>({
    client,
    url: fetchURLs[FetchType.Products],
    params: options,
    limit
  });
};

const useProducts = ({ productID, promoID, resourceID, enabled }: Params) => {
  const queryKey = getKey(productID || promoID || resourceID);
  const client = useClient();
  const limit = getLimitFromConfig(useConfig())

  deleteCacheWithOldAccessTokens(getKeyWithoutAccessToken(productID || promoID || resourceID), 2);

  return useQuery<ResponseSingle<Product[]>>({
    queryKey,
    queryFn: () => fetch({ client, productID, promoID, resourceID, limit }),
    enabled: !!productID || !!promoID || !!resourceID || !!enabled,
    notifyOnChangeProps: ['data', 'isLoading'],
  });
};

export default useProducts;
