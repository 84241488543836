import * as cn from 'classnames';
import * as React from 'react';

import { ResetIcon } from './ResetIcon';
import * as styles from './styles.module.css';

type Props = {
  isFocused: boolean;
  className?: string;
};

export const ResetButton: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={ cn(styles.button, props.className, {
        [styles.focused]: props.isFocused,
      }) }
    >
      <ResetIcon className={ styles.icon } />
    </div>
  );
};
