import * as React from 'react';

import SceneManagerFetcher from '~components/Scene/SceneManagerFetcher';
import SceneItem from '~components/Scene/typings';
import ItemObject from '~typings/ItemObject';

import SceneMediaItemDescriptionText from './description-text';
import * as styles from './styles.module.css';


type Props = Readonly<{
  withFullInfo: boolean;
  item?: SceneItem | null;
  meta?: any;
  description?: any;
  children?: any;
  disabledSceneManager?: boolean;
}>;


const SceneMediaItemDescription: React.FC<Props> = (props: Props) => {
  return <>
    {
      !props.disabledSceneManager
        ? <SceneManagerFetcher withInfo id={ props.item?.id } object={ props.item?.object as ItemObject } />
        : null
    }
    {
      (props.withFullInfo) &&
        <div className={ styles.container }>
            <SceneMediaItemDescriptionText description={ props.description } />
          { props.children }
        </div>
    }
  </>
}

export default React.memo(SceneMediaItemDescription);
