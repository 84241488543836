import * as React from 'react';

import { switchSliderAnimationMS } from '~app/variables';
import SceneItem from '~components/Scene/typings';

import {
  ActionType,
  reducer,
  SceneContext,
  SceneDispatchContext,
  useSceneHelper,
} from './component.helpers';


const SceneProvider = ({ children }: {
  children?: React.ReactNode;
}): JSX.Element => {
  const [localItem, setLocalItem] = React.useState<SceneItem | null>(null);
  const [localWithInfo, setLocalWithInfo] = React.useState<boolean>(true);
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      item: null,
      withInfo: true,
      isChangeItemInProgress: false,
    },
  );
  const changeSceneMediaItem = React.useCallback((item, withInfo, setImmediately) => {
    if (setImmediately || state.withInfo !== withInfo) {
      dispatch({
        type: ActionType.SetItem,
        payload: {
          item,
          withInfo,
        },
      });
    }

    if (item?.id !== localItem?.id) {
      setLocalItem(item);
      setLocalWithInfo(withInfo);
    }
  }, [localItem?.id, state.withInfo]);

  const { item, isChangeItemInProgress } = useSceneHelper(localItem, (switchSliderAnimationMS * 2));

  React.useEffect(() => {
    if (item !== state.item) {
      dispatch({
        type: ActionType.SetItem,
        payload: {
          item,
          withInfo: localWithInfo,
        },
      });
    }
  }, [item]);
  React.useEffect(() => {
    dispatch({
      type: ActionType.SetIsChangeItemInProgress,
      payload: { isChangeItemInProgress },
    });
  }, [isChangeItemInProgress]);

  const value = React.useMemo(() => ({
    changeSceneMediaItem,
    item: state.item,
    withInfo: state.withInfo,
    isChangeItemInProgress: state.isChangeItemInProgress,
  }), [
    changeSceneMediaItem,
    state.item?.id,
    state.withInfo,
    state.isChangeItemInProgress,
  ]);

  return (
    <SceneContext.Provider
      value={ value }
    >
      <SceneDispatchContext.Provider
        value={ dispatch }
      >
        { children }
      </SceneDispatchContext.Provider>
    </SceneContext.Provider>
  );
};


SceneProvider.displayName = 'SceneProvider';


export default React.memo(SceneProvider);
