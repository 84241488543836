import * as React from 'react';

import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { PlayerState, PlayerStatus } from '~components/ScenePlayer/Player/useHandleEvents';
import { IPlayer } from '~lib/player/typings';


type Props = Readonly<{
  state: PlayerState;
  player: IPlayer;
}>;


const useAudioTracks = (props: Props) => {
  const playerState = usePlayerState();
  const { setAudioTracks } = usePlayerStateActions();

  const handleSetAudioTracks = React.useCallback(() => {
    try {
      setAudioTracks(props.player.getAudioTracks());
    } catch (ignore) {
      // ignore
    }
  }, [playerState.audioTracks]);

  React.useEffect(() => {
    if (
      props.state.status === PlayerStatus.LoadedMetaData
      || props.state.status === PlayerStatus.TrackChanged
    ) {
      handleSetAudioTracks();
    }
  }, [props.state]);
};


export default useAudioTracks;
