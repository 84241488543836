import Focuser from '@focuser';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { PrimaryButton } from '~app/ui-kit/PrimaryButton/PrimaryButton';
import ExclamationMarkIcon from '~ui/Icon/icons/ExclamationMarkIcon';

import * as styles from './styles.module.css';

type Props = Readonly<{
  errorText: string;
  buttonText: string;
}>;

export const DefaultError: React.FC<Props> = ({ errorText, buttonText }) => {
  const history = useHistory();

  const clickHandler = () => {
    history.goBack();
  }

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.iconWrapper }>
        <ExclamationMarkIcon />
      </div>
      <div className={ styles.text }>
        { errorText }
      </div>
      <Focuser.FocusableBlock
        isFocused
        onClick={ clickHandler }
        noNeedForceFocus
        isLastBlock
      >
        <PrimaryButton
          isFocused
          content={ buttonText }
          className={ styles.button } 
        />
      </Focuser.FocusableBlock>
    </div>
  );
};
