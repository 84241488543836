import focuser, { FocuserKeyHandler } from '@focuser';
import availableLangs from 'customization-project-name/languages.json';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Typography } from '~app/ui-kit/Typography';
import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { APPLICATION_LANGUAGE } from '~lib/localizator/src/dictionaries';
import { CheckboxFocuser } from '~newapp/ui-kit/Other/Checkbox';
import Language from '~typings/Language';

import * as styles from './Settings.module.css';
import { LanguageToTextMap } from './utils';

export const Settings = injectIntl(({ intl }) => {
  const { language: currentAppLanguage } = useApp();
  const history = useHistory();
  const appAction = useAppAction();

  const [focusedIdx, setFocusedIdx] = React.useState(0);
  const onKeyDown: FocuserKeyHandler = (event) => {
    if (focusedIdx < availableLangs.length - 1) {
      setFocusedIdx(focusedIdx + 1);
      event.stop();
    }
  };

  const onKeyUp: FocuserKeyHandler = (event) => {
    if (focusedIdx > 0) {
      setFocusedIdx(focusedIdx - 1);
      event.stop();
    }
  };

  const handleChangeLanguage = (language: Language) => {
    if (language === currentAppLanguage) {
      return;
    }
    appAction({
      type: ActionType.SetIsLaunchingDone,
      payload: { isLaunchingDone: false },
    });
    appAction({
      type: ActionType.SetLanguage,
      payload: { language },
    });
    setTimeout(() => {
      history.replace('/');
      document.location.reload();
    }, 500);
  };

  return (
    <focuser.FocusableBlock
      isFocused
      noNeedForceFocus
      onKeyDown={ onKeyDown }
      onKeyUp={ onKeyUp }
      isPointerDownAvailable={ focusedIdx < availableLangs.length - 1 }
      isPointerUpAvailable={ focusedIdx > 0 }
      className={ styles.Settings }
    >
      <Typography
        variant="h5_bold"
        className={ styles.langTitle }
      >
        {intl.formatMessage({ id: APPLICATION_LANGUAGE })}
      </Typography>

      {(availableLangs as Language[]).map((lang: Language, idx: number) => {
        return (
          <div
            className={ styles.checkboxLine }
            key={ lang }
          >
            <CheckboxFocuser
              isFocused={ idx === focusedIdx }
              isChecked={ currentAppLanguage === lang }
              focuserClassName={ styles.checkboxButton }
              onClick={ () => {
                handleChangeLanguage(lang);
              } }
              onForceFocus={ () => setFocusedIdx(idx) }
              emitForceFocusOnHover
            />
            <Typography variant="subtitle1_medium">{LanguageToTextMap[lang]}</Typography>
          </div>
        );
      })}
    </focuser.FocusableBlock>
  );
});
