import React, { useEffect } from 'react';

import { BigKeyboardButton } from '~app/ui-kit/BigKeyboardButton';

import { HideBtnState } from '../NavigableKeyboard/types';
import { FBtn } from '../types';
import { getContentForFButton, getStateForFButton } from './utils';

export type Props = {
  btn: FBtn;
  isFocused: boolean;
  hideBtnState: HideBtnState;
  isConfirmBtnActive: boolean;
  onMouseEnter: (btn: FBtn, ev: MouseEvent) => void;
  onMouseClick: (btn: FBtn) => void;
  className?: string;
};

export const NavigableKeyboardButton: React.FC<Props> = React.memo(
  ({
    btn,
    isFocused,
    hideBtnState,
    isConfirmBtnActive,
    onMouseEnter,
    className,
    onMouseClick,
  }) => {
    const content = btn.action ? getContentForFButton(btn.action, hideBtnState) : btn.id;
    const state = getStateForFButton(btn, isFocused, hideBtnState, isConfirmBtnActive);

    const ref = React.useRef<HTMLDivElement>(null);
    useEffect(() => {
      const div = ref.current;
      if (!div) {
        return;
      }

      const clickHandler = () => {
        onMouseClick(btn);
      };

      const mouseEnterHandler = (ev: MouseEvent) => {
        onMouseEnter(btn, ev);
      };

      div.addEventListener('click', clickHandler);
      div.addEventListener('mouseenter', mouseEnterHandler);
      return () => {
        div.removeEventListener('click', clickHandler);
        div.removeEventListener('mouseenter', mouseEnterHandler);
      }
    }, [onMouseClick, onMouseEnter, btn]);

    return (
      <BigKeyboardButton
        forwardRef={ ref }
        content={ content }
        state={ state }
        isLarge={ btn.isLarge }
        className={ className }
      ></BigKeyboardButton>
    );
  },
);

NavigableKeyboardButton.displayName = 'NavigableKeyboardButton';
