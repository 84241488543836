import cn from 'classnames';
import { find } from 'lodash';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import useRentInvoice from '~hooks/fetch/usePayments/useRentInvoice';
import { PromoData } from '~hooks/fetch/usePromocode';
import useNavigationByKeys from '~hooks/useNavigation';
import usePaymentProceed from '~hooks/usePaymentProceed/usePaymentProceed';
import { BY_CLICKING_THE_PAYMENT_BUTTON, ENABLE, FREE, MOBILE_PAYMENT, 
  PAYMENT_SERVICE_FEE_INFORMATION, PROMOCODE, THE_TERMS_OF_THE_USER_AGREEMENT, 
  WHAT_IS_AN_SUBSCRIPTION, WRONG_PROMO_CODE_ERROR } from '~localization';
import NavigationDirection from '~typings/NavigationDirection';
import Product from '~typings/Product';
import Button from '~ui/button';
import { CheckboxButton } from '~ui/CheckboxButton';

import * as styles from '../../styles.module.css';
import { getPhaseTextsForSubscr, getPlanType } from './PromocodeSubscConfirm.utils';
import { CheckboxSubcrPhaseText } from './PromocodeSubscrConfirmTypes';


type Props = Readonly<{
  promocode: string;
  promo?: PromoData;
  productId: string;
  isProcessing: boolean;
  onSuccess: () => void;
  onError: (errorMessage?: string | null) => void;
  setProcessingStatus: (isProcessing: boolean) => void;
  needToReset: () => void;
  intl: InjectedIntl;
}>;

const NOT_AVAILABLE_PROMO_CODE = 'not_available_promo_code';
const LAST_FOCUS_ON_CHECKBOX = 'LAST_FOCUS_ON_CHECKBOX';

enum PromocodeConfirmActions {
  ContinueConfirmation = 'continue_confirm',
  WhatIsAnSubscription = 'what_is_an_subscription',
}


const PromocodeSubscrConfirm: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { mutate, data: invoice, error: invoiceError } = useRentInvoice();

  const product = (find(props.promo?.products, { id: props.productId }) ?? {}) as Product;
  const { plans } = product;
  const promoPhase = find(plans?.[0]?.phases, ['type', 'promo']);
  const promo = promoPhase?.promos?.[0];

  const [isFocusOnLeftBlock, setIsFocusOnLeftBlock] = React.useState<boolean>(true);
  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState<number>(0);

  /////////логика получения значений для методов оплаты (оператора и промокода)
  const { operator, promoCode, promocodeAndOperatorArr } = React.useMemo(() => {
    const { operator, promoCode } = getPhaseTextsForSubscr(product);
    const promocodeAndOperatorArr : CheckboxSubcrPhaseText[] = [...operator, ...promoCode];
    return {
      operator,
      promoCode,
      promocodeAndOperatorArr,
    };
  }, [product]);
  /////////

  const getLastCheckedButtonFromHistory = () => {
    const currentSearch = parse(history.location.search);

    const lastState = currentSearch[LAST_FOCUS_ON_CHECKBOX];
    if(typeof lastState === 'string') {
      const parsedLastState = JSON.parse(lastState);
      const { id, type } = parsedLastState;
      delete currentSearch[LAST_FOCUS_ON_CHECKBOX];
      
      const foundedCurrentPlan = promocodeAndOperatorArr.find(p => p.plan.id === id && p.type === type);
      if(foundedCurrentPlan){
        return foundedCurrentPlan;
      }
    }

    const firstPaymentMethod = promoPhase?.billing?.prices?.[0]?.payment_method_type;
    if (firstPaymentMethod === 'operator') {
      return operator[0] || null;
    }
    if (firstPaymentMethod === 'promo_code') {
      return promoCode[0] || null;
    }
    
    return promocodeAndOperatorArr[0] || null;
  }

  const [checkedButtonWithPhase, setCheckedButtonWithPhase] =
    React.useState<CheckboxSubcrPhaseText | null>(getLastCheckedButtonFromHistory());

  const {
    isSuccess,
    isError,
    error: errorReason,
  } = usePaymentProceed(invoice?.data.id, checkedButtonWithPhase?.type === 'operator');

  const actionsList = [
    {
      type: PromocodeConfirmActions.ContinueConfirmation,
      text: props.intl.formatMessage({ id: ENABLE })
    },
    {
      type: PromocodeConfirmActions.WhatIsAnSubscription,
      text: props.intl.formatMessage({ id: WHAT_IS_AN_SUBSCRIPTION })
    },
  ];

  const handleLeftBlockNavigation = (direction: NavigationDirection) => {
    if (direction === NavigationDirection.Up) {
        if (selectedButtonIndex > 0) {
          setSelectedButtonIndex(selectedButtonIndex - 1);
        }
    } else if (direction === NavigationDirection.Down) {
        if (selectedButtonIndex < actionsList.length - 1) {
          setSelectedButtonIndex(selectedButtonIndex + 1);
        }
    } else if (direction === NavigationDirection.Right) {
        setIsFocusOnLeftBlock(false);
        setSelectedButtonIndex(0);
    }
  }

  const handleRightBlockNavigation = (direction: NavigationDirection) => {
    if (direction === NavigationDirection.Up) {
      if (selectedButtonIndex > 0) {
        setSelectedButtonIndex(selectedButtonIndex - 1);
      }
    } else if (direction === NavigationDirection.Down) {
      if (selectedButtonIndex < promocodeAndOperatorArr.length - 1) {
        setSelectedButtonIndex(selectedButtonIndex + 1);
      }
    } else if (direction === NavigationDirection.Left) {
        setIsFocusOnLeftBlock(true);
        setSelectedButtonIndex(0);
    }
  }

  const handleNavigation = (direction: NavigationDirection) => {
    if(isFocusOnLeftBlock) {
      handleLeftBlockNavigation(direction);
      return;
    }
    handleRightBlockNavigation(direction);
  };

  useNavigationByKeys({
    isMounted: true,
    onNavigation: handleNavigation,
  }, [selectedButtonIndex, isFocusOnLeftBlock]);

  React.useEffect(() => {
    if (!invoiceError) return;
    const error = (invoiceError as any)?.errors?.[0]?.code;

    props.setProcessingStatus(false);
    if (error === NOT_AVAILABLE_PROMO_CODE) {
      return props.onError(props.intl.formatMessage({ id: WRONG_PROMO_CODE_ERROR }))
    }
  }, [invoiceError]);

  React.useEffect(() => {
    if (!isSuccess && !isError || !props.isProcessing) return;
    props.setProcessingStatus(false);

    if (isSuccess) {
      return props.onSuccess();
    }

    if (isError) {
      return props.onError(errorReason?.message);
    }
  }, [isSuccess, isError, errorReason]);

  if (!promo) {
    return null;
  }

  const getFocusForPromocodePayment = (idx: number):boolean => {
    if(!isFocusOnLeftBlock) {
      return (idx + operator.length) === selectedButtonIndex;
    }
    return false;
  }

  const buttonsClickHandler = (button: { type: PromocodeConfirmActions; text?: string }) => {
    if (button.type === PromocodeConfirmActions.WhatIsAnSubscription) {  
      if(checkedButtonWithPhase) {
        const currentSearch = parse(history.location.search);

        currentSearch[LAST_FOCUS_ON_CHECKBOX] = JSON.stringify({
          id: checkedButtonWithPhase.plan.id,
          type: checkedButtonWithPhase.type
        })

        history.replace({
          search: stringify(currentSearch),
        });
      }

      history.push(`/products/${product.id}`);
      return;
    }

    if (button.type === PromocodeConfirmActions.ContinueConfirmation) {
      if (!checkedButtonWithPhase) {
        return;
      }

      const plan = checkedButtonWithPhase.plan;
      props.setProcessingStatus(true);
      // props.setSelectedPlan(plan);

      mutate({
        planID: plan.id,
        promocode: String(props.promocode),
        planType: getPlanType(plan),
        paymentMethodType: checkedButtonWithPhase.type === 'operator' ? 'operator' : 'promo_code',
      });
      return;
    }
  };

  const renderPaymentMethods = () => {
    return (
      <>
        {operator.length !== 0 &&
          <div className={ styles.paymentTitle }>
            { props.intl.formatMessage({ id: MOBILE_PAYMENT }) }
          </div>
        }
        { operator?.map((phase, idx) => {
          return (
            <div className={ styles.optionsWrapper } key={ idx }>
            <CheckboxButton
              key={ idx }
              onClick={ () => setCheckedButtonWithPhase(phase) }
              isFocused={ !isFocusOnLeftBlock && idx === selectedButtonIndex }
              isChecked={ checkedButtonWithPhase === phase }
              className={ styles.checkboxButtonWrapper }
            >
              <div className={ styles.checkboxButtonEvergreen }>
                    <>
                      <div className={ styles.checkboxInfoPromophase }>
                        { phase.promo }
                      </div>
                      <div className={ styles.checkboxInfoEvergreen }>
                        { phase.everGreen }
                      </div>
                    </>
              </div>
            </CheckboxButton>
          </div>
          )
        })
        }

        { promoCode.length !== 0 &&
          <div className={ styles.paymentTitle }>
            { props.intl.formatMessage({ id: PROMOCODE }) }
          </div>
        }

        { promoCode?.map((phase, idx) => {
          return (
            <div className={ styles.optionsWrapper } key={ idx }>
              <CheckboxButton
                key={ idx }
                onClick={ ()=> setCheckedButtonWithPhase(phase) }
                isFocused={ getFocusForPromocodePayment(idx) }
                isChecked={ checkedButtonWithPhase === phase }
                className={ styles.checkboxButtonWrapper }
              >
                <label className={ styles.checkboxButton }>
                  { `${props.intl.formatMessage({ id: FREE })} на ${phase.promo.split(" / ")[1]}` }
                </label>
              </CheckboxButton>
            </div>
          )
        })}
      </>
    )
  }

  const renderPaymentInfo = () => {
    if (checkedButtonWithPhase?.type === 'operator') {
      return (
        <>
          <div className={ styles.promoPrice }>
            {checkedButtonWithPhase?.promo}
          </div>
          <div className={ styles.evergreenPrice }>
            {checkedButtonWithPhase?.everGreen}
          </div>
        </>
      );
    }

    if (checkedButtonWithPhase?.type === 'promocode') {
      return (
        <>
          <div className={ styles.promoPrice }>
            <div>{props.intl.formatMessage({ id: FREE }).toUpperCase()}</div>
          </div>
          <div className={ styles.evergreenPrice }>
            {`На ${promoCode?.[0].promo.split(' / ')[1]}`}
          </div>
        </>
      );
    }
  };

  return (
    <div className={ styles.subscrConfirmWrapper }>

      <div className={ styles.contentInfoWrapper }>
        <div className={ styles.promoName }>
          { product.name }
        </div>

        {renderPaymentInfo()}

        {actionsList.map((button, index) => (
          <Button
            isFocused={ isFocusOnLeftBlock && index === selectedButtonIndex }
            className={ styles.buttonsWrap }
            onClick={ () => buttonsClickHandler(button) }
            isDisabled={ button.type === PromocodeConfirmActions.WhatIsAnSubscription
              ? false
              : !checkedButtonWithPhase
            }
            key={ index }
          >
            {button.text}
          </Button>
        ))}

        { checkedButtonWithPhase?.type === 'operator'
          ? <>
              <div className={ cn(styles.paymentInfo, styles.paymentInfoDescription) }>
                <div>{ product?.plans[0].description }</div>
              </div>
              <div className={ styles.paymentInfo }>
                { props.intl.formatMessage({ id: PAYMENT_SERVICE_FEE_INFORMATION }) }
              </div>
            </>
          : <div className={ cn(styles.paymentInfo, styles.paymentInfoDescription) }>
              <div>{ product?.plans[0].description }</div>
            </div>
        }
        <div className={ styles.paymentInfo }>
          {props.intl.formatMessage({ id: BY_CLICKING_THE_PAYMENT_BUTTON })}  
          <u className={ styles.rulesText }>
            {props.intl.formatMessage({ id: THE_TERMS_OF_THE_USER_AGREEMENT })}
          </u>
        </div>
      </div>
      <div className={ styles.paymentMethodWrapper }>
        {renderPaymentMethods()}
      </div>
    </div>
  )
};

export default injectIntl(PromocodeSubscrConfirm);