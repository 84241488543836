/* eslint-disable max-len */
import * as React from 'react';


const IconFavorite: React.FC = () => (
  <svg
    className="playerIcon"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.07,7.95l2,3.981.471.938,1.039.146L24,13.634l-3.177,3.128-.751.739.185,1.037.78,4.374-3.956-2.05-.93-.482-.926.49L11.27,22.962l.731-4.4.173-1.04-.76-.73-3.2-3.074,4.4-.66,1.039-.156.461-.944L16.07,7.95m-.025-4.506-3.73,7.638L3.9,12.345l6.13,5.891L8.634,26.619l7.525-3.981,7.557,3.916-1.492-8.367,6.065-5.972-8.433-1.181-3.811-7.59Z"
      fill="currentColor"
    />
  </svg>
);


export default React.memo(IconFavorite);
