import { find, get } from 'lodash';

import SceneItem from '~components/Scene/typings';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import BannerBig from '~typings/BannerBig';
import BannerMiddle from '~typings/BannerMiddle';
import Image from '~typings/Image';
import ImageType from '~typings/ImageType';
import ManyItemType from '~typings/ManyItemType';
import Page from '~typings/Page';


// replace vars in url
const fillImageTemplateBySizes = (
  template = '',
  w = 0, h = 0,
  c = false,
  b = false
): string =>
  template
    .replace('{width}', w.toString())
    .replace('{height}', h.toString())
    .replace('{crop}', b ? 'b' : c ? 'c' : '');


const getImageURLTemplateWithRatio = (
  item: ManyItemType,
  ratio: number,
  imgTypes: ImageType[],
  needToCrop = false,
): [string, boolean] => {
  const images: Image[] = get(item, 'images', []);
  let image: Image | null = null;

  for (let imgType of imgTypes) {
    image = find(
      images,
      (img: Image) => (
        (img.type === imgType && img.url_template !== null)
      )
    ) as Image | null;
    if (image) { break; }
  }

  if (image) {
    return [image?.url_template || '', needToCrop];
  }

  for (let img of images) {
    const originalRatio = (img.original_width / img.original_height);
    if (originalRatio === ratio) {
      image = img;
    } else if (
      (originalRatio > 1 && ratio > 1)
      || (originalRatio < 1 && ratio < 1)
    ) {
      image = img;
      needToCrop = true;
    }
    if (image) {
      break;
    }

    image = img;
    if (originalRatio !== ratio) {
      needToCrop = true;
    }
    if (image) {
      break;
    }
  }

  return [image?.url_template || '', needToCrop];
};

const getImageURLTemplate = (
  item: SceneItem | BannerBig | BannerMiddle | Page,
  ratio: number,
  imgTypes: ImageType[] = [],
  needToCrop = false,
): [string, boolean] => {
  const images: Image[] = get(item, 'images', EMPTY_ARRAY);

  let imageMatch: Image | null = null;

  for (let imgType of imgTypes) {
    imageMatch = find(
      images,
      (img: Image) => (
        img.type === imgType &&
        img.url_template !== null &&
        (img.original_width / img.original_height) === ratio
      )
    ) as Image | null;
    if (imageMatch) { break; }
  }
  if (imageMatch) {
    return [imageMatch?.url_template || '', needToCrop];
  }

  let image: Image | null = null;

  for (let imgType of imgTypes) {
    image = find(
      images,
      (img: Image) => (
        (img.type === imgType && img.url_template !== null)
      )
    ) as Image | null;
    if (image) { break; }
  }
  if (image) {
    return [image?.url_template || '', needToCrop];
  }

  for (let img of images) {
    const originalRatio = (img.original_width / img.original_height);

    if (originalRatio === ratio) {
      image = img;
    } else if (
      (originalRatio > 1 && ratio > 1)
      || (originalRatio < 1 && ratio < 1)
    ) {
      image = img;
      needToCrop = true;
    }
    if (image) { break; }

    image = img;
    if (originalRatio !== ratio) {
      needToCrop = true;
    }
    if (image) { break; }
  }

  return [image?.url_template || '', needToCrop];
};

// use from channels object for get preview image
const getPreview = (image: Image, width = 260, height = 150, crop = false, blur = false) => {
  if (!image || !image.url_template) return '';

  return fillImageTemplateBySizes(image.url_template, width, height, crop, blur);
};

const imageByType = (images: Image[], imgTypes: ImageType[]): Image | null => {
  let image: Image | null = null;

  for (let imgType of imgTypes) {
    image = find(
      images,
      (img: Image) => (
        (img.type === imgType && img.url_template !== null)
      )
    ) || null as Image | null;

    if (image) { break; }
  }

  return image;
};


export {
  fillImageTemplateBySizes,
  getImageURLTemplate,
  getImageURLTemplateWithRatio,
  getPreview,
  imageByType,
}
