import focuser from '@focuser';
import React from 'react';

import { SimpleSlider } from './components/SimpleSlider';
import * as styles from './SimpleSliderList.module.css'

export const SimpleSliderList = () => {
  const [focusedIdx, setFocusedIdx] = React.useState(0);

  return (
    <focuser.FocusableBlock
      isFocused
      onKeyDown={ (event) => {
        if (focusedIdx < 3) {
          setFocusedIdx(focusedIdx + 1);
          event.stop();
        }
      } }
      onKeyUp={ (event) => {
        if (focusedIdx > 0) {
          setFocusedIdx(focusedIdx - 1);
          event.stop();
        }
      } }
      className={ styles.simpleSliderList }
      isPointerUpAvailable={ focusedIdx > 0 }
      isPointerDownAvailable={ focusedIdx < 3 }
      noNeedForceFocus
    >
      <focuser.FocusableBlock isFocused={ focusedIdx === 0 } onForceFocus={ () => setFocusedIdx(0) }>
        <SimpleSlider></SimpleSlider>
      </focuser.FocusableBlock>
      <focuser.FocusableBlock isFocused={ focusedIdx === 1 } onForceFocus={ () => setFocusedIdx(1) }>
        <SimpleSlider></SimpleSlider>
      </focuser.FocusableBlock>
      <focuser.FocusableBlock isFocused={ focusedIdx === 2 } onForceFocus={ () => setFocusedIdx(2) }>
        <SimpleSlider></SimpleSlider>
      </focuser.FocusableBlock>
      <focuser.FocusableBlock isFocused={ focusedIdx === 3 } onForceFocus={ () => setFocusedIdx(3) }>
        <SimpleSlider></SimpleSlider>
      </focuser.FocusableBlock>
    </focuser.FocusableBlock>
  );
};
