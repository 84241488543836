import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import UnsubscribePage from '~components/ProductPlanPage/UnsubscribePage';
import UnsubscribeProceed from '~components/ProductPlanPage/UnsubscribeProceed';
import { ProductsList } from '~components/ProductsList';
import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import useProducts from '~hooks/fetch/useProducts';
import { useAllSubscriptions } from '~hooks/fetch/useSubscriptions';

import { ProductRoutes } from './ProductRoutes';


const ProductScreen: React.FC = () => {
  const { path } = useRouteMatch();
  const { isLaunchingDone } = useApp();
  const { subscriptions } = useAllSubscriptions()
  const { data: products, isLoading: productsIsLoading } = useProducts({ enabled: true });
  const appAction = useAppAction();

  React.useEffect(() => {
    if (!productsIsLoading && !isLaunchingDone) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: { isLaunchingDone: true },
      });
    }
  }, [productsIsLoading]);

  if (!isLaunchingDone || !products) { return null; }

  return (
    <Switch>
      <Route path={ path } exact>
        <ProductsList
          products={ products }
          subscriptions={ subscriptions }
        />
      </Route>
      <Route path={ `${path}/unsubscribe/:subscriptionID/delete` }>
        <UnsubscribeProceed />
      </Route>
      <Route path={ `${path}/unsubscribe/:subscriptionID` }>
        <UnsubscribePage subscriptions={ subscriptions } />
      </Route>
      <Route path={ `${path}/:productID` }>
        <ProductRoutes subscriptions={ subscriptions } />
      </Route>
    </Switch>
  );
};

export { ProductScreen };

export default ProductScreen;
