import cn from 'classnames';
import * as React from 'react';

import OnwardIcon from '~ui/Icon/icons/Onward';
import IconTextItem from '~ui/Icon/IconTextItem';

import * as styles from './styles.module.css';


type Props = Readonly<{
  children?: any;
  isFocused?: boolean;
  onClick?: () => void;
}>;

/**
 * Кнопка из старого дизайна
 * В UI ките есть замена для этой кнопки:
 *
 * `ui-kit/SimpleButton`
 *
 * @deprecated
 */
const IconButton: React.FC<Props> = ({ children, isFocused, onClick }: Props) => (
  <div
    className={
      cn(styles.wrapper,
      {
        'focusedNavigationNode': isFocused,
        [styles.focused]: isFocused
      })
    }
    onClick={ onClick }
  >
    <IconTextItem
      Icon={ OnwardIcon }
      isFocused={ isFocused }
      className={ styles.icon }
    />
    { children }
  </div>
);

export default IconButton;
