import ApiClient from '~lib/ApiClient';
import ResponseMany from '~typings/ResponseMany';
import ResponseManyMeta from '~typings/ResponseMeta';

import { fetchRetryer } from './fetch-retryer';

/**
 * Функция примнимает на вход пагинацию от ответа первого запроса, и возращает массив параметров,
 * которые нужно вставить в следующие запросы
 * То есть если функция вернула массив с двумя элементами, то нужно сделать еще 2 запроса
 */
export const splitPaginationToRequestsParams = ( pagination: ResponseManyMeta['pagination']) => {
  const { limit, total, count } = pagination;

  const itemsOnBackend = total - count;

  if (itemsOnBackend <= 0) {
    return [];
  }

  const moreRequestsCount = Math.ceil(itemsOnBackend / limit);

  const moreRequestParams = new Array(moreRequestsCount).fill(0).map((_z, i) => ({
    'page[limit]': limit,
    'page[offset]': (i + 1) * limit
  }));
  return moreRequestParams;
};

interface MakeSplitReuqestProps {
  client: ApiClient;
  url: string;
  limit: number;
  params?: object;
}

/**
 * Кол-во ретраев, при ошибке
 */
const DEFAULT_RETRY_COUNT = 5;
/**
 * Кол-во времени между запросами
 */
const DEFAULT_RETRY_WAIT = 1000;

/**
 * Принимает на вход все параметры для запроса, и разбивает его на несколько, а потом соединяет
 * Внимание, работает только с запросами, ответ которых возвращает пагинацию
 * @see ResponseMany тип
 */
export const makeSplitRequestByLimit = async <RDATA extends unknown[]>({
  client,
  url,
  params,
  limit,
}: MakeSplitReuqestProps): Promise<ResponseMany<RDATA>> => {
  const firstResp = await fetchRetryer(
    () =>
      client.get(url, {
        ...params,
        'page[limit]': limit,
        'page[offset]': 0,
      }) as Promise<ResponseMany<RDATA>>,
    {
      retryCount: DEFAULT_RETRY_COUNT,
      waitTime: DEFAULT_RETRY_WAIT,
    },
  );

  const paginationParams = splitPaginationToRequestsParams(firstResp.meta.pagination);

  const nextResps = await Promise.all(
    paginationParams.map((paginationParams) => {
      return fetchRetryer(
        () => client.get(url, { ...params, ...paginationParams }) as Promise<ResponseMany<RDATA>>,
        {
          retryCount: DEFAULT_RETRY_COUNT,
          waitTime: DEFAULT_RETRY_WAIT,
        },
      );
    }),
  );

  return nextResps.reduce((acc, cur) => {
    acc.data = acc.data.concat(cur.data) as RDATA;
    acc.meta.pagination.count = acc.data.length;
    acc.meta.pagination.offset = 0;
    return acc;
  }, firstResp);
};
