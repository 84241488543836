import bugsnag from '@bugsnag/js';

import { appId,version } from '~lib/env-utils';

const Bugsnag = bugsnag({
  apiKey: '419731d5942e3822f65a390e0f1f33e3',
  releaseStage: appId,
  appVersion: version,

  // options, see: https://docs.bugsnag.com/platforms/javascript/configuration-options/
  autoCaptureSessions: __BUILD_ENV__ === 'production',
  autoNotify: __BUILD_ENV__ === 'production',
  beforeSend: function(report) {
    // Example: Disable reporting of errors
    if (__BUILD_ENV__ !== 'production') report.ignore();
  },
});

export default Bugsnag;
