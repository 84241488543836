// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--zrhcJ {\n  text-align: center;\n  font-size: 1.25vw;\n}\n\n.messageText--ombbc {\n  font-size: 1.7187500000000002vw;\n  margin-bottom: 5.555vh;\n}\n\n.button--uyW03 {\n  margin: 0 0.390625vw;\n  min-width: 20.3125vw;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/blocks/playerPopcorn/PlayerErrors/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAuB;AACzB;;AAEA;EACE,+BAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;AACtB","sourcesContent":[".container {\n  text-align: center;\n  font-size: $fontBody_vw;\n}\n\n.messageText {\n  font-size: $fontH6_vw;\n  margin-bottom: 5.555vh;\n}\n\n.button {\n  margin: 0 0.390625vw;\n  min-width: 20.3125vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "container--zrhcJ";
export var messageText = "messageText--ombbc";
export var button = "button--uyW03";
export default ___CSS_LOADER_EXPORT___;
