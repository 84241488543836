import * as React from 'react';

import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import useHotKey from '~hooks/useHotKey';
import useSwitchChannelsList from '~hooks/useSwitchChannel/channels';
import HotKey from '~typings/HotKey';


type Props = Readonly<{
  isMounted: boolean;
  popupName: string | null;
  onClosePopup: () => void;
}>;


const useSwitchChannels: React.FC<Props> = (props: Props) => {
  const { setItem } = usePlayerStateActions();
  const currentChannelID = usePlayerState().item?.id;
  const channels = useSwitchChannelsList();
  const update = [currentChannelID, channels.length, props.popupName];
  const getCurrentChannelIndex = (): number => channels.findIndex(({ id }) => (id === currentChannelID));
  const switchChannel = React.useCallback((direction: 'prev' | 'next') => {
    const currentChannelIndex = getCurrentChannelIndex();

    if (currentChannelIndex === -1) { return; }

    let nextChannelIndex: number | null = null;

    if (direction === 'next') {
      if ((currentChannelIndex + 1) <= (channels.length - 1)) {
        nextChannelIndex = (currentChannelIndex + 1);
      } else {
        nextChannelIndex = 0;
      }
    } else {
      if ((currentChannelIndex - 1) > -1) {
        nextChannelIndex = (currentChannelIndex - 1);
      } else {
        nextChannelIndex = (channels.length - 1);
      }
    }

    const nextChannel = channels[nextChannelIndex];

    if (nextChannel) {
      setItem(nextChannel);
      if (props.popupName) {
        props.onClosePopup();
      }
    }
  }, update);
  const handlePrevChannel = React.useCallback(() => {
    switchChannel('prev');
  }, update);
  const handleNextChannel = React.useCallback(() => {
    switchChannel('next');
  }, update);

  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_CH_DOWN, // "h" Key
    action: handlePrevChannel,
  }, update);
  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_CH_UP, // "t" Key
    action: handleNextChannel,
  }, update);

  return null;
};


export default useSwitchChannels;
