import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import PaymentInstruction from '~components/ProductPlanPage/PaymentInstruction';
import PaymentMethodRadioButtons from '~components/ProductPlanPage/PaymentMethodRadioButtons';
import { matchPaymentMethodFromProduct } from '~components/ProductPlanPage/SelectPlan.helpers';
import * as productsStyles from '~components/ProductPlanPage/styles.module.css';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import {
  BUY_ACTION,
  EST_DESCRIPTION,
  RENT_ACTION,
  TVOD_DESCRIPTION,
} from '~localization';
import NavigationDirection from '~typings/NavigationDirection';
import PaymentMethod from '~typings/PaymentMethod';
import RentPlan, { RentPlanType } from '~typings/RentPlan';
import UserPaymentMethod from '~typings/UserPaymentMethod';
import Button from '~ui/button';

import PlansRadioButtons from './PlansRadioButtons';
import * as styles from './styles.module.css';


enum FocusOn {
  RadioButtons = 0,
  MethodButtons = 1,
  Buttons = 2,
}

type Props = {
  rentPlans: RentPlan[] | undefined;
  type: RentPlanType;
  url: string | null | undefined;
  focusOnMenu: boolean;
  onMenu: (arg?: boolean) => void;
  intl: InjectedIntl;
};

const RentType: React.FC<Props> = ({ rentPlans, type, url, focusOnMenu, onMenu, intl }) => {
  const plans = rentPlans?.filter((plan) => plan.type === type);
  const isEST = type === 'EST';
  const [selectedPlan, setSelectedPlan] = React.useState<RentPlan | null>(null);
  const [selectedMethod, setSelectedMethod] = React.useState<UserPaymentMethod>()
  const history = useHistory();

  const hasAnyMethod = plans?.some(
    (plan) => plan.phases?.length
  ) ?? true;

  const navigationItems = React.useRef([
    {}, // RadioButtons
    { maxIndex: 0 }, // MethodButtons
    { maxIndex: 0 }, // Buttons
  ]);

  React.useEffect(() => {
    if (!hasAnyMethod) {
      navigationItems.current = [{ maxIndex: 0 }]; // Back Button
    } else if (plans && plans.length !== 0) {
      navigationItems.current[FocusOn.RadioButtons] = { maxIndex: plans.length - 1 };
      if (selectedPlan) {
        const matchedMethod = matchPaymentMethodFromProduct(plans)[selectedPlan.id];
        const maxIndex = matchedMethod?.length ? matchedMethod.length - 1 : 0;
        navigationItems.current[FocusOn.MethodButtons]
          = { maxIndex };
      }
    }
  }, [plans?.length, hasAnyMethod, selectedPlan]);

  React.useEffect(() => {
    if (plans) {
      setSelectedPlan(plans[0])
    }
  }, [plans?.length, type])

  const navigationState = useSpatialNavigation({
    allowNavigation: !focusOnMenu && (plans?.length || 0) > 0,
    navigationItems: navigationItems.current,
    onLeave: (direction) => {
      if (direction === NavigationDirection.Up) {
        onMenu();
      }
    }
  });

  const handleSelectPlan = (planID: string) => {
    const plan = plans?.find(p => p.id === planID);
    if (plan) {
      setSelectedPlan(plan);
    }
  };

  const handleClick = () => {
    if (!url || !selectedPlan) { return; }
    switch (selectedMethod?.payment_method_type) {
      case PaymentMethod.Operator:
        history.replace(`${url}/rents/${selectedPlan.id}/invoice`);
        break
      case PaymentMethod.NewCard:
      case PaymentMethod.a1NewCard:
      case PaymentMethod.Existing_card:
        history.replace(`${url}/rents/${selectedPlan.id}/invoice/proceed_card_payment`);
        break
      default:
        break;
    }
  };

  if (plans?.length === 0) { return null; }

  return (
    <>
      {
        hasAnyMethod ? (
          <>
            <PlansRadioButtons
              isFocused={ !focusOnMenu && (navigationState.focusOn === FocusOn.RadioButtons) }
              focusIndex={ navigationState.focusedIndex[FocusOn.RadioButtons] }
              plans={ plans }
              selectedPlan={ selectedPlan }
              onClick={ handleSelectPlan }
            />
            <PaymentMethodRadioButtons
              paymentMethods={ selectedPlan ? matchPaymentMethodFromProduct(plans)[selectedPlan.id] : null }
              setSelectedMethod={ setSelectedMethod }
              selectedMethod={ selectedMethod }
              isFocused={ !focusOnMenu && (navigationState.focusOn === FocusOn.MethodButtons) }
              focusIndex={ navigationState.focusedIndex[FocusOn.MethodButtons] }
              intl={ intl }
            />
            <div className={ productsStyles.buttonWrapper }>
              <Button
                isFocused={ !focusOnMenu && navigationState.focusOn === FocusOn.Buttons }
                onClick={ handleClick }
                className={ cn(productsStyles.button, {
                  [productsStyles.isFocused]: !focusOnMenu && navigationState.focusOn === FocusOn.Buttons,
                }) }
              >
                {
                  intl.formatMessage({ id: isEST ? BUY_ACTION : RENT_ACTION })
                }
              </Button>
            </div>
            {
              selectedPlan ? (
                <div className={ cn(productsStyles.buttonWrapper, styles.description) }>
                  {
                    isEST ?
                      intl.formatMessage({ id: EST_DESCRIPTION })
                      :
                      intl.formatMessage({ id: TVOD_DESCRIPTION }, {
                        durationValue: selectedPlan.duration.value,
                        durationUnit: selectedPlan.duration.unit,
                        accessDurationValue: selectedPlan.access_period.value,
                        accessDurationUnit: selectedPlan.access_period.unit,
                      })
                  }
                </div>
              ) : null
            }
          </>
        ) : (
          <PaymentInstruction
            isFocused={ (!focusOnMenu && navigationState.focusOn === FocusOn.RadioButtons) }
          />
        )
      }
    </>
  );
};


export default React.memo(injectIntl(RentType));
