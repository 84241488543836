import * as React from 'react';

import { getScheduleTime } from '~lib/localeUtils/dates';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isSingleEvent: boolean;
  startAt: string;
  endAt: string;
}>;


const ScheduleTime: React.FC<Props> = (props: Props) => {
  const renderText = (props.isSingleEvent) ?
    [
      getScheduleTime(props.startAt),
      ' — ',
      getScheduleTime(props.endAt),
    ]
    :
    getScheduleTime(props.startAt);

  return (
    (
      React.createElement(
        'div',
        {
          className: styles.time
        },
        renderText,
      )
    )
  );
};


export default React.memo(ScheduleTime);
