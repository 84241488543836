import * as cn from 'classnames';
import * as React from 'react';
import { FormattedMessage } from "react-intl";
import { useHistory } from 'react-router-dom';
import shallow from "zustand/shallow";

import { TextContent } from "~components/AgreementPopup/component";
import * as blockStyles from '~components/Block/styles.module.css';
import * as styles from "~components/BlockCollection/styles.module.css";
import { BlockTitle } from '~components/BlockTitle';
import { Filters } from "~components/Filters";
import Loader from "~components/LightLoader/Loader";
import CatchBack from '~components/PageManager/CatchBack';
import { ActionType, useAppAction } from '~components/Provider/App';
import { ActionType as channelsReducerAction, useChannelsAction } from "~components/Provider/Channels";
import { ActionType as MenuActionType, useMenu, useMenuAction } from '~components/Provider/Menu';
import { useScene } from '~components/Provider/Scene';
import TiledView from '~components/TiledView/component';
import useCardCollection from '~hooks/fetch/useCardCollection';
import { useInfinityCardColItems } from '~hooks/fetch/useCardCollectionsItems';
import useIsPlayerPage from '~hooks/useIsPlayerPage';
import usePageInfo from "~hooks/usePageInfo";
import { usePaginationByItems } from '~hooks/usePagination';
import useSceneMediaItemPageInfo from '~hooks/useSceneMediaItemPage';
import { getCardCollectionDimension } from "~lib/Dimension";
import { setPopupState } from '~lib/PopupState';
import { FILTER_BUTTON_RESET, SEARCH_NO_RESULTS } from '~localization';
import useFilters from "~stores/Filters";
import useNavigationOnLayer from '~stores/LayerNavigation';
import useNavigation, { pageWeComeFromTS } from "~stores/Navigation";
import ImageType from '~typings/ImageType';
import Button from "~ui/button";

import { reduceString } from "../../supportFunctions";
import * as css from '../CollectionPage/styles.module.css'

interface CardCollectionTS {
  propsCardCollectionId: string;
  focusedBlockID: string | null;
  isFocusedBlock: boolean;
  focusedItemIndex: number;
}

const BlockCardCollectionExpand = (props: CardCollectionTS) => {
  // компонент гетерогенной коллекции для главной страницы
  // CC - Card Collection - инфа о коллекции
  // CCI - Card Collection Items - сами карточки
  const { propsCardCollectionId, isFocusedBlock, focusedBlockID, focusedItemIndex: focusedIndex } = props
  const channelsReducerDispatch = useChannelsAction()
  const { pageID } = usePageInfo();
  const scene = useScene();
  const history = useHistory();
  const appAction = useAppAction();
  const isPlayerPage = useIsPlayerPage();
  const { isSceneMediaItemPageMode } = useSceneMediaItemPageInfo();
  const menu = useMenu();
  const menuAction = useMenuAction();
  const isPopupOpened = (menu.isMenuOpened || menu.isPopupOpened);
  const setFocusedIndex = useNavigationOnLayer(state => state.setFocusedIndex);

  const layerID = `expandable-block-${ propsCardCollectionId }`;

  // попап при клике на описание ↓ ↓ ↓ ↓ ↓
  const [showPopUp, setShowPopUp] = React.useState(false)

  // установка/сброс страницы с которой мы пришли ↓ ↓ ↓ ↓ ↓
  const setPageWeComeFrom = useNavigation(state => state.setPageWeComeFrom)

  // страница с которой мы пришли, нужна для навигации ↓ ↓ ↓ ↓ ↓
  const pageWeComeFrom = useNavigation(state => state.pageWeComeFrom)

  // сброс фильтров ↓ ↓ ↓ ↓ ↓
  const resetSelectedFilters = useFilters(state => state.resetFilters); // сброс фильтров

  // достаем фильтры ↓ ↓ ↓ ↓ ↓
  const selectedFilters = useFilters(state => ({
    quickFilters: state.quickFilters?.[layerID],
    dropdownFilters: state.dropdownFilters?.[layerID],
  }), shallow);

  // функция обновления грида на главной  ↓ ↓ ↓ ↓ ↓
  const setFocusHistoryExpandItemsCount = useNavigation(state => state.setFocusHistoryExpandItemsCount)

  let descriptionExist: boolean // существует ли описание ?
  let descriptionIsLong: boolean // если существует то более 290 символов ?
  let description: string | undefined = '' // описание
  let filtersExist: boolean // есть ли фильтры внутри коллекции
  let allowNavigation: boolean // запрещаем навигацию при показе описания

  // фетчим инфу о коллекции (описание + фильтры) ↓ ↓ ↓ ↓ ↓
  const { data: dataCC, isLoading: dataCC_isLoading } = useCardCollection(propsCardCollectionId, false);
  const dimension = dataCC?.data
  ? getCardCollectionDimension(dataCC.data)
  : ImageType.BANNER;

  // фетчим итемы из коллекции ↓ ↓ ↓ ↓ ↓
  const {query: queryCCI, parsedData: dataCCI} = useInfinityCardColItems({
    cardCollectionID: propsCardCollectionId,
    filters: selectedFilters
  });


  const dataCCI_isLoading = queryCCI.isLoading;

  const SLIDES_COUNT_PER_LINE = dimension === ImageType.POSTER ? 6 : 4;

  // В TiledView нужно передать мемоизированные размеры карточек ↓ ↓ ↓ ↓ ↓
  const slidesCountForTiledView: [number, number]  = React.useMemo(
    () => [SLIDES_COUNT_PER_LINE, SLIDES_COUNT_PER_LINE],
    [SLIDES_COUNT_PER_LINE],
  );

  // пагинация ↓ ↓ ↓ ↓ ↓
  usePaginationByItems({
    currentFetchedCount: dataCCI?.data.length || 0,
    focusedLineIdx: Math.floor(focusedIndex / SLIDES_COUNT_PER_LINE),
    elementsPerLine: SLIDES_COUNT_PER_LINE,
    minimalLinesOnScreen: 3, // сколько минимум линий должно быть предзагружено
    fetchMoreData: ()=> {
      if(queryCCI.hasNextPage && !queryCCI.isFetchingNextPage){
        queryCCI.fetchNextPage();
      }
    }
  });

  React.useEffect(() => {
    //каждый раз при изменении количества итемов, нужно обновлять навигацию на главной
    setFocusHistoryExpandItemsCount(pageID, dataCCI?.data.length)
  }, [dataCCI?.data.length])

  // клик
  const handleClick = (item) => {
    if (item) {
      history.push(`/media-item/${ item?.resource_type }/${ item?.resource_id }`);
    }
  };

  // обрабатываем description  ↓ ↓ ↓ ↓ ↓
  descriptionExist = (dataCC?.data.description.length ?? 0) > 0;
  descriptionIsLong = descriptionExist && (dataCC?.data?.description?.length ?? 0) >= 290

  descriptionIsLong
    ? description = reduceString(dataCC?.data.description, 290)
    : description = dataCC?.data.description;


  filtersExist = (dataCC?.data.search_filters_group?.items.length || 0) > 0

  allowNavigation = !isPopupOpened;


  // кнопка "назад"
  const handleBack = React.useCallback(() => {
    if (showPopUp) {
      setShowPopUp(false)
      return
    }


    if (isPlayerPage || isSceneMediaItemPageMode) {
      history.goBack();

      return;
    }

    if (menu.isPopupOpened) {
      setPopupState(history, null);

      return;
    }

    if (allowNavigation) {
      if (focusedIndex > 0) {
        setFocusedIndex(layerID, 0);
      }
      else if (pageWeComeFrom === pageWeComeFromTS.menu) {
        menuAction({
          type: MenuActionType.ChangeMenuState,
          isMenuOpened: true,
        })
      }
      else {
        history.goBack();
      }
    }
  }, [
    allowNavigation,
    focusedIndex,
    isPlayerPage,
    isSceneMediaItemPageMode,
    menu.isPopupOpened,
    layerID,
    showPopUp
  ]);

  React.useEffect(() => {
    scene.changeSceneMediaItem(null, false, true);
  }, []);

  React.useEffect(() => {
    if (dataCC_isLoading) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: { isLaunchingDone: true },
      });
    }
  }, [dataCC_isLoading]);

  React.useEffect(() => {
    channelsReducerDispatch({
      type: channelsReducerAction.ChangeCollectionID,
      collectionID: propsCardCollectionId
    })
  }, [propsCardCollectionId])

  React.useEffect(() => {
    setPageWeComeFrom(pageWeComeFromTS.cardCollection)
  }, [])

  React.useEffect(() => {
    if (props.isFocusedBlock) {
      scene.changeSceneMediaItem(null, false, true);
    }
  }, [props.isFocusedBlock]);

  return (
    <>
      <CatchBack action={ handleBack } triggers={ [handleBack] } />
      {
        showPopUp && dataCC?.data.description &&
          <div className={ css.popup } onClick={ () => setShowPopUp(!showPopUp) }>
              <div className={ css.popupWrapper }>
                  <TextContent text={ dataCC?.data.description } />
              </div>
          </div>
      }
      <div className={ css.container }>
        {
          (dataCC?.data.name) &&
            <div style={ { marginTop: isFocusedBlock ? '4.861vh' : '' } }>
                <BlockTitle
                    noPortal={ true }
                    isFocusedBlock={ isFocusedBlock }
                    isVisible
                    isFadeOut={ false }
                >
                  { dataCC?.data.name }
                </BlockTitle>
            </div>
        }
        {
          descriptionExist &&
            <div className={ cn(css.descWrapper, {
              [css.focusOnDesc]: isFocusedBlock && focusedBlockID?.includes('description-U7sGA1hs2'),
              'focusedNavigationNode': isFocusedBlock && focusedBlockID?.includes('description-U7sGA1hs2'),
            }) }
                 onClick={ () => descriptionIsLong && setShowPopUp(!showPopUp) }>
                <div className={ css.desc }>{ description }</div>
            </div>
        }
        {
          filtersExist &&
            <div className={ css.filters }>
                <Filters
                    ID={ layerID }
                    isFocused={ isFocusedBlock && focusedBlockID?.includes('filters-U7sGA1hs2') || false }
                    focusedIndex={
                      isFocusedBlock
                      && focusedBlockID?.includes('filters-U7sGA1hs2') ? focusedIndex : 0
                    }
                    filterGroup={ dataCC?.data.search_filters_group?.items }
                />
            </div>
        }
        { !dataCC_isLoading &&
            <div className={ cn(blockStyles.block3, blockStyles.visible) }>
              {
                dataCCI_isLoading
                  ? <div className={ styles.loaderWrapper }><Loader /></div>
                  : dataCCI?.data.length === 0
                    ? <div className={ styles.noResults }>
                      <div><FormattedMessage id={ SEARCH_NO_RESULTS } /></div>
                      <div>
                        <Button
                          isFocused={ isFocusedBlock
                            && !!focusedBlockID?.includes('grid-U7sGA1hs2') }
                          className={ styles.button }
                          onClick={ resetSelectedFilters }
                        >
                          <FormattedMessage id={ FILTER_BUTTON_RESET } />
                        </Button>
                      </div>
                    </div>
                    : <TiledView
                      items={ dataCCI }
                      focusedItemIndex={ isFocusedBlock
                      && focusedBlockID?.includes('grid-U7sGA1hs2') ? focusedIndex : 0 }
                      dimension={ dimension }
                      disableText={ dataCC?.data.card_config.disable_text }
                      allowNavigation={
                        allowNavigation && isFocusedBlock && !!focusedBlockID?.includes('grid-U7sGA1hs2')
                      }
                      onClick={ handleClick }
                      slidesCount={ slidesCountForTiledView }
                      forceOnClick
                      showOnlyFetchedItems
                    />
              }
            </div>
        }
      </div>
    </>
  )
};


export { BlockCardCollectionExpand };
