import { queryClient } from "~app/global";
import ApiClient from "~lib/ApiClient";
import { makeSplitRequestByLimit } from "~lib/fetch";
import AudioShow from "~typings/AudioShow";
import AudioShowPart from "~typings/AudioShowPart";
import ItemObject from "~typings/ItemObject";
import ResponseSingle from "~typings/ResponseSingle";

import { fetchURLs } from "../fetch-parameters";
import { getMediaItemKey } from "./mediaItemKeys";


export const fetchPartWithAudioShow = async (
  client: ApiClient,
  limit: number,
  accessToken: string | null,
  partId: string) => {
    const partResponse: ResponseSingle<AudioShowPart> =  await client.get(
      `${ fetchURLs[ItemObject.Part] }/${ partId }`,
      {
        access_token: accessToken,
        [`expand[${ ItemObject.Part }]`]: 'images,video_file,audio_show',
      }
    );

    const part = partResponse.data;
    const audioShowId = part.audio_show.id;

    const audioShowResponce = await queryClient.fetchQuery({
      queryKey: getMediaItemKey(accessToken, audioShowId, ItemObject.AudioShow),
      queryFn: () => fetchAuidoshowWithParts(client, limit, accessToken, audioShowId),
    });

    part.audio_show = audioShowResponce.data;

    return partResponse;
}

export const fetchAuidoshowWithParts = async (
  client: ApiClient,
  limit: number,
  accessToken: string | null,
  audioShowId: string) => {
    const audioShowResponse: ResponseSingle<AudioShow> =  await client.get(
      `${ fetchURLs[ItemObject.AudioShow] }/${ audioShowId }`,
      { access_token: accessToken }
    );

    const audioShow = audioShowResponse.data;

    audioShow.parts = await (fetchListParts(client, limit, audioShow.id, accessToken)).then(partsResp=>{
      const parts = partsResp.data;
      return parts.map((part) => {
        part.audio_show = audioShow;
        return part;
      })
    });
    return audioShowResponse;
}

const fetchListParts = async (
  client: ApiClient,
  limit: number,
  audioShowId: string,
  accessToken: string | null,
) => {
  const params = {
    'filter[audio_show_id_in]': audioShowId,
    'expand[part]': 'images,video_file',
    sort: 'number',
    access_token: accessToken,
  };

  return queryClient.fetchQuery({
    queryKey: getMediaItemKey(accessToken, audioShowId, 'parts-list'),
    queryFn: () =>
      makeSplitRequestByLimit<AudioShowPart[]>({
        client,
        url: '/v1/parts',
        params,
        limit,
      }),
    cacheTime: 5 * 60 * 1000 // 5 минут
  });
};
