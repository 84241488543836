import * as React from 'react';

import IconMediaItem from './IconMediaItem';
import AudioIcon from './icons/Audio';


type Props = {
  className?: string;
};

const IconMediaItemPlayAudio: React.FC<Props> = ({ className }) => {
  return (
    <IconMediaItem
      Icon={ AudioIcon }
      className={ className }
    />
  );
};


export default IconMediaItemPlayAudio;
