// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sliderDotsWrapper--M6lYo {\n  position: absolute;\n  -webkit-border-bottom-right-radius: 0;\n          border-bottom-right-radius: 0;\n  width: 86.25vw;\n  text-align: center;\n}\n\n.sliderItemDot--y_tlT {\n  display: inline-block;\n  margin: 0 0.390625vw;\n  width: 1.328125vw;\n  height: 0.3125vw;\n  -webkit-border-radius: 0.15625vw;\n          border-radius: 0.15625vw;\n  background-color: rgba(255, 255, 255, 0.3);\n}\n\n.fullHD .sliderItemDot--y_tlT {\n  border-width: 2px;\n}\n\n.active--V83Dc {\n  background-color: rgba(243, 243, 247, 1);\n}\n\n", "",{"version":3,"sources":["webpack://./src/app/components/Slider/items/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qCAA6B;UAA7B,6BAA6B;EAC7B,cAA0B;EAC1B,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,iBAAiB;EACjB,gBAAgB;EAChB,gCAAwB;UAAxB,wBAAwB;EACxB,0CAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,wCAAwB;AAC1B","sourcesContent":[".sliderDotsWrapper {\n  position: absolute;\n  border-bottom-right-radius: 0;\n  width: $(sceneWidthInVW)vw;\n  text-align: center;\n}\n\n.sliderItemDot {\n  display: inline-block;\n  margin: 0 0.390625vw;\n  width: 1.328125vw;\n  height: 0.3125vw;\n  border-radius: 0.15625vw;\n  background-color: $w_30;\n}\n\n:global(.fullHD) .sliderItemDot {\n  border-width: 2px;\n}\n\n.active {\n  background-color: $gw_10;\n}\n\n"],"sourceRoot":""}]);
// Exports
export var sliderDotsWrapper = "sliderDotsWrapper--M6lYo";
export var sliderItemDot = "sliderItemDot--y_tlT";
export var active = "active--V83Dc";
export default ___CSS_LOADER_EXPORT___;
