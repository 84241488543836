import { find } from 'lodash';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import AvatarsGallery from '~components/AvatarGallery';
import DropDownPopup from '~components/InputWithPopup/popups/DropDownPopup';
import TextInputPopup from '~components/InputWithPopup/popups/TextInputPopup';
import PinCodePopup from '~components/PinCodePopup';
import { useAccount } from '~hooks/fetch/useAccount';
import {
  ProfileActionType,
  useProfiles,
  useProfilesMutation,
} from '~hooks/fetch/useProfiles';
import { useSetState } from '~hooks/useSetState';
import {PROFILES_ADD_PROFILE, PROFILES_EDIT_PROFILE } from '~localization';
import AllowedContent from '~typings/AllowedContent';
import ProfileFormData from '~typings/ProfileFormData';
import { PopupIsolated } from '~ui/Popup';

import { ageSelectionField, defaultProfile, fieldWithProfileName, translatedConfig } from './helpers';
import ConfirmDeletePopup from './MainComponent/ConfirmDeletePopup/ConfirmDeletePopup';
import MainEditProfileComponent from './MainComponent/MainComponent';

export enum Block {
  MainComponent = 'main_component',
  AvatarPopup = 'avatar_popup',
  ChangeNamePopup = 'change_name_popup',
  ChangeAgePopup = 'dpop_down_popup',
  PinCodePopup = 'pincode_popup',
  ConfirmDeletePopup = 'confirm_delete_popup',
}

type State =
  | {
      block: Block.MainComponent;
      initialFocus?: 'avatar' | 'name' | 'age' | 'delete_profile_button';
    }
  | { block: Block.PinCodePopup; cause: 'saving' | 'deleting' }
  | { block: Exclude<Block, Block.MainComponent | Block.PinCodePopup> };

type Props = Readonly<{
  profileId: string | undefined;
  onBack: () => void;
  onDelete: () => void;
  intl: InjectedIntl;
}>;


const ProfileEditorPopup: React.FC<Props> = ({ profileId, onBack, onDelete, intl }: Props) => {
  const { data: profilesItems } = useProfiles();
  const account = useAccount();
  const { mutate: mutateProfiles, isLoading} = useProfilesMutation();
  const isSecurityPinOn = account?.data?.security_pin === true;

  const originalProfile = find(profilesItems, { id: profileId });

  const profileTemplate: ProfileFormData = originalProfile
    ? {
      name: originalProfile.name,
      gender: originalProfile.gender,
      birthdate: originalProfile.birthdate,
      kids: originalProfile.kids,
      allowed_content: originalProfile.allowed_content,
      avatar_id: originalProfile.avatar?.id
    } as ProfileFormData
    : defaultProfile;

  const [ stepScreen, setStepScreen] = React.useState<State>({ block: Block.MainComponent });

  const profileState = useSetState<ProfileFormData>(profileTemplate as ProfileFormData);
  const [profileData, setProfileData] = profileState;

  const isEditMode = !!profileId;

  const isShowCheckboxButton =
    (originalProfile?.account_creator === false
    || originalProfile?.account_creator === undefined)

  const isKidsProfile = profileData.kids === true;
  const popupTitle = intl.formatMessage({ id: isEditMode ? PROFILES_EDIT_PROFILE : PROFILES_ADD_PROFILE });

  const translatedProfileNameField = translatedConfig(fieldWithProfileName, intl);
  let translatedAgeSelectionAge = translatedConfig(ageSelectionField, intl);

  const saveClickButtonHandler = () => {
    if(isSecurityPinOn) {
      setStepScreen({block: Block.PinCodePopup, cause: 'saving'})
    } else {
      saveDataHandler()
    }
  }

  const saveDataHandler = () => {

    if (!isEditMode) {
      mutateProfiles({
        type: ProfileActionType.Create,
        payload: { profileData }
      }, {
        onSettled: () => onBack(),
      });
    } else {
      mutateProfiles({
        type: ProfileActionType.Update,
        payload: { id: profileId, profileData }
      }, {
        onSettled: () => onBack(),
      });
    }
  };

  const deleteProfileHandler = () => {
    mutateProfiles({ type: ProfileActionType.Delete, payload: { id: originalProfile?.id } }, {
      onSettled: onDelete,
    });
  };

  const deleteClickButtonHandler = () => {
    if(isSecurityPinOn) {
      setStepScreen({block: Block.PinCodePopup, cause: 'deleting'})
    } else {
      deleteProfileHandler();
    }
  }

  // Хендлеры кликов для открытия поп-апов
  const handleClickAvatarEditPopupOpen = () => {
    setStepScreen({block: Block.AvatarPopup})
  }

  const handleClickNameChangePopupOpen = () => {
    setStepScreen({block: Block.ChangeNamePopup})
  }

  const handleClickAgeChangePopupOpen = () => {
    setStepScreen({block: Block.ChangeAgePopup})
  }

  const handleClickDeleteProfilePopupOpen = () => {
    setStepScreen({block: Block.ConfirmDeletePopup})
  }

  if(isLoading) {
    return null;
  }

  if(stepScreen.block === Block.MainComponent) {
    return (
      <MainEditProfileComponent
        profileId={ profileId }
        popupTitle={ popupTitle }
        profileData={ profileData }
        setProfileData={ setProfileData }
        originalProfile={ originalProfile }
        isShowCheckboxButton={ isShowCheckboxButton }
        onSelectAvatar={ handleClickAvatarEditPopupOpen }
        onSelectNameField={ handleClickNameChangePopupOpen }
        onSelectAgeField={ handleClickAgeChangePopupOpen }
        confirmDeletePopupOpen={ handleClickDeleteProfilePopupOpen }
        onBack={ onBack }
        initialFocus={ stepScreen.initialFocus }
        onSave={ saveClickButtonHandler }
      />
    )
  }

  if(stepScreen.block === Block.AvatarPopup) {
    return (
      <AvatarsGallery
        selectedAvatar={ profileData.avatar_id }
        title={ popupTitle }
        onSelect={ (avatarId) => {
          setProfileData({ avatar_id: avatarId });
          setStepScreen({block: Block.MainComponent, initialFocus: 'avatar'});
        } }
        onBack={ () => setStepScreen({block: Block.MainComponent, initialFocus: 'avatar'}) }
      />
    )
  }

  if(stepScreen.block === Block.ChangeNamePopup) {
    const onClose = () => {
      setStepScreen({block: Block.MainComponent, initialFocus: 'name'});
    }

    return (
      <TextInputPopup
        value={ profileData.name }
        maxLength={ 25 }
        minLength={ 2 }
        placeholder={ translatedProfileNameField.placeholder }
        onBack={ onClose }
        onSubmit={ value => {
          setProfileData({ name: value })
          onClose()
        } }
      />
    )
  }

  if(stepScreen.block === Block.ChangeAgePopup) {
    if(isKidsProfile) {
      translatedAgeSelectionAge = {
        ...translatedAgeSelectionAge,
        items: translatedAgeSelectionAge.items?.filter(
          allowedContentItem => allowedContentItem.value !== AllowedContent.Adult
        )
      };
    }

    return (
      <DropDownPopup
        items={ translatedAgeSelectionAge.items ?? [] }
        value={ profileData.allowed_content }
        title={ popupTitle }
        label={ translatedAgeSelectionAge.placeholder }
        onSubmit={ (value) => {
          setProfileData({ allowed_content: value })
          setStepScreen({ block: Block.MainComponent, initialFocus: 'age' })
        } }
        onBack={ () => { setStepScreen({block: Block.MainComponent, initialFocus: 'age'})} }
      />
    )
  }

  if(stepScreen.block === Block.PinCodePopup) {
    return (
      <PinCodePopup
        onSuccess={ () => {
          if(stepScreen.cause === 'saving') {
            saveDataHandler()
          } else {
            deleteProfileHandler()
          }
        } }
        onBack={ () => {
          setStepScreen({block: Block.MainComponent})
        } }
      />
    )
  }

  if(stepScreen.block === Block.ConfirmDeletePopup) {
    return (
      <PopupIsolated>
        <ConfirmDeletePopup
          profileName={ profileData.name }
          onCancel={ () => { setStepScreen({block: Block.MainComponent, initialFocus: 'delete_profile_button'}) } }
          onDelete={ deleteClickButtonHandler }
        />
      </PopupIsolated>
    )
  }

  return null;
};

export default injectIntl(ProfileEditorPopup);
