import { FocuserKeyHandler, useFocuserContext } from '@focuser';
import cn from 'classnames';
import * as DOMPurify from 'dompurify';
import React, { useEffect, useMemo } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { Typography } from '~app/ui-kit/Typography';
import { ABOUT_CHANNEL, ABOUT_PROGRAM_EVENT, INFO_NOT_EXISTS } from '~lib/localizator/src/dictionaries';
import { getChannelLogo, getProgramEventLogo } from '~lib/MediaItemImageSRC';
import Channel from '~typings/Channel';
import ProgramEvent from '~typings/Event';
import ItemObject from '~typings/ItemObject';

import * as styles from './EPGEventDetails.module.css';

export type Props = {
  channel: Channel;
  isFocusOnChannels: boolean;
  programEvent?: ProgramEvent;
  className?: string;
  intl: InjectedIntl;
  beforeLeave: ()=> void;
};

const EPGEventDetails$: React.FC<Props> = ({
  channel,
  isFocusOnChannels,
  programEvent,
  intl,
  className,
  beforeLeave
}) => {
  const history = useHistory();
  const [focusedButton, setFocusedButton] = React.useState<'channel' | 'programEvent'>(
    'programEvent',
  );

  const {isFocused} = useFocuserContext();

  const infoNotExistsText = intl.formatMessage({ id: INFO_NOT_EXISTS });

  const handleProgramEventClick = (programEvent: ProgramEvent) => {
    beforeLeave();
    history.push(`/media-item-details/${ItemObject.ProgramEvent}/${programEvent.id}`);
  }

  const handleChannelClick = (channel: Channel) => {
    beforeLeave();
    history.push(`/media-item-details/${ItemObject.Channel}/${channel.id}`);
  }


  const nextProps = useMemo(() => {
    if (isFocusOnChannels || !programEvent) {
      const description = channel.description
        ? DOMPurify.sanitize(channel.description, { ALLOWED_TAGS: [] })
        : infoNotExistsText;
      const imageUrl = getChannelLogo(channel);
      return {
        description,
        imageUrl,
        type: 'forChannel' as const,
      };
    }

    const description = programEvent.description || infoNotExistsText;
    const imageUrl = getProgramEventLogo(programEvent);

    return {
      description,
      imageUrl,
      type: 'forProgramEvent' as const,
    };
  }, [channel, programEvent, isFocusOnChannels]);

  const isRenderingForProgramEvent = nextProps.type === 'forProgramEvent';


  const onKeyUp: FocuserKeyHandler = (e) => {
    if (focusedButton === 'channel' && isRenderingForProgramEvent) {
      setFocusedButton('programEvent');
      e.stop();
    }
  };

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (focusedButton === 'programEvent') {
      setFocusedButton('channel');
      e.stop();
    }
  };

  useEffect(() => {
    if(!isRenderingForProgramEvent && focusedButton === 'programEvent') {
      setFocusedButton('channel');
      return;
    }

    if(isRenderingForProgramEvent && focusedButton === 'channel' && !isFocused) {
      setFocusedButton('programEvent');
      return;
    }
  }, [isRenderingForProgramEvent]);

  return (
    <div className={ cn(className, styles.mainWrapper) }>
      <div className={ styles.divider } />
      <div className={ styles.contentWrapper }>
        <div
          className={ styles.image }
          style={ nextProps.imageUrl ? { backgroundImage: `url(${nextProps.imageUrl})` } : {} }
        />
        <Typography
          variant="subtitle1_medium"
          className={ cn(styles.description, {
            [styles.descriptionCentered]: nextProps.description === infoNotExistsText,
          }) }
        >
          {nextProps.description}
        </Typography>
        <div className={ styles.buttonsWrapper }>
          {isRenderingForProgramEvent ? (
            <PrimaryButtonFocuser
              onKeyDown={ onKeyDown }
              isFocused={ focusedButton === 'programEvent' }
              onForceFocus={ () => setFocusedButton('programEvent') }
              isPointerDownAvailable
              emitForceFocusOnHover
              focuserClassName={ styles.buttonFocuser }
              buttonClassName={ styles.button }
              onClick={ () => handleProgramEventClick(programEvent!) }
            >
              {intl.formatMessage({ id: ABOUT_PROGRAM_EVENT })}
            </PrimaryButtonFocuser>
          ) : null}
          <PrimaryButtonFocuser
            onKeyUp={ onKeyUp }
            isFocused={ focusedButton === 'channel' }
            onForceFocus={ () => setFocusedButton('channel') }
            isPointerUpAvailable
            emitForceFocusOnHover
            focuserClassName={ styles.buttonFocuser }
            buttonClassName={ styles.button }
            onClick={ () => handleChannelClick(channel) }
          >
            {intl.formatMessage({ id: ABOUT_CHANNEL })}
          </PrimaryButtonFocuser>
        </div>
      </div>
    </div>
  );
};

export const EPGEventDetails = React.memo(injectIntl(EPGEventDetails$));
