import { compact, find, get, maxBy, minBy } from 'lodash';

import bringingAudioTrackNameToStandard from '~lib/bringingAudioTrackNameToStandard';
import getSecontsFromPercents from "~lib/player/getSecontsFromPercents";
import sortVideoTracks from '~lib/player/sortVideoTracks';
import AudioTrack from '~typings/AudioTrack';
import PlayerAccessError from '~typings/PlayerStatus';
import PlayerType from '~typings/PlayerType';

import { Events, prepareTimeShiftUrl } from '../common';
import { IPlayer, PlayerEventSubscribe, PlayerLoadParameters, Props } from '../typings';
import onError from './error';


// enum PlayerState {
//   None = 'NONE',
//   Idle = 'IDLE',
//   Ready = 'READY',
//   Playing = 'PLAYING',
//   Paused = 'PAUSED',
// }


const TRACK_AUDIO = 'AUDIO';
const TRACK_VIDEO = 'VIDEO';


const tizenPlayer = ({ videoContainer, event }: Props): IPlayer => {
  let selectedAudioTrack: AudioTrack | null = null;
  let selectedVideoTrack: any | null = null;
  let currentTime: number = 0;
  let startTime: number | null = null;
  let params: PlayerLoadParameters | null = null;
  let switchToThisTime: number | null = null;
  let videoElement: HTMLVideoElement | null = null;
  let is4KSupported = false;

  // private method
  const onScreenSaver = () => {
    try {
      webapis.appcommon.setScreenSaver(1, () => {});
    } catch (ignore) {
      // ignore
    }

    try {
      webapis.appcommon.setScreenSaver(
        webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_ON,
        () => {}, () => {}
      );
    } catch (ignore) {
      // ignore
    }
  }

  // private method
  const offScreenSaver = () => {
    try {
      webapis.appcommon.setScreenSaver(0, () => {});
    } catch (ignore) {
      // ignore
    }

    try {
      webapis.appcommon.setScreenSaver(
        webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_OFF,
        () => {}, () => {}
      );
    } catch (ignore) {
      // ignore
    }
  }

  async function reset() {
    try {
      onScreenSaver();
      startTime = null;
      currentTime = 0;
      selectedAudioTrack = null;
      params = null;

      if (videoContainer && videoElement) {
        while (videoContainer.firstChild) {
          videoContainer.removeChild(videoContainer.firstChild);
        }

        videoElement = null;
      }

      webapis.avplay.stop();
      webapis.avplay.close();
    } catch (ignore) {

    }
  }

  const handleTrackChanged = () => {
    event.emit(Events.TRACK_CHANGED);
  };
  const dispose = () => {
    try {
      reset();
      webapis.avplay.close();
      if (videoContainer && videoElement) {
        videoContainer.removeChild(videoElement);
        videoElement = null;
      }
    } catch (ignore) {

    }
  };
  const getCurrentTime = (): number => currentTime;
  const getDuration = (): number => {
    try {
      if (params?.isLiveStream) {
        const [start, end] = webapis.avplay.getStreamingProperty('GET_LIVE_DURATION').split('|')

        return (
          webapis.avplay.getDuration() / 1000 ||
          parseInt(end, 10) - parseInt(start, 10) ||
          0
        );
      }
      return (webapis.avplay.getDuration() / 1000 || 0);
    } catch (e) {
      return (webapis.avplay.getDuration() / 1000 || 0);
    }
  };
  // const getPlayerState = (): PlayerState => webapis.avplay.getState();

  const createEl = () => {
    const el = document.createElement('object');

    el.setAttribute('type', 'application/avplayer');
    el.setAttribute('id', 'av-player');
    el.style.width = '100%';
    el.style.height = '100%';

    return el;
  };

  const drmEventCallback = (event, data) =>  {
    if (data.name === 'DrmError') {
      onError(event, 'PLAYER_ERROR_DRM_FAILED');
    }
  };

  const handleCurrentPlayTime = (time: number) => {
    currentTime = (time / 1000);
    event.emit(Events.TIME_UPDATE);
  };
  const handleEvent = (type, state) => {
    if (
      type === 'PLAYER_MSG_BITRATE_CHANGE'
      || type === 'PLAYER_MSG_RESOLUTION_CHANGED'
    ) {
      event.emit(Events.TRACK_CHANGED);
    }
    event.emit(Events.EVENT, { type, state });
  };
  const handleBufferingProgress = () => {
    event.emit(Events.BUFFERING_START);
  };
  const handleBufferingComplete = () => {
    event.emit(Events.BUFFERING_COMPLETE);
    event.emit(Events.PLAYING);
  };
  const handleStreamCompleted = () => {
    event.emit(Events.ENDED);
  };
  const handleError = (err) => {
    onError(event, err);
  };

  const addEvents = (hasDrm) => {
    if (!window.webapis || !webapis.avplay) {
      return;
    }
    webapis.avplay.setListener({
      oncurrentplaytime: handleCurrentPlayTime,
      onevent: handleEvent,
      // onbufferingstart: () => {},
      onbufferingprogress: handleBufferingProgress,
      onbufferingcomplete: handleBufferingComplete,
      onstreamcompleted: handleStreamCompleted,
      onerror: handleError,
      ...(hasDrm && { ondrmevent: drmEventCallback }),
    });
  };

  const prepareCallback = (language) => {
    try {
      const audioTracks = getAudioTracks();
      const preferredAudioTrack = audioTracks.find(({ name }) => (name === language));

      // console.log(preferredAudioTrack, language);

      if (preferredAudioTrack) {
        changeAudioTrack(preferredAudioTrack);
      } else if (audioTracks.length > 1) {
        changeAudioTrack(audioTracks[0]);
      }
    } catch (ignore) {
      // ignore
    }
    // console.log('getPlayerState():', getPlayerState());
    event.emit(Events.CAN_PLAY);
  };

  async function load(parameters: Readonly<PlayerLoadParameters>, videoTrack?: any | 'auto') {
    await reset();

    is4KSupported = !!parameters.platform.isUHDSupported;

    const playListsAttributes = (parameters.parsedManifest?.playlists || [])
      .map((item) => item.attributes);
    const supportedBandWidthCount = playListsAttributes
      .filter((item) => (
        item?.RESOLUTION?.width &&
        item?.RESOLUTION?.width <= 1920
      ))?.length || 0;

    if (!is4KSupported) {
      if (supportedBandWidthCount === 0) {
        onError(event, PlayerAccessError.CannotPlayStream);
        return;
      }
    }

    params = parameters;
    const osVersionNumber = parseInt(
      (parameters.platform.osVersion || '').toLowerCase().replace('tizen ', ''
      ).split('.')[0], 10);
    const playerUrl = prepareTimeShiftUrl(parameters.stream.url, parameters.timeShiftParams);
    // if (getProtocolByStreamURL(playerUrl) === Protocol.HLS) {
    //   playerUrl += '|COMPONENT=HLS';
    // }
    const DRMType = get(parameters.stream.drm, 'type');
    const licenseServer = get(parameters.stream.drm, 'license_server');
    const hasDRM = licenseServer && DRMType === 'playready';

    const videoEl = createEl();
    videoContainer?.appendChild(videoEl);

    addEvents(!!hasDRM);

    try {
      webapis.avplay.open(playerUrl);

      // if (seekTo !== undefined && !isNaN(seekTo)) {
      //   webapis.avplay.seekTo(seekTo * 1000);
      //
      //   switchToThisTime = null;
      // }

      /*
        https://developer.samsung.com/smarttv/develop/guides/multimedia/media-playback/using-avplay.html#drm-contents-playback-sequence
      */
      if (hasDRM) {
        const properties = {
          DeleteLicenseAfterUse: true,
          LicenseServer: licenseServer,
        };

        webapis.avplay.setDrm('PLAYREADY', 'SetProperties', JSON.stringify(properties));
      }
    } catch (error) {
      onError(event, 'PLAYER_ERROR_CONNECTION_FAILED');
      return;
    }

    try {
      webapis.avplay.setDisplayRect(0, 0, 1920, 1080);
      webapis.avplay.setDisplayMethod('PLAYER_DISPLAY_MODE_AUTO_ASPECT_RATIO');
      // webapis.avplay.setStreamingProperty('SET_MODE_4K', 'TRUE');
      const adaptiveInfo: string[] = [];

      if (is4KSupported) {
        const hasUHDTracks = playListsAttributes
          .find((item) => (
            item?.RESOLUTION?.width && item?.RESOLUTION?.width > 3800
          ))?.BANDWIDTH || null;

        // console.log('osVersionNumber:', osVersionNumber);
        // console.log('hasUHDTracks:', hasUHDTracks);
        // SET_MODE_4K Deprecated Snice 5 Version
        if (osVersionNumber < 5 && hasUHDTracks) {
          // console.log('SET_MODE_4K');
          webapis.avplay.setStreamingProperty('SET_MODE_4K', 'TRUE'); //for 4K contents
        }
      }

      if (videoTrack && videoTrack !== 'auto') {
        if (videoTrack?.attributes?.BANDWIDTH) {
          // "450001", "830002", "1500003", "4000004", "6500005"
          adaptiveInfo.push(`BITRATES=500~${videoTrack?.attributes?.BANDWIDTH}`);
          adaptiveInfo.push(`STARTBITRATE=${videoTrack?.attributes?.BANDWIDTH}`);
          adaptiveInfo.push(`SKIPBITRATE=HIGHEST`);

          // adaptiveInfo.push(`SKIPBITRATE=830002~6500005`);
          // adaptiveInfo.push(`STARTBITRATE=${videoTrack?.attributes?.BANDWIDTH}`);

          // webapis.avplay.setStreamingProperty(
          //   'ADAPTIVE_INFO',
          //   `BITRATES=${videoTrack?.attributes?.BANDWIDTH}`,
          // );
          selectedVideoTrack = videoTrack;
          //handleTrackChanged();
        }
      } else {
          const minBandWidth = minBy(playListsAttributes, (item) => item?.BANDWIDTH)?.BANDWIDTH || 0;
          let maxBandWidth = maxBy(playListsAttributes, (item) => item?.BANDWIDTH)?.BANDWIDTH || 0;
          if (!is4KSupported) {
            const firstUnsupportedBandWidth = playListsAttributes
              .find((item) => (
                item?.RESOLUTION?.width && item?.RESOLUTION?.width > 1920
              ))?.BANDWIDTH || null;

            if (firstUnsupportedBandWidth) {
              maxBandWidth = firstUnsupportedBandWidth - 1;
            }
          }

          adaptiveInfo.push(`BITRATES=${minBandWidth}~${maxBandWidth}`);
      }

      // if (switchToThisTime) {
      //   // webapis.avplay.seekTo(switchToThisTime * 1000);
      //   adaptiveInfo.push(`START_TIME=${switchToThisTime * 1000}`)
      //
      //   switchToThisTime = null;
      // }

      if (adaptiveInfo.length > 0) {
        webapis.avplay.setStreamingProperty('ADAPTIVE_INFO', adaptiveInfo.join('|'));
      }

      // webapis.avplay.setStreamingProperty('PREBUFFER_MODE', '3000');

      // console.log('ADAPTIVE_INFO', adaptiveInfo.join('|'));
      // webapis.avplay.setStreamingProperty("ADAPTIVE_INFO", "FIXED_MAX_RESOLUTION=3840x2160");

      webapis.avplay.prepareAsync(() => {
        // console.log('parameters.language:', parameters.language);
        prepareCallback(parameters.language);
        if (switchToThisTime !== null) {
          seekTo(switchToThisTime);
          switchToThisTime = null;
        } else if (parameters.percentsWatched) {
          seekTo(getSecontsFromPercents(parameters.percentsWatched, getDuration()));
        }
      }, (error) => {
        // console.log('Error prepareAsync CallBack', error);
        onError(event, error);
      });
    } catch (ignore) {
      // ignore
      // console.log('ignore:', ignore);
      if (switchToThisTime) {
        switchToThisTime = null;
      }
    }
  }

  const mute = () => {};
  const on: PlayerEventSubscribe = (target, listener) => event.on(target, listener);

  const removeListener: PlayerEventSubscribe = (target, listener) => {
    event.removeListener(target, listener);
  };

  const play = (): void => {
    try {
      offScreenSaver();
      webapis.avplay.play();
      event.emit(Events.PLAY);
    } catch (ignore) {
      // ignore
    }
  };

  const pause = () => {
    try {
      onScreenSaver();
      webapis.avplay.pause();
      event.emit(Events.PAUSE);
    } catch (err) {
      // ignore
    }
  };

  const seekTo = (time: number) => {
    if (startTime === null) {
      startTime = currentTime;
    }
    const diff = (startTime - currentTime) - (startTime - time);
    if (diff === 0) {
      return;
    }

    play();

    event.emit(Events.SEEKING);

    // need set time in ms
    if (diff < 0) {
      try {
        webapis.avplay.jumpBackward(Math.abs(diff) * 1000);
      } catch (err) {
        // ignore
      }
    } else {
      try {
        webapis.avplay.jumpForward(Math.abs(diff) * 1000);

      } catch (err) {
        // ignore
      }
    }

    setTimeout(() => {
      event.emit(Events.SEEKED);
    }, 100);
  };

  const stop = () => {
    reset();
  };

  const isAutoQualityEnabled = () => (selectedVideoTrack === null);
  const setAutoQuality = () => {
    if (params) {
      selectedVideoTrack = null;
      switchToThisTime = currentTime;
      load(params, 'auto');
      handleTrackChanged();
    }
  };

  const changeAudioTrack = (audioTrack: AudioTrack) => {
    try {
      webapis.avplay.setSelectTrack(TRACK_AUDIO, audioTrack.index);
      selectedAudioTrack = audioTrack;
      // console.log('selectedAudioTrack:', selectedAudioTrack);
      if (params) {
        params.language = audioTrack.name;
      }
      event.emit(Events.TRACK_CHANGED);
    } catch (ignore) {
      // console.log('ignore:', ignore);
      // ignore
    }
  };
  const changeVideoTrack = (videoTrack) => {
    try {
      // const playerVideoTracks = getNativeVideoTracks();

      // if (playerVideoTracks.length > 0) {
      //   console.log('setSelectTrack', videoTrack);
      //   webapis.avplay.setSelectTrack(TRACK_VIDEO, videoTrack.index);
      //   selectedVideoTrack = videoTrack;
      // } else {
        if (params && videoTrack.data?.attributes?.BANDWIDTH) {
          switchToThisTime = currentTime;
          load(params, videoTrack.data);
        }
      // }
    } catch (ignore) {
      // ignore
    }
  };
  const getAudioTracks = () => {
    try {
      const audioTracks: AudioTrack[] = [];

      // console.log('params?.parsedManifest:', params?.parsedManifest);
      // console.log('selectedAudioTrack:', selectedAudioTrack);
      webapis.avplay.getTotalTrackInfo().forEach((item) => {
        const extraInfo = JSON.parse(item.extra_info.toLowerCase());
        // console.log(item, extraInfo);

        if (item.type === TRACK_AUDIO) {
          audioTracks.push({
            index: item.index,
            name: bringingAudioTrackNameToStandard(extraInfo.language),
            data: extraInfo,
            isSelected: !!(selectedAudioTrack && selectedAudioTrack.index === item.index),
          });
        }
      });

      return audioTracks;
    } catch (ignore) {
      return [];
    }
  };
  // const getNativeVideoTracks = () => {
  //   try {
  //     return webapis.avplay
  //       .getTotalTrackInfo()
  //       .filter((item) => (item.type === TRACK_VIDEO)) || [];
  //   } catch (ignore) {
  //     // ignore
  //     return [];
  //   }
  // };
  const getVideoTracks = () => {
    try {
      // const playerVideoTracks = getNativeVideoTracks();
      // console.log('playerVideoTracks:',  playerVideoTracks);
      //
      // if (playerVideoTracks.length) {
      //   const selectedTrackExtraInfo = JSON.parse(
      //     find(webapis.avplay.getCurrentStreamInfo(), { type: TRACK_VIDEO })?.extra_info || {}
      //   );
      //   console.log('selectedTrackExtraInfo:',  selectedTrackExtraInfo);
      //   console.log('selectedVideoTrack:',  selectedVideoTrack);
      //   return (
      //     playerVideoTracks.map((item) => {
      //       const extraInfo = JSON.parse(item.extra_info);
      //
      //       return ({
      //         index: item.index,
      //         name: `${extraInfo.Width}x${extraInfo.Height}`,
      //         isSelected: (
      //           selectedVideoTrack !== null &&
      //           selectedVideoTrack?.data?.Width === extraInfo?.Width
      //         ),
      //         data: extraInfo,
      //       });
      //     })
      //   );
      // } else {

        // console.log('getNativeVideoTracks():',  getNativeVideoTracks());
        // console.log('selectedTrackExtraInfo:',  find(webapis.avplay.getCurrentStreamInfo(), { type: TRACK_VIDEO }));
        // eslint-disable-next-line
        // console.log('AVAILABLE_BITRATE:', (webapis.avplay.getStreamingProperty('AVAILABLE_BITRATE') || '').split(':'));
        return sortVideoTracks(
          compact(
            (params?.parsedManifest?.playlists || []).map((video, index) => {
              if (
                !video?.attributes?.RESOLUTION?.width ||
                !video?.attributes?.BANDWIDTH
              ) { return null; }

              if (!is4KSupported && video.attributes.RESOLUTION.width > 1920) { return null; }

              return ({
                index,
                isSelected: (selectedVideoTrack?.attributes?.BANDWIDTH === video.attributes?.BANDWIDTH),
                name: `${video.attributes.RESOLUTION.width}x${video.attributes.RESOLUTION.height}`,
                data: video,
              });
            })
          )
        );
      // }
    } catch (ignore) {
      return [];
    }
  };
  const getSelectedAudioTrack = () => {
    find(webapis.avplay.getCurrentStreamInfo(), { type: TRACK_AUDIO });
  };
  const getSelectedVideoTrack = () => {
    return find(webapis.avplay.getCurrentStreamInfo(), { type: TRACK_VIDEO });
  };

  const getProperties = () => {
    const res: any = {};

    if (webapis.avplay) {
      const properties = [
        'COOKIE',
        'USER_AGENT',
        'PREBUFFER_MODE',
        'ADAPTIVE_INFO',
        'SET_MODE_4K',
        'PROPERTY_HD_AUDIO',
        'LISTEN_SPARSE_TRACK',
        'IS_LIVE',
        'AVAILABLE_BITRATE',
        'GET_LIVE_DURATION',
        'CURRENT_BANDWIDTH',
        'WIDEVINE',
      ];

      const streamingProperties = {};
      properties.forEach((prop) => {
        try {
          const streamingProperty = webapis.avplay.getStreamingProperty(prop);
          if (streamingProperty.length !== 0) {
            streamingProperties[prop] = streamingProperty;
          }
        } catch (err) {
          // ignore
        }
      });

      res.streamingProperties = streamingProperties;
    }

    if (videoElement?.getVideoPlaybackQuality) {
      res.videoPlaybackQuality = videoElement.getVideoPlaybackQuality();
    }

    return res;
  }

  return {
    type: PlayerType.Tizen,

    dispose,
    getCurrentTime,
    getDuration,
    load,
    mute,
    on,
    removeListener,
    pause,
    play,
    reset,
    seekTo,
    stop,

    isAutoQualityEnabled,
    setAutoQuality,

    changeAudioTrack,
    changeVideoTrack,
    getAudioTracks,
    getVideoTracks,
    getSelectedAudioTrack,
    getSelectedVideoTrack,

    getProperties,
  };
};


export default tizenPlayer;
