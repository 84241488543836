import * as React from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { ProductPage } from '~components/ProductPage';
import ProductCollectionPage from '~components/ProductPage/CollectionPage/ProductCollectionPage';
import ProductPlanPage from '~components/ProductPlanPage/ProductPlanPage';
import useProducts from '~hooks/fetch/useProducts';
import { ResourceType } from '~hooks/fetch/useProductsItems/useProductsItems';
import getProductInSubscriptions from '~lib/product/getProductInSubscriptions';
import { AUDIO_SHOWS_IN_SUBSCRIPTION, CHANNELS_IN_SUBSCRIPTION, MOVIES_IN_SUBSCRIPTION, SERIES_IN_SUBSCRIPTION } from '~localization';
import Subscription from '~typings/Subscription';

type Props = {
  subscriptions?: Subscription[];
};

export const ProductRoutes: React.FC<Props> = ({ subscriptions }) => {
  const { path } = useRouteMatch();

  const { productID } = useParams<{ productID: string }>();
  const { data: products } = useProducts({ productID });
  const productInSubscriptions = React.useMemo(
    () => {
      return products?.data[0] ? getProductInSubscriptions(products.data[0], subscriptions) : null
    },
    [products?.data, subscriptions]
  );

  if (!productInSubscriptions) { return null; }

  return (
    <Switch>
      <Route path={ `${path}/collection-page/${ResourceType.Channels}` }>
        <ProductCollectionPage
          id={ CHANNELS_IN_SUBSCRIPTION }
          product={ productInSubscriptions }
          resourceTypes={ [ResourceType.Channels] }
        />
      </Route>
      <Route path={ `${path}/collection-page/${ResourceType.Movies}` }>
        <ProductCollectionPage
          id={ MOVIES_IN_SUBSCRIPTION }
          product={ productInSubscriptions }
          resourceTypes={ [ResourceType.Movies] }
        />
      </Route>
      <Route path={ `${path}/collection-page/${ResourceType.Series}` }>
        <ProductCollectionPage
          id={ SERIES_IN_SUBSCRIPTION }
          product={ productInSubscriptions }
          resourceTypes={ [ResourceType.Series] }
        />
      </Route>
      <Route path={ `${path}/collection-page/${ResourceType.AudioShows}` }>
        <ProductCollectionPage
          id={ AUDIO_SHOWS_IN_SUBSCRIPTION }
          product={ productInSubscriptions }
          resourceTypes={ [ResourceType.AudioShows] }
        />
      </Route>
      <Route path={ `${path}/plan` }>
        <ProductPlanPage
          product={ productInSubscriptions }
          subscriptions={ subscriptions }
        />
      </Route>
      <Route path={ `${path}/rent` }>
        RENT
      </Route>
      <Route path={ path } exact>
        <ProductPage product={ productInSubscriptions } />
      </Route>
    </Switch>
  )
};
