import * as cn from 'classnames';
import * as React from 'react';

import Portal from '~ui/Portal/Portal';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocusedBlock?: boolean;
  isVisible?: boolean;
  isFadeOut: boolean;
  isFixed?: boolean;
  children: any;
  noPortal?: boolean;
  searchPageExpand?: boolean;
  blockTitleClassName?: string;
  className?: string;
}>;


const BlockTitle: React.FC<Props> = (props: Props) => {
  if (props.isFixed) {
    return (
      <Portal id="block_title">
        <div className={ cn(styles.blockTitle, styles.fixedBlockTitle,
          { [styles.isVisible]: !props.isFadeOut }) }>
          { props.children }
        </div>
      </Portal>
    );
  }

  if (props.searchPageExpand) {
    return (
      <div className={ cn(
        styles.searchPageExpand,
        { [styles.isVisible]: !props.isFadeOut })
      }>
        { props.children }
      </div>
    )
  }

  if (props.noPortal) {
    return (
      <div className={ cn(
        props.isFocusedBlock
          ? styles.noPortalTitle
          : styles.noPortalTitleNoFocus,
        { [styles.isVisible]: !props.isFadeOut })
      }>
        { props.children }
      </div>
    )
  }

  return (
    <div className={ cn(styles.blockTitleWrapper, props.className) }>
      <div
        className={ cn(
          styles.blockTitle,
          { [styles.isVisible]: !props.isFocusedBlock },
          props.blockTitleClassName,
        ) }
      >
        {props.children}
      </div>
      <Portal id="block-title">
        <div
          className={ cn(styles.blockTitle, styles.fixedBlockTitle, {
            [styles.isVisible]: props.isFocusedBlock,
          }) }
        >
          {props.children}
        </div>
      </Portal>
    </div>
  );
};


export default React.memo(BlockTitle);
