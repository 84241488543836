import focuser, { useFocuserThrottledHandler } from "@focuser";
import * as React from "react";
import { useEffect, useState } from "react";

import useSwitchChannelsList from "~hooks/useSwitchChannel/channels";
import Channel from "~typings/Channel";

import { ChannelBlock } from "./ChannelBlock/ChannelBlock";
import * as css from './ChannelFlipper.module.css'
import { SliderVerticableVirtual } from "./slider/SliderVerticableVirtual";

type Props = {
  channelID: string
  onClose: () => void
}

const ChannelFlipper$ = (props: Props) => {
  const channels = useSwitchChannelsList()
  const [focused, setFocused] = useState<number>(0)
  const [firstRender, setFirstRender] = useState(false)

  const handleScrollDown = useFocuserThrottledHandler(() => {
    if (focused + 1 > channels.length - 1) {
      return
    }
    setFocused(focused + 1)
  })

  const handleScrollUp = useFocuserThrottledHandler(() => {
    if (focused - 1 < 0) {
      return
    }
    setFocused(focused - 1)
  })

  const handleGoBack = () => {
    props.onClose()
  }

  const RenderMyItem = (idx: number) => {
    return <ChannelBlock
      isFocused={ idx === focused }
      setFocused={ setFocused }
      key={ idx }
      index={ idx }
      channel={ channels[idx] as Channel }
    />
  }

  useEffect(() => {
    if (channels.length !== 0 && !firstRender) {
      //это костыль, чтобы как только появятся каналы, сфокусироваться на том канале который прилетел сверху
      const focused = channels.findIndex(item => props.channelID === item.id)
      setFirstRender(true)
      setFocused(focused)
    }
  }, [channels])

  return (
    <div className={ css.flipperWrapper }>
      <focuser.FocusableBlock
        isFocused
        onKeyDown={ handleScrollDown }
        onKeyUp={ handleScrollUp }
        onKeyReturn={ handleGoBack }
        noNeedForceFocus
      >
        <SliderVerticableVirtual
          className={ css.wrapperSlider }
          focusedIndex={ focused }
          childsCount={ channels.length }
          renderChild={ RenderMyItem }
          itemHeight={ 22.916666666666664 }
          maxInDOM={ 10 }
          transitionGapUp={ 0 }
          transitionGapDown={ 1 }
        />
      </focuser.FocusableBlock>
    </div>
  )
}

export const ChannelFlipper = ChannelFlipper$