/* eslint-disable max-len */
import * as React from 'react';


const IconEPG: React.FC = () => (
  <svg
    className="playerIcon"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.594,23.531h-11a1.5,1.5,0,0,1-1.5-1.5h0a1.5,1.5,0,0,1,1.5-1.5h11a1.5,1.5,0,0,1,1.5,1.5h0A1.5,1.5,0,0,1,24.594,23.531Zm-18.5-1.5h0a1.5,1.5,0,0,0,1.5,1.5h1a1.5,1.5,0,0,0,1.5-1.5h0a1.5,1.5,0,0,0-1.5-1.5h-1A1.5,1.5,0,0,0,6.094,22.031Zm20-6h0a1.5,1.5,0,0,0-1.5-1.5h-11a1.5,1.5,0,0,0-1.5,1.5h0a1.5,1.5,0,0,0,1.5,1.5h11A1.5,1.5,0,0,0,26.094,16.031Zm-20,0h0a1.5,1.5,0,0,0,1.5,1.5h1a1.5,1.5,0,0,0,1.5-1.5h0a1.5,1.5,0,0,0-1.5-1.5h-1A1.5,1.5,0,0,0,6.094,16.031Zm20-6h0a1.5,1.5,0,0,0-1.5-1.5h-11a1.5,1.5,0,0,0-1.5,1.5h0a1.5,1.5,0,0,0,1.5,1.5h11A1.5,1.5,0,0,0,26.094,10.031Zm-20,0h0a1.5,1.5,0,0,0,1.5,1.5h1a1.5,1.5,0,0,0,1.5-1.5h0a1.5,1.5,0,0,0-1.5-1.5h-1A1.5,1.5,0,0,0,6.094,10.031Z"
      fill="currentColor"
    />
  </svg>
);


export default React.memo(IconEPG);
