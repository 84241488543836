/* global window */
import * as React from 'react';

import { State } from '~stores/HotKeys';
import HotKey from '~typings/HotKey';
import KeyCodes from '~typings/KeyCodes';
import NavigationDirection from '~typings/NavigationDirection';
import PlatformName from '~typings/PlatformName';

type DirectionsByKeyCode = Readonly<{ [x: number]: NavigationDirection; }>;


const getDirectionsByKeyCode = (keyCodes: KeyCodes): DirectionsByKeyCode => ({
  [keyCodes.KEY_LEFT as number]: NavigationDirection.Left,
  [keyCodes.KEY_RIGHT as number]: NavigationDirection.Right,
  [keyCodes.KEY_UP as number]: NavigationDirection.Up,
  [keyCodes.KEY_DOWN as number]: NavigationDirection.Down,
});


type KeyNames = Readonly<{ [key: number]: HotKey; }>;


const getKeyNames = (keyCodes: KeyCodes): KeyNames => {
  const result = {};

  Object.keys(keyCodes).forEach((key) => {
    // @ts-ignore
    const value = keyCodes[key];

    if (Array.isArray(value)) {
      value.forEach((valueItem) => {
        // @ts-ignore
        result[valueItem] = key;
      });
    } else {
      // @ts-ignore
      result[value] = key;
    }
  });

  return result;
};


const simulateClick = (node: Element | null) => {
  if (!node) { return; }

  const mouseEvent = document.createEvent('MouseEvents');
  mouseEvent.initMouseEvent(
    'click', // type
    true, // canBubble
    true, // cancelable
    window, // view
    0, // detail,
    0, // screenX
    0, // screenY
    0, // clientX
    0, // clientY
    false, // ctrlKey
    false, // altKey
    false, // shiftKey
    false, // metaKey
    0, // button
    null, // relatedTarget
  );

  node.dispatchEvent(mouseEvent);
};


type OnKeyDownProps = Readonly<{
  keyCodes: KeyCodes;
  keyNames: KeyNames;
  prevKeyName: { current: string | null; };
  keyDownEvent: React.KeyboardEvent;
  // getFocusedNode: () => HTMLElement;
  // moveInDirection: (direction: NavigationDirection) => void;
  changePointerEnabled?: (isPointerVisibility: boolean) => void;
  applyHotKey: State['apply'];
}>;


const onKeyDown = (properties: OnKeyDownProps) => {
  // const directionsByKeyCode: DirectionsByKeyCode = getDirectionsByKeyCode(properties.keyCodes);
  const keyCode = properties.keyDownEvent.keyCode;
  const keyName = properties.keyNames[keyCode];

  if (keyName === HotKey.KEY_ENTER || keyName === HotKey.KEY_RETURN) {
    if (properties.prevKeyName.current === keyName) {
      return;
    }

    properties.prevKeyName.current = keyName;
  }


  if (properties.changePointerEnabled) {
    properties.changePointerEnabled(false);
  }
  properties.applyHotKey(keyName, properties.keyDownEvent);

  // const direction = directionsByKeyCode[keyCode];

  // if (direction) {
    // console.log('TODO direction:', direction);
    // properties.moveInDirection(direction);
  // }

  // TODO solve philips problem – move platform related code to platform package
  // for philips platform need remove native focus
  // otherwise philips show native stupid focus SPBTV-5567
  // if (__PLATFORM__ === __PLATFORM_NAME_PHILIPS__) {
  //   document.body.focus();
  // }

  const isEnterKey = keyName === HotKey.KEY_ENTER;
  if (isEnterKey) {
    const focusedNode = window.document.getElementsByClassName('focusedNavigationNode');

    if (focusedNode && focusedNode.length !== 0) {
      simulateClick(focusedNode[0]);
    }
  }

  // for philips platform need remove native focus
  // otherwise philips show native stupid focus SPBTV-5567
  if (
    // __PLATFORM__ === PlatformName.Philips ||
    __PLATFORM__ === PlatformName.Hisense ||
    __PLATFORM__ === PlatformName.Vewd ||
    __PLATFORM__ === PlatformName.TSL
  ) {
    properties.keyDownEvent.preventDefault();
    properties.keyDownEvent.stopPropagation();
  }
};


export {
  DirectionsByKeyCode,
  getDirectionsByKeyCode,
  getKeyNames,
  KeyNames,
  NavigationDirection,
  onKeyDown,
  OnKeyDownProps,
  simulateClick,
};
