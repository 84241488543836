import * as React from 'react';

import { PlayButtonState } from '~components/ScenePlayer/Player/controls/PlayPauseButton';
import { isChannel } from '~components/ScenePlayer/Player/utils';
import Channel from '~typings/Channel';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Stream from '~typings/Stream';

import ChannelProgressBar from './channel';
import ProgressBarView from './ProgressBarView';


type Props = Readonly<{
  item: PlayerMediaItem;
  isLoading: boolean;
  isPlaying: boolean;
  isFocused: boolean;
  isFocusedGoToAir: boolean;
  durationInSec: number;
  currentTimeInSec: number;
  onChangeIsVisibleGoToAir: (isVisible: boolean) => void;
  onSeek: (seekTo: number) => void;
  onKeyPressedToSeek: (seekTo: PlayButtonState.SeekToBackward | PlayButtonState.SeekToForward) => void;
  stream?: Stream;
  isSeeking?: boolean;
  status?: any;
}>;


const ProgressBar: React.FC<Props> = (props: Props) => {
  if (isChannel(props.item)) {
    return (
      <ChannelProgressBar
        channelId={ props.item.id }
        isLoading={ props.isLoading }
        isPlaying={ props.isPlaying }
        isFocused={ props.isFocused }
        isFocusedGoToAir={ props.isFocusedGoToAir }
        timeShift={ (props.item as Channel).timeshift_availability }
        onChangeIsVisibleGoToAir={ props.onChangeIsVisibleGoToAir }
        currentTimeInSec={ props.currentTimeInSec }
        durationInSec={ props.durationInSec }
        onSeek={ props.onSeek }
        onKeyPressedToSeek={ props.onKeyPressedToSeek }
      />
    );
  }

  return (
    <ProgressBarView
      isLoading={ props.isLoading }
      isFocused={ props.isFocused }
      durationInSec={ props.durationInSec }
      currentTimeInSec={ props.currentTimeInSec }
      onSeek={ props.onSeek }
      onKeyPressedToSeek={ props.onKeyPressedToSeek }
      stream={ props.stream }
      isSeeking={ props.isSeeking }
      status={ props.status }
      item={ props.item }
    />
  );
};

export default React.memo(ProgressBar);
