import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

// import BrowsingHistoryPage from '~components/PersonalPage/BrowsingHistory';
import FavoritesPage from '~components/PersonalPage/Favorites/Favorites';
import PersonalPageUnauthorized from '~components/PersonalPage/PersonalPageUnauthorized/PersonalPageUnauthorized';
import SideBarLayout from '~components/PersonalPage/SideBarLayout/SideBarLayout';
import WatchLater from '~components/PersonalPage/WatchLater/WatchLater';
import PinCodePopup from '~components/PinCodePopup';
import ProfileImage from '~components/ProfileImage';
import { useApp } from '~components/Provider/App';
import { getAuthData, useAccount } from '~hooks/fetch/useAccount';
import { usePageByType } from '~hooks/fetch/usePages/usePages';
import { useProfiles } from '~hooks/fetch/useProfiles';
import {
  MY_VIDEO_LIBRARY,
  PERSONAL_UNAUTHORIZED_CHANNELS,
  PERSONAL_UNAUTHORIZED_CONTINUE_WATCHING,
  PERSONAL_UNAUTHORIZED_FAVORITES
} from '~localization';
import useSideBarNavigation, { FOCUS_ON } from "~stores/SideBarNavigation";
import ContinueWatchingType from '~typings/ContinueWatchingType';
import FavoriteType from '~typings/FavoriteType';
import PageObject from '~typings/PageObject';
import Profile from '~typings/Profile';

import { getMenu, PersonalPageMenuID, useMenuFocusedIndex, usePageId } from './routes.helpers';
import * as styles from './styles.module.css';

type Props = Readonly<{
  intl: InjectedIntl;
}>;


const PersonalScreen = (props: Props) => {
  const { path, params } = useRouteMatch<{ pageId: string; }>();
  const history = useHistory();
  const personalPage = usePageByType(PageObject.PersonalPage);
  const { tabId, resourceTypes } = usePageId();
  const editMode = useSideBarNavigation(store => store.editMode);

  const menuItems = React.useMemo(
    () => getMenu(params.pageId, props.intl, personalPage),
    [personalPage, params.pageId],
  );

  const menuFocusedIndex = useMenuFocusedIndex({
    tabId,
    resourceTypes,
    items: menuItems,
  });
  const { accessToken } = getAuthData();
  const { activeProfileId } = useApp();
  const { data: profiles = [] } = useProfiles();
  const activeProfile: Profile | undefined = profiles.find(({ id }) => (id === activeProfileId));
  const isKids = !!activeProfile?.kids;
  const account = useAccount();
  const [isPinCodeShow, setPinCodeShow] = React.useState<boolean>(false);
  const isPinVerificationCompele = React.useRef<boolean>(false);
  const setFocusOn = useSideBarNavigation(st => st.setFocusOn);

  const [navigationItemsContent, setNavigationItemsContent] = React.useState<boolean>(false);

  React.useEffect(() => () => {
    setNavigationItemsContent(!accessToken);
  }, [history.location.pathname, accessToken]);

  React.useEffect(() => {
    setNavigationItemsContent(!accessToken);
  }, [accessToken]);

  React.useEffect(() => {
    if (
      !tabId
      && !resourceTypes
      && menuItems?.[0]?.link
    ) {
      history.replace(menuItems[0].link);
    }
  }, [tabId, resourceTypes, menuItems]);

  React.useEffect(() => {
    if (
      !isPinVerificationCompele.current &&
      activeProfile &&
      account?.data?.security_pin === true &&
      (!!activeProfile?.id && !isKids)
    ) {
      setPinCodeShow(true);
    }
  }, [
    isPinVerificationCompele.current,
    activeProfile,
    account?.data?.security_pin,
    activeProfile?.id,
    isKids,
  ]);

  React.useEffect(() => {
    setFocusOn(FOCUS_ON.LEFT_MENU);
  }, [])


  if (isPinCodeShow) {
    return (
      <PinCodePopup
        onSuccess={ () => {
          isPinVerificationCompele.current = true;
          setPinCodeShow(false);
        } }
        onBack={ () => history.goBack() }
      />
    )
  }

  if (accessToken && !activeProfile?.id) {
    return null;
  }

  return (
    <>
      <div className={ styles.container }>
        <div className={ styles.headerWrapper }>
          <ProfileImage
            className={ styles.profileImage }
            profile={ activeProfile }
            width={ 94 }
            height={ 94 }
          />
          <div className={ styles.headerText }>
            { personalPage?.name || props.intl.formatMessage({ id: MY_VIDEO_LIBRARY }) }
          </div>
        </div>
        <div className={ styles.contentContainer }>
          <div className={ editMode ? styles.sidebarContainerClosed : styles.sidebarContainer }>
            <SideBarLayout
              layoutID="PersonalScreen"
              menuItems={ menuItems }
              menuFocusedIndex={ menuFocusedIndex }
              navigationItemsContent={ navigationItemsContent }
            />
          </div>
          {
            accessToken
              ? <Switch>
                <Route path={ `${ path }/${ PersonalPageMenuID.watch_later }` }>
                  <div className={ styles.authTiledViewWrapper }>
                    <WatchLater
                      types={ (resourceTypes || '').split(',') as ContinueWatchingType[] }
                      onNavigationItemsReady={ setNavigationItemsContent }
                    />
                  </div>
                </Route>
                <Route path={ `${ path }/${ PersonalPageMenuID.favorites }` }>
                  <div className={ styles.authTiledViewWrapper }>
                    <FavoritesPage
                      types={ (resourceTypes || '').split(',') as FavoriteType[] }
                      onNavigationItemsReady={ setNavigationItemsContent }
                    />
                  </div>
                </Route>
              </Switch>
              : <div className={ styles.noAuthWrapper }>
                <Switch>
                  <Route path={ `${ path }/${ PersonalPageMenuID.favorites }` }>
                    <PersonalPageUnauthorized
                      id={ resourceTypes === FavoriteType.Channels
                        ? PERSONAL_UNAUTHORIZED_CHANNELS
                        : PERSONAL_UNAUTHORIZED_FAVORITES
                      }
                    />
                  </Route>
                  <Route path={ `${ path }/${ PersonalPageMenuID.watch_later }` }>
                    <PersonalPageUnauthorized id={ PERSONAL_UNAUTHORIZED_CONTINUE_WATCHING }/>
                  </Route>
                  <Route exact path={ path }>
                    <PersonalPageUnauthorized id={ PERSONAL_UNAUTHORIZED_CONTINUE_WATCHING }/>
                  </Route>
                </Switch>
              </div>
          }
        </div>
      </div>
    </>
  );
};


export default React.memo(injectIntl(PersonalScreen));
