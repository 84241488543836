import { FocuserKeyHandler } from '@focuser';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useProducts from '~hooks/fetch/useProducts';
import { useAllSubscriptions } from '~hooks/fetch/useSubscriptions';
import { useLocationSearchState } from '~hooks/useLocationSearchState';
import getProductInSubscriptions from '~lib/product/getProductInSubscriptions';
import Product from '~typings/Product';
import Subscription from '~typings/Subscription';

import { SUBSCRIPTIONS_FOCUSED_SEARCH_PARAM } from './utils';

type UseGetProductsReturn = {
  availableProducts: Product[];
  userSubscriptions: Subscription[];
  isLoading: boolean;
};
export const useGetProducts = (): UseGetProductsReturn => {
  const { isLoading: subscriptionsIsLoading, subscriptions } = useAllSubscriptions();
  const { data: products, isLoading: productsIsLoading } = useProducts({ enabled: true });

  const { availableProducts, userSubscriptions } = useMemo(() => {
    if (!products?.data || !subscriptions || productsIsLoading || subscriptionsIsLoading) {
      return {
        availableProducts: [],
        userSubscriptions: [],
      };
    }

    const availableProducts = products.data
      .filter((product) => {
        const subscriptionsWithProduct = subscriptions.filter(
          (s) => product.id === s?.plan?.product?.id,
        );
        const activeOrInactiveSubcriptiopn = subscriptionsWithProduct.some(
          (s) => s.status === 'active' || (s.access_granted && s.expires_at),
        );

        if (activeOrInactiveSubcriptiopn) {
          return false;
        }

        return true;
      })
      .map((product) => getProductInSubscriptions(product, subscriptions));

    const filteredSubscriptions = subscriptions.filter((el) => {
      return el.status === 'active' || (el.access_granted && el.expires_at !== null);
    });

    const productToSubscriptionMap = new Map<string, Subscription>();

    filteredSubscriptions.forEach((currentSub) => {
      const product = currentSub.plan.product;
      const prevSub = productToSubscriptionMap.get(product.id);
      if (!prevSub) {
        productToSubscriptionMap.set(product.id, currentSub);
        return;
      }

      if (prevSub.status === 'active') {
        return;
      }

      if (currentSub.status === 'active') {
        productToSubscriptionMap.set(product.id, currentSub);
        return;
      }

      const prevExpires = prevSub.expires_at;
      const currentExpires = currentSub.expires_at;

      if (prevExpires === null) {
        productToSubscriptionMap.set(product.id, currentSub);
        return;
      }

      if (currentExpires === null) {
        return;
      }

      if (prevExpires > currentExpires) {
        return;
      }

      productToSubscriptionMap.set(product.id, currentSub);
    });

    const userSubscriptions = Array.from(productToSubscriptionMap.values());

    return {
      availableProducts,
      userSubscriptions,
    };
  }, [subscriptions, products]);

  return {
    availableProducts,
    userSubscriptions,
    isLoading: subscriptionsIsLoading || productsIsLoading,
  } as const;
};

type UseSubscriptionsNavigationProps = {
  availableProductsCount: number;
  userSubscriptionsCount: number;
  isLoading: boolean;
  isSubscriptionCancelableOnIdx: (subscriptionIdx: number) => boolean;
};

type FocusedState = {
  focusedSection: 'subscriptions' | 'products';
  focusedLine: number;
  focusedOn: 'text' | 'button';
};

export const useSubscriptionsNavigation = ({
  availableProductsCount,
  userSubscriptionsCount,
  isSubscriptionCancelableOnIdx,
  isLoading,
}: UseSubscriptionsNavigationProps) => {
  const [focusedState, setFocusedState] = useState<FocusedState>({
    focusedSection: 'subscriptions',
    focusedLine: 0,
    focusedOn: 'text',
  });

  const {
    parsedState,
    clearState: clearStateInSearch,
    setNewState: setNewStateInSearch,
  } = useLocationSearchState<FocusedState>({
    pathInSearch: SUBSCRIPTIONS_FOCUSED_SEARCH_PARAM,
  });

  const focusedStateRef = useRef(focusedState);
  focusedStateRef.current = focusedState;

  const handleRememberFocusBeforeExit = useCallback(() => {
    setNewStateInSearch(focusedStateRef.current);
  }, []);

  const isInitialFocusLoaded = useRef(false);

  const tryToRecoverFocusFromSearch = (): boolean => {


    if(!parsedState) {
      return false;
    }

    const {
      focusedSection,
      focusedLine,
      focusedOn
    } = parsedState;

    if(focusedSection === 'products') {
      if(focusedLine < 0 || focusedLine >= availableProductsCount) {
        return false;
      }
      setFocusedState({
        focusedSection: 'products',
        focusedLine,
        focusedOn
      });
      return true;
    }

    if(focusedLine < 0 || focusedLine >= userSubscriptionsCount) {
      return false;
    }

    if(focusedOn === 'text'){
      setFocusedState({
        focusedSection: 'subscriptions',
        focusedLine,
        focusedOn
      });
      return true;
    }

    if(isSubscriptionCancelableOnIdx(focusedLine)){
      setFocusedState({
        focusedSection: 'subscriptions',
        focusedLine,
        focusedOn
      });
      return true;
    }

    setFocusedState({
      focusedSection: 'subscriptions',
      focusedLine,
      focusedOn: 'text'
    });

    return true;
  }

  // Загрузка первоначального фокуса
  useEffect(() => {
    if (isInitialFocusLoaded.current || isLoading) {
      return;
    }

    isInitialFocusLoaded.current = true;

    const isRecovered = tryToRecoverFocusFromSearch();
    clearStateInSearch()

    if(isRecovered) {
      return;
    }

    if (userSubscriptionsCount > 0) {
      return;
    }

    if (availableProductsCount > 0) {
      setFocusedState({
        ...focusedState,
        focusedSection: 'products',
        focusedLine: 0,
      });
      return;
    }
  }, [isLoading]);

  type NextOrPrevLine = null | { section: FocusedState['focusedSection']; line: number };
  type NextOrPrevBlock = null | FocusedState['focusedOn'];

  // Тут считается следующая линия для перехода вниз
  const nextLineToFocus: NextOrPrevLine = useMemo(() => {
    const countInFocusedSection =
      focusedState.focusedSection === 'subscriptions'
        ? userSubscriptionsCount
        : availableProductsCount;

    // Если мы не на последнем элементе в секции, то нужно будет просто перейти к следующему
    if (focusedState.focusedLine < countInFocusedSection - 1) {
      const section = focusedState.focusedSection;
      const line = focusedState.focusedLine + 1;
      return {
        section,
        line,
      };
    }

    // Если мы на продуктах, то некуда дальше перейти
    if (focusedState.focusedSection === 'products') {
      return null;
    }

    // Если мы на подписках, то дальше можно перейти только к продуктам, но если они доступны
    if (focusedState.focusedSection === 'subscriptions' && availableProductsCount > 0) {
      const section = 'products';
      const line = 0;
      return {
        section,
        line,
      };
    }
    return null;
  }, [focusedState, availableProductsCount, userSubscriptionsCount]);

  // Тут считается следующий блок для перехода вниз
  const nextBlockToFocus: NextOrPrevBlock = useMemo(() => {
    if (!nextLineToFocus) {
      return null;
    }

    if (nextLineToFocus.section === 'products') {
      return focusedState.focusedOn;
    }

    if (focusedState.focusedOn === 'text') {
      return 'text';
    }

    return isSubscriptionCancelableOnIdx(nextLineToFocus.line) ? 'button' : 'text';
  }, [nextLineToFocus, focusedState]);

  // Тут считается предыдущая линия для перехода вверх
  const prevLineToFocus: NextOrPrevLine = useMemo(() => {
    // Если фокус не на первой строке, то просто поднимаемся на 1 линию
    if (focusedState.focusedLine > 0) {
      const section = focusedState.focusedSection;
      const line = focusedState.focusedLine - 1;
      return {
        section,
        line,
      };
    }

    // Если мы на подписках, то переключаться некуда
    if (focusedState.focusedSection === 'subscriptions') {
      return null;
    }

    // Если мы на продуктах, и доступны подписки, переключаемся на них
    if (focusedState.focusedSection === 'products' && userSubscriptionsCount > 0) {
      const section = 'subscriptions';
      const line = userSubscriptionsCount - 1;
      return {
        section,
        line,
      };
    }

    return null;
  }, [focusedState, userSubscriptionsCount]);

  // Тут считается предыдущий блок для перехода вверх
  const prevBlockToFocus: NextOrPrevBlock = useMemo(() => {
    if (!prevLineToFocus) {
      return null;
    }

    if (prevLineToFocus.section === 'products') {
      return focusedState.focusedOn;
    }

    if (focusedState.focusedOn === 'text') {
      return 'text';
    }

    return isSubscriptionCancelableOnIdx(prevLineToFocus.line) ? 'button' : 'text';
  }, [prevLineToFocus, focusedState]);

  // Доступен ли переход на кнопку, на текущей линии
  const isButtonOnCurrentFocusAvailable = useMemo(() => {
    // Если мы на продуктах, то можно переключиться на кнопку, иначе нужно проверять, отменяемая ли подписка
    return focusedState.focusedSection === 'products'
      ? true
      : isSubscriptionCancelableOnIdx(focusedState.focusedLine);
  }, [isSubscriptionCancelableOnIdx, focusedState]);

  const onKeyUp: FocuserKeyHandler = (ev) => {
    if (!prevLineToFocus || !prevBlockToFocus) {
      return;
    }
    ev.stop();
    setFocusedState({
      focusedLine: prevLineToFocus.line,
      focusedSection: prevLineToFocus.section,
      focusedOn: prevBlockToFocus,
    });
  };

  const onKeyDown: FocuserKeyHandler = (ev) => {
    if (!nextLineToFocus || !nextBlockToFocus) {
      return;
    }

    ev.stop();
    setFocusedState({
      focusedLine: nextLineToFocus.line,
      focusedSection: nextLineToFocus.section,
      focusedOn: nextBlockToFocus,
    });
  };

  const onKeyLeft: FocuserKeyHandler = (ev) => {
    // Если мы уже на тексте, то ничего не делаем
    if (focusedState.focusedOn === 'text') {
      return;
    }

    // Иначе переключаемся на текст
    ev.stop();
    setFocusedState({
      ...focusedState,
      focusedOn: 'text',
    });
  };

  const onKeyRight: FocuserKeyHandler = (ev) => {
    // Если мы уже на кнопке, то ничего не делаем
    if (focusedState.focusedOn === 'button') {
      return;
    }

    // Иначе переключаемся на кнопку, но сначала проверяем, можно ли переключиться
    if (!isButtonOnCurrentFocusAvailable) {
      return;
    }

    ev.stop();
    setFocusedState({
      ...focusedState,
      focusedOn: 'button',
    });
  };

  const isPointerUpAvailable = Boolean(prevLineToFocus);
  const isPointerDownAvailable = Boolean(nextLineToFocus);
  const isPointerLeftAvailable = focusedState.focusedOn === 'button';
  const isPointerRightAvailable =
    focusedState.focusedOn === 'text' && isButtonOnCurrentFocusAvailable;


    const handleForceFocusForSubMemo = useCallback((idx: number, block: 'button' | 'text')=> {
      if(idx >= userSubscriptionsCount) {
        return;
      }
      setFocusedState({
        focusedSection: 'subscriptions',
        focusedLine: idx,
        focusedOn: block,
      })
    }, [userSubscriptionsCount]);

    const handleForceFocusForProductsMemo = useCallback((idx: number, block: 'button' | 'text')=> {
      if(idx >= availableProductsCount) {
        return;
      }
      setFocusedState({
        focusedSection: 'products',
        focusedLine: idx,
        focusedOn: block,
      })
    }, [availableProductsCount]);

  return {
    onKeyUp,
    onKeyDown,
    onKeyLeft,
    onKeyRight,
    isPointerUpAvailable,
    isPointerDownAvailable,
    isPointerLeftAvailable,
    isPointerRightAvailable,
    focusedState,
    handleForceFocusForSubMemo,
    handleForceFocusForProductsMemo,
    handleRememberFocusBeforeExit
  };
};

export const useFocusedIdxForSlider = (
  focusedState: FocusedState,
  availableSubscriptionsCount: number,
) => {
  return useMemo(() => {
    const { focusedLine, focusedSection } = focusedState;

    if (focusedSection === 'subscriptions') {
      if (focusedLine === 0) {
        return 0;
      }

      // Есть 1 div под заголовки, добавляем его, чтобы слайдер корректно фокусировался
      return focusedLine + 1;
    }

    if(availableSubscriptionsCount === 0 && focusedLine === 0) {
      return 0;
    }

    // В продуктах 2 div под заголовки (от продуктов и от подписок)
    // Еще добавляем кол-во подписок, чтобы слайдер корректно фокусировался
    return availableSubscriptionsCount + focusedLine + (availableSubscriptionsCount ? 2 : 1);
  }, [focusedState, availableSubscriptionsCount]);
};
