

import PromoPhase from "~typings/PromoPhase";
import RentPlan from "~typings/RentPlan";

import { CheckboxRentPlanOperatorText, CheckboxRentPlanPromocodeText } from "./PromocodeRentPlanConfirmTypes";

/**
 * Функция собирает цены для отображения рядом с кнопками чекбоксов
 * Мы проходимся по prices в переданной в параметрах promoPhase
 * Это нужно для того, чтобы сформировать 2 массива с ценами для payment_method_type === 'operator' 
 * и для payment_method_type === 'promo_code'
 * Эти массивы с ценами используются при рендере чекбоксов метода оплаты (для рент планов)
 */

export function getPhaseTextsForRentPlans(promoPhase: PromoPhase): {
  operator: CheckboxRentPlanOperatorText[];
  promoCode: CheckboxRentPlanPromocodeText[];
} {
  const operator: CheckboxRentPlanOperatorText[] = [];
  const promoCode: CheckboxRentPlanPromocodeText[] = [];

  if(promoPhase) {
    let operatorArrPromo = ''; 
    let promoCodeArrPromo = '';

    promoPhase.billing.prices.forEach((price) => {
        if (price.payment_method_type === 'operator') {
          operatorArrPromo = `${price.money.formatted}`;
        }
        if (price.payment_method_type === 'promo_code') {
          promoCodeArrPromo = `${price.money.formatted}`;
        }
      })

      if (operatorArrPromo !== '') {
        operator.push({
          promo: operatorArrPromo,
          type: 'operator',
        });
      }

      if (promoCodeArrPromo !== '') {
        promoCode.push({
          promo: promoCodeArrPromo,
          type: 'promocode',
        });
      }
  }
  return { operator, promoCode };
}

export const getPlanType = (rentPlan: RentPlan): string => {
  if (rentPlan.object === 'tvod_plan') {
    return 'TVOD';
  } else if (rentPlan.object === 'est_plan') {
    return 'EST'
  }
  return '';
}