import { useConfig } from '~global';
import { LoginFieldType, LoginFormType } from '~typings/StartupConfig';

export const useIsImplicitFlow = () => {
  const {
    smartTV,
    startup: { login_form: { login_form_type, login_field_type } }
  } = useConfig();

  return (
    smartTV.isSimpleAuth === true &&
    login_field_type !== LoginFieldType.Email &&
    login_form_type === LoginFormType.Implicit
  );
};
