import * as cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { CurrentEventInfo } from '~components/CurrentEventInfo';
import { useMenu } from '~components/Provider/Menu';
import useChannelBySlug from '~hooks/fetch/useChannel';
import useNavigation from '~stores/Navigation';
import { Block } from '~typings/Block';
import Page from '~typings/Page';
import WatchButton from '~ui/WatchButton/WatchButton';

import * as styles from './styles.module.css';


type Props = Readonly<{
  page: Page;
  block: Block;
  isVisible: boolean;
  isFocusedBlock: boolean;
  isPopupOpened: boolean;
  blockIndex: number;
}>;

const BlockCurrentProgramLine: React.FC<Props> = (props) => {
  const menu = useMenu();
  const isPopupOpened = (menu.isMenuOpened || menu.isPopupOpened);
  const { data: channel } = useChannelBySlug(props.block.channel_slug);

  const history = useHistory();
  const showWatchButton = (!props.isPopupOpened && props.isFocusedBlock);
  const focusedBlockIndex = useNavigation(state => state.getFocusedBlockIndex());
  const withIcon = focusedBlockIndex < props.blockIndex;

  const handleClick = React.useCallback(() => {
    if (!isPopupOpened && channel) {
      history.push(`/media-item/channel/${channel.data.id}?player=true`);
    }
  }, [channel?.data.id]);

  if (!channel) { return null; }

  if (!props.block || !props.isVisible) { return null; }

  return (
    <div
      className={
        cn({
          'focusedNavigationNode': (props.isFocusedBlock && !isPopupOpened),
        })
      }
      style={ { position: 'relative', height: '100%' } }
      onClick={ handleClick }
    >
      <div
        className={
          cn(styles.currentProgramLineWrapper, {
            [styles.focused]: props.isFocusedBlock,
          })
        }
      >
        <CurrentEventInfo
          withAnimation
          withIcon={ withIcon }
          isFullSize={ props.isFocusedBlock || showWatchButton }
          item={ channel.data }
          // isHidden={ isFocusedBlock }
        />
      </div>
      {
        (showWatchButton && !props.isPopupOpened) &&
          <WatchButton className={ styles.watchButton } />
      }
    </div>
  );
};


export default React.memo(BlockCurrentProgramLine);
