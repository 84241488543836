import * as React from 'react';

import RatingsUI from '~components/RatingsBlock/Ratings';

import { DetailItem } from '../types';
import * as styles from './styles.module.css';

type Props = Readonly<{
  item: DetailItem;
  originalName: string | null;
}>;

const DefaultHeader: React.FC<Props> = ({ item, originalName }: Props) => {

  return (
    <>
      <RatingsUI item={ item } />
      <div className={ styles.header }>{ item.name }</div>
      {
        (originalName) ?
          <div className={ styles.origName }>{ originalName }</div>
          :
          null
      }
    </>
  );
};

export default DefaultHeader;
