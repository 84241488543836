import * as React from 'react';
import { InjectedIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

import { BROWSING_HISTORY, FAVORITES, I_LOOK } from '~localization';
import MenuID from '~typings/MenuID';
import MenuItem from '~typings/MenuItem';
import Page from '~typings/Page';


export const PersonalPageMenuID = {
  'watch_later': 'watchLater',
  'favorites': 'favorites',
  'watch_history': 'watchHistory',
} as const;

type PersonalPageMenuType = typeof PersonalPageMenuID[keyof typeof PersonalPageMenuID];

const PERSONAL_PAGE_PATH = `/${ MenuID.PersonalPage }`;

const allowedTabs = [
  PersonalPageMenuID.watch_later,
  PersonalPageMenuID.favorites,
];

const ITEM_TEXT_BY_ID: { [key in PersonalPageMenuType]: string } = {
  [PersonalPageMenuID.watch_later]: I_LOOK,
  [PersonalPageMenuID.favorites]: FAVORITES,
  [PersonalPageMenuID.watch_history]: BROWSING_HISTORY,
};

const getMenu = (pageId: string | undefined, intl: InjectedIntl, personalPage: Page | undefined): MenuItem[] => {

  return personalPage?.tabs
    ?.filter(t => !!allowedTabs.find(at => at === PersonalPageMenuID[t.type]))
    ?.map((tab) => ({
      id: tab.id,
      title: tab.title || intl.formatMessage({ id: ITEM_TEXT_BY_ID[PersonalPageMenuID[tab.type]] }),
      link: `${
        pageId ?
          `/page/${ pageId }`
          : PERSONAL_PAGE_PATH
      }/${ PersonalPageMenuID[tab.type] }/${
        (tab.resource_types?.length ? tab.resource_types : ['all']).join(',')
      }`,
      meta: {
        tabId: PersonalPageMenuID[tab.type],
        resourceTypes: (tab.resource_types?.length ? tab.resource_types : ['all']).join(','),
      }
    })) || [];
};

const usePageId = (): {
  pageId: string | undefined;
  tabId: string | undefined;
  resourceTypes: string | undefined;
} => {
  const matchItemObject = useRouteMatch<{ pageId: string }>(
    '/page/:pageId'
  );

  const matchPathObject = useRouteMatch<{ pageId: string; tabId: string; resourceTypes: string }>(
    matchItemObject ? '/page/:pageId/:tabId/:resourceTypes' : `/${ PERSONAL_PAGE_PATH }/:tabId/:resourceTypes`
  );

  return {
    pageId: matchItemObject?.params.pageId,
    tabId: matchPathObject?.params.tabId,
    resourceTypes: matchPathObject?.params.resourceTypes,
  };
};


type MenuStateProps = Readonly<{
  tabId?: string;
  resourceTypes?: string;
  items: MenuItem[];
}>;

const useMenuFocusedIndex = (props: MenuStateProps) => {
  const menuFocusedIndex = React.useMemo(() => {
    const index = props.items.findIndex(item =>
      item.meta?.tabId === props.tabId
      && item.meta?.resourceTypes === props.resourceTypes
    );

    return Math.max(index, 0);
  }, [props.tabId, props.resourceTypes, props.items]);

  return menuFocusedIndex;
};


export {
  getMenu,
  PERSONAL_PAGE_PATH,
  useMenuFocusedIndex,
  usePageId,
};
