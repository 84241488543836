export function reduceString(description: string | undefined, length: number) {
  // принимает, обрезает и возвращает строку и добавляет "..." в конце
  // предполагается что это строка со словами и пробелами(!) между ними
  if (description === undefined) {
    return undefined
  }

  if (description.length >= length) {
    const arrayWithStrings = description.slice(0, length).split(' ')
    arrayWithStrings.pop()
    arrayWithStrings.push('...')
    return arrayWithStrings.join(' ')
  }

  return description
}
