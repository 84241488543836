/* линтер думает что в этом файле нарушается использование хуков */
/* eslint-disable react-hooks/rules-of-hooks */
import focuser from '@focuser';
import * as cn from 'classnames';
import { truncate } from 'lodash';
import * as React from 'react';
import { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { Typography } from '~app/ui-kit/Typography';
import { WarningTextlineBlock } from '~app/ui-kit/WarningTextlineBlock';
import ExpiredText from '~components/ProductItem/Texts/ExpiredText';
import TrialFreePeriodAndPriceText from '~components/ProductItem/Texts/TrialFreePeriodAndPriceText';
import getMinPrice from '~lib/product/getMinPrice';
import {
  FROM,
  PRODUCT_SELECT_PLAN_BUTTON,
  SUBSCRIPTION_NO_ACCESS_GRANTED,
  TRY_FOR_FREE,
} from '~localization';
import { Separator } from '~newapp/ui-kit/Tables_and_blocks/Separator';
import EverGreenPhase from '~typings/EvergreenPhase';
import Plan from '~typings/Plan';
import Product from '~typings/Product';
import Subscription from '~typings/Subscription';

import { isSubscriptionCancelable } from '../utils';
import { ButtonSelectPlanText } from './ButtonSelectPlanText';
import * as styles from './styles.module.css';

type Props = {
  product: Product | Plan['product'];
  subscription?: Subscription;

  /**
   * Показывать ли линию снизу
   * Верхняя линия показывается всегда, а нижняя по этому условию
   */
  showBottomSeperator: boolean;
  /**
   * Какой элемент фокусируется, текст или кнопка
   * Если этот ProductItem не сфокусирован, то сюда передается null
   *
   */
  focusedOn: 'text' | 'button' | null;

  /**
   *
   * Индекс по которому будет вызываться функция onForceFocus
   */
  indexForForceFocus: number;
  onForceFocus: (index: Props['indexForForceFocus'], block: 'button' | 'text') => void;

  /**
   * Коллбэк, который вызывается при смене истории
   */
  onHistoryChange: () => void;
};

export const ProductItem = React.memo(
  injectIntl<Props>(
    ({
      focusedOn,
      intl,
      subscription,
      product,
      onForceFocus,
      indexForForceFocus,
      onHistoryChange,
      showBottomSeperator,
    }) => {
      const history = useHistory();
      const expiresAt = subscription?.expires_at;
      const isActive = subscription?.status === 'active';
      const accessGranted = subscription?.access_granted;
      const autorenewable = subscription?.autorenew.autorenewable;
      const cancellable = subscription?.cancellable;
      const isExpired = Boolean(
        (isActive && accessGranted && !autorenewable && expiresAt && !cancellable) ||
          (!isActive && accessGranted && expiresAt),
      );
      const isButtonDisabled = subscription && !isSubscriptionCancelable(subscription);

      const text = useMemo(() => {
        const isSubscriptionInTrialPhase = !!(subscription?.phase?.type === 'trial');

        if (isActive && autorenewable && accessGranted) {
          return (
            <TrialFreePeriodAndPriceText
              isTrialPeriod={ isSubscriptionInTrialPhase }
              price={ {
                value: subscription?.phase?.billing.price.formatted,
                duration: subscription?.plan.eligible_phase?.access_period.value || undefined,
                unit: subscription?.plan.eligible_phase?.access_period.unit || undefined,
              } }
              autoReNew={ subscription?.autorenew }
            />
          );
        }

        if (isExpired) {
          return (
            <ExpiredText
              expiresAt={ expiresAt }
              phase={ subscription?.plan.eligible_phase as EverGreenPhase }
            />
          );
        }

        if (subscription?.phase?.type === 'trial') {
          return (
            <TrialFreePeriodAndPriceText
              isTrialPeriod={ isSubscriptionInTrialPhase }
              price={ {
                value: subscription?.plan.eligible_phase?.billing.price.formatted,
                duration: subscription?.plan.eligible_phase?.access_period.value || undefined,
                unit: subscription?.plan.eligible_phase?.access_period.unit || undefined,
              } }
              autoReNew={ subscription?.autorenew }
            />
          );
        }

        if (product.plans?.[0]?.eligible_phase?.type === 'trial') {
          const evergreenPhase = product.plans[0]?.phases.find(
            (p) => p.type === 'evergreen',
          ) as EverGreenPhase;

          return (
            <TrialFreePeriodAndPriceText
              isTrialPeriod={ !!evergreenPhase }
              price={ {
                value: evergreenPhase?.billing?.price.formatted,
                duration: evergreenPhase?.access_period?.value || undefined,
                unit: evergreenPhase?.access_period?.unit || undefined,
              } }
            />
          );
        }

        const minPriceWithPeriod = getMinPrice(product as Product);

        const hasOnlyPlan = product.plans?.length === 1;
        if (minPriceWithPeriod === 'free') {
          return intl.formatMessage({ id: TRY_FOR_FREE });
        }

        if (minPriceWithPeriod !== null) {
          return `${!hasOnlyPlan ? `${intl.formatMessage({ id: FROM })} ` : ''}${intl.formatMessage(
            { id: PRODUCT_SELECT_PLAN_BUTTON },
            {
              price: minPriceWithPeriod.money.formatted,
              durationValue: minPriceWithPeriod.period.value,
              durationUnit: minPriceWithPeriod.period.unit,
            },
          )}`;
        }

        return product?.description
          ? truncate(product?.description, {
              length: 48,
              separator: /,? +/,
            })
          : '';
      }, [subscription, product]);

      const handleClick = () => {
        if (!subscription) {
          onHistoryChange();
          history.push(`/products/${product.id}`);
        }
      };

      const handleButtonClick = () => {
        if (isButtonDisabled) {
          return;
        }

        onHistoryChange();
        if (isActive && subscription?.cancellable) {
          history.push(`/products/unsubscribe/${subscription.id}`);
        } else {
          history.push(`/products/${product.id}/plan`);
        }
      };

      return (
        <div className={ styles.productItem }>
          <Separator className={ styles.separator } />
          <div className={ styles.descriptionAndButtonWrapper }>
            <focuser.FocusableBlock
              isFocused={ focusedOn === 'text' }
              className={ styles.description }
              onClick={ handleClick }
              onForceFocus={ () => onForceFocus(indexForForceFocus, 'text') }
              emitForceFocusOnHover
            >
              {({ isFocused }) => {
                return (
                  <>
                    <Typography
                      variant="h6_medium"
                      className={ cn(styles.descriptionName, {
                        [styles.descriptionNameFocused]: isFocused,
                      }) }
                    >
                      {product.name}
                    </Typography>
                    <Typography
                      variant="subtitle2_medium"
                      className={ styles.descriptionText }
                    >
                      {text}
                    </Typography>
                  </>
                );
              }}
            </focuser.FocusableBlock>
            <PrimaryButtonFocuser
              isFocused={ focusedOn === 'button' }
              onForceFocus={ () => onForceFocus(indexForForceFocus, 'button') }
              onClick={ handleButtonClick }
              isDisabled={ isButtonDisabled }
              buttonClassName={ styles.button }
              emitForceFocusOnHover
            >
              <ButtonSelectPlanText
                isProductInSubscriptions={ Boolean(subscription) }
                isCancellable={ !isButtonDisabled }
                intl={ intl }
              />
            </PrimaryButtonFocuser>
          </div>
          {subscription && !subscription.access_granted ? (
            <WarningTextlineBlock
              className={ styles.warning }
              iconClassName={ styles.warningIcon }
              errorText={ intl.formatMessage({ id: SUBSCRIPTION_NO_ACCESS_GRANTED }) }
              wrapText={ false }
              typographyVariant="body1_medium"
            />
          ) : null}
          {showBottomSeperator ? <Separator className={ styles.separatorBottom } /> : null}
        </div>
      );
    },
  ),
);

ProductItem.displayName = 'ProductItem';
