import * as React from 'react';


type Result = Readonly<{
  ref: React.MutableRefObject<HTMLDivElement | null>;
  isHovered: boolean;
}>;


const useHover = (): Result => {
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);

  React.useEffect(
    () => {
      const node = ref.current;

      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return { ref, isHovered };
};


export default useHover;
