import cn from 'classnames';
import * as React from 'react';

import { IconBase } from '~ui/Icon';
import PenIcon from '~ui/Icon/icons/PenIcon';
import TrashIcon from '~ui/Icon/icons/TrashIcon';

import * as styles from '../Icon/styles.module.css';

export type IconType = 'pen' | 'trash';

type Props = Readonly<{
  isFocused?: boolean;
  className: string;
  iconType: IconType;
  onClick?: () => void;
}>;

const getIconByType = (iconType: IconType) => {
  switch (iconType) {
    case 'pen':
      return PenIcon;
    case 'trash':
      return TrashIcon;
  }
};

const ChangeButton: React.FC<Props> = ({ isFocused, className, iconType, onClick }: Props) => (
  <div onClick={ onClick }>
    <IconBase
      Icon={ getIconByType(iconType) }
      classNames={ {
        wrapper: cn(
          styles.primaryRound,
          className,
          { [styles.inactive]: !isFocused },
          {
            'focusedNavigationNode': isFocused,
          }
        )
      } }
    />
  </div>
);

export default ChangeButton;
