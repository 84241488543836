import EverGreenPhase from '~typings/EvergreenPhase';
import ItemObject from '~typings/ItemObject';
import Period from '~typings/Period';
import PromoPhase from '~typings/PromoPhase';

export enum RentPlanType {
  EST = 'EST',
  TVOD = 'TVOD',
}

type RentPlan = Readonly<{
  object: ItemObject.RentPlanEST | ItemObject.RentPlanTVOD;
  id: string;
  type: RentPlanType;
  name: string;
  short_description: string;
  description: string;
  access_period: Period;
  duration: Period;
  phases: Array<PromoPhase | EverGreenPhase>;
  quality: string;
}>;


export default RentPlan;
