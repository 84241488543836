import { QueryKey } from 'react-query';

import { queryCache, queryClient } from '~global';


// Функция удаляет кеш react-query
const deleteCacheByKeys = (keys: QueryKey[]): void => {
  keys.forEach((key) => {
    try {
      const cachedQueries = queryCache.findAll(Array.isArray(key) ? key : [key]);
      
      for (let query of cachedQueries) {  
        queryClient.removeQueries(query.queryKey);
      }
    } catch (ignore) {
  
    }
  });
};


export default deleteCacheByKeys;
