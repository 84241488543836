import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { NAVIGATION_BACK } from '~localization';
import Button from '~ui/button';

import * as styles from './styles.module.css';

type Props = Readonly<{
  error: any;
}>;

const SomeErrorDialog: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const renderError = () => {
    if (
      typeof props.error === 'object'
      && !props.error?.$$typeof
    ) {
      return 'Error';
    }

    return props.error;
  };

  return (
    <>
      <div className={ styles.messageText }>
        { renderError() }
      </div>
      <Button onClick={ handleBack } isFocused>
        <FormattedMessage id={ NAVIGATION_BACK } />
      </Button>
    </>
  )
};

export default SomeErrorDialog;
