import focuser, { FocuserKeyHandler } from '@focuser';
import cn from 'classnames';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PromoData } from '~hooks/fetch/usePromocode';
import { getFullFormattedTime } from '~lib/pluralize/index';
import { FREE, PROMO_VOD_DISCLAMER,PROMOCODE_ACTIVATION } from '~localization';
import ItemObject from '~typings/ItemObject';

import * as styles from '../styles.module.css';

type Props = Readonly<{
  promocode: string;
  promo?: PromoData | null;
  onSelect: (id: string, type: string) => void;
  intl: InjectedIntl;
}>;

const LAST_FOCUS_ON_RESOURCE = 'LAST_FOCUS_ON_RESOURCE';


const PromocodeContentList: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const getLastFocusedResourceFromHistory = () => {
    const currentSearch = parse(history.location.search);

    const lastState = currentSearch[LAST_FOCUS_ON_RESOURCE];
    if(typeof lastState === 'string') {
      const parsedLastState = JSON.parse(lastState);
      const { index } = parsedLastState;
      delete currentSearch[LAST_FOCUS_ON_RESOURCE];

      return index;
    }
    return 0;
  }

  const [focusedIndex, setFocusedIndex] = React.useState<number>(() => getLastFocusedResourceFromHistory());

  const products = [...(props.promo?.products || []), ...(props.promo?.rentPlans || [])];

  const productsArr = products.map((product) => {
    if(product.object === ItemObject.Product) {
      return {
        name: product?.name,
        type: product?.type,
        id: product?.id,

        promoPrice: product?.plans?.[0].phases?.filter((phase) => phase.type === "promo")[0]
        ?.billing?.prices[0].money.formatted,

        promoPeriod: product?.plans?.[0].phases?.filter((phase) => phase.type === "promo")[0]
        ?.access_period.value,

        promoUnit: product?.plans?.[0].phases?.filter((phase) => phase.type === "promo")[0]
        ?.access_period.unit,

        evergreenPrice: product?.plans?.[0].phases.filter((phase) => phase.type === "evergreen")[0]
        ?.billing?.prices?.[0]?.money.formatted,

        evergreenPeriod: product?.plans?.[0].phases.filter((phase) => phase.type === "evergreen")[0]
        ?.access_period.value,

        evergreenUnit: product?.plans?.[0].phases.filter((phase) => phase.type === "evergreen")[0]
        ?.access_period.unit,
      }
    }
  }).filter((product) => product !== undefined);

  const rentPlanArr = products.flatMap((product) => {
    if(product.object !== ItemObject.Product) {
      return {
        name: product?.name,
        type: product?.type,
        quality: product?.quality,
        id: product?.id,

        tvodPrice: product?.phases?.[0].billing?.price.formatted,
        tvodPeriod: product?.duration?.value,
        tvodUnit: product?.duration?.unit,
      };
    }
  }).filter((product) => product !== undefined);

  const mergedArray = [...productsArr, ...rentPlanArr];

  const subscriptionSelectedHandler = (id: string, type: string) => {
    const currentSearch = parse(history.location.search);
    currentSearch[LAST_FOCUS_ON_RESOURCE] = JSON.stringify({
      index: focusedIndex
    })

    history.replace({
      search: stringify(currentSearch),
    });

    props.onSelect(id, type);
  };

  const handleDown: FocuserKeyHandler = (event) => {
    if (focusedIndex < mergedArray.length - 1) {
      setFocusedIndex(focusedIndex + 1);
      event.stop();
    }
  };

  const handleUp: FocuserKeyHandler = (event) => {
    if(focusedIndex > 0) {
      setFocusedIndex((focusedIndex - 1));
      event.stop();
    }
  }

  const renderEvergreenPrice = (button) => {
    if(button.evergreenPrice !== undefined) {
      return (
        `, затем ${button.evergreenPrice} / ${getFullFormattedTime(button?.evergreenUnit, button?.evergreenPeriod)}`
      )
    }
    return '';
  }

  if (!products) return null;

  return (
    <focuser.FocusableBlock
      isFocused={ true }
      noNeedForceFocus
      onKeyDown={ handleDown }
      onKeyUp={ handleUp }
    >
      <div className={ styles.promocodeContentWrap }>
        <div className={ styles.backgroundImage }/>
        <div className={ styles.mask }></div>

        <div className={ styles.contentListWrap }>
          <div className={ styles.contentTextWrap }>
            <div className={ styles.contentTextTitle }>
              { props.intl.formatMessage({ id: PROMOCODE_ACTIVATION }) }
            </div>

            <div className={ styles.contentText }>
            { props.intl.formatMessage({ id: PROMO_VOD_DISCLAMER }) }
            </div>
          </div>

          { mergedArray.map((button, idx) => (
            <focuser.FocusableBlock
              isFocused={ focusedIndex === idx }
              key={ idx }
              onForceFocus={ () => setFocusedIndex(idx) }
              emitForceFocusOnHover
              onClick={ (ev) => {
                subscriptionSelectedHandler(button!.id, button!.type);
                ev.stop();
                ev.stopNativeEvent();
              } }
              className={
                cn(styles.selectSubscrList,
                { [styles.focusedButton]: focusedIndex === idx })
              }
            >
              <div className={ styles.buttonTitle }>
                {button?.type === 'TVOD'
                  ? `Аренда ${button.quality}`
                  : button?.type === 'EST'
                    ? `Покупка ${button.quality}`
                    : button?.name
                }
              </div>
              <div className={ styles.buttonPriceInfo }>
                { button?.type === 'base'
                  ? `${button.promoPrice} / ${getFullFormattedTime(button?.promoUnit, button?.promoPeriod || 0)}
                      ${renderEvergreenPrice(button)}`
                  : button?.tvodPeriod
                    && button.tvodPrice?.includes('0.00')
                    ? props.intl.formatMessage({ id: FREE })
                    : `${button?.tvodPrice} на ${getFullFormattedTime(button?.tvodUnit, button?.tvodPeriod || 0)}`
                }
              </div>
            </focuser.FocusableBlock>
            ))
          }
        </div>
      </div>
    </focuser.FocusableBlock>
  )
};

export default injectIntl(PromocodeContentList);
