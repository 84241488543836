import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import { PlayerAccessReason } from '~components/Provider/Player/component.helpers';
import { favoriteTypeByItemObject } from '~hooks/fetch/useFavorites/useFavoritesV3.helpers';
import AudioShowPart from '~typings/AudioShowPart';
import Episode from '~typings/Episode';
import { FavoriteType } from '~typings/FavoriteType';
import ItemObject from '~typings/ItemObject';
import { ExpandedPlayerAccess, PrimitivePlayerAccess } from '~typings/PlayerAccess';
import { VoteAction } from '~typings/Vote';

import { RentPlanWithAccessPeriod } from './RentActivationWarning';

export type Props = {
  access: PrimitivePlayerAccess | null;
  isAuthorized: boolean | null;
  hasTrailer: boolean;
  object: ItemObject.ProgramEvent | ItemObject.Movie | ItemObject.Series | ItemObject.AudioShow;
  isInFavorites?: boolean;
  watchInProgress?: boolean;
  vote?: VoteAction;
  notPurchased?: boolean;
  hasPromo?: boolean;
  /**
   * Только для сериалов
   *
   * 'one' - если только один сезон
   *
   * 'many' - если больше одного сезона
   *
   * undefined - если item => не сериал, или сериал, но без сезонов
   */
  seasonsCount?: 'one' | 'many';
  allParts?: boolean;
};

export const getBarsItems = (props: Props): BarItem[] => {
  const barItems: BarItem[] = [];

  const playOrContinueButton = getPlayOrPayButton({
    access: props.access,
    hasPromo: props.hasPromo,
    isWatchInProgress: props.watchInProgress,
  });

  if(playOrContinueButton) {
    barItems.push(playOrContinueButton);
  }

  if(props.seasonsCount){
      if(props.seasonsCount === 'one'){
        barItems.push(BarItem.AllEpisodes);
      } else {
        barItems.push(BarItem.AllSeasons);
      }
  };

  if(props.allParts) {
    barItems.push(BarItem.AllParts)
  }

  if (props.hasTrailer) {
    barItems.push(BarItem.Trailer);
  }
  if (props.isAuthorized) {
    if (
      Object.keys(FavoriteType)
        .some(key => FavoriteType[key] === favoriteTypeByItemObject[props.object])
    ) {
      barItems.push(
        props.isInFavorites ? BarItem.WatchLaterRemove : BarItem.WatchLaterAdd,
      );
    }

    barItems.push(props.vote === VoteAction.Up ? BarItem.RatedUp : BarItem.RateUp);
    barItems.push(props.vote === VoteAction.Down ? BarItem.RatedDown : BarItem.RateDown);
  }
  return barItems;
};


export type getPlayOrPayButtonParams = {
  access: PrimitivePlayerAccess | null,
  hasPromo?: boolean,
  isWatchInProgress?: boolean,
}
const getPlayOrPayButton = ({
  access,
  hasPromo,
  isWatchInProgress,
}: getPlayOrPayButtonParams):
  | BarItem.PlayContinue
  | BarItem.Play
  | BarItem.BestPrice
  | BarItem.PromoPrice
  | null => {
  if (!access) {
    return null;
  }

  if (access.allowed) {
    // нужно показать кнопку Смотреть или Продолжить
    return isWatchInProgress ? BarItem.PlayContinue : BarItem.Play;
  }

  if (
    access.reason === PlayerAccessReason.RestrictedByGeo ||
    access.reason === PlayerAccessReason.SubOnHold
  ) {
    // Сценарий когда кнопку Смотреть или Продолжить нельзя показывать
    return null;
  }

  if (access.reason === PlayerAccessReason.NotPurchased) {
    // Сценарий когда нам нужно показать кнопку "Смотреть от Xруб" или "Смотреть по подписке"
    return hasPromo ? BarItem.PromoPrice : BarItem.BestPrice;
  }

  if (access.reason === PlayerAccessReason.NotAuthorized) {
    return BarItem.Play;
  }

  return null;
};


export const doesNeedShowWarningForRent = (
  access?: ExpandedPlayerAccess | null,
): access is ExpandedPlayerAccess & {
  reason_object: { status: 'purchased'; expires_at: string, rent_plan: {} };
} => {
  return Boolean(access?.reason_object?.status === 'purchased' && access?.reason_object.expires_at);
};


export type RentStartWarningStateForMovie =
  | { isOpen: false }
  | { isOpen: true; rentPlan: RentPlanWithAccessPeriod; purchaseExpiresAt: string };

export type RentStartWarningStateForSeries =
  | { isOpen: false }
  | {
      isOpen: true;
      rentPlan: RentPlanWithAccessPeriod;
      purchaseExpiresAt: string;
      episode: Episode;
    };

export type RentStartWarningStateForAudioShow =
  | { isOpen: false }
  | {
      isOpen: true;
      rentPlan: RentPlanWithAccessPeriod;
      purchaseExpiresAt: string;
      part: AudioShowPart
    };

