import React from 'react';

import { CardCollectionGrid } from '~components/CardCollectionGrid';
import { CardConfig } from '~typings/ScreenCardConfigs';
import { SelectedFilters } from '~typings/SelectedFilters';

import { useGridNavigationHandler } from './CardColContentGrid.hooks';

interface Props {
  selectedFilters: SelectedFilters;
  cardCollectionId: string;
  cardConfig: CardConfig;
  onScrollToGrid: (grid: HTMLDivElement | null) => void;
  onFocusAvailabilityChange: (canFocus: boolean) => void;
  onFocusRestore: () => void;
}

const CardCollectionContentGrid$: React.FC<Props> = ({
  selectedFilters,
  cardCollectionId,
  cardConfig,
  onScrollToGrid,
  onFocusAvailabilityChange,
  onFocusRestore,
}) => {

  const {
    onNavigationChange,
    gridRef,
    forceSliderOnFirstRow
  } = useGridNavigationHandler({ onScrollToGrid });

  return (
    <CardCollectionGrid
      selectedFilters={ selectedFilters }
      cardCollectionId={ cardCollectionId }
      cardConfig={ cardConfig }
      onNavigationChange={ onNavigationChange }
      forwardRef={ gridRef }
      forceSliderOnFirstRow={ forceSliderOnFirstRow }
      onFocusAvailabilityChange={ onFocusAvailabilityChange }
      beforeFocusRestore={ onFocusRestore }
      rememberFocusOnLeave
    />
  );
};
export const CardCollectionContentGrid = React.memo(CardCollectionContentGrid$);
