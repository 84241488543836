import { QueryKey, useQuery } from 'react-query';

import { usePlatform } from '~components/Provider/Platform';
import { queryClient, useDeviceToken } from '~global';
import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import { streamURL } from '~hooks/fetch/useStreamFetch/helpers';
import ApiError from '~typings/ApiError';
import { DRMType } from '~typings/Drm';
import ItemObject from '~typings/ItemObject';
import PlatformName from '~typings/PlatformName';
import Protocol from '~typings/Protocol';
import Stream from '~typings/Stream';


type Errors = Readonly<{
  errors: ApiError[];
}>;

type Result = Readonly<{
  errors?: ApiError[];
  data?: Stream;
  meta: any;
}>;

type Props = Readonly<{
  itemID: string | null;
  itemObject: ItemObject | null;
  is4K: boolean;
  drm: DRMType | null;
  protocol: Protocol | null;
}>;

type RequiredParameters = Readonly<{
  itemID: string | null;
  itemObject: ItemObject | null;
  drm: DRMType | null;
  protocol: Protocol | null;
  deviceToken: string | null;
  accessToken: string | null;
}>;

const getStreamKey = (props: RequiredParameters): QueryKey => [
  'stream',
  props.itemID, props.itemObject,
  props.deviceToken, props.accessToken,
  props.drm, props.protocol,
];

const getHLSVersionForWebOS = (osVersionNumber: number, is4K: boolean): number => {
  if (osVersionNumber <= 3) {
    // webOS TV 3.5 & webOS TV 3.0 & webOS TV 2.0 & webOS TV 1.0 Supported HLS v5
    return 3;
  } else if (osVersionNumber < 5) {
    // webOS TV 4.5 & webOS TV 4.0 Supported HLS v5
    return 5;
  } else if (!is4K) {
    return 5;
  }
  // webOS TV 5.0 Supported HLS v7
  return 7;
};

const fetch = async (
  client,
  cacheData,
  props: RequiredParameters,
  osVersionNumber: number,
  is4K,
  // isUHDSupported: boolean,
) => {
  if (cacheData) { return cacheData; }

  const requestData = {
    drm: props.drm,
    protocol: props.protocol,
    video_codec: 'h264',
    audio_codec: 'mp4a',
    screen_width: 1280,
    screen_height: 720,
    device_token: props.deviceToken,
    access_token: props.accessToken,
  };
  if (props.accessToken) {
    requestData['access_token'] = props.accessToken;
  }
  if (props.protocol === Protocol.HLS) {
    if (__PLATFORM__ === PlatformName.WebOS) {
      requestData['profile'] = getHLSVersionForWebOS(osVersionNumber, is4K);
    } else if (__PLATFORM__ === PlatformName.Tizen) {
      // https://developer.samsung.com/smarttv/develop/specifications/general-specifications.html
      // Tizen 2.3 (2015 year) and Older not Supported HLS v7
      // requestData['profile'] = (osVersionNumber < 3) ? 3 : 5;
      requestData['profile'] = (osVersionNumber < 3) ? 3 : 7;
      // if (props.itemObject === ItemObject.Channel) {
      requestData['join_chunks'] = 4;
      // }
    }
  }

  return await client.get(
    streamURL(props.itemID as string, props.itemObject as ItemObject),
    requestData,
  );
};

const getCachedStream = (props: RequiredParameters) => {
  if (!props.itemID || !props.itemObject) {
    return undefined;
  }

  const queryKey = getStreamKey(props);
  const cachedData = queryClient.getQueryData<Result>(queryKey);

  return cachedData;
};


const useStreamFetch = (params: Props) => {
  const {accessToken} = getAuthData();
  const deviceToken = useDeviceToken();
  const requiredParameters: RequiredParameters = {
    accessToken,
    deviceToken,
    itemID: params.itemID,
    itemObject: params.itemObject,
    drm: params.drm,
    protocol: params.protocol,
  }
  const queryKey = getStreamKey(requiredParameters);
  const client = useClient();
  const cacheData = getCachedStream(requiredParameters);
  const {platform} = usePlatform();
  const {osVersion} = platform.getDeviceInfo();
  const osVersionNumber = parseInt((osVersion || '').toLowerCase().replace('tizen ', '').split('.')[0], 10);

  return useQuery<Result, Errors>({
    queryKey,
    queryFn: () => fetch(client, cacheData, requiredParameters, osVersionNumber, params.is4K),
    enabled: (
      params.drm !== null && params.protocol !== null
      && params.itemID !== null && params.itemObject !== null
      && !!deviceToken
    ),
    staleTime: 0,
    cacheTime: 0,
  });
};


export default useStreamFetch;
