import React, { useLayoutEffect } from 'react';

import { getWindowAboveFocus } from '~lib/getWindowAboveFocus';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import { getElementTransformY } from '~newapp/utils/globals/dom/getElementTransformY';

type UpdateOffsetArgs = {
  focusedIndex: number;
  slider: HTMLElement;
  sliderWrapper: HTMLElement;
  stickSlideFromUpVW: number;
};

const updateOffsetOfSlider = ({ focusedIndex, slider, sliderWrapper, stickSlideFromUpVW }: UpdateOffsetArgs) => {
  const childrenArray = Array.from(slider.children);
  const childRect = childrenArray?.[focusedIndex]?.getBoundingClientRect();

  if (!childRect) {
    return;
  }

  let { top: sliderWrapperTop, height: sliderWrapperHeight } =
    sliderWrapper.getBoundingClientRect();

  const { top: childTop } = childRect;

  const focusedChildTopNeedToBe = sliderWrapperTop + valueInPixelsByWidth(stickSlideFromUpVW);

  const sliderHeight = slider.getBoundingClientRect().height;

  const sliderTransformY = getElementTransformY(slider) ?? 0;

  const diff = focusedChildTopNeedToBe - childTop;
  const sliderTransformNeedToBe = sliderTransformY + diff;
  const sliderTransformNeedToBeSafe = Math.max(
    // Должен быть не положительным
    Math.min(0, sliderTransformNeedToBe),
    // разница height-ов не должна быть отрицательной, но их разницу нужно привести к отрицательному числу (через -1)
    -1 * Math.max(sliderHeight - sliderWrapperHeight, 0),
  );

  slider.style.transform = `translate3d(0, ${sliderTransformNeedToBeSafe}px, 0)`;
  slider.style.webkitTransform = `translate3d(0, ${sliderTransformNeedToBeSafe}px, 0)`;
  return;
};

type UseDynamicOffsetChangerProps = {
  focusedIndex: number;
  realFocusedIndex: number;
  stickSlideFromUpVW: number;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
  sliderWrapperRef: React.MutableRefObject<HTMLElement | null>;
  updates?: unknown[];
};

/**
 * Хук добавляет offset к слайдеру в зависимости от индекса активного слайда
 */
export const useDyanmicOffsetChanger = (params: UseDynamicOffsetChangerProps) => {
  const { focusedIndex, sliderRef, sliderWrapperRef, realFocusedIndex, stickSlideFromUpVW, updates = [] } = params;

  useLayoutEffect(() => {
    if (!sliderRef.current || !sliderWrapperRef.current) {
      return;
    }

    updateOffsetOfSlider({
      focusedIndex,
      slider: sliderRef.current,
      sliderWrapper: sliderWrapperRef.current,
      stickSlideFromUpVW
    });
  }, [focusedIndex, realFocusedIndex, ...updates]);
};

/**
 * Утилитка помогает получить минимальный и максимальный индекс для отрисовки виртуальных элементов
 * buffer-size => количество элементов, которое должно буферироваться за view-port—ом
 * Это нужно, чтобы на старых телевизорах элементы были отрисованы
 * Если на каждом шаге влево/вправо удалять и вставлять элементы в DOM, то это будет лагать
 * Из-за этого мы должны буферировать несколько элементов слева и справа
 */
const getVirtualChildsBoundsWithBuffer = ({
  idx,
  recalcStep,
  totalCount,
  maxInDOM,
}: {
  idx: number;
  recalcStep: number;
  maxInDOM: number;
  totalCount: number;
}) => {
  const { start, end } = getWindowAboveFocus(idx, recalcStep);
  const leftBound = Math.floor((maxInDOM - recalcStep) / 2);
  const rightBound = Math.ceil((maxInDOM - recalcStep) / 2);

  if((start - leftBound) < 0) {
    return [0, Math.min(maxInDOM, totalCount)];
  }

  if((end + rightBound) > totalCount) {
    return [Math.max(totalCount - maxInDOM, 0), totalCount];
  }

  return [start - leftBound, end + rightBound];
};

export type UseVirtualChildsParams = {
  childsCount: number;
  renderChild: (idx: number) => React.ReactNode;
  focusedIndex: number;
  maxInDOM: number;
  recalcStep: number;
};

export const useVirtualChilds = ({
  focusedIndex,
  childsCount,
  renderChild,
  maxInDOM,
  recalcStep,
}: UseVirtualChildsParams) => {
  const [minIdxToRender, maxIdxToRender] = getVirtualChildsBoundsWithBuffer({
    idx: focusedIndex,
    recalcStep: recalcStep,
    maxInDOM: maxInDOM,
    totalCount: childsCount,
  });

  const virtualChildren = React.useMemo(() => {
    return new Array(Math.max(maxIdxToRender - minIdxToRender, 0))
      .fill(0)
      .map((_, idx) => renderChild(minIdxToRender + idx));
  }, [renderChild, minIdxToRender, maxIdxToRender]);

  const virtualFocus = focusedIndex - minIdxToRender;

  return {
    virtualChildren,
    virtualFocus,
    heightMultiplier: minIdxToRender,
  };
};
