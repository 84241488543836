import {
  KeyBoardKey,
  KeyBoardKeyType,
} from '~typings/Keyboard';


const keyboardDefault: KeyBoardKey[][] = [
  [
    { type: KeyBoardKeyType.Char, id: 1, },
    { type: KeyBoardKeyType.Char, id: 2, },
    { type: KeyBoardKeyType.Char, id: 3, },
    { type: KeyBoardKeyType.Char, id: 4, },
    { type: KeyBoardKeyType.Char, id: 5, },
    { type: KeyBoardKeyType.Char, id: 6, },
    { type: KeyBoardKeyType.Char, id: 7, },
    { type: KeyBoardKeyType.Char, id: 8, },
    { type: KeyBoardKeyType.Char, id: 9, },
    { type: KeyBoardKeyType.Char, id: 0, },
    { type: KeyBoardKeyType.Space },
    { type: KeyBoardKeyType.Backspace },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'Й', },
    { type: KeyBoardKeyType.Char, id: 'Ц', },
    { type: KeyBoardKeyType.Char, id: 'У', },
    { type: KeyBoardKeyType.Char, id: 'К', },
    { type: KeyBoardKeyType.Char, id: 'Е', },
    { type: KeyBoardKeyType.Char, id: 'Н', },
    { type: KeyBoardKeyType.Char, id: 'Г', },
    { type: KeyBoardKeyType.Char, id: 'Ш', },
    { type: KeyBoardKeyType.Char, id: 'Щ', },
    { type: KeyBoardKeyType.Char, id: 'З', },
    { type: KeyBoardKeyType.Char, id: 'Х', },
    { type: KeyBoardKeyType.SpecialChars, id: '*#$', disabled: true },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'Ф', },
    { type: KeyBoardKeyType.Char, id: 'Ы', },
    { type: KeyBoardKeyType.Char, id: 'В', },
    { type: KeyBoardKeyType.Char, id: 'А', },
    { type: KeyBoardKeyType.Char, id: 'П', },
    { type: KeyBoardKeyType.Char, id: 'Р', },
    { type: KeyBoardKeyType.Char, id: 'О', },
    { type: KeyBoardKeyType.Char, id: 'Л', },
    { type: KeyBoardKeyType.Char, id: 'Д', },
    { type: KeyBoardKeyType.Char, id: 'Ж', },
    { type: KeyBoardKeyType.Char, id: 'Э', },
    { type: KeyBoardKeyType.SwitchLang },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'Я', },
    { type: KeyBoardKeyType.Char, id: 'Ч', },
    { type: KeyBoardKeyType.Char, id: 'С', },
    { type: KeyBoardKeyType.Char, id: 'М', },
    { type: KeyBoardKeyType.Char, id: 'И', },
    { type: KeyBoardKeyType.Char, id: 'Т', },
    { type: KeyBoardKeyType.Char, id: 'Ь', },
    { type: KeyBoardKeyType.Char, id: 'Б', },
    { type: KeyBoardKeyType.Char, id: 'Ю', },
    { type: KeyBoardKeyType.Char, id: 'Ъ', },
    { type: KeyBoardKeyType.Char, id: 'Ё', },
    { type: KeyBoardKeyType.Search },
  ],
];


export default keyboardDefault;
