// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".keyboard--h8HqA {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n     -moz-box-orient: vertical;\n     -moz-box-direction: normal;\n          flex-direction: column;\n\n  /* 3px */\n  padding: 0.234vw 0;\n}\n\n\n.keyboardLine--PybLU {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n\n  /* 6px */\n  margin-bottom: 0.469vw;\n}\n\n.keyboardLine--PybLU:last-child {\n  margin-bottom: 0;\n}\n\n.button--lEffk {\n  margin-right: 0.469vw;\n}\n\n.button--lEffk:last-child {\n  margin-right: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/FocuserKeyboard/NavigableKeyboard/NavigableKeyboard.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,4BAAsB;EAAtB,6BAAsB;EAAtB,8BAAsB;KAAtB,yBAAsB;KAAtB,0BAAsB;UAAtB,sBAAsB;;EAEtB,QAAQ;EACR,kBAAkB;AACpB;;;AAGA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;;EAEb,QAAQ;EACR,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".keyboard {\n  display: flex;\n  flex-direction: column;\n\n  /* 3px */\n  padding: 0.234vw 0;\n}\n\n\n.keyboardLine {\n  display: flex;\n\n  /* 6px */\n  margin-bottom: 0.469vw;\n}\n\n.keyboardLine:last-child {\n  margin-bottom: 0;\n}\n\n.button {\n  margin-right: 0.469vw;\n}\n\n.button:last-child {\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var keyboard = "keyboard--h8HqA";
export var keyboardLine = "keyboardLine--PybLU";
export var button = "button--lEffk";
export default ___CSS_LOADER_EXPORT___;
