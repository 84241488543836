import settings from 'customization-project-name/settings.json';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import InputWithKeyboard, { FocusOn } from '~components/InputWithKeyboard';
import { useConfig } from '~global';
import { useUsernameAvailabilityMutation } from '~hooks/fetch/useUsernameAvailability';
import { useIsImplicitFlow } from '~hooks/useIsImplicitFlow';
import { newFieldValue } from '~lib/keyboard';
import { KeyBoardKey } from '~typings/Keyboard';
import { LoginFieldType } from '~typings/StartupConfig';
import { UsernameAvailabilityType } from '~typings/UsernameAvailabilityType';

import { getMessageByError } from '../helpers';
import { AuthType, KeyboardTypes, Placeholders } from '../types';
import { userNameErrorProcessing } from './helpers';

type UsernameFormProps = Readonly<{
  defaultValue?: string;
  isFocused?: boolean;
  authType: AuthType;
  onSuccess: (username: string, loginType: UsernameAvailabilityType) => void;
  onError?: (errorMessage: string, error?: any) => void;
  onProcessingStatus: (isProcessing: boolean) => void;
  onLeave: (NavigationDirection) => void;
  intl: InjectedIntl;
}>;

const UsernameForm: React.FC<UsernameFormProps> = (props: UsernameFormProps) => {
  const {
    smartTV: { phoneCode },
    startup: { login_form: { login_field_type: loginFieldType } },
  } = useConfig();
  const isImplicitFlow = useIsImplicitFlow();

  const phoneNumberPrefixIfNeeded = (loginFieldType === LoginFieldType.Phone);
  const [username, setUsername] = React.useState<string>(
    (props.defaultValue && props.defaultValue.length !== 0) ?
      props.defaultValue
      :
      phoneNumberPrefixIfNeeded ? phoneCode : ''
  );
  const { mutate: checkUsername } = useUsernameAvailabilityMutation();
  const userNameMustBeAvailable = props.authType === AuthType.SignUp;

  React.useEffect(() => {
    if (props.defaultValue) {
      setUsername(props.defaultValue);
    }
  }, [props.defaultValue]);

  const handleValueChange = React.useCallback((key: KeyBoardKey) => {
    setUsername(oldValue => newFieldValue(oldValue, key))
  }, []);

  const handleClearClick = React.useCallback(() => {
    setUsername('');
  }, []);

  if (!loginFieldType) return null;

  const onCheckUsernameSuccess = async (data) => {
    const formatedUsername = data?.data.format;
    const usernameType = data?.data.type;
    setUsername(formatedUsername || username);

    const isAvailable = data?.data?.available;
    const isOk = (
      usernameType !== UsernameAvailabilityType.Unknown &&
      userNameMustBeAvailable === isAvailable
    );

    if (isOk || isImplicitFlow) {
      return props.onSuccess(formatedUsername, usernameType);
    }

    if (props.onError) {
      props.onError(
        props.intl.formatMessage({
          id: userNameErrorProcessing(data, props.authType),
        }, {
          site: settings.baseSiteUrl
        })
      );
    }
  }

  const handleFormSubmit = () => {
    props.onProcessingStatus(true);

    checkUsername({ username }, {
      onSuccess: onCheckUsernameSuccess,
      onError(error) {
        if (!props.onError) return;
        const messageText = getMessageByError(error, props.intl.formatMessage);
        props.onError(messageText, error);
      },
      onSettled: () => props.onProcessingStatus(false)
    })
  };

  return (
    <InputWithKeyboard
      inputValue={ username }
      focusOn={ props.isFocused || props.isFocused === undefined ? FocusOn.KeyBoard : null }
      placeholder={ props.intl.formatMessage({ id: Placeholders[loginFieldType] }) }
      keyBoardType={ KeyboardTypes[loginFieldType] }
      onLeave={ props.onLeave }
      onChange={ handleValueChange }
      onClear={ handleClearClick }
      onSubmit={ handleFormSubmit }
    />
  );
};


export default injectIntl(UsernameForm);
