import * as cn from 'classnames';
import * as React from 'react';

import { BannerItem } from './../component';
import * as styles from './styles.module.css';


type Props = Readonly<{
  index: number;
  item: BannerItem;
  isFocused: boolean;
  onClick?: (item: BannerItem) => void;
}>


const SliderDotsItem: React.FC<Props> = (props: Props) => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.item);
    }
  };

  return (
    <span
      className={
        cn(
          styles.sliderItemDot,
          {
            [styles.active]: props.isFocused,
            'focusedNavigationNode': props.isFocused,
          }
        )
      }
      onClick={ handleClick }
    />
  );
};


export default React.memo(SliderDotsItem);
