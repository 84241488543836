import * as React from 'react';

import IconMediaItem from './IconMediaItem';
import OnwardIcon from './icons/Onward';


type Props = {
  className?: string;
};

const IconMediaItemDetails: React.FC<Props> = ({ className }) => {
  return (
    <IconMediaItem
      Icon={ OnwardIcon }
      className={ className }
    />
  );
};


export default IconMediaItemDetails;
