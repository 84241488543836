import { ac_00 } from 'customization-project-name/colors'
import React from "react";

interface PropsTS {
  className?: string;
}

/* eslint-disable max-len */
const SpinnerJS = ({ className }: PropsTS) => {
  return (
    <svg fill={ ac_00 }
         className={ className }
         viewBox="0 0 80 80"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.0013 6.6665C58.4108 6.6665 73.3346 21.5903 73.3346 39.9998C73.3346 58.4093 58.4108 73.3332 40.0013 73.3332C21.5918 73.3332 6.66797 58.4093 6.66797 39.9998L6.66923 39.9346C6.70348 39.0688 7.41619 38.3774 8.29037 38.3774C9.16456 38.3774 9.87727 39.0688 9.91151 39.9346L9.91282 39.9998C9.91282 56.6173 23.3839 70.0883 40.0013 70.0883C56.6187 70.0883 70.0898 56.6173 70.0898 39.9998C70.0898 23.3824 56.6062 8.98234 40.0013 8.33676C35.5351 8.16312 28.1106 8.45811 23.7794 10.8732C28.5805 8.19352 34.1125 6.6665 40.0013 6.6665Z" />
    </svg>
  )
}


export { SpinnerJS }
