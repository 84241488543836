import HotKey from "~typings/HotKey";
import NavigationDirection from "~typings/NavigationDirection";

export const FOCUSER_KEY_EVENT_NAME = 'focuser-key-event';
export const FOCUSER_CLICK_EVENT_NAME = 'focuser-click';
export const FOCUSER_FORCE_FOCUS_EVENT_NAME = 'focuser-force-focus-event';
export const FOCUSER_POINTER_EVENT_NAME = 'focuser-pointer-event';

export type FocuserKeyEventDetail = Readonly<{
  type: typeof FOCUSER_KEY_EVENT_NAME;
  keyCode: HotKey;
  stop: () => void;
  stopNativeEvent: () => void;
  nativeEvent: Event;
}>;

export type FocuserClickEventDetail = Readonly<{
  type: typeof FOCUSER_CLICK_EVENT_NAME;
  keyCode: HotKey;
  stop: () => void;
  stopNativeEvent: () => void;
  nativeEvent: Event;
  isPhysicalMouseClick: boolean;
}>;;

export type FocuserForceFocusEventDetail = Readonly<{
  type: typeof FOCUSER_FORCE_FOCUS_EVENT_NAME;
  stop: () => void;
  stopNativeEvent: () => void;
  nativeEvent: Event;
}>;

export type FocuserPointerEventDetail = {
  type: typeof FOCUSER_POINTER_EVENT_NAME;
  addDirection: (directions: NavigationDirection) => void;
  getDirections: () => Set<NavigationDirection>;
  returnButtonType?: 'back' | 'close' | 'menu' | null;
  /**
   * Если этот event прошел через isolation, то тут будет `true`.
   * В этом случае все FocusableBlock-и не должны добавлять в этот event направления
   */
  isBlockedByIsolation: boolean;
}

export type FocuserKeyHandler = (event: FocuserKeyEventDetail) => void;
export type FocuserClickHandler = (event: FocuserClickEventDetail) => void;
export type FocuserForceFocusHandler = (event: FocuserForceFocusEventDetail) => void;
export type FocuserPointerHandler = (event: FocuserPointerEventDetail) => void;
export type FocuserKeyOrClickHandler = (event: FocuserKeyEventDetail | FocuserClickEventDetail) => void;

export type AnyFocuserKeyEvent = FocuserKeyEventDetail | FocuserClickEventDetail;
export type AnyFocuserEvent =
  | FocuserKeyEventDetail
  | FocuserClickEventDetail
  | FocuserForceFocusEventDetail
  | FocuserPointerEventDetail;


export const allFocuserEventsArray = [
  FOCUSER_KEY_EVENT_NAME,
  FOCUSER_CLICK_EVENT_NAME,
  FOCUSER_FORCE_FOCUS_EVENT_NAME,
  FOCUSER_POINTER_EVENT_NAME
] as const;
