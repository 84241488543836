import cn from 'classnames';
import React from 'react';

import { Typography } from '~app/ui-kit/Typography';

import * as styles from './ControlField.module.css';

export interface Props {
  fieldName: string;
  fieldValue: string;
  button?: React.ReactNode;
  className?: string;
}

export const ControlField: React.FC<Props> = ({ fieldName, fieldValue, button, className }) => {
  return (
    <div className={ cn(styles.field, className) }>
      <Typography
        className={ styles.fieldName }
        variant={ 'subtitle1_medium' }
      >
        {fieldName}
      </Typography>
      <Typography
        className={ styles.fieldValue }
        variant={ 'subtitle1_medium' }
      >
        {fieldValue}
      </Typography>
      {button ? button : null}
    </div>
  );
};
