import * as React from 'react';

import { spinner } from '../../../../html/common';
import * as styles from './styles.module.css';

type Props = Readonly<{
  style?: React.CSSProperties;
}>;

/** @deprecated
 *  этот лоадер deprecated
 *  не нужно использовать лоадер из splash-screen
 *  взамен используйте лоадер из ~newapp/globalBlocks/spinner
 */

const Loader: React.FC<Props> = (props: Props) => {

  return <img
    style={ props.style }
    alt="Loader"
    src={ spinner }
    className={ styles.loader }
  />
}


export default Loader;
