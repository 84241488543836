import focuser from '@focuser';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { useCardConfig } from '~app/global';
import { Typography } from '~app/ui-kit/Typography';
import { useActorPageSliderNavigation } from '~components/ActorPage/hooks';
import { Card } from '~components/Card';
import { MOVIES_AND_SERIES } from '~lib/localizator/src/dictionaries';
import CardType from '~typings/Card';
import { getSliderPropsForCards, SliderOffsetableVirtual } from '~ui/SliderOffsetable';

import * as styles from './styles.module.css';


type Props = {
  isFocused: boolean;
  dataMoviesAndSeries: CardType[];
  onForceFocus: () => void;
}

const MoviesAndSeriesSlider$:React.FC<Props> = ({
  isFocused,
  dataMoviesAndSeries,
  onForceFocus
}:Props) => {
  const cardConfig = useCardConfig();

  const { 
    focusedIndex,
    setFocusedIndex,
    onKeyLeftThrottled,
    onKeyRightThrottled,
  } = useActorPageSliderNavigation({
    elementsCountInSlider: dataMoviesAndSeries.length,
  })

  const renderMoviesAndSeries = (index: number) => {

    const cardItem = dataMoviesAndSeries[index];

    if(!cardItem) {
      return null;
    } 

    return  (
        <Card 
          key={ cardItem.id }
          item={ cardItem }
          cardConfig={ cardConfig }
          isFocused={ isFocused && focusedIndex === index }
          emitForceFocusOnHover
          onForceFocus={ () => setFocusedIndex(index) }
        />
      )
  }
  
  
  if(!dataMoviesAndSeries.length) {
    return null;
  }

  return (
    <div className={ styles.sliderWrapper }>
      <Typography variant={ 'h5_medium' } className={ styles.sliderTitle }>
        <FormattedMessage id={ MOVIES_AND_SERIES } />
      </Typography>
      <focuser.FocusableBlock
        isFocused={ isFocused }
        onKeyLeft={ onKeyLeftThrottled }
        onKeyRight={ onKeyRightThrottled }
        onForceFocus={ onForceFocus }
      >
        <SliderOffsetableVirtual 
          sliderClassName={ styles.slider }
          focusedIndex={ focusedIndex }
          childsCount={ dataMoviesAndSeries.length }
          renderChild={ renderMoviesAndSeries }
          { ...getSliderPropsForCards(cardConfig.layout) }
        />
      </focuser.FocusableBlock>
    </div>
  )
}

export const MoviesAndSeriesSlider = MoviesAndSeriesSlider$;