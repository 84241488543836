import focuser from "@focuser"
import * as cn from 'classnames';
import * as React from 'react';

import { Typography } from '~app/ui-kit/Typography';
import { fillImageTemplateBySizes } from '~lib/image';
import Person from '~typings/Person';

import * as styles from './styles.module.css';

type Props = {
  dataPerson: Person;
  isFocused: boolean;
  onForceFocus: () => void;
  descriptionTextRef: React.RefObject<HTMLDivElement>;
  setShowActorDescriptionPopup: any;
}


const ActorPageHeader$: React.FC<Props> = ({ 
  dataPerson, 
  isFocused, 
  onForceFocus, 
  descriptionTextRef,
  setShowActorDescriptionPopup 
}) => {

  return (
    <div>
      <div className={ styles.headerInfoWrap }>
        { dataPerson?.images.length > 0
          ? <img 
              className={ styles.img }
              src={ fillImageTemplateBySizes(dataPerson?.images[1]?.url_template) }
            />
          : <>
              <div className={ styles.noImgWrap }>
                <img className={ styles.img }/>
                <div className={ styles.firstLetter }>
                  { dataPerson?.full_name[0] }
                </div>
              </div>
            </>
        }
        <div className={ styles.textWrap }>
          <Typography variant={ 'h3_bold' }>{ dataPerson?.full_name }</Typography>
          <Typography variant={ 'h6_bold' } className={ styles.text }>{ dataPerson?.original_name }</Typography>
        </div>
      </div>

      {dataPerson?.description !== '' && 
        <focuser.FocusableBlock 
          isFocused={ isFocused }
          onClick={ () => setShowActorDescriptionPopup('open') }
          onForceFocus={ onForceFocus }
          emitForceFocusOnHover
          isLastBlock
        >
            <div 
              className={ cn(styles.descriptionWrap, {
                [styles.descriptionFocused]: isFocused,
              }) } 
            >
              <Typography variant={ 'subtitle1_medium' } className={ styles.description }>
                <div ref={ descriptionTextRef } dangerouslySetInnerHTML={ { __html: dataPerson?.description } } />
              </Typography>
            </div>
        </focuser.FocusableBlock>
      }
    </div>
  )
}

export const ActorPageHeader = ActorPageHeader$;