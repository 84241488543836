import React from 'react';

import { squareImageWidthVh, squareImageWidthVw } from '~app/variables';
import { useAnimationContext } from '~components/Provider/Animation';
import Card from '~typings/Card';
import { CardMutationState } from '~typings/CardMutation';
import { CardImageType } from '~typings/Image';
import { CardConfig } from '~typings/ScreenCardConfigs';
import { SquareCard } from '~ui/CardV2/Square/SquareCard';

import { useCardImage, useDynamicProgress, useGetFieldsFromCard } from '../hooks';

interface PropsTS {
  isFocused: boolean;
  card: Card;
  mutationState: CardMutationState | null;
  cardConfig: CardConfig;
  hardcodedProgress?: number;
}

export function SquareCardWrapper(props: PropsTS) {
  const {
    isFocused,
    card,
    cardConfig,
    hardcodedProgress,
    mutationState
  } = props;

  const {
    showBottomTextBlock,
    contentProviderIconUrl,
    label,
  } = useGetFieldsFromCard(card, cardConfig);

  const imageSource = useCardImage({
    cardImages: card.images,
    isCardInappropriate: card.inappropriate,
    cardImageType:  CardImageType.Card1x1,
    widthVW: squareImageWidthVw,
    heightVH: squareImageWidthVh,
  });

  const progress = useDynamicProgress({
    hardcodedProgress,
    cardMutationState: mutationState,
  });

  const { isAnimationEnabled } = useAnimationContext();


  return (
    <SquareCard
      isFocused={ isFocused }
      contentProviderIconUrl={ contentProviderIconUrl }
      showBottomTextBlock={ showBottomTextBlock }
      progress={ progress }
      imageUrl={ imageSource }
      label={ label }
      certificationTag={ card.certification_tags?.[0] }
      additionalName={ card.additional_name }
      name={ card.name }
      isAnimationEnabled={ isAnimationEnabled }
    />
  );
}
