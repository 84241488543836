import { gw_00,gw_10 } from 'customization-project-name/colors.js'
import React from "react";


interface Props {
  width?: number;
  height?: number;
  color?: string;
  isFocused: boolean;
}

export function SpaceSearch(props: Props) {
  const { width = 2.03125, height = 3.6111111111111107, isFocused } = props
  return (
    <div style={ { width: `${ width }vw`, height: `${ height }vh`, display: 'flex' } }>
      <svg viewBox="0 0 34 34"
           style={ { width: '100%', height: '100%', fill: isFocused ? gw_00 : gw_10 } }>
        <path d="M2 20H4.8125V24.0922H29.1875V20H32V26.3309H2V20Z"/>
      </svg>
    </div>
  )
}