import { useQuery } from 'react-query';

import SceneItem from '~components/Scene/typings';
import { queryClient } from '~global';
import { useClient, useConfig } from '~global';
import expand from '~hooks/fetch/expand';
import { getAuthData } from "~hooks/fetch/useAccount";
import ApiClient from '~lib/ApiClient';
import ItemObject from '~typings/ItemObject';
import ResponseSingle from '~typings/ResponseSingle';

import { getLimitFromConfig } from '../utils';
import { fetchAuidoshowWithParts, fetchPartWithAudioShow } from './audioshowFetchs';
import { getMediaItemKey,PREFIX_OF_A_COMPOSITE_KEY } from './mediaItemKeys';
import { fetchEpisodeWithSeriesSeasons, fetchSeriesWithSeasonEpisodes } from './seriesEpisodesFetchs';


type FetchURL = Readonly<{
  [key in ItemObject]?: string;
}>;

type SingleDataItem = ResponseSingle<SceneItem>;

const fetchURLs: FetchURL = {
  [ItemObject.Channel]: '/v5/channels',
  [ItemObject.Movie]: '/v5/movies',
  [ItemObject.ProgramEvent]: '/v2/epg/program_events',
  [ItemObject.News]: '/v1/news',
};

const fetch = async (
  cacheData,
  client: ApiClient,
  limit: number,
  accessToken: string | null,
  ID?: string,
  object?: ItemObject) => {
    if (cacheData) {
      return cacheData;
    }

    if (!ID || !object) {
      return null;
    }

    if(object === ItemObject.Series){
      return fetchSeriesWithSeasonEpisodes(client, limit, accessToken, ID)
    }

    if(object === ItemObject.Episode){
      return fetchEpisodeWithSeriesSeasons(client, limit, accessToken, ID);
    }

    if(object === ItemObject.Part){
      return fetchPartWithAudioShow(client, limit, accessToken, ID);
    }

    if(object === ItemObject.AudioShow){
      return fetchAuidoshowWithParts(client, limit, accessToken, ID);
    }


    if(object === ItemObject.Movie
      || object === ItemObject.Channel
    ) {
      return await client.get(
        `${ fetchURLs[object] }/${ ID }`,
        {
          access_token: accessToken,
        }
      );
    }

    return await client.get(
      `${ fetchURLs[object] }/${ ID }`,
      {
        access_token: accessToken,
        [`expand[${ object }]`]: expand[object],
      }
    );
};


const getCachedMediaItem = <T, >(ID?: string, object?: ItemObject): ResponseSingle<T> | undefined => {
  const { accessToken } = getAuthData();
  const queryKey = getMediaItemKey(accessToken, ID, object);
  const cachedData = queryClient.getQueryData<ResponseSingle<T>>(queryKey);

  return cachedData;
};

const useMediaItem = (ID?: string, object?: ItemObject) => {
  const { accessToken } = getAuthData();
  const queryKey = getMediaItemKey(accessToken, ID, object);
  const cacheData = getCachedMediaItem(ID, object) || undefined;
  const client = useClient();
  const limit = getLimitFromConfig(useConfig())

  return useQuery<SingleDataItem>({
    queryKey,
    queryFn: () => fetch(cacheData, client, limit, accessToken, ID, object),
    enabled: !!ID,
  });
};


export {
  fetchSeriesWithSeasonEpisodes,
  getCachedMediaItem,
  getMediaItemKey,
  PREFIX_OF_A_COMPOSITE_KEY,
};

export default useMediaItem;
