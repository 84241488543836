import { QueryKey } from "react-query";

import ItemObject from "~typings/ItemObject";

export const PREFIX_OF_A_COMPOSITE_KEY = 'media_item';

export const getMediaItemKey = (
  accessToken: string | null,
  ID?: string,
  object?: ItemObject | 'episodes-list' | 'parts-list',
): QueryKey => [PREFIX_OF_A_COMPOSITE_KEY, accessToken, ID, object];
