import * as React from 'react';

import preparingText from "~lib/preparingText";

import * as styles from './styles.module.css';

type Props = Readonly<{
  label: string;
  children: string | number | Array<string> | null | undefined;
}>;

const LabeledText: React.FC<Props> = ({ label, children }: Props) => {
  const text = Array.isArray(children) ? children.join(', ') : children;
  if (!text) return null;

  return (
    <>
      <div className={ styles.label }>{ label }</div>
      <div className={ styles.text } dangerouslySetInnerHTML={ { __html: preparingText(text.toString()) } } />
    </>
  );
};

export default LabeledText;
