import * as cn from 'classnames';
import * as React from 'react';

import SliderDotsItem from '~components/Slider/items/item-dot';
import * as styles from '~components/Slider/styles.module.css';
import ResponseMany from '~typings/ResponseMany';

import { BannerItem } from './component';
import * as scrollableStyles from './scrollable/styles.module.css';


type Props = Readonly<{
  UUID: string[];
  focusedIndex: number;
  isFocusedBlock: boolean;
  items: ResponseMany<BannerItem[]>;
  onClick?: (item: BannerItem) => void;
}>;


const ItemsWithDots = (props: Props) => {
  return (
    <div
      className={ cn(scrollableStyles.sliderScrollableWrapper, scrollableStyles.sliderDotsWrapper, {
        [styles.hidden]: (props.items.data.length <= 1),
      }) }
    >
      {
        props.items.data.map((item, index) => (
          <SliderDotsItem
            key={ props.UUID[index] }
            item={ item }
            index={ index }
            isFocused={ props.isFocusedBlock && (index === props.focusedIndex) }
            onClick={ props.onClick }
          />
        ))
      }
    </div>
  );
};


export default React.memo(ItemsWithDots);
