import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { SpinnerFullScreen } from './spinnerFullScreen';

type Props = Omit<FocusableBlockProps, 'isFocused' | 'noNeedForceFocus' | 'isLastBlock'>;

/**
 * Спиннер, который нужно показать во весь экран, и с отключенными стрелками (pointer-ами).
 */
export const SpinnerFullScreenIsolated: React.FC<Props> = (props) => {
  return (
    <focuser.Isolation>
      <focuser.FocusableBlock
        isFocused
        noNeedForceFocus
        isLastBlock
        { ...props }
      >
        <SpinnerFullScreen />
      </focuser.FocusableBlock>
    </focuser.Isolation>
  );
};
