const LinksRegexp = /\[([^\[\]]*?)\]\((\S*?)\)/gi;

/*
    Input example:
      [Google](https://google.com) is a world domination company.
    Result:
      [
        { text: 'Google', href: 'https://google.com'' },
        ' is a world domination company.'
      ]
*/
export const parseTextToElements = (markedUpText: string) => {
  const elements: Array<string | { text: string, href: string }> = [];
  let textBlockStartIndex = 0;
  let textTailStartIndex = 0;
  let match;

  while ((match = LinksRegexp.exec(markedUpText)) !== null) {
    if (match.index === LinksRegexp.lastIndex) {
      LinksRegexp.lastIndex++;
    }
  
    const [fullLink, linkText, linkUrl] = match;
    const prevTextBlock = markedUpText.substring(textBlockStartIndex, match.index);
    
    elements.push(prevTextBlock);
    elements.push({ text: linkText, href: linkUrl });

    textBlockStartIndex = LinksRegexp.lastIndex;
    textTailStartIndex = match.index + fullLink.length;
  }
  if (textTailStartIndex !== markedUpText.length) {
    elements.push(markedUpText.substring(textTailStartIndex));
  }

  return elements;
}
