import { stringify } from 'query-string';
import React from 'react';

import useLocationSearch from '~hooks/useLocationSearch';

import { MediaScreenSearchParams, RET_PATH_SEARCH_FIELD } from './MediaItemScreenTypes';


/**
 * @deprecated
 * TODO: поменять на `useLocationSearchState`
 */
export const useMediaItemScreenSearchParams = () => {
  const search = useLocationSearch();
  const parsedScreenSearchParams: MediaScreenSearchParams | undefined = React.useMemo(() => {
    const encodedField = search[RET_PATH_SEARCH_FIELD];

    if (!encodedField) {
      return;
    }

    try {
      return JSON.parse(encodedField as string);
    } catch {
      return;
    }
  }, [search]);

  return parsedScreenSearchParams

}

export const getMediaItemScreenSearchParams = (searchParams: MediaScreenSearchParams) => {
  return stringify({
    [RET_PATH_SEARCH_FIELD]: JSON.stringify(searchParams),
  });
};
