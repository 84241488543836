import * as React from 'react';



export interface State {
  isMenuAppeared: boolean;
  isMenuOpened: boolean;
  isPopupOpened: boolean;
  openPopupCounter: number;
  isExitOpened: boolean;
}

export enum ActionType {
  ChangeMenuState,
  ChangeMenuAppearing,
  ChangePopUpState,
  ChangeExitState,
}

export interface Action {
  type: ActionType;
  isMenuOpened?: boolean;
  isMenuAppeared?: boolean;
  isPopupOpened?: boolean;
  isExitOpened?: boolean;
}

type Dispatch = (action: Action) => void;


export const MenuContext = React.createContext<State>({} as State);
export const MenuDispatchContext = React.createContext<Dispatch | undefined>(undefined);

export const useMenu = () => {
  const context = React.useContext(MenuContext);

  if (context === undefined) {
    throw new Error('useMenu must be used within a MenuContext')
  }
  return context;
};
export const useMenuAction = () => {
  const context = React.useContext(MenuDispatchContext);

  if (context === undefined) {
    throw new Error('useMenu must be used within a MenuContext')
  }
  return context;
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ChangeMenuState: {
      return ({
        ...state,
        isMenuOpened: !!action.isMenuOpened,
      });
    }
    case ActionType.ChangePopUpState: {
      const openPopupCounter = state.openPopupCounter + (action.isPopupOpened ? 1 : -1);
      return ({
        ...state,
        openPopupCounter,
        isPopupOpened: openPopupCounter > 0,
      });
    }
    case ActionType.ChangeMenuAppearing: {
      return ({
        ...state,
        isMenuAppeared: !!action.isMenuAppeared,
      });
    }
    case ActionType.ChangeExitState: {
      return ({
        ...state,
        isExitOpened: !!action.isExitOpened,
      });
    }
    default:
      return state;
  }
};
