import React from 'react';

import * as styles from './icons.module.css';
/* eslint-disable max-len */
export const ToLowerCaseIcon = () => {
  return (
    <svg
      className={ styles.icon }
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6362 11.2498L12.8413 0.353318C12.3693 -0.117773 11.6162 -0.117773 11.1442 0.353318L0.359305 11.2498C0.00784131 11.598 -0.0925769 12.1306 0.0881758 12.5914C0.268928 13.0523 0.710768 13.3493 1.20282 13.3493H4.13503V22.1361C4.13503 23.1705 4.95846 24 5.96264 24H18.0329C19.0471 24 19.8605 23.1602 19.8605 22.1361V13.3493H22.7927C23.2848 13.3493 23.7166 13.0523 23.9074 12.5914C24.0982 12.1306 23.9877 11.598 23.6362 11.2498Z"
        fill="currentColor"
      />
    </svg>
  );
};
