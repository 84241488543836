import create, { EqualityChecker, StateSelector } from 'zustand';

import { fetchAppConfigs } from '~hooks/fetch/useConfig';
import { registerDevice } from '~hooks/fetch/useDeviceRegister/useDeviceRegister';
import { fetch as fetchNetwork } from '~hooks/fetch/useNetwork/useNetwork';
import { fetchScreenCardConfigs } from '~hooks/fetch/useScreenCardConfig';
import ApiClient from '~lib/ApiClient';
import { FetchRetryerParams } from '~lib/fetch';
import AppConfig from '~typings/AppConfig';
import { DeviceTokenObject } from '~typings/Devices';
import KeyCodes from '~typings/KeyCodes';
import { NetworkInfo } from '~typings/Network';
import Platform from '~typings/Platform';
import { CardConfigByScreen } from '~typings/ScreenCardConfigs';

import { initApiClient } from './client';
import { initFeatureFlags } from './featureFlags';
import { initPlatformParams } from './initPlatform';

export interface NotInitedState {
  status: 'idle';
}

export interface LoadingState {
  status: 'loading';
}

export interface ErrorState {
  status: 'error';
}

export interface ReadyState {
  status: 'ready';
  network: NetworkInfo;
  apiClient: ApiClient;
  config: AppConfig;
  screenCardConfigs: CardConfigByScreen;
  platformParams: {
    platform: Platform;
    keyCodes: KeyCodes;
  };
  device: DeviceTokenObject;
}

export type State = NotInitedState | ReadyState | LoadingState | ErrorState;

export type Store = {
  state: State;
  setState: (state: State) => void;
  setStatusLoading: () => void;
  setStatusError: () => void;
  loadData: () => Promise<void>;
  fillWithFakeData: () => Promise<void>;
};

/**
 * Стора, в которой хранятся глобальные данные,
 * которые необходимы для работы всего приложения
 */
export const useVitalStore = create<Store>((set, get) => ({
  state: {
    status: 'idle',
  },
  setStatusLoading: () =>
    set((draft) => {
      draft.state = {
        status: 'loading',
      };
    }),
  setStatusError: () =>
    set((draft) => {
      draft.state = {
        status: 'error',
      };
    }),

  setState: (newState) =>
    set((draft) => {
      draft.state = newState;
    }),

  loadData: async () => {
    get().setStatusLoading();
    const apiClient = initApiClient();

    const retryParams: FetchRetryerParams = {
      retryCount: 5,
      waitTime: 1000,
    };

    try {
      const [network, config, screenCardConfigs, platformParams] = await Promise.all([
        fetchNetwork(apiClient, retryParams),
        fetchAppConfigs(apiClient, retryParams),
        fetchScreenCardConfigs(apiClient, retryParams),
        initPlatformParams(),
      ]);

      initFeatureFlags(config.smartTV?.featureFlags);

      const device = await registerDevice(apiClient, platformParams.platform, retryParams);
      apiClient.setDefaultHeader('Device-Token', device.device_token);

      get().setState({
        status: 'ready' as const,
        network,
        config,
        apiClient,
        screenCardConfigs,
        platformParams,
        device,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      get().setStatusError();
    }
  },
  /**
   * Заполняет стор данными по умолчанию, для сценариев тестирования
   * Еще используется в сценарии краша приложения
   */
  fillWithFakeData: async () => {
    const platformParams = await initPlatformParams();
    get().setState({
      status: 'ready' as const,
      platformParams,
      apiClient: {} as ApiClient,
      network: {} as NetworkInfo,
      config: {
        smartTV: {
          ratingSystem: 'Russian',
        },
      } as AppConfig,
      screenCardConfigs: {} as CardConfigByScreen,
      device: {} as DeviceTokenObject,
    });
  },
}));

/**
 * Хук помогает использовать `ready` состояние из VitalStore
 * VitalStore должна быть инициализирована (поле `state.status` должно быть равно `ready`) для того,
 * чтобы этот хук корректно работал.
 *
 * @param selector Селектор, который помогает получить нужные данные.
 * Корректно подобранный селектор + эквивалентность помогают избежать перерендеров
 * @returns Возвращает результат применения селлектора
 */
export const useReadyVitalStore = <U>(
  selector: StateSelector<ReadyState, U>,
  equalityFn?: EqualityChecker<U>,
): U => {
  const status = useVitalStore((store) => store.state.status);
  const globalDataPart = useVitalStore<U>(
    (store) => selector(store.state as ReadyState),
    equalityFn,
  );
  if (status !== 'ready') {
    throw new Error('[useGlobalState] used before initialization');
  }

  return globalDataPart;
};
