import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ActionType,useApp, useAppAction } from '~components/Provider/App';
import { logout } from '~hooks/fetch/useAccount';
import usePrevious from '~hooks/usePrevious';
import { NO_INTERNET_CONNECTION_AVAILABLE } from '~localization';
import { Alert } from '~ui/Alert';
import Fade from '~ui/Effects/Fade';

import * as styles from './styles.module.css';


export const NetworkManager: React.FC = () => {
  const { isOnLine, isIPTVOn } = useApp();
  const appAction = useAppAction();
  const history = useHistory();

  const handleUpdateNetworkState = React.useCallback(() => {
    appAction({
      type: ActionType.SetIsOnLine,
      payload: { isOnLine: navigator.onLine },
    });
  }, []);

  React.useEffect(() => {
    window.addEventListener('online', handleUpdateNetworkState);
    window.addEventListener('offline', handleUpdateNetworkState);

    return () => {
      window.removeEventListener('online', handleUpdateNetworkState);
      window.removeEventListener('offline', handleUpdateNetworkState);
    };
  }, []);

  const prevIsOnLine = usePrevious(isOnLine);

  React.useEffect(() => {
    if (prevIsOnLine === false && isOnLine && isIPTVOn) {
      logout();

      setTimeout(() => {
        history.length = 0;
        history.replace('/');
      }, 100);
    }
  }, [isOnLine, isIPTVOn]);

  if (isOnLine) { return null; }

  const notification = () => (
    <Fade
      isVisible={ !isOnLine }
      className={ styles.text }
    >
      <FormattedMessage id={ NO_INTERNET_CONNECTION_AVAILABLE } />
    </Fade>
  );

  return <Alert notification={ notification() } />;
};

export default React.memo(NetworkManager);
