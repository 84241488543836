import * as DOMPurify from 'dompurify';
import { truncate } from 'lodash';
import * as React from 'react';

import * as styles from './styles.module.css';


const preparingText = (text: string): string => {
  return DOMPurify.sanitize(
    text,
    { ALLOWED_TAGS: [] },
  );
};

type Props = Readonly<{
  description?: string;
}>;

const SceneMediaItemDescriptionText: React.FC<Props> = ({ description }: Props) => {
  if (!description || description.length === 0) { return null }

  const __html = truncate(preparingText(description), {
    length: 300,
    separator: /\.|\s|\\n/,
    omission: '',
  });

  return (
    <div
      className={ styles.descriptionText }
      dangerouslySetInnerHTML={ { __html } }
    />
  );
};


export default React.memo(SceneMediaItemDescriptionText);
