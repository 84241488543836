import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useBannersV4 } from '~hooks/fetch/useBannersV4/useBanners';
import useDeepLink from '~hooks/useDeepLink';
import BannerBig from '~typings/BannerBig';
import { Block } from '~typings/Block';

import { BigBannerContent } from './components/BigBannerContent';
import { BigBannerLoading } from './components/BigBannerLoading';

type Props = {
  blockId: Block['id'];
};

/**
 * Блок баннеров "Большой баннер"
 *
 * Работает на фокусере. Имеет четкую высоту,
 * но каринка выходит за рамки собственной высоты, так сделано специально, по дизайну.
 */
const BigBannerBlock$: React.FC<Props> = ({ blockId }) => {
  const history = useHistory();
  const { urlFromDeepLink } = useDeepLink();
  const { data: items } = useBannersV4(blockId);
  const bigBanners = items?.data as BannerBig[] | undefined;

  const handleClick = useCallback(
    (banner: BannerBig) => {
      if (banner.deeplink) {
        const URL = urlFromDeepLink(banner.deeplink);
        if (URL) {
          history.push(URL);
        }
      }
    },
    [urlFromDeepLink],
  );

  if (!bigBanners?.length) {
    return <BigBannerLoading />;
  }

  return (
    <BigBannerContent
      bigBanners={ bigBanners }
      onClick={ handleClick }
    />
  );
};

export const BigBannerBlock = React.memo(BigBannerBlock$);
