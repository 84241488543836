// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".progressBarWrapper--rw9eB {\n  width: 100%;\n\n  /* 4px */\n  height: 0.313vw;\n  background-color: rgba(255, 255, 255, 1);\n  -webkit-border-radius: 0.15625vw;\n          border-radius: 0.15625vw;\n}\n\n.progressBar--uw_8I {\n  height: 100%;\n  background-color: rgba(253, 216, 53, 1);\n  -webkit-border-radius: 0.15625vw;\n          border-radius: 0.15625vw;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/ui-kit/Other/ProgressBar/ProgressBar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;;EAEX,QAAQ;EACR,eAAe;EACf,wCAAwB;EACxB,gCAAwB;UAAxB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,uCAAwB;EACxB,gCAAwB;UAAxB,wBAAwB;AAC1B","sourcesContent":[".progressBarWrapper {\n  width: 100%;\n\n  /* 4px */\n  height: 0.313vw;\n  background-color: $gw_11;\n  border-radius: 0.15625vw;\n}\n\n.progressBar {\n  height: 100%;\n  background-color: $ac_00;\n  border-radius: 0.15625vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var progressBarWrapper = "progressBarWrapper--rw9eB";
export var progressBar = "progressBar--uw_8I";
export default ___CSS_LOADER_EXPORT___;
