export interface FetchRetryerParams {
  retryCount: number;
  waitTime: number;
}
/**
 * Утилита ретраит любую асинхронную функцию, которая может выкинуть ошибку
 *
 * `retryCount` - кол-во ретраев. Должно быть позитивным числом.
 *  Если указать меньше 1, то функция выполнится всего 1 раз
 *
 * `waitTime` - время в миллисекундах, которое нужно ждать перед следующим ретраем,
 * если указать отрицательное, то ожидание будет 0мс.
 */
export const fetchRetryer = async <RESULT>(
  fetchFunction: () => Promise<RESULT>,
  { retryCount, waitTime }: FetchRetryerParams,
): Promise<RESULT> => {
  try {
    return await fetchFunction();
  } catch (err) {
    const remainingCountToRetry = retryCount - 1;

    if (remainingCountToRetry <= 0) {
      throw err;
    }

    await wait(Math.max(waitTime, 0));

    return fetchRetryer(fetchFunction, {
      retryCount: remainingCountToRetry,
      waitTime,
    });
  }
};


const wait = (ms: number)=> new Promise<void>(res=>setTimeout(() => res(), ms))
