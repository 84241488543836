import * as cn from 'classnames';
import * as React from 'react';

import { Option } from '~typings/DropdownFilter';

import ArrowDown from './ArrowDown';
import CrossIcon from './CrossIcon';
import * as styles from './styles.module.css';


type PilsButtonProps = {
  isChecked?: boolean;
  isDropdown?: boolean;
  isCloseButton?: boolean;
  isFocused?: boolean;
  onFocus?: (onFocusArg?: any) => void,
  onFocusArg?: any;
  onClick?: (event: React.SyntheticEvent<EventTarget> | any) => void,
  onClickArg?: any;
  onHover?: () => void,
  onLeave?: () => void,
  selectedFilters?: Option[],
  className?: string,
  filterId?: string,
  children?: React.ReactNode;
};

const PilsButton = (props: PilsButtonProps) => {

  const mergedFilters = React.useMemo(() =>
    props.selectedFilters?.map(option => option.name)
    .join(', '), [props.isDropdown, props.selectedFilters])

  return (
    <div
      className={
        cn(styles.pils, props.className, {
          [styles.isChecked]: props.isChecked,
          [styles.isFocused]: props.isFocused,
          [styles.hasDropdown]: props.isDropdown,
          [styles.hasValue]: mergedFilters,
          'focusedNavigationNode': props.isFocused,
        })
      }
      onClick={ props.onClick }
      onFocus={ props.onFocus }
      // onMouseOverCapture={ props.onHover }
      // onMouseLeave={ props.onLeave }
    >
      { mergedFilters?.length ? mergedFilters : props.children }
      { props.isDropdown && <ArrowDown className={ styles.isDropdown }/> }
      { props.isCloseButton && <CrossIcon className={ styles.closeButton }/> }
    </div>
  );
};


export default PilsButton;
