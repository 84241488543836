import focuser, { FocuserKeyHandler } from '@focuser';
import * as React from 'react';

import CommonButton from '~components/MediaItemPage/Buttons/CommonButton';
import DefaultBarItem from '~components/MediaItemPage/Description/bar/DefaultBarItem';
import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { useFavoriteItem, useToggleFavorite } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { Method, useVote, useVoteMutation } from '~hooks/fetch/useVote';
import FavoriteType from '~typings/FavoriteType';
import ItemObject from '~typings/ItemObject';
import Movie from '~typings/Movie';
import Series from '~typings/Series';
import { VoteAction } from '~typings/Vote';

import { getBarItems } from './PromocodeRentPlanResourcePage.utils';
import * as styles from './styles.module.css';


type Props = Readonly<{
  mediaItem: Movie | Series;
}>;

const ResourceBarItems: React.FC<Props> = ({mediaItem}) => {
  const { mutate: toggleFavorite, isLoading } = useToggleFavorite();
  const { mutate: toggleVote } = useVoteMutation(mediaItem.object, mediaItem.id);
  const { setItem: playerSetItem } = usePlayerStateActions();
  const favoriteTypeOfMediaItem = mediaItem.object === ItemObject.Movie
    ? FavoriteType.Movies
    : FavoriteType.Series
  const favoriteItemQuery = useFavoriteItem(favoriteTypeOfMediaItem, mediaItem.id);
  const isInFavorites = Boolean(favoriteItemQuery.data);
  const { data: vote } = useVote(mediaItem.object, mediaItem.id);
  const [focusedIdx, setFocusedIdx] = React.useState(0);

  const barItems = React.useMemo(() => (
    getBarItems({
      hasTrailer: !!mediaItem.trailer,
      object: mediaItem.object,
      isInFavorites,
      vote: vote?.data?.action,
    })
  ), [mediaItem.object, !!mediaItem.trailer, vote?.data, isInFavorites])

  const handleRight: FocuserKeyHandler = (event) => {
    if (focusedIdx < barItems.length - 1) {
      setFocusedIdx(focusedIdx + 1);
      event.stop();
    }
  };

  const handleLeft: FocuserKeyHandler = (event) => {
    if(focusedIdx > 0) {
      setFocusedIdx((focusedIdx - 1));
      event.stop();
    }
  }

  const handleBarClick = (barItem: BarItem) => {
    if (barItem === BarItem.WatchLaterRemove && !isLoading) {
      toggleFavorite({
        itemID: mediaItem.id,
        isInFavorites,
        type: favoriteTypeOfMediaItem
      });
    }
    else if (barItem === BarItem.WatchLaterAdd && !isLoading) {
      toggleFavorite({
        itemID: mediaItem.id,
        isInFavorites,
        type: favoriteTypeOfMediaItem
      });
    }
    else if (barItem === BarItem.Trailer && mediaItem.trailer) {
      playerSetItem(mediaItem.trailer);
    }
    else if (barItem === BarItem.RatedUp || barItem === BarItem.RatedDown) {
      toggleVote({ method: Method.DELETE });
    }
    else if (barItem === BarItem.RateUp) {
      toggleVote({ method: Method.POST, mention: VoteAction.Up });
    }
    else if (barItem === BarItem.RateDown) {
      toggleVote({ method: Method.POST, mention: VoteAction.Down });
    }
  };

  const wrapperDIV = (item: BarItem, button: React.ReactNode) => (
    <div
      key={ item }
      className={ styles.buttonWrapper }
    >
      { button }
    </div>
  );

  const render = (item: BarItem, index: number) => {
    return (
      <focuser.FocusableBlock
        isFocused={ focusedIdx === index }
        key={ item }
        onForceFocus={ () => setFocusedIdx(index) }
        emitForceFocusOnHover
        onClick={ () => handleBarClick(item) }
      >
        {({ isFocused }) => {
          switch (item) {
            case BarItem.Trailer:
              return wrapperDIV(
                item,
                <CommonButton
                  barItem={ item }
                  isFocused={ isFocused }
                />,
              );

            default:
              return (
                <DefaultBarItem
                  key={ item }
                  item={ item }
                  isFocused={ isFocused }
                />
              );
          }
        }}
      </focuser.FocusableBlock>

    )

  };

  return (
    <focuser.FocusableBlock
      className={ styles.mediaItemBar }
      isFocused
      onKeyRight={ handleRight }
      onKeyLeft={ handleLeft }
      noNeedForceFocus
      // isPointerRightAvailable={ focusedIdx < barItems.length - 1 }
      // isPointerLeftAvailable={ focusedIdx > 0 }
    >
      { barItems.map(render)}
    </focuser.FocusableBlock>
  );
}

export default ResourceBarItems;
