import { isEqual } from 'lodash';
import * as React from 'react';

import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { PlayerState, PlayerStatus } from '~components/ScenePlayer/Player/useHandleEvents';
import { IPlayer } from '~lib/player/typings';


type Props = Readonly<{
  state: PlayerState;
  player: IPlayer;
}>;


const useVideoTracks = (props: Props) => {
  const isAutoQuality = props.player.isAutoQualityEnabled();
  const playerState = usePlayerState();
  const { setVideoTracks } = usePlayerStateActions();

  const handleSetVideoTracks = React.useCallback(() => {
    const videoTracks = props.player.getVideoTracks();
    const newSelectedTrackIndex = videoTracks.findIndex(({ isSelected }) => isSelected);
    const selectedTrackIndex = playerState.videoTracks.findIndex(({ isSelected }) => isSelected);

    if (
      newSelectedTrackIndex !== selectedTrackIndex ||
      !isEqual(playerState.videoTracks, videoTracks)
    ) {
      setVideoTracks(videoTracks);
    }
  }, [playerState.videoTracks]);

  React.useEffect(() => {
    setVideoTracks(props.player.getVideoTracks());
  }, [isAutoQuality]);
  React.useEffect(() => {
    if (
      props.state.status === PlayerStatus.LoadedMetaData
      || props.state.status === PlayerStatus.TrackChanged
      || props.state.status === PlayerStatus.CanPlay
    ) {
      handleSetVideoTracks();
    }
  }, [props.state, playerState.videoTracks]);
};


export default useVideoTracks;
