import React, { memo } from 'react';
import { useHistory } from 'react-router-dom'

import AgeConfirmationPopup from '~components/AgeConfirmationPopup';
import PinCodePopup from '~components/PinCodePopup';
import PolicyConfirmationPopup from '~components/PolicyConfirmationPopup';
import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { useMenu } from '~components/Provider/Menu';
import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { ConfirmationStatus, State } from '~components/Provider/Player/component.helpers';
import { useClient, useConfig } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import useDelayedChange from '~hooks/useDelayedChange';
import usePageInfo from '~hooks/usePageInfo';
import { isChannelURL, isEpisodeURL } from '~lib/url';
import getWatchProgress from '~lib/watchProgress';
import { NewPinCodeFlow$ } from "~newapp/blocks/playerPopcorn/flows/pincode/new/NewPinCodeFlow";
import ItemObject from '~typings/ItemObject';
import Manifest from '~typings/Manifest';
import PlayerAccessError from '~typings/PlayerStatus';
import Protocol from '~typings/Protocol';
import Stream from '~typings/Stream';
import Fade from '~ui/Effects/Fade';

import { Player3 } from './player3';
import PlayerErrors from './PlayerErrors';
import getProtocolByStreamURL from './playerLib/getProtocol';
import parseDashManifest from "./playerLib/manifest/parsing/dash";
import parseHLSManifest from './playerLib/manifest/parsing/hls';
import PlayerLoadScreen from './PlayerLoadScreen';
import usePlayer from './ScenePlayer.helpers';
import * as styles from './styles.module.css';

interface PropsTS {
  playerState: State;
}

// eslint-disable-next-line react/display-name
const Player2 = memo((props: PropsTS) => {
  const appAction = useAppAction();
  const { timeout } = useApp();

  const player = usePlayer();

  const client = useClient();
  const { accessToken } = getAuthData();
  const playerState = usePlayerState();
  const { playList } = playerState
  const watchProgress = getWatchProgress({
    accessToken,
    id: props.playerState.item?.id,
    object: props.playerState.item?.object,
  });

  const { generic } = useConfig();

  const { setConfirmationRequired, flush } = usePlayerStateActions();

  const history = useHistory();
  const { isPopupOpened } = useMenu();
  const {
    isPlayerPage: playerPageHashValue,
    mediaItemPage,
  } = usePageInfo();


  const [manifest, setManifest] = React.useState<Manifest | null>(null);
  const getManifest = async (stream: Stream | null) => {
    if (stream) {
      if ((getProtocolByStreamURL(stream.url) || stream.protocol) === Protocol.HLS) {
        const result = await parseHLSManifest(client, stream.url);

        setManifest(result);
        // } else if (stream.protocol === Protocol.DASH) {
        //   setManifest({});
      }
      else if ((getProtocolByStreamURL(stream.url) || stream.protocol) === Protocol.DASH) {
        const result = await parseDashManifest(playerState);
        setManifest(result);
      }
      else {
        setManifest({});
      }
    }
    else {
      setManifest(null);
    }
  };
  const isPlayerPage = React.useRef<boolean>(false);
  const isShowingHTML = useDelayedChange<boolean>(!!props.playerState.item, 500);
  const isNothingToShowInHTML = React.useMemo(() => (
    !isShowingHTML && !props.playerState.item
  ), [isShowingHTML, props.playerState.item]);


  const isAllowToRenderError = (mediaItemPage && mediaItemPage.id);
  const isError = isAllowToRenderError && (
    (props.playerState.access !== null && props.playerState.access !== PlayerAccessError.AccessAllowed)
    || props.playerState.error !== null
  );


  const [nextArchiveEventIndex, setNextArchiveEventIndex] = React.useState<number | undefined>(undefined)

  const currentEventId: string = props.playerState?.item?.id || ''


  const handleCanPlay = React.useCallback(() => {
    if (!isPlayerPage.current) {
      if (isChannelURL(history.location.pathname)) {
        history.replace({ search: 'player=true' });
      }
      else {
        history.push({ search: 'player=true' });
      }
    }
  }, [isPlayerPage.current, props.playerState.item?.id, history.location.pathname]);

  const addTimeout = () => {
    appAction({
      type: ActionType.setAddTimeout,
      payload: { timeout: true },
    });

    if (generic.age_restriction_confirmation_timeout_secs !== undefined) {
      setTimeout(() => {
        appAction({
          type: ActionType.setAddTimeout,
          payload: { timeout: false },
        });
      }, generic.age_restriction_confirmation_timeout_secs * 1000)
    }
    else {
      setTimeout(() => {
        appAction({
          type: ActionType.setAddTimeout,
          payload: { timeout: false },
        });
      }, 100)
    }
  }

  const handleEnded = React.useCallback(() => {
    if (playList?.[0]) {
      const firstEventInList = playList?.[0].split(' ', 2)
      const nextEvent = nextArchiveEventIndex && playList?.[nextArchiveEventIndex].split(' ', 2)
      const [nextEventId, _isEventListItem] = nextEvent || []
      const [_currentEventId, isEventListItem] = firstEventInList || []

      if (nextArchiveEventIndex === undefined && isEventListItem) {
        flush();
        history.goBack();
        return
      }
      if (nextArchiveEventIndex) {
        history.replace(`/media-item/${ ItemObject.ProgramEvent }/${ nextEventId }/player?player=true`)
        return
      }
      if (isChannelURL(history.location.pathname) && !nextArchiveEventIndex && !isEventListItem) {
        const { item } = props.playerState
        item?.object === ItemObject.ProgramEvent &&
        (history.replace(`/media-item/${ ItemObject.Channel }/${ item?.channel_id }?player=true`));
        return
      }
    }
    if (isPlayerPage.current && !isEpisodeURL(history.location.pathname)) {
      flush();
      history.goBack();
    }
  }, [isPlayerPage.current, history.location.pathname, nextArchiveEventIndex, player]);

  const popupsBackHandler = React.useCallback(() => {
    flush();
    if (
      isChannelURL(history.location.pathname)
      || (
        isEpisodeURL(history.location.pathname)
        && isPlayerPage.current
      )
    ) {
      history.goBack();
    }
  }, [isPlayerPage.current, history.location.pathname]);

  React.useEffect(() => {
    isPlayerPage.current = playerPageHashValue;
  }, [playerPageHashValue]);

  React.useEffect(() => {
    if (isError) {
      if (
        isChannelURL(history.location.pathname) ||
        playerPageHashValue
      ) {
        history.replace({ search: 'player=error' });
      }
      else {
        history.push({ search: 'player=error' });
      }
    }
  }, [isError, history.location.pathname]);

  React.useEffect(() => {
    playList?.forEach((listItem, index) => {
      const [listItemId, isEventListItem] = listItem.split(' ')

      if (listItemId === currentEventId && index === playList.length - 1 && isEventListItem) {
        setNextArchiveEventIndex(undefined)
        return
      }

      if (listItemId === currentEventId && index === playList.length - 1) {
        setNextArchiveEventIndex(undefined)
        return
      }
      if (listItemId === currentEventId && index + 1 <= playList.length - 1) {
        setNextArchiveEventIndex(index + 1)
      }
    })
  }, [props.playerState.stream]);

  React.useEffect(() => {
    getManifest(props.playerState.stream || null);
  }, [props.playerState.fetchResponse])

  if (props.playerState.confirmationRequired !== null) {
    switch (props.playerState.confirmationRequired) {
      case ConfirmationStatus.NewPinCodeFlow: {
        return <NewPinCodeFlow$ />
      }
      case ConfirmationStatus.PolicyConfirmation:
        return (
          <PolicyConfirmationPopup
            onAccept={ () => setConfirmationRequired(null) }
            onBack={ popupsBackHandler }
          />
        );
      case ConfirmationStatus.PinCodeConfirmation:
        return (
          <PinCodePopup
            onSuccess={ (code, wasDropped) => {
              if (wasDropped) {
                flush();
                return;
              }
              // addTimeout();
              setConfirmationRequired(ConfirmationStatus.WithoutConfirmation);
            } }
            onBack={ popupsBackHandler }
          />
        );
      case ConfirmationStatus.AgeConfrimation:
        if (!timeout) {
          if (
            (playerState.item?.object === ItemObject.Episode
              && playerState.item.series?.certification_ratings?.[0]?.may_be_inappropriate === true)
            ||
            (playerState.item?.object === ItemObject.Movie
              && playerState.item?.certification_ratings?.[0]?.may_be_inappropriate === true)
            ||
            (playerState.item?.object === ItemObject.Channel
              && playerState.item?.certification_ratings?.[0]?.may_be_inappropriate === true)
            ||
            (playerState.item?.object === ItemObject.ProgramEvent
              && playerState.item?.channel?.certification_ratings?.[0]?.may_be_inappropriate === true)
          ) {
            return (
              <AgeConfirmationPopup
                onAccept={ () => {
                  addTimeout();
                  setConfirmationRequired(ConfirmationStatus.WithoutConfirmation);
                } }
                onBack={ popupsBackHandler }
              />
            );
          }
          else {
            setConfirmationRequired(ConfirmationStatus.WithoutConfirmation);
          }
        }
    }
  }

  if (isError) {
    return (
      <PlayerErrors
        access={ props.playerState.access }
        item={ props.playerState.item }
        error={ props.playerState.error }
      />
    );
  }

  if (isNothingToShowInHTML || isPopupOpened) {
    return null;
  }

  return (
    <Fade duration={ 1000 } isVisible={ !!props.playerState.item }>
      <div className={ styles.scenePlayerWrapper }>
        {
          (props.playerState.item && props.playerState.stream && manifest &&
            (props.playerState.parsedManifest
              || (getProtocolByStreamURL(props.playerState.stream.url)
                || props.playerState.stream.protocol) === Protocol.MP4)) && player.agePopupTimeout === null
            ? <Player3
              isShowing={ isPlayerPage.current }
              item={ props.playerState.item }
              stream={ props.playerState.stream }
              manifest={ manifest }
              parsedManifest={ props.playerState.parsedManifest }
              timeShiftParams={ player.timeShiftParams() }
              percentsWatched={
                !watchProgress?.completed
                && watchProgress?.percents_watched
                && ((watchProgress.percents_watched || 0) > 0)
                  ? watchProgress.percents_watched
                  : undefined
              }
              onCanPlay={ handleCanPlay }
              onEnded={ handleEnded }
              onError={ player.onPlayError }
            />
            : <PlayerLoadScreen />
        }
      </div>
    </Fade>
  );
})


export { Player2 }
