import * as cn from 'classnames';
import * as React from 'react';

import SceneItem from '~components/Scene/typings';
import { CatchupStatus } from '~lib/eventsHelper';
import ItemObject from '~typings/ItemObject';
import { IconMediaItemDetails, IconMediaItemNews, IconMediaItemPlay } from '~ui/Icon';
import IconMediaItemPlayAudio from '~ui/Icon/IconMediaItemPlayAudio';

import IconCatchup from './ProgramEvent/IconCatchup';
import * as styles from './styles.module.css';


type Props = Readonly<{
  item: SceneItem;
  catchupStatus: CatchupStatus;
  isFocused: boolean;
}>;


const RenderIcon: React.FC<Props> = (props: Props) => {
  const isReadyToPlay = [
    ItemObject.Channel,
    ItemObject.Episode,
    ItemObject.ProgramEvent,
  ].includes(props.item?.object as ItemObject);
  const isNews = props.item?.object === ItemObject.News;
  const isPart = props.item?.object === ItemObject.Part;
  const isProgramEvent = props.item?.object === ItemObject.ProgramEvent;

  if (
    isProgramEvent
    && props.catchupStatus !== CatchupStatus.Unknown
  ) {
    return (
      <IconCatchup
        isFocused={ props.isFocused }
        catchupStatus={ props.catchupStatus }
        className={ styles.icon }
      />
    );
  }

  let Icon: any;
  if (props.isFocused) {
    if (isNews) {
      Icon = IconMediaItemNews;
    } else if (isReadyToPlay) {
      Icon = IconMediaItemPlay;
    } else if (isPart) {
      Icon = IconMediaItemPlayAudio;
    } else {
      Icon = IconMediaItemDetails;
    }
  }

  return (
    (Icon) ?
      <Icon
        isVisible={ props.isFocused }
        className={ cn(styles.icon, styles[props.item.object]) }
      />
      :
      null
  );
};


export default RenderIcon;
