import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch, useHistory, useParams,useRouteMatch } from 'react-router-dom';

import ErrorDialog from '~components/ErrorDialog/ErrorDialog';
import LightLoader from '~components/LightLoader';
import CardProceed from '~components/ProductPlanPage/CardProceed';
import { resolvePlayerAccessStatus } from '~components/Provider/Player/component.helpers';
import { getAuthData } from '~hooks/fetch/useAccount';
import useMediaItem from '~hooks/fetch/useMediaItem';
import useProducts from '~hooks/fetch/useProducts';
import useRentPlans from '~hooks/fetch/useRentPlans/useRentPlans';
import useStreamAccess from '~hooks/fetch/useStreamAccess';
import { useAllSubscriptions } from '~hooks/fetch/useSubscriptions';
import getProductInSubscriptions from '~lib/product/getProductInSubscriptions';
import { NO_PURCHASES } from '~localization';
import ItemObject from '~typings/ItemObject';
import { PlayerMediaItemObject } from '~typings/PlayerMediaItem';
import PlayerAccessError from '~typings/PlayerStatus';
import { RentPlanType } from '~typings/RentPlan';

import Menu from './Menu';
import PaymentProceed from './PaymentProceed';
import ProductInvoce from './ProductInvoce';
import ProductPlan from './ProductPlan';
import ProductsList from './ProductsList';
import RentInvoce from './RentInvoce';
import RentType from './RentType';
import * as styles from './styles.module.css';


const ProductsForRecource: React.FC = () => {
  // const { smartTV } = useConfig();
  const { accessToken } = getAuthData();
  const { path } = useRouteMatch();
  const history = useHistory();
  const matchPage = useRouteMatch<{ itemId: string; itemObject: ItemObject }>(
    '/:page/:id/media-item/:itemObject/:itemId/products'
  );
  const matchMediaPage = useRouteMatch<{ itemId: string; itemObject: ItemObject }>(
    '/media-item/:itemObject/:itemId/products'
  );
  const match = matchPage || matchMediaPage;
  const url = match?.url;
  const { itemId } = useParams<{ itemId: string }>();
  const { data: mediaItem } = useMediaItem(match?.params.itemId, match?.params.itemObject);
  const { data: accessRes, isLoading } = useStreamAccess({
    itemID: (mediaItem?.data?.id || null),
    itemObject: (mediaItem?.data?.object as PlayerMediaItemObject || null),
  });
  const id = mediaItem?.data?.object === ItemObject.ProgramEvent ?
    mediaItem?.data?.channel_id
    :
    mediaItem?.data?.id;
  const { data: rentPlans, isFetching: rentPlansIsFetching } = useRentPlans(id);
  const { data: products, isFetching: productsIsFetching } = useProducts({ resourceID: id });
  const { isFetchedAfterMount, subscriptions } = useAllSubscriptions()
  const productsWithSubscriptions = React.useMemo(
    () => products?.data && subscriptions ? products?.data.map(
      (product) => getProductInSubscriptions(product, subscriptions)
    ) : null,
    [products?.data, subscriptions]
  );
  const [focusOnMenu, setFocusOnMenu] = React.useState(true);

  React.useEffect(() => {
    const accessData = accessRes?.data?.[0];
    const access = accessData ? resolvePlayerAccessStatus(accessData) : null;
    if (access && url) {
      if (access === PlayerAccessError.AccessAllowed) {
        history.replace(`${url}`.replace(/\/products[.+]?$/, ''));
      } else if (
        productsWithSubscriptions?.[0]
        && !history.location.pathname.includes('subscriptions/')
      ) {
        history.replace(`${url}/subscriptions/${productsWithSubscriptions[0].id}`);
      } else if (
        productsWithSubscriptions?.length === 0
        && rentPlans?.data?.[0]
        && !history.location.pathname.includes('rents/')
      ) {
        history.replace(`${url}/rents/${rentPlans?.data?.[0].type}`);
      }
    }
  }, [
    url,
    productsWithSubscriptions,
    rentPlans?.data,
    accessRes?.data,
  ]);

  const handleMenu = React.useCallback((value?: boolean) => {
    setFocusOnMenu(value !== undefined ? value : true);
  }, [setFocusOnMenu]);

  // if (!smartTV.profileSection.subscriptions) {
  //   return <ErrorDialog access="cantPurchase" />;
  // }

  if (!accessToken) {
    return <ErrorDialog access="notAuthorized" />;
  }

  if (
    !rentPlansIsFetching
    && !productsIsFetching
    && rentPlans?.data.length === 0
    && products?.data.length === 0
  ) {
    return <ErrorDialog error={ <FormattedMessage id={ NO_PURCHASES } /> } />;
  }

  if (isLoading) {
    return <LightLoader />;
  }
  return (
    <div className={ styles.scene }>
      <Menu
        url={ url }
        products={ productsWithSubscriptions }
        rentPlans={ rentPlans?.data }
        focusOnMenu={ focusOnMenu }
        onMenu={ handleMenu }
      />
      <Switch>
        <Route path={ `${path}/payment/:invoiceID` }>
          <PaymentProceed />
        </Route>
        <Route path={ `${path}/rents/:planID/invoice/proceed_card_payment` }>
          <CardProceed/>
        </Route>
        <Route path={ `${path}/rents/:planID/invoice` }>
          <RentInvoce
            url={ url }
            rentPlans={ rentPlans?.data }
            focusOnMenu={ focusOnMenu }
            onMenu={ handleMenu }
          />
        </Route>
        <Route path={ `${path}/rents/${RentPlanType.EST}` }>
          <RentType
            url={ url }
            rentPlans={ rentPlans?.data }
            type={ RentPlanType.EST }
            focusOnMenu={ focusOnMenu }
            onMenu={ handleMenu }
          />
        </Route>
        <Route path={ `${path}/rents/${RentPlanType.TVOD}` }>
          <RentType
            url={ url }
            rentPlans={ rentPlans?.data }
            type={ RentPlanType.TVOD }
            focusOnMenu={ focusOnMenu }
            onMenu={ handleMenu }
          />
        </Route>
        <Route path={ `${path}/rents` }>
          {/* <RentsList
            url={ url }
            rentPlans={ rentPlans?.data }
            focusOnMenu={ focusOnMenu }
            onMenu={ handleMenu }
          /> */}
        </Route>
        <Route path={ `${path}/subscriptions/:planID/proceed_card_payment` }>
          <CardProceed/>
        </Route>
        <Route path={ `${path}/subscriptions/:planID/invoice` }>
          <ProductInvoce
            url={ url }
            products={ productsWithSubscriptions }
            focusOnMenu={ focusOnMenu }
            onMenu={ handleMenu }
          />
        </Route>
        <Route path={ `${path}/subscriptions/:productID` }>
          <ProductPlan
            url={ url }
            products={ productsWithSubscriptions }
            subscriptions={ subscriptions }
            resourceID={ itemId }
            focusOnMenu={ focusOnMenu }
            onMenu={ handleMenu }
          />
        </Route>
        <Route path={ `${path}/subscriptions` }>
          {/* <ProductsList
            url={ url }
            products={ productsWithSubscriptions }
            subscriptions={ subscriptions?.data }
            resourceID={ itemId }
            focusOnMenu={ focusOnMenu }
            onMenu={ handleMenu }
          /> */}
        </Route>
        <Route path={ path } exact>
          <ProductsList
            url={ url }
            products={ productsWithSubscriptions }
            subscriptions={ subscriptions }
            resourceID={ itemId }
            focusOnMenu={ focusOnMenu }
            onMenu={ handleMenu }
            isFetched={ isFetchedAfterMount }
          />
        </Route>
      </Switch>
    </div>
  )
};


export default React.memo(ProductsForRecource);
