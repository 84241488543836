import { gw_04 } from 'customization-project-name/colors'
import * as React from 'react';

type Props = {
  className?: string;
};

/* eslint-disable max-len */
const AndroidIcon: React.FC<Props> = ({ className = '' }) => (
  <svg className={ className } viewBox="0 0 39 70" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M15.1535 65.1175C14.494 65.1175 13.956 64.6975 13.956 64.19C13.956 63.6825 14.494 63.2625 15.1535 63.2625H23.6924C24.3519 63.2625 24.8899 63.6825 24.8899 64.19C24.8899 64.6975 24.3519 65.1175 23.6924 65.1175H15.1535ZM4.91385 3.535C4.35848 3.535 3.88988 3.9725 3.88988 4.515V59.85C3.88988 60.3925 4.34112 60.83 4.91385 60.83H33.932C34.5048 60.83 34.956 60.3925 34.956 59.85V4.5325C34.956 3.99 34.5048 3.5525 33.932 3.5525H4.91385V3.535ZM3.09154 1.6625C2.53616 1.6625 2.06757 2.1 2.06757 2.6425V67.34C2.06757 67.8825 2.51881 68.32 3.09154 68.32H35.7543C36.3271 68.32 36.7783 67.8825 36.7783 67.34V2.66C36.7783 2.1175 36.3271 1.68 35.7543 1.68H3.09154V1.6625ZM3.09154 70C1.56426 70 0.332031 68.81 0.332031 67.34V2.66C0.332031 1.19 1.56426 0 3.09154 0H35.7543C37.2816 0 38.5138 1.19 38.5138 2.66V67.34C38.5138 68.81 37.2816 70 35.7543 70H3.09154Z" 
      fill={ gw_04 }
    />
  </svg>
);


export default React.memo(AndroidIcon);