/* eslint-disable max-len */
import * as React from 'react';


const IcoSpace: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 58 32"
  >
    <path d="M0 0h58v32H0V0z" fill="none" />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      className="pathHighlighted"
      d="M16 17v7h26v-7h-2v5H18v-5h-2z"
  />
  </svg>
);


export default IcoSpace;
