import { get, omit } from 'lodash';

import {getAuthData, setRefreshedTokens} from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';


function updateAccessTokenInQuery(originalRequestParams, newAccessToken) {
  const newHeaders =
    newAccessToken && originalRequestParams?.settings?.headers?.['Access-Token']
      ? { ...originalRequestParams.settings.headers, 'Access-Token': newAccessToken }
      : omit(originalRequestParams?.settings?.headers, 'Access-Token');

  return {
    ...originalRequestParams,
    settings: {
      ...originalRequestParams.settings,
      headers: newHeaders,
    },
  };
}

const getAccessToken = (requestParams): string | null => {
 return get(requestParams, 'settings.headers.Access-Token') || null
}

async function tryToFetchWithUpdatedAccessToken (client, originalRequestParams, accessToken) {
  return await client.fetch(
    updateAccessTokenInQuery(originalRequestParams, accessToken)
  );
}

const createInvalidAccessTokenErrorHandler = (
  client: ApiClient,
  logout: () => void,
) => async function handleInvalidTokenError(error, originalRequestParams) {
  const { accessToken, refreshToken } = getAuthData();
  // token isn't looks like expired and does not need to be refreshed
  if (!refreshToken) { throw error; }

  const oldToken = getAccessToken(originalRequestParams);

  if (accessToken !== oldToken) {
    return await tryToFetchWithUpdatedAccessToken(client, originalRequestParams, accessToken);
  }

  if (client.fetchLock === null) {
    client.lockFetching();

    try {
      const url = { pathname: '/oauth/token' };
      const settings = {
        method: 'POST',
        body: { grant_type: 'refresh_token', refresh_token: refreshToken },
      };

      const requestParams = client.enhanceRequestParams({ url, settings });
      const {
        access_token: newAccessToken,
        refresh_token: newRefreshToken,
      } = await client.fetchIgnoringLock(requestParams);
      setRefreshedTokens(newAccessToken, newRefreshToken);
      if (client.unlockFetching) {
        client.unlockFetching();
      }

      const fixedRequestParams = updateAccessTokenInQuery(originalRequestParams, newAccessToken);
      const repeatedFetch = await client.fetch(fixedRequestParams);

      return repeatedFetch;
    } catch (ignore) {
      logout();

      if (client.unlockFetching) {
        client.unlockFetching();
      }
    }
  } else {
    return await client.fetchLock;
  }

  return await client.fetch(updateAccessTokenInQuery(originalRequestParams, accessToken));
};

export {
  createInvalidAccessTokenErrorHandler,
};
