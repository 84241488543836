import Language from '~typings/Language';


// From @formatjs/intl-displaynames/locale-data/
const LANGS: { [key in Language]: { [key in string]: string } } = {
  [Language.Ru]: {
    "aa": "Караоке (минусовка)",
    "ab": "Абхазский",
    "ace": "Ачехский",
    "ach": "Ачоли",
    "ada": "Адангме",
    "ady": "Адыгейский",
    "ae": "Авестийский",
    "af": "Африкаанс",
    "afh": "Африхили",
    "agq": "Агем",
    "ain": "Айнский",
    "ak": "Акан",
    "akk": "Аккадский",
    "ale": "Алеутский",
    "alt": "Южноалтайский",
    "am": "Амхарский",
    "an": "Арагонский",
    "ang": "Староанглийский",
    "anp": "Ангика",
    "ar": "Арабский",
    "ar-001": "Арабский литературный",
    "arc": "Арамейский",
    "arn": "Мапуче",
    "arp": "Арапахо",
    "ars": "Недждийский арабский",
    "arw": "Аравакский",
    "as": "Ассамский",
    "asa": "Асу",
    "ast": "Астурийский",
    "av": "Аварский",
    "awa": "Авадхи",
    "ay": "Аймара",
    "az": "Азербайджанский",
    "ba": "Башкирский",
    "bal": "Белуджский",
    "ban": "Балийский",
    "bas": "Баса",
    "bax": "Бамум",
    "bbj": "Гомала",
    "be": "Белорусский",
    "bej": "Беджа",
    "bem": "Бемба",
    "bez": "Бена",
    "bfd": "Бафут",
    "bg": "Болгарский",
    "bgn": "Западный белуджский",
    "bho": "Бходжпури",
    "bi": "Бислама",
    "bik": "Бикольский",
    "bin": "Бини",
    "bkm": "Ком",
    "bla": "Сиксика",
    "bm": "Бамбара",
    "bn": "Бенгальский",
    "bo": "Тибетский",
    "br": "Бретонский",
    "bra": "Брауи",
    "brx": "Бодо",
    "bs": "Боснийский",
    "bss": "Акоосе",
    "bua": "Бурятский",
    "bug": "Бугийский",
    "bum": "Булу",
    "byn": "Билин",
    "byv": "Медумба",
    "ca": "Каталанский",
    "cad": "Каддо",
    "car": "Кариб",
    "cay": "Кайюга",
    "cch": "Атсам",
    "ccp": "Чакма",
    "ce": "Чеченский",
    "ceb": "Себуано",
    "cgg": "Кига",
    "ch": "Чаморро",
    "chb": "Чибча",
    "chg": "Чагатайский",
    "chk": "Чукотский",
    "chm": "Марийский",
    "chn": "Чинук жаргон",
    "cho": "Чоктавский",
    "chp": "Чипевьян",
    "chr": "Чероки",
    "chy": "Шайенский",
    "ckb": "Сорани",
    "co": "Корсиканский",
    "cop": "Коптский",
    "cr": "Кри",
    "crh": "Крымско-татарский",
    "crs": "Сейшельский креольский",
    "cs": "Чешский",
    "csb": "Кашубский",
    "cu": "Церковнославянский",
    "cv": "Чувашский",
    "cy": "Валлийский",
    "da": "Датский",
    "dak": "Дакота",
    "dar": "Даргинский",
    "dav": "Таита",
    "de": "Немецкий",
    "de-AT": "Австрийский немецкий",
    "de-CH": "Литературный швейцарский немецкий",
    "del": "Делаварский",
    "den": "Слейви",
    "dgr": "Догриб",
    "din": "Динка",
    "dje": "Джерма",
    "doi": "Догри",
    "dsb": "Нижнелужицкий",
    "dua": "Дуала",
    "dum": "Средненидерландский",
    "dv": "Мальдивский",
    "dyo": "Диола-фоньи",
    "dyu": "Диула",
    "dz": "Дзонг-кэ",
    "dzg": "Даза",
    "ebu": "Эмбу",
    "ee": "Эве",
    "efi": "Эфик",
    "egy": "Древнеегипетский",
    "eka": "Экаджук",
    "el": "Греческий",
    "elx": "Эламский",
    "en": "Английский",
    "en-AU": "Австралийский английский",
    "en-CA": "Канадский английский",
    "en-GB": "Британский английский",
    "en-US": "Американский английский",
    "enm": "Среднеанглийский",
    "eo": "Эсперанто",
    "es": "Испанский",
    "es-419": "Латиноамериканский испанский",
    "es-ES": "Европейский испанский",
    "es-MX": "Мексиканский испанский",
    "et": "Эстонский",
    "eu": "Баскский",
    "ewo": "Эвондо",
    "fa": "Персидский",
    "fa-AF": "Дари",
    "fan": "Фанг",
    "fat": "Фанти",
    "ff": "Фулах",
    "fi": "Финский",
    "fil": "Филиппинский",
    "fj": "Фиджи",
    "fo": "Фарерский",
    "fon": "Фон",
    "fr": "Французский",
    "fr-CA": "Канадский французский",
    "fr-CH": "Швейцарский французский",
    "frc": "Каджунский французский",
    "frm": "Среднефранцузский",
    "fro": "Старофранцузский",
    "frr": "Северный фризский",
    "frs": "Восточный фризский",
    "fur": "Фриульский",
    "fy": "Западнофризский",
    "ga": "Ирландский",
    "gaa": "Га",
    "gag": "Гагаузский",
    "gan": "Гань",
    "gay": "Гайо",
    "gba": "Гбая",
    "gd": "Гэльский",
    "gez": "Геэз",
    "gil": "Гилбертский",
    "gl": "Галисийский",
    "gmh": "Средневерхненемецкий",
    "gn": "Гуарани",
    "goh": "Древневерхненемецкий",
    "gon": "Гонди",
    "gor": "Горонтало",
    "got": "Готский",
    "grb": "Гребо",
    "grc": "Древнегреческий",
    "gsw": "Швейцарский немецкий",
    "gu": "Гуджарати",
    "guz": "Гусии",
    "gv": "Мэнский",
    "gwi": "Гвичин",
    "ha": "Хауса",
    "hai": "Хайда",
    "hak": "Хакка",
    "haw": "Гавайский",
    "he": "Иврит",
    "hi": "Хинди",
    "hil": "Хилигайнон",
    "hit": "Хеттский",
    "hmn": "Хмонг",
    "ho": "Хиримоту",
    "hr": "Хорватский",
    "hsb": "Верхнелужицкий",
    "hsn": "Сян",
    "ht": "Гаитянский",
    "hu": "Венгерский",
    "hup": "Хупа",
    "hy": "Армянский",
    "hz": "Гереро",
    "ia": "Интерлингва",
    "iba": "Ибанский",
    "ibb": "Ибибио",
    "id": "Индонезийский",
    "ie": "Интерлингве",
    "ig": "Игбо",
    "ii": "Носу",
    "ik": "Инупиак",
    "ilo": "Илоко",
    "inh": "Ингушский",
    "io": "Идо",
    "is": "Исландский",
    "it": "Итальянский",
    "iu": "Инуктитут",
    "ja": "Японский",
    "jbo": "Ложбан",
    "jgo": "Нгомба",
    "jmc": "Мачаме",
    "jpr": "Еврейско-персидский",
    "jrb": "Еврейско-арабский",
    "jv": "Яванский",
    "ka": "Грузинский",
    "kaa": "Каракалпакский",
    "kab": "Кабильский",
    "kac": "Качинский",
    "kaj": "Каджи",
    "kam": "Камба",
    "kaw": "Кави",
    "kbd": "Кабардинский",
    "kbl": "Канембу",
    "kcg": "Тьяп",
    "kde": "Маконде",
    "kea": "Кабувердьяну",
    "kfo": "Коро",
    "kg": "Конго",
    "kgp": "Kgp",
    "kha": "Кхаси",
    "kho": "Хотанский",
    "khq": "Койра чиини",
    "ki": "Кикуйю",
    "kj": "Кунама",
    "kk": "Казахский",
    "kkj": "Како",
    "kl": "Гренландский",
    "kln": "Календжин",
    "km": "Кхмерский",
    "kmb": "Кимбунду",
    "kn": "Каннада",
    "ko": "Корейский",
    "koi": "Коми-пермяцкий",
    "kok": "Конкани",
    "kos": "Косраенский",
    "kpe": "Кпелле",
    "kr": "Канури",
    "krc": "Карачаево-балкарский",
    "krl": "Карельский",
    "kru": "Курух",
    "ks": "Кашмири",
    "ksb": "Шамбала",
    "ksf": "Бафия",
    "ksh": "Кёльнский",
    "ku": "Курдский",
    "kum": "Кумыкский",
    "kut": "Кутенаи",
    "kv": "Коми",
    "kw": "Корнский",
    "ky": "Киргизский",
    "la": "Латинский",
    "lad": "Ладино",
    "lag": "Ланго",
    "lah": "Лахнда",
    "lam": "Ламба",
    "lb": "Люксембургский",
    "lez": "Лезгинский",
    "lg": "Ганда",
    "li": "Лимбургский",
    "lij": "Lij",
    "lkt": "Лакота",
    "ln": "Лингала",
    "lo": "Лаосский",
    "lol": "Монго",
    "lou": "Луизианский креольский",
    "loz": "Лози",
    "lrc": "Севернолурский",
    "lt": "Литовский",
    "lu": "Луба-катанга",
    "lua": "Луба-лулуа",
    "lui": "Луисеньо",
    "lun": "Лунда",
    "luo": "Луо",
    "lus": "Мизо",
    "luy": "Лухья",
    "lv": "Латышский",
    "mad": "Мадурский",
    "maf": "Мафа",
    "mag": "Магахи",
    "mai": "Майтхили",
    "mak": "Макассарский",
    "man": "Мандинго",
    "mas": "Масаи",
    "mde": "Маба",
    "mdf": "Мокшанский",
    "mdr": "Мандарский",
    "men": "Менде",
    "mer": "Меру",
    "mfe": "Маврикийский креольский",
    "mg": "Малагасийский",
    "mga": "Среднеирландский",
    "mgh": "Макуа-меетто",
    "mgo": "Мета",
    "mh": "Маршалльский",
    "mi": "Маори",
    "mic": "Микмак",
    "min": "Минангкабау",
    "mk": "Македонский",
    "ml": "Малаялам",
    "mn": "Монгольский",
    "mnc": "Маньчжурский",
    "mni": "Манипурский",
    "moh": "Мохаук",
    "mos": "Моси",
    "mr": "Маратхи",
    "ms": "Малайский",
    "mt": "Мальтийский",
    "mua": "Мунданг",
    "mul": "Языки разных семей",
    "mus": "Крик",
    "mwl": "Мирандский",
    "mwr": "Марвари",
    "my": "Бирманский",
    "mye": "Миене",
    "myv": "Эрзянский",
    "mzn": "Мазандеранский",
    "na": "Науру",
    "nan": "Миньнань",
    "nap": "Неаполитанский",
    "naq": "Нама",
    "nb": "Норвежский букмол",
    "nd": "Северный ндебеле",
    "nds": "Нижненемецкий",
    "nds-NL": "Нижнесаксонский",
    "ne": "Непальский",
    "new": "Неварский",
    "ng": "Ндонга",
    "nia": "Ниас",
    "niu": "Ниуэ",
    "nl": "Нидерландский",
    "nl-BE": "Фламандский",
    "nmg": "Квасио",
    "nn": "Нюнорск",
    "nnh": "Нгиембунд",
    "no": "Норвежский",
    "nog": "Ногайский",
    "non": "Старонорвежский",
    "nqo": "Нко",
    "nr": "Южный ндебеле",
    "nso": "Северный сото",
    "nus": "Нуэр",
    "nv": "Навахо",
    "nwc": "Классический невари",
    "ny": "Ньянджа",
    "nym": "Ньямвези",
    "nyn": "Ньянколе",
    "nyo": "Ньоро",
    "nzi": "Нзима",
    "oc": "Окситанский",
    "oj": "Оджибва",
    "om": "Оромо",
    "or": "Ория",
    "os": "Осетинский",
    "osa": "Оседжи",
    "ota": "Старотурецкий",
    "pa": "Панджаби",
    "pag": "Пангасинан",
    "pal": "Пехлевийский",
    "pam": "Пампанга",
    "pap": "Папьяменто",
    "pau": "Палау",
    "pcm": "Нигерийско-креольский",
    "peo": "Староперсидский",
    "phn": "Финикийский",
    "pi": "Пали",
    "pl": "Польский",
    "pon": "Понапе",
    "prg": "Прусский",
    "pro": "Старопровансальский",
    "ps": "Пушту",
    "pt": "Португальский",
    "pt-BR": "Бразильский португальский",
    "pt-PT": "Европейский португальский",
    "qu": "Кечуа",
    "quc": "Киче",
    "raj": "Раджастхани",
    "rap": "Рапануйский",
    "rar": "Раротонга",
    "rm": "Романшский",
    "rn": "Рунди",
    "ro": "Румынский",
    "ro-MD": "Молдавский",
    "rof": "Ромбо",
    "rom": "Цыганский",
    "ru": "Русский",
    "rup": "Арумынский",
    "rw": "Киньяруанда",
    "rwk": "Руанда",
    "sa": "Санскрит",
    "sad": "Сандаве",
    "sah": "Саха",
    "sam": "Самаритянский арамейский",
    "saq": "Самбуру",
    "sas": "Сасакский",
    "sat": "Сантали",
    "sba": "Нгамбайский",
    "sbp": "Сангу",
    "sc": "Сардинский",
    "scn": "Сицилийский",
    "sco": "Шотландский",
    "sd": "Синдхи",
    "sdh": "Южнокурдский",
    "se": "Северносаамский",
    "see": "Сенека",
    "seh": "Сена",
    "sel": "Селькупский",
    "ses": "Койраборо сенни",
    "sg": "Санго",
    "sga": "Староирландский",
    "sh": "Сербскохорватский",
    "shi": "Ташельхит",
    "shn": "Шанский",
    "shu": "Чадский арабский",
    "si": "Сингальский",
    "sid": "Сидама",
    "sk": "Словацкий",
    "sl": "Словенский",
    "sm": "Самоанский",
    "sma": "Южносаамский",
    "smj": "Луле-саамский",
    "smn": "Инари-саамский",
    "sms": "Колтта-саамский",
    "sn": "Шона",
    "snk": "Сонинке",
    "so": "Сомали",
    "sog": "Согдийский",
    "sq": "Албанский",
    "sr": "Сербский",
    "srn": "Сранан-тонго",
    "srr": "Серер",
    "ss": "Свази",
    "ssy": "Сахо",
    "st": "Южный сото",
    "su": "Сунданский",
    "suk": "Сукума",
    "sus": "Сусу",
    "sux": "Шумерский",
    "sv": "Шведский",
    "sw": "Суахили",
    "sw-CD": "Конголезский суахили",
    "swb": "Коморский",
    "syc": "Классический сирийский",
    "syr": "Сирийский",
    "ta": "Тамильский",
    "te": "Телугу",
    "tem": "Темне",
    "teo": "Тесо",
    "ter": "Терено",
    "tet": "Тетум",
    "tg": "Таджикский",
    "th": "Тайский",
    "ti": "Тигринья",
    "tig": "Тигре",
    "tiv": "Тиви",
    "tk": "Туркменский",
    "tkl": "Токелайский",
    "tl": "Тагалог",
    "tlh": "Клингонский",
    "tli": "Тлингит",
    "tmh": "Тамашек",
    "tn": "Тсвана",
    "to": "Тонганский",
    "tog": "Тонга",
    "tpi": "Ток-писин",
    "tr": "Турецкий",
    "tru": "Туройо",
    "trv": "Седекский",
    "ts": "Тсонга",
    "tsi": "Цимшиан",
    "tt": "Татарский",
    "tum": "Тумбука",
    "tvl": "Тувалу",
    "tw": "Тви",
    "twq": "Тасавак",
    "ty": "Таитянский",
    "tyv": "Тувинский",
    "tzm": "Среднеатласский тамазигхтский",
    "udm": "Удмуртский",
    "ug": "Уйгурский",
    "uga": "Угаритский",
    "uk": "Украинский",
    "umb": "Умбунду",
    "und": "Неизвестный язык",
    "ur": "Урду",
    "uz": "Узбекский",
    "vai": "Ваи",
    "ve": "Венда",
    "vi": "Вьетнамский",
    "vo": "Волапюк",
    "vot": "Водский",
    "vun": "Вунджо",
    "wa": "Валлонский",
    "wae": "Валлисский",
    "wal": "Воламо",
    "war": "Варай",
    "was": "Вашо",
    "wbp": "Вальбири",
    "wo": "Волоф",
    "wuu": "Ву",
    "xal": "Калмыцкий",
    "xh": "Коса",
    "xog": "Сога",
    "yao": "Яо",
    "yap": "Яп",
    "yav": "Янгбен",
    "ybb": "Йемба",
    "yi": "Идиш",
    "yo": "Йоруба",
    "yue": "Кантонский",
    "za": "Чжуань",
    "zap": "Сапотекский",
    "zbl": "Блиссимволика",
    "zen": "Зенагский",
    "zgh": "Тамазигхтский",
    "zh": "Китайский",
    "zh-Hans": "Китайский, упрощенное письмо",
    "zh-Hant": "Китайский, традиционное письмо",
    "zu": "Зулу",
    "zun": "Зуньи",
    "zxx": "Нет языкового материала",
    "zza": "Заза"
  },
  [Language.En]: {
    "aa": "Karaoke",
    "ab": "Abkhazian",
    "ace": "Achinese",
    "ach": "Acoli",
    "ada": "Adangme",
    "ady": "Adyghe",
    "ae": "Avestan",
    "aeb": "Tunisian Arabic",
    "af": "Afrikaans",
    "afh": "Afrihili",
    "agq": "Aghem",
    "ain": "Ainu",
    "ak": "Akan",
    "akk": "Akkadian",
    "akz": "Alabama",
    "ale": "Aleut",
    "aln": "Gheg Albanian",
    "alt": "Southern Altai",
    "am": "Amharic",
    "an": "Aragonese",
    "ang": "Old English",
    "anp": "Angika",
    "ar": "Arabic",
    "ar-001": "Modern Standard Arabic",
    "arc": "Aramaic",
    "arn": "Mapuche",
    "aro": "Araona",
    "arp": "Arapaho",
    "arq": "Algerian Arabic",
    "ars": "Najdi Arabic",
    "arw": "Arawak",
    "ary": "Moroccan Arabic",
    "arz": "Egyptian Arabic",
    "as": "Assamese",
    "asa": "Asu",
    "ase": "American Sign Language",
    "ast": "Asturian",
    "av": "Avaric",
    "avk": "Kotava",
    "awa": "Awadhi",
    "ay": "Aymara",
    "az": "Azerbaijani",
    "ba": "Bashkir",
    "bal": "Baluchi",
    "ban": "Balinese",
    "bar": "Bavarian",
    "bas": "Basaa",
    "bax": "Bamun",
    "bbc": "Batak Toba",
    "bbj": "Ghomala",
    "be": "Belarusian",
    "bej": "Beja",
    "bem": "Bemba",
    "bew": "Betawi",
    "bez": "Bena",
    "bfd": "Bafut",
    "bfq": "Badaga",
    "bg": "Bulgarian",
    "bgn": "Western Balochi",
    "bho": "Bhojpuri",
    "bi": "Bislama",
    "bik": "Bikol",
    "bin": "Bini",
    "bjn": "Banjar",
    "bkm": "Kom",
    "bla": "Siksika",
    "bm": "Bambara",
    "bn": "Bangla",
    "bo": "Tibetan",
    "bpy": "Bishnupriya",
    "bqi": "Bakhtiari",
    "br": "Breton",
    "bra": "Braj",
    "brh": "Brahui",
    "brx": "Bodo",
    "bs": "Bosnian",
    "bss": "Akoose",
    "bua": "Buriat",
    "bug": "Buginese",
    "bum": "Bulu",
    "byn": "Blin",
    "byv": "Medumba",
    "ca": "Catalan",
    "cad": "Caddo",
    "car": "Carib",
    "cay": "Cayuga",
    "cch": "Atsam",
    "ccp": "Chakma",
    "ce": "Chechen",
    "ceb": "Cebuano",
    "cgg": "Chiga",
    "ch": "Chamorro",
    "chb": "Chibcha",
    "chg": "Chagatai",
    "chk": "Chuukese",
    "chm": "Mari",
    "chn": "Chinook Jargon",
    "cho": "Choctaw",
    "chp": "Chipewyan",
    "chr": "Cherokee",
    "chy": "Cheyenne",
    "cic": "Chickasaw",
    "ckb": "Central Kurdish",
    "co": "Corsican",
    "cop": "Coptic",
    "cps": "Capiznon",
    "cr": "Cree",
    "crh": "Crimean Turkish",
    "crs": "Seselwa Creole French",
    "cs": "Czech",
    "csb": "Kashubian",
    "cu": "Church Slavic",
    "cv": "Chuvash",
    "cy": "Welsh",
    "da": "Danish",
    "dak": "Dakota",
    "dar": "Dargwa",
    "dav": "Taita",
    "de": "German",
    "de-AT": "Austrian German",
    "de-CH": "Swiss High German",
    "del": "Delaware",
    "den": "Slave",
    "dgr": "Dogrib",
    "din": "Dinka",
    "dje": "Zarma",
    "doi": "Dogri",
    "dsb": "Lower Sorbian",
    "dtp": "Central Dusun",
    "dua": "Duala",
    "dum": "Middle Dutch",
    "dv": "Divehi",
    "dyo": "Jola-Fonyi",
    "dyu": "Dyula",
    "dz": "Dzongkha",
    "dzg": "Dazaga",
    "ebu": "Embu",
    "ee": "Ewe",
    "efi": "Efik",
    "egl": "Emilian",
    "egy": "Ancient Egyptian",
    "eka": "Ekajuk",
    "el": "Greek",
    "elx": "Elamite",
    "en": "English",
    "en-AU": "Australian English",
    "en-CA": "Canadian English",
    "en-GB": "British English",
    "en-US": "American English",
    "enm": "Middle English",
    "eo": "Esperanto",
    "es": "Spanish",
    "es-419": "Latin American Spanish",
    "es-ES": "European Spanish",
    "es-MX": "Mexican Spanish",
    "esu": "Central Yupik",
    "et": "Estonian",
    "eu": "Basque",
    "ewo": "Ewondo",
    "ext": "Extremaduran",
    "fa": "Persian",
    "fa-AF": "Dari",
    "fan": "Fang",
    "fat": "Fanti",
    "ff": "Fulah",
    "fi": "Finnish",
    "fil": "Filipino",
    "fit": "Tornedalen Finnish",
    "fj": "Fijian",
    "fo": "Faroese",
    "fon": "Fon",
    "fr": "French",
    "fr-CA": "Canadian French",
    "fr-CH": "Swiss French",
    "frc": "Cajun French",
    "frm": "Middle French",
    "fro": "Old French",
    "frp": "Arpitan",
    "frr": "Northern Frisian",
    "frs": "Eastern Frisian",
    "fur": "Friulian",
    "fy": "Western Frisian",
    "ga": "Irish",
    "gaa": "Ga",
    "gag": "Gagauz",
    "gan": "Gan Chinese",
    "gay": "Gayo",
    "gba": "Gbaya",
    "gbz": "Zoroastrian Dari",
    "gd": "Scottish Gaelic",
    "gez": "Geez",
    "gil": "Gilbertese",
    "gl": "Galician",
    "glk": "Gilaki",
    "gmh": "Middle High German",
    "gn": "Guarani",
    "goh": "Old High German",
    "gom": "Goan Konkani",
    "gon": "Gondi",
    "gor": "Gorontalo",
    "got": "Gothic",
    "grb": "Grebo",
    "grc": "Ancient Greek",
    "gsw": "Swiss German",
    "gu": "Gujarati",
    "guc": "Wayuu",
    "gur": "Frafra",
    "guz": "Gusii",
    "gv": "Manx",
    "gwi": "Gwichʼin",
    "ha": "Hausa",
    "hai": "Haida",
    "hak": "Hakka Chinese",
    "haw": "Hawaiian",
    "he": "Hebrew",
    "hi": "Hindi",
    "hif": "Fiji Hindi",
    "hil": "Hiligaynon",
    "hit": "Hittite",
    "hmn": "Hmong",
    "ho": "Hiri Motu",
    "hr": "Croatian",
    "hsb": "Upper Sorbian",
    "hsn": "Xiang Chinese",
    "ht": "Haitian Creole",
    "hu": "Hungarian",
    "hup": "Hupa",
    "hy": "Armenian",
    "hz": "Herero",
    "ia": "Interlingua",
    "iba": "Iban",
    "ibb": "Ibibio",
    "id": "Indonesian",
    "ie": "Interlingue",
    "ig": "Igbo",
    "ii": "Sichuan Yi",
    "ik": "Inupiaq",
    "ilo": "Iloko",
    "inh": "Ingush",
    "io": "Ido",
    "is": "Icelandic",
    "it": "Italian",
    "iu": "Inuktitut",
    "izh": "Ingrian",
    "ja": "Japanese",
    "jam": "Jamaican Creole English",
    "jbo": "Lojban",
    "jgo": "Ngomba",
    "jmc": "Machame",
    "jpr": "Judeo-Persian",
    "jrb": "Judeo-Arabic",
    "jut": "Jutish",
    "jv": "Javanese",
    "ka": "Georgian",
    "kaa": "Kara-Kalpak",
    "kab": "Kabyle",
    "kac": "Kachin",
    "kaj": "Jju",
    "kam": "Kamba",
    "kaw": "Kawi",
    "kbd": "Kabardian",
    "kbl": "Kanembu",
    "kcg": "Tyap",
    "kde": "Makonde",
    "kea": "Kabuverdianu",
    "ken": "Kenyang",
    "kfo": "Koro",
    "kg": "Kongo",
    "kgp": "Kaingang",
    "kha": "Khasi",
    "kho": "Khotanese",
    "khq": "Koyra Chiini",
    "khw": "Khowar",
    "ki": "Kikuyu",
    "kiu": "Kirmanjki",
    "kj": "Kuanyama",
    "kk": "Kazakh",
    "kkj": "Kako",
    "kl": "Kalaallisut",
    "kln": "Kalenjin",
    "km": "Khmer",
    "kmb": "Kimbundu",
    "kn": "Kannada",
    "ko": "Korean",
    "koi": "Komi-Permyak",
    "kok": "Konkani",
    "kos": "Kosraean",
    "kpe": "Kpelle",
    "kr": "Kanuri",
    "krc": "Karachay-Balkar",
    "kri": "Krio",
    "krj": "Kinaray-a",
    "krl": "Karelian",
    "kru": "Kurukh",
    "ks": "Kashmiri",
    "ksb": "Shambala",
    "ksf": "Bafia",
    "ksh": "Colognian",
    "ku": "Kurdish",
    "kum": "Kumyk",
    "kut": "Kutenai",
    "kv": "Komi",
    "kw": "Cornish",
    "ky": "Kyrgyz",
    "la": "Latin",
    "lad": "Ladino",
    "lag": "Langi",
    "lah": "Lahnda",
    "lam": "Lamba",
    "lb": "Luxembourgish",
    "lez": "Lezghian",
    "lfn": "Lingua Franca Nova",
    "lg": "Ganda",
    "li": "Limburgish",
    "lij": "Ligurian",
    "liv": "Livonian",
    "lkt": "Lakota",
    "lmo": "Lombard",
    "ln": "Lingala",
    "lo": "Lao",
    "lol": "Mongo",
    "lou": "Louisiana Creole",
    "loz": "Lozi",
    "lrc": "Northern Luri",
    "lt": "Lithuanian",
    "ltg": "Latgalian",
    "lu": "Luba-Katanga",
    "lua": "Luba-Lulua",
    "lui": "Luiseno",
    "lun": "Lunda",
    "luo": "Luo",
    "lus": "Mizo",
    "luy": "Luyia",
    "lv": "Latvian",
    "lzh": "Literary Chinese",
    "lzz": "Laz",
    "mad": "Madurese",
    "maf": "Mafa",
    "mag": "Magahi",
    "mai": "Maithili",
    "mak": "Makasar",
    "man": "Mandingo",
    "mas": "Masai",
    "mde": "Maba",
    "mdf": "Moksha",
    "mdr": "Mandar",
    "men": "Mende",
    "mer": "Meru",
    "mfe": "Morisyen",
    "mg": "Malagasy",
    "mga": "Middle Irish",
    "mgh": "Makhuwa-Meetto",
    "mgo": "Metaʼ",
    "mh": "Marshallese",
    "mi": "Maori",
    "mic": "Mi'kmaq",
    "min": "Minangkabau",
    "mk": "Macedonian",
    "ml": "Malayalam",
    "mn": "Mongolian",
    "mnc": "Manchu",
    "mni": "Manipuri",
    "moh": "Mohawk",
    "mos": "Mossi",
    "mr": "Marathi",
    "mrj": "Western Mari",
    "ms": "Malay",
    "mt": "Maltese",
    "mua": "Mundang",
    "mul": "Multiple languages",
    "mus": "Muscogee",
    "mwl": "Mirandese",
    "mwr": "Marwari",
    "mwv": "Mentawai",
    "my": "Burmese",
    "mye": "Myene",
    "myv": "Erzya",
    "mzn": "Mazanderani",
    "na": "Nauru",
    "nan": "Min Nan Chinese",
    "nap": "Neapolitan",
    "naq": "Nama",
    "nb": "Norwegian Bokmål",
    "nd": "North Ndebele",
    "nds": "Low German",
    "nds-NL": "Low Saxon",
    "ne": "Nepali",
    "new": "Newari",
    "ng": "Ndonga",
    "nia": "Nias",
    "niu": "Niuean",
    "njo": "Ao Naga",
    "nl": "Dutch",
    "nl-BE": "Flemish",
    "nmg": "Kwasio",
    "nn": "Norwegian Nynorsk",
    "nnh": "Ngiemboon",
    "no": "Norwegian",
    "nog": "Nogai",
    "non": "Old Norse",
    "nov": "Novial",
    "nqo": "N’Ko",
    "nr": "South Ndebele",
    "nso": "Northern Sotho",
    "nus": "Nuer",
    "nv": "Navajo",
    "nwc": "Classical Newari",
    "ny": "Nyanja",
    "nym": "Nyamwezi",
    "nyn": "Nyankole",
    "nyo": "Nyoro",
    "nzi": "Nzima",
    "oc": "Occitan",
    "oj": "Ojibwa",
    "om": "Oromo",
    "or": "Odia",
    "os": "Ossetic",
    "osa": "Osage",
    "ota": "Ottoman Turkish",
    "pa": "Punjabi",
    "pag": "Pangasinan",
    "pal": "Pahlavi",
    "pam": "Pampanga",
    "pap": "Papiamento",
    "pau": "Palauan",
    "pcd": "Picard",
    "pcm": "Nigerian Pidgin",
    "pdc": "Pennsylvania German",
    "pdt": "Plautdietsch",
    "peo": "Old Persian",
    "pfl": "Palatine German",
    "phn": "Phoenician",
    "pi": "Pali",
    "pl": "Polish",
    "pms": "Piedmontese",
    "pnt": "Pontic",
    "pon": "Pohnpeian",
    "prg": "Prussian",
    "pro": "Old Provençal",
    "ps": "Pashto",
    "pt": "Portuguese",
    "pt-BR": "Brazilian Portuguese",
    "pt-PT": "European Portuguese",
    "qu": "Quechua",
    "quc": "Kʼicheʼ",
    "qug": "Chimborazo Highland Quichua",
    "raj": "Rajasthani",
    "rap": "Rapanui",
    "rar": "Rarotongan",
    "rgn": "Romagnol",
    "rif": "Riffian",
    "rm": "Romansh",
    "rn": "Rundi",
    "ro": "Romanian",
    "ro-MD": "Moldavian",
    "rof": "Rombo",
    "rom": "Romany",
    "rtm": "Rotuman",
    "ru": "Russian",
    "rue": "Rusyn",
    "rug": "Roviana",
    "rup": "Aromanian",
    "rw": "Kinyarwanda",
    "rwk": "Rwa",
    "sa": "Sanskrit",
    "sad": "Sandawe",
    "sah": "Sakha",
    "sam": "Samaritan Aramaic",
    "saq": "Samburu",
    "sas": "Sasak",
    "sat": "Santali",
    "saz": "Saurashtra",
    "sba": "Ngambay",
    "sbp": "Sangu",
    "sc": "Sardinian",
    "scn": "Sicilian",
    "sco": "Scots",
    "sd": "Sindhi",
    "sdc": "Sassarese Sardinian",
    "sdh": "Southern Kurdish",
    "se": "Northern Sami",
    "see": "Seneca",
    "seh": "Sena",
    "sei": "Seri",
    "sel": "Selkup",
    "ses": "Koyraboro Senni",
    "sg": "Sango",
    "sga": "Old Irish",
    "sgs": "Samogitian",
    "sh": "Serbo-Croatian",
    "shi": "Tachelhit",
    "shn": "Shan",
    "shu": "Chadian Arabic",
    "si": "Sinhala",
    "sid": "Sidamo",
    "sk": "Slovak",
    "sl": "Slovenian",
    "sli": "Lower Silesian",
    "sly": "Selayar",
    "sm": "Samoan",
    "sma": "Southern Sami",
    "smj": "Lule Sami",
    "smn": "Inari Sami",
    "sms": "Skolt Sami",
    "sn": "Shona",
    "snk": "Soninke",
    "so": "Somali",
    "sog": "Sogdien",
    "sq": "Albanian",
    "sr": "Serbian",
    "sr-ME": "Montenegrin",
    "srn": "Sranan Tongo",
    "srr": "Serer",
    "ss": "Swati",
    "ssy": "Saho",
    "st": "Southern Sotho",
    "stq": "Saterland Frisian",
    "su": "Sundanese",
    "suk": "Sukuma",
    "sus": "Susu",
    "sux": "Sumerian",
    "sv": "Swedish",
    "sw": "Swahili",
    "sw-CD": "Congo Swahili",
    "swb": "Comorian",
    "syc": "Classical Syriac",
    "syr": "Syriac",
    "szl": "Silesian",
    "ta": "Tamil",
    "tcy": "Tulu",
    "te": "Telugu",
    "tem": "Timne",
    "teo": "Teso",
    "ter": "Tereno",
    "tet": "Tetum",
    "tg": "Tajik",
    "th": "Thai",
    "ti": "Tigrinya",
    "tig": "Tigre",
    "tiv": "Tiv",
    "tk": "Turkmen",
    "tkl": "Tokelau",
    "tkr": "Tsakhur",
    "tl": "Tagalog",
    "tlh": "Klingon",
    "tli": "Tlingit",
    "tly": "Talysh",
    "tmh": "Tamashek",
    "tn": "Tswana",
    "to": "Tongan",
    "tog": "Nyasa Tonga",
    "tpi": "Tok Pisin",
    "tr": "Turkish",
    "tru": "Turoyo",
    "trv": "Taroko",
    "ts": "Tsonga",
    "tsd": "Tsakonian",
    "tsi": "Tsimshian",
    "tt": "Tatar",
    "ttt": "Muslim Tat",
    "tum": "Tumbuka",
    "tvl": "Tuvalu",
    "tw": "Twi",
    "twq": "Tasawaq",
    "ty": "Tahitian",
    "tyv": "Tuvinian",
    "tzm": "Central Atlas Tamazight",
    "udm": "Udmurt",
    "ug": "Uyghur",
    "uga": "Ugaritic",
    "uk": "Ukrainian",
    "umb": "Umbundu",
    "und": "Unknown language",
    "ur": "Urdu",
    "uz": "Uzbek",
    "vai": "Vai",
    "ve": "Venda",
    "vec": "Venetian",
    "vep": "Veps",
    "vi": "Vietnamese",
    "vls": "West Flemish",
    "vmf": "Main-Franconian",
    "vo": "Volapük",
    "vot": "Votic",
    "vro": "Võro",
    "vun": "Vunjo",
    "wa": "Walloon",
    "wae": "Walser",
    "wal": "Wolaytta",
    "war": "Waray",
    "was": "Washo",
    "wbp": "Warlpiri",
    "wo": "Wolof",
    "wuu": "Wu Chinese",
    "xal": "Kalmyk",
    "xh": "Xhosa",
    "xmf": "Mingrelian",
    "xog": "Soga",
    "yao": "Yao",
    "yap": "Yapese",
    "yav": "Yangben",
    "ybb": "Yemba",
    "yi": "Yiddish",
    "yo": "Yoruba",
    "yrl": "Nheengatu",
    "yue": "Cantonese",
    "za": "Zhuang",
    "zap": "Zapotec",
    "zbl": "Blissymbols",
    "zea": "Zeelandic",
    "zen": "Zenaga",
    "zgh": "Standard Moroccan Tamazight",
    "zh": "Chinese",
    "zh-Hans": "Simplified Chinese",
    "zh-Hant": "Traditional Chinese",
    "zu": "Zulu",
    "zun": "Zuni",
    "zxx": "No linguistic content",
    "zza": "Zaza"
  },
  [Language.Fa]: {
    "aa": "آفاری",
    "ab": "آبخازی",
    "ace": "آچئی",
    "ach": "آچولیایی",
    "ada": "آدانگمه‌ای",
    "ady": "آدیجیایی",
    "ae": "اوستایی",
    "aeb": "عربی تونسی",
    "af": "آفریکانس",
    "afh": "آفریهیلی",
    "agq": "آگیم",
    "ain": "آینویی",
    "ak": "آکان",
    "akk": "اکدی",
    "akz": "آلابامایی",
    "ale": "آلئوتی",
    "alt": "آلتایی جنوبی",
    "am": "امهری",
    "an": "آراگونی",
    "ang": "انگلیسی باستان",
    "anp": "آنگیکا",
    "ar": "عربی",
    "ar-001": "عربی رسمی",
    "arc": "آرامی",
    "arn": "ماپوچه‌ای",
    "arp": "آراپاهویی",
    "arq": "عربی الجزایری",
    "arw": "آراواکی",
    "ary": "عربی مراکشی",
    "arz": "عربی مصری",
    "as": "آسامی",
    "asa": "آسو",
    "ast": "آستوری",
    "av": "آواری",
    "awa": "اودهی",
    "ay": "آیمارایی",
    "az": "ترکی آذربایجانی",
    "az-Arab": "ترکی آذری جنوبی",
    "ba": "باشقیری",
    "bal": "بلوچی",
    "ban": "بالیایی",
    "bar": "باواریایی",
    "bas": "باسایی",
    "bax": "بمونی",
    "be": "بلاروسی",
    "bej": "بجایی",
    "bem": "بمبایی",
    "bez": "بنایی",
    "bg": "بلغاری",
    "bgn": "بلوچی غربی",
    "bho": "بوجپوری",
    "bi": "بیسلاما",
    "bik": "بیکولی",
    "bin": "بینی",
    "bla": "سیکسیکا",
    "bm": "بامبارایی",
    "bn": "بنگالی",
    "bo": "تبتی",
    "bqi": "لری بختیاری",
    "br": "برتون",
    "bra": "براج",
    "brh": "براهویی",
    "brx": "بودویی",
    "bs": "بوسنیایی",
    "bua": "بوریاتی",
    "bug": "بوگیایی",
    "byn": "بلین",
    "ca": "کاتالان",
    "cad": "کادویی",
    "car": "کاریبی",
    "ccp": "چاکما",
    "ce": "چچنی",
    "ceb": "سبویی",
    "cgg": "چیگا",
    "ch": "چامورویی",
    "chb": "چیبچا",
    "chg": "جغتایی",
    "chk": "چوکی",
    "chm": "ماریایی",
    "cho": "چوکتویی",
    "chp": "چیپه‌ویه‌ای",
    "chr": "چروکی",
    "chy": "شایانی",
    "ckb": "کردی مرکزی",
    "co": "کورسی",
    "cop": "قبطی",
    "cr": "کریایی",
    "crh": "ترکی کریمه",
    "crs": "سیشل آمیختهٔ فرانسوی",
    "cs": "چکی",
    "csb": "کاشوبی",
    "cu": "اسلاوی کلیسایی",
    "cv": "چوواشی",
    "cy": "ولزی",
    "da": "دانمارکی",
    "dak": "داکوتایی",
    "dar": "دارقینی",
    "dav": "تایتا",
    "de": "آلمانی",
    "de-AT": "آلمانی اتریش",
    "de-CH": "آلمانی معیار سوئیس",
    "del": "دلاواری",
    "dgr": "دوگریب",
    "din": "دینکایی",
    "dje": "زرما",
    "doi": "دوگری",
    "dsb": "صُربی سفلی",
    "dua": "دوآلایی",
    "dum": "هلندی میانه",
    "dv": "دیوهی",
    "dyo": "دیولا فونی",
    "dyu": "دایولایی",
    "dz": "دزونگخا",
    "dzg": "دازاگایی",
    "ebu": "امبو",
    "ee": "اوه‌ای",
    "efi": "افیکی",
    "egy": "مصری کهن",
    "eka": "اکاجوک",
    "el": "یونانی",
    "elx": "عیلامی",
    "en": "انگلیسی",
    "en-AU": "انگلیسی استرالیا",
    "en-CA": "انگلیسی کانادا",
    "en-GB": "انگلیسی بریتانیا",
    "en-US": "انگلیسی امریکا",
    "enm": "انگلیسی میانه",
    "eo": "اسپرانتو",
    "es": "اسپانیایی",
    "es-419": "اسپانیایی امریکای لاتین",
    "es-ES": "اسپانیایی اروپا",
    "es-MX": "اسپانیایی مکزیک",
    "et": "استونیایی",
    "eu": "باسکی",
    "ewo": "اواندو",
    "fa": "فارسی",
    "fa-AF": "دری",
    "fan": "فانگی",
    "fat": "فانتیایی",
    "ff": "فولانی",
    "fi": "فنلاندی",
    "fil": "فیلیپینی",
    "fj": "فیجیایی",
    "fo": "فارویی",
    "fon": "فونی",
    "fr": "فرانسوی",
    "fr-CA": "فرانسوی کانادا",
    "fr-CH": "فرانسوی سوئیس",
    "frc": "فرانسوی کادین",
    "frm": "فرانسوی میانه",
    "fro": "فرانسوی باستان",
    "frr": "فریزی شمالی",
    "frs": "فریزی شرقی",
    "fur": "فریولیایی",
    "fy": "فریسی غربی",
    "ga": "ایرلندی",
    "gaa": "گایی",
    "gag": "گاگائوزیایی",
    "gay": "گایویی",
    "gba": "گبایایی",
    "gbz": "دری زرتشتی",
    "gd": "گیلی اسکاتلندی",
    "gez": "گی‌ئزی",
    "gil": "گیلبرتی",
    "gl": "گالیسیایی",
    "glk": "گیلکی",
    "gmh": "آلمانی معیار میانه",
    "gn": "گوارانی",
    "goh": "آلمانی علیای باستان",
    "gon": "گوندی",
    "gor": "گورونتالو",
    "got": "گوتی",
    "grb": "گریبویی",
    "grc": "یونانی کهن",
    "gsw": "آلمانی سوئیسی",
    "gu": "گجراتی",
    "guz": "گوسی",
    "gv": "مانی",
    "gwi": "گویچ این",
    "ha": "هوسایی",
    "hai": "هایدایی",
    "haw": "هاوایی",
    "he": "عبری",
    "hi": "هندی",
    "hif": "هندی فیجیایی",
    "hil": "هیلی‌گاینونی",
    "hit": "هیتی",
    "hmn": "همونگ",
    "ho": "موتویی هیری",
    "hr": "کروات",
    "hsb": "صُربی علیا",
    "ht": "هائیتیایی",
    "hu": "مجاری",
    "hup": "هوپا",
    "hy": "ارمنی",
    "hz": "هریرویی",
    "ia": "اینترلینگوا",
    "iba": "ایبانی",
    "ibb": "ایبیبیو",
    "id": "اندونزیایی",
    "ie": "اکسیدنتال",
    "ig": "ایگبویی",
    "ii": "یی سیچوان",
    "ik": "اینوپیک",
    "ilo": "ایلوکویی",
    "inh": "اینگوشی",
    "io": "ایدو",
    "is": "ایسلندی",
    "it": "ایتالیایی",
    "iu": "اینوکتیتوت",
    "ja": "ژاپنی",
    "jbo": "لوجبان",
    "jgo": "نگومبا",
    "jmc": "ماچامه‌ای",
    "jpr": "فارسی یهودی",
    "jrb": "عربی یهودی",
    "jv": "جاوه‌ای",
    "ka": "گرجی",
    "kaa": "قره‌قالپاقی",
    "kab": "قبایلی",
    "kac": "کاچینی",
    "kaj": "جو",
    "kam": "کامبایی",
    "kaw": "کاویایی",
    "kbd": "کاباردینی",
    "kcg": "تیاپی",
    "kde": "ماکونده",
    "kea": "کابووردیانو",
    "kfo": "کورو",
    "kg": "کنگویی",
    "kgp": "kgp",
    "kha": "خاسیایی",
    "kho": "ختنی",
    "khq": "کوجراچینی",
    "khw": "کهوار",
    "ki": "کیکویویی",
    "kiu": "کرمانجی",
    "kj": "کوانیاما",
    "kk": "قزاقی",
    "kkj": "کاکایی",
    "kl": "گرینلندی",
    "kln": "کالنجین",
    "km": "خمری",
    "kmb": "کیمبوندویی",
    "kn": "کانارا",
    "ko": "کره‌ای",
    "koi": "کومی پرمیاک",
    "kok": "کنکانی",
    "kpe": "کپله‌ای",
    "kr": "کانوریایی",
    "krc": "قره‌چایی‐بالکاری",
    "krl": "کاریلیانی",
    "kru": "کوروخی",
    "ks": "کشمیری",
    "ksb": "شامبالا",
    "ksf": "بافیایی",
    "ksh": "کولش",
    "ku": "کردی",
    "kum": "کومیکی",
    "kut": "کوتنی",
    "kv": "کومیایی",
    "kw": "کورنی",
    "ky": "قرقیزی",
    "la": "لاتین",
    "lad": "لادینو",
    "lag": "لانگی",
    "lah": "لاهندا",
    "lam": "لامبا",
    "lb": "لوگزامبورگی",
    "lez": "لزگی",
    "lg": "گاندایی",
    "li": "لیمبورگی",
    "lij": "lij",
    "lkt": "لاکوتا",
    "ln": "لینگالا",
    "lo": "لائوسی",
    "lol": "مونگویی",
    "lou": "زبان آمیختهٔ مادری لوئیزیانا",
    "loz": "لوزیایی",
    "lrc": "لری شمالی",
    "lt": "لیتوانیایی",
    "lu": "لوبایی‐کاتانگا",
    "lua": "لوبایی‐لولوا",
    "lui": "لویسنو",
    "lun": "لوندایی",
    "luo": "لوئویی",
    "lus": "لوشه‌ای",
    "luy": "لویا",
    "lv": "لتونیایی",
    "lzh": "چینی ادبی",
    "mad": "مادورایی",
    "mag": "ماگاهیایی",
    "mai": "مایدیلی",
    "mak": "ماکاسار",
    "man": "ماندینگویی",
    "mas": "ماسایی",
    "mdf": "مکشایی",
    "mdr": "ماندار",
    "men": "منده‌ای",
    "mer": "مرویی",
    "mfe": "موریسین",
    "mg": "مالاگاسی",
    "mga": "ایرلندی میانه",
    "mgh": "ماکوا متو",
    "mgo": "متایی",
    "mh": "مارشالی",
    "mi": "مائوری",
    "mic": "میکماکی",
    "min": "مینانگ‌کابویی",
    "mk": "مقدونی",
    "ml": "مالایالامی",
    "mn": "مغولی",
    "mnc": "مانچویی",
    "mni": "مانیپوری",
    "moh": "موهاکی",
    "mos": "ماسیایی",
    "mr": "مراتی",
    "ms": "مالایی",
    "mt": "مالتی",
    "mua": "ماندانگی",
    "mul": "چندین زبان",
    "mus": "کریکی",
    "mwl": "میراندی",
    "mwr": "مارواری",
    "my": "برمه‌ای",
    "myv": "ارزیایی",
    "mzn": "مازندرانی",
    "na": "نائورویی",
    "nap": "ناپلی",
    "naq": "نامایی",
    "nb": "نروژی بوک‌مُل",
    "nd": "انده‌بله‌ای شمالی",
    "nds": "آلمانی سفلی",
    "nds-NL": "ساکسونی سفلی",
    "ne": "نپالی",
    "new": "نواریایی",
    "ng": "اندونگایی",
    "nia": "نیاسی",
    "niu": "نیویی",
    "nl": "هلندی",
    "nl-BE": "فلمنگی",
    "nmg": "کوازیو",
    "nn": "نروژی نی‌نُشک",
    "nnh": "نیامبون",
    "no": "نروژی",
    "nog": "نغایی",
    "non": "نرس باستان",
    "nqo": "نکو",
    "nr": "انده‌بله‌ای جنوبی",
    "nso": "سوتویی شمالی",
    "nus": "نویر",
    "nv": "ناواهویی",
    "nwc": "نواریایی کلاسیک",
    "ny": "نیانجایی",
    "nym": "نیام‌وزیایی",
    "nyn": "نیانکوله‌ای",
    "nyo": "نیورویی",
    "nzi": "نزیمایی",
    "oc": "اکسیتان",
    "oj": "اوجیبوایی",
    "om": "اورومویی",
    "or": "اوریه‌ای",
    "os": "آسی",
    "osa": "اوسیجی",
    "ota": "ترکی عثمانی",
    "pa": "پنجابی",
    "pag": "پانگاسینانی",
    "pal": "پهلوی",
    "pam": "پامپانگایی",
    "pap": "پاپیامنتو",
    "pau": "پالائویی",
    "pcm": "نیم‌زبان نیجریه‌ای",
    "pdc": "آلمانی پنسیلوانیایی",
    "peo": "فارسی باستان",
    "phn": "فنیقی",
    "pi": "پالی",
    "pl": "لهستانی",
    "pon": "پانپیی",
    "prg": "پروسی",
    "pro": "پرووانسی باستان",
    "ps": "پشتو",
    "pt": "پرتغالی",
    "pt-BR": "پرتغالی برزیل",
    "pt-PT": "پرتغالی اروپا",
    "qu": "کچوایی",
    "quc": "کیچه‌",
    "raj": "راجستانی",
    "rap": "راپانویی",
    "rar": "راروتونگایی",
    "rm": "رومانش",
    "rn": "روندیایی",
    "ro": "رومانیایی",
    "ro-MD": "مولداویایی",
    "rof": "رومبویی",
    "rom": "رومانویی",
    "ru": "روسی",
    "rup": "آرومانی",
    "rw": "کینیارواندایی",
    "rwk": "روایی",
    "sa": "سانسکریت",
    "sad": "سانداوه‌ای",
    "sah": "یاقوتی",
    "sam": "آرامی سامری",
    "saq": "سامبورو",
    "sas": "ساساکی",
    "sat": "سانتالی",
    "sba": "انگامبایی",
    "sbp": "سانگویی",
    "sc": "ساردینیایی",
    "scn": "سیسیلی",
    "sco": "اسکاتلندی",
    "sd": "سندی",
    "sdh": "کردی جنوبی",
    "se": "سامی شمالی",
    "seh": "سنا",
    "sel": "سلکوپی",
    "ses": "کویرابورا سنی",
    "sg": "سانگو",
    "sga": "ایرلندی باستان",
    "sh": "صرب و کرواتی",
    "shi": "تاچل‌هیت",
    "shn": "شانی",
    "shu": "عربی چادی",
    "si": "سینهالی",
    "sid": "سیدامویی",
    "sk": "اسلواکی",
    "sl": "اسلوونیایی",
    "sli": "سیلزیایی سفلی",
    "sm": "ساموآیی",
    "sma": "سامی جنوبی",
    "smj": "لوله سامی",
    "smn": "ایناری سامی",
    "sms": "اسکولت سامی",
    "sn": "شونایی",
    "snk": "سونینکه‌ای",
    "so": "سومالیایی",
    "sog": "سغدی",
    "sq": "آلبانیایی",
    "sr": "صربی",
    "srn": "تاکی‌تاکی",
    "srr": "سریری",
    "ss": "سوازیایی",
    "ssy": "ساهو",
    "st": "سوتوی جنوبی",
    "su": "سوندایی",
    "suk": "سوکومایی",
    "sus": "سوسویی",
    "sux": "سومری",
    "sv": "سوئدی",
    "sw": "سواحیلی",
    "sw-CD": "سواحیلی کنگو",
    "swb": "کوموری",
    "syc": "سریانی کلاسیک",
    "syr": "سریانی",
    "szl": "سیلزیایی",
    "ta": "تامیلی",
    "te": "تلوگویی",
    "tem": "تمنه‌ای",
    "teo": "تسویی",
    "ter": "ترنو",
    "tet": "تتومی",
    "tg": "تاجیکی",
    "th": "تایلندی",
    "ti": "تیگرینیایی",
    "tig": "تیگره‌ای",
    "tiv": "تیوی",
    "tk": "ترکمنی",
    "tl": "تاگالوگی",
    "tlh": "کلینگون",
    "tli": "تلین‌گیتی",
    "tmh": "تاماشقی",
    "tn": "تسوانایی",
    "to": "تونگایی",
    "tog": "تونگایی نیاسا",
    "tpi": "توک‌پیسینی",
    "tr": "ترکی استانبولی",
    "trv": "تاروکویی",
    "ts": "تسونگایی",
    "tsi": "تسیم‌شیانی",
    "tt": "تاتاری",
    "tum": "تومبوکایی",
    "tvl": "تووالویی",
    "tw": "توی‌یایی",
    "twq": "تسواکی",
    "ty": "تاهیتیایی",
    "tyv": "تووایی",
    "tzm": "آمازیغی اطلس مرکزی",
    "udm": "اودمورتی",
    "ug": "اویغوری",
    "uga": "اوگاریتی",
    "uk": "اوکراینی",
    "umb": "امبوندویی",
    "und": "زبان نامشخص",
    "ur": "اردو",
    "uz": "ازبکی",
    "vai": "ویایی",
    "ve": "وندایی",
    "vi": "ویتنامی",
    "vo": "ولاپوک",
    "vot": "وتی",
    "vun": "ونجو",
    "wa": "والونی",
    "wae": "والسر",
    "wal": "والامو",
    "war": "وارایی",
    "was": "واشویی",
    "wbp": "وارلپیری",
    "wo": "ولوفی",
    "xal": "قلموقی",
    "xh": "خوسایی",
    "xog": "سوگایی",
    "yao": "یائویی",
    "yap": "یاپی",
    "yav": "یانگبنی",
    "ybb": "یمبایی",
    "yi": "یدی",
    "yo": "یوروبایی",
    "yue": "کانتونی",
    "za": "چوانگی",
    "zap": "زاپوتکی",
    "zen": "زناگا",
    "zgh": "آمازیغی معیار مراکش",
    "zh": "چینی",
    "zh-Hans": "چینی ساده‌شده",
    "zh-Hant": "چینی سنتی",
    "zu": "زولویی",
    "zun": "زونیایی",
    "zxx": "بدون محتوای زبانی",
    "zza": "زازایی"
  },
  [Language.Kk]: {
    "aa": "афар тілі",
    "ab": "абхаз тілі",
    "ace": "ачех тілі",
    "ada": "адангме тілі",
    "ady": "адыгей тілі",
    "af": "африкаанс тілі",
    "agq": "агхем тілі",
    "ain": "айну тілі",
    "ak": "акан тілі",
    "ale": "алеут тілі",
    "alt": "оңтүстік алтай тілі",
    "am": "амхар тілі",
    "an": "арагон тілі",
    "anp": "ангика тілі",
    "ar": "араб тілі",
    "ar-001": "қазіргі стандартты араб тілі",
    "arn": "мапуче тілі",
    "arp": "арапахо тілі",
    "as": "ассам тілі",
    "asa": "асу тілі",
    "ast": "астурия тілі",
    "av": "авар тілі",
    "awa": "авадхи тілі",
    "ay": "аймара тілі",
    "az": "әзірбайжан тілі",
    "ba": "башқұрт тілі",
    "ban": "бали тілі",
    "bas": "баса тілі",
    "be": "беларусь тілі",
    "bem": "бемба тілі",
    "bez": "бена тілі",
    "bg": "болгар тілі",
    "bgn": "батыс балучи тілі",
    "bho": "бходжпури тілі",
    "bi": "бислама тілі",
    "bin": "бини тілі",
    "bla": "сиксика тілі",
    "bm": "бамбара тілі",
    "bn": "бенгал тілі",
    "bo": "тибет тілі",
    "br": "бретон тілі",
    "brx": "бодо тілі",
    "bs": "босния тілі",
    "bug": "бугис тілі",
    "byn": "блин тілі",
    "ca": "каталан тілі",
    "ccp": "чакма тілі",
    "ce": "шешен тілі",
    "ceb": "себуано тілі",
    "cgg": "кига тілі",
    "ch": "чаморро тілі",
    "chk": "чуук тілі",
    "chm": "мари тілі",
    "cho": "чокто тілі",
    "chr": "чероки тілі",
    "chy": "шайен тілі",
    "ckb": "сорани тілі",
    "co": "корсика тілі",
    "crs": "сейшельдік креол тілі",
    "cs": "чех тілі",
    "cu": "шіркеулік славян тілі",
    "cv": "чуваш тілі",
    "cy": "валлий тілі",
    "da": "дат тілі",
    "dak": "дакота тілі",
    "dar": "даргин тілі",
    "dav": "таита тілі",
    "de": "неміс тілі",
    "de-AT": "австриялық неміс тілі",
    "de-CH": "швейцариялық әдеби неміс тілі",
    "dgr": "догриб тілі",
    "dje": "зарма тілі",
    "doi": "doi",
    "dsb": "төменгі лужица тілі",
    "dua": "дуала тілі",
    "dv": "дивехи тілі",
    "dyo": "диола тілі",
    "dz": "дзонг-кэ тілі",
    "dzg": "дазага тілі",
    "ebu": "эмбу тілі",
    "ee": "эве тілі",
    "efi": "эфик тілі",
    "eka": "экаджук тілі",
    "el": "грек тілі",
    "en": "ағылшын тілі",
    "en-AU": "австралиялық ағылшын тілі",
    "en-CA": "канадалық ағылшын тілі",
    "en-GB": "британиялық ағылшын тілі",
    "en-US": "америкалық ағылшын тілі",
    "eo": "эсперанто тілі",
    "es": "испан тілі",
    "es-419": "латынамерикалық испан тілі",
    "es-ES": "еуропалық испан тілі",
    "es-MX": "мексикалық испан тілі",
    "et": "эстон тілі",
    "eu": "баск тілі",
    "ewo": "эвондо тілі",
    "fa": "парсы тілі",
    "fa-AF": "дари тілі",
    "ff": "фула тілі",
    "fi": "фин тілі",
    "fil": "филиппин тілі",
    "fj": "фиджи тілі",
    "fo": "фарер тілі",
    "fon": "фон тілі",
    "fr": "француз тілі",
    "fr-CA": "канадалық француз тілі",
    "fr-CH": "швейцариялық француз тілі",
    "frc": "frc",
    "fur": "фриуль тілі",
    "fy": "батыс фриз тілі",
    "ga": "ирланд тілі",
    "gaa": "га тілі",
    "gag": "гагауз тілі",
    "gd": "шотландиялық гэль тілі",
    "gez": "геэз тілі",
    "gil": "гильберт тілі",
    "gl": "галисия тілі",
    "gn": "гуарани тілі",
    "gor": "горонтало тілі",
    "gsw": "швейцариялық неміс тілі",
    "gu": "гуджарати тілі",
    "guz": "гусии тілі",
    "gv": "мэн тілі",
    "gwi": "гвичин тілі",
    "ha": "хауса тілі",
    "haw": "гавайи тілі",
    "he": "иврит тілі",
    "hi": "хинди тілі",
    "hil": "хилигайнон тілі",
    "hmn": "хмонг тілі",
    "hr": "хорват тілі",
    "hsb": "жоғарғы лужица тілі",
    "ht": "гаити тілі",
    "hu": "венгр тілі",
    "hup": "хупа тілі",
    "hy": "армян тілі",
    "hz": "гереро тілі",
    "ia": "интерлингва тілі",
    "iba": "ибан тілі",
    "ibb": "ибибио тілі",
    "id": "индонезия тілі",
    "ie": "интерлингве тілі",
    "ig": "игбо тілі",
    "ii": "сычуан и тілі",
    "ilo": "илоко тілі",
    "inh": "ингуш тілі",
    "io": "идо тілі",
    "is": "исланд тілі",
    "it": "итальян тілі",
    "iu": "инуктитут тілі",
    "ja": "жапон тілі",
    "jbo": "ложбан тілі",
    "jgo": "нгомба тілі",
    "jmc": "мачаме тілі",
    "jv": "ява тілі",
    "ka": "грузин тілі",
    "kab": "кабил тілі",
    "kac": "качин тілі",
    "kaj": "каджи тілі",
    "kam": "камба тілі",
    "kbd": "кабардин тілі",
    "kcg": "тьяп тілі",
    "kde": "маконде тілі",
    "kea": "кабувердьяну тілі",
    "kfo": "коро тілі",
    "kgp": "kgp",
    "kha": "кхаси тілі",
    "khq": "койра чини тілі",
    "ki": "кикуйю тілі",
    "kj": "кваньяма тілі",
    "kk": "қазақ тілі",
    "kkj": "како тілі",
    "kl": "калаалисут тілі",
    "kln": "каленжин тілі",
    "km": "кхмер тілі",
    "kmb": "кимбунду тілі",
    "kn": "каннада тілі",
    "ko": "корей тілі",
    "koi": "коми-пермяк тілі",
    "kok": "конкани тілі",
    "kpe": "кпелле тілі",
    "kr": "канури тілі",
    "krc": "қарашай-балқар тілі",
    "krl": "карель тілі",
    "kru": "курух тілі",
    "ks": "кашмир тілі",
    "ksb": "шамбала тілі",
    "ksf": "бафиа тілі",
    "ksh": "кёльн тілі",
    "ku": "күрд тілі",
    "kum": "құмық тілі",
    "kv": "коми тілі",
    "kw": "корн тілі",
    "ky": "қырғыз тілі",
    "la": "латын тілі",
    "lad": "ладино тілі",
    "lag": "ланги тілі",
    "lb": "люксембург тілі",
    "lez": "лезгин тілі",
    "lg": "ганда тілі",
    "li": "лимбург тілі",
    "lij": "lij",
    "lkt": "лакота тілі",
    "ln": "лингала тілі",
    "lo": "лаос тілі",
    "lou": "lou",
    "loz": "лози тілі",
    "lrc": "солтүстік люри тілі",
    "lt": "литва тілі",
    "lu": "луба-катанга тілі",
    "lua": "луба-лулуа тілі",
    "lun": "лунда тілі",
    "luo": "луо тілі",
    "lus": "мизо тілі",
    "luy": "лухиа тілі",
    "lv": "латыш тілі",
    "mad": "мадур тілі",
    "mag": "магахи тілі",
    "mai": "майтхили тілі",
    "mak": "макасар тілі",
    "mas": "масай тілі",
    "mdf": "мокша тілі",
    "men": "менде тілі",
    "mer": "меру тілі",
    "mfe": "морисиен тілі",
    "mg": "малагаси тілі",
    "mgh": "макуа-меетто тілі",
    "mgo": "мета тілі",
    "mh": "маршалл тілі",
    "mi": "маори тілі",
    "mic": "микмак тілі",
    "min": "минангкабау тілі",
    "mk": "македон тілі",
    "ml": "малаялам тілі",
    "mn": "моңғол тілі",
    "mni": "манипури тілі",
    "moh": "могавк тілі",
    "mos": "мосси тілі",
    "mr": "маратхи тілі",
    "ms": "малай тілі",
    "mt": "мальта тілі",
    "mua": "мунданг тілі",
    "mul": "бірнеше тіл",
    "mus": "крик тілі",
    "mwl": "миранд тілі",
    "my": "бирма тілі",
    "myv": "эрзян тілі",
    "mzn": "мазандеран тілі",
    "na": "науру тілі",
    "nap": "неаполитан тілі",
    "naq": "нама тілі",
    "nb": "норвегиялық букмол тілі",
    "nd": "солтүстік ндебеле тілі",
    "nds": "төменгі неміс тілі",
    "nds-NL": "төменгі саксон тілі",
    "ne": "непал тілі",
    "new": "невар тілі",
    "ng": "ндонга тілі",
    "nia": "ниас тілі",
    "niu": "ниуэ тілі",
    "nl": "нидерланд тілі",
    "nl-BE": "фламанд тілі",
    "nmg": "квасио тілі",
    "nn": "норвегиялық нюнорск тілі",
    "nnh": "нгиембун тілі",
    "no": "норвег тілі",
    "nog": "ноғай тілі",
    "nqo": "нко тілі",
    "nr": "оңтүстік ндебеле тілі",
    "nso": "солтүстік сото тілі",
    "nus": "нуэр тілі",
    "nv": "навахо тілі",
    "ny": "ньянджа тілі",
    "nyn": "нианколе тілі",
    "oc": "окситан тілі",
    "om": "оромо тілі",
    "or": "ория тілі",
    "os": "осетин тілі",
    "pa": "пенджаб тілі",
    "pag": "пангасинан тілі",
    "pam": "пампанга тілі",
    "pap": "папьяменто тілі",
    "pau": "палау тілі",
    "pcm": "нигериялық пиджин тілі",
    "pl": "поляк тілі",
    "prg": "пруссия тілі",
    "ps": "пушту тілі",
    "pt": "португал тілі",
    "pt-BR": "бразилиялық португал тілі",
    "pt-PT": "еуропалық португал тілі",
    "qu": "кечуа тілі",
    "quc": "киче тілі",
    "rap": "рапануй тілі",
    "rar": "раротонган тілі",
    "rm": "романш тілі",
    "rn": "рунди тілі",
    "ro": "румын тілі",
    "ro-MD": "молдован тілі",
    "rof": "ромбо тілі",
    "ru": "орыс тілі",
    "rup": "арумын тілі",
    "rw": "киньяруанда тілі",
    "rwk": "руа тілі",
    "sa": "санскрит тілі",
    "sad": "сандаве тілі",
    "sah": "саха тілі",
    "saq": "самбуру тілі",
    "sat": "сантали тілі",
    "sba": "нгамбай тілі",
    "sbp": "сангу тілі",
    "sc": "сардин тілі",
    "scn": "сицилия тілі",
    "sco": "шотланд тілі",
    "sd": "синдхи тілі",
    "sdh": "оңтүстік күрд тілі",
    "se": "солтүстік саам тілі",
    "seh": "сена тілі",
    "ses": "койраборо сенни тілі",
    "sg": "санго тілі",
    "sh": "серб-хорват тілі",
    "shi": "ташелхит тілі",
    "shn": "шан тілі",
    "si": "сингал тілі",
    "sk": "словак тілі",
    "sl": "словен тілі",
    "sm": "самоа тілі",
    "sma": "оңтүстік саам тілі",
    "smj": "луле саам тілі",
    "smn": "инари саам тілі",
    "sms": "колтта саам тілі",
    "sn": "шона тілі",
    "snk": "сонинке тілі",
    "so": "сомали тілі",
    "sq": "албан тілі",
    "sr": "серб тілі",
    "srn": "сранан тонго тілі",
    "ss": "свати тілі",
    "ssy": "сахо тілі",
    "st": "сесото тілі",
    "su": "сундан тілі",
    "suk": "сукума тілі",
    "sv": "швед тілі",
    "sw": "суахили тілі",
    "sw-CD": "конго суахили тілі",
    "swb": "комор тілі",
    "syr": "сирия тілі",
    "ta": "тамил тілі",
    "te": "телугу тілі",
    "tem": "темне тілі",
    "teo": "тесо тілі",
    "tet": "тетум тілі",
    "tg": "тәжік тілі",
    "th": "тай тілі",
    "ti": "тигринья тілі",
    "tig": "тигре тілі",
    "tk": "түрікмен тілі",
    "tlh": "клингон тілі",
    "tn": "тсвана тілі",
    "to": "тонган тілі",
    "tpi": "ток-писин тілі",
    "tr": "түрік тілі",
    "trv": "тароко тілі",
    "ts": "тсонга тілі",
    "tt": "татар тілі",
    "tum": "тумбука тілі",
    "tvl": "тувалу тілі",
    "tw": "тви тілі",
    "twq": "тасавак тілі",
    "ty": "таити тілі",
    "tyv": "тувин тілі",
    "tzm": "орталық атлас тамазигхт тілі",
    "udm": "удмурт тілі",
    "ug": "ұйғыр тілі",
    "uk": "украин тілі",
    "umb": "умбунду тілі",
    "und": "белгісіз тіл",
    "ur": "урду тілі",
    "uz": "өзбек тілі",
    "vai": "вай тілі",
    "ve": "венда тілі",
    "vi": "вьетнам тілі",
    "vo": "волапюк тілі",
    "vun": "вунджо тілі",
    "wa": "валлон тілі",
    "wae": "вальзер тілі",
    "wal": "волайта тілі",
    "war": "варай тілі",
    "wbp": "вальбири тілі",
    "wo": "волоф тілі",
    "xal": "қалмақ тілі",
    "xh": "кхоса тілі",
    "xog": "сога тілі",
    "yav": "янгбен тілі",
    "ybb": "йемба тілі",
    "yi": "идиш тілі",
    "yo": "йоруба тілі",
    "yue": "кантон тілі",
    "zgh": "марокколық стандартты тамазигхт тілі",
    "zh": "қытай тілі",
    "zh-Hans": "жеңілдетілген қытай тілі",
    "zh-Hant": "дәстүрлі қытай тілі",
    "zu": "зулу тілі",
    "zun": "зуни тілі",
    "zxx": "тілдік мазмұны жоқ",
    "zza": "заза тілі"
  },
  [Language.Uz]: {
    "aa": "afar",
    "ab": "abxaz",
    "ace": "achin",
    "ada": "adangme",
    "ady": "adigey",
    "af": "afrikaans",
    "agq": "agem",
    "ain": "aynu",
    "ak": "akan",
    "ale": "aleut",
    "alt": "janubiy oltoy",
    "am": "amxar",
    "an": "aragon",
    "anp": "angika",
    "ar": "arab",
    "ar-001": "standart arab",
    "arn": "mapuche",
    "arp": "arapaxo",
    "as": "assam",
    "asa": "asu",
    "ast": "asturiy",
    "av": "avar",
    "awa": "avadxi",
    "ay": "aymara",
    "az": "ozarbayjon",
    "ba": "boshqird",
    "ban": "bali",
    "bas": "basa",
    "be": "belarus",
    "bem": "bemba",
    "bez": "bena",
    "bg": "bolgar",
    "bgn": "g‘arbiy baluj",
    "bho": "bxojpuri",
    "bi": "bislama",
    "bin": "bini",
    "bla": "siksika",
    "bm": "bambara",
    "bn": "bengal",
    "bo": "tibet",
    "br": "breton",
    "brx": "bodo",
    "bs": "bosniy",
    "bug": "bugi",
    "byn": "blin",
    "ca": "katalan",
    "ccp": "chakma",
    "ce": "chechen",
    "ceb": "sebuan",
    "cgg": "chiga",
    "ch": "chamorro",
    "chk": "chukot",
    "chm": "mari",
    "cho": "choktav",
    "chr": "cheroki",
    "chy": "cheyenn",
    "ckb": "sorani-kurd",
    "co": "korsikan",
    "crs": "kreol (Seyshel)",
    "cs": "chex",
    "cu": "slavyan (cherkov)",
    "cv": "chuvash",
    "cy": "valliy",
    "da": "dan",
    "dak": "dakota",
    "dar": "dargva",
    "dav": "taita",
    "de": "nemischa",
    "de-AT": "nemis (Avstriya)",
    "de-CH": "yuqori nemis (Shveytsariya)",
    "dgr": "dogrib",
    "dje": "zarma",
    "doi": "doi",
    "dsb": "quyi sorb",
    "dua": "duala",
    "dv": "divexi",
    "dyo": "diola-fogni",
    "dz": "dzongka",
    "dzg": "dazag",
    "ebu": "embu",
    "ee": "eve",
    "efi": "efik",
    "eka": "ekajuk",
    "el": "grek",
    "en": "inglizcha",
    "en-AU": "ingliz (Avstraliya)",
    "en-CA": "ingliz (Kanada)",
    "en-GB": "ingliz (Britaniya)",
    "en-US": "ingliz (Amerika)",
    "eo": "esperanto",
    "es": "ispancha",
    "es-419": "ispan (Lotin Amerikasi)",
    "es-ES": "ispan (Yevropa)",
    "es-MX": "ispan (Meksika)",
    "et": "estoncha",
    "eu": "bask",
    "ewo": "evondo",
    "fa": "fors",
    "fa-AF": "dari",
    "ff": "fula",
    "fi": "fincha",
    "fil": "filipincha",
    "fj": "fiji",
    "fo": "farercha",
    "fon": "fon",
    "fr": "fransuzcha",
    "fr-CA": "fransuz (Kanada)",
    "fr-CH": "fransuz (Shveytsariya)",
    "frc": "frc",
    "fur": "friul",
    "fy": "g‘arbiy friz",
    "ga": "irland",
    "gaa": "ga",
    "gag": "gagauz",
    "gan": "gan",
    "gd": "shotland-gel",
    "gez": "geez",
    "gil": "gilbert",
    "gl": "galisiy",
    "gn": "guarani",
    "gor": "gorontalo",
    "gsw": "nemis (Shveytsariya)",
    "gu": "gujarot",
    "guz": "gusii",
    "gv": "men",
    "gwi": "gvichin",
    "ha": "xausa",
    "haw": "gavaycha",
    "he": "ivrit",
    "hi": "hind",
    "hil": "hiligaynon",
    "hmn": "xmong",
    "hr": "xorvat",
    "hsb": "yuqori sorb",
    "ht": "gaityan",
    "hu": "venger",
    "hup": "xupa",
    "hy": "arman",
    "hz": "gerero",
    "ia": "interlingva",
    "iba": "iban",
    "ibb": "ibibio",
    "id": "indonez",
    "ig": "igbo",
    "ii": "sichuan",
    "ilo": "iloko",
    "inh": "ingush",
    "io": "ido",
    "is": "island",
    "it": "italyan",
    "iu": "inuktitut",
    "ja": "yapon",
    "jbo": "lojban",
    "jgo": "ngomba",
    "jmc": "machame",
    "jv": "yavan",
    "ka": "gruzincha",
    "kab": "kabil",
    "kac": "kachin",
    "kaj": "kaji",
    "kam": "kamba",
    "kbd": "kabardin",
    "kcg": "tyap",
    "kde": "makonde",
    "kea": "kabuverdianu",
    "kfo": "koro",
    "kgp": "kgp",
    "kha": "kxasi",
    "khq": "koyra-chiini",
    "ki": "kikuyu",
    "kj": "kvanyama",
    "kk": "qozoqcha",
    "kkj": "kako",
    "kl": "grenland",
    "kln": "kalenjin",
    "km": "xmer",
    "kmb": "kimbundu",
    "kn": "kannada",
    "ko": "koreyscha",
    "koi": "komi-permyak",
    "kok": "konkan",
    "kpe": "kpelle",
    "kr": "kanuri",
    "krc": "qorachoy-bolqor",
    "krl": "karel",
    "kru": "kurux",
    "ks": "kashmircha",
    "ksb": "shambala",
    "ksf": "bafiya",
    "ksh": "kyoln",
    "ku": "kurdcha",
    "kum": "qo‘miq",
    "kv": "komi",
    "kw": "korn",
    "ky": "qirgʻizcha",
    "la": "lotincha",
    "lad": "ladino",
    "lag": "langi",
    "lb": "lyuksemburgcha",
    "lez": "lezgin",
    "lg": "ganda",
    "li": "limburg",
    "lij": "lij",
    "lkt": "lakota",
    "ln": "lingala",
    "lo": "laos",
    "lou": "lou",
    "loz": "lozi",
    "lrc": "shimoliy luri",
    "lt": "litva",
    "lu": "luba-katanga",
    "lua": "luba-lulua",
    "lun": "lunda",
    "luo": "luo",
    "lus": "lushay",
    "luy": "luhya",
    "lv": "latishcha",
    "mad": "madur",
    "mag": "magahi",
    "mai": "maythili",
    "mak": "makasar",
    "mas": "masay",
    "mdf": "moksha",
    "men": "mende",
    "mer": "meru",
    "mfe": "morisyen",
    "mg": "malagasiy",
    "mgh": "maxuva-mitto",
    "mgo": "meta",
    "mh": "marshall",
    "mi": "maori",
    "mic": "mikmak",
    "min": "minangkabau",
    "mk": "makedon",
    "ml": "malayalam",
    "mn": "mongol",
    "mni": "manipur",
    "moh": "mohauk",
    "mos": "mossi",
    "mr": "maratxi",
    "ms": "malay",
    "mt": "maltiy",
    "mua": "mundang",
    "mul": "bir nechta til",
    "mus": "krik",
    "mwl": "miranda",
    "my": "birman",
    "myv": "erzya",
    "mzn": "mozandaron",
    "na": "nauru",
    "nap": "neapolitan",
    "naq": "nama",
    "nb": "norveg-bokmal",
    "nd": "shimoliy ndebele",
    "nds": "quyi nemis",
    "nds-NL": "quyi sakson",
    "ne": "nepal",
    "new": "nevar",
    "ng": "ndonga",
    "nia": "nias",
    "niu": "niue",
    "nl": "niderland",
    "nl-BE": "flamand",
    "nmg": "kvasio",
    "nn": "norveg-nyunorsk",
    "nnh": "ngiyembun",
    "no": "no",
    "nog": "no‘g‘ay",
    "nqo": "nko",
    "nr": "janubiy ndebel",
    "nso": "shimoliy soto",
    "nus": "nuer",
    "nv": "navaxo",
    "ny": "cheva",
    "nyn": "nyankole",
    "oc": "oksitan",
    "om": "oromo",
    "or": "oriya",
    "os": "osetin",
    "pa": "panjobcha",
    "pag": "pangasinan",
    "pam": "pampanga",
    "pap": "papiyamento",
    "pau": "palau",
    "pcm": "kreol (Nigeriya)",
    "pl": "polyakcha",
    "prg": "pruss",
    "ps": "pushtu",
    "pt": "portugalcha",
    "pt-BR": "portugal (Braziliya)",
    "pt-PT": "portugal (Yevropa)",
    "qu": "kechua",
    "quc": "kiche",
    "rap": "rapanui",
    "rar": "rarotongan",
    "rm": "romansh",
    "rn": "rundi",
    "ro": "rumincha",
    "ro-MD": "moldovan",
    "rof": "rombo",
    "ru": "ruscha",
    "rup": "arumin",
    "rw": "kinyaruanda",
    "rwk": "ruanda",
    "sa": "sanskrit",
    "sad": "sandave",
    "sah": "saxa",
    "saq": "samburu",
    "sat": "santali",
    "sba": "ngambay",
    "sbp": "sangu",
    "sc": "sardin",
    "scn": "sitsiliya",
    "sco": "shotland",
    "sd": "sindhi",
    "sdh": "janubiy kurd",
    "se": "shimoliy saam",
    "seh": "sena",
    "ses": "koyraboro-senni",
    "sg": "sango",
    "shi": "tashelxit",
    "shn": "shan",
    "si": "singal",
    "sk": "slovakcha",
    "sl": "slovencha",
    "sm": "samoa",
    "sma": "janubiy saam",
    "smj": "lule-saam",
    "smn": "inari-saam",
    "sms": "skolt-saam",
    "sn": "shona",
    "snk": "soninke",
    "so": "somalicha",
    "sq": "alban",
    "sr": "serbcha",
    "srn": "sranan-tongo",
    "ss": "svati",
    "ssy": "saho",
    "st": "janubiy soto",
    "su": "sundan",
    "suk": "sukuma",
    "sv": "shved",
    "sw": "suaxili",
    "sw-CD": "suaxili (Kongo)",
    "swb": "qamar",
    "syr": "suriyacha",
    "ta": "tamil",
    "te": "telugu",
    "tem": "timne",
    "teo": "teso",
    "tet": "tetum",
    "tg": "tojik",
    "th": "tay",
    "ti": "tigrinya",
    "tig": "tigre",
    "tk": "turkman",
    "tlh": "klingon",
    "tn": "tsvana",
    "to": "tongan",
    "tpi": "tok-piksin",
    "tr": "turk",
    "trv": "taroko",
    "ts": "tsonga",
    "tt": "tatar",
    "tum": "tumbuka",
    "tvl": "tuvalu",
    "twq": "tasavak",
    "ty": "taiti",
    "tyv": "tuva",
    "tzm": "markaziy atlas tamazigxt",
    "udm": "udmurt",
    "ug": "uyg‘ur",
    "uk": "ukrain",
    "umb": "umbundu",
    "und": "noma’lum til",
    "ur": "urdu",
    "uz": "o‘zbek",
    "vai": "vai",
    "ve": "venda",
    "vi": "vyetnam",
    "vo": "volapyuk",
    "vun": "vunjo",
    "wa": "vallon",
    "wae": "valis",
    "wal": "volamo",
    "war": "varay",
    "wbp": "valbiri",
    "wo": "volof",
    "xal": "qalmoq",
    "xh": "kxosa",
    "xog": "soga",
    "yav": "yangben",
    "ybb": "yemba",
    "yi": "idish",
    "yo": "yoruba",
    "yue": "kanton",
    "zgh": "tamazigxt",
    "zh": "xitoy",
    "zh-Hans": "xitoy (soddalashgan)",
    "zh-Hant": "xitoy (an’anaviy)",
    "zu": "zulu",
    "zun": "zuni",
    "zxx": "til tarkibi yo‘q",
    "zza": "zaza"
  },
  [Language.Zh]: {
    "aa": "阿法尔语",
    "ab": "阿布哈西亚语",
    "ace": "亚齐语",
    "ach": "阿乔利语",
    "ada": "阿当梅语",
    "ady": "阿迪格语",
    "ae": "阿维斯塔语",
    "af": "南非荷兰语",
    "afh": "阿弗里希利语",
    "agq": "亚罕语",
    "ain": "阿伊努语",
    "ak": "阿肯语",
    "akk": "阿卡德语",
    "ale": "阿留申语",
    "alt": "南阿尔泰语",
    "am": "阿姆哈拉语",
    "an": "阿拉贡语",
    "ang": "古英语",
    "anp": "昂加语",
    "ar": "阿拉伯语",
    "ar-001": "现代标准阿拉伯语",
    "arc": "阿拉米语",
    "arn": "马普切语",
    "arp": "阿拉帕霍语",
    "ars": "纳吉迪阿拉伯语",
    "arw": "阿拉瓦克语",
    "as": "阿萨姆语",
    "asa": "帕雷语",
    "ast": "阿斯图里亚斯语",
    "av": "阿瓦尔语",
    "awa": "阿瓦德语",
    "ay": "艾马拉语",
    "az": "阿塞拜疆语",
    "az-Arab": "南阿塞拜疆语",
    "ba": "巴什基尔语",
    "bal": "俾路支语",
    "ban": "巴厘语",
    "bas": "巴萨语",
    "bax": "巴姆穆语",
    "bbj": "戈马拉语",
    "be": "白俄罗斯语",
    "bej": "贝沙语",
    "bem": "本巴语",
    "bez": "贝纳语",
    "bfd": "巴非特语",
    "bg": "保加利亚语",
    "bgn": "西俾路支语",
    "bho": "博杰普尔语",
    "bi": "比斯拉马语",
    "bik": "比科尔语",
    "bin": "比尼语",
    "bkm": "科姆语",
    "bla": "西克西卡语",
    "bm": "班巴拉语",
    "bn": "孟加拉语",
    "bo": "藏语",
    "br": "布列塔尼语",
    "bra": "布拉杰语",
    "brx": "博多语",
    "bs": "波斯尼亚语",
    "bss": "阿库色语",
    "bua": "布里亚特语",
    "bug": "布吉语",
    "bum": "布鲁语",
    "byn": "比林语",
    "byv": "梅敦巴语",
    "ca": "加泰罗尼亚语",
    "cad": "卡多语",
    "car": "加勒比语",
    "cay": "卡尤加语",
    "cch": "阿灿语",
    "ccp": "查克玛语",
    "ce": "车臣语",
    "ceb": "宿务语",
    "cgg": "奇加语",
    "ch": "查莫罗语",
    "chb": "奇布查语",
    "chg": "察合台语",
    "chk": "楚克语",
    "chm": "马里语",
    "chn": "奇努克混合语",
    "cho": "乔克托语",
    "chp": "奇佩维安语",
    "chr": "切罗基语",
    "chy": "夏延语",
    "ckb": "中库尔德语",
    "co": "科西嘉语",
    "cop": "科普特语",
    "cr": "克里族语",
    "crh": "克里米亚土耳其语",
    "crs": "塞舌尔克里奥尔语",
    "cs": "捷克语",
    "csb": "卡舒比语",
    "cu": "教会斯拉夫语",
    "cv": "楚瓦什语",
    "cy": "威尔士语",
    "da": "丹麦语",
    "dak": "达科他语",
    "dar": "达尔格瓦语",
    "dav": "台塔语",
    "de": "德语",
    "de-AT": "奥地利德语",
    "de-CH": "瑞士高地德语",
    "del": "特拉华语",
    "den": "史拉维语",
    "dgr": "多格里布语",
    "din": "丁卡语",
    "dje": "哲尔马语",
    "doi": "多格拉语",
    "dsb": "下索布语",
    "dua": "杜阿拉语",
    "dum": "中古荷兰语",
    "dv": "迪维希语",
    "dyo": "朱拉语",
    "dyu": "迪尤拉语",
    "dz": "宗卡语",
    "dzg": "达扎葛语",
    "ebu": "恩布语",
    "ee": "埃维语",
    "efi": "埃菲克语",
    "egy": "古埃及语",
    "eka": "艾卡朱克语",
    "el": "希腊语",
    "elx": "埃兰语",
    "en": "英语",
    "en-AU": "澳大利亚英语",
    "en-CA": "加拿大英语",
    "en-GB": "英国英语",
    "en-US": "美国英语",
    "enm": "中古英语",
    "eo": "世界语",
    "es": "西班牙语",
    "es-419": "拉丁美洲西班牙语",
    "es-ES": "欧洲西班牙语",
    "es-MX": "墨西哥西班牙语",
    "et": "爱沙尼亚语",
    "eu": "巴斯克语",
    "ewo": "埃翁多语",
    "fa": "波斯语",
    "fa-AF": "达里语",
    "fan": "芳格语",
    "fat": "芳蒂语",
    "ff": "富拉语",
    "fi": "芬兰语",
    "fil": "菲律宾语",
    "fj": "斐济语",
    "fo": "法罗语",
    "fon": "丰语",
    "fr": "法语",
    "fr-CA": "加拿大法语",
    "fr-CH": "瑞士法语",
    "frc": "卡真法语",
    "frm": "中古法语",
    "fro": "古法语",
    "frr": "北弗里西亚语",
    "frs": "东弗里西亚语",
    "fur": "弗留利语",
    "fy": "西弗里西亚语",
    "ga": "爱尔兰语",
    "gaa": "加族语",
    "gag": "加告兹语",
    "gan": "赣语",
    "gay": "迦约语",
    "gba": "格巴亚语",
    "gd": "苏格兰盖尔语",
    "gez": "吉兹语",
    "gil": "吉尔伯特语",
    "gl": "加利西亚语",
    "gmh": "中古高地德语",
    "gn": "瓜拉尼语",
    "goh": "古高地德语",
    "gon": "冈德语",
    "gor": "哥伦打洛语",
    "got": "哥特语",
    "grb": "格列博语",
    "grc": "古希腊语",
    "gsw": "瑞士德语",
    "gu": "古吉拉特语",
    "guz": "古西语",
    "gv": "马恩语",
    "gwi": "哥威迅语",
    "ha": "豪萨语",
    "hai": "海达语",
    "hak": "客家语",
    "haw": "夏威夷语",
    "he": "希伯来语",
    "hi": "印地语",
    "hil": "希利盖农语",
    "hit": "赫梯语",
    "hmn": "苗语",
    "ho": "希里莫图语",
    "hr": "克罗地亚语",
    "hsb": "上索布语",
    "hsn": "湘语",
    "ht": "海地克里奥尔语",
    "hu": "匈牙利语",
    "hup": "胡帕语",
    "hy": "亚美尼亚语",
    "hz": "赫雷罗语",
    "ia": "国际语",
    "iba": "伊班语",
    "ibb": "伊比比奥语",
    "id": "印度尼西亚语",
    "ie": "国际文字（E）",
    "ig": "伊博语",
    "ii": "四川彝语",
    "ik": "伊努皮克语",
    "ilo": "伊洛卡诺语",
    "inh": "印古什语",
    "io": "伊多语",
    "is": "冰岛语",
    "it": "意大利语",
    "iu": "因纽特语",
    "ja": "日语",
    "jbo": "逻辑语",
    "jgo": "恩艮巴语",
    "jmc": "马切姆语",
    "jpr": "犹太波斯语",
    "jrb": "犹太阿拉伯语",
    "jv": "爪哇语",
    "ka": "格鲁吉亚语",
    "kaa": "卡拉卡尔帕克语",
    "kab": "卡拜尔语",
    "kac": "克钦语",
    "kaj": "卡捷语",
    "kam": "卡姆巴语",
    "kaw": "卡威语",
    "kbd": "卡巴尔德语",
    "kbl": "加涅姆布语",
    "kcg": "卡塔布语",
    "kde": "马孔德语",
    "kea": "卡布佛得鲁语",
    "kfo": "克罗语",
    "kg": "刚果语",
    "kgp": "kgp",
    "kha": "卡西语",
    "kho": "和田语",
    "khq": "西桑海语",
    "ki": "吉库尤语",
    "kj": "宽亚玛语",
    "kk": "哈萨克语",
    "kkj": "卡库语",
    "kl": "格陵兰语",
    "kln": "卡伦金语",
    "km": "高棉语",
    "kmb": "金邦杜语",
    "kn": "卡纳达语",
    "ko": "韩语",
    "koi": "科米-彼尔米亚克语",
    "kok": "孔卡尼语",
    "kos": "科斯拉伊语",
    "kpe": "克佩列语",
    "kr": "卡努里语",
    "krc": "卡拉恰伊巴尔卡尔语",
    "krl": "卡累利阿语",
    "kru": "库鲁克语",
    "ks": "克什米尔语",
    "ksb": "香巴拉语",
    "ksf": "巴菲亚语",
    "ksh": "科隆语",
    "ku": "库尔德语",
    "kum": "库梅克语",
    "kut": "库特奈语",
    "kv": "科米语",
    "kw": "康沃尔语",
    "ky": "柯尔克孜语",
    "la": "拉丁语",
    "lad": "拉迪诺语",
    "lag": "朗吉语",
    "lah": "印度-雅利安语",
    "lam": "兰巴语",
    "lb": "卢森堡语",
    "lez": "列兹金语",
    "lg": "卢干达语",
    "li": "林堡语",
    "lij": "lij",
    "lkt": "拉科塔语",
    "ln": "林加拉语",
    "lo": "老挝语",
    "lol": "蒙戈语",
    "lou": "路易斯安那克里奥尔语",
    "loz": "洛齐语",
    "lrc": "北卢尔语",
    "lt": "立陶宛语",
    "lu": "鲁巴加丹加语",
    "lua": "卢巴-卢拉语",
    "lui": "卢伊塞诺语",
    "lun": "隆达语",
    "luo": "卢奥语",
    "lus": "米佐语",
    "luy": "卢雅语",
    "lv": "拉脱维亚语",
    "mad": "马都拉语",
    "maf": "马法语",
    "mag": "摩揭陀语",
    "mai": "迈蒂利语",
    "mak": "望加锡语",
    "man": "曼丁哥语",
    "mas": "马赛语",
    "mde": "马坝语",
    "mdf": "莫克沙语",
    "mdr": "曼达尔语",
    "men": "门德语",
    "mer": "梅鲁语",
    "mfe": "毛里求斯克里奥尔语",
    "mg": "马拉加斯语",
    "mga": "中古爱尔兰语",
    "mgh": "马库阿语",
    "mgo": "梅塔语",
    "mh": "马绍尔语",
    "mi": "毛利语",
    "mic": "密克马克语",
    "min": "米南佳保语",
    "mk": "马其顿语",
    "ml": "马拉雅拉姆语",
    "mn": "蒙古语",
    "mnc": "满语",
    "mni": "曼尼普尔语",
    "moh": "摩霍克语",
    "mos": "莫西语",
    "mr": "马拉地语",
    "ms": "马来语",
    "mt": "马耳他语",
    "mua": "蒙当语",
    "mul": "多语种",
    "mus": "克里克语",
    "mwl": "米兰德斯语",
    "mwr": "马尔瓦里语",
    "my": "缅甸语",
    "mye": "姆耶内语",
    "myv": "厄尔兹亚语",
    "mzn": "马赞德兰语",
    "na": "瑙鲁语",
    "nan": "闽南语",
    "nap": "那不勒斯语",
    "naq": "纳马语",
    "nb": "书面挪威语",
    "nd": "北恩德贝勒语",
    "nds": "低地德语",
    "nds-NL": "低萨克森语",
    "ne": "尼泊尔语",
    "new": "尼瓦尔语",
    "ng": "恩东加语",
    "nia": "尼亚斯语",
    "niu": "纽埃语",
    "nl": "荷兰语",
    "nl-BE": "弗拉芒语",
    "nmg": "夸西奥语",
    "nn": "挪威尼诺斯克语",
    "nnh": "恩甘澎语",
    "no": "挪威语",
    "nog": "诺盖语",
    "non": "古诺尔斯语",
    "nqo": "西非书面文字",
    "nr": "南恩德贝勒语",
    "nso": "北索托语",
    "nus": "努埃尔语",
    "nv": "纳瓦霍语",
    "nwc": "古典尼瓦尔语",
    "ny": "齐切瓦语",
    "nym": "尼扬韦齐语",
    "nyn": "尼昂科勒语",
    "nyo": "尼奥罗语",
    "nzi": "恩济马语",
    "oc": "奥克语",
    "oj": "奥吉布瓦语",
    "om": "奥罗莫语",
    "or": "奥里亚语",
    "os": "奥塞梯语",
    "osa": "奥塞治语",
    "ota": "奥斯曼土耳其语",
    "pa": "旁遮普语",
    "pag": "邦阿西南语",
    "pal": "巴拉维语",
    "pam": "邦板牙语",
    "pap": "帕皮阿门托语",
    "pau": "帕劳语",
    "pcm": "尼日利亚皮钦语",
    "peo": "古波斯语",
    "phn": "腓尼基语",
    "pi": "巴利语",
    "pl": "波兰语",
    "pon": "波纳佩语",
    "prg": "普鲁士语",
    "pro": "古普罗文斯语",
    "ps": "普什图语",
    "pt": "葡萄牙语",
    "pt-BR": "巴西葡萄牙语",
    "pt-PT": "欧洲葡萄牙语",
    "qu": "克丘亚语",
    "quc": "基切语",
    "raj": "拉贾斯坦语",
    "rap": "拉帕努伊语",
    "rar": "拉罗汤加语",
    "rm": "罗曼什语",
    "rn": "隆迪语",
    "ro": "罗马尼亚语",
    "ro-MD": "摩尔多瓦语",
    "rof": "兰博语",
    "rom": "吉普赛语",
    "ru": "俄语",
    "rup": "阿罗马尼亚语",
    "rw": "卢旺达语",
    "rwk": "罗瓦语",
    "sa": "梵语",
    "sad": "桑达韦语",
    "sah": "萨哈语",
    "sam": "萨马利亚阿拉姆语",
    "saq": "桑布鲁语",
    "sas": "萨萨克文",
    "sat": "桑塔利语",
    "sba": "甘拜语",
    "sbp": "桑古语",
    "sc": "萨丁语",
    "scn": "西西里语",
    "sco": "苏格兰语",
    "sd": "信德语",
    "sdh": "南库尔德语",
    "se": "北方萨米语",
    "see": "塞内卡语",
    "seh": "塞纳语",
    "sel": "塞尔库普语",
    "ses": "东桑海语",
    "sg": "桑戈语",
    "sga": "古爱尔兰语",
    "sh": "塞尔维亚-克罗地亚语",
    "shi": "希尔哈语",
    "shn": "掸语",
    "shu": "乍得阿拉伯语",
    "si": "僧伽罗语",
    "sid": "悉达摩语",
    "sk": "斯洛伐克语",
    "sl": "斯洛文尼亚语",
    "sm": "萨摩亚语",
    "sma": "南萨米语",
    "smj": "吕勒萨米语",
    "smn": "伊纳里萨米语",
    "sms": "斯科特萨米语",
    "sn": "绍纳语",
    "snk": "索宁克语",
    "so": "索马里语",
    "sog": "粟特语",
    "sq": "阿尔巴尼亚语",
    "sr": "塞尔维亚语",
    "srn": "苏里南汤加语",
    "srr": "塞雷尔语",
    "ss": "斯瓦蒂语",
    "ssy": "萨霍语",
    "st": "南索托语",
    "su": "巽他语",
    "suk": "苏库马语",
    "sus": "苏苏语",
    "sux": "苏美尔语",
    "sv": "瑞典语",
    "sw": "斯瓦希里语",
    "sw-CD": "刚果斯瓦希里语",
    "swb": "科摩罗语",
    "syc": "古典叙利亚语",
    "syr": "叙利亚语",
    "ta": "泰米尔语",
    "te": "泰卢固语",
    "tem": "泰姆奈语",
    "teo": "特索语",
    "ter": "特伦诺语",
    "tet": "德顿语",
    "tg": "塔吉克语",
    "th": "泰语",
    "ti": "提格利尼亚语",
    "tig": "提格雷语",
    "tiv": "蒂夫语",
    "tk": "土库曼语",
    "tkl": "托克劳语",
    "tl": "他加禄语",
    "tlh": "克林贡语",
    "tli": "特林吉特语",
    "tmh": "塔马奇克语",
    "tn": "茨瓦纳语",
    "to": "汤加语",
    "tog": "尼亚萨汤加语",
    "tpi": "托克皮辛语",
    "tr": "土耳其语",
    "trv": "赛德克语",
    "ts": "聪加语",
    "tsi": "钦西安语",
    "tt": "鞑靼语",
    "tum": "通布卡语",
    "tvl": "图瓦卢语",
    "tw": "契维语",
    "twq": "北桑海语",
    "ty": "塔希提语",
    "tyv": "图瓦语",
    "tzm": "塔马齐格特语",
    "udm": "乌德穆尔特语",
    "ug": "维吾尔语",
    "uga": "乌加里特语",
    "uk": "乌克兰语",
    "umb": "翁本杜语",
    "und": "未知语言",
    "ur": "乌尔都语",
    "uz": "乌兹别克语",
    "vai": "瓦伊语",
    "ve": "文达语",
    "vep": "维普森语",
    "vi": "越南语",
    "vo": "沃拉普克语",
    "vot": "沃提克语",
    "vun": "温旧语",
    "wa": "瓦隆语",
    "wae": "瓦尔瑟语",
    "wal": "瓦拉莫语",
    "war": "瓦瑞语",
    "was": "瓦绍语",
    "wbp": "瓦尔皮瑞语",
    "wo": "沃洛夫语",
    "wuu": "吴语",
    "xal": "卡尔梅克语",
    "xh": "科萨语",
    "xog": "索加语",
    "yao": "瑶族语",
    "yap": "雅浦语",
    "yav": "洋卞语",
    "ybb": "耶姆巴语",
    "yi": "意第绪语",
    "yo": "约鲁巴语",
    "yue": "粤语",
    "za": "壮语",
    "zap": "萨波蒂克语",
    "zbl": "布里斯符号",
    "zen": "泽纳加语",
    "zgh": "标准摩洛哥塔马塞特语",
    "zh": "中文",
    "zh-Hans": "简体中文",
    "zh-Hant": "繁体中文",
    "zu": "祖鲁语",
    "zun": "祖尼语",
    "zxx": "无语言内容",
    "zza": "扎扎语"
  },
  [Language.Ky]: {
    "aa": "афарский",
    "ab": "абхазский",
    "ace": "ачехский",
    "ach": "ачоли",
    "ada": "адангме",
    "ady": "адыгейский",
    "ae": "авестийский",
    "af": "африкаанс",
    "afh": "африхили",
    "agq": "агем",
    "ain": "айнский",
    "ak": "акан",
    "akk": "аккадский",
    "ale": "алеутский",
    "alt": "южноалтайский",
    "am": "амхарский",
    "an": "арагонский",
    "ang": "староанглийский",
    "anp": "ангика",
    "ar": "арабский",
    "ar-001": "арабский литературный",
    "arc": "арамейский",
    "arn": "мапуче",
    "arp": "арапахо",
    "ars": "недждийский арабский",
    "arw": "аравакский",
    "as": "ассамский",
    "asa": "асу",
    "ast": "астурийский",
    "av": "аварский",
    "awa": "авадхи",
    "ay": "аймара",
    "az": "азербайджанский",
    "ba": "башкирский",
    "bal": "белуджский",
    "ban": "балийский",
    "bas": "баса",
    "bax": "бамум",
    "bbj": "гомала",
    "be": "белорусский",
    "bej": "беджа",
    "bem": "бемба",
    "bez": "бена",
    "bfd": "бафут",
    "bg": "болгарский",
    "bgn": "западный белуджский",
    "bho": "бходжпури",
    "bi": "бислама",
    "bik": "бикольский",
    "bin": "бини",
    "bkm": "ком",
    "bla": "сиксика",
    "bm": "бамбара",
    "bn": "бенгальский",
    "bo": "тибетский",
    "br": "бретонский",
    "bra": "брауи",
    "brx": "бодо",
    "bs": "боснийский",
    "bss": "акоосе",
    "bua": "бурятский",
    "bug": "бугийский",
    "bum": "булу",
    "byn": "билин",
    "byv": "медумба",
    "ca": "каталанский",
    "cad": "каддо",
    "car": "кариб",
    "cay": "кайюга",
    "cch": "атсам",
    "ccp": "чакма",
    "ce": "чеченский",
    "ceb": "себуано",
    "cgg": "кига",
    "ch": "чаморро",
    "chb": "чибча",
    "chg": "чагатайский",
    "chk": "чукотский",
    "chm": "марийский",
    "chn": "чинук жаргон",
    "cho": "чоктавский",
    "chp": "чипевьян",
    "chr": "чероки",
    "chy": "шайенский",
    "ckb": "сорани",
    "co": "корсиканский",
    "cop": "коптский",
    "cr": "кри",
    "crh": "крымско-татарский",
    "crs": "сейшельский креольский",
    "cs": "чешский",
    "csb": "кашубский",
    "cu": "церковнославянский",
    "cv": "чувашский",
    "cy": "валлийский",
    "da": "датский",
    "dak": "дакота",
    "dar": "даргинский",
    "dav": "таита",
    "de": "немецкий",
    "de-AT": "австрийский немецкий",
    "de-CH": "литературный швейцарский немецкий",
    "del": "делаварский",
    "den": "слейви",
    "dgr": "догриб",
    "din": "динка",
    "dje": "джерма",
    "doi": "догри",
    "dsb": "нижнелужицкий",
    "dua": "дуала",
    "dum": "средненидерландский",
    "dv": "мальдивский",
    "dyo": "диола-фоньи",
    "dyu": "диула",
    "dz": "дзонг-кэ",
    "dzg": "даза",
    "ebu": "эмбу",
    "ee": "эве",
    "efi": "эфик",
    "egy": "древнеегипетский",
    "eka": "экаджук",
    "el": "греческий",
    "elx": "эламский",
    "en": "английский",
    "en-AU": "австралийский английский",
    "en-CA": "канадский английский",
    "en-GB": "британский английский",
    "en-US": "американский английский",
    "enm": "среднеанглийский",
    "eo": "эсперанто",
    "es": "испанский",
    "es-419": "латиноамериканский испанский",
    "es-ES": "европейский испанский",
    "es-MX": "мексиканский испанский",
    "et": "эстонский",
    "eu": "баскский",
    "ewo": "эвондо",
    "fa": "персидский",
    "fa-AF": "дари",
    "fan": "фанг",
    "fat": "фанти",
    "ff": "фулах",
    "fi": "финский",
    "fil": "филиппинский",
    "fj": "фиджи",
    "fo": "фарерский",
    "fon": "фон",
    "fr": "французский",
    "fr-CA": "канадский французский",
    "fr-CH": "швейцарский французский",
    "frc": "каджунский французский",
    "frm": "среднефранцузский",
    "fro": "старофранцузский",
    "frr": "северный фризский",
    "frs": "восточный фризский",
    "fur": "фриульский",
    "fy": "западнофризский",
    "ga": "ирландский",
    "gaa": "га",
    "gag": "гагаузский",
    "gan": "гань",
    "gay": "гайо",
    "gba": "гбая",
    "gd": "гэльский",
    "gez": "геэз",
    "gil": "гилбертский",
    "gl": "галисийский",
    "gmh": "средневерхненемецкий",
    "gn": "гуарани",
    "goh": "древневерхненемецкий",
    "gon": "гонди",
    "gor": "горонтало",
    "got": "готский",
    "grb": "гребо",
    "grc": "древнегреческий",
    "gsw": "швейцарский немецкий",
    "gu": "гуджарати",
    "guz": "гусии",
    "gv": "мэнский",
    "gwi": "гвичин",
    "ha": "хауса",
    "hai": "хайда",
    "hak": "хакка",
    "haw": "гавайский",
    "he": "иврит",
    "hi": "хинди",
    "hil": "хилигайнон",
    "hit": "хеттский",
    "hmn": "хмонг",
    "ho": "хиримоту",
    "hr": "хорватский",
    "hsb": "верхнелужицкий",
    "hsn": "сян",
    "ht": "гаитянский",
    "hu": "венгерский",
    "hup": "хупа",
    "hy": "армянский",
    "hz": "гереро",
    "ia": "интерлингва",
    "iba": "ибанский",
    "ibb": "ибибио",
    "id": "индонезийский",
    "ie": "интерлингве",
    "ig": "игбо",
    "ii": "носу",
    "ik": "инупиак",
    "ilo": "илоко",
    "inh": "ингушский",
    "io": "идо",
    "is": "исландский",
    "it": "итальянский",
    "iu": "инуктитут",
    "ja": "японский",
    "jbo": "ложбан",
    "jgo": "нгомба",
    "jmc": "мачаме",
    "jpr": "еврейско-персидский",
    "jrb": "еврейско-арабский",
    "jv": "яванский",
    "ka": "грузинский",
    "kaa": "каракалпакский",
    "kab": "кабильский",
    "kac": "качинский",
    "kaj": "каджи",
    "kam": "камба",
    "kaw": "кави",
    "kbd": "кабардинский",
    "kbl": "канембу",
    "kcg": "тьяп",
    "kde": "маконде",
    "kea": "кабувердьяну",
    "kfo": "коро",
    "kg": "конго",
    "kgp": "kgp",
    "kha": "кхаси",
    "kho": "хотанский",
    "khq": "койра чиини",
    "ki": "кикуйю",
    "kj": "кунама",
    "kk": "казахский",
    "kkj": "како",
    "kl": "гренландский",
    "kln": "календжин",
    "km": "кхмерский",
    "kmb": "кимбунду",
    "kn": "каннада",
    "ko": "корейский",
    "koi": "коми-пермяцкий",
    "kok": "конкани",
    "kos": "косраенский",
    "kpe": "кпелле",
    "kr": "канури",
    "krc": "карачаево-балкарский",
    "krl": "карельский",
    "kru": "курух",
    "ks": "кашмири",
    "ksb": "шамбала",
    "ksf": "бафия",
    "ksh": "кёльнский",
    "ku": "курдский",
    "kum": "кумыкский",
    "kut": "кутенаи",
    "kv": "коми",
    "kw": "корнский",
    "ky": "киргизский",
    "la": "латинский",
    "lad": "ладино",
    "lag": "ланго",
    "lah": "лахнда",
    "lam": "ламба",
    "lb": "люксембургский",
    "lez": "лезгинский",
    "lg": "ганда",
    "li": "лимбургский",
    "lij": "lij",
    "lkt": "лакота",
    "ln": "лингала",
    "lo": "лаосский",
    "lol": "монго",
    "lou": "луизианский креольский",
    "loz": "лози",
    "lrc": "севернолурский",
    "lt": "литовский",
    "lu": "луба-катанга",
    "lua": "луба-лулуа",
    "lui": "луисеньо",
    "lun": "лунда",
    "luo": "луо",
    "lus": "мизо",
    "luy": "лухья",
    "lv": "латышский",
    "mad": "мадурский",
    "maf": "мафа",
    "mag": "магахи",
    "mai": "майтхили",
    "mak": "макассарский",
    "man": "мандинго",
    "mas": "масаи",
    "mde": "маба",
    "mdf": "мокшанский",
    "mdr": "мандарский",
    "men": "менде",
    "mer": "меру",
    "mfe": "маврикийский креольский",
    "mg": "малагасийский",
    "mga": "среднеирландский",
    "mgh": "макуа-меетто",
    "mgo": "мета",
    "mh": "маршалльский",
    "mi": "маори",
    "mic": "микмак",
    "min": "минангкабау",
    "mk": "македонский",
    "ml": "малаялам",
    "mn": "монгольский",
    "mnc": "маньчжурский",
    "mni": "манипурский",
    "moh": "мохаук",
    "mos": "моси",
    "mr": "маратхи",
    "ms": "малайский",
    "mt": "мальтийский",
    "mua": "мунданг",
    "mul": "языки разных семей",
    "mus": "крик",
    "mwl": "мирандский",
    "mwr": "марвари",
    "my": "бирманский",
    "mye": "миене",
    "myv": "эрзянский",
    "mzn": "мазандеранский",
    "na": "науру",
    "nan": "миньнань",
    "nap": "неаполитанский",
    "naq": "нама",
    "nb": "норвежский букмол",
    "nd": "северный ндебеле",
    "nds": "нижненемецкий",
    "nds-NL": "нижнесаксонский",
    "ne": "непальский",
    "new": "неварский",
    "ng": "ндонга",
    "nia": "ниас",
    "niu": "ниуэ",
    "nl": "нидерландский",
    "nl-BE": "фламандский",
    "nmg": "квасио",
    "nn": "нюнорск",
    "nnh": "нгиембунд",
    "no": "норвежский",
    "nog": "ногайский",
    "non": "старонорвежский",
    "nqo": "нко",
    "nr": "южный ндебеле",
    "nso": "северный сото",
    "nus": "нуэр",
    "nv": "навахо",
    "nwc": "классический невари",
    "ny": "ньянджа",
    "nym": "ньямвези",
    "nyn": "ньянколе",
    "nyo": "ньоро",
    "nzi": "нзима",
    "oc": "окситанский",
    "oj": "оджибва",
    "om": "оромо",
    "or": "ория",
    "os": "осетинский",
    "osa": "оседжи",
    "ota": "старотурецкий",
    "pa": "панджаби",
    "pag": "пангасинан",
    "pal": "пехлевийский",
    "pam": "пампанга",
    "pap": "папьяменто",
    "pau": "палау",
    "pcm": "нигерийско-креольский",
    "peo": "староперсидский",
    "phn": "финикийский",
    "pi": "пали",
    "pl": "польский",
    "pon": "понапе",
    "prg": "прусский",
    "pro": "старопровансальский",
    "ps": "пушту",
    "pt": "португальский",
    "pt-BR": "бразильский португальский",
    "pt-PT": "европейский португальский",
    "qu": "кечуа",
    "quc": "киче",
    "raj": "раджастхани",
    "rap": "рапануйский",
    "rar": "раротонга",
    "rm": "романшский",
    "rn": "рунди",
    "ro": "румынский",
    "ro-MD": "молдавский",
    "rof": "ромбо",
    "rom": "цыганский",
    "ru": "русский",
    "rup": "арумынский",
    "rw": "киньяруанда",
    "rwk": "руанда",
    "sa": "санскрит",
    "sad": "сандаве",
    "sah": "саха",
    "sam": "самаритянский арамейский",
    "saq": "самбуру",
    "sas": "сасакский",
    "sat": "сантали",
    "sba": "нгамбайский",
    "sbp": "сангу",
    "sc": "сардинский",
    "scn": "сицилийский",
    "sco": "шотландский",
    "sd": "синдхи",
    "sdh": "южнокурдский",
    "se": "северносаамский",
    "see": "сенека",
    "seh": "сена",
    "sel": "селькупский",
    "ses": "койраборо сенни",
    "sg": "санго",
    "sga": "староирландский",
    "sh": "сербскохорватский",
    "shi": "ташельхит",
    "shn": "шанский",
    "shu": "чадский арабский",
    "si": "сингальский",
    "sid": "сидама",
    "sk": "словацкий",
    "sl": "словенский",
    "sm": "самоанский",
    "sma": "южносаамский",
    "smj": "луле-саамский",
    "smn": "инари-саамский",
    "sms": "колтта-саамский",
    "sn": "шона",
    "snk": "сонинке",
    "so": "сомали",
    "sog": "согдийский",
    "sq": "албанский",
    "sr": "сербский",
    "srn": "сранан-тонго",
    "srr": "серер",
    "ss": "свази",
    "ssy": "сахо",
    "st": "южный сото",
    "su": "сунданский",
    "suk": "сукума",
    "sus": "сусу",
    "sux": "шумерский",
    "sv": "шведский",
    "sw": "суахили",
    "sw-CD": "конголезский суахили",
    "swb": "коморский",
    "syc": "классический сирийский",
    "syr": "сирийский",
    "ta": "тамильский",
    "te": "телугу",
    "tem": "темне",
    "teo": "тесо",
    "ter": "терено",
    "tet": "тетум",
    "tg": "таджикский",
    "th": "тайский",
    "ti": "тигринья",
    "tig": "тигре",
    "tiv": "тиви",
    "tk": "туркменский",
    "tkl": "токелайский",
    "tl": "тагалог",
    "tlh": "клингонский",
    "tli": "тлингит",
    "tmh": "тамашек",
    "tn": "тсвана",
    "to": "тонганский",
    "tog": "тонга",
    "tpi": "ток-писин",
    "tr": "турецкий",
    "tru": "туройо",
    "trv": "седекский",
    "ts": "тсонга",
    "tsi": "цимшиан",
    "tt": "татарский",
    "tum": "тумбука",
    "tvl": "тувалу",
    "tw": "тви",
    "twq": "тасавак",
    "ty": "таитянский",
    "tyv": "тувинский",
    "tzm": "среднеатласский тамазигхтский",
    "udm": "удмуртский",
    "ug": "уйгурский",
    "uga": "угаритский",
    "uk": "украинский",
    "umb": "умбунду",
    "und": "неизвестный язык",
    "ur": "урду",
    "uz": "узбекский",
    "vai": "ваи",
    "ve": "венда",
    "vi": "вьетнамский",
    "vo": "волапюк",
    "vot": "водский",
    "vun": "вунджо",
    "wa": "валлонский",
    "wae": "валлисский",
    "wal": "воламо",
    "war": "варай",
    "was": "вашо",
    "wbp": "вальбири",
    "wo": "волоф",
    "wuu": "ву",
    "xal": "калмыцкий",
    "xh": "коса",
    "xog": "сога",
    "yao": "яо",
    "yap": "яп",
    "yav": "янгбен",
    "ybb": "йемба",
    "yi": "идиш",
    "yo": "йоруба",
    "yue": "кантонский",
    "za": "чжуань",
    "zap": "сапотекский",
    "zbl": "блиссимволика",
    "zen": "зенагский",
    "zgh": "тамазигхтский",
    "zh": "китайский",
    "zh-Hans": "китайский, упрощенное письмо",
    "zh-Hant": "китайский, традиционное письмо",
    "zu": "зулу",
    "zun": "зуньи",
    "zxx": "нет языкового материала",
    "zza": "заза"
  }
};

const languageTranslate = (isoName: string, translateTo: Language): string => {
  try {
    if (LANGS[translateTo] && LANGS[translateTo]?.[isoName]) {
      return (
        LANGS[translateTo]?.[isoName] || isoName
      );
    }
  } catch (ignore) {
    return isoName;
  }

  return isoName;
};


export default languageTranslate;
