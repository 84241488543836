import * as cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import CurrentEventProgress from '~components/CurrentEventProgress/component';
import { getCachedCardMutation, removeCardMutations, useCardMutations } from '~hooks/fetch/useCardMutations';
import useCurrentEventCard from '~hooks/useCurrentEventCard';
import useDeepLink from '~hooks/useDeepLink';
import { isCardChannel } from '~lib/objectType';
import { isCardProgramEvent } from '~lib/objectType';
import Card, { Modification } from '~typings/Card';
import ImageType from '~typings/ImageType';
import ItemObject from '~typings/ItemObject';
import { isCardImageUpdatable } from '~ui/Card/Render/Image.helpers';
import CardItemImageUpdatable from '~ui/Card/Render/ImageUpdatable';
import CardItemName from '~ui/Card/Render/Name';
import EditCheckboxIcon from '~ui/EditCheckboxIcon/EditCheckboxIcon';
import { Label } from '~ui/Label';
import LabelCard from '~ui/Label/LabelCard';
import { CardItemProps } from '~ui/MediaItem/component';
import * as styles from '~ui/MediaItem/styles.module.css';
import { ProgressBar } from '~ui/ProgressBar';

import getMediaItemWrapperStyles from './getMediaItemWrapperStyles';
import CardItemAdditionalName from './Render/AdditionalName';
import CardItemImage from './Render/Image';
import RenderIcon from './RenderIcon';


const CardItem: React.FC<CardItemProps> = (props: CardItemProps) => {
  const isAllowMutations = props.item?.mutable && (
    !props.isSliderView
    || isCardProgramEvent(props.item)
    || props.isFocused
  );
  const cardConfigID = props.cardConfigID || props.item?.card_config_id;
  const mutations = props.item?.mutable ? getCachedCardMutation(props.item?.id, cardConfigID) : undefined;
  // const mutation = getProgramEventMutation(mutations);
  const mutation = useCurrentEventCard(mutations, props.item);
  if (mutation === null && isCardChannel(props.item)) {
    removeCardMutations(
      (isAllowMutations && props.item?.id) ? props.item.id : undefined,
      props.cardConfigID,
    )
  }

  useCardMutations(
    (isAllowMutations && props.item?.id) ? props.item.id : undefined,
    cardConfigID,
    isCardChannel(props.item),
  );
  const item: Card | undefined = mutation ? mutation.card : props.item;
  const history = useHistory();
  const { urlFromDeepLink } = useDeepLink();
  const style = getMediaItemWrapperStyles({
    top: props.top,
    left: props.left,
  });
  const showAsSeries = !!props.showAsSeries || !!item?.catalog_header;
  const isImageUpdatable = isCardImageUpdatable(item);

  const handleClick = () => {
    if (props.editMode && props.onClick && item) {
      props.onClick(item);

      return;
    }

    if (item?.deeplink) {
      if(item.modification === Modification.CATCHUP) {
        return history.push(`/media-item/${ItemObject.ProgramEvent}/${item.resource_id}/player?player=true`);
      }

      if(props.item?.resource_type === ItemObject.Person) {
        return history.push(`/people/${item.resource_id}`)
      }

      if(props.item?.resource_type === ItemObject.AudioShow) {
        return history.push(`/media-item/${ItemObject.AudioShow}/${item.resource_id}`)
      }

      const url = urlFromDeepLink(item.deeplink, item.modification);
      if (url) {

        history.push(url);
      }
    }

    if(props.onClick) {
      if(item?.deeplink && item.deeplink.includes('promo/rent-plan')) {
        history.push({
          pathname: `/promo/rent-plan-confirm/${item?.resource_type}/${item?.id}`,
          search: history.location.search,
        });
      } else {
        // @ts-ignore
        props.onClick()
      }
    }
  };

  const renderProgress = () => {
    if (isCardChannel(props.item as Card) || isCardProgramEvent(props.item as Card)) { return null; }

    if (!mutation || mutation.disable_progress_line === true) { return false; }

    return (
      <CurrentEventProgress
        className={ styles.currentEventProgress }
        startAt={ mutation.start_at }
        endAt={ mutation.end_at || undefined }
      />
    );
  };

  const getSeriesNumber = (str: string | null | undefined) => {
    if(!!(str?.includes('Эпизод'))) {
      return str?.slice(16)
    } else {
      return null;
    }
  }
  const episodeNumber = getSeriesNumber(item?.additional_name);

  const imageContent = (
    <>
      <RenderIcon
        resourceType={ item?.resource_type }
        modification={ item?.modification }
        isFocused={ props.isFocused }
        className={ styles.icon }
      />
      <LabelCard
        className={ cn(styles.textLabel, {
          [styles.posterLabel]: [ImageType.Card1x1, ImageType.POSTER].includes(props.dimension),
        }) }
        labels={ item?.labels }
        modification={ item?.modification }
      />
      {
        (item?.content_icon || item?.card_icon) && (
          <Label
            className={ styles.label }
            image={ item.content_icon || item?.card_icon || undefined }
          />
        )
      }
      {
         props.withProgress
         && typeof(props.percentWatched) !== 'undefined'
         && (!props.isSliderView || !props.isFocused) ? (
          <ProgressBar progress={ props.percentWatched } />
        ) : null
      }
      {
        props.editMode ? (
          <EditCheckboxIcon isFocused={ props.isFocused } isChecked={ props.isChecked } />
        ) : null
      }
      { renderProgress() }
    </>
  );

  return (
    <div
      key={ props.itemKey }
      style={ style }
      className={ cn(props.className, styles.withFocusBorder, styles.mediaItem, {
        [styles.focused]: props.isFocused,
        [styles.showAsSeries]: showAsSeries,
        [styles.showAsSeriesFocused]: (showAsSeries && props.isFocused),
        // [styles.focused]: props.isFocused || !!props.alwaysShowName,
      }) }
      onClick={ handleClick }
    >
      {
        (isImageUpdatable) ? (
          <CardItemImageUpdatable
            item={ item }
            dimension={ props.dimension }
          />
        ) : (
          <CardItemImage
            item={ item }
            dimension={ props.dimension }
          />
        )
      }
      {
        <div className={ styles.episodeNumber }>
          {episodeNumber}
        </div>
      }
      { imageContent }
      {
        (!props.isFocused || props.alwaysShowName) && item && (
          <>
            {
              (props.withName) ?
                !props.isFocused ?
                  <CardItemName item={ item as Card } isFocused={ false }/>
                  : <CardItemName item={ item as Card } isFocused={ true }/>
                :null
            }
            {
              (props.withGenres) ?
                !props.isFocused ?
                  <CardItemAdditionalName item={ item as Card } isFocused={ false }/>
                  : <CardItemAdditionalName item={ item as Card } isFocused={ true }/>
                :null
            }
          </>
        )
      }
    </div>
  );
};

export default CardItem;
