import * as React from 'react';

import Product from '~typings/Product';

import * as styles from './styles.module.css';


type Props = Readonly<{
  product: Product;
}>;


const ProductItem: React.FC<Props> = (props: Props) => {
  return (
    <>
      <h2
        className={ styles.name }
      >
        { props.product.name }
      </h2>
      <h3
        className={ styles.subtitle }
      >
        { props.product.subscription_page_subtitle }
      </h3>
    </>
  );
};


export default ProductItem;
