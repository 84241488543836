import focuser from '@focuser';
import * as React from 'react';

import { Typography } from '~app/ui-kit/Typography';
import { useAnimationContext } from '~components/Provider/Animation';
import ScrollBar from '~components/ScrollBar';
import { useScrollFocuser } from '~hooks/useScroll/useScrollFocuser';
import { PopupIsolated } from '~ui/Popup';

import * as styles from './DescriptionShowPopup.module.css';

export type Props = {
  name?: string;
  description?: string;
  onBack: () => void;
};

export const DescriptionShowPopup$ = ({ onBack, description, name }) => {
  const { isAnimationEnabled } = useAnimationContext();
  const animationDuration = isAnimationEnabled ? 200 : 0;

  const {
    wrapperRef,
    contentRef,
    scrollPercent,
    isScrollAvailable,
    ...focuserProps
  } = useScrollFocuser({
    animationDurationInMS: animationDuration,
  });

  return (
    <PopupIsolated focuserClassName={ styles.isolationContainer }>
      <focuser.FocusableBlock
        { ...focuserProps }
        className={ styles.descriptionShowPopup }
        forwardRef={ wrapperRef }
        isFocused
        onKeyReturn={ onBack }
        isLastBlock
        noNeedForceFocus
      >
        <div ref={ contentRef }>
          <Typography
            variant="h3_bold"
            className={ styles.title }
          >
            {name}
          </Typography>
          <Typography
            variant="subtitle1_medium"
            className={ styles.description }
          >
            {description}
          </Typography>
        </div>
        {isScrollAvailable ? (
          <ScrollBar
            position={ scrollPercent }
            className={ styles.scrollbar }
            animationDurationInMS={ animationDuration }
            isFocused
          />
        ) : null}
      </focuser.FocusableBlock>
    </PopupIsolated>
  );
};

/**
 * Компонент показывает описание коллекции в попапе
 */
export const DescriptionShowPopup = React.memo(DescriptionShowPopup$)
