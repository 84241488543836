// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubscriptionsTab--ZD8tR {\n  /* 10px */\n  padding-top: 0.78125vw;\n}\n\n.subscriptionsTitle--g4Qh4 {\n  margin-bottom: 1.5625vw;\n  color: rgba(223, 223, 228, 1);\n}\n\n.productsTitle--Yc8Kz {\n  margin-bottom: 1.5625vw;\n  color: rgba(223, 223, 228, 1);\n}\n\n.productsTitleIfSubscription--EgRRT {\n  /* 60px */\n  margin-top: 4.6875vw;\n}\n\n.sliderWrapper--H9elF {\n  /* 564px */\n  height: 44.0625vw;\n  overflow: hidden;\n}\n\n.slider--JORUp {\n  -webkit-box-align: start;\n  -webkit-align-items: flex-start;\n     -moz-box-align: start;\n          align-items: flex-start;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/Subscriptions/Subscriptions.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,6BAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,6BAAa;AACf;;AAEA;EACE,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;AACzB","sourcesContent":[".SubscriptionsTab {\n  /* 10px */\n  padding-top: 0.78125vw;\n}\n\n.subscriptionsTitle {\n  margin-bottom: 1.5625vw;\n  color: $gw_08;\n}\n\n.productsTitle {\n  margin-bottom: 1.5625vw;\n  color: $gw_08;\n}\n\n.productsTitleIfSubscription {\n  /* 60px */\n  margin-top: 4.6875vw;\n}\n\n.sliderWrapper {\n  /* 564px */\n  height: 44.0625vw;\n  overflow: hidden;\n}\n\n.slider {\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
export var SubscriptionsTab = "SubscriptionsTab--ZD8tR";
export var subscriptionsTitle = "subscriptionsTitle--g4Qh4";
export var productsTitle = "productsTitle--Yc8Kz";
export var productsTitleIfSubscription = "productsTitleIfSubscription--EgRRT";
export var sliderWrapper = "sliderWrapper--H9elF";
export var slider = "slider--JORUp";
export default ___CSS_LOADER_EXPORT___;
