import React from 'react';
import { useInfiniteQuery } from 'react-query';

import { useClient, useConfig } from '~global';
import ApiClient from '~lib/ApiClient';
import Card from '~typings/Card';
import ResponseMany from '~typings/ResponseMany';

import { getAuthData } from '../useAccount';
import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '../utils';
import {
  getDropdownFiltersOptions,
  getFiltersKeyString,
  getQuickFiltersString,
  SearchFilters,
} from './useSearch.helpers';

type FetchParams = {
  page: number;
  searchQuery: string;
  filters: SearchFilters;
  client: ApiClient;
  searchPageResourceTypes?: string;
  limit: number
  accessToken: string | null;
};

const PREFIX_OF_A_COMPOSITE_KEY = 'search_results';

type QueryKeyParams = {
  searchQuery: string;
  accessToken: string | null;
  filters: SearchFilters;
}

const searchKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,
  infinitySearch: (keyParams: QueryKeyParams)=>[
    ...searchKeys.PREFIX,
    keyParams.accessToken,
    keyParams.searchQuery,
    getFiltersKeyString(keyParams.filters)
  ]
}


const fetchSearchItems = async ({
  searchQuery: query,
  filters,
  client,
  page,
  searchPageResourceTypes,
  limit,
  accessToken,
}: FetchParams) => {
  let options = {
    query,
    'filter[resource_type_in]':
      searchPageResourceTypes || 'channels,movies,series,program_events,episodes',
    'page[offset]': page * limit,
    'page[limit]': limit,
    access_token: accessToken,
  };

  if (filters?.quickFilters) {
    options['filter[quick_filter_ids_include]'] = getQuickFiltersString(filters.quickFilters);
  }
  if (filters?.dropdownFilters) {
    options = {
      ...options,
      ...getDropdownFiltersOptions(filters.dropdownFilters),
    };
  }

  return await client.get('/v3/search/cards.json', options);
};

type Params = {
  searchQuery?: string;
  filters: SearchFilters,
  searchPageResourceTypes?: string;
  enabled: boolean;
}

function useInfinitySearch({
  searchPageResourceTypes,
  searchQuery = '',
  filters,
  enabled
}: Params) {
  const client = useClient();
  const limit = getLimitFromConfig(useConfig());
  const accessToken = getAuthData().accessToken;

  const infinityQuery = useInfiniteQuery<ResponseMany<Card[]>>({
    queryKey: searchKeys.infinitySearch({
      searchQuery,
      accessToken,
      filters
    }),
    queryFn:  ({ pageParam = 0 })=>fetchSearchItems({
      searchQuery,
      filters,
      client,
      page: pageParam,
      searchPageResourceTypes,
      accessToken,
      limit
    }),
    getNextPageParam: getNextPageForInfinityPagination,
    enabled,
    notifyOnChangeProps: 'tracked'
  });



  const parsedData = React.useMemo(() => {
    return infinityQuery.data?.pages && reducePaginatedPages(infinityQuery.data.pages);
  }, [infinityQuery]);



  return { query: infinityQuery, parsedData };
}


export {
  useInfinitySearch,
};
