import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { useConfig } from '~global';
import { useAccount } from '~hooks/fetch/useAccount';
import usePasswordBySms from '~hooks/fetch/useAccount/usePasswordBySms';
import useUsernameAvailabilityMutation from '~hooks/fetch/useUsernameAvailability';
import { useCountdownCounter } from '~hooks/useCountdownCounter';
import {
  AUTH_SEND_CODE,
  FORGOT_PASSWORD,
  RESEND_PASSWORD_COUNTER_TEXT
} from '~localization';
import { AccountField } from '~typings/AccountInfo';
import NavigationDirection from '~typings/NavigationDirection';
import { LoginFieldType, LoginFormType } from '~typings/StartupConfig';
import { UsernameAvailabilityType } from '~typings/UsernameAvailabilityType';
import IconButton from '~ui/IconButton';
import { NavigationHelper } from '~ui/NavigationHelper';

const RESEND_PASSWORD_DELAY = 300;

type Props = Readonly<{
  isFocused: boolean;
  onLeave: (direction: NavigationDirection) => void,
  onFocusLost?: () => void;
  onError?: (error: any) => void;
  setIsProcessing?: (isProcessing: boolean) => void;
}>;

const PasswRecoveryButton: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const {
    smartTV: { isSimpleAuth },
    startup: { login_form: { login_form_type, login_field_type } }
  } = useConfig();

  const { data: accountInfo } = useAccount();
  const username = accountInfo?.[AccountField.Username];

  const { mutate: checkUsername, data: availabilityInfo } = useUsernameAvailabilityMutation();
  const { mutate: sendPasswordBySms } = usePasswordBySms();

  const [ resendPasswordCounter, setResetPasswordCounter ] = useCountdownCounter();

  const isResendPasswordAvailable = availabilityInfo?.data.type === UsernameAvailabilityType.Msisdn;

  const setIsProcessing = props.setIsProcessing ?? (() => {});

  React.useLayoutEffect(() => {
    if (
      props.onFocusLost &&
      props.isFocused &&
      resendPasswordCounter > 0
    ) {
      props.onFocusLost();
    }
  }, [
    resendPasswordCounter > 0,
    props.isFocused,
    props.onFocusLost
  ]);

  React.useEffect(() => {
    if (
      username &&
      isSimpleAuth === true &&
      login_field_type !== LoginFieldType.Email &&
      login_form_type === LoginFormType.Implicit
    ) {
      checkUsername({ username });
    }
  }, [username, isSimpleAuth, login_field_type, login_form_type]);


  let messageId = isResendPasswordAvailable
      ? AUTH_SEND_CODE
      : FORGOT_PASSWORD;

  const buttonClickHandler = () => {
    if (
      isResendPasswordAvailable &&
      username
    ) {
      setIsProcessing(true),
      sendPasswordBySms({ username }, {
        onSuccess: () => {
          setResetPasswordCounter(RESEND_PASSWORD_DELAY);
        },
        onError: props.onError,
        onSettled: () => setIsProcessing(false),
      });
    } else {
      history.push('/auth/forgot-password');
    }
  };

  const renderButton = () => (
    <>
      { !!props.onLeave && props.isFocused && (
        <NavigationHelper
          isFocused={ props.isFocused }
          onLeave={ props.onLeave }
        />
      ) }

      <IconButton
        isFocused={ props.isFocused }
        onClick={ buttonClickHandler }
      >
        <FormattedMessage id={ messageId } />
      </IconButton>
    </>
  );

  const renderCounter = () => (
    <FormattedMessage
      id={ RESEND_PASSWORD_COUNTER_TEXT }
      values={ { sec: resendPasswordCounter } }
    />
  );


  return (
    resendPasswordCounter > 0
      ? renderCounter()
      : renderButton()
  );
};

export default PasswRecoveryButton;
