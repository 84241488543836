import { useEffect, useMemo, useState } from 'react';
import { InjectedIntl } from 'react-intl';

import useProducts from '~hooks/fetch/useProducts';
import useRentPlans from '~hooks/fetch/useRentPlans/useRentPlans';
import { getAvailableMinPriceFromRents } from '~lib/product/getRentMinPrice';
import { BY_SUBSCRIPTION, FOR_PRICE, FREE, FROM, NAV_LEGEND_PLAY_CHANNEL } from '~localization';
import Money from '~typings/Money';

/**
 * Хук возвращает `eligible-trial-exists` или `eligible-trial-not-exists`
 * в зависимости от наличия плана с eligible_phase.type == trial в продуктах
 *
 * Если продуктов нет, то возвращает `no-products`
 */
const useBestPriceFromProducts = ({
  resourceID,
  enabled,
}: {
  resourceID: string | undefined;
  enabled: boolean;
}): 'no-products' | 'eligible-trial-exists' | 'eligible-trial-not-exists' | 'loading' => {
  const { data: products, isLoading } = useProducts(enabled ? { resourceID } : {});

  const isEligiblePhaseExists = useMemo(
    () =>
      products?.data
        .flatMap((product) => product.plans)
        .some((plan) => plan.eligible_phase?.type === 'trial'),
    [products?.data],
  );

  if (isLoading) {
    return 'loading';
  }

  if (!products?.data?.length) {
    return 'no-products';
  }

  return isEligiblePhaseExists ? 'eligible-trial-exists' : 'eligible-trial-not-exists';
};

const useBestPriceFromRentPlans = ({
  resourceID,
  enabled,
}: {
  resourceID: string | undefined;
  enabled: boolean;
}):
  | {
      state: 'no-rent-plans' | 'loading';
    }
  | {
      state: 'min-price';
      pricesCount: number;
      minPrice: Money;
    } => {
  const { data: rentPlans, isLoading } = useRentPlans(enabled ? resourceID : undefined);
  const bestPrice = useMemo(
    () => rentPlans?.data && getAvailableMinPriceFromRents(rentPlans?.data),
    [rentPlans?.data],
  );

  if (isLoading) {
    return {
      state: 'loading',
    };
  }

  if (!rentPlans?.data?.length) {
    return {
      state: 'no-rent-plans',
    };
  }

  if (!bestPrice) {
    return {
      state: 'no-rent-plans',
    };
  }

  return {
    state: 'min-price',
    minPrice: bestPrice.money,
    pricesCount: bestPrice.pricesCount,
  };
};

type BestPriceButtonState =
  | { state: 'loading-products' }
  | { state: 'watch-free' }
  | { state: 'watch-by-subscription' }
  | { state: 'loading-rent-plans' }
  | { state: 'unavailable-content' }
  | { state: 'min-price'; minPrice: Money; pricesCount: number };

/**
 * Хук возвращает текст, который нужно отобразить на кнопке, на странице ресурса
 *
 * Поле `text` показывает сам текст,
 *
 * Поле `from` показывает откуда берется текст (из продуктов или из планов)
 * чтобы при клике понимать по какому роуту идти
 */
export const useBestPriceButtonText = ({
  resourceID,
  intl,
}: {
  resourceID: string | undefined;
  intl: InjectedIntl;
}):
  | {
      from: null;
      text: string;
    }
  | {
      from: 'rents' | 'products';
      text: string;
    } => {
  const [bestPriceState, setBestPriceState] = useState<BestPriceButtonState>({
    state: 'loading-products',
  });

  const productsState = useBestPriceFromProducts({
    resourceID,
    enabled: bestPriceState.state === 'loading-products',
  });
  const rentPlansState = useBestPriceFromRentPlans({
    resourceID,
    enabled: bestPriceState.state === 'loading-rent-plans',
  });

  useEffect(() => {
    if (bestPriceState.state !== 'loading-products') {
      return;
    }
    if (productsState === 'loading') {
      return;
    }

    // Если есть план с eligible_phase.type == trial, значит можно смотреть бесплатно
    if (productsState === 'eligible-trial-exists') {
      setBestPriceState({
        state: 'watch-free',
      });
      return;
    }

    // Если нет плана с eligible_phase.type == trial, значит можно смотреть по подписке
    if (productsState === 'eligible-trial-not-exists') {
      setBestPriceState({
        state: 'watch-by-subscription',
      });
      return;
    }

    // Если нет продуктов, значит нельзя смотреть по подписке
    if (productsState === 'no-products') {
      setBestPriceState({
        state: 'loading-rent-plans',
      });
      return;
    }
  }, [productsState]);

  useEffect(() => {
    if (bestPriceState.state !== 'loading-rent-plans') {
      return;
    }

    if (rentPlansState.state === 'loading') {
      return;
    }

    if (rentPlansState.state === 'no-rent-plans') {
      setBestPriceState({
        state: 'unavailable-content',
      });
      return;
    }

    if (rentPlansState.state === 'min-price') {
      setBestPriceState({
        state: 'min-price',
        minPrice: rentPlansState.minPrice,
        pricesCount: rentPlansState.pricesCount,
      });
      return;
    }
  }, [rentPlansState]);

  const prefix = intl.formatMessage({ id: NAV_LEGEND_PLAY_CHANNEL }) + ' ';

  switch (bestPriceState.state) {
    case 'loading-products':
    case 'loading-rent-plans':
      return {
        from: null,
        text: '...',
      };
    case 'unavailable-content':
      return {
        from: null,
        text: `${prefix}${intl.formatMessage({ id: BY_SUBSCRIPTION })}`,
      };
    case 'watch-free':
      return {
        from: 'products',
        text: `${prefix}${intl.formatMessage({ id: FREE })}`,
      };
    case 'watch-by-subscription':
      return {
        from: 'products',
        text: `${prefix}${intl.formatMessage({ id: BY_SUBSCRIPTION })}`,
      };
    case 'min-price':
      if (bestPriceState.pricesCount === 1) {
        {
          return {
            from: 'rents',
            text: `${prefix}${intl.formatMessage(
              { id: FOR_PRICE },
              {
                price: bestPriceState.minPrice.formatted,
              },
            )}`,
          };
        }
      }
      return {
        from: 'rents',
        text: `${prefix}${intl.formatMessage({ id: FROM })} ${bestPriceState.minPrice.formatted}`,
      };
  }
};
