import ApiClient from '~lib/ApiClient';
import bringingAudioTrackNameToStandard from '~lib/bringingAudioTrackNameToStandard';
import Manifest, { ManifestVideoTrack } from '~typings/Manifest';

import { getDir } from './helpers';


const optionValue = (optionsString: string, optionName): string | null => {
  try {
    const lineOptions = optionsString.split(',') || [];

    const nameParams = lineOptions.find((option) => (option.indexOf(`${optionName}=`) !== -1));

    return (nameParams && nameParams.replace(`${optionName}=`, '')) || null;
  } catch (ignore) {
    return null;
  }
};

const removeQuotes = (str: string): string => (
  str.replace(/"/g, '')
);


const parsing = (manifestTextData: string, dir: string): Manifest => {
  // console.log(manifestTextData);

  try {
    const lines = manifestTextData.replace(/\r\n/g, "\n").split("\n");
    const manifest: Manifest = {
      source: manifestTextData,
      video: [],
      audio: [],
    };
    const video: ManifestVideoTrack[] = [];

    for (let i = 0; i < lines.length; i += 1) {
      const line = lines[i];

      if (line && line.length !== 0 && line.substring(0, 1) === '#') {
        const lineOptions = line.split(':');
        const [type = '', lineOptionsString = null] = lineOptions;

        if (
          type === '#EXT-X-STREAM-INF'
          && lines.length >= (i + 1)
        ) {
          const name = optionValue(lineOptionsString || '', 'RESOLUTION');

          if (name !== null) {
            const [width, height] = name.split('x');
            const isAbsolutePath = (lines[(i + 1)].substring(0, 4) === 'http');
            const url = (isAbsolutePath) ?
              lines[(i + 1)]
              :
              `${dir}${lines[(i + 1)]}`;
            const brandWidth = parseInt(optionValue(lineOptionsString || '', 'BANDWIDTH') || '', 10);
            const group = removeQuotes(optionValue(lineOptionsString || '', 'AUDIO') || '');

            video.push({
              name,
              url,
              brandWidth: (!isNaN(brandWidth)) ? brandWidth : null,
              width: (!isNaN(parseInt(width, 10))) ? parseInt(width, 10) : null,
              height: (!isNaN(parseInt(height, 10))) ? parseInt(height, 10) : null,
              source: [line, url],
              group: (group.length !== 0) ? group : null,
            });
          }
        } else if (type === '#EXT-X-MEDIA') {
          const mediaType = optionValue(lineOptionsString || '', 'TYPE');
          const isAudio = !!(mediaType === 'AUDIO');

          if (isAudio) {
            let name = optionValue(lineOptionsString || '', 'LANGUAGE') || undefined;

            if (name === undefined) {
              name = optionValue(lineOptionsString || '', 'NAME') || undefined;
            }

            if (name !== undefined) {
              name = bringingAudioTrackNameToStandard(removeQuotes(name));
            }

            if (name) {
              let url = removeQuotes(optionValue(lineOptionsString || '', 'URI') || '');

              if (url) {
                const isAbsolutePath = (url.substring(0, 4) === 'http');

                if (!isAbsolutePath) {
                  url = `${dir}/${url}`;
                }

                const group = removeQuotes(optionValue(lineOptionsString || '', 'GROUP-ID') || '');

                manifest.audio?.push({
                  name,
                  url,
                  source: line.replace(/URI="(.*)"/i, `URI="${url}"`),
                  group: (group.length !== 0) ? group : null,
                })
              }
            }
          }
        }
      }
    }
    manifest.video = video.sort((a, b) => {
      if (a.name < b.name) {
        return 1;
      } else if (a.name > b.name) {
        return -1;
      }

      return 0;
    });
    // console.log('manifest:', manifest);

    return manifest;
  } catch (ignore) {
    return {};
  }
};


const parseHLSManifest = async (client: ApiClient, manifestURL: string): Promise<Manifest> => {
  try {
    const { result: manifestTextData, url } = await client.fetchText(manifestURL);
    const dir = getDir(url);

    return parsing(manifestTextData, dir);
  } catch (ignore) {
    return {};
  }
};


const generateHLSManifest = (manifest: Manifest, manifestVideoTrack: ManifestVideoTrack): string | null => {
  try {
    // console.log('manifest:', manifest, 'manifestVideoTrack:', manifestVideoTrack);
    const audio = manifest.audio?.filter(({ group }) => (group === manifestVideoTrack.group))
      .map(({ source }) => source) || [];
    const result = [
      '#EXTM3U',
      '#EXT-X-VERSION:5',
      ...audio,
      ...manifestVideoTrack.source,
    ];
    return (result.join('\n'));
  } catch (ignore) {
    return null;
  }
};


const getBlobVideoString = (file): string | null => {
  try {
    const blob = new Blob([file], {type : 'application/vnd.apple.mpegURL'});

    return (
      (window.URL || window.webkitURL).createObjectURL(blob)
    );
  } catch (ignore) {
    // console.log('ignore:', ignore);
    return null;
  }
}


export {
  generateHLSManifest,
  getBlobVideoString,
}


export default parseHLSManifest;
