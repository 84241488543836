export const requestFrames = (count: number) => {
  return new Promise<void>(res=>{
    requestAnimationFrame(() => {
      if(count <= 1) {
        res();
      } else {
        requestFrames(count - 1).then(res);
      }
    })
  })
}
