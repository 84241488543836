import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';

import isAllowedSinglePageMediaItem from '~lib/allowedMediaItem';
import ItemObject from '~typings/ItemObject';


type Props = Readonly<{
  isMediaItemPageMode: boolean;
  mediaItemPage: {
    id: string | undefined;
    object: ItemObject | undefined;
  };
}>;


const useMediaItemPageInfo = (): Props => {
  const matchItemObject = useRouteMatch<{ itemID: string, itemObject: ItemObject }>('/media-item/:itemObject/:itemID');
  const matchItemObjectWithBreadCrumbs = useRouteMatch<{
    pageID, itemID: string, itemObject: ItemObject
  }>('/page/:pageID/media-item/:itemObject/:itemID');
  const mediaItem = matchItemObject ?
    {
      id: matchItemObject?.params.itemID,
      object: matchItemObject?.params.itemObject,
    }
    :
    {
      id: matchItemObjectWithBreadCrumbs?.params.itemID,
      object: matchItemObjectWithBreadCrumbs?.params.itemObject,
    }
  const isMediaItemPageMode = !!(
    mediaItem?.id
    && mediaItem?.object
    && isAllowedSinglePageMediaItem(mediaItem.object)
  );

  return React.useMemo(() => ({
    mediaItemPage: mediaItem,
    isMediaItemPageMode,
  }), [mediaItem, isMediaItemPageMode]);
};


export default useMediaItemPageInfo;
