/* eslint-disable max-len */
import * as React from 'react';

type Props = {
  className?: string;
  color: string
}

const ForButtonsPause = ({ className, color }: Props) => (
  <svg 
    viewBox="0 0 30 30" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className={ className }
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M8 4C6.89543 4 6 4.89543 6 6V24C6 25.1046 6.89543 26 8 26H11C12.1046 26 13 25.1046 13 24V6C13 4.89543 12.1046 4 11 4H8ZM19 4C17.8954 4 17 4.89543 17 6V24C17 25.1046 17.8954 26 19 26H22C23.1046 26 24 25.1046 24 24V6C24 4.89543 23.1046 4 22 4H19Z" 
      fill={ color }
    />
  </svg>
);

export { ForButtonsPause };
