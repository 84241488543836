import * as React from 'react';

import { PlayButtonState } from '~components/ScenePlayer/Player/controls/PlayPauseButton';
import Stream from '~typings/Stream';

import RewindPointerRender from './RewindPointerRender';
import useNavKeys from './useNavKeys';


type Props = Readonly<{
  isLoading: boolean;
  isFocused: boolean;
  durationInSec: number;
  currentTimeInSec: number;
  percentsWatched: number;
  rewindTime: number | null;
  onSeek: (seekTo: number) => void;
  onKeyPressedToSeek: (seekTo: PlayButtonState.SeekToBackward | PlayButtonState.SeekToForward) => void;
  stream?: Stream;
  audioshowObject: boolean;
}>;


const RewindPointer: React.FC<Props> = (props: Props) => {
  const rewindTime = useNavKeys({
    duration: props.durationInSec,
    currentTime: props.currentTimeInSec,
    isAllowSeek: (props.isFocused && !props.isLoading),
    onSeek: props.onSeek,
    onKeyPressedToSeek: props.onKeyPressedToSeek,
  });

  if (props.rewindTime === null && rewindTime === null) { return null; }

  return (
    <RewindPointerRender
      rewindTime={ props.rewindTime ?? rewindTime! }
      isLoading={ props.isLoading }
      isFocused={ props.isFocused }
      durationInSec={ props.durationInSec }
      currentTimeInSec={ props.currentTimeInSec }
      percentsWatched={ props.percentsWatched }
      stream={ props.stream }
      audioshowObject={ props.audioshowObject }
    />
  );
};


export default React.memo(RewindPointer);
