export enum LoginFormType {
  None = 'none',
  SocialOnly = 'social_only',
  Explicit = 'explicit',
  Implicit = 'implicit',
}


export enum LoginFieldType {
  Username = 'username',
  Phone = 'phone',
  Email = 'email',
}


export enum ConfirmationTypeEmail {
  Code = 'code',
  Link = 'link',
}

export enum ConfirmationTypePhone {
  Sms = 'sms',
  Call = 'call',
}

export type Confirmation = Readonly<{
  email_confirmation?: boolean;
  phone_confirmation?: boolean;
  email_confirmation_type?: ConfirmationTypeEmail;
  phone_confirmation_type?: ConfirmationTypePhone;
  phone_confirmation_number?: string;
}>;


export type FieldsFormat = Readonly<{
  login_min_length?: number;
  password_min_length?: number;
}>;


export type NotificationCheckbox = Readonly<{
  notifications_checkbox_enable: boolean;
  default_value: boolean;
}>;


type StartupConfig = Readonly<{
  login_form: {
    login_form_type: LoginFormType;
    login_field_type?: LoginFieldType;
    confirmation: Confirmation;
    fields_format: FieldsFormat;
    notifications_checkbox?: NotificationCheckbox;
    registration_field_type?: LoginFieldType;
    phone_prefix?: string;
  };
  meta_description?: {
    eula_path?: string;
    privacy_path?: string;
    license_text?: string;
    auth_custom_text?: string;
    registration_custom_text?: string;
  };
}>;


export const STARTUP_CONFIG_DEFAULT_VALUE: StartupConfig = {
  login_form: {
    login_form_type: LoginFormType.None,
    confirmation: {},
    fields_format: {},
  },
};


export default StartupConfig;
