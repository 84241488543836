import cn from 'classnames';
import * as React from 'react';

import { isCardChannel } from '~lib/objectType';
import Card from '~typings/Card';
import * as styles from '~ui/MediaItem/styles.module.css';


type Props = Readonly<{
  item: Card;
  isFocused: boolean;
}>;


const CardItemName: React.FC<Props> = (props: Props) => {
  const name = isCardChannel(props.item) ?
    (props.item as Card).additional_name
    :
    (props.item as Card).name;

  return (
    props.isFocused ?
    (
      <span className={ cn(styles.name, styles.focusedName) }>
        {name}
      </span>
    ) : (
      <span className={ styles.name }>
        {name}
      </span>
    )
  );
};


export default CardItemName;
