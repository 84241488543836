/**
 * Метод возвращает смещение по Y,
 * для компонента у которого задано смещение через `translate3d(0, 0, 0)`
 */
export const getElementTransformY = (elt: HTMLElement) => {
  const sliderTranslateYString = elt.style.getPropertyValue('transform')?.split(',')?.[1]?.trim();
  if (!sliderTranslateYString) {
    return null;
  }
  const sliderTranslateYParsed = parseFloat(sliderTranslateYString);

  if (isNaN(sliderTranslateYParsed)) {
    return null;
  }

  return sliderTranslateYParsed;
};
