import { filter } from 'lodash';

import { isInTheFuture, isInThePast } from '~lib/datetime-utils';
import { isCatchupAvailable } from '~lib/eventsHelper';
import Blackout from '~typings/Blackout';
import Channel from '~typings/Channel';
import Event from '~typings/Event';

export type FilterEventProps = Readonly<{
  withoutEventID: string;
  maxCount: number,
  onlyCatchups: boolean,
  events?: Event[],
  channel?: Channel,
  blackouts?: Blackout[]
}>;

export const filterEvents = ({
  withoutEventID,
  maxCount = 3,
  onlyCatchups,
  events,
  channel,
  blackouts,
}: FilterEventProps) => {
  if (onlyCatchups) {
    if (!channel) return [];

    return filter(
      events,
      event => (
        event.id !== withoutEventID &&
        isInThePast(event.end_at) && isCatchupAvailable(event, channel, blackouts)
      )
    ).slice(-maxCount);
  } else {
    return filter(
      events,
      (event) => (event.id !== withoutEventID && isInTheFuture(event.end_at))
    ).slice(0, maxCount);
  }
};
