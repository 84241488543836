import focuser, { FocuserKeyHandler } from '@focuser';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Typography } from '~app/ui-kit/Typography';
import { useApp } from '~components/Provider/App';
import { useDispatchLaunchDone } from '~hooks/useDispatchLaunchDone';
import { makeReadyChildsMapperHook } from '~lib/focuserUtils/readyChildsMapper';
import { NO_CONTENT_WITH_THIS_PERSON } from '~localization';
import { useCanFocusOnDescription } from '~newapp/components/CardCollectionContent/CardCollectionContent.hooks';
import Card from '~typings/Card';
import Person from '~typings/Person';
import ErrorIcon from '~ui/ErrorText/icon';
import { SliderVerticable } from '~ui/SliderVerticable';

import { ActorDescriptionPopup } from './ActorPageHeader/ActorDescription/ActorDescriptionPopup';
import { ActorPageHeader } from './ActorPageHeader/ActorPageHeader';
import { AudioShowSlider } from './ActorPageSliders/AudioShowsSlider/AudioShowsSlider';
import { MoviesAndSeriesSlider } from './ActorPageSliders/MoviesAndSeriasSlider/MoviesAndSeriesSlider';
import { ProgramEventSlider } from './ActorPageSliders/ProgramEventSlider/ProgramEventSlider';
import * as styles from './styles.module.css';


export enum FocusOn {
  actorDescription = 'actor-description',
  moviesAndSeriesSlider = 'movies-and-series-slider',
  programEventsSlider = 'program-events-slider',
  audioShowsSlider = 'audio-shows-slider',
}

type Props = {
  dataPerson: Person;
  dataMoviesAndSeries: Card[];
  dataProgramEvents: Card[];
  dataAudioShows: Card[];
}


const ActorPage:React.FC<Props> = ({
  dataPerson,
  dataMoviesAndSeries,
  dataProgramEvents,
  dataAudioShows,
}: Props) => {
  const history = useHistory();

  const { isLaunchingDone } = useApp();
  useDispatchLaunchDone();

  const [showActorDescriptionPopup, setShowActorDescriptionPopup] = React.useState<'close' | 'open'>('close');

  const [useReadyChildsHook] = React.useState(() => makeReadyChildsMapperHook({
    actorDescription: false,
    moviesAndSeriesSlider: dataMoviesAndSeries.length > 0,
    programEventsSlider: dataProgramEvents.length > 0,
    audioShowsSlider: dataAudioShows.length > 0,
  }));

  const {
    focusOn,
    setFocusOn,
    nextChildToNavigate,
    prevChildToNavigate,
    setIsActordescriptionReady,
    readyChilds,
  } = useReadyChildsHook(null);

  const handleDescriptionReadyToFocus = () => {
    setIsActordescriptionReady(true);
    
    setFocusOn('actordescription');
  };

  const { descriptionTextRef } = useCanFocusOnDescription({
    canCheck: !!isLaunchingDone,
    onReady: handleDescriptionReadyToFocus,
  });

  const navigateOnNext: FocuserKeyHandler = (evt) => {
    if (nextChildToNavigate) {
      setFocusOn(nextChildToNavigate);
      evt.stop();
      evt.stopNativeEvent();
    }
  };

  const navigateOnPrev: FocuserKeyHandler = (evt) => {
    if (prevChildToNavigate) {
      setFocusOn(prevChildToNavigate);
      evt.stop();
      evt.stopNativeEvent();
    }
  };

  const handleBack: FocuserKeyHandler = (evt) => {
    evt.stop();
    evt.stopNativeEvent();
    history.goBack();
  }

  const focusedIndex = React.useMemo(() => {
    let availableElementsArr:string[] = [];

    for(const child in readyChilds) {
      // Пушим child с описанием актера в массив, чтобы он был всегда,
      // даже если его нельзя взять в фокус
      if(child === 'actordescription') {
        availableElementsArr.push(child);
        continue;
      }
      if(readyChilds[child]) {
        availableElementsArr.push(child)
      }
    }

    const focusedIndex = Math.max(availableElementsArr.indexOf(focusOn), 0);

    // Проверка нужна, чтобы сделать скролл до начала страницы(показать описание актера).
    // Это нужно когда мы находимся на втором или третьем слайдере по счету.
    // Если эту проверку не сделать, до описания актера мы не сможем подняться, 
    // фокус будет оставаться на первом слайдере
    if(focusedIndex <= 1) {
      return 0;
    }

    return focusedIndex;
  }, [focusOn])
  
  const renderAlertBlock = () => {
    if(
      dataMoviesAndSeries.length === 0 
      && dataProgramEvents.length === 0  
      && dataAudioShows.length === 0 
    ) {
      return (
        <div className={ styles.errorBlock }>
          <ErrorIcon className={ styles.errorIcon }/>
          <Typography variant='subtitle1_medium' className={ styles.errorText }>
            <FormattedMessage id={ NO_CONTENT_WITH_THIS_PERSON } />
          </Typography>
        </div>
      )
    }
  }

  if(showActorDescriptionPopup === 'open') {
    return (
      <ActorDescriptionPopup 
        isFocused={ showActorDescriptionPopup === 'open' }
        person={ dataPerson }
        text={ dataPerson?.description }
        onBack={ () => setShowActorDescriptionPopup('close') } 
      />
    )
  }

  
  return (
    <focuser.FocusableBlock
      isFocused
      onKeyReturn={ handleBack }
      returnButtonType={ 'back' }
      className={ styles.page }
      onKeyDown={ navigateOnNext }
      onKeyUp={ navigateOnPrev }
      noNeedForceFocus
    >
      <SliderVerticable 
        focusedIndex={ focusedIndex }
        wrapperClassName={ styles.sliderWrapper }
      >
        <ActorPageHeader 
          isFocused={ focusOn === 'actordescription' }
          dataPerson={ dataPerson }
          onForceFocus={ () => readyChilds['actordescription'] && setFocusOn('actordescription') }
          descriptionTextRef={ descriptionTextRef }
          setShowActorDescriptionPopup={ setShowActorDescriptionPopup }
        />
        
        { renderAlertBlock() }

        <MoviesAndSeriesSlider 
          isFocused={ focusOn === 'moviesandseriesslider' }
          dataMoviesAndSeries={ dataMoviesAndSeries }
          onForceFocus={ () => setFocusOn('moviesandseriesslider') }
        />

        <ProgramEventSlider
          isFocused={ focusOn === 'programeventsslider' }
          dataProgramEvents={ dataProgramEvents }
          onForceFocus={ () => setFocusOn('programeventsslider') }
        />

        <AudioShowSlider
          isFocused={ focusOn === 'audioshowsslider' }
          dataAudioShows={ dataAudioShows }
          onForceFocus={ () => setFocusOn('audioshowsslider') }
        />
      </SliderVerticable>
    </focuser.FocusableBlock>
  )
}

export default ActorPage;