import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Loader from '~components/LightLoader/Loader';
import CatchBack from '~components/PageManager/CatchBack';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { isChannelURL } from '~lib/url';


const PlayerLoadScreen: React.FC = () => {
  const history = useHistory();
  const { flush } = usePlayerStateActions();

  const handleBack = () => {
    flush();

    if (isChannelURL(history.location.pathname)) {
      history.goBack();
    }
  };

  return (
    <>
      <Loader />
      <CatchBack
        action={ handleBack }
        triggers={ [] }
      />
    </>
  );
};


export default PlayerLoadScreen;
