import focuser, { FocuserKeyHandler } from '@focuser';
import React, { useCallback, useRef, useState } from 'react';

import { makeReadyChildsMapperHook } from '~lib/focuserUtils/readyChildsMapper';
import Channel from '~typings/Channel';

import { type SliderImperativeRefType,EPGChannels } from '../EPGChannels';
import { EPGEventDetails } from '../EPGEventDetails';
import { EPGEventsListLoader } from '../EPGEventsList';
import { useChildrenFocusStore, useRememberFocusState, useRestoreFocusState } from './EPGPageLoaded.hooks';
import * as styles from './EPGPageLoaded.module.css';

export type Props = {
  /**
   * Массив каналов (ожидает, что в массиве есть как минимум 1 элемент)
   */
  channels: Channel[];
};

const EPGPageLoaded$: React.FC<Props> = ({ channels }) => {


  const restoredState = useRestoreFocusState({ channels });

  const [useReadyChildsHook] = useState(() =>
    makeReadyChildsMapperHook({
      /**
       * Каналы
       */
      channels: true,
      /**
       * Список событий
       */
      events: Boolean(restoredState?.globalFocusedState.eventsState),
      /**
       * Детали события
       */
      details: true,
    }),
  );

  const {
    setIsEventsReady,
    readyChilds,
    focusOn,
    setFocusOn,
    nextChildToNavigate,
    prevChildToNavigate,
  } = useReadyChildsHook(restoredState?.epgFocusOn ||'channels');



  const onKeyLeft: FocuserKeyHandler = (e) => {
    if (prevChildToNavigate) {
      setFocusOn(prevChildToNavigate);
      e.stop();
    }
  };

  const onKeyRight: FocuserKeyHandler = (e) => {
    if (nextChildToNavigate) {
      setFocusOn(nextChildToNavigate);
      e.stop();
    }
  };

  const onChannelsChange = useCallback(() => {
    setIsEventsReady(false);
  }, []);

  const handleEventsAndDetailsIsReady = useCallback(() => {
    setIsEventsReady(true);
  }, []);


  const { eventsSliderState, setEventsSliderState, channelsSliderState, setChannelsSliderState, store } =
    useChildrenFocusStore({
      channels,
      onChannelsChange,
      initialState: restoredState?.globalFocusedState
    });

  const channelsSliderRef: SliderImperativeRefType = useRef();

  const {
    beforeChannelLeave,
    beforeEventListLeave,
    beforeDetailsLeave,
  } = useRememberFocusState({ globalFocusedStore: store, channelsSliderRef });

  return (
    <focuser.FocusableBlock
      isFocused
      className={ styles.EPGPage }
      noNeedForceFocus
      onKeyLeft={ onKeyLeft }
      onKeyRight={ onKeyRight }
    >
      <focuser.FocusableBlock
        isFocused={ focusOn === 'channels' }
        onForceFocus={ () => setFocusOn('channels') }
        className={ styles.channelsSlider }
      >
        <EPGChannels
          channels={ channels }
          focusedState={ channelsSliderState }
          setFocusedState={ setChannelsSliderState }
          beforeLeave={ beforeChannelLeave }
          sliderRef={ channelsSliderRef }
          initialOffset={ restoredState?.channelsSliderOffset || undefined }
        />
      </focuser.FocusableBlock>
      <focuser.FocusableBlock
        isFocused={ focusOn === 'events' }
        onForceFocus={ () => setFocusOn('events') }
        className={ styles.eventsList }
      >
        <EPGEventsListLoader
          channel={ channelsSliderState.focusedChannel }
          focusedState={ eventsSliderState }
          setFocusedState={ setEventsSliderState }
          handleEventsIsReady={ handleEventsAndDetailsIsReady }
          isEventsReady={ readyChilds['events'] }
          beforeLeave={ beforeEventListLeave }
        />
      </focuser.FocusableBlock>
      <focuser.FocusableBlock
        isFocused={ focusOn === 'details' }
        onForceFocus={ () => setFocusOn('details') }
      >
        <EPGEventDetails
          channel={ channelsSliderState.focusedChannel }
          programEvent={ eventsSliderState?.focusedEvent }
          isFocusOnChannels={ focusOn === 'channels' }
          beforeLeave={ beforeDetailsLeave }
        />
      </focuser.FocusableBlock>
    </focuser.FocusableBlock>
  );
};

/**
 * Внутренняя часть EPG страницы, когда каналы загружены
 */
export const EPGPageLoaded = React.memo(EPGPageLoaded$);
