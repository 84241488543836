import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ActionType, useAppAction } from '~components/Provider/App';
import useMediaItem from '~hooks/fetch/useMediaItem';
import { isAudioShow, isChannel, isMovie, isProgramEvent, isSeries } from '~lib/objectType';
import ItemObject from '~typings/ItemObject';

import MediaItemDetailsCommon from './MediaItemDetailsCommon';
import MediaItemDetailsEvent from './MediaItemDetailsEvent';



type Props = Readonly<{
  intl: InjectedIntl;
}>;


const MediaItemDetails: React.FC<Props> = (props: Props) => {
  const appAction = useAppAction();
  const { itemId, itemObject } = useParams<{ itemId: string, itemObject: ItemObject }>();

  const mediaItemQuery = useMediaItem(itemId, itemObject);
  const mediaItem = mediaItemQuery.data?.data;

  React.useEffect(() => {
    appAction({
      type: ActionType.SetIsLaunchingDone,
      payload: { isLaunchingDone: !!mediaItem },
    });
  }, [mediaItem?.id]);

  if (
    !mediaItem
    || !mediaItem.object
    || (
      !isAudioShow(mediaItem) &&
      !isMovie(mediaItem) &&
      !isSeries(mediaItem) &&
      !isChannel(mediaItem) &&
      !isProgramEvent(mediaItem)
    )
  ) { return null; }

  if(isProgramEvent(mediaItem)){
    return <MediaItemDetailsEvent
      item={ mediaItem }
      intl={ props.intl }
    />
  }

  return <MediaItemDetailsCommon
    item={ mediaItem }
    />
};

export default injectIntl(MediaItemDetails);
