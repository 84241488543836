import * as React from 'react'
import { FormattedMessage } from 'react-intl';

import { usePlatform } from '~components/Provider/Platform';
import { useConfig } from '~global';
import { CONFIRM_EXIT_TEXT, NO, YES } from '~localization';
import NavigationDirection from '~typings/NavigationDirection';
import Button from '~ui/button';
import { Popup } from '~ui/Popup';

import * as styles from './styles.module.css';


enum FocusOn {
  NoButton = 'NoButton',
  YesButton = 'YesButton',
}

type Props = {
  isPopupOpen: boolean;
  closePopup: () => void;
};

const ExitPopup = ({ isPopupOpen, closePopup }: Props) => {
  const { platform } = usePlatform();
  const { smartTV } = useConfig();
  const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.NoButton)

  const onLeaveHandler = (direction: NavigationDirection) => {
    if (
      direction === NavigationDirection.Right
      ) {
      setFocusOn(FocusOn.YesButton);
    } else if (
      direction === NavigationDirection.Left
    ) {
      setFocusOn(FocusOn.NoButton);
    }
  };

  const handleNoButton = () => {
    closePopup();
  };

  const handleYesButton = (event?: React.SyntheticEvent) => {
    platform.exit(event);

    // in webos env exit method pushes the application to background and opens Recent List
    // so we should render actual app content instead of Exit Popup
    if (__PLATFORM__ === __PLATFORM_NAME_WEBOS__) {
      closePopup();
    }
  };

  if (!isPopupOpen) { return null; }

  return (
    <Popup semiTransporent /* onClose={ togglePopup } */>
      <div className={ styles.container } >
        <div className={ styles.messageText }>
          <FormattedMessage
            id={ CONFIRM_EXIT_TEXT }
            values={ { product: smartTV.projectTitle } }
          />
        </div>
        <Button
          className={ styles.button }
          onLeave={ onLeaveHandler }
          isFocused={ focusOn === FocusOn.NoButton }
          onClick={ handleNoButton }
        >
          <FormattedMessage id={ NO } />
        </Button>
        <Button
          className={ styles.button }
          onLeave={ onLeaveHandler }
          isFocused={ focusOn === FocusOn.YesButton }
          onClick={ handleYesButton }
        >
          <FormattedMessage id={ YES } />
        </Button>
      </div>
    </Popup>
  );
};


export default React.memo(ExitPopup);
