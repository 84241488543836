import ApiClient from '~lib/ApiClient';
import { fetchRetryer, FetchRetryerParams } from '~lib/fetch';
import AppConfig from '~typings/AppConfig';
import GenericConfig from '~typings/GenericConfig';
import ResponseSingle from '~typings/ResponseSingle';
import { ServerGeneratedConfig } from '~typings/ServerGeneratedConfig';
import SmartTVConfig from '~typings/SmartTVConfig';
import StartupConfig from '~typings/StartupConfig';

enum ConfigType {
  SmartTV = 'smarttv',
  Startup = 'startup',
  Generic = 'generic',
  ServerGenerated = '__server_generated',
}

const fetch = async <T>(type: ConfigType, client: ApiClient): Promise<T> => {
  const { data } = await client.get<ResponseSingle<T>>(`/v1/client_configs/${type}`);
  return data;
};

export const fetchAppConfigs = async (client: ApiClient, retryParams: FetchRetryerParams): Promise<AppConfig> => {
  const fetchConfigWithRetry = async <T>(type: ConfigType) => {
    return fetchRetryer(() => fetch<T>(type, client), retryParams);
  };

  const [SmartTVConfig, StartupConfig, GenericConfig, ServerGeneratedConfig] = await Promise.all([
    fetchConfigWithRetry<SmartTVConfig>(ConfigType.SmartTV),
    fetchConfigWithRetry<StartupConfig>(ConfigType.Startup),
    fetchConfigWithRetry<GenericConfig>(ConfigType.Generic),
    fetchConfigWithRetry<ServerGeneratedConfig>(ConfigType.ServerGenerated),
  ]);

  return {
    smartTV: SmartTVConfig,
    startup: StartupConfig,
    generic: GenericConfig,
    serverGenerated: ServerGeneratedConfig,
  };
};

