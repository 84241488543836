import * as cn from 'classnames';
import * as React from 'react';

import CheckIcon from '~ui/Icon/icons/Check';

import * as styles from './styles.module.css';

type RadioButtonProps = {
  isChecked: boolean;
  isFocused: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  isChecked,
  isFocused,
  className,
  children,
  onClick,
  style = {},
}) => {
  return (
    <div
      className={ cn(styles.wrapper, className, {
        'focusedNavigationNode': isFocused,
        [styles.focused]: isFocused,
      }) }
      style={ style }
      onClick={ onClick }
    >
      <span
        className={ cn(styles.iconWrapper, {
          [styles.focused]: isFocused,
        }) }
      >
        <CheckIcon
          className={ cn(styles.icon, {
            [styles.checked] : isChecked,
          }) }
        />
      </span>
      {children}
    </div>
  );
};

export default RadioButton;
