import { guid } from './util';
import warning from './util/warning';

export default class Base {
  constructor(options = {}) {
    this.options = options;
    this.cache = {};
  }

  init() {
    return Promise.resolve();
  }

  getDeviceInfo() {
    throw new Error('getDeviceInfo method is not implemented');
  }

  /**
   * @deprecated
   */
  getDeviceId() {
    if (__BUILD_ENV__ !== 'production') {
      warning(
        'Please use "getDeviceInfo().deviceId" instead of getDeviceId().' +
          'In next release method getDeviceId will be removed.'
      );
    }
    return guid();
  }

  checkInternetConnection() {
    if (navigator && navigator.onLine !== undefined) {
      return navigator.onLine;
    }
    return true;
  }

  /**
   * @deprecated
   */
  getDeviceModel() {
    if (__BUILD_ENV__ !== 'production') {
      warning(
        'Please use "getDeviceInfo().model" instead of getDeviceModel().' +
          'In next release method getDeviceModel will be removed.'
      );
    }
    return 'undefined';
  }

  /**
   * @deprecated
   */
  getVendor() {
    if (__BUILD_ENV__ !== 'production') {
      warning(
        'Please use "getDeviceInfo().vendor" instead of getVendor().' +
          'In next release method getVendor will be removed.'
      );
    }
    return 'undefined';
  }

  get(id, fromCache = true) {
    if (this.cache[id] && fromCache) return this.cache[id];

    const elem = document.getElementById(id);

    if (!elem) {
      throw new Error(`Can't find element by ${id}`);
    }

    this.cache[id] = elem;

    return this.cache[id];
  }

  getKeyCodes() {
    throw new Error('Platform is not implement method getKeyCodes');
  }

  back(event) {
    if (!event) {
      throw new Error('Need Event object for call back function');
    }
    event.preventDefault();
    event.stopPropagation();
    window.history.back();
  }

  getDeviceFriendlyName() {
    return 'smarttv-device';
  }

  exit(event) {
    if (!event) {
      throw new Error('Need Event object for call back function');
    }
    event.preventDefault();
    event.stopPropagation();
    window.close();
  }
}
