import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import shallow from 'zustand/shallow';

import {getTiledDimension} from "~components/TiledView/component.helpers";
import useCollection from '~hooks/fetch/useCollection';
import useCollectionItems, { getLastFetchedPage} from '~hooks/fetch/useCollectionItems';
import usePagination from '~hooks/usePagination';
import useFilters from '~stores/Filters';
import useNavigationOnLayer from '~stores/LayerNavigation';
import ImageType from '~typings/ImageType';

import CollectionPageWrapper from './Wrapper';


export default function CollectionPage() {
  const {collectionID} = useParams<{ collectionID: string }>();

  const layerID = `collection_page-${collectionID}`;
  const focusedIndex = useNavigationOnLayer(state => state.getFocusedIndexOnLayer(layerID));

  const {data: collectionData} = useCollection(collectionID);

  const selectedFilters = useFilters(state => ({
    quickFilters: state.quickFilters?.[layerID],
    dropdownFilters: state.dropdownFilters?.[layerID],
  }), shallow);

  const [page, setPage] = React.useState<number>(getLastFetchedPage(collectionID));

  const handleNeedFetchNextPage = (nextPage: number): void => {
    if (nextPage > page) {
      setPage(nextPage);
    }
  };

  const {isLoading, data} = useCollectionItems(collectionID, selectedFilters, page);

  const dimension = getTiledDimension(data);

  usePagination({
    total: data?.meta.pagination.total || 0,
    currentPage: page,
    currentCount: data?.data.length || 0,
    focusedIndex: focusedIndex + (
      (dimension === ImageType.LOGO || dimension === ImageType.BANNER) ? 4 : 6
    ),
    onNextPage: handleNeedFetchNextPage,
  });

  useEffect(() => void setPage(0), [collectionID, selectedFilters]);

  return (
    <CollectionPageWrapper
      layerID={ layerID }
      title={ collectionData?.data.name }
      items={ data }
      dimension={ dimension }
      filters={ collectionData?.data.search_filters_group?.items }
      isLoading={ isLoading }
      description={ collectionData?.data?.description }
    />
  );
};
