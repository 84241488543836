import { QueryKey,useQuery } from 'react-query';

import { useClient, useConfig } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import { makeSplitRequestByLimit } from '~lib/fetch';
import RentPlan from '~typings/RentPlan';
import ResponseMany from '~typings/ResponseMany';

import { fetchOptionsBasic,FetchType, fetchURLs } from '../fetch-parameters';
import { getLimitFromConfig } from '../utils';


const PREFIX_OF_A_COMPOSITE_KEY = 'rent_plans';

const getKey = (resourceID?: string): QueryKey => [PREFIX_OF_A_COMPOSITE_KEY, resourceID];


const fetch = async (client: ApiClient, limit: number, accessToken: string | null, resourceID?: string) => {
  if (!resourceID) { throw new Error('resourceID missing') }

  const params = {
    ...fetchOptionsBasic[FetchType.RentPlans],
    access_token: accessToken,
    'filter[resource_id_in]': resourceID,
  }


  return await makeSplitRequestByLimit<RentPlan[]>({
    client,
    url: fetchURLs[FetchType.RentPlans],
    params,
    limit
  });
};


const useRentPlans = (resourceID?: string) => {
  const queryKey = getKey(resourceID);
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(useConfig());

  const client = useClient();

  return useQuery<ResponseMany<RentPlan[]>>({
    queryKey,
    queryFn: () => fetch(client, limit, accessToken, resourceID),
    enabled: !!(accessToken && resourceID),
    staleTime: 0,
    cacheTime: 0,
  });
};

export default useRentPlans;
