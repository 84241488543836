import focuser, { FocuserKeyHandler, useFocuserContext, useFocuserThrottledHandler } from '@focuser';
import { uniqBy } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import usePurchases from '~hooks/fetch/usePurchases/usePurchases';
import { useLocationSearchState } from '~hooks/useLocationSearchState';
import { usePaginationOneLine } from '~hooks/usePagination';
import { PAYMENT_HISTORY_EMPTY } from '~localization';
import { SpinnerFullScreen } from '~newapp/globalBlocks/spinner';
import Purchase from '~typings/Purchase';
import { SliderVerticableVirtual } from '~ui/SliderVerticable';

import { PurchaseItem } from './PurchaseItem/PurchaseItem';
import * as styles from './styles.modules.css';



export const Purchases: React.FC = () => {
  const { query: paymentsQuery, parsedData: purchases, isLoading } = usePurchases();
  const { isFocused } = useFocuserContext();

  const purchasedItems = React.useMemo(() =>
      uniqBy<Purchase>((purchases || []), 'resource.id'),
    [purchases]
  );

  const {
    parsedState,
    setNewState
  } = useLocationSearchState<number>({
    pathInSearch: 'purchase_focused_index'
  });

  const [focusedPurchase, setFocusedPurchase] = React.useState<number>(parsedState ?? 0);

  usePaginationOneLine({
    currentFetchedCount: purchasedItems.length,
    focusedElemIdx: focusedPurchase,
    minimalPrefetchedCount: 4,
    updates: [paymentsQuery],
    fetchMoreData: () => {
      if (!paymentsQuery.isFetchingNextPage && paymentsQuery.hasNextPage) {
        paymentsQuery.fetchNextPage();
      }
    },
  });

  const handleUp: FocuserKeyHandler = (evt) => {
    if(focusedPurchase > 0) {
      setFocusedPurchase(focusedPurchase - 1);
      evt.stop();
    }
  }

  const handleDown: FocuserKeyHandler = (evt) => {
    if(focusedPurchase !== (purchasedItems.length - 1)) {
      setFocusedPurchase(focusedPurchase + 1);
      evt.stop();
    }
  }

  const onKeyUpThrottled = useFocuserThrottledHandler(handleUp);
  const onKeyDownThrottled = useFocuserThrottledHandler(handleDown);

  const renderPurchase = React.useCallback((i: number) => {
    const purchase = purchasedItems[i];

    return (
      <PurchaseItem
        key={ purchase.id }
        purchase={ purchase }
        isFocused={ isFocused && i === focusedPurchase }
        index={ i }
        setLastFocusedIdx={ setNewState }
        setFocusedPurchase={ setFocusedPurchase }
      />
    );
  }, [focusedPurchase, purchasedItems, isFocused]);

  if(isLoading) {
    return (
      <focuser.Isolation>
        <SpinnerFullScreen/>
      </focuser.Isolation>
    )
  }


  if (!purchasedItems.length) {
    return (
      <Typography variant="h6_medium">
        <FormattedMessage id={ PAYMENT_HISTORY_EMPTY } />
      </Typography>
    );
  }



  return (
    <focuser.FocusableBlock
      isFocused
      onKeyDown={ onKeyDownThrottled }
      onKeyUp={ onKeyUpThrottled }
      noNeedForceFocus
    >
      <SliderVerticableVirtual
        wrapperClassName={ styles.purchasesSlider }
        focusedIndex={ focusedPurchase }
        childsCount={ purchasedItems.length }
        renderChild={ renderPurchase }
        itemHeight={ 10.938 }
        maxInDOM={ 8 }
      />
    </focuser.FocusableBlock>
  );
};
