import * as React from 'react';

import { useMenu } from '~components/Provider/Menu';
import useNavigationByKeys from '~hooks/useNavigation';
import NavigationDirection from '~typings/NavigationDirection';
import IconButton from '~ui/IconButton';

type Props = Readonly<{
  items: string[],
  isFocused: boolean;
  selectedId?: number;
  ignoreOpenedPopup?: boolean;
  onSelect: (id: number) => void;
  onLeave?: (dir: NavigationDirection) => void;
  className?: string;
}>;

export const SelectList: React.FC<Props> = (props: Props) => {
  const menu = useMenu();
  const isPopupOpened = !props.ignoreOpenedPopup && (menu.isMenuOpened || menu.isPopupOpened);
  const [focusedIndex, setFocusedIndex] = React.useState<number>(props.selectedId ?? 0);

  const handleKeyNavigate = (direction): void => {
    if (direction === NavigationDirection.Up) {
      if (focusedIndex === 0) {
        props.onLeave && props.onLeave(direction);
      } else {
        setFocusedIndex(oldValue => oldValue -1);
      }
    } else if (direction === NavigationDirection.Down) {
      if (focusedIndex === (props.items.length - 1)) {
        props.onLeave && props.onLeave(direction);
      } else {
        setFocusedIndex(oldValue => oldValue + 1);
      }
    }
  };

  useNavigationByKeys({
    isMounted: isPopupOpened && props.isFocused,
    onNavigation: handleKeyNavigate,
  }, [props.items, props.onLeave, focusedIndex]);

  return (
    <div className={ props.className }>
      {
        props.items.map((item: string, id: number) => (
          <IconButton
            onClick={ () => props.onSelect(id) }
            isFocused={ props.isFocused && focusedIndex === id }
            key={ `select_list_item_${id}` }
          >
            { item.toUpperCase() }
          </IconButton>
        ))
      }
    </div>
  )
};
