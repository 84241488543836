import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { Frame_channels_cards_16_9 } from './Frame_channels_cards_16_9';

type Props = {
  isFocused: boolean;
  focuserClassName?: string;
  cardClassName?: string;
  urlTemplate: string;
} & Omit<FocusableBlockProps, 'className' | 'isLastBlock'>;

export const ChannelCardFocuser: React.FC<Props> = ({
  isFocused,
  focuserClassName,
  cardClassName,
  urlTemplate,
  ...focuserProps
}) => {
  return (
    <focuser.FocusableBlock
      { ...focuserProps }
      isFocused={ isFocused }
      className={ focuserClassName }
      isLastBlock
    >
      {({ isFocused }) => (
        <Frame_channels_cards_16_9
          className={ cardClassName }
          isFocused={ isFocused }
          url={ urlTemplate }
        />
      )}
    </focuser.FocusableBlock>
  );
};
