import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NAVIGATION_BACK, SOMETHING_WENT_WRONG } from '~lib/localizator/src/dictionaries';
import GeoRestrictError from '~screens/ErrorScreen/GeoRestrictError/GeoRestrictError';

import ApiClientError from './ApiClient';
import { DefaultError } from './DefaultError';

type Props = {
  intl: InjectedIntl;
};

const ErrorScreen: React.FC<Props> = (props:Props) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={ `${path}/geo-restrict` }>
        <GeoRestrictError />
      </Route>
      <Route path={ `${path}/api-client` }>
        <ApiClientError />
      </Route>
      <Route exact path={ path }>
        <DefaultError 
          errorText={ props.intl.formatMessage({ id: SOMETHING_WENT_WRONG }) }
          buttonText={ props.intl.formatMessage({ id: NAVIGATION_BACK }) }
        />
      </Route>
    </Switch>
  );
};

export default injectIntl(ErrorScreen);
