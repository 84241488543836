import * as React from 'react';

import { valueInPixelsByWidth } from '~lib/SizesInPX';

import * as  styles from './styles.module.css';


type Props = Readonly<{
  focusedIndex: number;
  children: any;
}>;


const epgItemHeight = 8.125;


const EPGPageChannelsListScrollWrapper: React.FC<Props> = (props: Props) => {
  // const blockScrollableYRef = React.useRef<HTMLDivElement>(null);
  //
  // const handleSetTransform = React.useCallback((index: number) => {
  //   if (blockScrollableYRef.current !== null) {
  //     const transform = `translate3d(0, ${valueInPixelsByWidth(24.375 - (index * epgItemHeight))}px, 0)`;
  //
  //     blockScrollableYRef.current.style.transform = transform;
  //     blockScrollableYRef.current.style.webkitTransform = transform;
  //   }
  // }, []);
  // const callTransformWithCorrectFocusedIndex = () => {
  //   handleSetTransform(props.focusedIndex);
  // }
  //
  // React.useEffect(callTransformWithCorrectFocusedIndex, [props.focusedIndex]);

  const transform = `translate3d(0, ${valueInPixelsByWidth(24.375 - (props.focusedIndex * epgItemHeight))}px, 0)`;

  return (
    <div
      // ref={ blockScrollableYRef }
      style={ {
        transform,
        WebkitTransform: transform,
      } }
      className={ styles.channelsScrollableBlock }
    >
      { props.children }
    </div>
  );
};


export default React.memo(EPGPageChannelsListScrollWrapper);
