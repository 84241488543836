import * as React from 'react';

import getImageSRC from '~lib/MediaItemImageSRC';
import Channel from '~typings/Channel';
import ImageType from '~typings/ImageType';
import MediaItemDimension from '~typings/MediaItemDimension';

import { hasChannelCardWide } from './../helpers';
import ChannelContentImageLogo from './ImageLogo';
import ChannelContentImagePreview from './ImagePreview';


type Props = Readonly<{
  channel: Channel;
  imageURL: string | null;
  dimension: MediaItemDimension;
}>;


const ChannelContent: React.FC<Props> = (props: Props) => {
  const isLogoDimension = React.useMemo(() => (
    props.dimension === ImageType.LOGO
    || props.dimension === ImageType.LOGO_SMALL
  ), [props.dimension]);
  const withChannelCardWide = isLogoDimension && hasChannelCardWide(props.channel?.images || []);
  const imagePreviewURL = (isLogoDimension && props.channel) ?
    getImageSRC({
      dimension: ImageType.BANNER,
      mediaItem: props.channel,
    })
    :
    null;

  const preview = (imagePreviewURL) ?
    <ChannelContentImagePreview
      src={ imagePreviewURL }
    />
    :
    null;
  const logo = () => {
    if (withChannelCardWide) {
      const channelCardWideImageURL = getImageSRC({
        dimension: ImageType.CHANNEL_CARD_WIDE,
        mediaItem: props.channel,
      });

      if (channelCardWideImageURL) {
        return (
          <ChannelContentImageLogo
            isChannelCardWide
            src={ channelCardWideImageURL }
          />
        );
      }
    }

    if (isLogoDimension && props.imageURL) {
      return (
        <ChannelContentImageLogo
          src={ props.imageURL }
        />
      );
    }

    return null;
  };

  return (
    <>
      { preview }
      { logo() }
    </>
  );
};


export default React.memo(ChannelContent);
