import * as cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';


type Props = Readonly<{
  buttonText: string;
  isFocused: boolean;
  focusedIndex: number;
}>;


const SkipIntervalsButton: React.FC<Props> = (props: Props): JSX.Element => {

  return (
    <div
      className={ cn(styles.buttonWrapper, {
        [styles.focused]: props.isFocused,
        'focusedNavigationNode': props.isFocused,
      }) }
    >
      <div 
        className={ cn(styles.buttonText, {
        [styles.focusedButton]: props.isFocused,
        }) }
      >
        {props.buttonText.toUpperCase()}
      </div>
    </div>
  )
}

export default SkipIntervalsButton;