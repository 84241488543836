import { FocuserKeyHandler } from '@focuser';
import { useEffect, useState } from 'react';

import { ProcedureType } from '~components/PinCodeManager';
import { useLocationSearchState } from '~hooks/useLocationSearchState';

import { SECURITY_CREATE_PIN_SEARCH_FIELD } from '../../../constants';

export const useInitialPinCodeManagerProcedure = () => {
  const { parsedState: isCreatePinMode, clearState: clearIsCreatePinMode } =
    useLocationSearchState<boolean>({
      pathInSearch: SECURITY_CREATE_PIN_SEARCH_FIELD,
    });

  useEffect(() => {
    clearIsCreatePinMode();
  }, []);

  return isCreatePinMode ? ProcedureType.Recreation : null;
};

type FocusOn = 'pin-toggle-checkbox' | 'pin-change-button' | 'parental-toggle-checkbox';
type useSecurityNavigationParams = {
  isSecurityPinOn: boolean;
};

export const useSecurityNavigation = ({ isSecurityPinOn: isPinOn }: useSecurityNavigationParams) => {
  const [focusedOn, setFocusOn] = useState<FocusOn>('pin-toggle-checkbox');

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (!isPinOn) {
      return;
    }
    if (focusedOn === 'pin-toggle-checkbox') {
      setFocusOn('pin-change-button');
      e.stop();
      return;
    }

    if (focusedOn === 'pin-change-button') {
      setFocusOn('parental-toggle-checkbox');
      e.stop();
      return;
    }
  };

  const onKeyUp: FocuserKeyHandler = (e) => {
    if (focusedOn === 'pin-change-button') {
      setFocusOn('pin-toggle-checkbox');
      e.stop();
      return;
    }

    if (focusedOn === 'parental-toggle-checkbox') {
      setFocusOn('pin-change-button');
      e.stop();
      return;
    }
  };

  const isPointerUpAvailable =
    focusedOn === 'pin-change-button' || focusedOn === 'parental-toggle-checkbox';

  const isPointerDownAvailable =
    (focusedOn === 'pin-toggle-checkbox' && isPinOn) || focusedOn === 'pin-change-button';

  return {
    onKeyDown,
    onKeyUp,
    isPointerUpAvailable,
    isPointerDownAvailable,
    focusedOn,
    setFocusOn,
  }

};
