import { FocuserKeyHandler } from '@focuser';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useForceRerender } from '~hooks/useForceRerender';

import { SelectOption } from './types';

export type UseSelectedOptionsOpsProps<T> = {
  optionsByChunks: SelectOption<T>[][];
  initialSelectedOptions?: SelectOption<T>[];
  isMultiSelect: boolean;
  onApply: (values: SelectOption<T>[]) => void;
};

export const useSelectedOptionsOps = <T>({
  optionsByChunks,
  initialSelectedOptions,
  isMultiSelect,
  onApply,
}: UseSelectedOptionsOpsProps<T>) => {
  const forceRender = useForceRerender();

  /**
   * Содержит карту, где ключ - индекс колонки из `optionsByChunks`, значение - выбранные элементы в этой колонке
   *
   * Используется, когда активен мультивыбор
   */
  const selectedOptionsByColumnMap = useRef<Record<number, SelectOption<T>[]>>({});

  useMemo(() => {
    selectedOptionsByColumnMap.current = {};
  }, [optionsByChunks, isMultiSelect]);

  useEffect(() => {
    const initialValues = initialSelectedOptions;
    if (!initialValues) {
      return;
    }

    if (!isMultiSelect) {
      const initialValue = initialValues[0];
      if (!initialValue) {
        return;
      }

      const columnIdx = optionsByChunks.findIndex((options) =>
        options.some((o) => initialValues.includes(o)),
      );
      if (columnIdx === -1) {
        return;
      }

      selectedOptionsByColumnMap.current = { [columnIdx]: initialValues };

      forceRender();
      return;
    }

    optionsByChunks.forEach((chunkOptions, index) => {
      selectedOptionsByColumnMap.current[index] = selectedOptionsByColumnMap.current[index] || [];
      const selectedInCurrentColumn = chunkOptions.filter((o) => initialValues.includes(o));
      selectedOptionsByColumnMap.current[index] = selectedInCurrentColumn;
    });

    forceRender();
  }, []);

  const handleClickOnCheckbox = useCallback(
    (option: SelectOption<T>) => {
      const columnInChunks = optionsByChunks.find((options) => options.includes(option));
      if (!columnInChunks) {
        return;
      }
      const columnIdxInChunks = optionsByChunks.indexOf(columnInChunks);

      if (!isMultiSelect) {
        selectedOptionsByColumnMap.current = { [columnIdxInChunks]: [option] };
        forceRender();
        return;
      }

      selectedOptionsByColumnMap.current[columnIdxInChunks] =
        selectedOptionsByColumnMap.current[columnIdxInChunks] || [];
      const columnInMap = selectedOptionsByColumnMap.current[columnIdxInChunks];

      // Тут мы перезаписываем массивы, чтобы та колонка, которая была затронута, отрендерилась
      // Ôбычный splice/push не перезапишет ссылку, а только перезапишет значения
      if (columnInMap.includes(option)) {
        selectedOptionsByColumnMap.current[columnIdxInChunks] = columnInMap
          .slice()
          .filter((o) => o !== option);
      } else {
        selectedOptionsByColumnMap.current[columnIdxInChunks] = columnInMap.slice().concat(option);
      }
      forceRender();
    },
    [optionsByChunks, isMultiSelect],
  );

  const handleClickOnApply = useCallback(() => {
    const selectedOptions = Object.values(selectedOptionsByColumnMap.current).reduce(
      (acc, options) => acc.concat(options),
      [],
    );
    onApply(selectedOptions);
  }, [onApply]);

  const handleResetAll = useCallback(() => {
    selectedOptionsByColumnMap.current = {};
    forceRender();
  }, []);

  const isAnyItemSelected = Object.values(selectedOptionsByColumnMap.current).some(
    (options) => options.length > 0,
  );

  return {
    selectedOptionsByColumnMap,
    handleClickOnCheckbox,
    handleClickOnApply,
    handleResetAll,
    isAnyItemSelected,
  };
};

type FocusedState = {
  columnIdx: number;
  rowIdx: number;
};

type UseDropdownSelectNavigationProps<T> = {
  optionsByChunks: SelectOption<T>[][];
};

export const useColumnsNavigation = <T>({
  optionsByChunks,
}: UseDropdownSelectNavigationProps<T>) => {
  const [focusedState, setFocusedState] = useState<FocusedState>({ columnIdx: 0, rowIdx: 0 });

  const nextStateOnDown = useMemo(() => {
    const { columnIdx, rowIdx } = focusedState;
    if (rowIdx < optionsByChunks[columnIdx].length - 1) {
      return { columnIdx, rowIdx: rowIdx + 1 };
    }
    return null;
  }, [focusedState, optionsByChunks]);

  const nextStateOnUp = useMemo(() => {
    const { columnIdx, rowIdx } = focusedState;
    if (rowIdx > 0) {
      return { columnIdx, rowIdx: rowIdx - 1 };
    }
    return null;
  }, [focusedState]);

  const nextStateOnRight = useMemo(() => {
    const { rowIdx, columnIdx } = focusedState;

    if (columnIdx >= optionsByChunks.length - 1) {
      return null;
    }

    const nextColumnIdx = columnIdx + 1;
    const nextRowIdx = Math.min(optionsByChunks[nextColumnIdx].length - 1, rowIdx);

    return {
      columnIdx: nextColumnIdx,
      rowIdx: nextRowIdx,
    };
  }, [focusedState, optionsByChunks]);

  const nextStateOnLeft = useMemo(() => {
    const { rowIdx, columnIdx } = focusedState;
    if (columnIdx <= 0) {
      return null;
    }
    return {
      columnIdx: columnIdx - 1,
      rowIdx,
    };
  }, [focusedState]);

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (!nextStateOnDown) {
      return;
    }
    setFocusedState(nextStateOnDown);
    e.stop();
  };

  const onKeyUp: FocuserKeyHandler = (e) => {
    if (!nextStateOnUp) {
      return;
    }
    setFocusedState(nextStateOnUp);
    e.stop();
  };

  const onKeyRight: FocuserKeyHandler = (e) => {
    if (!nextStateOnRight) {
      return;
    }
    setFocusedState(nextStateOnRight);
    e.stop();
  };

  const onKeyLeft: FocuserKeyHandler = (e) => {
    if (!nextStateOnLeft) {
      return;
    }
    setFocusedState(nextStateOnLeft);
    e.stop();
  };

  const isPointerUpAvailable = Boolean(nextStateOnUp);

  const isPointerDownAvailable = Boolean(nextStateOnDown);

  const isPointerRightAvailable = Boolean(nextStateOnRight);

  const isPointerLeftAvailable = Boolean(nextStateOnLeft);

  return {
    columnsFocusedState: focusedState,
    setColumnsFocusedState: setFocusedState,
    isPointerUpAvailable,
    isPointerDownAvailable,
    isPointerRightAvailable,
    isPointerLeftAvailable,
    onKeyDown,
    onKeyUp,
    onKeyRight,
    onKeyLeft,
  };
};

export const useDropdownSelectPopupNavigation = () => {
  const [focusedOn, setFocusedOn] = useState<'columns' | 'buttons'>('columns');

  const onKeyUp: FocuserKeyHandler = (e) => {
    if (focusedOn === 'buttons') {
      setFocusedOn('columns');
      e.stop();
    }
  };

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (focusedOn === 'columns') {
      setFocusedOn('buttons');
      e.stop();
    }
  };

  const isPointerUpAvailable = focusedOn === 'buttons';
  const isPointerDownAvailable = focusedOn === 'columns';

  return {
    focusedOn,
    setFocusedOn,
    onKeyUp,
    onKeyDown,
    isPointerUpAvailable,
    isPointerDownAvailable,
  };
};
