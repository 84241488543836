export const matchPaymentMethodFromProduct = (plans) => {
  const methods = {}
  plans.forEach(plan => {
    if (plan.eligible_phase) {
      methods[plan.id] = plan.eligible_phase.billing.prices
    }
    if (plan.phases) {
      plan.phases.forEach(phase => {
        methods[plan.id] = phase.billing.prices
      })
    }
  })
  return methods
}
