import * as React from 'react';

import Language from '~typings/Language';

import { IS_AGREEMENT_CONFIRMED } from './constants';


export interface State {
  enableAutomaticLogIn: boolean;
  isIPTVOn: boolean | null;
  isLaunchingDone: boolean | null;
  isOnLine: boolean;
  isAuthorized: boolean | null;
  activeProfileId: string | null;
  regionUID: string | null;
  language: Language;
  authRedirectUrl: string | null;
  [IS_AGREEMENT_CONFIRMED]: boolean | null;
  isNeedToResetPin: boolean;
  timeout: boolean;
}

export enum ActionType {
  SetNetworkInfo,
  ToggleIPTV,
  SetEnableAutomaticLogIn,
  SetIsLaunchingDone,
  SetIsOnLine,
  SetLanguage,
  SetIsAuthorized,
  SetActiveProfileId,
  SetAuthRedirectUrl,
  SetAgreementStatus,
  SetNeedToResetPin,
  setAddTimeout
}

export interface Action {
  type: ActionType;
  payload?: Partial<State>;
}

export type Dispatch = (action: Action) => void;


export const AppContext = React.createContext<State>({} as State);
export const AppDispatchContext = React.createContext<Dispatch | undefined>(undefined);

export const useApp = () => {
  const context = React.useContext(AppContext);

  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider')
  }
  return context;
};

export const useAppAction = () => {
  const context = React.useContext(AppDispatchContext);

  if (context === undefined) {
    throw new Error('useAppAction must be used within a AppProvider')
  }
  return context;
};


export const reducer = (state, action: Action) => {
  switch (action.type) {
    case ActionType.SetNetworkInfo: {
      return ({
        ...state,
        isIPTVOn: action.payload?.isIPTVOn,
        regionUID: action.payload?.regionUID,
      });
    }
    case ActionType.ToggleIPTV: {
      return ({
        ...state,
        isIPTVOn: !state.isIPTVOn,
      });
    }
    case ActionType.SetEnableAutomaticLogIn: {
      return ({
        ...state,
        enableAutomaticLogIn: !!action.payload?.enableAutomaticLogIn,
      });
    }
    case ActionType.SetIsLaunchingDone: {
      return ({
        ...state,
        isLaunchingDone: action.payload?.isLaunchingDone,
      });
    }
    case ActionType.SetIsOnLine: {
      return ({
        ...state,
        isOnLine: action.payload?.isOnLine,
      });
    }
    case ActionType.SetIsAuthorized: {
      return ({
        ...state,
        isAuthorized: action.payload?.isAuthorized,
      });
    }
    case ActionType.SetActiveProfileId: {
      return ({
        ...state,
        activeProfileId: action.payload?.activeProfileId,
      });
    }
    case ActionType.SetLanguage: {
      return ({
        ...state,
        language: action.payload?.language,
      });
    }
    case ActionType.SetAuthRedirectUrl: {
      return ({
        ...state,
        authRedirectUrl: action.payload?.authRedirectUrl,
      });
    }
    case ActionType.SetAgreementStatus: {
      return ({
        ...state,
        [IS_AGREEMENT_CONFIRMED]: action.payload?.isAgreementConfirmed,
      });
    }
    case ActionType.SetNeedToResetPin: {
      return ({
        ...state,
        isNeedToResetPin: action.payload?.isNeedToResetPin,
      });
    }
    case ActionType.setAddTimeout: {
      return ({
        ...state,
        timeout: action.payload?.timeout,
      });
    }
    default:
      return state;
  }
};
