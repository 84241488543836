import { InjectedIntl } from 'react-intl';

import Payment from '~typings/Payment';
import Purchase from '~typings/Purchase';
import SmartTVConfig from '~typings/SmartTVConfig';

import { AccountTabIdMap } from './constants';
import { AccountTab, AccountTabId } from './types';

type getAccountTabParams = {
  intl: InjectedIntl;
  isAuthorized: boolean;
  isKidsProfile: boolean;
  profileSection: SmartTVConfig['profileSection'];
  isDevicesLoaded: boolean;
  isQuestionsLoaded: boolean;
  purchases: Purchase[];
  isPurchasesLoaded: boolean;
  payments: Payment[];
  isPaymentsLoaded: boolean;
};

/**
 * Возвращает массив доступных табов для страницы
 */
export const getAccountTabs = ({
  intl,
  isKidsProfile,
  profileSection,
  isAuthorized,
  isDevicesLoaded,
  isQuestionsLoaded,
  purchases,
  isPurchasesLoaded,
  payments,
  isPaymentsLoaded
}: getAccountTabParams): AccountTab[] => {
  // Для неавторизованных
  if (!isAuthorized) {
    return getAvailableTabsForUnathorized(intl);
  }

  // Для детских профилей
  if (isKidsProfile) {
    return getAvailableTabsForKids(intl);
  }

  // Для обычных профилей
  return getAvailableTabsForAdults({ 
    profileSection, 
    isDevicesLoaded, 
    isQuestionsLoaded, 
    purchases, 
    isPurchasesLoaded,
    payments, 
    isPaymentsLoaded,
    intl 
  });
};

const getAvailableTabsForUnathorized = (intl: InjectedIntl) => {
  return [
    AccountTabId.Signin,
    AccountTabId.Promocode, 
    AccountTabId.Settings,
    AccountTabId.Help,
    AccountTabId.About,
  ].map((id) => ({
    id,
    text: intl.formatMessage({ id: AccountTabIdMap[id] }),
  }));
}

const getAvailableTabsForKids = (intl: InjectedIntl) => {
  return [
    {
      id: AccountTabId.Profiles,
      text: intl.formatMessage({ id: AccountTabIdMap[AccountTabId.Profiles] }),
    },
  ];
}

const getAvailableTabsForAdults = ({
  profileSection,
  isDevicesLoaded,
  isQuestionsLoaded,
  purchases,
  isPurchasesLoaded,
  payments,
  isPaymentsLoaded,
  intl
}: {
  profileSection: SmartTVConfig['profileSection'],
  isDevicesLoaded: boolean,
  isQuestionsLoaded: boolean,
  purchases: Purchase[],
  isPurchasesLoaded: boolean,
  payments: Payment[],
  isPaymentsLoaded: boolean,
  intl: InjectedIntl,
}
): AccountTab[] => {
  return Object.values(AccountTabId)
    .filter((ID) => {
      switch (ID) {
        case AccountTabId.Subscriptions:
          return profileSection.subscriptions;
        case AccountTabId.Security:
          return profileSection.security;
        case AccountTabId.Payments:
          return profileSection.paymentHistory && isPaymentsLoaded && payments.length > 0;
        case AccountTabId.Purchases:
          return isPurchasesLoaded && purchases.length > 0;
        case AccountTabId.Devices:
          return profileSection.devices && isDevicesLoaded;
        case AccountTabId.Signin:
          return false;
        case AccountTabId.Stats:
          return profileSection.stats;
        case AccountTabId.Help:
          return isQuestionsLoaded;
        // Следующие табы пока не готовы
        case AccountTabId.Cards: 
          //return profileSection.cards
          return false;
        default:
          return true;
      }
    })
    .map((id) => ({
      id,
      text: intl.formatMessage({ id: AccountTabIdMap[id] }),
    }));
}

