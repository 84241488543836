/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export const ArrowRightIcon: React.FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      { ...props }
    >
      <path
        d="M8.52186 5.79873C7.8123 5.16497 7.80072 4.05853 8.49686 3.41006C9.11036 2.83858 10.0589 2.83095 10.6815 3.3925L19.1939 11.0701C19.628 11.4616 19.6352 12.1404 19.2094 12.541L10.689 20.5577C10.0657 21.1441 9.09434 21.1465 8.4681 20.5632C7.78137 19.9235 7.77785 18.8367 8.46044 18.1925L15.2377 11.7971L8.52186 5.79873Z"
        fill="currentColor"
      />
    </svg>
  );
};
