import { FocuserKeyHandler, useFocuserThrottledHandler } from '@focuser';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { usePreloadImagesArray } from '~hooks/usePreloadImages';
import { getBigBannerImage } from '~lib/MediaItemImageSRC';
import BannerBig from '~typings/BannerBig';

type useBigBannerContentNavigationProps = {
  bigBanners: BannerBig[];
};

export const useBigBannerContentNavigation = ({
  bigBanners,
}: useBigBannerContentNavigationProps) => {
  const [focusedIdx, setFocusedIdx] = useState(0);

  const focusedBanner = bigBanners[focusedIdx] || bigBanners[0];
  useEffect(() => {
    return () => {
      setFocusedIdx(0);
    };
  }, [bigBanners]);

  useEffect(() => {
    if (!bigBanners[focusedIdx]) {
      setFocusedIdx(0);
    }
  }, [focusedIdx]);

  const onNextSlide = useCallback(() => {
    // Листаем на следующий слайд, или первый, если дошли до конца
    setFocusedIdx((prev) => prev + (1 % bigBanners.length));
  }, [bigBanners]);

  useBannersAutoScroller({
    banner: focusedBanner,
    onNextSlide,
  });

  const onKeyLeft: FocuserKeyHandler = (e) => {
    if (focusedIdx === 0) return;
    setFocusedIdx(focusedIdx - 1);
    e.stop();
  };

  const onKeyRight: FocuserKeyHandler = (e) => {
    if (focusedIdx >= bigBanners.length - 1) return;
    setFocusedIdx(focusedIdx + 1);
    e.stop();
  };

  const onKeyRightThrottled = useFocuserThrottledHandler(onKeyRight);
  const onKeyLeftThrottled = useFocuserThrottledHandler(onKeyLeft);

  return {
    focusedBanner,
    onKeyLeft: onKeyLeftThrottled,
    onKeyRight: onKeyRightThrottled,
    focusedIdx,
  };
};

type useBigBannerContentImagesProps = {
  bigBanners: BannerBig[];
  focusedBanner: BannerBig;
};

export const useBigBannerContentImages = ({
  bigBanners,
  focusedBanner,
}: useBigBannerContentImagesProps) => {
  const images = useMemo(() => bigBanners.map(getBigBannerImage), [bigBanners]);
  const { images: imagesPreloaded, isLoading } = usePreloadImagesArray(images);
  const focusedIdx = bigBanners.indexOf(focusedBanner);
  const bigBannerImagePreloaded = imagesPreloaded[focusedIdx] || null;

  return {
    bigBannerImagePreloaded,
    isLoading,
  };
};

const DEFAULT_ROTATION_DELAY = 6700;

type useBannersAutoScrollerProps = {
  banner: BannerBig;
  onNextSlide: () => void;
};

const useBannersAutoScroller = ({ banner, onNextSlide }: useBannersAutoScrollerProps) => {
  useEffect(() => {
    const speed = banner.rotation_delay * 1000 || DEFAULT_ROTATION_DELAY;
    const timer = setTimeout(() => {
      onNextSlide();
    }, speed);
    return () => {
      clearTimeout(timer);
    };
  }, [banner, onNextSlide]);
};
