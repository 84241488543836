import React from 'react';

import * as styles from './icons.module.css';
/* eslint-disable max-len */
export const BackSpaceIcon = () => {
  return (
    <svg
      className={ styles.icon }
      viewBox="0 0 34 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.13804 0C8.56808 0 8.02519 0.243181 7.64572 0.668468L0.507679 8.66847C-0.169226 9.42711 -0.169227 10.5729 0.507679 11.3315L7.64572 19.3315C8.02519 19.7568 8.56808 20 9.13804 20H31.9901C33.0947 20 33.9901 19.1046 33.9901 18V2C33.9901 0.895431 33.0947 0 31.9901 0H9.13804ZM23.3945 5.60548C23.7574 5.96834 23.7574 6.55664 23.3945 6.91949L20.314 10L23.3946 13.0806C23.7574 13.4435 23.7574 14.0318 23.3946 14.3946C23.0317 14.7575 22.4434 14.7575 22.0806 14.3946L19 11.314L15.9195 14.3945C15.5567 14.7573 14.9684 14.7573 14.6055 14.3945C14.2427 14.0316 14.2427 13.4433 14.6055 13.0805L17.686 10L14.6056 6.91963C14.2427 6.55678 14.2427 5.96847 14.6056 5.60562C14.9684 5.24277 15.5567 5.24277 15.9196 5.60562L19 8.68601L22.0805 5.60548C22.4434 5.24263 23.0317 5.24263 23.3945 5.60548Z"
        fill="currentColor"
      />
    </svg>
  );
};
