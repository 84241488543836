import React from "react";

import { useGetDataResult } from "../../hooks/getDescriptionFields";
import * as css from "../styles.module.css";

interface Props {
  data: useGetDataResult
}

const LocalComponent = ({ data }: Props) => {
  return (
    <>
      {
        data.title &&
          <div className={ css.vodSecondLine }>
              <div className={ css.itemName }>{ data.title }</div>
          </div>
      }
      </>
  )
}

export const VodTitle = LocalComponent