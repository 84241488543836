import * as moment from 'moment';
import * as React from 'react';

import getProgramEventMutation from '~lib/getProgramEventMutation';
import { isCardChannel, isCardProgramEvent } from '~lib/objectType';
import Card, {Modification} from '~typings/Card';
import CardMutation, { CardMutationState } from '~typings/CardMutation';


const useCurrentEventCard = (cardMutation?: CardMutation, item?: Card): CardMutationState | null | undefined => {
  // null - indicates that the current event not found in cardMutation;
  const [currentEvent, setCurrentEvent] = React.useState<CardMutationState | null | undefined>(
    getProgramEventMutation(cardMutation)
  );
  const timeout = React.useRef<any>(null);

  React.useEffect(() => () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  React.useEffect(() => {

  }, []);

  React.useEffect(() => {
    if (!cardMutation || (cardMutation.states || []).length === 0) {
      setCurrentEvent(undefined);
    } else {
      const possiblyNewValueForCurrentEvent = getProgramEventMutation(cardMutation);

      if (possiblyNewValueForCurrentEvent?.card.id !== currentEvent?.card.id) {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        setCurrentEvent(possiblyNewValueForCurrentEvent);
      } else if (possiblyNewValueForCurrentEvent === undefined && isCardProgramEvent(item)) {
        setCurrentEvent(cardMutation.states.find(({ card }) => (card.modification === Modification.PAST)));
      } else if (!possiblyNewValueForCurrentEvent) {
        setCurrentEvent(null);
      }
    }
  }, [cardMutation]);

  React.useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (cardMutation && currentEvent && currentEvent.end_at && isCardChannel(item)) {
      const now = moment();
      const end = moment(currentEvent.end_at);
      const timeInMSToCalculateNewCurrentEvent = end.add(15, 'seconds').diff(now, 'milliseconds');

      timeout.current = setTimeout(() => {
        setCurrentEvent(getProgramEventMutation(cardMutation));
      }, timeInMSToCalculateNewCurrentEvent);
    }
  }, [currentEvent?.end_at]);

  return currentEvent || undefined;
};


export default useCurrentEventCard;
