import * as cn from 'classnames';
import * as React from 'react';

import NavigationDirection from '~typings/NavigationDirection';

import ArrowIcon from './icons/Arrow';
import * as styles from './styles.module.css';

type Props = {
  direction: NavigationDirection;
  classNames?: {
    wrapper?: string;
    icon?: string;
  };
};

const IconArrow: React.FC<Props> = ({ direction, classNames }) => {
  return (
    <div className={ cn(styles.wrapper, classNames?.wrapper) }>
      <ArrowIcon className={ cn(styles.icon, classNames?.icon, {
        [styles.up]: direction === NavigationDirection.Up,
        [styles.right]: direction === NavigationDirection.Right,
        [styles.left]: direction === NavigationDirection.Left,
      }) } />
    </div>
  );
};


export default IconArrow;
