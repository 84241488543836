import { compact } from 'lodash';
import * as React from 'react';

import { getItemsCountInLine } from '~components/TiledView/component.helpers';
import { useContinueWatchingInfinity } from '~hooks/fetch/useContinueWatching/useContinueWatchingV3';
import { useDeleteWatchProgress } from '~hooks/fetch/useWatchProgress/useWatchProgress';
import { usePaginationByItems } from '~hooks/usePagination';
import useNavigationOnLayer from '~stores/LayerNavigation';
import useSideBarNavigation, { FOCUS_ON } from "~stores/SideBarNavigation";
import ContinueWatchingType from '~typings/ContinueWatchingType';
import ImageType from '~typings/ImageType';

import { Delete } from '../EditItemsMenu/EditItemsMenu';
import PersonalPageEditWrapper, { PERSONAL_PAGE_ID } from '../Wrapper/Wrapper';


type Props = {
  types: ContinueWatchingType[]
  onNavigationItemsReady: (isReady: boolean) => void;
}

const WatchLater = (props: Props) => {
  const pageID = `watch_later-${ props.types.join('_') }`;
  const layerID = `${ PERSONAL_PAGE_ID }-${ pageID }`;
  const slidesCount: [number, number] = [4, 3];
  const itemsCountInLine = getItemsCountInLine(ImageType.BANNER, slidesCount);
  const focusOn = useNavigationOnLayer(state => (state.focusHistory[layerID] || {})?.focusOn ?? 0);
  const focusedItemIndex = useNavigationOnLayer(state => state.getFocusedIndex(layerID, focusOn));
  const dropFocusedItemIndex =  useNavigationOnLayer(state => ()=> state.setFocusedIndexInBehind(layerID, 0));

  const { mutate, isLoading : isLoadingMutation } = useDeleteWatchProgress();
  const { query, parsedData: items} = useContinueWatchingInfinity({
    types: props.types
  });
  const isFetchingContinueWatching = query.isLoading || isLoadingMutation;
  const setFocusOnLeftMenu = useSideBarNavigation(st => ()=> st.setFocusOn(FOCUS_ON.LEFT_MENU));
  useSideBarNavigation(st => st.getFocusOn()); //is needed for rerender to fix nav bug

  usePaginationByItems({
    currentFetchedCount: items?.data.length || 0,
    focusedLineIdx: Math.floor(focusedItemIndex / itemsCountInLine),
    elementsPerLine: itemsCountInLine,
    minimalLinesOnScreen: 3,
    updates: [isLoadingMutation],
    fetchMoreData: ()=> {
      if(query.hasNextPage && !query.isFetchingNextPage && !isLoadingMutation){
        query.fetchNextPage();
      }
    }
  });

  const setNavigatinItemsReadyToTrue = React.useCallback(()=>{
      props.onNavigationItemsReady(true)
  }, [props.onNavigationItemsReady]);

  const handleDelete = React.useCallback((arg: Delete, selectedItems: number[]) => {
    if (arg === Delete.All) {
      return mutate(
        { resourceIDs: [] },
        {
          onSettled: dropFocusedItemIndex,
          onSuccess: ()=> { setFocusOnLeftMenu(); props.onNavigationItemsReady(false);}
        },
      );
    }

    if(selectedItems.length === 0){
      return;
    }

    return mutate(
      {
        resourceIDs: compact(
          selectedItems.map((idx) => items?.data?.[idx].resource_id)
        ),
      },
      {
        onSuccess: () => {
          if(selectedItems.length === items?.data.length){
            // Удалили все, нужно переключиться на левый блок
            setFocusOnLeftMenu();
            props.onNavigationItemsReady(false);
          }
        },
        onSettled: dropFocusedItemIndex,
      },
    );

  }, [items?.data, dropFocusedItemIndex, setFocusOnLeftMenu, props.onNavigationItemsReady]);
  return (
    <PersonalPageEditWrapper
      layerID={ layerID }
      items={ items }
      isLoading={ isFetchingContinueWatching }
      onDelete={ handleDelete }
      onNavigationItemsReady={ setNavigatinItemsReadyToTrue }
    />
  );
};


export default React.memo(WatchLater);
