import { DeleteIcon } from '~newapp/ui-kit/Icons/For_buttons/deleteIcon';

import { IconBtnHOC, IconBtnProps } from '../../IconBtnHOC/IconBtnHOC';
import { IconBtnFocuserHOC, IconBtnFocuserProps } from '../../IconBtnHOC/IconBtnHOCFocuser';

export const DeleteButton = IconBtnHOC(DeleteIcon, 'big');
export const DeleteButtonFocuser = IconBtnFocuserHOC(DeleteIcon, 'big');

export type DeleteButtonProps = IconBtnProps;
export type DeleteButtonFocuserProps = IconBtnFocuserProps;
