import { get } from 'lodash';

import { ManifestParser } from '~lib/player/utils/manifest-parser';
import Manifest from '~typings/Manifest';

// import { getDir } from './helpers';

// const parseDashManifest = async (): Promise<Manifest> => {
const parseDashManifest = async (playerState: any): Promise<Manifest> => {
  try {
    const { result: manifestTextData } = playerState.fetchResponse
    const manifest: Manifest = {
      source: manifestTextData,
      video: [],
      audio: [],
    };
    const parsed: ManifestParser = playerState.parsedManifest

    Object.keys(get(parsed, 'mediaGroups.AUDIO.audio', {})).forEach((key) => {
      const item = get(parsed, `mediaGroups.AUDIO.audio.${ key }`, null);

      if (item) {
        manifest.audio?.push({
          name: get(item, 'language', key),
          url: '',
          source: '',
          group: null,
        });
      }
    });

    return manifest;
  } catch (ignore) {
    return {};
  }
};


export default parseDashManifest;
