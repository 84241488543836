import * as cn from 'classnames';
import React from "react";
import { FormattedMessage } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import { NAV_LEGEND_SEARCH } from '~lib/localizator/src/dictionaries';

import * as css from './styles.module.css'

interface Props {
  text?: string; // текст который нужно отобразить
}


const SearchInput$:React.FC<Props> = ({ text }: Props) => {

  const getFirstLetterIsBig = (string: string | undefined) => {
    if (!string) return null;
      
    return string[0].toUpperCase() + string.slice(1);
  }

  const inputArea = (
    <div className={ cn(css.valueWrapper, css.alignLeft) }>
      <div className={ css.value }>
        { getFirstLetterIsBig(text) }

        {text
          ? <div className={ css.cursor }/>
          : <Typography variant={ 'h5_medium' } className={ css.inputPlaceholder }>
              <FormattedMessage id={ NAV_LEGEND_SEARCH }/>
            </Typography>
        }
      </div>
    </div>
  ) 

  // инпут для страницы поиска
  return (
    <div className={ css.input1 }>
      {inputArea}
      <div className={ css.input3 }></div>
    </div>
  )
}

export const SearchInput = SearchInput$;
