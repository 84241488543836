import { find } from 'lodash';

import { queryClient } from '~global';
import Page from '~typings/Page';

import { flatManyPages,pageKeys } from './usePages';

const getCachedPages = () => queryClient.getQueryData<Page[]>(pageKeys.withToken());

const getFlattenPagesFromCache = () : Page[] | undefined=> {
  const queryData: Page[] | undefined = getCachedPages();

  if (!queryData) {
    return;
  }

  const pages : Page[] =  flatManyPages(queryData);

  return pages;
}

export const getPageByUID = (UID: string): any => {
  const pages = getFlattenPagesFromCache();
  if(!pages){
    return;
  }
  const page = find(pages, ['uid', UID]) || find(pages, ['slug', UID]);
  return page;
};

export function getPageIdByDeepLink(deepLink: string): string {
  // достает из диплинка ("pages/senanta") id соостветующей страницы из кеша
  const pageList = getFlattenPagesFromCache();
  if(!pageList){
    return 'error';
  }
  const searchUid = deepLink.split('/');
  const targetPage = pageList.find((page) => page.slug === searchUid[1]);
  return targetPage ? targetPage.id : 'error';
}
