import {intersection} from 'lodash';

import {getAllowedDRMs, getAllowedProtocols,} from '~lib/mediaItem';
import {getProtocolsByPlatform} from '~lib/player/getProtocolsByPlatform';
import {DeviceInfo} from '~typings/DeviceInfo';
import {DRMType} from '~typings/Drm';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Protocol from '~typings/Protocol';


const SUPPORTING_DRM_LIST: DRMType[] = [DRMType.SPbTVCas, DRMType.WideVine, DRMType.PlayReady];


export type ProtocolsByDRM = {
  [key in DRMType]: Protocol[] | null;
};

const PROTOCOLS_BY_DRM: Readonly<ProtocolsByDRM> = {
  [DRMType.SPbTVCas]: [Protocol.DASH, Protocol.HLS, Protocol.MSS],
  [DRMType.WideVine]: [Protocol.DASH],
  [DRMType.PlayReady]: [Protocol.DASH, Protocol.MSS],
  [DRMType.FairPlay]: null, // [DRMType.FairPlay]: [Protocol.HLS],
  [DRMType.VeriMatrix]: null,
  [DRMType.AdobeDRM]: null,
};


const getProtocolsByDRM = (drm: DRMType): Protocol[] | null => PROTOCOLS_BY_DRM[drm];


const allowedDRMsForPlayerItem = (item: PlayerMediaItem): DRMType[] => {
  return intersection(getAllowedDRMs(item), SUPPORTING_DRM_LIST);
};


type ProtocolByDeviceReturnType = {
  drmKeys: DRMType[];
  protocolsByDrm: ProtocolsByDRM;
};

const getProtocolsByDevice = (item: PlayerMediaItem, device: DeviceInfo): ProtocolByDeviceReturnType => {
  const drms = allowedDRMsForPlayerItem(item);
  const allowedProtocols = getAllowedProtocols(item);

  const protocolsByDrm = {} as ProtocolsByDRM;
  const drmKeys = [] as DRMType[];

  for (const drm of drms) {
    const protocolsByPlatform = getProtocolsByPlatform(item, device, drm);
    let protocols = intersection(
      [protocolsByPlatform],
      (getProtocolsByDRM(drm) || [])
    );

    if (allowedProtocols) {
      protocols = intersection(allowedProtocols, protocols);

      if (protocols.length === 0) {
        protocols = allowedProtocols;
      }
    }

    if (protocols.length > 0) {
      drmKeys.push(drm);
      protocolsByDrm[drm] = protocols;
    }
  }

  return { drmKeys, protocolsByDrm };
}


export {
  allowedDRMsForPlayerItem,
  getProtocolsByDevice,
};
