import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  KeyBoardLayout,
  KeyBoardType,
  type0_dig,
  type0_dig_shortcut,
  type0_en,
  type0_en_shortcut,
  type0_ru,
  type0_ru_shortcut
} from "~components/SearchPage/keyBoard/keyboardLayouts/type0";
import { ButtonLine } from "~components/SearchPage/keyBoard/keyLine";
import { DIR, SearchPageBlocks } from "~components/SearchPage/SearchPage";
import useNavigationByKeys from "~hooks/useNavigation";
import NavigationDirection from "~typings/NavigationDirection";

import { KeyBoardKey } from "./keyboardLayouts/type0";


// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
//                                                                                                    //
//                    подробная информация по работе клавиатуры лежит в type0                         //
//                                                                                                    //
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

const keyBoardButtons: { [key in KeyBoardType]?: KeyBoardKey[][] } = {
  [KeyBoardType.type0 + KeyBoardLayout.Ru]: type0_ru,
  [KeyBoardType.type0 + KeyBoardLayout.En]: type0_en,
  [KeyBoardType.type0 + KeyBoardLayout.Digits]: type0_dig,
}

const keyBoardShortCut: { [key in KeyBoardType]?: KeyBoardKey[][] } = {
  [KeyBoardType.type0 + KeyBoardLayout.Ru]: type0_ru_shortcut,
  [KeyBoardType.type0 + KeyBoardLayout.En]: type0_en_shortcut,
  [KeyBoardType.type0 + KeyBoardLayout.Digits]: type0_dig_shortcut
}

interface Props {
  keyBoardType: KeyBoardType
  keyBoardLayout: KeyBoardLayout
  isFocused: boolean
  query: string
  setQuery: (newQuery: string) => void;
  // функция для переключения блоков, передается из родительского компонента
  switchNavigationBlock: (arg1: NavigationDirection, arg2: SearchPageBlocks) => void
  //функция для установки возможных направлений для кнопок pointer navigation
  setKeyboardDirections: Dispatch<SetStateAction<DIR>>
  handleSearchButtonClick?: () => void
}


export function KeyBoard({
                           keyBoardType,
                           keyBoardLayout,
                           isFocused,
                           query,
                           setQuery,
                           switchNavigationBlock,
                           setKeyboardDirections,
                           handleSearchButtonClick
                         }: Props) {
  // eslint-disable-next-line max-len
  const [keyBoard, setKeyBoard] = useState<string>(keyBoardType + keyBoardLayout || KeyBoardType.type0 + KeyBoardLayout.Ru);
  const buttons = keyBoardButtons[keyBoard] as KeyBoardKey[][];
  const shortCut = keyBoardShortCut[keyBoard] as KeyBoardKey[][];
  const [activeKey, setActiveKey] = useState<KeyBoardKey>(buttons[0][0]);

  const handleKeyNavigate = (direction): void => {
    if (!isFocused) {
      return
    }
    switch (direction) {
      case NavigationDirection.Up: {
        if (activeKey.potentialMove[0] === true) {
          // делаем что-то если движение вверх у активной клавиши возможно
          if (typeof activeKey.dir[0] === 'string') {
            // если клавиша всего одна то устанавливаем ее
            // достаем ее координаты и делаем активной
            const index = activeKey.dir[0].split('-')
            setActiveKey({
              ...buttons[index[0]][index[1]],
              previousNavDir: 'up',
              previousKey: activeKey.position
            })
            //так же устанавливаем потенциальные ходы следующей клавиши в родительский компонент
            //на основе этой даты в родительском компоненте вычисляем какие стрелочки показать на экране
            setKeyboardDirections(buttons[index[0]][index[1]].potentialMove)
          }
          else {
            // если клавиш две и более то вычисляем направление с которого пришли и устанавливаем нужную клавишу
            if (activeKey.previousNavDir === 'up') {
              // на данном этапе этот кейс невозможен
            }
            else if (activeKey.previousNavDir === 'right') {
              const index = activeKey.dir[0][0].split('-')
              setActiveKey({
                ...buttons[index[0]][index[1]],
                previousNavDir: 'up',
                previousKey: activeKey.position
              })
              setKeyboardDirections(buttons[index[0]][index[1]].potentialMove)
            }
            else if (activeKey.previousNavDir === 'down') {
              const prevKeyPos = activeKey.previousKey.split('-')
              setActiveKey({
                ...buttons[prevKeyPos[0]][prevKeyPos[1]],
                previousNavDir: 'up',
                previousKey: activeKey.position
              })
              setKeyboardDirections(buttons[prevKeyPos[0]][prevKeyPos[1]].potentialMove)
            }
            else if (activeKey.previousNavDir === 'left') {
              const index = activeKey.dir[0][1].split('-')
              setActiveKey({
                ...buttons[index[0]][index[1]],
                previousNavDir: 'up',
                previousKey: activeKey.position
              })
              setKeyboardDirections(buttons[index[0]][index[1]].potentialMove)

            }
          }
        }
        else {
          switchNavigationBlock(direction, SearchPageBlocks.Keyboard)
        }
        break;
      }
      case NavigationDirection.Right: {
        if(!query && activeKey.id === ' ') {
          return;
        }

        if (activeKey.potentialMove[1] === true) {
          if (typeof activeKey.dir[1] === 'string') {
            const index = activeKey.dir[1].split('-')
            setActiveKey({
              ...buttons[index[0]][index[1]],
              previousNavDir: 'right',
              previousKey: activeKey.position
            })
            setKeyboardDirections(buttons[index[0]][index[1]].potentialMove)
          }
          else {
            // данный кейс сейчас невозможен
          }
        }
        else {
          // если уперлись в правый край, по логике страницы поиска мы должны переключится на другой блок
          switchNavigationBlock(direction, SearchPageBlocks.Keyboard)
        }
        break;
      }
      case NavigationDirection.Down: {
        const blockedButtonsIds = ['Ы', 'Ь', '*', ':', 'W', 'X'];
        if(!query && blockedButtonsIds.includes(activeKey.id)) {
          return;
        }

        if (activeKey.potentialMove[2] === true) {
          if (typeof activeKey.dir[2] === 'string') {
            const index = activeKey.dir[2].split('-')
            setActiveKey({
              ...buttons[index[0]][index[1]],
              previousNavDir: 'down',
              previousKey: activeKey.position
            })
            setKeyboardDirections(buttons[index[0]][index[1]].potentialMove)
          }
          else {
            if (activeKey.previousNavDir === undefined) {
              const index = activeKey.dir[2][0].split('-')
              setActiveKey({
                ...buttons[index[0]][index[1]],
                previousNavDir: 'down',
                previousKey: activeKey.position
              })
              setKeyboardDirections(buttons[index[0]][index[1]].potentialMove)
            }
            else if (activeKey.previousNavDir === 'up') {
              const prevKeyPos = activeKey.previousKey.split('-')
              setActiveKey({
                ...buttons[prevKeyPos[0]][prevKeyPos[1]],
                previousNavDir: 'down',
                previousKey: activeKey.position
              })
              setKeyboardDirections(buttons[prevKeyPos[0]][prevKeyPos[1]].potentialMove)

            }
            else if (activeKey.previousNavDir === 'right') {
              const index = activeKey.dir[2][0].split('-')
              setActiveKey({
                ...buttons[index[0]][index[1]],
                previousNavDir: 'down',
                previousKey: activeKey.position
              })
              setKeyboardDirections(buttons[index[0]][index[1]].potentialMove)
            }
            else if (activeKey.previousNavDir === 'down') {
              // на данном этапе этот кейс невозможен
            }
            else if (activeKey.previousNavDir === 'left') {
              const index = activeKey.dir[2][1].split('-')
              setActiveKey({
                ...buttons[index[0]][index[1]],
                previousNavDir: 'down',
                previousKey: activeKey.position
              })
              setKeyboardDirections(buttons[index[0]][index[1]].potentialMove)
            }
          }
        }
        else {
          switchNavigationBlock(direction, SearchPageBlocks.Keyboard)
        }
        break;
      }
      case NavigationDirection.Left: {
        if (activeKey.potentialMove[3] === true) {
          if (typeof activeKey.dir[3] === 'string') {
            const index = activeKey.dir[3].split('-')
            setActiveKey({
              ...buttons[index[0]][index[1]],
              previousNavDir: 'left',
              previousKey: activeKey.position
            })
            setKeyboardDirections(buttons[index[0]][index[1]].potentialMove)
          }
        }
        else {
          setActiveKey({
            ...activeKey,
            previousNavDir: 'right',
            previousKey: activeKey.position
          })
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    setActiveKey(buttons[0][0])
    setKeyboardDirections(buttons[0][0].potentialMove)
  }, [keyBoard])

  useNavigationByKeys({
    isMounted: isFocused,
    onNavigation: handleKeyNavigate,
  }, [activeKey, isFocused]);

  function handleKeydown(event) {
    if (!isFocused) {
      return
    }
    else {
      // реагируем на нажатие только при фокусе клавы
      if (shortCut.includes(event.key.toUpperCase())) {
        setQuery(query + event.key)
      }
    }
  }

  useEffect(() => {
    // подключаем слушатель на кнопки, один слушатель на все клавиши
    window.addEventListener('keydown', handleKeydown)
    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [keyBoard, query])

  return (
    <>
      {
        buttons.map((line, index) => {
          return <ButtonLine key={ keyBoardType + index }
                             isFocused={ isFocused }
                             buttonLine={ line }
                             buttonLineIndex={ index }
                             keyBoardType={ keyBoardType }
                             activeKey={ activeKey }
                             query={ query }
                             setQuery={ setQuery }
                             keyBoard={ keyBoard }
                             setKeyBoard={ setKeyBoard }
                             handleSearchButtonClick={ handleSearchButtonClick }
          />
        })
      }
    </>
  )
}
