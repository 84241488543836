import * as React from 'react';

import { getEPGEventItemHeight } from '~lib/SizesInPX';


type Props = Readonly<{
  focusedIndex: number | null;
  children: any;
  className?: string;
  offsetShift: number;
}>;

const EPGEventHeight = getEPGEventItemHeight();


const EPGPageEventsListScrollWrapper: React.FC<Props> = (props: Props) => {
  const transform = `translate3d(0, ${
    props.offsetShift + ((props.focusedIndex || 0) * EPGEventHeight * -1)
  }px, 0)`;

  return (
    React.createElement(
      'div',
      {
        // ref: blockScrollableYRef,
        className: props.className,
        style: {
          position: 'absolute',
          width: '100%',
          height: '100%',
          overflow: 'visible',
          transform,
          WebkitTransform: transform,
        }
      },
      props.children,
    )
  );
};


export default React.memo(EPGPageEventsListScrollWrapper);
