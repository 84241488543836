import { parse } from 'query-string';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Хук парсит параметры из URL.
 * Если в URL нет параметров, или некорректный формат, то возвращает пустой объект
 */
const useLocationSearch = () => {
  const history = useHistory();

  return useMemo(() => {
    try {
      return parse(history.location.search, {
        parseBooleans: true,
      });
    } catch {
      return {};
    }
  }, [history.location.search]);
};

export default useLocationSearch;
