import classnames from 'classnames';
import React from 'react';

import * as styles from './BigKeyboardButton.module.css';

export type Props = {
  content: React.ReactNode;
  state: BigKeyboardButtonState;
  className?: string;
  isLarge?: boolean;
  forwardRef?: React.Ref<HTMLDivElement>;
};

export type BigKeyboardButtonState =
  | 'normal'
  | 'disabled'
  | 'selected'
  | 'pressed'
  | 'confirmation';

export const BigKeyboardButton: React.FC<Props> = ({
  content,
  state,
  className,
  isLarge,
  forwardRef,
}) => {
  return (
    <div
      ref={ forwardRef }
      className={ classnames(styles.button, getButtonState(state), className, {
        [styles.largeButton]: isLarge,
        [styles.buttonFocusable]: state !== 'disabled',
      }) }
    >
      {content}
    </div>
  );
};

BigKeyboardButton.displayName = 'BigKeyboardButton';

const getButtonState = (state: Props['state']): string => {
  const stateMapper: Record<typeof state, string> = {
    normal: styles.normalState,
    confirmation: styles.confirmationState,
    selected: styles.selectedState,
    pressed: styles.pressedState,
    disabled: styles.disabledState,
  };

  return stateMapper[state];
};
