import {useEffect,useMemo,useReducer } from 'react'
import { QueryKey, useQuery } from 'react-query'

import { useClient } from '~global'
import { FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters'
import ApiClient from '~lib/ApiClient'
import Card from '~typings/Card'
import CardMutation, { CardMutationState } from '~typings/CardMutation';
import ResponseMany from "~typings/ResponseMany";


const PREFIX_OF_A_COMPOSITE_KEY = 'new_card_mutations'

const getKey = (cardId: string, cardConfigId?: string): QueryKey => ([PREFIX_OF_A_COMPOSITE_KEY, cardId, cardConfigId]);

const fetch = async (client: ApiClient, cardId: string, cardConfigId?: string) => {

  const quarter = 1000 * 60 * 15;
  // Получаем текущую дату, но кратно 15 минутам,
  // то есть если сейчас 15:27 то получим 15:15
  // если сейчас 15:32 то получим 15:30
  // и так далее
  const currentDateRounded = Math.round(Date.now() / quarter) * quarter;
  const from = new Date(currentDateRounded).toISOString();
  const halfHourInMS = 1000 * 60 * 30;
  const to = new Date(currentDateRounded + halfHourInMS).toISOString();

  const query: Record<string, string> = {
    'filter[id_eq]': cardId,
    'filter[from]': from,
    'filter[to]': to,
  };

  if (cardConfigId) {
    query.card_config_id = cardConfigId
  }

  return await client.get(
    fetchURLs[FetchType.CardMutations],
    query,
  );
};

const MUTATION_CACHE_TIME = 1000 * 60 * 5; // 5 минут

const useCardMutation = (enabled: boolean, cardId: string, cardConfigId?: string) => {
  const queryKey = getKey(cardId, cardConfigId);
  const client = useClient();

  return useQuery<ResponseMany<CardMutation[]>>({
    queryKey,
    queryFn: () => fetch(client, cardId, cardConfigId),
    enabled: enabled,
    cacheTime: MUTATION_CACHE_TIME,
    notifyOnChangeProps: 'tracked',
  });
};

const REFRESH_TIMEOUT = 1000 * 15; // 15 секунд

/**
 * Хук оборачивает карточку, которая возможно имеет мутацию
 * Если карточка не имеет мутацию, то вернет оригинальную
 * Иначе вернет карточку из мутации
 */
const useCardMutationWithCalculation = (card: Card, cardConfigId: string) => {
  const mutationQuery = useCardMutation(card.mutable, card.id, cardConfigId);
  const mutation = mutationQuery.data;
  const [forceUpdateCounter, forceUpdate] = useReducer(c => c + 1, 0);

  const currentMutationState = useMemo(() => {
    if(!card.mutable || !mutation) {
      return null
    }

    const states = mutation.data[0]?.states || [];

    const currentDate = new Date().getTime();

    return states.reduce<CardMutationState | null>((acc, state) => {
      const stateStartDate = new Date(state.start_at).getTime();
      if(stateStartDate > currentDate) {
        return acc;
      }

      if(!acc) {
        return state;
      }

      const accStartTime = new Date(acc.start_at).getTime();
      if(stateStartDate > accStartTime) {
        return state;
      }

      return acc;
    }, null);

  }, [mutation, card.mutable, forceUpdateCounter]);

  const isThereMoreStates = useMemo(() => {
    if(!currentMutationState || !mutation) {
      return false;
    }
    const currentMutationStateDate = new Date(currentMutationState.start_at).getTime();
    const isThereMoreStates = (mutation.data[0]?.states ?? []).find(
      (state) => new Date(state.start_at).getTime() > currentMutationStateDate,
    );
    return isThereMoreStates;
  },  [mutation, currentMutationState]);


  useEffect(() => {
    /**
     * В этом useEffect мы проверяем, есть ли актуальная мутация, и если есть, то перерендерим компонент
     * когда актуальная мутация закончится, чтобы показать следующую актуальную мутацию
     */

    if(!currentMutationState) {
      return;
    }

    if(!isThereMoreStates){
      // Состояние, когда следующего стейта нет, перерендер не нужен, в этом случае будет перезапрос
      return;
    }
    // Как только время до конца мутации закончится,
    // то перерендерим компонент, чтобы снова показать актуальную мутацию

    const currentDate = new Date().getTime();
    const endAt = currentMutationState.end_at ? new Date(currentMutationState.end_at).getTime() : 0;

    const timeToEventEnd = endAt - currentDate;


    if(timeToEventEnd > 0) {
      const timeout = setTimeout(() => {
        forceUpdate();
      }, timeToEventEnd);

      return () => clearTimeout(timeout);
    }
  }, [currentMutationState, isThereMoreStates]);

  useEffect(() => {
    /**
     * В этом useEffect мы проверяем, нужно ли перезапрашивать мутации
     */

    if(!card.mutable) {
      return;
    }

    if(!mutation || mutationQuery.isFetching) {
      return;
    }

    if(currentMutationState) {
      if(isThereMoreStates) {
        // Тут сценарий когда мутации еще не закончились, ничего не делаем
        return;
      }

      // Если это последняя мутация, то нужно будет перезапросить мутации, когда она истечет
      const currentDate = new Date().getTime();
      const endAt = currentMutationState.end_at ? new Date(currentMutationState.end_at).getTime() : 0;
      const timeToEventEnd = endAt - currentDate;


      if(timeToEventEnd > 0) {
        const timeout = setTimeout(() => {
          mutationQuery.refetch();
        }, timeToEventEnd);
        return () => clearTimeout(timeout);
      }

      // Если таймаут меньше 0, то мутация закончилась, значит бэкенд вернул пробел среди мутаций,
      // в этом случае нужно также перезапросить мутации, но не сразу, а через какой-то таймаут
    }

    // Тут сценарий когда все мутации с бэка истекшие (так прислал бэк), либо бэк прислал пустой массив
    // В этом случае нужно будет перезапросить мутации, но не часто, чтобы не DDOS-ить бэк
    const timeout = setTimeout(() => {
      mutationQuery.refetch();
    }, REFRESH_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [card.mutable, mutationQuery.isFetching, currentMutationState]);

  if(card.mutable && currentMutationState) {
    return {
      card: currentMutationState.card,
      mutationState: currentMutationState,
    }
  }

  return {
    card,
    mutationState: null,
  }
};


export {
  getKey,
  useCardMutation,
  useCardMutationWithCalculation,
};
