import { useMutation } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import CancellationTransaction from '~typings/CancellationTransaction';
import Meta from '~typings/Meta';


interface ConfirmationError extends CancellationTransaction {
  data: CancellationTransaction;
  meta?: Meta;
  errors?: object[];
}

const fetch = async (client, accessToken, subscriptionID, param) => {
  if (!accessToken || !subscriptionID) { return undefined; }

  return await client.delete(
    `/v3/subscriptions/${subscriptionID}`,
    {
      access_token: accessToken,
      ...(param && { param: param }),
    }
  );
};


const useUnsubscribe = () => {
  const { accessToken } = getAuthData();

  const client = useClient();

  return useMutation<ConfirmationError, void, { subscriptionID: string, param: string | null }>(
    ({ subscriptionID, param }) => fetch(client, accessToken, subscriptionID, param),
    {
      retry: false,
    }
  );
};


export default useUnsubscribe;
