import focuser, { FocuserKeyHandler } from '@focuser';
import serviceInfoByLang from 'customization-project-name/service-info';
import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';

import { SimpleButtonFocuser } from '~app/ui-kit/SimpleButton';
import { DocViewPopup } from '~components/DocViewPopup';
import { useApp } from '~components/Provider/App';
import { PRIVACY_POLICY, USER_AGREEMENT } from '~lib/localizator/src/dictionaries';

import * as styles from './AboutButtons.module.css';

export interface Props {
  className?: string;
}

export const AboutButtons = injectIntl<Props>(({ intl }) => {
  const { language } = useApp();
  const isTermsExists = Boolean(serviceInfoByLang?.terms?.[language]);
  const isPolicyExists = Boolean(serviceInfoByLang?.policy?.[language]);

  const [openedDoc, openDoc] = React.useState<'terms' | 'policy' | null>(null);

  const closeDoc = useCallback(() => {
    openDoc(null);
  }, []);

  const [focusOn, setFocusOn] = React.useState<'terms' | 'policy'>(() =>
    isTermsExists ? 'terms' : 'policy',
  );

  if (!isTermsExists && !isPolicyExists) {
    return null;
  }

  const onKeyUp: FocuserKeyHandler = (e) => {
    if (focusOn === 'terms') {
      return;
    }
    if (isTermsExists) {
      e.stop();
      setFocusOn('terms');
    }
  };

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (focusOn === 'policy') {
      return;
    }
    if (isPolicyExists) {
      e.stop();
      setFocusOn('policy');
    }
  };

  const renderOpenedDoc = () => {
    if (!openedDoc) {
      return null;
    }
    return (
      <DocViewPopup
        docType={ openedDoc }
        onBack={ closeDoc }
        onError={ closeDoc }
        language={ language }
      />
    );
  };

  return (
    <>
      <focuser.FocusableBlock
        isFocused
        noNeedForceFocus
        onKeyDown={ onKeyDown }
        onKeyUp={ onKeyUp }
      >
        {isTermsExists && (
          <SimpleButtonFocuser
            focuserClassName={ styles.button }
            emitForceFocusOnHover
            onForceFocus={ () => setFocusOn('terms') }
            isFocused={ focusOn === 'terms' }
            onClick={ () => openDoc('terms') }
            isPointerDownAvailable={ isPolicyExists }
          >
            {intl.formatMessage({ id: USER_AGREEMENT })}
          </SimpleButtonFocuser>
        )}
        {isPolicyExists && (
          <SimpleButtonFocuser
            isFocused={ focusOn === 'policy' }
            focuserClassName={ styles.button }
            emitForceFocusOnHover
            onForceFocus={ () => setFocusOn('policy') }
            onClick={ () => openDoc('policy') }
            isPointerUpAvailable={ isTermsExists }
          >
            {intl.formatMessage({ id: PRIVACY_POLICY })}
          </SimpleButtonFocuser>
        )}
      </focuser.FocusableBlock>
      {renderOpenedDoc()}
    </>
  );
});

AboutButtons.displayName = 'AboutButtons';
