import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import useSpatialNavigation, { NavigationLine } from '~hooks/useSpatialNavigation';
import {SCREEN_RESOLUTION_INFO} from '~localization';

import CheckBoxListItem, { PlayerPopupCheckBoxListItem } from './item';
import * as styles from './styles.module.css';


type Props = Readonly<{
  header: string;
  items: PlayerPopupCheckBoxListItem[];
  onClick: (index: number) => void;
  intl: InjectedIntl;
}>;


const CheckBoxList: React.FC<Props> = (props: Props) => {
  const navigationItems = React.useRef<NavigationLine[]>([]);

  const navState = useSpatialNavigation({
    allowNavigation: true,
    navigationItems: navigationItems.current,
  });

  React.useEffect(() => {
    const selectedIndex = props.items.findIndex(({ isChecked }) => (isChecked));

    if (selectedIndex > -1 && navigationItems[selectedIndex]) {
      navigationItems[selectedIndex].isFocused = true;
    }
    navigationItems.current = props.items.map((_, index) => ({
      maxIndex: 0,
      isFocused: (selectedIndex === index),
    }));
  }, [props.items]);

  const renderItems = React.useMemo(() => (
    props.items.map((item, index) => {
      const handleClick = () => {
        props.onClick(index);
      }

      return (
        <CheckBoxListItem
          key={ item.index }
          item={ item }
          isFocused={ (navState.focusOn === index) }
          onClick={ handleClick }
        />
      );
    })
  ), [props.items, navState.focusOn]);

  return (
    <div className={ styles.wrapperList }>
      <h2 className={ styles.header }>
        { props.header }
      </h2>
      <div className={ styles.list }>
        { renderItems }
      </div>
      <div className={ styles.text }>
        { props.intl.formatMessage({ id: SCREEN_RESOLUTION_INFO}) }:
      </div>
    </div>
  );
};


export default injectIntl(CheckBoxList);
