import messagesByLocale from 'customization-project-name/localization';
import * as React from 'react';

import { useVitalStoreFakeLoader } from '~app/global';
import { CODE_100501, useDevCodeListener } from '~components/DevManager/DevManager.hooks';
import { getLanguage } from '~components/Provider/App/component';
import { LocalizationProvider } from '~components/Provider/Localization';
import { PlatformProvider } from '~components/Provider/Platform';
import removeMainLoader from '~lib/removeMainLoader';

import { DevPageInError } from './DevPageInError';
import * as styles from './ErrorCrashScreen.module.css';
import { ErrorInfo } from './ErrorInfo';
import { ErrorMessage } from './ErrorMessage';
import { ErrorFallbackProps } from './types';

export const ErrorCrashScreen: React.FC<ErrorFallbackProps> = (props) => {
  const [language] = React.useState(() => getLanguage());
  const [isDevPageOpen, setIsDevPageOpen] = React.useState(false);

  React.useEffect(() => {
    removeMainLoader();
  }, []);

  useDevCodeListener({
    [CODE_100501]: () => setIsDevPageOpen(true),
  });

  const isLoaded = useVitalStoreFakeLoader();

  if (!isLoaded) {
    return null;
  }

  return (
    <PlatformProvider>
      <LocalizationProvider
        messagesByLocale={ messagesByLocale }
        language={ language }
      >
        {isDevPageOpen ? (
          <DevPageInError />
        ) : (
          <div className={ styles.wrapper }>
            <div className={ styles.errorMessage }>
              <ErrorMessage />
            </div>
            <div className={ styles.errorInfo }>
              {__BUILD_ENV__ !== 'production' && <ErrorInfo { ...props } />}
            </div>
          </div>
        )}
      </LocalizationProvider>
    </PlatformProvider>
  );
};
