import cn from "classnames";
import * as React from 'react';
import { InjectedIntl } from "react-intl";

import { getLineOptions, LineFocusInfo } from '~components/NavMenu/line/component.helpers';
import { RestLines } from "~components/NavMenu/line/restLines/line";
import { RootLineItem } from '~components/NavMenu/line/rootLine/rootLineItem';
import { useMenu } from '~components/Provider/Menu';
import useDelayedChange from '~hooks/useDelayedChange';
import useNavMenu from '~stores/NavMenu';
import MenuItem from '~typings/MenuItem';

import * as styles from '../styles.module.css'
import * as rootCSS from './styles.module.css'

interface Props {
  isVisible: boolean;
  isAuthorized: boolean | null;
  index: number;
  items: MenuItem[];
  breadCrumbs: LineFocusInfo[];
  onClick: (coordinates: LineFocusInfo) => void;
  intl: InjectedIntl;
}

const FirstLine = (props: Props) => {
  /**
   * у меню есть разные уровни (далее линии), они отличаются дизайном
   * всего может быть 4 линии меню в глубину
   * первая линия рендерится с помощью renderNavItems, и не меняется,
   * у первой линии свои стили и отдельные стили для внутренних элементов
   * первая линия так же рендерит другие линии с помощью Line2
   * Line2 компонент который вызывает сам себя тк дизайн второй и последующих линий и
   * элементов внутри - совпадает
   *
   * здесь все намазано useMemo/React.memo/useCallback это костыль который фиксит некоторые баги
   * плохой архитектуры компонента. кое где есть хардкод который так же фиксит баги(например props.index)
   * очень сложно работать с анимацией/отступами/версткой поэтому лучше ничего не трогать
   *
   * props.breadCrumbs - уровни меню и история хождения по меню
   * props.items - линия
   * props.index - уровень меню в текущем компоненте, в первом будет 0, во втором 1,  в следующих 1+N,
   * хардкод, трогать не надо, работает именно так.
   * focusInfo - инфа по фокусу в текущем компоненте, работает плохо, не передается в другие компоненты
   *
   * ребилд компонента
   * с компонентом сложно работать, его нужно переделать, поскольку уровня всего 4 да и вряд-ли будет больше,
   * я бы захардкодил все уровни и ушел от рекурсии а так же сделал нормальную модульную навигацию
   * и возможно ушел от глобального использования компонента(придется зашивать меню на каждой странице)
   */
  const isHovered = useNavMenu(state => state.isHovered);
  const {
    subMenu,
    focusInfo,
    isFocused,
    isHideOnTop,
    isCSSAnimationAvailable,
  } = getLineOptions(props.index, props.items, props.breadCrumbs);
  const isVisibleSubMenu = useDelayedChange<boolean>(!!subMenu, 100);
  const { isMenuOpened } = useMenu();

  const rootLineItems = React.useMemo(() => (
    props.items.map((item: MenuItem, index: number) => (
      <RootLineItem
        isAuthorized={ props.isAuthorized }
        key={ item.id }
        id={ item.id }
        icon={ item.icon }
        image={ item.image }
        isFocused={ (isMenuOpened && isFocused && !isHovered && index === focusInfo.x) }
        isActive={ isHovered && isFocused && index === focusInfo.x }
        title={ item.title }
        link={ item.link }
        index={ index }
        lineIndex={ props.index }
        onClick={ props.onClick }
        intl={ props.intl }
      />
    ))
  ), [props.isAuthorized, props.items, isHovered, isFocused, focusInfo.x, isMenuOpened]);

  const restLines = React.useMemo(() => {
    if (subMenu) {
      return (
        <div>
          <RestLines
            isAuthorized={ props.isAuthorized }
            isVisible={ isVisibleSubMenu }
            items={ subMenu }
            index={ 1 }
            breadCrumbs={ props.breadCrumbs }
            onClick={ props.onClick }
          />
        </div>
      );
    }

    return null;
  }, [
    props.isAuthorized,
    isVisibleSubMenu,
    subMenu,
    props.index,
    props.breadCrumbs,
  ]);

  return (
    <>
      <div
        className={ cn(rootCSS.rootLineItemsWrapper, {
          [styles.navMenuLineWithAnimation]: isCSSAnimationAvailable,
          [styles.navMenuLineVisible]: props.isVisible,
          [styles.navMenuLineHideOnTop]: isHideOnTop,
        }) }
      >
        { rootLineItems }
      </div>
      { restLines }
    </>
  );
};


export const RootLine = React.memo(FirstLine)
