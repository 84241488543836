import { useEffect, useLayoutEffect, useRef } from 'react';

import HotKey from '~typings/HotKey';
import NavigationDirection from '~typings/NavigationDirection';

import { useIsWrongNestingContext } from './context';
import {
  FOCUSER_CLICK_EVENT_NAME,
  FOCUSER_FORCE_FOCUS_EVENT_NAME,
  FOCUSER_KEY_EVENT_NAME,
  FOCUSER_POINTER_EVENT_NAME,
  FocuserClickEventDetail,
  FocuserForceFocusEventDetail,
  FocuserKeyEventDetail,
  FocuserPointerEventDetail,
} from './events';
import { simulateClickOnNode, simulateFocusEventOnNode, simulatePointerEventOnInnerMost } from './KeyDownHandler/utils';

export type CustomEventCallbacks = {
  /**
   * Обработчик кнопки "вверх"
   */
  onKeyUp?: (eventDetail: FocuserKeyEventDetail) => void;
  /**
   * Обработчик кнопки "вниз"
   */
  onKeyDown?: (eventDetail: FocuserKeyEventDetail) => void;
  /**
   * Обработчик кнопки "влево"
   */
  onKeyLeft?: (eventDetail: FocuserKeyEventDetail) => void;
  /**
   * Обработчик кнопки "вправо"
   */
  onKeyRight?: (eventDetail: FocuserKeyEventDetail) => void;
  /**
   * Обработчик кнопки "назад"
   */
  onKeyReturn?: (eventDetail: FocuserKeyEventDetail) => void;
  /**
   * Обработчик нажатия для всех горячих клавиш кроме
   * "вверх", "вниз", "влево", "вправо", "назад"
   */
  onKeyPress?: (eventDetail: FocuserKeyEventDetail, hotKey: HotKey) => void;
  /**
   * Обработчик клика
   */
  onClick?: (eventDetail: FocuserClickEventDetail) => void;
  /**
   * Обработчик для force-focus, вызывается только когда этот блок не сфокусирован (то есть isFocused === false)
   */
  onForceFocus?: (eventDetail: FocuserForceFocusEventDetail) => void;
};

export type AvailablePointerDirections = {
  /**
   * Есть ли возможность нажать виртуальную стрелку "влево"
   */
  isPointerLeftAvailable?: boolean;
  /**
   * Есть ли возможность нажать виртуальную стрелку "вправо"
   */
  isPointerRightAvailable?: boolean;
  /**
   * Есть ли возможность нажать виртуальную стрелку "вверх"
   */
  isPointerUpAvailable?: boolean;
  /**
   * Есть ли возможность нажать виртуальную стрелку "вниз"
   */
  isPointerDownAvailable?: boolean;


  /**
   *
   * Виртуальная стрелка назад
   *
   * `undefined` - то виртуальная стрелка назад не будет затронута
   *
   * `null` - виртуальная стрелка не будет отображаться
   *
   * `back` - стрелка "назад"
   *
   * `close` - стрелка "закрыть"
   *
   * `menu` - стрелка "меню"
   */
  returnButtonType?: 'back' | 'close' | 'menu' | null;

};

export type CustomEventsHandlerProps = CustomEventCallbacks & AvailablePointerDirections;

export type CustomEventsHandlerFocusProps = {
  htmlElementRef: React.RefObject<HTMLElement>;
  isFocusedFromProps: boolean;
  isFocusedFinnally: boolean;
};

export const useCustomEventsHandler = (
  props: CustomEventsHandlerProps,
  htmlElementProps: CustomEventsHandlerFocusProps,
) => {
  const propsRef = useRef<CustomEventsHandlerProps>(props);
  const focusPropsRef = useRef<CustomEventsHandlerFocusProps>(htmlElementProps);

  propsRef.current = props;
  focusPropsRef.current = htmlElementProps;

  /** KEYBOARD CUSTOM EVENT */
  useEffect(() => {
    const eventHandler = (event: Event) => {
      const customEvent = event as CustomEvent<FocuserKeyEventDetail>;
      const { keyCode } = customEvent.detail;

      const handlers = propsRef.current;

      switch (keyCode) {
        case HotKey.KEY_UP:
          if (handlers.onKeyUp) {
            handlers.onKeyUp(customEvent.detail);
            return;
          }
          break;

        case HotKey.KEY_DOWN:
          if (handlers.onKeyDown) {
            handlers.onKeyDown(customEvent.detail);
            return;
          }
          break;

        case HotKey.KEY_LEFT:
          if (handlers.onKeyLeft) {
            handlers.onKeyLeft(customEvent.detail);
            return;
          }
          break;

        case HotKey.KEY_RIGHT:
          if (handlers.onKeyRight) {
            handlers.onKeyRight(customEvent.detail);
            return;
          }
          break;

        case HotKey.KEY_RETURN:
          if (handlers.onKeyReturn) {
            handlers.onKeyReturn(customEvent.detail);
            return;
          }
          break;

        default:
          handlers.onKeyPress?.(customEvent.detail, keyCode);
      }
    };

    focusPropsRef.current.htmlElementRef.current?.addEventListener(
      FOCUSER_KEY_EVENT_NAME,
      eventHandler,
    );
    return () => {
      focusPropsRef.current.htmlElementRef.current?.removeEventListener(
        FOCUSER_KEY_EVENT_NAME,
        eventHandler,
      );
    };
  }, []);

  /** CLICK CUSTOM EVENT */
  useEffect(() => {
    const eventHandler = (event: Event) => {
      const customEvent = event as CustomEvent<FocuserClickEventDetail>;
      const handlers = propsRef.current;
      handlers.onClick?.(customEvent.detail);
      return;
    };

    focusPropsRef.current.htmlElementRef.current?.addEventListener(
      FOCUSER_CLICK_EVENT_NAME,
      eventHandler,
    );
    return () => {
      focusPropsRef.current.htmlElementRef.current?.removeEventListener(
        FOCUSER_CLICK_EVENT_NAME,
        eventHandler,
      );
    };
  }, []);

  /** CLICK NATIVE EVENT */
  useEffect(() => {
    const eventHandler = (event: Event) => {
      const focuserClickParsedProperty = 'focuser-click-parsed';
      if (event[focuserClickParsedProperty]) {
        // Событие уже обработано, останавливаем нативное браузерное событие
        return;
      }
      event[focuserClickParsedProperty] = true;
      if (focusPropsRef.current.htmlElementRef.current) {
        simulateClickOnNode(focusPropsRef.current.htmlElementRef.current, event, true);
      }
      return;
    };

    focusPropsRef.current.htmlElementRef.current?.addEventListener('click', eventHandler);
    return () => {
      focusPropsRef.current.htmlElementRef.current?.removeEventListener('click', eventHandler);
    };
  }, []);

  /** FORCE-FOCUS CUSTOM EVENT */
  useEffect(() => {
    const eventHandler = (event: Event) => {
      if(focusPropsRef.current.isFocusedFinnally) {
        // Наша ветка уже сфокусирована, останавливаем событие
        event.stopImmediatePropagation();
        return;
      }
      const customEvent = event as CustomEvent<FocuserForceFocusEventDetail>;
      const handlers = propsRef.current;
      handlers.onForceFocus?.(customEvent.detail);
      return;
    };

    focusPropsRef.current.htmlElementRef.current?.addEventListener(
      FOCUSER_FORCE_FOCUS_EVENT_NAME,
      eventHandler,
    );
    return () => {
      focusPropsRef.current.htmlElementRef.current?.removeEventListener(
        FOCUSER_FORCE_FOCUS_EVENT_NAME,
        eventHandler,
      );
    };
  }, []);

  /** POINTER CUSTOM EVENT */
  useEffect(() => {
    const eventHandler = (event: Event) => {
      const customEvent = event as CustomEvent<FocuserPointerEventDetail>;
      if(customEvent.detail.isBlockedByIsolation){
        return;
      }
      const availableDirections = propsRef.current;
      if (availableDirections.isPointerUpAvailable) {
        customEvent.detail.addDirection(NavigationDirection.Up);
      }
      if (availableDirections.isPointerDownAvailable) {
        customEvent.detail.addDirection(NavigationDirection.Down);
      }
      if (availableDirections.isPointerRightAvailable) {
        customEvent.detail.addDirection(NavigationDirection.Right);
      }
      if (availableDirections.isPointerLeftAvailable) {
        customEvent.detail.addDirection(NavigationDirection.Left);
      }

      if(customEvent.detail.returnButtonType === undefined) {
        if(availableDirections.returnButtonType !== undefined) {
          customEvent.detail.returnButtonType = availableDirections.returnButtonType;
        }
      }
    };

    focusPropsRef.current.htmlElementRef.current?.addEventListener(
      FOCUSER_POINTER_EVENT_NAME,
      eventHandler,
    );
    return () => {
      focusPropsRef.current.htmlElementRef.current?.removeEventListener(
        FOCUSER_POINTER_EVENT_NAME,
        eventHandler,
      );
    };
  }, []);
};

export type UseErrorsLoggerProps = CustomEventCallbacks &
  {
    ref: React.RefObject<HTMLElement>;
    noNeedForceFocus?: boolean;
    isCurrentBlockForceFocusEnabled: boolean;
  };

export const useErrorsLogger = ({
  ref,
  onForceFocus,
  noNeedForceFocus,
  isCurrentBlockForceFocusEnabled,
}: UseErrorsLoggerProps) => {
  const errorsLoggerRef = useRef<{
    isWrongContextLogged: boolean;
    isForceFocusErrorLogged: boolean;
  }>({ isWrongContextLogged: false, isForceFocusErrorLogged: false });

  const isWrongNestingContext = useIsWrongNestingContext();

  if (ref.current && isWrongNestingContext) {
    if (!errorsLoggerRef.current.isWrongContextLogged) {
      console.error(
        'FOCUSER: Блок не может быть вложенным в другой блок у которого есть prop [isLastElement == true]',
        ref.current,
      );
      errorsLoggerRef.current.isWrongContextLogged = true;
    }
  }

  if (ref.current && isCurrentBlockForceFocusEnabled && !onForceFocus && !noNeedForceFocus) {
    if (!errorsLoggerRef.current.isForceFocusErrorLogged) {
      logForceFocusMissingErrorForBlock(ref.current);
      errorsLoggerRef.current.isForceFocusErrorLogged = true;
    }
  }
};

const logForceFocusMissingErrorForBlock = (htmlElement: HTMLElement) => {
  console.error(
    `FOCUSER: По контексту БЛОК должен иметь [onForceFocus].
Либо измените контекст выше, через prop [isForceFocusEnabledInBranch],
либо передайте [onForceFocus], либо укажите явно, что блок не нуждается в forceFocus-е
с помощью "noNeedForceFocus". Блок =>`,
    htmlElement,
  );
};

type UseForceFocusEmitterProps = {
  htmlElementRef: React.RefObject<HTMLElement>;
  emitForceFocus: boolean;
}

/** FORCE FOCUS EMITTER */
export const useForceFocusEmitter = (props: UseForceFocusEmitterProps) => {
  useEffect(() => {
    if (!props.emitForceFocus) {
      return;
    }
    const eventHandler = (event: Event) => {
      if (props.htmlElementRef.current) {
        simulateFocusEventOnNode(props.htmlElementRef.current, event);
      }
    };

    props.htmlElementRef.current?.addEventListener('mouseenter', eventHandler);
    return () => {
      props.htmlElementRef.current?.removeEventListener('mouseenter', eventHandler);
    };
  }, [props.emitForceFocus]);
};

export type UsePointerEventEmitterProps = {
  isCurrentBlockFocused: boolean;
  isCurrentBlockPointerEnabled: boolean;
}

/** POINTER EVENT EMITTER */
export const usePointerEventEmitter = (
  props: UsePointerEventEmitterProps & AvailablePointerDirections,
) => {
  useLayoutEffect(() => {
    if (props.isCurrentBlockFocused && props.isCurrentBlockPointerEnabled) {
      simulatePointerEventOnInnerMost();
    }
  }, [
    props.isCurrentBlockFocused,
    props.isCurrentBlockPointerEnabled,
    props.isPointerDownAvailable,
    props.isPointerUpAvailable,
    props.isPointerLeftAvailable,
    props.isPointerRightAvailable,
    props.returnButtonType,
  ]);

  useEffect(() => {
    return () => {
      simulatePointerEventOnInnerMost();
    };
  }, []);
};
