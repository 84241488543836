type RightHolder = {
  id: string;
  name: string;
  object: 'right_holder';
}

export enum RightHolderType {
  Amediateka = 'amediateka',
  IVI = 'ivi',
  Megogo = 'megogo',
  Start = 'start.ru',
};

export default RightHolder;
