import React, { useEffect, useState } from 'react';

import { InfinityProgramEvents } from '~hooks/fetch/useProgramEvents/useProgramEvents';

import { EventsListFocusedState } from '../../../../EPGPageCommon.types';
import { dataWeekDayId } from '../../../../EPGPageCommon.utils';
import { EPGDayHeader } from '../EPGDayHeader';

export type Props = {
  className?: string;
  focusedState: EventsListFocusedState;
  pages?: InfinityProgramEvents[];
};

const EPGStickyDayHeader$: React.FC<Props> = ({ className, focusedState, pages }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [stickyPage, setStickyPage] = useState<InfinityProgramEvents | null>(focusedState.focusedPage);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (!focusedState || !pages?.length) {
      return;
    }

    // Получаем все отрендеренные даты
    const elements = document.querySelectorAll(`[${dataWeekDayId}]`);

    const mappedElements = Array.from(elements)
      .map((el) => {
        return {
          el,
          pivotDate: el.getAttribute(dataWeekDayId) as string,
        };
      })
      .filter((el) => el.pivotDate);

    const divBottom = ref.current.getBoundingClientRect().bottom;

    // Находим первый элемент, который выше нашего div-а, то есть выше sticky даты
    const renderedElementAboveSticky = mappedElements
      .slice()
      .reverse()
      .find((elem) => {
        const bottom = elem.el.getBoundingClientRect().bottom;
        if (bottom < divBottom) {
          return elem;
        }
      });

    // Если нашли, ищем его страницу, и выставляем как состояние
    if (renderedElementAboveSticky) {
      const page = pages.find((page) => page.pivotDate === renderedElementAboveSticky.pivotDate);
      if (!page) {
        return;
      }

      setStickyPage(page);
      return;
    }

    // Если не нашли, значит нам мешает виртуализация

    // Берем первый отрендеренный элемент (он будет выше всех отрендерен, но не выше sticky даты)
    const lastRendered = mappedElements[0];

    // Если его нет, то значит у нас много отрендеренных ивентов, и это тот сценарий,
    // когда сфокусированную страницу и нужно отображать как sticky дату
    if (!lastRendered) {
      setStickyPage(focusedState.focusedPage);
      return;
    }

    // Если нашли, то ищем его страницу
    const lastRenderedForPageIdx = pages.findIndex(
      (page) => page.pivotDate === lastRendered.pivotDate,
    );

    if (lastRenderedForPageIdx === -1) {
      setStickyPage(focusedState.focusedPage);
      return;
    }

    // Берем предыдыщую страницу, она как раз и будет в качестве sticky даты
    const previousPage = pages[lastRenderedForPageIdx - 1];

    if (!previousPage) {
      setStickyPage(focusedState.focusedPage);
      return;
    }

    setStickyPage(previousPage);
  }, [focusedState, pages]);

  return (
    <>
      <div className={ className }>
        {stickyPage && <EPGDayHeader pivotRawDate={ stickyPage.pivotRawDate }></EPGDayHeader>}
      </div>
      <div ref={ ref } />
    </>
  );
};

/**
 * Компонент показывает дату над слайдером, в странице EPG
 * Он смотрит, какая дата была последней отрендеренна, и на этой основе рендерит дату над слайдером
 */
export const EPGStickyDayHeader = EPGStickyDayHeader$;
