import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { ProfilesSwitcher } from '~components/ProfileSwitcher';
import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { PROFILES_WHO_IS_WATHCING } from '~localization';

import * as styles from './styles.module.css';


type Props = Readonly<{
  intl: InjectedIntl;
}>;


const ProfilesSwitcherPage: React.FC<Props> = (props: Props) => {
  const app = useApp();
  const appAction = useAppAction();

  React.useEffect(() => {
    if (!app.isLaunchingDone) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: {isLaunchingDone: true},
      });
    }
  }, []);

  return (
    <div className={ styles.profilesPage }>
      <div className={ styles.contentWrapper }>
        <div className={ styles.headerTitle }>
          { `${ props.intl.formatMessage({id: PROFILES_WHO_IS_WATHCING}) }?` }
        </div>
        <ProfilesSwitcher/>
      </div>
    </div>
  );
};


export default injectIntl(ProfilesSwitcherPage);
