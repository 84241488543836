import { useEffect, useState } from "react";

import { useLastViewedEpisode } from "~hooks/fetch/useLastViewedEpisode/useLastViewedEpisode";
import Series from "~typings/Series";



type State = {
  status: 'initial'
} | {
  status: 'waiting-for-last-episode'
} | {
  status: 'ready';
  episodeIdx: number;
  seasonIdx: number;
  isNeedToShowInitialFocus: boolean;
}

export const useInitialFocuses = (series: Series, selectedEpisodeID?: string) => {

  const [state, setState] = useState<State>({ status: 'initial' });


  const { nextEpisode: lastViewedEpisode, query  } = useLastViewedEpisode(
    state.status === 'waiting-for-last-episode' ? series.id : undefined,
  );

  useEffect(() => {
    /// Обработка начального состояния
    if(state.status !== 'initial'){
      return;
    }

    if(selectedEpisodeID) {
      const { episodeIdx, seasonIdx, isFound } = getIndexesOfEpisode(series, selectedEpisodeID);
      if(isFound) {
        setState({ status: 'ready', episodeIdx, seasonIdx, isNeedToShowInitialFocus: true });
        return;
      }
    }

    setState({ status: 'waiting-for-last-episode' });
  }, [selectedEpisodeID, series, state.status]);


  useEffect(() => {
    if(state.status !== 'waiting-for-last-episode'){
      return;
    }

    if(query.status === 'loading') {
      return;
    }

    if(query.status === 'idle' || query.status === 'error') {
      setState({ status: 'ready', episodeIdx: 0, seasonIdx: 0, isNeedToShowInitialFocus: false });
      return;
    }

    if(lastViewedEpisode) {
      const { episodeIdx, seasonIdx, isFound } = getIndexesOfEpisode(series, lastViewedEpisode.id);
      if(isFound) {
        setState({ status: 'ready', episodeIdx, seasonIdx, isNeedToShowInitialFocus: true });
        return;
      }
    }
    setState({ status: 'ready', episodeIdx: 0, seasonIdx: 0, isNeedToShowInitialFocus: false });
  }, [lastViewedEpisode, series, state.status, query.status ]);



  return state.status === 'ready' ? state : undefined;

}

const getIndexesOfEpisode = (series: Series, selectedEpisodeID: string) => {
  let episodeIdx = 0;
  let seasonIdx = 0;
  let isFound = false;

  for (let i = 0; i < series.seasons.length; i++) {
    const season = series.seasons[i];
    for (let j = 0; j < season.episodes.length; j++) {
      if (season.episodes[j].id === selectedEpisodeID) {
        isFound = true;
        episodeIdx = j;
        seasonIdx = i;
        break;
      }
    }
    if (isFound) {
      break;
    }
  }

  return {
    episodeIdx,
    seasonIdx,
    isFound,
  };
};
