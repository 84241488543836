import * as React from 'react';
import { useHistory } from 'react-router-dom';

import CatchBack from '~components/PageManager/CatchBack';
import { ActionType as AppActionType, useAppAction } from '~components/Provider/App';
import { ActionType as MenuActionType, useMenuAction } from '~components/Provider/Menu';
import { useScene } from '~components/Provider/Scene';
import SideBar from '~components/SideBar';
import useSideBarNavigation, { FOCUS_ON } from '~stores/SideBarNavigation';
import MenuItem from '~typings/MenuItem';
import NavigationDirection from '~typings/NavigationDirection';
import { PageNavigation } from '~ui/PageNavigation/PageNavigation';

import { useAllowNavigation } from './useAllowNavigation';


type Props = Readonly<{
  layoutID: string;
  header?: string | JSX.Element;
  menuItems: MenuItem[];
  menuFocusedIndex: number;
  navigationItemsContent: boolean;
}>;


const SideBarLayout = (props: Props) => {
  const {
    isMenuOpened,
    isLaunchingDone,
    allowNavigation,
    isPlayerPage,
    isSceneMediaItemPageMode,
  } = useAllowNavigation();
  const menuAction = useMenuAction();
  const appAction = useAppAction();
  const history = useHistory();
  const scene = useScene();
  const getNavigationOnLayout = useSideBarNavigation(st => st.getNavigationOnLayout);
  const setNavigationOnLayout = useSideBarNavigation(st => st.setNavigationOnLayout);
  const focusOn = useSideBarNavigation(st => st.getFocusOn());
  const setFocusOn = useSideBarNavigation(st => st.setFocusOn);
  const editMode = useSideBarNavigation(st => st.getEditMode());
  const setEditMode = useSideBarNavigation(st => st.setEditMode);

  React.useEffect(() => {
    if (!isLaunchingDone) {
      appAction({
        type: AppActionType.SetIsLaunchingDone,
        payload: { isLaunchingDone: true },
      });
    }
  }, []);

  React.useEffect(() => {
    if (getNavigationOnLayout() !== props.layoutID) {
      setNavigationOnLayout(props.layoutID);
    }
  }, [props.layoutID]);

  React.useEffect(() => {
    if (!isSceneMediaItemPageMode && !isPlayerPage) {
      scene.changeSceneMediaItem(null, false, true);
    }
  }, [isSceneMediaItemPageMode, isPlayerPage]);

  const handleBack = React.useCallback(() => {
    if (isSceneMediaItemPageMode || isPlayerPage) {
      history.goBack();
    }
    else if (editMode) {
      setEditMode(false);
    }
    else {
      menuAction({
        type: MenuActionType.ChangeMenuState,
        isMenuOpened: !isMenuOpened,
      });
    }
  }, [allowNavigation, isMenuOpened, isSceneMediaItemPageMode, isPlayerPage, editMode]);

  const menuNavigationItems = props.menuItems.map(() => ({ maxIndex: 0 }));

  const handleLeave = React.useCallback((direction: NavigationDirection) => {
    switch (direction) {
      case NavigationDirection.Up: {
        menuAction({
          type: MenuActionType.ChangeMenuState,
          isMenuOpened: !isMenuOpened,
        });
        break;
      }
      case NavigationDirection.Right: {
        if (props.navigationItemsContent) {
          setFocusOn(FOCUS_ON.CONTENT);
        }
        break;
      }
      default:
        break;
    }
  }, [props.navigationItemsContent]);

  const renderContent = () => {
    if (isSceneMediaItemPageMode || isPlayerPage) {
      return null;
    }


    return (
      <>
        {
          !editMode
            ? <PageNavigation
              layerID={ `${ props.layoutID }-side_menu` }
              isAllowedNavigation={ allowNavigation && focusOn === FOCUS_ON.LEFT_MENU }
              navigationItems={ menuNavigationItems }
              onLeave={ handleLeave }
            >
              {
                (focusPosition) => (
                  <SideBar
                    items={ props.menuItems }
                    isFocused={ allowNavigation && focusOn === FOCUS_ON.LEFT_MENU }
                    activeIndex={ allowNavigation && focusOn === FOCUS_ON.LEFT_MENU ? focusPosition.focusOn : null }
                    focusedIndex={ props.menuFocusedIndex }
                  />
                )
              }
            </PageNavigation>
            : null
        }
      </>
    );
  };

  return (
    <>
      <CatchBack action={ handleBack } triggers={ [handleBack] } />
      { renderContent() }
    </>
  );
};


export default React.memo(SideBarLayout);
