import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { getBigText } from "~components/ScenePlayer/Player/controls/PlayerMediaItemShortInfo/utils";
import { isChannel } from "~components/ScenePlayer/Player/utils";
import { arrBinarySearchCheck, useTodayProgramEvents } from "~hooks/fetch/useProgramEvents/useProgramEvents";
import { usePlayerStore } from "~stores/Player";
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';

import ProgramEventText from './ProgramEventText';
import SmallText from './SmallText';
import * as styles from './styles.module.css';


type Props = Readonly<{
  item: PlayerMediaItem;
  isVisiblePopup: boolean;
  intl: InjectedIntl;
}>;


const PlayerMediaItemShortInfo: React.FC<Props> = (props) => {
  // TODO: проверить функцию снизу на перфоманс по отношению к новой функции бинарного поиска
  //const currentEvent = useCurrentEvent((props.item.object === ItemObject.Channel) ? props.item.id : undefined);
  const setCurrentProgram = usePlayerStore(state => state.setCurrentProgram)
  const isLive = usePlayerStore(state => state.isLive)
  const currentProgram = usePlayerStore(state => state.currentProgram) || null
  const searchDate = usePlayerStore(state => state.searchDate)
  const { isLoading } = useTodayProgramEvents(isChannel(props.item) ? props.item.id : undefined);
  const playPauseButton = usePlayerStore(state => state.playPauseButton)

  React.useEffect(() => {
    if (isLive) {
      const currentProgram = arrBinarySearchCheck(props.item.id)
      setCurrentProgram(currentProgram)
    }
  }, [isLoading, isLive])


  React.useEffect(() => {
    let timeOut

    if (currentProgram && playPauseButton && currentProgram.length === 2) {
      const futureDateStart = !currentProgram[1] ? currentProgram[0]['end_at'] : currentProgram[1]['start_at']
      const futureDateStartInMs = new Date(futureDateStart).getTime()
      const presentTime = new Date().getTime()
      let diff
      if (searchDate === 0) {
        diff = futureDateStartInMs - presentTime
      }
      else {
        diff = futureDateStartInMs - searchDate
      }
      timeOut = setTimeout(() => {
        const crnt = arrBinarySearchCheck(props.item.id, futureDateStartInMs)
        setCurrentProgram(crnt)
      }, diff + 5000)
    }
    return () => {
      clearTimeout(timeOut)
    }
  }, [currentProgram, playPauseButton])


  const bigText = currentProgram ? getBigText(props.item, currentProgram[0]) : null

  return (
    <div className={ styles.playerMediaItem }>
      <SmallText item={ props.item } intl={ props.intl } />
      {
        (bigText !== null)
          ? <h2 className={ cn(styles.bigText, { [styles.isVisiblePopup]: props.isVisiblePopup, }) }>
            { bigText }
          </h2>
          : <br />
      }
      {
        props.item.object === ItemObject.ProgramEvent && !props.isVisiblePopup
          ? <ProgramEventText event={ props.item } intl={ props.intl } />
          : null
      }
    </div>
  );
};


export default React.memo(injectIntl(PlayerMediaItemShortInfo));
