import React from 'react';
import { InfiniteData, useInfiniteQuery, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient, useConfig } from '~global';
import expand from '~hooks/fetch/expand';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import AppConfig from '~typings/AppConfig';
import Card from '~typings/Card';
import { DataItem, MediaItemType } from '~typings/DataItem';
import ResponseMany from '~typings/ResponseMany';

import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '../utils';

const PREFIX_OF_A_COMPOSITE_KEY = 'recommendations';
const DEFAULT_CONTEXT = '';
const FIRST_PAGE = 0;

const recommendationKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,

  withAccessToken: (accessToken: string | null, context: string) => [
    recommendationKeys.PREFIX,
    'user-rec', // Нужно для разделение обычных рекомендаций от movies/series
    accessToken,
    context,
  ],
  firstNormalPage: (accessToken: string | null, context: string, limit: number) => [
    ...recommendationKeys.withAccessToken(accessToken, context),
    'normal-pages-fist-page',
    limit,
  ],

  infinityPages: (accessToken: string | null, limit: number) => [
    ...recommendationKeys.withAccessToken(accessToken, DEFAULT_CONTEXT),
    'infinity-pages',
    limit,
  ],
};

type FetchParams = {
  client: ApiClient;
  accessToken: string | null;
  page: number;
  context: string;
  limit: number;
};

const fetch = async ({ client, accessToken, page, context, limit }: FetchParams) => {
  let url = `/v3/user/recommendations`;
  const options = {
    access_token: accessToken,
    context,
    'filter[resource_type_in]': 'movies,series',
    'expand[movie]': expand.movie,
    'expand[series]': expand.series,
    'page[offset]': page * limit,
    'page[limit]': limit,
  };

  return await client.get(url, options);
};

const fetchInfinityPages = (params: Omit<FetchParams, 'context'>) => {
  if (params.page === FIRST_PAGE) {
    // Если мы запрашиваем первую страницу, и она уже загружена, то можно ее вернуть,
    // она идиентична
    const firstPage = queryClient.getQueryData<ResponseMany<Card[]>>(
      recommendationKeys.firstNormalPage(params.accessToken, DEFAULT_CONTEXT, params.limit),
    );

    if (firstPage) {
      return firstPage;
    }
  }

  return fetch({
    ...params,
    context: DEFAULT_CONTEXT,
  });
};

const fetchFirstPage = (params: Omit<FetchParams, 'page'>) => {
  const infinityPages = queryClient.getQueryData<InfiniteData<ResponseMany<DataItem[]>>>(
    recommendationKeys.infinityPages(params.accessToken, params.limit),
  );
  const firstPageInInfinityPages = infinityPages?.pages[0];
  if (firstPageInInfinityPages) {
    // Если мы загрузили "бесконечные страницы", то можно вернуть первую из них,
    // она полностью идиентична тому, что нужно запросить
    return firstPageInInfinityPages;
  }
  return fetch({
    ...params,
    page: FIRST_PAGE,
  });
};

const getRecsFirstPageFromCache = (config: AppConfig, context?: string) => {
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(config);
  return queryClient.getQueryData<ResponseMany<Card[]>>(
    recommendationKeys.firstNormalPage(accessToken, context ?? DEFAULT_CONTEXT, limit),
  );
};

const getRecsFirstPageQueryParams = (client: ApiClient, context = '', config: AppConfig) => {
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(config);
  return {
    queryFn: () =>
      fetchFirstPage({
        client,
        accessToken,
        context,
        limit,
      }),
    queryKey: recommendationKeys.firstNormalPage(accessToken, context, limit),
    enabled: !!accessToken && config.smartTV.recommenderEngineEnable,
  };
};

const useRecommendationsFirstPage = (context = '') => {
  const client = useClient();
  const config = useConfig();

  const queryParams = getRecsFirstPageQueryParams(client, context, config);

  return useQuery<ResponseMany<DataItem[]>>(queryParams);
};

const useInfinityRecommendations = (params?: {enabled: boolean}) => {
  const enabled = params?.enabled ?? true;
  const client = useClient();
  const { accessToken } = getAuthData();
  const config = useConfig();
  const recommenderEngineEnable = config.smartTV.recommenderEngineEnable;
  const recsLimit = getLimitFromConfig(config);

  const query = useInfiniteQuery<ResponseMany<DataItem[]>>({
    queryKey: recommendationKeys.infinityPages(accessToken, recsLimit),
    queryFn: ({ pageParam = 0 }) =>
      fetchInfinityPages({ client, accessToken, page: pageParam, limit: recsLimit }),
    getNextPageParam: getNextPageForInfinityPagination,
    enabled: Boolean(accessToken) && recommenderEngineEnable && enabled,
  });

  const parsedData: ResponseMany<MediaItemType[]> | undefined = React.useMemo(() => {
    return query.data?.pages && reducePaginatedPages(query.data.pages);
  }, [query.data?.pages]);

  return { query, parsedData, isExist: Boolean(parsedData?.meta.pagination.total) };
};

export {
  getRecsFirstPageFromCache,
  getRecsFirstPageQueryParams,
  PREFIX_OF_A_COMPOSITE_KEY,
  recommendationKeys,
  useInfinityRecommendations,
  useRecommendationsFirstPage,
};
