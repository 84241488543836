import * as React from "react";


function ArrowDown(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 21 21" fill="none" { ...props }>
      <path d="M1 6L10.5001 16L20.0001 6" stroke="currentColor" strokeWidth="2"/>
    </svg>
  );
}


export default React.memo(ArrowDown);
