import { applyHotKeyOrClickOnInnerMost } from '@focuser';
import * as cn from 'classnames';
import { throttle } from 'lodash';
import * as React from 'react';
import shallow from 'zustand/shallow';

import { getKeyNames } from '~components/Provider/KeyDownHandler/utils';
import {useMenu} from "~components/Provider/Menu";
import { usePlatform } from '~components/Provider/Platform';
import useWeel from '~hooks/useScroll/useWeel';
import useHotKeysStore from '~stores/HotKeys';
import usePointer from '~stores/Pointer';
import KeyCodes from '~typings/KeyCodes';
import NavigationDirection from '~typings/NavigationDirection';
import PointerNavigation from '~typings/PointerNavigation';
import IconArrow from '~ui/Icon/IconArrow';

import BackOrToggleMenuIcon from './BackOrToggleMenuIcon';
import * as styles from './styles.module.css';


export const getDirectionsKeyCode = (keyCodes: KeyCodes) => ({
  [NavigationDirection.Left]: keyCodes.KEY_LEFT!,
  [NavigationDirection.Right]: keyCodes.KEY_RIGHT!,
  [NavigationDirection.Up]: keyCodes.KEY_UP!,
  [NavigationDirection.Down]: keyCodes.KEY_DOWN!,
});


const PointerManagerWithHooks = () => {
  const menu = useMenu();
  const directions = usePointer((state) => ({
    [NavigationDirection.Up]: state.directions[NavigationDirection.Up],
    [NavigationDirection.Down]: state.directions[NavigationDirection.Down],
    [NavigationDirection.Left]: state.directions[NavigationDirection.Left],
    [NavigationDirection.Right]: state.directions[NavigationDirection.Right],
    [PointerNavigation.Menu]: state.directions[PointerNavigation.Menu],
    [PointerNavigation.Back]: state.directions[PointerNavigation.Back],
    [PointerNavigation.Close]: state.directions[PointerNavigation.Close],
  }), shallow);
  const applyHotKey  = useHotKeysStore(state => state.apply);
  const { keyCodes } = usePlatform();
  const keyNames = getKeyNames(keyCodes);

  const handleNavigate = React.useCallback(throttle<any>(
    (direction: NavigationDirection, e: any) => {
      if (typeof directions[direction] === 'function') {
        directions[direction]();
      } else {
        const keyName = getDirectionsKeyCode(keyCodes)[direction];
        const hotKey = keyNames[keyName];
        applyHotKey(hotKey, e);
        applyHotKeyOrClickOnInnerMost(hotKey, e?.nativeEvent || e);
      }
    },
    100,
    { leading: false },
  ), [keyCodes, keyNames, directions]);

  useWeel((ev) => {
    if (directions[NavigationDirection.Up] && ev.deltaY < 0) {
      handleNavigate(NavigationDirection.Up, ev);
    }
    if (directions[NavigationDirection.Down] && ev.deltaY > 0) {
      handleNavigate(NavigationDirection.Down, ev);
    }
  }, [directions]);

  const renderSpatialArrows = () => {
    if (menu.isMenuOpened) { return null; }

    return (
      <>
        <div
          className={
            cn(styles.button, styles.up, {
              [styles.visible]: directions[NavigationDirection.Up],
            })
          }
          onClick={ (e) => handleNavigate(NavigationDirection.Up, e) }
        >
          <IconArrow direction={ NavigationDirection.Up } classNames={ { wrapper: styles.icon } } />
        </div>
        <div
          className={
            cn(styles.button, styles.down, {
              [styles.visible]: directions[NavigationDirection.Down],
            })
          }
          onClick={ (e) => handleNavigate(NavigationDirection.Down, e) }
        >
          <IconArrow direction={ NavigationDirection.Down } classNames={ { wrapper: styles.icon } } />
        </div>
        <div
          className={
            cn(styles.button, styles.left, {
              [styles.visible]: directions[NavigationDirection.Left],
            })
          }
          onClick={ (e) => handleNavigate(NavigationDirection.Left, e) }
        >
          <IconArrow direction={ NavigationDirection.Left } classNames={ { wrapper: styles.icon } } />
        </div>
        <div
          className={
            cn(styles.button, styles.right, {
              [styles.visible]: directions[NavigationDirection.Right],
            })
          }
          onClick={ (e) => handleNavigate(NavigationDirection.Right, e) }
        >
          <IconArrow direction={ NavigationDirection.Right } classNames={ { wrapper: styles.icon } } />
        </div>
      </>
    );
  };

  return (
    <>
      <BackOrToggleMenuIcon
        directions={ directions }
        keyCodes={ keyCodes }
        keyNames={ keyNames }
        isMenuOpened={ menu.isMenuOpened }
      />
      { renderSpatialArrows() }
    </>
  );
};


export default PointerManagerWithHooks;
