import focuser from '@focuser';
import * as React from 'react';
import { injectIntl } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import { useAnimationContext } from '~components/Provider/Animation';
import ScrollBar from '~components/ScrollBar';
import { useScrollFocuser } from '~hooks/useScroll/useScrollFocuser';
import preparingText from '~lib/preparingText';
import Question from '~typings/Question';
import { PopupIsolated } from '~ui/Popup';

import * as styles from './QuestionPopup.module.css';
import { sectionTitles } from './utils';

export type Props = {
  question: Question;
  onBack: () => void;
};

export const QuestionPopup = injectIntl<Props>(({ onBack, question, intl }) => {
  const { isAnimationEnabled } = useAnimationContext();
  const animationDuration = isAnimationEnabled ? 200 : 0;

  const {
    wrapperRef,
    contentRef,
    isPointerDownAvailable,
    isPointerUpAvailable,
    onKeyDown,
    onKeyUp,
    scrollPercent,
    isScrollAvailable,
  } = useScrollFocuser({
    animationDurationInMS: animationDuration,
  });

  const answerHtmlCleaned = React.useMemo(
    () => question.answer && preparingText(question.answer),
    [question.answer],
  );

  return (
    <PopupIsolated focuserClassName={ styles.isolationContainer }>
      <div className={ styles.QuestionPopup }>
        <Typography
          variant="h3_bold"
          className={ styles.title }
        >
          {intl.formatMessage({ id: sectionTitles[question.section] })}
        </Typography>
        <Typography
          variant="h5_bold"
          className={ styles.question }
        >
          {question.questionSanitized}
        </Typography>
        <focuser.FocusableBlock
          isFocused
          onKeyDown={ onKeyDown }
          onKeyUp={ onKeyUp }
          isPointerDownAvailable={ isPointerDownAvailable }
          isPointerUpAvailable={ isPointerUpAvailable }
          noNeedForceFocus
          forwardRef={ wrapperRef }
          className={ styles.answerWrapper }
          onKeyReturn={ onBack }
          isLastBlock
        >
          <Typography
            variant="subtitle1_medium"
            className={ styles.answer }
          >
            <div
              ref={ contentRef }
              dangerouslySetInnerHTML={ { __html: answerHtmlCleaned + answerHtmlCleaned } }
            />
          </Typography>
        </focuser.FocusableBlock>
        {isScrollAvailable ? (
          <ScrollBar
            position={ scrollPercent }
            className={ styles.scrollbar }
            animationDurationInMS={ animationDuration }
            isFocused
          />
        ) : null}
      </div>
    </PopupIsolated>
  );
});
