import { PLAYER_TYPES } from 'platform-name/index';

import getProtocolByStreamURL from '~lib/getProtocol';
import { getItemName } from '~lib/mediaItem';
import DeviceInfo from '~typings/DeviceInfo';
import { DRMType } from '~typings/Drm';
import ItemObject from "~typings/ItemObject";
import Manifest from '~typings/Manifest';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import PlayerType from '~typings/PlayerType';
import Protocol from "~typings/Protocol";
import Stream from '~typings/Stream';


type Props = {
  manifest: Manifest;
  mediaItem: PlayerMediaItem;
  stream: Stream;
  device: DeviceInfo;
}

const getPlayerType = (props: Props): PlayerType => {
  /**
   * 1. Shaka
   * 2. Native
   *  - for .mp4 (adv)
   *  - for stream (Webos, Other)
   * 3. Native with drm (Webos)
   * 4. Tizen avplay
   */

  const isLive = (props.mediaItem.object === ItemObject.Channel);
  const isMSS = (/\/manifest/i.test(props.stream.url));
  const protocol = getProtocolByStreamURL(props.stream.url);
  const isWidevine = props.stream.drm.type === 'widevine';
  // For WebOS
  const isShaka = (
    isWidevine && (
      props.stream.player?.type === 'ivi'
      || props.stream.player?.type === 'amedia'
      || (props.stream.player?.type === 'megogo' && props.stream?.drm?.type !== DRMType.SPbTVCas)
    )
  );
  const osVersionNumber = parseInt((props.device.osVersion || '')
    .toLowerCase()
    .replace('tizen ', '')
    .split('.')[0], 10);
  const playerType = PLAYER_TYPES[__PLATFORM__];
  const audioTracksCount = (props.manifest.audio?.length || 0);

  return playerType ? playerType({
    isLive,
    is4K: (getItemName(props.mediaItem).search(/uhd|4k/) >= 0),
    isMP4: (protocol === Protocol.MP4),
    isWidevine,
    isShaka,
    isHLS: (protocol === Protocol.HLS),
    isWVM: (protocol === Protocol.WVM),
    isMSS,
    isDash: (protocol === Protocol.DASH),
    audioTracksCount,
    osVersionNumber,
    drm: props.stream.drm,
  }) : PlayerType.Native;
};


export default getPlayerType;
