import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import { CONFLICT_SUBSCRIPTION_WARNING } from '~localization';
import Plan from '~typings/Plan';
import Subscription from '~typings/Subscription';


type Props = {
  conflictedProducts: Array<Subscription | Plan> | undefined;
  intl: InjectedIntl;
}

const ConflictedSubscriptions: React.FC<Props> = ({ conflictedProducts, intl }) => {
  if (!conflictedProducts || conflictedProducts.length === 0) { return null; }

  const plans = conflictedProducts.map(plan => {
    if (plan.object === 'subscription') {
      return plan?.plan?.product?.name
    } else if (plan.object === 'plan') {
      return plan?.product?.name
    } else {
      return ''
    }
  });

  return (
    <div>
      {
        intl.formatMessage({ id:  CONFLICT_SUBSCRIPTION_WARNING })
      }
      <span style={ { fontWeight: 500 } }>
        {' '}
        { plans.join(', ') }
      </span>
    </div>
  );
};


export default ConflictedSubscriptions;
