// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".keyBoardRow--sds4F {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n}\n\n.searchIcon--QEaOH {\n  width: 2.656vw;\n  height: 2.656vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/SearchPageFocuser/keyBoard/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;AACf;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".keyBoardRow {\n  display: flex;\n}\n\n.searchIcon {\n  width: 2.656vw;\n  height: 2.656vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var keyBoardRow = "keyBoardRow--sds4F";
export var searchIcon = "searchIcon--QEaOH";
export default ___CSS_LOADER_EXPORT___;
