import * as React from 'react';

import { Filters } from '~components/Filters';
import { Block } from '~typings/Block';
import Page from '~typings/Page';

import * as styles from './styles.module.css';


type Props = Readonly<{
  page: Page;
  block: Block;
  isFocusedBlock: boolean;
  isPopupOpened: boolean;
  isVisible: boolean;
  focusedItemIndex: number;
  isTiledView?: boolean;
}>;

const BlockFilters = (props: Props) => {
  const { isTiledView } = props

  if (isTiledView) {
    return (
      <div className={ styles.filtersTiled }>
        <Filters
          ID={ props.page.id }
          isFocused={ (props.isFocusedBlock && !props.isPopupOpened) }
          focusedIndex={ props.focusedItemIndex }
          filterGroup={ props.block.search_filters_group?.items }
        />
      </div>
    )
  }

  return (
    <div className={ styles.filters }>
      <Filters
        ID={ props.page.id }
        isFocused={ (props.isFocusedBlock && !props.isPopupOpened) }
        focusedIndex={ props.focusedItemIndex }
        filterGroup={ props.block.search_filters_group?.items }
      />
    </div>
  );
};


export default React.memo(BlockFilters);
