/* eslint-disable max-len */

import * as React from 'react';

export const IconTop = () => {
  return (
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.64153 20.3072C5.84322 21.1628 4.48895 21.1769 3.6728 20.3383L3.57811 20.241C2.8162 19.458 2.80616 18.2162 3.55532 17.4211L14.0135 6.32186C14.412 5.89892 15.0835 5.89185 15.4909 6.3063L26.4147 17.4199C27.1923 18.211 27.1955 19.4759 26.4219 20.2709L26.3156 20.3801C25.5149 21.2029 24.1918 21.2072 23.3857 20.3895L14.744 11.6236L6.64153 20.3072Z" fill="white"/>
    </svg>
  );
};
