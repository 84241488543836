import cn from 'classnames';
import React from 'react';

import { ArrowRightIcon } from '~ui/Icon/icons/ArrowRight';

import * as styles from './SimpleButton.module.css';

export interface Props {
  className?: string;
  content?: React.ReactNode | React.ReactNode[];
  isFocused?: boolean;
  isDisabled?: boolean;
}

export const SimpleButton = ({ className, content, isFocused, isDisabled }: Props) => {
  const state = isDisabled ? 'disabled' : isFocused ? 'focused' : 'normal';

  return (
    <div className={ cn(styles.button, className, {[styles.buttonFocusable] : state !== 'disabled'}) }>
      <div
        className={ cn(styles.iconBox, {
          [styles.iconBoxFocused]: state === 'focused',
          [styles.iconBoxDisabled]: state === 'disabled',
        }) }
      >
        <ArrowRightIcon className={ styles.icon }></ArrowRightIcon>
      </div>
      <div
        className={ cn(styles.text, {
          [styles.textFocused]: state === 'focused',
          [styles.textDisabled]: state === 'disabled',
        }) }
      >
        {content}
      </div>
    </div>
  );
};
