import { orderBy } from 'lodash';
import { useMemo } from 'react';

import { useChannels } from '~components/Provider/Channels';
import { useAutoInfinityCardColItems } from "~hooks/fetch/useCardCollectionsItems";
import { useAutoInfinityChannels } from '~hooks/fetch/useChannels';
import { useAutoInfinityFavorites } from '~hooks/fetch/useFavorites/useFavoritesV3';
import useNavigation, { pageWeComeFromTS } from "~stores/Navigation";
import Channel from '~typings/Channel';
import FavoritesType from '~typings/FavoriteType';


const useSwitchChannelsList = (): Channel[] => {
  const pageWeComeFrom = useNavigation(state => state.pageWeComeFrom);
  const weComeFromFavorites = [pageWeComeFromTS.sliderFavorites, pageWeComeFromTS.favorites].includes(pageWeComeFrom);
  const weComeFromCardCollection = [
    pageWeComeFromTS.sliderCardCollection,
    pageWeComeFromTS.cardCollection,
  ].includes(pageWeComeFrom);

  const { collectionID } = useChannels();
  const { parsedData: allCardColItems } = useAutoInfinityCardColItems({
    cardCollectionID: collectionID,
    enabled: weComeFromCardCollection
  });

  const { parsedData: allFavorites } = useAutoInfinityFavorites({
    types: [FavoritesType.Channels],
    enabled: weComeFromFavorites
  });


  const channels = useAutoInfinityChannels();

  const allChannels = useMemo(
    () => orderBy(channels.data?.data || [], ['position'], ['asc']) as Channel[],
    [channels.data?.data],
  );

  if (weComeFromFavorites) {
    const cachedFavorites = allFavorites?.data || [];
    const onlyFavorites = cachedFavorites.filter(item => item.card?.resource_type === "channel")

    const filteredChannels = allChannels
      .filter(channel => onlyFavorites.some(item => item.card?.resource_id === channel.id))

    const sortFiltered = filteredChannels.sort((a, b) => {
      const first = onlyFavorites.findIndex(e => e.card?.resource_id === a.id)
      const second = onlyFavorites.findIndex(e => e.card?.resource_id === b.id)
      return first < second ? -1 : 1
    })

    return sortFiltered;
  }


  if (weComeFromCardCollection && collectionID) {
      const cachedItems = allCardColItems?.data || [];
      const onlyChannels = cachedItems.filter(item => item.resource_type === "channel")

      const filteredChannels = allChannels
        .filter(channel => onlyChannels.some(object => object.resource_id === channel.id))

      const sortFiltered = filteredChannels.sort((a, b) => {
        const first = onlyChannels.findIndex(e => e.resource_id === a.id)
        const second = onlyChannels.findIndex(e => e.resource_id === b.id)
        return first < second ? -1 : 1
      })
      return sortFiltered
  }

  return allChannels
};

export default useSwitchChannelsList;
