import * as cn from 'classnames';
import * as React from 'react';
import { animated } from 'react-spring';

import * as styles from './styles.module.css';

type Props = {
  isFocused: boolean;
  onClick?: () => void;
  heightType?: 'full' | 'small';
};

function DescriptionWrapper(props: React.PropsWithChildren<Props>) {
  const { isFocused, onClick, children } = props

  return (
    <animated.div
      className={ cn(styles.description,
        {
          'focusedNavigationNode': isFocused,
          [styles.focused]: isFocused
        })
      }
      onClick={ onClick }
    >
      { children }
    </animated.div>
  );
}

export default React.memo(DescriptionWrapper);
