import { QueryKey, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import getProtocolByStreamURL from '~lib/getProtocol';
import parseManifestMSS from '~lib/player/manifest/parsing/mss';
import { ManifestParser, parseManifestM3u8, parseManifestMpd } from '~lib/player/utils/manifest-parser';
import { getSubTitlesStructure, parseTTML, parseVTT } from '~lib/player/utils/subtitle';
import ApiError from '~typings/ApiError';
import Protocol from '~typings/Protocol';


type Errors = Readonly<{
  errors: ApiError[];
}>;

type Track = {
  start: number;
  end: number;
  text: string;
};

type TextTrack = {
  name: 'off' | string;
  subTitle: Track[] | null;
};

type Props = Readonly<{
  streamURL: string | null | undefined;
}>;

type ResParsedManifest = Readonly<{
  trackList: TextTrack[],
  parsedManifest: ManifestParser | null,
  fetchResponse?: any
}>


const getQueryKey = (streamURL: Props['streamURL']): QueryKey => [
  'track_list',
  streamURL,
];

const getCachedTrackList = (streamURL: Props['streamURL']) => {
  if (streamURL) {
    return undefined;
  }

  const queryKey = getQueryKey(streamURL);
  const cachedData = queryClient.getQueryData<ResParsedManifest>(queryKey);

  return cachedData;
};

const fetch = async (
  client,
  streamURL: Props['streamURL'],
) => {
  if (!streamURL) {
    return ({
      trackList: [],
      parsedManifest: null,
    });
  }

  const cacheData = getCachedTrackList(streamURL);
  if (cacheData) {
    return cacheData;
  }


  const response = await client.fetchText(streamURL);
  const { result: manifestText } = response;

  let parsedManifest: ManifestParser | null = null;

  const streamProtocol = getProtocolByStreamURL(streamURL);
  if (streamProtocol === Protocol.DASH) {
    parsedManifest = parseManifestMpd(manifestText, streamURL);
  }
  else if (streamProtocol === Protocol.HLS) {
    parsedManifest = parseManifestM3u8(manifestText);
  }
  else if (streamProtocol === Protocol.MSS) {
    parsedManifest = parseManifestMSS(manifestText);
  }

  const subTitlesStructure = getSubTitlesStructure(
    streamURL,
    parsedManifest?.mediaGroups?.SUBTITLES.subs || {},
  );

  if (!subTitlesStructure) {
    return ({
      trackList: [],
      parsedManifest,
      fetchResponse: response,
    });
  }

  const data = await Promise.all(
    subTitlesStructure.map(item => client.fetchText(item.url))
  )

  const trackList: TextTrack[] = [];
  subTitlesStructure.forEach(({ name, url }, index) => {
    let subTitle: Track[] | null = null;
    if (url.indexOf('.vtt') !== -1) {
      subTitle = parseVTT(data[index].result);
    }
    else if (url.indexOf('.ttml') !== -1) {
      subTitle = parseTTML(data[index].result);
    }

    if (subTitle) {
      trackList.push({
        name,
        subTitle,
      });
    }
  });

  return ({
    trackList,
    parsedManifest,
    fetchResponse: response,
  }) as ResParsedManifest;
};


const useTrackList = ({ streamURL }: Props) => {
  const client = useClient();

  return useQuery<ResParsedManifest, Errors>({
    queryKey: getQueryKey(streamURL),
    queryFn: () => fetch(client, streamURL),
    enabled: !!streamURL,
  });
};

export { getCachedTrackList, ResParsedManifest, TextTrack };


export default useTrackList;
