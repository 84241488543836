import { CardConfigLayout } from "~typings/ScreenCardConfigs";

export const needToShowRKNPlate = __PROJECT_NAME__ === 'popcorn';

/**
 * Метод проверяет, показывать ли RKN плашку для данного layout.
 * Так же учитывает PROJECT (попркорн или другие)
 */
export const showRknForLayout = (layout: CardConfigLayout)=> {
  return needToShowRKNPlate && availableLayouts.includes(layout)
}

const availableLayouts: CardConfigLayout[] = [
  CardConfigLayout.Horizontal,
  CardConfigLayout.NewsWithoutPicture,
  CardConfigLayout.News,
];
