// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sliderName--iXNje {\n  margin-top: 4.6875vw;\n  margin-bottom: 1.640625vw;\n  font-size: 1.5625vw;\n  font-weight: 500;\n  color: rgba(243, 243, 247, 1);\n}\n\n.sliderWrapper--ewX9p {\n  position: relative;\n  width: 71.40625vw;\n  height: 11.407vw;\n  white-space: nowrap;\n}\n\n.sliderWrapper--ewX9p.poster--EJVVW {\n  height: 19.21875vw;\n}\n\n.sliderWrapper--ewX9p.logo_small--I7ecz {\n  height: 7.187499999999999vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/ProductPage/sliders/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,mBAAuB;EACvB,gBAAkB;EAClB,6BAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgD;EAChD,mBAAmB;AACrB;;AAEA;EACE,kBAAsC;AACxC;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".sliderName {\n  margin-top: 4.6875vw;\n  margin-bottom: 1.640625vw;\n  font-size: $fontSub1_vw;\n  font-weight: $fw_5;\n  color: $gw_10;\n}\n\n.sliderWrapper {\n  position: relative;\n  width: 71.40625vw;\n  height: $(mediaItemDefaultDimensionHeightInVW)vw;\n  white-space: nowrap;\n}\n\n.sliderWrapper.poster {\n  height: $(mediaItemPosterHeightInVW)vw;\n}\n\n.sliderWrapper.logo_small {\n  height: 7.187499999999999vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var sliderName = "sliderName--iXNje";
export var sliderWrapper = "sliderWrapper--ewX9p";
export var poster = "poster--EJVVW";
export var logo_small = "logo_small--I7ecz";
export default ___CSS_LOADER_EXPORT___;
