import { useQuery } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import ResponseMany from "~typings/ResponseMany";

export const PREFIX_OF_A_COMPOSITE_KEY = 'view_summary';

interface ViewSummary {
  movie:  ViewSummaryObject,
  episode:  ViewSummaryObject,
  channel:  ViewSummaryObject,
  part:  ViewSummaryObject,
  program_event:  ViewSummaryObject,
}

export interface ViewSummaryObject {
  duration: number;
  amount: number;
  name: string;
}

const getKey = (accessToken: string | null) => [PREFIX_OF_A_COMPOSITE_KEY, accessToken];

const fetch = async (client, accessToken) => {
  const params = { access_token: accessToken }

  return await client.get(
    '/v1/account/view_summary',
    params
  );
};

export const useViewSummary = () => {
  const { accessToken } = getAuthData();
  const client = useClient();
  const queryKey = getKey(accessToken);

  return useQuery<ResponseMany<ViewSummary>>({
    queryKey: queryKey,
    queryFn: async () => fetch(client, accessToken),
    enabled: !!accessToken,
    cacheTime: 10 * 60 * 1000 // 10 минут
  })
};
