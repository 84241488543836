import { useMutation } from 'react-query';

import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';


export type SendSecurityCodeResult = Readonly<{
  data: null;
  meta: {
    status: number;
  };
}>;


const fetch = async (
  client: ApiClient,
  username: string,
): Promise<SendSecurityCodeResult> => client.post(
  '/v1/users/confirmations/codes.json',
  {
    username,
  },
);

export const useSendSecurityCodeMutation = () => {
  const client = useClient();

  return useMutation(({ username }: { username: string }) => (
    fetch(client, username)
  ));
}


export default useSendSecurityCodeMutation;
