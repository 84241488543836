// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tabsPanel--J03v3 {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  font-size: 1.25vw;\n}\n\n.tab--tKSQD {\n  border: none;\n  padding: 0 1.171875vw;\n  text-transform: none;\n  text-transform: initial;\n  min-width: 0;\n  min-width: initial;\n}\n\n.selected--jYxk3 {\n  color: rgba(243, 243, 247, 1);\n  background-color: rgba(255, 255, 255, 0.1);\n}\n\n.pointerEnabled .tab--tKSQD:not(.selected--jYxk3):hover {\n  -webkit-box-shadow: 0 0 0 1px rgba(253, 216, 53, 1);\n          box-shadow: 0 0 0 1px rgba(253, 216, 53, 1);\n}\n\n.fullHD.pointerEnabled .tab--tKSQD:not(.selected--jYxk3):hover {\n  -webkit-box-shadow: 0 0 0 2px rgba(253, 216, 53, 1);\n          box-shadow: 0 0 0 2px rgba(253, 216, 53, 1);\n}\n", "",{"version":3,"sources":["webpack://./src/app/ui/TabsPanel/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,iBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,oBAAuB;EAAvB,uBAAuB;EACvB,YAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,6BAAa;EACb,0CAAuB;AACzB;;AAEA;EACE,mDAA4B;UAA5B,2CAA4B;AAC9B;;AAEA;EACE,mDAA4B;UAA5B,2CAA4B;AAC9B","sourcesContent":[".tabsPanel {\n  display: flex;\n  font-size: $fontBody_vw;\n}\n\n.tab {\n  border: none;\n  padding: 0 1.171875vw;\n  text-transform: initial;\n  min-width: initial;\n}\n\n.selected {\n  color: $gw_10;\n  background-color: $w_10;\n}\n\n:global(.pointerEnabled) .tab:not(.selected):hover {\n  box-shadow: 0 0 0 1px $ac_00;\n}\n\n:global(.fullHD.pointerEnabled) .tab:not(.selected):hover {\n  box-shadow: 0 0 0 2px $ac_00;\n}\n"],"sourceRoot":""}]);
// Exports
export var tabsPanel = "tabsPanel--J03v3";
export var tab = "tab--tKSQD";
export var selected = "selected--jYxk3";
export default ___CSS_LOADER_EXPORT___;
