import focuser, { FocuserKeyHandler } from '@focuser';
import cn from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { InputFormFocuser } from '~components/InputFormFocuser';
import { ENTER_PROFILE_NAME, PROFILE_EDITOR_NAME_MIN_LENGTH_ERROR } from '~localization';
import { PopupIsolated } from '~ui/Popup';

import * as styles from './TextInputPopup.module.css';

export type Props = {
  value?: string | null;
  maxLength?: number;
  minLength?: number;
  placeholder?: string;
  onBack?: () => void;
  onSubmit: (value: string) => void;
};

const TextInputPopup: React.FC<Props> = ({
  value,
  maxLength,
  minLength,
  placeholder,
  onSubmit,
  onBack,
}) => {
  const [isShowError, setShowError] = React.useState(false);

  const handleBack: FocuserKeyHandler = (ev) => {
    if (onBack) {
      ev.stop();
      ev.stopNativeEvent();
      onBack();
    }
  };

  const handleValueChange = () => {
    if (isShowError) setShowError(false);
  };

  const submitHandler = (value: string) => {
    if (!minLength || value.length >= minLength) {
      onSubmit(value);
    } else {
      setShowError(true);
    }
  };

  return (
    <PopupIsolated
      focuserClassName={ styles.popup }
    >
      <div className={ cn(styles.title, {
        [styles.error]: isShowError
      }) }>
        {isShowError ? (
          <FormattedMessage
            id={ PROFILE_EDITOR_NAME_MIN_LENGTH_ERROR }
            values={ { num: minLength } }
          />
        ) : (
          <FormattedMessage
            id={ ENTER_PROFILE_NAME }
          />
        )}
      </div>
      <focuser.FocusableBlock
        noNeedForceFocus
        isFocused
        onKeyReturn={ handleBack }
      >
        <InputFormFocuser
          initialValue={ value || '' }
          placeholder={ placeholder }
          onDone={ submitHandler }
          initialKeyboardLayout="eng_lowercase"
          onValueChange={ handleValueChange }
          maxLength={ maxLength }
        />
      </focuser.FocusableBlock>
    </PopupIsolated>
  );
};

export default React.memo(TextInputPopup);
