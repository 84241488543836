import {
  backgroundImageBanner,
  backgroundImageMediaItemPage,
  backgroundImagePage,
} from 'customization-project-name/assets';
import * as React from 'react';

import defaultMask from '../images/mask.png';
import defaultBackgroundImage from '../images/mask-media-item-page-mode.png';
import * as styles from './styles.module.css';


type Props = {
  isBanner?: boolean;
  isMediaItem?: boolean;
  isMediaItemPage?: boolean;
}

const BackgroundImage: React.FC<Props> = ({
  isBanner = false,
  isMediaItem = false,
  isMediaItemPage = false,
}) => {
  const [src, setSrc] = React.useState<string | null>(null);

  React.useEffect(() => {
    let newSrc: string | null = null

    if (isBanner) {
      newSrc = backgroundImageBanner || defaultMask;
    } else if (isMediaItem) {
      newSrc = backgroundImagePage || defaultBackgroundImage;
    } else if (isMediaItemPage) {
      newSrc = backgroundImageMediaItemPage || defaultBackgroundImage;
    }

    setSrc(newSrc)
  }, [
    isBanner,
    isMediaItem,
    isMediaItemPage,
  ]);

  if (!src) { return null; }

  return (
    <img
      className={ styles.mask }
      src={ src }
    />
  );
};


export default React.memo(BackgroundImage);
