import { useQuery } from 'react-query';

import { Nullable } from '~lib/type-utils/utils';

const imageKeys = {
  PREFIX: 'utils-image-updatable',
  withUrl: (url: Nullable<string>) => [imageKeys.PREFIX, url],
};

/**
 * Хук помогает перезапрашивать изображение с заданным интервалом
 * В браузере образуется кэш, и если ссылку на изображение, которое возвращает этот хук, вставить в тег img,
 * то изображение сразу отобразиться
 */
export function useUpdatableImage({
  url,
  interval,
}: {
  url?: Nullable<string>;
  interval?: Nullable<number>;
}) {
  return useQuery<string>({
    queryKey: imageKeys.withUrl(url),
    queryFn: () => {
      const preparedUrl = url! + `?${Date.now()}`;

      const img = new Image();
      img.src = preparedUrl;
      return new Promise((res, rej) => {
        img.onload = () => {
          res(preparedUrl);
        };
        img.onerror = (err) => {
          rej(err);
        };
      });
    },
    refetchInterval: interval || false,
    enabled: !!url,
    cacheTime: 1000 * 60 * 10, // 10 минут
  });
}
