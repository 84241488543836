import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { 
  CAST_MEMBERS_ACTOR, CAST_MEMBERS_EDITOR, 
  CAST_MEMBERS_PRODUCER, CAST_MEMBERS_WRITER, 
  COUNTRIES, DURATION, GENRES, INFORMATION, LANGUAGES, PRODUCTION_YEAR, 
  RIGHT_HOLDER, ROLE_COMPOSER, ROLE_DIRECTOR, STUDIOS 
} from '~localization';
import Role from '~typings/Role';

import LabeledText from "./LabeledText";
import * as styles from './styles.module.css';

type Props = Readonly<{
  membersByRole: any;
  genres: string[];
  countries: string[],
  studios: string[],
  productionYear: number | null;
  rightHolderName?: string | null;
  externalCatalog?: string | null;
  languages: string[];
  durationMin: number | string | null;
  intl: InjectedIntl;
}>;

const DetailsList: React.FC<Props> = ({
  membersByRole,
  genres,
  countries,
  studios,
  productionYear,
  languages,
  durationMin,
  externalCatalog,
  intl
}: Props ) => {
  const checkDetails = genres?.length > 0 || membersByRole?.actor?.length > 0;

  return (
    <>
      { checkDetails ?
        <p className={ styles.infoTitle }>{intl.formatMessage({ id: INFORMATION })}</p>
      : null
      }

      <LabeledText label={ intl.formatMessage({ id: CAST_MEMBERS_ACTOR }) }>
        { membersByRole[Role.Actor] }
      </LabeledText>

      <LabeledText label={ intl.formatMessage({ id: ROLE_DIRECTOR }) }>
        { membersByRole[Role.Director] }
      </LabeledText>

      <LabeledText label={ intl.formatMessage({ id: CAST_MEMBERS_WRITER }) }>
        { membersByRole[Role.Writer] }
      </LabeledText>

      <LabeledText label={ intl.formatMessage({ id: ROLE_COMPOSER }) }>
        { membersByRole[Role.Composer] }
      </LabeledText>

      <LabeledText label={ intl.formatMessage({ id: CAST_MEMBERS_EDITOR }) }>
        { membersByRole[Role.Editor] }
      </LabeledText>

      <LabeledText label={ intl.formatMessage({ id: CAST_MEMBERS_PRODUCER }) }>
        { membersByRole[Role.Producer] }
      </LabeledText>

      <LabeledText label={ intl.formatMessage({ id: GENRES }) }>
        { genres }
      </LabeledText>

      <LabeledText label={ intl.formatMessage({ id: COUNTRIES }) }>
        { countries }
      </LabeledText>

      {
        (productionYear)
          ? <LabeledText label={ intl.formatMessage({ id: PRODUCTION_YEAR }) }>{ productionYear }</LabeledText>
          : null
      }
      
      <LabeledText label={ intl.formatMessage({ id: LANGUAGES }) }>
        { languages }
      </LabeledText>

      <LabeledText label={ intl.formatMessage({ id: STUDIOS }) }>
        { studios }
      </LabeledText>

      <LabeledText label={ intl.formatMessage({ id: DURATION }) }>
        { durationMin }
      </LabeledText>

      { externalCatalog && 
        <LabeledText label={ intl.formatMessage({ id: RIGHT_HOLDER }) }>
          { externalCatalog }
        </LabeledText> }
    </>
  )
};

export default injectIntl(DetailsList);
