import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { PrimaryButtonWrap } from '~app/ui-kit/PrimaryButton';
import CatchBack from '~components/PageManager/CatchBack';
import {INAPPROPRIATE_CONTENT_AGE_CONFIRMATION,NO, YES } from '~localization';
import NavigationDirection from '~typings/NavigationDirection';
import { Popup } from '~ui/Popup';

import Icon18plus from './Icon18plus';
import * as styles from './styles.module.css';

enum FocusOn {
  ConfirmButton,
  RejectButton
}

type Props = Readonly<{
  onBack?: () => void;
  onAccept: () => void;
}>;


const AgeConfirmationPopup: React.FC<Props> =(props: Props) => {
  const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.RejectButton);

  const handleBack = React.useCallback(() => {
    if (props.onBack) {
      props.onBack();
    }
  }, []);

  const leaveButtonHandler = (dir: NavigationDirection) => {
    if (dir === NavigationDirection.Left) {
      setFocusOn(FocusOn.RejectButton);
    } else if (dir === NavigationDirection.Right) {
      setFocusOn(FocusOn.ConfirmButton);
    }
  };

  return (
    <Popup>
      <CatchBack
        action={ handleBack }
        triggers={ [props.onBack] }
      />

      <div className={ styles.wrapper }>
        <div className={ styles.ageIcon }>
          <div className={ styles.age }>
            <Icon18plus />
          </div>
        </div>
        <div className={ styles.agreementText }>
          <FormattedMessage id={ INAPPROPRIATE_CONTENT_AGE_CONFIRMATION } values={ { age: 18 } } />
        </div>

        <div className={ styles.buttonsWrap }>
          <PrimaryButtonWrap
            isFocused={ focusOn === FocusOn.RejectButton }
            onClick={ handleBack }
            onLeave={ leaveButtonHandler }
            className={ styles.button }
          >
            <FormattedMessage id={ NO } />
          </PrimaryButtonWrap>

          <PrimaryButtonWrap
            isFocused={ focusOn === FocusOn.ConfirmButton }
            onClick={ props.onAccept }
            onLeave={ leaveButtonHandler }
            className={ styles.button }
          >
            <FormattedMessage id={ YES } />
          </PrimaryButtonWrap>
        </div>
      </div>
    </Popup>
  );
};


export default AgeConfirmationPopup;
