/* eslint-disable max-len */

import ItemObject from '~typings/ItemObject';

type Expand = Readonly<
  {
    [key in ItemObject]?: string;
  }
>;

const expand: Expand = {
  [ItemObject.BuiltInPage]: 'images',
  [ItemObject.MainPage]: 'blocks,images',
  [ItemObject.ContentPage]: 'blocks,images',
  [ItemObject.WebPage]: 'images',
  [ItemObject.SingleCollectionPage]: 'blocks,blocks.search_filters_group.items',
  [ItemObject.NavigationPage]: 'images,subpages,subpages.subpages,subpages.blocks,subpages.blocks.search_filters_group.items,subpages.subpages.blocks,subpages.subpages.blocks.search_filters_group.items,subpages.images,subpages.subpages.images',
  [ItemObject.CompetitionPage]: 'blocks,images',

  [ItemObject.Banner]: 'images',
  [ItemObject.Catchup]: 'channel,channel.catchup_availability,channel.images,images',
  [ItemObject.Channel]: 'images,genres,certification_ratings,live_preview,iptv_live_preview,live_stream,distribution',
  [ItemObject.Collections]: 'page_id,type',
  [ItemObject.CompetitionHighlight]: 'images',
  [ItemObject.CompetitionMatch]: 'highlights,highlights.images,program_event,program_event.images,program_event.certification_ratings,program_event.genres,program_event.genres.images,images,stadium,results,stage_unit,stage_unit.competitors,stage_unit.standings,competitors.images',
  [ItemObject.Episode]: `images,video_file,series,series.images,series.genres,series.certification_ratings,series.external_catalog,series.external_catalog.images,series.studios,series.studios.images,series.ratings,series.countries,series.language,series.languages,series.right_holder,series.seasons,distribution,series.trailer,series.trailer.video_file`,
  [ItemObject.Episodes]: 'images,video_file',
  [ItemObject.Movie]: 'images,genres,certification_ratings,external_catalog,external_catalog.images,video_file,external_catalog,external_catalog.images,studios,studios.images,ratings,countries,language,languages,right_holder,trailer,trailer.video_file,teaser,teaser.images,distribution',
  [ItemObject.Offer]: 'following_phases,following_phases.phase,following_phases.money',
  [ItemObject.Plan]: 'product,product.plans,product.included_add_on_products,product.product_features,product.product_features.images,product.images,eligible_phase,eligible_phase.promos,phases,phases.promos',
  [ItemObject.Product]: 'plans,plans.phases,plans.phases.promos,plans.eligible_phase,included_add_on_products,product_features,product_features.images,images',
  [ItemObject.Program]: 'images,genres,certification_ratings,channel,program_type',
  [ItemObject.ProgramEvent]: 'images,certification_ratings,genres,genres.images,channel,channel.catchup_availability',
  [ItemObject.Season]: 'series,episodes,episodes.images,episodes.video_file,episodes.season,episodes.series,series.trailer,series.trailer.video_file',
  [ItemObject.Series]: 'images,genres,certification_ratings,external_catalog,external_catalog.images,studios,studios.images,ratings,countries,language,languages,right_holder,seasons,trailer,trailer.video_file,teaser,teaser.images',
  [ItemObject.AudioShow]: 'images,genres,certification_ratings,external_catalog,external_catalog.images,studios,studios.images,ratings,countries,language,languages,right_holder,trailer,trailer.video_file,teaser,teaser.images',
  [ItemObject.News]: 'images',
  [ItemObject.Avatar]: 'image',
};

export default expand;
