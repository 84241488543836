/**
 * Примает дату начала и дату окончания какого-то промежутка
 * Возвращает прогресс в процентах, от 0 до 100
 * Считает на основе текущей даты
 * Например, если начало ровно 2000 год, а окончание 2100 год, то в 2025-м году вернет 25%
 *
 * Если будут переданы невалидные даты, то вернет 0
 *
 * Если дата начала больше даты окончания, то вернет 100
 */
export const getProgressFromTwoDates = (start: Date, end: Date) => {
  if(isNaN(start.getTime()) || isNaN(end.getTime())) return 0;
  if(end < start) return 100;
  const now = new Date();
  const a = now.getTime() - start.getTime(); // сколько прошло с начала
  const b = end.getTime() - start.getTime(); // сколько всего
  if(b === 0) return 100;
  const result = Math.trunc((100 * a) / b);
  return Math.max(Math.min(result, 100), 0);
};
