/**
 * @see https://gist.github.com/rhumlover/5747417
 */
export const VastErrors = {
  XML_PARSING_ERROR: 100,
  VAST_SCHEMA_VALIDATION_ERROR: 101,
  VAST_VERSION_OF_RESPONSE_NOT_SUPPORTED: 102,
  TRAFFICKING_ERROR: 200,
  EXPECTING_DIFFERENT_LINEARITY: 201,
  EXPECTING_DIFFERENT_DURATION: 202,
  EXPECTING_DIFFERENT_SIZE: 203,
  GENERAL_WRAPPER_ERROR: 300,
  TIMEOUT_VAST_URI_PROVIDER: 301,
  WRAPPER_LIMIT_REACHED: 302,
  NO_ADS_VAST_RESPONSE_AFTER_ONE_OR_MORE_WRAPPERS: 303,
  GENERAL_LINEAR_ERROR: 400,
  FILE_NOT_FOUND: 401,
  TIMEOUT_OF_MEDIA_FILE_URI: 402,
  NOT_FIND_SUPPORTED_MEDIA_FILE: 403,
  PROBLEM_DISPLAYING_MEDIA_FILE: 405,
  GENERAL_NON_LINEAR_ADS_ERROR: 500,
  UNABLE_TO_DISPLAY_NONLINEAR_ADS: 501,
  UNABLE_TO_FETCH_NON_LINEAR_ADS: 502,
  NOT_FIND_NON_LINEAR_RESOURCE_WITH_SUPPORTED_TYPE: 503,
  GENERAL_COMPANION_ADS_ERROR: 600,
  UNABLE_TO_DISPLAY_COMPANION: 601,
  UNABLE_TO_DISPLAY_REQUIRED_COMPANION: 602,
  UNABLE_TO_FETCH_COMPANION_RESOURCE: 603,
  NOT_FIND_COMPANION_RESOURCE_WITH_SUPPORTED_TYPE: 604,
  UNDEFINED_ERROR: 900,
  GENERAL_VPAID_ERROR: 901,
};

const ShakaErrorCategory = {
  /** Errors from the network stack. */
  NETWORK: 1,
  /** Errors parsing text streams. */
  TEXT: 2,
  /** Errors parsing or processing audio or video streams. */
  MEDIA: 3,
  /** Errors parsing the Manifest. */
  MANIFEST: 4,
  /** Errors related to streaming. */
  STREAMING: 5,
  /** Errors related to DRM. */
  DRM: 6,
  /** Miscellaneous errors from the player. */
  PLAYER: 7,
  /** Errors related to cast. */
  CAST: 8,
  /** Errors in the database storage (offline). */
  STORAGE: 9,
};

const shakaCategoryToVastErrorMap = {
  [ShakaErrorCategory.CAST]: VastErrors.UNDEFINED_ERROR,
  [ShakaErrorCategory.DRM]: VastErrors.PROBLEM_DISPLAYING_MEDIA_FILE,
  [ShakaErrorCategory.MANIFEST]: VastErrors.PROBLEM_DISPLAYING_MEDIA_FILE,
  [ShakaErrorCategory.MEDIA]: VastErrors.PROBLEM_DISPLAYING_MEDIA_FILE,
  [ShakaErrorCategory.NETWORK]: VastErrors.TRAFFICKING_ERROR,
  [ShakaErrorCategory.PLAYER]: VastErrors.PROBLEM_DISPLAYING_MEDIA_FILE,
  [ShakaErrorCategory.STORAGE]: VastErrors.UNDEFINED_ERROR,
  [ShakaErrorCategory.STREAMING]: VastErrors.TRAFFICKING_ERROR,
  [ShakaErrorCategory.TEXT]: VastErrors.NOT_FIND_SUPPORTED_MEDIA_FILE,
};

/**
 * @param {{
 *    category: number,
 *    code: number,
 *    data: *,
 *    handled: boolean,
 *    severity: number
 * }} shakaError
 * @return {number}
 */
export default ({ shakaError }) => {
  const { category } = shakaError;

  return shakaCategoryToVastErrorMap[category] || VastErrors.UNDEFINED_ERROR;
};
