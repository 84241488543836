import * as React from 'react';

import AudioShowPart from "~typings/AudioShowPart";
import Channel from "~typings/Channel";
import Event from "~typings/Event";
import ItemObject from "~typings/ItemObject";
import PlayerMediaItem from '~typings/PlayerMediaItem';

import { AudioshowInfo } from "./audioshow/AudishowInfo";
import { CatchupInfo } from "./catchup/CatchupInfo";
import { LiveInfo } from "./live/LiveInfo";
import { VodInfo } from "./vod/VodInfo";

type Props = Readonly<{
  item: PlayerMediaItem;
}>;


const LocalComponent = (props: Props) => {
  if (props.item.object === ItemObject.Channel) {
    return <LiveInfo item={ props.item as Channel } />
  }

  if (props.item.object === ItemObject.ProgramEvent) {
    return <CatchupInfo item={ props.item as Event } />
  }

  if (props.item.object === ItemObject.Part) {
    return <AudioshowInfo item={ props.item as AudioShowPart } />
  }

  return <VodInfo item={ props.item } />
};


export const ItemInfoRouter = React.memo(LocalComponent)
