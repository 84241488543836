// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".field--MBegZ {\n  /* 728px */\n  width: 56.875vw;\n\n  /* 44px */\n  height: 3.4375vw;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n     -moz-box-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n}\n\n.fieldName--ekPeB {\n  /* 150px */\n  width: 11.71875vw;\n\n  /* 24px */\n  height: 1.875vw;\n\n  /* 20px */\n  margin-right: 1.5625vw;\n}\n\n.fieldValue--of55O {\n  /* 474px */\n  width: 37.03125vw;\n\n  /* 24px */\n  height: 1.875vw;\n\n  /* 40px */\n  margin-right: 3.125vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/Control/ControlField.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;;EAEf,SAAS;EACT,gBAAgB;EAChB,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,uBAA2B;EAA3B,mCAA2B;KAA3B,oBAA2B;UAA3B,2BAA2B;EAC3B,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,iBAAiB;;EAEjB,SAAS;EACT,eAAe;;EAEf,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,iBAAiB;;EAEjB,SAAS;EACT,eAAe;;EAEf,SAAS;EACT,qBAAqB;AACvB","sourcesContent":[".field {\n  /* 728px */\n  width: 56.875vw;\n\n  /* 44px */\n  height: 3.4375vw;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.fieldName {\n  /* 150px */\n  width: 11.71875vw;\n\n  /* 24px */\n  height: 1.875vw;\n\n  /* 20px */\n  margin-right: 1.5625vw;\n}\n\n.fieldValue {\n  /* 474px */\n  width: 37.03125vw;\n\n  /* 24px */\n  height: 1.875vw;\n\n  /* 40px */\n  margin-right: 3.125vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var field = "field--MBegZ";
export var fieldName = "fieldName--ekPeB";
export var fieldValue = "fieldValue--of55O";
export default ___CSS_LOADER_EXPORT___;
