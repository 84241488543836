import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { LeftMenuButton } from './LeftMenuButton';

type Props = {
  isFocused: boolean;
  isSelected?: boolean;
  children?: React.ReactNode;
  focuserClassName?: string;
  buttonClassName?: string;
} & Omit<FocusableBlockProps, 'className' | 'isLastBlock'>;

export const LeftMenuButtonFocuser: React.FC<Props> = ({
  isFocused,
  isSelected,
  children,
  focuserClassName,
  buttonClassName,
  ...focuserProps
}) => {
  return (
    <focuser.FocusableBlock
      { ...focuserProps }
      isFocused={ isFocused }
      className={ focuserClassName }
      isLastBlock
    >
      {({ isFocused }) => (
        <LeftMenuButton
          className={ buttonClassName }
          isFocused={ isFocused }
          isSelected={ isSelected }
        >
        {children}
        </LeftMenuButton>
      )}
    </focuser.FocusableBlock>
  );
};
