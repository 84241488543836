import * as React from 'react'

import { getVars } from '~components/TiledView/component.helpers';
import useNavigationByKeys from '~hooks/useNavigation';
import useNavigationOnLayer, { FocusPosition, NavigationLine } from '~stores/LayerNavigation';
import usePointer from '~stores/Pointer';
import MediaItemDimension from '~typings/MediaItemDimension';
import NavigationDirection from '~typings/NavigationDirection';

const switchSliderAnimationMS = 150;
const changeSliderIndexAnimationMS = 75;

type Props = {
  layerID: string;
  isAllowedNavigation: boolean;
  navigationItems: NavigationLine[];

  isTiledView?: boolean;
  dimension?: MediaItemDimension;
  slidesCount?: [number, number];
  onLeave?: (direction: NavigationDirection) => void;

  children: (focusPosition: FocusPosition) => JSX.Element | null;
};

export function PageNavigation(props: Props) {
  const navigationOnLayer = useNavigationOnLayer(state => state.navigationOnLayer);
  const setNavigationOnLayer = useNavigationOnLayer(state => state.setNavigationOnLayer);
  const setFocusPosition = useNavigationOnLayer(state => state.setFocusPosition);
  const verticalNavigation = useNavigationOnLayer(state => state.verticalNavigation);
  const horizontalNavigation = useNavigationOnLayer(state => state.horizontalNavigation);
  const setNavigationItems = useNavigationOnLayer(state => state.setNavigationItems);
  const focusPosition = useNavigationOnLayer(React.useCallback(
    state => state.getFocusPosition(props.layerID),
    [props.layerID],
  ));
  const navigationItems = useNavigationOnLayer(React.useCallback(
    state => state.getNavigationItems(props.layerID) || [],
    [props.layerID],
  ));
  const focusOn = useNavigationOnLayer(React.useCallback(
    state => (state.focusHistory[props.layerID] || {})?.focusOn ?? 0,
    [props.layerID],
  ));
  const focusedIndex = useNavigationOnLayer(React.useCallback(
    state => state.getFocusedIndex(props.layerID, focusOn),
    [props.layerID, focusOn],
  ));

  const isPointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);
  const flush = usePointer(state => state.flush);

  const isNavigationInProcess = React.useRef(false);
  const timeout = React.useRef<any | null>(null);
  const clear = () => {
    isNavigationInProcess.current = false;

    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };
  const handleNavigationInProgress = (ms: number) => {
    isNavigationInProcess.current = true;
    timeout.current = setTimeout(() => {
      isNavigationInProcess.current = false;
    }, ms);
  };
  React.useEffect(() => clear, []);

  const isFocusOnTiledView = Boolean(props.isTiledView && navigationItems?.[focusOn]?.isTiled);
  const maxIndex = React.useMemo(
    () => navigationItems?.[focusOn]?.maxIndex ?? 0,
    [navigationItems, focusOn],
  );
  const { lineByItemIndex, itemsCountInLine } = React.useMemo(
    () => isFocusOnTiledView && props.dimension ?
      getVars(props.dimension, props.slidesCount)
      : {} as ReturnType<typeof getVars>,
    [isFocusOnTiledView, props.dimension, props.slidesCount],
  );
  const totalLines = isFocusOnTiledView && itemsCountInLine ?
    Math.ceil(maxIndex / itemsCountInLine) - 1 : 0;
  const focusedLine = React.useMemo(
    () => isFocusOnTiledView && lineByItemIndex ? lineByItemIndex(focusedIndex) : 0,
    [isFocusOnTiledView, lineByItemIndex, focusedIndex],
  );

  const updateProps = [
    focusedIndex,
    focusedLine,
    focusOn,
    maxIndex,
    isFocusOnTiledView,
    navigationItems?.length,
    navigationOnLayer,
    props.isAllowedNavigation,
    props.layerID,
    props.onLeave,
    totalLines,
    itemsCountInLine,
  ];
  const handleKeyNavigate = React.useCallback((direction: NavigationDirection): void => {
    if (!isNavigationInProcess.current) {
      switch (direction) {
        case NavigationDirection.Up: {
          handleNavigationInProgress(
            isFocusOnTiledView ?
              changeSliderIndexAnimationMS
              : switchSliderAnimationMS
          );

          if (isFocusOnTiledView && (focusedLine > 0)) {
            const newFocusedIndex = Math.max(
              (focusedIndex - itemsCountInLine),
              0
            );

            setFocusPosition(props.layerID, [focusOn, newFocusedIndex]);
          } else {
            verticalNavigation(direction, props.onLeave);
          }

          break;
        }
        case NavigationDirection.Down: {
          handleNavigationInProgress(
            isFocusOnTiledView ?
              changeSliderIndexAnimationMS
              : switchSliderAnimationMS
          );

          if (totalLines > focusedLine) {
            const newFocusedIndex = Math.min(
              (focusedIndex + itemsCountInLine),
              (maxIndex - 1)
            );

            setFocusPosition(props.layerID, [focusOn, newFocusedIndex]);
          } else {
            verticalNavigation(direction, props.onLeave);
          }

          break;
        }
        case NavigationDirection.Right: {
          handleNavigationInProgress(changeSliderIndexAnimationMS);

          if (
            isFocusOnTiledView
            && (
              ((focusedIndex + 1) >= maxIndex)
              || ((focusedIndex + 1) % itemsCountInLine) === 0
            )
          ) {
            props.onLeave?.(direction);
          } else {
            horizontalNavigation(direction, props.onLeave);
          }

          break;
        }
        case NavigationDirection.Left: {
          handleNavigationInProgress(changeSliderIndexAnimationMS);

          if (
            isFocusOnTiledView
            && ((focusedIndex % itemsCountInLine) === 0)
          ) {
            props.onLeave?.(direction);
          } else {
            horizontalNavigation(direction, props.onLeave);
          }

          break;
        }
      }
    }
  }, updateProps);

  useNavigationByKeys({
    isMounted: props.isAllowedNavigation && navigationOnLayer === props.layerID,
    onNavigation: handleKeyNavigate,
  }, updateProps);

  React.useEffect(() => {
    if (
      props.layerID
      && props.isAllowedNavigation
      && navigationOnLayer !== props.layerID
      && navigationItems.length !== 0
    ) {
      setNavigationOnLayer(props.layerID);
    }
  }, [
    navigationOnLayer,
    navigationItems.length,
    props.layerID,
    props.isAllowedNavigation,
  ]);


  React.useEffect(() => {
    if (props.layerID && props.navigationItems?.length != 0) {
      setNavigationItems(props.layerID, props.navigationItems);
    }
  }, [props.layerID, JSON.stringify(props.navigationItems)]);

  React.useEffect(() => {
    if (!isPointerEnabled) { return; }

    const focusedIndexInLine = (focusedIndex % itemsCountInLine);
    updateDirections({
      [NavigationDirection.Up]: isFocusOnTiledView ? (focusedLine !== 0) : focusOn !== 0,
      [NavigationDirection.Down]: isFocusOnTiledView ?
        (focusedLine < totalLines)
        : focusOn < navigationItems.length - 1,
      [NavigationDirection.Left]: isFocusOnTiledView ?
        (focusedIndexInLine !== 0)
        : focusedIndex !== 0,
      [NavigationDirection.Right]: isFocusOnTiledView ? (
        focusedIndexInLine < (itemsCountInLine - 1)
        && focusedIndex < (maxIndex - 1)
      ) : focusedIndex < (maxIndex - 1),
    });

  }, [
    focusedIndex,
    focusedLine,
    isPointerEnabled,
    itemsCountInLine,
    maxIndex,
    navigationItems.length,
    isFocusOnTiledView,
    totalLines,
  ]);

  React.useEffect(() => () => flush([
    NavigationDirection.Left,
    NavigationDirection.Right,
    NavigationDirection.Up,
    NavigationDirection.Down,
  ]), []);

  return props.children(focusPosition);
}


export default React.memo(PageNavigation);

