import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { useScene } from '~components/Provider/Scene';
import useMediaItemPageInfo from '~hooks/useMediaItemPage';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import getMinPrice from '~lib/product/getMinPrice';
import getRentMinPrice from '~lib/product/getRentMinPrice';
import groupPlansByType from '~lib/product/groupPlansByType';
import { FREE, FROM, PRICE_PERIOD, PURCHASE_TITLE, RENT_TITLE, TRIAL_PERIOD } from '~localization';
import Channel from '~typings/Channel';
import ItemObject from '~typings/ItemObject';
import NavigationDirection from '~typings/NavigationDirection';
import Product from '~typings/Product';
import RentPlan, { RentPlanType } from '~typings/RentPlan';

import * as styles from './styles.module.css';


type DescriptionProps = {
  rentPlans?: RentPlan[] | undefined;
  product?: Product | null;
  intl: InjectedIntl;
}

const Description: React.FC<DescriptionProps> = ({ rentPlans, product, intl }) => {
  if (!intl) { return null; }

  let text: string | null = null;
  let hasFrom = false;
  const hasOnlyPlan = (product?.plans?.length === 1);

  if (product) {
    const eligiblePhases = product.plans.map((plan) => plan.eligible_phase);
    const trialPhase = eligiblePhases.find(phase => phase?.type === 'trial');
    const evergreenPhase = eligiblePhases.find(phase => phase?.type === 'evergreen');
    const price = getMinPrice(product);

    hasFrom = price !== 'free' && !hasOnlyPlan;

    if (price === 'free') {
      text = trialPhase ?
        intl.formatMessage({ id: TRIAL_PERIOD }, {
          trialDurationUnits: trialPhase.access_period.unit,
          trialDurationValue: trialPhase.access_period.value,
        })
        : intl.formatMessage({ id: FREE });
    } else {
      text = hasOnlyPlan ?
        intl.formatMessage({ id: PRICE_PERIOD }, {
          price: evergreenPhase?.billing.price.value === 0 ?
            intl.formatMessage({ id: FREE })
            : evergreenPhase?.billing.price.formatted,
          durationUnit: evergreenPhase?.access_period.unit,
          durationValue: evergreenPhase?.access_period.value,
        })
        : price?.money.value === 0 ?
        intl.formatMessage({ id: FREE })
        : price?.money.formatted || null;
    }
  }

  if (rentPlans) {
    const minPrice = getRentMinPrice(rentPlans);
    text = minPrice?.money.formatted || null;
  }

  return text ? (
    <div className={ styles.menuItemDescription }>
      {hasFrom ? `${intl.formatMessage({ id: FROM })} ` : null}
      {text}
    </div>
  ) : (
    <div> </div>
  );
};

const MemoDescription = React.memo(Description);

type Props = {
  url: string | null | undefined;
  products: Product[] | null;
  rentPlans: RentPlan[] | undefined;
  focusOnMenu: boolean;
  onMenu: (arg?: boolean) => void;
  product?: Product | null;
  intl: InjectedIntl;
}

const Menu: React.FC<Props> = ({
  url,
  products,
  rentPlans,
  focusOnMenu,
  onMenu,
  intl,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const scene = useScene();
  const { mediaItemPage } = useMediaItemPageInfo();
  const groupedPlans = React.useMemo(() => groupPlansByType(rentPlans), [rentPlans]);
  const itemsLength = (products?.length || 0) + (groupedPlans.length || 0);

  const navigationItems = React.useRef([
    { maxIndex: itemsLength - 1 },
  ]);
  React.useEffect(() => {
    navigationItems.current = [
      { maxIndex: itemsLength - 1 },
    ];
  }, [itemsLength]);
  const navigationState = useSpatialNavigation({
    allowNavigation: focusOnMenu,
    navigationItems: navigationItems.current,
    onLeave: (direction) => {
      if (direction === NavigationDirection.Down) {
        onMenu(false);
      }
    }
  });
  const focusedIndex = navigationState.focusedIndex[0];

  const handleRents = (type: string) => {
    history.replace(`${url}/rents/${type}`);
  };

  const handleSubscriptions = (priductID: string) => {
    history.replace(`${url}/subscriptions/${priductID}`);
  };

  React.useEffect(() => {
    if (
      (
        mediaItemPage.object === ItemObject.Channel
        || mediaItemPage.object === ItemObject.ProgramEvent
      ) && products?.length
    ) {
      const productIndex = products?.findIndex(product => pathname.includes(product.id)) ?? -1;

      if (products && productIndex >= 0) {
        const name = products[productIndex].name;
        scene.changeSceneMediaItem({
          id: name,
          name,
        } as Channel, true, false);
      }
    }
  }, [pathname, mediaItemPage, products, groupedPlans]);

  return (
    <div className={ styles.menuWrapper }>
      {
        (products || []).map((product, idx) => (
          <div
            key={ product.id }
            className={ cn(styles.menuItem, {
              [styles.isFocused]: focusOnMenu && focusedIndex === idx,
              'focusedNavigationNode': focusOnMenu && focusedIndex === idx,
              [styles.isActive]: pathname.includes(product.id),
            }) }
            onClick={ () => handleSubscriptions(product.id) }
          >
            <div
              className={ styles.menuItemTitle }
            >
              { product.name }
            </div>
            <MemoDescription
              product={ product }
              intl={ intl }
            />
          </div>
        ))
      }
      {
        groupedPlans ? groupedPlans.map(([type, plans], idx) => (
          <div
            key={ type }
            className={ cn(styles.menuItem, {
              [styles.isFocused]: focusOnMenu && focusedIndex === ((products || []).length + idx),
              'focusedNavigationNode': focusOnMenu && focusedIndex === ((products || []).length + idx),
              [styles.isActive]: pathname.includes(type),
            }) }
            onClick={ () => handleRents(type) }
          >
            <div
              className={ styles.menuItemTitle }
            >
              {
                type === RentPlanType.EST ?
                  intl.formatMessage({ id: PURCHASE_TITLE })
                  :
                  intl.formatMessage({ id: RENT_TITLE })
              }
            </div>
            <MemoDescription
              rentPlans={ plans }
              intl={ intl }
            />
          </div>
        )) : null
      }
    </div>
  )
};


export default React.memo(injectIntl(Menu));
