import { chunk } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { PrimaryButtonWrap } from '~app/ui-kit/PrimaryButton';
import HorizontalPointerNavigation from '~components/PointerManager/HorizontalPointerNavigation';
import * as pointerStyles from '~components/PointerManager/styles.module.css';
import useNavigationByKeys from '~hooks/useNavigation';
import useScrollable, { ScrollDirection } from '~hooks/useScrollable/useScrollable';
import { getSceneWidth, valueInPixelsByWidth } from '~lib/SizesInPX';
import { FILTER_BUTTON_RESET, FILTER_BUTTON_SUBMIT } from '~localization';
import usePointer from '~stores/Pointer';
import DropdownFilter, { Option } from '~typings/DropdownFilter';
import NavigationDirection from '~typings/NavigationDirection';
import { PilsButton } from '~ui/PilsButton';
import { Popup } from '~ui/Popup';
import { RadioButton } from '~ui/RadioButton';

import * as styles from './styles.module.css';


const ROWS = 5;

const calculatewidth = (length: number): number =>(
  Math.min(
    Math.ceil(length / ROWS) * valueInPixelsByWidth(28.749999999999996),
    getSceneWidth(),
  )
);


enum FocusOn {
  RadioButtons = 'radio_buttons',
  ButtonSubmit = 'button_submit',
  ButtonCancel = 'button_cancel',
  ButtonDeleteFilters = 'button_delete',
}

type Props = {
  dropdown: DropdownFilter | null;
  selectedFilters: DropdownFilter[] | undefined;
  onClose: (selected: Option[]) => void;
  isDropdown?: any;
}

/**
 * @deprecated, используй DropdownSelectPopup
 */
const FilterDropdown: React.FC<Props> = ({dropdown, selectedFilters, onClose}) => {
  const [focusedIndex, setFocusedIndex] = React.useState<number>(0);
  const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.RadioButtons);
  const currentFilterInSelected = selectedFilters?.find(x => x.id === dropdown?.id);
  const [selected, setSelected] = React.useState<Option[]>(
    () => currentFilterInSelected?.options || []
  );
  const options = React.useMemo(() => {
    const yearFrom = selectedFilters?.find(x => x?.api_param === 'year_gteq');
    const yearTo = selectedFilters?.find(x => x?.api_param === 'year_lteq');

    if (yearFrom && dropdown?.api_param === 'year_lteq') {
      return dropdown?.options
        .filter(x => parseInt(x.value, 10) >= parseInt(yearFrom?.options[0].value, 10) || 0);
    }
    if (yearTo && dropdown?.api_param === 'year_gteq') {
      return dropdown?.options
        .filter(x => parseInt(x.value, 10) <= parseInt(yearTo?.options[0].value, 10) || Infinity);
    }
    return dropdown?.options;
  }, [dropdown, selectedFilters]);
  const cols = React.useMemo(
    () => chunk(options, ROWS),
    [options, ROWS],
  );
  const focusedCol = Math.trunc(focusedIndex / ROWS);

  React.useEffect(() => {
    if (dropdown) {
      setFocusOn(FocusOn.RadioButtons);
      setFocusedIndex(0);
    }
  }, [dropdown]);

  React.useEffect(() => {
    setSelected(currentFilterInSelected?.options || []);
  }, [selectedFilters]);

  const handleKeyNavigate = (direction: NavigationDirection) => {
    if (!dropdown || !options) { return; }

    if (direction === NavigationDirection.Down) {
      if (((focusedIndex + 1) % ROWS === 0 || (focusedIndex + 1) === options.length)
        && focusOn === FocusOn.RadioButtons
      ) {
        setFocusOn(FocusOn.ButtonSubmit);
      } else if (focusOn === FocusOn.ButtonDeleteFilters) {
        setFocusOn(FocusOn.RadioButtons);
      } else {
        setFocusedIndex(prev => Math.min((focusedCol * ROWS) + 4, prev + 1));
      }
    }
    if (direction === NavigationDirection.Up) {
      if (focusOn !== FocusOn.RadioButtons) {
        setFocusOn(FocusOn.RadioButtons);
      } else if (selected.length > 0 && focusedIndex % ROWS === 0) {
        setSelected(selected)
        setFocusOn(FocusOn.ButtonDeleteFilters);
      } else {
        setFocusedIndex(prev => Math.max(focusedCol * ROWS, prev - 1));
      }
      if (focusOn === FocusOn.ButtonDeleteFilters) {
        setFocusOn(FocusOn.ButtonDeleteFilters);
      }
    }
    if (direction === NavigationDirection.Right) {
      if (focusOn === FocusOn.ButtonSubmit) {
        setFocusOn(FocusOn.ButtonCancel);
      } else if (focusedCol + 1 !== Math.ceil(options.length / ROWS)) {
        setFocusedIndex(prev => Math.min(options.length - 1, prev + ROWS));
      }
    }
    if (direction === NavigationDirection.Left) {
      if (focusOn === FocusOn.ButtonCancel) {
        setFocusOn(FocusOn.ButtonSubmit);
      } else if (focusedCol !== 0) {
        setFocusedIndex(prev => Math.max(0, prev - ROWS));
      }
    }
  };

  const update = [options, focusedIndex, focusOn];

  // const throttledHandleKeyNavigate = React.useCallback(
  //   throttle(handleKeyNavigate, changeSliderIndexAnimationMS, { trailing: false }),
  //   update,
  // );

  useNavigationByKeys({
    isMounted: !!options,
    onNavigation: handleKeyNavigate,
  }, update);

  const handleClose = React.useCallback(() => {
    if (selected.length > 0) {
      setSelected([]);
    }
    onClose([]);
  }, []);

  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const [scrollableWrapperRef, scrollable] = useScrollable({
    focusedIndex: focusedCol,
    wrapperSize: getSceneWidth(),
    direction: ScrollDirection.X,
  });

  const handleDeleteFiltersClick = () => {
    if(selected.length > 0) {
      setSelected([]);
      setFocusOn(FocusOn.RadioButtons)
    } else {
      setSelected(selected);
    }
  }

  const hanldeItemClick = React.useCallback((option) => {
    setSelected(
      prev => dropdown?.multiselect ? (
        prev.includes(option) ?
          prev.filter(x => x?.value !== option.value)
          :
          [...prev, option]
      ) : prev.includes(option) ? [] : [option]
    );
  }, []);

  return dropdown && options ? (
    <Popup semiTransporent>
      <div>
        <div className={ styles.dropdownName }>
          { dropdown.name }
        </div>
        <div className={ styles.selectedWrapper }>
          {
            (selected.length > 0) ?
              (<PilsButton
                  onClick={ handleDeleteFiltersClick }
                  isFocused={ focusOn === FocusOn.ButtonDeleteFilters }
                  className={ styles.closeButton }
                  isCloseButton
              />
            ) : (
            <></>)
          }
          {
            selected.map(x => x.name).join(', ')
          }
        </div>
        <div
          ref={ scrollableWrapperRef }
          className={ styles.radioWrapper }
          style={ { width: `${calculatewidth(options.length)}px` } }
        >
          {
            cols.map((col, colIdx) => (
              <div
                key={ `col-${col[0].value}` }
                className={ styles.column }
              >
                {
                  col.map((option, idx) => (
                    <RadioButton
                      key={ option.value }
                      isFocused={
                        (focusOn === FocusOn.RadioButtons)
                        && (colIdx === focusedCol)
                        && (((focusedCol * ROWS) + idx) === focusedIndex)
                      }
                      isChecked={ selected.includes(option) }
                      className={ styles.radioButton }
                      onClick={ () => hanldeItemClick(option) }
                    >
                      <span
                        className={ styles.name }
                      >
                        { option.name }
                      </span>
                    </RadioButton>
                  ))
                }
              </div>
            ))
          }
        </div>
        <div className={ styles.buttonsWrapper }>
          <PrimaryButtonWrap
            isFocused={ focusOn === FocusOn.ButtonSubmit }
            className={ styles.button }
            onClick={ () => onClose(selected) }
          >
            <FormattedMessage id={ FILTER_BUTTON_SUBMIT } />
          </PrimaryButtonWrap>
          <PrimaryButtonWrap
            isFocused={ focusOn === FocusOn.ButtonCancel }
            className={ styles.button }
            onClick={ handleClose }
          >
            <FormattedMessage id={ FILTER_BUTTON_RESET } />
          </PrimaryButtonWrap>
        </div>
        {
          pointerEnabled && scrollable ? (
            <HorizontalPointerNavigation
              isLeftEnabled={ focusedCol > 0 }
              isRightEnabled={ focusedCol < Math.ceil((options?.length ?? 0) / ROWS) }
              classNames={ {
                left: pointerStyles.left,
                right: pointerStyles.right,
              } }
            />
          ) : null
        }
      </div>
    </Popup>
  ) : null;
};

/**
 * @deprecated, используй DropdownSelectPopup
 */
export default React.memo(FilterDropdown);
