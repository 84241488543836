// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lightLoaderWrapper--SfNgr {\n  position: fixed;\n  z-index: 10;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(23, 23, 27, 0.8);\n}\n\n.loader--h9WA7 {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 4.6875vw;\n  height: 4.6875vw;\n  margin: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/LightLoader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,WAAW;EACX,YAAY;EACZ,uCAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".lightLoaderWrapper {\n  position: fixed;\n  z-index: 10;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: $b_80;\n}\n\n.loader {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 4.6875vw;\n  height: 4.6875vw;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export var lightLoaderWrapper = "lightLoaderWrapper--SfNgr";
export var loader = "loader--h9WA7";
export default ___CSS_LOADER_EXPORT___;
