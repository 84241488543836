import useLocationSearch from '~hooks/useLocationSearch';


const useIsErrorPlayerPage = (): boolean => {
  const playerPageHashValue = useLocationSearch()?.player;

  if (playerPageHashValue === 'error') { return true; }

  return false;
};


export default useIsErrorPlayerPage;
