import * as cn from 'classnames';
import * as React from 'react';

import { Typography } from '../Typography';
import * as styles from './styles.module.css';

type Props = {
  isFocused: boolean;
  isSelected?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
};

export const LeftMenuButton: React.FC<Props> = (props: Props) => {
  return (
    <div className={ cn( styles.button, props.className, {
      [styles.focused]: props.isFocused,
      [styles.selected]: !props.isFocused && props.isSelected,
    }) }>
      <Typography variant='subtitle2_medium' className={ styles.text }>
      { props.children }
      </Typography>
    </div>
  )
}
