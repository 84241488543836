import focuser from '@focuser';
import React from 'react';
import { injectIntl } from 'react-intl';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { Typography } from '~app/ui-kit/Typography';
import { useAccount } from '~hooks/fetch/useAccount';
import { useToggle } from '~hooks/useToggle';
import {
  ACCOUNT_LABEL_PASSWORD,
  ACCOUNT_LABEL_USERNAME,
  LOGOUT_FROM_ACCOUNT,
  REGISTRATION_NOTIFY_ME_ABOUT_NEWS_PROMO,
} from '~localization';
import { EditButtonFocuser } from '~newapp/ui-kit/Controls/Buttons/Icon_btn/Big_icon_btn/EditButton';
import { CheckboxFocuser } from '~newapp/ui-kit/Other/Checkbox';

import * as styles from './Control.module.css';
import { ControlField } from './ControlField';
import { useControlHandlers, useControlNavigation, useIsNotificationEditable } from './hooks';
import { LogoutConfirmPopup } from './LogoutConfirmPopup';

export const Control = injectIntl(({ intl }) => {
  const { data: accountData } = useAccount();
  const isNotificationEditable = useIsNotificationEditable();
  const [isLogoutPopupOpen, { turnOn: openLogoutPopup, turnOff: cancelLogout }] = useToggle(false);

  const {
    focusedOn,
    handleUp,
    handleDown,
    setFocusOnLogout,
    setFocusOnPassword,
    setFocusOnCheckbox,
  } = useControlNavigation({
    isNotificationEditable,
  });

  const { handleCheckboxChange, handlePasswordChange } = useControlHandlers();


  if (isLogoutPopupOpen) {
    return <LogoutConfirmPopup onCancel={ cancelLogout } />;
  }

  const renderPasswordEditButton = () => {
    return (
      <EditButtonFocuser
        emitForceFocusOnHover
        onForceFocus={ setFocusOnPassword }
        isFocused={ focusedOn === 'password' }
        onClick={ handlePasswordChange }
      />
    );
  };

  return (
    <focuser.FocusableBlock
      isFocused
      onKeyUp={ handleUp }
      onKeyDown={ handleDown }
      isPointerUpAvailable={ focusedOn !== 'password' }
      isPointerDownAvailable={ focusedOn !== 'logout' }
      noNeedForceFocus
    >
      <div>
        <ControlField
          fieldName={ intl.formatMessage({ id: ACCOUNT_LABEL_USERNAME }) }
          fieldValue={ accountData?.username || '' }
          className={ styles.controlField }
        />
        <ControlField
          fieldName={ intl.formatMessage({ id: ACCOUNT_LABEL_PASSWORD }) }
          fieldValue="***********"
          button={ renderPasswordEditButton() }
          className={ styles.controlField }
        />
      </div>
      {isNotificationEditable && (
        <div className={ styles.checkboxLine }>
          <CheckboxFocuser
            isFocused={ focusedOn === 'checkbox' }
            isChecked={ accountData?.allow_notifications }
            focuserClassName={ styles.checkboxButton }
            onForceFocus={ setFocusOnCheckbox }
            onClick={ handleCheckboxChange }
            emitForceFocusOnHover
          />
          <Typography variant="subtitle1_medium">
            {intl.formatMessage({ id: REGISTRATION_NOTIFY_ME_ABOUT_NEWS_PROMO })}
          </Typography>
        </div>
      )}
      <PrimaryButtonFocuser
        onForceFocus={ setFocusOnLogout }
        focuserClassName={ styles.logoutButton }
        isFocused={ focusedOn === 'logout' }
        emitForceFocusOnHover
        onClick={ openLogoutPopup }
      >
        {intl.formatMessage({ id: LOGOUT_FROM_ACCOUNT })}
      </PrimaryButtonFocuser>
    </focuser.FocusableBlock>
  );
});
