import { useEffect, useRef } from 'react';

import useHotKeysStore from '~stores/HotKeys';
import { HotKey } from '~typings/HotKey';


type Props = Readonly<{
  isMounted: boolean;
  keyName: HotKey;
  action: () => void;
}>;

export const useHotKey = (props: Props, update: any[] = []): void => {
  const hotKey = useRef<any>(null);
  const bind = useHotKeysStore(state => state.bind);

  // unbind hot key when hook unmounting
  useEffect(() => {
    hotKey.current = bind({
      keyName: props.keyName,
      action: props.action,
      isActive: props.isMounted,
    });

    return () => {
      if (hotKey.current) {
        hotKey.current.unbind();
      }
    }
  }, []);

  useEffect(() => {
    hotKey.current.update({
      action: props.action,
      isActive: props.isMounted,
    });
  }, [...update, props.isMounted, props.action]);
};


export default useHotKey;
