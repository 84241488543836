import { CardMutationState } from '~typings/CardMutation';
import ProgramEvent from '~typings/Event';


type Items = Array<ProgramEvent | CardMutationState>;

type Result = ProgramEvent | CardMutationState | undefined;


const currentByStartAtAndEndAt = (items?: Items, pivotDate: Date = new Date()): Result => {
  if (!items || items.length === 0) { return undefined; }

  try {
    const current = (new Date(pivotDate).getTime() / 1000) >> 0;

    return items.find(({ start_at, end_at }) => {
      const startAt = (new Date(start_at).getTime() / 1000) >> 0;

      if (!end_at) { return (current >= startAt); }

      const endAt = (new Date(end_at).getTime() / 1000) >> 0;

      return (current >= startAt && current < endAt);
    });
  } catch (ignore) {
    return undefined;
  }
};

export default currentByStartAtAndEndAt;
