import cn from 'classnames';
import React from 'react';

import { ResetButton } from '~app/ui-kit/ResetButton';

import * as styles from './TextEditInput.module.css';

export interface Props {
  /**
   * Значение которое показывается
   */
  value?: string;
  /**
   * Выравнивание текста по левому краю или по центру
   * @default center
   */
  align?: 'left' | 'center';
  /**
   * Текст, который будет показан, когда ввод пуст
   */
  placeholder?: string;
  /**
   * Состояние фокуса
   */
  isFocused: boolean;

  /**
   * Дополнительный CSS-класс
   */
  className?: string;
}

export const TextEditInput: React.FC<Props> = ({
  value = '',
  align = 'center',
  placeholder,
  className,
  isFocused,
}) => {
  const isValueExists = value.length > 0;

  return (
    <div className={ cn(styles.TextEditInput, {
      [styles.TextEditInputFocusable]: isValueExists,
    }, className,) }>
      <div className={ styles.inputAndButton }>
        <div className={ cn(styles.input, { [styles.inputCenter]: align === 'center' }) }>
          <div className={ cn(styles.text, { [styles.textPlaceholder]: !isValueExists }) }>
            {isValueExists ? value : placeholder}
          </div>
          {isValueExists && <div className={ styles.cursor } />}
        </div>
        {isValueExists && (
          <ResetButton
            className={ styles.resetButton }
            isFocused={ isFocused }
          />
        )}
      </div>
      <div className={ styles.bottomLine }></div>
    </div>
  );
};
