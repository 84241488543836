import { useMemo } from "react";

import { useWatchProgressMultiple } from "~hooks/fetch/useWatchProgress/useWatchProgress";
import { getWindowAboveFocus } from "~lib/getWindowAboveFocus";
import AudioShowPart from "~typings/AudioShowPart";
import ItemObject from "~typings/ItemObject";


const WINDOW_SIZE = 10;


export const useWatchProgressForParts = (parts: AudioShowPart[], focusedIdx: number) => {

  const {start, end} = useMemo(() => getWindowAboveFocus(focusedIdx, WINDOW_SIZE), [focusedIdx]);

  const partIds = useMemo(() => parts.slice(start, end).map((part) => part.id), [parts, start, end]);

  const { data: watchProgress} = useWatchProgressMultiple(ItemObject.Part, partIds);

  const watchProgressForFocusedPart = useMemo(() => {
    const wpToShow = watchProgress?.find((wp) => wp.resource.id === parts[focusedIdx]?.id);
    if (!wpToShow) return null;
    if (!wpToShow.started) {
      return null;
    }

    return wpToShow.percents_watched;
  }, [watchProgress, focusedIdx, parts]);

  return {
    watchProgressForFocusedPart
  }
}
