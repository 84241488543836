import { useMutation } from 'react-query';

import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';


export type PhoneCallConfirmationResult = Readonly<{
  data?: {
    confirmed: boolean;
    object: 'confirmation_status';
  };
  errors?: {
    object: 'error';
    code: 'invalid_confirmation_code';
  }[];
  meta: {
    status: number;
  };
}>;

const fetch = async (
  client: ApiClient,
  username: string,
): Promise<PhoneCallConfirmationResult> => client.get(
  '/v1/users/confirmation_status.json',
  {
    username,
  },
);

export const usePhoneCallConfirmMutation = () => {
  const client = useClient();

  return useMutation(({ username }: { username: string }) => (
    fetch(client, username)
  ));
}

export default usePhoneCallConfirmMutation;
