import * as React from 'react';
import { Transition } from 'react-transition-group';


type Props = Readonly<{
  isVisible: boolean;
  duration?: number;
  delay?: number;
  willBeNeedRemoveChildAfterFadeOut?: boolean;
  isDisplayNone?: boolean;
  className?: string;
  children: any;
  itemKey?: any;
}>;


const Fade: React.FC<Props> = (props: Props) => {
  const {
    duration = 300,
    delay = 0,
    isDisplayNone,
    isVisible
  } = props;

  const defaultStyle = {
    transition: `opacity ${duration}ms linear ${delay}ms`,
    opacity: 0,
  }

  const transitionStyles = {
    appearing: { opacity: 0 },
    appeared:  { opacity: 1 },
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
  };

  return (
    <Transition
      key={ `fade-${props.itemKey || 'fade'}` }
      in={ isVisible }
      timeout={ {
        appear: duration,
        enter: duration,
        exit: duration + (duration / 2),
      } }
      unmountOnExit={ props.willBeNeedRemoveChildAfterFadeOut }
      appear
    >
      {
        (state) => {
          const newStyle = {
            ...defaultStyle,
            ...transitionStyles[state],
            ...(isDisplayNone && { display: 'none' }),
          };

          return (
            <div className={ props.className } style={ newStyle }>
              { props.children }
            </div>
          )
        }
      }
    </Transition>
  );
};


export default React.memo(Fade);
