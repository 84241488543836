import { parse } from 'query-string';
import { stringify } from 'query-string';
import * as React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import MediaItemDetails from '~components/MediaItemDetails';
import AudioShowPage from '~components/MediaItemPage/AudioShow/AudioShowPage';
import { ChannelPage } from '~components/MediaItemPage/Channel';
import { MoviePage } from '~components/MediaItemPage/Movie';
import NewsPage from '~components/MediaItemPage/News/component';
import { ProgramEventPage, ProgramEventPlayer } from '~components/MediaItemPage/ProgramEvent';
import { SeriesPage } from '~components/MediaItemPage/Series';
import { SeriesPageEpisodeRedirector } from '~components/MediaItemPage/Series';
import ProductsForRecource from '~components/ProductsForRecource/ProductsForRecource';
import PromoPaymentProceed from '~components/ProductsForRecource/PromoPaymentProceed';
import PromoRentInvoce from '~components/ProductsForRecource/PromoRentInvoce';
import PromocodeResourcePage from '~components/PromocodeActivation/screens/PromocodeRentPlanConfirm/PromocodeRentPlanResourcePage/PromocodeRentPlanResourcePage';
import { ActionType, useAppAction } from '~components/Provider/App';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { useScene } from '~components/Provider/Scene';
import { getAuthData } from '~hooks/fetch/useAccount';
import useMediaItem, { getMediaItemKey } from '~hooks/fetch/useMediaItem';
import { getStreamAccessKey } from '~hooks/fetch/useStreamAccess';
import { PREFIX_OF_A_COMPOSITE_KEY as subscriptionsKey } from '~hooks/fetch/useSubscriptions';
import { getKey as getVoteKey } from '~hooks/fetch/useVote/useVote';
import useIsPlayerPage from '~hooks/useIsPlayerPage';
import useMediaItemPageInfo from '~hooks/useMediaItemPage';
import findMediaItem from '~lib/findMediaItem';
import deleteCacheByKeys from '~lib/ReactQuery/deleteCacheByKeys';
import AudioShow from '~typings/AudioShow';
import AudioShowPart from "~typings/AudioShowPart";
import Channel from '~typings/Channel';
import { DataItemCollection } from '~typings/DataItem';
import Episode from '~typings/Episode';
import Event from '~typings/Event';
import ItemObject from '~typings/ItemObject';
import Movie from '~typings/Movie';
import News from '~typings/News';
import Series from '~typings/Series';

import { useMediaItemScreenSearchParams } from './MediaItemScreenHooks';
import { RET_PATH_SEARCH_FIELD } from './MediaItemScreenTypes';


const MediaItemScreen: React.FC = () => {
  const isPlayerPage = useIsPlayerPage();
  const mediaScreenSearchParams = useMediaItemScreenSearchParams();
  const { setItem: playerSetItem } = usePlayerStateActions();
  const history = useHistory();
  const { accessToken } = getAuthData();
  const appAction = useAppAction();
  const scene = useScene();
  const { path } = useRouteMatch();
  const { mediaItemPage } = useMediaItemPageInfo();
  const cachedItem = findMediaItem(mediaItemPage.id, mediaItemPage.object, true);
  const cachedAudioShowItem = cachedItem?.object === ItemObject.Part ? cachedItem.audio_show : undefined;
  // Если осуществлён переход на страницу медиа элемента,
  // но сам медиа элемент отсутствует в кеше, то его необходимо зафетчить
  const fetchID = (cachedItem === null) ? mediaItemPage.id : undefined;
  const fetchObject = (cachedItem === null) ? mediaItemPage.object : undefined;
  const fetchedItem = useMediaItem(fetchID, fetchObject);

  React.useEffect(() => () => {
    if (mediaItemPage.id && mediaItemPage.object) {
      const keys = [
        subscriptionsKey,
        getMediaItemKey(accessToken, mediaItemPage.id, mediaItemPage.object),
        getStreamAccessKey({
          itemID: mediaItemPage.id,
          itemObject: mediaItemPage.object,
          accessToken,
        }),
      ];

      if (accessToken) {
        if (
          mediaItemPage.object === ItemObject.Movie
          || mediaItemPage.object === ItemObject.Series
        ) {
          keys.push(getVoteKey(mediaItemPage.object, mediaItemPage.id));
        }
      }

      deleteCacheByKeys(keys);
    }
  }, [mediaItemPage.id]);

  React.useEffect(() => {
    if (cachedItem !== null) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: { isLaunchingDone: true },
      });

      if (
        cachedItem.object === ItemObject.Movie
        || cachedItem.object === ItemObject.Series
        || cachedItem.object === ItemObject.AudioShow
      ) {
        scene.changeSceneMediaItem(cachedItem, true, true);
      }

      if(cachedItem.object === ItemObject.Part) {
        scene.changeSceneMediaItem(cachedItem.audio_show, true, true);
      }

      if(cachedItem.object === ItemObject.Episode) {
        scene.changeSceneMediaItem(cachedItem.series!, true, true);
      }
    }
  }, [cachedItem?.id, fetchedItem.isLoading, fetchedItem.data?.data]);

  React.useEffect(() => {
    if (mediaScreenSearchParams?.immidiatePlay && cachedItem) {
      // Тут мы точно знаем, что cachedItem предназначен для проигрывания
      // поэтому сразу запускаем стрим с данным медиа элементом
      const currentSearch = parse(history.location.search);
      // Удаляем параметр RET_PATH_SEARCH_FIELD из поисковой строки
      // потому что этот параметр уже обработан и не нужен
      delete currentSearch[RET_PATH_SEARCH_FIELD];
      history.replace({
        search: stringify(currentSearch),
      })
      playerSetItem(cachedItem as any);
    }
  }, [cachedItem]);

  if (!cachedItem) {
    return null;
  }

  if (
    isPlayerPage && (
      cachedItem.object !== ItemObject.Channel &&
      cachedItem.object !== ItemObject.ProgramEvent
    )
  ) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={ path }>
        <div>404</div>
      </Route>
      <Route path={ `${ path }/:itemObject/:itemId/details` }>
        <MediaItemDetails />
      </Route>
      <Route path={ `${ path }/:itemObject/:itemId/products` }>
        <ProductsForRecource />
      </Route>
      <Route path={ `${ path }/:itemObject/:itemId/promo/:invoiceID` }>
        <PromoPaymentProceed mediaItem={ cachedItem as DataItemCollection } />
      </Route>
      <Route exact path={ `${ path }/:itemObject/:itemId/promo` }>
        <PromoRentInvoce />
      </Route>
      <Route path={ `${ path }/${ ItemObject.Channel }/:itemId/player` }>
        <ChannelPage withFullInfo item={ cachedItem as Channel } />
      </Route>
      <Route path={ `${ path }/${ ItemObject.Channel }/:itemId` }>
        <ChannelPage withFullInfo item={ cachedItem as Channel } />
      </Route>
      <Route exact path={ `${ path }/${ ItemObject.Series }/:itemId` }>
        <SeriesPage item={ cachedItem as Series } />
      </Route>
      <Route path={ `${ path }/${ ItemObject.Series }/:itemId/promocode` }>
        <PromocodeResourcePage item={ cachedItem as Series } />
      </Route>
      <Route exact path={ `${ path }/${ ItemObject.Episode }/:itemId` }>
        <SeriesPage
          item={ (cachedItem as Episode).series as Series }
          selectedEpisodeID={ (cachedItem as Episode).id }
        />
      </Route>
      <Route path={ `${ path }/${ ItemObject.Episode }/:itemId/player` }>
        <SeriesPageEpisodeRedirector episode={ (cachedItem as Episode) }/>
      </Route>
      <Route exact path={ `${ path }/${ ItemObject.Movie }/:itemId` }>
        <MoviePage item={ cachedItem as Movie } />
      </Route>
      <Route path={ `${ path }/${ ItemObject.Movie }/:itemId/promocode` }>
        <PromocodeResourcePage item={ cachedItem as Movie } />
      </Route>
      <Route path={ `${ path }/${ ItemObject.AudioShow }/:itemId` }>
        <AudioShowPage item={ cachedItem as AudioShow } />
      </Route>
      <Route path={ `${ path }/${ ItemObject.Part }/:itemId` }>
        <AudioShowPage item={ cachedAudioShowItem as AudioShow } part={ (cachedItem as AudioShowPart) } />
      </Route>
      <Route path={ `${ path }/${ ItemObject.Part }/:itemId/player` }>
        <AudioShowPage part={ cachedItem as AudioShowPart } />
      </Route>
      <Route path={ `${ path }/${ ItemObject.News }/:itemId` }>
        <NewsPage withFullInfo item={ cachedItem as News } />
      </Route>
      <Route path={ `${ path }/${ ItemObject.ProgramEvent }/:itemId/player` }>
        <ProgramEventPlayer item={ cachedItem as Event } />
      </Route>
      <Route path={ `${ path }/${ ItemObject.ProgramEvent }/:itemId` }>
        <ProgramEventPage item={ cachedItem as Event } />
      </Route>
      <Route exact path={ `${ path }/:itemObject` }>
        <div>404</div>
      </Route>
    </Switch>
  );
};

export { MediaItemScreen };

export default MediaItemScreen;
