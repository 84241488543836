import { useEffect, useRef } from 'react';

import { usePlatform } from "~components/Provider/Platform";
import { useClient } from "~global";
import { Events } from '~lib/player/common';
import { IPlayer } from '~lib/player/typings';
import VideoError from "~lib/player/VideoError";
import { DeviceInfo } from '~typings/DeviceInfo';
import { Heartbeats } from '~typings/Heartbeats';
import PlatformName from "~typings/PlatformName";
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Stream from '~typings/Stream';

import send from './send';
import useTimeshift from './useTimeshift';

interface PropsTS {
  player: IPlayer | null;
  stream: Stream;
  mediaItem: PlayerMediaItem;
  device: DeviceInfo;
}

interface HeartBeatsTS {
  mediascope: Heartbeats | null,
  vitrina_tv: Heartbeats | null,
  vitrina_tns: Heartbeats | null
}

interface HeartBeatsIntervalsTS {
  mediascope?: number,
  vitrina_tv?: number,
  vitrina_tns?: number
}

enum TrackingSystem {
  mediascope = 'mediascope',
  vitrina_tv = 'vitrina_tv',
  vitrina_tns = 'vitrina_tns'
}

const activeTrackingSystems = [TrackingSystem.mediascope, TrackingSystem.vitrina_tv]
const DEFAULT_INTERVAL = 15;
const startStream = Date.parse(new Date().toUTCString().replace(' GMT', '')).toString()


/**
 * mediascope
 * vitrina
 * активные трекинг системы лежат в activeTrackingSystems, можно добавить и другие
 * системы используются одновременно
 */

const useHeartbeats = ({ player, stream, mediaItem, device }: PropsTS) => {
  const { platform } = usePlatform();
  const client = useClient()
  const deviceInfo = platform.getDeviceInfo();
  const isLive = mediaItem.object === 'channel';
  const {
    started,
    time,
    startTimeshift,
    resetTimeshift,
  } = useTimeshift({ player, isLive, device })

  const heartBeatsIntervals = useRef<HeartBeatsIntervalsTS>({});
  const heartBeats = useRef<HeartBeatsTS>({ mediascope: null, vitrina_tv: null, vitrina_tns: null })
  const paused = useRef<boolean>(false)
  const prevTime = useRef<number>(time.current)


  //дергаем трекинг системы из стрима
  const streamTrackingSystems =
    stream.heartbeats.reduce((total, heartbeat) => {
      total.push(heartbeat.name as TrackingSystem)
      return total
    }, [] as TrackingSystem[])

  //получаем актуальные трекинг системы которые работают у нас
  const enabledTrackingSystems =
    streamTrackingSystems.filter(trackingSystem => activeTrackingSystems.includes(trackingSystem))

  // для каждой системы проверяем есть ли объект в рефе, если нет добавляем
  enabledTrackingSystems.forEach(trackingSystem => {
    if (!heartBeats.current[trackingSystem]) {
      heartBeats.current[trackingSystem] =
        stream.heartbeats.find(heartBeat => heartBeat.name === TrackingSystem[trackingSystem]) || null;
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const mediascope = (action = 'on_heartbeat', error?: VideoError) => {
    const hearbeatUrl = heartBeats.current[TrackingSystem.mediascope]?.actions[action]

    if (!hearbeatUrl) {
      return;
    }

    const url = hearbeatUrl
      .replace('{tns_fts}', Math.floor(Math.min(Date.now() / 1000, time.current)))
      .replace('{tns_vts}', Math.floor(Date.now() / 1000))
      .replace(/(dvtp:(\w|_)+)/, 'dvtp:5')
      .replace(/(advid:\{(\w|_)+\})/, '')
      .replace(/(idfa:\{(\w|_)+\})/, '')
      .replace(/(dvid:\{(\w|_)+\})/, '')
      .replace(/(mac:\{(\w|_)+\})/, '')
      .replace(/(app:\{(\w|_)+\})/, '')
      .replace(/:{2,}/g, ':');

    send(url);
  };
  const vitrina = (action = 'on_heartbeat', error?: VideoError) => {
    const currentEvent = heartBeats.current[TrackingSystem.vitrina_tv]?.actions_v2[action]
    if (!currentEvent) {
      return;
    }

    const params = {
      deviceOsName: deviceInfo.osName?.toString().replace(' ', '%20') || '',
      deviceId: deviceInfo.deviceId?.toString().replace(' ', '%20') || '',
      deviceModel: deviceInfo.model?.toString().replace(' ', '%20') || '',
      deviceOsVersion: deviceInfo.osVersion?.toString().replace(' ', '%20') || '',
      deviceVendor: deviceInfo.vendor?.toString().replace(' ', '%20') || '',
      dvrLiveVod: isLive ? 'live' : ' vod',
      streamStartTimeStamp: startStream,
      eventTimeStamp: Date.parse(new Date().toUTCString().replace(' GMT', '')).toString(),
      timeZone: client.defaultQuery.timezone.toString() || '',
      platformName: getCorrectPlatformName(__PLATFORM__),
      errorShort: `errorCode:%20${ error?.vastError }`,
      // eslint-disable-next-line max-len
      fullError: `errorCode:%20${ error?.vastError },%20errorMsg:%20${ error?.message.toString().replace(' ', '%20') }`,
      empty: '',
    }

    const url = currentEvent.url
      .replace(/{{(.*?)\}}/g, params.empty)
      .replace('{vitrina_mode}', params.dvrLiveVod)
      .replace('{event_timestamp}', params.eventTimeStamp) //
      .replace('{web_location}', params.empty)
      .replace('{os_name}', params.deviceOsName)
      .replace('{vitrina_user_os_ver_major}', params.deviceOsVersion)
      .replace('{vitrina_user_os_ver_minor}', params.empty)
      .replace('{device_vendor}', params.deviceVendor)
      .replace('{vitrina_device_type}', 'tv')
      .replace('{device_model}', params.deviceModel)
      .replace('{vitrina_user_browser}', params.empty)
      .replace('{vitrina_user_browser_ver}', params.empty)
      .replace('{vitrina_user_browser_ver_major}', params.empty)
      .replace('{vitrina_user_browser_ver_minor}', params.empty)
      .replace('{stream_timestamp}', params.streamStartTimeStamp) //
      .replace('{client_time_zone_offset}', params.timeZone)
      .replace('{app_package_id}', params.empty)
      .replace('{bitrate}', params.empty)
      .replace('{vitrina_drm}', params.empty)
      .replace('{vitrina_init_before_stream_or_ad_request_msec}', params.empty)
      .replace('{vitrina_stream_or_ad_initial_buffering_msec}', params.empty)
      .replace('{vitrina_content_sec}', params.empty)
      .replace('{vitrina_pause_sec}', params.empty)
      .replace('{vitrina_error_title}', error ? params.errorShort : params.empty)
      .replace('{vitrina_error_adv}', error ? params.fullError : params.empty)
      .replace('{vitrina_buffering_sec}', params.empty)
      .replace('{vitrina_buffering_count}', params.empty)
      .replace('{vitrina_client_ad_sec}', params.empty)
      .replace('{vitrina_ad_position}', params.empty)
      .replace('{vitrina_is_subtitles_mode}', params.empty)
      .replace('{vitrina_is_fullscreen_mode}', params.empty)
      .replace('{vitrina_is_muted}', params.empty)
      .replace('{vitrina_product}', params.platformName)
      .replace('{vitrina_is_web_player}', '1')
      .replace(/{(.*?)\}/g, params.empty)
    send(url)
  }

  const getTrackingSystemLogic = (system: TrackingSystem) => {
    switch (system) {
      case TrackingSystem.mediascope: {
        return mediascope
      }
      case TrackingSystem.vitrina_tv: {
        return vitrina
      }
      default:
        return () => null
    }
  }
  const getCorrectPlatformName = (platform: string) => {
    switch (platform) {
      case PlatformName.WebOS: {
        return 'app_webos'
      }
      case PlatformName.Tizen: {
        return 'app_tizen'
      }
      default:
        return ''
    }
  }


  const startHeartbeats = () => {
    const intervalsExist =
      enabledTrackingSystems.every(trackingSystem => heartBeatsIntervals.current[trackingSystem] !== undefined)

    if (paused.current) {
      enabledTrackingSystems.forEach(trackingSystem => {
        getTrackingSystemLogic(trackingSystem)('on_resume')
      })
      paused.current = false;
    }

    if (intervalsExist) {
      return
    }

    enabledTrackingSystems.forEach(trackingSystem => {
      heartBeatsIntervals.current[trackingSystem] = window.setInterval(
        getTrackingSystemLogic(trackingSystem),
        (heartBeats.current[trackingSystem]?.period || DEFAULT_INTERVAL) * 1000
      )
    })

    startTimeshift();

    if (!paused.current) {
      enabledTrackingSystems.forEach(trackingSystem => {
        getTrackingSystemLogic(trackingSystem)('on_start')
      })
    }
  };
  const resetSession = () => {
    enabledTrackingSystems.forEach(trackingSystem => {
      getTrackingSystemLogic(trackingSystem)('on_stop')
      clearInterval(heartBeatsIntervals.current[trackingSystem])
      heartBeats.current[trackingSystem] = null
    })
  };
  const handleTimeUpdate = () => {
    if (!started) {
      startHeartbeats();
      return;
    }

    if (
      time.current !== 0
      && Math.abs(prevTime.current - time.current) > 5000
      && Math.abs(prevTime.current - time.current) < time.current
    ) {
      enabledTrackingSystems.forEach(trackingSystem => {
        getTrackingSystemLogic(trackingSystem)('on_rewind')
      })
    }
    prevTime.current = time.current
  };
  const handleError = (error: VideoError) => {
    if (enabledTrackingSystems.includes(TrackingSystem.vitrina_tv)) {
      getTrackingSystemLogic(TrackingSystem.vitrina_tv)('error', error)
    }
    resetSession();
  };
  const handlePause = () => {
    if (!started) {
      return;
    }
    enabledTrackingSystems.forEach(trackingSystem => {
      getTrackingSystemLogic(trackingSystem)('on_pause')
    })
    paused.current = true;
  };
  const handleStop = () => {
    if (!started) {
      return;
    }
    resetSession();
  };

  useEffect(() => {
    if ((stream.heartbeats?.length || 0) > 0 && player) {
      startHeartbeats();
    }

    return () => {
      resetTimeshift();
    }
  }, [stream, player]);

  useEffect(() => {
    if (!player) {
      return;
    }

    player.on(Events.TIME_UPDATE, handleTimeUpdate);
    player.on(Events.ERROR, handleError);
    player.on(Events.PAUSE, handlePause);
    player.on(Events.STOP, handleStop);
    player.on(Events.PLAY, startHeartbeats)

    return () => {
      if (!player) {
        return;
      }
      resetSession();
      player.removeListener(Events.TIME_UPDATE, handleTimeUpdate);
      player.removeListener(Events.ERROR, handleError);
      player.removeListener(Events.PAUSE, handlePause);
      player.removeListener(Events.STOP, handleStop);
      player.removeListener(Events.PLAY, startHeartbeats)
    };
  }, [player]);
};

export { useHeartbeats };
