// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title--KR2aq {\r\n  color: rgba(223, 223, 228, 1);\r\n  margin-bottom: 1.5625vw;\r\n  margin-top: 0.78125vw;\r\n}\r\n\r\n.descr--GtiH6 {\r\n  color: rgba(223, 223, 228, 1);\r\n  margin-bottom: 2.34375vw;\r\n  width: 56.88vw;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/Promocode/styles.modules.css"],"names":[],"mappings":"AAAA;EACE,6BAAa;EACb,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,6BAAa;EACb,wBAAwB;EACxB,cAAc;AAChB","sourcesContent":[".title {\r\n  color: $gw_08;\r\n  margin-bottom: 1.5625vw;\r\n  margin-top: 0.78125vw;\r\n}\r\n\r\n.descr {\r\n  color: $gw_08;\r\n  margin-bottom: 2.34375vw;\r\n  width: 56.88vw;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var title = "title--KR2aq";
export var descr = "descr--GtiH6";
export default ___CSS_LOADER_EXPORT___;
