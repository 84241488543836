export enum PeriodUnit {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  Months = 'months',
  Years = 'years',
  Unlimited = 'unlimited',
}

export type ApiPeriodUnit = (
  'minutes'
  | 'hours'
  | 'days'
  | 'months'
  | 'years'
  | 'unlimited'
);

type Period = Readonly<{
  value: number | null;
  object: 'period';
  unit: ApiPeriodUnit;
}>;


export default Period;
