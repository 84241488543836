import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Alert2 } from "~components/Alert";
import Placeholder from '~components/MediaItemPage/Buttons/Placeholder';
import { getMeta, SceneMediaItemDescription } from '~components/MediaItemPage/Description';
import MediaItemBar from '~components/MediaItemPage/Description/bar';
import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import SceneMediaItemDescriptionText from '~components/MediaItemPage/Description/description-text';
import SceneMediaItemMeta from '~components/MediaItemPage/Description/meta';
import { FocusOn, navigationItems } from '~components/MediaItemPage/ProgramEvent/component.helpers';
import PurchaseInfo from '~components/MediaItemPage/PurchaseInfo/PurchaseInfo';
import { getBarsItems } from '~components/MediaItemPage/utils';
import { useMenu } from '~components/Provider/Menu';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { PlayerAccessReason } from '~components/Provider/Player/component.helpers';
import { useFavoriteItem } from '~hooks/fetch/useFavorites/useFavoritesV3';
import useAccess from '~hooks/useAccess/useAccess';
import useLocationSearch from '~hooks/useLocationSearch';
import usePointerNavigation from '~hooks/usePointerNavigation/usePointerNavigation';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import Event from '~typings/Event';
import FavoriteType from '~typings/FavoriteType';
import ItemObject from '~typings/ItemObject';
import LineDelayScroll from '~ui/LineDelayScroll/component';

import DescriptionWrapper from '../Description/wrapper';


type Props = Readonly<{
  item: Event;
}>;


const ProgramEventPage: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const menu = useMenu();
  const { data: isInFavorites } = useFavoriteItem(FavoriteType.Channels, props.item?.channel_id);
  const { setItem: playerSetItem } = usePlayerStateActions();
  const access = useAccess(props.item.id, ItemObject.ProgramEvent);
  const rented = access?.reason === PlayerAccessReason.Rented;
  const notPurchased = access?.reason === PlayerAccessReason.NotPurchased;
  const barItems = getBarsItems({
    access,
    isAuthorized: false,
    hasTrailer: false,
    object: ItemObject.ProgramEvent,
    isInFavorites,
    notPurchased,
  });
  const navItems = React.useRef(navigationItems({
    barItems,
    description: props.item.description,
  }));

  const handleBarClick = (barItem: BarItem) => {
    if (barItem === BarItem.Play || barItem === BarItem.PlayContinue) {
      playerSetItem(props.item);
    }
  };

  const matchPage = useRouteMatch('/page/:id');
  const descriptionClick = () => {
    history.push(`${
      matchPage ? `${ matchPage.url }/` : '/'
    }media-item/${ props.item.object }/${ props.item.id }/details`);
  };

  const allowNavigation = !useLocationSearch()?.player && !menu.isPopupOpened;
  const navigationState = useSpatialNavigation({
    allowNavigation,
    navigationItems: navItems.current,
  });
  usePointerNavigation({
    allowNavigation,
    itemsCount: navigationItems.length,
    focusOn: navigationState.focusOn,
  });
  const lineDelayScrollFocusedLine = navigationState.focusOn === FocusOn.Recommendations ? 1 : 0;

  return (
    <SceneMediaItemDescription
      withFullInfo
      item={ props.item }
    >
      <LineDelayScroll
        focusedLine={ lineDelayScrollFocusedLine }
        lines={
          [
            <>
              <DescriptionWrapper
                isFocused={ allowNavigation && navigationState.focusOn === FocusOn.Description }
                onClick={ descriptionClick }
              >
                <SceneMediaItemMeta
                  meta={ getMeta(props.item) }
                />
                <SceneMediaItemDescriptionText
                  description={ props.item.description }
                />
              </DescriptionWrapper>
              {
                !access ? (
                  <Placeholder />
                ) : <>
                  <MediaItemBar
                    mediaItem={ props.item }
                    items={ barItems }
                    isFocused={ allowNavigation && navigationState.focusOn === FocusOn.Bar }
                    focusedIndex={ navigationState.focusedIndex[FocusOn.Bar] }
                    onClick={ handleBarClick }
                  />
                  { !access.allowed && access.reason && <Alert2 reason={ access.reason } /> }
                </>
              }
              { rented ? <PurchaseInfo purchase={ access.reason_object } /> : null }
            </>
          ]
        }
      />
    </SceneMediaItemDescription>
  );
};


export default React.memo(ProgramEventPage);
