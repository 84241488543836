import focuser, { FocuserKeyHandler } from '@focuser';
import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';

import { LeftMenuButtonFocuser } from '~app/ui-kit/LeftMenuButton';
import { SliderVerticable } from '~ui/SliderVerticable';

import * as styles from './SidebarWithTabs.module.css';
import { SidebarTab } from './types';

export interface Props<ID = string> {
  /**
   * Табы, которые будут отображаться
   * (передавать мемоизированный массив)
   */
  tabs: SidebarTab<ID>[];
  /**
   * Текущая активная вкладка
   */
  selectedTabId: ID;
  /**
   * Коллбэк, который будет вызван при смене активной вкладки
   */
  onTabSelect: (tabId: ID) => void;
  /**
   * Дополнительные CSS-классы
   */
  className?: string;
}

/**
 * Сайдбар с табами, отображающий активный таб
 *
 * Навигация внутри на фокусере
 */
function SidebarWithTabs<T extends string>(props: Props<T>) {
  const [focusedTabId, setFocusedTabId] = React.useState(props.selectedTabId);
  const focusedTabIdx = useMemo(
    () => props.tabs.findIndex((tab) => tab.id === focusedTabId),
    [focusedTabId, props.tabs],
  );


  useEffect(() => {
    if (focusedTabIdx === -1) {
      setFocusedTabId(props.tabs[0]?.id);
    }
  }, [props.tabs]);

  const onUp: FocuserKeyHandler = (e) => {
    const nextTabId = props.tabs[focusedTabIdx - 1]?.id;
    if (nextTabId) {
      e.stop();
      setFocusedTabId(nextTabId);
    }
  };

  const onDown: FocuserKeyHandler = (e) => {
    const nextTabId = props.tabs[focusedTabIdx + 1]?.id;
    if (nextTabId) {
      e.stop();
      setFocusedTabId(nextTabId);
    }
  };

  return (
    <focuser.FocusableBlock
      isFocused
      onKeyUp={ onUp }
      onKeyDown={ onDown }
      className={ cn(styles.SidebarWithTabs, props.className) }
      noNeedForceFocus
    >
      <SliderVerticable focusedIndex={ focusedTabIdx } childBufferCount={ 2 }>
      {props.tabs.map((tab) => (
        <LeftMenuButtonFocuser
          key={ tab.id }
          isFocused={ tab.id === focusedTabId }
          isSelected={ tab.id === props.selectedTabId }
          emitForceFocusOnHover
          onForceFocus={ () => setFocusedTabId(tab.id) }
          onClick={ () => props.onTabSelect(tab.id) }
          buttonClassName={ styles.button }
        >
          {tab.text}
        </LeftMenuButtonFocuser>
      ))}
      </SliderVerticable>
    </focuser.FocusableBlock>
  );
}

export const SidebarWithTabsMemoized = React.memo(SidebarWithTabs) as typeof SidebarWithTabs;
