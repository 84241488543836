import cn from "classnames";
import React from "react";

import * as css from "./ProgressBar.module.css";

interface Props {
  progress: number;
  className?: string;
}

export function ProgressBar({ progress, className }: Props) {
  return (
    <div className={ cn(css.progressBarWrapper, className) }>
       <div className={ css.progressBar } style={ { width: `${ Math.min(Math.max(progress, 0), 100) }%` } } />
    </div>
  )
}
