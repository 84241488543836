import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { Checkbox } from './Checkbox';

type Props = {
  isFocused: boolean;
  isChecked?: boolean;
  focuserClassName?: string;
  buttonClassName?: string;
  isDisabled?: boolean;
} & Omit<FocusableBlockProps, 'className' | 'isLastBlock' | 'children'>;

export const CheckboxFocuser: React.FC<Props> = ({
  isFocused,
  isChecked,
  focuserClassName,
  buttonClassName,
  isDisabled,
  ...focuserProps
}) => {
  return (
    <focuser.FocusableBlock
      { ...focuserProps }
      className={ focuserClassName }
      isFocused={ isFocused && !isDisabled }
      onClick={ isDisabled ? undefined : focuserProps.onClick }
      emitForceFocusOnHover={ isDisabled ? false : focuserProps.emitForceFocusOnHover }
      isLastBlock
    >
      {({ isFocused }) => (
        <Checkbox
          className={ buttonClassName }
          isFocused={ isFocused }
          isChecked={ isChecked }
        ></Checkbox>
      )}
    </focuser.FocusableBlock>
  );
};
