import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { ActionType, useAppAction } from '~components/Provider/App';
import { useMenuAction } from '~components/Provider/Menu';
import { ActionType as ActionTypeMenu } from '~components/Provider/Menu';
import { queryCache } from '~global';
import { useConfig } from '~global';
import { logout } from '~hooks/fetch/useAccount';
import useHotKey from '~hooks/useHotKey';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import goWithReload from '~lib/goWithReload';
import { GEORESTRICTION_TEXT } from '~localization';
import enMessages from '~localization/en.json';
import HotKey from '~typings/HotKey';

import { GeoRestrictButtons } from './Buttons';
import PlanetIcon from './PlanetIcon';
import * as styles from './styles.module.css';


type Props = Readonly<{
  intl: InjectedIntl;
}>;


export const GeoRestrictError: React.FC<Props> = (props: Props) => {
  const menuAction = useMenuAction();
  const applicationAction = useAppAction();
  const { smartTV: { projectTitle } } = useConfig();

  const onExitBtnClick = () => {
    menuAction({
      type: ActionTypeMenu.ChangeExitState,
      isExitOpened: true,
    });
  };
  const onLogOutBtnClick = () => {
    logout();

    queryCache.clear();
    setTimeout(() => {
      goWithReload(history, '/');
    }, 100);
  };

  useHotKey({
    isMounted: true,
    keyName: HotKey.KEY_RETURN,
    action: onExitBtnClick,
  }, EMPTY_ARRAY);

  React.useEffect(() => {
    applicationAction({
      type: ActionType.SetIsLaunchingDone,
      payload: { isLaunchingDone: true },
    })
  }, []);

  const renderEnMessage = () => {
    try {
      if (enMessages?.[GEORESTRICTION_TEXT]) {
        return (
          <div
            className={ styles.reasonText }
            dangerouslySetInnerHTML={ {
              __html: props.intl.formatMessage({
                id: 'no_id',
                defaultMessage: enMessages[GEORESTRICTION_TEXT]
              }, {
                product: projectTitle,
              }),
            } }
          />
        );
      }
    } catch (ignore) {
      // ignore
    }

    return 'We’re sorry. Product is not available in your location'
  };

  return (
    <div
      className={ styles.wrapper }
    >
      <PlanetIcon 
        className={ styles.icon }
      />
      <div
        className={ styles.reasonText }
        dangerouslySetInnerHTML={ {
          __html: props.intl.formatMessage({ id: GEORESTRICTION_TEXT }, {
            product: projectTitle,
          }),
        } }
      />
      { renderEnMessage() }
      <GeoRestrictButtons
        intl={ props.intl }
        onExit={ onExitBtnClick }
        onLogOut={ onLogOutBtnClick }
      />
    </div>
  );
};

export default injectIntl(GeoRestrictError);
