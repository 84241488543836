import SceneItem from '~components/Scene/typings';
import { getImageDimensionByType, getImageRatio } from '~lib/Dimension';
import {
  fillImageTemplateBySizes,
  getImageURLTemplate,
  getImageURLTemplateWithRatio,
  getPreview,
} from '~lib/image';
import { getResourceObjectType, isCardChannel, isChannel } from '~lib/objectType';
import BannerBig from '~typings/BannerBig';
import BannerMiddle from '~typings/BannerMiddle';
import Card from '~typings/Card';
import Channel from '~typings/Channel';
import ProgramEvent from '~typings/Event';
import Image from '~typings/Image';
import ImageType from '~typings/ImageType';
import ItemObject from '~typings/ItemObject';
import MediaItemDimension from '~typings/MediaItemDimension';

import getScreenHeight from './screen/height';
import getScreenWidth from './screen/width';
import { valueInPixelsByWidth } from './SizesInPX';


const BANNER_TYPES = [
  ImageType.Card4x3, ImageType.Card16x9, ImageType.BANNER_SMALL, ImageType.BANNER, ImageType.BANNER_WIDE,
] as const;

const POSTER_TYPES = [
  ImageType.POSTER, ImageType.Card2x3,
] as const;

const DEFAULT_IMG_TYPES = [
  ImageType.BANNER_WIDE,
  ImageType.BANNER,
  ImageType.Card16x9,
];

// BANNER_WIDE -- for SceneMediaItem
const IMG_TYPES = {
  [ItemObject.MiddleBanner]: {
    [ImageType.BANNER]: [ImageType.MiddleBanner8x1, ...BANNER_TYPES],
    [ImageType.BANNER_WIDE]: [ImageType.MiddleBanner8x1, ...BANNER_TYPES],
  },
  [ItemObject.BigBanner]: {
    [ImageType.BANNER]: [ImageType.BigBanner21x9, ImageType.BigBanner27x10, ...BANNER_TYPES],
    [ImageType.BANNER_WIDE]: [ImageType.BigBanner21x9, ImageType.BigBanner27x10, ...BANNER_TYPES],
  },
  [ItemObject.Movie]: {
    [ImageType.BANNER]: [ImageType.CARD_PREVIEW, ImageType.PREVIEW, ...BANNER_TYPES],
    [ImageType.POSTER]: POSTER_TYPES,
    [ImageType.BANNER_WIDE]: [ImageType.BANNER_WIDE, ImageType.BANNER],
  },
  [ItemObject.Series]: {
    [ImageType.BANNER]: [ImageType.CARD_PREVIEW, ImageType.PREVIEW, ...BANNER_TYPES],
    [ImageType.POSTER]: POSTER_TYPES,
    [ImageType.BANNER_WIDE]: [ImageType.BANNER_WIDE, ImageType.BANNER],
  },
  [ItemObject.Episode]: {
    [ImageType.BANNER]: [ImageType.PREVIEW, ...BANNER_TYPES],
    [ImageType.POSTER]: POSTER_TYPES,
    [ImageType.BANNER_WIDE]: [ImageType.BANNER_WIDE, ImageType.BANNER],
  },
  [ItemObject.ProgramEvent]: {
    [ImageType.BANNER]: [ImageType.CARD_PREVIEW, ImageType.PREVIEW, ...BANNER_TYPES],
    [ImageType.POSTER]: POSTER_TYPES,
    [ImageType.BANNER_WIDE]: [ImageType.BANNER_WIDE, ImageType.BANNER],
  },
  [ItemObject.Channel]: {
    [ImageType.BANNER]: [ImageType.CARD_PREVIEW, ImageType.PREVIEW, ...BANNER_TYPES],
    [ImageType.POSTER]: POSTER_TYPES,
    [ImageType.LOGO]: [ImageType.LOGO],
    [ImageType.BANNER_WIDE]: [ImageType.BANNER_WIDE, ImageType.BANNER],
  },
  [ItemObject.Highlight]: {
    [ImageType.BANNER]: [ImageType.CARD_PREVIEW, ImageType.PREVIEW, ...BANNER_TYPES],
    [ImageType.POSTER]: POSTER_TYPES,
    [ImageType.BANNER_WIDE]: [ImageType.CARD_PREVIEW, ImageType.PREVIEW, ...BANNER_TYPES],
  },
  [ItemObject.Banner]: {
    [ImageType.BANNER]: [ImageType.BANNER, ImageType.BANNER_WIDE],
    [ImageType.BANNER_WIDE]: [ImageType.BANNER_WIDE, ImageType.BANNER],
  },
  [ItemObject.News]: {
    [ImageType.BANNER]: [ImageType.BANNER, ImageType.BANNER_WIDE, ImageType.HEADER, ImageType.Card16x9],
    [ImageType.BANNER_WIDE]: [ImageType.BANNER_WIDE, ImageType.BANNER, ImageType.HEADER, ImageType.Card16x9],
    [ImageType.POSTER]: POSTER_TYPES,
  },
  [ItemObject.CompetitionMatch]: {
    [ImageType.BANNER]: [ImageType.CARD_PREVIEW, ImageType.PREVIEW, ...BANNER_TYPES],
    [ImageType.POSTER]: POSTER_TYPES,
    [ImageType.BANNER_WIDE]: [ImageType.BANNER_WIDE, ImageType.BANNER],
  },
  [ItemObject.Product]: {
    [ImageType.PROMO_BANNER_WIDE]:
    [ImageType.PROMO_BANNER_WIDE, ImageType.BANNER_WIDE, ImageType.BANNER, ImageType.BANNER_SMALL],
    [ImageType.BANNER_WIDE]: [ImageType.BANNER_WIDE, ImageType.BANNER, ImageType.BANNER_SMALL],
  },
  [ItemObject.ProductFeature]: {
    [ImageType.Icon]: [ImageType.Icon, ...BANNER_TYPES],
  },
};

type Props = Readonly<{
  mediaItem?: SceneItem | Card | BannerBig | BannerMiddle;
  dimension: MediaItemDimension;
  width?: number;
  height?: number;
  isNeedToCrop?: boolean;
  isDoubleQuality?: boolean;
}>;

/**
 * @deprecated
 * Есть очень много сложной логики, которая сейчас не нужна
 * Эту утилку нужно переписать, лучше ее не использовать
 */
const getImageSRC = (props: Props): string | null => {
  const { mediaItem, dimension } = props;

  if (!mediaItem) { return null; }

  const imageDimensionByType = getImageDimensionByType(
    dimension === ImageType.BANNER_WIDE ? ImageType.BANNER : dimension,
  );
  let width = props.width || imageDimensionByType.width;
  let height = props.height || imageDimensionByType.height;

  if (props.isDoubleQuality) {
    width *= 2;
    height *= 2;
  }

  switch (mediaItem.object) {
    case ItemObject.Channel: {
      let isBlur = false;
      if (mediaItem.certification_ratings[0]?.may_be_inappropriate) {
        isBlur = true;
      }

      if (dimension === ImageType.LOGO || dimension === ImageType.LOGO_SMALL) {
        const [template/* , isNeedToCrop */] = getImageURLTemplateWithRatio(
          mediaItem,
          getImageRatio(dimension),
          [ImageType.LOGO]
        );

        return (
          fillImageTemplateBySizes(
            template,
            width,
            height,
          ) || null
        );
      } else if (dimension === ImageType.CHANNEL_CARD_WIDE) {
        const ratio = (props.width && props.height) ?
          (props.width / props.height)
          :
          getImageRatio(dimension);
        const [template] = getImageURLTemplateWithRatio(
          mediaItem,
          ratio,
          [ImageType.CHANNEL_CARD_WIDE],
          props.isNeedToCrop,
        );

        return (
          fillImageTemplateBySizes(
            template,
            width,
            height,
            props.isNeedToCrop,
            isBlur,
          ) || null
        );
      } else {
        const previewSRC = getPreview(
          (mediaItem as Channel).live_preview,
          width,
          height,
          props.isNeedToCrop,
          isBlur,
        );

        return previewSRC
          ? previewSRC
          : null;
      }
    }
    default: {
      const imgTypes: ImageType[] = IMG_TYPES[
        getResourceObjectType(mediaItem) || ItemObject.Movie
      ]?.[dimension] || DEFAULT_IMG_TYPES;

      const ratio = (props.width && props.height) ?
        (props.width / props.height)
        :
        getImageRatio(dimension);
      const [template] = getImageURLTemplate(
        mediaItem,
        ratio,
        imgTypes,
        props.isNeedToCrop,
      );
      let isNeedBlur = false;
      if (mediaItem.object === ItemObject.Card && mediaItem.resource_type === ItemObject.Channel) {
        isNeedBlur = mediaItem.inappropriate;
      }

      return (
        fillImageTemplateBySizes(
          template,
          width,
          height,
          props.isNeedToCrop,
          isNeedBlur,
        ) || null
      );
    }
  }
};

/**
 * Возвращает изображение, которое нужно использовать в качестве background-а сцены
 */
const getBackgroundImageUrl = ({
  mediaItem,
  width,
  height,
}: {
  mediaItem: SceneItem | Card | BannerBig | BannerMiddle
  width: number;
  height: number;
}): string | null => {

  const images = mediaItem.images;

  const backgroundImage =
    images?.find((i) => i.type === ImageType.ContentBG16x9) ||
    images?.find((i) => i.type === ImageType.Card16x9) ||
    (mediaItem.object === ItemObject.AudioShow
      ? images?.find((i) => i.type === ImageType.Card1x1)
      : null);



  if (!backgroundImage) {
    return null;
  }

  const backgroundTemplate = backgroundImage.url_template;

  const needBloor = isMediaItemNeedBloor(mediaItem);

  return fillImageTemplateBySizes(backgroundTemplate, width, height, false, needBloor);
};


const isMediaItemNeedBloor = (mediaItem: SceneItem | Card | BannerBig | BannerMiddle) : boolean =>{
  if(isCardChannel(mediaItem)){
    return mediaItem.inappropriate;
  }

  if(isChannel(mediaItem)){
    return Boolean(mediaItem.certification_ratings?.[0]?.may_be_inappropriate);
  }

  return false;
};

const filterBrokenImages = (image: Image): boolean =>
  !!(image.url_template && image.original_height && image.original_width);

const findImageByType = (images: Image[], imageTypes: Image['type'][]): Image | undefined => {
  return images
    .filter(filterBrokenImages)
    .filter((img) => imageTypes.includes(img.type))
    .sort((img1, img2) => imageTypes.indexOf(img1.type) - imageTypes.indexOf(img2.type))[0];
};


const getMiddleBannerImage = (middleBanner: BannerMiddle) => {
  const image = findImageByType(middleBanner.images, [ImageType.MiddleBanner8x1, ...BANNER_TYPES]);

  if (!image) {
    return null;
  }

  return fillImageTemplateBySizes(image.url_template, 1920, 240, false, false);
};

const getBigBannerImage = (bannerBig: BannerBig) => {
  const image = findImageByType(bannerBig.images, [
    ImageType.BigBanner21x9,
    ImageType.BigBanner27x10,
    ...BANNER_TYPES,
  ]);

  if (!image) {
    return null;
  }

  const imageComponentWidth = getScreenWidth();
  const is2K = imageComponentWidth === 1920;

  const isIncludedSmartTVBanner = bannerBig.images.some(
    ({ original_width, original_height }) =>
      (original_width === 1280 && original_height === 548) ||
      (original_width === 1920 && original_height === 822),
  );

  const width = isIncludedSmartTVBanner ? (is2K ? 1920 : 1280) : getScreenWidth();

  const height = isIncludedSmartTVBanner ? (is2K ? 822 : 548) : getScreenHeight();

  return fillImageTemplateBySizes(image.url_template, width, height, false, false);
};

const getChannelLogo = (channel: Channel) => {
  const image = findImageByType(channel.images, [ImageType.CHANNEL_CARD_WIDE]);
  if(!image){
    return null;
  }

  const width = valueInPixelsByWidth(25.625); // 328px
  const height = valueInPixelsByWidth(14.375); // 184px
  return fillImageTemplateBySizes(image.url_template, width, height);
}

const getProgramEventLogo = (programEvent: ProgramEvent) => {
  const image = findImageByType(programEvent.images, [ImageType.Card16x9]);
  if(!image){
    return null;
  }

  const width = valueInPixelsByWidth(25.625); // 328px
  const height = valueInPixelsByWidth(14.375); // 184px
  return fillImageTemplateBySizes(image.url_template, width, height);
}


export {
  findImageByType,
  getBackgroundImageUrl,
  getBigBannerImage,
  getChannelLogo,
  getMiddleBannerImage,
  getProgramEventLogo,
};

export default getImageSRC;
