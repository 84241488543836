import cn from 'classnames';
import React from 'react';

import { CheckboxLineFcuser } from '~newapp/ui-kit/Table_items/Account_items/CheckboxLine';

import { SelectOption } from '../../types';
import * as styles from './DropdownSelectColumn.module.css';

export type Props<T> = {
  options: SelectOption<T>[];
  /**
   * Сфокусированный чекбокс
   *
   * Если эта колонка не сфокусирована, то передавать `null`
   */
  focusedIdx: null | number;

  onForceFocus: (state: { columnIdx: number; rowIdx: number }) => void;

  /**
   * Индекс колонки, используется только для мемоизации, чтобы корректно использовать onForceFocus
   */
  columnIdx: number;

  onClick: (option: SelectOption<T>) => void;

  selectedOptionsInColumn: SelectOption<T>[] | null;

  className?: string;
};

type DropdownSelectColumnType = <T>(props: Props<T>) => React.ReactElement | null;

/* eslint-disable-next-line react/display-name */
export const DropdownSelectColumn: DropdownSelectColumnType = React.memo((props) => {
  const { options, columnIdx, focusedIdx, selectedOptionsInColumn, onForceFocus, onClick } = props;

  return (
    <div className={ cn(styles.DropdownSelectColumn, props.className) }>
      {options.map((option, index) => (
        <CheckboxLineFcuser
          onClick={ (e) => {
            onClick(option);
            e.stop();
          } }
          focuserClassName={ styles.checkboxButton }
          key={ index }
          isFocused={ focusedIdx === index }
          emitForceFocusOnHover
          isChecked={ selectedOptionsInColumn?.includes(option) }
          onForceFocus={ () =>
            onForceFocus({
              columnIdx: columnIdx,
              rowIdx: index,
            })
          }
        >
          {option.name}
        </CheckboxLineFcuser>
      ))}
    </div>
  );
});

(DropdownSelectColumn as React.FC).displayName = 'DropdownSelectColumn'
