import React from 'react';

import { useCardMutationWithCalculation } from '~hooks/fetch/useCardMutations2';
import CardType from '~typings/Card';
import { CardMutationState } from '~typings/CardMutation';

export type ChildrenRenderParams = {
  mutationState: CardMutationState | null;
  maybeMutatedCard: CardType;
};
export interface Props {
  children: (params: ChildrenRenderParams) => JSX.Element;
  card: CardType;
  cardConfigId: string;
}

/**
 * Этот компонент нужен для оптмизации работы с карточками
 *
 * Если карточка немутабельная, то хук мутации `useCardMutationWithCalculation` - не будет вызван
 */
export const CardMutableHoc: React.FC<Props> = ({ card, children, cardConfigId }) => {
  if (card.mutable) {
    return (
      <CardMutableComp
        card={ card }
        cardConfigId={ cardConfigId }
      >
        {children}
      </CardMutableComp>
    );
  }

  return children({
    mutationState: null,
    maybeMutatedCard: card,
  });
};

const CardMutableComp: React.FC<Props> = ({ card: notParsedCard, cardConfigId, children }) => {
  const { mutationState, card } = useCardMutationWithCalculation(notParsedCard, cardConfigId);

  return children({ mutationState, maybeMutatedCard: card });
};
