import { QueryKey,useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient, useConfig } from '~global';
import { fetchOptionsBasic,FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getLimitFromConfig } from "~hooks/fetch/utils";
import ApiClient from '~lib/ApiClient';
import { makeSplitRequestByLimit } from "~lib/fetch";
import BannerBig from '~typings/BannerBig';
import BannerMiddle from '~typings/BannerMiddle';
import ResponseMany from '~typings/ResponseMany';

import { getAuthData } from '../useAccount/authData';

type Banners = ResponseMany<BannerBig[] | BannerMiddle[]>;

const getBannersKey = (blockID?: string): QueryKey =>
['bannersV4', blockID, getAuthData().accessToken];

const getCachedBanners = (blockID?: string) => (
  queryClient.getQueryData<Banners>(getBannersKey(blockID))
);

const fetch = async (blockID: string | undefined, client: ApiClient, limit: number) => {
  const { accessToken } = getAuthData();
  const url = fetchURLs[FetchType.BannersV4]
  const params = {
    ...fetchOptionsBasic[FetchType.BannersV4],
    'filter[banners_block_eq]': blockID,
    sort: 'relevance',
    access_token: accessToken,
  }

  return await makeSplitRequestByLimit<BannerBig[] | BannerMiddle[]>({
    client,
    url,
    params,
    limit,
  })
};


const useBannersV4 = (blockID: string) => {
  const queryKey = getBannersKey(blockID);
  const client = useClient();
  const limit = getLimitFromConfig(useConfig());

  return useQuery<Banners>({
    queryKey,
    queryFn: () => fetch(blockID, client, limit),
  });
};


export {
  fetch,
  getBannersKey,
  getCachedBanners,
  useBannersV4
};
