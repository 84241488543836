import { RefObject, useEffect, useRef } from 'react';

import { changeSliderIndexAnimationMS } from '~app/variables';
import getScreenHeight from '~lib/screen/height';
import getScreenWidth from '~lib/screen/width';

import useIsNeedToScroll from './useIsNeedToScroll';


export enum ScrollDirection {
  X = 'x',
  Y = 'y',
}

type Props = Readonly<{
  ref?: RefObject<HTMLDivElement>;
  wrapperSize?: number;
  animationDurationInMS?: number;
  focusedIndex?: number;
  direction?: ScrollDirection;
}>;


const useScrollable = (props: Props = {}, update: any[] = []) => {
  const {
    focusedIndex,
    direction = ScrollDirection.Y,
    animationDurationInMS = changeSliderIndexAnimationMS,
  } = props;
  const isX = (direction === ScrollDirection.X);
  const wrapperSize = props.wrapperSize
    || (isX ? getScreenWidth() : getScreenHeight());
  const ref = useRef<HTMLDivElement>(null);
  const wrapperRef = props.ref || ref;
  const [isNeedToScroll] = useIsNeedToScroll({
    ref: wrapperRef,
    wrapperSize,
    direction,
  }, update);

  useEffect(() => {
    if (wrapperRef.current && focusedIndex !== undefined) {
      wrapperRef.current.style.transitionDuration = `${animationDurationInMS}ms`;
      // @ts-ignore
      wrapperRef.current.style.WebkitTransitionDuration = `${animationDurationInMS}ms`;

      const {
        left: containerLeft,
        top: containerTop,
      } = wrapperRef.current.getBoundingClientRect();
      const { children } = wrapperRef.current;
      const childrenArray = Array.from(children)
        .filter((child) => !child.getAttribute('data-scrollindex'));

      const childRect = childrenArray?.[focusedIndex]?.getBoundingClientRect();
      if (!childRect) { return; }

      const { left, width, top, height } = childRect;
      const offset = Math.max(
        Math.abs(isX ? (containerLeft - left) : (containerTop - top))
          - (isX ? (wrapperSize - width) : (wrapperSize - height)),
        0,
      );

      if (isX) {
        wrapperRef.current.style.transform = `translate3d(${-offset}px, 0, 0)`;
        // @ts-ignore
        wrapperRef.current.style.WebkitTransform = `translate3d(${-offset}px, 0, 0)`;
      } else {
        wrapperRef.current.style.transform = `translate3d(0, ${-offset}px, 0)`;
        // @ts-ignore
        wrapperRef.current.style.WebkitTransform = `translate3d(0, ${-offset}px, 0)`;
      }
    }
  }, [focusedIndex, isX, isNeedToScroll, update]);

  return [
    wrapperRef,
    isNeedToScroll,
  ] as const ;
};


export default useScrollable;
