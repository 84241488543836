import { Coord, FBtn, KeyboardLayout } from '../types';
import { KeyboardEngLowerLayout, KeyboardEngUpperLayout } from './KeyboardEngLayouts';
import { KeyboardNumbersLayout } from './KeyboardNumbersLayout';
import { KeyboardRusLowerLayout, KeyboardRusUpperLayout } from './KeyboardRusLayouts';
import { KeyboardSymbolsLayout } from './KeyboardSymbolsLayout';

const letterLayouts: [FBtn[], KeyboardLayout][] = [
  [KeyboardEngLowerLayout, 'eng_lowercase'],
  [KeyboardEngUpperLayout, 'eng_uppercase'],
  [KeyboardRusLowerLayout, 'ru_lowercase'],
  [KeyboardRusUpperLayout, 'ru_uppercase'],
  [KeyboardSymbolsLayout, 'symbols'],
];

/**
 * Тут собрана карта `символ => клавиатура => координаты`
 * Эта карта поможет при нажатии на физическу клавиатуру, сменить раскладку на виртуальной клавиатуре
 */
export const lettersKeyToLayoutMap: {
  [key: string]: {
    [layout in KeyboardLayout]?: Coord;
  };
} = {};

letterLayouts.forEach(([keyboardLayout, layoutCode]) => {
  keyboardLayout.forEach((btn) => {
    if (btn.action === 'space') {
      const id = ' '; // space
      lettersKeyToLayoutMap[id] = lettersKeyToLayoutMap[id] || {};
      lettersKeyToLayoutMap[id][layoutCode] = btn.coord;
      return;
    }

    if(btn.action){
      return;
    }

    if (btn.id.length !== 1) {
      return;
    }

    if (btn.alwaysDisabled) {
      return;
    }

    lettersKeyToLayoutMap[btn.id] = lettersKeyToLayoutMap[btn.id] || {};
    lettersKeyToLayoutMap[btn.id][layoutCode] = btn.coord;
  });
});

Object.freeze(lettersKeyToLayoutMap);

export const numbersKeyToLayoutMap: Record<string, Coord | undefined> = {};

KeyboardNumbersLayout.forEach((btn) => {
  if(btn.action){
    return;
  }

  if (btn.id.length !== 1) {
    return;
  }

  if (btn.alwaysDisabled) {
    return;
  }
  numbersKeyToLayoutMap[btn.id] = btn.coord;
});

Object.freeze(numbersKeyToLayoutMap);
