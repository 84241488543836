// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EPGPage--Fp1vG {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n     -moz-box-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: start;\n  -webkit-align-items: flex-start;\n     -moz-box-align: start;\n          align-items: flex-start;\n  /* 30px */\n  margin-left: 2.34375vw;\n}\n\n.channelsSlider--R_kAX, .eventsList--rqMXG {\n  /* 30px */\n  margin-right: 2.34375vw;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/screens/EPGPage/components/EPGPageLoaded/EPGPageLoaded.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,uBAA2B;EAA3B,mCAA2B;KAA3B,oBAA2B;UAA3B,2BAA2B;EAC3B,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,uBAAuB;AACzB","sourcesContent":[".EPGPage {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  /* 30px */\n  margin-left: 2.34375vw;\n}\n\n.channelsSlider, .eventsList {\n  /* 30px */\n  margin-right: 2.34375vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var EPGPage = "EPGPage--Fp1vG";
export var channelsSlider = "channelsSlider--R_kAX";
export var eventsList = "eventsList--rqMXG";
export default ___CSS_LOADER_EXPORT___;
