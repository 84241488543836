import { History } from 'history';
import { parse, stringify } from 'query-string';


const set = (params: Object, history: History): void => {
  history.replace({
    ...history.location,
    search: stringify(Object.assign({
      ...(get(history.location.search) || {}),
      ...params,
    })),
  });
};

const get = (search: string = ''): Object => {
  try {
    return (
      parse(
        search,
        {
          parseBooleans: true,
          parseNumbers: true,
        },
      ) || {}
    );
  } catch (ignore) {
    return {};
  }
};

const getField = (search: string = '', fieldName) => get(search)[fieldName];

export {
  get,
  getField,
  set,
};
