import * as cn from 'classnames';
import * as React from 'react'
import { InjectedIntl, injectIntl } from 'react-intl';

import { ActionType as MenuActionType, useMenuAction } from '~components/Provider/Menu';
import {
  CANCEL_SELECTION,
  DELETE_ALL_ITEMS,
  DELETE_COUNT,
  EDIT_MODE_DESCRIPTION,
  NAVIGATION_BACK,
} from '~localization';
import useSideBarNavigation, { FOCUS_ON } from '~stores/SideBarNavigation';
import NavigationDirection from '~typings/NavigationDirection';
import BackIcon from '~ui/Icon/icons/Back';
import CloseIcon from '~ui/Icon/icons/Close';
import DeleteIcon from '~ui/Icon/icons/DeleteIcon';
import EditSmall from '~ui/Icon/icons/EditSmall';
import { PageNavigation } from '~ui/PageNavigation/PageNavigation';

import { useAllowNavigation } from '../SideBarLayout/useAllowNavigation';
import * as styles from './styles.module.css';


export enum Delete {
  Selected = 'selected',
  All = 'all',
}

type Props = {
  selectedItemsCount: number;
  resetSelection: () => void;
  onDelete: (arg: Delete) => void;
  intl: InjectedIntl;
};


const EditItemsMenu = (props: Props) => {
  const focusOn = useSideBarNavigation(st => st.getFocusOn());
  const setFocusOn = useSideBarNavigation(st => st.setFocusOn);
  const editMode = useSideBarNavigation(st => st.getEditMode());
  const setEditMode = useSideBarNavigation(st => st.setEditMode);

  const {
    isMenuOpened,
    allowNavigation,
  } = useAllowNavigation();
  const menuAction = useMenuAction();

  const menuNavigationItems = editMode
    ? [{ maxIndex: 0 },
      { maxIndex: 0 },
      { maxIndex: 0 },]
    : [{ maxIndex: 0 }];

  const handleLeave = React.useCallback((direction: NavigationDirection) => {
    switch (direction) {
      case NavigationDirection.Up: {
        if (!editMode) {
          menuAction({
            type: MenuActionType.ChangeMenuState,
            isMenuOpened: !isMenuOpened,
          });
        }
        break;
      }
      case NavigationDirection.Left: {
        setFocusOn(FOCUS_ON.CONTENT);
        break;
      }
      default:
        break;
    }
  }, [editMode, isMenuOpened]);

  const handleEditModeButton = () => {
    setEditMode(true);
  };

  return (
    <PageNavigation
      layerID="personal_page-side_menu"
      isAllowedNavigation={ allowNavigation && focusOn === FOCUS_ON.RIGHT_MENU }
      navigationItems={ menuNavigationItems }
      onLeave={ handleLeave }
    >
      {
        (focusPosition) => (
          <div className={ cn(styles.wrapper, { [styles.editMode]: editMode, }) }>
            {
              editMode
                ? (<>
                  <div
                    onClick={ () => props.selectedItemsCount ? props.resetSelection() : setEditMode(false) }
                    className={ cn(styles.editButton, {
                      'focusedNavigationNode': allowNavigation
                        && focusOn === FOCUS_ON.RIGHT_MENU
                        && focusPosition.focusOn === 0,
                      [styles.focused]: allowNavigation
                      && focusOn === FOCUS_ON.RIGHT_MENU
                      && focusPosition.focusOn === 0,
                    }) }
                  >
                    <div className={ styles.button }>
                      {
                        props.selectedItemsCount
                          ? <EditSmall className={ styles.icon }/>
                          : <BackIcon className={ styles.icon }/>
                      }
                    </div>
                    <span>
                      {
                        props.intl.formatMessage({
                          id: props.selectedItemsCount ? CANCEL_SELECTION : NAVIGATION_BACK
                        })
                      }
                    </span>
                  </div>


                  <div
                    onClick={ () => props.onDelete(Delete.Selected) }
                    className={ cn(styles.editButton, {
                      'focusedNavigationNode': allowNavigation
                        && focusOn === FOCUS_ON.RIGHT_MENU
                        && focusPosition.focusOn === 1,
                      [styles.focused]: allowNavigation
                      && focusOn === FOCUS_ON.RIGHT_MENU
                      && focusPosition.focusOn === 1,
                    }) }
                  >
                    <div className={ styles.button }>
                      <CloseIcon className={ styles.icon }/>
                    </div>
                    <span>
                      {
                        props.intl.formatMessage(
                          { id: DELETE_COUNT },
                          { count: props.selectedItemsCount },
                        )
                      }
                    </span>
                  </div>


                  <div
                    onClick={ () => props.onDelete(Delete.All) }
                    className={ cn(styles.editButton, {
                      'focusedNavigationNode': allowNavigation
                        && focusOn === FOCUS_ON.RIGHT_MENU
                        && focusPosition.focusOn === 2,
                      [styles.focused]: allowNavigation
                      && focusOn === FOCUS_ON.RIGHT_MENU
                      && focusPosition.focusOn === 2,
                    }) }
                  >
                    <div className={ styles.button }>
                      <DeleteIcon className={ styles.icon }/>
                    </div>
                    <span>
                      {
                        props.intl.formatMessage({ id: DELETE_ALL_ITEMS })
                      }
                    </span>
                  </div>


                  <div className={ styles.description }>
                    { props.intl.formatMessage({ id: EDIT_MODE_DESCRIPTION }) }
                  </div>


                </>)
                : (<div
                  onClick={ handleEditModeButton }
                  className={ cn(styles.button, {
                    'focusedNavigationNode': allowNavigation && focusOn === FOCUS_ON.RIGHT_MENU,
                    [styles.focused]: allowNavigation && focusOn === FOCUS_ON.RIGHT_MENU
                  }) }
                >
                  <EditSmall className={ styles.icon }/>
                </div>)
            }
          </div>
        )
      }
    </PageNavigation>
  );
}


export default React.memo(injectIntl(EditItemsMenu));
