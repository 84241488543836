import { InjectedIntl } from 'react-intl';

import * as URLSearchManager from '~lib/URLQueryStringManager';
import {
  CONFIRM_NEW_PASSWORD,
  CREATE_NEW_PASSWORD,
  ENTER_PASSWORD,
} from '~localization';


export enum Step {
  CurrentPassword,
  NewPassword,
  ConfirmNewPassword,
}


const getHeaderText = (step: Step, intl: InjectedIntl): string => {
  switch (step) {
    case Step.CurrentPassword:
      return intl.formatMessage({ id: ENTER_PASSWORD });
    case Step.NewPassword:
      return intl.formatMessage({ id: CREATE_NEW_PASSWORD });
    case Step.ConfirmNewPassword:
      return intl.formatMessage({ id: CONFIRM_NEW_PASSWORD });
  }
};

const getCurrentStep = (history): Step => (
  URLSearchManager.getField(history.location.search, 'step') || Step.CurrentPassword
);

const setCurrentStep = (step: Step, history) => {
  URLSearchManager.set({ step }, history);
};


export {
  getCurrentStep,
  getHeaderText,
  setCurrentStep,
};
