import cn from "classnames";
import React from "react";
import { InjectedIntl, injectIntl } from "react-intl";

import { rkn_plate } from "~localization";
import { Attention } from "~newapp/ui-kit/Icons/For_message/Attention";

import * as css from "./RknPlate.module.css"

type Props = {
  intl: InjectedIntl;
  className?: string
}

const RknPlate$ = ({ intl, className }: Props) => {

  return (
    <div className={ cn(css.rknPlate, className) }>
      <div className={ css.innerContent }>
        <div className={ css.iconMargin }>
          <Attention />
        </div>
        { intl.formatMessage({ id: rkn_plate }) }
      </div>
    </div>
  )
}

/**
 * плашка РКН которая показывается только на попкорне и показывается после гридов
 * на главной в самом конце, показываем через фича флаг
 */
export const RknPlate = injectIntl(RknPlate$)