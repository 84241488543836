import { ac_00, gw_10 } from 'customization-project-name/colors';
import * as React from 'react';


type Props = {
  isFocused?: boolean;
  className?: string;
};

/* eslint-disable max-len */
const CheckIcon: React.FC<Props> = ({ isFocused, className = '' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" className={ className }>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M15 30.5C23.2843 30.5 30 23.7843 30 15.5C30 7.21573 23.2843 0.5 15 0.5C6.71573 0.5 0 7.21573 0 15.5C0 23.7843 6.71573 30.5 15 30.5ZM22.4334 10.0348C21.7461 9.36783 20.6057 9.3791 19.9335 10.0596L13.9524 17.7611L10.0436 13.9707C9.36481 13.3204 8.25065 13.3238 7.57635 13.9782L7.48683 14.0651C6.83539 14.6973 6.83806 15.7034 7.49285 16.3325L13.3235 21.9901C13.6666 22.3197 14.232 22.3141 14.5676 21.9777L22.5324 12.3549C23.1632 11.7226 23.1548 10.7349 22.5132 10.1122L22.4334 10.0348Z" 
        fill={ isFocused ? ac_00 : gw_10 }
      />
    </svg>
  );
};


export default CheckIcon;
