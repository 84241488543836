import ApiClient from "~lib/ApiClient";
import SmartTVConfig from "~typings/SmartTVConfig";

// TODO:
// Этот код перемещен сюда после рефкаторинга конфига, нужно разобраться зачем он нужен и переместить в нужно ЗО

export const setIPTVCredentials = (isMergedAccount: boolean, config: SmartTVConfig, client: ApiClient): void => {
  client.lockFetching();

  // const clientIdIPTVAccountMerged = config?.clientIdIPTVAccountMerged || null;
  // const clientSecretIPTVAccountMerged = config?.clientSecretIPTVAccountMerged || null;
  // const clientIdIPTV = config?.clientIdIPTV || null;
  // const clientSecretIPTV = config?.clientSecretIPTV || null;

  const clientIdIPTVAccountMerged = (config?.clientIdIPTVAccountMerged) ?
    '9f53276e-e10b-47b8-8c69-0e6a3034167f'
    :
    null;
  const clientSecretIPTVAccountMerged = (config?.clientSecretIPTVAccountMerged) ?
    '89da4b91-9269-472d-8b9d-00c0ae948418'
    :
    null;
  const clientIdIPTV = (config?.clientIdIPTV) ?
    'd13a327e-daf8-4026-b66a-b8fccb0737de'
    :
    null;
  const clientSecretIPTV = (config?.clientSecretIPTV) ?
    '93b6af14-2370-4424-99c2-192e578974e4'
    :
    null;

  if (isMergedAccount && clientIdIPTVAccountMerged && clientSecretIPTVAccountMerged) {
    client.setQueryParam('client_id', clientIdIPTVAccountMerged);
    client.setQueryParam('client_secret', clientSecretIPTVAccountMerged);
  }
  else if (clientIdIPTV && clientSecretIPTV) {
    client.setQueryParam('client_id', clientIdIPTV);
    client.setQueryParam('client_secret', clientSecretIPTV);
  }

  if (client.unlockFetching) {
    client.unlockFetching();
  }
};
