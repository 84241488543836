import { QueryKey } from 'react-query';

import { queryCache } from '~global';


const invalidateByKeys = (keys: QueryKey[], filter?: string): void => {
  keys.forEach((key) => {
    try {
      const cachedQueries = queryCache.findAll(Array.isArray(key) ? key : [key])

      for (let query of cachedQueries) {
        if (
          (!filter || query.queryHash.includes(filter))
          && !query.state.error
        ) {
          query.invalidate();
          setTimeout(() => {
            // timeout is needed between mutation and new data fetch
            query.fetch();
          }, 200)
        }
      }
    } catch (ignore) {

    }
  });
};


export default invalidateByKeys;
