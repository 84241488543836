import * as React from 'react'

import { hasFeature } from '~app/global/featureFlags';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import uuid from '~lib/uuid';
import { RknPlate } from '~newapp/ui/rkn-plate';
import { Feature } from '~typings/FeatureFlags';
import ImageType from '~typings/ImageType';

import type {Props} from './component';
import { getVars } from './component.helpers';
import TiledItem from './item';

export type TiledItemsProps = Omit<Props, 'isNeedJustHideChildren'> & {
  showOnlyFetchedItems?: boolean;
}

export function TiledItems(props: TiledItemsProps) {
  const UUID = React.useMemo(() =>
    (new Array(props.items?.meta.pagination.total || 0))
      .fill(0)
      .map((_, index) => uuid(index)),
    [props.items?.meta.pagination.total],
  );
  const { itemWidth, itemHeight, lineByItemIndex, itemsCountInLine } = React.useMemo(() => (
    getVars(props.dimension, props.slidesCount)
  ), [props.dimension, props.slidesCount]);
  const focusedLine = lineByItemIndex(props.focusedItemIndex);

  const calculateItemTop = (index: number): number => (
    (Math.trunc((index / itemsCountInLine))) * itemHeight
  );
  const calculateItemLeft = (index: number): number => (
    (index % itemsCountInLine) * itemWidth
  );

  const handleClick = React.useCallback((item) => {
    if (typeof props.onClick === 'function') {
      props.onClick(item);
    }
  }, [props.onClick]);

  const itemsForRender: JSX.Element[] = [];

  const fetchedElemsLength = props.items?.data.length ?? 0
  const lengthToRender = props.showOnlyFetchedItems ? fetchedElemsLength  : UUID.length;

  const getRKNPlateTop = () => {
    return (Math.trunc(lengthToRender / itemsCountInLine) + 1) * itemHeight;
  };

  const initialIndex = Math.max((focusedLine - 4) * itemsCountInLine, 0);
  const maxIndex = Math.min(((focusedLine + 6) * itemsCountInLine) - 1, (lengthToRender - 1));

  const isLastCardBeenRendered = maxIndex + 1 === lengthToRender;

  for (
    let index = initialIndex;
    index <= maxIndex;
    index += 1
  ) {
    itemsForRender.push(
      <TiledItem
        key={ `TiledItem-${UUID[index]}` }
        itemKey={ UUID[index] }
        top={ calculateItemTop(index) }
        left={ calculateItemLeft(index) }
        dimension={ props.dimension }
        isFocused={ (props.allowNavigation && index === props.focusedItemIndex) }
        item={ props.items?.data[index] }
        onClick={ handleClick }
        withCurrentEvent={ props.withCurrentEvent }
        withProgress={ props.withProgress }
        showAsSeries={ props.showAsSeries }
        forceOnClick={ props.forceOnClick }
        editMode={ props.editMode }
        isChecked={ props.selectedItems?.includes(index) }
        disableText={ props.disableText }
      />
    );
  }

  if ( 
    isLastCardBeenRendered 
    && props.dimension !== ImageType.POSTER 
    && hasFeature(Feature.ShowRknPlate)
  ) {
    itemsForRender.push(
      <div
        key="rkn"
        style={ {
          position: 'absolute',
          left: 0,
          transform: `translate3d(-${valueInPixelsByWidth(5.315)}px, ${getRKNPlateTop()}px, 0)`,
        } }
      >
        <RknPlate />
      </div>
    );
  }

  return (
    <>
      { itemsForRender }
    </>
  );
}

export default React.memo(TiledItems)
