import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useApp } from '~components/Provider/App';
import usePlayerStateActions from '~components/Provider/Player/actions';
import {
  ConfirmationStatus,
  isItFreeContent,
  PlayerAccessReason,
  resolvePlayerAccessStatus,
  usePlayerState,
} from '~components/Provider/Player/component.helpers';
import { useConfig } from '~global';
import { useAccount } from '~hooks/fetch/useAccount';
import useStreamAccess from '~hooks/fetch/useStreamAccess';
import useStreamFetch from '~hooks/fetch/useStreamFetch';
import useTrackList from '~hooks/fetch/useTrackList';
import useIsErrorPlayerPage from '~hooks/useIsErrorPlayerPage';
import { useIsNeedToConfirmation } from '~hooks/useIsNeedToConfirmation';
import useIsPlayerPage from '~hooks/useIsPlayerPage';
import useLocationSearch from '~hooks/useLocationSearch';
import { usePrevious } from '~hooks/usePrevious';
import useStreamParams from '~hooks/useStreamParams';
import { allowedDRMsForPlayerItem } from '~hooks/useStreamParams/utils';
import { isDeviceNotLinked } from '~lib/ApiClient/error-checkers';
import getProtocolByStreamURL from '~lib/getProtocol';
import { getItemName, getRightHolder } from '~lib/mediaItem';
import { isSingleProtocolRightHolder } from "~lib/player/getProtocolsByPlatform";
import { openDeviceLinkingPage } from '~screens/Devices';
import { getMediaItemScreenSearchParams } from '~screens/MediaItemScreen/MediaItemScreenHooks';
import ItemObject from '~typings/ItemObject';
import PlayerAccessError from '~typings/PlayerStatus';
import Protocol from '~typings/Protocol';
import ShakaErrorCode from "~typings/ShakaErrorCode";
import TimeShiftAvailability from '~typings/TimeshiftAvailability';


const usePlayer = () => {
  const { isAgreementConfirmed, isAuthorized, timeout } = useApp();
  const [agePopupTimeout, setAgePopupTimeout] = useState<any>(null);

  const state = usePlayerState();
  const {
    flush,
    setAccess,
    setError,
    setStream,
    setConfirmationRequired,
    setParsedManifest,
  } = usePlayerStateActions();
  const {
    smartTV: {
      profileSection: {
        subscriptions: hasSubscriptions,
      },
    },
    generic
  } = useConfig();
  const isPlayerPage = useIsPlayerPage();
  const prevIsPlayerPage = usePrevious(isPlayerPage);
  const isErrorPlayerPage = useIsErrorPlayerPage();
  const matchPage = useRouteMatch('/page/:id');
  const history = useHistory();
  const authRedirect = useLocationSearch()?.redirect;
  const isAccessAllowed = useMemo(() => (
    state.access === PlayerAccessError.AccessAllowed
  ), [state.access]);
  const isNeedToConfirmation = useIsNeedToConfirmation();
  const isConfirmComplete = (
    state.confirmationRequired === ConfirmationStatus.WithoutConfirmation
    || state.item?.object === ItemObject.ExtraVideo
  );
  const { protocol, drm, takeNextProtocol, isReadyToFetchStream } = useStreamParams({
    item: state.item,
    accessAllowed: isAccessAllowed && isConfirmComplete,
  });
  const isFree = isItFreeContent(state.item);
  const isNeedToLogin = !isAuthorized && !isFree;
  const isExtraVideo = useMemo(() => (
    state.item?.object === ItemObject.ExtraVideo
  ), [state.item?.object]);
  const { data: accountInfo } = useAccount();
  const isSecurityPinOn = accountInfo?.security_pin === true;
  const comingOfAge = state.item?.name.indexOf('18+');

  const onPlayError = (error) => {
    if (error?.shakaError) {
      if (error?.shakaError.code === ShakaErrorCode.CONTENT_UNSUPPORTED_BY_BROWSER) {
        if (state.item && isSingleProtocolRightHolder(getRightHolder(state.item))) {
          setError(error);
        }
        else {
          takeNextProtocol();
        }
        // player?.flush();
      }
      else setError(error);
    }
    else {

      setError(error);
    }
  }
  const timeShiftParams = useCallback((): TimeShiftAvailability | null => {
    if (state.item?.object === ItemObject.Channel) {
      return state.item?.timeshift_availability;
    }

    return null;
  }, [(state.item?.object === ItemObject.Channel) && state.item?.timeshift_availability]);
  const is4K = state.item ? (getItemName(state.item).search(/uhd|4k/) >= 0) : false;

  const stream = useStreamFetch({
    drm,
    protocol,
    itemID: isReadyToFetchStream ? (state.item?.id || null) : null,
    itemObject: isReadyToFetchStream ? (state.item?.object || null) : null,
    is4K,
  });

  const streamProtocol = stream.data?.data?.url && getProtocolByStreamURL(stream.data?.data?.url);
  const isAllowedProtocolForParse = (protocol): boolean => (
    protocol === Protocol.DASH ||
    protocol === Protocol.HLS ||
    protocol === Protocol.MSS
  );
  const streamForParse = useStreamFetch({
    drm,
    protocol: Protocol.DASH,
    itemID: isReadyToFetchStream && streamProtocol && !(isAllowedProtocolForParse(streamProtocol)) ?
      (state.item?.id || null) : null,
    itemObject: isReadyToFetchStream && streamProtocol && !(isAllowedProtocolForParse(streamProtocol)) ?
      (state.item?.object || null) : null,
    is4K,
  });

  useEffect(() => {
    // Тут проверяем, залинковано ли устройство пользователя,
    // если нет - передаем на страницу линкинга
    if (stream.error && isDeviceNotLinked(stream.error)) {
      flush();
      remove();
      openDeviceLinkingPage(history, {
        afterContinueOnLinkingLimitPage: {
          retPath: history.location.pathname,
        },
        onSuccessLinking: {
          retPath: history.location.pathname,
          searchParamsToProxy: getMediaItemScreenSearchParams({
            immidiatePlay: true,
          }),
        },
      });
    }
  }, [stream]);

  const parsed = useTrackList({
    streamURL: (isAllowedProtocolForParse(streamProtocol))
      ? stream.data?.data?.url
      : (streamForParse.data?.data?.url &&
        isAllowedProtocolForParse(getProtocolByStreamURL(streamForParse.data?.data?.url)))
        ? streamForParse.data?.data?.url
        : undefined
  });

  useEffect(() => {
    if (parsed.data?.trackList) {
      setParsedManifest(parsed.data);
    }
  }, [parsed.data]);

  const { data: access, remove } = useStreamAccess({
    itemID: (state.item?.id || null),
    itemObject: (state.item?.object || null),
  });

  // Flush Player state onUnmount
  useEffect(() => flush, []);

  useEffect(() => {
    if (isExtraVideo) {
      setAccess(PlayerAccessError.AccessAllowed);
    }
  }, [isExtraVideo]);

  useEffect(() => {
    if (isNeedToLogin) {
      setAccess(PlayerAccessError.NotAuthorized);
    }
  }, [isNeedToLogin]);

  useEffect(() => {
    if (isNeedToLogin || !access?.data?.length) return;

    if (!isAuthorized && isAgreementConfirmed === false) {
      setConfirmationRequired(ConfirmationStatus.PolicyConfirmation);
      return;
    }

    const accessType = resolvePlayerAccessStatus(access?.data[0]);
    if (
      accessType === PlayerAccessReason.NotPurchased
      && hasSubscriptions && state.item
    ) {
      const object = state.item.object === ItemObject.Episode ?
        state.item.series?.object
        : state.item.object;
      const id = state.item.object === ItemObject.Episode ?
        state.item.series?.id
        : state.item.id;
      const url = matchPage ?
        `${ matchPage.url }/media-item/${ object }/${ id }/products`
        :
        `/media-item/${ object }/${ id }/products`;
      const goTo = (isPlayerPage || authRedirect) ? history.replace : history.push;

      flush();

      goTo(`${ url }/subscriptions`);
      return;
    }

    if (state.confirmationRequired === null) {
      if (!isNeedToConfirmation(state.item)) {
        setConfirmationRequired(ConfirmationStatus.WithoutConfirmation);
      }
      else {
        if (isAuthorized && comingOfAge === -1 && isSecurityPinOn && accountInfo?.parental_control) {
          setConfirmationRequired(ConfirmationStatus.PinCodeConfirmation);
        }
        else if (
          (isAuthorized || !isAuthorized) &&
          (!isSecurityPinOn || isSecurityPinOn) && !accountInfo?.parental_control
          && generic.age_restriction_confirmation_timeout_secs !== -1 && !timeout
        ) {
          setConfirmationRequired(ConfirmationStatus.AgeConfrimation);
        }
        else if (
          generic.age_restriction_confirmation_timeout_secs === undefined
          && (isAuthorized || !isAuthorized) && (!isSecurityPinOn || isSecurityPinOn)
          && accountInfo?.parental_control
        ) {
          setConfirmationRequired(ConfirmationStatus.PinCodeConfirmation);
        }
        else {
          setConfirmationRequired(ConfirmationStatus.WithoutConfirmation);
        }
      }
      return;
    }

    if (
      isFree
      || accessType === PlayerAccessReason.Subscribed
      || accessType === PlayerAccessReason.Rented
    ) {
      setAccess(PlayerAccessError.AccessAllowed);
    }
    else {
      setAccess(accessType);
    }
  }, [
    access?.data,
    isNeedToLogin,
    isFree,
    isAgreementConfirmed,
    state.confirmationRequired,
    hasSubscriptions,
    isPlayerPage,
    authRedirect,
  ]);

  useEffect(() => {
    if (access?.errors) {
      setAccess(PlayerAccessError.ErrorAccessRequestFailed);
    }
  }, [access?.errors]);

  useEffect(() => {
    if (access?.errors) {
      setAccess(PlayerAccessError.ErrorAccessRequestFailed);
    }
  }, [access?.errors]);

  useEffect(() => {
    if (stream.data?.data) {
      setStream(stream.data?.data);
    }
  }, [stream.data]);

  useEffect(() => {
    if (stream.data?.errors) {
      setAccess(PlayerAccessError.StreamFetchFailed);
    }
  }, [stream.data?.errors]);

  useEffect(() => {
    if (stream.error) {
      setAccess(PlayerAccessError.StreamFetchFailed);
    }
  }, [stream.error]);

  useEffect(() => {
    if (prevIsPlayerPage && !isPlayerPage && !isErrorPlayerPage) {
      flush();
    }

    if (!isErrorPlayerPage && state.error !== null) {
      setError(null);
    }
  }, [isPlayerPage, prevIsPlayerPage, isErrorPlayerPage]);

  useEffect(() => {
    if (state.item?.id) {
      setError(null);
    }
  }, [state.item?.id]);

  useEffect(() => {
    if (!isAccessAllowed && state.access && isPlayerPage && state.item) {
      // player?.flush();
      setError(state.access || null);
    }
    if (state.item && isAccessAllowed) {
      if (allowedDRMsForPlayerItem(state.item).length === 0) {
        setError('DRM or Protocol is Empty!');
      }
    }
  }, [isAccessAllowed, state.access, isPlayerPage]);

  useEffect(() => {
    if (state.item && isReadyToFetchStream) {
      if (state.access !== null && state.access !== PlayerAccessError.AccessAllowed) {
        flush();
      }
    }
    if (state.item && isReadyToFetchStream && drm === null && protocol === null) {
      setError('DRM or Protocol is Empty!');
    }
  }, [drm, protocol, isReadyToFetchStream, state.access, isAccessAllowed]);

  return ({
    timeShiftParams,
    isAccessAllowed,
    isReadyToFetchStream,
    onPlayError,
    setAgePopupTimeout,
    agePopupTimeout
  });
};


export default usePlayer;
