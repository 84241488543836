/**
 * Документация есть в сторибуке
 */


/**
 * Название дата атрибута, по которому можно получить индекс элемента
 */
export const IDX = 'idx';
/**
 * Дата атрибут, который нужно повесить на элемент, чтобы легко можно было получить его индекс
 */
export const DATA_ATTR_IDX = `data-${IDX}`;

export type DataIdxAttrType = number;

/**
 * Метод находит элемент на котором есть дата атрибут с индексом. Если такого нет, то возвращает null.
 * Идет от события клика наверх, по иерархии, до последнего родителя.
 * Полезно, чтобы не вешать onClick на каждый элемент в DOM-е.
 */
export const getClickedElWithDataIdx = (e: Event) => {
  if (!(e.target instanceof HTMLElement)) {
    return null;
  }

  let result = null as null | {
    idx: number;
    element: HTMLElement;
  };
  let currentEl = e.target as HTMLElement | null;

  while (!result) {
    if (!currentEl) {
      break;
    }

    if (currentEl.dataset[IDX]) {
      const idx = Number(currentEl.dataset[IDX]);
      result = {
        idx,
        element: currentEl,
      };
      break;
    }
    currentEl = currentEl.parentElement;
  }

  if (!result) {
    return null;
  }

  if (isNaN(result.idx)) {
    return null;
  }

  return result;
};
