import * as React from 'react';

import { usePlayerState } from '~components/Provider/Player/component.helpers';
import ScenePlayerWithHooks from '~components/ScenePlayer/ScenePlayerWithHooks';
import { usePlayerStore } from "~stores/Player";
import Fade from '~ui/Effects/Fade';

import * as styles from './styles.module.css';

/**
 * этот плеер со старой логикой для кастомок БУЗ БКГ БКЗ и других, чтобы ничего не сломать
 */

const ScenePlayer = () => {
  const setSecondsMissing = usePlayerStore(state => state.setMissingSeconds)
  const playerState = usePlayerState();

  React.useEffect(() => {
    setSecondsMissing(0)
  }, [])

  return (
    <Fade
      duration={ 1000 }
      className={ styles.scenePlayerWrapper }
      isVisible={ !!playerState.item }
      willBeNeedRemoveChildAfterFadeOut
    >
      {
        playerState.item
          ? <ScenePlayerWithHooks playerState={ playerState } />
          : null
      }
    </Fade>
  );
};


export default React.memo(ScenePlayer);
