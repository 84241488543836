export enum FavoriteType {
  AudioShows = 'audio_shows',
  Channels = 'channels',
  Movies = 'movies',
  RadioStations = 'radio_stations',
  Series = 'series',
}


export default FavoriteType;
