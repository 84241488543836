import focuser, { FocusableBlockProps } from '@focuser';
import cn from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { CardLayer2 } from '~components/Card/layer2';
import useDeepLink from '~hooks/useDeepLink';
import { isCard } from '~lib/objectType';
import CardType from '~typings/Card';
import { CardConfig } from '~typings/ScreenCardConfigs';
import WatchProgressWithResource from '~typings/WatchProgressWithResource';

import { getProgress, handleClickOnCard } from './Card.common.utils';
import * as styles from './card.module.css';

export type Props = {
  item: CardType | WatchProgressWithResource;
  cardConfig: CardConfig;
} & Omit<FocusableBlockProps, 'onClick' | 'isLastBlock'>;

export const Card = (props: Props) => {
  const { item, cardConfig, ...focuserProps } = props;

  const { urlFromDeepLink } = useDeepLink();
  const history = useHistory();
  const card = isCard(item) ? item : item.card;

  return (
    <focuser.FocusableBlock
      { ...focuserProps }
      onClick={ ()=>{ handleClickOnCard({ history, card, urlFromDeepLink }) } }
      className={ cn(styles.cardWrapper, focuserProps.className) }
    >
      {({ isFocused }) => (
        <CardLayer2
          card={ card }
          isFocused={ isFocused }
          cardConfig={ cardConfig }
          progress={ getProgress(card) }
        />
      )}
    </focuser.FocusableBlock>
  );
};

export const CardMemoized = React.memo(Card);
