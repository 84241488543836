import React, { useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef } from 'react';

import { getWindowAboveFocus } from '~lib/getWindowAboveFocus';

export type useOffsetChangerProps = {
  focusedIndex: number;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
  sliderWrapperRef: React.MutableRefObject<HTMLElement | null>;
  updates?: unknown[];
};
/**
 * Хук добавляет offset к слайдеру в зависимости от индекса активного слайда
 */
export const useOffsetChanger = ({
  focusedIndex,
  sliderRef,
  sliderWrapperRef,
  updates = [],
}: useOffsetChangerProps) => {
  useLayoutEffect(() => {
    if (!sliderRef.current || !sliderWrapperRef.current) {
      return;
    }

    updateOffsetOfSlider({
      focusedIndex,
      slider: sliderRef.current,
      sliderWrapper: sliderWrapperRef.current,
      isVirtual: false,
    });
  }, [focusedIndex, ...updates]);
};

export type useTransitionStyleChangerProps = {
  animationDurationInMS: number | null;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
};

export const useTransitionStyleChanger = ({
  animationDurationInMS,
  sliderRef,
}: useTransitionStyleChangerProps) => {
  useLayoutEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    if (!animationDurationInMS) {
      sliderRef.current.style.removeProperty('transitionDuration');
      return;
    }

    sliderRef.current.style.transitionDuration = `${animationDurationInMS}ms`;
  }, [animationDurationInMS]);
};

export type useVirtualOffsetChangerProps = {
  focusedIndex: number;
  realFocusedIndex: number;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
  sliderWrapperRef: React.MutableRefObject<HTMLElement | null>;
  updates?: unknown[];
};
/**
 * Хук добавляет offset к слайдеру в зависимости от индекса активного слайда
 */
export const useVirtualOffsetChanger = ({
  focusedIndex,
  sliderRef,
  sliderWrapperRef,
  realFocusedIndex,
  updates = [],
}: useVirtualOffsetChangerProps) => {
  useLayoutEffect(() => {
    if (!sliderRef.current || !sliderWrapperRef.current) {
      return;
    }

    updateOffsetOfSlider({
      focusedIndex,
      slider: sliderRef.current,
      sliderWrapper: sliderWrapperRef.current,
      isVirtual: true,
    });
  }, [focusedIndex, realFocusedIndex, ...updates]);
};

type UpdateOffsetArgs = {
  focusedIndex: number;
  slider: HTMLElement;
  sliderWrapper: HTMLElement;
  isVirtual: boolean;
};

const updateOffsetOfSlider = ({
  focusedIndex,
  slider,
  sliderWrapper,
  isVirtual,
}: UpdateOffsetArgs) => {
  const childrenArray = Array.from(slider.children);
  const childRect = childrenArray?.[focusedIndex + (isVirtual ? 1 : 0)]?.getBoundingClientRect();

  if (!childRect) {
    return;
  }

  const {
    width: sliderWrapperWidth,
    right: sliderWrapperRight,
    left: sliderWrapperLeft,
  } = sliderWrapper.getBoundingClientRect();
  const { left: childLeft, width: childWidth, right: childRight } = childRect;

  if (
    childRight >= sliderWrapperLeft &&
    childRight <= sliderWrapperRight &&
    childLeft >= sliderWrapperLeft &&
    childLeft <= sliderWrapperRight
  ) {
    // Элемент внутри наблюдаемой области, ничего не делаем
    return;
  }

  const { left: sliderLeft } = slider.getBoundingClientRect();

  if (childRight < sliderWrapperRight) {
    // следующий слайд слева, значит нужно просто переместить слайдер до слайда
    const sliderOffsetToChild = Math.abs(sliderLeft - childLeft);

    slider.style.transform = `translate3d(${-sliderOffsetToChild}px, 0, 0)`;
    slider.style.webkitTransform = `translate3d(${-sliderOffsetToChild}px, 0, 0)`;
    return;
  }

  // следующий слайд справа, двигаемся к нему, но он должен быть с правого края
  const sliderOffsetToChild = Math.abs(sliderLeft - childLeft);
  const bufferOffsetBrake = sliderWrapperWidth - childWidth;
  const offset = Math.max(sliderOffsetToChild - bufferOffsetBrake, 0);

  slider.style.transform = `translate3d(${-offset}px, 0, 0)`;
  slider.style.webkitTransform = `translate3d(${-offset}px, 0, 0)`;
};

export type UseVirtualChildsParams = {
  childsCount: number;
  renderChild: (idx: number) => React.ReactNode;
  focusedIndex: number;
  maxInDOM: number;
  recalcStep: number;
};

/**
 * Утилитка помогает получить минимальный и максимальный индекс для отрисовки виртуальных элементов
 * buffer-size => количество элементов, которое должно буферироваться за view-port—ом
 * Это нужно, чтобы на старых телевизорах элементы были отрисованы
 * Если на каждом шаге влево/вправо удалять и вставлять элементы в DOM, то это будет лагать
 * Из-за этого мы должны буферировать несколько элементов слева и справа
 */
const getVirtualChildsBoundsWithBuffer = ({
  idx,
  recalcStep,
  totalCount,
  maxInDOM,
}: {
  idx: number;
  recalcStep: number;
  maxInDOM: number;
  totalCount: number;
}) => {
  const { start, end } = getWindowAboveFocus(idx, recalcStep);
  const leftBound = Math.floor((maxInDOM - recalcStep) / 2);
  const rightBound = Math.ceil((maxInDOM - recalcStep) / 2);

  if((start - leftBound) < 0) {
    return [0, Math.min(maxInDOM, totalCount)];
  }

  if((end + rightBound) > totalCount) {
    return [Math.max(totalCount - maxInDOM, 0), totalCount];
  }

  return [start - leftBound, end + rightBound];
};

export const useVirtualChilds = ({
  focusedIndex,
  childsCount,
  renderChild,
  maxInDOM,
  recalcStep,
}: UseVirtualChildsParams) => {
  const [minIdxToRender, maxIdxToRender] = getVirtualChildsBoundsWithBuffer({
    idx: focusedIndex,
    recalcStep: recalcStep,
    maxInDOM: maxInDOM,
    totalCount: childsCount,
  });

  const virtualChildren = React.useMemo(() => {
    return new Array(Math.max(maxIdxToRender - minIdxToRender, 0))
      .fill(0)
      .map((_, idx) => renderChild(minIdxToRender + idx));
  }, [renderChild, minIdxToRender, maxIdxToRender]);

  const virtualFocus = focusedIndex - minIdxToRender;

  return {
    virtualChildren,
    virtualFocus,
    widthMultiplier: minIdxToRender,
  };
};

/**
 * Хук добавляет offset к слайдеру в зависимости от индекса активного слайда
 */
export const useDyanmicOffsetChanger = (params: useVirtualOffsetChangerProps) => {
  const { focusedIndex, sliderRef, sliderWrapperRef, realFocusedIndex, updates = [] } = params;

  useDynamicOffsetFixer(params);

  useEffect(() => {
    if (!sliderRef.current || !sliderWrapperRef.current) {
      return;
    }

    updateOffsetOfSlider({
      focusedIndex,
      slider: sliderRef.current,
      sliderWrapper: sliderWrapperRef.current,
      isVirtual: false,
    });
  }, [focusedIndex, realFocusedIndex, ...updates]);
};

/**
 * Хук фиксирует расстояние, когда в доме изменяются элементы при виртуализации
 */
const useDynamicOffsetFixer = ({
  focusedIndex,
  realFocusedIndex,
  sliderRef,
  sliderWrapperRef,
  updates = [],
}: useVirtualOffsetChangerProps) => {
  const focusedChildLastLeftRef = useRef<{ el: Element; left: number } | null>(null);

  // Как только меняется индекс активного слайда, надо запомнить каким он был до перерендера:
  useMemo(() => {
    if (!sliderRef.current || !sliderWrapperRef.current) {
      return;
    }

    const child = sliderRef.current.children[focusedIndex];
    if (!child) {
      focusedChildLastLeftRef.current = null;
      return;
    }
    const childRect = child.getBoundingClientRect();
    focusedChildLastLeftRef.current = {
      el: child,
      left: childRect.left,
    };
  }, [focusedIndex, realFocusedIndex, ...updates]);

  // После перерендера, фиксируем позицию слайдов, если есть расхождение
  useLayoutEffect(() => {
    const focusedChildLastLeft = focusedChildLastLeftRef.current;
    if (!sliderRef.current || !sliderWrapperRef.current || focusedChildLastLeft === null) {
      return;
    }
    const child = focusedChildLastLeft.el;
    const childPrevLeft = focusedChildLastLeft.left;
    const childRect = child.getBoundingClientRect();
    const childCurrentLeft = childRect.left;
    if (childPrevLeft === childCurrentLeft) {
      return;
    }

    const diffBetweenChildLefts = childPrevLeft - childCurrentLeft;
    const sliderTranslateXParsed = getElementTransformX(sliderRef.current);
    if (sliderTranslateXParsed === null) {
      return;
    }

    const sliderLeftNeedToBe = sliderTranslateXParsed + diffBetweenChildLefts;

    const sliderTransitionDuration = sliderRef.current.style.transitionDuration;
    sliderRef.current.style.transitionDuration = '0ms';
    sliderRef.current.style.transform = `translate3d(${sliderLeftNeedToBe}px, 0, 0)`;
    sliderRef.current.style.webkitTransform = `translate3d(${sliderLeftNeedToBe}px, 0, 0)`;
    reflow(sliderRef.current);
    sliderRef.current.style.transitionDuration = sliderTransitionDuration;
  }, [focusedIndex, realFocusedIndex, ...updates]);
};

const reflow = (elt: HTMLElement) => {
  void elt.offsetHeight;
};

const getElementTransformX = (elt: HTMLElement) => {
  const transformStr = elt.style.getPropertyValue('transform');
  const values = transformStr.match(/translate3d\((-?\d+)px, (\d+)px, (\d+)px\)/);
  const sliderTranslateXString = values?.[1];

  if (!sliderTranslateXString) {
    return null;
  }
  const sliderTranslateYParsed = parseFloat(sliderTranslateXString);

  if (isNaN(sliderTranslateYParsed)) {
    return null;
  }

  return sliderTranslateYParsed;
};

// Дальше хуки, которые могут помочь в будущем, но сейчас не используются

export type SliderOffsetableRefType = {
  /**
   * Проверяет, находится ли ребенок в области видимости
   * принимает индекс ребенка, которого надо проверить
   * если возвращаемое значение null, значит не удалось проверить ребенка
   * (например, нет доступа к ребенку, некорректный индекс, или компонент не успел проинициализироваться)
   */
  isChildInView: (childIdx: number) => boolean | null;
};

export type SliderOffsetableRef = React.MutableRefObject<SliderOffsetableRefType | undefined>;

export const useSliderImperativeHandle = ({
  sliderOffsetableRef,
  sliderRef,
  sliderWrapperRef,
}: {
  sliderOffsetableRef?: SliderOffsetableRef;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
  sliderWrapperRef: React.MutableRefObject<HTMLElement | null>;
}) => {
  useImperativeHandle(
    sliderOffsetableRef,
    () => {
      return {
        isChildInView(idx) {
          if (!sliderWrapperRef.current || !sliderRef.current) {
            return null;
          }

          const childrenArray = Array.from(sliderRef.current.children);
          const childRect = childrenArray?.[idx]?.getBoundingClientRect();

          if (!childRect) {
            return null;
          }

          const { right: sliderWrapperRight, left: sliderWrapperLeft } =
            sliderWrapperRef.current.getBoundingClientRect();
          const { left: childLeft, right: childRight } = childRect;

          if (
            childRight >= sliderWrapperLeft &&
            childRight <= sliderWrapperRight &&
            childLeft >= sliderWrapperLeft &&
            childLeft <= sliderWrapperRight
          ) {
            return true;
          }

          return false;
        },
      };
    },
    [],
  );
};
