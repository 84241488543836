// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".parts--nceMp {\n  margin: 5vw 0 15.234375vw 0;\n}\n\n.partSelect--3D162 {\n  margin-top: 25vw;\n  position: absolute;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemPage/AudioShow/styles.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".parts {\n  margin: 5vw 0 15.234375vw 0;\n}\n\n.partSelect {\n  margin-top: 25vw;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
export var parts = "parts--nceMp";
export var partSelect = "partSelect--3D162";
export default ___CSS_LOADER_EXPORT___;
