/* eslint-disable max-len */
import { gw_11 } from 'customization-project-name/colors';
import * as React from 'react';

type Props = Readonly<{
  className?: string;
}>;

const SelectedIcon: React.FC<Props> = ({ className }: Props) => (
  <svg className={ className } viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
    <circle cx="22" cy="22" r="22" fill={ gw_11 }/>
    <path d="M29.2357 14.0206C30.2216 13.0226 31.8943 13.0061 32.9023 13.9843L33.0193 14.0978C33.9603 15.0111 33.9727 16.4597 33.0474 17.3871L21.3658 31.5006C20.8736 31.9939 20.0442 32.0022 19.5411 31.5187L10.9894 23.2209C10.0291 22.2982 10.0252 20.8226 10.9806 19.8953L11.1119 19.7679C12.1009 18.8081 13.735 18.8031 14.7305 19.7569L20.4635 25.3162L29.2357 14.0206Z" fill="#17171B"/>
  </svg>
);

export default SelectedIcon;
