import React from "react";


interface Props {
  text: string
}

export function Text({ text }: Props) {
  const style = {
    fontWeight: 500,
    fontSize: '1.875vw',
  }
  return (
    <div style={ style }>
      { text }
    </div>
  )
}