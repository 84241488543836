export enum RatingType {
  Users = 'users',
  IMDB = 'imdb',
  Kinopoisk = 'kinopoisk',
}

export enum RatingTypeCaseSensitive {
  Users = 'Users',
  IMDB = 'IMDb',
  Kinopoisk = 'Kinopoisk',
}


interface Rating {
  max_value: number;
  object: 'rating';
  id: string;
  source: RatingType;
  total_rates: number;
  value: number;
}

interface LinkedContentRating {
  [RatingTypeCaseSensitive.IMDB]?: number | string;
  [RatingTypeCaseSensitive.Kinopoisk]?: number | string;
  [RatingTypeCaseSensitive.Users]?: number | string;
}


export {
  LinkedContentRating,
};

export default Rating;
