import settings from 'customization-project-name/settings.json';

import { getLanguage } from '~components/Provider/App/component';
import ApiClient from '~lib/ApiClient';
import getAppVersion from '~lib/appVersion';
import { api } from '~lib/env-utils';

export const initApiClient = (): ApiClient => {
  const locale = getLanguage();
  // TODO: src/app/components/Provider/Env/component.tsx назначется дефолтное API, а тут таже самая логика
  // Может в будущем быть источником багов, нужно логику переместить в одно место
  const uri = JSON.parse((window.sessionStorage.getItem('env') || '{}'))?.api || api;
  const clientVersion = getAppVersion();
  return new ApiClient({
    uri,
    defaultQuery: {
      locale,
      client_id: settings.clientId,
      client_version: clientVersion,
      client_secret: settings.clientSecret,
      timezone: -(new Date().getTimezoneOffset() * 60),
    },
  });

};
