/* eslint-disable max-len */
import { gw_07 } from 'customization-project-name/colors'
import * as React from 'react';

type Props = {
  width?: string
  height?: string
}

const KinoPoiskIcon = ({ width = '1.5625vw', height = '2.7777777777777777vh' }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 20 20" fill={ gw_07 } xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M18.1036 15.9633L18.1033 15.9638L9.86517 11.652L15.1232 17H12.1307L8.45567 11.928V17H6.02535V3H8.45567V8.08L12.1307 3H15.1232L9.72462 8.522L18.1137 4.05C16.2839 1.59267 13.3499 0 10.042 0C4.49615 0 0 4.47733 0 10C0 15.5227 4.49615 20 10.042 20C13.3437 20 16.2728 18.4129 18.1033 15.9638L18.1036 15.964V15.9633ZM20 11.2947L10.7629 10.6767L19.2226 14.0567C19.608 13.1933 19.8736 12.266 20 11.2947ZM19.2227 5.94423L19.2233 5.944H19.2226C19.2227 5.94408 19.2227 5.94415 19.2227 5.94423ZM10.8221 9.3L19.2227 5.94423C19.608 6.8075 19.8736 7.73475 20 8.706L10.8221 9.3Z" />
    </svg>
  </div>
);


export { KinoPoiskIcon };
