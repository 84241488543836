import * as cn from 'classnames';
import * as React from 'react';

import { IconChipsAudio } from "~newapp/ui-kit/Buttons_chips_tabs/Icon_btn/audio";
import { IconChipsChannels } from "~newapp/ui-kit/Buttons_chips_tabs/Icon_btn/channels";
import { IconChipsEpg } from '~newapp/ui-kit/Buttons_chips_tabs/Icon_btn/epg';
import { IconChipsEpisodes } from '~newapp/ui-kit/Buttons_chips_tabs/Icon_btn/episodes';
import { IconChipsFavourite } from '~newapp/ui-kit/Buttons_chips_tabs/Icon_btn/favourite';
import { IconChipsInfo } from "~newapp/ui-kit/Buttons_chips_tabs/Icon_btn/info";
import { IconChipsSettings } from "~newapp/ui-kit/Buttons_chips_tabs/Icon_btn/settings";
import { IconChipsSubtitles } from "~newapp/ui-kit/Buttons_chips_tabs/Icon_btn/subtitles";

import IconFavoriteOff from '../icons/favorite-off';
import { IconBar } from '../usePlayerControlsNavigation';
import * as styles from './styles.module.css';


type Props = Readonly<{
  icon: IconBar;
  isFocused: boolean;
  onClick: (item: IconBar) => void;
}>;


const IconsBarItem = (props: Props) => {
  const handleClick = React.useCallback(() => {
    props.onClick(props.icon);
  }, [props.onClick, props.icon]);

  const renderIcon = React.useCallback(() => {
    switch (props.icon) {
      case IconBar.EPG: {
        return (<IconChipsEpg isFocused={ props.isFocused } />);
      }
      case IconBar.Info: {
        return (<IconChipsInfo isFocused={ props.isFocused } />);
      }
      case IconBar.AudioTracks: {
        return (<IconChipsAudio isFocused={ props.isFocused } />);
      }
      case IconBar.FavoriteToggle: {
        return (<IconChipsFavourite isFocused={ props.isFocused } />);
      }
      case IconBar.FavoriteToggleOff: {
        return (<IconFavoriteOff isFocused={ props.isFocused }/>);
      }
      case IconBar.ChannelsList: {
        return (<IconChipsChannels isFocused={ props.isFocused } />);
      }
      case IconBar.TextTracks: {
        return (<IconChipsSubtitles isFocused={ props.isFocused } />);
      }
      case IconBar.VideoTracks: {
        return (<IconChipsSettings isFocused={ props.isFocused } />);
      }
      case IconBar.EpisodesList: {
        return (<IconChipsEpisodes isFocused={ props.isFocused } />);
      }
      default:
        return null;
    }
  }, [props.icon, props.isFocused])

  return (
    <div
      className={ cn(styles.iconsBarItem, {
        [styles.focused]: props.isFocused,
        'focusedNavigationNode': props.isFocused,
      }) }
      onClick={ handleClick }
    >
      { renderIcon() }
    </div>
  )
};


export default IconsBarItem
