import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { ChipsButton } from './ChipsButton';

type Props = {
  isFocused: boolean;
  isActive: boolean;
  children?: React.ReactNode;
  showArrow?: boolean;
  focuserClassName?: string;
  buttonClassName?: string;
} & Omit<FocusableBlockProps, 'className' | 'isLastBlock'>;

export const ChipsButtonFocuser: React.FC<Props> = ({
  isFocused,
  isActive,
  children,
  showArrow,
  focuserClassName,
  buttonClassName,
  ...focuserProps
}) => {
  return (
    <focuser.FocusableBlock
      { ...focuserProps }
      isFocused={ isFocused }
      className={ focuserClassName }
      isLastBlock
    >
      {({ isFocused }) => (
        <ChipsButton
          className={ buttonClassName }
          isFocused={ isFocused }
          isActive={ isActive }
          content={ children }
          showArrow={ showArrow }
        />
      )}
    </focuser.FocusableBlock>
  );
};
