import * as React from 'react';
import { InjectedIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PrimaryButtonWrap } from '~app/ui-kit/PrimaryButton';
import { useMenu } from '~components/Provider/Menu';
import { useAccount } from '~hooks/fetch/useAccount';
import {
  CLOSE_THE_APP,
  LOGIN,
  LOGOUT,
} from '~localization';
import NavigationDirection from '~typings/NavigationDirection';

import * as styles from './styles.module.css';


type Props = Readonly<{
  onExit: () => void;
  onLogOut: () => void;
  intl: InjectedIntl;
}>;

enum FocusOn {
  SignInButton = 'sign-in',
  CloseApp = 'close-app',
}


export const GeoRestrictButtons: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { isExitOpened } = useMenu();
  const { data: account } = useAccount();
  const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.SignInButton);
  const onAuthBtnClick = () => {
    history.replace('/auth');
  };
  const onLeaveHandlerSignInOut = (direction) => {
    if (direction === NavigationDirection.Left || direction === NavigationDirection.Right) {
      setFocusOn(FocusOn.CloseApp);
    }
  };
  const onLeaveHandlerExit = (direction) => {
    if (direction === NavigationDirection.Left || direction === NavigationDirection.Right) {
      setFocusOn(FocusOn.SignInButton);
    }
  };

  return (
    <div
      className={ styles.buttons }
    >
      {
        (account === undefined) ?
          <div className={ styles.buttonWrapper }>
          <PrimaryButtonWrap
            onClick={ onAuthBtnClick }
            className={ styles.button }
            onLeave={ onLeaveHandlerSignInOut }
            isFocused={ (!isExitOpened && focusOn === FocusOn.SignInButton) }
          >
            { props.intl.formatMessage({ id: LOGIN }) }
          </PrimaryButtonWrap>
          </div>
          :
          <div className={ styles.buttonWrapper }>
          <PrimaryButtonWrap
            onClick={ props.onLogOut }
            className={ styles.button }
            onLeave={ onLeaveHandlerSignInOut }
            isFocused={ (!isExitOpened && focusOn === FocusOn.SignInButton) }
          >
            { props.intl.formatMessage({ id: LOGOUT }) }
          </PrimaryButtonWrap>
          </div>
      }
      <div className={ styles.buttonWrapper }>
      <PrimaryButtonWrap
        onClick={ props.onExit }
        className={ styles.button }
        onLeave={ onLeaveHandlerExit }
        isFocused={ (!isExitOpened && focusOn === FocusOn.CloseApp) }
      >
        { props.intl.formatMessage({ id: CLOSE_THE_APP }) }
      </PrimaryButtonWrap>
      </div>
    </div>
  );
};

export default GeoRestrictButtons;
