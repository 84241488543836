import getScreenHeight from '~lib/screen/height';
import getScreenWidth from '~lib/screen/width';
import {
  getBannerFullScreenWidth,
  getBlockHeightBanner,
  getBlockHeightBannerFullScreen,
  getBlockHeightCardCollection,
  getBlockHeightPoster,
  getBlockHeightProgramLine,
  getHeightBanner8x1,
  getImageBannerHeight,
  getImageBannerWidth,
  getImageDefaultDimensionHeight,
  getImageDefaultDimensionWidth,
  getImageLogoHeight,
  getImageLogoWidth,
  getImagePosterHeight,
  getImagePosterWidth,
  getImageSquareWidth,
  getMediaItemBannerHeight,
  getMediaItemBannerWidth,
  getMediaItemDefaultDimensionHeight,
  getMediaItemDefaultDimensionWidth,
  getMediaItemPosterHeight,
  getMediaItemPosterWidth,
} from '~lib/SizesInPX';
import { Block, BlockType } from '~typings/Block';
import CardCollection from '~typings/CardCollection';
import Collection from '~typings/Collection';
import CollectionType from '~typings/CollectionType';
import ImageType from '~typings/ImageType';
import ItemObject from "~typings/ItemObject";
import MediaItemDimension from '~typings/MediaItemDimension';
import { CardConfigLayout } from '~typings/ScreenCardConfigs';


type Dimension = Readonly<{
  width: number;
  height: number;
}>;


// Available options for displaying media items
const MEDIA_ITEM_DIMENSIONS: { [key in MediaItemDimension]?: Dimension } = {
  [ImageType.BannerFullScreen]: {
    width: getBannerFullScreenWidth(),
    height: getMediaItemBannerHeight(),
  },
  [ImageType.LOGO]: {
    width: getMediaItemBannerWidth(),
    height: getMediaItemBannerHeight(),
  },
  [ImageType.Card1x1]: {
    // width: getMediaItemBannerWidth(),
    // height: getMediaItemBannerHeight(),
    width: getImageSquareWidth(),
    height: getImageSquareWidth(),
  },
  [ImageType.POSTER]: {
    width: getMediaItemPosterWidth(),
    height: getMediaItemPosterHeight(),
  },
  [ImageType.BANNER]: {
    width: getMediaItemBannerWidth(),
    height: getMediaItemBannerHeight(),
  }
};

const DEFAULT_MEDIA_ITEM_DIMENSION: Dimension = {
  width: getMediaItemDefaultDimensionWidth(),
  height: getMediaItemDefaultDimensionHeight(),
};

// Available options for displaying images
const MEDIA_ITEM_IMAGE_DIMENSIONS: { [key in MediaItemDimension]?: Dimension } = {
  [ImageType.LOGO]: {
    width: getImageLogoWidth(),
    height: getImageLogoHeight(),
  },
  [ImageType.POSTER]: {
    width: getImagePosterWidth(),
    height: getImagePosterHeight(),
  },
  [ImageType.BANNER]: {
    width: getImageBannerWidth(),
    height: getImageBannerHeight(),
  },
};

const DEFAULT_IMAGE_DIMENSION: Dimension = {
  width: getImageDefaultDimensionWidth(),
  height: getImageDefaultDimensionHeight(),
};

const getDimensionByObject = (imageType: ImageType): Dimension => {
  return MEDIA_ITEM_DIMENSIONS[imageType] || DEFAULT_MEDIA_ITEM_DIMENSION;
};

const getImageDimensionByType = (dimension: MediaItemDimension): Dimension => {
  return MEDIA_ITEM_IMAGE_DIMENSIONS[dimension] || DEFAULT_IMAGE_DIMENSION;
};

const getImageRatio = (dimension: MediaItemDimension): number => {
  switch (dimension) {
    case ImageType.BANNER: {
      return (getScreenWidth() / getScreenHeight());
    }
    case ImageType.LOGO: {
      return (getImageLogoWidth() / getImageLogoHeight());
    }
    case ImageType.POSTER: {
      return 0.65166667;
    }
    default: {
      return (getMediaItemDefaultDimensionWidth() / getMediaItemDefaultDimensionHeight());
    }
  }
};

const getDimension = (collection?: Collection | CardCollection, object?: BlockType): MediaItemDimension => {
  try {
    if (collection) {
      if (collection.object === ItemObject.CardCollection) {
        return (
          (
            collection.card_config.layout === CardConfigLayout.Vertical ||
            collection.card_config.layout === CardConfigLayout.News
          ) ?
            ImageType.POSTER
            :
            ImageType.BANNER
        );
      }
      else {
        if (
          collection.object === CollectionType.ChannelsLogo
          || collection.object === CollectionType.RadioStations
        ) {
          return ImageType.LOGO;
        }
        else if (collection.object === CollectionType.MoviesPoster) {
          return ImageType.POSTER;
        }
      }
    }
    if (object === BlockType.BannersBlock) {
      return ImageType.BannerFullScreen;
    }
    if (object === BlockType.FiltersBlock) {
      return ImageType.Filter;
    }
    if (object === BlockType.CurrentProgramLineBlock) {
      return ImageType.ProgramLine;
    }

    return ImageType.BANNER;
  } catch (ignore) {
    return ImageType.BANNER;
  }
};

const getCardCollectionDimension = (cardCollection: CardCollection): MediaItemDimension => {
  switch (cardCollection.card_config.layout) {
    case CardConfigLayout.Vertical: {
      return ImageType.POSTER;
    }
    case CardConfigLayout.News: {
      return ImageType.BANNER;
    }
    case CardConfigLayout.Horizontal: {
      return ImageType.BANNER;
    }
    case CardConfigLayout.Square: {
      return ImageType.Card1x1;
    }
    default:
      return ImageType.BANNER;
  }
};

const getBlockHeight = (collection?: Collection | CardCollection, object?: BlockType): number => {
  if (object === BlockType.ExpandableCardColletion) {
    return getBlockHeightCardCollection()
  }

  if (collection) {
    if (collection.object === ItemObject.CardCollection) {
      return (
        (
          collection.card_config.layout === CardConfigLayout.Vertical ||
          collection.card_config.layout === CardConfigLayout.News
        )
          ? getBlockHeightPoster()
          : getBlockHeightBanner()
      );
    }
    else {
      if (
        collection.object === CollectionType.ChannelsLogo
        || collection.object === CollectionType.RadioStations
      ) {
        return getBlockHeightBanner();
      }
      else if (collection.object === CollectionType.MoviesPoster) {
        return getBlockHeightPoster();
      }
    }
  }
  if (object === BlockType.BannersBlock) {
    return getBlockHeightBannerFullScreen();
  }
  if (object === BlockType.BigBannersBlock) {
    return getBlockHeightBannerFullScreen();
  }
  if (object === BlockType.MiddleBannersBlock) {
    return getHeightBanner8x1();
  }
  if (object === BlockType.FiltersBlock) {
    return 0;
  }
  if (object === BlockType.CurrentProgramLineBlock) {
    return getBlockHeightProgramLine();
  }

  return getBlockHeightBanner();
};

const blocksDimensions = (blocks: Block[]): number[] => (
  blocks.map((block: Block) => getBlockHeight(block?.collection || block.card_collection, block?.object))
);


export {
  blocksDimensions,
  Dimension,
  getCardCollectionDimension,
  getDimension,
  getDimensionByObject,
  getImageDimensionByType,
  getImageRatio,
  MEDIA_ITEM_DIMENSIONS,
  MEDIA_ITEM_IMAGE_DIMENSIONS,
};
