import { useEffect } from 'react';

import usePointer from '~stores/Pointer';
import NavigationDirection from '~typings/NavigationDirection';


type Props = {
  allowNavigation: boolean;
  itemsCount: number;
  focusOn: number;
  horizontal?: boolean;
}

function usePointerNavigation({
  allowNavigation,
  itemsCount,
  focusOn,
  horizontal = false,
}: Props, update: any[] = []) {
  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);
  const flush = usePointer(state => state.flush);

  useEffect(() => () => flush(
    horizontal ?
      [NavigationDirection.Left, NavigationDirection.Right]
      :
      [NavigationDirection.Up, NavigationDirection.Down]
  ), update);

  useEffect(() => {
    if (pointerEnabled) {
      updateDirections(
        horizontal ? {
          [NavigationDirection.Left]: allowNavigation && focusOn > 0,
          [NavigationDirection.Right]: allowNavigation && focusOn < itemsCount - 1,
        } : {
          [NavigationDirection.Up]: allowNavigation && focusOn > 0,
          [NavigationDirection.Down]: allowNavigation && focusOn < itemsCount - 1,
        }
      );
    }
  }, [
    pointerEnabled,
    updateDirections,
    allowNavigation,
    itemsCount,
    focusOn,
    horizontal,
    update,
  ]);
}


export default usePointerNavigation;
