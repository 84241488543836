import React from "react";

import { fillImageTemplateBySizes } from "~lib/image";
import { valueInPixelsByHeight, valueInPixelsByWidth } from "~lib/SizesInPX";
import Image from "~typings/Image";

import * as css from "../styles.module.css";


type Props = {
  image: Image
}

const LocalComponent = ({ image }: Props) => {
  const height = valueInPixelsByHeight(5.555555555555555) //40px in vh -> px
  const width = valueInPixelsByWidth(3.125) //40px in vw -> px
  const url = fillImageTemplateBySizes(image.url_template, width, height)

  return (
    <div className={ css.iconWrapper }>
      <div className={ css.icon } style={ { background: `url(${ url }) center no-repeat` } }></div>
    </div>
  )
}

export const ChannelIcon = LocalComponent