import { FavoriteType } from '~typings/FavoriteType';
import ItemObject from '~typings/ItemObject';


const favoriteTypeByItemObject = {
  [ItemObject.Movie]: FavoriteType.Movies,
  [ItemObject.Channel]: FavoriteType.Channels,
  [ItemObject.AudioShow]: FavoriteType.AudioShows,
  [ItemObject.Part]: FavoriteType.AudioShows,
  [ItemObject.RadioStation]: FavoriteType.RadioStations,
  [ItemObject.Series]: FavoriteType.Series,
  [ItemObject.Episode]: FavoriteType.Series,
};


export { favoriteTypeByItemObject };
