import * as React from 'react';
import { useHistory } from 'react-router-dom';

import ExitPopup from '~components/ExitPopup/ExitPopup';
import { NavMenu } from '~components/NavMenu';
import PointerManager from '~components/PointerManager/PointerManager';
import { useApp } from '~components/Provider/App';
import { useMenu } from '~components/Provider/Menu';
import { getAuthData } from '~hooks/fetch/useAccount';
import { useIsPagesFetched } from '~hooks/fetch/usePages/usePages';
import { PageInfo } from '~hooks/usePageInfo';
import { getOpenedPopupName } from '~lib/PopupState';
import usePointer from '~stores/Pointer';
import PointerNavigation from '~typings/PointerNavigation';

import { PopcornPlayer } from "../../../newapp/blocks/playerPopcorn/player";
import CatchBack from './CatchBack';
import usePageManagerHelper, { useIsPageManagerPointersBlocked } from './PageManager.helper';
import useHandleBack from './useHandleBack';


type Props = Readonly<{
  pageInfo: PageInfo;
  children: any;
}>;


const PageManager = (props: Props) => {
  const { isLaunchingDone } = useApp();
  const isPagesFetched = useIsPagesFetched();
  const { isMenuOpened, isMenuAppeared, isExitOpened, isPopupOpened } = useMenu();
  const { accessToken } = getAuthData();
  const history = useHistory();
  const isPopupName = !!getOpenedPopupName(history);
  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);
  const flush = usePointer(state => state.flush);

  const isPageManagerPointersBlocked = useIsPageManagerPointersBlocked();
  React.useEffect(() => {
    if(!pointerEnabled || isPageManagerPointersBlocked) {
      return;
    }

      updateDirections({
        [PointerNavigation.Menu]: props.pageInfo.isAllowedMenuRender && !isMenuAppeared && !isMenuOpened
        && !isPopupOpened && !props.pageInfo.isProfileSelectPage && !props.pageInfo.isAuthPage
        && !!isLaunchingDone && !!isPagesFetched,
        [PointerNavigation.Close]: props.pageInfo.isAllowedMenuRender && isMenuOpened && !isMenuAppeared
        && !isPopupOpened && !props.pageInfo.isProfileSelectPage && !props.pageInfo.isAuthPage
        && !!isLaunchingDone && !!isPagesFetched,
      });

      return () => {
        flush([
          PointerNavigation.Menu,
          PointerNavigation.Close,
        ]);
      }

  }, [
    props.pageInfo.pageID,
    pointerEnabled,
    isLaunchingDone,
    isPagesFetched,
    props.pageInfo.isAllowedMenuRender,
    updateDirections,
    props.pageInfo.isProfileSelectPage,
    props.pageInfo.isAuthPage,
    flush,
    isMenuOpened,
    isPopupOpened,
    isPopupName,
    isMenuAppeared,
    isPageManagerPointersBlocked,
  ]);

  usePageManagerHelper({
    accessToken,
    pageID: props.pageInfo.pageID,
  });

  const {
    handleBack,
    handleBackUpdate,
    handleCloseExitPopup,
  } = useHandleBack(props.pageInfo);

  return (
    <>
      <CatchBack
        action={ handleBack }
        triggers={ handleBackUpdate }
      />
      { props.children }
      {
        (isLaunchingDone && props.pageInfo.isAllowedMenuRender) && <NavMenu />
      }
      <PopcornPlayer />
      <PointerManager />
      <ExitPopup
        isPopupOpen={ isExitOpened }
        closePopup={ handleCloseExitPopup }
      />
    </>
  );
};


export default PageManager;
