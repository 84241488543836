import * as React from 'react';

import { usePlayerState } from '~components/Provider/Player';
import { favoriteTypeByItemObject } from '~hooks/fetch/useFavorites/useFavoritesV3.helpers';
import useSpatialNavigation, { NavigationLine } from '~hooks/useSpatialNavigation';
import FavoriteType from '~typings/FavoriteType';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';

import { isChannel, isEpisode,isTimeShiftAllowed } from '../utils';


export enum IconBar {
  EPG = 'epg',
  Info = 'info',
  AudioTracks = 'audioTracks',
  TextTracks = 'textTracks',
  VideoTracks = 'videoTracks',
  FavoriteToggle = 'favorite_toggle',
  FavoriteToggleOff = 'favorite_toggle_off',
  ChannelsList = 'channels_list',
  EpisodesList = 'episodes_list',
}

export enum PlayerControl {
  OnAir = 'on_air',
  NextEpisode = 'next_episode',
  PlayPauseProgressBar = 'play_pause_progress_bar',
  IconBar = 'icons_bar',
  SkipButton = 'skip_button',
}

export enum PlayerLine {
  OnAir = 0,
  NextEpisode = 0,
  PlayPauseProgressBar = 1,
  IconBar = 2,
  SkipButton = 3,
}

type Props = Readonly<{
  item: PlayerMediaItem;
  allowNavigation: boolean;
  isAuthorized: boolean | null;
  isInFavorites: boolean;
  isVisibleGoToAir: boolean;
  duration: number;
  isHasInfo: boolean;
}>;


const usePlayerControlsNavigation = (props: Props) => {
  const {
    audioTracks,
    textTracks,
    videoTracks,
  } = usePlayerState();

  const { navigationItems, iconBar, controls } = React.useMemo(() => {
    const iconBar: IconBar[] = [];
    const controls: PlayerControl[] = [];

    if (!isChannel(props.item)) {
      controls.push(PlayerControl.SkipButton);
      if (!(isNaN(props.duration) || props.duration === 0)) {
        controls.push(PlayerControl.PlayPauseProgressBar);
      }
      if (props.item.object === ItemObject.ProgramEvent) {
        iconBar.push(IconBar.EPG);
      }
      if (props.item.object === ItemObject.Episode) {
        iconBar.push(IconBar.EpisodesList);
      }
      iconBar.push(IconBar.Info);
      if (audioTracks && audioTracks.length > 1) {
        iconBar.push(IconBar.AudioTracks);
      }
      if (props.isAuthorized && Object.keys(FavoriteType)
          .some(key => FavoriteType[key] === favoriteTypeByItemObject[props.item.object])
      ) {
        iconBar.push(
          props.isInFavorites ?
            IconBar.FavoriteToggle
            : IconBar.FavoriteToggleOff
        );
      }
      if (props.item.object === ItemObject.ProgramEvent) {
        iconBar.push(IconBar.ChannelsList);
      }
      if (textTracks && textTracks.length > 0) {
        iconBar.push(IconBar.TextTracks);
      }
      if (videoTracks && videoTracks.length > 1) {
        iconBar.push(IconBar.VideoTracks);
      }
      if (isEpisode(props.item)) {
        if (props.isVisibleGoToAir) {
          controls.push(PlayerControl.NextEpisode);
        }
      }
    } else {
      if (isTimeShiftAllowed(props.item) && !(isNaN(props.duration) || props.duration === 0)) {
        if (props.isVisibleGoToAir) {
          controls.push(PlayerControl.OnAir);
        }
        controls.push(PlayerControl.PlayPauseProgressBar);
      }
      if (props.isHasInfo) {
        iconBar.push(IconBar.EPG);
        iconBar.push(IconBar.Info);
      }
      if (audioTracks && audioTracks.length > 1) {
        iconBar.push(IconBar.AudioTracks);
      }
      if (props.isAuthorized) {
        iconBar.push(
          props.isInFavorites ?
            IconBar.FavoriteToggle
            : IconBar.FavoriteToggleOff
        );
      }
      iconBar.push(IconBar.ChannelsList);
      if (videoTracks && videoTracks.length > 1) {
        iconBar.push(IconBar.VideoTracks);
      }
    }

    controls.push(PlayerControl.IconBar);
    const navigationItems: NavigationLine[] = [
      {}, // on Air or NextEpisode
      {}, // PlayPause Progress Bar
      {}, // Icons Bar
    ];
    controls.forEach((control: PlayerControl) => {
      if (control === PlayerControl.OnAir) {
        navigationItems[PlayerLine.OnAir] = ({ maxIndex: 0 });
      }
      if (control === PlayerControl.NextEpisode) {
        navigationItems[PlayerLine.NextEpisode] = ({ maxIndex: 1 });
      }
      if (control === PlayerControl.PlayPauseProgressBar) {
        navigationItems[PlayerLine.PlayPauseProgressBar] = ({ maxIndex: 0 });
      }

      if (control === PlayerControl.IconBar) {
        navigationItems[PlayerLine.IconBar] = ({ maxIndex: (iconBar.length - 1) });
      }

      if (control === PlayerControl.SkipButton) {
        navigationItems[PlayerLine.SkipButton] = ({ maxIndex: 0 });
      }
    });

    return ({
      iconBar,
      controls,
      navigationItems,
    });
  }, [
    props.isHasInfo,
    props.item.id,
    props.isAuthorized,
    props.isInFavorites,
    props.isVisibleGoToAir,
    audioTracks,
    textTracks,
    videoTracks,
    props.duration,
  ]);

  const navState = useSpatialNavigation({
    navigationItems,
    allowNavigation: props.allowNavigation,
  });

  return ({
    iconBar,
    controls,
    navState,
  });
};


const getIconsBarFocusedItemIndex = (focusedIndex: number[]): number => {
  return focusedIndex[PlayerLine.IconBar];
};

export {
  getIconsBarFocusedItemIndex,
};


export default usePlayerControlsNavigation;
