import focuser, { FocuserClickHandler, FocuserKeyHandler } from '@focuser';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { InputFormFocuser } from '~components/InputFormFocuser';
import { KeyboardFieldSet } from '~components/KeyboardFieldSet';
import LightLoader from '~components/LightLoader';
import PinCodeForm from '~components/PinCodeForm';
import { PinCodeManager,ProcedureType } from '~components/PinCodeManager';
import { ActionType, useAppAction } from '~components/Provider/App';
import { PinActionType, SecurityPinErrors, useSecurityPinMutation } from '~hooks/fetch/useAccount';
import {
  ACCOUNT_SECURITY_ENTER_PIN_CODE,
  AUTH_ENTER_PASSWORD_TO_LOGIN,
  ERROR_HAS_OCCURRED,
  RESET_PIN_CODE_TEXT,
  RESET_PIN_CODE_TITLE,
  SECURITY_CODE,
  WRONG_PASSWORD,
} from '~localization';
import { SECURITY_CREATE_PIN_MODE_PATH } from '~screens/AccountScreen';
import { PopupIsolated } from '~ui/Popup';

import RecoveryButton from './RecoveryButton';
import * as styles from './styles.module.css';

export type Props = {
  isNeedFlushPinPopupState?: boolean;
  onSuccess: (code: string, wasDropped?: boolean) => void;
  onBack?: () => void;
  headerTextId?: string;
  messageId?: string;
  errorId?: string | null;
  withoutValidation?: boolean;
  intl: InjectedIntl;
};

enum FocusOn {
  InputForm = 'inputForm',
  RecoveryButton = 'recoveryButton',
}

export const PinCodePopup: React.FC<Props> = ({
  isNeedFlushPinPopupState,
  onSuccess,
  onBack,
  headerTextId,
  messageId,
  errorId,
  withoutValidation,
  intl,
}) => {
  const history = useHistory();
  const appAction = useAppAction();
  const { mutate: securityPinAction } = useSecurityPinMutation();

  const [errorText, setErrorText] = React.useState<string | null>(null);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.InputForm);
  const [scenario, setScenario] = React.useState<'enter' | 'reset' | 'create'>('enter');
  const isResetPinBtnVisible = !withoutValidation;

  React.useEffect(() => {
    const message = errorId ? intl.formatMessage({ id: errorId }) : null;
    setErrorText(message);
  }, [errorId, messageId]);

  React.useEffect(() => {
    if (isNeedFlushPinPopupState) {
      setIsProcessing(false);
    }
  }, [isNeedFlushPinPopupState]);

  const handleBack: FocuserKeyHandler = React.useCallback((e) => {
    e.stop();
    e.stopNativeEvent()
    if (scenario === 'reset') {
      setErrorText(null);
      setScenario('enter');
      setFocusOn(FocusOn.InputForm);
    } else if (onBack) {
      onBack();
    }
  }, [scenario, onBack]);


  const resetPinErrorHandler = React.useCallback((resp) => {
    if (resp.errors[0].code === SecurityPinErrors.ResetPinInvalidPassword) {
      setErrorText(intl.formatMessage({ id: WRONG_PASSWORD }));
    } else {
      setErrorText(intl.formatMessage({ id: ERROR_HAS_OCCURRED }));
    }
  }, []);

  // Buttons

  const recoveryBtnClickHandler: FocuserClickHandler = (e) => {
    e.stop();
    e.stopNativeEvent()
    if (scenario === 'enter') {
      setErrorText(null);
      setScenario('reset');
      setFocusOn(FocusOn.InputForm);
      return;
    }

    // дальше сценарий 'reset'
    appAction({
      type: ActionType.SetAuthRedirectUrl,
      payload: { authRedirectUrl: SECURITY_CREATE_PIN_MODE_PATH },
    });

    appAction({
      type: ActionType.SetNeedToResetPin,
      payload: { isNeedToResetPin: true },
    });
    onBack?.();
    history.push('/auth/forgot-password');
  };


  // Forms

  const onKeyDownInForms = () => {
    if (isResetPinBtnVisible) {
      setFocusOn(FocusOn.RecoveryButton);
    }
  };

  const resetSecurityPinHandler = React.useCallback((password: string) => {
    setIsProcessing(true);
    securityPinAction(
      {
        type: PinActionType.Removal,
        payload: { password },
      },
      {
        onSuccess: () => {
          setScenario('create');
        },
        onError: resetPinErrorHandler,
        onSettled: () => setIsProcessing(false),
      },
    );
  }, [securityPinAction, resetPinErrorHandler]);

  // Text

  const headerText = intl.formatMessage({
    id:
      scenario === 'reset'
        ? RESET_PIN_CODE_TITLE
        : headerTextId?.length
          ? headerTextId
          : SECURITY_CODE,
  });

  const descriptionText = errorId
    ? null
    : intl.formatMessage({
        id:
          scenario === 'reset'
            ? RESET_PIN_CODE_TEXT
            : messageId?.length
              ? messageId
              : ACCOUNT_SECURITY_ENTER_PIN_CODE,
      });

  if (scenario === 'create') {
    return (
      <PinCodeManager
        onDone={ () => {
          onSuccess('', true);
        } }
        procedureType={ ProcedureType.Recreation }
      />
    );
  }

  const renderContent = () => {
    switch (scenario) {
      case 'enter':
        return (
          <focuser.FocusableBlock
            isFocused={ focusOn === FocusOn.InputForm }
            onForceFocus={ () => setFocusOn(FocusOn.InputForm) }
            onKeyReturn={ handleBack }
            onKeyDown={ onKeyDownInForms }
            isPointerDownAvailable={ isResetPinBtnVisible }
            key="enter"
          >
            <PinCodeForm
              isNeedFlushPinPopupState={ isNeedFlushPinPopupState }
              onSuccess={ onSuccess }
              onError={ setErrorText }
              onProcessingStatus={ setIsProcessing }
              withoutValidation={ withoutValidation }
            />
          </focuser.FocusableBlock>
        );
      case 'reset':
        return (
          <focuser.FocusableBlock
            isFocused={ focusOn === FocusOn.InputForm }
            onKeyReturn={ handleBack }
            key="reset"
            onForceFocus={ () => setFocusOn(FocusOn.InputForm) }
            emitForceFocusOnHover
            isPointerDownAvailable={ isResetPinBtnVisible }
            onKeyDown={ onKeyDownInForms }
          >
            <InputFormFocuser
              onDone={ resetSecurityPinHandler }
              initialKeyboardLayout={ 'eng_lowercase' }
              hideChars
              placeholder={ intl.formatMessage({ id: AUTH_ENTER_PASSWORD_TO_LOGIN }) }
            />
          </focuser.FocusableBlock>
        );
    }
  };

  return (
    <PopupIsolated>
      <KeyboardFieldSet
        headerText={ headerText }
        errorText={ errorText }
        descriptionText={ descriptionText }
        forKeyboardType={ scenario === 'reset' ? 'password' : 'pincode' }
      >
        <div className={ styles.content }>
          {renderContent()}
          {isResetPinBtnVisible && (
            <focuser.FocusableBlock
              isFocused={ focusOn === FocusOn.RecoveryButton }
              onKeyReturn={ handleBack }
              className={ styles.recoveryButton }
              onKeyUp={ () => setFocusOn(FocusOn.InputForm) }
              onClick={ recoveryBtnClickHandler }
              onForceFocus={ () => setFocusOn(FocusOn.RecoveryButton) }
              isPointerUpAvailable
            >
              <RecoveryButton
                isPasswordRecoveryMode={ scenario === 'reset' }
                onNeedToLeave={ () => setFocusOn(FocusOn.InputForm) }
                setIsProcessing={ setIsProcessing }
              />
            </focuser.FocusableBlock>
          )}
        </div>
      </KeyboardFieldSet>

      {isProcessing && <LightLoader />}
    </PopupIsolated>
  );
};

export default
injectIntl(PinCodePopup);
