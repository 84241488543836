import { flatMap } from 'lodash';
import { useMemo } from 'react';
import { InfiniteData, Query, useInfiniteQuery, useQuery } from 'react-query';

import { queryCache, queryClient } from '~global';
import { useClient, useConfig } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getAuthData } from '~hooks/fetch/useAccount';
import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '~hooks/fetch/utils';
import ApiClient from '~lib/ApiClient';
import { transformArrayToUniqueString } from '~lib/cache-utils';
import deleteCacheWithOldAccessTokens from '~lib/ReactQuery/deleteCacheWithOldAccessTokens';
import AppConfig from '~typings/AppConfig';
import ContinueWatchingType from '~typings/ContinueWatchingType';
import ItemObject from '~typings/ItemObject';
import ResponseMany from '~typings/ResponseMany';
import WatchProgressWithResource from '~typings/WatchProgressWithResource';

type FetchParams = Readonly<{
  page: number;
  accessToken: string | null;
  types: ContinueWatchingType[]
  client: ApiClient;
  limit: number;
}>;

const DEFAULT_TYPES = [
  ContinueWatchingType.Channels,
  ContinueWatchingType.Movies,
  ContinueWatchingType.Episodes,
  ContinueWatchingType.Parts,
];

const PREFIX_OF_A_COMPOSITE_KEY = 'continue_watching_v3';
const FIRST_PAGE = 0;

const getContinueWatchingByResource = (
  id: string,
  object: ItemObject.Movie | ItemObject.Episode | ItemObject.Channel | ItemObject.Part,
) => {
  const { accessToken } = getAuthData();

  // Достаем весь кэш
  const allCachedCW = queryCache.findAll(
    ContinueWatchingKeys.withToken(accessToken)
  );

  // Фильтруем из кэша только обычные запросы, infinity нам не нужно
  const normalQueries = allCachedCW.filter(
    (query) =>
       typeof query.state.data === 'object' && query.state.data && 'data' in query.state.data
  ) as Query<ResponseMany<WatchProgressWithResource[]>>[];

  // Находим нужный continue Watching по фильтру
  const foundCW = flatMap(normalQueries, (query) => query.state.data?.data).find(
    (cw) => cw && cw.resource.id === id && cw.resource.object === object
  );

  return foundCW;
};

const fetchV3 = async (params: FetchParams) => {
  if (!params.accessToken) {
    throw new Error('accessToken is missing');
  }

  deleteCacheWithOldAccessTokens(ContinueWatchingKeys.PREFIX, 1);

  return await params.client.get(
    fetchURLs[FetchType.ContinueWatchingV3],
    {
      ...fetchOptionsBasic[FetchType.ContinueWatchingV3],
      'access_token': params.accessToken,
      'filter[resource_type_in]': params.types.join(','),
      'page[offset]': (params.page * params.limit),
      'page[limit]': params.limit,
    }
  );
};

type CommonQueryKeyParam = {
  types: ContinueWatchingType[],
  accessToken: string | null,
  limit: number,
}

const ContinueWatchingKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,
  withToken: (accessToken: string | null)=>[ContinueWatchingKeys.PREFIX, accessToken],
  withDefaultParams: (keyParams: CommonQueryKeyParam)=>[
    ...ContinueWatchingKeys.withToken(keyParams.accessToken),
    keyParams.limit,
    transformArrayToUniqueString(keyParams.types)
  ],
  firstNormalPage: (
    keyParams: CommonQueryKeyParam
  ) => [
    ...ContinueWatchingKeys.withDefaultParams(keyParams),
    'fist-normal-page',
  ],
  infinityPages: (
    keyParams: CommonQueryKeyParam
  ) => [
    ...ContinueWatchingKeys.withDefaultParams(keyParams),
    'infinity-pages',
  ],
};


type ContinueWatchingQueryParams = {
  types?: ContinueWatchingType[];
}

const getContinueWatchingFirstPageHookParams = ({
  config,
  client,
  queryParams
}: {
  config: AppConfig,
  client: ApiClient
  queryParams?: ContinueWatchingQueryParams
}) => {
  const { accessToken } = getAuthData();
  const parsedTypes = queryParams?.types?.length ? queryParams.types : DEFAULT_TYPES;
  const limit = getLimitFromConfig(config);

  const keyParams : CommonQueryKeyParam = {
    types: parsedTypes,
    accessToken,
    limit,
  }

  return ({
    queryFn: async ()=>{
      // Смотрим в кэш infinity запросов
      const infinityPagesCache = queryClient.getQueryData<InfiniteData<ResponseMany<WatchProgressWithResource[]>>>(
        ContinueWatchingKeys.infinityPages(keyParams),
      );
      // Если есть - достаем первую страницу, она нам и нужна
      const firstPageFromInfinityPages = infinityPagesCache?.pages[0];

      if(firstPageFromInfinityPages){
        return firstPageFromInfinityPages;
      }
      // Иначе делаем запрос, одновременно заполняя и infinity кэш, и возвращаем ответ
      const infinityPagesReq = await queryClient.fetchInfiniteQuery<ResponseMany<WatchProgressWithResource[]>>({
        queryKey: ContinueWatchingKeys.infinityPages(keyParams),
        queryFn: () =>
        fetchV3({
            client,
            accessToken,
            page: FIRST_PAGE,
            types: parsedTypes,
            limit,
          }),
      });
      return infinityPagesReq.pages[0];
    },
    queryKey: ContinueWatchingKeys.firstNormalPage({
      types: parsedTypes,
      accessToken,
      limit,
    }),
    enabled: Boolean(accessToken),
  });
}

const getContinueWatchingFirstPageFromCache = ({
  config,
  queryParams
}: {
  config: AppConfig,
  queryParams?: ContinueWatchingQueryParams
}) => {
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(config);
  const parsedTypes = queryParams?.types?.length ? queryParams.types : DEFAULT_TYPES;
  return queryClient.getQueryData<ResponseMany<WatchProgressWithResource[]>>(
    ContinueWatchingKeys.firstNormalPage({
      types: parsedTypes,
      accessToken,
      limit,
    }),
  );
};

const useContinueWatchingFirstPage = (queryParams: ContinueWatchingQueryParams) => {
  const client = useClient();
  const config = useConfig();
  const hookParams = getContinueWatchingFirstPageHookParams({
    queryParams,
    client,
    config,
  });
  return useQuery<ResponseMany<WatchProgressWithResource[]>>(hookParams);
};

const useContinueWatchingInfinity = (queryParams: ContinueWatchingQueryParams) => {
  const { accessToken } = getAuthData();
  const client = useClient();
  const config = useConfig();
  const parsedTypes = queryParams.types?.length ? queryParams.types : DEFAULT_TYPES;
  const limit = getLimitFromConfig(config);


  const query = useInfiniteQuery<ResponseMany<WatchProgressWithResource[]>>({
    queryKey: ContinueWatchingKeys.infinityPages({
      types: parsedTypes,
      accessToken,
      limit,
    }),
    queryFn: ({ pageParam = 0 }) =>
      fetchV3({ page: pageParam, accessToken, types: parsedTypes, client, limit }),
    getNextPageParam: getNextPageForInfinityPagination,
    enabled: Boolean(accessToken),
  });

  const parsedData = useMemo(() => {
    return query.data?.pages && reducePaginatedPages(query.data.pages);
  }, [query.data?.pages]);


  return {
    query,
    parsedData
  }

}

const invalidateAllContinueWatchingData = ()=>{
  // Кэш сбрасываем с помощью `resetQueries`, а не `invalidateQueries`
  // если будет `invalidateQueries`, и `infinityPages` загружены например на 100 страниц, то все они будут перезагружены
  // А с помощью `resetQueries` будет загружена только первая страница
  return queryClient.resetQueries({
    queryKey: [ContinueWatchingKeys.PREFIX]
  });
}

export {
  ContinueWatchingKeys,
  getContinueWatchingByResource,
  getContinueWatchingFirstPageFromCache,
  getContinueWatchingFirstPageHookParams,
  invalidateAllContinueWatchingData,
  PREFIX_OF_A_COMPOSITE_KEY,
  useContinueWatchingFirstPage,
  useContinueWatchingInfinity,
};
