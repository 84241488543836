import * as Hls from 'hls.js/dist/hls.min';
import { find } from 'lodash';

import bringingAudioTrackNameToStandard from "~lib/bringingAudioTrackNameToStandard";
import getSecontsFromPercents from "~lib/player/getSecontsFromPercents";
import onError from "~lib/player/shaka/error";
import sortVideoTracks from '~lib/player/sortVideoTracks';
import AudioTrack from '~typings/AudioTrack';
import PlayerType from '~typings/PlayerType';
import VideoTrack from '~typings/VideoTrack';

import { createHTMLVideoElement, Events, prepareTimeShiftUrl, resetHTMLVideoElement } from '../common';
import { IPlayer, PlayerEventSubscribe, PlayerLoadParameters,Props } from '../typings';


// https://github.com/video-dev/hls.js/blob/master/docs/API.md
const dashPlayer = ({ videoContainer, event }: Props): IPlayer => {
  let player: any | null = null;
  let videoElement: HTMLVideoElement | null = null;

  const dispose = () => {
    try {
      if (videoContainer && videoElement) {
        videoContainer.removeChild(videoElement);
        videoElement = null;
      }
    } catch (ignore) {

    }
  };
  const getCurrentTime = (): number => videoElement?.currentTime || 0;
  const getDuration = (): number => videoElement?.duration || 0;

  const handleTrackChanged = () => {
    event.emit(Events.TRACK_CHANGED);
  };
  const handleSetDefaultAudioTrack = (language) => {
    try {
      const audioTracks = getAudioTracks();

      if (audioTracks.length > 1) {
        const requiredAudioTrack = audioTracks.find(({ name }) => (name === language));

        if (requiredAudioTrack && !requiredAudioTrack.isSelected) {
          changeAudioTrack(requiredAudioTrack);
        }
      }
    } catch (ignore) {
      // ignore
    }
  };

  async function reset() {
    if (player) {
      player.destroy();
    }
    resetHTMLVideoElement(videoContainer, videoElement);
    videoElement = null;
  }

  async function load(parameters: PlayerLoadParameters) {
    await reset();

    try {
      const playerUrl = prepareTimeShiftUrl(parameters.stream.url, parameters.timeShiftParams);

      videoElement = createHTMLVideoElement(event);
      videoContainer?.appendChild(videoElement);

      player = new Hls({
        // debug: true,
        enableWorker: false,
        // appendErrorMaxRetry: 3,
      });
      player.attachMedia(videoElement);
      player.on(Hls.Events.MEDIA_ATTACHED, () => {
        player.loadSource(playerUrl);
      });
      player.on(Hls.Events.MANIFEST_PARSED, () => {
        handleSetDefaultAudioTrack(parameters.language);
        player.levelController.manualLevelIndex = -1;
        play();
      });
      videoElement.addEventListener('loadedmetadata', () => {
        if (parameters.percentsWatched) {
          seekTo(getSecontsFromPercents(parameters.percentsWatched, getDuration()));

          play();
        }
      });
      player.on(Hls.Events.LEVEL_SWITCHED, handleTrackChanged);
      player.on(Hls.Events.ERROR, (evnt, data) => {
        if (data && data.fatal) {
          onError(event, data);
        }
      });
    } catch (error) {
      onError(event, error);
    }
  }

  const mute = () => {};
  const on: PlayerEventSubscribe = (target, listener) => event.on(target, listener);
  const removeListener: PlayerEventSubscribe = (target, listener) => {
    event.removeListener(target, listener);
  };

  const play = (): void => {
    videoElement?.play();

    try {
      if (player) {
        // const levels = player.levels;

        // @ts-ignore
        // console.log({ levels });
      }
    } catch (e) {

    }
  };

  const pause = () => {
    videoElement?.pause();
  };

  const seekTo = (timePosition: number) => {
    if (videoElement?.currentTime !== undefined) {
      play();
      videoElement.currentTime = timePosition;
    }
  };

  const stop = () => {
    reset();
  };

  const isAutoQualityEnabled = () => {
    try {
      return (player.levelController.manualLevelIndex === -1);
    } catch (ignore) {
      // ignore
    }

    return true;
  };
  const setAutoQuality = (enabled, index?: number) => {
    try {
      player.levelController.manualLevelIndex = enabled ?
        -1
        :
        (index || 0);
      handleTrackChanged();
    } catch (ignore) {
      // ignore
    }
  };

  const changeAudioTrack = (audioTrack: AudioTrack) => {
    try {
      pause();
      player.audioTrack = audioTrack.index;
      handleTrackChanged();
      setTimeout(() => {
        seekTo(Math.max((getCurrentTime() - 1), 0));
        play();
      }, 300);
    } catch (ignore) {
      // ignore
    }
  };
  const changeVideoTrack = (videoTrack: VideoTrack) => {
    try {
      setAutoQuality(false, videoTrack.index);
      player.currentLevel = videoTrack.index;
      handleTrackChanged();
    } catch (ignore) {
      // ignore
    }
  };
  const getAudioTracks = () => {
    const audioTracks: AudioTrack[] = [];

    try {
      const {
        audioTracks: tracks = [],
        audioTrack: selectedTrackIndex,
      } = player;

      for (let index = 0; index < tracks.length; index += 1) {
        const name = bringingAudioTrackNameToStandard(tracks[index].lang);

        audioTracks.push({
          index,
          name,
          isSelected: (index === selectedTrackIndex),
        });
      }
    } catch (ignore) {
      // ignore
    }

    return audioTracks;
  };
  const getVideoTracks = () => {
    const videoTracks: VideoTrack[] = [];

    try {
      const { levels = [], currentLevel } = player;

      for (let index = 0; index < levels.length; index += 1) {
        videoTracks.push({
          index,
          bitrate: levels[index].bitrate,
          isSelected: (index === currentLevel),
          // isSelected: levels[index].selected,
          name: levels[index].width,
        });
      }
    } catch (ignore) {
      // ignore
    }

    return sortVideoTracks(videoTracks);
  };
  const getSelectedAudioTrack = () => {
    return find(getAudioTracks(), { isSelected: true });
  };
  const getSelectedVideoTrack = () => {
    return find(getVideoTracks(), { isSelected: true });
  };

  const getProperties = () => {
    if (!videoElement) { return null; }

    return {
      videoPlaybackQuality: videoElement?.getVideoPlaybackQuality ?
        videoElement.getVideoPlaybackQuality() : null,
    };
  };

  return {
    type: PlayerType.Hls,

    dispose,
    getCurrentTime,
    getDuration,
    load,
    mute,
    on,
    removeListener,
    pause,
    play,
    reset,
    seekTo,
    stop,

    isAutoQualityEnabled,
    setAutoQuality,

    changeAudioTrack,
    changeVideoTrack,
    getAudioTracks,
    getVideoTracks,
    getSelectedAudioTrack,
    getSelectedVideoTrack,

    getProperties,
  };
};


export default dashPlayer;
