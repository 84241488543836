import * as cn from 'classnames';
import * as React from 'react';

import { CheckboxButton } from '~ui/CheckboxButton';

import * as styles from './styles.module.css';


export type PlayerPopupCheckBoxListItem = Readonly<{
  index: number;
  name: string;
  isChecked: boolean;
}>;

type Props = Readonly<{
  item: PlayerPopupCheckBoxListItem;
  isFocused: boolean;
  onClick: () => void;
}>;


const CheckBoxListItem: React.FC<Props> = (props: Props) => (
  <div
    className={ cn(styles.item, {
      [styles.focused]: props.isFocused,
    }) }
  >
    <div
      className={ styles.chb }
    >
      <CheckboxButton
        isChecked={ props.item.isChecked }
        isFocused={ props.isFocused }
        className={ styles.clickable }
        onClick={ props.onClick }
      />
    </div>
    <div
      className={ styles.chbLabel }
    >
      <div
        className={ cn(styles.clickable, {
          'focusedNavigationNode': props.isFocused,
        }) }
        onClick={ props.onClick }
      >
        { props.item.name }
      </div>
    </div>
  </div>
);


export default React.memo(CheckBoxListItem);
