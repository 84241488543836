import { getEventStatus, getFullDate,getTimePeriod } from '~lib/datetime-utils';
import Event from "~typings/Event";
import { EventStatus } from '~typings/EventStatus';
import Language from '~typings/Language';

export const getEventStatusText = (event: Event | null, locale: Language, intl): string | null => {
  if (!event) return null;
  const status = getEventStatus(event);
  const started = getFullDate(event.start_at, locale, true, intl);
  const timePeriod = getTimePeriod(event.start_at, event.end_at, locale);

  switch(status) {
    case EventStatus.Now:
      return `Сейчас в эфире, ${timePeriod}`;
    case EventStatus.InThePast:
      return `Эфир от ${started}, ${timePeriod}`;
    case EventStatus.InTheFutute:
      return `${started}, ${timePeriod}`; 
  }

  return null;
};
