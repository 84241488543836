import { parse } from 'query-string';


const ANIMATION_TIME = 400;

const getProductIndex = (history): number => {
  try {
    const parsedLocationSearch = parse(
      history.location.search,
      {
        parseBooleans: true,
        parseNumbers: true,
      },
    );
    const index = parseInt(parsedLocationSearch.productIndex as string, 10);

    return (isNaN(index)) ? 0 : index;
  } catch (ignore) {
    return 0;
  }
};


export {
  ANIMATION_TIME,
  getProductIndex,
};
