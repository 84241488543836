import * as React from 'react';

import { AuthLink, AuthLinkType } from '~components/AuthLinks/link';
import { AuthType } from '~components/AuthStepScreen/types';
import { useMenu } from '~components/Provider/Menu';
import useNavigationByKeys from '~hooks/useNavigation';
import NavigationDirection from '~typings/NavigationDirection';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  links: AuthLinkType[];
  onLeave?: (direction) => void;
  onClick?: { [key in AuthLinkType]?: () => void };
  authType?: AuthType;
}>;


export const AuthLinks: React.FC<Props> = (props: Props) => {
  const [selectedAuthType, setSelectedAuthType] = React.useState<AuthLinkType>(props.links[0]);
  const menu = useMenu();
  const isPopupOpened = (menu.isMenuOpened || menu.isPopupOpened);

  React.useEffect(() => {
    setSelectedAuthType(props.links[0]);
  }, [props.links]);

  const handleKeyNavigate = (direction): void => {
    const focusedIndex = props.links.findIndex((link) => link === selectedAuthType);

    if (direction === NavigationDirection.Up) {
      if (props.onLeave && focusedIndex === 0) {
        props.onLeave(direction);
      } else {
        setSelectedAuthType(props.links[Math.max((focusedIndex - 1), 0)]);
      }
    } else if (direction === NavigationDirection.Down) {
      if (props.onLeave && focusedIndex === (props.links.length - 1)) {
        props.onLeave(direction);
      } else {
        setSelectedAuthType(props.links[Math.min((focusedIndex + 1), (props.links.length - 1))]);
      }
    }
  };

  useNavigationByKeys({
    isMounted: (!isPopupOpened && props.isFocused),
    onNavigation: handleKeyNavigate,
  }, [selectedAuthType]);

  const renderAuthLinks = () => (
    props.links.map((link) => (
        <AuthLink
          key={ link }
          isFocused={ (props.isFocused && selectedAuthType === link) }
          link={ link }
          onClick={ props?.onClick?.[link] }
        />
      ))
  );

  return (
    <div className={ styles.wrapper }>
      { renderAuthLinks() }
    </div>
  );
};


AuthLinks.displayName = 'AuthLinks';


export default React.memo(AuthLinks);
