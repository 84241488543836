import Focuser, { FocuserKeyHandler } from "@focuser";
import focuser from "@focuser";
import { useFocuserContext } from "@focuser/context";
import React, { useEffect, useState } from "react";

import { MemoizedButton } from "./buttons/button0";
import {
  KeyBoardLayout,
  KeyBoardType,
  KeyIcon,
  KeyType,
  type0_dig,
  type0_dig_shortcut,
  type0_en,
  type0_en_shortcut,
  type0_ru,
  type0_ru_shortcut
} from "./keyboardLayouts/type0";
import { KeyBoardKey } from "./keyboardLayouts/type0";
import * as css from './styles.module.css';

const keyBoardButtons: { [key in KeyBoardType]?: KeyBoardKey[][] } = {
  [KeyBoardType.type0 + KeyBoardLayout.Ru]: type0_ru,
  [KeyBoardType.type0 + KeyBoardLayout.En]: type0_en,
  [KeyBoardType.type0 + KeyBoardLayout.Digits]: type0_dig,
}

const keyBoardShortCut: { [key in KeyBoardType]?: KeyBoardKey[][] } = {
  [KeyBoardType.type0 + KeyBoardLayout.Ru]: type0_ru_shortcut,
  [KeyBoardType.type0 + KeyBoardLayout.En]: type0_en_shortcut,
  [KeyBoardType.type0 + KeyBoardLayout.Digits]: type0_dig_shortcut
}

interface Props {
  keyBoardType: KeyBoardType
  keyBoardLayout: KeyBoardLayout
  query: string
  setQuery: (newQuery: string) => void;
  handleSearchButtonClick: () => void
}


export function KeyBoard({
                           keyBoardType,
                           keyBoardLayout,
                           query,
                           setQuery,
                           handleSearchButtonClick
                         }: Props) {
  // eslint-disable-next-line max-len
  const [keyBoard, setKeyBoard] = useState<string>(keyBoardType + keyBoardLayout || KeyBoardType.type0 + KeyBoardLayout.Ru);
  const buttons = keyBoardButtons[keyBoard] as KeyBoardKey[][];
  const shortCut = keyBoardShortCut[keyBoard] as KeyBoardKey[][];

  const { isFocused } = useFocuserContext();


  // TODO refactor
  function handleKeyboardNativeEvent(event: any) {
    if (!isFocused) {
      return
    }
    else {
      // реагируем на нажатие только при фокусе клавы
      if (shortCut.includes(event.key.toUpperCase())) {
        setQuery(query + event.key)
      }
    }
  }


  useEffect(() => {
    // подключаем слушатель на кнопки, один слушатель на все клавиши
    window.addEventListener('keydown', handleKeyboardNativeEvent)
    return () => {
      window.removeEventListener('keydown', handleKeyboardNativeEvent)
    }
  }, [keyBoard, query, isFocused])


  const [navigation, setNavigation] = useState<{
    line: number,
    column: number
  }>({
    line: 0,
    column: 0
  });


  function handleButtonClick(button:KeyBoardKey ) {
    if (button.type ===  KeyType.Char) {
      setQuery(query + button.id.toLowerCase())
    }
    else if (button.type === KeyType.Icon) {
      switch (button.id) {
        case KeyIcon.BackSpace:
          return setQuery(query.slice(0, -1))
        case KeyIcon.Space:
          return setQuery(query + ' ')
        case KeyIcon.Search:
          if (handleSearchButtonClick) {
            handleSearchButtonClick()
          }
          return
        case KeyIcon.SwitchLayout:
          setKeyBoard(keyBoardType + button.switchTo)
          return
        default:
          return
      }
    }
  }


const handleKeyRight: FocuserKeyHandler = (event) => {
  if(navigation.column < buttons[navigation.line].length - 1) {
    setNavigation({
      ...navigation,
      column: navigation.column + 1
    });
    event.stop();
  }
};

const handleKeyLeft: FocuserKeyHandler = (event) => {
  if(navigation.column > 0) {
    setNavigation({
      ...navigation,
      column: navigation.column - 1
    });
    event.stop();
  }
};

const handleKeyUp: FocuserKeyHandler = (event) => {
  if (navigation.line > 0) {
    const nextLine = buttons[navigation.line - 1];
    if (navigation.column > nextLine.length - 1) {
      setNavigation({
        ...navigation,
        column: nextLine.length - 1,
        line: navigation.line - 1,
      });
    } else {
      setNavigation({
        ...navigation,
        line: navigation.line - 1,
      });
    }
    event.stop();
  }
};

const handleKeyDown: FocuserKeyHandler = (event) => {
  if (navigation.line < buttons.length - 1) {
    const nextLine = buttons[navigation.line + 1];
    if (navigation.column > nextLine.length - 1) {
      setNavigation({
        ...navigation,
        column: nextLine.length - 1,
        line: navigation.line + 1,
      });
    } else {
      setNavigation({
        ...navigation,
        line: navigation.line + 1,
      });
    }
    event.stop();
  }
};




  return (
    <Focuser.FocusableBlock
      isFocused
      onKeyUp={ handleKeyUp }
      onKeyDown={ handleKeyDown }
      onKeyLeft={ handleKeyLeft }
      onKeyRight={ handleKeyRight }
      noNeedForceFocus
    >
      {buttons.map((line, lineIdx) => {
        return (
          <Focuser.FocusableBlock
            key={ keyBoard + lineIdx }
            isFocused={ navigation.line === lineIdx }
            isPointerUpAvailable={ navigation.line > 0 }
            isPointerDownAvailable={ navigation.line < buttons.length - 1 }
            isPointerLeftAvailable={ navigation.column > 0 }
            isPointerRightAvailable={ navigation.column < line.length - 1 }
            noNeedForceFocus
          >
            <div className={ css.keyBoardRow }>
              {line.map((button, buttonIndex) => {
                return (
                  <focuser.FocusableBlock
                    key={ keyBoard + buttonIndex }
                    isFocused={ navigation.column === buttonIndex }
                    onForceFocus={ () => setNavigation({ line: lineIdx, column: buttonIndex }) }
                    emitForceFocusOnHover
                    isLastBlock
                    onClick={ (ev) => {
                      handleButtonClick(button);
                      ev.stop();
                    } }
                  >
                    {({ isFocused }) => (
                      <MemoizedButton
                        key={ keyBoard + buttonIndex }
                        buttonIsFocused={ isFocused }
                        button={ button }
                        keyBoard={ keyBoard }
                      />
                    )}
                  </focuser.FocusableBlock>
                );
              })}
            </div>
          </Focuser.FocusableBlock>
        );
      })}
    </Focuser.FocusableBlock>
  );
}
