import { QueryKey,useQuery } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import Operator from '~typings/Operator';
import ResponseSingle from '~typings/ResponseSingle';

const PREFIX_OF_A_COMPOSITE_KEY = 'payments_status';

const getKey = (paymentID?: string): QueryKey => [PREFIX_OF_A_COMPOSITE_KEY, paymentID];


const fetch = async (client, accessToken, paymentID) => {
  if (!paymentID) { return undefined; }

  return await client.get(
    '/v1/payments',
    {
      access_token: accessToken,
      'filter[id_in]': paymentID,
    }
  );
};


const usePaymentStatus = (paymentID?: string) => {
  const queryKey = getKey(paymentID);
  const { accessToken } = getAuthData();

  const client = useClient();

  return useQuery<ResponseSingle<Operator[]>>({
    queryKey,
    queryFn: () => fetch(client, accessToken, paymentID),
    enabled: !!(accessToken && paymentID),
    refetchInterval: 5000,
    staleTime: 0,
    cacheTime: 0,
    notifyOnChangeProps: ['data', 'isLoading', 'error'],
  });
};


export {
  getKey,
  PREFIX_OF_A_COMPOSITE_KEY,
};

export default usePaymentStatus;
