import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Episodes from '~components/MediaItemPage/Series/episodes';
import Seasons from '~components/MediaItemPage/Series/seasons';
import { useMenu } from '~components/Provider/Menu';
import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { setPlayingEpisode } from '~components/ScenePlayer/Player/controls/NextEpisode/helpers';
import usePointerNavigation from '~hooks/usePointerNavigation/usePointerNavigation';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import EMPTY_ARRAY from "~lib/constants/emptyArray";
import { getEpisodeIndexByID, getSeasonByEpisodeID, getSeasonIndexByID } from '~lib/Series';
import Episode from '~typings/Episode';
import Season from '~typings/Season';

import * as styles from './styles.module.css';


enum Focus {
  Seasons = 0,
  Episodes = 1
}

type Props = Readonly<{
  currentEpisode: Episode;
  onClose: () => void;
}>;


const EpisodesListPopup: React.FC<Props> = (props: Props) => {
  const { isPopupOpened } = useMenu()
  const history = useHistory();
  const playerState = usePlayerState();
  const matchPage = useRouteMatch('/:page/:id');
  const { series: { seasons } = {} } = props.currentEpisode;
  const { setItem } = usePlayerStateActions();
  const currentSeason = getSeasonByEpisodeID(seasons, props.currentEpisode.id);
  const currentSeasonIndex = getSeasonIndexByID(seasons, currentSeason?.id);
  const currentEpisodeIndex = getEpisodeIndexByID(currentSeason?.episodes, props.currentEpisode.id);
  const [selectedSeasonIndex, setSelectedSeasonIndex] = React.useState<number>(currentSeasonIndex);
  const [selectedEpisodeIndex, setSelectedEpisodeIndex] = React.useState<number>(currentEpisodeIndex);

  const handleSelectEpisode = (episode) => {
    if (playerState.item?.id !== episode.id) {
      const nextEpisode = (!episode.series) ?
        {
          ...episode,
          series: props.currentEpisode.series,
        }
        :
        episode;

      setPlayingEpisode(nextEpisode, matchPage, history, setItem);
    }
  };

  const navigationItems = React.useMemo(
    () => (seasons) ? [{
      maxIndex: seasons.length - 1,
      focusedIndex: selectedSeasonIndex
    }, {
      isFocused: true,
      maxIndex: seasons[selectedSeasonIndex].episodes.length - 1,
      focusedIndex: selectedEpisodeIndex
    }] : EMPTY_ARRAY,
    [seasons?.length, selectedSeasonIndex, selectedEpisodeIndex],
  );

  const navigationState = useSpatialNavigation({
    navigationItems: navigationItems,
    allowNavigation: !isPopupOpened,
  });
  usePointerNavigation({
    allowNavigation: !isPopupOpened,
    itemsCount: navigationItems.length,
    focusOn: navigationState.focusOn,
  });

  if (!seasons) {
    return null;
  }

  const seasonChangeHandler = (season: Season) => {
    const seasonIdx = getSeasonIndexByID(seasons, season.id);
    setSelectedSeasonIndex(seasonIdx);
    setSelectedEpisodeIndex(0);
  };

  return (
    <div className={ styles.wrapper }>
      <Seasons
        isFocused={ !isPopupOpened && navigationState.focusOn === Focus.Seasons  }
        seasons={ seasons }
        selectedIndex={ selectedSeasonIndex }
        focusedIndex={ navigationState.focusedIndex[Focus.Seasons] }
        onClick={ seasonChangeHandler }
      />
      <Episodes
        isFocused={ !isPopupOpened && navigationState.focusOn === Focus.Episodes }
        episodes={ seasons[selectedSeasonIndex].episodes }
        focusedIndex={ navigationState.focusedIndex[Focus.Episodes] }
        onClick={ handleSelectEpisode }
      />
    </div>
  );
};


export default EpisodesListPopup;
