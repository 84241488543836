// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".back--UdpjX {\n  position: absolute;\n  bottom: 0.234375vw;\n  left: 0.234375vw;\n  right: 0.234375vw;\n  -webkit-border-radius: 0.15625vw;\n          border-radius: 0.15625vw;\n  background-color: rgba(23, 23, 27, 0.7);\n}\n\n.line--Lo7Ef {\n  position: relative;\n  height: 0.3125vw;\n  -webkit-border-radius: 0.15625vw;\n          border-radius: 0.15625vw;\n  opacity: 0.7;\n  background-color: rgba(253, 216, 53, 1);\n  min-width: 1%;\n}\n", "",{"version":3,"sources":["webpack://./src/app/ui/ProgressBar/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,gCAAwB;UAAxB,wBAAwB;EACxB,uCAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gCAAwB;UAAxB,wBAAwB;EACxB,YAAY;EACZ,uCAAwB;EACxB,aAAa;AACf","sourcesContent":[".back {\n  position: absolute;\n  bottom: 0.234375vw;\n  left: 0.234375vw;\n  right: 0.234375vw;\n  border-radius: 0.15625vw;\n  background-color: $b_70;\n}\n\n.line {\n  position: relative;\n  height: 0.3125vw;\n  border-radius: 0.15625vw;\n  opacity: 0.7;\n  background-color: $ac_00;\n  min-width: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
export var back = "back--UdpjX";
export var line = "line--Lo7Ef";
export default ___CSS_LOADER_EXPORT___;
