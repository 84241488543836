import focuser from "@focuser";
import React from "react";
import { InjectedIntl, injectIntl } from "react-intl";

import { PrimaryButtonFocuser } from "~app/ui-kit/PrimaryButton";
import { Typography } from "~app/ui-kit/Typography";
import { usePageManagerPointersBlocker } from "~components/PageManager";
import {
  NEW_PIN_CODE_FLOW_PARENTAL_CONTROL_SUCCESS_HEADER,
  NEW_PIN_CODE_FLOW_PIN_CODE_SUCCESS_HEADER,
  NEW_PIN_CODE_FLOW_SUCCESS_BUTTON,
  NEW_PIN_CODE_FLOW_SUCCESS_MESSAGE
} from "~localization";
import { Ok } from "~newapp/ui-kit/Icons/For_message/Ok";

import * as css from './styles.modules.css'


type Props = {
  from: 'parental' | 'pin-code'
  intl: InjectedIntl
  handleEndFlow: () => void
}

const SuccessWindow = ({ from, intl, handleEndFlow }: Props) => {
  usePageManagerPointersBlocker()

  return (
    <focuser.FocusableBlock
      isFocused
      className={ css.mainWrapper }
      noNeedForceFocus
      returnButtonType={ null }
    >
      <div className={ css.successWrapper }>
        <Ok side={ 13.88888888888889 } />
        <Typography className={ css.successHeader } variant={ 'h5_medium' }>
          { from === 'parental'
            ? intl.formatMessage({ id: NEW_PIN_CODE_FLOW_PARENTAL_CONTROL_SUCCESS_HEADER })
            : intl.formatMessage({ id: NEW_PIN_CODE_FLOW_PIN_CODE_SUCCESS_HEADER })
          }
        </Typography>
        <Typography variant={ 'h5_medium' } className={ css.text }>
          { intl.formatMessage({ id: NEW_PIN_CODE_FLOW_SUCCESS_MESSAGE }) }
        </Typography>
        <PrimaryButtonFocuser
          focuserClassName={ css.buttonWrapper }
          noNeedForceFocus
          isFocused={ true }
          onClick={ handleEndFlow }>
          <div>{ intl.formatMessage({ id: NEW_PIN_CODE_FLOW_SUCCESS_BUTTON }) }</div>
        </PrimaryButtonFocuser>
      </div>
    </focuser.FocusableBlock>
  )
}

export const SuccessWindow$ = injectIntl(SuccessWindow)