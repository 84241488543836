import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import TrialDescription from '~components/ProductItem/TrialDescription';
import ConflictedSubscriptions from '~components/ProductPlanPage/ConflictedSubsriptions';
import PaymentInstruction from '~components/ProductPlanPage/PaymentInstruction';
import PaymentMethodRadioButtons from '~components/ProductPlanPage/PaymentMethodRadioButtons';
import { matchPaymentMethodFromProduct } from '~components/ProductPlanPage/SelectPlan.helpers';
import * as productsStyles from '~components/ProductPlanPage/styles.module.css';
import useSubscriptionsPlansOffer from '~hooks/fetch/useSubscriptions/useSubscriptionsPlansOffer';
import useSubscriptionsProducts from '~hooks/fetch/useSubscriptions/useSubscriptionsProducts';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import { ENABLE, WHAT_IS_AN_SUBSCRIPTION } from '~localization';
import EverGreenPhase from '~typings/EvergreenPhase';
import NavigationDirection from '~typings/NavigationDirection';
import PaymentMethod from '~typings/PaymentMethod';
import Plan from '~typings/Plan';
import Product from '~typings/Product';
import Subscription from '~typings/Subscription';
import TrialPhase from '~typings/TrialPhase';
import UserPaymentMethod from '~typings/UserPaymentMethod';
import Button from '~ui/button';

import PlansRadioButtons from './PlansRadioButtons';
import * as styles from './styles.module.css';


enum FocusOn {
  RadioButtons = 0,
  MethodButtons = 1,
  Buttons = 2,
}

type Props = {
  products: Product[] | null;
  subscriptions?: Subscription[] | undefined;
  resourceID: string;
  focusOnMenu: boolean;
  onMenu: (arg?: boolean) => void;
  url: string | null | undefined;
  intl: InjectedIntl;
};

const ProductPlan: React.FC<Props> = ({ products, url, focusOnMenu, onMenu, intl }) => {
  const { productID } = useParams<{ productID: string }>();
  const product = (products || []).find(p => p.id === productID)
  const [selectedPlan, setSelectedPlan] = React.useState<Plan | null>(null);
  const history = useHistory();
  const { data: offer } = useSubscriptionsPlansOffer(selectedPlan?.id);

  const hasAnyMethod = product?.plans?.some(
    (plan) => plan.phases?.length
  ) ?? true;
  const { data: conflictedSubscriptions } = useSubscriptionsProducts(productID);

  const [selectedMethod, setSelectedMethod] = React.useState<UserPaymentMethod>()
  const navigationItems = React.useRef([
    {}, // RadioButtons
    { maxIndex: 0 }, // MethodButtons
    { maxIndex: 1 }, // Buttons
  ]);
  React.useEffect(() => {
    if (product) {
      setSelectedPlan(product.plans[0])
    }
  }, [product])

  React.useEffect(() => {
    if (!hasAnyMethod) {
      navigationItems.current = [{ maxIndex: 0 }]; // Back Button
    }
    if (product && product.plans.length !== 0) {
      navigationItems.current[FocusOn.RadioButtons] = { maxIndex: product.plans.length - 1 };
      if (selectedPlan) {
        navigationItems.current[FocusOn.MethodButtons]
          = { maxIndex: product.plans.length - 1};
      }  
    }
  }, [product?.plans.length, productID, hasAnyMethod, selectedPlan]);

  const navigationState = useSpatialNavigation({
    allowNavigation: !focusOnMenu && (product?.plans.length || 0) > 0,
    navigationItems: navigationItems.current,
    onLeave: (direction) => {
      if (direction === NavigationDirection.Up) {
        onMenu();
      }
    }
  });

  if (!product || product?.plans.length === 0) { return null; }

  const handleSelectPlan = (planID: string) => {
    const plan = product.plans.find(p => p.id === planID);
    if (plan) {
      setSelectedPlan(plan);
    }
  };

  const handleClick = () => {
    if (!url || !selectedPlan) { return; }
    switch (selectedMethod?.payment_method_type) {
      case PaymentMethod.Operator:
        history.replace(`${url}/subscriptions/${selectedPlan.id}/invoice`);
        break
      case PaymentMethod.NewCard:
      case PaymentMethod.a1NewCard:
      case PaymentMethod.Existing_card:
        history.replace(`${url}/subscriptions/${selectedPlan.id}/proceed_card_payment`);
        break
      default:
        break;
    }
  };

  const handleGoToProduct = () => {
    history.push(`/products/${product.id}`);
  };

  const trialPhase = (offer?.data.following_phases || [])
    .find(x => x.phase.type === 'trial')?.phase as TrialPhase;
  const evergreenPhase = (offer?.data.following_phases || [])
    .find(x => x.phase.type === 'evergreen')?.phase as EverGreenPhase;
    
  return (
    <>
      {
        hasAnyMethod ? (
          <>
            <PlansRadioButtons
              isFocused={ !focusOnMenu && (navigationState.focusOn === FocusOn.RadioButtons) }
              focusIndex={ navigationState.focusedIndex[FocusOn.RadioButtons] }
              plans={ product.plans }
              selectedPlan={ selectedPlan }
              onClick={ handleSelectPlan }
            />
            <PaymentMethodRadioButtons
              selectedMethod={ selectedMethod }
              setSelectedMethod={ setSelectedMethod }
              paymentMethods={ selectedPlan ? matchPaymentMethodFromProduct(product?.plans)[selectedPlan.id] : null }
              isFocused={ !focusOnMenu && (navigationState.focusOn === FocusOn.MethodButtons) }
              focusIndex={ navigationState.focusedIndex[FocusOn.MethodButtons] }
              intl={ intl }
            />
            <div className={ productsStyles.buttonWrapper }>
              <Button
                isFocused={ !focusOnMenu && navigationState.focusOn === FocusOn.Buttons
                  && (navigationState.focusedIndex[FocusOn.Buttons] === 0) }
                onClick={ handleClick }
                className={ cn(productsStyles.button, {
                  [styles.isFocused]: !focusOnMenu && navigationState.focusOn === FocusOn.Buttons
                    && (navigationState.focusedIndex[FocusOn.Buttons] === 0),
                }) }
              >
                {
                  intl.formatMessage({ id: ENABLE })
                }
              </Button>
              <Button
                isFocused={ !focusOnMenu && navigationState.focusOn === FocusOn.Buttons
                && (navigationState.focusedIndex[FocusOn.Buttons] === 1)  }
                onClick={ handleGoToProduct }
                className={ cn(productsStyles.button, {
                  [styles.isFocused]: !focusOnMenu && navigationState.focusOn === FocusOn.Buttons
                    && (navigationState.focusedIndex[FocusOn.Buttons] === 1),
                }) }
              >
                {
                  intl.formatMessage({ id: WHAT_IS_AN_SUBSCRIPTION })
                }
              </Button>
            </div>
            <div className={ cn(productsStyles.buttonWrapper, styles.description) }>
              <TrialDescription
                trialPhase={ trialPhase }
                evergreenPhase={ evergreenPhase }
                intl={ intl }
              />
            </div>
            <div className={ cn(productsStyles.buttonWrapper, styles.description) }>
              <ConflictedSubscriptions
                conflictedProducts={ conflictedSubscriptions?.data }
                intl={ intl }
              />
            </div>
          </>
        ) : (
          <PaymentInstruction
            isFocused={ (!focusOnMenu && navigationState.focusOn === FocusOn.RadioButtons) }
          />
        )
      }
    </>
  );
};


export default React.memo(injectIntl(ProductPlan));
