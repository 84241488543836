import { FocuserKeyHandler } from '@focuser';
import React, { useCallback } from 'react';

import { useMenuOpener } from '~hooks/useMenuOpener';
import { CardCollectionContent } from '~newapp/components/CardCollectionContent';
import Page from '~typings/Page';

import { useSCCPBlocks } from './SingleCardCollectionPage.hooks';

interface Props {
  page: Page;
}

const SingleCardCollectionPage$: React.FC<Props> = ({ page }) => {
  const { cardCollectionBlock, bigBannerBlock, middleBannerBlock, filters } = useSCCPBlocks(page);
  const { openMenu } = useMenuOpener();

  const onKeyReturn: FocuserKeyHandler = useCallback((e) => {
    e.stop();
    openMenu();
  }, [openMenu]);

  return (
    <CardCollectionContent
      cardCollection={ cardCollectionBlock }
      bigBannerBlock={ bigBannerBlock }
      middleBannerBlock={ middleBannerBlock }
      filters={ filters }
      onKeyReturn={ onKeyReturn }
      returnButtonType='menu'
    />
  );
};

/**
 * Компонент, который отображает страницу с одной коллекцией карточек
 */
export const SingleCardCollectionPage = React.memo(SingleCardCollectionPage$);
