import React from 'react';

import { verticalImageHeightVh, verticalImageWidthVw } from '~app/variables';
import { useAnimationContext } from '~components/Provider/Animation';
import Card from '~typings/Card';
import { CardImageType } from '~typings/Image';
import { CardConfig } from '~typings/ScreenCardConfigs';
import { VerticalCard } from '~ui/CardV2/Vertical/VerticalCard';

import { useCardImage, useGetFieldsFromCard } from '../hooks';

interface PropsTS {
  card: Card;
  cardConfig: CardConfig;
  isFocused: boolean;
}

export function VerticalCardWrapper(props: PropsTS) {
  const {
    isFocused,
    cardConfig,
    card
  } = props;

  const {
    catalogHeaderExist,
    showBottomTextBlock,
    contentProviderIconUrl,
    label,
  } = useGetFieldsFromCard(card, cardConfig);


  const imageSource = useCardImage({
    cardImages: card.images,
    isCardInappropriate: card.inappropriate,
    cardImageType:  CardImageType.Card2x3,
    widthVW: verticalImageWidthVw,
    heightVH: verticalImageHeightVh,
  });

  const { isAnimationEnabled } = useAnimationContext();

  return (
    <VerticalCard
      isFocused={ isFocused }
      showCatalogHeader={ catalogHeaderExist }
      contentProviderIconUrl={ contentProviderIconUrl }
      showBottomTextBlock={ showBottomTextBlock }
      name={ card.name }
      additionalName={ card.additional_name }
      imageUrl={ imageSource }
      label={ label }
      certificationTag={ card.certification_tags?.[0] }
      isAnimationEnabled={ isAnimationEnabled }
    />
  );
}
