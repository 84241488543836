/* eslint-disable max-len */
import * as cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';

type Props = {
  isFocused: boolean;
  isChecked?: boolean;
  className?: string;
};

export const Checkbox: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={ cn(styles.checkbox, props.className, {
        [styles.focused]: props.isFocused,
        [styles.checked]: props.isChecked,
      }) }
    >
      {props.isChecked ? <Checked /> : <NotChecked />}
    </div>
  );
};

const NotChecked = () => (
  <svg
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
      fill="currentColor"
    />
  </svg>
);

const Checked = () => (
  <svg
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM22.4334 9.53484C21.7461 8.86783 20.6057 8.8791 19.9335 9.55955L13.9524 17.2611L10.0436 13.4707C9.36481 12.8204 8.25065 12.8238 7.57635 13.4782L7.48683 13.5651C6.83539 14.1973 6.83806 15.2034 7.49285 15.8325L13.3235 21.4901C13.6666 21.8197 14.232 21.8141 14.5676 21.4777L22.5324 11.8549C23.1632 11.2226 23.1548 10.2349 22.5132 9.61223L22.4334 9.53484Z"
      fill="currentColor"
    />
  </svg>
);
