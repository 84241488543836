import classnames from 'classnames';
import React from 'react';

import { useTransitionStyleChanger, useVirtualChilds, useVirtualOffsetChanger } from './hooks';
import * as styles from './SliderOffsetable.module.css';

export type SliderOffsetableVirtualProps = {

  /**
   * Индекс сфокусированного элемента
   */
  focusedIndex: number;
  /**
   * Сколько должна длится анимация перелистывания
   * @default 200
   */
  animationDurationInMS?: number;

  /**
   * Если какое-то действие изменяет ширину слайдов, то через этот массив можно оповестить об изменениях
   * Подробнее смотреть в Storybook
   */
  updates?: unknown[];

  /**
   * Дополнительный класс для обертки слайдера
   */
  wrapperClassName?: string;

  /**
   * Дополнительный класс для слайдера
   */
  sliderClassName?: string;

  /**
   * Полное количество дочерних элементов
   */
  childsCount: number;

  /**
   * Функция для отрисовки дочерних элементов
   * idx => индекс дочернего элемента
   * Возвращать нужно сам дочерний элемент
   */
  renderChild: (idx: number)=> React.ReactNode;

  /**
   * Ширина одного элемента
   * Если между элементами есть пробелы, то сюда нужно передавать ширину с учетом пробелов
   *
   * Указывать в VW
   *
   * для карточек использовать утилитку getSliderPropsForCards
   */
  itemWidth: number;

  /**
   * Максимальное количество элементов, которые будут в DOM
   */
  maxInDOM: number;

  /**
   * Как часто пересчитывать виртуальные элементы слайдера
   * Чем больше значение, тем меньше будет пересчитывание, и больше будет буффер
   *
   * Дефолтное значение `1`
   *
   *
   * Например, если maxInDOM = 10, а recalcStep = 2, то в завимости от сфокусированного элемента,
   * будет следующее кол-во карточек в DOM:
   *
   * focusedIdx = 0, или focusedIdx = 1 => Показаываем карточки с индексом от 0 до 10
   *
   * focusedIdx = 2, или focusedIdx = 3 => Показаываем карточки с индексом от 2 до 12
   *
   *
   *
   * Другой пример:
   *
   * если maxInDOM = 10, а recalcStep = 5, то:
   *
   * focusedIdx = 0,1,2,3,4 => Показаываем карточки с индексом от 0 до 10
   *
   * focusedIdx = 5,6,7,8,9 => Показаываем карточки с индексом от 5 до 15
   *
   * focusedIdx = 10,11,12,13,14 => Показаываем карточки с индексом от 10 до 20
   *
   */
  recalcStep?: number;
};


export const SliderOffsetableVirtual: React.FC<SliderOffsetableVirtualProps> = React.memo(({
  animationDurationInMS = 200,
  focusedIndex,
  childsCount,
  renderChild,
  updates,
  itemWidth,
  maxInDOM,
  recalcStep = 1,
  wrapperClassName,
  sliderClassName,
}) => {

  const sliderRef = React.useRef<HTMLDivElement>(null);
  const sliderWrapperRef = React.useRef<HTMLDivElement>(null);

  const {
    virtualChildren,
    virtualFocus,
    widthMultiplier,
  } = useVirtualChilds({
    focusedIndex,
    childsCount,
    renderChild,
    maxInDOM,
    recalcStep,
  });


  useVirtualOffsetChanger({
    focusedIndex: virtualFocus,
    sliderRef,
    sliderWrapperRef,
    updates,
    realFocusedIndex: focusedIndex,
  });

  useTransitionStyleChanger({
    animationDurationInMS,
    sliderRef,
  });

  return (
    <div
      className={ classnames(styles.sliderWrapper, wrapperClassName) }
      ref={ sliderWrapperRef }
    >
      <div
        ref={ sliderRef }
        className={ classnames(styles.slider, sliderClassName) }
      >
        <div style={ { flexShrink: 0, width: `${widthMultiplier * itemWidth}vw` } }></div>
        {virtualChildren}
      </div>
    </div>
  );
});

SliderOffsetableVirtual.displayName = 'SliderOffsetable';
