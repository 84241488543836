import * as React from 'react';
import { animated } from 'react-spring';

import Slider from '~components/Slider';
import { DataItem } from '~typings/DataItem';
import Episode from '~typings/Episode';
import ImageType from '~typings/ImageType';

import useEpisodesHelpers from './episodes.helpers';
import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  episodes: Episode[];
  focusedIndex: number;
  onClick: (episode: Episode) => void;
}>;

/**
 * @deprecated, это старые эпизоды, сейчас они заменены.
 * Для плеера нужно будет изучить, и создать новые
 */
const Episodes: React.FC<Props> = (props: Props): JSX.Element => {
  const handleClick = (item: DataItem): void => {
    props.onClick(item as Episode);
  };
  const {
    episodes,
    springProps,
    focusedIndex,
  } = useEpisodesHelpers({
    episodes: props.episodes,
    focusedIndex: props.focusedIndex,
  });

  return (
    <animated.div
      style={ springProps }
      className={ styles.episodes }
    >
      <Slider
        withName
        withGenres
        dimension={ ImageType.BANNER }
        items={ ({
          data: episodes,
          meta: {
            status: 200,
            pagination: {
              offset: 0,
              total: episodes.length,
              limit: episodes.length,
              count: episodes.length,
            },
          },
        }) }
        isFocusedBlock={ props.isFocused }
        focusedIndex={ focusedIndex }
        onClick={ handleClick }
      />
    </animated.div>
  );
};


export default React.memo(Episodes);
