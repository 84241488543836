import ImageType from '~typings/ImageType';

export enum CardImageType {
  Card16x9 = 'card_16_9',
  Card4x3 = 'card_4_3',
  Card2x3 = 'card_2_3',
  Card1x1 = 'card_1_1',
}

interface Image {
  object: 'image';
  id: string;
  original_height: number;
  original_width: number;
  update_interval?: null | number;
  url_template: string;
  type: ImageType | CardImageType;
}


export default Image;
