import { PlayerAccessReason } from '~components/Provider/Player/component.helpers';
import { PickByExpand, PickOnlyPrimitive } from '~lib/type-utils';

import Purchase from './Purchase';
import Subscription from './Subscription';

export const defaultExpand =
  'reason_object.rent_plan.duration,reason_object.rent_plan.access_period';

export type PlayerAccess = {
  allowed: boolean;
  object: 'access';
  expires_at: string | null;
  reason: PlayerAccessReason | null;
  reason_object: Purchase | Subscription | null;
};

export type PrimitivePlayerAccess = PickOnlyPrimitive<PlayerAccess>;

export type ExpandedPlayerAccess = PickByExpand<PlayerAccess, typeof defaultExpand>;
export type ExpandedReasonObj = ExpandedPlayerAccess['reason_object'];
