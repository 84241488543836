import * as cn from 'classnames';
import * as React from 'react';

import { CardResourceType, Modification } from '~typings/Card';
import ItemObject from '~typings/ItemObject';
import { IconBase, IconMediaItemDetails, IconMediaItemNews, IconMediaItemPlay } from '~ui/Icon';
import CatchupIcon from '~ui/Icon/icons/Catchup';
import FutureIcon from '~ui/Icon/icons/FutureEvent';
import * as iconStyles from '~ui/Icon/styles.module.css';


const RenderIcon = (props: {
  resourceType: CardResourceType | undefined;
  modification: Modification | null | undefined;
  isFocused: boolean;
  className?: string;
}) => {
  let Icon: any = null;

  switch (props.resourceType) {
    case ItemObject.ProgramEvent: {
      switch (props.modification) {
        case Modification.CURRENT: {
          if (props.isFocused) {
            Icon = IconMediaItemPlay;
          }
          break;
        }
        case Modification.PAST: {
          if (props.isFocused) {
            Icon = IconMediaItemDetails;
          }

          break;
        }
        case Modification.FUTURE: {
          Icon = FutureIcon;
          break;
        }
        case Modification.CATCHUP: {
          Icon = CatchupIcon;
          break;
        }
        default:
          break;
      }

      break;
    }
    case ItemObject.News: {
      if (props.isFocused) {
        Icon = IconMediaItemNews;
      }
      break;
    }
    case ItemObject.Channel:
    // case ItemObject.Episode:
    case ItemObject.ProgramEvent: {
      if (props.isFocused) {
        Icon = IconMediaItemPlay;
      }
      break;
    }
    default: {
      if (props.isFocused) {
        Icon = IconMediaItemDetails;
      }
      break;
    }
  }

  if (Icon) {
    return (<div className={ props.className }>
      <IconBase Icon={ Icon } classNames={ {
        icon: iconStyles.icon,
        wrapper: cn({
          [iconStyles.primaryRound]: props.isFocused,
          [iconStyles.active]: props.resourceType === ItemObject.ProgramEvent
            && props.modification !== Modification.CURRENT
            && props.modification !== Modification.PAST
            && !props.isFocused
        }),
      } } />
    </div>);
  }

  return null;
};


export default RenderIcon;
