import { FocuserKeyHandler, useFocuserThrottledHandler } from "@focuser";
import { useState } from "react";



export enum ResourceType {
  Movies = 'movies',
  Series = 'series',
  AudioShows = 'audio_shows',
  ProgramEvents = 'program_events',
}

type UseActorPageNavigationProps = {
  elementsCountInSlider: number;
}

/**
 * Навигация для слайдеров на странице Actor Page
 *
 * Используется во всех слайдерах на странице, 
 * в хук нужно прокинуть длинну массива с данными.
 * Например: dataMoviesAndSeries.length или dataAudioShows.length
 * 
 */
export const useActorPageSliderNavigation = ({
  elementsCountInSlider
}: UseActorPageNavigationProps) => {
  const [focusedIndex, setFocusedIndex] = useState<number>(0);

  const onLeft: FocuserKeyHandler = (evt) => {
    if (focusedIndex > 0) {
      evt.stop();
      setTimeout(() => setFocusedIndex(focusedIndex - 1), 0);
    }
  };
  const onRight: FocuserKeyHandler = (evt) => {
    if (focusedIndex < elementsCountInSlider - 1) {
      evt.stop();
      setTimeout(() => setFocusedIndex(focusedIndex + 1), 0);
    }
  };

  const onKeyLeftThrottled = useFocuserThrottledHandler(onLeft);
  const onKeyRightThrottled = useFocuserThrottledHandler(onRight);


  return {
    focusedIndex,
    setFocusedIndex,
    onKeyLeftThrottled,
    onKeyRightThrottled,
  }
}