// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contentWrapper--i5n1S {\r\n  margin-top: 0.8vw;\r\n}\r\n\r\n.itemsContainer--giWkH {\r\n  margin-top: 2.344vw;\r\n}\r\n\r\n.title--fhZZk {\r\n  color: rgba(223, 223, 228, 1);\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/ViewStatistics/styles.modules.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,6BAAa;AACf","sourcesContent":[".contentWrapper {\r\n  margin-top: 0.8vw;\r\n}\r\n\r\n.itemsContainer {\r\n  margin-top: 2.344vw;\r\n}\r\n\r\n.title {\r\n  color: $gw_08;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var contentWrapper = "contentWrapper--i5n1S";
export var itemsContainer = "itemsContainer--giWkH";
export var title = "title--fhZZk";
export default ___CSS_LOADER_EXPORT___;
