import React from 'react';


export interface Props {
  className?: string;
}

/* eslint-disable max-len */
export const OpenEyeIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg 
      viewBox="0 0 30 30" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={ className }
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M26.8154 15.5786L26.8173 15.576C27.0601 15.2308 27.0601 14.7692 26.8173 14.424C26.5318 14.0199 26.2179 13.6365 25.898 13.2594C25.3186 12.5763 24.4791 11.664 23.4382 10.749C21.3888 8.94726 18.4058 7 14.9998 7C11.5937 7 8.61076 8.94726 6.56135 10.749C5.5205 11.664 4.681 12.5763 4.1016 13.2594C3.78247 13.6356 3.46957 14.0187 3.18413 14.4214C2.94265 14.7636 2.94153 15.2337 3.1828 15.5767C3.46806 15.9806 3.7819 16.3637 4.1016 16.7406C4.681 17.4237 5.5205 18.336 6.56135 19.251C8.61076 21.0527 11.5937 23 14.9998 23C18.4058 23 21.3888 21.0527 23.4382 19.251C24.4791 18.336 25.3186 17.4237 25.898 16.7406C26.2171 16.3644 26.53 15.9813 26.8154 15.5786ZM5.62683 15.4469C5.48678 15.2818 5.36351 15.1315 5.2581 15C5.36351 14.8685 5.48678 14.7182 5.62683 14.5531C6.16069 13.9237 6.93211 13.086 7.88187 12.251C9.81367 10.5527 12.3307 9 14.9998 9C17.6689 9 20.1859 10.5527 22.1177 12.251C23.0675 13.086 23.8389 13.9237 24.3727 14.5531C24.5128 14.7182 24.6361 14.8685 24.7415 15C24.6361 15.1315 24.5128 15.2818 24.3727 15.4469C23.8389 16.0763 23.0675 16.914 22.1177 17.749C20.1859 19.4473 17.6689 21 14.9998 21C12.3307 21 9.81367 19.4473 7.88187 17.749C6.93211 16.914 6.16069 16.0763 5.62683 15.4469Z" 
        fill="currentColor"
      />
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M9.99978 15C9.99978 12.2386 12.2384 10 14.9998 10C17.7612 10 19.9998 12.2386 19.9998 15C19.9998 17.7614 17.7612 20 14.9998 20C12.2384 20 9.99978 17.7614 9.99978 15ZM11.9998 15C11.9998 13.3431 13.3429 12 14.9998 12C16.6566 12 17.9998 13.3431 17.9998 15C17.9998 16.6569 16.6566 18 14.9998 18C13.3429 18 11.9998 16.6569 11.9998 15Z" 
        fill="currentColor"
      />
    </svg>
  );
};
