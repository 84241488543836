// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--eiccg {\n  width: 5.46875vw;\n  height: 5.46875vw;\n  padding: 0;\n  margin: 0;\n  overflow: hidden;\n}\n\n.icon--tO9Mf {\n  width: 100%;\n  height: 100%;\n}\n\n.primaryRound--QiIOd {\n  -webkit-border-radius: 2.734375vw;\n          border-radius: 2.734375vw;\n  background-color: rgba(253, 216, 53, 1);\n  color: rgba(23, 23, 27, 1);\n}\n\n.inactive--KDqbA {\n  background-color: rgba(38, 38, 42, 1);\n  color: rgba(243, 243, 247, 1);\n}\n\n.active--_QyFm {\n  color: rgba(243, 243, 247, 1);\n}\n\n.up--IQpP9 {\n  transform: rotate(180deg);\n  -webkit-transform: rotate(180deg);\n}\n\n.right--YbjXY {\n  transform: rotate(-90deg);\n  -webkit-transform: rotate(-90deg);\n}\n\n.left--ksUHu {\n  transform: rotate(90deg);\n  -webkit-transform: rotate(90deg);\n}\n", "",{"version":3,"sources":["webpack://./src/app/ui/Icon/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,UAAU;EACV,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iCAAyB;UAAzB,yBAAyB;EACzB,uCAAwB;EACxB,0BAAa;AACf;;AAEA;EACE,qCAAwB;EACxB,6BAAa;AACf;;AAEA;EACE,6BAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,iCAAiC;AACnC;;AAEA;EACE,wBAAwB;EACxB,gCAAgC;AAClC","sourcesContent":[".wrapper {\n  width: 5.46875vw;\n  height: 5.46875vw;\n  padding: 0;\n  margin: 0;\n  overflow: hidden;\n}\n\n.icon {\n  width: 100%;\n  height: 100%;\n}\n\n.primaryRound {\n  border-radius: 2.734375vw;\n  background-color: $ac_00;\n  color: $gw_00;\n}\n\n.inactive {\n  background-color: $gw_02;\n  color: $gw_10;\n}\n\n.active {\n  color: $gw_10;\n}\n\n.up {\n  transform: rotate(180deg);\n  -webkit-transform: rotate(180deg);\n}\n\n.right {\n  transform: rotate(-90deg);\n  -webkit-transform: rotate(-90deg);\n}\n\n.left {\n  transform: rotate(90deg);\n  -webkit-transform: rotate(90deg);\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = "wrapper--eiccg";
export var icon = "icon--tO9Mf";
export var primaryRound = "primaryRound--QiIOd";
export var inactive = "inactive--KDqbA";
export var active = "active--_QyFm";
export var up = "up--IQpP9";
export var right = "right--YbjXY";
export var left = "left--ksUHu";
export default ___CSS_LOADER_EXPORT___;
