import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useDeviceToken } from '~app/global';
import Loader from '~components/LightLoader/Loader';
import { usePlatform } from '~components/Provider/Platform';
import { useLinkDevice } from '~hooks/fetch/useLinkDevice';

import { useRetPathFromLinking } from './DeviceLinkingHooks';
import * as styles from './DeviceLinkingPage.module.css';

export const DeviceLinkingPage: React.FC = () => {
  const deviceToken = useDeviceToken();
  const { platform } = usePlatform();
  const { mutate: linkDevice } = useLinkDevice();
  const history = useHistory();
  const { search } = useLocation();

  const { goToAfterSuccesLinking } = useRetPathFromLinking();

  React.useEffect(() => {
    linkDevice(
      { deviceToken, deviceName: platform.getDeviceFriendlyName() },
      {
        onSuccess: () => {
          goToAfterSuccesLinking();
        },
        onError: () => {
          history.replace({
            pathname: 'device-linking-limit',
            search,
          });
        },
      },
    );
  }, []);

  return (
    <div className={ styles.loaderWrap }>
      <Loader />
    </div>
  )
};
