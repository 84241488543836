import cn from 'classnames';
import React, { useMemo } from 'react';

import { Typography } from '~app/ui-kit/Typography';
import RatingsUI from '~components/RatingsBlock/Ratings';
import { fillImageTemplateBySizes } from '~lib/image';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import BannerBig from '~typings/BannerBig';
import ImageType from '~typings/ImageType';

import * as styles from './BigBannerContentText.module.css';

export type Props = {
  bigBanner: BannerBig;
};

const BigBannerContentText$: React.FC<Props> = ({ bigBanner }) => {
  const texts = bigBanner.big_screen_text_block;

  const alignCn = useMemo(() => {
    const aligns: Record<typeof bigBanner.text_position, string> = {
      'align-right': styles.wrapperRight,
      'align-left': styles.wrapperLeft,
      'align-center': styles.wrapperCenter,
    };

    return aligns[bigBanner.text_position] || styles.wrapperCenter;
  }, [bigBanner.text_position]);

  const contentLogo = useMemo(
    () => bigBanner.images.find((image) => image.type === ImageType.ContentLogo),
    [bigBanner.images],
  );

  const renderTitle = () => {
    if (contentLogo) {
      const height = valueInPixelsByWidth(6.171875);
      return (
        <img
          height={ height }
          className={ styles.textLine }
          src={ fillImageTemplateBySizes(contentLogo.url_template, 0, height) }
        />
      );
    }

    if (texts.title) {
      return (
        <Typography
          variant={ 'h3_bold' }
          className={ cn(styles.textLine, styles.title) }
        >
          {texts.title}
        </Typography>
      );
    }

    return null;
  };

  return (
    <div className={ cn(styles.wrapper, alignCn) }>
      <RatingsUI
        item={ bigBanner }
        className={ styles.textLine }
      />
      {renderTitle()}
      {texts.other_data && (
        <Typography
          variant="subtitle1_medium"
          className={ cn(styles.textLine, styles.otherData) }
        >
          {texts.other_data}
        </Typography>
      )}
      {texts.subtitle && (
        <Typography
          variant="subtitle1_medium"
          className={ cn(styles.textLine, styles.subtitle) }
        >
          {texts.subtitle}
        </Typography>
      )}
    </div>
  );
};

export const BigBannerContentText = React.memo(BigBannerContentText$);
