// цвета для дев окон
const devColor_0 = "rgba(255, 255, 255, 0.03)"

// дефолтный цвет для градиентов от 0 до N, везде одинаковый, цвет не важен
const default_0 = "rgba(0, 0, 0, 0)"

// далее цвета из дизайн системы
const w_100 = 'rgba(255, 255, 255, 1)'
const w_90 = 'rgba(255, 255, 255, 0.9)'
const w_80 = 'rgba(255, 255, 255, 0.8)'
const w_70 = 'rgba(255, 255, 255, 0.7)'
const w_60 = 'rgba(255, 255, 255, 0.6)'
const w_50 = 'rgba(255, 255, 255, 0.5)'
const w_40 = 'rgba(255, 255, 255, 0.4)'
const w_30 = 'rgba(255, 255, 255, 0.3)'
const w_20 = 'rgba(255, 255, 255, 0.2)'
const w_10 = 'rgba(255, 255, 255, 0.1)'

const b_100 = "rgba(23, 23, 27, 1)"
const b_90 = "rgba(23, 23, 27, 0.9)"
const b_80 = "rgba(23, 23, 27, 0.8)"
const b_70 = "rgba(23, 23, 27, 0.7)"
const b_60 = "rgba(23, 23, 27, 0.6)"
const b_50 = "rgba(23, 23, 27, 0.5)"
const b_40 = "rgba(23, 23, 27, 0.4)"
const b_30 = "rgba(23, 23, 27, 0.3)"
const b_20 = "rgba(23, 23, 27, 0.2)"
const b_10 = "rgba(23, 23, 27, 0.1)"

const gw_00 = b_100
const gw_01 = "rgba(34, 34, 38, 1)"
const gw_02 = "rgba(38, 38, 42, 1)"
const gw_03 = "rgba(58, 58, 66, 1)"
const gw_04 = "rgba(90, 90, 102, 1)"
const gw_05 = "rgba(125, 125, 138, 1)"
const gw_06 = "rgba(162, 162, 173, 1)"
const gw_07 = "rgba(202, 202, 208, 1)"
const gw_08 = "rgba(223, 223, 228, 1)"
const gw_09 = "rgba(235, 235, 240, 1)"
const gw_10 = "rgba(243, 243, 247, 1)"
const gw_11 = w_100

const ac_00 = "rgba(253, 216, 53, 1)"
const ac_01 = "rgba(253, 196, 53, 1)" // пока нигде не используется
const ac_02 = "rgba(253, 180, 53, 1)" // пока не используется, нужен для user interaction(нажатие)

const adc_00 = "rgba(208, 1, 27, 1)"
const adc_01 = "rgba(255, 85, 85, 1)"
const adc_02 = "rgba(255, 106, 0, 1)"
const adc_03 = "rgba(255, 148, 25, 1)"
const adc_04 = "rgba(188, 0, 184, 1)"
const adc_05 = "rgba(44, 168, 83, 1)"
const adc_06 = "rgba(26, 115, 232, 1)"
const adc_07 = "rgba(126, 0, 237, 1)"

const f_0 = "Montserrat"

const fw_0 = "normal"
const fw_00 = "bold"
const fw_1 = "100"
const fw_2 = "200"
const fw_3 = "300"
const fw_4 = "400"
const fw_5 = "500"
const fw_6 = "600"
const fw_7 = "700"
const fw_8 = "800"
const fw_9 = "900"

module.exports = {
    devColor_0,
    default_0,

    w_100,
    w_90,
    w_80,
    w_70,
    w_60,
    w_50,
    w_40,
    w_30,
    w_20,
    w_10,

    b_100,
    b_90,
    b_80,
    b_70,
    b_60,
    b_50,
    b_40,
    b_30,
    b_20,
    b_10,

    gw_00,
    gw_01,
    gw_02,
    gw_03,
    gw_04,
    gw_05,
    gw_06,
    gw_07,
    gw_08,
    gw_09,
    gw_10,
    gw_11,

    ac_00,
    ac_01,
    ac_02,

    adc_00,
    adc_01,
    adc_02,
    adc_03,
    adc_04,
    adc_05,
    adc_06,
    adc_07,

    f_0,

    fw_0,
    fw_00,
    fw_1,
    fw_2,
    fw_3,
    fw_4,
    fw_5,
    fw_6,
    fw_7,
    fw_8,
    fw_9
}