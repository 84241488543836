import * as React from 'react';

import useHotKey from '~hooks/useHotKey';
import HotKey from '~typings/HotKey';


type Props = Readonly<{
  isMounted: boolean;
  onDigitPressed: (digit: number) => void;
  update: any[];
}>;


const usePressingDigitOnRemote: React.FC<Props> = (props: Props) => {
  const on0Pressed = () => { props.onDigitPressed(0); };
  const on1Pressed = () => { props.onDigitPressed(1); };
  const on2Pressed = () => { props.onDigitPressed(2); };
  const on3Pressed = () => { props.onDigitPressed(3); };
  const on4Pressed = () => { props.onDigitPressed(4); };
  const on5Pressed = () => { props.onDigitPressed(5); };
  const on6Pressed = () => { props.onDigitPressed(6); };
  const on7Pressed = () => { props.onDigitPressed(7); };
  const on8Pressed = () => { props.onDigitPressed(8); };
  const on9Pressed = () => { props.onDigitPressed(9); };

  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_0,
    action: on0Pressed,
  }, props.update);
  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_1,
    action: on1Pressed,
  }, props.update);
  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_2,
    action: on2Pressed,
  }, props.update);
  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_3,
    action: on3Pressed,
  }, props.update);
  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_4,
    action: on4Pressed,
  }, props.update);
  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_5,
    action: on5Pressed,
  }, props.update);
  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_6,
    action: on6Pressed,
  }, props.update);
  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_7,
    action: on7Pressed,
  }, props.update);
  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_8,
    action: on8Pressed,
  }, props.update);
  useHotKey({
    isMounted: props.isMounted,
    keyName: HotKey.KEY_9,
    action: on9Pressed,
  }, props.update);

  return null;
};


export default usePressingDigitOnRemote;
