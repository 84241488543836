import * as React from 'react';

import { getActiveSubscriptionWithProduct } from '~lib/product/getProductInSubscriptions';
import Product from '~typings/Product';
import Subscription from '~typings/Subscription';

import SelectPlan from './SelectPlan';
import Unsubscribe from './Unsubscribe';


type Props = Readonly<{
  product: Product;
  subscriptions?: Subscription[];
}>;

const ProductPlanManager: React.FC<Props> = ({ product, subscriptions }) => {
  const activeSubscriptionWithProduct = getActiveSubscriptionWithProduct(product.id, subscriptions);
  
  return !!activeSubscriptionWithProduct ? (
    <Unsubscribe
      product={ product }
      subscriptions={ subscriptions }
    />
  ) : (
    <SelectPlan
      product={ product }
    />
  );
};


export default ProductPlanManager;
