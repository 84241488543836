import * as React from 'react';

import { KeyBoardKey, KeyBoardType } from '~typings/Keyboard';
import NavigationDirection from '~typings/NavigationDirection';
import RemoveIcon from '~ui/Icon/icons/RemoveIcon';
import Input from '~ui/Input';
import { ShowCursor } from '~ui/Input/component';
import KeyBoard from '~ui/KeyBoard/component';

import * as styles from './styles.module.css';


export enum FocusOn {
  Input,
  KeyBoard
}

export type Props = Readonly<{
  inputValue?: string | null;
  focusOn: FocusOn | null;
  hideValue?: boolean;
  keyBoardType: KeyBoardType;
  placeholder?: string;
  onChange: (key: KeyBoardKey) => void;
  onClear: () => void;
  onSubmit: () => void;
  onEyeClick?: (eyeValue: boolean) => void;
  onLeave?: (direction: NavigationDirection) => void;
}>;

/**
 * @deprecated
 * Это старый компонент, со старым дизайном и старой навигацией.
 * Есть новый - InputFormFocuser
 */
const InputWithKeyboard: React.FC<Props> = (props: Props) => {
  const [focusOn, setFocusOn] = React.useState<FocusOn | null>(props.focusOn);

  const handleLeaveInput = React.useCallback((direction: NavigationDirection) => {
    if (direction === NavigationDirection.Down) {
      setFocusOn(FocusOn.KeyBoard);
    }
  }, []);
  const handleLeaveKeyboard = React.useCallback((direction: NavigationDirection) => {
    if (direction === NavigationDirection.Up) {
      setFocusOn(FocusOn.Input);
    } else if (direction === NavigationDirection.Down && props.onLeave) {
      props.onLeave(direction);
    }
  }, [props.onLeave]);
  React.useEffect(() => {
    setFocusOn(props.focusOn);
  }, [props.focusOn]);

  return (
    <>
      <Input
        Icon={ RemoveIcon }
        isFocused={ (focusOn === FocusOn.Input) }
        onLeave={ handleLeaveInput }
        value={ props.inputValue }
        placeholder={ props.placeholder }
        onClick={ props.onClear }
        showCursor={ ShowCursor.Always }
      />
      <div
        className={ styles.keyboardWrapper }
      >
        <KeyBoard
          isHorizontalLoopAllowed
          isFocused={ (focusOn === FocusOn.KeyBoard) }
          type={ props.keyBoardType }
          hideValue={ props.hideValue }
          onEyeClick={ props.onEyeClick }
          onKeyClick={ props.onChange }
          onLeaveKeyboard={ handleLeaveKeyboard }
          onClose={ props.onSubmit }
        />
      </div>
    </>
  );
};

export default React.memo(InputWithKeyboard);
