import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { useApp } from '~components/Provider/App';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import {
  MARKER_FEATURED,
  MARKER_FREE,
  MARKER_NEW,
  MARKER_POPULAR,
  MARKER_SUBSCRIPTION,
} from '~localization';
import Card, { CardLabel, Modification } from '~typings/Card';
import Marker from '~typings/Marker';

import * as styles from './styles.module.css';


const LABEL_NAME = {
  [Marker.New]: MARKER_NEW,
  [Marker.Popular]: MARKER_POPULAR,
  [Marker.Free]: MARKER_FREE,
  [Marker.Featured]: MARKER_FEATURED,
  [Marker.Subscription]: MARKER_SUBSCRIPTION,
} as const;


type Props = Readonly<{
  className: string;
  labels?: CardLabel[] | null;
  modification?: Card['modification'];
  intl: InjectedIntl;
}>;


const LabelCard: React.FC<Props> = (props: Props) => {
  const { language } = useApp();
  const { labels = EMPTY_ARRAY } = props;

  if (labels?.length == 0) { return null; }

  const [label] = labels?.filter(
    lb => props.modification === Modification.ANNOUNCEMENT_MOD ?
      lb.type === 'soon'
      : true
  ) || [];

  if (!label) { return null; }

  const style: React.CSSProperties = {};

  if (label.bg_color && label.bg_color.length !== 0) {
    style.backgroundColor = label.bg_color;
  }
  if (label.text_color && label.text_color.length !== 0) {
    style.color = label.text_color;
  }

  return (
    <span
      style={ style }
      className={ cn(styles.textLabel, props.className, styles[label.type]) }
    >
      {
        label.name_translations?.[language] ?
          label.name_translations[language]
          : LABEL_NAME[label.type] ?
            props.intl.formatMessage({ id: LABEL_NAME[label.type] })
            :
            label.name
      }
    </span>
  );
};


export default injectIntl(LabelCard);
