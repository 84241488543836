/* eslint-disable max-len */
import React from "react";

import Channel from "~typings/Channel";

import { useDynamicTitle } from "./hooks/useDynamicTitle";
import * as css from './styles.module.css'
import { ChannelIcon } from "./subComponents/ChannelIcon";

type Props = {
  item: Channel
}

const LocalComponent = ({ item }: Props) => {
  const dynamicTitle = useDynamicTitle(item)

  const image = item.images && item.images.find(el => el.type === 'logo')
  return (
    <div className={ css.mainWrapper }>
      <div className={ css.liveFirstLine }>
        { image && <ChannelIcon image={ image } /> }
        <div className={ css.itemName }>{ item.position + ' ' + item.name }</div>
      </div>
      { dynamicTitle && <div className={ css.liveSecondLine }> { dynamicTitle }</div> }
    </div>
  )
}

export const LiveInfo = LocalComponent