import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useApp } from '~components/Provider/App';
import {
  AUTH_BY_LOGIN_AND_PASSWORD,
  AUTH_SEND_CODE,
  ENABLE_AUTOMATIC_LOGIN,
  FORGOT_PASSWORD,
  LOGIN,
  NOT_REGISTERED_YET,
  REGISTER_NOW,
  REGISTER_OR_SIGN_IN_IMPLICIT,
  RESEND_SECURITY_CODE,
} from '~localization';
import IconButton from '~ui/IconButton';


export enum AuthLinkType {
  Login = 'login',
  ByPhone = 'phone',
  NotRegisteredYet = 'notRegisteredYet',
  Register = 'register',
  RegisterOrLogin = 'registerOrLogin',
  ForgotPassword = 'forgotPassword',
  ResendSecurityCode = 'resendSecurityCode',
  ResendPasswordBySms = 'resendPasswordBySms',
  EnableAutomaticLogIn = 'enableAutomaticLogIn',
}

type Props = Readonly<{
  isFocused: boolean;
  link: AuthLinkType;
  onClick?: (link: AuthLinkType) => void;
}>;


const LINK_TEXT_BY_TYPE: { [key in AuthLinkType]: string } = {
  [AuthLinkType.Login]: LOGIN,
  [AuthLinkType.Register]: REGISTER_NOW,
  [AuthLinkType.RegisterOrLogin]: REGISTER_OR_SIGN_IN_IMPLICIT,
  [AuthLinkType.ForgotPassword]: FORGOT_PASSWORD,
  [AuthLinkType.ByPhone]: AUTH_BY_LOGIN_AND_PASSWORD,
  [AuthLinkType.NotRegisteredYet]: NOT_REGISTERED_YET,
  [AuthLinkType.ResendSecurityCode]: RESEND_SECURITY_CODE,
  [AuthLinkType.ResendPasswordBySms]: AUTH_SEND_CODE,
  [AuthLinkType.EnableAutomaticLogIn]: ENABLE_AUTOMATIC_LOGIN,
};

export const AuthLink: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { authRedirectUrl } = useApp();
  const goTo = (authRedirectUrl) ? history.replace : history.push;

  const handleGoToPage = () => {
    switch (props.link) {
      case AuthLinkType.ByPhone: {
        goTo('/auth/signin/');
        break;
      }
      // Simple Auth
      case AuthLinkType.RegisterOrLogin: {
        goTo('/auth/signin/');
        break;
      }
      case AuthLinkType.NotRegisteredYet: {
        goTo('/auth/signup/');

        break;
      }
      case AuthLinkType.Register: {
        history.push('/auth/signup/');

        break;
      }
      case AuthLinkType.ForgotPassword: {
        goTo('/auth/forgot-password');

        break;
      }
      case AuthLinkType.Login: {
        goTo('/auth');

        break;
      }
    }
  };
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.link);
    }
    else {
      handleGoToPage();
    }
  };

  return (
    <IconButton
      isFocused={ props.isFocused }
      onClick={ handleClick }
    >
      {/* eslint-disable-next-line react/no-children-prop,max-len */ }
      <FormattedMessage id={ LINK_TEXT_BY_TYPE[props.link] } children={ msg => <div style={ { marginRight: "auto", display: "inline-block" } }>{ msg }</div> } />
</IconButton>
)
  ;
};


AuthLink.displayName = 'AuthLink';


export default React.memo(AuthLink);
