import * as React from 'react';

import Slider from '~components/Slider';
import {
  useContinueWatchingFirstPage,
} from '~hooks/fetch/useContinueWatching/useContinueWatchingV3';
import { usePageByType } from '~hooks/fetch/usePages/usePages';
import { PersonalPageMenuID } from '~screens/PersonalScreen/routes.helpers';
import useNavigation, { pageWeComeFromTS } from "~stores/Navigation";
import { usePlayerStore } from "~stores/Player";
import { Block, BlockType } from '~typings/Block';
import ContinueWatchingType from '~typings/ContinueWatchingType';
import ItemObject from '~typings/ItemObject';
import MediaItemDimension from '~typings/MediaItemDimension';
import Page from '~typings/Page';
import PageObject from '~typings/PageObject';


type Props = Readonly<{
  page: Page;
  block: Block;
  dimension: MediaItemDimension;
  isFocusedBlock: boolean;
  isPopupOpened: boolean;
  isVisible: boolean;
  focusedItemIndex: number;
  types?: ContinueWatchingType[];
}>;

const BlockContinueWatching: React.FC<Props> = (props: Props) => {
  const personalPage = usePageByType(PageObject.PersonalPage);
  const tabs = personalPage?.tabs
    ?.filter(tab => tab.type === 'favorites')
    .map(tab => tab.resource_types?.join(','));
  const types = (props.types || ['all']).join(',');
  const deeplink = tabs?.includes(types) ?
    `/page/${personalPage?.id}/${PersonalPageMenuID.watch_later}/${types}`
    :
    `/${ItemObject.Collections}/${BlockType.ContinueWatchingBlock}?blockTitle=${
      props.block?.title
    }&types=${types}`;

  const setIsLive = usePlayerStore(state => state.setIsLive)
  const setPageWeComeFrom = useNavigation(state => state.setPageWeComeFrom)

  const { data: items } = useContinueWatchingFirstPage({ types: props.types });

  if (items?.data.length == 0 || items?.meta.pagination.total === 0) { return null; }

  const handleOnClick = () => {
    setIsLive(true)
    setPageWeComeFrom(pageWeComeFromTS.continueWatching)
  }

  return (
    <Slider
      dimension={ props.dimension }
      items={ items }
      isFocusedBlock={ !props.isPopupOpened && props.isFocusedBlock }
      isVisible={ props.isVisible }
      focusedIndex={ props.focusedItemIndex }
      deeplink={ deeplink }
      onClick = { handleOnClick }
    />
  );
};


export default React.memo(BlockContinueWatching);
