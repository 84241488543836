import * as React from 'react';

import { PlayerAccessReason } from '~components/Provider/Player/component.helpers';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import PlayerAccessError from '~typings/PlayerStatus';
import { Popup } from '~ui/Popup';

import PlayerAccessErrorDialog from './AccessErrorDialog';
import ErrorDialog from './ErrorDialog';
import * as styles from './styles.module.css';


type Props = Readonly<{
  error?: Object | string;
  access?: PlayerAccessError | PlayerAccessReason | null;
  item?: PlayerMediaItem | null;
  onClose?: () => void;
}>;


const PlayerErrors: React.FC<Props> = (props: Props): JSX.Element | null => {
  const renderPopupContent = React.useMemo(() => {
    if (
      props.error
      && props.error !== PlayerAccessError.NotAuthorized
      && props.error !== PlayerAccessReason.NotPurchased
    ) {
      return (
        <ErrorDialog
          error={ props.error }
        />
      );
    }

    if (props.access) {
      return (
        <PlayerAccessErrorDialog
          access={ props.access }
          item={ props.item }
          onClose={ props.onClose }
        />
      );
    }

    return null;
  }, [props.access, props.error]);

  if (!props.error && !props.access) {
    return null;
  }

  return (
    <Popup semiTransporent>
      <div className={ styles.container }>
        { renderPopupContent }
      </div>
    </Popup>
  );
};


export default React.memo(PlayerErrors);
