export enum HotKey {
  VIRTUAL_UP = 'VIRTUAL_UP',
  VIRTUAL_DOWN = 'VIRTUAL_DOWN',
  KEY_GREEN = 'KEY_GREEN',
  KEY_ENTER = 'KEY_ENTER',
  KEY_0 = 'KEY_0',
  KEY_1 = 'KEY_1',
  KEY_2 = 'KEY_2',
  KEY_3 = 'KEY_3',
  KEY_4 = 'KEY_4',
  KEY_5 = 'KEY_5',
  KEY_6 = 'KEY_6',
  KEY_7 = 'KEY_7',
  KEY_8 = 'KEY_8',
  KEY_9 = 'KEY_9',
  KEY_VOL_DOWN = 'KEY_VOL_DOWN',
  KEY_VOL_UP = 'KEY_VOL_UP',
  KEY_CH_DOWN = 'KEY_CH_DOWN',
  KEY_CH_UP = 'KEY_CH_UP',
  KEY_SEARCH = 'KEY_SEARCH',
  KEY_INFO = 'KEY_INFO',
  KEY_MUTE = 'KEY_MUTE',
  KEY_PREV = 'KEY_PREV',
  KEY_NEXT = 'KEY_NEXT',
  KEY_YELLOW = 'KEY_YELLOW',
  KEY_EXIT = 'KEY_EXIT',
  KEY_RETURN = 'KEY_RETURN',
  KEY_STOP = 'KEY_STOP',
  KEY_RED = 'KEY_RED',
  KEY_PLAY = 'KEY_PLAY',
  KEY_PLAY_PAUSE = 'KEY_PLAY_PAUSE',
  KEY_PAUSE = 'KEY_PAUSE',
  KEY_RIGHT = 'KEY_RIGHT',
  KEY_LEFT = 'KEY_LEFT',
  KEY_DOWN = 'KEY_DOWN',
  KEY_UP = 'KEY_UP',
  KEY_BLUE = 'KEY_BLUE',
}

export default HotKey;
