/* eslint-disable max-len */
import * as React from 'react';


const IconPause: React.FC = () => (
  <svg
    className="playerIconPause"
    viewBox="0 0 26 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.2 30H1.8c-1 0-1.8-.8-1.8-1.8V1.8C0 .8.8 0 1.8 0h6.3c1 0 1.8.8 1.8 1.8v26.3c.1 1.1-.7 1.9-1.7 1.9zm16 0h-6.3c-1 0-1.8-.8-1.8-1.8V1.8c0-1 .8-1.8 1.8-1.8h6.3c1 0 1.8.8 1.8 1.8v26.3c0 1.1-.8 1.9-1.8 1.9z"
    />
  </svg>
);


export default IconPause;
