import { find } from 'lodash';

export type DDListItem<T = any> = {
  text: string,
  value: T
};

export const getCorrectListValue = (
  value: any, items?: DDListItem[]
) : DDListItem => {
  if (!Array.isArray(items)) return {
    value,
    text: value
  };

  const result = find(items, item => item.value === value);

  if (!result) {
    return { value: undefined, text: '' };
  }

  return result;
};
