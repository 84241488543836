import ItemObject from '~typings/ItemObject';


const isAllowedSinglePageMediaItem = (object: ItemObject): boolean => (
  object === ItemObject.Episode ||
  object === ItemObject.Series ||
  object === ItemObject.Movie ||
  object === ItemObject.Channel ||
  object === ItemObject.ProgramEvent ||
  object === ItemObject.News ||
  object === ItemObject.AudioShow ||
  object === ItemObject.Part
);


export default isAllowedSinglePageMediaItem;
