import * as React from 'react';

import Season from '~typings/Season';

import SeasonItem from './season';
import * as styles from './styles.module.css';


type Props = Readonly<{
  seasons: Season[];
  isFocused: boolean;
  selectedIndex: number;
  focusedIndex: number;
  onClick: (season: Season) => void;
  onSeasonIDChange?: (season_id: string) => void;
}>;

/**
 * @deprecated, это старые сезоны, сейчас они заменены.
 * Для плеера нужно будет изучить, и создать новые
 */
const Seasons: React.FC<Props> = (props: Props) => (
  <div
    className={ styles.seasons }
  >
    {
      props.seasons.map((season: Season, index: number) => (
        <SeasonItem
          key={ season.id }
          season={ season }
          isFocused={ props.isFocused && (props.focusedIndex === index) }
          isSelected={ (props.selectedIndex === index) }
          onClick={ props.onClick }
          onSeasonIDChange={ props.onSeasonIDChange }
        />
      ))
    }
  </div>
);


export default React.memo(Seasons);
