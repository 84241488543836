import { memo,useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { AuthActionType, useAccount,useAuthMutation } from '~hooks/fetch/useAccount';
import { useProfiles } from '~hooks/fetch/useProfiles';
import usePrevious from '~hooks/usePrevious';

const PROFILES_PAGE_PATHNAME = '/profiles';


export const ProfilesManager: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const appAction = useAppAction();
  const { data: accountInfo } = useAccount();
  const isProfilesRoute = !!useRouteMatch(`${PROFILES_PAGE_PATHNAME}/:subsection?`);
  const isAuthRoute = !!useRouteMatch(`/auth/:subsection?`);

  const {
    isAuthorized,
    activeProfileId,
    isLaunchingDone,
  } = useApp();
  const { mutate: mutateAuth } = useAuthMutation();
  const { data: profiles } = useProfiles();

  const prevActiveProfileId = usePrevious(activeProfileId);
  useEffect(() => {
    // Если activeProfileId не имеет отношение ни к одному из профилей
    // такое возможно после анмёржа IPTV + OTT аккаунта
    if (
      activeProfileId && profiles && profiles.length !== 0
      && !profiles.find(({ id }) => (id === activeProfileId))
    ) {
      appAction({
        type: ActionType.SetActiveProfileId,
        payload: { activeProfileId: null },
      });
    }
  }, [activeProfileId, profiles?.length]);
  useEffect(() => {
    if (
      isAuthorized
      && accountInfo
      && !isAuthRoute
      && !isProfilesRoute
      && profiles?.length
      && !activeProfileId
    ) {
      if (!accountInfo.parental_control && profiles.length === 1) {
        mutateAuth({type: AuthActionType.AuthSameAccount, payload: { userId: profiles[0].id }}, {
          onSuccess: () => {
            appAction({
              type: ActionType.SetActiveProfileId,
              payload: { activeProfileId: profiles[0].id },
            });
            if (!isLaunchingDone) {
              appAction({
                type: ActionType.SetIsLaunchingDone,
                payload: { isLaunchingDone: true },
              });
            }
          },
        });
      } else {
        appAction({
          type: ActionType.SetAuthRedirectUrl,
          payload: { authRedirectUrl: location.pathname },
        });
        history.replace(PROFILES_PAGE_PATHNAME);
      }
    }
  }, [
    accountInfo,
    profiles?.length,
    isAuthorized,
    activeProfileId,
    isProfilesRoute,
    isLaunchingDone,
    isAuthRoute,
  ]);

  useEffect(() => {
    if (
      prevActiveProfileId &&
      activeProfileId &&
      prevActiveProfileId !== activeProfileId
    ) {
      mutateAuth({type: AuthActionType.AuthSameAccount, payload: { userId: activeProfileId }});
    }
  }, [prevActiveProfileId, activeProfileId]);

  return null;
};


export {
  PROFILES_PAGE_PATHNAME,
};

export default memo(ProfilesManager);
