// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dots--gj4Mx {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n}\n\n.dot--V56XI {\n  /* 8px */\n  width: 0.625vw;\n\n  /* 4px */\n  height: 0.3125vw;\n  background-color: rgba(255, 255, 255, 1);\n  opacity: 0.3;\n\n  /* 6px */\n  margin-right: 0.46875vw;\n\n  /* 8px */\n  -webkit-border-radius: 0.625vw;\n          border-radius: 0.625vw;\n}\n\n.dot--V56XI:last-child {\n  margin-right: 0;\n}\n\n.dotSelected--AL74X {\n  /* 22px */\n  width: 1.71875vw;\n  opacity: 1;\n}\n\n.dotFocused--pAA7D {\n  background-color: rgba(253, 216, 53, 1);\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/ui-kit/Cards_elements/PageController/PageController.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;AACrB;;AAEA;EACE,QAAQ;EACR,cAAc;;EAEd,QAAQ;EACR,gBAAgB;EAChB,wCAAwB;EACxB,YAAY;;EAEZ,QAAQ;EACR,uBAAuB;;EAEvB,QAAQ;EACR,8BAAsB;UAAtB,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,uCAAwB;AAC1B","sourcesContent":[".dots {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.dot {\n  /* 8px */\n  width: 0.625vw;\n\n  /* 4px */\n  height: 0.3125vw;\n  background-color: $gw_11;\n  opacity: 0.3;\n\n  /* 6px */\n  margin-right: 0.46875vw;\n\n  /* 8px */\n  border-radius: 0.625vw;\n}\n\n.dot:last-child {\n  margin-right: 0;\n}\n\n.dotSelected {\n  /* 22px */\n  width: 1.71875vw;\n  opacity: 1;\n}\n\n.dotFocused {\n  background-color: $ac_00;\n}\n"],"sourceRoot":""}]);
// Exports
export var dots = "dots--gj4Mx";
export var dot = "dot--V56XI";
export var dotSelected = "dotSelected--AL74X";
export var dotFocused = "dotFocused--pAA7D";
export default ___CSS_LOADER_EXPORT___;
