import * as URLSearchManager from '~lib/URLQueryStringManager';


const FIELD_NAME = 'popup';
const getOpenedPopupName = (history) => URLSearchManager.getField(history.location.search, FIELD_NAME);
const setPopupState = (history, popupName) => {
  URLSearchManager.set({ [FIELD_NAME]: popupName }, history);
};


export {
  getOpenedPopupName,
  setPopupState,
};
