import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { getBreadCrumbsByPageUrl } from '~components/NavMenu/line/component.helpers';
import CatchBack from '~components/PageManager/CatchBack';
import { ActionType, useMenuAction } from '~components/Provider/Menu';
import { useMainPageID } from '~hooks/fetch/usePages/usePages';
import usePageInfo from '~hooks/usePageInfo';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import useNavMenu from '~stores/NavMenu';
import MenuItem from '~typings/MenuItem';


type Props = Readonly<{
  rootLine: MenuItem[];
}>;


const BackCatcher: React.FC<Props> = (props: Props) => {
  const { rootLine } = props;
  const history = useHistory();
  const { pageID } = usePageInfo();
  const menuAction = useMenuAction();
  const mainPageID = useMainPageID() || null;
  const breadCrumbs = useNavMenu(state => state.breadCrumbs);
  const setBreadCrumbs = useNavMenu(state => state.setBreadCrumbs);
  const mainPageId = useMainPageID();

  const lineIndexOfLastItem = ((breadCrumbs || EMPTY_ARRAY).length !== 0) ?
    breadCrumbs[(breadCrumbs.length - 1)]?.lineIndex || 0
    :
    0;
  const prevLineIndexOfLastItem = React.useRef<number | null>(null);

  const handleShowExitPopup = () => {
    menuAction({
      type: ActionType.ChangeExitState,
      isExitOpened: true,
    });
  };

  const handleBack = () => {
    let newBreadCrumbsValue = breadCrumbs.slice(0, (breadCrumbs.length - 1));
    if (mainPageID === pageID) {
      handleShowExitPopup();

      return;
    }

    if (newBreadCrumbsValue.length === 0) {
      newBreadCrumbsValue = getBreadCrumbsByPageUrl('/', rootLine, mainPageId);
    }

    const isNeedGoToMainPage = (
      prevLineIndexOfLastItem.current !== null
      && prevLineIndexOfLastItem.current === lineIndexOfLastItem
      && lineIndexOfLastItem === 0
    );

    if (isNeedGoToMainPage) {
      history.push(`/page/${ mainPageID }`);

      return;
    }

    setBreadCrumbs(newBreadCrumbsValue);

    prevLineIndexOfLastItem.current = lineIndexOfLastItem;
  };

  return (
    <CatchBack
      action={ handleBack }
      triggers={ [lineIndexOfLastItem, (breadCrumbs || EMPTY_ARRAY).length] }
    />
  );
};


export default BackCatcher;
