import { EditIcon } from '~newapp/ui-kit/Icons/For_buttons/editIcon';

import { IconBtnHOC, IconBtnProps } from '../../IconBtnHOC/IconBtnHOC';
import { IconBtnFocuserHOC, IconBtnFocuserProps } from '../../IconBtnHOC/IconBtnHOCFocuser';

export const EditButton = IconBtnHOC(EditIcon, 'big');
export const EditButtonFocuser = IconBtnFocuserHOC(EditIcon, 'big');

export type EditButtonProps = IconBtnProps;
export type EditButtonFocuserProps = IconBtnFocuserProps;
