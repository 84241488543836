import useLocationSearch from '~hooks/useLocationSearch';


const useIsNeedRestartApp = (): boolean => {
  const needRestartApp = useLocationSearch()?.restartApp;

  return !!needRestartApp;
};


export default useIsNeedRestartApp;
