import * as React from 'react';


type Props = {
  className?: string;
};

/* eslint-disable max-len */
const BackIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg className={ className } viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3501 12.9138C20.7305 13.3142 20.7143 13.9472 20.3139 14.3276L16.5371 17.9156L27.7198 17.9155C32.6904 17.9155 36.7198 21.945 36.7198 26.9155C36.7198 31.8861 32.6904 35.9155 27.7198 35.9155H24.2672C23.7149 35.9155 23.2672 35.4678 23.2672 34.9155V33.9155C23.2672 33.3633 23.7149 32.9155 24.2672 32.9155H27.7198C31.0336 32.9155 33.7198 30.2293 33.7198 26.9155C33.7198 23.6018 31.0336 20.9155 27.7198 20.9155L16.5371 20.9156L20.3139 24.5035C20.7143 24.8839 20.7305 25.5169 20.3501 25.9173L19.6614 26.6423C19.281 27.0427 18.648 27.0589 18.2476 26.6785L10.6024 19.4156L18.2476 12.1526C18.648 11.7722 19.281 11.7884 19.6614 12.1888L20.3501 12.9138Z"
        fill="currentColor"
      />
    </svg>
  );
};


export default BackIcon;
