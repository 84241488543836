import { applyHotKeyOrClickOnInnerMost } from '@focuser';
import * as cn from 'classnames';
import { throttle } from 'lodash';
import * as React from 'react';

import { KeyNames } from '~components/Provider/KeyDownHandler/utils';
import { ActionType, useMenuAction } from '~components/Provider/Menu';
import useHotKeysStore from '~stores/HotKeys';
import HotKey from '~typings/HotKey';
import KeyCodes from '~typings/KeyCodes';
import NavigationDirection from '~typings/NavigationDirection';
import PointerNavigation from '~typings/PointerNavigation';
import { IconBase } from '~ui/Icon';
import BackIcon from '~ui/Icon/icons/Back';
import CloseIcon from '~ui/Icon/icons/Close';
import MenuIcon from '~ui/Icon/icons/Menu';

import * as styles from './styles.module.css';


type Props = Readonly<{
  directions: Record<NavigationDirection & PointerNavigation, boolean | Function>;
  keyCodes: KeyCodes;
  keyNames: KeyNames;
  isMenuOpened: boolean;
}>;


const BackOrToggleMenuIcon: React.FC<Props> = (props: Props) => {
  const {
    directions,
    keyCodes,
    keyNames,
    isMenuOpened
  } = props;
  const dispatch = useMenuAction();
  const applyHotKey  = useHotKeysStore(state => state.apply);

  const handleClick = React.useCallback(throttle<any>((e) => {
    applyHotKey(HotKey.KEY_RETURN, e);
    applyHotKeyOrClickOnInnerMost(HotKey.KEY_RETURN, e?.nativeEvent || e);
  }, 400, { leading: false }), [keyCodes, keyNames, directions]);

  const handleCloseMenuOrReturn = (e) => {
    if(isMenuOpened) {
      dispatch({
        type: ActionType.ChangeMenuState,
        isMenuOpened: false,
      });
      return;
    }
    handleClick(e);
  };



  const isNeedShowMenuOpenIcon = directions[PointerNavigation.Menu];
  const isNeedShowMenuCloseIcon = directions[PointerNavigation.Close];
  const isNeedShowBackIcon = directions[PointerNavigation.Back];

  if (isNeedShowMenuOpenIcon) {
    return (
      <div
        className={ cn(styles.button, styles.menu, styles.visible) }
        onClick={ handleClick }
      >
        <IconBase Icon={ MenuIcon } classNames={ { wrapper: styles.icon } } />
      </div>
    );
  }

  if (isNeedShowMenuCloseIcon) {
    return (
      <div
        className={ cn(styles.button, styles.menu, styles.visible) }
        onClick={ handleCloseMenuOrReturn }
      >
        <IconBase Icon={ CloseIcon } classNames={ { wrapper: styles.icon } } />
      </div>
    );
  }

  if (isNeedShowBackIcon) {
    return (
      <div
        className={ cn(styles.button, styles.menu, styles.visible) }
        onClick={ handleClick }
      >
        <IconBase Icon={ BackIcon } classNames={ { wrapper: styles.icon } } />
      </div>
    );
  }

  return null;
};


export default BackOrToggleMenuIcon;
