import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useAccount } from "~hooks/fetch/useAccount";
import { PincodeLocalState } from "~newapp/blocks/playerPopcorn/flows/pincode/new/NewPinCodeFlow";

export enum PinCodeFlowState {
  enterPin = 'enter-pin',
  skipToWatch = 'skip-to-watch',
  askToSetParentalControl = 'ask-to-set-parental-control',
  askToSetPinCode = 'ask-to-set-pin-code',
  wrongState = 'wrong-state'
}

type Props = {
  setLocalState: Dispatch<SetStateAction<PincodeLocalState>>
}

const ExtractFlowStateFromAccount = ({ setLocalState }: Props): null => {
  const { data: accountInfo, refetch } = useAccount();
  const [switcher, setSwitcher] = useState(false)
  const parentalControl = accountInfo?.parental_control
  const pinCode = accountInfo?.security_pin
  const parentalControlFlowPassed = accountInfo?.parental_control_flow_passed

  useEffect(() => {
    if (!switcher) {
      return
    }

    const enterPin = parentalControl && pinCode
    const skipToWatch = !parentalControl && parentalControlFlowPassed
    const askToSetParentalControl = !parentalControl && pinCode && !parentalControlFlowPassed
    const askToSetPinCode = !parentalControl && !pinCode && !parentalControlFlowPassed;

    if (enterPin) {
      setLocalState({ state: PinCodeFlowState.enterPin, extraData: { parentalControlFlowPassed } })
      return
    }
    if (skipToWatch) {
      setLocalState({ state: PinCodeFlowState.skipToWatch })
      return
    }
    if (askToSetParentalControl) {
      setLocalState({ state: PinCodeFlowState.askToSetParentalControl })
      return
    }
    if (askToSetPinCode) {
      setLocalState({ state: PinCodeFlowState.askToSetPinCode })
      return
    }
    else {
      //если с переменными выше что-то пойдет не так(ошибка на беке) у нас непонятное состояние
      setLocalState({ state: PinCodeFlowState.wrongState })
      return
    }
  }, [switcher, accountInfo])

  useEffect(() => {
    if (!parentalControlFlowPassed) {
      // здесь проверяем актуальность данных аккаунта
      // тут обрабатывается ситуация когда юзер зашел с двух девайсов и на обоих девайсах у нас PCFP = false
      // на другом девайсе начинает смотреть контент и PCFP = true
      // но на телевизоре по прежнему PCFP = false и при начале просмотра у нас будет ошибка ввода пин кода
      // чтобы не показывать юзеру флоу на котором 100% будет ошибка, здесь мы актуализируем данные аккаунта
      // это будет происходить каждый раз при запуске стрима
      refetch()
        .then(res => {
          if (res.status === 'success') {
            setSwitcher(true)
          }
          else {
            setLocalState({ state: PinCodeFlowState.wrongState })
          }
        })
    }
    else {
      setSwitcher(true)
    }
  }, [])

  return null
}

export const ExtractFlowStateFromAccount$ = ExtractFlowStateFromAccount