import * as cn from 'classnames';
import { Moment } from 'moment';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { useApp } from '~components/Provider/App';
import { getScheduleDate, isToday } from '~lib/localeUtils/dates';
import { TODAY } from '~localization';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  isActive: boolean;
  index: number;
  item: Moment;
  intl: InjectedIntl;
  onClick: (index: number) => void;
}>;


const EPGWeekDay: React.FC<Props> = (props: Props) => {
  const { language } = useApp();
  const formatted = props.item.format('YYYY-MM-DD');
  const handleClick = () => {
    props.onClick(props.index);
  };
  const renderText = isToday(formatted) ?
    props.intl.formatMessage({ id: TODAY })
    :
    getScheduleDate(formatted, language);
  const properties = {
    className: cn(styles.weekDay, {
      'focusedNavigationNode': props.isFocused,
      [styles.weekDayFocused]: props.isFocused,
      [styles.weekDayActive]: props.isActive,
    }),
    onClick: handleClick,
  };

  return (
    React.createElement(
      'div',
      properties,
      renderText,
    )
  );
};


export default injectIntl(React.memo(EPGWeekDay));
