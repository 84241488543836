import * as cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';

type SwitchButtonProps = {
  isChecked: boolean;
  isFocused: boolean;
};

const SwitchButton: React.FC<SwitchButtonProps> = ({ isChecked, isFocused, children }) => {
  return (
    <div
      className={ cn(styles.wrapper, {
        [styles.focused]: isFocused,
      }) }
    >
      <div className={ styles.switchButton }>
        <div
          className={ cn(styles.button, {
            [styles.active]: isChecked,
          }) }
        />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default SwitchButton;
