import { VastErrors } from './VastErrors';


export default class VideoError extends Error {
  nativeError: any;
  vastError: any;
  message: string;

  constructor({ message = 'Unknown error', nativeError = new Error(message), vastError = VastErrors.UNDEFINED_ERROR }) {
    super();

    this.nativeError = nativeError;
    this.vastError = vastError;
    this.message = message;
  }
}
