import focuser, { FocuserClickHandler } from '@focuser';
import * as cn from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { BarItem } from '~components/MediaItemPage/Description/bar/typings';

import { ICON_BY_BAR_ID, TEXT_BY_BAR_ID } from './item.helpers';
import * as styles from './styles.module.css';


type Props = Readonly<{
  item: BarItem;
  isFocused: boolean;
  onClick?: (item: BarItem) => void;
}>;


const DefaultBarItem: React.FC<Props> = (props: Props) => {
  const formattedMessageID = TEXT_BY_BAR_ID[props.item] || null;
  const Icon = ICON_BY_BAR_ID[props.item];
  const handleClick: FocuserClickHandler = (event) => {
    event.stop()
    event.stopNativeEvent()
    props.onClick?.(props.item);
  };

  return (
    <focuser.FocusableBlock
      isFocused={ props.isFocused }
      className={ cn(styles.barItem, styles[props.item], {
        [styles.focus]: props.isFocused,
      }) }
      onClick={ handleClick }
      noNeedForceFocus
    >
      {Icon && (
        <div className={ styles.iconWrapper }>
          <Icon isFocused={ props.isFocused } />
        </div>
      )}
      {
        formattedMessageID && (
          <div
            className={ styles.barItemText }
          >
            <FormattedMessage id={ formattedMessageID } />
          </div>
        )
      }
    </focuser.FocusableBlock>
  );
};


export default React.memo(DefaultBarItem);
