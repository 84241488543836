import * as cn from 'classnames';
import * as React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import { easeOut } from '~app/variables';

import { BaseImage } from '.';
import useImage from './Image.helper';
import * as styles from './styles.module.css';


type ImageProps = {
  src: string | null;
  className?: string;
  imageClassName?: string;
  durationFadeIn?: number;
};

const Image: React.FC<ImageProps> = ({
                                       src,
                                       className,
                                       imageClassName,
                                       durationFadeIn = 800,
                                     }) => {
  const [items] = useImage({ src });

  const defaultStyle = {
    transition: `opacity ${ durationFadeIn }ms ${ easeOut }`,
    opacity: 0,
    width: '100vw',
    height: '56.250vw'
  }

  const transitionStyles = {
    appearing: { opacity: 0 },
    appeared: { opacity: 1 },
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <TransitionGroup className={ cn(styles.container, className) }>
      {
        [items].map(
          (item) => (
            <Transition
              key={ item?.src || 'image' }
              in={ !!item }
              timeout={ {
                appear: durationFadeIn,
                enter: durationFadeIn,
                exit: durationFadeIn + (durationFadeIn / 2),
              } }
              appear
            >
              {
                (state) => (
                  <BaseImage
                    src={ item?.src || '' }
                    className={ cn(styles.ubdatable, imageClassName) }
                    style={ {
                      ...defaultStyle,
                      ...transitionStyles[state]
                    } }
                  />
                )
              }
            </Transition>
          )
        )
      }
    </TransitionGroup>
  );
};


export { ImageProps };

export default React.memo(Image);
