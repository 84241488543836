import * as React from 'react';

import { isCardChannel, isChannel } from '~lib/objectType';
import Card from '~typings/Card';
import Channel from '~typings/Channel';

import CurrentEventInfoCard from './CurrentEventCard';
import CurrentEventInfoChannel from './CurrentEventChannel';


type Props = Readonly<{
  item: Channel | Card;
  withAnimation?: boolean;
  isHidden?: boolean;
  isBigSize?: boolean;
  isFullSize?: boolean;
  withIcon?: boolean;
  fadeDuration?: number;
  fadeDelay?: number;
}>;


const CurrentEventInfo: React.FC<Props> = (props: Props) => {
  if (isChannel(props.item)) {
    return (
      <CurrentEventInfoChannel
        item={ props.item as Channel }
        withAnimation={ props.withAnimation }
        isHidden={ props.isHidden }
        isBigSize={ props.isBigSize }
        isFullSize={ props.isFullSize }
        withIcon={ props.withIcon }
        fadeDuration={ props.fadeDuration }
        fadeDelay={ props.fadeDelay }
      />
    );
  }

  if (isCardChannel(props.item)) {
    return (
      <CurrentEventInfoCard
        item={ props.item as Card }
        withAnimation={ props.withAnimation }
        isHidden={ props.isHidden }
        isBigSize={ props.isBigSize }
        isFullSize={ props.isFullSize }
        withIcon={ props.withIcon }
        fadeDuration={ props.fadeDuration }
        fadeDelay={ props.fadeDelay }
      />
    );
  }

  return null;
};


export default CurrentEventInfo;
