import focuser from '@focuser';
import * as React from 'react';

import { useMenu } from '~components/Provider/Menu';
import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { useLastViewedEpisode } from '~hooks/fetch/useLastViewedEpisode/useLastViewedEpisode';
import useAccess from '~hooks/useAccess/useAccess';
import useLocationSearch from '~hooks/useLocationSearch';
import Episode from '~typings/Episode';
import ItemObject from '~typings/ItemObject';
import Series from '~typings/Series';

import { RentActivationWarning } from '../RentActivationWarning';
import { doesNeedShowWarningForRent,RentStartWarningStateForSeries } from '../utils';
import { SeriesEpisodeSelect } from './components/SeriesEpisodeSelect';
import { SeriesFrontContent } from './components/SeriesFrontContent';
import * as styles from './SeriesPage.module.css';

type Props = Readonly<{
  item: Series;
  selectedEpisodeID?: string;
}>;

const SeriesPage: React.FC<Props> = (props: Props) => {
  const [contentToShow, setContentToShow] = React.useState<'front' | 'episodes'>(
    props.selectedEpisodeID ? 'episodes' : 'front',
  );
  const [rentActivationWarningState, setRentActivationWarningState] =
    React.useState<RentStartWarningStateForSeries>({ isOpen: false });

  const { nextEpisode: lastViewedEpisode } = useLastViewedEpisode(props.item.id);
  const access = useAccess(
    (lastViewedEpisode || props.item.seasons[0]?.episodes?.[0])?.id,
    ItemObject.Episode
  );

  const { setItem: playerSetItem } = usePlayerStateActions();
  const playerState = usePlayerState();
  const menu = useMenu();


  const allowNavigation = (
    !useLocationSearch()?.player && !menu.isPopupOpened && !playerState.item
  );

  const handleSetPlayerItem = (episode: Episode) => {
    playerSetItem({
      ...episode,
      series: props.item,
    } as Episode);
  };

  const handleEpisodePlay = (episode: Episode) => {
    if (doesNeedShowWarningForRent(access)) {
      setRentActivationWarningState({
        isOpen: true,
        rentPlan: access.reason_object.rent_plan,
        purchaseExpiresAt: access.reason_object.expires_at,
        episode,
      });
      return;
    }

    handleSetPlayerItem(episode);
  };

  if (rentActivationWarningState.isOpen) {
    return (
      <RentActivationWarning
        resourceType={ ItemObject.Series }
        rentPlan={ rentActivationWarningState.rentPlan }
        purchaseExpiresAt={ rentActivationWarningState.purchaseExpiresAt }
        onContinue={ () => {
          setRentActivationWarningState({ isOpen: false });
          handleSetPlayerItem(rentActivationWarningState.episode);
        } }
        onCancel={ () => {
          setRentActivationWarningState({ isOpen: false });
        } }
      />
    );
  }



  const renderInFocuser = (content: JSX.Element) => {
    return <focuser.FocusableBlock isFocused={ allowNavigation } noNeedForceFocus>
      {content}
    </focuser.FocusableBlock>
  }




  if (contentToShow === 'episodes') {
    return renderInFocuser(
      <div className={ styles.seriesEpisodeSelect }>
        <SeriesEpisodeSelect
          onBack={ () => setContentToShow('front') }
          series={ props.item }
          selectedEpisodeID={ props.selectedEpisodeID }
          onEpisodePlay={ handleEpisodePlay }
        />
      </div>,
    );
  }

  return renderInFocuser(
    <SeriesFrontContent
      item={ props.item }
      onOpenEpisodes={ () => setContentToShow('episodes') }
      onEpisodePlay={ handleEpisodePlay }
    />
  );
};

export default React.memo(SeriesPage);
