import React from 'react';

import { AccountTabId } from '../../types';
import { About } from '../Tabs/About/About';
import { Control } from '../Tabs/Control/Control';
import { Devices } from '../Tabs/Devices/Devices';
import { Help } from '../Tabs/Help/Help';
import { Payments } from '../Tabs/Payments/Payments';
import { ProfileTab } from '../Tabs/ProfilesList/ProfilesListWrapper';
import { PromoCodeTab } from '../Tabs/Promocode/Promocode';
import { Purchases } from '../Tabs/Purchases/Purchases';
import { Security } from '../Tabs/Security/Security';
import { Settings } from '../Tabs/Settings/Settings';
import { SignIn } from '../Tabs/SignIn/SignIn';
import { Subscriptions } from '../Tabs/Subscriptions/Subscriptions';
import { ViewStatistics } from '../Tabs/ViewStatistics/ViewStatistics';

interface Props {
  activeTabId: AccountTabId;
}

export const AccountTabSwitcher: React.FC<Props> = React.memo(({ activeTabId }) => {
  switch (activeTabId) {
    case AccountTabId.AccountControl:
      return <Control />;
    case AccountTabId.Profiles:
      return <ProfileTab />;
    case AccountTabId.Subscriptions:
      return <Subscriptions />;
    case AccountTabId.Purchases:
      return <Purchases />;
    case AccountTabId.Payments:
      return <Payments />;
    case AccountTabId.Security:
      return <Security />;
    case AccountTabId.Cards:
      return <div>Cards</div>;
    case AccountTabId.Devices:
      return <Devices />;
    case AccountTabId.Promocode:
      return <PromoCodeTab />;
    case AccountTabId.Settings:
      return <Settings />;
    case AccountTabId.Help:
      return <Help />;
    case AccountTabId.About:
      return <About />;
    case AccountTabId.Signin:
      return <SignIn />;
    case AccountTabId.Stats:
      return <ViewStatistics />;
  }
});

AccountTabSwitcher.displayName = 'AccountTabSwitcher';
