import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useOperatorPayment from '~hooks/fetch/usePayments/useOperatorPayment';
import usePaymentStatus from '~hooks/fetch/usePayments/usePaymentStatus';
import usePromocodePayment from '~hooks/fetch/usePayments/usePromocodePayment';
import * as URLSearchManager from '~lib/URLQueryStringManager';
import ErrorReason from '~typings/ErrorReason';


function usePaymentProceed(invoiceIdFromArgs?: string, checkedOperatorButton?:boolean) {
  const history = useHistory();
  const { invoiceID = invoiceIdFromArgs } = useParams<{ invoiceID: string }>();
  const promocode = URLSearchManager.getField(history.location.search, 'promo_code');
  const isMounted = useRef<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<ErrorReason | null | undefined>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const {
    mutate: operatorMutate,
    data: operatorPayment,
    isError: operatorPaymentError,
  } = useOperatorPayment();
  const {
    mutate: promocodeMutate,
    data: promocodePayment,
    isError: promocodePaymentError,
  } = usePromocodePayment();
  const mutate = promocode && !checkedOperatorButton ? promocodeMutate : operatorMutate;
  const payment = promocode && !checkedOperatorButton ? promocodePayment : operatorPayment;

  const paymentError = promocode ? promocodePaymentError : operatorPaymentError;
  const { data: paymentStatus, isError: paymentStatusError } = usePaymentStatus(
    !(isSuccess || isError) ? payment?.data?.id : undefined
  );

  useEffect(() => {
    if (invoiceID && !isMounted.current) {
      mutate(invoiceID);
      isMounted.current = true;
    }
  }, [invoiceID, isMounted, promocode]);

  useEffect(() => {
    if (paymentStatus?.data?.[0]?.status === 'success'
      || payment?.data?.status === 'success'
      || promocodePayment && payment?.meta?.status === 201) {
      setIsSuccess(true);
    }
  }, [paymentStatus?.data?.[0]?.status, payment?.meta.status]);

  useEffect(() => {
    if (paymentError || paymentStatusError || paymentStatus?.data?.[0]?.status === 'error') {
      setIsError(true);
      setError(paymentStatus?.data?.[0].error_reason);
    }
  }, [paymentError, paymentStatusError, paymentStatus?.data?.[0]?.status]);

  return {
    isError,
    isSuccess,
    error,
  } as const;
}


export default usePaymentProceed;
