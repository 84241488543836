import focuser, { FocuserKeyHandler } from "@focuser";
import React from "react";
import { InjectedIntl, injectIntl } from "react-intl";

import { PrimaryButtonFocuser } from "~app/ui-kit/PrimaryButton";
import { Typography } from "~app/ui-kit/Typography";
import {
  NEW_PIN_CODE_FLOW_ASK_BUTTON_ON,
  NEW_PIN_CODE_FLOW_ASK_BUTTON_SETUP,
  NEW_PIN_CODE_FLOW_ASK_BUTTON_SKIP,
  NEW_PIN_CODE_FLOW_ASK_MESSAGE,
  NEW_PIN_CODE_FLOW_ASK_PARENTAL_CONTROL_HEADER,
  NEW_PIN_CODE_FLOW_ASK_PIN_HEADER,
} from "~localization";
import { AdultIcon } from "~newapp/ui-kit/Icons/For_message/AdultIcon";

import * as css from './styles.modules.css'

type FocusedItem = 'left' | 'right'

type Props = {
  from: 'parental' | 'pin-code'
  intl: InjectedIntl
  handleActivatePinOrParental: () => void
  handleSkipToWatch: () => void
  onBack: FocuserKeyHandler
}

const PinOrParentalWarning = ({ from, intl, handleActivatePinOrParental, handleSkipToWatch, onBack }: Props) => {
  const [focusedItem, setFocusedItem] = React.useState<FocusedItem>('left');

  return (
    <>
      <focuser.FocusableBlock
        isFocused
        onKeyLeft={ () => setFocusedItem('left') }
        onKeyRight={ () => setFocusedItem('right') }
        className={ css.mainWrapper }
        noNeedForceFocus
        onKeyReturn={ onBack }
      >
        <div className={ css.pinOrParentalWrapper }>
          <AdultIcon />
          <Typography className={ css.pinOrParentalHeader } variant={ 'h5_medium' }>
            {
              from === 'parental'
                ? intl.formatMessage({ id: NEW_PIN_CODE_FLOW_ASK_PARENTAL_CONTROL_HEADER })
                : intl.formatMessage({ id: NEW_PIN_CODE_FLOW_ASK_PIN_HEADER })
            }
          </Typography>
          <Typography variant={ 'h5_medium' } className={ css.text }>
            { intl.formatMessage({ id: NEW_PIN_CODE_FLOW_ASK_MESSAGE }) }
          </Typography>
          <div className={ css.buttonWrapper }>
            <div className={ css.leftButtonWrapper }>
              <PrimaryButtonFocuser
                emitForceFocusOnHover
                buttonClassName={ css.button }
                isFocused={ focusedItem === 'left' }
                onClick={ handleActivatePinOrParental }
                onForceFocus={ () => setFocusedItem('left') }
              >
                {
                  from === 'parental'
                    ? intl.formatMessage({ id: NEW_PIN_CODE_FLOW_ASK_BUTTON_ON })
                    : intl.formatMessage({ id: NEW_PIN_CODE_FLOW_ASK_BUTTON_SETUP })
                }
              </PrimaryButtonFocuser>
            </div>
            <div className={ css.rightButtonWrapper }>
              <PrimaryButtonFocuser
                emitForceFocusOnHover
                buttonClassName={ css.button }
                isFocused={ focusedItem === 'right' }
                onClick={ handleSkipToWatch }
                onForceFocus={ () => setFocusedItem('right') }
              >
                { intl.formatMessage({ id: NEW_PIN_CODE_FLOW_ASK_BUTTON_SKIP }) }
              </PrimaryButtonFocuser>
            </div>
          </div>
        </div>
      </focuser.FocusableBlock>
    </>
  )
}

export const PinOrParentalWarning$ = injectIntl(PinOrParentalWarning)