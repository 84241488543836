import { QueryKey, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';

import { getAuthData } from '../useAccount';


const getSuggestions = async (query, cacheData, client, srchPageRsrceType) => {
  if (cacheData) {
    return cacheData;
  }

  let url = `/v1/search/suggestions`;
  const options = {
    query,
    'filter[resource_type_in]': srchPageRsrceType ? srchPageRsrceType : 'channels,program_events,movies,series',
    access_token: getAuthData().accessToken,
  };

  const { data } = await client.get(url, options);

  return data;
};

function useSuggestions(query?: string, srchPageRsrceType: string | undefined = undefined): any {
  const queryKey: QueryKey = ['search_suggestions', query,];
  const client = useClient();

  const cacheData = queryClient.getQueryData(queryKey);

  return useQuery<Array<{ value: string; object: string }>>({
    queryKey,
    queryFn: () => getSuggestions(query, cacheData, client, srchPageRsrceType),
    enabled: !!query && query.length > 0,
    notifyOnChangeProps: ['data', 'isLoading'],
  });
}


export {
  useSuggestions
}
