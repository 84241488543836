import { FocuserKeyHandler } from '@focuser';
import { useEffect, useMemo, useState } from 'react';

export const COLUMNS_COUNT = 4;

export const indexToFocusedState = (index: number): FocusedState => {
  if (index < 0) {
    return { focusedLine: 0, focusedCol: 0 };
  }

  return {
    focusedLine: Math.max(Math.floor(index / COLUMNS_COUNT), 0),
    focusedCol: Math.max(index % COLUMNS_COUNT, 0),
  };
};

type FocusedState = {
  focusedLine: number;
  focusedCol: number;
};

type useAvatarsNavigationProps = {
  preSelectedIdx: number;
  avatarsCount: number;
};

export const useAvatarsNavigation = ({
  preSelectedIdx: preselectedIdx,
  avatarsCount,
}: useAvatarsNavigationProps) => {
  const lastLineIdx = Math.floor((avatarsCount - 1) / COLUMNS_COUNT);
  const lastLineColumnMaxIdx = avatarsCount - 1 - lastLineIdx * COLUMNS_COUNT;

  const [focusedState, setFocusedState] = useState<FocusedState>(() =>
    indexToFocusedState(preselectedIdx),
  );

  useEffect(() => {
    if (preselectedIdx < 0) return;
    setFocusedState(indexToFocusedState(preselectedIdx));
  }, [preselectedIdx]);

  const downLineToFocus = useMemo(() => {
    if (focusedState.focusedLine >= lastLineIdx) {
      return null;
    }

    return focusedState.focusedLine + 1;
  }, [focusedState.focusedLine, lastLineIdx]);

  const rightColToFocus = useMemo(() => {
    if (focusedState.focusedLine === lastLineIdx) {
      const nextCol = Math.min(
        lastLineColumnMaxIdx,
        focusedState.focusedCol + 1,
        COLUMNS_COUNT - 1,
      );
      if (focusedState.focusedCol === nextCol) {
        return null;
      }

      return nextCol;
    }

    const nextCol = Math.min(focusedState.focusedCol + 1, COLUMNS_COUNT - 1);

    return nextCol === focusedState.focusedCol ? null : nextCol;
  }, [focusedState, lastLineIdx]);

  const upLineToFocus = useMemo(() => {
    if (focusedState.focusedLine <= 0) {
      return null;
    }

    return focusedState.focusedLine - 1;
  }, [focusedState.focusedLine]);

  const leftColToFocus = useMemo(() => {
    if (focusedState.focusedCol <= 0) {
      return null;
    }
    return focusedState.focusedCol - 1;
  }, [focusedState.focusedCol]);

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (downLineToFocus === null) {
      return;
    }
    const nextCol =
      downLineToFocus === lastLineIdx
        ? Math.min(lastLineColumnMaxIdx, focusedState.focusedCol)
        : focusedState.focusedCol;
    setFocusedState({ focusedLine: downLineToFocus, focusedCol: nextCol });
    e.stop();
  };

  const onKeyUp: FocuserKeyHandler = (e) => {
    if (upLineToFocus === null) {
      return;
    }
    setFocusedState({ focusedLine: upLineToFocus, focusedCol: focusedState.focusedCol });
    e.stop();
  };

  const onKeyLeft: FocuserKeyHandler = (e) => {
    if (leftColToFocus === null) {
      return;
    }
    setFocusedState({ focusedLine: focusedState.focusedLine, focusedCol: leftColToFocus });
    e.stop();
  };

  const onKeyRight: FocuserKeyHandler = (e) => {
    if (rightColToFocus === null) {
      return;
    }
    setFocusedState({ focusedLine: focusedState.focusedLine, focusedCol: rightColToFocus });
    e.stop();
  };

  const isPointerUpAvailable = upLineToFocus !== null;
  const isPointerDownAvailable = downLineToFocus !== null;
  const isPointerLeftAvailable = leftColToFocus !== null;
  const isPointerRightAvailable = rightColToFocus !== null;

  return {
    onKeyDown,
    onKeyUp,
    onKeyLeft,
    onKeyRight,
    isPointerUpAvailable,
    isPointerDownAvailable,
    isPointerLeftAvailable,
    isPointerRightAvailable,
    focusedState,
    setFocusedState,
  };
};
