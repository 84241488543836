import * as React from 'react';

import BlockPageItems from '~components/BlockPage/items';
import { ActionType as MenuActionType, useMenu, useMenuAction } from '~components/Provider/Menu';
import SceneManagerByIndex from '~components/Scene/ManagerByIndex';
import { useMainPageID } from '~hooks/fetch/usePages/usePages';
import { PageInfo } from '~hooks/usePageInfo';
import useSceneMediaItemPageInfo from '~hooks/useSceneMediaItemPage';
import useNavigation from '~stores/Navigation';
import usePointer from '~stores/Pointer';
import { Block } from '~typings/Block';
import NavigationDirection from '~typings/NavigationDirection';
import Page from '~typings/Page';

import BlockPageNavigation from './navigation';


type Props = Readonly<{
  page: Page;
  pageInfo: PageInfo;
  blocks: Block[];
  isPopupOpened: boolean;
}>;


const BlockPageWithHooks: React.FC<Props> = (props: Props) => {
  const { page, pageInfo, blocks, isPopupOpened } = props;
  const mainPageID = useMainPageID() || null;
  const menuAction = useMenuAction();
  const { isMenuAppeared } = useMenu();
  const { isSceneMediaItemPageMode } = useSceneMediaItemPageInfo();
  const focusedBlockID = useNavigation(state => state.getFocusedBlockID());
  const getFocusedBlock = useNavigation(state => state.getFocusedBlockIndex);
  const itemsCount = useNavigation(state => state.getFocused()?.itemsCount) || 0;
  // show/hide pointer navigation
  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);
  const flush = usePointer(state => state.flush);
  const isMediaItemPage = !!pageInfo.mediaItemPage.id;
  const isMediaItemDetailsPage = pageInfo.isMediaItemDetailsPage;
  const isAllowedNavigation = (!isSceneMediaItemPageMode && !isPopupOpened && !isMediaItemDetailsPage);
  const prevBlockID = useNavigation(state => state.getPrevFocusedBlockID());
  const nextBlockID = useNavigation(state => state.getNextFocusedBlockID());

  React.useEffect(() => {
    if (pointerEnabled && !isMediaItemPage) {
      const isFocusOnFirst = !prevBlockID;
      const isFocusOnLast = !nextBlockID;

      updateDirections({
        [NavigationDirection.Up]: isAllowedNavigation && !isFocusOnFirst,
        [NavigationDirection.Down]: isAllowedNavigation && !isFocusOnLast,
        [NavigationDirection.Left]: false,
        [NavigationDirection.Right]: false,
      });
    }
  }, [
    isMediaItemPage,
    pointerEnabled,
    updateDirections,
    prevBlockID,
    nextBlockID,
    itemsCount,
    isAllowedNavigation,
  ]);

  React.useEffect(() => () => flush([
    NavigationDirection.Left,
    NavigationDirection.Right,
    NavigationDirection.Up,
    NavigationDirection.Down,
  ]), []);

  React.useEffect(() => {
    if (isMediaItemPage) {
      menuAction({
        type: MenuActionType.ChangeMenuAppearing,
        isMenuAppeared: false,
      });
    }
  }, [isMediaItemPage]);

  React.useEffect(() => {
    const newValue = (props.page.id === mainPageID) && (getFocusedBlock() === 0);
    if (!isMediaItemPage && isMenuAppeared !== newValue) {
      menuAction({
        type: MenuActionType.ChangeMenuAppearing,
        isMenuAppeared: newValue,
      });
    }
  }, [isMediaItemPage, props.page.id, mainPageID, focusedBlockID, isMenuAppeared]);

  const renderContent = (index, focusedBlockID) => {
    return <>
      {
        (!isSceneMediaItemPageMode) &&
          <SceneManagerByIndex
              isFetchingDone
              focusedBlockID={ focusedBlockID }
              page={ page }
              focusedItemIndex={ index }
          />
      }
      <BlockPageItems
        isPopupOpened={ isPopupOpened }
        isSceneMediaItemPageMode={ isSceneMediaItemPageMode }
        page={ page }
        blocks={ blocks }
        focusedBlockID={ focusedBlockID }
        focusedItemIndex={ index }
      />
    </>
  };

  return (
    <BlockPageNavigation
      mainPageID={ mainPageID }
      pageId={ page.id }
      isMediaItemPage={ !!pageInfo.mediaItemPage.id }
      isAllowedMenuRender={ pageInfo.isAllowedMenuRender }
      isAllowedNavigation={ isAllowedNavigation }
    >

      { renderContent }
    </BlockPageNavigation>
  )
};


export default BlockPageWithHooks;
