import create from 'zustand';

import ProgramEvent from '~typings/Event';

interface state {
  missingSeconds: number;
  searchDate: number;
  setSearchDate: (value: number) => void;
  currentProgram: ProgramEvent[] | null;
  startTime: number | null;
  playPauseButton: boolean | null;
  isLive: boolean;
  setIsLive: (value: boolean) => void;
  setPlayPauseButton: (value: boolean) => void; // true = play, false = pause, состояние кнопки
  setStartTime: (value: number) => void;
  setCurrentProgram: (value: ProgramEvent[] | null) => void;
  setMissingSeconds: (value: number) => void;
  setPlusOne: () => void;
}

export const usePlayerStore = create<state>(
  (set, get) => ({
    missingSeconds: 0,
    currentProgram: null,
    isLive: true,
    startTime: null,
    searchDate: 0,
    playPauseButton: true,
    setIsLive: (value: boolean) => set(state => state.isLive = value),
    setPlayPauseButton: (value: boolean| null) => set(state => state.playPauseButton = value ),
    setSearchDate: (value: number) => set(() => get().searchDate = value),
    setStartTime: (value: number) => set(() => get().startTime = value),
    setCurrentProgram: (value: ProgramEvent[] | null) => set(() => get().currentProgram = value),
    setMissingSeconds: (value: number) => set(() => get().missingSeconds = value),
    setPlusOne: () => set((draft: state) => draft.missingSeconds = get().missingSeconds + 1)
  })
)