import * as React from 'react';

import { useInterval } from '~hooks/useInterval';

export const useCountdownCounter = (): [number, (n: number) => void] => {
  const [ counter, setCounter ] = React.useState<number>(0);

  useInterval(() => {
    setCounter(oldVal => oldVal > 1 ? oldVal - 1 : 0)
  }, counter > 0 ? 1000 : null);

  return [counter, setCounter];
};
