/* eslint-disable max-len */
import { ac_00, gw_00, gw_02, gw_11 } from "customization-project-name/colors";
import * as React from 'react';

type Props = {
  width?: string
  height?: string
  isFocused: boolean
}

const IconChipsEpg = ({ width = '6.111111111111111vh', height = '6.111111111111111vh', isFocused }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={ isFocused ? ac_00 : gw_02 }/>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M10 15C10 13.8954 10.8954 13 12 13H32C33.1046 13 34 13.8954 34 15V29C34 30.1046 33.1046 31 32 31H12C10.8954 31 10 30.1046 10 29V15ZM18 17C18.5523 17 19 17.4477 19 18C19 18.5523 18.5523 19 18 19L15 19C14.4477 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17L18 17ZM19 22C19 21.4477 18.5523 21 18 21L15 21C14.4477 21 14 21.4477 14 22C14 22.5523 14.4477 23 15 23L18 23C18.5523 23 19 22.5523 19 22ZM18 25C18.5523 25 19 25.4477 19 26C19 26.5523 18.5523 27 18 27L15 27C14.4477 27 14 26.5523 14 26C14 25.4477 14.4477 25 15 25L18 25ZM29 25C29.5523 25 30 25.4477 30 26C30 26.5523 29.5523 27 29 27L22 27C21.4477 27 21 26.5523 21 26C21 25.4477 21.4477 25 22 25L29 25ZM30 22C30 21.4477 29.5523 21 29 21L22 21C21.4477 21 21 21.4477 21 22C21 22.5523 21.4477 23 22 23L29 23C29.5523 23 30 22.5523 30 22ZM29 17C29.5523 17 30 17.4477 30 18C30 18.5523 29.5523 19 29 19L22 19C21.4477 19 21 18.5523 21 18C21 17.4477 21.4477 17 22 17L29 17Z" 
        fill={ isFocused ? gw_00 : gw_11 }
      />
    </svg>
  </div>
);


export { IconChipsEpg };
