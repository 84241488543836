import focuser from '@focuser';
import * as cn from 'classnames';
import { chunk } from 'lodash';
import * as React from 'react';

import ProfileImage from '~components/ProfileImage';
import useAvatars from '~hooks/fetch/useAvatars/useAvatars';
import Avatar from '~typings/Avatar';
import SelectedIcon from '~ui/Icon/icons/SelectedIcon';
import { PopupIsolated } from '~ui/Popup/PopupInSandbox';

import { COLUMNS_COUNT, useAvatarsNavigation } from './helper';
import * as styles from './styles.module.css';

type Props = Readonly<{
  selectedAvatar?: string | null;
  title: string;
  onSelect: (imageId?: string) => void;
  onBack?: () => void;
}>;

const AvatarsGallery: React.FC<Props> = (props: Props) => {
  const { data: avatarsData } = useAvatars();

  const preselectedIdx = React.useMemo(() => {
    if (!props.selectedAvatar) return -1;
    return avatarsData?.data.findIndex((avatar) => avatar.id === props.selectedAvatar) ?? -1;
  }, [props.selectedAvatar, avatarsData?.data]);

  const { focusedState, setFocusedState, ...focuserProps } = useAvatarsNavigation({
    preSelectedIdx: preselectedIdx,
    avatarsCount: avatarsData?.data.length ?? 0,
  });

  const avatarsDataByChanks = React.useMemo(() => {
    return chunk(avatarsData?.data ?? [], COLUMNS_COUNT);
  }, [avatarsData?.data]);

  const renderAvatarLine = (avatars: Avatar[], lineIdx: number) => {
    const isFocusedLine = focusedState.focusedLine === lineIdx;

    return (
      <focuser.FocusableBlock
        isFocused={ isFocusedLine }
        key={ `line_${lineIdx}` }
        className={ styles.avatarLine }
        noNeedForceFocus
      >
        {avatars.map((avatar, idx) => (
          <focuser.FocusableBlock
            onClick={ () => props.onSelect(avatar.id) }
            isFocused={ isFocusedLine && focusedState.focusedCol === idx }
            className={ styles.avatarWrap }
            key={ `avatar_${avatar.id}` }
            onForceFocus={ () => setFocusedState({ focusedLine: lineIdx, focusedCol: idx }) }
            emitForceFocusOnHover
          >
            {({ isFocused }) => (
              <ProfileImage
                className={ cn(styles.avatar, { [styles.focused]: isFocused }) }
                avatar={ avatar }
                isFocused={ isFocused }
                OverflowIcon={ avatar.id === props.selectedAvatar ? SelectedIcon : undefined }
                overflowIconClassName={ styles.selectedIcon }
              />
            )}
          </focuser.FocusableBlock>
        ))}
      </focuser.FocusableBlock>
    );
  };

  return (
    <PopupIsolated focuserClassName={ styles.avatarsPopup }>
      <div className={ styles.avatarsGallery }>
        <div className={ styles.popupTitle }>{props.title}</div>
        <focuser.FocusableBlock
          onKeyReturn={ props.onBack }
          isFocused
          { ...focuserProps }
          className={ styles.avatars }
          noNeedForceFocus
        >
          {avatarsDataByChanks.map((avatars, idx) => renderAvatarLine(avatars, idx))}
        </focuser.FocusableBlock>
      </div>
    </PopupIsolated>
  );
};

export default AvatarsGallery;
