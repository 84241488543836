export const enum KeyBoardType {
  type0 = 'type0'  //клава на поиске
}

export const enum KeyBoardLayout {
  Ru = 'ru',
  En = 'en',
  Digits = 'dig'
}
/* eslint-disable max-len */

export const enum KeyType {
  Char = 'char',
  Icon = 'icon'
}

export const enum KeyIcon {
  SwitchLayout = 'switch_layout',
  BackSpace = 'backspace',
  Space = ' ',
  Search = 'search',
}

type B = string | string[]
type C = [B, B, B, B]

// id
// контент элемента
// иногда используется как сочетание с type для иконок

// type
// каждый элемент клавиатуры может быть символом или логической кнопкой. логика взаимодействий с ними различается

// position
// на примере раскладки клавиатуры type0 (поиск) можно понять что это массив с линиями в которых лежат кнопки
// таким образом у каждой кнопки есть своя позиция в виде строки например '0-0'
// первая цифра отвечает за номер линии а вторая за номер элемента в этой линии
// отсчет линий и элементов начинается с нуля
// для верхнего ряда из трех кнопок мы получим значения '0-0', '0-1', '0-2' соответственно
// для буквы "Ы" значения будут '5-4'

// potentialMove
// блокировка переходов с данной клавиши
// отвечает за переходы [вверх, вправо, вниз, влево]

// dir
// координаты соседних клавиш на которые можно логически перейти. может содержать строку или массив строк.
// в массиве всегда 4 элемента на которые упадет фокус при нажатии [вверх, вправо, вниз, влево].
// когда указан массив, это значит что перейти можно на 2 или более кнопок, указываются их координаты и далее в навигации вычисляется
// можно ли перейти на эту клавишу с помощь свойства potentialMove
// например клавиша '0-0' и соседние клавиши [['6-0', '6-1'], '0-1', ['1-0', '1-1'], '0-2']
// при нажатии наверх мы могли бы перейти на клавиши ['6-0', '6-1'] но potentialMove [false, true, true, false] запрещает нам это
// у крайних элементов таких как '0-0' (первый и последний ряд, первый и последний элемент) есть все направления возможного перехода
// с клавиши '0-0', логически при нажатии вверх можно перейти на клавишу 'Э' и 'Ю' с координатами ['6-0', '6-1'] при условии что клавиатура зациклена
// сейчас логика зацикливания не реализована поэтому и эти координаты не используются, но все равно указаны

// previousNavDir
// направление с которого мы пришли, по дефолту '', используется в момент вычисления и установки следующей кнопки
// нужно чтобы определить на какую кнопку перейти когда возможный ход предполагает две клавиши
// например клавиша с координатами '0-1' и возможными ходами ['6-2', '0-2', ['1-2', '1-3'], '0-0']
// при нажатии вниз мы можем перейти на ['1-2', '1-3'], но как определить на какую клавишу перейти?
// например при активной клавише '0-0' и нажатии вправо, осуществляется переход на '0-1' (смотрим dir)
// клавиша '0-1' устанавливается как активная а previousNavDir устанавливается как 'right' ведь мы нажали вправо
// далее находясь на '0-1' мы нажимаем вниз и тут нам нужно определить какую клавишу сделать активной, для этого используем previousNavDir
// логичное поведение предполагает что если мы зашли на клавишу слева (нажав вправо на предыдущей т.е. previousNavDir = 'right')
// то и сделать активной нужно '1-2' и соответственно если мы зашли на клавишу справа (previousNavDir = 'left')
// то и сделать активной нужно '1-3'. данная логика работает для всех клавиш
// все просто! =)

// switchTo
// это для клавиш переключения раскладки

// disabled
// предполагается что некоторые клавиши можно выключать и делать неактивными, но сейчас этой нужды нет и данная логика не реализована

interface KeyBoardKey {
  id: string
  type: KeyType;
  position: string
  dir: C;
  potentialMove: [boolean, boolean, boolean, boolean];
  previousNavDir: 'up' | 'right' | 'down' | 'left' | undefined
  previousKey: string
  switchTo?: KeyBoardLayout | undefined
  disabled?: boolean;
}

// шорткаты нужны только для ввода с клавиатуры (удобно для десктопов)
const type0_ru_shortcut = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Ъ', 'Ы', 'Ь', 'Э', 'Ю', 'Я', ' ']

const type0_en_shortcut = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', ' ']

const type0_dig_shortcut = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '/', '$', '&', '#', '(', ')', '@', '!', '%', '+', '-', '"', '*', ':', '.', ',', ' ']

const type0_ru: KeyBoardKey[][] = [
  [
    {
      type: KeyType.Icon,
      id: KeyIcon.SwitchLayout,
      position: '0-0',
      dir: [['6-0', '6-1'], '0-1', ['1-0', '1-1'], '0-2'],
      potentialMove: [false, true, true, false],
      previousNavDir: undefined,
      previousKey: '',
      switchTo: KeyBoardLayout.Digits
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.SwitchLayout,
      position: '0-1',
      dir: ['6-2', '0-2', ['1-2', '1-3'], '0-0'],
      potentialMove: [false, true, true, true],
      previousNavDir: undefined,
      previousKey: '',
      switchTo: KeyBoardLayout.En
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.BackSpace,
      position: '0-2',
      dir: ['6-3', '0-0', ['1-4', '1-5'], '0-1'],
      potentialMove: [false, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },

  ],
  [
    {
      type: KeyType.Char,
      id: 'А',
      position: '1-0',
      dir: ['0-0', '1-1', '2-0', '1-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Б',
      position: '1-1',
      dir: ['0-0', '1-2', '2-1', '1-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'В',
      position: '1-2',
      dir: ['0-1', '1-3', '2-2', '1-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Г',
      position: '1-3',
      dir: ['0-1', '1-4', '2-3', '1-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Д',
      position: '1-4',
      dir: ['0-2', '1-5', '2-4', '1-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Е',
      position: '1-5',
      dir: ['0-2', '1-0', '2-5', '1-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: 'Ё',
      position: '2-0',
      dir: ['1-0', '2-1', '3-0', '2-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Ж',
      position: '2-1',
      dir: ['1-1', '2-2', '3-1', '2-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'З',
      position: '2-2',
      dir: ['1-2', '2-3', '3-2', '2-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'И',
      position: '2-3',
      dir: ['1-3', '2-4', '3-3', '2-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Й',
      position: '2-4',
      dir: ['1-4', '2-5', '3-4', '2-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'К',
      position: '2-5',
      dir: ['1-5', '2-0', '3-5', '2-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: 'Л',
      position: '3-0',
      dir: ['2-0', '3-1', '4-0', '3-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'М',
      position: '3-1',
      dir: ['2-1', '3-2', '4-1', '3-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Н',
      position: '3-2',
      dir: ['2-2', '3-3', '4-2', '3-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'О',
      position: '3-3',
      dir: ['2-3', '3-4', '4-3', '3-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'П',
      position: '3-4',
      dir: ['2-4', '3-5', '4-4', '3-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Р',
      position: '3-5',
      dir: ['2-5', '3-0', '4-5', '3-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: 'С',
      position: '4-0',
      dir: ['3-0', '4-1', '5-0', '4-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Т',
      position: '4-1',
      dir: ['3-1', '4-2', '5-1', '4-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'У',
      position: '4-2',
      dir: ['3-2', '4-3', '5-2', '4-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Ф',
      position: '4-3',
      dir: ['3-3', '4-4', '5-3', '4-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Х',
      position: '4-4',
      dir: ['3-4', '4-5', '5-4', '4-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Ц',
      position: '4-5',
      dir: ['3-5', '4-0', '5-5', '4-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: 'Ч',
      position: '5-0',
      dir: ['4-0', '5-1', '6-0', '5-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Ш',
      position: '5-1',
      dir: ['4-1', '5-2', '6-1', '5-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Щ',
      position: '5-2',
      dir: ['4-2', '5-3', '6-2', '5-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Ъ',
      position: '5-3',
      dir: ['4-3', '5-4', '6-3', '5-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Ы',
      position: '5-4',
      dir: ['4-4', '5-5', '6-4', '5-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Ь',
      position: '5-5',
      dir: ['4-5', '5-0', '6-4', '5-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
    type: KeyType.Char,
    id: 'Э',
    position: '6-0',
    dir: ['5-0', '6-1', '0-0', '6-4'],
    potentialMove: [true, true, false, false],
    previousNavDir: undefined,
    previousKey: ''
  },
    {
      type: KeyType.Char,
      id: 'Ю',
      position: '6-1',
      dir: ['5-1', '6-2', '0-0', '6-0'],
      potentialMove: [true, true, false, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Я',
      position: '6-2',
      dir: ['5-2', '6-3', '0-1', '6-1'],
      potentialMove: [true, true, false, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.Space,
      position: '6-3',
      dir: ['5-3', '6-4', '0-1', '6-2'],
      potentialMove: [true, true, false, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.Search,
      position: '6-4',
      dir: [['5-4', '5-5'], '6-0', '0-2', '6-3'],
      potentialMove: [true, false, false, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
];

const type0_en: KeyBoardKey[][] = [
  [
    {
      type: KeyType.Icon,
      id: KeyIcon.SwitchLayout,
      position: '0-0',
      dir: [['6-0', '6-1'], '0-1', ['1-0', '1-1'], '0-2'],
      potentialMove: [false, true, true, false],
      previousNavDir: undefined,
      previousKey: '',
      switchTo: KeyBoardLayout.Digits
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.SwitchLayout,
      position: '0-1',
      dir: ['6-2', '0-2', ['1-2', '1-3'], '0-0'],
      potentialMove: [false, true, true, true],
      previousNavDir: undefined,
      previousKey: '',
      switchTo: KeyBoardLayout.Ru
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.BackSpace,
      position: '0-2',
      dir: ['6-3', '0-0', ['1-4', '1-5'], '0-1'],
      potentialMove: [false, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },

  ],
  [
    {
      type: KeyType.Char,
      id: 'A',
      position: '1-0',
      dir: ['0-0', '1-1', '2-0', '1-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'B',
      position: '1-1',
      dir: ['0-0', '1-2', '2-1', '1-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'C',
      position: '1-2',
      dir: ['0-1', '1-3', '2-2', '1-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'D',
      position: '1-3',
      dir: ['0-1', '1-4', '2-3', '1-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'E',
      position: '1-4',
      dir: ['0-2', '1-5', '2-4', '1-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'F',
      position: '1-5',
      dir: ['0-2', '1-0', '2-5', '1-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: 'G',
      position: '2-0',
      dir: ['1-0', '2-1', '3-0', '2-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'H',
      position: '2-1',
      dir: ['1-1', '2-2', '3-1', '2-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'I',
      position: '2-2',
      dir: ['1-2', '2-3', '3-2', '2-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'J',
      position: '2-3',
      dir: ['1-3', '2-4', '3-3', '2-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'K',
      position: '2-4',
      dir: ['1-4', '2-5', '3-4', '2-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'L',
      position: '2-5',
      dir: ['1-5', '2-0', '3-5', '2-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: 'M',
      position: '3-0',
      dir: ['2-0', '3-1', '4-0', '3-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'N',
      position: '3-1',
      dir: ['2-1', '3-2', '4-1', '3-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'O',
      position: '3-2',
      dir: ['2-2', '3-3', '4-2', '3-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'P',
      position: '3-3',
      dir: ['2-3', '3-4', '4-3', '3-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Q',
      position: '3-4',
      dir: ['2-4', '3-5', '4-4', '3-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'R',
      position: '3-5',
      dir: ['2-5', '3-0', '4-5', '3-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: 'S',
      position: '4-0',
      dir: ['3-0', '4-1', '5-0', '4-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'T',
      position: '4-1',
      dir: ['3-1', '4-2', '5-1', '4-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'U',
      position: '4-2',
      dir: ['3-2', '4-3', '5-2', '4-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'V',
      position: '4-3',
      dir: ['3-3', '4-4', '5-2', '4-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'W',
      position: '4-4',
      dir: ['3-4', '4-5', '5-3', '4-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'X',
      position: '4-5',
      dir: ['3-5', '4-0', '5-3', '4-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: 'Y',
      position: '5-0',
      dir: ['4-0', '5-1', '0-0', '5-3'],
      potentialMove: [true, true, false, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: 'Z',
      position: '5-1',
      dir: ['4-1', '5-2', '0-0', '5-0'],
      potentialMove: [true, true, false, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.Space,
      position: '5-2',
      dir: [['4-2', '4-3'], '5-3', '0-1', '5-1'],
      potentialMove: [true, true, false, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.Search,
      position: '5-3',
      dir: [['4-4', '4-5'], '5-0', '0-2', '5-2'],
      potentialMove: [true, false, false, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
];

const type0_dig: KeyBoardKey[][] = [
  [
    {
      type: KeyType.Icon,
      id: KeyIcon.SwitchLayout,
      position: '0-0',
      dir: [['6-0', '6-1'], '0-1', ['1-0', '1-1'], '0-2'],
      potentialMove: [false, true, true, false],
      previousNavDir: undefined,
      previousKey: '',
      switchTo: KeyBoardLayout.Ru
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.SwitchLayout,
      position: '0-1',
      dir: ['6-2', '0-2', ['1-2', '1-3'], '0-0'],
      potentialMove: [false, true, true, true],
      previousNavDir: undefined,
      previousKey: '',
      switchTo: KeyBoardLayout.En
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.BackSpace,
      position: '0-2',
      dir: ['6-3', '0-0', ['1-4', '1-5'], '0-1'],
      potentialMove: [false, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },

  ],
  [
    {
      type: KeyType.Char,
      id: '1',
      position: '1-0',
      dir: ['0-0', '1-1', '2-0', '1-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '2',
      position: '1-1',
      dir: ['0-0', '1-2', '2-1', '1-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '3',
      position: '1-2',
      dir: ['0-1', '1-3', '2-2', '1-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '4',
      position: '1-3',
      dir: ['0-1', '1-4', '2-3', '1-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '5',
      position: '1-4',
      dir: ['0-2', '1-5', '2-4', '1-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '6',
      position: '1-5',
      dir: ['0-2', '1-0', '2-5', '1-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: '7',
      position: '2-0',
      dir: ['1-0', '2-1', '3-0', '2-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '8',
      position: '2-1',
      dir: ['1-1', '2-2', '3-1', '2-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '9',
      position: '2-2',
      dir: ['1-2', '2-3', '3-2', '2-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '0',
      position: '2-3',
      dir: ['1-3', '2-4', '3-3', '2-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '/',
      position: '2-4',
      dir: ['1-4', '2-5', '3-4', '2-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '$',
      position: '2-5',
      dir: ['1-5', '2-0', '3-5', '2-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: '&',
      position: '3-0',
      dir: ['2-0', '3-1', '4-0', '3-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '#',
      position: '3-1',
      dir: ['2-1', '3-2', '4-1', '3-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '(',
      position: '3-2',
      dir: ['2-2', '3-3', '4-2', '3-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: ')',
      position: '3-3',
      dir: ['2-3', '3-4', '4-3', '3-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '@',
      position: '3-4',
      dir: ['2-4', '3-5', '4-4', '3-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '!',
      position: '3-5',
      dir: ['2-5', '3-0', '4-5', '3-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: '%',
      position: '4-0',
      dir: ['3-0', '4-1', '5-0', '4-5'],
      potentialMove: [true, true, true, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '+',
      position: '4-1',
      dir: ['3-1', '4-2', '5-1', '4-0'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '-',
      position: '4-2',
      dir: ['3-2', '4-3', '5-2', '4-1'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '"',
      position: '4-3',
      dir: ['3-3', '4-4', '5-2', '4-2'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: '*',
      position: '4-4',
      dir: ['3-4', '4-5', '5-3', '4-3'],
      potentialMove: [true, true, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: ':',
      position: '4-5',
      dir: ['3-5', '4-0', '5-3', '4-4'],
      potentialMove: [true, false, true, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
  [
    {
      type: KeyType.Char,
      id: '.',
      position: '5-0',
      dir: ['4-0', '5-1', '0-0', '5-3'],
      potentialMove: [true, true, false, false],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Char,
      id: ',',
      position: '5-1',
      dir: ['4-1', '5-2', '0-0', '5-0'],
      potentialMove: [true, true, false, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.Space,
      position: '5-2',
      dir: [['4-2', '4-3'], '5-3', '0-1', '5-1'],
      potentialMove: [true, true, false, true],
      previousNavDir: undefined,
      previousKey: ''
    },
    {
      type: KeyType.Icon,
      id: KeyIcon.Search,
      position: '5-3',
      dir: [['4-4', '4-5'], '5-0', '0-2', '5-2'],
      potentialMove: [true, false, false, true],
      previousNavDir: undefined,
      previousKey: ''
    },
  ],
];

export { KeyBoardKey, type0_dig, type0_dig_shortcut, type0_en, type0_en_shortcut, type0_ru, type0_ru_shortcut }
