import * as moment from 'moment';

import { TODAY } from '~localization';
import Event from '~typings/Event';
import { EventStatus } from '~typings/EventStatus';

export const scheduleTime = 'HH:mm';

export const locales = {
  en: 'en_GB',
  ru: 'ru_RU',
  kk: 'kk_KZ',
  fa: 'fa_IR',
  zh: 'zh-cn',
  uz: 'uz-latn',
};

const capitalizeString = str => str.charAt(0).toUpperCase() + str.slice(1);

export const isToday = date => moment().isSame(moment(date), 'day');
export const isEqualDay = (date0, date1) => moment(date0).isSame(moment(date1), 'day');
export const isInTheFuture = date => moment().isBefore(moment(date));
export const isInThePast = date => moment().isAfter(moment(date));
export const isNow = (startAt, endAt) => moment().isBetween(startAt, endAt);

export const dateFromLocale = (date = new Date(), locale = 'en') => moment(date).locale(locales[locale]);
export const getScheduleTime = (date, locale) => dateFromLocale(date, locale).format(scheduleTime);

export function getMonth(date, locale) {
  const format = locale === 'fa' ? 'jMMMM' : 'MMMM';
  return dateFromLocale(date, locale).format(format);
}

export const getDayOfWeek = (date, locale, intl) => (
  isToday(date) ? intl.formatMessage({id: TODAY}) : dateFromLocale(date, locale).format('dd.')
);

export const getFullDate = (date, locale, withDayName = false, intl) => {
  const day = withDayName ? `[${getDayOfWeek(date, locale, intl)}, ]` : '';
  const formattedDate = dateFromLocale(date, locale).format(`${day}D MMMM`);
  return capitalizeString(formattedDate);
}


export const getTimePeriod = (startDate, endDate, locale) => {
  const start = getScheduleTime(startDate, locale);
  const end = getScheduleTime(endDate, locale);
  return `${start} - ${end}`;
};

export const getEventStatus = (event?: Event): EventStatus | undefined => {
  if (!event) return;

  if (isInThePast(event.end_at)) {
    return EventStatus.InThePast;
  }
  if (isInTheFuture(event.start_at)) {
    return EventStatus.InTheFutute;
  }

  return EventStatus.Now;
};

