import cn from 'classnames';
import React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import * as styles from './BigBannerBackgroundImage.module.css';

export type Props = {
  imageUrl?: string | null;
  className?: string;
};

const BigBannerBackgroundImage$: React.FC<Props> = ({ imageUrl, className }) => {
  return (
    <div className={ cn(className, styles.container) }>
      <TransitionGroup component={ null }>
        <Transition
          key={ imageUrl || 'empty' }
          timeout={ 350 }
        >
          {(state) => (
            <div
              style={ {
                backgroundImage: imageUrl ? `url(${imageUrl})` : undefined,
              } }
              className={ cn(styles.image, {
                [styles.animationHide]: state === 'exiting' || state === 'exited',
              }) }
            />
          )}
        </Transition>
      </TransitionGroup>
      <div className={ styles.topDiv } />
      <div className={ styles.bottomDiv } />
    </div>
  );
};

export const BigBannerBackgroundImage = React.memo(BigBannerBackgroundImage$);
