import 'customization-project-name/styles/fonts.css';

import focuser from '@focuser'
import messagesByLocale from 'customization-project-name/localization';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';

import { App } from '~components/App';
import { AnimationContextProvider } from '~components/Provider/Animation';
import { AppProvider } from '~components/Provider/App';
import { ChannelsProvider } from '~components/Provider/Channels';
import { EnvProvider } from '~components/Provider/Env';
import KeyDownHandler from '~components/Provider/KeyDownHandler';
import { LocalizationProviderWithApp } from '~components/Provider/Localization';
import { MenuProvider } from '~components/Provider/Menu';
import { PlatformProvider } from '~components/Provider/Platform';
import PlayerProvider from '~components/Provider/Player/component';
import { SceneProvider } from '~components/Provider/Scene';
import { queryClient } from '~global';
import { useVitalStoreLoader } from '~global';

import RQueryDevtools from './RQueryDevtools';




export const Root: React.FC = () => {
  const isVitalDataLoaded = useVitalStoreLoader()

  if (!isVitalDataLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={ queryClient }>
        <EnvProvider>
            <AppProvider>
              <LocalizationProviderWithApp messagesByLocale={ messagesByLocale }>
                <PlatformProvider>
                  <AnimationContextProvider>
                    <KeyDownHandler>
                      <SceneProvider>
                        <MenuProvider>
                          <ChannelsProvider>
                            <PlayerProvider>
                              <focuser.RootContextProvider>
                                <focuser.KeyDownHandler />
                                <focuser.PointerUpdater />
                                <HashRouter basename={ '/' }>
                                  <App />
                                </HashRouter>
                              </focuser.RootContextProvider>
                            </PlayerProvider>
                          </ChannelsProvider>
                        </MenuProvider>
                      </SceneProvider>
                    </KeyDownHandler>
                  </AnimationContextProvider>
                </PlatformProvider>
              </LocalizationProviderWithApp>
            </AppProvider>
        </EnvProvider>
      <RQueryDevtools />
    </QueryClientProvider>
  );
};
