import languages from 'customization-project-name/languages.json';
import settings from 'customization-project-name/settings.json';
import { get, isString } from 'lodash';
import { format as formatURL, parse as parseURI } from 'url';

import { getAuthData } from '~hooks/fetch/useAccount';
import { version } from '~lib/env-utils';

import * as ErrorCheckers from './error-checkers';

const API_CLIENT_ERROR_API = 'API_CLIENT_ERROR_API';
const API_CLIENT_ERROR_NETWORK = 'API_CLIENT_ERROR_NETWORK';
const API_CLIENT_ERROR_API_UNAVAILABLE = 'API_CLIENT_ERROR_API_UNAVAILABLE';

const createApiError = errors => ({ code: API_CLIENT_ERROR_API, errors });
const createNetworkError = error => ({ code: API_CLIENT_ERROR_NETWORK, error });
const createApiUnavailableError = error => ({ code: API_CLIENT_ERROR_API_UNAVAILABLE, error });

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultErrorHandler = (error, _requestParams) => {
  throw error;
};
const clientVersion = (): string => {
  if (document) {
    const regExp = /^\?version=(.+)&/g;
    const customVersion = get(regExp.exec(document.location.search), [1], null);

    return customVersion || version;
  }

  return version;
};

interface DefaultHeaders {
  Accept: string;
  'Content-Type': string;
  'Device-Token'?: string;
}

interface DefaultQuery {
  client_id: string;
  client_version: string;
  client_secret: string;
  timezone: number;
  locale: string;
}

class ApiClient {
  host = '';
  protocol = '';
  fetchLock: null | Promise<unknown> = null;
  isFetchLockStarted = false;
  unlockFetching: null | (() => void) = null;
  defaultQuery: DefaultQuery = {
    client_id: settings.clientId,
    client_version: clientVersion(),
    client_secret: settings.clientSecret,
    timezone: -(new Date().getTimezoneOffset() * 60),
    locale: languages[0],
  };
  defaultHeaders: DefaultHeaders = DEFAULT_HEADERS;
  errorHandler = defaultErrorHandler;

  constructor({ uri, defaultQuery }: { uri: string; defaultQuery?: DefaultQuery }) {
    this.setHost(uri);

    if (defaultQuery) {
      this.defaultQuery = defaultQuery;
    }
  }

  lockFetching = () => {
    this.fetchLock = new Promise<void>(resolve => {
      this.unlockFetching = () => {
        this.fetchLock = null;
        resolve();
      };
    });
  };

  setHost = (uri: string) => {
    const { host, protocol } = parseURI(uri);

    if (host) {
      this.host = host;
    }

    // @ts-ignore
    this.protocol = protocol;
  };

  setErrorHandler = errorHandler => {
    this.errorHandler = errorHandler;
  };

  setQueryParam = (paramName: keyof DefaultQuery, paramValue?: string | number) => {
    if (!paramValue) {
      if (this.defaultQuery[paramName]) {
        delete this.defaultQuery[paramName];
      }
    } else {
      this.defaultQuery = {
        ...this.defaultQuery,
        [paramName]: paramValue,
      };
    }
  };

  setDefaultHeader = (headerName: keyof DefaultHeaders, headerValue?: string | number) => {
    if (!headerValue) {
      if (this.defaultHeaders[headerName]) {
        delete this.defaultHeaders[headerName];
      }
    } else {
      this.defaultHeaders = {
        ...this.defaultHeaders,
        [headerName]: headerValue,
      };
    }
  };

  static async fetchUnsafe({ url: urlObject, settings }) {
    let response;
    try {
      const url = formatURL(urlObject);
      response = await fetch(url, settings);
    } catch (error) {
      throw createNetworkError(error);
    }

    let result;
    try {
      // если ответ 204, то json() не сработает т.к. тело ответа пустое и вылетит ошибка АПИ
      result = await response.json();
    } catch (error) {
      throw createApiUnavailableError(error);
    }

    ApiClient.throwApiErrorIfPresented(result);

    return result;
  }

  static throwApiErrorIfPresented(response) {
    if (response.error && isString(response.error)) {
      const syntheticError = { ...response, code: response.error };
      throw createApiError([syntheticError]);
    }

    if (response.errors && !response.meta?.popup) {
      throw createApiError(response.errors || []);
    }
  }

  async fetchText(url: string) {
    let result;
    let uri;
    try {
      const response = await fetch(url);
      uri = response?.url;
      result = await response.text();
    } catch (ignore) {
      // ignore
    }

    return { result, url: uri };
  }

  moveAccessAndDeviceTokensToHeaders(url, body) {
    let parsedBody = undefined as any;

    if(isString(body)){
      try {
        parsedBody = JSON.parse(body);
      } catch (ingore){}
    } else {
      parsedBody = body;
    }


    let access_token = undefined;

    if(parsedBody?.access_token){
      access_token = parsedBody.access_token;
    } else if (url?.query.access_token){
      access_token = url.query.access_token;
    }

    let device_token = undefined;

    if(parsedBody?.device_token){
      device_token = parsedBody.device_token;
    } else if (url?.query.device_token){
      device_token = url.query.device_token;
    }

    const updatedHeaders = {};

    if(access_token) {
      updatedHeaders['Access-Token'] = access_token
    }
    if(device_token) {
      updatedHeaders['Device-Token'] = device_token
    }

    return {
      updatedHeaders,
      updatedbody: parsedBody 
        ? {
            ...parsedBody,
            access_token: undefined,
            device_token: undefined,
          }
        : undefined,
      updatedurl: {
        ...url,
        query: {
          ...url.query,
          access_token: undefined,
          device_token: undefined,
        }
      }
    }
  }

  enhanceRequestParams = ({ url: url_, additionalQuery = {}, settings: settings_ }) => {
    // @ts-ignore
    const { defaultQuery } = this;

    const {
      pathname,
      query,
      host = this.host,
      protocol = this.protocol,
    } = url_;
    const { method, body, headers: settings_headers } = settings_;
    
    const url = {
      protocol,
      host,
      pathname,
      query: { ...defaultQuery, ...query, ...additionalQuery },
    };

    const result = this.moveAccessAndDeviceTokensToHeaders(url, body);

    const settings : {
      method: any;
      headers: {
          Accept: string;
          'Content-Type': string;
      };
      body?: string;
    } = {  
          method, 
          headers: { 
            ...this.defaultHeaders,  
            ...result.updatedHeaders,
            ...settings_headers,
            
          }, 
          body: undefined 
        };
    if (result.updatedbody) {
      settings.body = isString(result.updatedbody) 
        ? result.updatedbody 
        : JSON.stringify(result.updatedbody);
    }

    return { url: result.updatedurl, settings };
  };

  async fetchIgnoringLock(requestParams) {
    try {
      return await ApiClient.fetchUnsafe(requestParams);
    } catch (error) {
      return this.errorHandler(error, requestParams);
    }
  }

  async fetch(requestParams) {
    const { accessToken } = getAuthData();
    const accessTokenInParams = get(requestParams, 'settings.headers.Access-Token') || null;

    if (accessTokenInParams && accessToken !== accessTokenInParams) {
      requestParams.url.query.access_token = accessToken;
    }

    if (this.fetchLock !== null) {
      await this.fetchLock;
    }

    
    // we need to rebuild params after lock release
    // because defaultQuery may change in waiting time
    const finalRequestParams = (this.fetchLock) ?
      this.enhanceRequestParams(requestParams)
      :
      requestParams;

    try {
      return  await ApiClient.fetchUnsafe(finalRequestParams);
    } catch (error) {
      return await this.errorHandler(error, finalRequestParams);
    }


  }

  get = <T = any>(pathname, query?: any): Promise<T> => {
    const url = { pathname, query };
    const settings = { method: 'GET' };

    const requestParams = this.enhanceRequestParams({ url, settings });
    delete requestParams.settings.body;
    return this.fetch(requestParams);
  };

  patch = (pathname, body) => {
    const url = { pathname };
    const settings = { method: 'PATCH', body };

    const requestParams = this.enhanceRequestParams({ url, settings });
    return this.fetch(requestParams);
  };

  post = <T = any>(pathname, body): Promise<T> => {
    const url = { pathname };
    const settings = { method: 'POST', body };

    const requestParams = this.enhanceRequestParams({ url, settings });
    return this.fetch(requestParams);
  };

  put = (pathname, body) => {
    const url = { pathname };
    const settings = { method: 'PUT', body };

    const requestParams = this.enhanceRequestParams({ url, settings });
    return this.fetch(requestParams);
  };

  delete = (pathname, additionalQuery) => {
    const url = { pathname };
    const settings = { method: 'DELETE' };
    
    const requestParams = this.enhanceRequestParams({ url, additionalQuery, settings });
    return this.fetch(requestParams);
  };
}

export {
  API_CLIENT_ERROR_API,
  API_CLIENT_ERROR_API_UNAVAILABLE,
  API_CLIENT_ERROR_NETWORK,
  ApiClient as default,
  ErrorCheckers,
};
