// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grid--mT7y6 {\n  width: 100%;\n  /* 40px 0 60px 0 */\n  padding: 3.125vw 0 4.6875vw 0;\n  max-height: 100vh;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n}\n\n.sliderWrapper--iTPdI {\n  /* 1104px */\n  width: 86.25vw;\n  /* 620px */\n  max-height: 48.4375vw;\n}\n\n.slider--lso5r {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  /* 1104px */\n  width: 86.25vw;\n}\n\n.row--znNqO {\n  /* 40px */\n  margin-bottom: 3.125vw;\n}\n\n.row--znNqO:last-child {\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/CardCollectionGrid/CardCollectionGrid.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,6BAA6B;EAC7B,iBAAiB;EACjB,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".grid {\n  width: 100%;\n  /* 40px 0 60px 0 */\n  padding: 3.125vw 0 4.6875vw 0;\n  max-height: 100vh;\n  display: flex;\n  justify-content: center;\n}\n\n.sliderWrapper {\n  /* 1104px */\n  width: 86.25vw;\n  /* 620px */\n  max-height: 48.4375vw;\n}\n\n.slider {\n  display: flex;\n  align-items: center;\n  /* 1104px */\n  width: 86.25vw;\n}\n\n.row {\n  /* 40px */\n  margin-bottom: 3.125vw;\n}\n\n.row:last-child {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var grid = "grid--mT7y6";
export var sliderWrapper = "sliderWrapper--iTPdI";
export var slider = "slider--lso5r";
export var row = "row--znNqO";
export default ___CSS_LOADER_EXPORT___;
