/* eslint-disable max-len */
import { gw_11 } from 'customization-project-name/colors';
import React from 'react';

type Props = {
  className?: string;
};

export const CatchUpIcon: React.FC<Props> = ({ className }): JSX.Element => {
  return (
    <svg
      className={ className }
      style={ {
        // 24px
        width: '1.875vw',
        height: '1.875vw',
      } }
      viewBox="0 0 24 24"
    >
      <path
        d="M21.5984 12.0004C21.5984 17.2964 17.2944 21.6004 11.9984 21.6004C11.1024 21.6004 10.2064 21.4724 9.35844 21.2324C8.92644 21.1124 8.68644 20.6644 8.80644 20.2404C8.92644 19.8164 9.37444 19.5684 9.79844 19.6964C10.5104 19.8964 11.2464 20.0004 11.9984 20.0004C16.4064 20.0004 19.9984 16.4084 19.9984 12.0004C19.9984 7.59239 16.4064 4.00039 11.9984 4.00039C7.59044 4.00039 3.99844 7.59239 3.99844 12.0004C3.99844 13.9524 4.71844 15.8324 5.99844 17.2804V15.2724C5.99844 14.8324 6.35844 14.4724 6.79844 14.4724C7.23844 14.4724 7.59844 14.8324 7.59844 15.2724V18.8724C7.59844 19.3124 7.23844 19.6724 6.79844 19.6724H3.19844C2.75844 19.6724 2.39844 19.3124 2.39844 18.8724C2.39844 18.4324 2.75844 18.0724 3.19844 18.0724H4.56644C3.17444 16.3684 2.39844 14.2244 2.39844 12.0004C2.39844 6.70439 6.70244 2.40039 11.9984 2.40039C17.2944 2.40039 21.5984 6.70439 21.5984 12.0004ZM15.9744 12.0004C15.9744 11.7364 15.8464 11.5124 15.6304 11.3764L10.7104 8.32839C10.5904 8.25639 10.4544 8.21639 10.3264 8.21639C9.91844 8.21639 9.58244 8.54439 9.58244 8.95239V15.0324C9.58244 15.4404 9.91844 15.7764 10.3264 15.7764C10.4544 15.7764 10.5904 15.7444 10.7104 15.6644L15.6304 12.6164C15.8464 12.4884 15.9744 12.2484 15.9744 11.9924V12.0004Z"
        fill={ gw_11 }
      />
    </svg>
  );
};
