/* eslint-disable max-len */
import React from 'react';

interface Props {
  className?: string;
}

export const EditIcon: React.FC<Props> = React.memo(({className}) => {
  return (
    <svg 
      viewBox="0 0 30 30" 
      xmlns="http://www.w3.org/2000/svg"
      className={ className }
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M17.1428 3.63408C16.1864 3.0819 14.9635 3.4094 14.411 4.36565C11.965 8.59967 9.51486 12.8314 7.07602 17.0696C6.86068 17.4438 6.77222 17.8777 6.82386 18.3063L7.52171 24.0986C7.6812 25.4223 9.06684 26.2223 10.293 25.6986L15.6567 23.4075C16.0555 23.2371 16.3882 22.9417 16.6045 22.5657L23.9368 9.8666C24.4894 8.90998 24.1617 7.68647 23.205 7.13407L17.1428 3.63408ZM9.50735 23.8594L8.81582 18.1195L14.824 21.5883L9.50735 23.8594Z" 
        fill="currentColor"
      />
    </svg>
  );
});

EditIcon.displayName = 'EditIcon';
