import { Moment } from "moment";

import { isEqualDay, isToday } from "~lib/datetime-utils";
import ProgramEvent from '~typings/Event';


const getInitialDateIndex = (days: Moment[], event?: ProgramEvent): number => {
  let res = 0;

  for (let index = 0; index < days.length; index += 1) {
    if (event) {
      if (isEqualDay(new Date(event.start_at), days[index])) {
        res = index; break;
      }
    } else if (isToday(days[index])) {
      res = index; break;
    }
  }

  return res;
};


export {
  getInitialDateIndex,
};
