export enum PlatformName {
  Desktop = 'desktop',
  Foxxum = 'foxxum',
  Hisense = 'hisense',
  Panasonic = 'panasonic',
  TSL = 'tsl',
  Tizen = 'tizen',
  Vewd = 'vewd',
  WebOS = 'webos',
  // NetCast = 'netcast',
  // Orsay = 'orsay',
  // Philips = 'philips',
}

export default PlatformName;
