import * as cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  href?: string;
  onClick?: () => void;
  children: string;
}>;


const Link: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    } else if (props.href?.length) {
      history.push(props.href);
    }
  };

  return (
    <div
      className={
        cn(styles.link, {
          [styles.focused]: props.isFocused,
          'focusedNavigationNode': props.isFocused,
        })
      }
      onClick={ handleClick }
    >
      { props.children }
    </div>
  );
};

export default Link;
