import { format as formatURL, parse as parseURI } from 'url';

import Drm, { DRMType } from '~typings/Drm';
import { Player } from '~typings/Player';


const getDRM = (drm: Drm, player?: Player) => {
  if (drm.type === DRMType.PlayReady) {
    return ({
      servers: {
        'com.microsoft.playready': drm.license_server || 'http://pr.msk.spbtv.com/playready/rightsmanager.asmx',
      },
    });
  }

  if (drm.type === DRMType.WideVine) {
    if (player?.type === 'ivi') {
      const url = parseURI(drm.license_server, true);
      
      if (url && drm.drm_asset_id && player.content_id && player.app_version && player.session) {
        url.query.content_id = player.content_id;
        url.query.asset = drm.drm_asset_id;
        url.query.app_version = String(player.app_version);
        url.query.session = player.session;
      }
      
      return ({
        servers: {
          'com.widevine.alpha': formatURL(url),
        },
      });
    }

    return ({
      servers: {
        'com.widevine.alpha': drm.license_server || '//wv.spbtv.com/wvproxy',
      },
    });
  }

  return {};
};


export default getDRM;
