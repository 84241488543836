import cn from 'classnames';
import * as React from 'react';

import ProfileImage from '~components/ProfileImage';
import Profile from '~typings/Profile';

import * as styles from './styles.module.css';


type AddProfileItemProps = {
  isFocused: boolean;
};

const AddProfileItem: React.FC<AddProfileItemProps> = (props) => (
  <div
    className={ cn({
      [styles.navProfileItemActive]: props.isFocused,
    }, styles.profileImageWrapper) }
  >
    <div className={ styles.addProfileCircle }>
      +
    </div>
  </div>
);

type RealProfileItemProps = {
  user: Profile;
  isFocused: boolean;
};

const RealProfileItem: React.FC<RealProfileItemProps> = (props) => {
  const { name, username } = props.user;

  return (
    <div>
      <div className={ styles.profileImageWrapper }>
        <ProfileImage
          className={ styles.profileAvatar }
          isFocused={ props.isFocused }
          profile={ props.user }
        />
      </div>
      <div className={ styles.profileNameWrapper }>
        <div className={ cn({ [styles.profileNameActive]: props.isFocused }, styles.profileName) }>
          { name || username || '?' }
        </div>
      </div>
    </div>
  );
};

type NavProfileItemProps = {
  user?: Profile;
  isFocused: boolean;
};

const ProfileItem: React.FC<NavProfileItemProps> = (props) => (
  props.user
    ? <RealProfileItem
      user={ props.user }
      isFocused={ props.isFocused }
    />
    : <AddProfileItem
      isFocused={ props.isFocused }
    />
);

export default ProfileItem;
