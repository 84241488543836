import * as React from 'react';

import ImageType from '~typings/ImageType';
import MediaItemDimension from '~typings/MediaItemDimension';
import NavigationDirection from '~typings/NavigationDirection';


type Props = Readonly<{
  itemsCount: number;
  focusedIndex: number;
  isFocusedBlock: boolean;
  dimension: MediaItemDimension;
  direction: NavigationDirection.Left | NavigationDirection.Right | null;
}>;

type Result = Readonly<{
  from: number;
  to: number;
}>;

type PrevItemsProps = Readonly<{
  direction: NavigationDirection.Left | NavigationDirection.Right | null;
  focusedIndex: number;
}>;

type NextItemsProps = Readonly<{
  direction: NavigationDirection.Left | NavigationDirection.Right | null;
  focusedIndex: number;
  visibleItemsInScreen: number;
  itemsCount: number;
}>;


const overScanItemsCount = 10;
const getPrevItems = (props: PrevItemsProps): number => {
  if (props.direction !== null) {
    if (props.direction === NavigationDirection.Right) {
      return Math.max((props.focusedIndex - 2), 0);
    } else {
      return Math.max((props.focusedIndex - overScanItemsCount * 2), 0);
    }
  }

  return Math.max((props.focusedIndex - overScanItemsCount), 0);
};
const getNextItems = (props: NextItemsProps): number => {
  if (props.direction !== null) {
    if (props.direction === NavigationDirection.Right) {
      return (
        Math.min(
          (props.focusedIndex + props.visibleItemsInScreen + overScanItemsCount * 2),
          (props.itemsCount - 1),
        )
      );
    } else {
      return Math.min((props.focusedIndex + props.visibleItemsInScreen), (props.itemsCount - 1));
    }
  }

  return Math.min(
    (props.focusedIndex + props.visibleItemsInScreen + overScanItemsCount),
    (props.itemsCount - 1),
  );
};

const useVisibleRange = (props: Props): Result => {
  const {
    dimension,
    direction,
    itemsCount,
    focusedIndex,
    isFocusedBlock,
  } = props;
  const visibleItemsInScreen = (dimension === ImageType.POSTER || dimension === ImageType.Card1x1) ? 7 : 5;
  const [[from, to], setRange] = React.useState<number[]>([0, visibleItemsInScreen]);
  const isReachedCriticalValue = (
    focusedIndex <= (from + 1) || focusedIndex >= (to - visibleItemsInScreen)
  );

  if (isReachedCriticalValue) {
    const minimumItemsForLeftSide = Math.max((focusedIndex - 1), 0);
    const minimumItemsForRightSide = Math.min(
      (focusedIndex + (visibleItemsInScreen + 1)),
      (itemsCount - 1),
    );
    const itemsFrom = (isFocusedBlock) ?
      getPrevItems({ direction, focusedIndex })
      :
      minimumItemsForLeftSide;
    const itemsTo = (isFocusedBlock) ?
      getNextItems({ direction, focusedIndex, visibleItemsInScreen, itemsCount })
      :
      minimumItemsForRightSide;

    if (itemsFrom !== from || itemsTo !== to) {
      setRange([itemsFrom, itemsTo]);
    }
  }

  return ({ from, to });
};


export default useVisibleRange;
