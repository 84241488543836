import React, { useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef } from 'react';

import { getWindowAboveFocus } from '~lib/getWindowAboveFocus';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import { getElementTransformY } from '~newapp/utils/globals/dom/getElementTransformY';

export type useOffsetChangerProps = {
  focusedIndex: number;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
  sliderWrapperRef: React.MutableRefObject<HTMLElement | null>;
  childBufferCount: number;
  initialOffset?: number;
  updates?: unknown[];
};
/**
 * Хук добавляет offset к слайдеру в зависимости от индекса активного слайда
 */
export const useOffsetChanger = ({
  focusedIndex,
  sliderRef,
  sliderWrapperRef,
  childBufferCount,
  initialOffset,
  updates = [],
}: useOffsetChangerProps) => {
  useLayoutEffect(() => {
    if (!sliderRef.current || !sliderWrapperRef.current || typeof initialOffset === 'undefined') {
      return;
    }

    setInitialOffsetOfSlider({
      slider: sliderRef.current,
      offsetY: initialOffset,
    });
  }, []);

  useLayoutEffect(() => {
    if (!sliderRef.current || !sliderWrapperRef.current) {
      return;
    }

    updateOffsetOfSlider({
      focusedIndex,
      slider: sliderRef.current,
      sliderWrapper: sliderWrapperRef.current,
      isVirtual: false,
      childBufferCount,
    });
  }, [focusedIndex, childBufferCount, ...updates]);
};

export type useTransitionStyleChangerProps = {
  animationDurationInMS: number | null;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
};

export const useTransitionStyleChanger = ({
  animationDurationInMS,
  sliderRef,
}: useTransitionStyleChangerProps) => {
  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    if (!animationDurationInMS) {
      sliderRef.current.style.removeProperty('transitionDuration');
      return;
    }

    sliderRef.current.style.transitionDuration = `${animationDurationInMS}ms`;
  }, [animationDurationInMS]);
};

export type useVirtualOffsetChangerProps = {
  focusedIndex: number;
  realFocusedIndex: number;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
  sliderWrapperRef: React.MutableRefObject<HTMLElement | null>;
  childBufferCount: number;
  updates?: unknown[];
  transitionGapUpVW?: number;
  transitionGapDownVW?: number;
  initialOffset?: number;
};
/**
 * Хук добавляет offset к слайдеру в зависимости от индекса активного слайда
 */
export const useVirtualOffsetChanger = ({
  focusedIndex,
  sliderRef,
  sliderWrapperRef,
  realFocusedIndex,
  updates = [],
  childBufferCount,
  transitionGapUpVW,
  transitionGapDownVW,
  initialOffset,
}: useVirtualOffsetChangerProps) => {
  useLayoutEffect(() => {
    if (!sliderRef.current || !sliderWrapperRef.current || typeof initialOffset === 'undefined') {
      return;
    }

    setInitialOffsetOfSlider({
      slider: sliderRef.current,
      offsetY: initialOffset,
    });
  }, []);

  useLayoutEffect(() => {
    if (!sliderRef.current || !sliderWrapperRef.current) {
      return;
    }

    updateOffsetOfSlider({
      focusedIndex,
      slider: sliderRef.current,
      sliderWrapper: sliderWrapperRef.current,
      isVirtual: true,
      childBufferCount,
      transitionGapUpVW,
      transitionGapDownVW,
    });
  }, [focusedIndex, realFocusedIndex, ...updates]);
};

type UpdateOffsetArgs = {
  focusedIndex: number;
  slider: HTMLElement;
  sliderWrapper: HTMLElement;
  isVirtual: boolean;
  childBufferCount: number;
  transitionGapUpVW?: number;
  transitionGapDownVW?: number;
};

const updateOffsetOfSlider = ({
  focusedIndex,
  slider,
  sliderWrapper,
  isVirtual,
  childBufferCount,
  transitionGapDownVW,
  transitionGapUpVW,
}: UpdateOffsetArgs) => {
  const childrenArray = Array.from(slider.children);
  const childRect = childrenArray?.[focusedIndex + (isVirtual ? 1 : 0)]?.getBoundingClientRect();

  if (!childRect) {
    return;
  }

  const childBufferUpPx = transitionGapUpVW ? valueInPixelsByWidth(transitionGapUpVW) : 0;
  const childBufferDownPx = transitionGapDownVW ? valueInPixelsByWidth(transitionGapDownVW) : 0;

  let {
    height: sliderWrapperHeight,
    bottom: sliderWrapperBottom,
    top: sliderWrapperTop,
  } = sliderWrapper.getBoundingClientRect();

  const { top: childTop, height: childHeight, bottom: childBottom } = childRect;
  const childBufferPx = childBufferCount * childHeight;
  sliderWrapperTop = sliderWrapperTop + childBufferPx - childBufferUpPx;
  sliderWrapperBottom = sliderWrapperBottom - childBufferPx - childBufferDownPx;
  if (
    childBottom >= sliderWrapperTop &&
    childBottom <= sliderWrapperBottom &&
    childTop >= sliderWrapperTop &&
    childTop <= sliderWrapperBottom
  ) {
    // Элемент внутри наблюдаемой области, ничего не делаем
    return;
  }

  let { top: sliderTop, height: sliderHeight } = slider.getBoundingClientRect();

  if (childBottom < sliderWrapperBottom) {
    // следующий слайд сверху, значит нужно просто переместить слайдер до слайда
    const sliderOffsetToChild = Math.abs(sliderTop - childTop);
    const offset = Math.max(sliderOffsetToChild - childBufferPx - childBufferUpPx, 0);
    slider.style.transform = `translate3d(0, ${-offset}px, 0)`;
    slider.style.webkitTransform = `translate3d(0, ${-offset}px, 0)`;
    return;
  }

  // следующий слайд снизу, двигаемся к нему, но он должен быть в нижнем крае
  let sliderOffsetToChild = Math.abs(sliderTop - childTop - childBufferPx - childBufferDownPx);
  sliderOffsetToChild = Math.min(sliderOffsetToChild, sliderHeight - childHeight);
  const bufferOffsetBrake = sliderWrapperHeight - childHeight;
  const offset = Math.max(sliderOffsetToChild - bufferOffsetBrake, 0);
  slider.style.transform = `translate3d(0, ${-offset}px, 0)`;
  slider.style.webkitTransform = `translate3d(0, ${-offset}px, 0)`;
};

type SetInitialOffsetArgs = {
  slider: HTMLElement;
  offsetY: number;
};

const setInitialOffsetOfSlider = ({ slider, offsetY }: SetInitialOffsetArgs) => {
  slider.style.transform = `translate3d(0, ${offsetY}px, 0)`;
  slider.style.webkitTransform = `translate3d(0, ${offsetY}px, 0)`;
};

/**
 * Хук добавляет offset к слайдеру в зависимости от индекса активного слайда
 */
export const useDyanmicOffsetChanger = (params: useVirtualOffsetChangerProps) => {
  const {
    focusedIndex,
    sliderRef,
    sliderWrapperRef,
    realFocusedIndex,
    updates = [],
    childBufferCount,
  } = params;

  useDynamicOffsetFixer(params);

  useEffect(() => {
    if (!sliderRef.current || !sliderWrapperRef.current) {
      return;
    }

    updateOffsetOfSlider({
      focusedIndex,
      slider: sliderRef.current,
      sliderWrapper: sliderWrapperRef.current,
      isVirtual: false,
      childBufferCount,
    });
  }, [focusedIndex, realFocusedIndex, childBufferCount, ...updates]);
};

/**
 * Хук фиксирует расстояние, когда в доме изменяются элементы при виртуализации
 */
const useDynamicOffsetFixer = ({
  focusedIndex,
  realFocusedIndex,
  sliderRef,
  sliderWrapperRef,
  childBufferCount,
  updates = [],
}: useVirtualOffsetChangerProps) => {
  const focusedChildLastTopRef = useRef<{ el: Element; top: number } | null>(null);

  // Как только меняется индекс активного слайда, надо запомнить каким он был до перерендера:
  useMemo(() => {
    if (!sliderRef.current || !sliderWrapperRef.current) {
      return;
    }

    const child = sliderRef.current.children[focusedIndex];
    if (!child) {
      focusedChildLastTopRef.current = null;
      return;
    }
    const childRect = child.getBoundingClientRect();
    focusedChildLastTopRef.current = {
      el: child,
      top: childRect.top,
    };
  }, [focusedIndex, realFocusedIndex, childBufferCount, ...updates]);

  // После перерендера, фиксируем позицию слайдов, если есть расхождение
  useLayoutEffect(() => {
    const focusedChildLastTop = focusedChildLastTopRef.current;
    if (!sliderRef.current || !sliderWrapperRef.current || focusedChildLastTop === null) {
      return;
    }

    const child = focusedChildLastTop.el;
    const childPrevTop = focusedChildLastTop.top;
    const childRect = child.getBoundingClientRect();
    const childCurrentTop = childRect.top;
    if (childPrevTop === childCurrentTop) {
      return;
    }

    const diffBetweenChildTops = childPrevTop - childCurrentTop;
    const sliderTranslateYParsed = getElementTransformY(sliderRef.current);
    if (sliderTranslateYParsed === null) {
      return;
    }

    const sliderTopNeedToBe = sliderTranslateYParsed + diffBetweenChildTops;

    const sliderTransitionDuration = sliderRef.current.style.transitionDuration;
    sliderRef.current.style.transitionDuration = '0ms';
    sliderRef.current.style.transform = `translate3d(0, ${sliderTopNeedToBe}px, 0)`;
    sliderRef.current.style.webkitTransform = `translate3d(0, ${sliderTopNeedToBe}px, 0)`;
    reflow(sliderRef.current);
    sliderRef.current.style.transitionDuration = sliderTransitionDuration;
  }, [focusedIndex, realFocusedIndex, childBufferCount, ...updates]);
};

export type UseVirtualChildsParams = {
  childsCount: number;
  renderChild: (idx: number) => React.ReactNode;
  focusedIndex: number;
  maxInDOM: number;
  recalcStep: number;
};

/**
 * Утилитка помогает получить минимальный и максимальный индекс для отрисовки виртуальных элементов
 * buffer-size => количество элементов, которое должно буферироваться за view-port—ом
 * Это нужно, чтобы на старых телевизорах элементы были отрисованы
 * Если на каждом шаге влево/вправо удалять и вставлять элементы в DOM, то это будет лагать
 * Из-за этого мы должны буферировать несколько элементов слева и справа
 */
const getVirtualChildsBoundsWithBuffer = ({
  idx,
  recalcStep,
  totalCount,
  maxInDOM,
}: {
  idx: number;
  recalcStep: number;
  maxInDOM: number;
  totalCount: number;
}) => {
  const { start, end } = getWindowAboveFocus(idx, recalcStep);
  const leftBound = Math.floor((maxInDOM - recalcStep) / 2);
  const rightBound = Math.ceil((maxInDOM - recalcStep) / 2);

  if (start - leftBound < 0) {
    return [0, Math.min(maxInDOM, totalCount)];
  }

  if (end + rightBound > totalCount) {
    return [Math.max(totalCount - maxInDOM, 0), totalCount];
  }

  return [start - leftBound, end + rightBound];
};

export const useVirtualChilds = ({
  focusedIndex,
  childsCount,
  renderChild,
  maxInDOM,
  recalcStep,
}: UseVirtualChildsParams) => {
  const [minIdxToRender, maxIdxToRender] = getVirtualChildsBoundsWithBuffer({
    idx: focusedIndex,
    recalcStep: recalcStep,
    maxInDOM: maxInDOM,
    totalCount: childsCount,
  });

  const virtualChildren = React.useMemo(() => {
    return new Array(Math.max(maxIdxToRender - minIdxToRender, 0))
      .fill(0)
      .map((_, idx) => renderChild(minIdxToRender + idx));
  }, [renderChild, minIdxToRender, maxIdxToRender]);

  const virtualFocus = focusedIndex - minIdxToRender;

  return {
    virtualChildren,
    virtualFocus,
    heightMultiplier: minIdxToRender,
  };
};

const reflow = (elt: HTMLElement) => {
  void elt.offsetHeight;
};

export type SliderImperativeRefParams = {
  /**
   * Возвращает смещение слайдера в пикселях
   * если возвращаемое значение null, значит не удалось получить смещение
   * (например компонент не успел проинициализироваться)
   */
  getOffsetY: () => number | null;
};

export type SliderImperativeRefType = React.MutableRefObject<SliderImperativeRefParams | undefined>;

export const useSliderImperativeHandle = ({
  sliderImperativeRef,
  sliderRef,
  sliderWrapperRef,
}: {
  sliderImperativeRef?: SliderImperativeRefType;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
  sliderWrapperRef: React.MutableRefObject<HTMLElement | null>;
}) => {
  useImperativeHandle(
    sliderImperativeRef,
    () => {
      return {
        getOffsetY() {
          if (!sliderWrapperRef.current || !sliderRef.current) {
            return null;
          }
          return getElementTransformY(sliderRef.current);
        },
      };
    },
    [],
  );
};
