// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pageContainer--EUH0T {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(23, 23, 27, 1);\n  z-index: 99;\n}\n\n.container--VYEOu {\n  padding: 9.722222222222223vh 0;\n}\n\n.tabsPanel--OKrVN {\n  margin: 3.125vw 0 2.734375vw;\n}\n\n.scrollbar--DBjXy {\n  position: fixed;\n  top: 3.90625vw;\n  bottom: 3.90625vw;\n  right: 2.03125vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemDetails/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,qCAAwB;EACxB,WAAW;AACb;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".pageContainer {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: $gw_00;\n  z-index: 99;\n}\n\n.container {\n  padding: 9.722222222222223vh 0;\n}\n\n.tabsPanel {\n  margin: 3.125vw 0 2.734375vw;\n}\n\n.scrollbar {\n  position: fixed;\n  top: 3.90625vw;\n  bottom: 3.90625vw;\n  right: 2.03125vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var pageContainer = "pageContainer--EUH0T";
export var container = "container--VYEOu";
export var tabsPanel = "tabsPanel--OKrVN";
export var scrollbar = "scrollbar--DBjXy";
export default ___CSS_LOADER_EXPORT___;
