// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../../Scene/images/mask-media-item-page-mode.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".playerPopup--Uzrk_ {\n  position: absolute;\n  z-index: 10;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n\n.playerPopupWithBG--MDn2s {\n  background-color: rgba(23, 23, 27, 0.9);\n}\n\n.playerPopupRadialBG--H9Vh_ {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/ScenePlayer/Player/controls/Popup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,4BAA4B;AAC9B;;AAEA;EACE,uCAAuB;AACzB;;AAEA;EACE,yDAAiF;AACnF","sourcesContent":[".playerPopup {\n  position: absolute;\n  z-index: 10;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n\n.playerPopupWithBG {\n  background-color: $b_90;\n}\n\n.playerPopupRadialBG {\n  background-image: url(\"./../../../../Scene/images/mask-media-item-page-mode.png\");\n}\n"],"sourceRoot":""}]);
// Exports
export var playerPopup = "playerPopup--Uzrk_";
export var playerPopupWithBG = "playerPopupWithBG--MDn2s";
export var playerPopupRadialBG = "playerPopupRadialBG--H9Vh_";
export default ___CSS_LOADER_EXPORT___;
