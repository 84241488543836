import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { useClient, useConfig } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import Card from '~typings/Card';
import ResponseMany from '~typings/ResponseMany';

import { FetchType, fetchURLs } from '../fetch-parameters';
import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '../utils';


const PREFIX_OF_A_COMPOSITE_KEY = 'rent_plan_cards';

const rentPlanCardsQueryKeys = {
  all: PREFIX_OF_A_COMPOSITE_KEY,
  defaultKey: (limit: number, accessToken: string | null, rentPlanID?: string) => [
    rentPlanCardsQueryKeys.all,
    limit,
    accessToken,
    rentPlanID || 'nope',
  ],
};


type FetchParams = {
  client: ApiClient;
  rentPlanID: string | undefined;
  page: number;
  accessToken: string | null;
  limit: number;
};

const fetch = async ({
  client,
  rentPlanID,
  page,
  accessToken,
  limit,
}: FetchParams) => {
  if (!rentPlanID) {
    throw new Error('rentPlanID is missing');
  }

  return await client.get(fetchURLs[FetchType.RentPlanCards], {
    access_token: accessToken,
    'filter[resource_type_in]': 'movies,series,channels',
    'filter[rent_plan_id_in]': rentPlanID,
    'page[offset]': page * limit,
    'page[limit]': limit,
  });
};

const useInfinityRentPlanCards = (rentPlanID?: string) => {
  const client = useClient();
  const limit = getLimitFromConfig(useConfig());
  const accessToken = getAuthData().accessToken;
  const queryKey = rentPlanCardsQueryKeys.defaultKey(limit, accessToken, rentPlanID);

  const query = useInfiniteQuery<ResponseMany<Card[]>>({
    queryKey,
    queryFn: ({ pageParam = 0 }) =>
      fetch({ client, accessToken, page: pageParam, limit, rentPlanID }),
    getNextPageParam: getNextPageForInfinityPagination,
    enabled: !!rentPlanID,
  });

  const parsedData = useMemo(() => {
    return query.data?.pages && reducePaginatedPages(query.data?.pages);
  }, [query.data?.pages]);

  return {
    query,
    parsedData,
  };
};

export default useInfinityRentPlanCards;
