import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import MediaItemDetailsEvent from '~components/MediaItemDetails/MediaItemDetailsEvent';
import useCurrentEvent from '~hooks/useCurrentEvent';
import { isChannel } from '~lib/objectType';
import Episode from '~typings/Episode';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';

type Props = Readonly<{
  item: PlayerMediaItem;
  onClose: () => void;
  intl: InjectedIntl;
}>;

const ItemDetailsPopup: React.FC<Props> = ({ item, onClose, intl }: Props) => {
  const currentEvent = useCurrentEvent(isChannel(item) ? item.id : undefined);

  let mediaItem;

  if (item.object === ItemObject.Episode) {
    mediaItem = (item as Episode).series;
  } else if (item.object === ItemObject.Channel) {
    mediaItem = currentEvent;
  } else {
    mediaItem = item;
  }

  if (!mediaItem) return null;

  return (
    <MediaItemDetailsEvent
      item={ mediaItem }
      onWatchBtnClick={ onClose }
      intl={ intl }
    />
  );
};


export default injectIntl(ItemDetailsPopup);
