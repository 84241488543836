import * as cn from 'classnames';
import * as React from 'react';

import { CurrentEventProgress } from '~components/CurrentEventProgress';
import { isCatchupAvailable } from '~lib/eventsHelper';
import Blackout from '~typings/Blackout';
import Channel from '~typings/Channel';
import Event from '~typings/Event';
import CatchupIcon from '~ui/Icon/Catchup';

import ScheduleTime from './ScheduleTime';
import * as styles from './styles.module.css';


type Props = Readonly<{
  channel?: Channel;
  blackouts?: Blackout[];
  isCurrentEvent: boolean;
  isSingleEvent?: boolean;
  item: Event;
}>;


const EPGPageEventRender: React.FC<Props> = (props: Props) => {
  const isPast = (new Date(props.item.end_at) < new Date());
  const isCatchup = !!(
    isPast
    && props.channel?.catchup_availability.available
    && isCatchupAvailable(props.item, props.channel, props.blackouts)
  );
  const renderCatchupIcon = (isCatchup) ?
    <CatchupIcon
      className={ cn(styles.catchup) }
    />
    :
    null;
  const renderName = (props.isSingleEvent) ?
    React.createElement(
      'span',
      {
        className: styles.name,
      },
      props.item.name
    )
    :
    props.item.name;
  const renderCurrentEventProgress = (props.isCurrentEvent) ?
    <CurrentEventProgress
      className={ cn(styles.currentEventProgress) }
      startAt={ props.item.start_at }
      endAt={ props.item.end_at }
    />
    :
    null;

  return (
    <>
      <ScheduleTime
        isSingleEvent={ !!props.isSingleEvent }
        startAt={ props.item.start_at }
        endAt={ props.item.end_at }
      />
      { renderCatchupIcon }
      { renderName }
      { renderCurrentEventProgress }
    </>
  );
};


export default React.memo(EPGPageEventRender);
