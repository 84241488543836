import * as cn from 'classnames';
import * as React from 'react';

import { DownArrowIcon } from '~newapp/ui-kit/Icons/For_buttons/downArrow';

import { Typography } from '../Typography';
import * as styles from './ChipsButton.module.css';

type Props = {
  isFocused: boolean;
  isActive: boolean;
  content: React.ReactNode;
  className?: string;
  showArrow?: boolean;
};

export const ChipsButton: React.FC<Props> = (props: Props) => {
  const state =
    props.isFocused && props.isActive
      ? 'focusedAndActive'
      : props.isFocused
        ? 'focused'
        : props.isActive
          ? 'active'
          : 'normal';

  return (
    <div
      className={ cn(styles.button, props.className, {
        [styles.focused]: state === 'focused',
        [styles.active]: state === 'active',
        [styles.focusedAndActive]: state === 'focusedAndActive',
      }) }
    >
      <Typography
        variant="body1_medium_uppercase"
        className={ styles.text }
      >
        {props.content}
      </Typography>
      {props.showArrow && <DownArrowIcon className={ cn(styles.icon, styles.text) } />}
    </div>
  );
};
