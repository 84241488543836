import focuser, { FocuserClickHandler } from '@focuser';
import { useFocuserContext } from '@focuser';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { SimpleButton } from '~app/ui-kit/SimpleButton';
import { useConfig } from '~global';
import { useAccount } from '~hooks/fetch/useAccount';
import usePasswordBySms from '~hooks/fetch/useAccount/usePasswordBySms';
import useUsernameAvailabilityMutation from '~hooks/fetch/useUsernameAvailability';
import { useCountdownCounter } from '~hooks/useCountdownCounter';
import {
  AUTH_SEND_CODE,
  FORGOT_PASSWORD,
  FORGOT_PIN,
  RESEND_PASSWORD_COUNTER_TEXT,
} from '~localization';
import { AccountField } from '~typings/AccountInfo';
import { LoginFieldType, LoginFormType } from '~typings/StartupConfig';
import { UsernameAvailabilityType } from '~typings/UsernameAvailabilityType';

const RESEND_PASSWORD_DELAY = 300;

type Props = Readonly<{
  isPasswordRecoveryMode: boolean;
  onNeedToLeave: () => void;
  setIsProcessing: (isProcessing: boolean) => void;
}>;

const RecoveryButton: React.FC<Props> = (props: Props) => {
  const {
    smartTV: { isSimpleAuth },
    startup: {
      login_form: { login_form_type, login_field_type },
    },
  } = useConfig();

  const { isFocused } = useFocuserContext();

  const { data: accountInfo } = useAccount();
  const username = accountInfo?.[AccountField.Username];

  const { mutate: checkUsername, data: availabilityInfo } = useUsernameAvailabilityMutation();
  const { mutate: sendPasswordBySms } = usePasswordBySms();

  const [resendPasswordCounter, setResetPasswordCounter] = useCountdownCounter();

  const isResendPasswordAvailable = availabilityInfo?.data.type === UsernameAvailabilityType.Msisdn;

  React.useLayoutEffect(() => {
    if (props.isPasswordRecoveryMode && isFocused && resendPasswordCounter > 0) {
      props.onNeedToLeave();
    }
  }, [props.isPasswordRecoveryMode, resendPasswordCounter > 0, isFocused]);

  React.useEffect(() => {
    if (
      username &&
      isSimpleAuth === true &&
      login_field_type !== LoginFieldType.Email &&
      login_form_type === LoginFormType.Implicit
    ) {
      checkUsername({ username });
    }
  }, [username, isSimpleAuth, login_field_type, login_form_type]);

  let messageId = !props.isPasswordRecoveryMode
    ? FORGOT_PIN
    : isResendPasswordAvailable
      ? AUTH_SEND_CODE
      : FORGOT_PASSWORD;

  const buttonClickHandler: FocuserClickHandler = (ev) => {
    if (props.isPasswordRecoveryMode && isResendPasswordAvailable && username) {
      ev.stop();
      props.setIsProcessing(true);
      sendPasswordBySms(
        { username },
        {
          onSuccess: () => {
            setResetPasswordCounter(RESEND_PASSWORD_DELAY);
          },
          onSettled: () => props.setIsProcessing(false),
        },
      );
    }
  };

  const renderButton = () => (
    <focuser.FocusableBlock
      isFocused
      onClick={ buttonClickHandler }
      noNeedForceFocus
      isLastBlock
      emitForceFocusOnHover
    >
      <SimpleButton
        isFocused={ isFocused }
        content={ <FormattedMessage id={ messageId } /> }
      />
    </focuser.FocusableBlock>
  );

  const renderCounter = () => (
    <FormattedMessage
      id={ RESEND_PASSWORD_COUNTER_TEXT }
      values={ { sec: resendPasswordCounter } }
    />
  );

  return props.isPasswordRecoveryMode && resendPasswordCounter ? renderCounter() : renderButton();
};

export default RecoveryButton;
