import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import * as productsStyles from '~components/ProductPlanPage/styles.module.css';
import getPlanMinPrice from '~lib/product/getPlanMinPrice';
import getRentMinPrice from '~lib/product/getRentMinPrice';
import { FREE, PRICE_PERIOD, TRIAL_PERIOD } from '~localization';
import Plan from '~typings/Plan';
import RentPlan from '~typings/RentPlan';
import { RadioButton } from '~ui/RadioButton';


type Props = {
  plans: (RentPlan | Plan)[] | undefined;
  isFocused: boolean;
  focusIndex: number;
  selectedPlan: RentPlan | Plan | null;
  onClick: (id: string) => void;
  intl: InjectedIntl;
};

function isPlan(arr: (RentPlan | Plan)[] | undefined): arr is Plan[] {
  return !!arr?.find(x => x.object === 'plan');
};

function isRentPlan(arr: (RentPlan | Plan)[] | undefined): arr is RentPlan[] {
  return !!arr?.find(x => x.object !== 'plan');
};

const PlansRadioButtons: React.FC<Props> = (props) => {
  const {
    isFocused,
    plans,
    focusIndex,
    selectedPlan,
    onClick,
    intl,
  } = props;

  const price = React.useMemo(
    () => isPlan(plans) ?
      getPlanMinPrice(plans)
      : isRentPlan(plans) ?
      getRentMinPrice(plans)
      : null,
    [plans],
  );

  return (
    <div className={ productsStyles.buttonWrapper }>
      {
        (plans || []).map((plan, idx) => {
          const trialPhase = plan.object === 'plan' && plan.eligible_phase?.type === 'trial' && plan.eligible_phase;
          const evergreenPhase = plan.object === 'plan' ?
            plan.phases?.find(phase => phase?.type === 'evergreen')
            : undefined;

          return (
            <div
              key={ plan.id }
              className={ cn(productsStyles.radioButton, {
                focusedNavigationNode: isFocused && focusIndex === idx,
              }) }
              onClick={ (_e) => onClick(plan.id)  }
            >
              <RadioButton
                isFocused={ isFocused && focusIndex === idx }
                isChecked={ selectedPlan?.id === plan.id }
              >
                <span className={ productsStyles.radioButtonTitle }>
                  {
                    isPlan(plans) || price === 'free' ? (
                      intl.formatMessage({ id: PRICE_PERIOD }, {
                        price: evergreenPhase?.billing.price.value === 0 ?
                          intl.formatMessage({ id: FREE })
                          : evergreenPhase?.billing.price.formatted,
                        durationUnit: evergreenPhase?.access_period.unit,
                        durationValue: evergreenPhase?.access_period.value,
                      })
                    ) : price?.money.formatted
                  }
                </span>
              </RadioButton>
              <div className={ productsStyles.radioButtonSubtitle }>
                {
                  trialPhase ? intl.formatMessage(
                    { id: TRIAL_PERIOD },
                    {
                      trialDurationUnits: trialPhase?.access_period.unit,
                      trialDurationValue: trialPhase?.access_period.value,
                    },
                  ) : isRentPlan(plans) ? plan.name : null
                }
              </div>
            </div>
          )
        })
      }
    </div>
  );
};


export default React.memo(injectIntl(PlansRadioButtons));
