// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerText--C3Km7 {\n  font-size: 1.25vw;\n  color: rgba(202, 202, 208, 1);\n  margin-top: 2.34375vw;\n  white-space: pre;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  text-align: center;\n}\n\n.previewLayout--oOZZ5 {\n  /* 100px */\n  margin-bottom: 7.8125vw;\n}\n\n.counter--bhkMt {\n  margin-bottom: 37px;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/AuthStepScreen/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAuB;EACvB,6BAAa;EACb,qBAAqB;EACrB,gBAAgB;EAChB,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".footerText {\n  font-size: $fontBody_vw;\n  color: $gw_07;\n  margin-top: 2.34375vw;\n  white-space: pre;\n  display: flex;\n  justify-content: center;\n  text-align: center;\n}\n\n.previewLayout {\n  /* 100px */\n  margin-bottom: 7.8125vw;\n}\n\n.counter {\n  margin-bottom: 37px;\n}\n"],"sourceRoot":""}]);
// Exports
export var footerText = "footerText--C3Km7";
export var previewLayout = "previewLayout--oOZZ5";
export var counter = "counter--bhkMt";
export default ___CSS_LOADER_EXPORT___;
