import {QueryKey, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import { UserDevice } from '~typings/Devices';

const PREFIX_OF_A_COMPOSITE_KEY = 'devices';

const getDeiceKey = (accessToken):QueryKey => [PREFIX_OF_A_COMPOSITE_KEY, accessToken];

const fetch = async (client, accessToken) => {
  const url = '/v1/user/devices';

  const response = await client.get(url, { access_token: accessToken, 'expand[user_device]': 'device' });

  return response.data;
}

export const useGetDevice = (enabled = true) => {
  const client = useClient();
  const { accessToken } = getAuthData();

  return useQuery<UserDevice[]>({
    queryKey: getDeiceKey(accessToken),
    queryFn: () => fetch(client, accessToken),
    enabled: !!accessToken && enabled,
  })
}

export const resetDeviceItems = ()=> {
  return queryClient.resetQueries({
    queryKey: [PREFIX_OF_A_COMPOSITE_KEY],
  })
}
