import React, { createContext, useContext } from "react";

export type FocuserContext = {
  isFocused: boolean;
  isForceFocusEnabled: boolean;
  isPointerEnabled: boolean;
};

export const FocuserContext = createContext<FocuserContext>({
  isFocused: false,
  isForceFocusEnabled: false,
  isPointerEnabled: false,
});
export const WrongNestingContext = createContext<boolean>(false);

export const useFocuserContext = () => {
  const context = useContext(FocuserContext);
  return context;
};

export const useIsWrongNestingContext = () => {
  const context = useContext(WrongNestingContext);
  return context;
};

export const DeafultContext : FocuserContext = { isFocused: true, isForceFocusEnabled: true, isPointerEnabled: true };

export const RootContextProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <FocuserContext.Provider value={ DeafultContext }>
      {children}
    </FocuserContext.Provider>
  );
};
