import { RefObject, useEffect, useState } from 'react';

import { ScrollDirection } from './useScrollable';


type Props = Readonly<{
  ref:  RefObject<HTMLDivElement>;
  wrapperSize?: number;
  direction?: ScrollDirection;
}>;

const useIsNeedToScroll = ({
  ref,
  wrapperSize,
  direction = ScrollDirection.Y,
}: Props, update: any[] = []) => {
  const [isNeedToScroll, setIsNeedToScroll] = useState<boolean | null>(null);

  useEffect(() => {
    if (ref.current) {
      const {
        width: wrapperWidth,
        height: wrapperHeight,
      } = ref.current.getBoundingClientRect();
      const { children } = ref.current;
      const [childrenWidth, childrenHeight] = Array.from(children)
        .reduce<[number, number]>(
          (res, cur) => {
            const rect = cur?.getBoundingClientRect();
            res[0] += rect?.width ?? 0;
            res[1] += rect?.height ?? 0;
            return res;
          },
          [0, 0],
        );

      setIsNeedToScroll(
        direction === ScrollDirection.X ?
          (childrenWidth > (wrapperSize ?? wrapperWidth))
          :
          (childrenHeight > (wrapperSize ?? wrapperHeight))
      );
    }
  }, [ref.current, direction, wrapperSize, update]);

  return [
    isNeedToScroll,
  ] as const ;
};


export default useIsNeedToScroll;
