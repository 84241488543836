/* eslint-disable max-len */
import { ac_00, gw_00, gw_02, gw_11 } from "customization-project-name/colors";
import * as React from 'react';

type Props = {
  width?: string
  height?: string
  isFocused: boolean
}

const IconChipsEpisodes = ({ width = '6.111111111111111vh', height = '6.111111111111111vh', isFocused }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={ isFocused ? ac_00 : gw_02 }/>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M12.6635 18.2666V29.4666H27.5969V18.2666H12.6635ZM18.6193 20.7215C18.9101 20.5553 19.2676 20.5577 19.5562 20.7277L23.5185 23.0625C23.8032 23.2303 23.978 23.5361 23.978 23.8666C23.978 24.1971 23.8032 24.5029 23.5185 24.6707L19.5562 27.0055C19.2676 27.1756 18.9101 27.1779 18.6193 27.0118C18.3285 26.8456 18.149 26.5363 18.149 26.2014V21.5318C18.149 21.1969 18.3285 20.8876 18.6193 20.7215Z" 
        fill={ isFocused ? gw_00 : gw_11 }
      />
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M14.5302 13.6C14.5302 13.0845 14.9481 12.6666 15.4635 12.6666H32.2635C32.779 12.6666 33.1969 13.0845 33.1969 13.6V26.6666C33.1969 27.1821 32.779 27.6 32.2635 27.6H31.3302C30.8147 27.6 30.3969 27.1821 30.3969 26.6666C30.3969 26.1512 30.8147 25.7333 31.3302 25.7333V14.5333H16.3897C16.3322 14.9937 15.9395 15.35 15.4635 15.35C14.9481 15.35 14.5302 14.9321 14.5302 14.4166V13.6ZM10.7969 17.3333C10.7969 16.8178 11.2147 16.4 11.7302 16.4H28.5302C29.0457 16.4 29.4635 16.8178 29.4635 17.3333V30.4C29.4635 30.9154 29.0457 31.3333 28.5302 31.3333H11.7302C11.2147 31.3333 10.7969 30.9154 10.7969 30.4V17.3333ZM12.6635 18.2666V29.4666H27.5969V18.2666H12.6635Z" 
        fill={ isFocused ? gw_00 : gw_11 }
      />
    </svg>
  </div>
);


export { IconChipsEpisodes };
