/* eslint-disable max-len */
import { ac_00, gw_00, gw_02, gw_11 } from "customization-project-name/colors";
import * as React from 'react';

type Props = {
  width?: string
  height?: string
  isFocused: boolean
}

const IconChipsSubtitles = ({ width = '6.111111111111111vh', height = '6.111111111111111vh', isFocused }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={ isFocused ? ac_00 : gw_02 } />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M33.2001 15.5649C33.2001 14.4842 32.2837 13.6 31.1637 13.6H12.8364C11.7164 13.6 10.8 14.4842 10.8 15.5649V26.3719C10.8 27.4526 11.7164 28.3368 12.8364 28.3368H25.3601V32.2666L29.7586 28.3368H31.1637C32.2837 28.3368 33.2001 27.4526 33.2001 26.3719V15.5649ZM16.4 23.5064C17.431 23.5064 18.2667 22.7 18.2667 21.7052C18.2667 20.7105 17.431 19.9041 16.4 19.9041C15.3691 19.9041 14.5334 20.7105 14.5334 21.7052C14.5334 22.7 15.3691 23.5064 16.4 23.5064ZM22 23.5064C23.031 23.5064 23.8667 22.7 23.8667 21.7052C23.8667 20.7105 23.031 19.9041 22 19.9041C20.9691 19.9041 20.1334 20.7105 20.1334 21.7052C20.1334 22.7 20.9691 23.5064 22 23.5064ZM29.4667 21.7052C29.4667 22.7 28.631 23.5064 27.6 23.5064C26.5691 23.5064 25.7334 22.7 25.7334 21.7052C25.7334 20.7105 26.5691 19.9041 27.6 19.9041C28.631 19.9041 29.4667 20.7105 29.4667 21.7052Z"
            fill={ isFocused ? gw_00 : gw_11 } />
    </svg>
  </div>
);


export { IconChipsSubtitles };
