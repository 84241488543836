import Channel from '~typings/Channel';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';


const isChannel = (item: PlayerMediaItem | null): boolean => (
  item?.object === ItemObject.Channel
);

const isProgramEvent = (item: PlayerMediaItem | null): boolean => (
  item?.object === ItemObject.ProgramEvent
);

const isEpisode = (item: PlayerMediaItem | null): boolean => (
  item?.object === ItemObject.Episode
);

const isTimeShiftAllowed = (item: PlayerMediaItem | null): boolean => (
  isChannel(item)
  && ((item as Channel).timeshift_availability.available)
);


export {
  isChannel,
  isEpisode,
  isProgramEvent,
  isTimeShiftAllowed,
};
