import { QueryKey } from 'react-query';

import { queryCache } from '~global';
import AppConfig from '~typings/AppConfig';
import ResponseMany from '~typings/ResponseMany';

import { LIMIT } from './constants';


export function getAllFetchedItems<T>(key: QueryKey): ResponseMany<T[]> | undefined {
  const queries = queryCache.findAll(key);

  if (queries.length === 0) {
    return undefined;
  }


  const data: T[] = [];
  let total = 0;

  for (let i = 0; i < queries.length; i += 1) {
    const query = queries[i].state.data as ResponseMany<T[]> | undefined;

    if (query) {
      total = query.meta?.pagination.total;
      data.push.apply(data, query.data);
    }
  }

  return ({
    data,
    meta: {
      status: 200,
      pagination: {
        total,
        count: data.length,
        limit: 0,
        offset: 0,
      },
    },
  });
}


export const getLimitFromConfig = (config: AppConfig) => {
  const maxPerPageFromConfig = config.serverGenerated.pagination?.max_per_page || LIMIT;
  const limit = Math.min(maxPerPageFromConfig, LIMIT);
  return limit;
};

export const reducePaginatedPages = <T>(pages: ResponseMany<T[]>[]) => {
  const lastData = pages[pages.length - 1];
  if (!lastData) {
    return undefined;
  }
  return pages.reduce(
    (acc, cur) => {
      acc.data = acc.data.concat(cur.data);
      acc.meta.pagination.count = acc.data.length;
      acc.meta.pagination.limit = acc.data.length;
      acc.meta.pagination.offset = 0;
      return acc;
    },
    {
      data: [],
      meta: {
        // Тут копируем данные, чтобы ничего не заафектить
        status: lastData.meta.status,
        pagination: { ...lastData.meta.pagination },
      },
    },
  );
};

export const getNextPageForInfinityPagination = <T>(lastPage: ResponseMany<T>) => {
  const { offset, limit, total } = lastPage.meta.pagination;
  if (offset + limit >= total) {
    return undefined;
  }
  return offset / limit + 1;
};
