import PlatformName from '~typings/PlatformName';

enum FocusOn {
  Channels = 0,
  Events = 1,
  Description = 2,
}

const DELAYED_CHANNEL_TIME_IN_MS = 250;


const isAnimatedScroll = (osVersion?: string): boolean => {
  try {
    if (__PLATFORM__ === PlatformName.WebOS) {
      return false;
    }

    const osVersionNumber = parseInt((osVersion || '').toLowerCase().replace('tizen ', '').split('.')[0], 10);

    if (__PLATFORM__ === PlatformName.Tizen && osVersionNumber > 3) {
      return true
    }

    return false;
  } catch (ignore) {
    return false;
  }
};

export {
  DELAYED_CHANNEL_TIME_IN_MS,
  FocusOn,
  isAnimatedScroll,
}
