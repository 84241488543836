import { QueryKey,useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import { LIMIT } from '~hooks/fetch/constants';
import { fetchOptionsBasic,FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import deleteCacheWithOldAccessTokens from '~lib/ReactQuery/deleteCacheWithOldAccessTokens';
import Banner from '~typings/Banner';
import ResponseMany from '~typings/ResponseMany';


type Banners = ResponseMany<Banner[]>;

const getBannersKeyWithoutAccessToken = (pageID?: string): QueryKey => ['banners', pageID];
const getBannersKey = (pageID?: string): QueryKey => ['banners', pageID, getAuthData().accessToken];


const getCachedBanners = (pageID?: string) => (
  queryClient.getQueryData<Banners>(getBannersKey(pageID))
);

const fetch = async (pageID: string | undefined, client: ApiClient) => {
  const cacheData = getCachedBanners(pageID);

  if (cacheData) { return cacheData; }

  return await client.get(
    fetchURLs[FetchType.Banners],
    {
      ...fetchOptionsBasic[FetchType.Banners],
      'filter[pages_id_in]': pageID,
      sort: 'relevance',
      'page[offset]': 0,
      'page[limit]': LIMIT,
      access_token: getAuthData().accessToken,
    }
  );
};


const useBanners = (pageID?: string) => {
  const queryKey = getBannersKey(pageID);
  const client = useClient();

  deleteCacheWithOldAccessTokens(getBannersKeyWithoutAccessToken(pageID), 2);

  return useQuery<Banners>({
    queryKey,
    queryFn: () => fetch(pageID, client),
    enabled: !!pageID,
  });
};


export {
  fetch,
  getBannersKey,
  getCachedBanners,
};

export default useBanners;
