import * as React from 'react';

import { DDListItem, getCorrectListValue } from '~components/InputWithPopup/helpers';
import ChangeButton from '~ui/ChangeButton/component';

import * as styles from '../../styles.module.css';

type Props = Readonly<{
  items?: DDListItem[];
  fieldValue?: string | number | undefined;
  placeholder: string;
  title?: string;
  maxLength?: number;
  minLength?: number;
  isFocused: boolean;
}>;

const ProfileField: React.FC<Props> = ({
  items,
  fieldValue,
  placeholder,
  isFocused,
}: Props) => {


  const { value, text } = React.useMemo(
    () => getCorrectListValue(fieldValue, items),
    [fieldValue, items]
  );

  return (
    <div className={ styles.profileFieldWrap }>
      <div className={ styles.profileFieldValue }>
        { !(value && value.length) 
            ? <div className={ styles.namePlaceholder }>
                { placeholder }
              </div>
            : <>{text}</>
        }
      </div>
      <ChangeButton
        isFocused={ isFocused }
        className={ styles.icon }
        iconType={ 'pen' }
      />
    </div>
  );
};

export default ProfileField;
