import create, { SetState } from 'zustand';

import { LineFocusInfo } from '~components/NavMenu/line/component.helpers';


export interface State {
  breadCrumbs: Array<LineFocusInfo>;
  isHovered: boolean;
  setHovered: (next: boolean) => void;
  setBreadCrumbs: (next: Array<LineFocusInfo>) => void;
}

const useNavMenu = create<State>(
  (set: SetState<State>) => ({
    breadCrumbs: [],
    isHovered: false,


    setHovered: (next: boolean) => set((draft: State) => {
      draft.isHovered = next;
    }),
    setBreadCrumbs: (next: Array<LineFocusInfo>) => set((draft: State) => {
      draft.breadCrumbs = next;
    }),
  })
);


export default useNavMenu;
