import focuser from '@focuser';
import { chunk } from 'lodash';
import React, { useMemo } from 'react';

import { Typography } from '~app/ui-kit/Typography';
import { usePageManagerPointersBlocker } from '~components/PageManager';
import { useAnimationContext } from '~components/Provider/Animation';
import { useOldNavigationBlocker } from '~hooks/useOldNavigationBlocker';
import { PopupIsolated } from '~ui/Popup';
import { SliderOffsetable } from '~ui/SliderOffsetable';

import { DropdownSelectButtons } from './components/DropdownSelectButtons/DropdownSelectButtons';
import { DropdownSelectColumn } from './components/DropdownSelectColumn';
import {
  useColumnsNavigation,
  useDropdownSelectPopupNavigation,
  useSelectedOptionsOps,
} from './DropdownSelectPopup.hooks';
import * as styles from './DropdownSelectPopup.module.css';
import { COLUMN_CHUNK_SIZE } from './DropdownSelectPopup.utils';
import { SelectOption } from './types';

export type Props<T> = {
  /**
   * Заголовок, который будет показан в шапке
   */
  header: string;
  /**
   * Все элементы, которые можно выбрать
   */
  options: SelectOption<T>[];
  /**
   * Начальные выбранные элементы
   */
  initialSelectedOptions?: SelectOption<T>[];
  /**
   * Можно ли выбирать несколько элементов
   */
  isMultiselect?: boolean;
  /**
   * Если isMultiselect = false, то массив будет из 1 или 0 выбранных элементов
   * Иначе массив будет из выбранных элементов (от 0 до количества выбранных элементов)
   */
  onApply: (values: SelectOption<T>[]) => void;
  /**
   * Вызывается при нажатии на кнопку "Отмена"
   * Также вызывается при нажатии на кнопку "назад"
   */
  onCancel: () => void;
};

type DropdownSelectPopupType = <T = string>(props: Props<T>) => React.ReactElement | null;

/**
 * Компонент-попап, для выбора значений из представленных.
 *
 * Можно посмотреть в сторибуке.
 */
/* eslint-disable-next-line react/display-name */
const DropdownSelectPopup$: DropdownSelectPopupType = (props) => {
  useOldNavigationBlocker();
  usePageManagerPointersBlocker();

  const { isAnimationEnabled } = useAnimationContext();

  const optionsByChunks = useMemo(() => chunk(props.options, COLUMN_CHUNK_SIZE), [props.options]);

  const { columnsFocusedState, setColumnsFocusedState, ...columnsFocuserProps } =
    useColumnsNavigation({
      optionsByChunks,
    });

  const { focusedOn, setFocusedOn, ...mainFocuserProps } = useDropdownSelectPopupNavigation();

  const {
    selectedOptionsByColumnMap,
    handleClickOnCheckbox,
    handleClickOnApply,
    handleResetAll,
    isAnyItemSelected,
  } = useSelectedOptionsOps({
    optionsByChunks,
    initialSelectedOptions: props.initialSelectedOptions,
    onApply: props.onApply,
    isMultiSelect: props.isMultiselect ?? false,
  });

  return (
    <PopupIsolated focuserClassName={ styles.popup }>
      <Typography
        variant="h3_medium"
        className={ styles.header }
      >
        {props.header}
      </Typography>
      <focuser.FocusableBlock
        isFocused
        { ...mainFocuserProps }
        noNeedForceFocus
        returnButtonType={ 'back' }
        onKeyReturn={ (e) => {
          e.stop();
          props.onCancel();
        } }
      >
        <focuser.FocusableBlock
          isFocused={ focusedOn === 'columns' }
          { ...columnsFocuserProps }
          onForceFocus={ () => setFocusedOn('columns') }
        >
          <SliderOffsetable
            focusedIndex={ columnsFocusedState.columnIdx }
            sliderClassName={ styles.slider }
            wrapperClassName={
              isAnimationEnabled ? styles.sliderWrapper : styles.sliderWrapperNoAnimation
            }
            animationDurationInMS={ isAnimationEnabled ? 200 : 0 }
          >
            {optionsByChunks.map((options, index) => (
              <DropdownSelectColumn
                key={ index }
                columnIdx={ index }
                options={ options }
                focusedIdx={
                  columnsFocusedState.columnIdx === index ? columnsFocusedState.rowIdx : null
                }
                onForceFocus={ setColumnsFocusedState }
                onClick={ handleClickOnCheckbox }
                selectedOptionsInColumn={ selectedOptionsByColumnMap.current[index] || null }
                className={ styles.selectColumn }
              />
            ))}
          </SliderOffsetable>
        </focuser.FocusableBlock>
        <focuser.FocusableBlock
          isFocused={ focusedOn === 'buttons' }
          onForceFocus={ () => setFocusedOn('buttons') }
          className={ styles.buttons }
        >
          <DropdownSelectButtons
            onApply={ handleClickOnApply }
            onReset={ handleResetAll }
            onCancel={ props.onCancel }
            isResetAvailable={ isAnyItemSelected }
          />
        </focuser.FocusableBlock>
      </focuser.FocusableBlock>
    </PopupIsolated>
  );
};

export const DropdownSelectPopup = React.memo(DropdownSelectPopup$) as DropdownSelectPopupType;
