enum MenuIcon {
  'Account' = 'account',
  'Search' = 'search',
  'Home' = 'home',
  'Pages' = 'pages',
  'Products' = 'products',
  'MyVideoLibrary' = 'myVideoLibrary',
  'TV' = 'tv',
}


export default MenuIcon;
