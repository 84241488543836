import { FocuserKeyHandler } from '@focuser';
import React, { useMemo } from 'react';

import Question from '~typings/Question';
import QuestionSection from '~typings/QuestionSection';

import { QuestionsBySection } from './types';
import { sectionsByPriority } from './utils';

export const useHelpNavigation = (questionsBySection: QuestionsBySection) => {
  const [focusedState, setFocusedState] = React.useState({
    section: QuestionSection.Main,
    index: 0,
  });

  /**
   * Следующая секция, на которую нужно будето перейти
   */
  const nextSection = useMemo(() => {
    const { section } = focusedState;
    const currentSectionIndex = sectionsByPriority.indexOf(section);
    const nextSection = sectionsByPriority
      .slice(currentSectionIndex + 1)
      .find((section) => questionsBySection[section]);
    return nextSection;
  }, [focusedState.section, questionsBySection]);

  /**
   * Предыдущая секция, на которую нужно будето перейти
   */
  const prevSection = useMemo(() => {
    const { section } = focusedState;
    const currentSectionIndex = sectionsByPriority.indexOf(section);
    const prevSection = sectionsByPriority
      .slice(0, currentSectionIndex)
      .reverse()
      .find((section) => questionsBySection[section]);
    return prevSection;
  }, [focusedState.section, questionsBySection]);

  /**
   * Кол-во вопросов, которые есть в секциях
   */
  const questionsCountBySection: Record<QuestionSection, number> = useMemo(
    () =>
      sectionsByPriority.reduce(
        (acc, section) => {
          const questions = questionsBySection[section];
          if (!questions) {
            return { ...acc, [section]: 0 };
          }

          // 1 добавляется для заголовков (они тоже div-ы, но слайдер не должен на них фокусироваться)
          return { ...acc, [section]: questions.length + 1 };
        },
        {} as Record<QuestionSection, number>,
      ),
    [questionsBySection],
  );

  /**
   * Специальный индекс, для слайдера, который считаем из текущей секции и индекса
   */
  const focusedIdxForSlider = useMemo(() => {
    const { section, index } = focusedState;
    const questions = questionsBySection[section];
    if (!questions) {
      return 0;
    }

    const questionsCountBeforeCurrentSection = sectionsByPriority
      .slice(0, sectionsByPriority.indexOf(section))
      .reduce((acc, section) => {
        return acc + questionsCountBySection[section];
      }, 0);

    // 1 добавляется для заголовков
    return questionsCountBeforeCurrentSection + index + 1;
  }, [questionsBySection, focusedState]);

  const onKeyDown: FocuserKeyHandler = (e) => {
    const { index, section } = focusedState;
    const questions = questionsBySection[section];
    if (!questions) {
      return;
    }

    if (index < questions.length - 1) {
      setFocusedState({
        section,
        index: index + 1,
      });
      e.stop();
      return;
    }

    if (nextSection) {
      setFocusedState({
        section: nextSection,
        index: 0,
      });
      e.stop();
      return;
    }
  };

  const onKeyUp: FocuserKeyHandler = (e) => {
    const { index, section } = focusedState;
    const questions = questionsBySection[section];

    if (!questions) {
      return;
    }

    if (index > 0) {
      setFocusedState({
        section,
        index: index - 1,
      });
      e.stop();
      return;
    }

    if (prevSection) {
      setFocusedState({
        section: prevSection,
        index: questionsBySection[prevSection]!.length - 1,
      });
      e.stop();
      return;
    }
  };


  const isPointerUpAvailable = useMemo(() => {
    if(prevSection) {
      return true;
    }

    if(focusedState.index > 0) {

      return true;
    }

  }, [questionsBySection, focusedState]);


  const isPointerDownAvailable = useMemo(() => {
    if(nextSection) {
      return true;
    }

    const questions = questionsBySection[focusedState.section];
    if(!questions) {
      return false;
    }

    if(focusedState.index < questions.length - 1) {
      return true;
    }

  }, [questionsBySection, focusedState]);


  return {
    focusedIdxForSlider,
    focusedState,
    onKeyDown,
    onKeyUp,
    isPointerUpAvailable,
    isPointerDownAvailable,
    setFocusedState,
  };
};


/**
 * Хук возвращает объект-карту, ключом которой является название секции, а значением - массив вопросов этой секции
 */
export const useQuestionsBySection = (questions?: Question[]) => {
  return React.useMemo(() => {
    return (
      questions?.reduce(
        (acc, item) => {
          acc[item.section] = [...(acc[item.section] || []), item];
          return acc;
        },
        {} as { [key in QuestionSection]?: Question[] },
      ) ?? {}
    );
  }, [questions]);
}
