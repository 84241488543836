import cn from 'classnames';
import * as React from 'react';

import {CurrentEventProgress} from "~components/CurrentEventProgress";
import Fade from '~ui/Effects/Fade';

import Icon from './asset/IconTVOnAir';
import * as style from './styles.module.css';


type Props = Readonly<{
  name?: string;
  eventName?: string;
  startAt?: string;
  endAt?: string;
  withAnimation?: boolean;
  isHidden?: boolean;
  isBigSize?: boolean;
  isFullSize?: boolean;
  withIcon?: boolean;
  fadeDuration?: number;
  fadeDelay?: number;
}>;


const CurrentEventInfoRender: React.FC<Props> = (props: Props) => {
  const { name, eventName, isHidden, isFullSize, isBigSize, withIcon, fadeDuration, fadeDelay } = props;

  const renderIcon = React.useMemo(() => (
    <div
      className={ cn(style.icon, {
        [style.iconSlowHide]: !withIcon,
      }) }
    >
      <Icon />
    </div>
  ), [props.withIcon]);
  const renderName = React.useMemo(() => {
    if (eventName === undefined) {
      return (
        <div
          className={ style.currentEventName }
        />
      );
    }

    return (
      <div
        className={ style.currentEventName }
      >
        { eventName }
      </div>
    );
  }, [eventName]);
  const renderChannelName = React.useMemo(() => {
    if (!name) { return null; }

    return (
      <div
        className={ style.channelName }
      >
        { name }
      </div>
    );
  }, [name]);

  return (
    <Fade
      isVisible={ !isHidden }
      duration={ fadeDuration }
      delay={ fadeDelay }
    >
      {
        <div
          className={
            cn(style.root, {
              [style.bigSize]: isBigSize,
              [style.fullSize]: isFullSize,
              [style.withAnimation]: props.withAnimation,
            })
          }
        >
          { props.withIcon && renderIcon }
          <div
            className={ style.eventWrapper }
          >
            { renderChannelName }
            {
              (props.startAt && props.endAt) && (
                <CurrentEventProgress
                  className={ style.currentEventProgressLine }
                  startAt={ props.startAt }
                  endAt={ props.endAt }
                />
              )
            }
            { renderName }
          </div>
        </div>
      }
    </Fade>
  );
};


export default CurrentEventInfoRender;
