import focuser from '@focuser';
import React from 'react';
import { injectIntl } from 'react-intl';

import { SimpleButtonFocuser } from '~app/ui-kit/SimpleButton';
import { Typography } from '~app/ui-kit/Typography';
import { useAutoInfinityQuestions } from '~hooks/fetch/useQuestions';
import { SpinnerFullScreen } from '~newapp/globalBlocks/spinner';
import Question from '~typings/Question';
import QuestionSection from '~typings/QuestionSection';
import { SliderVerticable } from '~ui/SliderVerticable';

import * as styles from './Help.module.css';
import { useHelpNavigation, useQuestionsBySection } from './hooks';
import { QuestionPopup } from './QuestionPopup';
import { sectionsByPriority, sectionTitles } from './utils';

export const Help = injectIntl(({ intl }) => {
  const { isLoading, data } = useAutoInfinityQuestions();
  const questions = data?.data;

  const questionsBySections = useQuestionsBySection(questions);
  const {
    focusedIdxForSlider,
    focusedState,
    setFocusedState,
    onKeyDown,
    onKeyUp,
    isPointerUpAvailable,
    isPointerDownAvailable,
  } = useHelpNavigation(questionsBySections);

  const [openedQuestion, setOpenedQuestion] = React.useState<Question | null>(null);

  if (isLoading || !questions) {
    return (
      <focuser.Isolation>
        <SpinnerFullScreen />
      </focuser.Isolation>
    );
  }


  const renderSection = (section: QuestionSection) => {
    const questions = questionsBySections[section];
    if (!questions) {
      return null;
    }

    return (
      <React.Fragment key={ section }>
        <Typography
          className={ styles.sectionTitle }
          variant="h5_bold"
        >
          {intl.formatMessage({ id: sectionTitles[section] })}
        </Typography>
        {questions.map((item, index) => (
          <SimpleButtonFocuser
            key={ item.id }
            focuserClassName={ styles.questionButton }
            isFocused={ focusedState.section === section && focusedState.index === index }
            onForceFocus={ () => setFocusedState({ section, index }) }
            onClick={ () => setOpenedQuestion(item) }
            emitForceFocusOnHover
          >
            {item.questionSanitized}
          </SimpleButtonFocuser>
        ))}
      </React.Fragment>
    );
  };

  return (
    <>
      {openedQuestion && (
        <QuestionPopup
          question={ openedQuestion }
          onBack={ () => setOpenedQuestion(null) }
        />
      )}
      <focuser.FocusableBlock
        className={ styles.Help }
        isFocused
        isPointerDownAvailable={ isPointerDownAvailable }
        isPointerUpAvailable={ isPointerUpAvailable }
        onKeyDown={ onKeyDown }
        onKeyUp={ onKeyUp }
        noNeedForceFocus
      >
        <SliderVerticable
          childBufferCount={ 2 }
          wrapperClassName={ styles.sliderWrapper }
          sliderClassName={ styles.slider }
          focusedIndex={ focusedIdxForSlider }
        >
          {sectionsByPriority.map((section) => renderSection(section))}
        </SliderVerticable>
      </focuser.FocusableBlock>
    </>
  );
});
