import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { PartCard, Props as PartCardProps } from './PartCard';
import * as styles from './styles.modules.css';


export type Props =  PartCardProps & Omit<FocusableBlockProps, 'isLastBlock'>;

export const PartCardFocuser: React.FC<Props> = ({
  part,
  audioshowCertificationRatings,
  partProgress,
  ...focuserProps
}) => {

  return (
    <focuser.FocusableBlock
      isLastBlock
      { ...focuserProps }
      className={ styles.partCardFocuser }
    >
      {({ isFocused }) => (
        <PartCard
          part={ part }
          partProgress={ partProgress }
          audioshowCertificationRatings={ audioshowCertificationRatings }
          isFocused={ isFocused }
        />
      )}
    </focuser.FocusableBlock>
  );
};
