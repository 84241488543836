import focuser from '@focuser';
import React, { useCallback, useEffect, useState } from 'react';

import { FiltersLine, useRememberFilters } from '~components/FiltersLine';
import { useRestoreFilters } from '~components/FiltersLine';
import DropdownFilter from '~typings/DropdownFilter';
import QuickFilter from '~typings/QuickFilter';
import { CardConfig } from '~typings/ScreenCardConfigs';
import {
  SelectedFilters,
} from '~typings/SelectedFilters';

import { CardCollectionContentGrid } from '../CardColContentGrid/CardColContentGrid';

type Props = {
  /**
   * Фильтры из блока фильтров из `page`-а
   */
  filters: (QuickFilter | DropdownFilter)[] | undefined;
  /**
   * Идентификатор коллекции карточек
   */
  cardCollectionId?: string;

  /**
   * Конфиг карточек для коллекции
   */
  cardConfig?: CardConfig;
  /**
   * Коллбэк, который будет вызван, если компонент может принять фокус
   * Вызывается 1 раз, или не вызовится вовсе
   */
  onReady: () => void;

  /**
   * Коллбэк, который будет вызван когда пользователь уходит вниз по гриду.
   */
  onScrollToGrid: (grid: HTMLDivElement | null) => void;

  onFocusRestore: () => void;
};

export const CardColContentGridWithFilters$: React.FC<Props> = ({
  filters,
  onReady,
  cardCollectionId,
  cardConfig,
  onScrollToGrid,
  onFocusRestore,
}) => {

  const rememberedFilters =  useRestoreFilters(filters);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(rememberedFilters ?? {});

  useRememberFilters(selectedFilters);

  const [canFocusOnGrid, setCanFocusOnGrid] = useState(false);

  const isFiltersExist = !!filters?.length;
  const isCardCollectionExist = !!cardCollectionId && !!cardConfig;

  const [focusOn, setFocusOn] = useState<'filters' | 'grid'>(() =>
    isFiltersExist ? 'filters' : 'grid',
  );

  useEffect(() => {
    if (isFiltersExist || isCardCollectionExist) {
      onReady();
    }
  }, []);

  const onGridFocusRestore = useCallback(() => {
    setFocusOn('grid');
    onFocusRestore();
  }, []);

  const renderFilters = () => {
    if (!isFiltersExist) {
      return null;
    }

    return (
      <focuser.FocusableBlock
        isFocused={ focusOn === 'filters' }
        onForceFocus={ () => setFocusOn('filters') }
        onKeyDown={ (e) => {
          if (canFocusOnGrid) {
            e.stop();
            setFocusOn('grid');
          }
        } }
        isPointerDownAvailable={ canFocusOnGrid }
      >
        <FiltersLine
          filters={ filters }
          onChangeFilters={ setSelectedFilters }
          selectedFilters={ selectedFilters }
        />
      </focuser.FocusableBlock>
    );
  };

  const renderGrid = () => {
    if (!isCardCollectionExist) {
      return null;
    }
    return (
      <focuser.FocusableBlock
        isFocused={ focusOn === 'grid' }
        onForceFocus={ () => setFocusOn('grid') }
        onKeyUp={ (e) => {
          if (isFiltersExist) {
            e.stop();
            setFocusOn('filters');
          }
        } }
        isPointerUpAvailable={ isFiltersExist }
      >
        <CardCollectionContentGrid
          selectedFilters={ selectedFilters }
          cardCollectionId={ cardCollectionId }
          cardConfig={ cardConfig }
          onScrollToGrid={ onScrollToGrid }
          onFocusAvailabilityChange={ setCanFocusOnGrid }
          onFocusRestore={ onGridFocusRestore }
        />
      </focuser.FocusableBlock>
    );
  };

  return (
    <>
      {renderFilters()}
      {renderGrid()}
    </>
  );
};

/**
 * Компонент показывает фильтры и грид с карточками
 */
export const CardColContentGridWithFilters = React.memo(CardColContentGridWithFilters$);
