// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".progressBarWrapper--AByjc {\n  position: absolute;\n  width: 100%;\n  left: 0;\n  bottom: 0;\n\n  /* 4px */\n  height: 0.3125vw;\n  overflow: hidden;\n  background-color: rgba(255, 255, 255, 0.2);\n\n  /* 2px */\n  -webkit-border-radius: 0.15625vw;\n          border-radius: 0.15625vw;\n}\n\n.progressBar--gsfjT {\n  position: absolute;\n\n  /* 4px */\n  height: 0.3125vw;\n  background-color: rgba(253, 216, 53, 1);\n  -webkit-transition: width 1s;\n  transition: width 1s;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/blocks/playerPopcorn/Player/controls/Popup/ChannelFlipper/ChannelBlock/CardInfo/CurrentEventProgress.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,OAAO;EACP,SAAS;;EAET,QAAQ;EACR,gBAAgB;EAChB,gBAAgB;EAChB,0CAAuB;;EAEvB,QAAQ;EACR,gCAAwB;UAAxB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;;EAElB,QAAQ;EACR,gBAAgB;EAChB,uCAAwB;EACxB,4BAAoB;EAApB,oBAAoB;AACtB","sourcesContent":[".progressBarWrapper {\n  position: absolute;\n  width: 100%;\n  left: 0;\n  bottom: 0;\n\n  /* 4px */\n  height: 0.3125vw;\n  overflow: hidden;\n  background-color: $w_20;\n\n  /* 2px */\n  border-radius: 0.15625vw;\n}\n\n.progressBar {\n  position: absolute;\n\n  /* 4px */\n  height: 0.3125vw;\n  background-color: $ac_00;\n  transition: width 1s;\n}\n"],"sourceRoot":""}]);
// Exports
export var progressBarWrapper = "progressBarWrapper--AByjc";
export var progressBar = "progressBar--gsfjT";
export default ___CSS_LOADER_EXPORT___;
