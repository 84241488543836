// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.DropdownSelectColumn--CuRTO {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n     -moz-box-orient: vertical;\n     -moz-box-direction: normal;\n          flex-direction: column;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: start;\n  -webkit-align-items: flex-start;\n     -moz-box-align: start;\n          align-items: flex-start;\n\n  /* 330px */\n  width: 25.78125vw;\n  -webkit-flex-shrink: 0;\n          flex-shrink: 0;\n}\n\n.checkboxButton--rCIIc {\n  /* 20px */\n  margin-bottom: 1.5625vw;\n}\n\n.checkboxButton--rCIIc:last-child {\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/DropdownSelectPopup/components/DropdownSelectColumn/DropdownSelectColumn.module.css"],"names":[],"mappings":";AACA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,4BAAsB;EAAtB,6BAAsB;EAAtB,8BAAsB;KAAtB,yBAAsB;KAAtB,0BAAsB;UAAtB,sBAAsB;EACtB,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;;EAEvB,UAAU;EACV,iBAAiB;EACjB,sBAAc;UAAd,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["\n.DropdownSelectColumn {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n\n  /* 330px */\n  width: 25.78125vw;\n  flex-shrink: 0;\n}\n\n.checkboxButton {\n  /* 20px */\n  margin-bottom: 1.5625vw;\n}\n\n.checkboxButton:last-child {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var DropdownSelectColumn = "DropdownSelectColumn--CuRTO";
export var checkboxButton = "checkboxButton--rCIIc";
export default ___CSS_LOADER_EXPORT___;
