export enum Language {
  Ru = 'ru',
  En = 'en',
  Fa = 'fa',
  Uz = 'uz',
  // Kz = 'kz',
  Kk = 'kk',
  Zh = 'zh',
  Ky = 'ky'
}

export default Language;
