/**
 * Дата атрибуты должны быть с маленькой буквы, при изменнии этой переменной учесть
 */
import {
  horizontalCardHeightNoTextVW,
  horizontalCardHeightTextVW,
  squareCardHeightNoTextVW,
  squareCardHeightTextVW,
  verticalCardHeightNoTextVW,
  verticalCardHeightTextVW,
} from '~app/variables';
import { CardConfig, CardConfigLayout } from '~typings/ScreenCardConfigs';

export const ROW_IDX = 'rowidx';
export const DATA_ROW_IDX = `data-${ROW_IDX}`;

const heightByLayoutText: Record<CardConfigLayout, number> = {
  [CardConfigLayout.Vertical]: verticalCardHeightTextVW,
  [CardConfigLayout.Square]: squareCardHeightTextVW,
  [CardConfigLayout.Horizontal]: horizontalCardHeightTextVW,
  [CardConfigLayout.News]: horizontalCardHeightTextVW,
  [CardConfigLayout.NewsWithoutPicture]: horizontalCardHeightTextVW,
};

const heightByLayoutNoText: Record<CardConfigLayout, number> = {
  [CardConfigLayout.Vertical]: verticalCardHeightNoTextVW,
  [CardConfigLayout.Square]: squareCardHeightNoTextVW,
  [CardConfigLayout.Horizontal]: horizontalCardHeightNoTextVW,
  [CardConfigLayout.News]: horizontalCardHeightNoTextVW,
  [CardConfigLayout.NewsWithoutPicture]: horizontalCardHeightNoTextVW,
};

export const getHeightByCardConfig = (cardConfig: CardConfig) => {
  return cardConfig.disable_text
    ? heightByLayoutNoText[cardConfig.layout]
    : heightByLayoutText[cardConfig.layout];
};



export const maxInDomByLayout: Record<CardConfigLayout, number> = {
  [CardConfigLayout.Vertical]: 5,
  [CardConfigLayout.Square]: 7,
  [CardConfigLayout.Horizontal]: 7,
  [CardConfigLayout.News]: 7,
  [CardConfigLayout.NewsWithoutPicture]: 7,
};


export const chunkByLayout: Record<CardConfigLayout, number> = {
  [CardConfigLayout.Vertical]: 6,
  [CardConfigLayout.Square]: 6,
  [CardConfigLayout.Horizontal]: 4,
  [CardConfigLayout.News]: 4,
  [CardConfigLayout.NewsWithoutPicture]: 4,
};


export const GRID_FOCUSED_SEARCH_PARAM = 'GRID_FOCUSED_STATE'

export const GRID_ANIMATION_MS = 400;
