import * as React from 'react';

import { IconDisLikeBase } from './dislikeBase';

const IconDisLikeFill: React.FC<{ isFocused: boolean }> = ({ isFocused }) => (
  <IconDisLikeBase
    isFocused={ isFocused }
    isPressed={ true }
  />
);


export default React.memo(IconDisLikeFill);
