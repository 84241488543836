// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".isolationContainer--g7O3n {\n  width: 100vw;\n  height: 100vh;\n}\n\n.QuestionPopup--E1dzJ {\n  /* 70px 88px 42px 88px */\n  margin: 5.46875vw 6.875vw 3.28125vw 6.875vw;\n}\n\n\n.title--fqoud {\n  /* 20px */\n  margin-bottom: 1.5625vw;\n}\n\n.question--MsDJa {\n  /* 30px */\n  margin-bottom: 2.34375vw;\n}\n\n\n.answerWrapper--DGYy6 {\n  /* 480px */\n  height: 37.5vw;\n\n  /* 324px */\n  margin-right: 25.3125vw;\n  overflow: hidden;\n}\n\n.answer--mD8v6 {\n  color: rgba(223, 223, 228, 1);\n}\n\n\n.scrollbar--Q9JFe {\n  position: fixed;\n  z-index: 2;\n  top: 3.90625vw;\n  bottom: 3.90625vw;\n  right: 2.03125vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/Help/QuestionPopup.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,2CAA2C;AAC7C;;;AAGA;EACE,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,wBAAwB;AAC1B;;;AAGA;EACE,UAAU;EACV,cAAc;;EAEd,UAAU;EACV,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,6BAAa;AACf;;;AAGA;EACE,eAAe;EACf,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".isolationContainer {\n  width: 100vw;\n  height: 100vh;\n}\n\n.QuestionPopup {\n  /* 70px 88px 42px 88px */\n  margin: 5.46875vw 6.875vw 3.28125vw 6.875vw;\n}\n\n\n.title {\n  /* 20px */\n  margin-bottom: 1.5625vw;\n}\n\n.question {\n  /* 30px */\n  margin-bottom: 2.34375vw;\n}\n\n\n.answerWrapper {\n  /* 480px */\n  height: 37.5vw;\n\n  /* 324px */\n  margin-right: 25.3125vw;\n  overflow: hidden;\n}\n\n.answer {\n  color: $gw_08;\n}\n\n\n.scrollbar {\n  position: fixed;\n  z-index: 2;\n  top: 3.90625vw;\n  bottom: 3.90625vw;\n  right: 2.03125vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var isolationContainer = "isolationContainer--g7O3n";
export var QuestionPopup = "QuestionPopup--E1dzJ";
export var title = "title--fqoud";
export var question = "question--MsDJa";
export var answerWrapper = "answerWrapper--DGYy6";
export var answer = "answer--mD8v6";
export var scrollbar = "scrollbar--Q9JFe";
export default ___CSS_LOADER_EXPORT___;
