// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.AccountTopLine--sdqBw {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n     -moz-box-pack: start;\n          justify-content: flex-start;\n}\n\n.iconOrImage--J8DWz {\n  /* 50px */\n  width: 3.90625vw;\n\n  /* 50px */\n  height: 3.90625vw;\n}\n\n.title--UmIQP {\n  /* 30px */\n  margin-left: 2.34375vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/AccountTopLine/AccountTopLine.module.css"],"names":[],"mappings":";AACA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,uBAA2B;EAA3B,mCAA2B;KAA3B,oBAA2B;UAA3B,2BAA2B;AAC7B;;AAEA;EACE,SAAS;EACT,gBAAgB;;EAEhB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,sBAAsB;AACxB","sourcesContent":["\n.AccountTopLine {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.iconOrImage {\n  /* 50px */\n  width: 3.90625vw;\n\n  /* 50px */\n  height: 3.90625vw;\n}\n\n.title {\n  /* 30px */\n  margin-left: 2.34375vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var AccountTopLine = "AccountTopLine--sdqBw";
export var iconOrImage = "iconOrImage--J8DWz";
export var title = "title--UmIQP";
export default ___CSS_LOADER_EXPORT___;
