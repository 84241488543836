/* eslint-disable max-len */
import * as React from 'react';

type Props = Readonly<{
  className?: string;
}>;

const RemoveIcon: React.FC<Props> = ({ className }: Props) => (
  <svg className={ className } x="0px" y="0px" width="18px" height="18px" viewBox="0 0 20 20" xmlSpace="preserve">
    <path
      d="M2.3 20c-.3 0-.5-.1-.7-.3L.3 18.4c-.4-.4-.4-1 0-1.4l7-7-7-7c-.4-.4-.4-1 0-1.4L1.6.3c.4-.4 1-.4 1.4 0l7 7 7-7c.4-.4 1-.4 1.4 0l1.3 1.3c.4.4.4 1 0 1.4l-7 7 7 7c.4.4.4 1 0 1.4l-1.3 1.3c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3l-7-7.1-7 7.1c-.2.2-.4.3-.7.3z"
      fill="currentColor"
    />
  </svg>
);

export default RemoveIcon;
