import moment from "moment";

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const MONTH = 30 * DAY;

/**
 * Возвращает время в формате '10:25'
 */
export const getLocalHHmm = (date: Date): string => {
  return moment(date).format('HH:mm');
};

/**
 * Возвращает true, если дата = сегодня
 */
export const isDateToday = (date: Date): boolean => {
  return moment(date).isSame(moment(), 'day');
}

/**
 * Возвращает true, если дата = завтра
 */
export const isDateTomorrow = (date: Date): boolean => {
  return moment(date).isSame(moment().add(1, 'day'), 'day');
}

/**
 * Возвращает true, если дата = вчера
 */
export const isDateYesterday = (date: Date): boolean => {
  return moment(date).isSame(moment().subtract(1, 'day'), 'day');
}
