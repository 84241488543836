import * as DOMPurify from 'dompurify';


const preparingText = (text: string): string => {
  return DOMPurify.sanitize(
    text,
    { FORBID_TAGS: ['a'] },
  );
};


export default preparingText;
