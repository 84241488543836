import { QueryKey,useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import ApiError from '~typings/ApiError';
import ItemObject from '~typings/ItemObject';
import { defaultExpand,ExpandedPlayerAccess } from '~typings/PlayerAccess';


const PREFIX_OF_A_COMPOSITE_KEY = 'stream_access';


type Errors = Readonly<{
  errors: ApiError[];
}>;

type Result = Readonly<{
  errors?: ApiError[];
  data?: ExpandedPlayerAccess[];
  meta: any;
}>;

type Props = Readonly<{
  itemID: string | null;
  itemObject: ItemObject | null;
}>;

type RequiredParametersForAccess = Readonly<{
  itemID: string | null;
  itemObject: ItemObject | null;
  accessToken: string | null;
}>;


const getStreamAccessKey = (props: RequiredParametersForAccess): QueryKey => [
  PREFIX_OF_A_COMPOSITE_KEY,
  props.itemObject,
  props.itemID,
  props.accessToken,
];

const fetch = async (
  client: ApiClient,
  props: RequiredParametersForAccess,
) => {


  const requestData = {
    'filter[resource_type_eq]': props.itemObject,
    'filter[resource_id_eq]': props.itemID,
    'expand[access]': defaultExpand
  };
  if (props.accessToken) {
    requestData['access_token'] = props.accessToken;
  }

  return await client.get(
    '/v4/accesses',
    requestData,
  );
};


const useStreamAccess = (params: Props) => {
  const { accessToken } = getAuthData();
  const requiredParameters: RequiredParametersForAccess = {
    accessToken,
    itemID: params.itemID,
    itemObject: params.itemObject,
  }
  const queryKey = getStreamAccessKey(requiredParameters);
  const client = useClient();

  return useQuery<Result, Errors>({
    queryKey,
    queryFn: () => fetch(client, requiredParameters),
    enabled: (params.itemID !== null && params.itemObject !== null),
  });
};


export const removeStreamAccessQueries = () => {
  queryClient.removeQueries({
    queryKey: [PREFIX_OF_A_COMPOSITE_KEY],
  });
};

export {
  getStreamAccessKey,
};

export default useStreamAccess;
