type Props = Readonly<{
  top?: number;
  left?: number;
}>;


const getMediaItemWrapperStyles = (props: Props): React.CSSProperties => {
  const result: React.CSSProperties= {};

  const translate = `translate3d(${
    props.left ? `${props.left}px` : 0
  }, ${
    props.top ? `${props.top}px` : 0
  }, 0)`;

  result.transform = translate;
  result.WebkitTransform = translate;

  return result;
};


export default getMediaItemWrapperStyles;
