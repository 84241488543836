import { find } from 'lodash';
import * as React from 'react';
import {InjectedIntl, injectIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

import * as blockCollectionStyles from '~components/BlockCollection/styles.module.css';
import { BlockTitle } from '~components/BlockTitle';
import Loader from '~components/LightLoader/Loader';
import { useMenu } from '~components/Provider/Menu';
import TiledView from '~components/TiledView/component';
import { PromoData } from '~hooks/fetch/usePromocode';
import useInfinityRentPlanCards from '~hooks/fetch/useRentPlans/useRentPlanCards';
import { usePaginationByItems } from '~hooks/usePagination';
import useSceneMediaItemPageInfo from '~hooks/useSceneMediaItemPage';
import {
  PROMO_VOD_DISCLAMER,
  PROMOCODE_ACTIVATION,
  SEARCH_NO_RESULTS,
} from '~localization';
import useNavigationOnLayer, { FocusPosition,NavigationLine } from '~stores/LayerNavigation';
import ImageType from '~typings/ImageType';
import RentPlan from '~typings/RentPlan';
import { PageNavigation } from '~ui/PageNavigation/PageNavigation';

import * as styles from '../styles.module.css';


type Props = Readonly<{
  promo?: PromoData;
  rentPlanId: string;
  intl: InjectedIntl;
}>;

const SLIDES_COUNT_PER_LINE = 6;
const MINIMAL_LINES_ON_SCREEN = 3;

const layerID = 'promo_rent_plan_page';

const PromocodeRentPlanPage: React.FC<Props> = (props: Props) => {
  const focusedIndex = useNavigationOnLayer(state => state.getFocusedIndexOnLayer(layerID + props.rentPlanId));
  const rentPlan = (find(props.promo?.rentPlans, { id: props.rentPlanId }) ?? {}) as RentPlan;
  const { path } = useRouteMatch();
  const rentPlanConfirmScreenUrl = `${path}/rent-plan-confirm/movie/:id`;

  const { query, parsedData } = useInfinityRentPlanCards(props.rentPlanId);
  const { isLoading } = query;
  const itemsWithDeeplink = React.useMemo(() => {
    if (!parsedData) { return; }

    return {
      data: parsedData.data.map((item) => ({
        ...item,
        deeplink: rentPlanConfirmScreenUrl,
      })),
      meta: parsedData.meta
    }
  }, [props.rentPlanId, parsedData]);

  const fetchedItemsWithDeeplink = (itemsWithDeeplink?.data.length || 0);

  usePaginationByItems({
    currentFetchedCount: itemsWithDeeplink?.data.length ?? 0,
    focusedLineIdx: Math.floor(focusedIndex / SLIDES_COUNT_PER_LINE),
    elementsPerLine: SLIDES_COUNT_PER_LINE,
    minimalLinesOnScreen: MINIMAL_LINES_ON_SCREEN,
    fetchMoreData: ()=> {
      if(query.hasNextPage && !query.isFetchingNextPage){
        query.fetchNextPage();
      }
    }


  });

  const { isSceneMediaItemPageMode } = useSceneMediaItemPageInfo();
  const menu = useMenu();
  const allowNavigation = !menu.isMenuOpened && !menu.isPopupOpened && !isSceneMediaItemPageMode;
  const navigationItems = React.useMemo(() => {
    const res: NavigationLine[] = [];
    res.push({ maxIndex: fetchedItemsWithDeeplink ?? 0, isTiled: true });

    return res;
  }, [fetchedItemsWithDeeplink]);

  if (!props.promo || !rentPlan) {
    return null;
  }
  
  const renderContent = (focusPosition: FocusPosition) => (
    <div>
      {
        (isLoading) ? (
          <div className={ blockCollectionStyles.loaderWrapper }>
            <Loader />
          </div>
        )
        : !isLoading && fetchedItemsWithDeeplink === 0 ? (
          <div className={ blockCollectionStyles.noResults }>
            <div>
              { props.intl.formatMessage({ id: SEARCH_NO_RESULTS }) }
            </div>
          </div>
        ) : itemsWithDeeplink?.data?.length !== 0 ? (
          <div> 
             <TiledView
               items={ itemsWithDeeplink }
               focusedItemIndex={ focusPosition.focusedIndex[0] }
               dimension={ ImageType.POSTER }
               allowNavigation={ allowNavigation && !!itemsWithDeeplink }
             />
          </div>
        ) : null
      }
    </div>
  );

  const hideDescription = focusedIndex >= 6;

  return (
    <>
    { !hideDescription
        ?
          <BlockTitle
            isFocusedBlock
            isVisible
            isFixed
            isFadeOut={ false }
          >
            { props.intl.formatMessage({ id: PROMOCODE_ACTIVATION }) }
          </BlockTitle>
        :
          null
    }
      <div className={ styles.rentPlanWrapper }>
      { !hideDescription
          ? <div className={ styles.rentPlanPageText }>
              { props.intl.formatMessage({ id: PROMO_VOD_DISCLAMER }) }
            </div>
          : null
        }
        <PageNavigation
          layerID={ layerID + props.rentPlanId }
          dimension={ ImageType.POSTER }
          navigationItems={ navigationItems }
          isAllowedNavigation={ allowNavigation && !!itemsWithDeeplink }
          isTiledView
        >
          { renderContent }
        </PageNavigation>
      </div>
    </>
  )
};


export default injectIntl(PromocodeRentPlanPage);
