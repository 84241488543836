import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  useContinueWatchingInfinity,
} from '~hooks/fetch/useContinueWatching/useContinueWatchingV3';
import { usePaginationByItems } from '~hooks/usePagination';
import { getField } from '~lib/URLQueryStringManager';
import { CONTINUE_WATCHING } from '~localization';
import useNavigationOnLayer from '~stores/LayerNavigation';
import ItemObject from '~typings/ItemObject';

import CollectionPageWrapper from './Wrapper';


const SLIDES_COUNT_PER_LINE = 4;
const MINIMAL_LINES_ON_SCREEN = 3;

const ContinueWatchingPage = () => {
  const layerID = 'collection_page-continue_watching';
  const focusedIndex = useNavigationOnLayer(state => state.getFocusedIndexOnLayer(layerID));

  const history = useHistory();
  const types = (getField(history.location.search, 'types') || '').split(',');

  const { query, parsedData: items} = useContinueWatchingInfinity({
    types
  });


  usePaginationByItems({
    currentFetchedCount: items?.data.length || 0,
    focusedLineIdx: Math.floor(focusedIndex / SLIDES_COUNT_PER_LINE),
    elementsPerLine: SLIDES_COUNT_PER_LINE,
    minimalLinesOnScreen: MINIMAL_LINES_ON_SCREEN,
    fetchMoreData: ()=> {
      if(query.hasNextPage && !query.isFetchingNextPage){
        query.fetchNextPage();
      }
    }
  });

  const handleMediaItemClicked = React.useCallback((item) => {
    if (item.object === ItemObject.Episode) {
      history.push(`/media-item/${ ItemObject.Episode }/${ item.id }`);
    }
  }, []);

  const title = getField(history.location.search, 'blockTitle');

  return (
    <CollectionPageWrapper
      layerID={ layerID }
      title={ title || <FormattedMessage id={ CONTINUE_WATCHING } /> }
      items={ items }
      onMediaItemClick={ handleMediaItemClicked }
      slidesCountPerLine={ SLIDES_COUNT_PER_LINE }
      showOnlyFetchedItems
      withProgress
      showAsSeries
    />
  );
};


export default ContinueWatchingPage;
