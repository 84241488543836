// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".currentProgramLineWrapper--hYaII {\n  position: relative;\n  top: 2vw;\n  width: 85.9375vw;\n  height: 7.8125vw;\n  color: rgba(202, 202, 208, 1);\n}\n\n.currentProgramLineWrapper--hYaII.focused--rw1ov {\n  height: 7.8125vw;\n}\n\n.watchButton--HT53W {\n  position: absolute;\n  bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/BlockCurrentProgramLine/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,gBAAgB;EAChB,gBAAgB;EAChB,6BAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX","sourcesContent":[".currentProgramLineWrapper {\n  position: relative;\n  top: 2vw;\n  width: 85.9375vw;\n  height: 7.8125vw;\n  color: $gw_07;\n}\n\n.currentProgramLineWrapper.focused {\n  height: 7.8125vw;\n}\n\n.watchButton {\n  position: absolute;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var currentProgramLineWrapper = "currentProgramLineWrapper--hYaII";
export var focused = "focused--rw1ov";
export var watchButton = "watchButton--HT53W";
export default ___CSS_LOADER_EXPORT___;
