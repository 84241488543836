import focuser, { FocuserKeyHandler, useFocuserExpHandler } from '@focuser';
import React, { useMemo } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { ChipsButtonFocuser } from '~app/ui-kit/ChipsButton';
import { useMaxMemoized } from '~hooks/useMaxMemoized';
import { SEASON_NUM } from '~lib/localizator/src/dictionaries';
import { SliderOffsetable } from '~ui/SliderOffsetable';

import * as styles from './SeasonsSlider.module.css';

export interface Props {
  seasonCount: number;
  activeSeasonIdx: number;
  onSeasonChange: (seasonIdx: number) => void;
}

const SeasonsSliderBase: React.FC<Props & InjectedIntlProps> = (props) => {
  const { seasonCount, activeSeasonIdx, intl } = props;

  const [focusedIdx, setFocusedIdx] = React.useState(0);

  const onLeft: FocuserKeyHandler = (e) => {
    if (focusedIdx > 0) {
      setFocusedIdx(focusedIdx - 1);
      e.stop();
    }
  };
  const onRight: FocuserKeyHandler = (e) => {
    if (focusedIdx < seasonCount - 1) {
      setFocusedIdx(focusedIdx + 1);
      e.stop();
    }
  };

  const onKeyLeftThrottled = useFocuserExpHandler(onLeft);
  const onKeyRightThrottled = useFocuserExpHandler(onRight);

  const seasonArrayToRender = useMemo(() => {
    return Array.from({ length: seasonCount }, (_, i) => i + 1);
  }, [seasonCount]);

  const {max: maximalIdx} = useMaxMemoized(focusedIdx + 10);

  return (
    <focuser.FocusableBlock
      isFocused
      onKeyLeft={ onKeyLeftThrottled }
      onKeyRight={ onKeyRightThrottled }
      isPointerLeftAvailable={ focusedIdx > 0 }
      isPointerRightAvailable={ focusedIdx < seasonCount - 1 }
      noNeedForceFocus
    >
      <SliderOffsetable focusedIndex={ focusedIdx }>
        {seasonArrayToRender.slice(0, Math.min(maximalIdx, seasonArrayToRender.length)).map((seasonNum, i) => (
          <ChipsButtonFocuser
            focuserClassName={ styles.sliderButton }
            isFocused={ i === focusedIdx }
            key={ seasonNum }
            isActive={ i === activeSeasonIdx }
            emitForceFocusOnHover
            onForceFocus={ () => setFocusedIdx(i) }
            onClick={ () => props.onSeasonChange(i) }
          >
            {intl.formatMessage({ id: SEASON_NUM }, { num: seasonNum })}
          </ChipsButtonFocuser>
        ))}
      </SliderOffsetable>
    </focuser.FocusableBlock>
  );
};

export const SeasonsSlider = React.memo(injectIntl<Props>(SeasonsSliderBase));

SeasonsSlider.displayName = 'SeasonsSlider';
