import focuser from '@focuser';
import * as React from 'react';

import { useApp } from '~components/Provider/App';

import { ProfileLine } from '../../ProfilesItem';
import { useProfileNavigation, useProfilesState } from './hooks';


type Props = Readonly<{
  onSelect: (profileId: string, immidiatly?: boolean, index?: number) => void;
  onEdit: (profileId: string, index: number) => void;
  initialIndex?: number;
}>;

export const ProfilesList: React.FC<Props> = ({
  onSelect,
  onEdit,
  initialIndex,
  }: Props) => {

  const { activeProfileId } = useApp();
  const { profilesItems, haveEditingRights } = useProfilesState(activeProfileId);


  const {
    block,
    line,
    setColumn,
    handleUp,
    handleDown,
    handleRight,
    handleLeft,
    setFocusOnProfileName,
    setFocusOnEditButton
  } = useProfileNavigation(profilesItems, initialIndex);


  return (
    <focuser.FocusableBlock
      isFocused
      onKeyDown={ handleDown }
      onKeyUp={ handleUp }
      onKeyRight={ handleRight }
      onKeyLeft={ handleLeft }
      noNeedForceFocus
    >
      { profilesItems.map((profile, index) => (
        <focuser.FocusableBlock
          onForceFocus={ () => setColumn(index)  }
          isFocused={ line === index }
          key={ index }
        >
          <ProfileLine
            profile={ profile }
            onClick={ onSelect }
            onEditBtnClick={ (profileId) => {
              onEdit(profileId, index);
            } }
            isSelected={ profile.id === activeProfileId }
            isEditable={ haveEditingRights }
            key={ `ProfileItem_${profile.id}` }
            focusedBlock={ block }
            setFocusOnProfileName={ setFocusOnProfileName }
            setFocusOnEditButton={ setFocusOnEditButton }
          />
        </focuser.FocusableBlock>
      ))}
    </focuser.FocusableBlock>
  )
}
