import * as React from 'react';

import * as styles from './styles.module.css';
import { formatTimeLeftForPopup } from './utils';


type Props = Readonly<{
  currentChapter: any;
  movingPopupStyle: string;
  currentTimeInSec: number;
  durationInSec: number;
  rewindTime: number;
  audioshowObject: boolean;
}>;


const ChapterPopup: React.FC<Props> = (props: Props) => {
  const timeLeftRefForPopup = React.useRef<HTMLDivElement | null>(null);
  const timeLeftForPopup = formatTimeLeftForPopup(props.rewindTime);

  React.useEffect(() => {
    if(timeLeftRefForPopup.current) {
      timeLeftRefForPopup.current.innerText = timeLeftForPopup;
    }
  }, [timeLeftForPopup, timeLeftRefForPopup.current]);


  return (
    // <div
    //   // style={ { left: `${(props.currentTimeInSec / props.durationInSec) * 50}%` } }
    // >
      <div
        className={ styles.chapterPopup }
        style={ {
          transform: props.movingPopupStyle,
          WebkitTransform: props.movingPopupStyle,
        } }
      >
        <div className={ styles.popupImg }/>
        <div className={ props.audioshowObject ? styles.popupInfoAudioshow : styles.popupInfo }>
          {props.currentChapter ? props.currentChapter.name : null}
          <div ref={ timeLeftRefForPopup } className={ styles.popupTime }/>
        </div>
      </div>
    // </div>
  )
}

export default ChapterPopup;
