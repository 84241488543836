import cn from "classnames";
import * as DOMPurify from 'dompurify';
import * as React from 'react';

import MenuIcon from '~typings/MenuIcon';

import { createMarkup } from '../../component.helpers';
import { IconUser } from '../../icons/IconUser';
import * as rootCSS from './styles.module.css'

type Props = Readonly<{
  image?: string;
  icon?: MenuIcon;
  isAuthorized: boolean | null;
  isFocused: boolean | undefined;
}>;

const NavMenuItemIcon = (props: Props) => {
  let Icon;
  let svgLink

  switch (props.icon) {
    case MenuIcon.Account: {
      Icon = IconUser;
      break;
    }
  }

  if (!props.image && props.icon !== MenuIcon.Account) {
    return null;
  }

  if (props.image && props.image.includes('svg')) {
    // с бека прилетает svg
    // нужно вырезать весь треш который контент менеджеры пихают в svg
    // так же вырезаем потенциально зашитый бекдор с помощью DOMPurify
    const sanitizedSvg: string = DOMPurify.sanitize(props.image)
    svgLink = sanitizedSvg
      .replace(/<defs(.*?)defs>/g, '')
      .replace(/<title(.*?)title>/g, '')
      .replace(/fill="(.*?)"/g, '')
      .replace(/style="(.*?)"/g, '')
      .replace(/width="(.*?)"/g, '')
      .replace(/height="(.*?)"/g, '')
      .replace(/id="(.*?)"/g, '')
      .replace(/data-(.*?)"(.*?)"/g, '')
  }
  else {
    svgLink = props.image
  }


  return (
    <div className={ cn(props.isFocused ? rootCSS.rootLineItemFocused : rootCSS.rootLineItemNotFocused) }>
      {
        props.icon === MenuIcon.Account
          ? <Icon className={ rootCSS.accountIcon } />
          : <div dangerouslySetInnerHTML={ createMarkup(svgLink) } />
      }
    </div>
  );
};


export default React.memo(NavMenuItemIcon);
