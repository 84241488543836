// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Settings--dpQM5 {\n  /* 10.5px */\n  padding-top: 0.8203125vw;\n}\n\n.langTitle--wRnlb {\n  /* 30px */\n  margin-bottom: 2.34375vw;\n}\n\n.checkboxLine--gZm4X {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n     -moz-box-pack: start;\n          justify-content: flex-start;\n\n  /* 20px */\n  margin-top: 1.5625vw;\n}\n\n.checkboxLine--gZm4X:last-child {\n  margin-bottom: 0;\n}\n\n.checkboxButton--uEadj {\n  /* 20px */\n  margin-right: 1.5625vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/Settings/Settings.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,wBAAwB;AAC1B;;AAEA;EACE,SAAS;EACT,wBAAwB;AAC1B;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,uBAA2B;EAA3B,mCAA2B;KAA3B,oBAA2B;UAA3B,2BAA2B;;EAE3B,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,sBAAsB;AACxB","sourcesContent":[".Settings {\n  /* 10.5px */\n  padding-top: 0.8203125vw;\n}\n\n.langTitle {\n  /* 30px */\n  margin-bottom: 2.34375vw;\n}\n\n.checkboxLine {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n\n  /* 20px */\n  margin-top: 1.5625vw;\n}\n\n.checkboxLine:last-child {\n  margin-bottom: 0;\n}\n\n.checkboxButton {\n  /* 20px */\n  margin-right: 1.5625vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var Settings = "Settings--dpQM5";
export var langTitle = "langTitle--wRnlb";
export var checkboxLine = "checkboxLine--gZm4X";
export var checkboxButton = "checkboxButton--uEadj";
export default ___CSS_LOADER_EXPORT___;
