import * as cn from 'classnames';
import * as React from 'react';

import { DataItem, MediaItemType } from '~typings/DataItem';
import ImageType from '~typings/ImageType';
import MediaItemDimension from '~typings/MediaItemDimension';
import { MediaItem } from '~ui/MediaItem';
import * as mediaItemStyles from '~ui/MediaItem/styles.module.css';


type Props = Readonly<{
  itemKey: string;
  top: number;
  left: number;
  dimension: MediaItemDimension;
  isFocused: boolean;
  item: MediaItemType | undefined;
  onClick?: (item: DataItem) => void;

  withCurrentEvent?: boolean;
  withProgress?: boolean;
  showAsSeries?: boolean;
  forceOnClick?: boolean;
  editMode?: boolean;
  isChecked?: boolean;
  disableText?: boolean | undefined;
}>;


const TiledItem: React.FC<Props> = (props: Props) => {
  const mediaItemType: ImageType.BANNER | ImageType.POSTER = (props.dimension === ImageType.POSTER) ?
    ImageType.POSTER
    :
    ImageType.BANNER;

  return (
    <MediaItem
      withName={ !props.disableText }
      withGenres={ !props.disableText }
      alwaysShowName
      withCurrentEvent={ props.withCurrentEvent }
      withProgress={ props.withProgress }
      showAsSeries={ props.showAsSeries }
      itemKey={ props.itemKey }
      top={ props.top }
      left={ props.left }
      className={
        cn(
          mediaItemStyles.mediaItem,
          mediaItemStyles[props?.item?.object || ''],
          mediaItemStyles[mediaItemType],
          {
            'focusedNavigationNode': props.isFocused,
            [mediaItemStyles.focused]: props.isFocused,
          }
        )
      }
      dimension={ props.dimension }
      isFocused={ props.isFocused }
      item={ props.item }
      onClick={ props.onClick }
      forceOnClick={ props.forceOnClick }
      editMode={ props.editMode }
      isChecked={ props.isChecked }
    />
  );
};


export default React.memo(TiledItem);
