import cn from "classnames";
import React from "react";

import { GetIcons } from "../icons";
import { KeyBoardKey, KeyBoardLayout, KeyBoardType,KeyIcon, KeyType } from "../keyboardLayouts/type0";
import * as css from './search2.module.css'


export interface Props {
  buttonIsFocused: boolean
  button: KeyBoardKey
  keyBoard: string
}


function Button({
                  buttonIsFocused,
                  button,
                  keyBoard,
                }: Props) {

  // все клавиши типа 'char' а так же пробел типа 'icon' в раскладке RU - квадратные
  const buttonType = button.type === KeyType.Char
    || (button.type === KeyType.Icon
      && button.id === KeyIcon.Space
      && keyBoard === (KeyBoardType.type0 + KeyBoardLayout.Ru))


      return <div className={ cn(css.type0Basic, buttonType ? css.button0 : css.button1,
        { [css.type0Active]: buttonIsFocused, }) }>
        {
          button.type === KeyType.Char
            ? button.id
            : <GetIcons id={ button.id } buttonIsFocused={ buttonIsFocused } keyBoard={ keyBoard }
                        switchTo={ button.switchTo } />
        }
      </div>

}

export const MemoizedButton = React.memo(Button)
