import * as React from 'react';

import Slider from '~components/Slider';
import { useFavoritesFirstPage } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { usePageByType } from '~hooks/fetch/usePages/usePages';
import { PersonalPageMenuID } from '~screens/PersonalScreen/routes.helpers';
import useNavigation, { pageWeComeFromTS } from "~stores/Navigation";
import { usePlayerStore } from "~stores/Player";
import { Block, BlockType } from '~typings/Block';
import { FavoriteType } from '~typings/FavoriteType';
import ItemObject from '~typings/ItemObject';
import MediaItemDimension from '~typings/MediaItemDimension';
import Page from '~typings/Page';
import PageObject from '~typings/PageObject';


type Props = Readonly<{
  page: Page;
  block: Block;
  types: FavoriteType[];
  dimension: MediaItemDimension;
  isFocusedBlock: boolean;
  isPopupOpened: boolean;
  isVisible: boolean;
  focusedItemIndex: number;
}>;


const BlockFavorites: React.FC<Props> = (props: Props) => {
  const setPageWeComeFrom = useNavigation(state => state.setPageWeComeFrom)
  const setIsLive = usePlayerStore(state => state.setIsLive)
  const personalPage = usePageByType(PageObject.PersonalPage);
  const tabs = personalPage?.tabs
    ?.filter(tab => tab.type === 'favorites')
    .map(tab => tab.resource_types?.join(','));
  const deeplink = tabs?.includes(props.types.join(','))
    ? `/page/${ personalPage?.id }/${ PersonalPageMenuID.favorites }/${ props.types.join(',') }`
    // eslint-disable-next-line max-len
    : `/${ ItemObject.Collections }/${ BlockType.FavoritesBlock }?blockTitle=${ props.block?.title }&types=${ props.types.join(',') }`;

  const { data: items} = useFavoritesFirstPage({
    types: props.types
  })

  if (items?.data.length == 0 || items?.meta.pagination.total === 0) {
    return null;
  }

  const handleClick = () => {
    setIsLive(true)
    setPageWeComeFrom(pageWeComeFromTS.sliderFavorites)
  };

  return (
    <Slider
      dimension={ props.dimension }
      items={ items }
      isFocusedBlock={ !props.isPopupOpened && props.isFocusedBlock }
      isVisible={ props.isVisible }
      focusedIndex={ props.focusedItemIndex }
      deeplink={ deeplink }
      onClick={ handleClick }
    />
  );
};


export default React.memo(BlockFavorites);
