import { useState } from 'react';
import React from 'react';

import { PopupIsolated } from '~ui/Popup';

import { PinCodeManager, ProcedureType } from './PinCodeManager';
import PinCodeQuestion from './PinCodeQuestion';

export interface Props {
  onBack: () => void;
  onDone: () => void;
}

export const PinCodeQuestionProcedure: React.FC<Props> = ({ onBack, onDone }) => {
  const [step, setStep] = useState<'asking' | 'creating'>('asking');

  const openPinCodeManager = () => {
    setStep('creating');
  };

  if (step === 'asking') {
    return (
      <PopupIsolated>
        <PinCodeQuestion
          onBack={ onBack }
          onSkip={ onDone }
          onConfirm={ openPinCodeManager }
        />
      </PopupIsolated>
    );
  }

  return (
    <PinCodeManager
      procedureType={ ProcedureType.Creation }
      onDone={ onDone }
    />
  );
};
