import { debounce } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import EPGPageEventDetail from '~components/EPG/EventDetail/component';
import ChannelsWithCurrentEvent from '~components/ScenePlayer/Player/controls/Popup/ChannelsList/Channels';
import {
  PREFIX_OF_A_COMPOSITE_KEY as currentEventKey,
} from '~hooks/fetch/useCurrentEvent/useCurrentEvent';
import deleteCacheByKeys from '~lib/ReactQuery/deleteCacheByKeys';
import URLVariableState from '~lib/URLVariableState';
import ProgramEvent from '~typings/Event';
import NavigationDirection from '~typings/NavigationDirection';

import * as styles from './styles.module.css';


type Props = Readonly<{
  channelID: string;
  onClose: () => void;
}>;

enum Focus {
  Channels = 'channels',
  EventDescription = 'EventDescription',
}


const ChannelsListPopup: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const channelID = props.channelID;
  const focusURL = React.useRef(URLVariableState<Focus | undefined>(history, 'focusOn', Focus.Channels)).current;
  const focusOn = focusURL.value();
  const isFocusOnChannels = React.useMemo(() => (focusOn === Focus.Channels), [focusOn]);
  const isFocusOnDetails = React.useMemo(() => (focusOn === Focus.EventDescription), [focusOn]);
  const [focusedEvent, setFocusedEvent] = React.useState<ProgramEvent | null>(null);
  const handleChangeFocusedEvent = (event: ProgramEvent | null) => {
    if (focusedEvent?.id !== event?.id) {
      setFocusedEvent(event);
    }
  };
  const debouncedHandleChangeFocusedEvent = React.useCallback(
    debounce(handleChangeFocusedEvent, 300),
    [focusedEvent?.id],
  )

  const handleLeave = React.useCallback((direction) => {
    const focusOn = focusURL.value();

    if (focusOn === Focus.Channels) {
      if (direction === NavigationDirection.Right) {
        focusURL.set(Focus.EventDescription);
      }
    } else if (focusOn === Focus.EventDescription) {
      if (direction === NavigationDirection.Left) {
        focusURL.set(Focus.Channels);
      }
    }
  }, []);

  React.useEffect(() => () => {
    focusURL.set(undefined);
    deleteCacheByKeys([[currentEventKey]]);
  }, []);

  const renderEventDetail = React.useMemo(() => (
    <EPGPageEventDetail
      isFocused={ isFocusOnDetails }
      className={ styles.EPGEventDetail }
      event={ focusedEvent }
      onLeave={ handleLeave }
    />
  ), [focusedEvent?.id, isFocusOnDetails]);

  return (
    <div
      className={ styles.wrapper }
    >
      <ChannelsWithCurrentEvent
        isFocused={ isFocusOnChannels }
        channelID={ channelID }
        onChangeCurrentEvent={ debouncedHandleChangeFocusedEvent }
        onLeave={ handleLeave }
        onClose={ props.onClose }
      />
      { renderEventDetail }
    </div>
  );
};


export default ChannelsListPopup;
