import React from 'react';
import { useHistory } from 'react-router-dom';

import useHotKey from '~hooks/useHotKey';
import { IPlayer } from '~lib/player/typings';
import HotKey from '~typings/HotKey';


type Props = Readonly<{
  player: IPlayer;
  onClickPlayPause: () => void;
}>;


const usePlayerHotKeysPausePlayStop: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  useHotKey({
    isMounted: true,
    keyName: HotKey.KEY_STOP,
    action: history.goBack,
  });
  useHotKey({
    isMounted: true,
    keyName: HotKey.KEY_PAUSE,
    action: props.player.pause,
  });
  useHotKey({
    isMounted: true,
    keyName: HotKey.KEY_PLAY,
    action: props.player.play,
  });
  useHotKey({
    isMounted: true,
    keyName: HotKey.KEY_PLAY_PAUSE,
    action: props.onClickPlayPause,
  }, [props.onClickPlayPause]);

  return null;
};


export default usePlayerHotKeysPausePlayStop;
