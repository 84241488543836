import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { isAllowedMenuItem } from '~components/NavMenu/component.helpers';
import { useScene } from '~components/Provider/Scene';
import Slider from '~components/Slider';
import { useConfig } from '~global';
import useBanners from '~hooks/fetch/useBanners/useBanners';
import { getPageByUID } from '~hooks/fetch/usePages/getPages';
import { usePageById } from '~hooks/fetch/usePages/usePages';
import {
  getAllowedLinks,
  getDeepLinkURL,
  getObjectAndID,
  isBannerSupported,
  isCollection,
  isMediaItem,
  isProduct,
} from '~lib/deepLinkResolver';
import { Block } from '~typings/Block';
import ImageType from '~typings/ImageType';
import MediaItemDimension from '~typings/MediaItemDimension';
import Page from '~typings/Page';

import * as styles from './styles.module.css';


type Props = Readonly<{
  page: Page;
  block: Block;
  dimension: MediaItemDimension;
  isFocusedBlock: boolean;
  isPopupOpened: boolean;
  isVisible?: boolean;
  focusedItemIndex: number;
}>;


const BlockBanners: React.FC<Props> = (props: Props) => {
  const scene = useScene();
  const history = useHistory();
  const { smartTV: { profileSection } } = useConfig();
  const matchPage = useRouteMatch('/:page/:id');
  const page = usePageById(props.page.id);
  const { data: items } = useBanners(props.page.id);
  const handleClearScene = () => {
    scene.changeSceneMediaItem(null, false, true);
  };

  if (!items || !page) { return null; }

  const allowedLinks = getAllowedLinks({ pages: [page], deepLinks: items.data.map((item) => item.deeplink) });

  const handleClick = (banner) => {
    if (isBannerSupported({ banner, allowedLinks })) {
      const url = getDeepLinkURL(banner.deeplink);

      if (url !== null) {
        const deeplinkOnPage = getPageByUID(url);

        if (deeplinkOnPage !== undefined && isAllowedMenuItem(deeplinkOnPage.object)) {
          handleClearScene();
          history.push(`/page/${deeplinkOnPage.id}`);
        } else if (isMediaItem(url)) {  // If Deep Link on MediaItem Page
          const item = getObjectAndID(url);

          if (matchPage?.url) {
            handleClearScene();
            history.push(`/media-item/${item.object}/${item.id}`);
          }
        } else if (isCollection(url) || (isProduct(url) && !!profileSection.subscriptions)) {
          handleClearScene();
          history.push(`/${url}`);
        }
      }
    }
  };
  const handleClickOnCurrentBanner = () => {
    const clickedBannerItem = items.data[props.focusedItemIndex] || undefined;

    if (clickedBannerItem) {
      handleClick(clickedBannerItem);
    }
  };

  return (
    <>
      {
        (!props.isPopupOpened && props.isFocusedBlock) && (
          <div
            className={ styles.clickableArea }
            onClick={ handleClickOnCurrentBanner }
          />
        )
      }
      <Slider
        dimension={ ImageType.BANNER }
        items={ items }
        isFocusedBlock={ !props.isPopupOpened && props.isFocusedBlock }
        isVisible={ props.isVisible }
        focusedIndex={ props.focusedItemIndex }
        onDotClick={ handleClick }
      />
    </>
  );
};


export default React.memo(BlockBanners);
