import * as React from 'react';

import { IconFavoriteBase } from './favoriteBase';

const IconFavorite: React.FC<{ isFocused: boolean;}> = ({
  isFocused,
}) => (
  <IconFavoriteBase
    isFocused={ isFocused }
    isPressed={ false }
  />
);


export default React.memo(IconFavorite);
