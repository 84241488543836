import {
  ACCOUNT_IS_BLOCKED,
	ACCOUNT_LABEL_EMAIL,
	INVALID_EMAIL_AND_PASSWORD,
	INVALID_FORMAT_PHONE_NUMBER_OR_EMAIL,
	NOT_CONFIRMED,
	PHONE_NUMBER,
	PHONE_NUMBER_OR_EMAIL,
	TOO_MANY_REQUESTS} from '~localization';
import { KeyBoardType } from '~typings/Keyboard';
import { LoginFieldType } from '~typings/StartupConfig';
import { UsernameAvailabilityType } from '~typings/UsernameAvailabilityType';

export enum AuthType {
	SignIn = 'singIn',
	SignUp = 'singUp',
	PasswordReset = 'passwd_reset'
}

export enum LayoutType {
	UsernameForm,
	PasswordForm,
	ConfirmCodeForm,
	PhoneCallConfirmBlock,
	ViewCredentials,
	CodeAuth,
	BigText,
}

export enum PageFocusOn {
	Form = 'form',
	Links = 'links',
  Footer = 'footer'
}

export const KeyboardTypes = {
  [LoginFieldType.Username]: KeyBoardType.EmailPhone,
  [LoginFieldType.Email]: KeyBoardType.EmailPhone,
  [LoginFieldType.Phone]: KeyBoardType.Number,
};

export const Placeholders = {
  [LoginFieldType.Username]: PHONE_NUMBER_OR_EMAIL,
  [LoginFieldType.Email]: ACCOUNT_LABEL_EMAIL,
  [LoginFieldType.Phone]: PHONE_NUMBER,
};

export const ErrorMessageIds = {
  invalid_credentials: INVALID_EMAIL_AND_PASSWORD,
	invalid_username: INVALID_FORMAT_PHONE_NUMBER_OR_EMAIL,
	invalid_confirmation_code: NOT_CONFIRMED,
	too_many_requests: TOO_MANY_REQUESTS,
  suspended: ACCOUNT_IS_BLOCKED,
  not_confirmed: NOT_CONFIRMED,
};

export type AuthState = Readonly<{
	username: string;
	password: string;
	confirmationType: UsernameAvailabilityType | null;
	isCredentialsSubmitted: boolean;
	code?: string;
}>;

export const initAuthState = {
	username: '',
	password: '',
	confirmationType: null,
	isCredentialsSubmitted: false,
	code: undefined,
};
