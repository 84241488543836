/* eslint-disable max-len */
import * as React from 'react';


const IconBackward: React.FC = () => (
  <svg
    className="playerIconBackward"
    viewBox="0 0 37.9 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.9.2L19.6 9.8c-.2.1-.4.3-.5.5-.4.6-.2 1.4.4 1.8h.1l16.3 9.6c.2.1.4.2.7.2.8 0 1.4-.6 1.4-1.4V1.4c0-.8-.6-1.4-1.4-1.4-.3 0-.5.1-.7.2zm-19 0L.6 9.8c-.2.1-.4.3-.5.5-.3.7-.1 1.4.5 1.9l16.3 9.6c.2.1.4.2.7.2.8 0 1.4-.6 1.4-1.4V1.4c0-.8-.6-1.4-1.4-1.4-.3 0-.5.1-.7.2z"
    />
  </svg>
);


export default IconBackward;
