import * as React from 'react';
import { useLocation } from 'react-router-dom';

import usePlayerStateActions from '~components/Provider/Player/actions';
import { useScene } from '~components/Provider/Scene';
import Channel from '~typings/Channel';


type Props = Readonly<{
  withFullInfo: boolean;
  item: Channel;
}>;


const ChannelPage: React.FC<Props> = (props: Props) => {
  const scene = useScene();
  const { setItem } = usePlayerStateActions();
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname.includes('products')) { return; }
    scene.changeSceneMediaItem(null, false, true);
  }, [pathname, props.item.id]);
  React.useEffect(() => {
    if (props.withFullInfo) {
      setItem(props.item);
    }
  }, [props.withFullInfo, props.item.id]);

  return null;
};


export default React.memo(ChannelPage);
