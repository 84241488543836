import * as React from 'react';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { getVars } from "~components/TiledView/component.helpers";
import TiledViewScroll from "~components/TiledView/component.scrollable";
import { TiledItems } from "~components/TiledView/items";
import useNavigationByKeys from "~hooks/useNavigation";
import { usePaginationByItems } from "~hooks/usePagination";
import { NewLayerNavigation } from "~stores/LayerNavigation/newLayerNavigation";
import { MediaItemType } from '~typings/DataItem';
import MediaItemDimension from '~typings/MediaItemDimension';
import NavigationDirection from "~typings/NavigationDirection";
import ResponseMany from '~typings/ResponseMany';


export type Props = Readonly<{
  items: ResponseMany<MediaItemType[]> | null | undefined;
  dimension: MediaItemDimension;
  allowNavigation: boolean;
  isFocused: boolean;
  itemsInLine: number;
  switchNavigationBlock: (arg1: any, arg2: any) => void
  setGridDirections: Dispatch<SetStateAction<[boolean, boolean, boolean, boolean]>>
  /**
   * Коллбек, который будет вызываться когда Grid понял что ему нужны еще данные (для предзагрузки)
   */
  onNeedMoreData: ()=> void;
  pageId: string
  gridId: string
}>;

const MINIMAL_LINES_ON_SCREEN = 3;


export function Grid(props: Props) {
  const { pageId, gridId } = props
  const focusHistory = NewLayerNavigation(state => state.focusHistory[pageId][gridId])
  const setFocusHistory = NewLayerNavigation(state => state.setFocusHistory)


  const { itemHeight } = React.useMemo(() => (
    getVars(props.dimension)
  ), [props.dimension]);

  const slidesCount = React.useMemo(
    () => [props.itemsInLine, props.itemsInLine] as [number, number],
    [props.itemsInLine],
  );

  const handleKeyNavigate = (direction): void => {
    if (!props.isFocused) {
      return
    }
    switch (direction) {
      case NavigationDirection.Up: {
        if (focusHistory.focusedElementIndex - props.itemsInLine >= 0) {
          const nextItem = focusHistory.focusedElementIndex - props.itemsInLine
          const leftEdge = (focusHistory.focusedLineIndex - 1) * props.itemsInLine
          const rightEdge = (leftEdge + props.itemsInLine - 1) > focusHistory.totalElements
            ? focusHistory.totalElements - 1
            : leftEdge + props.itemsInLine - 1

          const nextItemMoveUp = nextItem - props.itemsInLine >= 0
          const nextItemMoveRight = nextItem + 1 <= rightEdge
          const nextItemMoveDown = nextItem + props.itemsInLine <= focusHistory.totalElements - 1
          const nextItemMoveLeft = nextItem - 1 >= leftEdge

          setFocusHistory(pageId, gridId, {
            ...focusHistory,
            focusedElementIndex: nextItem,
            focusedLineIndex: focusHistory.focusedLineIndex - 1,
            focusedElementPossibleMove: [nextItemMoveUp, nextItemMoveRight, nextItemMoveDown, nextItemMoveLeft]

          })
          props.setGridDirections([nextItemMoveUp, nextItemMoveRight, nextItemMoveDown, nextItemMoveLeft])
        }
        else {
          props.switchNavigationBlock(direction, gridId)
        }
        break
      }
      case NavigationDirection.Down: {
        if (focusHistory.focusedElementIndex + props.itemsInLine <= focusHistory.totalElements - 1) {
          const nextItem = focusHistory.focusedElementIndex + props.itemsInLine
          const leftEdge = (focusHistory.focusedLineIndex + 1) * props.itemsInLine
          const rightEdge = (leftEdge + props.itemsInLine + 1) > focusHistory.totalElements
            ? focusHistory.totalElements - 1
            : leftEdge + props.itemsInLine - 1

          const nextItemMoveUp = nextItem - props.itemsInLine >= 0
          const nextItemMoveRight = nextItem + 1 <= rightEdge
          const nextItemMoveDown = nextItem + props.itemsInLine <= focusHistory.totalElements - 1
          const nextItemMoveLeft = nextItem - 1 >= leftEdge
          setFocusHistory(pageId, gridId, {
            ...focusHistory,
            focusedElementIndex: nextItem,
            focusedLineIndex: focusHistory.focusedLineIndex + 1,
            focusedElementPossibleMove: [nextItemMoveUp, nextItemMoveRight, nextItemMoveDown, nextItemMoveLeft]
          })
          props.setGridDirections([nextItemMoveUp, nextItemMoveRight, nextItemMoveDown, nextItemMoveLeft])
        }
        break
      }
      case NavigationDirection.Right: {
        const nextItem = focusHistory.focusedElementIndex + 1
        const leftEdge = focusHistory.focusedLineIndex * props.itemsInLine
        const rightEdge = (leftEdge + props.itemsInLine - 1) <= focusHistory.totalElements - 1
          ? leftEdge + props.itemsInLine - 1
          : focusHistory.totalElements - 1


        if (nextItem <= rightEdge) {
          const nextItemMoveUp = nextItem - props.itemsInLine >= 0
          const nextItemMoveRight = nextItem < rightEdge
          const nextItemMoveDown = nextItem + props.itemsInLine <= focusHistory.totalElements - 1
          const nextItemMoveLeft = nextItem > leftEdge

          setFocusHistory(pageId, gridId, {
            ...focusHistory,
            focusedElementIndex: nextItem,
            focusedElementPossibleMove: [nextItemMoveUp, nextItemMoveRight, nextItemMoveDown, nextItemMoveLeft]
          })
          props.setGridDirections([nextItemMoveUp, nextItemMoveRight, nextItemMoveDown, nextItemMoveLeft])

        }
        break
      }
      case NavigationDirection.Left: {
        const nextItem = focusHistory.focusedElementIndex - 1
        const leftEdge = focusHistory.focusedLineIndex * props.itemsInLine
        const rightEdge = (leftEdge + props.itemsInLine - 1) > focusHistory.totalElements
          ? focusHistory.totalElements - 1
          : leftEdge + props.itemsInLine - 1

        if (focusHistory.focusedElementIndex - 1 < leftEdge) {
          props.switchNavigationBlock(direction, gridId)
        }
        else {
          const nextItemMoveUp = (nextItem - props.itemsInLine) >= 0
          const nextItemMoveRight = nextItem < rightEdge
          const nextItemMoveDown = (nextItem + props.itemsInLine) <= focusHistory.totalElements - 1
          const nextItemMoveLeft = nextItem > leftEdge

          setFocusHistory(pageId, gridId, {
            ...focusHistory,
            focusedElementIndex: nextItem,
            focusedElementPossibleMove: [nextItemMoveUp, nextItemMoveRight, nextItemMoveDown, nextItemMoveLeft]
          })
          props.setGridDirections([nextItemMoveUp, nextItemMoveRight, nextItemMoveDown, nextItemMoveLeft])

        }
        break
      }
      default:
        break;
    }
  };

  usePaginationByItems({
    currentFetchedCount: props.items?.data.length || 0,
    focusedLineIdx: focusHistory.focusedLineIndex,
    elementsPerLine: props.itemsInLine,
    fetchMoreData: props.onNeedMoreData,
    minimalLinesOnScreen: MINIMAL_LINES_ON_SCREEN
  })

  useNavigationByKeys({
    isMounted: props.isFocused,
    onNavigation: handleKeyNavigate,
  }, [props.isFocused]);

  useEffect(() => {
    if (props.items && props.items.data.length === 1) {
      props.setGridDirections([false, false, false, false])
    }
    else if (props.items && props.items.data.length > 1 && props.items.data.length <= props.itemsInLine) {
      props.setGridDirections([false, true, false, false])
    }
    else if (props.items && props.items.data.length > props.itemsInLine) {
      props.setGridDirections([false, true, true, false])
    }
  }, []);

  useEffect(()=>{
    if (props.items && props.items.data) {
      setFocusHistory(pageId,gridId, {
        ...focusHistory,
        totalElements: props.items.data.length
      })
    }

  }, [props.items?.data.length]);

  return (
    <TiledViewScroll
      focusedLine={ focusHistory.focusedLineIndex }
      step={ itemHeight }
    >
      <TiledItems
        { ...props }
        showOnlyFetchedItems={ true }
        focusedItemIndex={ focusHistory.focusedElementIndex }
        slidesCount={ slidesCount }
      />
    </TiledViewScroll>
  );
}
