import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import Loader from '~components/LightLoader/Loader';
import CatchBack from '~components/PageManager/CatchBack';
import { useDeleteDevice } from '~hooks/fetch/useEditDevice';
import {
  CANCEL,
  DELETE,
  DO_YOU_REALLY_WANT_TO_DELETE,
} from '~lib/localizator/src/dictionaries';
import { UserDevice } from '~typings/Devices';
import NavigationDirection from '~typings/NavigationDirection';
import Button from '~ui/button';
import { Popup } from '~ui/Popup';

import * as styles from './DeviceDeleteConfirmPopup.module.css';

enum FocusOn {
  DeleteButton = 'delete-button',
  CancelButon = 'cancel-button',
}

type Props = Readonly<{
  onDeleteSuccess: () => void;
  onClose: () => void;
  deviceToDelete: UserDevice;
  intl: InjectedIntl;
}>;

export const DeviceDeleteConfirmPopup = injectIntl<Props>(
  ({ onDeleteSuccess, onClose, deviceToDelete, intl }) => {
    const { mutate: deleteDevice, isLoading } = useDeleteDevice();
    const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.CancelButon);

    const navigationHandler = (nav) => {
      if (nav === NavigationDirection.Right) {
        setFocusOn(FocusOn.DeleteButton);
      } else if (nav === NavigationDirection.Left) {
        setFocusOn(FocusOn.CancelButon);
      }
    };

    const handleDeleteDevice = () => {
      const deviceId = deviceToDelete.id;

      deleteDevice(
        { deviceId },
        {
          onSuccess: () => {
            onDeleteSuccess();
          },
        },
      );
    };

    return (
      <>
        <CatchBack
          action={ onClose }
          triggers={ [onClose] }
        />
        <Popup>
          {isLoading && <Loader />}
          <div className={ styles.popupContent }>
            <div className={ styles.popupText }>
              {intl.formatMessage({ id: DO_YOU_REALLY_WANT_TO_DELETE })}
            </div>
            <div className={ styles.buttonsWrap }>
              <Button
                isFocused={ focusOn === FocusOn.CancelButon }
                onLeave={ navigationHandler }
                className={ styles.button }
                onClick={ onClose }
              >
                {intl.formatMessage({ id: CANCEL })}
              </Button>
              <Button
                isFocused={ focusOn === FocusOn.DeleteButton }
                onLeave={ navigationHandler }
                className={ styles.button }
                onClick={ handleDeleteDevice }
              >
                {intl.formatMessage({ id: DELETE })}
              </Button>
            </div>
          </div>
        </Popup>
      </>
    );
  },
);
