import * as React from 'react';

type Props = {
  className?: string;
};

/* eslint-disable max-len */
const EditSmall: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" width="20px" height="20px" className={ className } >
      <path
        d="M18.7 3.9l-3-3c-.6-.6-1.4-.9-2.2-.9-.8 0-1.6.3-2.2.9L.3 11.8l-.3.4v7.4h7.4l.3-.3 10.9-11c.6-.6.9-1.3.9-2.2.1-.8-.3-1.6-.8-2.2zM7 15.3l-2.8-2.7 6.9-6.9 2.8 2.7L7 15.3z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditSmall;
