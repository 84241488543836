import languages from 'customization-project-name/languages.json';
import * as React from 'react';

import { useConfig } from '~global';
import { getAuthData, hydrateAuthData } from '~hooks/fetch/useAccount';
import { persistContext, reHydrate, Storage } from '~lib/reHydrate';
import { Enviroment } from '~typings/Enviroment';
import Language from '~typings/Language';

import {
  ActionType,
  AppContext,
  AppDispatchContext,
  reducer,
  State,
} from './component.helpers';
import {
  ACTIVE_PROFILE_ID,
  APP_STORAGE_KEY,
  ENABLE_AUTOMATIC_LOG_IN,
  IS_AGREEMENT_CONFIRMED,
  IS_IPTV_ON,
  LANGUAGE,
  USER_STORAGE_KEY,
} from './constants';


// const PERSISTENT_LS_PROPS = [IS_IPTV_ON];
const PERSISTENT_SS_PROPS = [ACTIVE_PROFILE_ID];
const getLanguage = (): Language => {
  const defaultLanguage = languages[0] as Language;

  try {
    const language = reHydrate(Storage.LocalStorage, APP_STORAGE_KEY, [LANGUAGE])[LANGUAGE] as Language | undefined;

    if (language && languages.includes(language)) {
      return language;
    }
    return defaultLanguage;
  } catch (ignore) {
    return defaultLanguage;
  }
}

const AppProvider: React.FC = ({ children }) => {
  const { smartTV: { isNeedPolicyConfirmation } } = useConfig();
  hydrateAuthData();

  const { accessToken, refreshToken } = getAuthData();
  const reHydratedProps = {
    // ...reHydrate(Storage.LocalStorage, USER_STORAGE_KEY, PERSISTENT_LS_PROPS),
    ...reHydrate(Storage.LocalStorage, APP_STORAGE_KEY, [
      LANGUAGE,
      ENABLE_AUTOMATIC_LOG_IN,
      IS_AGREEMENT_CONFIRMED
    ]),
    ...reHydrate(Storage.SessionStorage, USER_STORAGE_KEY, PERSISTENT_SS_PROPS)
  };

  if (__BUILD_ENV__ === Enviroment.Production) {
    reHydratedProps[ACTIVE_PROFILE_ID] = null;
  }

  const language = getLanguage();
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      [IS_IPTV_ON]: null,
      [ENABLE_AUTOMATIC_LOG_IN]: true,  // IPTV AutoLogIn
      [ACTIVE_PROFILE_ID]: null,
      [IS_AGREEMENT_CONFIRMED]: null,
      isLaunchingDone: null,
      isPagesFetched: false,
      isOnLine: true,
      isAuthorized: null,
      regionUID: null,
      ...reHydratedProps,
      language,
      timeout: false
    },
  );


  React.useEffect(() => {
    if (!accessToken && !refreshToken) {
      dispatch({
        type: ActionType.SetActiveProfileId,
        payload: { activeProfileId: null },
      });
    }
  }, [accessToken, refreshToken]);


  React.useEffect(() => {
    const isAgreementConfirmed = state[IS_AGREEMENT_CONFIRMED];

    if (isAgreementConfirmed !== null || !isNeedPolicyConfirmation) return;

    dispatch({
      type: ActionType.SetAgreementStatus,
      payload: { isAgreementConfirmed: false },
    });
  }, [isNeedPolicyConfirmation, state[IS_AGREEMENT_CONFIRMED]])

  React.useEffect(() => {
    // persistContext<State>(Storage.LocalStorage, USER_STORAGE_KEY, PERSISTENT_LS_PROPS, state);
    persistContext<State>(Storage.LocalStorage, APP_STORAGE_KEY, [
      LANGUAGE,
      ENABLE_AUTOMATIC_LOG_IN,
      IS_AGREEMENT_CONFIRMED
    ], state);
    persistContext<State>(Storage.SessionStorage, USER_STORAGE_KEY, PERSISTENT_SS_PROPS, state);
  }, [state]);

  return (
    <AppContext.Provider
      value={ state }
    >
      <AppDispatchContext.Provider
        value={ dispatch }
      >
        { children }
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};


AppProvider.displayName = 'AppProvider';


export {
  getLanguage,
};

export default AppProvider;
