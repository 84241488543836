import { FilterResetIcon } from '~newapp/ui-kit/Icons/For_buttons/filtetReset';

import { IconBtnHOC, IconBtnProps } from '../../IconBtnHOC/IconBtnHOC';
import { IconBtnFocuserHOC, IconBtnFocuserProps } from '../../IconBtnHOC/IconBtnHOCFocuser';

export const ResetFiltersButton = IconBtnHOC(FilterResetIcon, 'small');
export const ResetFiltersButtonFocuser = IconBtnFocuserHOC(FilterResetIcon, 'small');

export type ResetFiltersButtonProps = IconBtnProps;
export type ResetFiltersButtonFocuserProps = IconBtnFocuserProps;
