import { UseQueryResult } from "react-query";
import create from "zustand";

import Page from "~typings/Page";


export interface PageStoreState {
  pages: UseQueryResult<Page[]> | null;
  setPages: (pages: PageStoreState['pages']) => void;
}

/**
 * Эта стора - на самом деле костыль.
 * Она помогает в одном компоненте использовать хук useQuery для pages,
 * а в остальных компонентах, там где нужны pages-ы, использовать usePagesStore.
 *
 * Вопрос - а почему в остальных компонентах не использовать тоже useQuery для pages?
 * Потому что это приведет к неизвестному багу внутри react-query, и тот компонент,
 * который первый использует useQuery для pages,
 * в нем вернется `status` => `success`, и `pages`-ы будут загружены,
 * а остальные будут в статусе `loading`, хотя ничем не отличаются хуки, и у всех одинаковый queryKey.
 *
 */
export const usePagesStore = create<PageStoreState>((set) => ({
  pages: null,
  setPages: (pages) => set({ pages }),
}));
