import * as React from 'react';

import { BlockTitle } from '~components/BlockTitle';
import { ActionType, useChannelsAction } from "~components/Provider/Channels";
import Slider from '~components/Slider/component';
import { useCardColItemsFirstPage } from '~hooks/fetch/useCardCollectionsItems';
import useNavigation, { pageWeComeFromTS } from "~stores/Navigation";
import { usePlayerStore } from "~stores/Player";
import { Block } from '~typings/Block';
import ImageType from '~typings/ImageType';
import ItemObject from "~typings/ItemObject";
import MediaItemDimension from '~typings/MediaItemDimension';

type Props = Readonly<{
  block: Block;
  dimension: MediaItemDimension;
  focusedItemIndex: number;
  isFocusedBlock: boolean;
  isPopupOpened: boolean;
  isFadeOut: boolean;
  isVisible: boolean;
}>;


const BlockCardCollection: React.FC<Props> = (props: Props) => {
  const channelsActions = useChannelsAction();

  const setPageWeComeFrom = useNavigation(state => state.setPageWeComeFrom)

  const cardCollectionID = props.block.card_collection?.id;

  const setIsLive = usePlayerStore(state => state.setIsLive)

  const { data: items } = useCardColItemsFirstPage({
    cardCollectionID
  });

  const handleClick = () => {
    channelsActions({
      type: ActionType.ChangeCollectionID,
      collectionID: cardCollectionID,
    })
    setIsLive(true)
    setPageWeComeFrom(pageWeComeFromTS.sliderCardCollection)
  }

  return (
    <>
      <BlockTitle
        isFocusedBlock={ props.isFocusedBlock }
        isVisible={ props.isVisible }
        isFadeOut={ props.isFadeOut }
      >
        {props.block.card_collection?.name || props.block?.title || ''}
      </BlockTitle>
      <Slider
        cardConfigID={ props.block.card_collection?.card_config.id }
        dimension={ props.dimension === ImageType.Card1x1 ? ImageType.BANNER : props.dimension }
        items={ items }
        isFocusedBlock={ !props.isPopupOpened && props.isFocusedBlock }
        focusedIndex={ props.focusedItemIndex }
        isVisible={ props.isVisible }
        onClick={ handleClick }
        deeplink={ `/${ItemObject.CardCollection}/${props.block.card_collection?.id}` }
      />
    </>
  );
};


export default BlockCardCollection;
