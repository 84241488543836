import {
  ActionType,
  ConfirmationStatus,
  PlayerAccessReason,
  usePlayerAction,
  // usePlayerState,
} from '~components/Provider/Player/component.helpers';
import { ResParsedManifest } from '~hooks/fetch/useTrackList';
import findMediaItem from '~lib/findMediaItem';
import AudioTrack from "~typings/AudioTrack";
import ProgramEvent from '~typings/Event';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import PlayerAccessError from '~typings/PlayerStatus';
import Stream from '~typings/Stream';
import VideoTrack from "~typings/VideoTrack";


// const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms));


const usePlayerStateActions = () => {
  const playerAction = usePlayerAction();
  // const playerState = usePlayerState();

  const setItem = async (item: PlayerMediaItem) => {
    // if (playerState.item !== null) {
    //   flush();
    //
    //   await timeout(100);
    // }

    if (item.object === ItemObject.ProgramEvent) {
      const channel = findMediaItem(item.channel_id, ItemObject.Channel);

      playerAction({
        item: {
          ...item,
          channel,
        } as ProgramEvent,
        type: ActionType.SetItem,
      });
    } else {
      playerAction({
        item,
        type: ActionType.SetItem,
      });
    }
  };
  const setAudioTracks = (audioTracks: AudioTrack[]) => {
    playerAction({
      audioTracks,
      type: ActionType.SetAudioTracks,
    });
  };
  const setAudioLanguage = (audioLanguage) => {
    playerAction({
      audioLanguage,
      type: ActionType.SetAudioLanguage,
    });
  };
  const setParsedManifest = (parsed: ResParsedManifest) => {
    playerAction({
      parsed,
      type: ActionType.SetParsedManifest,
    });
  };
  const setSubTitle = (subTitleTrack?: number) => {
    playerAction({
      subTitleTrack,
      type: ActionType.SetSubTitle,
    });
  };
  const setVideoTracks = (videoTracks: VideoTrack[]) => {
    playerAction({
      videoTracks,
      type: ActionType.SetVideoTracks,
    });
  };
  const flush = (): void => {
    playerAction({
      type: ActionType.Flush,
    });
  };
  const setAccess = (access: PlayerAccessError | PlayerAccessReason | null): void => {
    playerAction({
      access,
      type: ActionType.SetAccess,
    });
  };
  const setError = (error): void => {
    playerAction({
      error,
      type: ActionType.SetError,
    });
  };
  const setStream = (stream: Stream | null): void => {
    playerAction({
      stream,
      type: ActionType.SetStream,
    });
  };
  const setConfirmationRequired = (confirmationRequired: ConfirmationStatus | null): void => {
    playerAction({
      confirmationRequired,
      type: ActionType.SetConfrimationRequired,
    });
  };
  const setPlayList = (playList?: string[] | []) => {
    playerAction({
      playList,
      type: ActionType.SetPlayList,
    });
  };

  return ({
    flush,
    setItem,
    setAudioTracks,
    setAudioLanguage,
    setParsedManifest,
    setSubTitle,
    setVideoTracks,
    setAccess,
    setError,
    setStream,
    setConfirmationRequired,
    setPlayList
  });
};


export default usePlayerStateActions;
