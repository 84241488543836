import React from 'react';

export const useToggle = (initialValue = false) => {
  const [value, setValue] = React.useState(initialValue);
  const togglers = React.useMemo(
    () => ({
      toggle: () => setValue((v) => !v),
      turnOn: () => setValue(true),
      turnOff: () => setValue(false),
    }),
    [],
  );
  return [value, togglers] as const;
};
