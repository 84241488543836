import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import { BUY_ACTION, ENABLE,PAY_NOW } from '~localization';
import Offer from '~typings/Offer';
import Button from '~ui/button';

import * as styles from './styles.module.css';


type Props = {
  isFocused: boolean;
  isTrial: boolean;
  offer: Offer | undefined;
  intl: InjectedIntl;
  handlePurchase: () => void;
}

const OfferedPriceButton: React.FC<Props> = (props) => {
  const price: string | undefined = props.offer?.following_phases?.[0].price.formatted;

  return (
    <Button
      isFocused={ props.isFocused }
      className={ cn(styles.button, {
        [styles.isFocused]: props.isFocused,
      }) }
      onClick={ props.handlePurchase }
    >
      {
        props.isTrial ? (
          <>
            { props.intl.formatMessage({ id: ENABLE }) }
          </>
        ) : price ? (
          <>
            { props.intl.formatMessage({ id: PAY_NOW }) }
            { ' ' }
            { price }
          </>
        ) : (
          <>
            { props.intl.formatMessage({ id: BUY_ACTION }) }
          </>
        )
      }
    </Button>
  );
};


export default OfferedPriceButton;
