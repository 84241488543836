import React from 'react';

import { horizontalImageHeightVh, horizontalImageWidthVw } from '~app/variables';
import { useAnimationContext } from '~components/Provider/Animation';
import Card from '~typings/Card';
import { CardImageType } from '~typings/Image';
import { CardConfig } from '~typings/ScreenCardConfigs';
import { NewsCard } from '~ui/CardV2/News/NewsCard';

import { useCardImage, useGetFieldsFromCard } from '../hooks';

interface PropsTS {
  isFocused: boolean;
  card: Card;
  cardConfig: CardConfig;
}

export function NewsCardWrapper(props: PropsTS) {
  const { isFocused, cardConfig, card } = props;

  const {
    showBottomTextBlock,
  } = useGetFieldsFromCard(card, cardConfig);

  const imageSource = useCardImage({
    cardImages: card.images,
    isCardInappropriate: card.inappropriate,
    cardImageType:  CardImageType.Card16x9,
    heightVH: horizontalImageHeightVh,
    widthVW: horizontalImageWidthVw,
  });

  const { isAnimationEnabled } = useAnimationContext();

  return (
    <NewsCard
      isFocused={ isFocused }
      showBottomTextBlock={ showBottomTextBlock }
      additionalText={ card.additional_name }
      imageUrl={ imageSource }
      mainText={ card.description }
      isAnimationEnabled={ isAnimationEnabled }
    />
  );
}
