import { useMutation } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import { removeStreamAccessQueries } from '~hooks/fetch/useStreamAccess';
import Operator from '~typings/Operator';
import ResponseSingle from '~typings/ResponseSingle';

const fetch = async (client, accessToken, invoiceID) => {
  if (!accessToken || !invoiceID) { return undefined; }

  return await client.post(
    '/v1/payments/operator',
    {
      access_token: accessToken,
      'invoice_id': invoiceID,
    }
  );
};


const useOperatorPayment = () => {
  const { accessToken } = getAuthData();

  const client = useClient();

  return useMutation<ResponseSingle<Operator>, void, string, any>(
    (invoiceID: string) => fetch(client, accessToken, invoiceID),
    {
      mutationKey: 'payments_operator',
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['subscriptions']);
        removeStreamAccessQueries()
      },
    },
  );
};


export default useOperatorPayment;
