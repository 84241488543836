import { isMovie, isSeries } from '~lib/objectType';
import CastMember from '~typings/CastMember';
import Movie from '~typings/Movie';
import Series from '~typings/Series';

import { groupCastMembersByRole } from './helpers';
import { DetailItem } from './types';



const isMovieOrSeries = (item: DetailItem): item is Movie | Series => isMovie(item) || isSeries(item)



const fields = (item: DetailItem, castMembers : CastMember[]) => {
  const { name, description, genres: genresArray = [] } = item;

  const originalName = isMovieOrSeries(item) ? item.original_name : null;

  const productionYear = isMovieOrSeries(item) ? item.production_year : null;

  const durationSeconds = isMovie(item) ? item?.duration || null : null;

  const studiosArray = isMovieOrSeries(item) ? item?.studios || [] : [];

  const countriesArray = isMovieOrSeries(item) ? item?.countries || [] : [];

  const languagesArray = isMovie(item) ? item.languages || [] : [];

  const rightHolderName = isMovieOrSeries(item) ? item?.right_holder?.name : null;

  const externalCatalog = isMovieOrSeries(item) ? item.external_catalog?.name : null;

  const membersByRole = groupCastMembersByRole(castMembers);

  const genres = genresArray.map(({ name }) => name);

  const studios = studiosArray.map(({ name }) => name);

  const countries = countriesArray.map(({ name }) => name);

  const languages = languagesArray.map(({ name }) => name);

  const durationMin = durationSeconds && `${Math.round(durationSeconds / 60)} мин.`;


  return {
    name,
    originalName,
    description,
    membersByRole,
    genres,
    countries,
    productionYear,
    languages,
    studios,
    durationMin,
    rightHolderName,
    externalCatalog,
  };
};

export {
  fields,
};
