import { keyCodes } from 'platform-name/index';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import DebugPanel from '~lib/console/debug-panel';
import { COMPONENT_TESTING_PAGE_PATH } from '~newapp/screens/ComponentTestingPage';

const CODE_EXACT_LENGTH = 6;

export const useDevUtilsOpener = () => {
  const history = useHistory();

  const debugPanelRef = useRef<DebugPanel>();

  useEffect(() => {
    return () => {
      if (debugPanelRef.current) {
        debugPanelRef.current.destroy();
      }
    };
  }, []);

  const listenCodesMap: ListenCodesMap = useMemo(() => {
    const map: ListenCodesMap = {};

    map[CODE_100500] = () => {
      if (debugPanelRef.current) {
        debugPanelRef.current.destroy();
        return;
      }

      debugPanelRef.current = new DebugPanel(getDebugPanelKeyCodes());
    };

    map[CODE_100501] = () => {
      history.push('/dev');
    };

    if(__BUILD_ENV__ !== 'production') {
      map[CODE_100502] = () => {
        history.push(COMPONENT_TESTING_PAGE_PATH);
      };
    }

    return map;
  }, []);

  useDevCodeListener(listenCodesMap);
};

export const useDevCodeListener = (codesMap: ListenCodesMap) => {
  const codePool = useRef<string>('');

  const codesMapRef = useRef(codesMap);
  codesMapRef.current = codesMap;

  useEffect(() => {
    const handler = ({ charCode }: KeyboardEvent) => {
      codePool.current = (codePool.current + String.fromCharCode(charCode)).slice(
        -CODE_EXACT_LENGTH,
      );

      const callback = codesMapRef.current[codePool.current];

      if (!callback) {
        return;
      }

      codePool.current = '';
      callback();
    };

    window.addEventListener('keypress', handler);

    return () => {
      window.removeEventListener('keypress', handler);
    };
  }, []);
};

const getDebugPanelKeyCodes = () => {
  return {
    up: keyCodes.KEY_RED,
    down: keyCodes.KEY_YELLOW,
    minimize: keyCodes.KEY_GREEN,
    clear: keyCodes.KEY_BLUE,
  };
};

/**
 * Код для открытия дебаг панели
 */
export const CODE_100500 = '100500';
/**
 * Код для открытия выбора апишки
 */
export const CODE_100501 = '100501';
/**
 * Код для открытия страницы тестирования
 */
export const CODE_100502 = '100502';

/**
 * Карта, в которой ключи - коды, а значения - функции, которые должны быть выполнены при нажатии на этот код.
 *
 * Размер кода должен быть длинной в `CODE_EXACT_LENGTH` символов
 *
 * Например:
 *
 * ```tsx
 * const listenCodesMap: ListenCodesMap = {
    '100501': () => {
      console.log('100501');
    }
 }
    ```
 */
type ListenCodesMap = { [listenCode: string]: () => void };
