// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--CccDX {\n  width: 100vw;\n  margin-top: 45.83333333333333vh;\n}\n\n.alert--IgDLi {\n  width: 100vw;\n}\n\n.recs--y1gaM {\n  margin-left: 6.875vw;\n}\n\n.newCardRecs--WeTok {\n  padding: 0 5.9375vw;\n  width: 100vw;\n}\n\n.verticalSlider--oP5BL {\n  -webkit-box-align: start;\n  -webkit-align-items: flex-start;\n     -moz-box-align: start;\n          align-items: flex-start;\n}\n\n.mediaItemBar--U_LvX {\n  margin-left: 6.875vw;\n}\n\n.description--vxHtZ {\n  margin-left: 5.3125vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemPage/Series/components/SeriesFrontContent/SeriesFrontContent.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".container {\n  width: 100vw;\n  margin-top: 45.83333333333333vh;\n}\n\n.alert {\n  width: 100vw;\n}\n\n.recs {\n  margin-left: 6.875vw;\n}\n\n.newCardRecs {\n  padding: 0 5.9375vw;\n  width: 100vw;\n}\n\n.verticalSlider {\n  align-items: flex-start;\n}\n\n.mediaItemBar {\n  margin-left: 6.875vw;\n}\n\n.description {\n  margin-left: 5.3125vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "container--CccDX";
export var alert = "alert--IgDLi";
export var recs = "recs--y1gaM";
export var newCardRecs = "newCardRecs--WeTok";
export var verticalSlider = "verticalSlider--oP5BL";
export var mediaItemBar = "mediaItemBar--U_LvX";
export var description = "description--vxHtZ";
export default ___CSS_LOADER_EXPORT___;
