// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--VlMzH {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  width: 85.9375vw;\n  height: 56.25vw;\n  margin: 0 auto;\n  color: rgba(243, 243, 247, 1);\n}\n\n.content--jH8IE {\n  padding: 7.8125vw 3.90625vw;\n  color: rgba(243, 243, 247, 1);\n  font-size: 1.1em;\n  line-height: 1.1em;\n  text-align: left;\n  white-space: normal;\n  transition: translate 200ms ease-in-out;\n  -webkit-transition: translate 200ms ease-in-out;\n}\n\n.content--jH8IE h1,\n.content--jH8IE h2 {\n  font-size: 1.1em;\n  margin-bottom: 1em;\n}\n\n.content--jH8IE p {\n  margin: 0 0 1em;\n}\n\n.content--jH8IE a {\n  color: green;\n}\n\n.scrollbar--D3R1d {\n  position: fixed;\n  top: 3.90625vw;\n  bottom: 3.90625vw;\n  right: 2.03125vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/AgreementPopup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,6BAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,6BAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uCAAuC;EACvC,+CAA+C;AACjD;;AAEA;;EAEE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  width: 85.9375vw;\n  height: 56.25vw;\n  margin: 0 auto;\n  color: $gw_10;\n}\n\n.content {\n  padding: 7.8125vw 3.90625vw;\n  color: $gw_10;\n  font-size: 1.1em;\n  line-height: 1.1em;\n  text-align: left;\n  white-space: normal;\n  transition: translate 200ms ease-in-out;\n  -webkit-transition: translate 200ms ease-in-out;\n}\n\n.content h1,\n.content h2 {\n  font-size: 1.1em;\n  margin-bottom: 1em;\n}\n\n.content p {\n  margin: 0 0 1em;\n}\n\n.content a {\n  color: green;\n}\n\n.scrollbar {\n  position: fixed;\n  top: 3.90625vw;\n  bottom: 3.90625vw;\n  right: 2.03125vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = "wrapper--VlMzH";
export var content = "content--jH8IE";
export var scrollbar = "scrollbar--D3R1d";
export default ___CSS_LOADER_EXPORT___;
