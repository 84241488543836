import * as React from 'react';

import { getCachedCardMutation, useCardMutations } from '~hooks/fetch/useCardMutations';
import getCurrentEventMutation from '~lib/getCurrentEventMutation';
import { getBackgroundImageUrl } from '~lib/MediaItemImageSRC';
import { getSceneItemHeight, getSceneItemWidth } from '~lib/SizesInPX';
import Card from '~typings/Card';



type Props = Readonly<{
  mediaItem: Card;
  renderImage: (imageUrl: string)=> JSX.Element;
}>;


export const MaskedSceneMediaItemMutable: React.FC<Props> = ({mediaItem, renderImage}) => {
  const width = getSceneItemWidth();
  const height = getSceneItemHeight();

  useCardMutations(
    mediaItem.id,
    mediaItem.card_config_id,
    true
  );

  const mutations = getCachedCardMutation(mediaItem?.id, mediaItem.card_config_id);

  const item = getCurrentEventMutation(mutations)?.card;

  if(!item){
    return null;
  }

  const imageUrl = getBackgroundImageUrl({
      mediaItem: item,
      width,
      height,
    });

  if (!imageUrl) {
    return null;
  }

  return renderImage(imageUrl)
};
