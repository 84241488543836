import { MediaPlayer } from 'dashjs';
import { find } from 'lodash';

import bringingAudioTrackNameToStandard from '~lib/bringingAudioTrackNameToStandard';
import getSecontsFromPercents from '~lib/player/getSecontsFromPercents';
import onError from '~lib/player/shaka/error';
import sortVideoTracks from '~lib/player/sortVideoTracks';
import AudioTrack from '~typings/AudioTrack';
import PlayerType from '~typings/PlayerType';
import VideoTrack from '~typings/VideoTrack';

import { createHTMLVideoElement, Events, prepareTimeShiftUrl, resetHTMLVideoElement } from '../common';
import { IPlayer, PlayerEventSubscribe, PlayerLoadParameters, Props } from '../typings';
import getDRM from './drm';

// require('dashjs/build/es5/src/mss');


const dashPlayer = ({ videoContainer, event }: Props): IPlayer => {
  let player: any | null = null;
  let videoElement: HTMLVideoElement | null = null;

  const dispose = () => {
    try {
      if (videoContainer && videoElement) {
        videoContainer.removeChild(videoElement);
        videoElement = null;
      }
    } catch (ignore) {

    }
  };
  const getCurrentTime = (): number => videoElement?.currentTime || 0;
  const getDuration = (): number => videoElement?.duration || 0;

  async function reset() {
    if (player && player.reset) {
      player.reset();
    }

    resetHTMLVideoElement(videoContainer, videoElement);
    videoElement = null;
  }

  async function load(parameters: PlayerLoadParameters) {
    await reset();

    try {
      const playerUrl = prepareTimeShiftUrl(parameters.stream.url, parameters.timeShiftParams);

      videoElement = createHTMLVideoElement(event);
      videoContainer?.appendChild(videoElement);

      player = MediaPlayer().create();
      player.initialize(videoElement, playerUrl, true);
      if (parameters.stream.drm && parameters.stream.drm.type) {
        player.setProtectionData(getDRM(parameters.stream.drm));
      }
      player.updateSettings({
        streaming: {
          trackSwitchMode: {
            video: 'alwaysReplace',
          },
        },
        'debug': {
          // LOG_LEVEL_NONE = 0,
          // LOG_LEVEL_FATAL = 1,
          // LOG_LEVEL_ERROR = 2,
          // LOG_LEVEL_WARNING = 3,
          // LOG_LEVEL_INFO = 4,
          // LOG_LEVEL_DEBUG = 5
          'logLevel': 0,
        },
      });
      if (parameters.language) {
        player.setInitialMediaSettingsFor('audio', { lang: parameters.language });
      }
      videoElement.addEventListener('loadedmetadata', () => {
        const duration = getDuration();

        if (parameters.percentsWatched && duration) {
          seekTo(getSecontsFromPercents(parameters.percentsWatched, duration));

          play();
        }
      });
      player.on('error', (error) => {
        onError(event, error);
      });
    } catch (error) {
      onError(event, error);
    }
  }

  const mute = () => {};
  const on: PlayerEventSubscribe = (target, listener) => event.on(target, listener);

  const removeListener: PlayerEventSubscribe = (target, listener) => {
    event.removeListener(target, listener);
  };

  const play = (): void => {
    videoElement?.play();
  };

  const pause = () => {
    videoElement?.pause();
  };

  const seekTo = (timePosition: number) => {
    if (videoElement?.currentTime !== undefined) {
      play();
      videoElement.currentTime = timePosition;
    }
  };

  const stop = () => {
    reset();
  };

  const isAutoQualityEnabled = () => {
    try {
      const settings = player.getSettings();

      return (settings.streaming.trackSwitchMode.video === 'alwaysReplace');
    } catch (ignore) {
      return false;
    }
  };
  const setAutoQuality = (enabled) => {
    try {
      player.updateSettings({
        streaming: {
          trackSwitchMode: {
            video: (enabled) ? 'alwaysReplace' : 'neverReplace',
          },
        },
      });
      // player.setTrackSwitchModeFor(
      //   'video',
      //   (enabled) ? 'alwaysReplace' : 'neverReplace'
      //   );

      event.emit(Events.TRACK_CHANGED);
    } catch (ignore) {
      // ignore
    }
  };

  const changeAudioTrack = (audioTrack: AudioTrack) => {
    try {
      player.setTrackSwitchModeFor('audio', 'alwaysReplace');
      player.setCurrentTrack(audioTrack.data);
      event.emit(Events.TRACK_CHANGED);
    } catch (ignore) {
      // ignore
    }
  };
  const changeVideoTrack = (videoTrack: VideoTrack) => {
    try {
      setAutoQuality(false);
      player.setQualityFor('video', videoTrack.index);
      event.emit(Events.TRACK_CHANGED);
    } catch (ignore) {
      // ignore
    }
  };
  const getAudioTracks = () => {
    const audioTracks: AudioTrack[] = [];

    try {
      const tracks = player.getTracksFor('audio');
      const selectedAudioTrackIndex = player.getCurrentTrackFor('audio').index;

      for (let index = 0; index < tracks.length; index += 1) {
        const audioIndex = tracks[index].index;
        const name = bringingAudioTrackNameToStandard(tracks[index].lang);

        audioTracks.push({
          data: tracks[index],
          index: audioIndex,
          isSelected: (audioIndex === selectedAudioTrackIndex),
          name,
        });
      }
    } catch (ignore) {
      // ignore
    }

    return audioTracks;
  };
  const getVideoTracks = () => {
    const videoTracks: VideoTrack[] = [];

    try {
      const bitrates = player.getBitrateInfoListFor('video');
      const selectedBitRateIndex = player.getQualityFor('video');
      // console.log({ selectedBitRateIndex })

      for (let index = 0; index < bitrates.length; index += 1) {
        const bitRateIndex = bitrates[index].qualityIndex;

        videoTracks.push({
          index: bitRateIndex,
          isSelected: (bitRateIndex === selectedBitRateIndex),
          name: `${bitrates[index].width}x${bitrates[index].height}`,
        });
      }
    } catch (ignore) {
      // ignore
    }

    // console.log('videoTracks:', videoTracks);
    return sortVideoTracks(videoTracks);
  };
  const getSelectedAudioTrack = () => {
    find(getAudioTracks(), { isSelected: true });
  };
  const getSelectedVideoTrack = () => {
    find(getVideoTracks(), { isSelected: true });
  };

  const getProperties = () => {
    if (!videoElement) { return null; }

    return {
      videoPlaybackQuality: videoElement?.getVideoPlaybackQuality ?
        videoElement.getVideoPlaybackQuality() : null,
    };
  };

  return {
    type: PlayerType.Dash,

    dispose,
    getCurrentTime,
    getDuration,
    load,
    mute,
    on,
    removeListener,
    pause,
    play,
    reset,
    seekTo,
    stop,

    isAutoQualityEnabled,
    setAutoQuality,

    changeAudioTrack,
    changeVideoTrack,
    getAudioTracks,
    getVideoTracks,
    getSelectedAudioTrack,
    getSelectedVideoTrack,

    getProperties,
  };
};


export default dashPlayer;
