import * as React from 'react';
import { useEffect } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import useMediaItemPageInfo from '~hooks/useMediaItemPage';
import { CONTINUE, PLEASE_COMPLETE_PURCHASE } from '~localization';
import Product from '~typings/Product';
import Button from '~ui/button';

import * as styles from './styles.module.css'

type Props = Readonly<{
  product?: Product;
  intl: InjectedIntl;
}>;

const CardProceed: React.FC<Props> = (props: Props) => {
  const client = useClient();
  const { accessToken } = getAuthData();
  const { planID } = useParams<{planID?: string}>();
  const { mediaItemPage } = useMediaItemPageInfo();
  const history = useHistory()
  const isPurchase = history.location.pathname.includes('rents')
  const handleContinue = () => {
    history.goBack()
  }

  useEffect(() => {
    if (!isPurchase) {
      client.post(
        '/v3/shorted_links/subscription',
        {
          access_token: accessToken,
          'plan_id': planID,
        }
      );
    } else {
      client.post(
        '/v3/shorted_links/purchase',
        {
          access_token: accessToken,
          'resource_id': mediaItemPage.id,
          'rent_plan_id': planID,
        }
      );
    }
  }, [])

  return (
    <>
      <div>{props.intl.formatMessage({ id: PLEASE_COMPLETE_PURCHASE })}</div>
      <Button onClick={ handleContinue } className={ styles.continueButton } isFocused >
        {props.intl.formatHTMLMessage({ id: CONTINUE })}
      </Button>
    </>
  )
}

export default injectIntl(CardProceed);
