import focuser from '@focuser';
import * as cn from 'classnames';
import { find } from 'lodash';
import * as React from 'react';

import ProfileImage from '~components/ProfileImage';
import useAvatars from '~hooks/fetch/useAvatars/useAvatars';
import AllowedContent from '~typings/AllowedContent';
import Profile from '~typings/Profile';
import ProfileFormData from '~typings/ProfileFormData';
import PenIcon from '~ui/Icon/icons/PenIcon';
import { PopupIsolated } from '~ui/Popup';

import { FieldsName } from '../helpers';
import * as styles from '../styles.module.css';
import ButtonsComponent from './ButtonsComponent/ButtonsComponent';
import ContentComponent from './ContentComponent/ContentComponent';


enum FocusOn {
  Avatar = 'avatar',
  Fields = 'fields',
  Buttons = 'buttons',
}

type Props = Readonly<{
  profileId: string | undefined;
  profileData: ProfileFormData;
  popupTitle: string;
  originalProfile?: Profile;
  setProfileData: (part: Partial<ProfileFormData>) => void;
  initialFocus?: 'avatar' | 'name' | 'age' | 'delete_profile_button';
  isShowCheckboxButton: boolean;
  onBack: () => void;
  onSelectAvatar: () => void;
  onSelectNameField: () => void;
  onSelectAgeField: () => void;
  confirmDeletePopupOpen: () => void;
  onSave: () => void;
}>;

const MainEditProfileComponent: React.FC<Props> = ({ 
  profileId, 
  profileData, 
  popupTitle,
  originalProfile, 
  setProfileData,  
  initialFocus,
  isShowCheckboxButton,
  onBack, 
  onSelectAvatar, 
  onSelectNameField,
  onSelectAgeField,
  confirmDeletePopupOpen,
  onSave,
}: Props) => {
  const { data: avatarsData } = useAvatars();

  const [focusedBlock, setFocusedBlock] = React.useState<FocusOn>(() => {
    if(initialFocus === 'avatar') {
      return FocusOn.Avatar
    }
    if(initialFocus === 'delete_profile_button') {
      return FocusOn.Buttons
    }
    return FocusOn.Fields
  });

  const isEditMode = !!profileId;
  const isKidsProfile = profileData.kids === true;

  const selectedAvatar = find(
    avatarsData?.data,
    avatar => avatar.id === profileData.avatar_id
  );

  React.useEffect(() => {
    if (isKidsProfile && profileData.allowed_content === AllowedContent.Adult) {
      setProfileData({ [FieldsName.AllowedContent]: AllowedContent.Teens })
    }
  }, [isKidsProfile, profileData.allowed_content]);

  const handleKeyRight = () => {
    if(focusedBlock === FocusOn.Avatar) {
      setFocusedBlock(FocusOn.Fields);
    }
  }

  const handleKeyLeft = () => {
    if(focusedBlock === FocusOn.Fields) {
      setFocusedBlock(FocusOn.Avatar);
    }
  }

  const handleKeyDown = () => {
    if(focusedBlock === FocusOn.Fields) {
      setFocusedBlock(FocusOn.Buttons)
    }
  }

  const handleKeyUp = () => {
    if(focusedBlock === FocusOn.Buttons) {
      setFocusedBlock(FocusOn.Fields);
    }
  }

  return (
    <PopupIsolated>
      <div className={ styles.popupTitle }>{popupTitle}</div>

      <focuser.FocusableBlock
        isFocused
        onKeyReturn={ (evt) => {
          onBack()
          evt.stop()
          evt.stopNativeEvent()
        } }
        onKeyRight={ handleKeyRight }
        onKeyLeft={ handleKeyLeft }
        onKeyDown={ handleKeyDown }
        onKeyUp={ handleKeyUp }
        noNeedForceFocus
      >
        <div className={ styles.popupContent }>
          <div className={ styles.contentWrap }>
            <focuser.FocusableBlock
              isFocused={ focusedBlock === FocusOn.Avatar }
              onClick={ (evt) => {
                onSelectAvatar();
                evt.stop();
                evt.stopNativeEvent();
              } }
              onForceFocus={ () => setFocusedBlock(FocusOn.Avatar) }
              emitForceFocusOnHover
              isLastBlock
            >
              {({ isFocused }) => (
                <div className={ styles.avatarWrap }>
                  <ProfileImage
                    avatar={ selectedAvatar ?? originalProfile?.avatar }
                    className={ cn(
                      styles.avatar,
                      styles.focused,
                      { [styles.shadowed]: isFocused }
                    ) }
                    isFocused={ isFocused }
                    isOnEditProfilePage
                  />
                  {isFocused && 
                    <div className={ styles.iconWrap }>
                      <div>
                        <PenIcon className={ styles.iconPenOnAvatar }/>
                      </div>
                    </div>
                  }
                </div>
              )}
            </focuser.FocusableBlock>

            <focuser.FocusableBlock
              isFocused={ focusedBlock === FocusOn.Fields }
              onForceFocus={ () => setFocusedBlock(FocusOn.Fields) }
              emitForceFocusOnHover
            >
              <ContentComponent
                popupTitle={ popupTitle }
                onSelectNameField={ onSelectNameField }
                onSelectAgeField={ onSelectAgeField }
                initialFocus={ 
                  initialFocus === 'avatar' || initialFocus === 'delete_profile_button' 
                    ? undefined 
                    : initialFocus 
                }
                profileData={ profileData }
                setProfileData={ setProfileData }
                isShowCheckboxButton={ isShowCheckboxButton }
              />
            </focuser.FocusableBlock>

          </div>
          <focuser.FocusableBlock
            isFocused={ focusedBlock === FocusOn.Buttons }
            onForceFocus={ () => setFocusedBlock(FocusOn.Buttons) }
            emitForceFocusOnHover
          >
            <ButtonsComponent
              profileData={ profileData }
              isEditMode={ isEditMode }
              originalProfile={ originalProfile }
              profileId={ profileId }
              onBack={ onBack }
              initialFocus={ initialFocus !== 'delete_profile_button' ? undefined : initialFocus }
              confirmDeletePopupOpen={ confirmDeletePopupOpen }
              onSave={ onSave }
            />
          </focuser.FocusableBlock>
        </div>
      </focuser.FocusableBlock>
    </PopupIsolated>
  )
}

export default MainEditProfileComponent;
