import React, { useMemo } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import { TODAY, TOMORROW, YESTERDAY } from '~lib/localizator/src/dictionaries';
import { isDateToday, isDateTomorrow, isDateYesterday } from '~newapp/utils/globals/time';

import { dataWeekDayId } from '../../../../EPGPageCommon.utils';
import * as styles from './EPGDayHeader.module.css';

export type Props = {
  className?: string;
  pivotRawDate: Date;
  weekDayDataId?: string;
  intl: InjectedIntl;
};

const EPGDayHeader$: React.FC<Props> = ({ className, pivotRawDate, intl, weekDayDataId }) => {
  const textToRender = useMemo(() => {
    if (isDateYesterday(pivotRawDate)) {
      return intl.formatMessage({ id: YESTERDAY });
    }

    if (isDateToday(pivotRawDate)) {
      return intl.formatMessage({ id: TODAY });
    }

    if (isDateTomorrow(pivotRawDate)) {
      return intl.formatMessage({ id: TOMORROW });
    }

    return `${localWeekday(intl.locale, pivotRawDate)}, ${localDay(
      intl.locale,
      pivotRawDate,
    )} ${localMonth(intl.locale, pivotRawDate)}`;
  }, [intl.locale, pivotRawDate]);

  return (
    <div
      className={ className }
      { ...(weekDayDataId ? { [dataWeekDayId]: weekDayDataId } : {}) }
    >
      <Typography
        className={ styles.dateItem }
        variant="h5_bold"
      >
        {textToRender}
      </Typography>
    </div>
  );
};

const localWeekday = (locale: Intl.LocalesArgument, date: Date) => {
  return date.toLocaleDateString(locale, { weekday: 'short' });
};

const localDay = (locale: Intl.LocalesArgument, date: Date) => {
  return date.toLocaleDateString(locale, { day: 'numeric' });
};

const localMonth = (locale: Intl.LocalesArgument, date: Date) => {
  if (locale === 'ru') {
    // Для русского языка возвращаем в родительском падеже
    return date
      .toLocaleString(locale, {
        month: 'long',
        day: 'numeric',
      })
      .split(' ')[1];
  }
  return date.toLocaleDateString(locale, { month: 'long' });
};

/**
 * Компонент показывает дату для слайдера событий в EPG
 */
export const EPGDayHeader = React.memo(injectIntl(EPGDayHeader$));
