import { FocuserKeyOrClickHandler } from '@focuser';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useBannersV4 } from '~hooks/fetch/useBannersV4/useBanners';
import useDeepLink from '~hooks/useDeepLink';
import BannerMiddle from '~typings/BannerMiddle';
import { Block } from '~typings/Block';

import { MiddleBannerContent } from './components/MiddleBannerContent/MiddleBannerContent';
import { MiddleBannerLoading } from './components/MiddleBannerLoading/MiddleBannerLoading';

type Props = Readonly<{
  blockId: Block['id'];
}>;

export const MiddleBannerBlock: React.FC<Props> = (props) => {
  const history = useHistory();
  const { urlFromDeepLink } = useDeepLink();
  const { data: items } = useBannersV4(props.blockId);
  const middleBanner = items?.data[0] as BannerMiddle | undefined;


  const handleClick: FocuserKeyOrClickHandler = useCallback((e) => {
    if (middleBanner?.deeplink) {
      const URL = urlFromDeepLink(middleBanner.deeplink);
      if (URL) {
        e.stop();
        e.stopNativeEvent();
        history.push(URL);
      }
    }
  }, [middleBanner?.deeplink, urlFromDeepLink]);


  if (!middleBanner) {
    return <MiddleBannerLoading />;
  }



  return (
    <MiddleBannerContent
      bannerMiddle={ middleBanner }
      onClick={ handleClick }
    />
  );
};
