import {QueryKey, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';
import DropdownFilter from '~typings/DropdownFilter';
import QuickFilter from '~typings/QuickFilter';

const getQueryKey = (groupID?: string): QueryKey => ['search_quick_filters', groupID];


const getSearchFiltersGroupItems = async (client: ApiClient, groupID?: string) => {
  const cacheData = queryClient.getQueryData(getQueryKey());

  if (cacheData) { return cacheData; }

  let url = `/v3/search_filters_group_items`;
  const options = {};

  if (groupID) {
    options['search_filters_group_id'] = groupID;
  }

  const { data } = await client.get(url, options);

  return data;
};

function useSearchFiltersGroupItems(groupID?: string) {
  const client = useClient();

  return useQuery<(QuickFilter | DropdownFilter)[]>({
    queryKey: getQueryKey(groupID),
    queryFn: () => getSearchFiltersGroupItems(client, groupID),
    notifyOnChangeProps: ['data', 'isLoading'],
  });
}


export default useSearchFiltersGroupItems;
