import { getResourceObjectType, isEpisode, isSeries } from '~lib/objectType';
import { DataItem } from '~typings/DataItem';
import Image from '~typings/Image';
import ImageType from '~typings/ImageType';
import ItemObject from '~typings/ItemObject';


const hasChannelCardWide = (images: Image[]): boolean => (
  !!images.find(({ type }) => (type === ImageType.CHANNEL_CARD_WIDE))
);

const showAsSeries = (propsShowAsSeries: boolean, item: DataItem): boolean => {
  const itemObject = getResourceObjectType(item);

  if (itemObject === ItemObject.Card) {
    return false;
  } else if (
    (propsShowAsSeries && isEpisode(item))
    || isSeries(item)
  ) {
    return true;
  }

  return false;
};


export {
  hasChannelCardWide,
  showAsSeries,
};
