import { EventEmitter } from 'events';
import { useEffect, useRef } from 'react';

import { usePlatform } from '~components/Provider/Platform';
import dashPlayer from '~lib/player/dash';
import getPlayerType from "~lib/player/getPlayerType";
import hlsPlayer from '~lib/player/hls';
import HTML5Player from '~lib/player/HTML5';
import nativePlayer from '~lib/player/native';
import shakaPlayer from '~lib/player/shaka';
import tizenPlayer from '~lib/player/tizen';
import { IPlayer } from '~lib/player/typings';
import webosPlayer from '~lib/player/webos';
import Manifest from '~typings/Manifest';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import PlayerType from '~typings/PlayerType';
import Stream from '~typings/Stream';

import useAnaliticsV2 from './plugins/useAnaliticsV2';
import useHeartbeat from './plugins/useHeartbeat';
import { useHeartbeats } from './plugins/useHeartbeats';
import useLogger from './plugins/useLogger';


const PLAYERS = {
  [PlayerType.HTML5]: HTML5Player,
  [PlayerType.Native]: nativePlayer,
  [PlayerType.Shaka]: shakaPlayer,
  [PlayerType.Tizen]: tizenPlayer,
  [PlayerType.Webos]: webosPlayer,
  [PlayerType.Dash]: dashPlayer,
  [PlayerType.Hls]: hlsPlayer,
};

type Props = {
  mediaItem: PlayerMediaItem;
  stream: Stream;
  manifest: Manifest;
};

type UsePlayerReturn = [
  { current: HTMLDivElement | null },
  { current: IPlayer | null, },
];

const usePlayer = ({ manifest, mediaItem, stream }: Props): UsePlayerReturn => {
  const { platform } = usePlatform();
  const device = platform.getDeviceInfo();
  const videoContainer = useRef<HTMLDivElement | null>(null);
  const player = useRef<IPlayer | null>(null);
  const event = useRef<EventEmitter>(new EventEmitter());

  const playerType = getPlayerType({
    manifest,
    mediaItem,
    stream,
    device,
  });

  /**
   * Plugins
   * 1. Metrics (useAnaliticsV2)
   * 2. Heartbeat (useHeartbeat & useHeartbeats)
   * 3. StreamLogger/ErrorSender (useLogger)
   *
   * TODO
   * 4. Ad Player (at PlayerContainer)
   * 5. NPAW Analitic (adapter for Shaka)
   */

  useAnaliticsV2({ player: player.current, stream, device });
  useLogger({ player: player.current, stream, mediaItem, device });
  useHeartbeat({ player: player.current, stream, mediaItem, device });
  useHeartbeats({ player: player.current, stream, mediaItem, device });

  useEffect(() => {
    if (videoContainer.current && player.current?.type !== playerType) {
      player.current = PLAYERS[playerType]({
        videoContainer: videoContainer.current,
        event: event.current,
      });
    }
  }, [videoContainer.current, player.current, PLAYERS[playerType]])

  return [videoContainer, player];
};


export default usePlayer;
