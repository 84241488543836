import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useConfig } from '~global';
import { NAVIGATION_BACK, PLAYER_ERROR_NOT_PURCHASED_SUBTEXT } from '~localization';
import Button from '~ui/button';

import * as styles from './styles.module.css';

const CantPurchaseErrorDialog: React.FC = () => {
  const history = useHistory();
  const { smartTV } = useConfig();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className={ styles.messageText }>
        <FormattedMessage
          id={ PLAYER_ERROR_NOT_PURCHASED_SUBTEXT }
          values={ {
            site: smartTV.baseSiteUrl,
          } }
        />
      </div>
      <Button onClick={ handleBack } isFocused>
        <FormattedMessage id={ NAVIGATION_BACK } />
      </Button>
    </>
  )
};

export default CantPurchaseErrorDialog;
