import focuser from '@focuser';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { SimpleButtonFocuser } from '~app/ui-kit/SimpleButton';
import { DeviceLine } from '~components/DeviceLine';
import {useGetDevice} from '~hooks/useGetDevices';
import { CONTINUE, YOU_CANNOT_ADD_THIS_DEVICE } from '~lib/localizator/src/dictionaries';
import {
  DeleteButtonFocuser,
} from '~newapp/ui-kit/Controls/Buttons/Icon_btn/Big_icon_btn/DeleteButton';
import { UserDevice } from '~typings/Devices';
import { SliderVerticable } from '~ui/SliderVerticable';

import { DeviceDeleteConfirmPopup } from '../DeviceDeleteConfirmPopup';
import { useRetPathFromLinking } from './DeviceLinkingHooks';
import * as styles from './DeviceLinkingLimitPage.module.css';

enum FocusOn {
  DevicesList = 'devices_list',
  ContinueButton = 'continue_button',
}

type Props = {
  intl: InjectedIntl;
};

export const DeviceLinkingLimitPage = injectIntl<Props>(({ intl }) => {
  const { data: devices = [] } = useGetDevice();

  const [userDeviceToDelete, setUserDeviceToDelete] = React.useState<UserDevice | null>(null);

  const [focusedBlock, setFocusedBlock] = React.useState(FocusOn.DevicesList);
  const [focusedDeviceIdx, setFocusedDeviceIdx] = React.useState(0);

  const { goToAfterContinueOnLinkingLimitPage } = useRetPathFromLinking();

  const handleDown = () => {
    if (focusedBlock === FocusOn.DevicesList) {
      if (focusedDeviceIdx < devices.length - 1) {
        setFocusedDeviceIdx((idx) => idx + 1);
      } else {
        setFocusedBlock(FocusOn.ContinueButton);
      }
    }
  };

  const handleUp = () => {
    if (focusedBlock === FocusOn.DevicesList) {
      if (focusedDeviceIdx > 0) {
        setFocusedDeviceIdx((idx) => idx - 1);
      }
    } else {
      setFocusedBlock(FocusOn.DevicesList);
    }
  };

  const handleCloseDeletePopup = () => {
    setUserDeviceToDelete(null);
    setFocusedBlock(FocusOn.DevicesList);
  };

  if (userDeviceToDelete) {
    return (
      <DeviceDeleteConfirmPopup
        onDeleteSuccess={ handleCloseDeletePopup }
        onClose={ handleCloseDeletePopup }
        deviceToDelete={ userDeviceToDelete }
      />
    );
  }

  const handleClick = (device: UserDevice) => {
    setUserDeviceToDelete(device);
  };

  return (
    <focuser.FocusableBlock
      isFocused
      noNeedForceFocus
      onKeyUp={ handleUp }
      onKeyDown={ handleDown }
      isPointerDownAvailable={ focusedBlock === FocusOn.DevicesList }
      isPointerUpAvailable={ focusedBlock === FocusOn.ContinueButton || focusedDeviceIdx > 0 }
    >
      <div className={ styles.linkDevicesPageWrap }>
        <div className={ styles.devicesPageText }>
          {intl.formatMessage({ id: YOU_CANNOT_ADD_THIS_DEVICE })}
        </div>
        <focuser.FocusableBlock
          isFocused={ focusedBlock === FocusOn.DevicesList }
          className={ styles.devicesListWrap }
          onForceFocus={ () => setFocusedBlock(FocusOn.DevicesList) }
        >
          <SliderVerticable
            focusedIndex={ focusedDeviceIdx }
            wrapperClassName={ styles.devicesList }
          >
            {devices.map((userDevice, idx) => (
              <DeviceLine
                key={ userDevice.device.device_id }
                userDevice={ userDevice }
                button={
                  <DeleteButtonFocuser
                    onClick={ () => handleClick(userDevice) }
                    emitForceFocusOnHover
                    isFocused={ focusedDeviceIdx === idx }
                    onForceFocus={ () => setFocusedDeviceIdx(idx) }
                  />
                }
              />
            ))}
          </SliderVerticable>
        </focuser.FocusableBlock>
        <SimpleButtonFocuser
          isFocused={ focusedBlock === FocusOn.ContinueButton }
          onClick={ goToAfterContinueOnLinkingLimitPage }
          onForceFocus={ () => setFocusedBlock(FocusOn.ContinueButton) }
          emitForceFocusOnHover
          focuserClassName={ styles.nextButton }
        >
          {intl.formatMessage({ id: CONTINUE })}
        </SimpleButtonFocuser>
      </div>
    </focuser.FocusableBlock>
  );
});

DeviceLinkingLimitPage.displayName = 'DeviceLinkingLimitPage';
