import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Loader from '~components/LightLoader/Loader';
import { removeStreamAccessQueries } from '~hooks/fetch/useStreamAccess';
import { PREFIX_OF_A_COMPOSITE_KEY as subscriptionsKey } from '~hooks/fetch/useSubscriptions';
import usePaymentProceed from '~hooks/usePaymentProceed/usePaymentProceed';
import deleteCacheByKeys from '~lib/ReactQuery/deleteCacheByKeys';
import {
  AUTH_ENTER_PASSWORD,
  ERROR_HAS_OCCURRED,
  NAVIGATION_BACK,
  PAYMENT_IN_PROGRESS,
  PAYMENT_WAS_SUCCESSFUL,
} from '~localization';
import Product from '~typings/Product';
import Button from '~ui/button';
import CheckIcon from '~ui/Icon/icons/Check';
import ErrorIcon from '~ui/Icon/icons/ErrorIcon';
import { Popup } from '~ui/Popup';

import * as styles from './styles.module.css';


type Props = Readonly<{
  product: Product;
  intl: InjectedIntl;
}>;

const PaymentProceed: React.FC<Props> = (props) => {
  const history = useHistory();
  const { isError, isSuccess, error } = usePaymentProceed();

  const handleBack = () => {
    history.goBack();
  };

  const handleContinue = () => {
    history.goBack();
    deleteCacheByKeys([subscriptionsKey]);
    removeStreamAccessQueries()
  };

  return (
    <Popup>
      <div className={ styles.contentWrapper }>
        <div className={ styles.infoIconWrapper }>
          {
            (isError) ? (
              <ErrorIcon className={ cn(styles.icon, styles.errorIcon) } />
            ) : (isSuccess) ? (
              <CheckIcon className={ cn(styles.icon, styles.successIcon) } />
            ) : (
              <Loader />
            )
          }
        </div>
        <div className={ styles.info }>
          {
            (isError) ? error?.message || (
              props.intl.formatMessage({ id: ERROR_HAS_OCCURRED })
            ) : (isSuccess) ? (
              props.intl.formatMessage({ id: PAYMENT_WAS_SUCCESSFUL })
            ) : (
              props.intl.formatMessage({ id: PAYMENT_IN_PROGRESS })
            )
          }
        </div>
        {
          (isError) ? (
            <Button
              isFocused
              className={ cn(styles.button, styles.center, styles.isFocused) }
              onClick={ handleBack }
            >
              {
                props.intl.formatMessage({ id: NAVIGATION_BACK })
              }
            </Button>
          ) : (isSuccess) ? (
            <Button
              isFocused
              className={ cn(styles.button, styles.center, styles.isFocused) }
              onClick={ handleContinue }
            >
              {
                props.intl.formatMessage({ id: AUTH_ENTER_PASSWORD })
              }
            </Button>
          ) : null
        }
      </div>
    </Popup>
  );
};


export default injectIntl(PaymentProceed);
