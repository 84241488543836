import * as cn from 'classnames';
import * as React from 'react';

import { ItemForRender } from '~components/Slider/items/items.helpers';
import {
  getMediaItemBannerWidth,
  getMediaItemPosterWidth,
  getSpaceBetweenSlides,
} from '~lib/SizesInPX';
import uuid from '~lib/uuid';
import { DataItem } from '~typings/DataItem';
import ImageType from '~typings/ImageType';
import MediaItemDimension from '~typings/MediaItemDimension';
import ResponseMany from '~typings/ResponseMany';
import { MediaItem } from '~ui/MediaItem';
import * as mediaItemStyles from '~ui/MediaItem/styles.module.css';

import * as styles from './styles.module.css';


type Props = Readonly<{
  dimension: MediaItemDimension;
  items: ResponseMany<DataItem[]> | undefined;
  showAsSeries?: boolean;
  withGenres?: boolean;
}>;

const Slider: React.FC<Props> = (props) => {
  const UUID = React.useRef(
    Array.from({ length: 5 }).fill(0).map((_, index) => uuid(index))
  ).current;

  if (props.items === undefined) { return null; }

  const itemsForRender: ItemForRender[] = props.items.data
    .slice(0, 5)
    .map((item, index) => ({
      key: UUID[index],
      index,
      item,
    }));
  const width = (
    (props.dimension === ImageType.POSTER || props.dimension === ImageType.LOGO_SMALL) ?
      getMediaItemPosterWidth() + getSpaceBetweenSlides()
      :
      getMediaItemBannerWidth() + getSpaceBetweenSlides()
  );
  const mediaItemType = (props.dimension === ImageType.POSTER) ?
    ImageType.POSTER
    : props.dimension === ImageType.LOGO_SMALL ?
      ImageType.LOGO_SMALL
      :
      ImageType.BANNER;

  return (
    <div
      className={ cn(styles.sliderWrapper, styles[props.dimension]) }
    >
      {
        itemsForRender.map((render: ItemForRender) => (
          <MediaItem
            key={ `SliderItem-${render.key}` }
            itemKey={ render.key }
            left={ (render.index * width) }
            className={
              cn(
                mediaItemStyles.mediaItem,
                mediaItemStyles[render?.item?.object || ''],
                mediaItemStyles[mediaItemType],
              )
            }
            dimension={ props.dimension }
            isFocused={ false }
            item={ render.item }
            showAsSeries={ props.showAsSeries }
            withName
            withGenres={ props.withGenres }
            alwaysShowName
          />
        ))
      }
    </div>
  );
};


export default React.memo(Slider);
