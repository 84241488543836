import React, { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { useClient, useConfig } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import deleteCacheWithOldAccessTokens from '~lib/ReactQuery/deleteCacheWithOldAccessTokens';
import { MINUTE } from '~newapp/utils/globals/time';
import Card from '~typings/Card';
import ResponseMany from '~typings/ResponseMany';
import { SelectedFilters } from '~typings/SelectedFilters';

import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '../utils';
import { getDropdownFiltersRequestParams, getQuickFiltersRequestParams, makeCacheKeyFromSelectedFilters } from './utils';

const PREFIX_OF_A_COMPOSITE_KEY = 'card_collections_items';

type CommonQueryKeyParam = {
  accessToken: string | null,
  cardCollectionId?: string,
  selectedFilters: SelectedFilters,
  limit: number,
}


const CCIKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,
  withAccessToken: (accessToken: string | null) => [CCIKeys.PREFIX, accessToken],
  withDefaultParams: (params: CommonQueryKeyParam)=>[
    ...CCIKeys.withAccessToken(params.accessToken),
    params.cardCollectionId,
    makeCacheKeyFromSelectedFilters(params.selectedFilters),
    params.limit
  ],
  firstNormalPage: (params: CommonQueryKeyParam) => [
    ...CCIKeys.withDefaultParams(params),
    'normal-pages-fist-page',
  ],
  infinityPages: (
    params: CommonQueryKeyParam
  ) => [
    ...CCIKeys.withDefaultParams(params),
    'infinity-pages',
  ],
};

type FetchParams = Readonly<{
  page: number;
  limit: number;
  client: ApiClient;
  cardCollectionId?: string;
  filters?: SelectedFilters;
  accessToken?: string | null;
}>;

const fetch = async (params: FetchParams): Promise<ResponseMany<Card[]>> => {
  let options = {
    ...fetchOptionsBasic[FetchType.CardCollectionsItems],
    'filter[collection_id_eq]': params.cardCollectionId,
    'page[offset]': params.page * params.limit,
    'page[limit]': params.limit,
  };

  if (params.accessToken) {
    options['access_token'] = params.accessToken;
  }


  options = {
    ...options,
    ...getQuickFiltersRequestParams(params.filters?? {}),
    ...getDropdownFiltersRequestParams(params.filters?? {}),
  }

  deleteCacheWithOldAccessTokens(CCIKeys.PREFIX, 1);

  const response = await params.client.get(
    fetchURLs[FetchType.CardCollectionsItems],
    options,
  );

  return response;
}



type CardColItemsQueryParams = {
  cardCollectionId?: string,
  selectedFilters?: SelectedFilters,
  /**
   * Активен ли запрос, по дефолту = true
   */
  enabled?: boolean;
}


export const useInfinityCardColItems = (
  {
    cardCollectionId: cardCollectionId,
    selectedFilters,
    enabled = true
  }: CardColItemsQueryParams
) => {
  const client = useClient();
  const { accessToken } = getAuthData();
  const config = useConfig();
  const limit = getLimitFromConfig(config);

  const queryKey = useMemo(
    () =>
      CCIKeys.infinityPages({
        accessToken,
        cardCollectionId: cardCollectionId,
        selectedFilters: selectedFilters ?? {},
        limit,
      }),
    [accessToken, cardCollectionId, selectedFilters, limit],
  );

  const query = useInfiniteQuery<ResponseMany<Card[]>>({
  queryKey,
  queryFn: ({ pageParam = 0 }) =>
    fetch({
      client,
      accessToken,
      page: pageParam,
      limit,
      cardCollectionId,
      filters: selectedFilters,
    }),
  getNextPageParam: getNextPageForInfinityPagination,
  enabled: Boolean(cardCollectionId) && enabled,
  cacheTime: 5 * MINUTE
});

const parsedData = React.useMemo(() => {
  return query.data?.pages && reducePaginatedPages(query.data.pages);
}, [query.data?.pages]);

  return { query, parsedData, isExist: Boolean(parsedData?.meta.pagination.total) };
};

