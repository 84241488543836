import React from 'react';
import { EqualityChecker, State, StateSelector } from 'zustand';
import shallow from 'zustand/shallow';

import { CardConfigByScreen } from '~typings/ScreenCardConfigs';

import { useReadyVitalStore, useVitalStore } from './GlobalVitalStore';
import { ReadyState } from './GlobalVitalStore';

/**
 * Тип помогает проксировать селектор до глобального состояния
 */
interface GlobalStateSliceHelper<GlobalSlice extends State> {
  (): GlobalSlice;
  <U>(selector: StateSelector<GlobalSlice, U>, equalityFn?: EqualityChecker<U>): U;
}

export const makeReadyStateSliceHook = <T extends keyof Omit<ReadyState, 'status'>>(
  storeKey: T,
): GlobalStateSliceHelper<ReadyState[T]> => {
  const defaultSelector = (store: ReadyState) => store[storeKey];

  return <U>(selector?: StateSelector<ReadyState[T], U>, equalityFn?: EqualityChecker<U>) => {
    if (!selector) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useReadyVitalStore(defaultSelector);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useReadyVitalStore((store) => selector(store[storeKey]), equalityFn);
  };
};

/**
 * Следующие хуки помогают получить нужные данные из глобального состояния
 * Хуки нужно использовать также как и используем хуки в zustand.
 *
 * Пример использования:
 * const config = useConfig(); // Получаем все конфигурации
 * const maxSliderSize = useConfig(c=>c.smartTV.maxSliderSize); // Получаем максимальную ширину слайдера
 *
 * Или чтобы сделать составной объект:
 * ``` const maxSliderSize = useConfig(c=>({
 *            maxSliderSize: c.smartTV.maxSliderSize,
 *            adsEnabled: c.smartTV.adsEnabled}), shallow);
 * ```
 *
 */

/**
 * Возвращает конфиг приложения
 */
export const useConfig = makeReadyStateSliceHook('config');
/**
 * Возвращает конфигурацию сети
 */
export const useNetwork = makeReadyStateSliceHook('network');
const useScreenCardConfigs = makeReadyStateSliceHook('screenCardConfigs');

/**
 * Возвращает конфиг для карточек, чтобы правильно отобразить карточки
 * Если конфиг не найден, то возвращает конфиг по умолчанию
 * Если не передан screen, то вернет `default` контекст
 */
export const useCardConfig = (screen?: keyof CardConfigByScreen) =>
  useScreenCardConfigs((c) => (screen && c[screen]) || c['default']);

/**
 * Возвращает клиент для работы с API
 */
export const useClient = () => {
  return useReadyVitalStore((store) => store.apiClient);
};

/**
 * Возвращает токен устройства
 */
export const useDeviceToken = () => {
  return useReadyVitalStore((store) => store.device.device_token);
};

/**
 * Хук загружает глобальные данные для всего приложения
 * Возвращает true, если глобальные данные загружены
 */
export const useVitalStoreLoader = () => {
  const { loadVitalData, status } = useVitalStore(
    (store) => ({
      loadVitalData: store.loadData,
      status: store.state.status,
    }),
    shallow,
  );

  React.useEffect(() => {
    loadVitalData();
  }, []);

  if (status === 'error') {
    throw new Error('Vital Data load error');
  }

  return status === 'ready';
};

/**
 * Хук заполняет глобальную стору фейковыми данными, для сценариев тестирования/краша
 * Возвращает true, если фековые данные загружены
 */
export const useVitalStoreFakeLoader = () => {
  const store = useVitalStore();

  React.useEffect(() => {
    store.fillWithFakeData();
  }, []);

  return store.state.status === 'ready';
};
