enum Marker {
  Featured = 'featured',
  Free = 'free',
  New = 'new',
  Popular = 'popular',
  Soon = 'soon',
  Subscription = 'subscription',
}

export default Marker;
