import React, { useLayoutEffect } from 'react';

import { getWindowAboveFocus } from '~lib/getWindowAboveFocus';

export type useOffsetChangerProps = {
  focusedIndex: number;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
  sliderWrapperRef: React.MutableRefObject<HTMLElement | null>;
  transitionGapDown: number;
  updates?: unknown[];
};

export type useTransitionStyleChangerProps = {
  animationDurationInMS: number | null;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
};

export const useTransitionStyleChanger = ({
                                            animationDurationInMS,
                                            sliderRef,
                                          }: useTransitionStyleChangerProps) => {
  useLayoutEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    if (!animationDurationInMS) {
      sliderRef.current.style.removeProperty('transitionDuration');
    }

    sliderRef.current.style.transitionDuration = `${ animationDurationInMS }ms`;
  }, [animationDurationInMS]);
};

export type useVirtualOffsetChangerProps = {
  focusedIndex: number;
  realFocusedIndex: number;
  sliderRef: React.MutableRefObject<HTMLElement | null>;
  sliderWrapperRef: React.MutableRefObject<HTMLElement | null>;
  transitionGapUp: number;
  transitionGapDown: number;
  updates?: unknown[];
};
/**
 * Хук добавляет offset к слайдеру в зависимости от индекса активного слайда
 */
export const useVirtualOffsetChanger = ({
                                          focusedIndex,
                                          sliderRef,
                                          sliderWrapperRef,
                                          realFocusedIndex,
                                          updates = [],
                                          transitionGapUp,
                                          transitionGapDown,
                                        }: useVirtualOffsetChangerProps) => {
  useLayoutEffect(() => {
    if (!sliderRef.current || !sliderWrapperRef.current) {
      return;
    }

    updateOffsetOfSlider({
      focusedIndex,
      slider: sliderRef.current,
      sliderWrapper: sliderWrapperRef.current,
      isVirtual: true,
      transitionGapUp,
      transitionGapDown,
    });
  }, [focusedIndex, realFocusedIndex, transitionGapDown, ...updates]);
};

type UpdateOffsetArgs = {
  focusedIndex: number;
  slider: HTMLElement;
  sliderWrapper: HTMLElement;
  isVirtual: boolean;
  transitionGapUp: number;
  transitionGapDown: number;
};

const updateOffsetOfSlider = ({
                                focusedIndex,
                                slider,
                                sliderWrapper,
                                isVirtual,
                                transitionGapUp,
                                transitionGapDown,
                              }: UpdateOffsetArgs) => {
  const childrenArray = Array.from(slider.children);
  const childRect = childrenArray?.[focusedIndex + (isVirtual ? 1 : 0)]?.getBoundingClientRect();

  if (!childRect) {
    return;
  }

  let {
    height: sliderWrapperHeight,
    bottom: sliderWrapperBottom,
    top: sliderWrapperTop,
  } = sliderWrapper.getBoundingClientRect();

  const { top: childTop, height: childHeight, bottom: childBottom } = childRect;
  const childBufferUpPx = transitionGapUp * childHeight
  const childBufferDownPx = transitionGapDown * childHeight;
  sliderWrapperTop = sliderWrapperTop + childBufferDownPx;
  sliderWrapperBottom = sliderWrapperBottom - childBufferDownPx;

  if (
    childBottom >= sliderWrapperTop &&
    childBottom <= sliderWrapperBottom &&
    childTop >= sliderWrapperTop &&
    childTop <= sliderWrapperBottom
  ) {
    // Элемент внутри наблюдаемой области, ничего не делаем
    return;
  }

  let { top: sliderTop, height: sliderHeight } = slider.getBoundingClientRect();

  if (childBottom < sliderWrapperBottom) {
    // следующий слайд сверху, значит нужно просто переместить слайдер до слайда
    const sliderOffsetToChild = Math.abs(sliderTop - childTop);
    const offset = Math.max(sliderOffsetToChild - childBufferUpPx, 0);
    slider.style.transform = `translate3d(0, ${ -offset }px, 0)`;
    slider.style.webkitTransform = `translate3d(0, ${ -offset }px, 0)`;
    return;
  }

  // следующий слайд снизу, двигаемся к нему, но он должен быть в нижнем крае
  let sliderOffsetToChild = Math.abs(sliderTop - childTop - childBufferDownPx);
  sliderOffsetToChild = Math.min(sliderOffsetToChild, sliderHeight - childHeight);
  const bufferOffsetBrake = sliderWrapperHeight - childHeight;
  const offset = Math.max(sliderOffsetToChild - bufferOffsetBrake, 0);
  slider.style.transform = `translate3d(0, ${ -offset }px, 0)`;
  slider.style.webkitTransform = `translate3d(0, ${ -offset }px, 0)`;
};

export type UseVirtualChildsParams = {
  childsCount: number;
  renderChild: (idx: number) => React.ReactNode;
  focusedIndex: number;
  maxInDOM: number;
  recalcStep: number;
};

/**
 * Утилитка помогает получить минимальный и максимальный индекс для отрисовки виртуальных элементов
 * buffer-size => количество элементов, которое должно буферироваться за view-port—ом
 * Это нужно, чтобы на старых телевизорах элементы были отрисованы
 * Если на каждом шаге влево/вправо удалять и вставлять элементы в DOM, то это будет лагать
 * Из-за этого мы должны буферировать несколько элементов слева и справа
 */
const getVirtualChildsBoundsWithBuffer = ({
                                            idx,
                                            recalcStep,
                                            totalCount,
                                            maxInDOM,
                                          }: {
  idx: number;
  recalcStep: number;
  maxInDOM: number;
  totalCount: number;
}) => {
  const { start, end } = getWindowAboveFocus(idx, recalcStep);
  const leftBound = Math.floor((maxInDOM - recalcStep) / 2);
  const rightBound = Math.ceil((maxInDOM - recalcStep) / 2);

  if((start - leftBound) < 0) {
    return [0, Math.min(maxInDOM, totalCount)];
  }

  if((end + rightBound) > totalCount) {
    return [Math.max(totalCount - maxInDOM, 0), totalCount];
  }

  return [start - leftBound, end + rightBound];
};

export const useVirtualChilds = ({
                                   focusedIndex,
                                   childsCount,
                                   renderChild,
                                   maxInDOM,
                                   recalcStep,
                                 }: UseVirtualChildsParams) => {
  const [minIdxToRender, maxIdxToRender] = getVirtualChildsBoundsWithBuffer({
    idx: focusedIndex,
    recalcStep: recalcStep,
    maxInDOM: maxInDOM,
    totalCount: childsCount,
  });


  const virtualChildren = React.useMemo(() => {
    return new Array(Math.max(maxIdxToRender - minIdxToRender, 0))
      .fill(0)
      .map((_, idx) => renderChild(minIdxToRender + idx));
  }, [renderChild, minIdxToRender, maxIdxToRender]);

const virtualFocus = focusedIndex - minIdxToRender;

  return {
    virtualChildren,
    virtualFocus,
    heightMultiplier: minIdxToRender,
  };
};
