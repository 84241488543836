import * as React from 'react';

import {
  AppContext,
  AppDispatchContext,
  Dispatch as AppDispatch,
  State as AppState,
} from '~components/Provider/App/component.helpers';

/**
 * Создает фейковый APP Provider, чтобы на ErrorScreen-е запустить компоненты, которые от этих контекстов зависимы
 */
export const FakeAppProvider = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  const appContextValue: AppState = React.useMemo(
    () =>
      ({
        isIPTVOn: false,
        isOnLine: true
      }) as AppState,
    [],
  );

  const appContextDispatch: AppDispatch = React.useCallback(() => {}, []);

  return (
    <AppContext.Provider value={ appContextValue }>
      <AppDispatchContext.Provider value={ appContextDispatch }>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};
