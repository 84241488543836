import { FBtn } from '../types';

export const KeyboardSymbolsLayout: FBtn[] = [
  // Первая линия
  {
    id: '1',
    coord: {
      x: 0,
      y: 0,
    },
    onLeft: 'skip',
    onUp: 'skip',
  },
  {
    id: '2',
    coord: {
      x: 1,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '3',
    coord: {
      x: 2,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '4',
    coord: {
      x: 3,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '5',
    coord: {
      x: 4,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '6',
    coord: {
      x: 5,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '7',
    coord: {
      x: 6,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '8',
    coord: {
      x: 7,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '9',
    coord: {
      x: 8,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '0',
    coord: {
      x: 9,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: ' ',
    action: 'space',
    coord: {
      x: 10,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: 'switch_to_letters',
    action: 'switch_to_letters',
    coord: {
      x: 11,
      y: 0,
    },
    onUp: 'skip',
    onDown: {
      x: 11,
      y: 2,
    },
  },
  {
    id: 'backspace',
    action: 'backspace',
    coord: {
      x: 12,
      y: 0,
    },
    onUp: 'skip',
    onRight: 'skip',
    onDown: {
      x: 12,
      y: 2,
    },
  },

  // Вторая линия
  {
    id: '!',
    coord: {
      x: 0,
      y: 1,
    },
    onLeft: 'skip',
  },
  {
    id: '"',
    coord: {
      x: 1,
      y: 1,
    },
  },
  {
    id: '#',
    coord: {
      x: 2,
      y: 1,
    },
  },
  {
    id: '$',
    coord: {
      x: 3,
      y: 1,
    },
  },
  {
    id: '%',
    coord: {
      x: 4,
      y: 1,
    },
  },
  {
    id: '&',
    coord: {
      x: 5,
      y: 1,
    },
  },
  {
    id: "'",
    coord: {
      x: 6,
      y: 1,
    },
  },
  {
    id: '(',
    coord: {
      x: 7,
      y: 1,
    },
  },
  {
    id: ')',
    coord: {
      x: 8,
      y: 1,
    },
  },
  {
    id: '*',
    coord: {
      x: 9,
      y: 1,
    },
  },
  {
    id: '@',
    coord: {
      x: 10,
      y: 1,
    },
    onRight: 'skip',
  },
  {
    id: 'switch_to_ru',
    action: 'switch_to_ru',
    alwaysDisabled: true,
    coord: {
      x: 11,
      y: 1,
    },
  },
  {
    id: 'switch_to_uppercase',
    action: 'switch_to_uppercase',
    coord: {
      x: 12,
      y: 1,
    },
    alwaysDisabled: true,
  },

  // Третья линия
  {
    id: '{',
    coord: {
      x: 0,
      y: 2,
    },
    onLeft: 'skip',
  },
  {
    id: '|',
    coord: {
      x: 1,
      y: 2,
    },
  },
  {
    id: '}',
    coord: {
      x: 2,
      y: 2,
    },
  },
  {
    id: '/',
    coord: {
      x: 3,
      y: 2,
    },
  },
  {
    id: ':',
    coord: {
      x: 4,
      y: 2,
    },
  },
  {
    id: ';',
    coord: {
      x: 5,
      y: 2,
    },
  },
  {
    id: '<',
    coord: {
      x: 6,
      y: 2,
    },
  },
  {
    id: '=',
    coord: {
      x: 7,
      y: 2,
    },
  },
  {
    id: '>',
    coord: {
      x: 8,
      y: 2,
    },
  },
  {
    id: '_',
    coord: {
      x: 9,
      y: 2,
    },
  },
  {
    id: '+',
    coord: {
      x: 10,
      y: 2,
    },
    onDown: ({ isHiddenBtnActive, setFocus, focuserEvent }) => {
      if (isHiddenBtnActive) {
        setFocus({
          x: 10,
          y: 3,
        });
        focuserEvent.stop();
        focuserEvent.stopNativeEvent();
        return;
      }
    },
  },
  {
    id: '.ru',
    coord: {
      x: 11,
      y: 2,
    },
    onUp: {
      x: 11,
      y: 0,
    },
    onDown: ({isConfirmBtnActive, focuserEvent, setFocus})=> {
      if(isConfirmBtnActive){
        setFocus({
          x: 11,
          y: 3,
        });
        focuserEvent.stop();
        focuserEvent.stopNativeEvent();
      }
    }
  },
  {
    id: '.com',
    coord: {
      x: 12,
      y: 2,
    },
    onRight: 'skip',
    onUp: {
      x: 12,
      y: 0,
    },
    onDown: ({isConfirmBtnActive, focuserEvent, setFocus})=> {
      if(isConfirmBtnActive){
        setFocus({
          x: 11,
          y: 3,
        });
        focuserEvent.stop();
        focuserEvent.stopNativeEvent();
      }
    }
  },

  // Четвертая линия
  {
    id: '[',
    coord: {
      x: 0,
      y: 3,
    },
    onLeft: 'skip',
    onDown: 'skip',
  },
  {
    id: '\\',
    coord: {
      x: 1,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: ']',
    coord: {
      x: 2,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: '^',
    coord: {
      x: 3,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: '?',
    coord: {
      x: 4,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: '`',
    coord: {
      x: 5,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: '~',
    coord: {
      x: 6,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: ',',
    coord: {
      x: 7,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: '.',
    coord: {
      x: 8,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: '-',
    coord: {
      x: 9,
      y: 3,
    },
    onDown: 'skip',
    onRight: ({ isHiddenBtnActive, isConfirmBtnActive, setFocus, focuserEvent }) => {
      const stopFocuser = () => {
        focuserEvent.stop();
        focuserEvent.stopNativeEvent();
      };

      if (isHiddenBtnActive) {
        setFocus({
          x: 10,
          y: 3,
        });
        stopFocuser();
        return;
      }

      if (isConfirmBtnActive) {
        setFocus({
          x: 11,
          y: 3,
        });
        stopFocuser();
      }
    },
  },
  {
    id: 'hide/show',
    action: 'hide/show',
    coord: {
      x: 10,
      y: 3,
    },
    onDown: 'skip',
    onRight: ({isConfirmBtnActive, focuserEvent, setFocus})=> {
      if(isConfirmBtnActive){
        setFocus({
          x: 11,
          y: 3,
        });
        focuserEvent.stop();
        focuserEvent.stopNativeEvent();
      }
    }
  },
  {
    id: 'ok_button',
    action: 'confirm',
    coord: {
      x: 11,
      y: 3,
    },
    onRight: 'skip',
    onDown: 'skip',
    onLeft: ({ isHiddenBtnActive, setFocus, focuserEvent }) => {
      focuserEvent.stop();
      focuserEvent.stopNativeEvent();
      if (isHiddenBtnActive) {
        setFocus({
          x: 10,
          y: 3,
        });
        return;
      }
      setFocus({
        x: 9,
        y: 3,
      });
    },
    isLarge: true,
  },
];


Object.freeze(KeyboardSymbolsLayout);
