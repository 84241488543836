import { useMutation } from 'react-query';

import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';
import ApiError from '~typings/ApiError';
import CredentialType from '~typings/CredentialType';

export type RegistrationResult = Readonly<{
  errors?: ApiError[];
  data?: {
    id: string;
    object: CredentialType;
    username: string;
    confirmed: boolean;
  };
  meta: any;
}>;

const fetch = async (
  client: ApiClient,
  username: string,
  password: string,
  allowNotifications: boolean,
): Promise<RegistrationResult> => client.post(
  '/v1/users.json',
  {
    username,
    password,
    allow_notifications: allowNotifications,
  },
);

export const useRegistrationMutation = () => {
  const client = useClient();

  return useMutation((
    {
      username,
      password,
      allowNotifications
    }: {
      username: string,
      password: string,
      allowNotifications: boolean
    }
  ) => (
    fetch(client, username, password, allowNotifications)
  ));
}

export default useRegistrationMutation;
