import * as cn from 'classnames';
import * as React from 'react';
import { animated } from 'react-spring/renderprops';

import { image } from './styles.module.css';


type ImageProps = {
  src: string;
  style?: React.CSSProperties;
  className?: string;
};

const BaseImage = ({ src, style, className = '' }: ImageProps) => {
  const styles: React.CSSProperties = React.useMemo(() => ({
    ...style,
    backgroundImage: `url(${src})`,
  }), [src, style]);

  return (
    <animated.div
      className={ cn(image, className) }
      style={ styles }
    />
  );
};


export default React.memo(BaseImage);
