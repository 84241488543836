// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Help--XypHj {\n  /* 10px */\n  padding-top: 0.78125vw;\n\n  /* 579px */\n  height: 45.234375vw;\n\n  /* 728px */\n  width: 56.875vw;\n  overflow: hidden;\n}\n\n\n.slider--w9kOo {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: start;\n  -webkit-align-items: flex-start;\n     -moz-box-align: start;\n          align-items: flex-start;\n}\n\n.sliderWrapper--iqaBr {\n  /* 474px */\n  height: 37.03125vw;\n}\n\n.sectionTitle--V9tfD {\n  /* 30px */\n  margin-bottom: 2.34375vw;\n\n  /* 50px */\n  margin-top: 3.90625vw;\n}\n\n.sectionTitle--V9tfD:first-child {\n  margin-top: 0;\n}\n\n\n.questionButton--AcIMZ + .questionButton--AcIMZ {\n  margin-top: 1.953125vw;\n}\n\n.spinner--gPdct {\n  width: 100vw;\n  height: 100vh;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/Help/Help.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;;EAEtB,UAAU;EACV,mBAAmB;;EAEnB,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB;;;AAGA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,wBAAwB;;EAExB,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;;AAGA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".Help {\n  /* 10px */\n  padding-top: 0.78125vw;\n\n  /* 579px */\n  height: 45.234375vw;\n\n  /* 728px */\n  width: 56.875vw;\n  overflow: hidden;\n}\n\n\n.slider {\n  display: flex;\n  align-items: flex-start;\n}\n\n.sliderWrapper {\n  /* 474px */\n  height: 37.03125vw;\n}\n\n.sectionTitle {\n  /* 30px */\n  margin-bottom: 2.34375vw;\n\n  /* 50px */\n  margin-top: 3.90625vw;\n}\n\n.sectionTitle:first-child {\n  margin-top: 0;\n}\n\n\n.questionButton + .questionButton {\n  margin-top: 1.953125vw;\n}\n\n.spinner {\n  width: 100vw;\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
export var Help = "Help--XypHj";
export var slider = "slider--w9kOo";
export var sliderWrapper = "sliderWrapper--iqaBr";
export var sectionTitle = "sectionTitle--V9tfD";
export var questionButton = "questionButton--AcIMZ";
export var spinner = "spinner--gPdct";
export default ___CSS_LOADER_EXPORT___;
