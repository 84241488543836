import { gw_00,gw_03 } from 'customization-project-name/colors.js'
import React from "react";

/* eslint-disable max-len */

interface Props {
  className?: string;
  color?: string;
  isDisabled?: boolean;
}

export function Search(props: Props) {
  
  return (
    <svg 
      viewBox="0 0 30 30" 
      fill='none'
      xmlns="http://www.w3.org/2000/svg"
      className={ props.className }
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M5 12.5C5 8.35786 8.35786 5 12.5 5C16.6421 5 20 8.35786 20 12.5C20 16.6421 16.6421 20 12.5 20C8.35786 20 5 16.6421 5 12.5ZM12.5 3C7.25329 3 3 7.25329 3 12.5C3 17.7467 7.25329 22 12.5 22C14.7631 22 16.8415 21.2086 18.4733 19.8875L25.2929 26.7071C25.6834 27.0976 26.3166 27.0976 26.7071 26.7071C27.0976 26.3166 27.0976 25.6834 26.7071 25.2929L19.8875 18.4733C21.2086 16.8415 22 14.7631 22 12.5C22 7.25329 17.7467 3 12.5 3Z" 
        fill={ props.isDisabled ? gw_03 : gw_00 }
      />
    </svg>
  )
}