import focuser, { useFocuserContext } from '@focuser';
import { FocuserClickEventDetail, FocuserKeyEventDetail } from '@focuser/events';
import { simulateFocusEventOnNode } from '@focuser/KeyDownHandler/utils';
import { throttle } from 'lodash';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import NavigationDirection from '~typings/NavigationDirection';

import { navigationHandler } from '../FButtonNavHandler';
import { getKeyboardButtonsByLayout, getKeyboardXYMapMemoized } from '../KeyboardLayouts';
import { NavigableKeyboardButton } from '../NavigableKeyboardButton/NavigableKeyboardButton';
import { FBtn, KeyboardLayout } from '../types';
import * as styles from './NavigableKeyboard.module.css';
import { HideBtnState, NaviagableKeyboardAction } from './types';
import { checkCanButtonFocus, handleFBtnClick, useFocuserPointerDirections, useNativeKeyboardHandler } from './utils';

export interface Props {
  /**
   * Показывать кнопку "OK" как активную?
   *
   * По дефолту `true`
   */
  isConfirmBtnActive?: boolean;
  /**
   * Состояние "глазика"
   * По дефолту `unnecessary`, то есть будет задизейбленно
   *
   * hidden - означает что текст в input-е скрыт, значит глаз будет открыт
   *
   * shown - означает что текст в input-е показан, значит глаз будет закрыт
   */
  hideBtnState?: HideBtnState;
  /**
   * Начальная раскладка клавиатуры
   * numbers - это отдельная раскладка, с нее никуда не сможет пользователь переключиться
   *
   * остальные раскладки могут переключяться пользователем
   */
  initialKeyboardLayout: KeyboardLayout;
  /**
   * Коллбэк, когда пользователь нажал на кнопку с текстом
   * string - это текст на кнопке, может быть и как одним символом, так и несколькими
   *
   * например `.com`, `.ru`, `A`
   */
  onInput: (string: string) => void;
  /**
   * Коллбэк, когда пользователь нажал на кнопку с действием
   *
   * `backspace` - нажал на удаление
   *
   * `hide` - нажал на закрытый глазик, нужно скрыть текст
   *
   * `show` - нажал на открытый глазик, нужно показать текст
   *
   * `confirm` - нажал на кнопку "OK"
   */
  onAction: (action: NaviagableKeyboardAction) => void;
}

const BUTTONS_COUNT_PER_LINE = 13;
const LINES_COUNT = 4;

/**
 * Клавиатура с навигацией. Основана на Focuser-е (можно обернуть в focusableBlock)
 *
 * Компонент сам по себе не легкий, в нем много кнопок, для этого обернут в мемоизацию
 *
 * Нужно внимательно передавать пропсы, мемоизировать коллбэки
 */
export const NavigableKeyboard: React.FC<Props> = React.memo(({
  initialKeyboardLayout,
  isConfirmBtnActive = true,
  hideBtnState = 'unnecessary',
  onInput,
  onAction,
}) => {
  const [previousLayout, setPreviousLayout] = useState<KeyboardLayout | null>(null);
  const [currentLayout, setCurrentLayout] = useState(() => initialKeyboardLayout);
  const focuserContext = useFocuserContext();
  const focuserRef = useRef<HTMLDivElement>(null);

  const keyboardLines = useMemo(
    () =>{
      const keyboardButtons = getKeyboardButtonsByLayout(currentLayout)
      return new Array(LINES_COUNT)
        .fill(0)
        .map((_, i) =>
          keyboardButtons.slice(BUTTONS_COUNT_PER_LINE * i, BUTTONS_COUNT_PER_LINE * (i + 1)),
        );
      },
    [currentLayout],
  );

  const buttonsXYMap = getKeyboardXYMapMemoized(currentLayout);
  const [focusedButton, setFocusedBtn] = useState(() => buttonsXYMap[0][0]);
  const isHiddenBtnActive = hideBtnState !== 'unnecessary';

  const switchKeyboardLayout = (newLayout: KeyboardLayout) => {
    const nextXYMap = getKeyboardXYMapMemoized(newLayout);
    const currentBtnCoord = focusedButton.coord;
    unstable_batchedUpdates(() => {
      setPreviousLayout(currentLayout);
      setCurrentLayout(newLayout);
      setFocusedBtn(nextXYMap[currentBtnCoord.x][currentBtnCoord.y]);
    });
  };

  const focuserKeyDownHandler = (
    direction: NavigationDirection,
    focuserEvent: FocuserKeyEventDetail,
  ) => {
    navigationHandler({
      direction,
      focusedBtn: focusedButton,
      setFocus: (coords) =>
        setFocusedBtn({
          ...buttonsXYMap[coords.x][coords.y],
          onReturnUp: direction === NavigationDirection.Down ? focusedButton.coord : undefined,
        }),
      isConfirmBtnActive,
      isHiddenBtnActive,
      focuserEvent,
    });
  };

  useNativeKeyboardHandler({
    isFocused: focuserContext.isFocused,
    onInput,
    switchKeyboardLayout,
    currentLayout,
    setFocusedBtn,
  });



  const buttonClickHandler = (clickEvent: FocuserClickEventDetail) => {
    if (clickEvent.isPhysicalMouseClick) {
      // Клик был с фокусера, но с мышки,
      // Такой клик обрабатывается отдельно
      return;
    }
    handleFBtnClick({
      onInput,
      onAction,
      hideBtnState,
      switchKeyboardLayout,
      currentLayout,
      previousLayout,
      focusedButton,
    });
    clickEvent.stop();
    clickEvent.stopNativeEvent();
  };


  const handleMouseClick = (btn: FBtn)=>{
    if(!checkCanButtonFocus({
      btn: btn,
      isConfirmBtnActive,
      hideBtnState,
    })){
      return;
    }
    handleFBtnClick({
      onInput,
      onAction,
      hideBtnState,
      switchKeyboardLayout,
      currentLayout,
      previousLayout,
      focusedButton: btn,
    });
  }

  const handleMouseClickRef = useRef(handleMouseClick);
  handleMouseClickRef.current = handleMouseClick;
  const handleMouseClickMemo = useCallback((btn: FBtn)=>{
    handleMouseClickRef.current(btn);
  }, []);



  const handleMouseEnter = (btn: FBtn, ev: Event)=>{
    if(!focuserContext.isForceFocusEnabled){
      return;
    }
    const canFocusOnButton =  checkCanButtonFocus({
      btn: btn,
      isConfirmBtnActive,
      hideBtnState,
    });

    if (!canFocusOnButton) {
      return
    }

    setFocusedBtn(btn);
    if(!focuserContext.isFocused && focuserRef.current){
      simulateFocusEventOnNode(focuserRef.current, ev);
    }
  };

  const handleMouseEnterRef = useRef(handleMouseEnter);
  handleMouseEnterRef.current = handleMouseEnter;
  const handleMouseEnterMemo = useCallback(throttle((btn: FBtn, ev: Event)=>{
    handleMouseEnterRef.current(btn, ev);
  }, 50), []);

  useLayoutEffect(()=>{
    if(!focuserContext.isFocused){
      handleMouseEnterMemo.cancel();
    }
  }, [focuserContext.isFocused]);
  // Тут хаки закончены


  const { pointerDirections } = useFocuserPointerDirections({
    focusedButton,
    isConfirmBtnActive,
    isHiddenBtnActive,
  });

  return (
    <focuser.FocusableBlock
      isFocused
      className={ styles.keyboard }
      onKeyDown={ (ev) => focuserKeyDownHandler(NavigationDirection.Down, ev) }
      onKeyLeft={ (ev) => focuserKeyDownHandler(NavigationDirection.Left, ev) }
      onKeyRight={ (ev) => focuserKeyDownHandler(NavigationDirection.Right, ev) }
      onKeyUp={ (ev) => focuserKeyDownHandler(NavigationDirection.Up, ev) }
      onClick={ buttonClickHandler }
      isPointerDownAvailable={ pointerDirections['Down'] }
      isPointerUpAvailable={ pointerDirections['Up'] }
      isPointerLeftAvailable={ pointerDirections['Left'] }
      isPointerRightAvailable={ pointerDirections['Right'] }
      isLastBlock
      forwardRef={ focuserRef }
      noNeedForceFocus
    >
      {keyboardLines.map((btns, i) => {
        return (
          <div
            className={ styles.keyboardLine }
            key={ i }
          >
            {btns
              .map((btn: FBtn) => (
                <NavigableKeyboardButton
                  key={ btn.id }
                  btn={ btn }
                  className={ styles.button }
                  isFocused={ focuserContext.isFocused && focusedButton.id === btn.id }
                  onMouseEnter={ handleMouseEnterMemo }
                  onMouseClick={ handleMouseClickMemo }
                  // Для мемоизции пропсы так передаются
                  hideBtnState={ btn.action === 'hide/show' ? hideBtnState : 'unnecessary' }
                  isConfirmBtnActive={ btn.action === 'confirm' && isConfirmBtnActive }
                  />
              ))}
          </div>
        );
      })}
    </focuser.FocusableBlock>
  );
});

NavigableKeyboard.displayName = 'NavigableKeyboard';
