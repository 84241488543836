import * as React from 'react';


type Props = {
  className?: string;
};

const NewsIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg className={ className } viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g stroke="currentColor" strokeWidth="3">
        <rect x="19" y="20" width="33" height="29" />
        <line x1="24" y1="27" x2="47" y2="27" />
        <line x1="24" y1="35" x2="47" y2="35" />
        <line x1="24" y1="43" x2="41" y2="43" />
      </g>
    </svg>
  );
};


export default NewsIcon;
