import cn from 'classnames';
import React from 'react';

import ErrorIcon from '~ui/ErrorText/icon';

import { Typography, TypographyVariant } from '../Typography';
import * as styles from './WarningTextlineBlock.module.css';

export interface Props {
  className?: string;
  errorText?: string;
  /**
   * Какой вариант текста использовать, по умолчанию 'subtitle1_medium'
   */
  typographyVariant?: TypographyVariant;

  /**
   * Переносить текст, если не хватает места
   * По умолчанию true
   */
  wrapText?: boolean;

  /**
   * Дополнительный класс иконки
   */
  iconClassName?: string;
}

export const WarningTextlineBlock = ({
  className,
  errorText,
  typographyVariant,
  wrapText = true,
  iconClassName
}: Props) => {
  return (
    <div className={ cn(styles.block, className) }>
      <ErrorIcon className={ cn(styles.icon, iconClassName) } />
      <Typography
        variant={ typographyVariant || 'subtitle1_medium' }
        className={ cn(styles.text, { [styles.textWrap]: wrapText }) }
      >
        {errorText}
      </Typography>
    </div>
  );
};
