import * as React from 'react';

import useNavigationByKeys from '~hooks/useNavigation';
import usePrevious from '~hooks/usePrevious';
import NavigationDirection from '~typings/NavigationDirection';


type Props = Readonly<{
  isFocused: boolean;
  initialFocusIndex: number;
  total: number;
  throttleTimeInMS?: number;
  onLeave: (direction: NavigationDirection) => void;
  onIndexChange?: (index: number) => void;
}>;

const switchSliderAnimationMS = 10;


const EPGPageNavigation: React.FC<Props> = (props: Props) => {
  const timeout = React.useRef<any | null>(null);
  const [isNavigationInProcess, setIsNavigationInProcess] = React.useState(false);
  const [index, setIndex] = React.useState<number>(props.initialFocusIndex || 0);
  const prevIsFocused = usePrevious<boolean>(props.isFocused);

  const clear = (): void => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };
  const handleNavigationInProgress = (ms: number): void => {
    setIsNavigationInProcess(true);
    timeout.current = setTimeout(setIsNavigationInProcess, ms, false);
  };

  const handleUpAction = (): void => {
    handleNavigationInProgress(switchSliderAnimationMS);

    setIndex(Math.max((index - 1), 0));
  };
  const handleDownAction = (): void => {
    handleNavigationInProgress(switchSliderAnimationMS);

    setIndex(Math.min((index + 1), (props.total - 1)));
  };

  const handleKeyNavigate = (direction): void => {
    switch (direction) {
      case NavigationDirection.Up: {
        handleUpAction();
        break;
      }
      case NavigationDirection.Right: {
        props.onLeave(direction);
        break;
      }
      case NavigationDirection.Down: {
        handleDownAction();
        break;
      }
      case NavigationDirection.Left: {
        props.onLeave(direction);
        break;
      }
    }
  };

  useNavigationByKeys({
    isMounted: (props.isFocused && !isNavigationInProcess),
    onNavigation: handleKeyNavigate,
  }, [props.onLeave]);

  React.useEffect(() => clear, []);

  React.useEffect(() => {
    if (prevIsFocused && props.isFocused && props.onIndexChange && index >= 0) {
      props.onIndexChange(index);
    }
  }, [index]);

  React.useEffect(() => {
    if (props.isFocused) {
      setIndex(props.initialFocusIndex);
    } else if (index !== -1) {
      setIndex(-1);
    }
  }, [props.isFocused]);


  return null;
};


export default EPGPageNavigation;
