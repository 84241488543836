// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--fw9Sa {\n  position: relative;\n  margin-top: 2.8125vw;\n  margin-left: 6.875000000000001vw;\n}\n\n.EPGEventDetail--ov7x4 {\n  top: 3.4375000000000004vw;\n  left: -6.484375000000001vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/ScenePlayer/Player/controls/Popup/ChannelsList/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B","sourcesContent":[".wrapper {\n  position: relative;\n  margin-top: 2.8125vw;\n  margin-left: 6.875000000000001vw;\n}\n\n.EPGEventDetail {\n  top: 3.4375000000000004vw;\n  left: -6.484375000000001vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = "wrapper--fw9Sa";
export var EPGEventDetail = "EPGEventDetail--ov7x4";
export default ___CSS_LOADER_EXPORT___;
