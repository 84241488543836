const fontGlobal = 'Roboto';
const fontTitles = 'Roboto';

const screenOffsetTopInVW = 2.734375;
const screenOffsetBottomInVW = 1.5625;
const screenOffsetHorizontalInVW = 6.875000000000001;
const sceneWidthInVW = 86.25;
const sceneHeightInVW = 86.25;

// Slider
const spaceBetweenSlidesInVW = 1.875;
const switchSliderAnimationMS = 500;
const changeSliderIndexAnimationMS = 240;

// Tiled view
const tiledViewSpaceBetweenRowsInVW = 8.984375;
const tiledViewPosterSpaceBetweenRowsInVW = 10.46875;

// ProfileSwitcher
const profileItemWidthInVW = 17.1875;
const changeProfilesSliderIndexAnimationMS = 300;

// Block Dimension
const blockHeightBanner21x9InVW = 42.8;
const blockHeightBanner8x1InVW = 20;
const blockHeightBannerFullScreenInVW = 18.75;
const blockHeightPosterInVW = 27.34375;
const blockHeightBannerInVW = 20.703125;
const blockHeightFilterInVW = 5.46875;
const blockHeightProgramLineInVW = 13.7;
const blockHeightCardCollectionInVh = 100;

// Image Dimensions
const imageDefaultDimensionWidthInVW = 20.15625;
const imageDefaultDimensionHeightInVW = 11.40625;
const imageLogoWidthInVW = 13.203124999999998;
const imageLogoHeightInVW = 5.15625;
const imagePosterWidthInVW = 12.812499999999998;
const imagePosterHeightInVW = 19.21875;
const imageBannerWidthInVW = imageDefaultDimensionWidthInVW;
const imageBannerHeightInVW = imageDefaultDimensionHeightInVW;
const imageSquareWidthInVW = 12.812499999999998;

// MediaItem Dimensions
const bannerFullScreenWidthInVW = 100;
const mediaItemDefaultDimensionWidthInVW = 20.157;
const mediaItemDefaultDimensionHeightInVW = 11.407;
const mediaItemBannerWidthInVW = mediaItemDefaultDimensionWidthInVW;
const mediaItemBannerHeightInVW = mediaItemDefaultDimensionHeightInVW;
const mediaItemPosterWidthInVW = imagePosterWidthInVW;
const mediaItemPosterHeightInVW = imagePosterHeightInVW;
const mediaItemBorderRadiusInVW = 0.3125;

const EPGEventHeightInVW = 3.90625;
const EPGEventVerticalPaddingInVW = 1.015625;

// Easing
const easeIn = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
const easeOut = 'cubic-bezier(0.49, 0.57, 0.85, 0.57)';

// fonts
/** 60px */
const fontH2_vw = '4.6875vw';
/** 40px */
const fontH3_vw = '3.125vw';
/** 24px */
const fontH5_vw = '1.875vw';
/** 22px */
const fontH6_vw = '1.7187500000000002vw';
/** 20px */
const fontSub1_vw = '1.5625vw';
/** 18px */
const fontSub2_vw = '1.40625vw';
/** 16px */
const fontBody_vw = '1.25vw';
const fontOverLine_vw = '0.9375vw'; // 12px
const fontHardCoded1 = '5.46875vw'; // 70px на карточке актера когда нет фото

// Максимальные размеры карточек в VW/VH для 16:9 (данный брались для 1280х720)
const horizontalCardWidthVw = 22.03125;
const horizontalCardHeightVh = 30.416666666666664;
const verticalCardWidthVw = 14.6875;
const verticalCardHeightVh = 45.69444444444444;
const squareCardWidthVw = 14.6875;
const squareCardHeightVh = 34.02777777777778;

// расстояние между строк
const gridGapBetweenLines = 5.555555555555555;

// Картинки для карточек в VW/VH для 16:9 (данный брались для 1280х720)
const horizontalImageWidthVw = 22.03125;
const horizontalImageHeightVh = 21.944444444444443;

const verticalImageWidthVw = 13.90625;
const verticalImageHeightVh = 36.94444444444444;

const squareImageWidthVw = 14.6875;
const squareImageWidthVh = 26.111111111111114;

// круглые и квадратные изображение внутри горизонтальной карточки в VW/VH для 16:9 (данный брались для 1280х720)
const squareImageInHorizontalWidthVw = 9.21875;
const squareImageInHorizontalHeightVh = 16.38888888888889;

const circleImageInHorizontalWidthVw = 9.84375;
const circleImageInHorizontalHeightVh = 17.5;

const horizontalCardHeightTextVW = 17.109375;
const horizontalCardHeightNoTextVW = 12.96875;
const squareCardHeightTextVW = 19.140625;
const squareCardHeightNoTextVW = 14.6875;
const verticalCardHeightTextVW = 25.703125;
const verticalCardHeightNoTextVW = 21.5625;

module.exports = {
  fontGlobal,
  fontTitles,

  screenOffsetTopInVW,
  screenOffsetBottomInVW,
  screenOffsetHorizontalInVW,
  sceneWidthInVW,
  sceneHeightInVW,

  blockHeightBanner21x9InVW,
  blockHeightBanner8x1InVW,
  blockHeightBannerFullScreenInVW,
  blockHeightPosterInVW,
  blockHeightBannerInVW,

  imageDefaultDimensionWidthInVW,
  imageDefaultDimensionHeightInVW,
  imageBannerWidthInVW,
  imageBannerHeightInVW,
  imageSquareWidthInVW,
  blockHeightFilterInVW,
  blockHeightProgramLineInVW,
  blockHeightCardCollectionInVh,
  imageLogoWidthInVW,
  imageLogoHeightInVW,
  imagePosterWidthInVW,
  imagePosterHeightInVW,

  bannerFullScreenWidthInVW,
  mediaItemDefaultDimensionWidthInVW,
  mediaItemDefaultDimensionHeightInVW,
  mediaItemBannerWidthInVW,
  mediaItemBannerHeightInVW,
  mediaItemPosterWidthInVW,
  mediaItemPosterHeightInVW,
  mediaItemBorderRadiusInVW,

  EPGEventHeightInVW,
  EPGEventVerticalPaddingInVW,

  spaceBetweenSlidesInVW,
  switchSliderAnimationMS,
  changeSliderIndexAnimationMS,

  tiledViewSpaceBetweenRowsInVW,
  tiledViewPosterSpaceBetweenRowsInVW,

  profileItemWidthInVW,
  changeProfilesSliderIndexAnimationMS,

  //easing
  easeIn,
  easeOut,

  //fonts
  fontH2_vw,
  fontH3_vw,
  fontH5_vw,
  fontH6_vw,
  fontSub1_vw,
  fontSub2_vw,
  fontBody_vw,
  fontOverLine_vw,

  // cards
  horizontalCardWidthVw,
  horizontalCardHeightVh,
  verticalCardWidthVw,
  verticalCardHeightVh,
  squareCardWidthVw,
  squareCardHeightVh,

  // cards images
  horizontalImageWidthVw,
  horizontalImageHeightVh,
  verticalImageWidthVw,
  verticalImageHeightVh,
  squareImageWidthVw,
  squareImageWidthVh,
  squareImageInHorizontalWidthVw,
  squareImageInHorizontalHeightVh,
  circleImageInHorizontalWidthVw,
  circleImageInHorizontalHeightVh,
  gridGapBetweenLines,

  horizontalCardHeightTextVW,
  horizontalCardHeightNoTextVW,
  squareCardHeightTextVW,
  squareCardHeightNoTextVW,
  verticalCardHeightTextVW,
  verticalCardHeightNoTextVW,
};
