import { useMutation } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import { resetDeviceItems } from '~hooks/useGetDevices';
import ApiClient from '~lib/ApiClient';


interface Parameters {
  access_token: string | null;
  name?: string;
}


const changeNameDevice = async (
  client: ApiClient, 
  accessToken: string | null, 
  deviceId: string, 
  newNameDevice: string
  ) => {
  const url = `/v1/user/devices/${deviceId}`;

  const parameters: Parameters = {
    access_token: accessToken,
    name: newNameDevice,
  } 
  
  return await client.patch(url, parameters);
}

export const usePatchDevice = () => {
  const client = useClient();
  const { accessToken } = getAuthData();

  return useMutation(({deviceId, deviceName}: { deviceId: string, deviceName: string }) => (
    changeNameDevice(client, accessToken, deviceId, deviceName)
  ), {
    onSettled: () => {
      resetDeviceItems();
    }
  }
  )
}

const deleteDevice = async (
  client: ApiClient, 
  accessToken: string | null, 
  deviceId: string, 
  ) => {
  const url = `/v1/user/devices/${deviceId}`;
  
  return await client.delete(url, {access_token: accessToken, deviceId});
  
}

export const useDeleteDevice = () => {
  const client = useClient();
  const { accessToken } = getAuthData();

  return useMutation(({deviceId}: { deviceId: string }) => (
    deleteDevice(client, accessToken, deviceId)
  ), {
    onSettled: () => {
      resetDeviceItems();
    }
  }
  )
}
