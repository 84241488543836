import cn from 'classnames';
import React from 'react';

import { CardLayer2 } from '~components/Card/layer2';
import { DATA_ROW_IDX } from '~components/CardCollectionGrid/CardCollectionGrid.utils';
import CardType from '~typings/Card';
import { CardConfig } from '~typings/ScreenCardConfigs';

import * as styles from './CardCollectionRow.module.css';

type RowProps = {
  cards: CardType[];
  cardConfig: CardConfig;
  focusedIdx: number | null;
  rowIdx: number;
  className?: string;
  itemHeightVW: number;
};

const CardCollectionRow$: React.FC<RowProps> = ({
  cards,
  cardConfig,
  focusedIdx,
  rowIdx,
  className,
  itemHeightVW,
}) => {
  return (
    <div
      className={ cn(styles.cardCollectionRow, className) }
      style={ {
        height: `${itemHeightVW}vw`,
      } }
      { ...{ [DATA_ROW_IDX]: rowIdx } }
    >
      {cards.map((card, idx) => (
        <CardLayer2
          key={ card.id }
          card={ card }
          cardConfig={ cardConfig }
          isFocused={ idx === focusedIdx }
        />
      ))}
    </div>
  );
};

export const CardCollectionRow = React.memo(CardCollectionRow$, (prev, next) => {
  return (
    prev.focusedIdx === next.focusedIdx &&
    prev.cards.length === next.cards.length &&
    prev.cards.every((prevCard, idx) => prevCard.id === next.cards[idx].id) &&
    prev.cardConfig === next.cardConfig
  );
});
