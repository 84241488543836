import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { CONGRATULATION, I_SEE, YOUR_PROMO_CODE_IS_ACTIVATED } from '~lib/localizator/src/dictionaries';
import Button from '~ui/button';

import * as styles from '../styles.module.css';


type Props = Readonly<{
  onSuccess: () => void;
  intl: InjectedIntl;
}>;

const PromocodeSuccessPage: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  
  const navHandler = () => {
    props.onSuccess();
    history.replace('/');
  };

  return (
    <div className={ styles.successStepWrap }>
      <div className={ styles.successContent }>
        <div className={ styles.successPageText }>
          { props.intl.formatMessage({ id: CONGRATULATION }) }
        </div>
        <div className={ styles.successPageText }>
          { props.intl.formatMessage({ id: YOUR_PROMO_CODE_IS_ACTIVATED }) }
        </div>

        <Button
          isFocused={ true }
          className={ styles.successButton }
          onClick={ () => navHandler() }
        >
          { props.intl.formatMessage({ id: I_SEE }) }
        </Button>
      </div>
    </div>
  )
};

export default injectIntl(PromocodeSuccessPage);
