import * as cn from 'classnames';
import * as React from 'react';

import { usePlayerState } from '~components/Provider/Player';
import EMPTY_ARRAY from "~lib/constants/emptyArray";

import * as styles from './styles.module.css';


interface Props {
  currentTimeInSec: number;
  isVisible: boolean;
}

const getSubTitleTracks = (subTitle, currentTime) => {
  return subTitle ? subTitle.filter(
    ({ start, end }) => (
      (start <= currentTime) &&
      (end >= currentTime)
    ),
  ) : EMPTY_ARRAY;
};


const SubTitle = ({ currentTimeInSec, isVisible }: Props) => {
  const { subTitleTrack } = usePlayerState();

  const subTitlesArray = React.useMemo(
    () => getSubTitleTracks(subTitleTrack?.subTitle, currentTimeInSec),
    [subTitleTrack, currentTimeInSec],
  );

  return React.useMemo(() => {
    if (subTitlesArray.length === 0) { return null; }

    return (
      <div
        key={ 'subtitleWrapper' }
        className={ cn(styles.subtitleWrapper, {
          [styles.isVisible]: isVisible,
        }) }
      >
        {
          subTitlesArray.map((st) => {
            if (st?.text && st.text.length === 0) { return null; }

            return (
              <div
                key={ st.text }
              >
                <div
                  className={ cn(styles.subtitleText) }
                  dangerouslySetInnerHTML={ { __html: st.text } }
                />
              </div>
            );
          })
        }
      </div>
    );
  }, [
    subTitlesArray.map((st) => st?.text),
  ]);
};


export default SubTitle;
