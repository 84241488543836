import { useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient,useConfig } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import Episode from '~typings/Episode';
import ItemObject from '~typings/ItemObject';
import ResponseMany from '~typings/ResponseMany';

import { fetchSeriesWithSeasonEpisodes, getMediaItemKey } from '../useMediaItem';
import { getLimitFromConfig } from '../utils';



const PREFIX_OF_A_COMPOSITE_KEY = 'last_viewed_episode';

type EpisodesResponse = ResponseMany<Episode[]>;

const lastViewedEpisodesKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,
  withOneSeriesId: (accessToken: string | null, seriesID?: string) => [
    lastViewedEpisodesKeys.PREFIX,
    accessToken,
    seriesID || 'empty-series-id',
  ],
};

const fetch = async (
  client: ApiClient,
  limit: number,
  accessToken: string | null,
  seriesID?: string,
): Promise<Episode | undefined> => {

  if (!seriesID) {
    throw new Error(`seriesID is missing`);
  }

  const expandString = `images,video_file,distribution`;

  const episodeResponse: EpisodesResponse = await client.get('/v1/user/next_episodes.json', {
    access_token: accessToken,
    'filter[series_id_in]': seriesID,
    'expand[episode]': expandString,
  });

  const episode = episodeResponse.data[0];

  if (!episode) {
    return;
  }
  // Загружаем сериал для этого эпизода, одновременно заполняя кэш mediaItem
  const seriesResponce = await queryClient.fetchQuery({
    queryKey: getMediaItemKey(accessToken, seriesID, ItemObject.Series),
    queryFn: () => fetchSeriesWithSeasonEpisodes(client, limit, accessToken, seriesID),
  });

  episode.series = seriesResponce.data;

  return episode;
};


export const useLastViewedEpisode = (seriesID?: string) => {
  const { accessToken } = getAuthData();
  const queryKey = lastViewedEpisodesKeys.withOneSeriesId(accessToken, seriesID)
  const client = useClient();
  const limit = getLimitFromConfig(useConfig());
  const query =  useQuery<Episode | undefined>({
    queryKey,
    queryFn: () => fetch(client,limit, accessToken, seriesID),
    enabled: Boolean(accessToken && seriesID),
    notifyOnChangeProps: ['status', 'data'],
    cacheTime: 0 // Кэш не нужен, данные часто нужно перезагружать
  });

  return {
    nextEpisode: query.data,
    query
  }
};
