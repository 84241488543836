import * as React from 'react';

import BestPriceButton from '~components/MediaItemPage/Buttons/BestPriceButton';
import CommonButton from '~components/MediaItemPage/Buttons/CommonButton';
import PromoPriceButton from '~components/MediaItemPage/Buttons/PromoPriceButton';
import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import AudioShow from '~typings/AudioShow';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Series from '~typings/Series';

import DefaultBarItem from './DefaultBarItem';
import * as styles from './styles.module.css';

type Props = Readonly<{
  mediaItem: PlayerMediaItem | AudioShow | Series | undefined | null;
  item: BarItem;
  isFocused: boolean;
  onClick: (item: BarItem) => void;
}>;

const BarItemFocuser: React.FC<Props> = (props: Props) => {
  const render = (item: BarItem) => {
    switch (item) {
      case BarItem.PromoPrice:
        return (
          <PromoPriceButton
            key={ item }
            item={ props.mediaItem }
            isFocused={ props.isFocused }
          />
        );

      case BarItem.BestPrice:
        return (
          <BestPriceButton
            item={ props.mediaItem }
            isFocused={ props.isFocused }
          />
        );
      case BarItem.Trailer:
      case BarItem.Play:
      case BarItem.PlayContinue:
      case BarItem.AllEpisodes:
      case BarItem.AllSeasons:
      case BarItem.AllParts:
        return (
          <CommonButton
            barItem={ item }
            isFocused={ props.isFocused }
            onClick={ props.onClick }
          />
        );

      default:
        return (
          <DefaultBarItem
            key={ item }
            item={ item }
            isFocused={ props.isFocused }
            onClick={ props.onClick }
          />
        );
    }
  };

  return <div className={ styles.buttonWrapper }>{render(props.item)}</div>;
};

export default React.memo(BarItemFocuser);
