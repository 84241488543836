import { useQuery } from 'react-query';

import { USER_STORAGE_KEY } from '~components/Provider/App';
import { queryClient } from '~global';

import { ACCOUNT_KEY,AUTH_DATA_KEY } from './auth.utils';


export type AccessData = Readonly<{
  accessToken: string | null,
  refreshToken: string | null;
}>;

const DEFAULT = {
  accessToken: null,
  refreshToken: null,
};

export const getAuthData = (): AccessData => (
  queryClient.getQueryData([ACCOUNT_KEY, AUTH_DATA_KEY]) ?? (DEFAULT)
);

export const useAuthData = () => {
  return useQuery<AccessData>({
    queryKey: [ACCOUNT_KEY, AUTH_DATA_KEY],
  }).data ?? (DEFAULT);
};

export const persistAuthData = () => {
  const authData = getAuthData();
  localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(authData));
};

export const hydrateAuthData = () => {
  const authData: AccessData = JSON.parse(localStorage.getItem(AUTH_DATA_KEY) || JSON.stringify(DEFAULT));
  queryClient.setQueryData([ACCOUNT_KEY, AUTH_DATA_KEY], authData);
};

export const clearAuthData = () => {
  queryClient.setQueryData([ACCOUNT_KEY, AUTH_DATA_KEY], DEFAULT);
  persistAuthData();
};

export const logout = () => {
  queryClient.setQueryData([ACCOUNT_KEY, AUTH_DATA_KEY], DEFAULT);
  sessionStorage.removeItem(USER_STORAGE_KEY);
  persistAuthData();
};

