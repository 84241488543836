import * as cn from 'classnames';
import { truncate } from 'lodash';
import * as React from 'react';

import SceneItem from '~components/Scene/typings';
import { fillImageTemplateBySizes } from '~lib/image';
import { isCard, isEpisode } from '~lib/objectType';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import ImageType from '~typings/ImageType';

import * as styles from './SceneItemCustomName.module.css';

type Props = Readonly<{
  className?: string;
  item: SceneItem;
  /**
   * Параметр влияет на стилизацию
   */
  withFullInfo: boolean;
  /**
   * Показывать ли вместо названия item-а, название сериала, если item является сериалом
   */
  useSeriesNameIfItemIsSeries: boolean;
}>;

/**
 * Компонент рендерит название item-а или content_logo изображение
 *
 */
const MAX_NAME_LENGTH = 60;
const IMAGE_HEIGHT_VW = 6.172;
const SceneItemCustomName: React.FC<Props> = ({
  item,
  className,
  withFullInfo,
  useSeriesNameIfItemIsSeries,
}: Props) => {
  const contentLogo = item.images?.find((image) => image.type === ImageType.ContentLogo);

  if (contentLogo) {
    const height = valueInPixelsByWidth(IMAGE_HEIGHT_VW);
    return (
      <img
        height={ height }
        src={ fillImageTemplateBySizes(contentLogo.url_template, 0, height) }
      />
    );
  }

  let name: string;
  if (useSeriesNameIfItemIsSeries && isEpisode(item) && item.series) {
    name = item.series.name;
  } else {
    name = 'name' in item ? item.name || '' : '';
  }

  const parsedName = truncate(name, {
    length: MAX_NAME_LENGTH,
    separator: /(\.)?(\s+)?/,
    omission: '…',
  });

  return (
    <div
      className={ cn(styles.name, className, {
        [styles.fullDescription]: withFullInfo,
        [styles.textOverflow] : isCard(item)
      }) }
    >
      {parsedName}
    </div>
  );
};


export const SceneItemCustomNameMemo = React.memo(SceneItemCustomName)
