import * as cn from 'classnames';
import * as React from 'react';

import { NavigationHelper } from '~ui/NavigationHelper';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  isDisabled?: boolean;
  className?: string;
  onClick?: (e?: React.SyntheticEvent) => void;
  onLeave?: (NavigationDirection) => void;
  children: any;
}>;

/** 
 *  @deprecated
 *  старая кнопка,
 *  в редизайне страниц теперь нужно использовать кнопку PrimaryButtonWrap
 */
const Button: React.FC<Props> = (props: Props) => {
  const clickHandler = (e) => {
    if (!props.isDisabled && props.onClick) {
      props.onClick(e);
    }
  }

  return (
    <>
      { !!props.onLeave && props.isFocused && (
        <NavigationHelper
          isFocused={ props.isFocused }
          onLeave={ props.onLeave }
        />
      ) }
      <div
        onClick={ clickHandler }
        className={ cn(props.className, styles.button, {
          [styles.focused]: props.isFocused,
          'focusedNavigationNode': props.isFocused,
          [styles.disabled]: props.isDisabled,
        }) }
      >
        { props.children }
      </div>
    </>
  );
};


export default Button;
