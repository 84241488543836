/* eslint-disable max-len */
import React from "react";

import AudioShowPart from "~typings/AudioShowPart";

import * as css from '../live/styles.module.css'

type Props = {
  item: AudioShowPart
}

const LocalComponent = ({ item }: Props) => {
  if (!item.audio_show) {
    return null
  }

  return (
    <div className={ css.mainWrapper }>
      {
        item.audio_show.name &&
          <div className={ css.liveFirstLine }>
              <div className={ css.itemName }>{ item.audio_show.name }</div>
          </div>
      }
      {
        item.audio_show.alt_description &&
          <div className={ css.liveSecondLine }> { item.audio_show.alt_description }</div>
      }
    </div>
  )
}

export const AudioshowInfo = LocalComponent

