import {useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';

const PREFIX_OF_A_COMPOSITE_KEY = 'persons';

const getKey = (personID: string) => ([
  PREFIX_OF_A_COMPOSITE_KEY,
  personID,
]);

const fetch = async (client, cacheData, id) => {
  if (cacheData) { return cacheData; }

  const url = `/v1/persons/${id}`

  return await client.get(url, { 'expand[person]': 'images' });
};

const usePersons = (id: string) => {
  const client = useClient();
  const queryKey = getKey(id);
  const cacheData = queryClient.getQueryData(id);

  return useQuery({
    queryKey,
    queryFn: () => fetch(client, cacheData, id ),
  });
};



export default usePersons;
