import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import { SEARCH_NO_RESULTS } from '~lib/localizator/src/dictionaries';

import * as styles from './CardCollectionNotFound.module.css';

type Props = {
  intl: InjectedIntl;
};

const CardCollectionNotFound$: React.FC<Props> = ({ intl }) => {
  return (
    <div className={ styles.notFoundMessage }>
      <Typography variant={ 'h5_medium' } className={ styles.text }>
        {intl.formatMessage({ id: SEARCH_NO_RESULTS })}
      </Typography>
    </div>
  );
};

export const CardCollectionNotFound = React.memo(injectIntl(CardCollectionNotFound$));
