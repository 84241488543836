import 'core-js';

import Set from 'core-js/fn/set';
import Map from 'core-js/fn/map';

import 'regenerator-runtime/runtime';

if (__PLATFORM__ === __PLATFORM_NAME_PANASONIC__) {
  const tmp = window.Blob;
  window.Blob = null;

  // eslint-disable-next-line global-require
  require('whatwg-fetch');

  window.Blob = tmp;
} else {
  // eslint-disable-next-line global-require
  require('whatwg-fetch');
}

// require('intl');

// Expose `IntlPolyfill` as global to add locale data into runtime later on.
window.IntlPolyfill = require('intl/lib/core.js');

// hack to export the polyfill as global Intl if needed
if (!window.Intl) {
  window.Intl = window.IntlPolyfill;
  // eslint-disable-next-line no-underscore-dangle
  window.IntlPolyfill.__applyLocaleSensitivePrototypes();
}

// window.onunhandledrejection = e => console.error('unhandled', e.reason, e.promise);
