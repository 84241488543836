// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mask--b7cxH {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.fakeImage--qSDJ_ {\n  position: absolute;\n  top: -100%;\n  left: -100%;\n  width: 100%;\n  height: 100%;\n  opacity: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/Scene/BackgroundImage/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,WAAW;EACX,YAAY;EACZ,UAAU;AACZ","sourcesContent":[".mask {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.fakeImage {\n  position: absolute;\n  top: -100%;\n  left: -100%;\n  width: 100%;\n  height: 100%;\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var mask = "mask--b7cxH";
export var fakeImage = "fakeImage--qSDJ_";
export default ___CSS_LOADER_EXPORT___;
