// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".type0Basic--qOogD {\n  background-color: #26262a;\n  margin: 0.15625vw;\n  -webkit-border-radius: 0.46875vw;\n          border-radius: 0.46875vw;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  color: rgba(243, 243, 247, 1);\n}\n\n.type0Active--ritaq {\n  color: rgba(23, 23, 27, 1);\n  background-color: rgba(253, 216, 53, 1);\n}\n\n.button0--L2l82 {\n  width: 3.5937499999999996vw;\n  height: 6.388888888888888vh;\n}\n\n.button1--wi3hv {\n  width: 7.5vw;\n  height: 6.388888888888888vh;\n}\n\n", "",{"version":3,"sources":["webpack://./src/app/components/SearchPageFocuser/keyBoard/buttons/search2.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,gCAAwB;UAAxB,wBAAwB;EACxB,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,6BAAa;AACf;;AAEA;EACE,0BAAa;EACb,uCAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,2BAA2B;AAC7B","sourcesContent":[".type0Basic {\n  background-color: #26262a;\n  margin: 0.15625vw;\n  border-radius: 0.46875vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: $gw_10;\n}\n\n.type0Active {\n  color: $gw_00;\n  background-color: $ac_00;\n}\n\n.button0 {\n  width: 3.5937499999999996vw;\n  height: 6.388888888888888vh;\n}\n\n.button1 {\n  width: 7.5vw;\n  height: 6.388888888888888vh;\n}\n\n"],"sourceRoot":""}]);
// Exports
export var type0Basic = "type0Basic--qOogD";
export var type0Active = "type0Active--ritaq";
export var button0 = "button0--L2l82";
export var button1 = "button1--wi3hv";
export default ___CSS_LOADER_EXPORT___;
