import { useEffect } from 'react';

import { useScene } from '~components/Provider/Scene';

/**
 * Удобный хук, позволяющий сбросить сцену
 */
export const useDropSceneItem = () => {
  const scene = useScene();

  useEffect(() => {
    scene.changeSceneMediaItem(null, false, true);
  }, []);
};
