import * as cn from 'classnames';
import * as React from 'react';

import getComponents from '~components/Block/component.helpers';
import { BlockTitle } from '~components/BlockTitle';
import useMediaItemPageInfo from '~hooks/useMediaItemPage';
import { getCardCollectionDimension, getDimension } from '~lib/Dimension';
import { Block, BlockType } from '~typings/Block';
import ImageType from '~typings/ImageType';
import Page from '~typings/Page';

import * as styles from './styles.module.css';


export type BlockProps = {
  page: Page;
  block: Block;
  isFocusedBlock: boolean;
  isVisibleBlock: boolean;
  isPopupOpened: boolean;
  focusedItemIndex: number;
  blockIndex: number;
  focusedBlockID?: string | null;
};


const BlockContent: React.FC<BlockProps> = (props: BlockProps): JSX.Element | null => {
  const {
    block,
    page,
    focusedItemIndex,
    isVisibleBlock,
    isFocusedBlock,
    isPopupOpened,
    blockIndex,
    focusedBlockID = null
  } = props;
  const isVisible = isVisibleBlock;

  const dimension =  props.block.card_collection
      ? getCardCollectionDimension(props.block.card_collection)
      : getDimension(block?.collection, block?.object);


  const { isMediaItemPageMode } = useMediaItemPageInfo();

  const { content, titleText } = getComponents({
    page,
    block,
    dimension,
    focusedItemIndex,
    isVisible,
    isVisibleBlock,
    isFocusedBlock,
    isPopupOpened,
    blockIndex,
    focusedBlockID
  });

  const title = (text) => (
    <BlockTitle isFocusedBlock={ isFocusedBlock } isVisible={ isVisible } isFadeOut={ !isVisibleBlock }>
      { text }
    </BlockTitle>
  );

  let blockClass
  let blockClass2

  if (block.object === BlockType.BannersBlock) {
    blockClass = styles.bannerFullscreen
    blockClass2 = styles.block
  }
  else if (block.object === BlockType.BigBannersBlock) {
    const blockIndex2 = props.page.blocks?.findIndex(({ id }) => (id === block.id));

    blockClass = (blockIndex2 !== 0 && props.isFocusedBlock)
      ? `${ styles.banner21x9 } ${ styles.notFirstFocusedBanner21x9 }`
      : styles.banner21x9;

    blockClass2 = styles.block
  }
  else if (block.object === BlockType.MiddleBannersBlock) {
    blockClass = styles.banner8x1;
    blockClass2 = styles.block
  }
  else if (block.object === BlockType.CurrentProgramLineBlock) {
    blockClass = styles.currentProgramLine
    blockClass2 = styles.block
  }
  else if (block.object === BlockType.ExpandableCardColletion){
    blockClass2 = isFocusedBlock ? styles.blockExpandFocus : styles.blockExpandNoFocus
  }
  else if (dimension === ImageType.LOGO || dimension === ImageType.BANNER || dimension === ImageType.Card1x1) {
    blockClass = styles.banner;
    blockClass2 = styles.block
  }
  else {
    blockClass = styles.poster;
    blockClass2 = styles.block
  }

  return (
    <>
      { content !== null
        && <div
          key={ `Block-${ block.id }` }
          className={ cn(  blockClass, blockClass2, {
            [styles.visible]: isVisibleBlock,
            [styles.focused]: (isFocusedBlock && !isPopupOpened),
          }) }
        >
          { !isMediaItemPageMode && titleText && title(titleText) }
          { content }
        </div>
      }
    </>

  )
};


export default React.memo(BlockContent);
