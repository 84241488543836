import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import { isChannel, isProgramEvent } from '~components/ScenePlayer/Player/utils';
import useMediaItem, { getCachedMediaItem } from '~hooks/fetch/useMediaItem';
import Channel from '~typings/Channel';
import ProgramEvent from '~typings/Event';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';

import * as styles from './styles.module.css';
import { getSmallText } from './utils';


type Props = Readonly<{
  item: PlayerMediaItem;
  intl: InjectedIntl;
}>;


const SmallText: React.FC<Props> = (props: Props) => {
  const channelID = (props.item.object === ItemObject.ProgramEvent) ? props.item.channel_id : undefined;

  useMediaItem(channelID, ItemObject.Channel);
  const channel = getCachedMediaItem<Channel>(channelID, ItemObject.Channel)
  const item = isProgramEvent(props.item) ?
    {
      ...props.item,
      channel: channel?.data,
    } as ProgramEvent
    :
    props.item;
  const smallText = getSmallText(item, props.intl);

  if (!smallText) { return null; }

  const renderChannelPosition = () => {
    let position: number | null = null;
    if (isChannel(item)) { position = (item as Channel).position }
    if (isProgramEvent(item)) { position = (item as ProgramEvent).channel.position }

    if (position !== null) {
      return (
        <span
          className={ styles.channelPosition }
        >
          { position }
        </span>
      );
    }

    return null;
  };

  return (
    <span
      className={ styles.smallText }
    >
      { renderChannelPosition() }
      { smallText }
    </span>
  );
};


export default SmallText;
