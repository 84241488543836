import * as React from 'react';

import {
  MenuContext,
  MenuDispatchContext,
  reducer,
} from './component.helpers';


const MenuProvider = ({ children }: {
  children?: React.ReactNode;
}): JSX.Element => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      isMenuAppeared: false,
      isMenuOpened: false,
      isPopupOpened: false,
      openPopupCounter: 0,
      isExitOpened: false,
    },
  );

  return (
    <MenuContext.Provider
      value={ state }
    >
      <MenuDispatchContext.Provider
        value={ dispatch }
      >
        { children }
      </MenuDispatchContext.Provider>
    </MenuContext.Provider>
  );
};


MenuProvider.displayName = 'MenuProvider';


export default React.memo(MenuProvider);
