import { useEffect } from 'react';

import { ActionType, useAppAction } from '~components/Provider/App';

/**
 * Удобный хук для выставления isLaunchingDone, при монтировании компонента
 */
export const useDispatchLaunchDone = () => {
  const applicationAction = useAppAction();
  useEffect(() => {
    applicationAction({
      type: ActionType.SetIsLaunchingDone,
      payload: { isLaunchingDone: true },
    });
  }, []);
};
