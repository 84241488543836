import * as cn from "classnames";
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { ON_AIR } from '~localization';

import * as styles from './styles.module.css'

type Props = Readonly<{
  isFocused: boolean;
  onClick: () => void;
  intl: InjectedIntl;
}>;


const OnAirButton: React.FC<Props> = (props: Props) => {

  const handleGoToAir = () => {
    props.onClick();
  };

  return (

    <div
      className={ cn(styles.buttonWrapper, {
        [styles.focused]: props.isFocused,
        'focusedNavigationNode': props.isFocused,
      }) }
      onClick={ handleGoToAir }
    >
      <div
        className={ cn(styles.buttonText, {
          [styles.focusedButton]: props.isFocused,
        }) }
      >
        { props.intl.formatMessage({ id: ON_AIR }) }
      </div>
    </div>
  );
};


export default injectIntl(OnAirButton);
