import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { Nullable } from '~lib/type-utils/utils';
import Episode from '~typings/Episode';
import Series from '~typings/Series';
import { HorizontalCard } from '~ui/CardV2/Horizontal';

import { useCardPropsFromEpisode } from './utils';

export type Props = {
  episode: Episode;
  episodeProgress?: Nullable<number>;
  seriesCertificationRatings: Series['certification_ratings'];
  isFocused: boolean;
};

const EpisodeCardBase: React.FC<Props & InjectedIntlProps> = ({
  episode,
  episodeProgress,
  seriesCertificationRatings,
  isFocused,
  intl,
}) => {
  const cardProps = useCardPropsFromEpisode({
    episode,
    episodeProgress,
    seriesCertificationRatings,
    intl,
  });

  return <HorizontalCard { ...cardProps } isFocused={ isFocused } />;
};

export const EpisodeCard = injectIntl(React.memo(EpisodeCardBase));
