import * as React from 'react';


type Props = {
  className?: string;
};

/* eslint-disable max-len */
const MenuIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg className={ className } viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9985 19.139C10.9985 18.5867 11.4463 18.139 11.9985 18.139H36.0014C36.5537 18.139 37.0014 18.5867 37.0014 19.139V20.139C37.0014 20.6913 36.5537 21.139 36.0014 21.139H11.9985C11.4463 21.139 10.9985 20.6913 10.9985 20.139V19.139ZM31.9778 29.139C31.9778 29.6913 31.5301 30.139 30.9778 30.139H16.9869C16.4346 30.139 15.9869 29.6913 15.9869 29.139V28.139C15.9869 27.5867 16.4346 27.139 16.9869 27.139H30.9778C31.5301 27.139 31.9778 27.5867 31.9778 28.139V29.139Z"
        fill="currentColor"
      />
    </svg>
  );
};


export default MenuIcon;
