// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".seasonsSlider--liH3y {\n  /* 0 88px */\n  padding: 0 6.875vw;\n  width: 100vw;\n}\n\n.episodesSlider--ttztx {\n  /* 0 76px */\n  padding: 0 5.9375vw;\n  width: 100vw;\n  margin-top: 2.34375vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemPage/Series/components/SeriesEpisodeSelect/SeriesEpisodeSelect.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".seasonsSlider {\n  /* 0 88px */\n  padding: 0 6.875vw;\n  width: 100vw;\n}\n\n.episodesSlider {\n  /* 0 76px */\n  padding: 0 5.9375vw;\n  width: 100vw;\n  margin-top: 2.34375vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var seasonsSlider = "seasonsSlider--liH3y";
export var episodesSlider = "episodesSlider--ttztx";
export default ___CSS_LOADER_EXPORT___;
