import * as React from 'react';

import { IconFavoriteBase } from './favoriteBase';

const IconFavoriteFill: React.FC<{ isFocused: boolean;}> = ({
  isFocused,
}) => (
  <IconFavoriteBase
    isFocused={ isFocused }
    isPressed={ true }
  />
);


export default React.memo(IconFavoriteFill);
