import React from "react";

import { KeyBoardLayout, KeyBoardType, KeyIcon } from "~components/SearchPage/keyBoard/keyboardLayouts/type0";
import { BackSpace } from "~ui/Icon/icons/BackSpace";
import { Search } from "~ui/Icon/icons/Search";
import { SpaceSearch } from "~ui/Icon/icons/SpaceSearch";
import { Text } from "~ui/Icon/icons/Text";

import * as styles from './styles.module.css';

interface Props {
  id: string
  buttonIsFocused: boolean;
  keyBoard: string;
  switchTo?: KeyBoardLayout | undefined;
  inputQueryText?: string;
}

export function GetIcons({ id, buttonIsFocused, keyBoard, switchTo, inputQueryText }: Props) {
  switch (id) {
    case KeyIcon.SwitchLayout:
      if (switchTo && switchTo === KeyBoardLayout.En) {
        if (keyBoard === KeyBoardType.type0 + KeyBoardLayout.Ru) {
          return <Text text={ 'ENG' } />
        }
        else if (keyBoard === KeyBoardType.type0 + KeyBoardLayout.Digits) {
          return <Text text={ 'ABC' } />
        }
        return null
      }
      else if (switchTo && switchTo === KeyBoardLayout.Digits) {
        return <Text text={ '123' } />
      }
      else if (switchTo && switchTo === KeyBoardLayout.Ru) {
        if (keyBoard === KeyBoardType.type0 + KeyBoardLayout.En) {
          return <Text text={ 'РУС' } />
        }
        else if (keyBoard === KeyBoardType.type0 + KeyBoardLayout.Digits) {
          return <Text text={ 'АБВ' } />
        }
        return null
      }
      return null
    case KeyIcon.Search:
      return <Search className={ styles.searchIcon } isDisabled={ !inputQueryText } />
    case KeyIcon.BackSpace:
      return <BackSpace width={ 2.734375 } height={ 2.7777777777777777 } isFocused={ buttonIsFocused } />
    case KeyIcon.Space:
      return <SpaceSearch width={ 2.65625 } height={ 4.722222222222222 } isFocused={ buttonIsFocused } />
    default:
      return null
  }
}
