import {
  bannerFullScreenWidthInVW,
  blockHeightBanner8x1InVW,
  blockHeightBanner21x9InVW,
  blockHeightBannerFullScreenInVW,
  blockHeightBannerInVW,
  blockHeightCardCollectionInVh,
  blockHeightFilterInVW,
  blockHeightPosterInVW,
  blockHeightProgramLineInVW,
  EPGEventHeightInVW,
  imageBannerHeightInVW,
  imageBannerWidthInVW,
  imageDefaultDimensionHeightInVW,
  imageDefaultDimensionWidthInVW,
  imageLogoHeightInVW,
  imageLogoWidthInVW,
  imagePosterHeightInVW,
  imagePosterWidthInVW,
  imageSquareWidthInVW,
  mediaItemBannerHeightInVW,
  mediaItemBannerWidthInVW,
  mediaItemDefaultDimensionHeightInVW,
  mediaItemDefaultDimensionWidthInVW,
  mediaItemPosterHeightInVW,
  mediaItemPosterWidthInVW,
  profileItemWidthInVW,
  screenOffsetBottomInVW,
  screenOffsetHorizontalInVW,
  screenOffsetTopInVW,
  spaceBetweenSlidesInVW,
  tiledViewPosterSpaceBetweenRowsInVW,
  tiledViewSpaceBetweenRowsInVW,
} from '~app/variables';
import getScreenHeight from '~lib/screen/height';
import getScreenWidth from '~lib/screen/width';


const valueInPixelsByWidth = (valueInVW: number): number => Math.ceil(
  getScreenWidth() * valueInVW / 100
);

const valueInPixelsByHeight = (valueInVh: number): number => Math.ceil(
  getScreenHeight() * valueInVh / 100
)

const valueInVwByPx = (valueInPx: number): number => Math.ceil(
  valueInPx / getScreenWidth() * 100
);

const valueInVhByPx = (valueInPx: number): number => Math.ceil(
  valueInPx / getScreenWidth() * 100
)

const getScreenOffsetHorizontal = (): number => valueInPixelsByWidth(screenOffsetHorizontalInVW);

const getScreenOffsetTop = (): number => valueInPixelsByWidth(screenOffsetTopInVW);

const getScreenOffsetBottom = (): number => valueInPixelsByWidth(screenOffsetBottomInVW);

const getSceneWidth = (): number => (getScreenWidth() - getScreenOffsetHorizontal() * 2);

const getSceneHeight = (): number => (getScreenHeight() - getScreenOffsetTop() - getScreenOffsetBottom());

const getMediaItemDefaultDimensionWidth = (): number => valueInPixelsByWidth(mediaItemDefaultDimensionWidthInVW);

const getMediaItemDefaultDimensionHeight = (): number => valueInPixelsByWidth(mediaItemDefaultDimensionHeightInVW);

const getMediaItemBannerWidth = (): number => valueInPixelsByWidth(mediaItemBannerWidthInVW);

const getBannerFullScreenWidth = (): number => valueInPixelsByWidth(bannerFullScreenWidthInVW);

const getMediaItemBannerHeight = (): number => valueInPixelsByWidth(mediaItemBannerHeightInVW);

const getSpaceBetweenSlides = (): number => valueInPixelsByWidth(spaceBetweenSlidesInVW);

const getTiledViewSpaceBetweenRows = (): number => valueInPixelsByWidth(tiledViewSpaceBetweenRowsInVW);

const getTiledViewPosterSpaceBetweenRows = (): number => valueInPixelsByWidth(tiledViewPosterSpaceBetweenRowsInVW);

const getProfileItemWidth = (): number => valueInPixelsByWidth(profileItemWidthInVW);

const getBlockHeightBannerFullScreen = (): number => valueInPixelsByWidth(blockHeightBannerFullScreenInVW);

const getHeightBanner21x9 = (): number => valueInPixelsByWidth(blockHeightBanner21x9InVW);

const getHeightBanner8x1 = (): number => valueInPixelsByWidth(blockHeightBanner8x1InVW);

const getBlockHeightPoster = (): number => valueInPixelsByWidth(blockHeightPosterInVW);

const getBlockHeightBanner = (): number => valueInPixelsByWidth(blockHeightBannerInVW);

const getBlockHeightFilter = (): number => valueInPixelsByWidth(blockHeightFilterInVW);

const getBlockHeightProgramLine = (): number => valueInPixelsByWidth(blockHeightProgramLineInVW);

const getBlockHeightCardCollection = (): number => valueInPixelsByHeight(blockHeightCardCollectionInVh)

const getMediaItemPosterWidth = (): number => valueInPixelsByWidth(mediaItemPosterWidthInVW);

const getMediaItemPosterHeight = (): number => valueInPixelsByWidth(mediaItemPosterHeightInVW);

const getImageLogoWidth = (): number => valueInPixelsByWidth(imageLogoWidthInVW);

const getImageLogoHeight = (): number => valueInPixelsByWidth(imageLogoHeightInVW);

const getEPGChannelItemHeight = (): number => valueInPixelsByWidth(8.125);

const getEPGEventItemHeight = (): number => valueInPixelsByWidth(EPGEventHeightInVW);

const getImagePosterWidth = (): number => valueInPixelsByWidth(imagePosterWidthInVW);

const getImagePosterHeight = (): number => valueInPixelsByWidth(imagePosterHeightInVW);

const getImageBannerWidth = (): number => valueInPixelsByWidth(imageBannerWidthInVW);

const getImageSquareWidth = (): number => valueInPixelsByWidth(imageSquareWidthInVW);

const getImageBannerHeight = (): number => valueInPixelsByWidth(imageBannerHeightInVW);

const getImageDefaultDimensionWidth = (): number => valueInPixelsByWidth(imageDefaultDimensionWidthInVW);

const getImageDefaultDimensionHeight = (): number => valueInPixelsByWidth(imageDefaultDimensionHeightInVW);

const getSceneItemWidth = (): number => valueInPixelsByWidth(71.09375);

const getSceneItemHeight = (): number => valueInPixelsByWidth(40);

const getEPGEventHeight = (): number => valueInPixelsByWidth(EPGEventHeightInVW)


export {
  getBannerFullScreenWidth,
  getBlockHeightBanner,
  getBlockHeightBannerFullScreen,
  getBlockHeightCardCollection,
  getBlockHeightFilter,
  getBlockHeightPoster,
  getBlockHeightProgramLine,
  getEPGChannelItemHeight,
  getEPGEventHeight,
  getEPGEventItemHeight,
  getHeightBanner8x1,
  getHeightBanner21x9,
  getImageBannerHeight,
  getImageBannerWidth,
  getImageDefaultDimensionHeight,
  getImageDefaultDimensionWidth,
  getImageLogoHeight,
  getImageLogoWidth,
  getImagePosterHeight,
  getImagePosterWidth,
  getImageSquareWidth,
  getMediaItemBannerHeight,
  getMediaItemBannerWidth,
  getMediaItemDefaultDimensionHeight,
  getMediaItemDefaultDimensionWidth,
  getMediaItemPosterHeight,
  getMediaItemPosterWidth,
  getProfileItemWidth,
  getSceneHeight,
  getSceneItemHeight,
  getSceneItemWidth,
  getSceneWidth,
  getScreenOffsetBottom,
  getScreenOffsetHorizontal,
  getScreenOffsetTop,
  getSpaceBetweenSlides,
  getTiledViewPosterSpaceBetweenRows,
  getTiledViewSpaceBetweenRows,
  valueInPixelsByHeight,
  valueInPixelsByWidth,
  valueInVhByPx,
  valueInVwByPx};
