// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button--v59_T {\n  display: inline-block;\n\n  /* 16px */\n  font-size: 1.25vw;\n  color: rgba(255, 255, 255, 1);\n  text-align: center;\n  font-weight: 500;\n  padding: 0.9375vw 2.5vw;\n  -webkit-border-radius: 0.46875vw;\n          border-radius: 0.46875vw;\n  text-transform: uppercase;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow-x: hidden;\n  background-color: rgba(38, 38, 42, 1);\n  max-width: 55vw;\n  height: 3.438vw;\n  line-height: 1.523vw;\n}\n\n.buttonFocusable--bqovm {\n  cursor: pointer;\n}\n\n.focused--GRPiE {\n  color: rgba(23, 23, 27, 1);\n  background-color: rgba(253, 216, 53, 1);\n}\n\n.disabled--vX3fL {\n  color: rgba(90, 90, 102, 1);\n  cursor: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/app/ui-kit/PrimaryButton/styles.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;;EAErB,SAAS;EACT,iBAAuB;EACvB,6BAAa;EACb,kBAAkB;EAClB,gBAAkB;EAClB,uBAAuB;EACvB,gCAAwB;UAAxB,wBAAwB;EACxB,yBAAyB;EACzB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,qCAAwB;EACxB,eAAe;EACf,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,0BAAa;EACb,uCAAwB;AAC1B;;AAEA;EACE,2BAAa;EACb,YAAY;AACd","sourcesContent":[".button {\n  display: inline-block;\n\n  /* 16px */\n  font-size: $fontBody_vw;\n  color: $gw_11;\n  text-align: center;\n  font-weight: $fw_5;\n  padding: 0.9375vw 2.5vw;\n  border-radius: 0.46875vw;\n  text-transform: uppercase;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow-x: hidden;\n  background-color: $gw_02;\n  max-width: 55vw;\n  height: 3.438vw;\n  line-height: 1.523vw;\n}\n\n.buttonFocusable {\n  cursor: pointer;\n}\n\n.focused {\n  color: $gw_00;\n  background-color: $ac_00;\n}\n\n.disabled {\n  color: $gw_04;\n  cursor: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export var button = "button--v59_T";
export var buttonFocusable = "buttonFocusable--bqovm";
export var focused = "focused--GRPiE";
export var disabled = "disabled--vX3fL";
export default ___CSS_LOADER_EXPORT___;
