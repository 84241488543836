import { isString, map } from 'lodash';
import * as React from 'react';

import { useMenu } from '~components/Provider/Menu';
import useNavigationByKeys from '~hooks/useNavigation';
import NavigationDirection from '~typings/NavigationDirection';
import Link from '~ui/Link';

import { parseTextToElements } from './helpers';

type Props = Readonly<{
  children: string;
  isFocused: boolean;
  onClick: (href:string) => void;
  onLeave?: (dir: NavigationDirection) => void;
  className?: string;
}>;

const HypertextBlock: React.FC<Props> = ({
  children,
  isFocused,
  onLeave,
  onClick,
  className
}: Props) => {
  const { isMenuOpened } = useMenu();
  const [ focusedLink, setFocusedLink ] = React.useState<number>(0);
  const isNavigationAllowed = (isFocused && !isMenuOpened);

  const parsedData = React.useMemo(
    () => parseTextToElements(children),
    [children]
  );

  const { elements, linksCount } = React.useMemo(() => {
    let linksCount = 0;
    const elements = map(parsedData, (el, id) => {
      if (isString(el)) {
        return el;
      } else {
        const linkId = linksCount++;
        return (
          <Link
            isFocused={ isFocused && focusedLink === linkId }
            onClick={ () => onClick(el.href) }
            key={ `hypertext_el_${id}` }
          >
            {el.text}
          </Link>
        );
      }
    });

    return {
      elements,
      linksCount
    };
  }, [parsedData, focusedLink, isFocused]);

  const handleKeyNavigate = (dir: NavigationDirection) => {
    const isIncreasing = (
      dir === NavigationDirection.Down ||
      dir === NavigationDirection.Right
    );

    setFocusedLink(oldIndex => oldIndex + (isIncreasing ? 1 : -1));
  }

  React.useLayoutEffect(() => {
    if (!isFocused || focusedLink >= 0 && focusedLink < linksCount) {
      return;
    }

    setFocusedLink(focusedLink < 0 ? 0 : linksCount - 1);
    onLeave && onLeave(
      focusedLink < 0
        ? NavigationDirection.Up
        : NavigationDirection.Down
    );
  }, [ focusedLink, linksCount, onLeave ])

  useNavigationByKeys({
    isMounted: isNavigationAllowed,
    onNavigation: handleKeyNavigate,
  }, [linksCount, onLeave]);

  return (
    <div className={ className }>
      { elements }
    </div>
  )
};

export default React.memo(HypertextBlock);
