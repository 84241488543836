import classnames from 'classnames';
import React from 'react';

import * as styles from './EPGEventsListSlider.module.css';
import { useDyanmicOffsetChanger, useVirtualChilds } from './hooks';

export type Props = {
  /**
   * Индекс сфокусированного элемента
   */
  focusedIndex: number;

  /**
   * Если какое-то действие изменяет ширину слайдов, то через этот массив можно оповестить об изменениях
   */
  updates?: unknown[];

  /**
   * Дополнительный класс для обертки слайдера
   */
  wrapperClassName?: string;

  /**
   * Дополнительный класс для слайдера
   */
  sliderClassName?: string;

  /**
   * Полное количество дочерних элементов
   */
  childsCount: number;

  /**
   * Функция для отрисовки дочерних элементов
   * idx => индекс дочернего элемента
   * Возвращать нужно сам дочерний элемент
   */
  renderChild: (idx: number) => React.ReactNode;

  /**
   * Максимальное количество элементов, которые будут в DOM
   */
  maxInDOM: number;

  /**
   * Как часто пересчитывать виртуальные элементы слайдера
   * Чем больше значение, тем меньше будет пересчитывание, и больше будет буффер
   *
   * Дефолтное значение `1`
   *
   * Например, если maxInDOM = 10, а recalcStep = 2, то в завимости от сфокусированного элемента,
   * будет следующее кол-во карточек в DOM:
   *
   * focusedIdx = 0, или focusedIdx = 1 => Показаываем карточки с индексом от 0 до 10
   *
   * focusedIdx = 2, или focusedIdx = 3 => Показаываем карточки с индексом от 2 до 12
   *
   *
   *
   * Другой пример:
   *
   * если maxInDOM = 10, а recalcStep = 5, то:
   *
   * focusedIdx = 0,1,2,3,4 => Показаываем карточки с индексом от 0 до 10
   *
   * focusedIdx = 5,6,7,8,9 => Показаываем карточки с индексом от 5 до 15
   *
   * focusedIdx = 10,11,12,13,14 => Показаываем карточки с индексом от 10 до 20
   *
   */
  recalcStep?: number;

  /**
   * Отступ сверху, для фокусированного слайда
   * Например если передать 15.625 (это 200px),
   * то фокусируемый слайд будет становиться на 200 пикселей ниже, от места где слайдер начинается
   */
  stickSlideFromUpVW: number;
};

const EPGEventsListSlider$: React.FC<Props> = ({
  focusedIndex,
  childsCount,
  renderChild,
  updates,
  maxInDOM,
  recalcStep = 1,
  wrapperClassName,
  sliderClassName,
  stickSlideFromUpVW,
}) => {
  const sliderRef = React.useRef<HTMLDivElement>(null);
  const sliderWrapperRef = React.useRef<HTMLDivElement>(null);

  const { virtualChildren, virtualFocus } = useVirtualChilds({
    focusedIndex,
    childsCount,
    renderChild,
    maxInDOM,
    recalcStep,
  });

  useDyanmicOffsetChanger({
    focusedIndex: virtualFocus,
    sliderRef,
    sliderWrapperRef,
    updates,
    realFocusedIndex: focusedIndex,
    stickSlideFromUpVW
  });

  return (
    <div
      className={ classnames(styles.sliderWrapper, wrapperClassName) }
      ref={ sliderWrapperRef }
    >
      <div
        ref={ sliderRef }
        className={ classnames(styles.slider, sliderClassName) }
      >
        {virtualChildren}
      </div>
    </div>
  );
};

export const EPGEventsListSlider = EPGEventsListSlider$;
