import { find,isArray } from 'lodash';

import { useConfig } from '~global';
import { useAccount } from '~hooks/fetch/useAccount';
import {isCard} from '~lib/objectType';
import { getCertificationRating } from '~lib/ratings';
import Card from '~typings/Card';
import ItemObject from '~typings/ItemObject';

export const useIsNeedToConfirmation = () => {
  const { smartTV: { ratingSystem } } = useConfig();
  const { data: accountInfo } = useAccount();

  return (item): boolean => {
    if (!item) return false;

    if (accountInfo && (!accountInfo.parental_control || !accountInfo.security_pin)) {
      return true;
    }

    if (isCard(item)) {
      return ((item as Card).inappropriate === true);
    } else {
      const certificationRating = getCertificationRating(item, ratingSystem);

      if (certificationRating && certificationRating.may_be_inappropriate === true) {
        return true;
      }

      const certificationRatings = item.object === ItemObject.Episode
        ? item.series?.certification_ratings
        : (item as any)?.certification_ratings;

      if (isArray(certificationRatings)) {
        return !!find(certificationRatings, { may_be_inappropriate: true });
      }

      return certificationRatings?.may_be_inappropriate === true;
    }
  };
};
