import focuser from '@focuser';
import { map } from 'lodash';
import * as React from 'react';

import { PopupIsolated } from '~ui/Popup';
import { RadioList } from '~ui/RadioList';

import { DDListItem } from '../helpers';
import * as styles from '../styles.module.css';

export type Props<T> = {
  items: DDListItem<T>[];
  value: T;
  label?: string;
  title?: string;
  onBack?: () => void;
  onSubmit:(value: T) => void;
};

function DropDownPopup<T>({
  items,
  value,
  label,
  onSubmit,
  onBack,
  title
  }: Props<T>) {

  const itemsTexts = React.useMemo(() => (
    map(items, item => item.text)
  ), [items]);

  const selectedId = React.useMemo(() => (
    Math.max(0, items.findIndex(item => item.value === value))
  ), [items, value])

  const submitHandler = (id: number) => onSubmit(items[id].value);

  return (
    <PopupIsolated focuserClassName={ styles.dropDownPopup }>
      <focuser.FocusableBlock isFocused noNeedForceFocus onKeyReturn={ onBack }>
      <div className={ styles.popupTitle }>
        { title }
      </div>

      <div className={ styles.listWrapper }>
        { !!label && (
          <div className={ styles.listLabel }>
            { label }
          </div>
        ) }
        <RadioList
          items={ itemsTexts }
          onSelect={ submitHandler }
          selectedId={ selectedId }
          className={ styles.selectList }
          ignoreOpenedPopup
          isFocused
        />
      </div>
      </focuser.FocusableBlock>
    </PopupIsolated>
  )
};

export default DropDownPopup;
