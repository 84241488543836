import * as React from 'react';
import { Dispatch, useRef } from 'react';

import { EventWithDate } from "~components/EPG/Events/EPGPageEvents";
import useNavigationByKeys from '~hooks/useNavigation';
import NavigationDirection from '~typings/NavigationDirection';


type Props = Readonly<{
  total: number;
  minIndex?: number;
  initialFocusedIndex: number | null;
  allowNavigation: boolean;
  onIndexChange: (index: number) => void;
  onLeave: (direction: NavigationDirection) => void;
}>;

type Props2 = Readonly<{
  maxIndex: number;
  minIndex: number;
  allowNavigation: boolean;
  onIndexChange: (index: number) => void;
  onLeave: (direction: NavigationDirection) => void;
  itemsWithDates: Record<number, EventWithDate>;
  focusedIndex: number | null;
  setPivotDate: Dispatch<React.SetStateAction<Date>>
}>;


const switchSliderAnimationMS = 50;

/**
 * TODO: Удалить после удаления старого плеера
 * @deprecated
 */
const useVerticalListNavigation = (props: Props): void => {
  const isMounted = React.useRef<boolean>(true);
  const timeout = React.useRef<any | null>(null);
  const [isNavigationInProcess, setIsNavigationInProcess] = React.useState(false);
  const focusedIndex = React.useRef<number>(props.initialFocusedIndex || 0);
  const clear = (): void => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };
  const handleNavigationInProgress = (ms: number): void => {
    setIsNavigationInProcess(true);
    timeout.current = setTimeout(setIsNavigationInProcess, ms, false);
  };

  const onUp = () => {
    handleNavigationInProgress(switchSliderAnimationMS);
    const nextIndex = (focusedIndex.current - 1);

    if (nextIndex < (props.minIndex ?? 0)) {
      props.onLeave(NavigationDirection.Up);

      return;
    }

    focusedIndex.current = nextIndex;
    props.onIndexChange(nextIndex);
  };
  const onDown = () => {
    handleNavigationInProgress(switchSliderAnimationMS);
    const nextIndex = (focusedIndex.current + 1);

    if (nextIndex > (props.total - 1)) {
      props.onLeave(NavigationDirection.Down);

      return;
    }

    focusedIndex.current = nextIndex;
    props.onIndexChange(nextIndex);
  };
  const onLeft = React.useCallback(() => {
    props.onLeave(NavigationDirection.Left);
  }, [props.onLeave]);
  const onRight = React.useCallback(() => {
    props.onLeave(NavigationDirection.Right);
  }, [props.onLeave]);

  const handleNavigate = {
    [NavigationDirection.Up]: onUp,
    [NavigationDirection.Right]: onRight,
    [NavigationDirection.Down]: onDown,
    [NavigationDirection.Left]: onLeft,
  };

  const handleKeyNavigate = (direction): void => {
    handleNavigate[direction]();
  };

  useNavigationByKeys({
    isMounted: (isMounted.current && props.allowNavigation && props.total !== 0 && !isNavigationInProcess),
    onNavigation: handleKeyNavigate,
  }, [props.total, props.allowNavigation, props.onLeave]);

  React.useEffect(() => clear, []);

  React.useEffect(() => () => {
    isMounted.current = false;
  }, []);

  React.useEffect(() => {
    if (props.initialFocusedIndex !== null) {
      focusedIndex.current = props.initialFocusedIndex;
      props.onIndexChange(focusedIndex.current);
    }
  }, [props.initialFocusedIndex]);
};

const useVerticalListNavigation2 = (props: Props2): void => {
  /**
   * Этот хук переделан специально для поддержки пагинации в компоненте EPGPageEvents.
   */
  let timeout = useRef(Date.now())

  const handleKeyNavigate = (direction): void => {
    const curDate = Date.now()
    if (direction === NavigationDirection.Up || direction === NavigationDirection.Down) {
      if ((curDate - timeout.current) < switchSliderAnimationMS) {
        return
      }
      else {
        timeout.current = curDate
      }
    }

    switch (direction) {
      case NavigationDirection.Up: {
        const nextIndex = (props.focusedIndex || 0) - 1

        if (nextIndex < (props.minIndex ?? 0)) {
          props.onLeave(NavigationDirection.Up);
          return;
        }
        if (nextIndex - 7 < props.minIndex) {
          const item = props.itemsWithDates[props.focusedIndex || 0].event?.start_at
          if (item) {
            const date = new Date(item).getTime()
            const past = new Date(date - 86400000)
            props.setPivotDate(past)
          }
        }

        props.onIndexChange(nextIndex);
        break
      }
      case NavigationDirection.Right: {
        props.onLeave(NavigationDirection.Right);
        break
      }
      case NavigationDirection.Down: {

        const nextIndex = (props.focusedIndex || 0) + 1;

        if (nextIndex > (props.maxIndex - 1)) {
          props.onLeave(NavigationDirection.Down);
          return;
        }

        if (nextIndex + 7 > props.maxIndex) {
          const item = props.itemsWithDates[props.focusedIndex || 0].event?.start_at
          if (item) {
            const date = new Date(item).getTime()
            const future = new Date(date + 86400000)
            props.setPivotDate(future)
          }
        }

        props.onIndexChange(nextIndex);
        break
      }
      case NavigationDirection.Left: {
        props.onLeave(NavigationDirection.Left);
        break
      }
    }
  };

  useNavigationByKeys({
    isMounted: (props.allowNavigation),
    onNavigation: handleKeyNavigate,
  }, [props.allowNavigation, props.onLeave]);

};

export default useVerticalListNavigation;

export { useVerticalListNavigation2 }
