import * as cn from 'classnames';
import * as React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import { easeIn } from '~app/variables';

import BlurableBaseImage from './BlurableBaseImage';
import useImage from './Image.helper';
import * as styles from './styles.module.css';


type ImageProps = {
  src: string | null;
  className?: string;
  width?: number;
  height?: number;
  durationFadeIn?: number;
  onError?: () => void
};

const Image: React.FC<ImageProps> = ({
  src,
  className,
  width,
  height,
  durationFadeIn = 800,
  onError
}) => {
  const [items] = useImage({ src, onError });

  const defaultStyle = {
    transition: `opacity ${durationFadeIn}ms ${easeIn}`,
    opacity: 0,
  }
  
  const transitionStyles = {
    appearing: { opacity: 1 },
    appeared:  { opacity: 1 },
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 }
  };

  const render = React.useCallback((items) => items.map(
    (item) => (
      <Transition
        key={ item?.src || 'image' }
        in={ !!item }
        timeout={ {
          appear: durationFadeIn,
          enter: durationFadeIn,
          exit: durationFadeIn + (durationFadeIn / 2),
        } }
        appear
      >
        {
          (state) => (
            <BlurableBaseImage
              width={ width }
              imageHeight={ item?.height }
              height={ height }
              src={ item?.src || '' }
              style={ {
                ...defaultStyle,
                ...transitionStyles[state]
              } }
            />
          )
        }
      </Transition>
    )
  ), [ durationFadeIn]);

  return (
    <div className={ cn(styles.container, className) }>
      <TransitionGroup className="image">
        { render([items]) }
      </TransitionGroup>
    </div>
  );
};


export default React.memo(Image);
