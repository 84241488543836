import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import CheckBoxList from '~components/ScenePlayer/Player/controls/Popup/CheckBoxList';
import { IPlayer } from '~lib/player/typings';
import { PLAYER_POPUP_SUBTITLE_LANGUAGE } from '~localization';


type Props = Readonly<{
  player: IPlayer;
  onClose: () => void;
  intl: InjectedIntl;
}>;



const TextTracksPopup: React.FC<Props> = (props: Props) => {
  const { textTracks, subTitleTrack } = usePlayerState();
  const { setSubTitle } = usePlayerStateActions();

  const handleChangeVideoTrack = (idx: number) => {
    setSubTitle(idx > 0 ? (idx - 1) : undefined);

    props.onClose();
  };

  const items = React.useMemo(() => [
    {
      index: 0.1,
      name: 'off',
      isChecked: !subTitleTrack,
    },
    ...textTracks.map((track, index) => ({
      index,
      name: track.name,
      isChecked: subTitleTrack?.name === track.name,
    })),
  ], [textTracks, subTitleTrack]);

  return (
    <CheckBoxList
      header={ props.intl.formatMessage({ id: PLAYER_POPUP_SUBTITLE_LANGUAGE }) }
      items={ items }
      onClick={ handleChangeVideoTrack }
    />
  );
};


export default injectIntl(TextTracksPopup);
