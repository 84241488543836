import * as cn from 'classnames';
import * as React from 'react';

import { RadioButton } from '~ui/RadioButton';
import { SwitchButton } from '~ui/SwitchButton';

import * as styles from './styles.module.css';


type FlagItemProps = {
  focused: boolean;
  onClick: (args: any) => void;
  active?: boolean;
  type?: 'radio' | 'switch';
  children?: React.ReactNode;
}

const FlagItem: React.FC<FlagItemProps> = ({
  focused,
  onClick,
  active = false,
  type = 'radio',
  children,
}) => {
  const Button = type === 'switch' ? SwitchButton : RadioButton;

  return (
    <div
      className={ cn(styles.item, {
        focusedNavigationNode: focused,
      }) }
      style={ {
        opacity: focused ? 1 : 0.75,
      } }
      onClick={ onClick }
    >
      <Button
        isFocused={ focused }
        isChecked={ active }
      >
      { children }
      </Button>
    </div>
  );
};


export default FlagItem;
