/* eslint-disable max-len */
import * as React from 'react';


const IconSettings: React.FC = () => (
  <svg
    className="playerIcon"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.715,12.717a2.934,2.934,0,0,1,2.136.872,2.946,2.946,0,0,1,0,4.239,3.07,3.07,0,0,1-4.287,0,2.946,2.946,0,0,1,0-4.239,2.952,2.952,0,0,1,2.151-.872Zm0,7.576A4.5,4.5,0,0,0,19,18.955a4.526,4.526,0,0,0,0-6.494,4.5,4.5,0,0,0-3.28-1.338,4.521,4.521,0,0,0-3.295,1.338,4.563,4.563,0,0,0,0,6.494,4.517,4.517,0,0,0,3.3,1.338ZM12.68,21.767,11.474,23.1l-.549.527-.946-.617-.808-.766.351-.587,1.113-1.518-.9-1.5L9.1,17l-1.831-.09-.748-.105-.106-1.1.106-1.1.672-.105,1.907-.09.64-1.639.9-1.5L9.552,9.815l-.381-.646L9.979,8.4l.946-.616.5.466,1.251,1.4,1.693-.526,1.77-.226.488-1.758.259-.692L18,6.6l1.083.346-.061.677L18.69,9.455l1.48.977,1.281,1.2,1.694-.706.717-.226.579.947.4,1.053-.564.361-1.679.9.153,1.743L22.6,17.452l1.618.857.625.421-.4,1.037-.579.947-.641-.2-1.77-.737-1.281,1.2-1.48.977.321,1.774.076.752L18,24.818l-1.114.151-.244-.632-.5-1.819-1.77-.225Zm5.614,4.615,2.5-.782-.183-2.075L20.46,22.7l.656-.436.686-.617.854.361,1.938.6,1.266-2.089.931-2.4-1.755-1.158-.747-.406.076-.842-.076-.841.808-.436,1.694-1.113-.87-2.285L24.594,8.808,22.58,9.44l-.778.33-.564-.541-.778-.511.168-.9.167-2-2.364-.752L15.837,4.7l-.747,1.94-.229.811-.778.106-.885.27-.61-.676L11.093,5.772,9.034,7.125,7.142,8.883l1.068,1.8.5.677-.412.661-.335.857-.931.045-2.014.286-.228,2.5.228,2.5,2.09.285.855.046.274.736.473.782-.549.751L7.142,22.533l1.785,1.684,2.166,1.428,1.541-1.428.564-.631.748.24.915.135.259.872.717,1.879Z"
      fill="currentColor"
    />
  </svg>
);


export default React.memo(IconSettings);
