import * as cn from 'classnames';
import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Season from '~typings/Season';

import * as styles from './styles.module.css';


type Props = Readonly<{
  season: Season;
  isFocused: boolean;
  isSelected: boolean;
  onClick: (season: Season) => void;
  onSeasonIDChange?: (season_id: string) => void;
}>;


const SeasonItem: React.FC<Props> = (props: Props) => {
  const handleClick = (): void => {
    props.onSeasonIDChange?.(props.season.id);
    props.onClick(props.season);
  };
  const num = (props.season.number) ?
    <FormattedNumber value={ props.season.number || 1 } />
    :
    props.season.name;

  return (
    <div
      className={ cn(styles.season, {
        [styles.active]: props.isSelected,
        [styles.focused]: props.isFocused,
        'focusedNavigationNode': props.isFocused,
      }) }
      onClick={ handleClick }
    >
      <FormattedMessage
        id={ '{num} season' }
        defaultMessage={ '{num} season' }
        values={ { num } }
      />
    </div>
  );
};


export default React.memo(SeasonItem);
