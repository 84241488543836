import { gw_04 } from 'customization-project-name/colors'
import * as React from 'react';

type Props = {
  className?: string;
};

/* eslint-disable max-len */
const TabletIcon: React.FC<Props> = ({ className = '' }) => (
  <svg className={ className } viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M27.405 10.7775C27.405 10.41 27.705 10.11 28.0725 10.11C28.44 10.11 28.74 10.41 28.74 10.7775C28.74 11.145 28.44 11.445 28.0725 11.445C27.705 11.445 27.405 11.145 27.405 10.7775ZM1.9575 1.155C1.515 1.155 1.1625 1.515 1.1625 1.95V19.5975C1.1625 20.04 1.5225 20.3925 1.9575 20.3925H25.935C26.3775 20.3925 26.73 20.0325 26.73 19.5975V1.9575C26.73 1.515 26.37 1.1625 25.935 1.1625H1.9575V1.155ZM1.2525 0.45C0.81 0.45 0.4575 0.81 0.4575 1.245V20.3025C0.4575 20.745 0.8175 21.0975 1.2525 21.0975H28.7475C29.19 21.0975 29.5425 20.7375 29.5425 20.3025V1.2525C29.5425 0.81 29.1825 0.4575 28.7475 0.4575H1.2525V0.45ZM28.7475 21.555H1.2525C0.5625 21.555 0 20.9925 0 20.3025V1.2525C0 0.5625 0.5625 0 1.2525 0H28.7475C29.4375 0 30 0.5625 30 1.2525V20.3025C30 20.9925 29.4375 21.555 28.7475 21.555Z" 
      fill={ gw_04 }
    />
  </svg>
);


export default TabletIcon;