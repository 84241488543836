import focuser, { useFocuserContext } from '@focuser';
import React, { useMemo } from 'react';

import { useAnimationContext } from '~components/Provider/Animation';
import { getChannelLogo } from '~lib/MediaItemImageSRC';
import { ChannelCard } from '~newapp/ui-kit/Frames_for_collection/Frame_channels_cards_16_9';
import Channel from '~typings/Channel';
import { SliderImperativeRefType, SliderVerticableVirtualNotMemo } from '~ui/SliderVerticable';

import { ChannelsFocusedState } from '../../EPGPageCommon.types';
import { useEPGChannelsClickHandler, useEPGChannelsNavigation } from './EPGChannels.hooks';
import * as styles from './EPGChannels.module.css';

export type Props = {
  channels: Channel[];
  focusedState: ChannelsFocusedState;
  setFocusedState: (state: ChannelsFocusedState) => void;
  beforeLeave: (channel: Channel) => void;
  sliderRef: SliderImperativeRefType;
  initialOffset?: number;
};

const EPGChannels$: React.FC<Props> = ({
  channels,
  focusedState,
  setFocusedState,
  beforeLeave,
  sliderRef,
  initialOffset,
}) => {
  const focusedIdx = focusedState.focusedIdx;
  const focuserProps = useEPGChannelsNavigation({ channels, focusedState, setFocusedState });
  const onClick = useEPGChannelsClickHandler({ focusedIdx, channels, beforeLeave });
  const { isFocused } = useFocuserContext();
  const { isAnimationEnabled } = useAnimationContext();

  const channelLogos = useMemo(() => {
    return channels.map((channel) => getChannelLogo(channel) || undefined);
  }, [channels]);

  const renderChannelCard = (idx: number) => {
    const channel = channels[idx];
    if (!channel) {
      return null;
    }

    return (
      <ChannelCard
        key={ channel.id }
        isFocused={ isFocused && focusedIdx === idx }
        url={ channelLogos[idx] }
        dataIdx={ idx }
      />
    );
  };

  return (
    <focuser.FocusableBlock
      isFocused
      className={ styles.mainWrapper }
      noNeedForceFocus
      onClick={ onClick }
      { ...focuserProps }
    >
      <SliderVerticableVirtualNotMemo
        focusedIndex={ focusedIdx }
        wrapperClassName={ styles.sliderWrapper }
        childsCount={ channels.length }
        renderChild={ renderChannelCard }
        inVW
        itemHeight={ sliderConstants.CHANNEL_CARD_HEIGHT }
        maxInDOM={ sliderConstants.MAX_CARDS_IN_DOM }
        animationDurationInMS={ isAnimationEnabled ? sliderConstants.SLIDER_ANIMATION_MS : 0 }
        transitionGapUpVW={ sliderConstants.SLIDER_GAP_UP }
        transitionGapDownVW={ sliderConstants.SLIDER_GAP_DOWN }
        sliderImperativeRef={ sliderRef }
        initialOffset={ initialOffset }
      />
    </focuser.FocusableBlock>
  );
};

const sliderConstants = {
  SLIDER_ANIMATION_MS: 200,
  MAX_CARDS_IN_DOM: 13,
  SLIDER_GAP_UP: 0.859375, // 11px
  SLIDER_GAP_DOWN: 1.25, // 16px
  CHANNEL_CARD_HEIGHT: 9.765625, // 125px
};

/**
 * Левый блок с каналами в EPG странице
 */
export const EPGChannels = React.memo(EPGChannels$);
