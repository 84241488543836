import React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { Typography } from '~app/ui-kit/Typography';
import { ActionType, useAppAction } from '~components/Provider/App';
import { logout, useAccount } from '~hooks/fetch/useAccount';
import { CONFIRM_LOGOUT_TEXT, NO, YES } from '~lib/localizator/src/dictionaries';
import { PopupIsolated } from '~ui/Popup';

import * as styles from './LogoutConfirmPopup.module.css';

interface Props {
  onCancel: () => void;
}

export const LogoutConfirmPopup = injectIntl<Props>(({ onCancel, intl }) => {
  const { data: accountData } = useAccount();
  const [focusedOn, setFocusOn] = React.useState<'no' | 'yes'>('no');
  const history = useHistory();

  const applicationAction = useAppAction();
  const handleLogOut = () => {
    applicationAction({
      type: ActionType.SetIsAuthorized,
      payload: { isAuthorized: false },
    });
    applicationAction({
      type: ActionType.SetIsLaunchingDone,
      payload: { isLaunchingDone: false },
    });

    logout();

    setTimeout(() => {
      history.push({ search: 'restartApp=true' });
    }, 300);
  };

  return (
    <PopupIsolated focuserClassName={ styles.popup }>
      <Typography
        className={ styles.text }
        variant="h5_medium"
      >
        {intl.formatMessage({ id: CONFIRM_LOGOUT_TEXT }, {
          username: accountData?.username || '',
        })}
      </Typography>
      <div className={ styles.buttons }>
        <div className={ styles.buttonWrapper }>
        <PrimaryButtonFocuser
          onKeyRight={ () => setFocusOn('yes') }
          onKeyReturn={ onCancel }
          onForceFocus={ () => setFocusOn('no') }
          buttonClassName={ styles.button }
          isPointerRightAvailable
          isFocused={ focusedOn === 'no' }
          emitForceFocusOnHover
          onClick={ onCancel }
        >
          {intl.formatMessage({ id: NO })}
        </PrimaryButtonFocuser>
        </div>
        <div className={ styles.buttonWrapper }>
        <PrimaryButtonFocuser
          onKeyLeft={ () => setFocusOn('no') }
          onKeyReturn={ onCancel }
          onForceFocus={ () => setFocusOn('yes') }
          isPointerLeftAvailable
          buttonClassName={ styles.button }
          isFocused={ focusedOn === 'yes' }
          emitForceFocusOnHover
          onClick={ handleLogOut }
        >
          {intl.formatMessage({ id: YES })}
        </PrimaryButtonFocuser>
        </div>
      </div>
    </PopupIsolated>
  );
});
