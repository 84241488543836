import * as React from 'react';
import { useEffect, useState } from 'react';

import SceneItem from '~components/Scene/typings';


export interface State {
  item: SceneItem | null;
  withInfo: boolean;
  isChangeItemInProgress: boolean;
  changeSceneMediaItem: (item: SceneItem | null, withInfo: boolean, setImmediately?: boolean) => void;
}

export enum ActionType {
  SetItem,
  SetInfo,
  SetIsChangeItemInProgress,
}

export interface Action {
  type: ActionType;
  payload: Partial<State>;
}

type Dispatch = (action: Action) => void;


export const SceneContext = React.createContext<State>({} as State);
export const SceneDispatchContext = React.createContext<Dispatch | undefined>(undefined);

export const useScene = () => {
  const context = React.useContext(SceneContext);

  if (context === undefined) {
    throw new Error('useSceneMediaItem must be used within a SceneMediaItemProvider')
  }
  return context;
};

export const useSceneAction = () => {
  const context = React.useContext(SceneDispatchContext);

  if (context === undefined) {
    throw new Error('useSceneMediaItemAction must be used within a SceneMediaItemProvider')
  }
  return context;
};


export const reducer = (state, action: Action) => {
  switch (action.type) {
    case ActionType.SetItem: {
      const withInfo = (action.payload.withInfo !== undefined) ?
        action.payload.withInfo
        :
        state.withInfo;

      if (action.payload.item?.id !== state.item?.id || withInfo !== state.withInfo) {
        return ({
          ...state,
          withInfo,
          item: action.payload.item,
        });
      }

      return state;
    }
    case ActionType.SetInfo: {
      return ({
        ...state,
        withInfo: action.payload.withInfo,
      });
    }
    case ActionType.SetIsChangeItemInProgress: {
      if (action.payload.isChangeItemInProgress !== state.isChangeItemInProgress) {
        return ({
          ...state,
          isChangeItemInProgress: action.payload.isChangeItemInProgress,
        });
      }

      return state;
    }
    default:
      return state;
  }
};


const useDebounce = (value: SceneItem | null, delay: number) => {
  const [isChangeItemInProgress, setIsChangeItemInProgress] = useState<boolean>(false);
  const [item, setItem] = useState(value);

  useEffect(
    () => {
      const isDifferentItem = (item?.id !== value?.id);

      setIsChangeItemInProgress(isDifferentItem);

      const handler = isDifferentItem ?
        setTimeout(() => {
          setIsChangeItemInProgress(false);
          setItem(value);
        }, delay)
        :
        null;

      return () => {
        if (handler) {
          clearTimeout(handler);
        }
      };
    },
    [value?.id]
  );

  return {
    item,
    isChangeItemInProgress,
  };
};


export const useSceneHelper = (
  mediaItem: SceneItem | null,
  debounce: number
) => {
  const [focused, setFocused] = useState<SceneItem | null>(null);
  const {
    item,
    isChangeItemInProgress,
  } = useDebounce(focused, debounce);

  useEffect(() => {
    const nextFocused = mediaItem;

    if (focused?.id !== nextFocused?.id) {
      setFocused(nextFocused);
    }
  }, [mediaItem]);

  return {
    item,
    isChangeItemInProgress,
  };
};


export default useSceneHelper;

