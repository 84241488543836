import * as React from 'react';

import * as styles from './styles.module.css';

export type AlertProps = {
  notification: React.ReactNode;
};

export const Alert: React.FC<AlertProps> = ({ notification }) => {
  return <div className={ styles.alert }>{notification}</div>;
};

export default Alert;
