import { find, flattenDeep } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { useApp } from '~components/Provider/App';
import { useClient } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import Page from '~typings/Page';
import { PageObject } from '~typings/PageObject';
import ResponseSingle from '~typings/ResponseSingle';

import { usePagesStore } from './pagesStore';


const PREFIX_OF_A_COMPOSITE_KEY = 'pages';

export const pageKeys = {
  all: ()=>[PREFIX_OF_A_COMPOSITE_KEY],
  withToken: ()=>[...pageKeys.all(), getAuthData().accessToken]
}

const fetch = async (client: ApiClient) => {

  const parameters: Record<string, any> = {
    ...fetchOptionsBasic[FetchType.PagesV4],
  };

  const { accessToken } = getAuthData();

  if (accessToken) {
    parameters.access_token = accessToken;
  }

  const { data } = await client.get<ResponseSingle<Page[]>>(fetchURLs[FetchType.PagesV4], parameters);

  return data;
};

export const flatOnePage = (page: Page) => {
  if (page.subpages) {
    return [page, ...page.subpages.map((page) => flatOnePage(page))];
  }

  return page;
};

export const flatManyPages = (pages: Page[]) => {
  return flattenDeep(pages.map(flatOnePage));
};

export const useFlattenPages = () : Page[]  => {
  const allpages = useLoadedPages();

  return useMemo(() => flatManyPages(allpages), [allpages]);
}

export const useMainPageID = (): string | undefined => {
  const allPages = useLoadedPages();
  const mainPage = useMemo(
    () =>
      find(allPages, (item) => {
        return item?.object === PageObject.MainPage;
      }),
    [allPages],
  );
  const mainPageID = mainPage?.id || allPages[0]?.id;
  return mainPageID;
};

export const usePageById = (pageID?: string | null) => {
  const pages = useFlattenPages();
  const page = useMemo(() => {
    if (!pageID) {
      return;
    }
    return pages.find((page) => page.id === pageID);
  }, [pages, pageID]);
  return page;
};

export const usePageByType = (pageType: PageObject) => {
  const pages = useFlattenPages();
  const page = useMemo(() => {
    return pages.find((el) => el.object === pageType);
  }, [pages, pageType]);
  return page;
};

export const useLoadedPages = () => {
  const { pages } = usePagesStore();
  return pages?.data || EMPTY_ARRAY;
};

export const useIsPagesFetched = () => {
  const { pages } = usePagesStore();
  return pages?.isFetched || false;
}

/**
 * Этот хук должен использоваться только на LaunchScreen.
 * В остальных местах, если нужны страницы, то нужно использовать usePagesStore
 * (или обертки, например useLoadedPages, usePageById, usePageByType)
 */
export const usePages = () => {
  const client = useClient();
  const { isAuthorized, activeProfileId } = useApp();

  const isEnabled = Boolean(!isAuthorized || activeProfileId);

  return useQuery<Page[]>({
    queryKey: pageKeys.withToken(),
    queryFn: () => fetch(client),
    enabled: isEnabled,
    cacheTime: 60_000, // 1 минута
  });
};
