export interface ScreenCardConfig {
  object: string;
  id: string;
  screen: string;
  card_config: CardConfig;
}

export type CardConfigByScreen = {
  [key: string]: CardConfig;
  'default' : CardConfig;
}

export interface CardConfig {
  id: string;
  layout: CardConfigLayout;
  disable_text: boolean;
  object: 'card_config';
}

export enum CardConfigLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
  Square = 'square',
  News = 'news',
  NewsWithoutPicture = 'news_without_picture',
}
