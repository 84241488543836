import * as React from 'react';

import usePlayerStateActions from '~components/Provider/Player/actions';
import { useScene } from '~components/Provider/Scene';
import Episode from '~typings/Episode';

type Props = Readonly<{
  episode?: Episode;
}>;

export const SeriesPageEpisodeRedirector: React.FC<Props> = (props: Props) => {
  const scene = useScene();
  const { setItem: playerSetItem } = usePlayerStateActions();
  React.useEffect(() => {
    if (props.episode) {
      scene.changeSceneMediaItem(null, false, true);
      playerSetItem(props.episode);
    }
  }, [props.episode]);
  return null;
};
