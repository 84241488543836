import { throttle } from 'lodash';
import * as React from 'react';
import { InjectedIntl } from "react-intl";
import { useLocation } from 'react-router-dom';

import ArrowTop from '~components/NavMenu/arrowTop';
import BackCatcher from '~components/NavMenu/BackCatcher';
import {
  calculateBreadCrumbs,
  getBreadCrumbsByPageUrl,
  getCurrentFocusedLineInfo,
  isMenuItemHaveSubMenu,
  LineFocusInfo
} from '~components/NavMenu/line/component.helpers';
import { RootLine } from '~components/NavMenu/line/rootLine/rootLine';
import { ActionType as MenuActionType, useMenu, useMenuAction } from '~components/Provider/Menu';
import { useMainPageID } from '~hooks/fetch/usePages/usePages';
import useDirectionalNavigation from '~hooks/useDirectionalNavigation';
import listenOffsetY from '~lib/CSSOffset/y';
import useNavMenu from '~stores/NavMenu';
import MenuItem from '~typings/MenuItem';
import NavigationDirection from '~typings/NavigationDirection';

interface Props {
  items: MenuItem[];
  isAuthorized: boolean | null;
  intl: InjectedIntl;
}

const MenuWrapper = (props: Props) => {
  const { items: rootLine, } = props;
  const location = useLocation();
  const menuAction = useMenuAction();
  const { isMenuOpened } = useMenu();
  const isHovered = useNavMenu(state => state.isHovered);
  const setHovered = useNavMenu(state => state.setHovered);
  const breadCrumbs = useNavMenu(state => state.breadCrumbs);
  const setBreadCrumbs = useNavMenu(state => state.setBreadCrumbs);
  const scrollableYNodeRef = React.useRef<HTMLDivElement>(null);
  const currentLineFocusInfo = React.useMemo(
    () => getCurrentFocusedLineInfo(breadCrumbs),
    [breadCrumbs, isMenuOpened],
  );
  const mainPageId = useMainPageID();

  const handleItemClick = React.useCallback(throttle<any>(({ x, lineIndex }) => {
    setBreadCrumbs(
      breadCrumbs[lineIndex] ?
        breadCrumbs.reduce((a, p, i) => {
          if (i === lineIndex) {
            a[i] = { x, lineIndex };
          }
          if (i < lineIndex) {
            a[i] = p;
          }
          return a;
        }, [] as LineFocusInfo[])
        :
        breadCrumbs.concat({ x, lineIndex })
    );
  }, 400, { leading: false }), [breadCrumbs, currentLineFocusInfo]);

  const handleKeyNavigate = React.useCallback((direction): void => {
    if (isHovered && direction !== NavigationDirection.Up) {
      setHovered(false);
    }
    if (direction === NavigationDirection.Down && !isMenuItemHaveSubMenu(
      breadCrumbs,
      currentLineFocusInfo,
      rootLine,
    )) {
      menuAction({
        type: MenuActionType.ChangeMenuState,
        isMenuOpened: false,
      });
    }
    else {
      setBreadCrumbs(calculateBreadCrumbs({
        rootLine,
        direction,
        breadCrumbs,
        currentLineFocusInfo,
      }));
    }
  }, [
    rootLine,
    breadCrumbs,
    currentLineFocusInfo,
    isHovered,
  ]);

  // Bind Up, Right, Bottom, Left Navigation Buttons
  useDirectionalNavigation({
    isAllowedNavigation: isMenuOpened,
    throttleWaitValue: 200,
    onNavigate: handleKeyNavigate,
  }, [currentLineFocusInfo]);

  listenOffsetY({
    blockScrollableYRef: scrollableYNodeRef,
    durationInSec: 0.3,
    focusedIndex: ((breadCrumbs || []).length - 1),
  });

  React.useEffect(() => {
    setBreadCrumbs(getBreadCrumbsByPageUrl(location.pathname, rootLine, mainPageId));
  }, [location.pathname, rootLine, isMenuOpened]);

  return React.useMemo(() => {
    return (
      <>
        <ArrowTop
          isVisible={ ((breadCrumbs || []).length > 1) }
          onClick={ () => void handleKeyNavigate(NavigationDirection.Up) }
        />
        <div ref={ scrollableYNodeRef } style={ { marginTop: "4.166666666666666vh" } }>
          <RootLine
            isVisible
            isAuthorized={ props.isAuthorized }
            items={ rootLine }
            index={ 0 }
            breadCrumbs={ (breadCrumbs || []) }
            onClick={ handleItemClick }
            intl={ props.intl }
          />
        </div>
        {
          isMenuOpened ? <BackCatcher rootLine={ rootLine } /> : null
        }
      </>
    );
  }, [
    props.isAuthorized,
    isMenuOpened,
    currentLineFocusInfo?.x,      // Horizontal Offset Focused Menu Item
    (breadCrumbs || []).length,
  ]);
};

export const LinesWrapper = React.memo(MenuWrapper);
