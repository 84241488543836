import * as cn from 'classnames';
import * as React from 'react';

import useHover from '~hooks/useHover';
import { KeyBoardKey, KeyBoardKeyType } from '~typings/Keyboard';
import IcoBackSpace from '~ui/KeyBoard/icons/IconBackSpace';
import IcoEyeOff from '~ui/KeyBoard/icons/IconEyeOff';
import IcoEyeOn from '~ui/KeyBoard/icons/IconEyeOn';
import IcoLowerCase from '~ui/KeyBoard/icons/IconLowerCase';
import IcoSpace from '~ui/KeyBoard/icons/IconSpace';
import IcoUpperCase from '~ui/KeyBoard/icons/IconUpperCase';
import { KeyPosition } from '~ui/KeyBoard/navigation';
import { isChar } from '~ui/KeyBoard/utils';

import SearchIcon from './icons/Search';
import * as styles from './styles.module.css';



type Props = Readonly<{
  isFocused: boolean;
  lineIndex: number;
  index: number;
  keyBoardKey: KeyBoardKey;
  onClick: (keyBoardKey: KeyBoardKey) => void;
  onHover: (coordinates: KeyPosition) => void;
}>;


const Icons = {
  [KeyBoardKeyType.Space]: <IcoSpace />,
  [KeyBoardKeyType.ShowValueOn]: <IcoEyeOn className={ styles.iconEye }/>,
  [KeyBoardKeyType.ShowValueOff]: <IcoEyeOff className={ styles.iconEye }/>,
  [KeyBoardKeyType.UpperCase]: <IcoUpperCase />,
  [KeyBoardKeyType.LowerCase]: <IcoLowerCase />,
  [KeyBoardKeyType.Backspace]: <IcoBackSpace className={ styles.iconBackSpace }/>,
  [KeyBoardKeyType.SwitchLang]: <>ENG</>,
  [KeyBoardKeyType.Search]: <SearchIcon />,
};


const Key: React.FC<Props> = (props: Props) => {
  const { ref, isHovered } = useHover();
  
  const title: string | number | JSX.Element | undefined = React.useMemo(() => {
    return (
      props.keyBoardKey.id !== undefined && props.keyBoardKey.id !== null
        ? props.keyBoardKey.id
        : Icons[props.keyBoardKey.type]
    );
  }, [props.keyBoardKey.id, props.keyBoardKey.id, props.keyBoardKey.type]);

  const handleClick = React.useCallback(() => {
    if (!props.keyBoardKey.disabled) {
      props.onClick(props.keyBoardKey);
    }
  }, [props.keyBoardKey.disabled, props.onClick, props.keyBoardKey]);

  const handleKeyHover = React.useCallback(() => {
    if (!props.keyBoardKey.disabled) {
      props.onHover({
        x: props.index,
        y: props.lineIndex,
      });
    }
  }, [props.keyBoardKey.disabled, props.onHover, props.index, props.lineIndex]);

  const keydownHandler = (event) => {
    if (props.keyBoardKey.disabled) {
      return;
    }

    let char: string;

    char = event.key || (event.charCode !== 0 && String.fromCharCode(event.charCode));
    if (!isChar(char) && event.keyIdentifier) {
      char = String.fromCharCode(parseInt(event.keyIdentifier.substr(2), 16));
    }

    if (!isChar(char)) return;
    if (char && char.toLowerCase() !== String(props.keyBoardKey.id).toLowerCase()) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    props.onClick(props.keyBoardKey);
    handleKeyHover();
  };

  React.useEffect(() => {
    if (!props.keyBoardKey.disabled) {
      window.addEventListener('keydown', keydownHandler);

      return (() => {
        window.removeEventListener('keydown', keydownHandler);
      });
    }
  }, [props.keyBoardKey.disabled]);

  // Тут слушатель click-а ставится через ref, а не через синтетический onClick,
  // потому что в React синтетический onClick имеет очень низкий приоритет
  // Это конфиктует в некоторых сценариях с новой навигацией
  const handleClickRef = React.useRef(handleClick);
  handleClickRef.current = handleClick;
  React.useEffect(() => {
    const handler = () => {
      handleClickRef.current();
    }
    ref.current?.addEventListener('click', handler);
    return ()=> {
      ref.current?.removeEventListener('click', handler);
    }
  }, []);

  React.useEffect(() => {
    if (isHovered) {
      handleKeyHover();
    }
  }, [isHovered]);

  return (
    <div
      ref={ ref }
      className={ cn(styles.keyBoardKey, {
        'focusedNavigationNode': props.isFocused,
        [styles.keyBoardKeyFocused]: props.isFocused,
        [styles.disabled]: !!props.keyBoardKey.disabled,
        [styles[props.keyBoardKey.type]]: (props.keyBoardKey.type !== KeyBoardKeyType.Char),
      }) }
    >
      { title }
    </div>
  );
};


export default React.memo(Key);
