import * as React from "react";
import { FormattedMessage } from "react-intl";

import { PlayerAccessReason } from "~components/Provider/Player/component.helpers";
import { ACCESS_REASON_RESTRICTED_BY_GEO, SUBSCRIPTION_NO_ACCESS_GRANTED } from '~localization';
import { Alert2css } from "~ui/Alert2";
import { ErrorIcon2 } from "~ui/Icon/icons/ErrorIcon2";


interface Props {
  reason: PlayerAccessReason
}


export function Alert2({ reason }: Props) {
  switch (reason) {
    case PlayerAccessReason.SubOnHold:
      return <Alert2css>
        <div style={ { marginRight: '1.5625vw' } }><ErrorIcon2 /></div>
        <FormattedMessage id={ SUBSCRIPTION_NO_ACCESS_GRANTED } />
      </Alert2css>
    case PlayerAccessReason.RestrictedByGeo:
      return <Alert2css><FormattedMessage id={ ACCESS_REASON_RESTRICTED_BY_GEO } /></Alert2css>
    default:
      return null
  }
}

