import * as React from 'react';

import getImageSRC from '~lib/MediaItemImageSRC';
import getScreenHeight from '~lib/screen/height';
import getScreenWidth from '~lib/screen/width';
import Banner from '~typings/Banner';
import BannerBig from '~typings/BannerBig';
import BannerMiddle from '~typings/BannerMiddle';
import ImageType from '~typings/ImageType';
import Fade from '~ui/Effects/Fade';
import { Image } from '~ui/Image';

import * as styles from './styles.module.css';


type Props = Readonly<{
  mediaItem: Banner | BannerBig | BannerMiddle;
}>;


const BannerSceneMediaItem: React.FC<Props> = ({ mediaItem }: Props) => {
  const imageComponentWidth = getScreenWidth();
  const is2K = (imageComponentWidth === 1920);

  const imageUrl = React.useMemo(() => {
    const isIncludedSmartTVBanner = mediaItem.images
      ?.some(
        ({ original_width, original_height }) => ((
          original_width === 1280
          && original_height === 548
        ) || (
          original_width === 1920
          && original_height === 822
        ))
      );

    const width = isIncludedSmartTVBanner
      ? (is2K)
        ? 1920
        : 1280
      : getScreenWidth();

    const height = isIncludedSmartTVBanner
      ? (is2K)
        ? 822
        : 548
      : getScreenHeight();


    return (
      mediaItem ?
        getImageSRC({
          mediaItem,
          dimension: ImageType.BANNER_WIDE,
          width,
          height,
          isNeedToCrop: false,
        })
        :
        null
    );
  }, [mediaItem.id]);

  return (
    <Fade
      isVisible={ !!imageUrl }
    >
      <Image
        className={ styles.imageFullScreen }
        imageClassName={ styles.smartTVBannerStyle }
        src={ imageUrl }
        durationFadeIn={ 400 }
      />
    </Fade>
  );
};


export default React.memo(BannerSceneMediaItem);
