enum ImageType {
  DEFAULT = 'default',
  IMAGE = 'image',
  LOGO = 'logo',
  CHANNEL_CARD_WIDE = 'channel_card_wide',
  LOGO_SMALL = 'logo_small',
  POSTER = 'poster',
  CARD_PREVIEW = 'card_preview',
  PREVIEW = 'preview',
  BANNER = 'banner',
  BANNER_WIDE = 'banner_wide',
  PROMO_BANNER_WIDE = 'promo_banner_wide',
  BANNER_SMALL = 'banner_small',
  HEADER = 'header',
  Icon = 'icon',
  Filter = 'filter',
  BannerFullScreen = 'banner_full_screen',
  ProgramLine = 'program_line',
  CardIcon = 'card_icon',
  Card16x9 = 'card_16_9',
  Card4x3 = 'card_4_3',
  Card2x3 = 'card_2_3',
  Card1x1 = 'card_1_1',
  BigBanner27x10 = 'big_banner_27_10',
  BigBanner21x9 = 'big_banner_21_9',
  BigBanner16x9 = 'big_banner_16_9',
  MiddleBanner8x1 = 'middle_banner_8_1',
  IconSmartTV = 'icon_filled_svg',
  ContentBG16x9 = 'content_bg_16_9',
  ContentLogo = 'content_logo',
}


// type ImageType = Readonly<
//   'default' | 'logo' | 'poster' | 'banner_small' | 'preview' |'banner_wide' | 'banner' |
//   'card_preview'
// >;


export default ImageType;
