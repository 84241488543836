import React from 'react';
import { useInfiniteQuery } from 'react-query';

import { useClient,useConfig } from '~global';
import ApiClient from '~lib/ApiClient';
import CastMember from '~typings/CastMember';
import ResponseMany from '~typings/ResponseMany';

import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '../utils';

const PREFIX_OF_A_COMPOSITE_KEY = 'cast_members';

const getKey = (limit: number, resourceId?: string) => [PREFIX_OF_A_COMPOSITE_KEY, limit, resourceId || 'empty'];

interface FetchParams {
  client: ApiClient, limit: number, resourceId?: string
  page: number;
}

const fetch = async ({
  client,
  limit,
  page,
  resourceId
}: FetchParams) => {
  const url = `/v1/cast_members`;

  const resp : ResponseMany<CastMember[]> = await client.get(url, {
    'filter[resource_id_eq]': resourceId,
    'expand[cast_member]': 'person.images',
    'page[offset]': page * limit,
    'page[limit]': limit,
    sort: 'position'
  });

  return resp;
};

export const useInfinityCastMembers = (resourceId?: string) => {
  const client = useClient();
  const config = useConfig();
  const limit = getLimitFromConfig(config);
  const queryKey = getKey(limit, resourceId);

  const query = useInfiniteQuery({
    queryFn: ({ pageParam = 0 }) => fetch({client, limit, resourceId, page: pageParam}),
    queryKey,
    getNextPageParam: getNextPageForInfinityPagination,
    enabled: Boolean(resourceId)
  });

  const parsedPages = React.useMemo(() => {
    return query.data?.pages && reducePaginatedPages(query.data.pages);
  }, [query.data?.pages]);

  return {
    parsedData: parsedPages?.data ?? [],
    query
  }

};
