import React from 'react';

import { BigKeyboardButtonState } from '~app/ui-kit/BigKeyboardButton';

import {
  BackSpaceIcon,
  ClosedEyeIcon,
  OpenEyeIcon,
  SpaceIcon,
  ToLowerCaseIcon,
  ToUpperCaseIcon,
} from '../Icons';
import * as styles from '../Icons/icons.module.css';
import { HideBtnState } from '../NavigableKeyboard/types';
import { FBtn, FBtnAction } from '../types';

export const getContentForFButton = (
  FBtnAction: FBtnAction,
  hideBtnState: HideBtnState,
) => {
  const buttonsMap: Record<FBtnAction, () => React.ReactNode> = {
    space: () => <SpaceIcon />,
    backspace: () => <BackSpaceIcon />,
    switch_to_symbols: () => '*#$',
    switch_to_ru: () => 'РУС',
    switch_to_uppercase: () => <ToUpperCaseIcon />,
    switch_to_lowercase: () => <ToLowerCaseIcon />,
    'hide/show': () => (hideBtnState === 'shown' 
      ? <OpenEyeIcon className={ styles.icon }/> 
      : <ClosedEyeIcon className={ styles.icon }/>),
    confirm: () => 'OK',
    switch_to_eng: () => 'ENG',
    switch_to_letters: () => 'ABC',
  };

  return buttonsMap[FBtnAction]();
};

export const getStateForFButton = (
  btn: FBtn,
  isFocused: boolean,
  hideBtnState: HideBtnState,
  isConfirmBtnActive?: boolean,
): BigKeyboardButtonState => {
  if (btn.alwaysDisabled) {
    return 'disabled';
  }

  if (isFocused) {
    return 'selected';
  }

  if(!btn.action){
    return 'normal';
  }

  if(btn.action === 'hide/show' && hideBtnState === 'unnecessary'){
    return 'disabled';
  }

  if(btn.action === 'confirm' && !isConfirmBtnActive){
    return 'disabled';
  }

  if (btn.action === 'confirm') {
    return 'confirmation';
  }

  return 'normal';
};
