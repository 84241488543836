import * as React from 'react';

import { valueInPixelsByHeight } from "~lib/SizesInPX";


type Props = Readonly<{
  blockScrollableYRef: React.RefObject<HTMLDivElement>;
  durationInSec: number;
  focusedIndex: number;
}>;


const listenOffsetY = (props: Props): void => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (props.blockScrollableYRef.current !== null) {
      const transition = `transform ${ (props.durationInSec * 1000) }ms linear`;

      props.blockScrollableYRef.current.style.transition = transition;
      props.blockScrollableYRef.current.style.webkitTransition = transition;
    }
  }, [props.blockScrollableYRef.current]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (props.blockScrollableYRef.current !== null) {

      // =))) костылил то что было, но с дизайном сошлось

      const countOffestInVw = props.focusedIndex <= 0
        ? 0
        : props.focusedIndex === 1
          ? 7.222222222222221
          : 7.222222222222221 + ((props.focusedIndex - 1) * 8.88888888888889)

      const convertOffsetToPx = valueInPixelsByHeight(countOffestInVw)

      const transform = `translate3d(0, -${ convertOffsetToPx }px, 0)`;
      props.blockScrollableYRef.current.style.transform = transform;
      props.blockScrollableYRef.current.style.webkitTransform = transform;
    }
  }, [props.focusedIndex]);
};


export default listenOffsetY;

