import cn from 'classnames';
import { find } from 'lodash';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import useRentInvoice from '~hooks/fetch/usePayments/useRentInvoice';
import { PromoData } from '~hooks/fetch/usePromocode';
import useRentPlanCards from '~hooks/fetch/useRentPlans/useRentPlanCards';
import useNavigationByKeys from '~hooks/useNavigation';
import usePaymentProceed from '~hooks/usePaymentProceed/usePaymentProceed';
import { getFormattedTimeExceptions, getFullFormattedTime } from '~lib/pluralize/index';
import { ABOUT_THE_FILM, ABOUT_THE_SERIES, BUY_ACTION, 
  BY_CLICKING_THE_PAYMENT_BUTTON, FREE, MOBILE_PAYMENT, 
  PAYMENT_SERVICE_FEE_INFORMATION, PROMOCODE, RENT_ACTION, 
  THE_TERMS_OF_THE_USER_AGREEMENT, WRONG_PROMO_CODE_ERROR } from '~localization';
import NavigationDirection from '~typings/NavigationDirection';
import PromoPhase from '~typings/PromoPhase';
import RentPlan from '~typings/RentPlan';
import Button from '~ui/button';
import { CheckboxButton } from '~ui/CheckboxButton';

import * as styles from '../../styles.module.css';
import { getPhaseTextsForRentPlans, getPlanType } from './PromocodeRentPlanConfirm.utils';
import { CheckboxRentPlanPhaseText } from './PromocodeRentPlanConfirmTypes';


type Props = Readonly<{
  promocode: string;
  promo: PromoData;
  rentPlanId: string;
  isProcessing: boolean;
  onSuccess: () => void;
  onError: (errorMessage?: string | null) => void;
  setProcessingStatus: (isProcessing: boolean) => void;
  intl: InjectedIntl;
}>;

const NOT_AVAILABLE_PROMO_CODE = 'not_available_promo_code';
const LAST_FOCUS_ON_CHECKBOX = 'LAST_FOCUS_ON_CHECKBOX';

enum PromocodeConfirmActions {
  ContinueConfirmation = 'continue_confirm',
  AboutContent = 'about_content',
}


const PromocodeRentPlanConfirm: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { mutate, data: invoice, error: invoiceError } = useRentInvoice();
  const params:any = useParams();
  const { parsedData } = useRentPlanCards(props.rentPlanId);
  const cards = parsedData?.data;
  const currentResource = cards?.find((card) => params.id === card.id);
  const rentPlan = (find(props.promo.rentPlans, { id: props.rentPlanId }) ?? {}) as RentPlan;

  const foundRightPromoPhase = rentPlan.phases.find((phase) => 
    phase.promos?.[0].id === props.promo.promocode?.promo.id);

  const isCurrentResourceMovie = currentResource?.resource_type === 'movie';
  const isRentTypeTVOD = rentPlan?.type === 'TVOD';

  const [isFocusOnLeftBlock, setIsFocusOnLeftBlock] = React.useState<boolean>(true);
  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState<number>(0);

  const { operator, promoCode, promocodeAndOperatorArr } = React.useMemo(() => {
    const { operator, promoCode } = getPhaseTextsForRentPlans(foundRightPromoPhase as PromoPhase);
    const promocodeAndOperatorArr : CheckboxRentPlanPhaseText[] = [...operator, ...promoCode];
    return {
      operator,
      promoCode,
      promocodeAndOperatorArr,
    };
  }, [foundRightPromoPhase]);

  const getLastCheckedButtonFromHistory = () => {
    const currentSearch = parse(history.location.search);

    const lastState = currentSearch[LAST_FOCUS_ON_CHECKBOX];

    if(typeof lastState === 'string') {
      const parsedLastState = JSON.parse(lastState);
      const { type } = parsedLastState;
      delete currentSearch[LAST_FOCUS_ON_CHECKBOX];
      //в методах оплаты может быть 3 варианта оплаты: 
      //promocode, operator, banking_card(не реализовано в данный момент)
      //сейчас запоминать стейт нет нужды, сделано на перспективу,
      //когда будет приходить 2 типа оплаты: operator и banking_card
      const foundedCurrentPaymentMethod = promocodeAndOperatorArr.find(p => p.type === type);

      if(foundedCurrentPaymentMethod){
        return foundedCurrentPaymentMethod;
      }
    }

    const firstPaymentMethod = foundRightPromoPhase?.billing.prices[0]?.payment_method_type;
    if (firstPaymentMethod === 'operator') {
      return operator[0] || null;
    }
    if (firstPaymentMethod === 'promo_code') {
      return promoCode[0] || null;
    }
    
    return promocodeAndOperatorArr[0] || null;
  }

  const [checkedButtonWithPhase, setCheckedButtonWithPhase] =
    React.useState<CheckboxRentPlanPhaseText | null>(getLastCheckedButtonFromHistory());

  const { isSuccess, isError, error: errorReason } = usePaymentProceed(invoice?.data.id, 
    checkedButtonWithPhase?.type === 'operator');

  const actionsList = React.useMemo(() => {
    return [
      {
        type: PromocodeConfirmActions.ContinueConfirmation,
        text: isRentTypeTVOD 
          ? props.intl.formatMessage({ id: RENT_ACTION }).toUpperCase()  
          : props.intl.formatMessage({ id: BUY_ACTION }).toUpperCase() 
      },
      {
        type: PromocodeConfirmActions.AboutContent,
        text: isCurrentResourceMovie 
          ? props.intl.formatMessage({ id: ABOUT_THE_FILM })
          : props.intl.formatMessage({ id: ABOUT_THE_SERIES })
      },
    ];
  }, [isRentTypeTVOD, isCurrentResourceMovie]) 

  const handleLeftBlockNavigation = (direction: NavigationDirection) => {
    if (direction === NavigationDirection.Up) {
        if (selectedButtonIndex > 0) {
          setSelectedButtonIndex(selectedButtonIndex - 1);
        }
    } else if (direction === NavigationDirection.Down) {
        if (selectedButtonIndex < actionsList.length - 1) {
          setSelectedButtonIndex(selectedButtonIndex + 1);
        }
    } else if (direction === NavigationDirection.Right) {
        setIsFocusOnLeftBlock(false);
        setSelectedButtonIndex(0);
    }
  }

  const handleRightBlockNavigation = (direction: NavigationDirection) => {
    if (direction === NavigationDirection.Up) {
      if (selectedButtonIndex > 0) {
        setSelectedButtonIndex(selectedButtonIndex - 1);
      }
    } else if (direction === NavigationDirection.Down) {
      if (selectedButtonIndex < promocodeAndOperatorArr.length - 1) {
        setSelectedButtonIndex(selectedButtonIndex + 1);
      }
    } else if (direction === NavigationDirection.Left) {
        setIsFocusOnLeftBlock(true);
        setSelectedButtonIndex(0);
    }
  }

  const handleNavigation = (direction: NavigationDirection) => {
    if(isFocusOnLeftBlock) {
      handleLeftBlockNavigation(direction);
      return;
    }
    handleRightBlockNavigation(direction);
  };


  useNavigationByKeys({
    isMounted: true,
    onNavigation: handleNavigation,
  }, [selectedButtonIndex, isFocusOnLeftBlock]);

  React.useEffect(() => {
    if (!invoiceError) return;
    const error = (invoiceError as any)?.errors?.[0]?.code;
    props.setProcessingStatus(false);
    if (error === NOT_AVAILABLE_PROMO_CODE) {
      return props.onError(props.intl.formatMessage({ id: WRONG_PROMO_CODE_ERROR }))
    }
  }, [invoiceError]);

  React.useEffect(() => {
    if (!isSuccess && !isError || !props.isProcessing) return;
    props.setProcessingStatus(false);

    if (isSuccess) {
      return props.onSuccess();
    }

    if (isError) {
      return props.onError(errorReason?.message);
    }
  }, [isSuccess, isError, errorReason]);

  const getFocusForPromocodePayment = (idx: number):boolean => {
    if(!isFocusOnLeftBlock) {
      return (idx + operator.length) === selectedButtonIndex;
    }
    return false;
  }
  
  const buttonsClickHandler = (type: PromocodeConfirmActions) => {
    if (type === PromocodeConfirmActions.AboutContent) {
      if(checkedButtonWithPhase) {
        const currentSearch = parse(history.location.search);

        currentSearch[LAST_FOCUS_ON_CHECKBOX] = JSON.stringify({
          type: checkedButtonWithPhase.type
        })

        history.replace({
          search: stringify(currentSearch),
        });
      }

      history.push({
        pathname: `/media-item/${currentResource?.resource_type}/${currentResource?.resource_id}/promocode`,
      });
      return;
    };

    if (type === PromocodeConfirmActions.ContinueConfirmation) {
      if (!checkedButtonWithPhase) {
        return;
      }

      props.setProcessingStatus(true);

      mutate({
        planID: rentPlan.id,
        resourceID: currentResource?.resource_id,
        promocode: String(props.promocode),
        planType: getPlanType(rentPlan),
        paymentMethodType: checkedButtonWithPhase.type === 'operator' ? 'operator' : 'promo_code', 
      });
      return;
    }
  }
  
  const renderPaymentMethods = () => {
    return (
      <>
        {operator.length !== 0 &&
          <div className={ styles.paymentTitle }>
            { props.intl.formatMessage({ id: MOBILE_PAYMENT }) }
          </div>
        }
        { operator?.map((phase, idx) => {
          return (
            <div className={ styles.optionsWrapper } key={ idx }>
            <CheckboxButton
              key={ idx }
              onClick={ () => setCheckedButtonWithPhase(phase) }
              isFocused={ !isFocusOnLeftBlock && idx === selectedButtonIndex }
              isChecked={ checkedButtonWithPhase === phase }
              className={ styles.checkboxButtonWrapper }
            >
              <div className={ styles.checkboxButtonEvergreen }>
                <div className={ styles.checkboxInfoWrap }>
                  <div className={ styles.checkboxInfoPromophase }>
                    { isRentTypeTVOD 
                      ? `Аренда ${rentPlan.quality}`
                      : `Покупка ${rentPlan.quality}`
                    }
                  </div>
                  <div className={ styles.checkboxInfoPromophase }>
                    { phase.promo }
                  </div>
                </div>
              </div>
            </CheckboxButton>
          </div>
          )
        })
        }

        { promoCode.length !== 0 &&
          <div className={ styles.paymentTitle }>
            { props.intl.formatMessage({ id: PROMOCODE }) }
          </div>
        }
        
        { promoCode?.map((phase, idx) => {
          return (
            <div className={ styles.optionsWrapper } key={ idx }>
              <CheckboxButton
                key={ idx }
                onClick={ () => setCheckedButtonWithPhase(phase) }
                isFocused={ getFocusForPromocodePayment(idx) }
                isChecked={ checkedButtonWithPhase === phase }
                className={ styles.checkboxButtonWrapper }
              >
                <label className={ styles.checkboxButton }>
                  { `${props.intl.formatMessage({ id: FREE })}` }
                </label>
              </CheckboxButton>
            </div>
          )
        })}
      </>
    )
  }

  const renderPaymentInfo = () => {
    if(checkedButtonWithPhase?.type === 'operator') {
      return (
        <div className={ styles.promoPrice }>
          { checkedButtonWithPhase.promo }
        </div>
      )
    } 
    
    if (checkedButtonWithPhase?.type === 'promocode') {
      return (
        <>
          <div className={ styles.promoPrice }>
            <div>{ props.intl.formatMessage({ id: FREE })  }</div>
          </div>
          {rentPlan !== undefined  && rentPlan.duration.value 
            ? <div className={ styles.evergreenPrice }>
                { `На ${ getFullFormattedTime(rentPlan.duration.unit, rentPlan.duration.value)}` }
              </div>
            : null
          }
        </>
      ) 
    }
  }
  
  return (
    <div className={ styles.subscrConfirmWrapper }>
      { currentResource &&
        <div className={ styles.contentInfoWrapper }>
          <div className={ styles.promoName }>
            { currentResource.name } 
          </div>

          {renderPaymentInfo()}
        
          {actionsList.map((button, index) => (
            <Button 
              isFocused={ isFocusOnLeftBlock && index === selectedButtonIndex }
              className={ styles.buttonsWrap }
              onClick={ () => {buttonsClickHandler(button.type)} }
              isDisabled={ button.type === PromocodeConfirmActions.AboutContent 
                ? false
                : !checkedButtonWithPhase 
              }
              key={ index }
            >
              {button.text}
            </Button>
          ))}

          <div className={ cn(styles.paymentInfo, 
            rentPlan.description !== "" 
              ? styles.paymentInfoDescription 
              : styles.paymentInfoNone) }
          >
            <div>{ rentPlan.description }</div>
          </div>
          {rentPlan !== undefined && isRentTypeTVOD && 
            <div className={ styles.paymentInfo }>
              {`${isCurrentResourceMovie ? "Фильм" : "Сериал"} можно посмотреть в течение 
              ${ getFormattedTimeExceptions(rentPlan.duration.unit, rentPlan.duration.value || 0)} с момента оплаты. 
              После начала просмотра ${isCurrentResourceMovie ? "фильм" : "сериал"} будет доступен в течение 
              ${ getFormattedTimeExceptions(rentPlan.access_period.unit, rentPlan.access_period.value || 0)}`}
            </div>
          }
          
          <div className={ styles.paymentInfo }>
            {props.intl.formatMessage({ id: PAYMENT_SERVICE_FEE_INFORMATION })}
          </div>
          <div className={ styles.paymentInfo }>
            {props.intl.formatMessage({ id: BY_CLICKING_THE_PAYMENT_BUTTON })}  
            <u className={ styles.rulesText }>
              {props.intl.formatMessage({ id: THE_TERMS_OF_THE_USER_AGREEMENT })}
            </u>
          </div>
        </div>
      }
      <div className={ styles.paymentMethodWrapper }>
        {renderPaymentMethods()}
      </div>
    </div>
  )
};

export default injectIntl(PromocodeRentPlanConfirm);
