const isChannelURL = (url: string): boolean => (
  (url.indexOf('/channel/') !== -1)
  || (
    (url.indexOf('/program_event/') !== -1)
    && (url.indexOf('/player') !== -1)
  )
);

const isEpisodeURL = (url: string): boolean => (
  (url.indexOf('/episode/') !== -1)
  || (url.indexOf('/series/') !== -1)
);


export {
  isChannelURL,
  isEpisodeURL,
};
