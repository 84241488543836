import focuser, { FocuserKeyHandler } from '@focuser';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { ADD_A_NEW_PROFILE } from '~lib/localizator/src/dictionaries';
import Profile from '~typings//Profile';

import NavProfileItem from './ProfileItem';
import * as styles from './styles.module.css';


type Props = {
  items?: Profile[];
  onProfileItemClick: (index: number) => void;
  onCreateNewProfileClick: () => void;
  intl: InjectedIntl;
};


const ProfileScrollWrapper: React.FC<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [focusBlock, setFocusBlock] = React.useState<'profiles' | 'button'>('profiles');
  const { items = [] } = props;

  const onKeyRight:FocuserKeyHandler = (evt) => {
    if(items.length - 1 > selectedIndex ) {
      evt.stop();
      setSelectedIndex(selectedIndex + 1);
    }
  }

  const onKeyLeft:FocuserKeyHandler = (evt) => {
    if(selectedIndex > 0) {
      evt.stop();
      setSelectedIndex(selectedIndex - 1);
    }
  }

  const onKeyDown:FocuserKeyHandler = (evt) => {
    if(focusBlock === 'profiles') {
      evt.stop();
      setFocusBlock('button')
    }
  }

  const onKeyUp:FocuserKeyHandler = (evt) => {
    if(focusBlock === 'button') {
      evt.stop();
      setFocusBlock('profiles');
    }
  }

  return (
    <focuser.FocusableBlock
      isFocused
      onKeyRight={ onKeyRight }
      onKeyLeft={ onKeyLeft }
      onKeyDown={ onKeyDown }
      onKeyUp={ onKeyUp }
      noNeedForceFocus
    >
      <div className={ styles.sliderScrollableWrapper }>
        {items.map((item: Profile, index: number) => (
          <focuser.FocusableBlock
            isFocused={ focusBlock === 'profiles' && selectedIndex === index }
            onForceFocus={ () => {
              setFocusBlock('profiles')
              setSelectedIndex(index)
            } }
            isLastBlock
            emitForceFocusOnHover
            className={ styles.itemWrapper }
            key={ item.id }
            onClick={ () => props.onProfileItemClick(index) }
          >
            {({ isFocused }) => (
              <NavProfileItem
                user={ item }
                isFocused={ isFocused }
              />
            )}
          </focuser.FocusableBlock>
        ))}
      </div>
      <div className={ styles.buttonWrap }>
        <PrimaryButtonFocuser
          isFocused={ focusBlock === 'button' }
          onClick={ (evt) => {
            evt.stop();
            evt.stopNativeEvent();
            props.onCreateNewProfileClick() } }
          onForceFocus={ () => setFocusBlock('button') }
          emitForceFocusOnHover
        >
          { props.intl.formatMessage({ id: ADD_A_NEW_PROFILE }) }
        </PrimaryButtonFocuser>
      </div>
    </focuser.FocusableBlock>
  );
};

export default injectIntl(ProfileScrollWrapper);
