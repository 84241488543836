import { useEffect, useMemo, useReducer } from 'react';

import { InfinityProgramEvents } from '~hooks/fetch/useProgramEvents/useProgramEvents';
import currentByStartAtAndEndAt from '~lib/currentByStartAtAndEndAt';
import { toPivotDate } from '~lib/eventsHelper';
import ProgramEvent from '~typings/Event';

/**
 * Хук берет программы из infinity запроса, и возвращает текущую программу
 */
export const useCurrentEventInInfinity = (
  pages: InfinityProgramEvents[] | undefined,
): ProgramEvent | undefined => {
  const [forceUpdateCounter, forceUpdate] = useReducer((c) => c + 1, 0);

  const todayPage = useMemo(() => {
    const todayPivotDate = toPivotDate(new Date());
    return pages?.find((page) => toPivotDate(page.pivotRawDate) === todayPivotDate);
  }, [pages, forceUpdateCounter]);

  const currentEvent = useMemo(() => {
    if (!todayPage) {
      return;
    }

    return currentByStartAtAndEndAt(todayPage.programEvents) as ProgramEvent | undefined;
  }, [todayPage, forceUpdateCounter]);

  useEffect(() => {
    if (!currentEvent) {
      return;
    }
    const timeToRecalc = new Date(currentEvent.end_at).getTime() - new Date().getTime();

    if (timeToRecalc <= 0) {
      return;
    }

    const timeout = setTimeout(() => {
      forceUpdate();
    }, timeToRecalc);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentEvent]);

  return currentEvent;
};
