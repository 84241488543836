import focuser, { FocuserKeyOrClickHandler, useFocuserContext } from '@focuser';
import cn from 'classnames';
import React, { useMemo } from 'react';

import { SecondaryButton } from '~app/ui-kit/SecondaryButton';
import { Typography } from '~app/ui-kit/Typography';
import { getMiddleBannerImage } from '~lib/MediaItemImageSRC';
import BannerMiddle from '~typings/BannerMiddle';

import * as commonStyles from './../../MiddleBannerCommon.module.css';
import * as styles from './MiddleBannerContent.module.css';

type MiddleBannerContentProps = {
  bannerMiddle: BannerMiddle;
  onClick: FocuserKeyOrClickHandler;
};

export const MiddleBannerContent: React.FC<MiddleBannerContentProps> = React.memo(({
  bannerMiddle,
  onClick,
}) => {
  const uiProps = useMemo(() => {
    const texts = bannerMiddle.big_screen_text_block;

    const title = texts.title;

    const subtitle = texts.subtitle;

    const buttonText = texts.button_text;

    const textAlign = bannerMiddle.text_position;

    const bannerImageUrl = getMiddleBannerImage(bannerMiddle);

    return {
      title,
      subtitle,
      buttonText,
      bannerImageUrl,
      textAlign,
    };
  }, [bannerMiddle]);

  return (
    <MiddleBannerContentUI
      { ...uiProps }
      onClick={ onClick }
    />
  );
});

MiddleBannerContent.displayName = 'MiddleBannerContent';

type MiddleBannerContentUIProps = {
  title: string;
  subtitle: string;
  buttonText: string;
  onClick: FocuserKeyOrClickHandler;
  bannerImageUrl?: string | null;
  textAlign: BannerMiddle['text_position'];
};

export const MiddleBannerContentUI: React.FC<MiddleBannerContentUIProps> = ({
  title,
  subtitle,
  buttonText,
  onClick,
  bannerImageUrl,
  textAlign,
}) => {
  const mainWrapperAlignStyle: string = useMemo(() => {
    switch (textAlign) {
      case 'align-center':
        return commonStyles.alignCentered;
      case 'align-right':
        return commonStyles.alignRight;
      case 'align-left':
        return commonStyles.alignLeft;
    }
  }, [textAlign]);

  const { isFocused } = useFocuserContext();

  return (
    <focuser.FocusableBlock
      isFocused
      emitForceFocusOnHover
      noNeedForceFocus
      onClick={ onClick }
      className={ cn(commonStyles.mainWrapper, mainWrapperAlignStyle, {
        [commonStyles.focused]: isFocused,
      }) }
      style={ { backgroundImage: `url(${bannerImageUrl})` } }
    >
      {title ? (
        <Typography
          variant="h5_bold"
          className={ styles.title }
        >
          {title}
        </Typography>
      ) : null}
      {subtitle ? (
        <Typography
          variant="subtitle1_medium"
          className={ styles.subtitle }
        >
          {subtitle}
        </Typography>
      ) : null}
      {buttonText ? <SecondaryButton isFocused={ isFocused }>{buttonText}</SecondaryButton> : null}
    </focuser.FocusableBlock>
  );
};
