import * as DOMPurify from 'dompurify';
import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { useClient, useConfig } from '~global';
import ApiClient from '~lib/ApiClient';
import Question from '~typings/Question';
import QuestionSection from "~typings/QuestionSection";
import ResponseMany from '~typings/ResponseMany';

import { fetchOptionsBasic, FetchType, fetchURLs } from '../fetch-parameters';
import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '../utils';

type FetchParams = {
  client: ApiClient;
  limit: number;
  page: number;
}


const PREFIX_OF_A_COMPOSITE_KEY = 'questions';

const faqKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,
  infinityPages:(limit: number)=> [
    faqKeys.PREFIX,
    limit,
  ]
}



const fetch = async ({ client, page, limit }: FetchParams): Promise<ResponseMany<Question[]>> => {
  return await client
    .get<ResponseMany<Question[]>>(fetchURLs[FetchType.Questions], {
      ...fetchOptionsBasic[FetchType.Questions],
      'filter[section_in]': Object.values(QuestionSection).join(','),
      'page[offset]': page * limit,
      'page[limit]': limit,
    })
    .then((res) => {
      res.data.forEach((item) => {
        item.questionSanitized = DOMPurify.sanitize(item.question, { ALLOWED_TAGS: [] });
      });
      return res;
    });
};


const useInfinityQuestions = () => {
  const client = useClient();
  const limit = getLimitFromConfig(useConfig());
  const query = useInfiniteQuery<ResponseMany<Question[]>>({
    queryKey: faqKeys.infinityPages(limit),
    queryFn: ({ pageParam = 0 }) =>
      fetch(
        {
          limit,
          client,
          page: pageParam
        },
      ),
    getNextPageParam: getNextPageForInfinityPagination,
  });

  const parsedData: ResponseMany<Question[]> | undefined = useMemo(() => {
    return query.data?.pages && reducePaginatedPages(query.data.pages);
  }, [query.data?.pages]);

  return {
    query,
    parsedData,
  };
};

/**
 * Хук сам автоматически загружает все вопросы
 */
const useAutoInfinityQuestions = () => {
  const { query, parsedData } = useInfinityQuestions();

  useEffect(() => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage();
    }
  }, [query]);

  return {
    data: parsedData,
    isLoading: Boolean(query.isFetchingNextPage || query.hasNextPage || query.isLoading),
  };
};


const useIsQuestionsAvailable = () => {
  const { query, parsedData: questions } = useInfinityQuestions();

  if(query.isLoading){
    return {
      isLoading: true,
      isAvailable: false
    }
  }

  return {
    isLoading: false,
    isAvailable: Boolean(questions?.data.length),
  }
}

export {
  useAutoInfinityQuestions,
  useIsQuestionsAvailable,
};
