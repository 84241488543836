/* eslint-disable max-len */
import * as React from 'react';


const ErrorIcon: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 36" className={ className }>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 36C28.4411 36 36.5 27.9411 36.5 18C36.5 8.05887 28.4411 0 18.5 0C8.55887 0 0.5 8.05887 0.5 18C0.5 27.9411 8.55887 36 18.5 36ZM16 10.5C16 9.11929 17.1193 8 18.5 8C19.8807 8 21 9.11929 21 10.5V19.5C21 20.8807 19.8807 22 18.5 22C17.1193 22 16 20.8807 16 19.5V10.5ZM21 26.5C21 27.8807 19.8807 29 18.5 29C17.1193 29 16 27.8807 16 26.5C16 25.1193 17.1193 24 18.5 24C19.8807 24 21 25.1193 21 26.5Z"
      fill="currentColor"
    />
  </svg>
);


export default ErrorIcon;
