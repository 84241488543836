import * as cn from 'classnames';
import customization from 'customization-project-name/base-domain.json';
import * as React from 'react';

import getImageSRC from '~lib/MediaItemImageSRC';
import { isCardAudioShow } from '~lib/objectType';
import Card from '~typings/Card';
import ItemObject from '~typings/ItemObject';
import MediaItemDimension from '~typings/MediaItemDimension';
import cardItemOptions from '~ui/Card/Render/Image.helpers';
import * as styles from '~ui/MediaItem/styles.module.css';


type Props = Readonly<{
  item: Card | undefined;
  dimension: MediaItemDimension;
  children?: any;
}>;


const CardItemImage: React.FC<Props> = (props: Props) => {
  const { isChannelCardWide } = cardItemOptions(props.dimension, props.item);
  const isSquare = props.item?.image_shape === 'square';
  const isCircle = props.item?.image_shape === 'circle';
  const isAudioShow = isCardAudioShow(props.item);
  const imageURL = getImageSRC({
    dimension: props.dimension,
    mediaItem: props.item,
    isNeedToCrop:  !isCircle && !isSquare,
    isDoubleQuality: true,
  });
  const style: React.CSSProperties = {};

  if (imageURL) {
    style.backgroundImage = `url('${imageURL}')`;
  }

  const renderRaitingCards = (str: string | null) => {
    if(customization === 'spbtv.com') {
      if(props.item?.certification_tags.length !== 0) {
        if(str?.includes("394x592") || str?.includes("328x492")) {
          return (
            <div className={ cn(styles.ratingText, styles.certRating) }>
                {props.item?.certification_tags[0]}
            </div>
          )
        } else {
          return (
          <div className={ cn(styles.ratingText, styles.certRating, styles.ratingTextHorizontal) }>
            {props.item?.certification_tags[0]}
          </div>
          )
        }
      }
    } else {
      return null;
    }
  }

  const sortedCards = renderRaitingCards(imageURL);

  return (
    <>
      { props.item?.images?.length === 0 && props.item?.resource_type === ItemObject.Person
      ? <div
          className={ cn(styles.mediaItemImage, {
            [styles.circleNoImg]: isCircle,
          }) }
        >
          <div className={ cn(styles.circleNoImg) }>
            <div className={ cn(styles.nameActor) }>
              {props.item?.name?.[0]}
            </div>
          </div>
        </div>
      : <div
            style={ style }
            className={ cn(styles.mediaItemImage, {
              [styles.card_1_1]: (isAudioShow && isSquare),
              [styles.circle]: isCircle,
              [styles.channelCardWide]: isChannelCardWide,
            }) }
          >
            { sortedCards }
            { props.children }
        </div>
      }
    </>
  );
};


export default CardItemImage;
