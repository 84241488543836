import { getFiltersKeyString, SearchFilters } from '~hooks/fetch/useSearch/useSearch.helpers';
import { SelectedFilters } from '~typings/SelectedFilters';

import { makeCacheKeyFromSelectedFilters } from './utils';

type QueryKeyParams = {
  accessToken: string | null;
  cardCollectionID?: string;
  filters: SearchFilters;
  limit: number;
};

type QueryKeyParamsSelectedFilters = {
  accessToken: string | null;
  cardCollectionId?: string;
  selectedFilters: SelectedFilters;
  limit: number;
};

export const PREFIX_OF_A_COMPOSITE_KEY = 'card_collections_items';

export const CCIKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,
  withAccessToken: (accessToken: string | null) => [CCIKeys.PREFIX, accessToken],
  // Старые фильтры, deprecaterd
  /**
   * @deprecated
   */
  withDefaultParams: (params: QueryKeyParams) => [
    ...CCIKeys.withAccessToken(params.accessToken),
    params.cardCollectionID,
    getFiltersKeyString(params.filters),
    params.limit,
  ],
  /**
   * @deprecated
   */
  firstNormalPage: (params: QueryKeyParams) => [
    ...CCIKeys.withDefaultParams(params),
    'normal-pages-fist-page',
  ],
  /**
   * @deprecated
   */
  infinityPages: (params: QueryKeyParams) => [
    ...CCIKeys.withDefaultParams(params),
    'infinity-pages',
  ],
  /// С новыми selectedFilters
  withDefaultParamsSelected: (params: QueryKeyParamsSelectedFilters) => [
    ...CCIKeys.withAccessToken(params.accessToken),
    params.cardCollectionId,
    makeCacheKeyFromSelectedFilters(params.selectedFilters),
    params.limit,
  ],
  firstNormalPageSelected: (params: QueryKeyParamsSelectedFilters) => [
    ...CCIKeys.withDefaultParamsSelected(params),
    'normal-pages-fist-page',
  ],
  infinityPagesSelected: (params: QueryKeyParamsSelectedFilters) => [
    ...CCIKeys.withDefaultParamsSelected(params),
    'infinity-pages',
  ],
};
