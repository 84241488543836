import { useEffect, useRef } from 'react';

import { Events } from '~lib/player/common';
import { IPlayer } from '~lib/player/typings';
import { DeviceInfo } from '~typings/DeviceInfo';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Stream from '~typings/Stream';

import send from './send';
import useTimeshift from './useTimeshift';


type Props = {
  player: IPlayer | null;
  stream: Stream;
  mediaItem: PlayerMediaItem;
  device: DeviceInfo;
};

const DEFAULT_INTERVAL = 5;

/**
 * Heartbeat
 * for old heartbeat
 */

const useHeartbeat = ({ player, stream, mediaItem, device }: Props) => {
  const isLive = mediaItem.object === ItemObject.Channel;
  const {
    currentTime,
    started,
    startTime,
    time,
    startTimeshift,
    resetTimeshift,
  } = useTimeshift({ player, isLive, device });

  const hearbeatInterval = useRef<any>(null);
  const duration = useRef<number>(0)

  const ping = (action = 'watch') => {
    if (
      !stream.heartbeat?.url
      || !started.current || !player
      || startTime.current === null
    ) {
      return;
    }

    let durationParam = '&duration=0';
    if (
      (action === 'start' || action === 'watch' || action === 'end') &&
      isFinite(duration.current) && !isLive
    ) {
      durationParam = `&duration=${Math.ceil(duration.current * 1000)}`;
    }

    const url = `${stream.heartbeat.url}&action=${action}&timestamp=${Math.floor(time.current)}${durationParam}`;

    send(url);
  };

  const startHeartbeat = () => {
    if (!stream.heartbeat || hearbeatInterval.current !== null || !player) { return; }

    startTimeshift();

    duration.current = player.getDuration();

    hearbeatInterval.current = setInterval(
      ping,
      (stream.heartbeat.interval || DEFAULT_INTERVAL) * 1000
    );

    setTimeout(() => { ping('start'); }, 100);
  };
  const resetSession = () => {
    ping('end');
    clearInterval(hearbeatInterval.current);
    hearbeatInterval.current = null;
  };

  const handleTimeUpdate = () => {
    if (!started.current) {
      startHeartbeat();
      return;
    }
  };
  const handleError = () => {
    resetSession();
  };
  const handlePause = () => {
    if (!started.current) { return; }
    ping('end');
    currentTime.current = 0;
    clearInterval(hearbeatInterval.current);
    hearbeatInterval.current = null;
  };
  const handleStop = () => {
    if (!started.current) { return; }
    ping('end');
    resetSession();
  };
  const handleEnded = () => {
    if (!started.current) { return; }
    ping('end');
    resetSession();
  };

  useEffect(() => {
    if (stream.heartbeat && player) {
      player.on(Events.CAN_PLAY, startHeartbeat);
    }

    return () => {
      resetSession();
      resetTimeshift();
      player?.removeListener(Events.CAN_PLAY, startHeartbeat);
    }
  }, [stream, player]);

  useEffect(() => {
    if (!player) { return; }

    player.on(Events.TIME_UPDATE, handleTimeUpdate);
    player.on(Events.ERROR, handleError);
    player.on(Events.PAUSE, handlePause);
    player.on(Events.STOP, handleStop);
    player.on(Events.ENDED, handleEnded);

    return () => {
      if (!player) { return; }

      player.removeListener(Events.TIME_UPDATE, handleTimeUpdate);
      player.removeListener(Events.ERROR, handleError);
      player.removeListener(Events.PAUSE, handlePause);
      player.removeListener(Events.STOP, handleStop);
      player.removeListener(Events.ENDED, handleEnded);
    };
  }, [player]);
};


export default useHeartbeat;
