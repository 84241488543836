import { FBtn } from '../types';

export const KeyboardRusLowerLayout: FBtn[] = [
  // Первая линия
  {
    id: '1',
    coord: {
      x: 0,
      y: 0,
    },
    onLeft: 'skip',
    onUp: 'skip',
  },
  {
    id: '2',
    coord: {
      x: 1,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '3',
    coord: {
      x: 2,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '4',
    coord: {
      x: 3,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '5',
    coord: {
      x: 4,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '6',
    coord: {
      x: 5,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '7',
    coord: {
      x: 6,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '8',
    coord: {
      x: 7,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '9',
    coord: {
      x: 8,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: '0',
    coord: {
      x: 9,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: ' ',
    action: 'space',
    coord: {
      x: 10,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: 'switch_to_symbols',
    action: 'switch_to_symbols',
    coord: {
      x: 11,
      y: 0,
    },
    onUp: 'skip',
  },
  {
    id: 'backspace',
    action: 'backspace',
    coord: {
      x: 12,
      y: 0,
    },
    onUp: 'skip',
    onRight: 'skip',
  },

  // Вторая линия
  {
    id: 'й',
    coord: {
      x: 0,
      y: 1,
    },
    onLeft: 'skip',
  },
  {
    id: 'ц',
    coord: {
      x: 1,
      y: 1,
    },
  },
  {
    id: 'у',
    coord: {
      x: 2,
      y: 1,
    },
  },
  {
    id: 'к',
    coord: {
      x: 3,
      y: 1,
    },
  },
  {
    id: 'е',
    coord: {
      x: 4,
      y: 1,
    },
  },
  {
    id: 'н',
    coord: {
      x: 5,
      y: 1,
    },
  },
  {
    id: 'г',
    coord: {
      x: 6,
      y: 1,
    },
  },
  {
    id: 'ш',
    coord: {
      x: 7,
      y: 1,
    },
  },
  {
    id: 'щ',
    coord: {
      x: 8,
      y: 1,
    },
  },
  {
    id: 'з',
    coord: {
      x: 9,
      y: 1,
    },
  },
  {
    id: 'х',
    coord: {
      x: 10,
      y: 1,
    },
  },
  {
    id: 'switch_to_eng',
    action: 'switch_to_eng',
    coord: {
      x: 11,
      y: 1,
    },
  },
  {
    id: 'switch_to_uppercase',
    action: 'switch_to_uppercase',
    coord: {
      x: 12,
      y: 1,
    },

    onRight: 'skip',
  },

  // Третья линия
  {
    id: 'ф',
    coord: {
      x: 0,
      y: 2,
    },
    onLeft: 'skip',
  },
  {
    id: 'ы',
    coord: {
      x: 1,
      y: 2,
    },
  },
  {
    id: 'в',
    coord: {
      x: 2,
      y: 2,
    },
  },
  {
    id: 'а',
    coord: {
      x: 3,
      y: 2,
    },
  },
  {
    id: 'п',
    coord: {
      x: 4,
      y: 2,
    },
  },
  {
    id: 'р',
    coord: {
      x: 5,
      y: 2,
    },
  },
  {
    id: 'о',
    coord: {
      x: 6,
      y: 2,
    },
  },
  {
    id: 'л',
    coord: {
      x: 7,
      y: 2,
    },
  },
  {
    id: 'д',
    coord: {
      x: 8,
      y: 2,
    },
  },
  {
    id: 'ж',
    coord: {
      x: 9,
      y: 2,
    },
  },
  {
    id: 'э',
    coord: {
      x: 10,
      y: 2,
    },
  },
  {
    id: '.ru',
    coord: {
      x: 11,
      y: 2,
    },
    onDown: ({isConfirmBtnActive, focuserEvent, setFocus})=> {
      if(isConfirmBtnActive){
        setFocus({
          x: 11,
          y: 3,
        });
        focuserEvent.stop();
        focuserEvent.stopNativeEvent();
      }
    }
  },
  {
    id: '.com',
    coord: {
      x: 12,
      y: 2,
    },
    onRight: 'skip',
    onDown: ({isConfirmBtnActive, focuserEvent, setFocus})=> {
      if(isConfirmBtnActive){
        setFocus({
          x: 11,
          y: 3,
        });
        focuserEvent.stop();
        focuserEvent.stopNativeEvent();
      }
    }
  },

  // Четвертая линия
  {
    id: 'я',
    coord: {
      x: 0,
      y: 3,
    },
    onLeft: 'skip',
    onDown: 'skip',
  },
  {
    id: 'ч',
    coord: {
      x: 1,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: 'с',
    coord: {
      x: 2,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: 'м',
    coord: {
      x: 3,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: 'и',
    coord: {
      x: 4,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: 'т',
    coord: {
      x: 5,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: 'ь',
    coord: {
      x: 6,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: 'б',
    coord: {
      x: 7,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: 'ю',
    coord: {
      x: 8,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: 'ъ',
    coord: {
      x: 9,
      y: 3,
    },
    onDown: 'skip',
  },
  {
    id: 'ё',
    coord: {
      x: 10,
      y: 3,
    },
    onDown: 'skip',
    onRight: ({isConfirmBtnActive, focuserEvent, setFocus})=> {
      if(isConfirmBtnActive){
        setFocus({
          x: 11,
          y: 3,
        });
        focuserEvent.stop();
        focuserEvent.stopNativeEvent();
      }
    }
  },
  {
    id: 'ok_button',
    action: 'confirm',
    coord: {
      x: 11,
      y: 3,
    },
    onRight: 'skip',
    onDown: 'skip',
    isLarge: true,
  },
];

export const KeyboardRusUpperLayout: FBtn[] = KeyboardRusLowerLayout.map((btn) => {
  if (!btn.action && btn.id.length === 1) {
    return {
      ...btn,
      id: btn.id.toUpperCase(),
    };
  }

  if(btn.action === 'switch_to_uppercase'){
    return {
      ...btn,
      action: 'switch_to_lowercase'
    };
  }

  return btn;
});

Object.freeze(KeyboardRusLowerLayout)
Object.freeze(KeyboardRusUpperLayout)
