
import Drm, { DRMType } from '~typings/Drm';


const getDRM = (drm: Drm) => {
  if (drm.type === DRMType.PlayReady) {
    return ({
      'com.microsoft.playready': {
        serverURL: drm.license_server || 'http://pr.msk.spbtv.com/playready/rightsmanager.asmx',
      }
    });
  }

  if (drm.type === DRMType.WideVine) {
    return ({
      'com.widevine.alpha': {
        serverURL: drm.license_server || '//wv.spbtv.com/wvproxy',
      }
    });
  }

  return {};
};


export default getDRM;
