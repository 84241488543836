import * as cn from 'classnames';
import * as React from 'react';

import Button from '~ui/button';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  className?: string;
  tabs: string[];
  selectedTab: number;
  focusedTab: number;
  onChange: (number) => void;
}>;


const TabsPanel: React.FC<Props> = (props: Props) => {
  const handleClick = (i: number) => {
    props.onChange(i);
  }

  return (
    <div className={ cn(styles.tabsPanel, props.className) }>
      { props.tabs.map((tab, i) => (
        <Button
          isFocused={ props.isFocused && props.focusedTab === i }
          onClick={ () => handleClick(i) }
          className={ cn(styles.tab, { [styles.selected]: i === props.selectedTab }) }
          key={ `TabsPanel_Tab${i}` }
        >
          { tab }
        </Button>
      )) }
    </div>
  );
};


export default TabsPanel;
