import * as cn from 'classnames';
import * as React from 'react';

import { ActionType, useMenuAction } from '~components/Provider/Menu';
import Portal from '~ui/Portal/Portal';

import * as styles from './styles.module.css';

export type Props = {
  /**
   * Нужен ли попап с полупрозрачным фоном
   * По умолчанию `false`
   */
  semiTransporent?: boolean;
  stylesProps?: object;
  className?: string;
  /**
   * Использовать ли портал для попапа
   * По умолчанию `true`
   */
  usePortal?: boolean;

  children?: React.ReactNode | React.ReactNode[];
};

const Popup: React.FC<Props> = ({
  children,
  semiTransporent = false,
  stylesProps,
  className,
  usePortal = true,
}) => {
  const menuAction = useMenuAction();
  const setPopupState = (isPopupOpened: boolean) => {
    menuAction({
      isPopupOpened,
      type: ActionType.ChangePopUpState,
    });
  };

  React.useEffect(() => {
    setPopupState(true);

    return () => {
      setPopupState(false);
    };
  }, []);

  const style: React.CSSProperties = {
    backgroundSize: 'contain',
    ...stylesProps,
  };

  const renderPopup = () => (
    <div
      className={ cn(
        styles.popup,
        {
          [styles.semiTransporent]: semiTransporent,
        },
        className,
      ) }
      style={ style }
    >
      <>{children}</>
    </div>
  );

  if (!usePortal) {
    return renderPopup();
  }

  return <Portal id="popup">{renderPopup()}</Portal>;
};

export default Popup;
