import { QueryKey,useMutation, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';
import { ItemObject } from '~typings/ItemObject';
import Vote from '~typings/Vote';

import { getAuthData } from '../useAccount';


const PREFIX_OF_A_COMPOSITE_KEY = 'votes';

enum Method {
  POST = 'post',
  DELETE = 'delete',
}

type Result = Readonly<{
  data: Vote;
  meta: {
    status: number;
  };
}>;

const getKey = (type: ItemObject, resourceID: string): QueryKey => ([
  [PREFIX_OF_A_COMPOSITE_KEY, type, resourceID]
]);

const fetch = async (
  client: ApiClient,
  resourceType: ItemObject,
  resourceID: string
): Promise<Result | undefined> => {
  let type: string | null = null;
  if (resourceType === ItemObject.Movie) {
    type = 'movies';
  }
  if (resourceType === ItemObject.Series) {
    type = 'series';
  }
  if (!type || !resourceID) { return; }

  const cache = queryClient.getQueryData<Result>(getKey(resourceType, resourceID));

  if (cache?.meta?.status === 200) { return cache; }

  return await client.get(
    `/v1/${type}/${resourceID}/votes`, {
    access_token: getAuthData().accessToken,
  });
};


const useVote = (type: ItemObject, resourceID: string) => {
  const client = useClient();

  return useQuery<Result | undefined>(
    getKey(type, resourceID),
    () => fetch(client, type, resourceID),
    {
      enabled: !!getAuthData().accessToken,
    }
  );
}


const mutate = async (
  client: ApiClient,
  resourceType: ItemObject,
  resourceID: string,
  method: Method = Method.POST,
  mention?: Vote['action']
) => {
  let type: string | null = null;
  if (resourceType === ItemObject.Movie) {
    type = 'movies';
  }
  if (resourceType === ItemObject.Series) {
    type = 'series';
  }
  if (!type || !resourceID) { return; }

  return await client[method](
    `/v1/${type}/${resourceID}/votes${
      method === Method.POST ? `/${mention}` : ''
    }`, {
      access_token: getAuthData().accessToken,
    },
  );
};

const useVoteMutation = (type: ItemObject, resourceID: string) => {
  const client = useClient();

  return useMutation<
    Result,
    any,
    { method?: Method, mention?: Vote['action'] },
    { previousVotes: Result | undefined }
  >(
    ({ method, mention }) => mutate(client, type, resourceID, method, mention),
    {
      onMutate: ({ method, mention }) => {
        queryClient.cancelQueries(getKey(type, resourceID));
        const previousVotes = queryClient.getQueryData<Result>(getKey(type, resourceID));
        const newVotes = method === Method.POST ?
          {
            object: 'vote',
            action: mention,
          } : {
            object: 'vote',
            action: null,
          };

        queryClient.setQueryData(
          getKey(type, resourceID),
          { data:newVotes },
        );

        return { previousVotes };
      },
      onError: (_err, _, context) => {
        queryClient.setQueryData(
          getKey(type, resourceID),
          context?.previousVotes,
        );
      },
    }
  );
}


export {
  getKey,
  Method,
  Result,
  useVote,
  useVoteMutation,
};
