import { gw_00,gw_10 } from 'customization-project-name/colors.js'
import * as React from "react";

/* eslint-disable max-len */

interface Props {
  width?: number;
  height?: number;
  color?: string;
  isFocused: boolean;
}

export function BackSpace(props: Props) {
  const { width = 2.03125, height = 3.6111111111111107, isFocused } = props
  return (
    <div style={ { width: `${ width }vw`, height: `${ height }vh` } }>
      <svg viewBox="0 0 35 20"
           style={ { width: '100%', height: '100%', fill: isFocused ? gw_00 : gw_10 } }>
        <path
          d="M9.65759 0C9.08087 0 8.53222 0.248961 8.15244 0.682991L1.15244 8.68299C0.492644 9.43704 0.492644 10.563 1.15244 11.317L8.15244 19.317C8.53222 19.751 9.08087 20 9.65759 20H33.0001C34.1046 20 35.0001 19.1046 35.0001 18V2C35.0001 0.895431 34.1046 0 33.0001 0H9.65759ZM24.7811 5.88539C25.1484 6.25264 25.1484 6.84809 24.7811 7.21534L21.6632 10.3332L24.7812 13.4512C25.1484 13.8184 25.1484 14.4139 24.7812 14.7811C24.4139 15.1484 23.8185 15.1484 23.4512 14.7811L20.3333 11.6632L17.2154 14.7811C16.8481 15.1483 16.2527 15.1483 15.8854 14.7811C15.5182 14.4138 15.5182 13.8184 15.8854 13.4511L19.0033 10.3332L15.8855 7.21543C15.5182 6.84817 15.5182 6.25273 15.8855 5.88547C16.2528 5.51821 16.8482 5.51821 17.2155 5.88547L20.3333 9.00328L23.4512 5.88539C23.8184 5.51813 24.4139 5.51813 24.7811 5.88539Z"
        />
      </svg>
    </div>
  )
}