// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--D3CaQ {\n  overflow: hidden;\n}\n\n.image--LnsZL {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  overflow: hidden;\n}\n\n.ubdatable--byeXO {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  overflow: hidden;\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  will-change: opacity;\n}\n", "",{"version":3,"sources":["webpack://./src/app/ui/Image/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,2BAA2B;EAC3B,sBAAsB;EACtB,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,gBAAgB;EAChB,2BAA2B;EAC3B,sBAAsB;EACtB,4BAA4B;EAC5B,oBAAoB;AACtB","sourcesContent":[".container {\n  overflow: hidden;\n}\n\n.image {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  overflow: hidden;\n}\n\n.ubdatable {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  overflow: hidden;\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  will-change: opacity;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "container--D3CaQ";
export var image = "image--LnsZL";
export var ubdatable = "ubdatable--byeXO";
export default ___CSS_LOADER_EXPORT___;
