import CardCollection from '~typings/CardCollection';

import ContinueWatchingType from './ContinueWatchingType';
import FavoritesType from './FavoriteType';
import SearchFiltersGroup from './SearchFiltersGroup';


enum BlockType {
  BannersBlock = 'banners_block',
  BigBannersBlock = 'big_banners_block',
  CardCollectionBlock = 'card_collection_block',
  CollectionBlock = 'collection_block',
  ContinueWatchingBlock = 'continue_watching_block',
  CurrentProgramLineBlock = 'current_program_line_block',
  FavoriteAudioShowBlock = 'favorite_audio_shows_block',
  FavoriteChannelsBlock = 'favorite_channels_block',
  FavoriteMoviesBlock = 'favorite_movies_block',
  FavoriteSeriesBlock = 'favorite_series_block',
  FavoritesBlock = 'favorites_block',
  FiltersBlock = 'filters_block',
  MatchesCalendarBlock = 'matches_calendar_block',
  MatchesListBlock = 'matches_list_block',
  MiddleBannersBlock = 'middle_banners_block',
  RecommendationsBlock = 'recommendations_block',
  TextBlock = 'text_block',
  TournamentTablesBlock = 'tournament_tables_block',
  ExpandableCardColletion = 'expandable_card_collection_block'
}

type Block = Readonly<{
  id: string;
  // metadata: object;
  object: BlockType;
  title: string;
  collectionID: string | null;
  collection?: any;
  card_collection?: CardCollection;
  competition_id?: null | string;
  competition_stage_id?: null | string;
  channel_slug?: string;
  search_filters_group?: SearchFiltersGroup;
  resource_types?: FavoritesType[] | ContinueWatchingType[];
  recommendation_context?: string;
}>;


export {
  Block,
  BlockType,
};
