import * as cn from 'classnames';
import customization from 'customization-project-name/base-domain.json';
import * as React from 'react';

import { imageByType } from '~lib/image';
import getImageSRC from '~lib/MediaItemImageSRC';
import Card from '~typings/Card';
import ImageType from '~typings/ImageType';
import MediaItemDimension from '~typings/MediaItemDimension';
import cardItemOptions, { getRandomUpdateInterval } from '~ui/Card/Render/Image.helpers';
import * as styles from '~ui/MediaItem/styles.module.css';


type Props = Readonly<{
  item?: Card;
  dimension: MediaItemDimension;
  children?: any;
}>;


const CardItemImageUpdatable: React.FC<Props> = (props: Props) => {
  const timeout = React.useRef<any | null>(null);
  const { isChannelCardWide } = cardItemOptions(props.dimension, props.item);
  const [isNeedUpdateImage, setIsNeedUpdateImage] = React.useState<boolean>(false);

  const imageURL = React.useMemo(() => {
    const res = getImageSRC({
      dimension: props.dimension,
      mediaItem: props.item,
      isNeedToCrop: true,
      isDoubleQuality: true,
    });

    if (res !== null) {
      return `${res}?${Math.random()}`;
    }

    return res;
  }, [props.dimension, props.item?.id, isNeedUpdateImage]);

  React.useEffect(() => () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);
  React.useEffect(() => {
    const image4x3 = imageByType(props.item?.images || [], [ImageType.Card4x3, ImageType.Card16x9]);

    if (image4x3 && image4x3.update_interval) {
      if (isNeedUpdateImage) {
        setIsNeedUpdateImage(false);
      } else {
        timeout.current = setTimeout(
          () => {
            setIsNeedUpdateImage(true);
          },
          getRandomUpdateInterval(image4x3.update_interval * 1000)
        );
      }
    }
  }, [isNeedUpdateImage]);

  const renderRaitingCards = (str: string | null) => {
    if(customization === 'spbtv.com') {
      if(props.item?.certification_tags.length !== 0) {
        if(str?.includes("394x592") || str?.includes("328x492")) {
          return (
            <div className={ cn(styles.ratingText, styles.certRating) }>
                {props.item?.certification_tags[0]}
            </div>
          )
        } else {
          return (
          <div className={ cn(styles.ratingText, styles.certRating, styles.ratingTextHorizontal) }>
            {props.item?.certification_tags[0]}
          </div>
          )
        }
      }
    } else {
      return null;
    }
  }

  const sortedCards = renderRaitingCards(imageURL);


  const style: React.CSSProperties = {
    backgroundImage: `url('${imageURL}')`,
  };

  return (
    <div
      style={ style }
      className={ cn(styles.mediaItemImage, {
        [styles.channelCardWide]: isChannelCardWide,
      }) }
    >
      {sortedCards}
      {props.children}
    </div>
  );
};


export default CardItemImageUpdatable;
