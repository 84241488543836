import focuser from '@focuser';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import { AVAILABLE_SUBSCRIPTIONS, MY_SUBCRIPTIONS } from '~lib/localizator/src/dictionaries';
import { SpinnerFullScreen } from '~newapp/globalBlocks/spinner';
import { SliderVerticable } from '~ui/SliderVerticable';

import { useFocusedIdxForSlider, useGetProducts, useSubscriptionsNavigation } from './hooks';
import { ProductItem } from './ProductItem/ProductItem';
import * as styles from './Subscriptions.module.css';
import { isSubscriptionCancelable } from './utils';

export const Subscriptions = injectIntl(({ intl }) => {
  const { availableProducts, userSubscriptions, isLoading } = useGetProducts();

  const isSubscriptionCancelableOnIdx = useCallback(
    (subscriptionIdx: number) => {
      return isSubscriptionCancelable(userSubscriptions[subscriptionIdx]);
    },
    [userSubscriptions],
  );

  const {
    focusedState,
    handleForceFocusForSubMemo,
    handleForceFocusForProductsMemo,
    handleRememberFocusBeforeExit,
    ...focuserProps
  } = useSubscriptionsNavigation({
    availableProductsCount: availableProducts.length,
    userSubscriptionsCount: userSubscriptions.length,
    isLoading,
    isSubscriptionCancelableOnIdx,
  });

  const sliderIdx = useFocusedIdxForSlider(focusedState, userSubscriptions.length);

  if (isLoading) {
    return (
      <focuser.Isolation>
        <SpinnerFullScreen />
      </focuser.Isolation>
    );
  }

  const renderSubscriptions = () => {
    if (!userSubscriptions.length) {
      return null;
    }

    return (
      <>
        <Typography
          variant="h5_bold"
          className={ styles.subscriptionsTitle }
        >
          {intl.formatMessage({ id: MY_SUBCRIPTIONS })}
        </Typography>
        {userSubscriptions.map((subscription, idx) => (
          <ProductItem
            key={ subscription.id }
            product={ subscription.plan.product }
            indexForForceFocus={ idx }
            onHistoryChange={ handleRememberFocusBeforeExit }
            onForceFocus={ handleForceFocusForSubMemo }
            subscription={ subscription }
            focusedOn={
              focusedState.focusedSection === 'subscriptions' && focusedState.focusedLine === idx
                ? focusedState.focusedOn
                : null
            }
            showBottomSeperator={ idx === userSubscriptions.length - 1 }
          />
        ))}
      </>
    );
  };

  const renderProducts = () => {
    if (!availableProducts.length) {
      return null;
    }

    return (
      <>
        <Typography
          variant="h5_bold"
          className={ cn(styles.productsTitle, {
            [styles.productsTitleIfSubscription]: Boolean(userSubscriptions.length),
          }) }
        >
          {intl.formatMessage({ id: AVAILABLE_SUBSCRIPTIONS })}
        </Typography>
        {availableProducts.map((product, idx) => (
          <ProductItem
            key={ product.id }
            product={ product }
            indexForForceFocus={ idx }
            onHistoryChange={ handleRememberFocusBeforeExit }
            onForceFocus={ handleForceFocusForProductsMemo }
            focusedOn={
              focusedState.focusedSection === 'products' && focusedState.focusedLine === idx
                ? focusedState.focusedOn
                : null
            }
            showBottomSeperator={ idx === availableProducts.length - 1 }
          />
        ))}
      </>
    );
  };

  return (
    <focuser.FocusableBlock
      { ...focuserProps }
      className={ styles.SubscriptionsTab }
      isFocused
      noNeedForceFocus
    >
      <SliderVerticable
        focusedIndex={ sliderIdx }
        wrapperClassName={ styles.sliderWrapper }
        sliderClassName={ styles.slider }
      >
        {renderSubscriptions()}
        {renderProducts()}
      </SliderVerticable>
    </focuser.FocusableBlock>
  );
});

Subscriptions.displayName = 'Subscriptions';
