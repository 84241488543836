import cn from 'classnames';
import React from 'react';

import { Nullable } from '~lib/type-utils/utils';
import { ProgressBar } from '~newapp/ui-kit/Other/ProgressBar';
import { Modification } from '~typings/Card';
import { CardPlayIcons } from '~ui/Icon/icons/CardPlayIcons';

import { CardSimpleLabel } from '../types';
import { HorizontalCardImage, HorizontalImageType } from './HorizontalCardImage';
import * as css from './styles.module.css';



export interface PropsTS {
  /**
   * Сфокусирована ли карточка (влияет на отображение)
   */
  isFocused: boolean;
  /**
   * Иконка провайдера, будет отображена в углу карточки
   */
  contentProviderIconUrl?: Nullable<string>;
  /**
   * Номер эпизода, будет отображен в углу карточки
   */
  episodeNumber?: Nullable<string>;
  /**
   * Прогресс карточки, будет отображен, даже если отрицательное значение
   */
  progress?: Nullable<number>;
  /**
   * Главное наименование карточки
   */
  name?: Nullable<string>;
  /**
   * Дополнительное наименование карточки
   */
  additionalName?: Nullable<string>;
  /**
   *  Тег карточки, будет отображен в углу карточки
   */
  label?: Nullable<CardSimpleLabel>;
  /**
   * Дисклеймер возраста, передавать как строку, например "16+"
   */
  certificationTag?: Nullable<string>;
  /**
   * Иконка которая будет отображена по середине карточки
   * Не все значения поддерживаются, смотреть поддержку в {@link CardPlayIcons}
   */
  modification?: Nullable<Modification>;
  /**
   * Изображение карточки, тип влияет на то, как будет отображаться изображение
   * Полностью, загругленное, или квадратное по середине
   */
  image?: Nullable<HorizontalImageType>;
  /**
   * Показывать ли ярлычок, чтобы карточка выглядела как стопка
   */
  showCatalogHeader: boolean;
  /**
   * Показывать ли блок текста внизу карточки
   */
  showBottomTextBlock?: boolean;

  /**
   * Включена ли анимация
   *
   * По умолчанию `true`
   */
  isAnimationEnabled?: boolean;
}

export const HorizontalCard = React.memo((props: PropsTS)=> {
  const {
    isFocused,
    showCatalogHeader,
    contentProviderIconUrl,
    episodeNumber,
    progress,
    showBottomTextBlock,
    additionalName,
    name,
    image,
    label,
    certificationTag,
    modification,
    isAnimationEnabled = true,
  } = props;


  return (
    <div className={ cn(css.card, {
      [css.cardAnimated]: isAnimationEnabled,
    }) }>
      <div className={ css.imageContainer }>
          <div
            className={ cn(css.catalogHeader, {
              [css.catalogHeaderFocused]: isFocused,
              [css.catalogHeaderVisible]: showCatalogHeader,
            }) }
          />
        <div
          className={ cn(
            css.imageContent,
            isFocused ? css.imageContentFocus : css.imageContentUnfocus,
          ) }
        >
          {image && <HorizontalCardImage image={ image } />}
          {
            // Фон который накрывает карточку, замутняя ее изображение
            modification && (
              <div
                className={ cn({
                  [css.imageOverlay]: !isFocused,
                }) }
              />
            )
          }
          <div className={ css.imageContentInnerIcons }>
            {label && (
              <div
                className={ cn(css.label, css.textOverflow) }
                style={ {
                  backgroundColor: label.backgroundColor ?? '#000',
                  color: label.textColor ?? '#fff',
                } }
              >
                {label.text.toUpperCase() || ''}
              </div>
            )}
            {contentProviderIconUrl && (
              <div
                className={ css.contentProviderIcon }
                style={ { backgroundImage: `url("${contentProviderIconUrl}")` } }
              ></div>
            )}
            {modification && (
              <div className={ css.playIcon }>
                <CardPlayIcons
                  isFocused={ isFocused }
                  modification={ modification }
                />
              </div>
            )}
            <div className={ cn(css.bottomIconsWrapper) }>
              <div className={ cn(css.episodeCertTagWrapper) }>
                {episodeNumber !== undefined && (
                  <div className={ css.episodeNumber }>{episodeNumber}</div>
                )}
                {certificationTag && (
                  <div className={ css.certTagWrapper }>
                    <div className={ css.certTag }>{certificationTag}</div>
                  </div>
                )}
              </div>
              {typeof progress === 'number' ? (
                <ProgressBar
                  progress={ progress }
                  className={ css.progress }
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {showBottomTextBlock && (
        <div className={ css.textContainer }>
          {name && (
            <div
              className={ cn(
                css.textOverflow,
                css.name,
                {
                  [css.nameFocus]: isFocused,
                },
              ) }
            >
              {name}
            </div>
          )}
          {additionalName && (
            <div
              className={ cn(
                css.textOverflow,
                css.addName,
                {
                  [css.addNameFocus]: isFocused,
                },
              ) }
            >
              {additionalName}
            </div>
          )}
        </div>
      )}
    </div>
  );
})

HorizontalCard.displayName = 'HorizontalCard';
