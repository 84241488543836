import { uniqBy } from 'lodash';

import VideoTrack from '~typings/VideoTrack';


const sortVideoTracks = (videoTracks: VideoTrack[]): VideoTrack[] => {
  try {
    return (
      uniqBy(
        videoTracks
          .sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
            // if (a.name < b.name) {
            //   return -1;
            // } else if (a.name > b.name) {
            //   return 1;
            // }
            //
            // return 0;
          }),
        'name',
      )
    );
  } catch (ignore) {
    return videoTracks;
  }
};


export default sortVideoTracks;
