import { compact, get } from 'lodash';
import * as React from 'react';

import SceneItem from '~components/Scene/typings';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import { isCard } from '~lib/objectType';
import { getCertificationRating } from '~lib/ratings';
import BannerBig from '~typings/BannerBig';
import BannerMiddle from '~typings/BannerMiddle';
import Card from '~typings/Card';
import ItemObject from '~typings/ItemObject';
import Rating, { RatingType, RatingTypeCaseSensitive } from '~typings/Rating';

import RatingIMDB from './icons/imdb';
import RatingKinopoisk from './icons/kinopoisk';
import RatingUser from './icons/likespb';


export type RatingParsed = Readonly<{
  id: string;
  value: number | string;
  icon: JSX.Element | null;
}>;

const getRatings = (item: SceneItem | BannerBig | BannerMiddle): RatingParsed[] => {
  try {
    if (item.object === ItemObject.BigBanner || item.object === ItemObject.MiddleBanner) {
      const res: Array<RatingParsed> = [];

      const imdb = (
        item.big_screen_text_block.ratings?.[RatingTypeCaseSensitive.IMDB] ||
        item.big_screen_text_block.ratings?.[RatingTypeCaseSensitive.IMDB.toLowerCase()]
      );
      const kinopoisk = (
        item.big_screen_text_block.ratings?.[RatingTypeCaseSensitive.Kinopoisk] ||
        item.big_screen_text_block.ratings?.[RatingTypeCaseSensitive.Kinopoisk.toLowerCase()]
      );
      const users = (
        item.big_screen_text_block.ratings?.[RatingTypeCaseSensitive.Users] ||
        item.big_screen_text_block.ratings?.[RatingTypeCaseSensitive.Users.toLowerCase()]
      );
      if (imdb) {
        res.push({
          id: RatingTypeCaseSensitive.IMDB,
          value: imdb as string | number,
          icon: <RatingIMDB />,
        });
      }
      if (users) {
        res.push({
          id: RatingTypeCaseSensitive.Users,
          value: users as string | number,
          icon: <RatingUser />,
        });
      }
      if (kinopoisk) {
        res.push({
          id: RatingTypeCaseSensitive.Kinopoisk,
          value: kinopoisk as string | number,
          icon: <RatingKinopoisk />,
        });
      }

      return res;
    }

    return compact(
      (
        (
          get(item, 'ratings')
          || (item.object === ItemObject.Episode && item?.series?.ratings)
          || EMPTY_ARRAY
        ) as Rating[]
      )
        .map((rating: Rating) => {
          if (rating.source === RatingType.Kinopoisk) {
            return ({
              id: rating.source,
              value: rating.value,
              icon: <RatingKinopoisk />,
            });
          } else if (rating.source === RatingType.IMDB) {
            return ({
              id: rating.source,
              value: rating.value,
              icon: <RatingIMDB />,
            });
          } else if (rating.source === RatingType.Users) {
            return ({
              id: rating.source,
              value: rating.value,
              icon: <RatingUser />,
            });
          }

          return null;
        }) as Array<RatingParsed | null>
    );
  } catch (ignore) {
    return EMPTY_ARRAY;
  }
};

export const getCertificationRatingIcon = (
  item: SceneItem | BannerBig | BannerMiddle,
  ratingSystem: string
): RatingParsed | null => {
  let ratingTextValue: string | undefined = undefined;

  if (isCard(item)) {
    ratingTextValue = (item as Card).certification_tags[0];
  } else {
    ratingTextValue = getCertificationRating(item, ratingSystem)?.tag;
  }

  if (ratingTextValue) {
    return ({
      id: 'certification',
      value: ratingTextValue,
      icon: null,
    })
  }

  return null;
};

export {
  getRatings,
};
