import focuser from '@focuser';
import * as React from 'react';
import { useCallback, useState } from 'react';

import { TextEditInputFocuser } from '~app/ui-kit/TextEditInput';
import {
  FocuserKeyboard,
  FocuserKeyboardAction,
  FocuserKeyboardProps,
  HideBtnState,
} from '~components/FocuserKeyboard';
import usePassword from '~hooks/usePassword';

import * as styles from './styles.module.css';

export type Props = Readonly<{
  /**
   * Начальное значение для инпута
   */
  initialValue?: string;
  /**
   * Подсказка для инпута
   */
  placeholder?: string;
  /**
   * Коллбэк, когда будет нажата кнопка "ОК"
   */
  onDone: (value: string) => void;
  /**
   * Какую раскладку использовать
   */
  initialKeyboardLayout: FocuserKeyboardProps['initialKeyboardLayout'];
  /**
   * Скрывать ли символы
   */
  hideChars?: boolean;

  /**
   * Коллбэк, когда будет изменено значение
   * Нужен для сценариев, когда нужно понимать, что значение изменилось, и сделать что-то
   * (например скрыть ошибку)
   */
  onValueChange?: (newValue: string) => void;

  /**
   * Максимальная длина строки, текст не будет набираться дальше
   */
  maxLength?: number;
}>;

export const InputFormFocuser: React.FC<Props> = React.memo(({
  initialValue,
  initialKeyboardLayout,
  placeholder,
  hideChars = false,
  onValueChange,
  maxLength,
  onDone,
}) => {
  const [focusOn, setFocusOn] = useState<'input' | 'keyboard'>('keyboard');
  const [inputValue, setInputValue] = useState<string>(initialValue ?? '');
  const isInputHasValue = inputValue.length > 0;

  const [hideButtonState, setHideButtonState] = useState<HideBtnState>(hideChars ? 'hidden': 'unnecessary');

  const inputHandler = useCallback((text: string) => {
    setInputValue((i) => (i + text).slice(0, maxLength));
  }, [maxLength]);

  React.useEffect(() => {
    onValueChange?.(inputValue);
  }, [inputValue]);

  // Ref нужен для того, чтобы не ломать мемоизацию actionHandler-а
  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;
  const actionHandler = useCallback((action: FocuserKeyboardAction) => {
    switch (action) {
      case 'backspace':
        setInputValue((i) => i.slice(0, -1));
        break;
      case 'hide':
        setHideButtonState('hidden');
        break;
      case 'show':
        setHideButtonState('shown');
        break;
      case 'confirm':
        onDone(inputValueRef.current);
        break;
    }
  }, [onDone]);

  const escapedValue = usePassword({
    password: inputValue,
    isShowingPassword: hideChars ? hideButtonState !== 'hidden' : true,
  });

  return (
    <>
      <TextEditInputFocuser
        isFocused={ focusOn === 'input' }
        onClick={ () => {
            setInputValue('');
            setFocusOn('keyboard')
        } }
        value={ escapedValue }
        placeholder={ placeholder }
        onKeyDown={ (e) => {
          setFocusOn('keyboard');
          e.stop();
        } }
        onForceFocus={ ()=> {
          if(isInputHasValue){
            setFocusOn('input');
          }
        } }
        emitForceFocusOnHover
      />
      <focuser.FocusableBlock
        className={ styles.keyboard }
        isFocused={ focusOn === 'keyboard' }
        onForceFocus={ ()=> {
          setFocusOn('keyboard');
        } }
        onKeyUp={ ()=> {
          if(isInputHasValue){
            setFocusOn('input');
          }
        } }
      >
        <FocuserKeyboard
          initialKeyboardLayout={ initialKeyboardLayout }
          onInput={ inputHandler }
          onAction={ actionHandler }
          hideBtnState={ hideChars ? hideButtonState : 'unnecessary' }
        />
      </focuser.FocusableBlock>
    </>
  );
});

InputFormFocuser.displayName = 'InputFormFocuser';
