import * as React from 'react';

import {
  getCachedCardMutation,
  useCardMutations,
} from '~hooks/fetch/useCardMutations';
import useCurrentEventCard from '~hooks/useCurrentEventCard';
import { isCardChannel } from '~lib/objectType';
import Card from '~typings/Card';

import CurrentEventInfoRender from './render';


type Props = Readonly<{
  item: Card;
  withAnimation?: boolean;
  isHidden?: boolean;
  isBigSize?: boolean;
  isFullSize?: boolean;
  withIcon?: boolean;
  fadeDuration?: number;
  fadeDelay?: number;
}>;


const CurrentEventInfoCard: React.FC<Props> = (props: Props) => {
  useCardMutations(
    (props.item?.id && props.item?.mutable) ? props.item.id : undefined,
    props.item.card_config_id,
    isCardChannel(props.item),
  );
  const mutations = props.item.mutable ? getCachedCardMutation(props.item?.id, props.item.card_config_id) : undefined;
  const mutation = useCurrentEventCard(mutations);
  const isDisableProgressLine = (mutation?.disable_progress_line === true);

  const name = (props.item.mutable)
    ? mutation?.card.name || props.item.name || undefined
    : undefined;

  const eventName = (props.item.mutable)
    ? mutation?.card.additional_name || undefined
    : props.item.additional_name || undefined

  return (
    <CurrentEventInfoRender
      name={ name }
      eventName={ eventName }
      startAt={ isDisableProgressLine ? undefined : mutation?.start_at || undefined }
      endAt={ isDisableProgressLine ? undefined : mutation?.end_at || undefined }
      withAnimation={ props.withAnimation }
      isHidden={ props.isHidden }
      isBigSize={ props.isBigSize }
      isFullSize={ props.isFullSize }
      withIcon={ props.withIcon }
      fadeDuration={ props.fadeDuration }
      fadeDelay={ props.fadeDelay }
    />
  );
};


export default CurrentEventInfoCard;
