import * as React from 'react';

import useNavigationByKeys from '~hooks/useNavigation';
import { KeyBoardKey } from '~typings/Keyboard';
import NavigationDirection from '~typings/NavigationDirection';
import { getClosestEnabledHorizontalIndex, haveEnabledCells, } from '~ui/KeyBoard/navigation.helpers';


export type KeyPosition = Readonly<{
  x: number;
  y: number;
}>;

type Props = Readonly<{
  forced: KeyPosition | null;
  keys: KeyBoardKey[][];
  isHorizontalLoopAllowed?: boolean;
  isVerticalLoopAllowed?: boolean;
  isFocused?: boolean;
  onLeaveKeyboard?: (direction: NavigationDirection) => void;
  onFocusChanged: (focus: KeyPosition) => void;
}>;


const KeyBoardNavigation: React.FC<Props> = (props: Props) => {
  const timeout = React.useRef<any | null>(null);
  const [focused, setFocused] = React.useState<KeyPosition>({ x: 0, y: 0 });

  const handleVerticalOffset = (direction: NavigationDirection.Up | NavigationDirection.Down): void => {
    const isUpDirection = direction === NavigationDirection.Up;
    const isItBorderLine = (isUpDirection) ? (focused.y === 0) : (focused.y === (props.keys.length - 1));
    const state = {
      x: focused.x,
      y: focused.y,
    };

    if (props.isVerticalLoopAllowed && isItBorderLine) {
      state.y = (isUpDirection) ?
        (props.keys.length - 1)
        :
        0;
    }
    else if (!isItBorderLine) {
      if (isUpDirection) {
        for (let lineIndex = (focused.y - 1); lineIndex >= 0; lineIndex -= 1) {
          if (haveEnabledCells(props.keys[lineIndex])) {
            state.y = lineIndex;

            break;
          }
        }
      }
      else {
        for (let lineIndex = (focused.y + 1); lineIndex < props.keys.length; lineIndex += 1) {
          if (haveEnabledCells(props.keys[lineIndex])) {
            state.y = lineIndex;

            break;
          }
        }
      }

      if (props.keys[state.y][state.x].disabled) {
        state.x = getClosestEnabledHorizontalIndex(props.keys[state.y], state.x);
      }
    }

    const currentLine = props.keys[state.y];

    if (!props.isVerticalLoopAllowed && props.onLeaveKeyboard && state.y === focused.y) {
      timeout.current = setTimeout(() => {
        if (props.onLeaveKeyboard) {
          props.onLeaveKeyboard(direction);
        }
      }, 100);
    }

    state.x = Math.min(state.x, (currentLine.length - 1));

    setFocused(state);
  };
  const handleHorizontalOffset = (direction: NavigationDirection.Right | NavigationDirection.Left): void => {
    const focusedLine = props.keys[focused.y];
    const isRightDirection = direction === NavigationDirection.Right;
    const getEnabledHorizontalIndex = (x): number => {
      const isItBorderLine = (
        (isRightDirection) ?
          (x === (focusedLine.length - 1))
          :
          (x === 0)
      );

      if (props.isHorizontalLoopAllowed && isItBorderLine) {
        const index = (isRightDirection) ? 0 : (focusedLine.length - 1);

        if (!focusedLine[index].disabled) {
          return index;
        }
        else {
          return getEnabledHorizontalIndex(index);
        }
      }

      const index = (isRightDirection) ?
        Math.min((x + 1), (focusedLine.length - 1))
        :
        Math.max((x - 1), 0);

      if (!props.isHorizontalLoopAllowed && props.onLeaveKeyboard && index === focused.x) {
        timeout.current = setTimeout(() => {
          if (props.onLeaveKeyboard) {
            props.onLeaveKeyboard(direction);
          }
        }, 100);
      }

      if (!focusedLine[index].disabled) {
        return index;
      }
      else {
        return getEnabledHorizontalIndex(index);
      }
    };

    setFocused({
      x: getEnabledHorizontalIndex(focused.x),
      y: focused.y,
    });
  };



  const handleKeyNavigate = (direction): void => {
    if (props.isFocused) {
      switch (direction) {
        case NavigationDirection.Up: {
          handleVerticalOffset(NavigationDirection.Up);
          break;
        }
        case NavigationDirection.Right: {
          handleHorizontalOffset(NavigationDirection.Right);
          break;
        }
        case NavigationDirection.Down: {
          handleVerticalOffset(NavigationDirection.Down);
          break;
        }
        case NavigationDirection.Left: {
          handleHorizontalOffset(NavigationDirection.Left);
          break;
        }
        default:
          break;
      }
    }
  };

  // const throttledHandleKeyNavigate = React.useCallback(
  //   throttle(handleKeyNavigate, 0, { trailing: false }),
  //   [focused.x, focused.y, props.isFocused],
  // );

  useNavigationByKeys({
    isMounted: !!props.isFocused,
    onNavigation: handleKeyNavigate,
  }, [focused.x, focused.y, props.isFocused]);

  React.useEffect(() => {
    return (() => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    });
  }, []);

  React.useEffect(() => {
    if (props.forced !== null) {
      setFocused(props.forced);
    }
  }, [props.forced]);

  React.useEffect(() => {
    props.onFocusChanged(focused);
  }, [focused]);

  return null;
};


export default React.memo(KeyBoardNavigation);
