import {adc_01, gw_02} from 'customization-project-name/colors.js'
import * as React from "react";

interface Props {
  children: React.ReactNode
}

export function Alert2css(props: Props) {
  return <div style={ {
    width: '100vw',
    backgroundColor: gw_02,
    color: adc_01,
    display: 'flex',
    alignItems: 'center',
    marginTop: '4.1666vh',
    fontSize: '1.5625vw',
    fontWeight: 500,
    paddingTop: '1.40625vw',
    paddingBottom: '1.40625vw',
    paddingLeft: '6.25vw',
    paddingRight: '6.25vw',
  } }>
    { props.children }
  </div>
}
