import { memoize } from "lodash";

import { horizontalImageWidthVw, squareCardWidthVw, verticalCardWidthVw } from "~app/variables";
import { CardConfigLayout } from "~typings/ScreenCardConfigs";



const widthByLayout: Record<CardConfigLayout, number> = {
  [CardConfigLayout.Vertical]: verticalCardWidthVw,
  [CardConfigLayout.Horizontal]: horizontalImageWidthVw,
  [CardConfigLayout.Square]: squareCardWidthVw,
  [CardConfigLayout.News]: horizontalImageWidthVw,
  [CardConfigLayout.NewsWithoutPicture]: horizontalImageWidthVw,
}


const maxInDOMByLayout : Record<CardConfigLayout, number> = {
  [CardConfigLayout.Vertical]: 18,
  [CardConfigLayout.Horizontal]: 14,
  [CardConfigLayout.Square]: 18,
  [CardConfigLayout.News]: 14,
  [CardConfigLayout.NewsWithoutPicture]: 14,
}


export const getSliderPropsForCards = memoize((layout: CardConfigLayout) => {
  return {
    itemWidth: widthByLayout[layout],
    maxInDOM: maxInDOMByLayout[layout],
    recalcStep: 6
  };
})
