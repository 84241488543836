import * as cn from 'classnames';
import * as React from 'react';

import IconAudio from '../icons/audio';
import IconCannels from '../icons/channels';
import IconEPG from '../icons/epg';
import IconFavoriteOff from '../icons/favorite-off';
import IconFavorite from '../icons/favorite-on';
import IconInfo from '../icons/info';
import IconSeries from '../icons/series';
import IconSettings from '../icons/settings';
import IconSubtitles from '../icons/subtitles';
import { IconBar } from '../usePlayerControlsNavigation';
import * as styles from './styles.module.css';


type Props = Readonly<{
  icon: IconBar;
  isFocused: boolean;
  onClick: (item: IconBar) => void;
}>;


const IconsBarItem: React.FC<Props> = (props: Props) => {
  const handleClick = React.useCallback(() => {
    props.onClick(props.icon);
  }, [props.onClick, props.icon]);

  const renderIcon = React.useMemo(() => {
    switch (props.icon) {
      case IconBar.EPG: {
        return (<IconEPG />);
      }
      case IconBar.Info: {
        return (<IconInfo />);
      }
      case IconBar.AudioTracks: {
        return (<IconAudio />);
      }
      case IconBar.FavoriteToggle: {
        return (<IconFavorite />);
      }
      case IconBar.FavoriteToggleOff: {
        return (<IconFavoriteOff />);
      }
      case IconBar.ChannelsList: {
        return (<IconCannels />);
      }
      case IconBar.TextTracks: {
        return (<IconSubtitles />);
      }
      case IconBar.VideoTracks: {
        return (<IconSettings />);
      }
      case IconBar.EpisodesList: {
        return (<IconSeries />);
      }
      default: return null;
    }
  }, [props.icon]);

  return (
    <div
      className={ cn(styles.iconsBarItem, {
        [styles.focused]: props.isFocused,
        'focusedNavigationNode': props.isFocused,
      }) }
      onClick={ handleClick }
    >
      { renderIcon }
    </div>
  )
};


export default React.memo(IconsBarItem);
