import * as React from 'react';

import { getBigText } from "~components/ScenePlayer/Player/controls/PlayerMediaItemShortInfo/utils";
import { isChannel } from "~components/ScenePlayer/Player/utils";
import { arrBinarySearchCheck, useTodayProgramEvents } from "~hooks/fetch/useProgramEvents/useProgramEvents";
import { usePlayerStore } from "~stores/Player";
import PlayerMediaItem from '~typings/PlayerMediaItem';

const useDynamicTitle = (item: PlayerMediaItem) => {
  // TODO: отрефачить, уйти от бинарного поиска в сторону простой фильтрации
  //const currentEvent = useCurrentEvent((props.item.object === ItemObject.Channel) ? props.item.id : undefined);
  const { setCurrentProgram, isLive, currentProgram, searchDate, playPauseButton } = usePlayerStore()
  const { isLoading } = useTodayProgramEvents(isChannel(item) ? item.id : undefined);

  React.useEffect(() => {
    if (isLive) {
      const currentProgram = arrBinarySearchCheck(item.id)
      setCurrentProgram(currentProgram)
    }
  }, [isLoading, isLive])


  React.useEffect(() => {
    let timeOut: number

    if (currentProgram && playPauseButton && currentProgram.length === 2) {
      const futureDateStart = !currentProgram[1] ? currentProgram[0]['end_at'] : currentProgram[1]['start_at']
      const futureDateStartInMs = new Date(futureDateStart).getTime()
      const presentTime = new Date().getTime()
      let diff
      if (searchDate === 0) {
        diff = futureDateStartInMs - presentTime
      }
      else {
        diff = futureDateStartInMs - searchDate
      }
      timeOut = window.setTimeout(() => {
        const crnt = arrBinarySearchCheck(item.id, futureDateStartInMs)
        setCurrentProgram(crnt)
      }, diff + 5000)
    }
    return () => {
      clearTimeout(timeOut)
    }
  }, [currentProgram, playPauseButton])


  return currentProgram ? getBigText(item, currentProgram[0]) : null
};

export { useDynamicTitle }
