import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import TrialFreePeriodAndPriceText from "~components/ProductItem/Texts/TrialFreePeriodAndPriceText";
import { useApp } from '~components/Provider/App';
import { getShortDate } from '~lib/localeUtils/dates';
import getMinPrice from '~lib/product/getMinPrice';
import { getActiveSubscriptionWithProduct } from '~lib/product/getProductInSubscriptions';
import {
  ALREADY_AVAILABLE,
  BUY_ACTION,
  CHOICE_SUBSCRIBTION,
  EXPIRES_ON_DATE,
  FREE,
  NEXT_BILLING_DATE,
  PRODUCT_SELECT_PLAN_BUTTON,
  SUBSCRIPTION_NO_ACCESS_GRANTED,
  TRY_FOR_FREE,
} from '~localization';
import Product from '~typings/Product';
import Subscription from '~typings/Subscription';
import * as buttonStyles from '~ui/button/styles.module.css';

import * as styles from './styles.module.css';


type Props = {
  product: Product;
  subscriptions?: Subscription[] | undefined;
  isFocused: boolean;
  onClick: (productID: string) => void;
  intl: InjectedIntl;
};

const ProductItem: React.FC<Props> = ({ product, subscriptions, isFocused, onClick, intl }) => {
  const activeSubscription = getActiveSubscriptionWithProduct(product.id, subscriptions);
  const { language } = useApp();
  const minPriceWithPeriod = React.useMemo(() => getMinPrice(product), [product]);
  const [text, setText] = React.useState<string | React.ReactNode | null>(null);
  const [price, setPrice] = React.useState<string | null>(null);
  const hasOnlyPlan = (product.plans.length === 1);

  React.useEffect(() => {
    let newPrice: string | null = null;
    if (minPriceWithPeriod === 'free') {
      newPrice = intl.formatMessage({ id: FREE });
    } else if (minPriceWithPeriod !== null) {
      newPrice = intl.formatMessage(
        {
          id: PRODUCT_SELECT_PLAN_BUTTON,
        }, {
          price: minPriceWithPeriod.money.formatted,
          durationValue: minPriceWithPeriod.period.value,
          durationUnit: minPriceWithPeriod.period.unit,
        }
      );
    } else if (!hasOnlyPlan) {
      newPrice = intl.formatMessage({ id: CHOICE_SUBSCRIBTION });
    } else {
      newPrice = intl.formatMessage({ id: BUY_ACTION });
    }

    setPrice(newPrice);
  }, [minPriceWithPeriod, hasOnlyPlan]);

  React.useEffect(() => {
    let newText: string | React.ReactNode | null = product.subscription_page_subtitle;

    if (activeSubscription) {
      const isActive = activeSubscription.status === 'active';
      const expiresAt = activeSubscription.expires_at;
      const accessGranted = activeSubscription.access_granted;
      const autorenewable = activeSubscription.autorenew.autorenewable;

      if (!accessGranted) {
        newText = intl.formatMessage({ id: SUBSCRIPTION_NO_ACCESS_GRANTED });
      } else if (isActive && autorenewable && expiresAt) {
        newText = (
          intl.formatMessage({ id: NEXT_BILLING_DATE }, {
            date: getShortDate(expiresAt, language),
          })
        );
      } else if (
        (isActive && !autorenewable && expiresAt) ||
        (!isActive && expiresAt)
      ) {
        newText = (
          intl.formatMessage({ id: EXPIRES_ON_DATE }, {
            date: getShortDate(expiresAt, language),
          })
        );
      } else if (
        activeSubscription?.phase?.type === 'trial'
        && expiresAt
      ) {
        newText = (
          <TrialFreePeriodAndPriceText
            isTrialPeriod={ !!(activeSubscription?.phase?.type === 'trial') }
            price={ {
              value: activeSubscription?.plan.eligible_phase?.billing.price.formatted,
              duration: (activeSubscription?.plan.eligible_phase?.access_period.value || 30),
              unit: (activeSubscription?.plan.eligible_phase?.access_period.unit || 'days'),
            } }
            autoReNew={ activeSubscription.autorenew }
          />
        );
      }
    }

    setText(newText);
  }, [activeSubscription, product]);

  const handleClick = () => {
    if (activeSubscription) { return; }

    onClick(product.id);
  };

  return (
    <div
      className={ cn(styles.plan, styles.product, {
        [styles.isFocused]: isFocused,
        'focusedNavigationNode': isFocused,
      }) }
      onClick={ handleClick }
    >
      <div>
        { product.name }
      </div>
      <div className={ styles.price }>
        { price }
      </div>
      <div
        className={ styles.subtitle }
      >
        { text }
      </div>
      <div
        className={ cn(buttonStyles.button, styles.button, {
          [buttonStyles.focused]: !activeSubscription && isFocused,
          [styles.isFocused]: !activeSubscription && isFocused,
          [styles.disabled]: activeSubscription,
        }) }
      >
        {
          intl.formatMessage({
            id: activeSubscription ?
              ALREADY_AVAILABLE
              : (minPriceWithPeriod === 'free') ?
              TRY_FOR_FREE
              : CHOICE_SUBSCRIBTION
          })
        }
      </div>
    </div>
  );
};


export default React.memo(injectIntl(ProductItem));
