import * as React from 'react';

import { requestFrames } from '~lib/animation';

import { AnyFocuserKeyEvent } from './events';

/**
 * Хук помогает затротлить обработчик события фокусера
 * Тротлит по времени и по rAF
 */
export const useFocuserThrottledHandler = <T extends AnyFocuserKeyEvent>(
  eventHandler: (event: T) => void,
  delay = 200,
  frames = 10,
) => {
  const isBlockedRef = React.useRef(false);
  const isBlockedByFramesRef = React.useRef(false);

  return (event: T) => {
    if (isBlockedRef.current || isBlockedByFramesRef.current) {
      event.stop();
      event.stopNativeEvent();
      return;
    }
    isBlockedRef.current = true;
    isBlockedByFramesRef.current = true;
    setTimeout(() => {
      isBlockedRef.current = false;
    }, delay);
    requestFrames(frames).then(() => {
      isBlockedByFramesRef.current = false;
    });
    eventHandler(event);
    return;
  };
};

/**
 * Хук помогает затротлить обработчик события фокусера
 */
export const useFocuserTimeoutHandler = <T extends AnyFocuserKeyEvent>(
  eventHandler: (event: T) => void,
  delay = 200,
) => {
  const isBlockedRef = React.useRef(false);
  return (event: T) => {
    if (isBlockedRef.current) {
      event.stop();
      event.stopNativeEvent();
      return;
    }
    isBlockedRef.current = true;
    setTimeout(() => {
      isBlockedRef.current = false;
    }, delay);
    eventHandler(event);
    return;
  };
};

/**
 * Хук помогает запускать обработчик события тормозируя по экспоненте
 * Помогает сделать небольшую задержку для навигации
 */
export const useFocuserExpHandler = <T extends AnyFocuserKeyEvent>(
  eventHandler: (event: T) => void,
  delayParams?: {
    maxDelay: number;
    step: number;
    dropTimeout: number;
    minDelay: number;
  },
) => {
  const { maxDelay, step, dropTimeout, minDelay } = delayParams || defaultDelayParams;

  const lastTimeCalled = React.useRef(Date.now());
  const currentDelay = React.useRef(maxDelay);

  return (event: T) => {
    const currentTime = Date.now();
    const diff = currentTime - lastTimeCalled.current;
    if (diff >= dropTimeout) {
      currentDelay.current = maxDelay;
    }

    if (diff > currentDelay.current) {
      currentDelay.current = Math.max(minDelay, currentDelay.current - step);
      lastTimeCalled.current = currentTime;
      eventHandler(event);
      return;
    }
    event.stop();
    event.stopNativeEvent();
  };
};

const defaultDelayParams = {
  maxDelay: 250,
  minDelay: 50,
  step: 15,
  dropTimeout: 500,
};
