import classnames from 'classnames';
import React from 'react';

import {
  SliderImperativeRefType,
  useSliderImperativeHandle,
  useTransitionStyleChanger,
  useVirtualChilds,
  useVirtualOffsetChanger,
} from './hooks';
import * as styles from './SliderVerticable.module.css';

export type SliderVerticableVirtualProps = {
  /**
   * Индекс сфокусированного элемента
   */
  focusedIndex: number;

  /**
   * Сколько должна длится анимация перелистывания
   * @default 200
   */
  animationDurationInMS?: number;

  /**
   * Если какое-то действие изменяет ширину слайдов, то через этот массив можно оповестить об изменениях
   * Подробнее смотреть в Storybook
   */
  updates?: unknown[];

  /**
   * Дополнительный класс для обертки слайдера
   */
  wrapperClassName?: string;

  /**
   * Дополнительный класс для слайдера
   */
  sliderClassName?: string;

  /**
   * Полное количество дочерних элементов
   */
  childsCount: number;

  /**
   * Функция для отрисовки дочерних элементов
   * idx => индекс дочернего элемента
   * Возвращать нужно сам дочерний элемент
   */
  renderChild: (idx: number) => React.ReactNode;

  /**
   * Высота одного элемента
   * Если между элементами есть пробелы, то сюда нужно передавать ширину с учетом пробелов
   *
   * Указывать в VW или в VH, в зависимости от пропа `useVW`
   *
   * для карточек использовать утилитку getSliderPropsForCards
   */
  itemHeight: number;

  /**
   * Считать `itemHeight` в VW, по умолчанию счиется в vh
   *
   * @default false
   */
  inVW?: boolean;

  /**
   * Максимальное количество элементов, которые будут в DOM
   */
  maxInDOM: number;

  /**
   * Как часто пересчитывать виртуальные элементы слайдера
   * Чем больше значение, тем меньше будет пересчитывание, и больше будет буффер
   *
   * Дефолтное значение `1`
   *
   * Например, если maxInDOM = 10, а recalcStep = 2, то в завимости от сфокусированного элемента,
   * будет следующее кол-во карточек в DOM:
   *
   * focusedIdx = 0, или focusedIdx = 1 => Показаываем карточки с индексом от 0 до 10
   *
   * focusedIdx = 2, или focusedIdx = 3 => Показаываем карточки с индексом от 2 до 12
   *
   *
   *
   * Другой пример:
   *
   * если maxInDOM = 10, а recalcStep = 5, то:
   *
   * focusedIdx = 0,1,2,3,4 => Показаываем карточки с индексом от 0 до 10
   *
   * focusedIdx = 5,6,7,8,9 => Показаываем карточки с индексом от 5 до 15
   *
   * focusedIdx = 10,11,12,13,14 => Показаываем карточки с индексом от 10 до 20
   *
   */
  recalcStep?: number;

  /**
   * За сколько слайдов заранее скроллить до активного
   * Например, нам нужно проскролить слайдер заранее, за 1 элемент
   * Для этого сюда можно передать 1
   *
   * По умолчанию 0
   */
  childBufferCount?: number;

  /**
   * Размер, на который нужно сузить высоту области прокрутки сверху
   * Например у нас sliderWrapper с высотой 50vw,
   * но мы хотим чтобы при скролле наверх слайд останавливался ниже на 2vw, тогда сюда передаем 2
   */
  transitionGapUpVW?: number;

  /**
   * Тоже самое что и `transitionGapUpVW`, только снизу.
   * Если передать 2, то слайдер остановится выше* на 2vw
   */
  transitionGapDownVW?: number;

  /**
   * Ref, с помощью которого можно получить доступ к методам слайдера
   */
  sliderImperativeRef?: SliderImperativeRefType;

  /**
   * Начальное Y смещение в пикселях
   * Можно использовать когда нужно восстановить состояние слайдера.
   * Текущее состояние можно брать через `sliderImperativeRef`
   */
  initialOffset?: number;
};

export const SliderVerticableVirtual$: React.FC<SliderVerticableVirtualProps> = ({
  animationDurationInMS = 200,
  focusedIndex,
  childsCount,
  renderChild,
  updates,
  itemHeight,
  maxInDOM,
  recalcStep = 1,
  childBufferCount = 0,
  wrapperClassName,
  sliderClassName,
  transitionGapUpVW,
  transitionGapDownVW,
  sliderImperativeRef,
  initialOffset,
  inVW,
}) => {
  const sliderRef = React.useRef<HTMLDivElement>(null);
  const sliderWrapperRef = React.useRef<HTMLDivElement>(null);

  const { virtualChildren, virtualFocus, heightMultiplier } = useVirtualChilds({
    focusedIndex,
    childsCount,
    renderChild,
    maxInDOM,
    recalcStep,
  });

  useVirtualOffsetChanger({
    focusedIndex: virtualFocus,
    sliderRef,
    sliderWrapperRef,
    updates,
    realFocusedIndex: focusedIndex,
    childBufferCount,
    transitionGapUpVW,
    transitionGapDownVW,
    initialOffset,
  });

  useSliderImperativeHandle({
    sliderRef,
    sliderWrapperRef,
    sliderImperativeRef,
  });

  useTransitionStyleChanger({
    animationDurationInMS,
    sliderRef,
  });

  return (
    <div
      className={ classnames(styles.sliderWrapper, wrapperClassName) }
      ref={ sliderWrapperRef }
    >
      <div
        ref={ sliderRef }
        className={ classnames(styles.slider, sliderClassName) }
      >
        <div
          style={ {
            flexShrink: 0,
            height: inVW
              ? `${heightMultiplier * itemHeight}vw`
              : `${heightMultiplier * itemHeight}vh`,
          } }
        ></div>
        {virtualChildren}
      </div>
    </div>
  );
};

export const SliderVerticableVirtualNotMemo = SliderVerticableVirtual$;
export const SliderVerticableVirtual = React.memo(SliderVerticableVirtual$);
