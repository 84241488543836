/* eslint-disable max-len */
import * as React from 'react';


const IconInfo: React.FC = () => (
  <svg
    className="playerIcon"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.962,12.014v1.577H14.2V24.135H11.793v1.577h8V24.135h-2.2l.033-12.121Zm5.8-4.4A1.769,1.769,0,0,1,17.079,9a2.626,2.626,0,0,1-1.66.553A2.674,2.674,0,0,1,13.725,9a1.766,1.766,0,0,1-.678-1.384,1.741,1.741,0,0,1,.678-1.356,2.674,2.674,0,0,1,1.694-.553,2.626,2.626,0,0,1,1.66.553,1.744,1.744,0,0,1,.678,1.356Z"
      fill="currentColor"
    />
  </svg>
);


export default React.memo(IconInfo);
