export enum PageObject {
  BuildInPage = 'built_in_page',
  CompetitionPage = 'competition_page',
  ContentPage = 'content_page',
  MainPage = 'main_page',
  NavigationPage = 'navigation_page',
  PersonalPage = 'personal_page',
  SingleCardCollectionPage = 'single_card_collection_page',
  SingleCollectionPage = 'single_collection_page',
  SearchPage = 'search_page',
  EpgPage = 'epg_page',
  SubscribePage = 'subscribe_page'
}

export default PageObject;
