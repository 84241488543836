import focuser, { FocuserKeyHandler } from "@focuser";
import React from 'react';
import { FormattedMessage } from "react-intl";

import { PrimaryButtonFocuser } from "~app/ui-kit/PrimaryButton";
import { PinCodeQuestionProcedure } from "~components/PinCodeManager/PinCodeQuestionProcedure";
import PinCodePopup from "~components/PinCodePopup";
import ProfileEditorPopup from "~components/ProfileEditorPopup";
import { ActionType, useApp, useAppAction } from "~components/Provider/App";
import { useAccount } from "~hooks/fetch/useAccount";
import { ADD_A_NEW_PROFILE } from "~lib/localizator/src/dictionaries";
import Profile from "~typings/Profile";

import { ProfilesList } from "./ProfilesList";
import { useProfilesState } from "./ProfilesList/hooks";
import * as styles from './styles.modules.css';


const PROFILES_LIMIT = 5;


export const ProfileTab: React.FC = () => {
  const [ block, setBlock ] = React.useState<'profilesListBlock' | 'addButton'>('profilesListBlock');

  const appAction = useAppAction();
  const account = useAccount();
  const { activeProfileId } = useApp();
  const isSecurityPinOn = account?.data?.security_pin === true;
  const isParentalControlOn = account?.data?.parental_control === true;

  const { profilesItems, isKids, haveEditingRights } = useProfilesState(activeProfileId);

  const [editableProfileId, setEditableProfileId] = React.useState<string | null>(null);
  const isProfileEditorOpen = editableProfileId !== null;
  const [initialFocusOnProfilesList, setInitialFocusOnProfilesList] =
  React.useState<number | undefined>(undefined);

  const [isPincodeQuestionPassed, setIsPincodeQuestionPassed] = React.useState(false);

  const [profileIdForPinPopup, setProfileIdForPinPopup] = React.useState<string | null>(null);

  const showAddButton = haveEditingRights && (profilesItems.length < PROFILES_LIMIT);

  React.useEffect(() => {
    setIsPincodeQuestionPassed(false);
  }, [editableProfileId]);

  React.useEffect(() => {
    if(!showAddButton && block === 'addButton') {
      setBlock('profilesListBlock');
    }
  }, [showAddButton])

  const handleDown: FocuserKeyHandler = (evt) => {
    if(block === 'profilesListBlock' && showAddButton) {
      setBlock('addButton');
      evt.stop()
    }
  }

  const handleUp: FocuserKeyHandler = (evt) => {
    if(block === 'addButton') {
      setBlock('profilesListBlock');
      evt.stop()
    }
  }

  const handleEditButton = (profileId, index) => {
    setEditableProfileId(profileId);
    setInitialFocusOnProfilesList(index)
  }

  const changeProfileHandler = React.useCallback((
    newProfileId: string,
    immidiatly: boolean = false,
  ) => {
    const newProfile = profilesItems.find((p) => p.id === newProfileId) ?? ({} as Profile);

    if (!immidiatly && isSecurityPinOn && isParentalControlOn && isKids && !newProfile.kids) {
      setProfileIdForPinPopup(newProfileId);
      return;
    }

    if (profileIdForPinPopup !== null) setProfileIdForPinPopup(null);

    appAction({
      type: ActionType.SetActiveProfileId,
      payload: { activeProfileId: newProfileId },
    });
  }, [profilesItems, isSecurityPinOn, isParentalControlOn, isKids, profileIdForPinPopup]);

  if (!profilesItems) return null;


  if(profileIdForPinPopup !== null){
    return (
      <PinCodePopup
        onSuccess={ () => changeProfileHandler(profileIdForPinPopup, true) }
        onBack={ () => setProfileIdForPinPopup(null) }
      />
    )
  }

  if(!isSecurityPinOn && !isPincodeQuestionPassed && editableProfileId !== null ){
    return (
      <PinCodeQuestionProcedure
        onDone={ () => setIsPincodeQuestionPassed(true) }
        onBack={ () => setEditableProfileId(null) }
      />
    )
  }

  if((isSecurityPinOn || isPincodeQuestionPassed) && editableProfileId !== null){
    return (
      <ProfileEditorPopup
        profileId={ editableProfileId }
        onBack={ () => setEditableProfileId(null) }
        onDelete={ () => {
          setInitialFocusOnProfilesList(undefined);
          setEditableProfileId(null);
        } }
      />
    )
  }

  return (
    <focuser.FocusableBlock
      isFocused={ !isProfileEditorOpen }
      onKeyDown={ handleDown }
      onKeyUp={ handleUp }
      noNeedForceFocus
    >
      <focuser.FocusableBlock
        isFocused={ block === 'profilesListBlock' }
        onForceFocus={ () => setBlock('profilesListBlock') }
        className={ styles.profileItemsContainer }
      >
        <ProfilesList
          onSelect={ changeProfileHandler }
          onEdit={ handleEditButton }
          initialIndex={ initialFocusOnProfilesList }
        />
      </focuser.FocusableBlock>

      { showAddButton && (
        <PrimaryButtonFocuser
          isFocused={ block === 'addButton' }
          focuserClassName={ styles.focuserButtonArea }
          onClick={ () => setEditableProfileId('') }
          onForceFocus={ () => setBlock('addButton') }
          emitForceFocusOnHover
        >
          <FormattedMessage id={ ADD_A_NEW_PROFILE } />
        </PrimaryButtonFocuser>
      )}
    </focuser.FocusableBlock>
  );
}
