import { FocuserKeyEventDetail } from '@focuser/events';

import NavigationDirection from '~typings/NavigationDirection';

import { Coord, FBtn } from './types';

const getHandlerFromFBtn = (btn: FBtn, direction: NavigationDirection) => {
  switch (direction) {
    case NavigationDirection.Up:
      return btn.onUp;
    case NavigationDirection.Right:
      return btn.onRight;
    case NavigationDirection.Down:
      return btn.onDown;
    case NavigationDirection.Left:
      return btn.onLeft;
  }
};

const handleDefaultNavigation = ({ direction, focusedBtn, setFocus, focuserEvent }: NavHandlerParams) => {

  const currentCoords = focusedBtn.coord;

  const xMap : Record<NavigationDirection, number> = {
    [NavigationDirection.Right]: 1,
    [NavigationDirection.Left]: -1,
    [NavigationDirection.Up]: 0,
    [NavigationDirection.Down]: 0,
  };

  const yMap : Record<NavigationDirection, number> = {
    [NavigationDirection.Up]: -1,
    [NavigationDirection.Down]: 1,
    [NavigationDirection.Right]: 0,
    [NavigationDirection.Left]: 0
  }

  const nextCoord = {
    x: currentCoords.x + xMap[direction],
    y: currentCoords.y + yMap[direction],
  }

  setFocus(nextCoord);
  focuserEvent.stop();
  focuserEvent.stopNativeEvent();

};

const handleCoordNavigation = ({ setFocus, focuserEvent }: NavHandlerParams, nextCoord: Coord) => {
  setFocus(nextCoord);
  focuserEvent.stop();
  focuserEvent.stopNativeEvent();
};

export type NavHandlerParams = {
  direction: NavigationDirection;
  focusedBtn: FBtn;
  setFocus: (coord: Coord) => void;
  isConfirmBtnActive: boolean;
  isHiddenBtnActive: boolean;
  focuserEvent: FocuserKeyEventDetail;
};

export const navigationHandler = (params: NavHandlerParams) => {
  const { direction, focusedBtn } = params;

  const handler = getHandlerFromFBtn(focusedBtn, direction) || 'default';

  if (handler === 'skip') {
    return;
  }

  if(direction === NavigationDirection.Up && focusedBtn.onReturnUp) {
    handleCoordNavigation(params, focusedBtn.onReturnUp);
    return;
  }

  if (handler === 'default') {
    handleDefaultNavigation(params);
    return;
  }

  if (typeof handler === 'function') {
    handler(params);
    return;
  }

  handleCoordNavigation(params, handler);
};
