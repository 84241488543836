import { getItemName, getRightHolder } from '~lib/mediaItem';
import { DeviceInfo } from '~typings/DeviceInfo';
import { DRMType } from '~typings/Drm';
import ItemObject from '~typings/ItemObject';
import PlatformName from '~typings/PlatformName';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Protocol from '~typings/Protocol';
import { RightHolderType } from '~typings/RightHolder';

type Args = {
  isLive: boolean;
  is4K: boolean;
  isAudioShowPart: boolean;
  osVersionNumber: number;
  isProgramEvent: boolean;
  drm: DRMType;
  rightHolder?: RightHolderType | null;
};
type ProtocolsByPlatform = {
  [key in PlatformName]: (args: Args) => Protocol;
};

export const isSingleProtocolRightHolder = (rightHolder: RightHolderType): boolean => (
  [
    RightHolderType.Megogo,
    RightHolderType.IVI,
    RightHolderType.Start,
  ].includes(rightHolder)
);

const PROTOCOLS_BY_PLATFORM: Readonly<ProtocolsByPlatform> = {
  [PlatformName.WebOS]: ({ isLive, is4K, isAudioShowPart, drm, rightHolder, isProgramEvent, osVersionNumber }) => {
    if (isAudioShowPart) { return Protocol.DASH; }
    if (isLive) {
      // Мы умеем парсить HLS-манифесты и проигрывать в нативном HTML5 плеере
      // НО старые телевизоры не умеют проигрывать HLS выше 3-ий версии
      // Поэтому в src/app/hooks/fetch/useStreamFetch/index.ts чекаем версию WebOS
      // и запрашиваем для старых WebOS HLS 3 версии
      return Protocol.HLS;
    }
    if (
      drm && [DRMType.PlayReady].includes(drm)
      && rightHolder && [
        RightHolderType.Megogo,
        RightHolderType.Amediateka,
      ].includes(rightHolder)
      && osVersionNumber < 3
    ) {
      return Protocol.MSS;
    }
    if (drm && [
      // DRMType.SPbTVCas,
      DRMType.PlayReady,
      DRMType.WideVine,
    ].includes(drm)) {
      return Protocol.DASH;
    }
    if (
      is4K
      // || (isLive && osVersionNumber)
    ) {
      return Protocol.DASH;
    }
    if (rightHolder === null && osVersionNumber >= 3) {
      return Protocol.DASH;
    }
    if (
      rightHolder && (isSingleProtocolRightHolder(rightHolder))
      || isProgramEvent
      || osVersionNumber >= 3
    ) {
      return Protocol.HLS;
    }
    return Protocol.MSS;
  },
  [PlatformName.Tizen]: ({ isProgramEvent, isLive, is4K,drm, rightHolder, osVersionNumber }) => {
    // if (isAudioShowPart) { return Protocol.MSS; }
    if (osVersionNumber < 3 &&
      rightHolder &&
      [RightHolderType.Amediateka,].includes(rightHolder)
    ) {
      return Protocol.MSS;
    }
    if (isLive) {
      // if (osVersionNumber > 3) {
      //   return Protocol.DASH;
      // }

      return Protocol.MSS;
    }
    if (
      is4K && osVersionNumber >= 3
      // || (isLive && osVersionNumber > 3)
    ) {
      return Protocol.DASH;
    }
    if (
      drm && [DRMType.WideVine].includes(drm)
      && osVersionNumber >= 3
    ) {
      return Protocol.DASH;
    }
    if (
      drm && [DRMType.PlayReady].includes(drm)
      && osVersionNumber >= 3
    ) {
      return Protocol.DASH;
    }
    if (
      rightHolder && [
        RightHolderType.IVI,
      ].includes(rightHolder)
    ) {
      return Protocol.HLS;
    }
    if (isProgramEvent && osVersionNumber < 3) {
      return Protocol.HLS;
    }
    if (
      drm && [DRMType.SPbTVCas].includes(drm)
      && !isLive && osVersionNumber >= 3
    ) {
      return Protocol.DASH;
    }
    if (
      rightHolder && [RightHolderType.Megogo].includes(rightHolder)
    ) {
      if (drm && [DRMType.PlayReady].includes(drm)) {
        return Protocol.DASH;
      } else {
        return Protocol.HLS;
      }
    }
    if (rightHolder && isSingleProtocolRightHolder(rightHolder)) {
      return Protocol.HLS;
    }
    return Protocol.MSS;
  },
  [PlatformName.Hisense]: ({ drm }) => {
    if (drm && [DRMType.PlayReady].includes(drm)) { return Protocol.DASH; }
    return Protocol.HLS;
  },
  [PlatformName.Desktop]: ({ is4K, drm, isLive, rightHolder }) => {
    if (isLive) { return Protocol.HLS; }

    if (drm && [DRMType.WideVine].includes(drm)) {
      return Protocol.DASH;
    }

    if (rightHolder && isSingleProtocolRightHolder(rightHolder)) {
      return Protocol.HLS;
    }

    if (is4K) {
      return Protocol.HLS;
    }

    return Protocol.DASH;
  },
  [PlatformName.Foxxum]: () => Protocol.HLS,
  [PlatformName.Panasonic]: () => Protocol.HLS,
  [PlatformName.TSL]: () => Protocol.HLS,
  [PlatformName.Vewd]: () => Protocol.HLS,
};


export const getProtocolsByPlatform = (item: PlayerMediaItem, device: DeviceInfo, drm: DRMType): Protocol => {
  const osVersionNumber = parseInt((device.osVersion || '').toLowerCase().replace('tizen ', '').split('.')[0], 10);

  return PROTOCOLS_BY_PLATFORM[__PLATFORM__]({
    drm,
    is4K: (getItemName(item).search(/uhd|4k/) >= 0),
    isLive: (item.object === ItemObject.Channel),
    isAudioShowPart: (item.object === ItemObject.Part),
    isProgramEvent: (item.object === ItemObject.ProgramEvent),
    osVersionNumber,
    rightHolder: getRightHolder(item),
  });
};
