// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nextEpisodeWrapper--UtuwJ {\n  position: absolute;\n  top: 39.921875vw;\n  left: 6.875000000000001vw;\n  padding-right: 1.171875vw;\n  padding-left: 1.171875vw;\n  z-index: 9;\n}\n\n.nextEpisode--mGLZ2 {\n  margin-right: 0.78125vw;\n  padding: 0 0.390625vw;\n  border-width: 1px;\n  background: rgba(255, 255, 255, 0.2);\n}\n\n.fullHD .nextEpisode--mGLZ2 {\n  border-width: 1.5px;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/blocks/playerPopcorn/Player/controls/NextEpisode/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,yBAAyB;EACzB,wBAAwB;EACxB,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,iBAAiB;EACjB,oCAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".nextEpisodeWrapper {\n  position: absolute;\n  top: 39.921875vw;\n  left: 6.875000000000001vw;\n  padding-right: 1.171875vw;\n  padding-left: 1.171875vw;\n  z-index: 9;\n}\n\n.nextEpisode {\n  margin-right: 0.78125vw;\n  padding: 0 0.390625vw;\n  border-width: 1px;\n  background: $w_20;\n}\n\n:global(.fullHD) .nextEpisode {\n  border-width: 1.5px;\n}\n"],"sourceRoot":""}]);
// Exports
export var nextEpisodeWrapper = "nextEpisodeWrapper--UtuwJ";
export var nextEpisode = "nextEpisode--mGLZ2";
export default ___CSS_LOADER_EXPORT___;
