import * as React from 'react';

type Props = {
  className?: string;
};

/* eslint-disable max-len */
const OnwardIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg className={ className } viewBox="0 0 70 70">
      <path
        d="M30.4 51c-.3 0-.5-.1-.7-.3l-1.9-1.8c-.4-.4-.4-1 0-1.4L41.2 35 27.8 22.5c-.4-.4-.4-1 0-1.4l1.9-1.8c.4-.4 1-.4 1.4 0l16.1 15c.4.4.4 1 0 1.4l-16.1 15c-.2.2-.4.3-.7.3z"
        fill='currentColor'
      />
    </svg>
  );
};


export default OnwardIcon;
