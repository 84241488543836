import focuser from '@focuser';
import * as cn from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import { PROFILES_MAIN_PROFILE } from '~lib/localizator/src/dictionaries';
import { EditButtonFocuser } from '~newapp/ui-kit/Controls/Buttons/Icon_btn/Big_icon_btn/EditButton';
import Profile from '~typings/Profile';
import SelectedIcon from '~ui/Icon/icons/SelectedIcon';

import { ProfileImageOnProfilesList } from './ProfileImageOnProfilesList/ProfileImageOnProfilesList';
import * as styles from './styles.modules.css';

type Props = Readonly<{
  profile: Profile;
  isEditable: boolean;
  isSelected: boolean;
  focusedBlock?: "profileName" | "editButton";
  onClick: (profileId: string) => void;
  onEditBtnClick: (profileId: string) => void;
  setFocusOnProfileName: () => void;
  setFocusOnEditButton: () => void;
}>;


export const ProfileLine: React.FC<Props> = ({
  profile,
  isEditable,
  isSelected,
  focusedBlock,
  onClick,
  onEditBtnClick,
  setFocusOnProfileName,
  setFocusOnEditButton,
}: Props) => {

  return (
    <div className={ styles.profilesItem }>
      <focuser.FocusableBlock 
        isFocused={ focusedBlock === 'profileName' }
        className={ styles.content }
        onClick={ () => onClick(profile.id) }
        onForceFocus={ setFocusOnProfileName }
        emitForceFocusOnHover
      >
        {({ isFocused }) => (
          <>
            <ProfileImageOnProfilesList
              profile={ profile }
              isFocused={ isFocused }
              OverflowIcon={ isSelected ? SelectedIcon : undefined }
            />
            <div className={ styles.textBlock }>
              <Typography
                variant={ 'h6_medium' }
                className={ cn(styles.profileName, { [styles.focusedProfileName]: isFocused }) }
              >
                { profile.name }
              </Typography>

              { profile.account_creator && (
                <Typography
                  variant={ 'body1_medium' }
                  className={ cn(styles.mainProfileText, { [styles.focusedProfileName]: isFocused }) }
                >
                  <FormattedMessage id={ PROFILES_MAIN_PROFILE } />
                </Typography>
              ) }
            </div>
          </>
        )}
      </focuser.FocusableBlock>
      { isEditable && (
        <EditButtonFocuser
          emitForceFocusOnHover
          buttonClassName={ styles.editButton }
          onForceFocus={ setFocusOnEditButton }
          isFocused={ focusedBlock === 'editButton' }
          onClick={ (e) => {
            e.stop();
            e.stopNativeEvent();
            onEditBtnClick && onEditBtnClick(profile.id)
          } }
        />
      )}
    </div>
  )
}