import { useCallback, useRef, useState } from 'react';

import { FocusedState } from '~components/CardCollectionGrid';

type GridScrollHandlerProps = {
  gridRef: React.RefObject<HTMLDivElement>;
};
export const useGridScrollHandler = ({ gridRef }: GridScrollHandlerProps) => {

  const changeGridStyles = useCallback(
    (addStyles: boolean) => {
      if (gridRef.current) {
        if (addStyles) {
          gridRef.current.style.transform = `translateY(-0.78125vw)`;
          gridRef.current.style.transition = `transform 0.5s ease`;
        } else {
          gridRef.current.style.removeProperty('transform');
          gridRef.current.style.removeProperty('transition');
        }
      }

    },
    [],
  );

  return {
    changeGridStyles,
  };
};


type GridNavigationHandlerProps = {
  onScrollToGrid: (grid: HTMLDivElement | null) => void;
};
export const useGridNavigationHandler = ({onScrollToGrid}: GridNavigationHandlerProps) => {

  const [forceSliderOnFirstRow, setForseSliderOnFirstRow] = useState(false);

  const gridRef = useRef<HTMLDivElement>(null);
  const isScrolledToGridRef = useRef(false);


  const { changeGridStyles } = useGridScrollHandler({
    gridRef
  });


  const onNavigationChange = useCallback((focusedState: FocusedState) => {
    if (!gridRef.current) {
      return;
    }
    const rowIdx = focusedState.rowIdx;
    if (rowIdx > 0) {
      if (isScrolledToGridRef.current) {
        return;
      }
      isScrolledToGridRef.current = true;
      changeGridStyles(false);
      setForseSliderOnFirstRow(false);
      onScrollToGrid(gridRef.current);
    } else {
      if (!isScrolledToGridRef.current) {
        return;
      }
      isScrolledToGridRef.current = false;
      changeGridStyles(true);
      setForseSliderOnFirstRow(true);
      onScrollToGrid(null);
    }
  }, []);



  return {
    gridRef,
    forceSliderOnFirstRow,
    onNavigationChange
  }


}
