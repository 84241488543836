import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import PinCodePopup from '~components/PinCodePopup';
import TrialFreePeriodAndPriceText from "~components/ProductItem/Texts/TrialFreePeriodAndPriceText";
import { useAccount } from '~hooks/fetch/useAccount';
import useInvoice from '~hooks/fetch/usePayments/useInvoice';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import {
  ENABLE,
  MOBILE_PAYMENT,
  NAVIGATION_BACK,
  NON_TRIAL_TEXT_CONFIRMATION,
  PAY_NOW,
  PAYMENT_METHOD,
  WILL_BE_DEBITED_FROM_YOUR_ACCOUNT,
} from '~localization';
import Product from '~typings/Product';
import Button from '~ui/button';

import * as styles from './styles.module.css';


type Props = Readonly<{
  product: Product;
  intl: InjectedIntl;
}>;

const ProductInvoicePage: React.FC<Props> = (props) => {
  const history = useHistory();
  const { planID } = useParams<{ planID: string }>();
  const { mutate, data: invoice } = useInvoice();
  const { data: accountInfo } = useAccount();
  const [isPinCodePopup, showPinCodePopup] = React.useState<boolean>(false)

  React.useEffect(() => {
    //пеймент метод добавлен временно,т.к
    //в новом дизайне с этой страницы не будет возможности оплатить подписку
    if (planID) { mutate({ planID, paymentMethod: 'operator' }); }
  }, [planID]);

  const plan = props.product?.plans.find(x => x.id === planID);
  const isTrialPhase = plan?.eligible_phase?.type === 'trial';
  const isEvergreenPhase = plan?.eligible_phase?.type === 'evergreen';
  const nextPhase = (plan?.phases || []).find(phase => phase.type === 'evergreen');

  const navigationItems = React.useRef([
    { maxIndex: 1 }, // Buttons
  ]);
  const navigationState = useSpatialNavigation({
    allowNavigation: !isPinCodePopup,
    navigationItems: navigationItems.current,
  });
  const focusIndex = navigationState.focusedIndex[0];

  const handleBack = () => {
    history.goBack();
  };

  const handleClosePopup = () => {
    showPinCodePopup(false)
  };

  const handleProceed = () => {
    if (accountInfo?.security_pin) {
      showPinCodePopup(true);
    } else {
      history.replace(`/products/${props.product.id}/plan/${planID}/invoice/${invoice?.data.id}`);
    }
  };

  const handleValidPinCode = () => {
    history.replace(`/products/${props.product.id}/plan/${planID}/invoice/${invoice?.data.id}`);
  };

  return (
    <>
      <h1 className={ styles.name }>{ props.product.name }</h1>
      <div className={ styles.buttonWrapper }>
        {
          props.intl.formatMessage({ id: PAYMENT_METHOD })
        }
        { <span>&nbsp;</span> }
        {
          props.intl.formatMessage({ id: MOBILE_PAYMENT })
        }
      </div>
      <div className={ styles.invoceWrapper }>
        {
          props.intl.formatMessage({ id: WILL_BE_DEBITED_FROM_YOUR_ACCOUNT }, {
            sum: invoice?.data.total_amount.formatted,
          })
        }
      </div>
      <div className={ styles.buttonWrapper }>
        <Button
          isFocused={ !isPinCodePopup && focusIndex === 0 }
          className={ cn(styles.button, {
            [styles.isFocused]: !isPinCodePopup && focusIndex === 0,
          }) }
          onClick={ handleProceed }
        >
          {
            props.intl.formatMessage({ id: isTrialPhase ? ENABLE : PAY_NOW })
          }
        </Button>
        <Button
          isFocused={ !isPinCodePopup && focusIndex === 1 }
          className={ cn(styles.button, {
            [styles.isFocused]: !isPinCodePopup && focusIndex === 1,
          }) }
          onClick={ handleBack }
        >
          {
            props.intl.formatMessage({ id: NAVIGATION_BACK })
          }
        </Button>
      </div>
      <div className={ styles.buttonWrapper }>
        {
          isTrialPhase ? (
            <TrialFreePeriodAndPriceText
              isTrialPeriod={ props.product.plans[0].eligible_phase?.type === 'trial' }
              price={ {
                value: nextPhase?.billing.price.formatted,
                duration: (nextPhase?.access_period.value || 0),
                unit: (nextPhase?.access_period.unit || 'days'),
              } }
            />
          ) : isEvergreenPhase ? (
            props.intl.formatMessage({ id: NON_TRIAL_TEXT_CONFIRMATION })
          ) : null
        }
      </div>
      {
        isPinCodePopup ? (
          <PinCodePopup
            onSuccess={ handleValidPinCode }
            onBack={ handleClosePopup }
          />
        ) : null
      }
    </>
  );
};


export default injectIntl(ProductInvoicePage);
