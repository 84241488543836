import cn from 'classnames';
import React from 'react';

import * as css from './image.module.css';

export type HorizontalImageType = {
  type: 'circle' | 'square' | 'full';
  url: string;
};

export const HorizontalCardImage = React.memo((props: {image: HorizontalImageType}) => {
  const { image } = props;
  if (image.type === 'full') {
    return (
      <div className={ css.imageWrapper }>
        <div
          className={ cn(css.imageFull, css.imageBackground) }
          style={ { backgroundImage: `url('${image.url}')` } }
        />
      </div>
    );
  }

  if (image.type === 'circle') {
    return (
      <div className={ css.imageWrapper }>
        <div
          className={ cn(css.imageCircle, css.imageBackground) }
          style={ { backgroundImage: `url('${image.url}')` } }
        />
      </div>
    );
  }

  if (image.type === 'square') {
    return (
      <div className={ css.imageWrapper }>
        <div
          className={ cn(css.imageSquare, css.imageBackground) }
          style={ { backgroundImage: `url('${image.url}')` } }
        />
      </div>
    );
  }

  return null;
});

HorizontalCardImage.displayName = 'HorizontalCardImage';
