import { QueryKey,useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import Channel from '~typings/Channel';
import ResponseSingle from '~typings/ResponseSingle';


type ChannelBySlug = ResponseSingle<Channel>;

const getChannelKey = (slugOrID?: string): QueryKey => ['channel', slugOrID];

const fetch = async (client, props) => {
  if (!props.slug) { return undefined; }

  if (props.cacheData) { return props.cacheData; }

  const url = (props.slug) ? `/v5/channels/${props.slug}` : '/v5/channels/';

  return await client.get(url);
};

const getCachedChannel = (slugOrID?: string) => {
  if (!slugOrID) { return undefined; }

  const queryKey = getChannelKey(slugOrID);
  return queryClient.getQueryData<ChannelBySlug>(queryKey);
};

const useChannelBySlug = (slug?: string) => {
  const queryKey = getChannelKey(slug);
  const client = useClient();
  const cacheData = queryClient.getQueryData(queryKey);

  return useQuery<ChannelBySlug>({
    queryKey,
    queryFn: () => fetch(client, { cacheData, slug }),
    enabled: !!slug,
  });
};


export {
  fetch,
  getCachedChannel,
  getChannelKey,
};


export default useChannelBySlug;
