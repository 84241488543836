import { gw_00 } from "customization-project-name/colors";
import * as React from 'react';

import { SpinnerGlobal } from "~newapp/globalBlocks/spinner";
import usePointer from '~stores/Pointer';


type Props = Readonly<{
  isOverPage?: boolean;
  isVisible: boolean;
  children?: any;
}>;

const GlobalLoader: React.FC<Props> = (props: Props) => {
  const flush = usePointer(state => state.flush);

  const styleMainLoaderForJS = {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: gw_00,
  };

  React.useEffect(() => {
    if (props.isVisible) flush();
  }, [props.isVisible]);


  return (
    // @ts-ignore
    <div id="global-loader" style={ props.isVisible ? styleMainLoaderForJS : {} }>
      { props.children }
      {
        props.isVisible && <SpinnerGlobal />
      }
    </div>
  );
};


export default React.memo(GlobalLoader);
