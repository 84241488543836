
import ApiClient from '~lib/ApiClient';
import { fetchRetryer, FetchRetryerParams } from '~lib/fetch';
import { DeviceTokenObject } from '~typings/Devices';
import Platform from '~typings/Platform';
import ResponseSingle from '~typings/ResponseSingle';

export const registerDevice = async (
  client: ApiClient,
  platform: Platform,
  retryParams: FetchRetryerParams,
): Promise<DeviceTokenObject> => {
  const deviceInfo = platform.getDeviceInfo();
  const body = {
    type: 'smarttv',
    device_id: deviceInfo.deviceId || 'unknown',
    os_name: deviceInfo.osName,
    os_version: deviceInfo.osVersion || '4.0',
    model: deviceInfo.model || 'unknown',
    vendor: deviceInfo.vendor,
  };

  const { data } = await fetchRetryer(
    () => client.post<ResponseSingle<DeviceTokenObject>>('/v1/devices', body),
    retryParams,
  );

  return data;
};
