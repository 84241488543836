import focuser from '@focuser';
import * as React from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { CANCEL, DELETE, DELETE_PROFILE_CONFIRM } from '~lib/localizator/src/dictionaries';

import * as styles from './styles.module.css';

enum FocusOn {
  CancelButton = 'cancel_button',
  DeleteButton = 'delete_button',
}

type Props = Readonly<{
  profileName: string;
  onCancel: () => void;
  onDelete: () => void;
  intl: InjectedIntl;
}>;


const ConfirmDeletePopup: React.FC<Props> = (props: Props) => {
  const [focusedButton, setFocusedButton] = React.useState<FocusOn>(FocusOn.CancelButton);

  const setFocusOnDeleteButton = () => {
    setFocusedButton(FocusOn.DeleteButton)
  }

  const setFocusOnCancelButton = () => {
    setFocusedButton(FocusOn.CancelButton)
  }

  return (
    <focuser.FocusableBlock
      className={ styles.popup }
      isFocused
      onKeyRight={ setFocusOnDeleteButton }
      onKeyLeft={ setFocusOnCancelButton }
      onKeyReturn={ (e) => {
        e.stopNativeEvent();
        e.stop();
        props.onCancel();
      }  }
      noNeedForceFocus
    >
      <div className={ styles.text }>
        { 
          props.intl.formatMessage(
            { id: DELETE_PROFILE_CONFIRM },
            { profileName: props.profileName })
        }
      </div>
      <div className={ styles.buttonsWrap }>
        <PrimaryButtonFocuser
          buttonClassName={ styles.button }
          isFocused={ focusedButton === FocusOn.CancelButton }
          onClick={ props.onCancel }
          noNeedForceFocus
        >
          <FormattedMessage id={ CANCEL } />
        </PrimaryButtonFocuser>
        <PrimaryButtonFocuser 
          buttonClassName={ styles.button }
          isFocused={ focusedButton === FocusOn.DeleteButton }
          onClick={ props.onDelete }
          noNeedForceFocus
        >
          <FormattedMessage id={ DELETE } />
        </PrimaryButtonFocuser>
      </div>
    </focuser.FocusableBlock>
  )
}

export default injectIntl(ConfirmDeletePopup);