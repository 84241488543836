/* eslint-disable max-len */
import * as React from 'react';


const IconForward: React.FC = () => (
  <svg
    className="playerIconForward"
    viewBox="0 0 37.9 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.4 0C.6 0 0 .6 0 1.4v19.3c0 .7.6 1.3 1.4 1.3.2 0 .5-.1.7-.2l16.3-9.6h.1c.6-.4.8-1.2.4-1.8-.1-.2-.3-.4-.5-.5L2 .2C1.8.1 1.6 0 1.4 0zm19 0c-.8 0-1.4.6-1.4 1.4v19.2c0 .8.6 1.4 1.4 1.4.2 0 .5-.1.7-.2l16.3-9.6c.6-.4.8-1.2.5-1.9-.1-.2-.3-.4-.5-.5L21 .2c-.2-.1-.4-.2-.6-.2z"
    />
  </svg>
);


export default IconForward;
