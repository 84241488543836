import cn from 'classnames';
import * as React from 'react';

import { useApp } from '~components/Provider/App';
import { getShortDate } from '~lib/localeUtils/dates';
import { isCardChannel, isCardNews } from '~lib/objectType';
import Card from '~typings/Card';
import * as styles from '~ui/MediaItem/styles.module.css';


type Props = Readonly<{
  item: Card;
  isFocused: boolean;
}>;


const CardItemAdditionalName: React.FC<Props> = (props: Props) => {
  const { language } = useApp();
  const additionalName = (isCardNews(props.item)) ?
    getShortDate(props.item.additional_name, language)
    :
    isCardChannel(props.item) ?
      (props.item as Card).name
      :
      (props.item as Card).additional_name;

  return (
    props.isFocused ?
    (
      <div className={ cn(styles.genre, styles.focusedGenre) }>
        {additionalName}
      </div>
    ) : (
      <div className={ styles.genre }>
        {additionalName}
      </div>
    )
  );
};


export default CardItemAdditionalName;
