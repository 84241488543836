import moment from 'moment';
import { xml2json } from 'xml-js';
import { compact, get } from 'lodash';
import { WebVTTParser } from './../../../assets/webvtt';


const parseURL = (url) => {
  const parser = document.createElement('a');
  const searchObject = {};
  let split;
  let i;
  // Let the browser do the work
  parser.href = url;
  // Convert query string to object
  const queries = parser.search.replace(/^\?/, '').split('&');

  for (i = 0; i < queries.length; i += 1) {
    split = queries[i].split('=');
    searchObject[split[0]] = split[1];
  }
  return {
    searchObject,
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    hash: parser.hash,
  };
};


const parseVTT = (vttStr) => {
  const parser = new WebVTTParser();
  const tree = parser.parse(vttStr, 'metadata');

  return tree.cues.map(({ startTime: start, endTime: end, text }) => ({ start, end, text }));
};


const parseTTML = (ttmlStr) => {
  try {
    const srcObj = JSON.parse(xml2json(ttmlStr, { compact: true }));

    return (
      get(srcObj, 'tt.body.div.p', [])
        .map(({ _text: text, _attributes: { begin: start, end } }) => ({
          start: moment(start, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds'),
          end: moment(end, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds'),
          text: (typeof text === 'string') ? text : text.join('<br />'),
        }))
    );
  } catch (ignore) {
    return null;
  }
};


const getSubTitlesStructure = (streamUrl, parsedSubtitles) => {
  const parsedURL = parseURL(streamUrl);
  const pathname = parsedURL.pathname.split('/');
  pathname.splice(-1, 1);
  const absPath = `${parsedURL.protocol}//${parsedURL.hostname}${pathname.join('/')}`;

  return compact(
    Object.keys(parsedSubtitles)
      .map((key) => {
        const fileName = get(parsedSubtitles, `${key}.playlists[0].resolvedUri`, '').replace('file:///', '');
        const splited = fileName.split('/');
        const splitedPath = splited[(splited.length - 1)];

        if (!splitedPath || splitedPath === '') { return null; }

        return ({
          name: key,
          url: `${absPath}/${splitedPath}`,
        });
      })
  );
};

export {
  getSubTitlesStructure,
  parseVTT,
  parseTTML,
};
