import {
  KeyBoardKey,
  KeyBoardKeyType,
} from '~typings/Keyboard';


const keyboardSpecialChars: KeyBoardKey[][] = [
  [
    { type: KeyBoardKeyType.Char, id: 1, },
    { type: KeyBoardKeyType.Char, id: 2, },
    { type: KeyBoardKeyType.Char, id: 3, },
    { type: KeyBoardKeyType.Char, id: 4, },
    { type: KeyBoardKeyType.Char, id: 5, },
    { type: KeyBoardKeyType.Char, id: 6, },
    { type: KeyBoardKeyType.Char, id: 7, },
    { type: KeyBoardKeyType.Char, id: 8, },
    { type: KeyBoardKeyType.Char, id: 9, },
    { type: KeyBoardKeyType.Char, id: 0, },
    { type: KeyBoardKeyType.Space },
    { type: KeyBoardKeyType.Backspace },
  ],
  [
    { type: KeyBoardKeyType.Char, id: '!', },
    { type: KeyBoardKeyType.Char, id: '"', },
    { type: KeyBoardKeyType.Char, id: '#', },
    { type: KeyBoardKeyType.Char, id: '$', },
    { type: KeyBoardKeyType.Char, id: '%', },
    { type: KeyBoardKeyType.Char, id: '&', },
    { type: KeyBoardKeyType.Char, id: '\'', },
    { type: KeyBoardKeyType.Char, id: '(', },
    { type: KeyBoardKeyType.Char, id: ')', },
    { type: KeyBoardKeyType.Char, id: '*', },
    { type: KeyBoardKeyType.Char, id: '@', },
    { type: KeyBoardKeyType.EmailPhoneChars, id: 'ABC' },
  ],
  [
    // { type: KeyBoardKeyType.Empty },
    { type: KeyBoardKeyType.Char, id: '{', },
    { type: KeyBoardKeyType.Char, id: '|', },
    { type: KeyBoardKeyType.Char, id: '}', },
    { type: KeyBoardKeyType.Char, id: '/', },
    { type: KeyBoardKeyType.Char, id: ':', },
    { type: KeyBoardKeyType.Char, id: ';', },
    { type: KeyBoardKeyType.Char, id: '<', },
    { type: KeyBoardKeyType.Char, id: '=', },
    { type: KeyBoardKeyType.Char, id: '>', },
    { type: KeyBoardKeyType.Char, id: '_', },
    { type: KeyBoardKeyType.Char, id: '+', },
    { type: KeyBoardKeyType.UpperCase },
  ],
  [
    { type: KeyBoardKeyType.Char, id: '[', },
    { type: KeyBoardKeyType.Char, id: '\\', },
    { type: KeyBoardKeyType.Char, id: ']', },
    { type: KeyBoardKeyType.Char, id: '^', },
    { type: KeyBoardKeyType.Char, id: '?', },
    { type: KeyBoardKeyType.Char, id: '`', },
    { type: KeyBoardKeyType.Char, id: '~', },
    { type: KeyBoardKeyType.Char, id: ',', },
    { type: KeyBoardKeyType.Char, id: '.', },
    { type: KeyBoardKeyType.Char, id: '-', },
    { type: KeyBoardKeyType.ShowValueOn, },
    { type: KeyBoardKeyType.Close, id: 'OK', },
  ],
];


export default keyboardSpecialChars;
