import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import BlockBanners from '~components/BlockBanners/component';
import BlockBannersBig from '~components/BlockBannersV4/BlockBannersBig';
import BlockCardCollection from '~components/BlockCardCollection';
import { BlockContinueWatching } from '~components/BlockContinueWatching';
import BlockCurrentProgramLine from '~components/BlockCurrentProgramLine/component';
import BlockFavorites from '~components/BlockFavorites/BlockFavorites';
import { BlockFilters } from '~components/BlockFilters';
import BlockRecommendations from '~components/BlockRecommendation/component';
import { BlockCardCollectionExpand } from '~components/CardCollection/block-card-collection'
import { MiddleBanner } from "~components/MiddleBanner";
import { CONTINUE_WATCHING, HEADER_FAVORITE_CHANNELS, HEADER_WATCH_LATER, RECOMMENDED_FOR_YOU } from '~localization';
import { Block, BlockType } from '~typings/Block';
import ContinueWatchingType from '~typings/ContinueWatchingType';
import FavoriteType from '~typings/FavoriteType';
import MediaItemDimension from '~typings/MediaItemDimension';
import Page from '~typings/Page';

type Props = Readonly<{
  page: Page;
  block: Block;
  dimension: MediaItemDimension;
  focusedItemIndex: number;
  blockIndex: number;
  isVisible: boolean;
  isVisibleBlock: boolean;
  isPopupOpened: boolean;
  isFocusedBlock: boolean;
  focusedBlockID: string | null;
}>;

interface Result {
  content: JSX.Element | null;
  titleText: JSX.Element | string | null;
}


const getComponents = (props: Props): Result => {
  const {
    page,
    block,
    dimension,
    focusedItemIndex,
    isVisible,
    isVisibleBlock,
    isFocusedBlock,
    isPopupOpened,
    blockIndex,
    focusedBlockID,
  } = props;

  switch (block.object) {
    case BlockType.BannersBlock: {
      return {
        content: (
          <BlockBanners
            page={ page }
            block={ block }
            dimension={ dimension }
            isFocusedBlock={ isFocusedBlock }
            isPopupOpened={ isPopupOpened }
            focusedItemIndex={ focusedItemIndex }
            isVisible={ isVisible }
          />
        ),
        titleText: null,
      };
    }
    case BlockType.BigBannersBlock: {
      return {
        content: (
          <BlockBannersBig
            block={ block }
            isFocusedBlock={ isFocusedBlock }
            isPopupOpened={ isPopupOpened }
            focusedItemIndex={ focusedItemIndex }
            blockIndex={ blockIndex }
            isVisible={ isVisible }
          />
        ),
        titleText: null,
      };
    }
    case BlockType.MiddleBannersBlock: {
      return {
        content: (
          <div style={ { position: 'relative', top: isFocusedBlock ? '-12vw' : '0vw' } }>
            <MiddleBanner
              block={ block }
              isFocusedBlock={ isFocusedBlock }
              isPopupOpened={ isPopupOpened }
              blockIndex={ blockIndex }
              pageObject={ page.object } />
          </div>
        ),
        titleText: null,
      }
        ;
    }
    case BlockType.CurrentProgramLineBlock: {
      return {
        content: (
          <BlockCurrentProgramLine
            page={ page }
            block={ block }
            isFocusedBlock={ isFocusedBlock }
            isPopupOpened={ isPopupOpened }
            blockIndex={ blockIndex }
            isVisible={ isVisible }
          />
        ),
        titleText: null,
      };
    }
    case BlockType.RecommendationsBlock: {
      return {
        content: (
          <BlockRecommendations
            block={ block }
            dimension={ dimension }
            isFocusedBlock={ isFocusedBlock }
            isPopupOpened={ isPopupOpened }
            focusedItemIndex={ focusedItemIndex }
            isVisible={ isVisible }
          />
        ),
        titleText: block.title || <FormattedMessage id={ RECOMMENDED_FOR_YOU } />,
      };
    }
    case BlockType.ContinueWatchingBlock: {
      return {
        content: (
          <BlockContinueWatching
            page={ page }
            block={ block }
            types={ (block.resource_types || []) as ContinueWatchingType[] }
            dimension={ dimension }
            isFocusedBlock={ isFocusedBlock }
            isPopupOpened={ isPopupOpened }
            focusedItemIndex={ focusedItemIndex }
            isVisible={ isVisible }
          />
        ),
        titleText: block.title || <FormattedMessage id={ CONTINUE_WATCHING } />,
      };
    }
    case BlockType.FavoriteMoviesBlock: {
      return {
        content: (
          <BlockFavorites
            page={ page }
            block={ block }
            types={ [FavoriteType.Movies] }
            dimension={ dimension }
            isFocusedBlock={ isFocusedBlock }
            isPopupOpened={ isPopupOpened }
            focusedItemIndex={ focusedItemIndex }
            isVisible={ isVisible }
          />
        ),
        titleText: block.title || <FormattedMessage id={ HEADER_WATCH_LATER } />,
      };
    }
    case BlockType.FavoriteChannelsBlock: {
      return {
        content: (
          <BlockFavorites
            page={ page }
            block={ block }
            types={ [FavoriteType.Channels] }
            dimension={ dimension }
            isFocusedBlock={ isFocusedBlock }
            isPopupOpened={ isPopupOpened }
            focusedItemIndex={ focusedItemIndex }
            isVisible={ isVisible }
          />
        ),
        titleText: block.title || <FormattedMessage id={ HEADER_FAVORITE_CHANNELS } />,
      };
    }
    case BlockType.FavoritesBlock: {
      return {
        content: (
          <BlockFavorites
            page={ page }
            block={ block }
            types={ (block.resource_types || []) as FavoriteType[] }
            dimension={ dimension }
            isFocusedBlock={ isFocusedBlock }
            isPopupOpened={ isPopupOpened }
            focusedItemIndex={ focusedItemIndex }
            isVisible={ isVisible }
          />
        ),
        titleText: block.title || <FormattedMessage id={ HEADER_WATCH_LATER } />,
      };
    }
    case BlockType.CardCollectionBlock: {
      return {
        content: (
          <BlockCardCollection
            block={ block }
            dimension={ dimension }
            isFocusedBlock={ isFocusedBlock }
            isPopupOpened={ isPopupOpened }
            focusedItemIndex={ focusedItemIndex }
            isFadeOut={ !isVisibleBlock }
            isVisible={ isVisible }
          />
        ),
        titleText: null,
      };
    }
    case BlockType.FiltersBlock: {
      return {
        content: (
          <BlockFilters
            page={ page }
            block={ block }
            isTiledView={ false }
            isFocusedBlock={ isFocusedBlock }
            isPopupOpened={ isPopupOpened }
            focusedItemIndex={ focusedItemIndex }
            isVisible={ isVisible }
          />
        ),
        titleText: null,
      };
    }
    case BlockType.ExpandableCardColletion: {
      if (block.card_collection?.id) {
        return {
          content: <BlockCardCollectionExpand
            focusedBlockID={ focusedBlockID }
            isFocusedBlock={ isFocusedBlock }
            propsCardCollectionId={ block.card_collection.id }
            focusedItemIndex={ focusedItemIndex }
          />,
          titleText: null
        }
      }
      else {
        return {
          content: null,
          titleText: null
        }
      }

    }
    default:
      return { content: null, titleText: null };
  }
};

export default getComponents;
