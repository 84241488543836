import * as React from 'react';

import CurrentEventInfoRender from "~components/CurrentEventInfo/render";
import { getCachedCardMutation, useCardMutations } from '~hooks/fetch/useCardMutations';
import useCurrentEventCard from '~hooks/useCurrentEventCard';
import { getScheduleTime } from '~lib/localeUtils/dates';
import Card, { Modification } from '~typings/Card';


type Props = Readonly<{
  event: Card;
}>;


const ProgramEventInfoCard: React.FC<Props> = ({ event }: Props) => {
  const mutations = event?.mutable ? getCachedCardMutation(event?.id, event.card_config_id) : undefined;
  const mutation = useCurrentEventCard(mutations);
  useCardMutations(
    (event?.mutable && event?.id) ? event.id : undefined,
    event.card_config_id,
  );
  const item: Card | undefined = mutation ? mutation.card : event;
  const isDisableProgressLine = (mutation?.disable_progress_line === true);
  const isCurrentEvent = (item?.modification === Modification.CURRENT);

  const eventName = (isCurrentEvent && mutation) ?
    `${getScheduleTime(mutation.start_at)}, ${event.name}`
    :
    event.name || undefined;

  const name = (item && !isCurrentEvent) ?
    item.additional_name || undefined
    :
    undefined;

  return (
    <CurrentEventInfoRender
      isBigSize
      name={ name }
      eventName={ eventName }
      startAt={ isDisableProgressLine ? undefined : mutation?.start_at || undefined }
      endAt={ isDisableProgressLine ? undefined : mutation?.end_at || undefined }
    />
  );
};


export default React.memo(ProgramEventInfoCard);
