import * as cn from 'classnames';
import * as React from 'react';

import HorizontalPointerNavigation from '~components/PointerManager/HorizontalPointerNavigation';
import Items from '~components/Slider/items';
import ItemsWithDots from '~components/Slider/itemsWithDots';
import { useConfig } from '~global';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import uuid from '~lib/uuid';
import usePointer from '~stores/Pointer';
import Banner from '~typings/Banner';
import BannerBig from '~typings/BannerBig';
import BannerMiddle from '~typings/BannerMiddle';
import { DataItem, MediaItemType } from '~typings/DataItem';
import MediaItemDimension from '~typings/MediaItemDimension';
import ResponseMany from '~typings/ResponseMany';

import * as styles from './styles.module.css';
import { calculateItemsCountToRender, checkDeepLink } from './utils';

export type BannerItem = Readonly<Banner | BannerBig | BannerMiddle>;
type Props = Readonly<{
  dimension: MediaItemDimension;
  items: ResponseMany<MediaItemType[] | BannerItem[]> | undefined;
  focusedIndex: number;
  isFocusedBlock: boolean;
  withName?: boolean;
  withGenres?: boolean;
  alwaysShowName?: boolean;
  isVisible?: boolean;
  showAsSeries?: boolean;
  className?: string;
  onClick?: (item: DataItem) => void;

  deeplink?: string;
  cardConfigID?: string;
  onDotClick?: (item: BannerItem) => void;
  disableHorizontalNavigation?: boolean;
}>;

const Slider: React.FC<Props> = (props) => {
  const { smartTV } = useConfig();
  const checkedDeeplink = React.useMemo(
    () =>
      checkDeepLink({
        deeplink: props.deeplink,
        sliderSize: smartTV.sliderSize,
        itemsCurrentLength: props.items?.data.length,
        itemsTotalLength: props.items?.meta.pagination.total,
      }),
    [
      props.deeplink,
      props.items?.data.length,
      props.items?.meta.pagination.total,
      smartTV.sliderSize,
    ],
  );

  const UUID = React.useMemo(
    () =>
      new Array(
        calculateItemsCountToRender({
          isDeeplinkExists: Boolean(checkedDeeplink),
          itemsCurrentLength:  props.items?.data.length,
          itemsTotalLength: props.items?.meta.pagination.total,
          sliderSize: smartTV.sliderSize,
        }),
      )
        .fill(0)
        .map((_item, index) => uuid(index)),
    [
      checkedDeeplink,
      props.items?.data.length,
      props.items?.meta.pagination.total,
      smartTV.sliderSize,
    ],
  );
  const pointerEnabled = usePointer(state => state.pointerEnabled);

  if (props.items === undefined) {
    return null;
  }

  return (
    <div
      className={
        cn(
          styles.sliderWrapper, props.className, styles[props.dimension], styles[props.dimension],
        )
      }
    >
      {
        (props.onDotClick)
          ? <ItemsWithDots
            UUID={ UUID }
            focusedIndex={ props.focusedIndex }
            isFocusedBlock={ props.isFocusedBlock }
            items={ (props.items || EMPTY_ARRAY) as ResponseMany<BannerItem[]> }
            onClick={ props.onDotClick }
          />
          : <Items
            cardConfigID={ props.cardConfigID }
            UUID={ UUID }
            dimension={ props.dimension }
            items={ props.items as ResponseMany<MediaItemType[]> }
            withName={ props.withName }
            withGenres={ props.withGenres }
            alwaysShowName={ props.alwaysShowName }
            isVisible={ props.isVisible }
            isFocusedBlock={ props.isFocusedBlock }
            focusedIndex={ props.focusedIndex }
            showAsSeries={ props.showAsSeries }
            onClick={ props.onClick }
            deeplink={ checkedDeeplink }
          />
      }
      {
        pointerEnabled && props.isFocusedBlock && !props.disableHorizontalNavigation ? (
          <HorizontalPointerNavigation
            isLeftEnabled={ props.focusedIndex > 0 }
            isRightEnabled={ props.focusedIndex < UUID.length - 1 }
            classNames={ {
              left: props.onDotClick ? styles.pointerDotsLeft : styles.pointerLeft,
              right: props.onDotClick ? styles.pointerDotsRight : styles.pointerRight,
            } }
          />
        ) : null
      }
    </div>
  );
};


Slider.displayName = 'Slider';


export default React.memo(Slider);
