import { useMemo } from "react";

import { useWatchProgressMultiple } from "~hooks/fetch/useWatchProgress/useWatchProgress";
import { getWindowAboveFocus } from "~lib/getWindowAboveFocus";
import Episode from "~typings/Episode";
import ItemObject from "~typings/ItemObject";

const WINDOW_SIZE = 10;


export const useWatchProgressForEpisodes = (episodes: Episode[], focusedIdx: number) => {

  const {start, end} = useMemo(() => getWindowAboveFocus(focusedIdx, WINDOW_SIZE), [focusedIdx]);

  const episodeIds = useMemo(() => episodes.slice(start, end).map((episode) => episode.id), [episodes, start, end]);

  const { data: watchProgress} = useWatchProgressMultiple(ItemObject.Episode, episodeIds);

  const watchProgressForFocusedEpisode = useMemo(() => {
    const wpToShow = watchProgress?.find((wp) => wp.resource.id === episodes[focusedIdx]?.id);
    if (!wpToShow) return null;
    if (!wpToShow.started) {
      return null;
    }

    return wpToShow.percents_watched;
  }, [watchProgress, focusedIdx, episodes]);


  return {
    watchProgressForFocusedEpisode
  }



}
