import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import InputWithKeyboard, { FocusOn } from '~components/InputWithKeyboard/component';
import usePassword from '~hooks/usePassword';
import { newFieldValue } from '~lib/keyboard';
import AccountInfo, { AccountField as Field } from '~typings/AccountInfo';
import { KeyBoardKey, KeyBoardType } from '~typings/Keyboard';
import NavigationDirection from '~typings/NavigationDirection';

import { getFieldName } from './utils';


type Props = Readonly<{
  isFocused: boolean;
  isNeedClear?: boolean;
  field: Field;
  accountInfo: AccountInfo;
  intl: InjectedIntl;
  onSubmit: (value: string) => void;
  onLeave?: (dir: NavigationDirection) => void;
}>;


const KEYBOARD: { [key in Field.Password]: KeyBoardType } = {
  [Field.Password]: KeyBoardType.Password,
};


const AccountEditField: React.FC<Props> = (props: Props) => {
  const [value, setValue] = React.useState<string>(props.accountInfo[props.field] || '');
  const [isShowingPassword, setIsShowingPassword] = React.useState<boolean>(false);
  const escapedPassword = usePassword({
    password: value,
    isShowingPassword,
  });
  const handleEyeClick = () => {
    setIsShowingPassword(!isShowingPassword);
  };
  const handleValueChange = (key: KeyBoardKey) => {
    setValue(oldValue => newFieldValue(oldValue, key))
  };
  const handleClearClick = () => {
    setValue('');
  };
  const handleSubmit = () => {
    props.onSubmit(value);
  };
  const header = getFieldName(props.field, props.intl);

  React.useEffect(() => {
    if (props.isNeedClear) {
      setValue('');
    }
  }, [props.isNeedClear]);

  return (
    <InputWithKeyboard
      inputValue={ (props.field === Field.Password) ? escapedPassword : value }
      focusOn={ props.isFocused ? FocusOn.KeyBoard : null }
      placeholder={ header }
      keyBoardType={ KEYBOARD[props.field] || KeyBoardType.Default }
      onChange={ handleValueChange }
      onClear={ handleClearClick }
      onSubmit={ handleSubmit }
      onEyeClick={ handleEyeClick }
      onLeave={ props.onLeave }
    />
  );
};


export default AccountEditField;
