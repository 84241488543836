import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import AutoScrollableArea from '~components/AutoScrollableArea/component';
import Loader from '~components/LightLoader/Loader';
import { ProductItem } from '~components/ProductItem';
import AlreadyAvailableButton from '~components/ProductItem/AlreadyAvailableButton';
import BestPriceButton, { Placeholder } from '~components/ProductItem/BestPriceButton';
import ExpiredText from '~components/ProductItem/Texts/ExpiredText';
import TrialFreePeriodAndPriceText from '~components/ProductItem/Texts/TrialFreePeriodAndPriceText';
import UnSubscribeButton from '~components/ProductItem/UnSubscribeButton';
import { getAuthData } from '~hooks/fetch/useAccount';
import { ResourceType, useInfinityProductsItems } from '~hooks/fetch/useProductsItems/useProductsItems';
import { useAllSubscriptions } from '~hooks/fetch/useSubscriptions';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import preparingText from '~lib/preparingText';
import { getActiveSubscriptionWithProduct } from '~lib/product/getProductInSubscriptions';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import {AUDIO_SHOWS_IN_SUBSCRIPTION,CHANNELS_IN_SUBSCRIPTION, MOVIES_IN_SUBSCRIPTION, NO_AVAILABLE_SUBSCRIPTIONS, SEE_ALL_ITEMS, SERIES_IN_SUBSCRIPTION } from '~localization';
import usePointer from '~stores/Pointer';
import Card from '~typings/Card';
import EverGreenPhase from '~typings/EvergreenPhase';
import NavigationDirection from "~typings/NavigationDirection";
import Product from '~typings/Product';
import ResponseMany from '~typings/ResponseMany';
import IconButton from '~ui/IconButton';

import ProductsItemsSlider from './sliders/ProductsItemsSlider';
import * as styles from './styles.module.css';


export enum FocusOn {
  SubscribeButton = 0,
  ChannelsSlider = 1,
  MoviesSlider = 2,
  SeriesSlider = 3,
  AudioShowsSlider = 4,
}

type Props = Readonly<{
  product?: Product;
  intl: InjectedIntl;
}>;


const ProductPage: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { accessToken } = getAuthData();
  const refByType: { [key in FocusOn]: { current: null | HTMLDivElement } } = {
    [FocusOn.SubscribeButton]: { current: null },
    [FocusOn.ChannelsSlider]: React.useRef(null),
    [FocusOn.MoviesSlider]: React.useRef(null),
    [FocusOn.SeriesSlider]: React.useRef(null),
    [FocusOn.AudioShowsSlider]: React.useRef(null),
  };
  const { productID } = useParams<{ productID: string }>();
  const { product } = props;
  const [linesCount, setLinesCount] = React.useState(0);
  // show/hide pointer navigation
  const isPointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);
  const flush = usePointer(state => state.flush);

  const navigationItems = React.useRef([
    { maxIndex: 0 }, // Button
    {}, // Channels Slider
    {}, // Movies Slider
    {}, // Series Slider
    {}, // AudioShows Slider
  ]);

  const navigationState = useSpatialNavigation({
    allowNavigation: !!product,
    navigationItems: navigationItems.current,
  });

  const {
    parsedItems: channels,
    query: { isSuccess: channelsFetched },
  } = useInfinityProductsItems({
    productID,
    resourceTypes: [ResourceType.Channels],
  });

  const {
    parsedItems: movies,
    query: { isSuccess: moviesFetched },
  } = useInfinityProductsItems({
    productID,
    resourceTypes: [ResourceType.Movies],
  });

  const {
    parsedItems: series,
    query: { isSuccess: seriesFetched },
  } = useInfinityProductsItems({
    productID,
    resourceTypes: [ResourceType.Series],
  });

  const {
    parsedItems: audioShows,
    query: { isSuccess: audioShowsFetched },
  } = useInfinityProductsItems({
    productID,
    resourceTypes: [ResourceType.AudioShows],
  });

  const isFetched = (channelsFetched && moviesFetched && seriesFetched && audioShowsFetched);

  const { isFetchedAfterMount, subscriptions } = useAllSubscriptions()

  React.useEffect(() => {
    if (isFetched) {
      if (channels?.data.length !== 0) {
        navigationItems.current[1] = { maxIndex: 0 };
        setLinesCount(1);
      } else {
        navigationItems.current[1] = {};
      }
      if (movies?.data.length !== 0) {
        navigationItems.current[2] = { maxIndex: 0 };
        setLinesCount(2);
      } else {
        navigationItems.current[2] = {};
      }
      if (series?.data.length !== 0) {
        navigationItems.current[3] = { maxIndex: 0 };
        setLinesCount(3);
      } else {
        navigationItems.current[3] = {};
      }
      if (audioShows?.data.length !== 0) {
        navigationItems.current[4] = { maxIndex: 0 };
        setLinesCount(4);
      } else {
        navigationItems.current[4] = {};
      }
    }
  }, [
    channels?.data,
    movies?.data,
    series?.data,
    audioShows?.data,
    isFetched,
  ]);
  React.useEffect(() => {
    if (isPointerEnabled) {
      updateDirections({
        [NavigationDirection.Up]: (navigationState.focusOn !== 0),
        [NavigationDirection.Down]: (navigationState.focusOn < linesCount),
      });
    }
  }, [
    linesCount,
    isPointerEnabled,
    navigationState.focusOn
  ]);

  React.useEffect(() => () => flush([
    NavigationDirection.Up,
    NavigationDirection.Down,
  ]), []);

  const handleSeeAllItemsClick = (resourceType: ResourceType | null) => {
    history.push(`/products/${productID}/collection-page/${resourceType}`);
  };

  const activeSubscriptionWithProduct = React.useMemo(
    () => getActiveSubscriptionWithProduct(product?.id, subscriptions),
    [product?.id, subscriptions],
  )
  const subscriptionWithProduct = React.useMemo(
    () => subscriptions
      ?.find(({ plan: { product: { id } }}) => (product?.id === id)),
    [product?.id, subscriptions],
  );
  const isExpired = React.useMemo(
    () => Boolean((
      activeSubscriptionWithProduct
      && activeSubscriptionWithProduct.access_granted
      && !activeSubscriptionWithProduct.autorenew.autorenewable
      && activeSubscriptionWithProduct?.expires_at
    ) || (
      !activeSubscriptionWithProduct
      && subscriptionWithProduct?.access_granted
      && subscriptionWithProduct?.expires_at
    )),
    [subscriptionWithProduct, activeSubscriptionWithProduct],
  );

  if (!product) {
    return (
      <div>
        { props.intl.formatMessage({ id: NO_AVAILABLE_SUBSCRIPTIONS }) }
      </div>
    );
  }

  const renderButton = () => {
    const isFocused = (navigationState.focusOn === FocusOn.SubscribeButton);

    if (accessToken && !isFetchedAfterMount) {
      return <Placeholder />
    }

    if (activeSubscriptionWithProduct?.cancellable) {
      return (
        <UnSubscribeButton
          isFocused={ isFocused }
          productID={ productID }
          subscriptionID={ activeSubscriptionWithProduct.id }
          intl={ props.intl }
        />
      );
    }

    if (isExpired) {
      return (
        <AlreadyAvailableButton isFocused={ isFocused } intl={ props.intl } />
      );
    }

    return (
      <BestPriceButton
        isFocused={ isFocused }
        isProductInSubscriptions={ !!activeSubscriptionWithProduct }
        product={ product }
        intl={ props.intl }
      />
    );
  };
  const renderSlider = (type: FocusOn) => {
    let id: string | null  = null;
    let items: ResponseMany<Card[]> | undefined = undefined;
    let resourceType: ResourceType | null = null;
    let className = '';

    if (type === FocusOn.MoviesSlider) {
      id = MOVIES_IN_SUBSCRIPTION;
      items = movies;
      resourceType = ResourceType.Movies;
    } else if (type === FocusOn.ChannelsSlider) {
      id = CHANNELS_IN_SUBSCRIPTION;
      items = channels;
      resourceType = ResourceType.Channels;
      className = styles.channel;
    } else if (type === FocusOn.SeriesSlider) {
      id = SERIES_IN_SUBSCRIPTION;
      items = series;
      resourceType = ResourceType.Series;
    } else if (type === FocusOn.AudioShowsSlider) {
      id = AUDIO_SHOWS_IN_SUBSCRIPTION;
      items = audioShows;
      resourceType = ResourceType.AudioShows;
    }

    if (
      !id || !resourceType
      || !items || !items?.data || items.data.length === 0
    ) { return null; }

    return (
      <div ref={ refByType[type] }>
        <ProductsItemsSlider
          id={ id }
          productID={ productID }
          resourceTypes={ [resourceType] }
          intl={ props.intl }
        />
        <div className={ cn(styles.buttonWrapper, className) }>
          <IconButton
            isFocused={ (navigationState.focusOn === type) }
            onClick={ () => handleSeeAllItemsClick(resourceType) }
          >
            { props.intl.formatMessage({ id: SEE_ALL_ITEMS }) }
          </IconButton>
        </div>
      </div>
    );
  };

  return (
    <AutoScrollableArea
      scrollToNode={ refByType[navigationState.focusOn]?.current }
      height={ valueInPixelsByWidth(43) }
    >
      <div
        className={ styles.container }
      >
        <ProductItem
          product={ product }
        />
        <div
          className={ styles.text }
        >
          {
            (product.subscription_page_description_header) ?
              <div
                className={ styles.subscriptionPageDescriptionHeader }
                dangerouslySetInnerHTML={ { __html: preparingText(product.subscription_page_description_header) } }
              />
              :
              null
          }
          {
            (product.subscription_page_description) ?
              <div
                className={ styles.subscriptionPageDescription }
                dangerouslySetInnerHTML={ { __html: preparingText(product.subscription_page_description) } }
                onClick={ (e) => { e.preventDefault(); e.stopPropagation(); } }
              />
              :
              null
          }
        </div>
        <div>
          { renderButton() }
        </div>
        {
          (isFetchedAfterMount && activeSubscriptionWithProduct?.cancellable) ? (
            <div
              className={ cn(styles.text, styles.description) }
            >
              <TrialFreePeriodAndPriceText
                isTrialPeriod={ (activeSubscriptionWithProduct?.phase?.type === 'trial') }
                price={ {
                  value: activeSubscriptionWithProduct?.plan.eligible_phase?.billing.price.formatted,
                  duration: (activeSubscriptionWithProduct?.plan.eligible_phase?.access_period.value || undefined),
                  unit: (activeSubscriptionWithProduct?.plan.eligible_phase?.access_period.unit || undefined),
                } }
                autoReNew={ activeSubscriptionWithProduct.autorenew }
              />
            </div>
          ) : isExpired ?
            (
              <div
                className={ cn(styles.text, styles.description) }
              >
                <ExpiredText
                  expiresAt={ subscriptionWithProduct?.expires_at }
                  phase={ subscriptionWithProduct?.plan.eligible_phase as EverGreenPhase }
                />
              </div>
            ) : null
        }
        {
          !isFetched ? (
            <div className={ cn(styles.loader) }>
              <Loader />
            </div>
          ) : (
            <>
              { renderSlider(FocusOn.ChannelsSlider) }
              { renderSlider(FocusOn.MoviesSlider) }
              { renderSlider(FocusOn.SeriesSlider) }
              { renderSlider(FocusOn.AudioShowsSlider) }
            </>
          )
        }
        <div
          className={ styles.text }
        >
          {
            (product.subscription_page_footer) ?
              <div
                className={ styles.subscriptionPageDescription }
                dangerouslySetInnerHTML={ { __html: preparingText(product.subscription_page_footer) } }
                onClick={ (e) => { e.preventDefault(); e.stopPropagation(); } }
              />
              :
              null
          }
        </div>
      </div>
    </AutoScrollableArea>
  );
};


export default injectIntl(ProductPage);
