import cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import findMediaItem from '~lib/findMediaItem';
import { ABOUT_CHANNEL } from '~localization';
import Channel from '~typings/Channel';
import Event from '~typings/Event';
import ItemObject from '~typings/ItemObject';
import IconButton from '~ui/IconButton';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  event: Event | null;
  intl: InjectedIntl;
}>;


const ChannelDetail: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const handleClick = React.useCallback(() => {
    if (props.event && props.event.channel_id) {
      history.push(`/media-item-details/${ItemObject.Channel}/${props.event.channel_id}`);
    }
  }, [props.event?.id]);
  const channel =
    (findMediaItem(props?.event?.channel_id, ItemObject.Channel) as Channel | null)
   ;
  return React.useMemo(() => (
    <div
      className={ styles.channelDetail }
    >
      {
        props.event === null
          ? <div className={ styles.channelDetailPlaceholder }/>
          : <IconButton
            isFocused={ props.isFocused }
            onClick={ handleClick }
          >
            <div className={ styles.channelInfo }>
              <div className={ cn(styles.channelAbout, {[styles.focused]: props.isFocused}) }>
                { props.intl.formatMessage({ id: ABOUT_CHANNEL }) }
              </div>
              <div className={ cn(styles.channelName, {[styles.focused]: props.isFocused}) }>{channel?.name}</div>
           </div>
          </IconButton>
      }
    </div>
  ), [props.isFocused, props.event?.id]);
};


export default injectIntl(ChannelDetail);
