/* eslint-disable max-len */
import { ac_00, gw_00, gw_02, gw_04, gw_11 } from 'customization-project-name/colors.js';
import * as React from 'react';

import { iconDisLike } from './styles.module.css';

export const IconDisLikeBase: React.FC<{ isFocused: boolean; isPressed: boolean }> = ({
  isFocused,
  isPressed,
}) => (
  <svg
    className={ iconDisLike }
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="22"
      cy="22"
      r="22"
      fill={ isFocused ? ac_00 : isPressed ? gw_11 : gw_02 }
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7729 23.9457C11.7915 24.7114 11.8754 25.2249 12.3692 25.533C13.1333 25.9998 15.5559 25.6917 16.5063 25.533C19.1059 25.0942 19.3109 25.0661 18.845 26.2799C18.7239 26.5974 17.9226 28.306 17.8387 30.4534C17.8108 31.0976 18.1462 32.4981 18.1462 32.4981C18.1462 32.4981 18.8357 34.5989 20.1868 34.0387C21.7522 29.9866 22.1715 27.8671 23.6157 25.8691C25.0506 23.8897 27.1471 23.1241 27.7714 22.2185C28.405 21.3035 28.4237 18.5118 27.9764 17.13C27.3894 15.3 26.9142 13.5447 23.5319 12.9005C22.1156 12.6017 19.5998 12.639 18.5189 12.7418C17.4474 12.8538 15.7981 13.3393 15.062 14.1236C14.3259 14.9079 14.2328 15.4774 13.916 16.187C13.5992 16.8966 12.4997 18.3157 12.3599 19.4641C12.2201 20.6219 12.4997 21.2941 12.3319 21.8917C12.1642 22.4892 11.7077 23.1801 11.7356 23.9457H11.7729ZM28.6566 22.0971C28.6566 22.0971 29.3741 22.4052 30.5761 22.4052C32.011 22.4052 32.7937 21.4342 33.0825 19.7536C33.3714 18.073 33.1012 16.5138 32.6073 15.7482C32.1694 15.0572 31.601 14.385 30.5108 13.9088C29.6629 13.5354 27.9112 13.1339 27.5944 14.0209C28.3771 15.2066 28.6473 15.6081 28.9082 16.5044C29.3368 17.9516 29.3368 19.2681 29.197 20.2951C29.0573 21.3221 28.6473 22.0971 28.6473 22.0971H28.6566Z"
      fill={ isFocused ? gw_00 : isPressed ? gw_04 : gw_11 }
    />
  </svg>
);
