import * as React from 'react';
import { InjectedIntl,injectIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import AccountPasswordChange from '~components/AccountEditField/Password';
import FieldSet from '~components/Fieldset/components';
import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { useAccount, useAccountMutation } from '~hooks/fetch/useAccount';
import useHotKey from '~hooks/useHotKey';
import AccountInfo, { AccountField as Field } from '~typings/AccountInfo';
import HotKey from '~typings/HotKey';
import { Popup } from '~ui/Popup';

import AccountEditField from './component';
import { getFieldName } from './utils';


type Props = Readonly<{
  intl: InjectedIntl;
}>;


const AccountEditFieldWrapper: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { mutateAsync } = useAccountMutation();
  const { isAuthorized, isLaunchingDone } = useApp();
  const match = useRouteMatch<{ fieldName: Field }>('/account-edit/:fieldName');
  const field: Field | undefined = match?.params.fieldName;
  const accountInfo = useAccount().data;
  const appAction = useAppAction();

  const handleBack = () => {
    history.goBack();
  };
  const handleFormSubmit = async (value: string) => {
    if (field) {
      await mutateAsync(
        {
            ...accountInfo,
            [field]: value,
          } as AccountInfo,
        {
          onSuccess: handleBack,
        },
      );
    }
  };

  React.useEffect(() => {
    if (!isLaunchingDone) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: { isLaunchingDone: true },
      });
    }
  }, []);

  useHotKey({
    isMounted: true,
    keyName: HotKey.KEY_RETURN,
    action: handleBack,
  }, []);

  if (!isAuthorized) { history.replace('/'); }

  if (!field) {
    handleBack();
    return null;
  }

  if (!accountInfo) { return null; }

  if (field === Field.Password) {
    return (
      <Popup>
        <AccountPasswordChange
          accountInfo={ accountInfo }
          intl={ props.intl }
        />
      </Popup>
    );
  }

  return (
    <Popup>
      <FieldSet
        headerText={ getFieldName(field, props.intl) }
      >
        <AccountEditField
          isFocused
          field={ field }
          accountInfo={ accountInfo }
          onSubmit={ handleFormSubmit }
          intl={ props.intl }
        />
      </FieldSet>
    </Popup>
  );
};


export default injectIntl(AccountEditFieldWrapper);
