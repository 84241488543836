import * as React from 'react';

import EPGPageEvent from '~components/EPG/Events/Event';
import NoEPGText from '~components/EPG/Events/NoEPGText';
import useFetchCurrentEvent, { getCachedCurrentEvent } from '~hooks/fetch/useCurrentEvent/useCurrentEvent';
import Event from '~typings/Event';

import * as styles from './../Events/styles.module.css';


type Props = Readonly<{
  channelID: string;
  isNeedFetchEvents?: boolean;
  onClick: () => void;
}>;


const CurrentEvent: React.FC<Props> = (props: Props) => {
  const cached = getCachedCurrentEvent(props.channelID);
  const currentEvent = cached?.data[0];
  const handleClick = React.useCallback(() => {}, []);

  const { isFetched } = useFetchCurrentEvent(
    props.isNeedFetchEvents ?
      props.channelID : undefined
  );

  const renderCurrentEvent = (
    <EPGPageEvent
      isVisible
      isCurrentEvent
      isSingleEvent
      isActive={ false }
      isFocused={ false }
      item={ currentEvent as Event }
      onClick={ handleClick }
    />
  );
  const renderNoEPG = React.useMemo(() => (
    <NoEPGText
      noEvents={ isFetched && (!cached?.data || cached.data.length === 0) }
    />
  ), [isFetched, cached?.data]);

  return React.useMemo(() => {
    return (
      (
        <div
          key={ props.channelID }
          className={ styles.singleCurrentEventBlockWrapper }
          onClick={ props.onClick }
        >
          {
            (currentEvent && currentEvent?.id) &&
              renderCurrentEvent
          }
          { renderNoEPG }
        </div>
      )
    );
  }, [cached?.data?.length, currentEvent?.id]);
};


export default React.memo(CurrentEvent);
