import focuser from '@focuser';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { useCardConfig } from '~app/global';
import { Typography } from '~app/ui-kit/Typography';
import { useActorPageSliderNavigation } from '~components/ActorPage/hooks';
import { Card } from '~components/Card';
import { TV_PROGRAMS } from '~lib/localizator/src/dictionaries';
import CardType from '~typings/Card';
import { CardConfigLayout } from '~typings/ScreenCardConfigs';
import { getSliderPropsForCards, SliderOffsetableVirtual } from '~ui/SliderOffsetable';

import * as styles from './styles.module.css';


type Props = {
  isFocused: boolean;
  dataProgramEvents: CardType[];
  onForceFocus: () => void;
}

const ProgramEventSlider$:React.FC<Props> = ({
  isFocused,
  dataProgramEvents,
  onForceFocus,
}:Props) => {
  const cardConfig = useCardConfig();

  const { 
    focusedIndex,
    onKeyLeftThrottled,
    onKeyRightThrottled,
    setFocusedIndex
  } = useActorPageSliderNavigation({
    elementsCountInSlider: dataProgramEvents.length,
  })

  const renderProgramEvent = (index: number) => {
    const cardItem = dataProgramEvents[index];

    if(!cardItem) {
      return null;
    } 

    return (
        <Card 
          key={ cardItem.id }
          item={ cardItem }
          cardConfig={ cardConfig }
          isFocused={ isFocused && focusedIndex === index }
          emitForceFocusOnHover
          onForceFocus={ () => setFocusedIndex(index) }
        />
      )
  }
  
  
  if(!dataProgramEvents.length) {
    return null;
  }

  return (
    <div className={ styles.sliderWrapper }>
      <Typography variant={ 'h5_medium' } className={ styles.sliderTitle }>
        <FormattedMessage id={ TV_PROGRAMS } />
      </Typography>
      <focuser.FocusableBlock
        isFocused={ isFocused }
        onKeyLeft={ onKeyLeftThrottled }
        onKeyRight={ onKeyRightThrottled }
        onForceFocus={ onForceFocus }
      >
        <SliderOffsetableVirtual 
          focusedIndex={ focusedIndex }
          childsCount={ dataProgramEvents.length }
          renderChild={ renderProgramEvent }
          { ...getSliderPropsForCards(CardConfigLayout.Horizontal) }
        />
      </focuser.FocusableBlock>
    </div>
  )
}

export const ProgramEventSlider = ProgramEventSlider$;