import React from 'react';

import getImageSRC from '~lib/MediaItemImageSRC';
import { getSceneItemHeight, getSceneItemWidth } from '~lib/SizesInPX';
import AudioShowPart from '~typings/AudioShowPart';
import ImageType from '~typings/ImageType';
import BlurableBaseImage from '~ui/Image/BlurableBaseImage';

import * as styles from './styles.module.css';


type Props = Readonly<{
  item: AudioShowPart;
}>;


const AudioShowScene: React.FC<Props> = (props: Props) => {
  const width = getSceneItemWidth();
  const height = getSceneItemHeight();

  const imageUrl = getImageSRC({
    mediaItem: props.item,
    dimension: ImageType.BANNER_WIDE,
    width,
    height,
  });

  if (!imageUrl) { return null; }

  return (
    <div
      className={ styles.imageWrapper }
    >
      <BlurableBaseImage
        className={ styles.image }
        width={ width }
        height={ height }
        src={ imageUrl }
      />
    </div>
  );
};


export default AudioShowScene;
