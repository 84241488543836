import * as React from 'react';

import * as styles from './styles.module.css';


type Props = Readonly<{
  meta?: any;
}>;


const SceneMediaItemMeta: React.FC<Props> = (props: Props) => {
  if (!props.meta || props.meta.every(x => (x?.length ?? 0) === 0)) { return null; }

  const renderItem = (line) => line ? <div key={ line }>{ line }</div> : null;

  return (
    <div
      className={ styles.meta }
    >
      { props.meta.map(renderItem) }
    </div>
  );
};


export default React.memo(SceneMediaItemMeta);
