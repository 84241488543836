import { useEffect, useMemo } from 'react';
import { QueryKey, useInfiniteQuery } from 'react-query';

import { useClient, useConfig } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import ApiClient from '~lib/ApiClient';
import Blackout from '~typings/Blackout';
import ResponseMany from '~typings/ResponseMany';

import {
  getLimitFromConfig,
  getNextPageForInfinityPagination,
  reducePaginatedPages,
} from '../utils';

const PREFIX_OF_A_COMPOSITE_KEY = 'blackouts';

const getKey = (channelID?: string | null): QueryKey => [PREFIX_OF_A_COMPOSITE_KEY, channelID];

const fetch = async (
  client: ApiClient,
  channelID: string | null | undefined,
  limit: number,
  offset: number,
) => {
  return await client.get(fetchURLs[FetchType.Blackouts], {
    ...fetchOptionsBasic[FetchType.Blackouts],
    'filter[channel_id]': channelID,
    'page[offset]': offset,
    'page[limit]': limit,
  });
};
export const useInfinityBlackouts = (channelID?: string | null) => {
  const client = useClient();
  const limit = getLimitFromConfig(useConfig());

  const query = useInfiniteQuery<ResponseMany<Blackout[]>>({
    queryKey: getKey(channelID),
    queryFn: ({ pageParam = 0 }) => fetch(client, channelID, limit, pageParam * limit),
    enabled: Boolean(channelID),
    getNextPageParam: getNextPageForInfinityPagination,
  });

  const parsedData: ResponseMany<Blackout[]> | undefined = useMemo(() => {
    return query.data?.pages && reducePaginatedPages(query.data.pages);
  }, [query.data?.pages]);

  return {
    query,
    parsedData,
  };
};

/**
 * Хук сам автоматически загружает все каналы
 */
export const useAutoInfinityBlackouts = (channelID?: string | null) => {
  const { query, parsedData } = useInfinityBlackouts(channelID);

  useEffect(() => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage();
    }
  }, [query]);

  return {
    data: parsedData,
    isFetching: Boolean(query.isFetchingNextPage || query.hasNextPage || query.isLoading),
  };
};
