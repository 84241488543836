import * as cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';

type IconProps = {
  className?: string;
};

type Props = {
  Icon: React.FC<IconProps>;
  classNames?: {
    wrapper?: string;
    icon?: string;
  };
  onClick?: () => void;
};

const IconBase = ({ Icon, onClick, classNames }: Props) => {
  return (
    <div onClick={ onClick } className={ cn(styles.wrapper, classNames?.wrapper) }>
      <Icon className={ cn(styles.icon, classNames?.icon) } />
    </div>
  );
};


export default IconBase;
