import { useEffect, useMemo } from 'react';
import { InfiniteData, QueryKey, useInfiniteQuery, useMutation, useQuery } from 'react-query';

import { useApp } from '~components/Provider/App';
import { queryClient } from '~global';
import { useClient, useConfig } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getAuthData } from '~hooks/fetch/useAccount';
import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from "~hooks/fetch/utils";
import ApiClient from '~lib/ApiClient';
import { transformArrayToUniqueString } from '~lib/cache-utils';
import deleteCacheWithOldAccessTokens from '~lib/ReactQuery/deleteCacheWithOldAccessTokens';
import AppConfig from '~typings/AppConfig';
import Favorite from '~typings/Favorite';
import { FavoriteType } from '~typings/FavoriteType';
import ResponseMany from '~typings/ResponseMany';


type Params = {
  accessToken: string | null;
  types: FavoriteType[];
  page: number;
  client: ApiClient;
  limit: number
}

const DEFAULT_TYPES = [
  FavoriteType.Channels,
  FavoriteType.Movies,
  FavoriteType.Series,
  FavoriteType.AudioShows,
  FavoriteType.RadioStations,
];

const PREFIX_OF_A_COMPOSITE_KEY = 'favorites_list';
const PREFIX_OF_ONE_ITEM = 'favorites_solo_item';
const FIRST_PAGE = 0;

type CommonQueryKeyParam = {
  types: FavoriteType[],
  accessToken: string | null,
  limit: number,
}

const favKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,
  withToken: (token: string | null) => [favKeys.PREFIX, token],
  withDefaultParams: (keyParams: CommonQueryKeyParam) => {
    return [
      ...favKeys.withToken(keyParams.accessToken),
      keyParams.limit,
      transformArrayToUniqueString(keyParams.types)
    ];
  },
  firstNormalPage: (keyParams: CommonQueryKeyParam) => [
    ...favKeys.withDefaultParams(keyParams),
    'fist-normal-page',
  ],
  infinityPages: (keyParams: CommonQueryKeyParam) => [
    ...favKeys.withDefaultParams(keyParams),
    'infinity-pages',
  ],
};

const favSolotemKeys = {
  PREFIX: PREFIX_OF_ONE_ITEM,
  oneItem: (keyParams: { accessToken: string | null; type?: FavoriteType; itemID?: string }) => [
    favSolotemKeys.PREFIX,
    keyParams.accessToken,
    keyParams.type || 'unknown-type',
    keyParams.itemID || 'empty',
  ],
};


/* Query Favorites List */
const fetch = async (params: Params): Promise<ResponseMany<Favorite[]>> => {
  deleteCacheWithOldAccessTokens(favKeys.PREFIX, 1);

  const types = (params.types.length !== 0 ? params.types : DEFAULT_TYPES);

  const optionsBasic = fetchOptionsBasic[FetchType.FavoritesV3]

  const options = {
    ...optionsBasic,
    access_token: params.accessToken,
    'filter[resource_type_in]': types.join(','),
    'page[offset]': (params.page * params.limit),
    'page[limit]': params.limit,
  };

  return await params.client.get(fetchURLs[FetchType.FavoritesV3], options);
};

type FavoritesQueryParams = {
  types: FavoriteType[],
  /**
   * Активен ли запрос, по дефолту = true
   * @default true
   */
  enabled?: boolean;
}


const getFavoritesFirstPageHookParams = ({
  config,
  client,
  queryParams,
}: {
  config: AppConfig,
  client: ApiClient
  queryParams: FavoritesQueryParams,
}) => {
  const {
    types,
    enabled = true
  } = queryParams;
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(config);

  const keyParams : CommonQueryKeyParam = {
    types: queryParams.types,
    accessToken,
    limit,
  }

  return ({
    queryFn: async ()=>{
      // Смотрим в кэш infinity запросов
      const infinityPagesCache = queryClient.getQueryData<InfiniteData<ResponseMany<Favorite[]>>>(
        favKeys.infinityPages(keyParams),
      );
      // Если есть - достаем первую страницу, она нам и нужна
      const firstPageFromInfinityPages = infinityPagesCache?.pages[0];

      if(firstPageFromInfinityPages){
        return firstPageFromInfinityPages;
      }
      // Иначе делаем запрос, одновременно заполняя и infinity кэш, и возвращаем ответ
      const infinityPagesReq = await queryClient.fetchInfiniteQuery<ResponseMany<Favorite[]>>({
        queryKey: favKeys.infinityPages(keyParams),
        queryFn: () =>
        fetch({
            client,
            accessToken,
            page: FIRST_PAGE,
            types,
            limit,
          }),
      });
      return infinityPagesReq.pages[0];
    },
    queryKey: favKeys.firstNormalPage({
      types,
      accessToken,
      limit,
    }),
    enabled: Boolean(accessToken && enabled),
  });
}

const getFavoritesFirstPageFromCache = ({
  config,
  types
}: {
  config: AppConfig,
  types: FavoriteType[],
}) => {
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(config);
  return queryClient.getQueryData<ResponseMany<Favorite[]>>(
    favKeys.firstNormalPage({
      types,
      accessToken,
      limit,
    }),
  );
};

const useFavoritesFirstPage = (queryParams: FavoritesQueryParams) => {
  const client = useClient();
  const config = useConfig();
  const hookParams = getFavoritesFirstPageHookParams({
    queryParams,
    client,
    config,
  });
  return useQuery<ResponseMany<Favorite[]>>(hookParams);
};


const useFavoritesInfinity = (queryParams: FavoritesQueryParams) => {
  const { accessToken } = getAuthData();
  const client = useClient();
  const config = useConfig();
  const limit = getLimitFromConfig(config);
  const { activeProfileId } = useApp();
  const {
    types,
    enabled = true
  } = queryParams;


  const query = useInfiniteQuery<ResponseMany<Favorite[]>>({
    queryKey: favKeys.infinityPages({
      types,
      accessToken,
      limit,
    }),
    queryFn: ({ pageParam = 0 }) =>
      fetch({ page: pageParam, accessToken, types, client, limit }),
    getNextPageParam: getNextPageForInfinityPagination,
    enabled: Boolean(accessToken && activeProfileId && enabled),
  });

  const parsedData = useMemo(() => {
    return query.data?.pages && reducePaginatedPages(query.data.pages);
  }, [query.data?.pages]);


  return {
    query,
    parsedData
  }

}

/**
 * Хук сам автоматически загружает все страницы
 */
const useAutoInfinityFavorites = (queryParams: FavoritesQueryParams) => {
  const { query, parsedData } = useFavoritesInfinity(queryParams);

  useEffect(() => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage();
    }
  }, [query]);

  return {
    query,
    parsedData,
  };
};


/* Update Favorites List */
const updateFavorites = async (
  client,
  accessToken: string | null,
  itemID: string,
  isInFavorites: boolean,
) => {
  if (!accessToken || !itemID) {
    return null;
  }

  const url = fetchURLs[FetchType.FavoritesV3]


    if (isInFavorites) {
      await client.delete(`${ url }/${ itemID }`, {
        access_token: accessToken,
      });
    }
    else {
      await client.post(`${ url }/${ itemID }`, {
        access_token: accessToken,
      });
    }
};

const deleteBulkFavorites = async (
  client: ApiClient,
  accessToken: string | null,
  resourceIDs: string[],
) => {
  if (!accessToken || !resourceIDs) {
    return null;
  }

  try {
    await client.patch(`${ fetchURLs[FetchType.FavoritesV3] }`, {
      access_token: accessToken,
      resource_id_in: resourceIDs
    });
  } catch {
    // ignore
  }
};

// Сбрасывает все запросы фаворитов, в которых есть списки
const resetListFavoriteItems = ()=> {
  return queryClient.resetQueries({
    queryKey: [favKeys.PREFIX],
  })
}

// Инвалидирует соло запросы фаворитов
const invalidateSoloFavoriteItems = () => {
  return queryClient.invalidateQueries({
    queryKey: [favSolotemKeys.PREFIX],
  });
}

const useToggleFavorite = () => {
  const { accessToken } = getAuthData();
  const client = useClient();

  return useMutation(
    ({ itemID, isInFavorites }: { itemID: string; isInFavorites: boolean; type?: FavoriteType }) =>
      updateFavorites(client, accessToken, itemID, isInFavorites),
    {
      onMutate: async ({ type, itemID, isInFavorites }) => {
        const currentTogglingFavoriteItemKey = favSolotemKeys.oneItem({
          accessToken, type, itemID
        });
        // Отменяем запросы на фавориты, если они есть в моменте
        await Promise.all([
          queryClient.cancelQueries([favSolotemKeys.PREFIX]),
          queryClient.cancelQueries([favKeys.PREFIX]),
        ]);

        // Получаем прошлое значение
        const previousFavorite = queryClient.getQueryData<boolean>(
          currentTogglingFavoriteItemKey,
        );

        if (!isInFavorites) {
          // Если запрос на добавление, нам нужно добавить этот фаворит в кэш
          queryClient.setQueryData<boolean>(currentTogglingFavoriteItemKey, true);
        } else {
          // Иначе мы должны просто удалить из кэша:
          queryClient.setQueryData<boolean>(currentTogglingFavoriteItemKey, false);
        }

        // Возвращаем прошлый фаворит, чтобы в случае ошибки вернуть как было
        return { previousFavorite };
      },
      onError: (
        _err,
        { itemID, type },
        context?: {
          previousFavorite?: boolean;
        },
      ) => {
        // Если произошла ошибка, возвращаем как было
        const currentTogglingFavoriteItemKey = favSolotemKeys.oneItem({accessToken, type, itemID});
        queryClient.setQueryData(currentTogglingFavoriteItemKey, context?.previousFavorite);
      },
      // В конце перезагружаем фавориты, которые загружены списком
      onSettled: resetListFavoriteItems,
    },
  );
};

const useDeleteMultipleFavorite = () => {
  const { accessToken } = getAuthData();
  const client = useClient();

  return useMutation(
    ( resourceIDs: string[]) => deleteBulkFavorites(client, accessToken, resourceIDs),

    {
      // В конце перезагружаем все фавориты
      onSettled:  async ()=>{
        await Promise.all([
          resetListFavoriteItems(),
          invalidateSoloFavoriteItems(),
        ]);
      },
    },
  );
};

type FavoriteItemParams = {
  accessToken: string | null;
  queryKey: QueryKey;
  client: ApiClient;
  type: FavoriteType;
  itemID: string | undefined;
};

const fetchFavoriteItem = async (params: FavoriteItemParams): Promise<boolean> => {
  const { accessToken, type, itemID, client } = params;
  if (!itemID || !type || !accessToken) {
    return false;
  }

  const response = await client.get(`${ fetchURLs[FetchType.FavoritesV1] }/${ type }`, {
    access_token: accessToken,
    'fields[movie]': 'id',
    'filter[id_in]': itemID,
  });

  return Boolean(response.data?.[0]?.created_at);
};

const useFavoriteItem = (type: FavoriteType, itemID: string | undefined) => {
  const { accessToken } = getAuthData();
  const queryKey = favSolotemKeys.oneItem({accessToken, type, itemID});
  const client = useClient();

  return useQuery<boolean>(
    queryKey,
    () => fetchFavoriteItem({ queryKey, accessToken, type, itemID, client }),
    {
      enabled: Boolean(itemID && accessToken && type),
      notifyOnChangeProps: 'tracked',
    },
  );
};

export {
  getFavoritesFirstPageFromCache,
  getFavoritesFirstPageHookParams,
  PREFIX_OF_A_COMPOSITE_KEY,
  useAutoInfinityFavorites,
  useDeleteMultipleFavorite,
  useFavoriteItem,
  useFavoritesFirstPage,
  useFavoritesInfinity,
  useToggleFavorite,
};
