import settings from 'customization-project-name/settings.json';
import { values } from 'lodash';
import * as React from 'react';

import PreLaunchIPTVAuth from '~components/PreLaunch/IPTVAuth';
import { ActionType, useEnv, useEnvAction } from '~components/Provider/Env';
import { useConfig } from '~global';
import { useAutoInfinityChannels } from '~hooks/fetch/useChannels';
import useClientApiErrorCatcher from '~hooks/useClientApiErrorCatcher';
import getScreenWidth from '~lib/screen/width';

type Props = Readonly<{
  children: any;
}>;


const PreLaunch: React.FC<Props> = (props: Props) => {
  const [isPreLaunchDone, setIsPreLaunchDone] = React.useState<boolean>(false);
  const config = useConfig();
  useClientApiErrorCatcher();
  useAutoInfinityChannels();

  React.useEffect(() => {
    // TODO:
    // Эта логика была перемещена сюда после рефакторинга конфига
    // Ее тоже нужно разрбрать, и понять как лучше сделать, может через брекпоинты CSS?
    if (typeof document !== 'undefined' && getScreenWidth() === 1920) {
      document.body.classList.add('fullHD');
    }
  }, []);

  // TODO:
  // Эта логика тоже осталась после рефакторинга конфига, нужно ее тоже отрефакторить

  const env = useEnv();
  const envAction = useEnvAction();

  React.useEffect(() => {
    if (
      [
        settings.apiProduction.replace(/https?:\/\//, ''),
        settings.apiDevelopment.replace(/https?:\/\//, '')
      ].includes(env.api.replace(/https?:\/\//, ''))
    ) {
      (values(config.smartTV.apis || {}) as string[] || []).forEach((api: string) => {
        envAction({
          api,
          type: ActionType.AddAPI,
        });
      });
    }
  }, [config.smartTV.apis]);



  if (!isPreLaunchDone) {
    return <PreLaunchIPTVAuth onPreLaunchChanged={ setIsPreLaunchDone } />
  }

  return props.children;
};


export default PreLaunch;
