import cn from 'classnames';
import React from 'react';

import * as styles from './IconBtnHOC.module.css';

export interface BaseProps {
  className?: string;
  isFocused?: boolean;
  isDisabled?: boolean;
  /**
   * Иконка, если size = 'big', то сюда передавать иконку с размером 28x28
   * Если size = 'small', то сюда передавать иконку с размером 24x24
   */
  Icon: React.FC<{ className?: string }>;

  /**
   * Размер кнопки, для кнопок 44x44 преедавать `big`, для кнопок 36x36 - `small`
   */
  size: 'small' | 'big';
}

export const IconBtn: React.FC<BaseProps> = (props) => {
  const Icon = props.Icon;
  return (
    <div
      className={ cn(
        styles.button,
        {
          [styles.focused]: props.isFocused && !props.isDisabled,
          [styles.disabled]: props.isDisabled,
          [styles.small]: props.size === 'small',
        },
        props.className,
      ) }
    >
      {
        <Icon
          className={ cn(styles.icon, {
            [styles.focused]: props.isFocused,
            [styles.disabled]: props.isDisabled,
            [styles.small]: props.size === 'small',
          }) }
        ></Icon>
      }
    </div>
  );
};

export type IconBtnProps = Omit<BaseProps, 'Icon' | 'size'>;
export const IconBtnHOC = (icon: BaseProps['Icon'], size: BaseProps['size']) => {
  const IconBtnHOC = (props: IconBtnProps) => (
    <IconBtn
      { ...props }
      Icon={ icon }
      size={ size }
    />
  );

  return IconBtnHOC;
};
