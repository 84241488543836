import { map, toLower, toUpper } from "lodash";

import { KeyBoardKey, KeyBoardKeyType, KeyBoardType } from "~typings/Keyboard";

const isChar = (char): boolean => {
  if (char === null || char === undefined) return false;

  if (typeof char === 'string') return !!char.length;
  if (typeof char === 'number') return true;

  return false;
};

/**
 * Преобразует клавиатуру в необходимую раскладку
 * @param keybard 
 * @param toUppercase 
 */
const keyboardShift = (keybard: KeyBoardKey[][], toUppercase: boolean): KeyBoardKey[][] => {
  const modifFn = toUppercase ? toUpper : toLower;

  const updateKey = (key: KeyBoardKey) => {
    if (key.type === KeyBoardKeyType.UpperCase)
      return { ...key, type: KeyBoardKeyType.LowerCase };
    if (key.type === KeyBoardKeyType.LowerCase)
      return { ...key, type: KeyBoardKeyType.UpperCase };
    if (key.type === KeyBoardKeyType.Char)
      return { ...key, id: modifFn('' + key.id) }
    return key;
  };

  return map(keybard, kbLine => map(kbLine, updateKey))
}



/**
 * Ищет пару клавиатур с 2мя раскладками одного языка,
 * инвентирует индекс, отдает вторую раскладку
 * @param keyboardPairsTable - массив пар клавиатур антиподов по какому-либо признаку
 * @param currentKeyboardType - текущий тип клавиатуры для инвентирования
 */
const invertKeyboardPair = (
  keyboardPairsTable: Array<[KeyBoardType, KeyBoardType]>,
  currentKeyboardType: KeyBoardType
): KeyBoardType => {

  const pair = keyboardPairsTable.find(pair => pair.includes(currentKeyboardType));
  if (!pair) return currentKeyboardType;

  const keyboardIndex = pair.indexOf(currentKeyboardType);
  const invertedIndex = +!keyboardIndex;
  return pair[invertedIndex];
};

export {
  invertKeyboardPair,
  isChar,
  keyboardShift,
};
