import { findIndex } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import AllowedContent from '~typings/AllowedContent';
import Gender from '~typings/Gender';
import Profile from '~typings/Profile';


export const PROFILES_KEY = 'profiles';

export interface ProfileData {
  id?: string;
  name: string;
  birthdate: string | null;
  gender: Gender | null;
  allowed_content: AllowedContent;
  kids: boolean;
}

export enum ProfileActionType {
  Create,
  Update,
  Delete
}

export interface ProfileAction {
  type: ProfileActionType;
  payload: any;
}


const fetchProfilesList = async (client): Promise<Profile[]>  => {
  const { accessToken } = getAuthData();
  if (!accessToken) return [];

  const { data } = await client.get('/v1/account/users', {
    access_token: accessToken,
    'expand[user]': 'avatar,avatar.image'
  });

  return data;
};

const createProfile = async (client, accessToken, data: ProfileData) => {
  await client.post('/v1/account/users', { access_token: accessToken, ...data });
};

const updateProfile =  async (client, accessToken, id: string, data: ProfileData) => {
  await client.patch(`/v1/account/users/${id}`, {
    access_token: accessToken,
    ...data,
  });
};

const deleteProfile = async (client, accessToken, id: string) => {
  await client.delete(`/v1/account/users/${id}`, { access_token: accessToken });
};


// ***


export const useProfiles = () => {
  const client = useClient();
  const { accessToken } = getAuthData();

  return useQuery(
    PROFILES_KEY,
    () => fetchProfilesList(client),
    {
      enabled: !!accessToken,
      cacheTime: Infinity
    }
  );
};

export const useProfilesMutation = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  const { accessToken } = getAuthData();

  return useMutation((action: ProfileAction) => {
    const { payload } = action;
    switch(action.type) {
      case ProfileActionType.Create:
        return createProfile(client, accessToken, payload.profileData);
      case ProfileActionType.Update:
        return updateProfile(client, accessToken, payload.id, action.payload.profileData);
      case ProfileActionType.Delete:
        return deleteProfile(client, accessToken, payload.id);
      default:
        return Promise.reject(new Error('Invalid parameter'));
    }
  }, {
    onMutate: (action: ProfileAction) => {
      const { payload } = action;
      queryClient.cancelQueries(PROFILES_KEY);
      const previousProfilesList = queryClient.getQueryData(PROFILES_KEY) as Profile[];
      let newProfilesList: Profile[] = [];

      // Optimistically update to the new value
      switch(action.type) {
        case ProfileActionType.Create: {
          const newProfileDraft: Profile = payload.profileData;
          newProfilesList = [...previousProfilesList, newProfileDraft];
          break;
        }
        case ProfileActionType.Update: {
          const index = findIndex(previousProfilesList, { id: payload.id });
          newProfilesList = [...previousProfilesList];
          newProfilesList[index] = payload.profileData as Profile;
          break;
        }
        case ProfileActionType.Delete: {
          const index = findIndex(previousProfilesList, { id: payload.id });
          newProfilesList = [...previousProfilesList];
          [].splice.call(newProfilesList, index, 1);
          break;
        }
      }

      queryClient.setQueryData(PROFILES_KEY, newProfilesList)

      return () => queryClient.setQueryData(PROFILES_KEY, previousProfilesList);
    },
    onSettled: () => {
      queryClient.invalidateQueries(PROFILES_KEY);
    }
  });
};

