import {
  KeyBoardKey,
  KeyBoardKeyType,
} from '~typings/Keyboard';


const keyboardDefault: KeyBoardKey[][] = [
  [
    { type: KeyBoardKeyType.Char, id: 1, },
    { type: KeyBoardKeyType.Char, id: 2, },
    { type: KeyBoardKeyType.Char, id: 3, },
    { type: KeyBoardKeyType.Char, id: 4, },
    { type: KeyBoardKeyType.Char, id: 5, },
    { type: KeyBoardKeyType.Char, id: 6, },
    { type: KeyBoardKeyType.Char, id: 7, },
    { type: KeyBoardKeyType.Char, id: 8, },
    { type: KeyBoardKeyType.Char, id: 9, },
    { type: KeyBoardKeyType.Char, id: 0, },
    { type: KeyBoardKeyType.Space },
    { type: KeyBoardKeyType.Backspace },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'q', },
    { type: KeyBoardKeyType.Char, id: 'w', },
    { type: KeyBoardKeyType.Char, id: 'e', },
    { type: KeyBoardKeyType.Char, id: 'r', },
    { type: KeyBoardKeyType.Char, id: 't', },
    { type: KeyBoardKeyType.Char, id: 'y', },
    { type: KeyBoardKeyType.Char, id: 'u', },
    { type: KeyBoardKeyType.Char, id: 'i', },
    { type: KeyBoardKeyType.Char, id: 'o', },
    { type: KeyBoardKeyType.Char, id: 'p', },
    { type: KeyBoardKeyType.Char, id: '@', },
    { type: KeyBoardKeyType.SpecialChars, id: '*#$' },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'a', },
    { type: KeyBoardKeyType.Char, id: 's', },
    { type: KeyBoardKeyType.Char, id: 'd', },
    { type: KeyBoardKeyType.Char, id: 'f', },
    { type: KeyBoardKeyType.Char, id: 'g', },
    { type: KeyBoardKeyType.Char, id: 'h', },
    { type: KeyBoardKeyType.Char, id: 'j', },
    { type: KeyBoardKeyType.Char, id: 'k', },
    { type: KeyBoardKeyType.Char, id: 'l', },
    { type: KeyBoardKeyType.Char, id: '_', },
    { type: KeyBoardKeyType.Char, id: '+', },
    { type: KeyBoardKeyType.Char, id: '.com', },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'z', },
    { type: KeyBoardKeyType.Char, id: 'x', },
    { type: KeyBoardKeyType.Char, id: 'c', },
    { type: KeyBoardKeyType.Char, id: 'v', },
    { type: KeyBoardKeyType.Char, id: 'b', },
    { type: KeyBoardKeyType.Char, id: 'n', },
    { type: KeyBoardKeyType.Char, id: 'm', },
    { type: KeyBoardKeyType.Char, id: ',', },
    { type: KeyBoardKeyType.Char, id: '.', },
    { type: KeyBoardKeyType.Char, id: '-', },
    { type: KeyBoardKeyType.Char, id: '.ru', },
    { type: KeyBoardKeyType.Close, id: 'OK', },
  ],
];


export default keyboardDefault;
