import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { useApp } from "~components/Provider/App";
import { getShortDate } from '~lib/localeUtils/dates';
import { EXPIRES_ON_DATE, PRICE_PERIOD } from '~localization';
import EverGreenPhase from '~typings/EvergreenPhase';


type Props = Readonly<{
  phase: EverGreenPhase | undefined;
  expiresAt: string | null | undefined;
  intl: InjectedIntl;
}>;


const ExpiredText: React.FC<Props> = ({ phase, expiresAt, intl }: Props) => {
  const { language } = useApp();

  return (
    <>
      {
        intl.formatMessage({ id: PRICE_PERIOD }, {
          price: phase?.billing.price.formatted,
          durationValue: (phase?.access_period.value || undefined),
          durationUnit: (phase?.access_period.unit || undefined),
        })
      }
      { '. ' }
      {
        intl.formatMessage({ id: EXPIRES_ON_DATE }, {
          date: getShortDate(expiresAt, language),
        })
      }
    </>
  );
};


export default injectIntl(ExpiredText);
