import './assets/modernizr';
import './assets/global-styles.css';

import bugsnagReact from '@bugsnag/plugin-react';
import { enableES5 } from 'immer';
import * as React from 'react';
import { render } from 'react-dom';

import { ErrorCrashScreen } from '~components/ErrorCrashFallback';
import Root from '~components/Root';

import Bugsnag from './setup/bugsnag';


enableES5();

Bugsnag.use(bugsnagReact, React);

const ErrorBoundary = Bugsnag.getPlugin('react');


render(
  <ErrorBoundary FallbackComponent={ ErrorCrashScreen }>
    <Root />
  </ErrorBoundary>,
  document.getElementById('app'),
);
