import * as React from 'react';

import {
  ChannelsContext,
  ChannelsDispatchContext,
  reducer,
} from './component.helpers';


const ChannelsProvider = ({ children }: {
  children?: React.ReactNode;
}): JSX.Element => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { collectionID: undefined },
  );

  return (
    <ChannelsContext.Provider
      value={ state }
    >
      <ChannelsDispatchContext.Provider
        value={ dispatch }
      >
        { children }
      </ChannelsDispatchContext.Provider>
    </ChannelsContext.Provider>
  );
};


ChannelsProvider.displayName = 'ChannelsProvider';


export default React.memo(ChannelsProvider);
