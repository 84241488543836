import ItemObject from '~typings/ItemObject';


const playerItemObjectHash = {
  'movie': 'movies',
  'episode': 'episodes',
  'channel': 'channels',
  'extra_video': 'extra_videos',
  'catchup': 'catchups',
  'competition': 'competitions',
  'highlight': 'highlights',
  'program_event': 'program_events',
  'part': 'parts',
};

export const streamURL = (itemID: string, itemObject: ItemObject): string => {


  return (
    // (item.object === 'competition_highlight') ?
    //   `/v1/competitions/highlights/${item.id}/stream`
    //   :
    `/v1/${playerItemObjectHash[itemObject]}/${itemID}/stream`
  );
};
