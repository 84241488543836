import React from 'react';
import { useInfiniteQuery } from 'react-query';

import { useClient, useConfig } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import ApiClient from '~lib/ApiClient';
import deleteCacheWithOldAccessTokens from '~lib/ReactQuery/deleteCacheWithOldAccessTokens';
import Card from '~typings/Card';
import ResponseMany from '~typings/ResponseMany';

import { getAuthData } from '../useAccount';
import { getDropdownFiltersOptions,getFiltersKeyString,getQuickFiltersString, SearchFilters } from '../useSearch/useSearch.helpers';
import { getLimitFromConfig, getNextPageForInfinityPagination, reducePaginatedPages } from '../utils';


enum ResourceType {
  Channels = 'channels',
  Movies = 'movies',
  Series = 'series',
  AudioShows = 'audio_shows',
};

type Params = {
  productID: string;
  resourceTypes: ResourceType[];
  filters?: SearchFilters;
};

type InfinityKeyParams = Params & {
  limit: number;
  accessToken: string | null;
}

type FetchParams = InfinityKeyParams & {
  page: number;
  client: ApiClient;
};

const productItemsQueryKeys = {
  all: ['products_items'],
  allByAccessToken: (accessToken: string | null) => [...productItemsQueryKeys.all, accessToken],
  infinityItems: (params: InfinityKeyParams) => [
    ...productItemsQueryKeys.allByAccessToken(params.accessToken),
    params.productID,
    params.resourceTypes.sort().join(','),
    getFiltersKeyString(params.filters || {}),
    params.limit,
  ],
};


const fetch = async (params: FetchParams) => {
  let options = {
    ...fetchOptionsBasic[FetchType.ProductsItems],
    'filter[product_id_eq]': params.productID,
    'filter[resource_type_in]': params.resourceTypes.join(','),
    access_token: params.accessToken,
    'page[offset]': (params.page * params.limit),
    'page[limit]': params.limit,
  };

  if (params.filters?.quickFilters) {
    options['filter[quick_filter_ids_include]'] = getQuickFiltersString(params.filters.quickFilters);
  }
  if (params.filters?.dropdownFilters) {
    options = {
      ...options,
      ...getDropdownFiltersOptions(params.filters.dropdownFilters),
    }
  }

  deleteCacheWithOldAccessTokens([productItemsQueryKeys.all], 1);

  return await params.client.get(fetchURLs[FetchType.ProductsItems], options);
};

const useInfinityProductsItems = (params: Params)=>{
  const { accessToken } = getAuthData();
  const limit = getLimitFromConfig(useConfig());
  const queryKey = productItemsQueryKeys.infinityItems({
    ...params,
    limit,
    accessToken,
  });
  const client = useClient();

  const query = useInfiniteQuery<ResponseMany<Card[]>>({
    queryKey,
    queryFn: ({ pageParam = 0 }) =>
    fetch({
      ...params,
      limit,
      client,
      page: pageParam,
      accessToken
    }),
    getNextPageParam: getNextPageForInfinityPagination,
    notifyOnChangeProps: 'tracked',
  });

  const parsedItems = React.useMemo(() => {
    return query.data?.pages && reducePaginatedPages(query.data.pages);
  }, [query.data?.pages]);

  return {
    query,
    parsedItems
  }
}




export {
  ResourceType,
  useInfinityProductsItems
};
