import focuser, { FocuserKeyHandler } from '@focuser';
import React, { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { useOldNavigationBlocker } from '~hooks/useOldNavigationBlocker';
import Episode from '~typings/Episode';
import Series from '~typings/Series';

import { EpisodesSlider } from '../EpisodesSlider';
import { SeasonsSlider } from '../SeasonsSlider';
import * as styles from './SeriesEpisodeSelect.module.css';
import { useInitialFocuses } from './utils';

export interface Props {
  series: Series;
  selectedEpisodeID?: string;
  onBack: () => void;
  onEpisodePlay: (episode: Episode) => void;
}

export const SeriesEpisodeSelect: React.FC<Props> = ({
  series,
  onBack,
  selectedEpisodeID,
  onEpisodePlay,
}) => {

  const [activeSeasonIdx, setActiveSeasonIdx] = React.useState(0);
  const [initialEpisode, setInitialEpisode] = React.useState(0);
  const [focusedSlider, setFocusedSlider] = React.useState<'episodes' | 'seasons'>('seasons');

  const [isInitialFocusLoaded, setIsInitialFocusLoaded] = React.useState(false);

  const initialFocuses = useInitialFocuses(series, selectedEpisodeID);

  useOldNavigationBlocker();

  useEffect(() => {
    if (isInitialFocusLoaded) {
      return;
    }

    if (!initialFocuses) {
      return;
    }

    const { seasonIdx, episodeIdx, isNeedToShowInitialFocus } = initialFocuses;

    unstable_batchedUpdates(() => {
      setIsInitialFocusLoaded(true);
      setActiveSeasonIdx(seasonIdx);
      setInitialEpisode(episodeIdx);
      if (isNeedToShowInitialFocus) {
        setFocusedSlider('episodes');
      }
    });
  }, [initialFocuses]);


  const backHandler: FocuserKeyHandler = (e) => {
    e.stop();
    e.stopNativeEvent();
    onBack();
  };

  if (!isInitialFocusLoaded) {
    return null;
  }

  return (
    <>
      <focuser.FocusableBlock
        isFocused={ focusedSlider === 'seasons' }
        onKeyDown={ () => setFocusedSlider('episodes') }
        onForceFocus={ () => setFocusedSlider('seasons') }
        isForceFocusEnabledInBranch = { false }
        className={ styles.seasonsSlider }
        isPointerDownAvailable
        onKeyReturn={ backHandler }
      >
        <SeasonsSlider
          seasonCount={ series.seasons.length }
          activeSeasonIdx={ activeSeasonIdx }
          onSeasonChange={ setActiveSeasonIdx }
        />
      </focuser.FocusableBlock>
      <focuser.FocusableBlock
        className={ styles.episodesSlider }
        onForceFocus={ () => setFocusedSlider('episodes') }
        isFocused={ focusedSlider === 'episodes' }
        onKeyUp={ () => setFocusedSlider('seasons') }
        isPointerUpAvailable
        onKeyReturn={ backHandler }
        isForceFocusEnabledInBranch = { false }
      >
        <EpisodesSlider
          // key нужен, чтобы этот компонент уничтожался после переключения сезона
          key={ activeSeasonIdx }
          series={ series }
          selectedSeasonIdx={ activeSeasonIdx }
          initialEpisodeIdx={ initialEpisode }
          onEpisodePlay={ onEpisodePlay }
        />
      </focuser.FocusableBlock>
    </>
  );
};
