export const MINIMAL_WEBOS_VERSION = 4.0;
export const MINIMAL_TIZEN_VERSION = 4.0;

/**
 * У webos версия в формате "1.3.0", "2.0.0"
 */
export const WEBOS_VERSION_REGEXP = /^\d+\.\d/;

/**
 * У тайзена версия в формате "Tizen 2.4", "Tizen 5.0"
 */
export const TIZEN_VERSION_REGEXP = /\d+\.\d$/;

export const isAnimationDisabledByUserAgent = () => {
  // проверяем только для webos, т.к. только у webos проблемы с доступом к версии
  // Подробнее в RST-624
  return isDisabledByUserAgentForWebos();
};

const isDisabledByUserAgentForWebos = () => {
  // userAgent строки взяты из: https://webostv.developer.lge.com/develop/specifications/web-api-and-web-engine

  const userAgentString = navigator?.userAgent;

  if (!userAgentString) {
    return false;
  }

  // Если не webos, то ничего не делаем
  if (userAgentString.indexOf('(Web0S; Linux/SmartTV)') === -1) {
    return false;
  }

  const chromeVersion = navigator?.userAgent?.match(/Chrome\/(\d+)\.\d+\.\d+\.\d+/)?.[1];
  if (!chromeVersion) {
    // если версии хрома нет, то скорее всего это старый телевизор, отключаем анимацию
    return true;
  }

  // если версия хром есть, и она равна 38, то это webos3, на нем анимация отключена
  if (Number(chromeVersion) === 38) {
    return true;
  }

  return false;
};
