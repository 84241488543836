import { clone, find, findIndex } from 'lodash';
import create, { GetState, SetState } from 'zustand';

import { getCachedCardCollection } from "~hooks/fetch/useCardCollection";
import { getCardColInfinityItemsCount } from "~hooks/fetch/useCardCollectionsItems";
import { allowedBlocks } from '~hooks/useBlocks/index.helpers';
import { getCardCollectionDimension } from "~lib/Dimension";
import { getBlockItemsCount, getIndex } from "~stores/Navigation/utils";
import AppConfig from '~typings/AppConfig';
import { Block, BlockType } from '~typings/Block';
import ImageType from "~typings/ImageType";
import MediaItemDimension from "~typings/MediaItemDimension";
import Page from '~typings/Page';
import PageObject from '~typings/PageObject';


export interface FocusPosition {
  pageID: string;
  blockID: string;
}

export interface Position {
  // pageID: string;
  // blockID: string;
  index: number;
  itemsCount: number;
  isGrid?: boolean;
  blockObject?: string;
  dimension?: MediaItemDimension
}

export enum pageWeComeFromTS {
  default = 'default',
  favorites = 'favorites',
  cardCollection = 'cardCollection',
  sliderFavorites = 'sliderFavorites',
  sliderCardCollection = 'sliderCardCollection',
  menu = 'menu',
  continueWatching = 'continueWatching',
}

export type BlockFocusHistory = { [keyBlockID in string]: Position; };

export type PageFocusHistory = { [keyPageID in string]: BlockFocusHistory; };

export interface State {
  currentPage: Page | null;
  focusHistory: PageFocusHistory;
  focusPositionOnPage: FocusPosition[];
  isNavigationInProcess: boolean;
  isNavigationNeedToSync: boolean;

  getPageFocusHistory: () => BlockFocusHistory;
  getFocusedBlockID: () => string | null;
  getFocusedBlockIndex: () => number;
  getFocused: () => Position | null;
  getPrevFocusedBlockID: () => string | null;
  getNextFocusedBlockID: () => string | null;
  getBlockPosition: (blockID: string, pageID?: string) => Position | null;
  getIsVisibleBlock: (blockID: string, blockObject?: BlockType) => boolean;
  flush: () => void;
  syncFocusedBlockIndex: (index: number, itemsCount?: number) => void;
  setFocusedBlockIndex: (index: number) => void;
  setCurrentPage: (page: Page) => void;
  setFocusedHistory: (
    config: AppConfig,
    accessToken: string | null,
    blocks: Block[],
    sliderSize?: [number, number],
  ) => void;
  setBlockCount: (pageID: string, blockID: string, itemsCount: number) => void;
  setFirstBlock: () => void;
  setPrevBlock: (prevBlockID?: string) => void;
  setNextBlock: (nextBlockID?: string) => void;
  setIsNavigationInProcess: (value: boolean) => void;
  setIsNavigationNeedToSync: (value: boolean) => void;
  pageWeComeFrom: pageWeComeFromTS;
  setPageWeComeFrom: (value: pageWeComeFromTS) => void;
  setFocusHistoryExpandItemsCount: (pageId: string | null, value: number | undefined) => void;
}


const useNavigation = create<State>(
  (set: SetState<State>, get: GetState<State>) => ({
    currentPage: null,
    focusHistory: {},
    focusPositionOnPage: [],
    isNavigationInProcess: false,
    isNavigationNeedToSync: false,
    pageWeComeFrom: pageWeComeFromTS.default,

    getPageFocusHistory: (): BlockFocusHistory => {
      try {
        const focusHistory = get().focusHistory;
        const pageID = get().currentPage?.id;

        return (pageID) ? focusHistory[pageID] || {} : {};
      } catch (ignore) {
        return {};
      }
    },
    getFocusedBlockID: (): string | null => {
      try {
        const currentPageID = get().currentPage?.id;
        const blockID = find(get().focusPositionOnPage, { pageID: currentPageID })?.blockID;
        const currentPageHistory = get().getPageFocusHistory();

        if (!blockID || !currentPageHistory[blockID]) {
          return Object.keys(currentPageHistory)[0] || null;
        }

        return blockID || null;
      } catch (ignore) {
        return null;
      }
    },
    getFocusedBlockIndex: () => {
      try {
        const blockID = find(get().focusPositionOnPage, { pageID: get().currentPage?.id })?.blockID;

        if (!blockID) {
          return 0;
        }

        const pageFocusHistory = get().getPageFocusHistory();

        return Math.max((Object.keys(pageFocusHistory).indexOf(blockID)), 0) || 0;
      } catch (ignore) {
        return 0;
      }
    },
    getFocused: () => {
      const blockID = get().getFocusedBlockID();

      if (!blockID) {
        return null;
      }

      const pageFocusHistory = get().getPageFocusHistory();

      try {
        return pageFocusHistory[blockID] || null;
      } catch (ignore) {
        return pageFocusHistory[0];
      }
    },
    getPrevFocusedBlockID: () => {
      const focusedBlockID = get().getFocusedBlockID();

      if (focusedBlockID === null) {
        return null;
      }

      const pageFocusHistory = get().getPageFocusHistory();
      const prevBlockIndex = (Object.keys(pageFocusHistory).indexOf(focusedBlockID)) - 1;
      const prevBlockID = Object.keys(pageFocusHistory)[prevBlockIndex];

      return prevBlockID || null;
    },
    getNextFocusedBlockID: () => {
      const focusedBlockID = get().getFocusedBlockID();

      if (focusedBlockID === null) {
        return null;
      }

      const pageFocusHistory = get().getPageFocusHistory();
      const prevBlockIndex = (Object.keys(pageFocusHistory).indexOf(focusedBlockID)) + 1;
      const prevBlockID = Object.keys(pageFocusHistory)[prevBlockIndex];

      return prevBlockID || null;
    },
    getBlockPosition: (blockID, paramsPageID) => {
      try {
        const pageID = paramsPageID || get().currentPage?.id;

        if (!pageID) {
          return null;
        }

        return (
          get().focusHistory[pageID][blockID] || null
        );
      } catch (ignore) {
        return null;
      }
    },
    getIsVisibleBlock: (blockID: string, blockObject?: BlockType): boolean => {
      const currentPage = get().currentPage;
      const pageFocusHistory = get().getPageFocusHistory();
      const focusedBlockIndex = get().getFocusedBlockIndex();

      if (blockObject && blockObject === BlockType.ExpandableCardColletion) {
        return true
      }

      const currentBlockIndex = Object.keys(pageFocusHistory).indexOf(blockID);
      //console.log(' blockID', blockID)
      //console.log('pageFocusHistory', pageFocusHistory)
      //console.log('Object.keys(pageFocusHistory)', Object.keys(pageFocusHistory))
      //console.log('currentBlockIndex', currentBlockIndex)
      //console.log('focusedBlockIndex', focusedBlockIndex)

      if (currentBlockIndex === -1) {
        return false;
      }

      if (currentPage?.object === PageObject.SingleCollectionPage) {
        return true;
      }

      const isFocusedBlock = (currentBlockIndex === focusedBlockIndex);
      const isAfterFocusedBlock = (currentBlockIndex > focusedBlockIndex)
        && (currentBlockIndex <= (focusedBlockIndex + 2));

      return isFocusedBlock || isAfterFocusedBlock;
    },

    flush: () => set((draft) => {
      draft.currentPage = null;
      draft.focusHistory = {};
      draft.focusPositionOnPage = [];
      draft.isNavigationInProcess = false;
      draft.isNavigationNeedToSync = false;
    }),
    syncFocusedBlockIndex: (index) => set((draft) => {
      draft.isNavigationNeedToSync = false;

      const pageID = get().currentPage?.id;
      const blockID = get().getFocusedBlockID();

      if (!pageID || !blockID) {
        return;
      }

      const focusHistory = clone(get().focusHistory);

      if (focusHistory[pageID][blockID]) {
        focusHistory[pageID][blockID].index = index;
      }

      draft.focusHistory = focusHistory;
    }),
    setFocusedBlockIndex: (index) => set((draft) => {
      const pageID = get().currentPage?.id;
      const blockID = get().getFocusedBlockID();

      if (!pageID || !blockID) {
        return;
      }

      const focusHistory = clone(get().focusHistory);

      if (focusHistory[pageID][blockID]) {
        focusHistory[pageID][blockID].index = index;
      }

      draft.focusHistory = focusHistory;
      draft.isNavigationNeedToSync = true;
    }),
    setCurrentPage: (currentPage) => set((draft: State) => {
      draft.currentPage = currentPage;
    }),
    setFocusedHistory: (config, accessToken, pageBlocks, sliderSize) => set(store => {
      const { currentPage } = store;

      if (currentPage !== null) {
        const focusHistory: BlockFocusHistory = {};
        // Put Focused Index Item Block to FocusHistory
        const blocks = allowedBlocks(!!accessToken, pageBlocks);

        blocks.forEach((block: Block) => {
          if (block.object !== BlockType.ExpandableCardColletion) {
            const index = getIndex(store.focusHistory, currentPage.id, block.id); //номер итема в фокусе в гор. слайдере

            const blockItemsCount = getBlockItemsCount(currentPage.id, config, block, sliderSize);

            if (blockItemsCount) {
              focusHistory[block.id] = {
                index: Math.min(index, (blockItemsCount - 1)),
                itemsCount: blockItemsCount,
              };
            }
          }
          else {
            if (block.card_collection?.id) {
              const cardCollection = getCachedCardCollection(block.card_collection?.id)?.data
              const cardCollectionItemsCount = getCardColInfinityItemsCount(config, {
                cardCollectionID: block.card_collection?.id
              })

              if (cardCollection?.description && cardCollection.description.length >= 290) {
                focusHistory[`${ block.id }-description-U7sGA1hs2`] = {
                  index: 0,
                  itemsCount: 1,
                }
              }
              if (cardCollection?.search_filters_group?.items && cardCollection.search_filters_group.items.length > 0) {
                focusHistory[`${ block.id }-filters-U7sGA1hs2`] = {
                  index: 0,
                  itemsCount: cardCollection.search_filters_group.items.length,
                }
              }
              if (cardCollectionItemsCount !== undefined) {
                focusHistory[`${ block.id }-grid-U7sGA1hs2`] = {
                  index: 0,
                  itemsCount: cardCollectionItemsCount,
                  isGrid: true,
                  dimension: cardCollection
                    ? getCardCollectionDimension(cardCollection)
                    : ImageType.BANNER,
                }
              }
            }

          }

        });

        store.focusHistory[currentPage.id] = focusHistory;

        // Set Focus
        if (!find(store.focusPositionOnPage, { pageID: currentPage.id }) && Object.keys(focusHistory).length !== 0) {
          store.focusPositionOnPage.push({
            pageID: currentPage.id,
            blockID: Object.keys(focusHistory)[0],
          });
        }
      }
    }),
    setBlockCount: (pageID, blockID, itemsCount) => set((draft) => {
      const focusHistory = clone(get().focusHistory);

      if (focusHistory[pageID]?.[blockID]) {
        focusHistory[pageID][blockID].itemsCount = itemsCount;
      }

      draft.focusHistory = focusHistory;
    }),
    setFirstBlock: () => set((draft: State) => {
      const pageFocusHistory = get().getPageFocusHistory();
      const blockID = Object.keys(pageFocusHistory)[0];

      if (blockID) {
        const currentPageID = draft?.currentPage?.id;
        if (currentPageID) {
          const { focusPositionOnPage } = draft;
          const index: number = findIndex(focusPositionOnPage, { pageID: currentPageID });

          if (index > -1) {
            focusPositionOnPage[index].blockID = blockID;
          }

          draft.focusPositionOnPage = focusPositionOnPage;
        }
      }
    }),
    setPrevBlock: (prevBlockID?: string) => set((draft: State) => {
      const prevFocusedBlockID = prevBlockID || get().getPrevFocusedBlockID();

      if (prevFocusedBlockID) {
        const currentPageID = draft?.currentPage?.id;
        if (currentPageID) {
          const { focusPositionOnPage } = draft;
          const index: number = findIndex(focusPositionOnPage, { pageID: currentPageID });

          if (index > -1) {
            focusPositionOnPage[index].blockID = prevFocusedBlockID;
          }

          draft.focusPositionOnPage = focusPositionOnPage;
        }
      }
    }),
    setNextBlock: (nextBlockID?: string) => set((state) => {
      const nextFocusedBlockID = nextBlockID || get().getNextFocusedBlockID();

      if (nextFocusedBlockID) {
        const currentPageID = state?.currentPage?.id;

        if (currentPageID) {
          const { focusPositionOnPage } = state;
          const index: number = findIndex(focusPositionOnPage, { pageID: state?.currentPage?.id });

          if (index > -1) {
            focusPositionOnPage[index].blockID = nextFocusedBlockID;
          }

          return ({
            ...state,
            focusPositionOnPage,
          });
        }
      }

      return state;
    }),
    setIsNavigationInProcess: (value) => set((draft) => {
      draft.isNavigationInProcess = value;
    }),
    setIsNavigationNeedToSync: (value) => set((draft) => {
      draft.isNavigationNeedToSync = value;
    }),
    setPageWeComeFrom: (value: pageWeComeFromTS) => set((state) => {
      state.pageWeComeFrom = value
    }),
    setFocusHistoryExpandItemsCount: (pageId: string | null, value: number | undefined) => {
      //expandable block
      //функция для изменения количества итемов в навигации в гриде на главной
      if (!pageId) return;
      if (!value) return;
      const focusHistory = get().focusHistory[pageId]
      for (let key in focusHistory) {
        if (key.includes('grid-U7sGA1hs2')) {
          set((store) => ({
            focusHistory: {
              ...store.focusHistory,
              [pageId]: {
                ...store.focusHistory[pageId],
                [key]: {
                  ...store.focusHistory[pageId][key],
                  itemsCount: value,
                },
              },
            },
          }));
        }
      }


    }
  })
);


export default useNavigation;
