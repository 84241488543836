import * as React from 'react';
import create, { GetState, SetState } from 'zustand';

import HotKey from '~typings/HotKey';


export type HotKeyAction = (event: React.KeyboardEvent) => void;

export type BindHotKey = {
  keyName: HotKey;
  action: HotKeyAction;
  isActive: boolean;
};

export type HotKeyUpdate = {
  action: HotKeyAction;
  isActive: boolean;
};

export type Bind = Readonly<{
  unbind: () => void;
  update: (update: HotKeyUpdate) => void;
}>;

export interface State {
  hotKeys: BindHotKey[];
  addHotKey: (hotKey: BindHotKey) => void;
  updateHotKey: (hotKey: BindHotKey) => void;
  removeHotKey: (hotKey: BindHotKey) => void;
  bind: (hotKey: BindHotKey) => Bind;
  apply: (keyNameToProcess: HotKey, keyDownEvent: React.KeyboardEvent) => void;
  navigationBlockerCounter: number;
  blockNavigation: ()=>void,
  unblockNavigation: ()=>void,
}


const useHotKeysStore = create<State>(
  (set: SetState<State>, get: GetState<State>) => ({
    navigationBlockerCounter: 0,
    hotKeys: [],
    addHotKey: (hotKey: BindHotKey) => set((draft: State) => {
      draft.hotKeys.unshift(hotKey);
    }),
    updateHotKey: (hotKey: BindHotKey) => set((draft: State) => {
      draft.hotKeys[draft.hotKeys.indexOf(hotKey)] = hotKey;
    }),
    removeHotKey: (hotKey: BindHotKey) => set((draft: State) => {
      draft.hotKeys.splice(draft.hotKeys.indexOf(hotKey), 1);
    }),
    bind: (hotKey: BindHotKey): Bind => {
      const state = get();
      state.addHotKey(hotKey);

      const unbind = () => {
        state.removeHotKey(hotKey);
      };
      const update = ({ action, isActive }) => {
        hotKey.action = action;
        hotKey.isActive = isActive;
        state.updateHotKey(hotKey);
      };

      return ({
        unbind,
        update,
      });
    },
    apply: (keyNameToProcess: HotKey, keyDownEvent: React.KeyboardEvent) => {
      const state = get();
      if(state.navigationBlockerCounter > 0) { return; }
      const hotKeyToProcess = state.hotKeys.find(({ keyName, isActive }) => (
        keyName === keyNameToProcess && isActive
      ));

      if (!hotKeyToProcess) { return false; }

      if (hotKeyToProcess) {
        hotKeyToProcess.action(keyDownEvent);
      }
    },
    blockNavigation: () => set((draft: State) => {
      draft.navigationBlockerCounter++;
      return draft;
    }),
    unblockNavigation: () => set((draft: State) => {
      draft.navigationBlockerCounter--;
      return draft;
    }),
  })
);


export default useHotKeysStore;
