import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useApp } from '~components/Provider/App';
import RatingsBlock from '~components/RatingsBlock';
import SceneItem from '~components/Scene/typings';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import { getFormatFullDate } from '~lib/localeUtils/dates';
import { isCard } from '~lib/objectType';
import useNavigation from '~stores/Navigation';
import { BlockType } from '~typings/Block';
import ItemObject from '~typings/ItemObject';
import News from '~typings/News';
import { SceneItemCustomName } from '~ui/SceneItemCustomName';

import * as styles from './styles.module.css';



type Props = Readonly<{
  item?: SceneItem | null;
  withFullInfo: boolean;
}>;

const MediaItemHeader:React.FC<Props> = ({ item, withFullInfo }: Props) => {
  const { language } = useApp();
  const { pathname } = useLocation();
  const focusedBlockID = useNavigation(state => state.getFocusedBlockID());
  const page = useNavigation(state => state.currentPage);
  const isContinueWatchingBlock = React.useMemo(
    () => (page?.blocks || EMPTY_ARRAY)
      .find(
        ({ object }) => (object === BlockType.ContinueWatchingBlock)
      )?.id === focusedBlockID,
    [page?.blocks, focusedBlockID],
  );

  const renderAdditionalName = () => {
    if (!item || !isCard(item)) { return null; }

    if (item.mutable) { return null; }

    if (!item.additional_name) { return null; }

    return (
      <div
      className={ styles.additionalName }
      >
        { item.additional_name }
      </div>
    );
  };

  if (!item) return null;

  return (
    <div
    className={ styles.wrapper }
    >
      { renderAdditionalName() }
      {
        (!item || pathname.includes('products')) ?
          null
          :
          <RatingsBlock
            isContinueWatchingBlock={ isContinueWatchingBlock }
            item={ item }
            className={ styles.ratingsIcons }
            classNames={ {
              progress: styles.progress,
            } }
          />
      }
      {
        (!item || item?.object !== ItemObject.News) ?
          null
          :
          <div
            className={ styles.date }
          >
            { getFormatFullDate((item as News).published_at, language) }
          </div>
      }
      <SceneItemCustomName
        item={ item }
        withFullInfo={ withFullInfo }
        useSeriesNameIfItemIsSeries={ isContinueWatchingBlock }
      />
      { !item || item?.object === ItemObject.AudioShow && 
        <div className={ styles.altDescriptionText }>
          { item.alt_description }
        </div>
      }
    </div>
  )
};

export default React.memo(MediaItemHeader);
