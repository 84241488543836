import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Route, Switch, useHistory, useRouteMatch}  from 'react-router-dom';
import { parse as parseURI} from 'url';

import AgreementBlock from '~components/AgreementBlock';
import { AuthLinkType } from '~components/AuthLinks/link';
import AuthStepScreen, { AuthState, AuthType, LayoutType, PageFocusOn } from '~components/AuthStepScreen';
import { PhoneConfirmType } from '~components/AuthStepScreen/PhoneCallConfirmBlock';
import Loader from '~components/LightLoader/Loader';
import CatchBack from '~components/PageManager/CatchBack';
import { PROFILES_PAGE_PATHNAME } from '~components/ProfilesManager/component';
import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { useConfig } from '~global';
import { useNetwork } from '~global';
import { useProfiles } from '~hooks/fetch/useProfiles';
import { useIsImplicitFlow } from '~hooks/useIsImplicitFlow';
import { isClientNotConfirmed } from '~lib/ApiClient/error-checkers';
import { PASSWORD_HAS_SENDED_WITH_EMAIL, PASSWORD_HAS_SENDED_WITH_PHONE } from '~localization';
import {
  DeviceLinkingCallbacks,
  DeviceLinkingLimitPage,
  DeviceLinkingPage,
  openDeviceLinkingPage,
  RetPathParams,
} from '~screens/Devices';
import { ConfirmationTypePhone, LoginFieldType } from '~typings/StartupConfig';
import { UsernameAvailabilityType } from '~typings/UsernameAvailabilityType';

import { getAuthLabels } from './getAuthLabels';

type Props = Readonly<{
  intl: InjectedIntl;
}>;

const AuthScreen: React.FC<Props> = ({ intl }: Props) => {
  const isIPTVNetwork = useNetwork().iptv_supported;
  const { authRedirectUrl } = useApp();
  const appAction = useAppAction();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { data: profiles } = useProfiles();
  const { smartTV, startup } = useConfig();
  const isImplicitFlow = useIsImplicitFlow();
  const { login_form: { login_field_type, confirmation } } = startup;

  const securitySigninRoute = useRouteMatch<{ page: string }>(`${path}/signin/:page`)?.params.page;
  const securitySignupRoute = useRouteMatch<{ page: string }>(`${path}/signup/:page`)?.params.page;
  const isSignUpPage = history.location.pathname.includes('signup')
  const AuthLabels = React.useMemo(() => getAuthLabels(
    intl, smartTV, isImplicitFlow,
  ),
    [intl, smartTV, isImplicitFlow]
  );

  const redirectToConfirmationPage = (confirmationType: UsernameAvailabilityType | null) => {
    if (!confirmationType) return;

    if (confirmationType === UsernameAvailabilityType.Email) {
      history.replace(`${path}/confirmations/email`);

    } else if (confirmationType === UsernameAvailabilityType.Msisdn) {
      const confirmType = confirmation.phone_confirmation_type;

      if (confirmType === ConfirmationTypePhone.Call) {
        history.replace(`${path}/confirmations/phonecall`);
      } else if (confirmType === ConfirmationTypePhone.Sms) {
        history.replace(`${path}/confirmations/sms`);
      }

    } else {
      history.replace('/');
    }
  };

  const redirectToForgotPasswordPage = (confirmationType: UsernameAvailabilityType | null) => {
    if (!confirmationType) return;

    if (confirmationType === UsernameAvailabilityType.Msisdn) {
      const confirmType = confirmation.phone_confirmation_type;

      if (confirmType === ConfirmationTypePhone.Call) {
        history.replace(`${path}/forgot-password/phonecall`);
      } else if (confirmType === ConfirmationTypePhone.Sms) {
        history.replace(`${path}/forgot-password/confirm`);
      }

    } else {
      history.replace('/');
    }
  };

  const openDeviceLinkingPageWithRetPaths = (retParams: RetPathParams) => {
    // В обеих случаях нам нужно перейти на одну и туже страницу
    const deviceLinkingCallbacks: DeviceLinkingCallbacks = {
      onSuccessLinking: retParams,
      afterContinueOnLinkingLimitPage: retParams
    }
    openDeviceLinkingPage(history, deviceLinkingCallbacks);
  };

  const [needToRedirect, setNeedToRedirect] = React.useState<boolean>(false);

  const redirectAfterAuth = () => {
    setNeedToRedirect(true);
  };

  React.useEffect(() => {
    if (!profiles || !needToRedirect) return;

    setNeedToRedirect(false);

    if (profiles.length > 1) {
      openDeviceLinkingPageWithRetPaths({
        retPath: PROFILES_PAGE_PATHNAME
      });
      return;
    }

    if (!authRedirectUrl) {
      openDeviceLinkingPageWithRetPaths({
        retPath: '/'
      });
      return;
    }

    appAction({
      type: ActionType.SetAuthRedirectUrl,
      payload: { authRedirectUrl: null },
    });

    const parsed = parseURI(authRedirectUrl);

    openDeviceLinkingPageWithRetPaths({
      retPath: parsed.pathname || authRedirectUrl,
      searchParamsToProxy: (parsed.search) ? `${parsed.search}&redirect=auth` : 'redirect=auth',
    });
  }, [needToRedirect, profiles])

  const handleBack = () => {
    if (!securitySigninRoute && !securitySignupRoute) {
      history.goBack();
    } else if (securitySigninRoute) {
      history.replace(`${path}/signin/`);
    } else if (securitySignupRoute) {
      history.replace(`${path}/signup/`);
    }
  };

  const rootAuthScreenLinks = React.useMemo(() => {
    const links: AuthLinkType[] = [];

    if (isSignUpPage && !isImplicitFlow) {
      links.push(AuthLinkType.ForgotPassword);
      links.push(AuthLinkType.ByPhone);
    }
    if (!isImplicitFlow && !isSignUpPage) {
      links.push(AuthLinkType.ByPhone);
      links.push(AuthLinkType.NotRegisteredYet);
    }
    if (isImplicitFlow) {
      links.push(AuthLinkType.RegisterOrLogin);
    }

    if (isIPTVNetwork) {
      links.push(AuthLinkType.EnableAutomaticLogIn);
    }

    return links;
  }, [isIPTVNetwork, isSignUpPage]);

  return (
    <>
      <CatchBack
        action={ handleBack }
        triggers={ [
          securitySigninRoute,
          securitySignupRoute,
        ] }
      />
      <Switch>
        <Route exact path={ `${path}/` }>
          <AuthStepScreen
            layoutType={ LayoutType.CodeAuth }
            links={ rootAuthScreenLinks }
            headerText={ AuthLabels.signInHeader as string }
            descriptionText={ AuthLabels.codeAuthDescr }
            onSubmit={ redirectAfterAuth }
          />
        </Route>

        <Route exact path={ `${path}/signin/` }>
          <AuthStepScreen
            authType={ AuthType.SignIn }
            layoutType={ LayoutType.UsernameForm }
            links={ !isImplicitFlow ? [ AuthLinkType.NotRegisteredYet ] : undefined }
            onSubmit={ () => history.replace(`${path}/signin/password`) }
            headerText={ AuthLabels.signInHeader as string }
            descriptionText={ AuthLabels.signInUserPageDescr }
          />
        </Route>

        <Route path={ `${path}/signin/password` }>
          <AuthStepScreen
            authType={ AuthType.SignIn }
            layoutType={ LayoutType.PasswordForm }
            links={ [AuthLinkType.ForgotPassword, AuthLinkType.ResendPasswordBySms] }
            onSubmit={ redirectAfterAuth } // ok
            onError={ (error, { confirmationType }: AuthState) => {
              if (isClientNotConfirmed(error)) {
                redirectToConfirmationPage(confirmationType);
              }
            } }
            headerText={ AuthLabels.signInHeader as string }
            descriptionText={ AuthLabels.signInPasswPageDescr }
            footerBlock={ isImplicitFlow ? (isFocused, onLeave) => (
              <AgreementBlock isFocused={ isFocused } onLeave={ onLeave } isImplicitFlow />
            ) : undefined }
          />
        </Route>

        <Route exact path={ `${path}/signup/` }>
          <AuthStepScreen
            authType={ AuthType.SignUp }
            links={ rootAuthScreenLinks }
            layoutType={ LayoutType.UsernameForm }
            onSubmit={ () => history.replace(`${path}/signup/password`) }
            headerText={ AuthLabels.signUpHeader as string }
            descriptionText={
              login_field_type === LoginFieldType.Phone ?
                AuthLabels.regUsernamePhoneDescr
                : login_field_type === LoginFieldType.Email ?
                  AuthLabels.regUsernameEmailDescr
                  : AuthLabels.regUsernameDescr
            }
          />
        </Route>

        <Route path={ `${path}/signup/password` }>
          <AuthStepScreen
            authType={ AuthType.SignUp }
            layoutType={ LayoutType.PasswordForm }
            onSubmit={ () => history.replace(`${path}/signup/credentials`) }
            headerText={ AuthLabels.signUpHeader as string }
            descriptionText={ AuthLabels.regPassDescr }
          />
        </Route>

        <Route path={ `${path}/signup/credentials` }>
          <AuthStepScreen
            authType={ AuthType.SignUp }
            layoutType={ LayoutType.ViewCredentials }
            defaultFocus={ PageFocusOn.Links }
            links={ [AuthLinkType.Register] }
            onSubmit={ ({ confirmationType }: AuthState) => {
              redirectToConfirmationPage(confirmationType);
            } }
            footerBlock={ (isFocused, onLeave) => (
              <AgreementBlock isFocused={ isFocused } onLeave={ onLeave } />
            ) }
            headerText={ AuthLabels.signUpHeader as string }
            descriptionText={ AuthLabels.credentialsDescr }
          />
        </Route>

        <Route path={ `${path}/confirmations/email` }>
          <AuthStepScreen
            authType={ AuthType.SignUp }
            layoutType={ LayoutType.ConfirmCodeForm }
            links={ [AuthLinkType.ResendSecurityCode] }
            onSubmit={ redirectAfterAuth } // ok
            headerText={ AuthLabels.signUpHeader as string }
            descriptionText={ AuthLabels.confirmEmailDescr }
          />
        </Route>

        <Route path={ `${path}/confirmations/sms` }>
          <AuthStepScreen
            authType={ AuthType.SignUp }
            layoutType={ LayoutType.ConfirmCodeForm }
            links={ [AuthLinkType.ResendSecurityCode] }
            onSubmit={ redirectAfterAuth } // ok
            headerText={ AuthLabels.signUpHeader as string }
            descriptionText={ AuthLabels.confirmPhoneDescr }
          />
        </Route>

        <Route path={ `${path}/confirmations/phonecall` }>
          <AuthStepScreen
            layoutType={ LayoutType.PhoneCallConfirmBlock }
            confirmType={ PhoneConfirmType.Registration }
            onSubmit={ redirectAfterAuth } // ok
            headerText={ AuthLabels.signUpHeader as string }
            descriptionText={ AuthLabels.confirmCallDescr }
            footerBlock={ (
              <>
                <div style={ {
                  transform: 'translate3d(0, -70px, 0)',
                  WebkitTransform: 'translate3d(0, -70px, 0)',
                  fontSize: '20px',
                } }>
                  { AuthLabels.confirmCallFooter }
                </div>
                <Loader
                  style={ {
                    transform: 'translate3d(0, 50px, 0)',
                    WebkitTransform: 'translate3d(0, 50px, 0)',
                  } }
                />
              </>
            ) }
          />
        </Route>

        <Route exact path={ `${path}/forgot-password/` }>
          <AuthStepScreen
            authType={ AuthType.PasswordReset }
            layoutType={ LayoutType.UsernameForm }
            headerText={ AuthLabels.resetPasswordHeader as string }
            descriptionText={
              login_field_type === LoginFieldType.Phone ?
                AuthLabels.forgotPhonePassDescr
                : login_field_type === LoginFieldType.Email ?
                  AuthLabels.forgotEmailPassDescr
                  : AuthLabels.forgotPhoneOrEmailPassDescr
            }
            onSubmit={ ({ confirmationType }) => {
              redirectToForgotPasswordPage(confirmationType);
            } }
          />
        </Route>

        <Route path={ `${path}/forgot-password/confirm` }>
          <AuthStepScreen
            authType={ AuthType.PasswordReset }
            layoutType={ LayoutType.ConfirmCodeForm }
            links={ [AuthLinkType.ResendSecurityCode] }
            onSubmit={ () => {
              history.replace(`${path}/forgot-password/new-password`);
            } }
            descriptionText={ (authState) => (
              login_field_type === LoginFieldType.Email || authState.username.includes('@') ?
                intl.formatMessage(
                  { id: PASSWORD_HAS_SENDED_WITH_EMAIL },
                  { email: authState.username }
                ) : intl.formatMessage(
                  { id: PASSWORD_HAS_SENDED_WITH_PHONE },
                  { phone: authState.username }
                )
              )
            }
            headerText={ AuthLabels.resetPasswordHeader as string }
          />
        </Route>

        <Route path={ `${path}/forgot-password/phonecall` }>
          <AuthStepScreen
            layoutType={ LayoutType.PhoneCallConfirmBlock }
            confirmType={ PhoneConfirmType.PasswordReset }
            onSubmit={ () => {
              history.replace(`${path}/forgot-password/new-password`);
            } }
            headerText={ AuthLabels.resetPasswordHeader as string }
            descriptionText={ AuthLabels.forgotPassCallDescr }
            footerBlock={ (
              <>
                <div style={ {
                  transform: 'translate3d(0, -70px, 0)',
                  WebkitTransform: 'translate3d(0, -70px, 0)',
                  fontSize: '20px',
                } }>
                  { AuthLabels.resetPassCallFooter }
                </div>
                <Loader
                  style={ {
                    transform: 'translate3d(0, 50px, 0)',
                    WebkitTransform: 'translate3d(0, 50px, 0)',
                  } }
                />
              </>
            ) }
          />
        </Route>

        <Route path={ `${path}/forgot-password/new-password` }>
          <AuthStepScreen
            layoutType={ LayoutType.PasswordForm }
            authType={ AuthType.PasswordReset }
            onSubmit={ redirectAfterAuth }
            headerText={ AuthLabels.resetPasswordHeader as string }
            descriptionText={ AuthLabels.newPasswordDescr }
          />
        </Route>

        <Route path={ `${path}/device-linking` }>
          <DeviceLinkingPage />
        </Route>

        <Route path={ `${path}/device-linking-limit` }>
          <DeviceLinkingLimitPage />
        </Route>

      </Switch>
    </>
  );
};

export default injectIntl(AuthScreen);
