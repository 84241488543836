import { Moment } from "moment";
import * as React from "react";
import { InjectedIntl, injectIntl } from "react-intl";

import { useApp } from "~components/Provider/App";
import { getScheduleDate, isToday } from "~lib/localeUtils/dates";
import { getEPGEventHeight } from "~lib/SizesInPX";
import { TODAY } from "~localization";

import * as styles from './styles.module.css';


type Props =  Readonly<{
  kind: 'Moment'
  idx: number;
  item?: Moment;
  properties?: never;
  withBackground?: boolean;
  intl: InjectedIntl;
}>;

function DateItem(props: Props) {
  const { language } = useApp();

  if (!props.item) { return null; }

  const renderText = isToday(props.item.format('YYYY-MM-DD')) ?
    props.intl.formatMessage({ id: TODAY })
    :
    getScheduleDate(props.item.format('YYYY-MM-DD'), language);

  return React.createElement(
    'div',
    {
      kind: props.item,
      className: styles.date,
      style: {
        top: `${getEPGEventHeight() * props.idx}px`
      }
    },
    renderText
  );
}


export default React.memo(injectIntl(DateItem));
