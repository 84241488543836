// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rknPlate--pE19n {\n  width: 100vw;\n  height: 6.5625vw;\n  background-color: rgba(38, 38, 42, 1);\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n}\n\n.innerContent--_TcXc {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  max-width: 82.578125vw;\n}\n\n.iconMargin--ty2sP {\n  margin-right: 1.5625vw;\n}", "",{"version":3,"sources":["webpack://./src/newapp/ui/rkn-plate/RknPlate.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,qCAAwB;EACxB,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;AACrB;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".rknPlate {\n  width: 100vw;\n  height: 6.5625vw;\n  background-color: $gw_02;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.innerContent {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  max-width: 82.578125vw;\n}\n\n.iconMargin {\n  margin-right: 1.5625vw;\n}"],"sourceRoot":""}]);
// Exports
export var rknPlate = "rknPlate--pE19n";
export var innerContent = "innerContent--_TcXc";
export var iconMargin = "iconMargin--ty2sP";
export default ___CSS_LOADER_EXPORT___;
