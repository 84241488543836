import focuser from '@focuser';
import React from 'react';

import { ChipsButtonFocuser } from '~app/ui-kit/ChipsButton';
import { DropdownSelectPopup } from '~components/DropdownSelectPopup';
import { ResetFiltersButtonFocuser } from '~newapp/ui-kit/Controls/Buttons/Icon_btn/Small_icon_btn/ResetFiltersButton';
import DropdownFilter from '~typings/DropdownFilter';
import QuickFilter from '~typings/QuickFilter';
import { SelectedFilters } from '~typings/SelectedFilters';
import { SliderOffsetable } from '~ui/SliderOffsetable';

import {
  getFilterName,
  useFiltersLineDropdownPoup,
  useFiltersLineHandlers,
  useFiltersLineNavigation,
} from './FiltersLine.hooks';
import * as styles from './FiltersLine.module.css';
import { DropdownPopupState } from './FiltersLine.types';

type Props = {
  /**
   * Фильтры которые можно выбрать
   */
  filters: (QuickFilter | DropdownFilter)[];
  /**
   * Выбранные фильтры
   */
  selectedFilters: SelectedFilters;
  /**
   * Коллбэк который будет вызываться при измении выбранных фильтров
   */
  onChangeFilters: (filters: SelectedFilters) => void;
  /**
   * Дополнительный класс для компонента
   */
  className?: string;
};

export const FiltersLine$: React.FC<Props> = ({
  filters,
  onChangeFilters,
  selectedFilters,
  className,
}) => {
  const isResetBtnActive = !!Object.keys(selectedFilters).length;

  const { sliderFocusedIdx, focuserProps, setFocusOn, focusOn, focusedIdx, setFocusedIdx } =
    useFiltersLineNavigation({
      filters,
      isResetBtnActive,
    });

  const [dropdownPopupState, setDropdownPopupState] = React.useState<DropdownPopupState>({
    isOpen: false,
  });

  const { onSelectDropdownFilter, makeOnclickForFilterButton } = useFiltersLineHandlers({
    onChangeFilters,
    selectedFilters,
    onDropdownPopupOpen: setDropdownPopupState,
  });

  const { dropdownSelectPopupProps } = useFiltersLineDropdownPoup({
    dropdownPopupState,
    setDropdownPopupState,
    selectedFilters,
    onSelectDropdownFilter,
  });

  return (
    <>
      <focuser.FocusableBlock
        isFocused
        { ...focuserProps }
        noNeedForceFocus
        className={ className }
      >
        <SliderOffsetable focusedIndex={ sliderFocusedIdx }>
          <ResetFiltersButtonFocuser
            isFocused={ focusOn === 'reset' }
            isDisabled={ !isResetBtnActive }
            focuserClassName={ styles.filterButton }
            emitForceFocusOnHover
            onClick={ () => {
              onChangeFilters({});
              setFocusOn('pick');
            } }
            onForceFocus={ () => {
              setFocusedIdx(0);
              setFocusOn('reset');
            } }
          />

          {filters.map((filter, idx) => (
            <ChipsButtonFocuser
              isFocused={ focusOn === 'pick' && focusedIdx === idx }
              key={ filter.id }
              isActive={ Boolean(selectedFilters[filter.id]) }
              focuserClassName={ styles.filterButton }
              showArrow={ filter.object === 'dropdown_filter' }
              emitForceFocusOnHover
              onForceFocus={ () => {
                setFocusedIdx(idx);
                setFocusOn('pick');
              } }
              onClick={ makeOnclickForFilterButton(filter) }
            >
              {getFilterName(filter, selectedFilters[filter.id])}
            </ChipsButtonFocuser>
          ))}
        </SliderOffsetable>
      </focuser.FocusableBlock>
      {dropdownSelectPopupProps && <DropdownSelectPopup { ...dropdownSelectPopupProps } />}
    </>
  );
};

/**
 * Компонент кнопки с фильтрами, которые можно выбрать
 *
 * Сделан на основе focuser-а, и ChipsButton кнопок
 */
export const FiltersLine = React.memo(FiltersLine$);
