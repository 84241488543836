import { useCallback } from 'react';

import { getAuthData } from '~hooks/fetch/useAccount';
import { invalidateAllContinueWatchingData } from '~hooks/fetch/useContinueWatching/useContinueWatchingV3';
import { getKey } from '~hooks/fetch/useWatchProgress/useWatchProgress';
import useUnmount from '~hooks/useUnmount/useUnmount';
import deleteCacheByKeys from '~lib/ReactQuery/deleteCacheByKeys';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';

import { getPercents } from './ProgressBar/utils';


type Props = {
  item: PlayerMediaItem;
  durationInSec: number;
  currentTimeInSec: number;
}

function useWatchProgressUpdate(props: Props) {
  const { accessToken } = getAuthData();
  const percentsWatched = getPercents(props.durationInSec, props.currentTimeInSec);

  const updateWatchProgress = useCallback(() => {
    if (!accessToken || !(
      props.item.object === ItemObject.Movie
      || props.item.object === ItemObject.Episode
      || props.item.object === ItemObject.Part
      || props.item.object === ItemObject.Channel
    )) { return; }

    deleteCacheByKeys([getKey(accessToken, props.item.object, props.item.id)]);

    // Инвалидируем ContinueWatching, потому что во время просмотра прогресс мог измениться
    invalidateAllContinueWatchingData();
  }, [percentsWatched, accessToken, props.item]);

  useUnmount(updateWatchProgress);
}


export default useWatchProgressUpdate;
