import { useEffect } from 'react';

import usePointer from '~stores/Pointer';
import NavigationDirection from '~typings/NavigationDirection';

import { FOCUSER_POINTER_EVENT_NAME, FocuserPointerEventDetail } from './events';

export const PointerUpdater = () => {
  const updateDirections = usePointer((state) => state.updateDirections);
  useEffect(() => {
    const eventHandler = (event: Event) => {
      const customEvent = event as CustomEvent<FocuserPointerEventDetail>;
      const dirs = customEvent.detail.getDirections();
      updateDirections({
        Up: dirs.has(NavigationDirection.Up),
        Right: dirs.has(NavigationDirection.Right),
        Down: dirs.has(NavigationDirection.Down),
        Left: dirs.has(NavigationDirection.Left),
      });

      if (customEvent.detail.returnButtonType === undefined) {
        return;
      }

      switch (customEvent.detail.returnButtonType) {
        case 'back':
          updateDirections({ Back: true, Menu: false, Close: false });
          break;
        case 'close':
          updateDirections({ Close: true, Back: false, Menu: false });
          break;
        case 'menu':
          updateDirections({ Menu: true, Back: false, Close: false });
          break;
        case null:
          updateDirections({ Back: false, Menu: false, Close: false });
          break;
      }

      return;
    };

    window.addEventListener(FOCUSER_POINTER_EVENT_NAME, eventHandler);
    return () => {
      window.removeEventListener(FOCUSER_POINTER_EVENT_NAME, eventHandler);
    };
  }, []);

  return null;
};
