import * as React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';

import { PrimaryButtonWrap } from '~app/ui-kit/PrimaryButton';
import Fieldset from '~components/Fieldset';
import InputWithKeyboard, { FocusOn } from '~components/InputWithKeyboard';
import Loader from '~components/LightLoader/Loader';
import CatchBack from '~components/PageManager/CatchBack';
import { usePlatform } from '~components/Provider/Platform';
import { usePatchDevice } from '~hooks/fetch/useEditDevice';
import {useGetDevice} from '~hooks/useGetDevices';
import { newFieldValue } from '~lib/keyboard';
import { REMOVE_DEVICE,
  THIS_IS_THE_CURRENT_DEVICE,
  YOU_CAN_CHANGE_THE_DEVICE_NAME,
  YOU_CAN_CHANGE_THE_DEVICE_NAME_CURRENT
} from '~lib/localizator/src/dictionaries';
import { KeyBoardKey, KeyBoardType } from '~typings/Keyboard';
import NavigationDirection from '~typings/NavigationDirection';
import { Popup } from '~ui/Popup';

import { DeviceDeleteConfirmPopup } from '../DeviceDeleteConfirmPopup';
import * as styles from './DeviceEdit.module.css';


export enum FocusElement {
  Input = 'input',
  DeletDeviceButton = 'delete_device_button',
  DeleteButtonForPopup = 'delete_button',
  CancelButtonForPopup = 'cancel_button'
}

enum Page {
  ChangingName = 'changing_name',
  ConfirmingDelete = 'confirming_delete'
}


export const DeviceEditPage = injectIntl(({ intl }) => {
  const history = useHistory();
  const params:any = useParams();
  const { data: devices } = useGetDevice();
  const currentDevice = devices?.find((device) => device.device.device_id === params.deviceId);

  const [page, setPage] = React.useState<string>(Page.ChangingName);
  const [focusOn, setFocusOn] = React.useState<FocusElement>(FocusElement.Input);
  const [deviceNameValue, setDeviceNameValue] = React.useState<string>(
    currentDevice ? currentDevice.name : ''
  );
  const { mutate: changeDeviceName, isLoading } = usePatchDevice();

  const { platform } = usePlatform();
  const isCurrentDevice = platform.getDeviceInfo().deviceId === params.deviceId;
  const deviceId = currentDevice?.device.id;

  const navigationHandlerOnEditPage = (navDir: NavigationDirection) => {
    if (navDir === NavigationDirection.Up) {
      setFocusOn(FocusElement.Input);
    } else if (navDir === NavigationDirection.Down) {
        if(isCurrentDevice) {
          return;
        }
      setFocusOn(FocusElement.DeletDeviceButton);
    }
  }

  const handleValueChange = (key: KeyBoardKey) => {
    setDeviceNameValue(oldValue => newFieldValue(oldValue, key))
  };

  const handleClearClick = () => {
    setDeviceNameValue('');
  };

  const handleSubmit = () => {
    if(deviceId) {
      changeDeviceName({deviceId, deviceName: deviceNameValue},{
        onSuccess: () => {
          history.goBack();
        }
      })
    }
  }

  const handleBack = () => {
    history.goBack();
  };

  const openDeletePage = () => {
    setPage(Page.ConfirmingDelete);
    setFocusOn(FocusElement.CancelButtonForPopup);
  }

  const closeDeletePage = ()=> {
    setPage(Page.ChangingName);
    setFocusOn(FocusElement.DeletDeviceButton);
  }

  const renderDeleteButton = () => {
    return (
      <>
        {!isCurrentDevice
          ? <PrimaryButtonWrap
              isFocused={ focusOn === FocusElement.DeletDeviceButton }
              onLeave={ navigationHandlerOnEditPage }
              onClick={ openDeletePage }
            >
              { intl.formatMessage({ id: REMOVE_DEVICE }) }
            </PrimaryButtonWrap>
          : null
        }
      </>
    )
  }

  if(isLoading){
    return <Loader/>
  }

  if(page === Page.ConfirmingDelete && currentDevice){
    return (
      <DeviceDeleteConfirmPopup
        onDeleteSuccess={ handleBack }
        onClose={ closeDeletePage }
        deviceToDelete={ currentDevice }
      />
    )
  }

  return (
    <>
      <CatchBack action={ handleBack } triggers={ [handleBack] }/>
      <Popup>
        <Fieldset
          footerAdditionalClassname={ styles.footerAdditionalClassname }
          descriptionText={
            isCurrentDevice
              ? `${ intl.formatMessage({ id: YOU_CAN_CHANGE_THE_DEVICE_NAME_CURRENT }) }
                ${ intl.formatMessage({ id: THIS_IS_THE_CURRENT_DEVICE }) }`
              : `${ intl.formatMessage({ id: YOU_CAN_CHANGE_THE_DEVICE_NAME }) }`
          }
          footer={ renderDeleteButton() }
        >
          <InputWithKeyboard
            inputValue={ deviceNameValue }
            focusOn={ focusOn === FocusElement.Input ? FocusOn.KeyBoard : null }
            keyBoardType={ KeyBoardType.UsernameEngUp }
            onChange={ handleValueChange }
            onClear={ handleClearClick }
            onSubmit={ handleSubmit }
            onLeave={ navigationHandlerOnEditPage }
          />
        </Fieldset>
      </Popup>
    </>
  )
})
