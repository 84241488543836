import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import Loader from '~components/LightLoader/Loader';
import { removeStreamAccessQueries } from '~hooks/fetch/useStreamAccess';
import { PREFIX_OF_A_COMPOSITE_KEY as subscriptionsKey } from '~hooks/fetch/useSubscriptions';
import useUnsubscribe from '~hooks/fetch/useSubscriptions/useUnsubscribe';
import { useSetState } from '~hooks/useSetState';
import deleteCacheByKeys from '~lib/ReactQuery/deleteCacheByKeys';
import {
  ASYNC_SUBSRIPTIONS,
  AUTH_ENTER_PASSWORD,
  CANCEL_PENDING,
  ERROR_HAS_OCCURRED,
  NAVIGATION_BACK,
  SUBSCRIPTION_WAS_CANCELLED,
} from '~localization';
import CancellationTransaction from '~typings/CancellationTransaction';
import Meta from '~typings/Meta';
import Button from '~ui/button';
import CheckIcon from '~ui/Icon/icons/Check';
import ErrorIcon from '~ui/Icon/icons/ErrorIcon';
import { Popup } from '~ui/Popup';

import RetentionFlowPopup from './RetentionFlowPopup';
import * as styles from './styles.module.css';

export interface State {
  data: CancellationTransaction | null;
  error: any | null;
  popup: Meta['popup'] | null;
  param: string | null;
  inProgress: boolean;
}

type Props = Readonly<{
  intl: InjectedIntl;
}>;

export const INITIAL_STATE = {
  data: null,
  error: null,
  popup: null,
  param: null,
  inProgress: false,
};

const UnsubscribeProceed: React.FC<Props> = ({ intl }) => {
  const history = useHistory();
  const { subscriptionID } = useParams<{ subscriptionID: string }>();
  const [state, setState] = useSetState<State>(INITIAL_STATE);
  const { mutate, isLoading } = useUnsubscribe();
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      subscriptionID
      && !isSuccess && !isError && !isLoading
      && !state.inProgress && !state.popup && !state.error
    ) {
      setState({ inProgress: true });
      mutate({ subscriptionID, param: state.param }, {
        onSuccess: (res) => {
          if (res?.meta?.popup) {
            setState({ popup: res.meta.popup });
          } else if (res?.data?.status === 'success') {
            setState({ data: res.data });
            setIsSuccess(true);
          } else {
            setState({ error: res?.data.error_reason });
            setIsError(true);
          }
        },
        onError: (error) => {
          setState({ error });
          setIsError(true);
        },
      });
    }
  }, [subscriptionID, state, isSuccess, isError, isLoading]);

  const handleBack = () => {
    history.goBack();
  };

  const handleContinue = () => {
    history.goBack();
    deleteCacheByKeys([subscriptionsKey]);
    removeStreamAccessQueries()
  };

  const isAsyncSubscription = state.error?.code === 'async_subscription';

  return (
    <>
      <Popup>
        <div className={ styles.contentWrapper }>
          <div className={ styles.infoIconWrapper }>
            {
              (isError && !isAsyncSubscription) ? (
                <ErrorIcon className={ cn(styles.icon, styles.errorIcon) } />
              ) : (isSuccess || isAsyncSubscription) ? (
                <CheckIcon className={ cn(styles.icon, styles.successIcon) } />
              ) : (
                <Loader />
              )
            }
          </div>
          <div className={ styles.info }>
            {
              (isError) ? isAsyncSubscription ?
                intl.formatMessage({ id: ASYNC_SUBSRIPTIONS })
                : state.error?.message || (
                intl.formatMessage({ id: ERROR_HAS_OCCURRED })
              ) : (isSuccess) ? (
                intl.formatMessage({ id: SUBSCRIPTION_WAS_CANCELLED })
              ) : (
                intl.formatMessage({ id: CANCEL_PENDING })
              )
            }
          </div>
          {
            (isError) ? (
              <Button
                isFocused
                className={ cn(styles.button, styles.center, styles.isFocused) }
                onClick={ handleBack }
              >
                {
                  intl.formatMessage({ id: isAsyncSubscription ? AUTH_ENTER_PASSWORD : NAVIGATION_BACK })
                }
              </Button>
            ) : (isSuccess) ? (
              <Button
                isFocused
                className={ cn(styles.button, styles.center, styles.isFocused) }
                onClick={ handleContinue }
              >
                {
                  intl.formatMessage({ id: AUTH_ENTER_PASSWORD })
                }
              </Button>
            ) : null
          }
        </div>
      </Popup>
      <RetentionFlowPopup
        state={ state }
        setState={ setState }
      />
    </>
  );
};


export default injectIntl(UnsubscribeProceed);
