import * as cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';

type ProgressBarProps = {
  progress: number;
  classNames?: {
    back?: string;
    line?: string;
  }
}


const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const properties = {
    className: cn(styles.back, props.classNames?.back),
  };
  const propertiesChildren = {
    className: cn(styles.line, props.classNames?.line),
    style: {
      width: `${props.progress}%`,
    },
  };

  return (
    React.createElement(
      'div',
      properties,
      React.createElement(
        'div',
        propertiesChildren,
      )
    )
  )
};


export default ProgressBar;
