/* global window */
import * as React from 'react';

import { useApp } from '~components/Provider/App';
import { usePlatform } from '~components/Provider/Platform';
import useHotKeysStore from '~stores/HotKeys';
import KeyCodes from '~typings/KeyCodes';

import {
  getKeyNames,
  onKeyDown,
} from './utils';


type Props = {
  keyCodes: KeyCodes;
}

const KeyDownHandler = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const applyHotKey = useHotKeysStore(state => state.apply);

  const { isOnLine } = useApp();
  const prevKeyName = React.useRef<string | null>(null);
  const keyNames = getKeyNames(props.keyCodes);
  const handleKeyUp = () => { prevKeyName.current = null; };

  const handleKeyDown = (keyDownEvent) => onKeyDown({
    keyNames,
    prevKeyName,
    keyDownEvent,
    keyCodes: props.keyCodes,
    applyHotKey,
  });
  const handleDisableClick = React.useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  React.useEffect(() => {
    if (isOnLine) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
    } else {
      window.addEventListener('click', handleDisableClick, true);
    }

    return (
      () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
        window.removeEventListener('click', handleDisableClick, true);
      }
    );
  }, [isOnLine]);

  return <>{ props.children }</>;
};


const KeyDownHandlerWrapper = ({ children }: {
  children?: React.ReactNode;
}): JSX.Element | null => {
  const { keyCodes } = usePlatform();

  if (!keyCodes) { return null; }

  return (
    <KeyDownHandler
      keyCodes={ keyCodes }
    >
      { children }
    </KeyDownHandler>
  );
};


export default React.memo(KeyDownHandlerWrapper);
