import * as cn from 'classnames';
import * as React from 'react';

import { Typography } from '~app/ui-kit/Typography';
import { Checkbox } from '~newapp/ui-kit/Other/Checkbox';

import * as styles from './styles.module.css';

type Props = {
  isFocused: boolean;
  isChecked?: boolean;
  children: React.ReactNode;
  className?: string;
};

export const CheckboxLine: React.FC<Props> = (props: Props) => {
  return (
    <div className={ cn(styles.checkboxLine, props.className) }>
      <Checkbox
        isFocused={ props.isFocused }
        isChecked={ props.isChecked }
        className={ styles.checkbox }
      />
      <Typography variant="subtitle1_medium" className={ styles.text }>{props.children}</Typography>
    </div>
  );
};
