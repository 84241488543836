import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { CheckboxLine } from './CheckboxLine';

type Props = {
  children?: React.ReactNode;
  isChecked?: boolean;
  focuserClassName?: string;
  buttonClassName?: string;
} & Omit<FocusableBlockProps, 'className' | 'isLastBlock'>;

export const CheckboxLineFcuser: React.FC<Props> = ({
  children,
  isChecked,
  focuserClassName,
  buttonClassName,
  ...focuserProps
}) => {
  return (
    <focuser.FocusableBlock
      { ...focuserProps }
      className={ focuserClassName }
      isLastBlock
    >
      {({ isFocused }) => (
        <CheckboxLine
          className={ buttonClassName }
          isFocused={ isFocused }
          isChecked={ isChecked }
        > { children }</CheckboxLine>
      )}
    </focuser.FocusableBlock>
  );
};
