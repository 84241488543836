import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl,injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useApp } from '~components/Provider/App';
import { useConfig } from '~global';
import { NAVIGATION_BACK,PLAYER_ERROR_NOT_PURCHASED_SUBTEXT } from '~localization';
import Button from '~ui/button';

import * as styles from './styles.module.css';


type Props = {
  isFocused: boolean;
  intl: InjectedIntl;
};

const PaymentInstruction = ({ isFocused, intl }: Props) => {
  const history = useHistory();
  const { smartTV: { paymentInstructions, baseSiteUrl } } = useConfig();
  const { language } = useApp();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className={ styles.info }>
        {
          paymentInstructions?.[language]
          ||
            intl.formatMessage({ id: PLAYER_ERROR_NOT_PURCHASED_SUBTEXT }, {
              site: baseSiteUrl
            })
        }
      </div>
      <div className={ styles.paymentMethodWrapper }>
          <Button
            isFocused={ isFocused }
            className={ cn(styles.button, {
              [styles.isFocused]: isFocused,
            }) }
            onClick={ handleGoBack }
          >
            { intl.formatMessage({ id: NAVIGATION_BACK }) }
          </Button>
      </div>
    </>
  );
};


export default injectIntl(PaymentInstruction);
