import { get } from 'lodash';
import { useEffect,useState } from 'react';

import { usePlatform } from '~components/Provider/Platform';
import { DRMType } from '~typings/Drm';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Protocol from '~typings/Protocol';

import { getProtocolsByDevice,ProtocolsByDRM } from './utils';


type Props = Readonly<{
  item: PlayerMediaItem | null;
  accessAllowed: boolean;
}>;

type Result = Readonly<{
  drm: DRMType | null;
  protocol: Protocol | null;
  takeNextProtocol: () => void;
  isReadyToFetchStream: boolean;
}>;


const useStreamParams = (props: Props): Result => {
  const { platform } = usePlatform();
  const [DRMs, setDRMs] = useState<DRMType[]>([]);
  const [protocols, setProtocols] = useState<ProtocolsByDRM>({} as ProtocolsByDRM);

  const drm = DRMs[0] || null;
  const protocol = get(protocols, [drm, 0], null);
  const isReadyToFetchStream = (props.accessAllowed && drm !== null && protocol !== null);

  const takeNextDRM = () => {
    if (DRMs.length > 1) {
      // Watching the next DRM
      setDRMs(DRMs.slice(1));
    }
  };

  const takeNextProtocol = () => {
    if (get(protocols, [drm, length], 0) > 1) {
      setProtocols({
        ...protocols,
        [drm]: (protocols[drm], []).slice(1),
      });
    } else {
      takeNextDRM();
    }
  };

  useEffect(() => {
    if (props.item !== null && typeof platform.getDeviceInfo === 'function') {
      const { drmKeys, protocolsByDrm } = getProtocolsByDevice(props.item, platform.getDeviceInfo());

      setDRMs(drmKeys);
      setProtocols(protocolsByDrm)
    }
  }, [props.item, platform.getDeviceInfo]);

  // if (props.item?.object === 'extra_video') {
  //   return ({
  //     drm: DRMType.SPbTVCas,
  //     protocol: Protocol.DASH,
  //     takeNextProtocol: () => {},
  //     isReadyToFetchStream: true,
  //   });
  // }

  return ({
    drm,
    protocol,
    takeNextProtocol,
    isReadyToFetchStream,
  });
};


export default useStreamParams;
