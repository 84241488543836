import { ac_00, gw_00, gw_11 } from 'customization-project-name/colors.js';
import React from "react";

interface Props {
  isFocused: boolean
}

/* eslint-disable max-len */
export function AnnounceIcon(props: Props) {
  if (props.isFocused) {
    return (
      <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30.1836" cy="30.1829" r="30" fill={ ac_00 } />
        <path
          d="M43.0316 23.9021C42.7276 23.9021 42.4357 23.7928 42.2168 23.5864L36.4767 18.401C36.1848 18.1339 36.0389 17.7574 36.0875 17.3688C36.1362 16.9802 36.3551 16.6402 36.6956 16.4459C38.6414 15.353 41.2074 15.6323 42.8492 17.1259C44.5396 18.656 45.0504 21.0848 44.1383 23.1735C43.9802 23.5256 43.664 23.7928 43.2748 23.8778C43.1897 23.9021 43.1046 23.9021 43.0195 23.9021H43.0316ZM17.347 23.9021C17.2618 23.9021 17.1767 23.9021 17.0916 23.8778C16.7146 23.7928 16.3862 23.5378 16.2281 23.1735C15.9119 22.4327 15.7538 21.6069 15.7903 20.7933C15.8633 19.3725 16.4713 18.0731 17.5294 17.1259C19.1712 15.6444 21.7372 15.3651 23.683 16.4459C24.0235 16.6402 24.2546 16.9802 24.2911 17.3688C24.3397 17.7574 24.1938 18.146 23.9019 18.401L18.1618 23.5864C17.9307 23.7928 17.6388 23.9021 17.347 23.9021ZM42.7276 42.5183L39.59 39.3731C41.3534 37.2358 42.4114 34.4913 42.4114 31.5162C42.4114 24.6914 36.8415 19.1296 29.9947 19.1296C23.1479 19.1296 17.5902 24.6914 17.5902 31.5162C17.5902 34.4913 18.6482 37.2358 20.4116 39.3731L17.2618 42.5183C16.7875 42.9919 16.7875 43.7569 17.2618 44.2305C17.5051 44.4734 17.8213 44.5827 18.1253 44.5827C18.4293 44.5827 18.7455 44.4734 18.9887 44.2305L22.1385 41.0853C24.2789 42.8462 27.0152 43.9027 29.9947 43.9027C32.9742 43.9027 35.7227 42.8462 37.8631 41.0975L41.0128 44.2305C41.2439 44.4734 41.5601 44.5827 41.8641 44.5827C42.1682 44.5827 42.4965 44.4734 42.7276 44.2305C43.2019 43.7569 43.2019 42.9919 42.7276 42.5183ZM31.2352 32.5362C31.2352 33.2284 30.6757 33.787 29.9825 33.787H24.2303C23.5249 33.787 22.9655 33.2284 22.9655 32.5362C22.9655 31.844 23.5249 31.2733 24.2303 31.2733H28.7299V25.3229C28.7299 24.6307 29.2894 24.0721 29.9825 24.0721C30.6757 24.0721 31.2352 24.6307 31.2352 25.3229V32.5362Z"
          fill={ gw_00 } />
      </svg>
    )
  }

  return (
    <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.4165 14.9749C39.028 14.9749 38.6551 14.8352 38.3753 14.5714L31.0407 7.94572C30.6678 7.60435 30.4813 7.12332 30.5435 6.62678C30.6056 6.13024 30.8853 5.69577 31.3204 5.4475C33.8067 4.05098 37.0856 4.40787 39.1834 6.31644C41.3434 8.27157 41.996 11.3749 40.8306 14.0438C40.6286 14.4938 40.2245 14.8352 39.7273 14.9438C39.6185 14.9749 39.5097 14.9749 39.4009 14.9749H39.4165ZM6.5972 14.9749C6.48843 14.9749 6.37965 14.9749 6.27088 14.9438C5.78915 14.8352 5.36959 14.5094 5.16758 14.0438C4.76355 13.0973 4.56154 12.0422 4.60816 11.0025C4.70139 9.18707 5.47837 7.52676 6.8303 6.31644C8.92812 4.42339 12.2069 4.0665 14.6932 5.4475C15.1284 5.69577 15.4236 6.13024 15.4702 6.62678C15.5324 7.12332 15.3459 7.61986 14.973 7.94572L7.63835 14.5714C7.3431 14.8352 6.97015 14.9749 6.5972 14.9749ZM39.028 38.7622L35.0188 34.7434C37.272 32.0124 38.624 28.5056 38.624 24.7039C38.624 15.9835 31.5069 8.87673 22.7582 8.87673C14.0095 8.87673 6.90799 15.9835 6.90799 24.7039C6.90799 28.5056 8.25992 32.0124 10.5131 34.7434L6.48843 38.7622C5.88239 39.3674 5.88239 40.345 6.48843 40.9501C6.79922 41.2604 7.20324 41.4001 7.59173 41.4001C7.98021 41.4001 8.38424 41.2604 8.69503 40.9501L12.7197 36.9312C15.4547 39.1812 18.9511 40.5312 22.7582 40.5312C26.5654 40.5312 30.0773 39.1812 32.8122 36.9468L36.8369 40.9501C37.1322 41.2604 37.5362 41.4001 37.9247 41.4001C38.3132 41.4001 38.7327 41.2604 39.028 40.9501C39.634 40.345 39.634 39.3674 39.028 38.7622ZM24.3432 26.0074C24.3432 26.8918 23.6284 27.6056 22.7427 27.6056H15.3925C14.4912 27.6056 13.7764 26.8918 13.7764 26.0074C13.7764 25.1229 14.4912 24.3936 15.3925 24.3936H21.1421V16.7903C21.1421 15.9059 21.8569 15.1921 22.7427 15.1921C23.6284 15.1921 24.3432 15.9059 24.3432 16.7903V26.0074Z"
        fill={ gw_11 }/>
    </svg>
  )
}
