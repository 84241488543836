import FavoriteType from "~typings/FavoriteType"
import ItemObject from "~typings/ItemObject"

export const getFavoriteType = (
  itemObject: ItemObject.Series | ItemObject.Movie | ItemObject.AudioShow
) => {
  if(itemObject === ItemObject.Movie) {
    return FavoriteType.Movies
  } else if(itemObject === ItemObject.Series) {
    return FavoriteType.Series
  }

  return FavoriteType.AudioShows
}
