/* eslint-disable max-len */
import React from "react";

type Props = {
  side?: number; //in vh
}

export const AdultIcon = ({ side }: Props) => {
  const width = side ? `${side}vh` : '19.444444444444446vh'
  const height = side ? `${side}vh`: '19.444444444444446vh'

  return (
    <div style={ { width, height } }>
      <svg  viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="70" cy="70" r="66" fill="#FF5555" stroke="white" strokeWidth="8" />
        <path
          d="M42.668 87.5615C45.5205 87.5615 47.4297 85.7197 47.4297 82.8672V59.3506C47.4297 56.0938 45.4082 54.0273 41.9717 54.0273C40.1299 54.0273 38.4902 54.207 36.6035 55.4648L30.7188 59.3955C29.4609 60.249 28.9219 61.3271 28.9219 62.6523C28.9219 64.5391 30.292 65.8643 32.0664 65.8643C32.9648 65.8643 33.4814 65.6846 34.29 65.123L37.7266 62.7422H37.9062V82.8672C37.9062 85.7197 39.8154 87.5615 42.668 87.5615Z"
          fill="white" />
        <path
          d="M68.0039 87.8984C76.6064 87.8984 82.5361 84.0352 82.5361 78.2402C82.5361 73.748 79.0771 70.7607 74.4053 70.0195V69.8398C78.4258 69.0537 81.3457 66.2236 81.3457 62.6523C81.3457 57.4414 75.9551 53.6904 68.0039 53.6904C60.0527 53.6904 54.6621 57.4414 54.6621 62.6299C54.6621 66.2686 57.582 69.0537 61.6475 69.8398V70.0195C56.9756 70.7607 53.4941 73.7256 53.4941 78.2178C53.4941 84.0352 59.3564 87.8984 68.0039 87.8984ZM68.0039 67.2568C65.7129 67.2568 64.0732 65.7744 64.0732 63.6182C64.0732 61.4619 65.7354 60.002 68.0039 60.002C70.2725 60.002 71.957 61.4619 71.957 63.6182C71.957 65.7744 70.2949 67.2568 68.0039 67.2568ZM68.0039 81.5645C65.3311 81.5645 63.5342 79.8574 63.5342 77.5215C63.5342 75.2305 65.3311 73.5684 68.0039 73.5684C70.6768 73.5684 72.4736 75.2305 72.4736 77.5215C72.4736 79.8574 70.6768 81.5645 68.0039 81.5645Z"
          fill="white" />
        <path
          d="M100.123 87C102.684 87 104.166 85.4277 104.166 82.6875V77.7012H108.905C111.533 77.7012 113.061 76.2637 113.061 73.793C113.061 71.3223 111.556 69.8848 108.95 69.8848H104.166V64.876C104.166 62.1357 102.684 60.5635 100.123 60.5635C97.585 60.5635 96.0801 62.1357 96.0801 64.876V69.8848H91.2734C88.668 69.8848 87.1855 71.3223 87.1855 73.793C87.1855 76.2637 88.668 77.7012 91.2734 77.7012H96.0801V82.665C96.0801 85.4277 97.5625 87 100.123 87Z"
          fill="white" />
      </svg>
    </div>
  )
}
