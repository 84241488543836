/**
 * Принимает индекс, и размер окна, а
 * возаращает начало и конец окна, в которых лежит индекс
 *
 * Например, если окно 10, то возвращает промежуток от 0 до 10, от 10 до 20, от 20 до 30 и т.д.
 *
 * Например, если окно 10, а focusedIdx = 12, то вернет от 10 до 20,
 * если focusedIdx = 45, то вернет от 40 до 50
 */
export const getWindowAboveFocus = (focusedIdx: number, windowSize: number) => {
  const start = windowSize * Math.floor(focusedIdx / windowSize);
  const end = start + windowSize;
  return { start, end };
}
