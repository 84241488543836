import * as React from 'react';

import { useApp } from '~components/Provider/App';
import { KeyBoardKey, KeyBoardKeyType, KeyBoardType } from '~typings/Keyboard';
import Language from '~typings/Language';
import NavigationDirection from '~typings/NavigationDirection';
import Key from '~ui/KeyBoard/key';
import keyboardDefault from '~ui/KeyBoard/keyboardLayouts/default';
import keyboardEmailPhone from '~ui/KeyBoard/keyboardLayouts/email-phone';
import keyboardNumber from '~ui/KeyBoard/keyboardLayouts/number';
import keyboardPassword from '~ui/KeyBoard/keyboardLayouts/password';
import keyboardPasswordUpperCase from '~ui/KeyBoard/keyboardLayouts/password-upper-case';
import keyboardPin from '~ui/KeyBoard/keyboardLayouts/pin';
import keyboardPromocode from '~ui/KeyBoard/keyboardLayouts/promocode';
import keyboardSearchENG from '~ui/KeyBoard/keyboardLayouts/search-eng';
import keyboardSearchRU from '~ui/KeyBoard/keyboardLayouts/search-ru';
import keyboardSpecialChars from '~ui/KeyBoard/keyboardLayouts/special-chars';
import { keyboardUnsernameEngLoCase, keyboardUnsernameEngUpCase } from '~ui/KeyBoard/keyboardLayouts/username-eng';
import { keyboardUnsernameRuLoCase, keyboardUnsernameRuUpCase } from '~ui/KeyBoard/keyboardLayouts/username-ru';
import KeyBoardNavigation, { KeyPosition } from '~ui/KeyBoard/navigation';

import keyboardPromocodeLowerCase from './keyboardLayouts/promocode-lower-case';
import * as styles from './styles.module.css';
import { invertKeyboardPair } from './utils';


type Props = Readonly<{
  type?: KeyBoardType;
  isFocused?: boolean;
  isHorizontalLoopAllowed?: boolean;
  isVerticalLoopAllowed?: boolean;
  hideValue?: boolean;
  onKeyClick: (keyBoardKey: KeyBoardKey) => void;
  onEyeClick?: (eyeMode: boolean) => void;
  onClose?: () => void;
  onLeaveKeyboard?: (direction: NavigationDirection) => void;
}>;


const readyKeyBoardLayout: { [key in KeyBoardType]?: KeyBoardKey[][] } = {
  [KeyBoardType.Default]: keyboardDefault,
  [KeyBoardType.Number]: keyboardNumber,
  [KeyBoardType.EmailPhone]: keyboardEmailPhone,
  [KeyBoardType.Password]: keyboardPassword,
  [KeyBoardType.PasswordUpperCase]: keyboardPasswordUpperCase,
  [KeyBoardType.SpecialChars]: keyboardSpecialChars,
  [KeyBoardType.Pin]: keyboardPin,
  [KeyBoardType.Promocode]: keyboardPromocode,
  [KeyBoardType.PromocodeLowerCase]: keyboardPromocodeLowerCase,
  [KeyBoardType.Search]: keyboardSearchRU,
  [KeyBoardType.SearchRU]: keyboardSearchRU,
  [KeyBoardType.SearchENG]: keyboardSearchENG,
  [KeyBoardType.Username]: keyboardUnsernameRuUpCase,
  [KeyBoardType.UsernameRuUp]: keyboardUnsernameRuUpCase,
  [KeyBoardType.UsernameRuLo]: keyboardUnsernameRuLoCase,
  [KeyBoardType.UsernameEngUp]: keyboardUnsernameEngUpCase,
  [KeyBoardType.UsernameEngLo]: keyboardUnsernameEngLoCase,
};

const keyboardPairsByCase: Array<[KeyBoardType, KeyBoardType]> = [
  [KeyBoardType.UsernameRuLo, KeyBoardType.UsernameRuUp],
  [KeyBoardType.UsernameEngLo, KeyBoardType.UsernameEngUp],
  [KeyBoardType.Password, KeyBoardType.PasswordUpperCase],
  [KeyBoardType.Promocode, KeyBoardType.PromocodeLowerCase]
];

const keyboardPairsByLang: Array<[KeyBoardType, KeyBoardType]> = [
  [KeyBoardType.UsernameRuLo, KeyBoardType.UsernameEngLo],
  [KeyBoardType.UsernameRuUp, KeyBoardType.UsernameEngUp],
  [KeyBoardType.SearchENG, KeyBoardType.SearchRU]
];

const engKeyboards = [
  KeyBoardType.SearchENG,
  KeyBoardType.UsernameEngLo,
  KeyBoardType.UsernameEngUp
];


/**
 * @deprecated
 * Есть FocuserKeyboard, которая основана на focuser-е
 * Лучше использовать ее
 */
const KeyBoard: React.FC<Props> = (props: Props) => {
  const { language } = useApp();
  const [focused, setFocused] = React.useState<KeyPosition | null>(null);
  const [keyboardType, setKeyboardType] = React.useState<KeyBoardType>(props.type || KeyBoardType.Default);
  
  // const [check, setCheck] = React.useState<boolean>(false); 

  const [forcedCoordinates, setForcedCoordinates] = React.useState<KeyPosition | null>(null);
  const keys = readyKeyBoardLayout[keyboardType] as KeyBoardKey[][];
  const isFocusedRef = React.useRef<boolean>(Boolean(props.isFocused));

  React.useEffect(() => {
    isFocusedRef.current = Boolean(props.isFocused);
  }, [props.isFocused]);
  
  const handleKeyPress = React.useCallback((key: KeyBoardKey): void => {
    if (!isFocusedRef.current) {
      return;
    }
    
    switch (key.type) {
      case KeyBoardKeyType.UpperCase: {
        if (keyboardType === KeyBoardType.PasswordUpperCase) {
          return setKeyboardType(KeyBoardType.Password);
        } else if(keyboardType === KeyBoardType.UsernameRuUp){
          return setKeyboardType(KeyBoardType.UsernameRuLo);
        } else if(keyboardType === KeyBoardType.UsernameEngUp) {
          return setKeyboardType(KeyBoardType.UsernameEngLo);
        }
        if(KeyBoardKeyType.SpecialChars) {
          setKeyboardType(KeyBoardType.Password);
        }
        setKeyboardType(KeyBoardType.Password);

        break;
      }
      case KeyBoardKeyType.LowerCase: {
        setKeyboardType(oldType => invertKeyboardPair(keyboardPairsByCase, oldType));

        break;
      }
      case KeyBoardKeyType.Close:
      case KeyBoardKeyType.Search: {
        if (props.onClose) {
          props.onClose();
        }

        break;
      }
      case KeyBoardKeyType.SpecialChars: {
        setKeyboardType(KeyBoardType.SpecialChars);

        break;
      }
      case KeyBoardKeyType.EmailPhoneChars: {
        setKeyboardType(KeyBoardType.EmailPhone);
        
        break;
      }
      case KeyBoardKeyType.SwitchLang: {
        setKeyboardType(oldType => invertKeyboardPair(keyboardPairsByLang, oldType));

        break;
      }
      case KeyBoardKeyType.ShowValueOff: {
        if (props.onEyeClick) {
          props.onEyeClick(!props.hideValue);
        }

        break;
      }
      case KeyBoardKeyType.ShowValueOn: {
        if (props.onEyeClick) {
          props.onEyeClick(!props.hideValue);
        }

        break;
      }
      default: {
        props.onKeyClick(key);
      }
    }
  }, [isFocusedRef.current, props.onClose, props.onEyeClick, props.onKeyClick, props.type, keyboardType]);

  const handleKeyHover = React.useCallback((coordinades: KeyPosition): void => {
    setForcedCoordinates(coordinades);
  }, []);

  const handleFocusChanged = React.useCallback((newFocusValue) => {
    setFocused(newFocusValue);
  }, []);

  React.useEffect(() => {
    if (props.type === KeyBoardType.Search) {
      setKeyboardType(
        language === Language.Ru
          ? KeyBoardType.SearchRU
          : KeyBoardType.SearchENG
      );
    }
    else if (props.type === KeyBoardType.Username) {
      setKeyboardType(
        language === Language.Ru
          ? KeyBoardType.UsernameRuUp
          : KeyBoardType.UsernameEngUp
      );
    }
    else {
      setKeyboardType(props.type || KeyBoardType.Default);
    }
  }, [props.type]);

  return (
    <div className={ styles.keyBoard }>
      {
        (props.isFocused)
          ? <KeyBoardNavigation
            keys={ keys }
            forced={ forcedCoordinates }
            isFocused={ props.isFocused }
            isHorizontalLoopAllowed={ props.isHorizontalLoopAllowed }
            isVerticalLoopAllowed={ props.isVerticalLoopAllowed }
            onLeaveKeyboard={ props.onLeaveKeyboard }
            onFocusChanged={ handleFocusChanged }
          />
          : null
      }
      {
        keys?.map((line, lineIndex) => (
          <div
            key={ `${ line.map((key) => (key.id || key.type)).join('') }` }
            className={ styles.keyBoardLine }
          >
            {
              line.map((key, index) => (
                <Key
                  key={ (key.id !== undefined && key.id !== null) ? key.id : key.type }
                  // eslint-disable-next-line max-len
                  isFocused={ (!!props.isFocused && (focused !== null) && lineIndex === focused.y && index === focused.x) }
                  lineIndex={ lineIndex }
                  index={ index }
                  keyBoardKey={
                    (key.type === KeyBoardKeyType.ShowValueOff || key.type === KeyBoardKeyType.ShowValueOn)
                      ? {
                        ...key,
                        type: (!!props.hideValue) ? KeyBoardKeyType.ShowValueOff : KeyBoardKeyType.ShowValueOn
                      }
                      : key.type === KeyBoardKeyType.SwitchLang
                        ? { ...key, id: engKeyboards.includes(keyboardType) ? 'РУС' : 'ENG' }
                        : key
                  }
                  onClick={ handleKeyPress }
                  onHover={ handleKeyHover }
                />
              ))
            }
          </div>
        ))
      }
    </div>
  );
};


export default React.memo(KeyBoard);
