import * as React from 'react';

import { useScene } from '~components/Provider/Scene';
import useMediaItem from '~hooks/fetch/useMediaItem';
import findMediaItem from '~lib/findMediaItem';
import ItemObject from '~typings/ItemObject';


type Props = Readonly<{
  id?: string;
  object?: ItemObject;
  withInfo: boolean;
}>;


const SceneManagerFetcher = (props: Props) => {
  const scene = useScene();
  const cachedItem = findMediaItem(props.id, props.object);
  const fetchID = (cachedItem === null) ? props.id : undefined;
  const fetchObject = (cachedItem === null) ? props.object : undefined;

  useMediaItem(fetchID, fetchObject);

  React.useEffect(() => {
    if (cachedItem) {
      scene.changeSceneMediaItem(cachedItem, props.withInfo);
    }
  }, [cachedItem?.id, props.withInfo]);

  return null;
};


export default React.memo(SceneManagerFetcher);
