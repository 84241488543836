import Image from '~typings/Image';
import ItemObject from '~typings/ItemObject';
import { Language } from '~typings/Language';
import Marker from "~typings/Marker";


type CardResourceType = Readonly<
  | ItemObject.News
  | ItemObject.Channel
  | ItemObject.Series
  | ItemObject.Movie
  | ItemObject.ProgramEvent
  | ItemObject.Episode
  | ItemObject.Part
  | ItemObject.AudioShow
  | ItemObject.Highlight
  | ItemObject.Match
  | ItemObject.SmallBanner
  | ItemObject.Person
>;

export enum TSimageShape {
  circle = 'circle',
  square = 'square',
  undefined = 'undefined'
}

type CertificationsTag = Readonly<string>;

type CardLabel = Readonly<{
  object: 'label';
  name: string;
  name_translations: Record<Language, string>;
  type: Marker;
  text_color: string | null;
  bg_color: string | null;
}>;

enum Modification {
  ANNOUNCEMENT_MOD = 'announcement_mod',
  CATCHUP = 'catchup',
  CURRENT = 'current',
  DEFAULT = 'default',
  FUTURE = 'future',
  PAST = 'past',
}

type Card = Readonly<{
  id: string;
  resource_id: string;
  resource_type: CardResourceType;
  name: string | null;
  description: string | null;
  images: Image[];
  labels: CardLabel[] | null;
  content_icon: Image | null;
  ratings: string[] | null;
  // Array of additional content tags (e.g. HD).
  content_tags: CertificationsTag[];
  // Array of certification tags (e.g. 18+). The tag from the project's default certification system is listed first.
  certification_tags: string[];
  overlay_header: string | null
  overlay_text: string | null;
  release_date: string;
  // Card's deeplink (used by clients other than web).
  deeplink: string | null;
  // Card's web URI (used by web clients).
  uri: string | null;
  // A boolean flag that shows whether card's content is inappropriate.
  inappropriate: boolean;
  // If the card's content is announced, this equals anouncement_mod, otherwise it equals default.
  modification: Modification | null;
  // A boolean flag that shows if the card has mutations.
  mutable: boolean;
  hover_text: string | null;
  hover_additional_text: string | null;
  additional_name: string | null;
  // An image object (e.g. a content provider icon).
  card_icon: Image | null;
  card_icon_dark_theme: Image | null;
  catalog_header: boolean;
  object: ItemObject.Card;
  image_shape: TSimageShape;

  /**
   * @deprecated
   * Этого типа нет в карточке, он добавляется после ответа, в CardCollectionItems
   * Это поле не использовать, если нужен card_config_id доставать отдельно а не из карточки
   *
   * @frontendOnly
   */
  card_config_id?: string;
}>;


export {
  CardLabel,
  CardResourceType,
  CertificationsTag,
  Modification,
}

export default Card;
