/* eslint no-console: 0 */
import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import getMinPrice from '~lib/product/getMinPrice';
import {
  ALREADY_AVAILABLE,
  BUY_ACTION,
  CHOICE_SUBSCRIBTION,
  FROM,
  PRODUCT_SELECT_PLAN_BUTTON,
  TRY_FOR_FREE,
} from '~localization';
import Product from '~typings/Product';
import Button from '~ui/button';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  isProductInSubscriptions: boolean;
  product: Product;
  intl: InjectedIntl;
}>;


const Placeholder: React.FC = () => {
  return <div className={ styles.placeholder } />;
};


const BestPriceButton: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const minPriceWithPeriod = React.useMemo(() => getMinPrice(props.product), [props.product]);
  const hasOnlyPlan = (props.product.plans.length === 1);
  const [text, setText] = React.useState<string | null>(null);
  
  
  React.useEffect(() => {
    if (minPriceWithPeriod === 'free') {
      setText(props.intl.formatMessage({ id: TRY_FOR_FREE }));
    } else if (minPriceWithPeriod !== null) {
      setText(`${!hasOnlyPlan ? `${props.intl.formatMessage({ id: FROM })} ` : ''}${
        props.intl.formatMessage({ id: PRODUCT_SELECT_PLAN_BUTTON }, {
            price: minPriceWithPeriod.money.formatted,
            durationValue: minPriceWithPeriod.period.value,
            durationUnit: minPriceWithPeriod.period.unit,
          }
        )
      }`);
    } else if (!hasOnlyPlan) {
      setText(props.intl.formatMessage({ id: CHOICE_SUBSCRIBTION }));
    } else {
      setText(props.intl.formatMessage({ id: BUY_ACTION }));
    }
  }, [minPriceWithPeriod]);

  const handleSubscribe = () => {
    if (props.isProductInSubscriptions) {
      history.goBack();
    } else {
      history.push(`/products/${props.product.id}/plan`)
    }
  };

  return (
    <Button
      isFocused={ props.isFocused }
      className={ cn(styles.button, {
        [styles.isFocused]: props.isFocused,
        [styles.disabled]: props.isProductInSubscriptions,
      }) }
      onClick={ handleSubscribe }
    >
      {
        (props.isProductInSubscriptions) ?
          props.intl.formatMessage({ id: ALREADY_AVAILABLE })
          :
          text
      }
    </Button>
  );
};


export { Placeholder };


export default React.memo(BestPriceButton);
