import { useQuery } from 'react-query';

import { useClient, useConfig } from '~global';
import ApiClient from '~lib/ApiClient';
import Card from '~typings/Card';
import ItemObject from '~typings/ItemObject';
import ResponseMany from '~typings/ResponseMany';

import { getAuthData } from '../useAccount';
import { getLimitFromConfig } from '../utils';
import { PREFIX_OF_A_COMPOSITE_KEY } from './useRecommendations';

const recsMediaItemKeys = {
  PREFIX: PREFIX_OF_A_COMPOSITE_KEY,

  mediaItem: (object: ItemObject.Movie | ItemObject.Series, id: string, limit: number) => [
    recsMediaItemKeys.PREFIX,
    'media-item-rec', // Нужно для разделения user рекомендаций от media-item
    object,
    id,
    limit
  ],
};

const fetchForMediaItem = async (
  client: ApiClient,
  recommenderEngineEnable: boolean,
  recommenderEngineEnableContentBased: boolean,
  object: ItemObject,
  id: string,
  genres: string,
  limit: number,
  accessToken: string | null
) => {
  const obj = object === ItemObject.Movie ? 'movies' : 'series';
  let url = `/v4/${obj}/${id}/recommendations`;
  const options = {
    'page[limit]': limit,
    access_token: accessToken,
  };

  if (
    !recommenderEngineEnable ||
    (recommenderEngineEnable && !recommenderEngineEnableContentBased)
  ) {
    url = `/v3/cards`;
    options['filter[resource_type_in]'] = obj;
    options['filter[genres_id_in]'] = genres;
  }

  return await client.get(url, options);
};

type Props = Readonly<{
  object: ItemObject.Movie | ItemObject.Series;
  id: string;
  genres: string;
  /**
   * Хук загрузит указанное кол-во элементов или меньше
   * Если переданный лимит меньше лимита из конфига, то будет использовано он
   * Иначе будет использован лимит из конфига
   */
  limit: number
}>;

function useRecommendationsForMediaItem(props: Props) {
  const {
    object,
    id,
    genres,
    limit: limitFromProps
  } = props;
  const client = useClient();
  const config = useConfig();
  const { smartTV: smartTVConfig } = config;
  const { accessToken } = getAuthData();
  const limitFromConfig = getLimitFromConfig(config);
  const limit = Math.min(limitFromConfig, limitFromProps);
  const queryKey = recsMediaItemKeys.mediaItem(object, id, limit);
  const { recommenderEngineEnable, recommenderEngineEnableContentBased } = smartTVConfig;

  return useQuery<ResponseMany<Card[]>>({
    queryKey: queryKey,
    queryFn: () =>
      fetchForMediaItem(
        client,
        recommenderEngineEnable,
        recommenderEngineEnableContentBased,
        object,
        id,
        genres,
        limit,
        accessToken
      ),
    cacheTime: 1000 * 60 * 5 // 5 минут кэша
  });
}

export {
  useRecommendationsForMediaItem,
};
