import focuser from '@focuser';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import { useActorPageSliderNavigation } from '~components/ActorPage/hooks';
import { Card } from '~components/Card';
import { VIEW_SUMMARY_PART } from '~lib/localizator/src/dictionaries';
import CardType from '~typings/Card';
import { getSliderPropsForCards, SliderOffsetableVirtual } from '~ui/SliderOffsetable';

import * as styles from './styles.modules.css';
import { cardConfigForAudioshowInActorPage } from './utils';



type Props = {
  isFocused: boolean;
  dataAudioShows: CardType[];
  onForceFocus: () => void;
}

const AudioShowSlider$:React.FC<Props> = ({
  isFocused,
  dataAudioShows,
  onForceFocus,
}:Props) => {
  const { 
    focusedIndex,
    onKeyLeftThrottled,
    onKeyRightThrottled,
    setFocusedIndex
  } = useActorPageSliderNavigation({
    elementsCountInSlider: dataAudioShows.length,
  })

  const renderAudioshows = (index: number) => {
    const cardItem = dataAudioShows[index];

    if(!cardItem) {
      return null;
    } 

    return (
        <Card 
          key={ cardItem.id }
          item={ cardItem }
          cardConfig={ cardConfigForAudioshowInActorPage }
          isFocused={ isFocused && focusedIndex === index }
          emitForceFocusOnHover
          onForceFocus={ () => setFocusedIndex(index) }
        />
      )
    
  }

  if(!dataAudioShows.length) {
    return null;
  }

  return (
    <div className={ styles.sliderWrapper }>
      <Typography variant={ 'h5_medium' } className={ styles.sliderTitle }>
        <FormattedMessage id={ VIEW_SUMMARY_PART } />
      </Typography>
      <focuser.FocusableBlock
        isFocused={ isFocused }
        onKeyLeft={ onKeyLeftThrottled }
        onKeyRight={ onKeyRightThrottled }
        onForceFocus={ onForceFocus }
      >
        <SliderOffsetableVirtual 
          focusedIndex={ focusedIndex }
          childsCount={ dataAudioShows.length }
          renderChild={ renderAudioshows }
          { ...getSliderPropsForCards(cardConfigForAudioshowInActorPage.layout) }
        />
      </focuser.FocusableBlock>
    </div>
  )
}

export const AudioShowSlider = AudioShowSlider$;