import focuser, { FocuserKeyHandler } from "@focuser";
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { CANCEL, PROFILES_REMOVE_PROFILE, SAVE } from '~lib/localizator/src/dictionaries';
import Profile from "~typings/Profile";
import ProfileFormData from '~typings/ProfileFormData';

import * as styles from '../../styles.module.css';

enum FocusOn {
  SaveButton = 'save_button',
  CancelButton = 'cancel_button',
  DeleteButton = 'delete_button',
}

type Props = Readonly<{
  profileData: ProfileFormData;
  isEditMode: boolean;
  originalProfile?: Profile;
  profileId?: string;
  onSave: () => void;
  onBack: () => void;
  initialFocus?:  'delete_profile_button';
  confirmDeletePopupOpen: () => void;
}>;


const ButtonsComponent: React.FC<Props> = ({
  profileData,
  isEditMode,
  originalProfile,
  onSave,
  onBack,
  initialFocus,
  confirmDeletePopupOpen,
}: Props) => {

  const isSaveBtnDisabled = !profileData.name || profileData.name.length < 2;

  const [focusedButton, setFocusedButton] = React.useState<FocusOn>(()=> {
      if(!isSaveBtnDisabled && initialFocus === 'delete_profile_button') {
        return FocusOn.DeleteButton
      }
      return FocusOn.CancelButton
      }
  );

  const isDeleteButtonActive = isEditMode && !originalProfile?.account_creator;

  const handleKeyRight: FocuserKeyHandler = (evt) => {
    if(focusedButton === FocusOn.SaveButton) {
      setFocusedButton(FocusOn.CancelButton);
      evt.stop();
    }
    if(isDeleteButtonActive) {
      if(focusedButton === FocusOn.CancelButton) {
        setFocusedButton(FocusOn.DeleteButton)
        evt.stop();
      }
    }
  }

  const handleKeyLeft: FocuserKeyHandler = (evt) => {
    if(isDeleteButtonActive) {
      if(focusedButton === FocusOn.DeleteButton) {
        setFocusedButton(FocusOn.CancelButton)
        evt.stop();
      }
    }
    if(focusedButton === FocusOn.CancelButton) {
      if(!isSaveBtnDisabled) {
        setFocusedButton(FocusOn.SaveButton)
        evt.stop();
      }
    }
  }

  const renderSecondButton = () => {
    if (isDeleteButtonActive) {
      return (
        <PrimaryButtonFocuser
          buttonClassName={ styles.deleteButton }
          onClick={ confirmDeletePopupOpen }
          isFocused={ focusedButton=== FocusOn.DeleteButton }
          onForceFocus={ () => setFocusedButton(FocusOn.DeleteButton) }
          emitForceFocusOnHover
        >
          <FormattedMessage id={ PROFILES_REMOVE_PROFILE } />
        </PrimaryButtonFocuser>
      );
    }
  }


  return (
    <focuser.FocusableBlock
      isFocused
      className={ styles.inputsWrap }
      onKeyLeft={ handleKeyLeft }
      onKeyRight={ handleKeyRight }
      noNeedForceFocus
    >
      <div className={ styles.buttons }>
        <PrimaryButtonFocuser
          buttonClassName={ isDeleteButtonActive ? styles.deleteButton : styles.button }
          onClick={ onSave }
          isFocused={ focusedButton === FocusOn.SaveButton }
          isDisabled={ isSaveBtnDisabled }
          onForceFocus={ () => setFocusedButton(FocusOn.SaveButton) }
          emitForceFocusOnHover
        >
          <FormattedMessage id={ SAVE } />
        </PrimaryButtonFocuser>

        <PrimaryButtonFocuser
          isFocused={ focusedButton === FocusOn.CancelButton }
          buttonClassName={ isDeleteButtonActive ? styles.deleteButton : styles.button }
          onClick={ onBack }
          onForceFocus={ () => setFocusedButton(FocusOn.CancelButton) }
          emitForceFocusOnHover
        >
          <FormattedMessage id={ CANCEL } />
        </PrimaryButtonFocuser>

        { renderSecondButton() }
      </div>
    </focuser.FocusableBlock>
  )
}

export default ButtonsComponent;
