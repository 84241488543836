// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button--s8xGy {\n  width: 22.03125vw;\n  height: 3.4375vw;\n  padding: 0.859375vw 1.5625vw;\n  -webkit-border-radius: 0.46875vw;\n          border-radius: 0.46875vw;\n  text-align: center;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow-x: hidden;\n  cursor: pointer;\n}\n\n\n.button--s8xGy .text--i7a5R {\n    color: rgba(223, 223, 228, 1);\n  }\n\n\n.focused--xQHbh {\n  background-color: rgba(253, 216, 53, 1);\n}\n\n\n.focused--xQHbh .text--i7a5R {\n    color: rgba(23, 23, 27, 1);\n  }\n\n.selected--JQqDw{\n  background-color: rgba(38, 38, 42, 1);\n}\n\n.selected--JQqDw .text--i7a5R {\n    color: rgba(255, 255, 255, 1);\n  }\n\n", "",{"version":3,"sources":["webpack://./src/app/ui-kit/LeftMenuButton/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,4BAA4B;EAC5B,gCAAwB;UAAxB,wBAAwB;EACxB,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;AAKjB;;;AAHE;IACE,6BAAa;EACf;;;AAIF;EACE,uCAAwB;AAK1B;;;AAHE;IACE,0BAAa;EACf;;AAGF;EACE,qCAAwB;AAK1B;;AAHE;IACE,6BAAa;EACf","sourcesContent":[".button {\n  width: 22.03125vw;\n  height: 3.4375vw;\n  padding: 0.859375vw 1.5625vw;\n  border-radius: 0.46875vw;\n  text-align: center;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow-x: hidden;\n  cursor: pointer;\n\n  .text {\n    color: $gw_08;\n  }\n}\n\n\n.focused {\n  background-color: $ac_00;\n\n  .text {\n    color: $gw_00;\n  }\n}\n\n.selected{\n  background-color: $gw_02;\n\n  .text {\n    color: $gw_11;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
export var button = "button--s8xGy";
export var text = "text--i7a5R";
export var focused = "focused--xQHbh";
export var selected = "selected--JQqDw";
export default ___CSS_LOADER_EXPORT___;
