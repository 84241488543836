enum CollectionType {
  Channels = 'channels_collection',
  ChannelsLogo = 'channels_logo_collection',
  Movies = 'movies_collection',
  MoviesPoster = 'movies_poster_collection',
  RadioStations = 'radio_stations_collection',
  AudioShows = 'audio_shows_collection',
  Series = 'series_collection',
  ProgramEvents = 'program_events_collection',
  ProgramProgramEvents = 'program_program_events_collection',
  News = 'news_collection',
}


export default CollectionType;
