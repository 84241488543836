import * as React from 'react';


type Props = {
  className?: string;
};

/* eslint-disable max-len */
const CloseIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg className={ className } viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8787 24.139L13.6464 15.9068C13.2559 15.5162 13.2559 14.8831 13.6464 14.4926L14.3535 13.7854C14.7441 13.3949 15.3772 13.3949 15.7678 13.7854L24 22.0177L32.2322 13.7855C32.6227 13.3949 33.2559 13.3949 33.6464 13.7854L34.3535 14.4926C34.7441 14.8831 34.7441 15.5162 34.3535 15.9068L26.1213 24.139L34.3535 32.3712C34.7441 32.7618 34.7441 33.3949 34.3535 33.7854L33.6464 34.4926C33.2559 34.8831 32.6227 34.8831 32.2322 34.4925L24 26.2603L15.7678 34.4926C15.3772 34.8831 14.7441 34.8831 14.3535 34.4926L13.6464 33.7854C13.2559 33.3949 13.2559 32.7618 13.6464 32.3712L21.8787 24.139Z"
        fill="currentColor"
      />
    </svg>
  );
};


export default CloseIcon;
