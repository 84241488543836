import { usePlatform } from '~components/Provider/Platform';
import { DRMType } from '~typings/Drm';
import ItemObject from "~typings/ItemObject";
import PlatformName from "~typings/PlatformName";
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Protocol from '~typings/Protocol';

import { getAllowedDRMs } from "../mediaItem";

type Props = Readonly<{
  item: PlayerMediaItem | null;
  accessAllowed: boolean;
}>;

type Result = Readonly<{
  drm: DRMType | null;
  protocol: Protocol | null;
  // takeNextProtocol: () => void;
  // isReadyToFetchStream: boolean;
}>;


const useStreamParams = (props: Props): Result => {
  const { platform } = usePlatform();
  const device = platform.getDeviceInfo()

  if (!props.item) {
    return {
      drm: null,
      protocol: null,
    }
  }

  const isLive = props.item.object === ItemObject.Channel
  const drmArr = getAllowedDRMs(props.item)
  const drm = !drmArr.includes(DRMType.SPbTVCas)

  if (__PLATFORM__ === PlatformName.WebOS) {
    // тут нет зависимости от версий
    return {
      drm: drm ? DRMType.PlayReady : DRMType.SPbTVCas,
      protocol: Protocol.DASH,
    }
  }
  if (__PLATFORM__ === PlatformName.Tizen) {
    const osVersionNumber = parseInt((device.osVersion || '').toLowerCase().replace('tizen ', '').split('.')[0], 10);

    if (device.osVersion && osVersionNumber < 4) {
      //тут тайзен 2.4 и тайзен 3
      if (isLive) {
        //лайв
        return {
          drm: drm ? DRMType.PlayReady : DRMType.SPbTVCas,
          protocol: Protocol.MSS
        }
      }
      else {
        //воды на старом играет avplay
        return {
          drm: drm ? DRMType.PlayReady : DRMType.SPbTVCas,
          protocol: Protocol.DASH
        }
      }
    }
    else {
      // тут и лайв и вод работают на dash + playready + new shaka
      return {
        drm: drm ? DRMType.PlayReady : DRMType.SPbTVCas,
        protocol: Protocol.DASH,
      }
    }

  }
  if (__PLATFORM__ === PlatformName.Desktop) {
    // тут только WV+DASH при любых кейсах

    return {
      drm: drm ? DRMType.WideVine : DRMType.SPbTVCas,
      protocol: Protocol.DASH,
    }
  }

  return ({
    drm: null,
    protocol: null,
  });
};


export { useStreamParams };
