import { useMutation, useQuery } from 'react-query';

import { ActionType, useAppAction } from '~components/Provider/App';
import { queryClient, useClient } from '~global';
import AccountInfo from '~typings/AccountInfo';

import { ACCOUNT_KEY } from './auth.utils';
import { getAuthData } from './authData';

const accountKey = [ACCOUNT_KEY, 'account_info'];

export const fetchAccountInfo = async (client) => {
  const { accessToken } = getAuthData();
  if (!accessToken) return null;

  const { data } = await client.get('/v1/account', {
    access_token: accessToken,
  });
  return data;
};

const useAccount = () => {
  const client = useClient();
  const applicationAction = useAppAction();
  const { accessToken } = getAuthData();

  return useQuery<AccountInfo>(
    accountKey,
    () => fetchAccountInfo(client),
    {
      enabled: !!accessToken,
      onSuccess: () => {
        applicationAction({
          type: ActionType.SetIsAuthorized,
          payload: { isAuthorized: true },
        });
      },
      onError: (_error) => {
        // console.log(_error); // eslint-disable-line
        applicationAction({
          type: ActionType.SetIsAuthorized,
          payload: { isAuthorized: false },
        });
      },
      cacheTime: Infinity,
    }
  );
};

enum AccountMutationType {
  Patch = 'patch',
}

const changeAccount = async (client, accessToken: string | null, account?: Partial<AccountInfo>) => {
  if (!accessToken || !account) { return; }

  client.defaultQuery['access_token'] = accessToken;
  const data = await client.patch('/v1/account', account);
  delete client.defaultQuery['access_token'];

  return data
};

const useAccountMutation = () => {
  const client = useClient();
  const { accessToken } = getAuthData();

  return useMutation(
    (account: Partial<AccountInfo>) => changeAccount(client, accessToken, account), {
      onSuccess: (data: { data: AccountInfo }) => {
        queryClient.setQueryData(
          accountKey,
          data.data,
        );
      },
    },
  );
};

export const useNotificationsMutation = () => {
  const client = useClient();
  const { accessToken } = getAuthData();

  return useMutation(
    (isEnabled: boolean) => changeAccount(client, accessToken, { allow_notifications: isEnabled}), {
      onMutate: async (isEnabled) => {
        const currentAccountInfo = queryClient.getQueryData<AccountInfo>(accountKey);

        if (currentAccountInfo) {
          queryClient.setQueryData(accountKey, () => ({
            ...currentAccountInfo,
            allow_notifications: isEnabled,
          }));
        }
        // Возвращаем информацию об аккаунте, чтобы можно было отменить мутацию
        return { currentAccountInfo };
      },
      onError: (
        _err,
        _data,
        context
      ) => {
        // Если произошла ошибка, возвращаем как было
        queryClient.setQueryData(accountKey, context?.currentAccountInfo);
      },
      onSuccess: (data: { data: AccountInfo }) => {
        queryClient.setQueryData(
          accountKey,
          data.data,
        );
      },
    },
  );
};

export {
  AccountMutationType,
  useAccount,
  useAccountMutation,
};
