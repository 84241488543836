// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eventLine--Vmpzv {\n  font-size: 1.5625vw;\n  font-weight: 400;\n  color: rgba(243, 243, 247, 1);\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  margin-bottom: 3.515625vw;\n}\n\n.eventName--QrHMw {\n  margin-bottom: 0.78125vw;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemDetails/EventProgramList/styles.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAuB;EACvB,gBAAkB;EAClB,6BAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;EACxB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;AACrB","sourcesContent":[".eventLine {\n  font-size: $fontSub1_vw;\n  font-weight: $fw_4;\n  color: $gw_10;\n  align-items: center;\n  margin-bottom: 3.515625vw;\n}\n\n.eventName {\n  margin-bottom: 0.78125vw;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var eventLine = "eventLine--Vmpzv";
export var eventName = "eventName--QrHMw";
export default ___CSS_LOADER_EXPORT___;
