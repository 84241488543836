import { FocuserClickHandler } from "@focuser";
import React from 'react';
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { PrimaryButtonFocuser } from "~app/ui-kit/PrimaryButton";
import { Typography } from "~app/ui-kit/Typography";
import { LOGIN_OR_REGISTER, REGISTER_USERS_CAN_ACCESS } from "~lib/localizator/src/dictionaries";

import * as styles from './styles.modules.css';


export const SignIn: React.FC = () => {
  const history = useHistory();

  const handleClickSignInButton: FocuserClickHandler = (evt) => {
    evt.stop();
    evt.stopNativeEvent();
    history.push('/auth');
  }

  return (
    <div className={ styles.tabWrap }>
      <div className={ styles.contentWrap }>
        <Typography
          variant={ 'subtitle1_medium' }
          className={ styles.text }
        >
          <FormattedMessage id={ REGISTER_USERS_CAN_ACCESS }/>
        </Typography>
        <PrimaryButtonFocuser
          isFocused
          onClick={ handleClickSignInButton }
          emitForceFocusOnHover
          noNeedForceFocus
        >
          <FormattedMessage id={ LOGIN_OR_REGISTER }/>
        </PrimaryButtonFocuser>
      </div>
    </div>
  )
}
