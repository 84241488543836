import * as cn from 'classnames';
import * as React from 'react';

import { getContinueWatchingByResource } from '~hooks/fetch/useContinueWatching/useContinueWatchingV3';
import ItemObject from '~typings/ItemObject';
import { ProgressBar } from "~ui/ProgressBar";

import * as styles from './styles.module.css';


type Props = {
  id: string;
  className?: string;
  object: ItemObject.Movie | ItemObject.Episode | ItemObject.Part | ItemObject.Channel;
};

const Progress = ({ id, object, className }: Props) => {
  const continueWatching = React.useMemo(()=>getContinueWatchingByResource(id, object), [id, object]);
  return (
    <div className={ styles.progressWrapper }>
      <ProgressBar
        progress={ continueWatching?.percents_watched ?? 0 }
        classNames={ {
          back: cn(styles.progress, className),
        } }
      />
    </div>
  );
};


export default Progress;
