import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import { DURATION_IN_UNIT, FIRST,FREE, PRODUCT_SELECT_PLAN_BUTTON } from '~localization';
import Plan from '~typings/Plan';
import { RadioButton } from '~ui/RadioButton';

import * as styles from './styles.module.css';


type Props = {
  plans: Plan[];
  isFocused: boolean;
  focusIndex: number;
  selectedPlan: Plan | null;
  onClick: (id: Plan) => void;
  intl: InjectedIntl;
};

const PlansRadioButtons: React.FC<Props> = (props) => {
  const {
    isFocused,
    plans,
    focusIndex,
    selectedPlan,
    onClick,
    intl,
  } = props;

  return (
    <div className={ styles.buttonWrapper }>
      {
        plans.map((plan, idx) => {
          const evergreenPhase = plan.phases.find(p => p.type === 'evergreen');

          return (
            <div
              key={ plan.id }
              className={ cn(styles.radioButton, {
                focusedNavigationNode: isFocused && focusIndex === idx,
              }) }
              onClick={ (_e) => onClick(plan)  }
            >
              <RadioButton
                isFocused={ isFocused && focusIndex === idx }
                isChecked={ selectedPlan?.id === plan.id }
              >
                <span className={ styles.radioButtonTitle }>
                  {
                    intl.formatMessage({ id: PRODUCT_SELECT_PLAN_BUTTON }, {
                      price: evergreenPhase?.billing.price.formatted,
                      durationValue: evergreenPhase?.access_period.value,
                      durationUnit: evergreenPhase?.access_period.unit,
                    })
                  }
                </span>
              </RadioButton>
              <div className={ styles.radioButtonSubtitle }>
                {
                  (plan.eligible_phase?.type === 'trial') ? (
                    <span style={ { textTransform: 'lowercase' } }>
                      { intl.formatMessage({ id: FIRST }) }
                      { ' ' }
                      {
                        intl.formatMessage({ id: DURATION_IN_UNIT }, {
                          durationValue: plan.eligible_phase?.access_period.value,
                          durationUnit: plan.eligible_phase?.access_period.unit,
                        })
                      }
                      { ' ' }
                      { intl.formatMessage({ id: FREE }) }
                    </span>
                  ) : null
                }
              </div>
            </div>
          )
        })
      }
    </div>
  );
};


export default React.memo(PlansRadioButtons);
