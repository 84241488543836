export const INFO_NOT_EXISTS = 'INFO_NOT_EXISTS';
export const MIXED_AUTH_RESEND_SMS = 'MIXED_AUTH_RESEND_SMS';
export const MIXED_AUTH_RESEND_SMS_TIME = 'MIXED_AUTH_RESEND_SMS_TIME';
export const YESTERDAY = 'YESTERDAY';
export const TOMORROW = 'TOMORROW';
export const RELEASE_CALENDAR = 'RELEASE_CALENDAR';
export const MIXED_AUTH_TERMS_ACCEPT_BUTTON = 'MIXED_AUTH_TERMS_ACCEPT_BUTTON';
export const MIXED_AUTH_INCORRECT_NUMBER = 'MIXED_AUTH_INCORRECT_NUMBER';
export const MIXED_AUTH_PHONE_LOGIN_PAGE_SUBHEADER = 'MIXED_AUTH_PHONE_LOGIN_PAGE_SUBHEADER';
export const NAV_LEGEND_SEARCH = 'NAV_LEGEND_SEARCH';
export const MIXED_AUTH_ERROR_4_ADD = 'MIXED_AUTH_ERROR_4_ADD';
export const MIXED_AUTH_ERROR_4 = 'MIXED_AUTH_ERROR_4';
export const MIXED_AUTH_ERROR_12 = 'MIXED_AUTH_ERROR_12';
export const MIXED_AUTH_ERROR_11 = 'MIXED_AUTH_ERROR_11';
export const MIXED_AUTH_ERROR_10 = 'MIXED_AUTH_ERROR_10';
export const MIXED_AUTH_ERROR_9 = 'MIXED_AUTH_ERROR_9';
export const MIXED_AUTH_ERROR_8 = 'MIXED_AUTH_ERROR_8';
export const MIXED_AUTH_ERROR_7 = 'MIXED_AUTH_ERROR_7';
export const MIXED_AUTH_ERROR_5 = 'MIXED_AUTH_ERROR_5';
export const MIXED_AUTH_ERROR_3 = 'MIXED_AUTH_ERROR_3';
export const MIXED_AUTH_ERROR_2 = 'MIXED_AUTH_ERROR_2';
export const MIXED_AUTH_ERROR_1 = 'MIXED_AUTH_ERROR_1';
export const MIXED_AUTH_ERROR_0 = 'MIXED_AUTH_ERROR_0';
export const MIXED_AUTH_MAIN_BUTTON = 'MIXED_AUTH_MAIN_BUTTON';
export const MIXED_AUTH_MAIN_SCAN = 'MIXED_AUTH_MAIN_SCAN';
export const MIXED_AUTH_MAIN_ENTER_CODE = 'MIXED_AUTH_MAIN_ENTER_CODE';
export const MIXED_AUTH_MAIN_TO_APP_LINK = 'MIXED_AUTH_MAIN_TO_APP_LINK';
export const MIXED_AUTH_MAIN_TO_APP = 'MIXED_AUTH_MAIN_TO_APP';
export const MIXED_AUTH_MAIN_TO_SITE_LINK = 'MIXED_AUTH_MAIN_TO_SITE_LINK';
export const MIXED_AUTH_MAIN_TO_SITE = 'MIXED_AUTH_MAIN_TO_SITE';
export const MIXED_AUTH_MAIN_FROM_CHANNEL = 'MIXED_AUTH_MAIN_FROM_CHANNEL';
export const MIXED_AUTH_MAIN_FROM_PAGE = 'MIXED_AUTH_MAIN_FROM_PAGE';
export const MIXED_AUTH_MAIN_FROM_18 = 'MIXED_AUTH_MAIN_FROM_18';
export const WELLCOME_AUTH_TEXT = 'WELLCOME_AUTH_TEXT';
export const rkn_plate = 'rkn_plate';
export const CHANNEL_FLIPPER_NO_SCHEDULE = 'CHANNEL_FLIPPER_NO_SCHEDULE';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';
export const PURCHASED = 'PURCHASED';
export const THE_WHOLE_SERIES = 'THE_WHOLE_SERIES';
export const SECURITY_TAB_PIN_LABEL = 'SECURITY_TAB_PIN_LABEL';
export const CHANGE_PIN = 'CHANGE_PIN';
export const SECURITY_TAB_PARENT_LABEL = 'SECURITY_TAB_PARENT_LABEL';
export const NEW_PIN_CODE_FLOW_ASK_BUTTON_SETUP = 'NEW_PIN_CODE_FLOW_ASK_BUTTON_SETUP';
export const NEW_PIN_CODE_FLOW_ASK_BUTTON_SKIP = 'NEW_PIN_CODE_FLOW_ASK_BUTTON_SKIP';
export const NEW_PIN_CODE_FLOW_ASK_BUTTON_ON = 'NEW_PIN_CODE_FLOW_ASK_BUTTON_ON';
export const NEW_PIN_CODE_FLOW_ASK_MESSAGE = 'NEW_PIN_CODE_FLOW_ASK_MESSAGE';
export const NEW_PIN_CODE_FLOW_ASK_PARENTAL_CONTROL_HEADER = 'NEW_PIN_CODE_FLOW_ASK_PARENTAL_CONTROL_HEADER';
export const NEW_PIN_CODE_FLOW_ASK_PIN_HEADER = 'NEW_PIN_CODE_FLOW_ASK_PIN_HEADER';
export const NEW_PIN_CODE_FLOW_SUCCESS_BUTTON = 'NEW_PIN_CODE_FLOW_SUCCESS_BUTTON';
export const NEW_PIN_CODE_FLOW_SUCCESS_MESSAGE = 'NEW_PIN_CODE_FLOW_SUCCESS_MESSAGE';
export const NEW_PIN_CODE_FLOW_PARENTAL_CONTROL_SUCCESS_HEADER = 'NEW_PIN_CODE_FLOW_PARENTAL_CONTROL_SUCCESS_HEADER';
export const NEW_PIN_CODE_FLOW_PIN_CODE_SUCCESS_HEADER = 'NEW_PIN_CODE_FLOW_PIN_CODE_SUCCESS_HEADER';
export const SIGN_IN_PROBLEMS_TEXT = 'SIGN_IN_PROBLEMS_TEXT';
export const APPLICATION_LANGUAGE = 'APPLICATION_LANGUAGE';
export const CONFIRM_LOGOUT_TEXT = 'CONFIRM_LOGOUT_TEXT';
export const REGISTER_USERS_CAN_ACCESS = 'REGISTER_USERS_CAN_ACCESS';
export const LOGOUT_FROM_ACCOUNT = 'LOGOUT_FROM_ACCOUNT';
export const LOGIN_OR_REGISTER_TEXT = 'LOGIN_OR_REGISTER_TEXT';
export const ABOUT_PROGRAM = 'ABOUT_PROGRAM';
export const ACCOUNT_CONTROL = 'ACCOUNT_CONTROL';
export const ALL_PARTS = 'ALL_PARTS';
export const DELETE_PROFILE_CONFIRM = 'DELETE_PROFILE_CONFIRM';
export const DELETE = 'DELETE';
export const PROFILES_REMOVE_PROFILE = 'PROFILES_REMOVE_PROFILE';
export const PROFILE_NAME = 'PROFILE_NAME';
export const PROFILES_ALLOWED_CONTENT_FIELD_NAME = 'PROFILES_ALLOWED_CONTENT_FIELD_NAME';
export const ENTER_PROFILE_NAME = 'ENTER_PROFILE_NAME';
export const PROFILES_ALLOWED_CONTENT_LITTLE_KIDS = 'PROFILES_ALLOWED_CONTENT_LITTLE_KIDS';
export const PROFILES_ALLOWED_CONTENT_OLDER_KIDS = 'PROFILES_ALLOWED_CONTENT_OLDER_KIDS';
export const PROFILES_ALLOWED_CONTENT_TEENS = 'PROFILES_ALLOWED_CONTENT_TEENS';
export const PROFILES_ALLOWED_CONTENT_ADULT = 'PROFILES_ALLOWED_CONTENT_ADULT';
export const ACCOUNT_SECURITY_CREATE_PIN_TEXT = 'ACCOUNT_SECURITY_CREATE_PIN_TEXT';
export const PIN_CODE_CREATE = 'PIN_CODE_CREATE';
export const PIN_CODE_CANCEL = 'PIN_CODE_CANCEL';
export const ALL_EPISODES = 'ALL_EPISODES';
export const ALL_SEASONS = 'ALL_SEASONS';
export const PIN_INVALID = 'PIN_INVALID';
export const CONFIRM_PIN_CODE_POPUP_TITLE = 'CONFIRM_PIN_CODE_POPUP_TITLE';
export const CREATE_PIN_CODE_POPUP_TITLE = 'CREATE_PIN_CODE_POPUP_TITLE';
export const PIN_CODE = 'PIN_CODE';
export const ACCOUNT_LABEL_SECURITY_PIN = 'ACCOUNT_LABEL_SECURITY_PIN';
export const ENTER_PIN_CODE_POPUP_TEXT = 'ENTER_PIN_CODE_POPUP_TEXT';
export const PIN_NOT_EQUAL = 'PIN_NOT_EQUAL';
export const THE_LIMIT_OF_PROFILES = 'THE_LIMIT_OF_PROFILES';
export const ADD_A_NEW_PROFILE = 'ADD_A_NEW_PROFILE';
export const ABOUT_PROGRAM_EVENT = 'ABOUT_PROGRAM_EVENT';
export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';
export const CLOSE_THE_APP = 'CLOSE_THE_APP';
export const USER_AGREEMENT = 'USER_AGREEMENT';
export const YOU_CANNOT_ADD_THIS_DEVICE = 'YOU_CANNOT_ADD_THIS_DEVICE';
export const YOU_CAN_CHANGE_THE_DEVICE_NAME = 'YOU_CAN_CHANGE_THE_DEVICE_NAME';
export const YOU_CAN_CHANGE_THE_DEVICE_NAME_CURRENT = 'YOU_CAN_CHANGE_THE_DEVICE_NAME_CURRENT';
export const THIS_IS_THE_CURRENT_DEVICE = 'THIS_IS_THE_CURRENT_DEVICE';
export const RENT_REMAINING_TIME_WARNING = 'RENT_REMAINING_TIME_WARNING';
export const PLAYBACK = 'PLAYBACK';
export const DO_YOU_WANT_TO_START_WATCHING = 'DO_YOU_WANT_TO_START_WATCHING';
export const INAPPROPRIATE_CONTENT_AGE_CONFIRMATION = 'INAPPROPRIATE_CONTENT_AGE_CONFIRMATION';
export const I_HAVE_A_PROMOCODE = 'I_HAVE_A_PROMOCODE';
export const HERE_YOU_CAN_ACTIVATE_A_PROMOCODE = 'HERE_YOU_CAN_ACTIVATE_A_PROMOCODE';
export const PROMO_CODE_PAGE_HEADER = 'PROMO_CODE_PAGE_HEADER';
export const PROMO_VOD_DISCLAMER = 'PROMO_VOD_DISCLAMER';
export const ABOUT_THE_SERIES = 'ABOUT_THE_SERIES';
export const ABOUT_THE_FILM = 'ABOUT_THE_FILM';
export const THE_TERMS_OF_THE_USER_AGREEMENT = 'THE_TERMS_OF_THE_USER_AGREEMENT';
export const BY_CLICKING_THE_PAYMENT_BUTTON = 'BY_CLICKING_THE_PAYMENT_BUTTON';
export const I_SEE = 'I_SEE';
export const YOUR_PROMO_CODE_IS_ACTIVATED = 'YOUR_PROMO_CODE_IS_ACTIVATED';
export const CONGRATULATION = 'CONGRATULATION';
export const DO_YOU_REALLY_WANT_TO_DELETE = 'DO_YOU_REALLY_WANT_TO_DELETE';
export const REGISTRATION_AT_THE_SPECIFIED_EMAIL_ADDRESS = 'REGISTRATION_AT_THE_SPECIFIED_EMAIL_ADDRESS';
export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export const CONFIRM_AGREE_TO_THE_TERMS = 'CONFIRM_AGREE_TO_THE_TERMS';
export const qwerty = 'qwerty';
export const PAYMENT_SERVICE_FEE_INFORMATION = 'PAYMENT_SERVICE_FEE_INFORMATION';
export const PROMO_CODE_ERROR = 'PROMO_CODE_ERROR';
export const TESTING_PHRAZE = 'TESTING_PHRAZE';
export const MENU_ABOUT = 'MENU_ABOUT';
export const LANGUAGES = 'LANGUAGES';
export const SPBTV = 'SPBTV';
export const MENU_SETTINGS = 'MENU_SETTINGS';
export const MENU_EPG = 'MENU_EPG';
export const FILTER_BUTTON_RESET = 'FILTER_BUTTON_RESET';
export const MENU_SERIES = 'MENU_SERIES';
export const MENU_MY_SPBTV = 'MENU_MY_SPBTV';
export const FILTER_BUTTON_SUBMIT = 'FILTER_BUTTON_SUBMIT';
export const MENU_ON_AIR = 'MENU_ON_AIR';
export const MENU_FOR_KIDS = 'MENU_FOR_KIDS';
export const MENU_INFO = 'MENU_INFO';
export const MENU_MOVIES = 'MENU_MOVIES';
export const MENU_CHANNELS = 'MENU_CHANNELS';
export const NAV_LEGEND_CLOSE_FILTER = 'NAV_LEGEND_CLOSE_FILTER';
export const NAV_LEGEND_SHOW_FILTER = 'NAV_LEGEND_SHOW_FILTER';
export const USER_INFO = 'USER_INFO';
export const NAV_LEGEND_HIDE_MENU = 'NAV_LEGEND_HIDE_MENU';
export const NAV_LEGEND_SHOW_MENU = 'NAV_LEGEND_SHOW_MENU';
export const GENRES = 'GENRES';
export const EPG_IS_NOT_AVAILABLE = 'EPG_IS_NOT_AVAILABLE';
export const EPG_LOADING = 'EPG_LOADING';
export const SEARCH_ALL_RESULTS = 'SEARCH_ALL_RESULTS';
export const TYPE_TO_SEARCH = 'TYPE_TO_SEARCH';
export const LOADING = 'LOADING';
export const CHANNEL_INFO = 'CHANNEL_INFO';
export const TV_GUIDE = 'TV_GUIDE';
export const SIGN_IN = 'SIGN_IN';
export const NAVIGATION_BACK = 'NAVIGATION_BACK';
export const PRIVACY_POLICY = 'PRIVACY_POLICY';
export const TERMS_OF_USE = 'TERMS_OF_USE';
export const VIDEO_PLAY = 'VIDEO_PLAY';
export const SIGN_IN_TEXT = 'SIGN_IN_TEXT';
export const PRODUCT_VERSION = 'PRODUCT_VERSION';
export const LOGOUT = 'LOGOUT';
export const NAV_LEGEND_ON_AIR = 'NAV_LEGEND_ON_AIR';
export const MENU_PROFILE = 'MENU_PROFILE';
export const DEVICE_ID = 'DEVICE_ID';
export const NO_EPG = 'NO_EPG';
export const PHONE = 'PHONE';
export const PLAYER_ERROR_NOT_AUTH = 'PLAYER_ERROR_NOT_AUTH';
export const PAGE_DOWN = 'PAGE_DOWN';
export const PLAYER_ERROR_UNAVAILABLE_COUNTRY = 'PLAYER_ERROR_UNAVAILABLE_COUNTRY';
export const PLAYER_ERROR_NOT_PURCHASED = 'PLAYER_ERROR_NOT_PURCHASED';
export const PAGE_UP = 'PAGE_UP';
export const PLAYER_ERROR_MAX_CONCURRENT_VIEWS = 'PLAYER_ERROR_MAX_CONCURRENT_VIEWS';
export const CLOSE = 'CLOSE';
export const PLAYER_ERROR_STREAM_NOT_FOUND = 'PLAYER_ERROR_STREAM_NOT_FOUND';
export const EXIT = 'EXIT';
export const GEORESTRICTION_SUBTEXT = 'GEORESTRICTION_SUBTEXT';
export const NAV_LEGEND_PLAY_CHANNEL = 'NAV_LEGEND_PLAY_CHANNEL';
export const GEORESTRICTION_TEXT = 'GEORESTRICTION_TEXT';
export const ADS_SKIP = 'ADS_SKIP';
export const SEARCH_NOT_FOUND = 'SEARCH_NOT_FOUND';
export const VIDEO_PLAY_CONTINUE = 'VIDEO_PLAY_CONTINUE';
export const NEW_SEARCH = 'NEW_SEARCH';
export const YEAR_BIRTH = 'YEAR_BIRTH';
export const BUTTON_RETRY = 'BUTTON_RETRY';
export const CONFIRM_EXIT_TITLE = 'CONFIRM_EXIT_TITLE';
export const CONFIRM_EXIT_TEXT = 'CONFIRM_EXIT_TEXT';
export const NO = 'NO';
export const YES = 'YES';
export const ADS = 'ADS';
export const PLAYER_ERROR_PLAYBACK = 'PLAYER_ERROR_PLAYBACK';
export const FULL_DESCRIPTION = 'FULL_DESCRIPTION';
export const HIDE_DESCRIPTION = 'HIDE_DESCRIPTION';
export const TRAILER = 'TRAILER';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SAVE = 'SAVE';
export const YOU_GENDER = 'YOU_GENDER';
export const YEAR_LABEL_FAILED = 'YEAR_LABEL_FAILED';
export const PLAYER_ERROR_COMMON_STREAM_REQUEST_FAILED = 'PLAYER_ERROR_COMMON_STREAM_REQUEST_FAILED';
export const USER_DEFAULT_NAME = 'USER_DEFAULT_NAME';
export const INTERNET_CONECTION_ERROR = 'INTERNET_CONECTION_ERROR';
export const INAPPROPRIATE_CONTENT_AGE_RESTRICTION = 'INAPPROPRIATE_CONTENT_AGE_RESTRICTION';
export const RELOAD = 'RELOAD';
export const CRASH_CLIENT_IS_OBSOLETE = 'CRASH_CLIENT_IS_OBSOLETE';
export const CRASH_SOMETHING_GOES_WRONG = 'CRASH_SOMETHING_GOES_WRONG';
export const LIVE = 'LIVE';
export const START_SPEEDTEST = 'START_SPEEDTEST';
export const RELOAD_SPEEDTEST = 'RELOAD_SPEEDTEST';
export const STOP_SPEEDTEST = 'STOP_SPEEDTEST';
export const LOCATION = 'LOCATION';
export const COUNTRY = 'COUNTRY';
export const SPEEDTEST_READY = 'SPEEDTEST_READY';
export const SPEEDTEST_PROCESS = 'SPEEDTEST_PROCESS';
export const SERVER_TIME = 'SERVER_TIME';
export const SPEEDTEST_INITIALIZE = 'SPEEDTEST_INITIALIZE';
export const YOUR_TIME = 'YOUR_TIME';
export const SPEEDTEST_END = 'SPEEDTEST_END';
export const TEST_ID = 'TEST_ID';
export const MENU_MY_DEVICES = 'MENU_MY_DEVICES';
export const ENABLE = 'ENABLE';
export const DISABLE = 'DISABLE';
export const MENU_PERSONAL_INFO = 'MENU_PERSONAL_INFO';
export const MENU_PURCHASE_HISTORY = 'MENU_PURCHASE_HISTORY';
export const MENU_SUBSCRIPTIONS = 'MENU_SUBSCRIPTIONS';
export const MENU_MY_CARD = 'MENU_MY_CARD';
export const SHOW_MORE = 'SHOW_MORE';
export const FREE = 'FREE';
export const DAY = 'DAY';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';
export const SUBSCRIPTIONS_EMPTY = 'SUBSCRIPTIONS_EMPTY';
export const PAYMENT_HISTORY_EMPTY = 'PAYMENT_HISTORY_EMPTY';
export const CARD_NOT_FOUND = 'CARD_NOT_FOUND';
export const DEVICES_NOT_FOUND = 'DEVICES_NOT_FOUND';
export const UNBIND_CARD = 'UNBIND_CARD';
export const PAY_NOW = 'PAY_NOW';
export const SELECT_PLAN = 'SELECT_PLAN';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SAVE_LANGUAGE_NOTCE = 'SAVE_LANGUAGE_NOTCE';
export const PLAYER_ERROR_DRM_UNSUPPORTED = 'PLAYER_ERROR_DRM_UNSUPPORTED';
export const GENDER_MALE = 'GENDER_MALE';
export const GENDER_FEMALE = 'GENDER_FEMALE';
export const RECOMMENDED = 'RECOMMENDED';
export const PROFILES_TAB_DEVICES = 'PROFILES_TAB_DEVICES';
export const PROFILES_LINK_PROFILE = 'PROFILES_LINK_PROFILE';
export const PROFILES_EDIT_PROFILE = 'PROFILES_EDIT_PROFILE';
export const PROFILES_ADD_PROFILE = 'PROFILES_ADD_PROFILE';
export const PROFILES_KIDS_CHECKER = 'PROFILES_KIDS_CHECKER';
export const PROFILES_MANAGE = 'PROFILES_MANAGE';
export const PROFILES_WHO_IS_WATHCING = 'PROFILES_WHO_IS_WATHCING';
export const PROFILES_ALLOWED_CONTENT_SELECT_VALUE = 'PROFILES_ALLOWED_CONTENT_SELECT_VALUE';
export const PROFILES_DONE = 'PROFILES_DONE';
export const CONTINUE_WATCHING = 'CONTINUE_WATCHING';
export const num_season = '{num} season';
export const CAN_WATCH_VIDEO_ONLY_ON = 'CAN_WATCH_VIDEO_ONLY_ON';
export const AUTH_BY_CODE = 'AUTH_BY_CODE';
export const NAV_LEGEND_FILTER = 'NAV_LEGEND_FILTER';
export const NAV_LEGEND_MENU = 'NAV_LEGEND_MENU';
export const AUTH_BY_LOGIN_AND_PASSWORD = 'AUTH_BY_LOGIN_AND_PASSWORD';
export const AUTH_SUBMIT_LOGIN_AND_PASSWORD = 'AUTH_SUBMIT_LOGIN_AND_PASSWORD';
export const AUTH_ENTER_PASSWORD = 'AUTH_ENTER_PASSWORD';
export const AUTH_BY_LOGIN_FAILED_ERROR_REQUISITES = 'AUTH_BY_LOGIN_FAILED_ERROR_REQUISITES';
export const ERROR_HAS_OCCURRED = 'ERROR_HAS_OCCURRED';
export const WATCH_LIST_REMOVE = 'WATCH_LIST_REMOVE';
export const WATCH_LIST_ADD = 'WATCH_LIST_ADD';
export const AUTH_ENTER_PASSWORD_TO_LOGIN = 'AUTH_ENTER_PASSWORD_TO_LOGIN';
export const AUTH_ENTER_LOGIN = 'AUTH_ENTER_LOGIN';
export const HEADER_WATCH_LATER = 'HEADER_WATCH_LATER';
export const HEADER_FAVORITE_CHANNELS = 'HEADER_FAVORITE_CHANNELS';
export const FAVORITE_CHANNEL_DELETE = 'FAVORITE_CHANNEL_DELETE';
export const FAVORITE_CHANNEL_ADD = 'FAVORITE_CHANNEL_ADD';
export const AUTH_SELECT_AUTH_METHOD = 'AUTH_SELECT_AUTH_METHOD';
export const SUBSCRIPTION_HOLD_MESSAGE = 'SUBSCRIPTION_HOLD_MESSAGE';
export const ACCOUNT_TAB_SECURITY = 'ACCOUNT_TAB_SECURITY';
export const ACCOUNT_TAB_PAYMENT_HISTORY = 'ACCOUNT_TAB_PAYMENT_HISTORY';
export const ACCOUNT_TAB_SUBSCRIPTIONS = 'ACCOUNT_TAB_SUBSCRIPTIONS';
export const ACCOUNT_TAB_CARDS = 'ACCOUNT_TAB_CARDS';
export const ACCOUNT_TAB_SETTINGS = 'ACCOUNT_TAB_SETTINGS';
export const ACCOUNT_LABEL_CHANGE_PASSWORD = 'ACCOUNT_LABEL_CHANGE_PASSWORD';
export const ACCOUNT_LABEL_PARENTAL_CONTROL = 'ACCOUNT_LABEL_PARENTAL_CONTROL';
export const ACCOUNT_LABEL_EMAIL = 'ACCOUNT_LABEL_EMAIL';
export const ACCOUNT_LABEL_STREET = 'ACCOUNT_LABEL_STREET';
export const ACCOUNT_LABEL_POSTCODE = 'ACCOUNT_LABEL_POSTCODE';
export const ACCOUNT_LABEL_CITY = 'ACCOUNT_LABEL_CITY';
export const ACCOUNT_LABEL_COUNTRY = 'ACCOUNT_LABEL_COUNTRY';
export const ACCOUNT = 'ACCOUNT';
export const MARKER_FEATURED = 'MARKER_FEATURED';
export const MARKER_FREE = 'MARKER_FREE';
export const MARKER_POPULAR = 'MARKER_POPULAR';
export const MARKER_NEW = 'MARKER_NEW';
export const PLAYER_ERROR_NETWORK = 'PLAYER_ERROR_NETWORK';
export const ENTER_PROMO_CODE = 'ENTER_PROMO_CODE';
export const PROMO_CODE_ENTER_BUTTON = 'PROMO_CODE_ENTER_BUTTON';
export const PROMO_CODE_PAGE_DESCRIPTION = 'PROMO_CODE_PAGE_DESCRIPTION';
export const PROMO_CODE_ERROR_TRY_IT_ON_A_DIFFERENT_PLATFORM = 'PROMO_CODE_ERROR_TRY_IT_ON_A_DIFFERENT_PLATFORM';
export const PROMO_CODE_ALREADY_SUBSCRIBED = 'PROMO_CODE_ALREADY_SUBSCRIBED';
export const PRICE_PERIOD = 'PRICE_PERIOD';
export const PROMO_CODE_FREE_PERIOD_TEXT = 'PROMO_CODE_FREE_PERIOD_TEXT';
export const PROMO_CODE_FREE_PERIOD_TEXT_MANY = 'PROMO_CODE_FREE_PERIOD_TEXT_MANY';
export const PROMO_CODE_GIVE_DISCOUNT_FOR_ONE_OF = 'PROMO_CODE_GIVE_DISCOUNT_FOR_ONE_OF';
export const PROMO_CODE_GIVE_DISCOUNT = 'PROMO_CODE_GIVE_DISCOUNT';
export const NO_INTERNET_CONNECTION_AVAILABLE = 'NO_INTERNET_CONNECTION_AVAILABLE';
export const PARENTAL_CONTROL_DESCRIPTION = 'PARENTAL_CONTROL_DESCRIPTION';
export const PARENTAL_CONTROL = 'PARENTAL_CONTROL';
export const PIN_CODE_DESCRIPTION = 'PIN_CODE_DESCRIPTION';
export const ADS_FROM_TO = 'ADS_FROM_TO';
export const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS';
export const CONTINUE = 'CONTINUE';
export const PASSWORD_NOT_EQUALS = 'PASSWORD_NOT_EQUALS';
export const CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD';
export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const WRONG_PASSWORD = 'WRONG_PASSWORD';
export const ENTER_PASSWORD = 'ENTER_PASSWORD';
export const ACCOUNT_LABEL_PASSWORD = 'ACCOUNT_LABEL_PASSWORD';
export const MARKER_SUBSCRIPTION = 'MARKER_SUBSCRIPTION';
export const MARKER_SOON = 'MARKER_SOON';
export const AUTH_SEND_CODE = 'AUTH_SEND_CODE';
export const ON_AIR = 'ON_AIR';
export const OPEN_DEEPLINK = 'OPEN_DEEPLINK';
export const ADS_SKIP_AFTER = 'ADS_SKIP_AFTER';
export const CONFIRM_PHONE_BY_CALL = 'CONFIRM_PHONE_BY_CALL';
export const NOT_CONFIRMED = 'NOT_CONFIRMED';
export const USERNAME_ALREADY_IN_USE = 'USERNAME_ALREADY_IN_USE';
export const ERROR_REGISTRATION_PASSWORD_LENGTH = 'ERROR_REGISTRATION_PASSWORD_LENGTH';
export const ENTER_REGISTRATION_NAME = 'ENTER_REGISTRATION_NAME';
export const ENTER_REGISTRATION_PASSWORD = 'ENTER_REGISTRATION_PASSWORD';
export const REGISTRATION = 'REGISTRATION';
export const PASSWORD_HAS_SENDED = 'PASSWORD_HAS_SENDED';
export const TODAY = 'TODAY';
export const EPISODES_OF_PROGRAM = 'EPISODES_OF_PROGRAM';
export const EPISODES_OF_SERIALS = 'EPISODES_OF_SERIALS';
export const SUPPORT = 'SUPPORT';
export const APPLICATION_LAUNCHING_ERROR = 'APPLICATION_LAUNCHING_ERROR';
export const NAV_TO_TOP = 'NAV_TO_TOP';
export const MENU_CATCHUP = 'MENU_CATCHUP';
export const ROLE_PRODUCER = 'ROLE_PRODUCER';
export const ROLE_EDITOR = 'ROLE_EDITOR';
export const ROLE_COMPOSER = 'ROLE_COMPOSER';
export const ROLE_WRITER = 'ROLE_WRITER';
export const ROLE_PRESENTER = 'ROLE_PRESENTER';
export const ROLE_DIRECTOR = 'ROLE_DIRECTOR';
export const ROLE_ACTOR = 'ROLE_ACTOR';
export const CERTIFICATION_RATING = 'CERTIFICATION_RATING';
export const EVENT_AVAILABLE_ENTRIES = 'EVENT_AVAILABLE_ENTRIES';
export const EVENT_NEAREST_BROADCAST = 'EVENT_NEAREST_BROADCAST';
export const EVENT_DESCRIPTION = 'EVENT_DESCRIPTION';
export const WHAT_IS_AN_SUBSCRIPTION = 'WHAT_IS_AN_SUBSCRIPTION';
export const TRY_FOR_FREE = 'TRY_FOR_FREE';
export const TO = 'TO';
export const FROM = 'FROM';
export const SEARCH_BY_DATE = 'SEARCH_BY_DATE';
export const ALREADY_AVAILABLE = 'ALREADY_AVAILABLE';
export const PRODUCT_SELECT_PLAN_BUTTON = 'PRODUCT_SELECT_PLAN_BUTTON';
export const trial = 'trial';
export const ETHER_FROM = 'ETHER_FROM';
export const AVAILABLE_UNTIL = 'AVAILABLE_UNTIL';
export const TRIAL_FREE_PERIOD_TEXT = 'TRIAL_FREE_PERIOD_TEXT';
export const SEE_ALL_ITEMS = 'SEE_ALL_ITEMS';
export const PAGE_LOADING = 'PAGE_LOADING';
export const RECOMMENDED_FOR_YOU = 'RECOMMENDED_FOR_YOU';
export const SIGN_IN_TO_PURCHASE = 'SIGN_IN_TO_PURCHASE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const SUBSCRIPTION_NO_ACCESS_GRANTED = 'SUBSCRIPTION_NO_ACCESS_GRANTED';
export const PURCHASE_DESCRIPTION = 'PURCHASE_DESCRIPTION';
export const SUBSCRIPTION_CANCELLATION_DESCRIPTION = 'SUBSCRIPTION_CANCELLATION_DESCRIPTION';
export const CANCEL_PENDING = 'CANCEL_PENDING';
export const NEXT_BILLING_DATE = 'NEXT_BILLING_DATE';
export const EXPIRES_ON_DATE = 'EXPIRES_ON_DATE';
export const PAYMENT_WAS_SUCCESSFUL = 'PAYMENT_WAS_SUCCESSFUL';
export const PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS';
export const SUBSCRIPTION_IS_NOT_CANCELABLE = 'SUBSCRIPTION_IS_NOT_CANCELABLE';
export const ALREADY_SUBSCRIBED = 'ALREADY_SUBSCRIBED';
export const CHOICE_SUBSCRIBTION = 'CHOICE_SUBSCRIBTION';
export const MOBILE_PAYMENT = 'MOBILE_PAYMENT';
export const PAYMENT_METHOD = 'PAYMENT_METHOD';
export const ARE_YOU_WANT_ANSUBSCRIBE = 'ARE_YOU_WANT_ANSUBSCRIBE';
export const MY_SUBCRIPTIONS = 'MY_SUBCRIPTIONS';
export const AVAILABLE_SUBSCRIPTIONS = 'AVAILABLE_SUBSCRIPTIONS';
export const WILL_BE_DEBITED_FROM_YOUR_ACCOUNT = 'WILL_BE_DEBITED_FROM_YOUR_ACCOUNT';
export const NO_AVAILABLE_SUBSCRIPTIONS = 'NO_AVAILABLE_SUBSCRIPTIONS';
export const NON_TRIAL_TEXT_CONFIRMATION = 'NON_TRIAL_TEXT_CONFIRMATION';
export const SUBSCRIPTION_WAS_CANCELLED = 'SUBSCRIPTION_WAS_CANCELLED';
export const SUBSCRIPTION_CHOOSING = 'SUBSCRIPTION_CHOOSING';
export const SUBSCRIPTION_CANCELLING = 'SUBSCRIPTION_CANCELLING';
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const CHOICE_OF_DATE = 'CHOICE_OF_DATE';
export const EST_DESCRIPTION = 'EST_DESCRIPTION';
export const TVOD_DESCRIPTION = 'TVOD_DESCRIPTION';
export const RENT_TITLE = 'RENT_TITLE';
export const PURCHASE_TITLE = 'PURCHASE_TITLE';
export const RENT_ACTION = 'RENT_ACTION';
export const BUY_ACTION = 'BUY_ACTION';
export const OR = 'OR';
export const PAYMENT_TITLE = 'PAYMENT_TITLE';
export const NO_PURCHASES = 'NO_PURCHASES';
export const EXPIRED_PURCHASES = 'EXPIRED_PURCHASES';
export const ACTIVE_PURCHASES = 'ACTIVE_PURCHASES';
export const PURCHASES = 'PURCHASES';
export const ACTIVE_RENT_AVAILABLE = 'ACTIVE_RENT_AVAILABLE';
export const WATCHING_OPTIONS_SELECTION = 'WATCHING_OPTIONS_SELECTION';
export const TVOD_PURCHASED = 'TVOD_PURCHASED';
export const EST_PURCHASED = 'EST_PURCHASED';
export const SUBSCRIPTION_ITEM_DESCRIPTION = 'SUBSCRIPTION_ITEM_DESCRIPTION';
export const ADS_ANNOUNCEMENT = 'ADS_ANNOUNCEMENT';
export const RENEWAL_PROMO_ATTENTION = 'RENEWAL_PROMO_ATTENTION';
export const SUBCRIBED_TRIAL_PROMO = 'SUBCRIBED_TRIAL_PROMO';
export const PROMO_DISCOUNT_DESCRIPTION = 'PROMO_DISCOUNT_DESCRIPTION';
export const PROMO_DISCOUNT_ON_PLAN_DESCRIPTION = 'PROMO_DISCOUNT_ON_PLAN_DESCRIPTION';
export const YOUR_PROMO_CODE = 'YOUR_PROMO_CODE';
export const PROMO_CODE_ACTIVATED = 'PROMO_CODE_ACTIVATED';
export const SELECT_SUBSCRIPTION_APPLY_PROMO = 'SELECT_SUBSCRIPTION_APPLY_PROMO';
export const ENTER_ANOTHER_PROMO_CODE = 'ENTER_ANOTHER_PROMO_CODE';
export const PROMO_AUTORENEW_AGREEMENT = 'PROMO_AUTORENEW_AGREEMENT';
export const ACTIVATE_PROMOCODE = 'ACTIVATE_PROMOCODE';
export const SELECT_PAYMENT_PRODUCT_SUBTITLE = 'SELECT_PAYMENT_PRODUCT_SUBTITLE';
export const PROMOCODE_ACTIVATION = 'PROMOCODE_ACTIVATION';
export const PROMOCODE = 'PROMOCODE';
export const WRONG_PROMO_CODE_ERROR = 'WRONG_PROMO_CODE_ERROR';
export const TRIAL_PROMO_DESCRIPTION = 'TRIAL_PROMO_DESCRIPTION';
export const CHOOSE_TRIAL_PROMO_PRODUCT = 'CHOOSE_TRIAL_PROMO_PRODUCT';
export const DISOUNT_PROMO_CODE_DURATION = 'DISOUNT_PROMO_CODE_DURATION';
export const PROMO_LABEL = 'PROMO_LABEL';
export const DURATION_IN_UNIT = 'DURATION_IN_UNIT';
export const DISABLE_ADS = 'DISABLE_ADS';
export const CONFLICT_SUBSCRIPTION_WARNING = 'CONFLICT_SUBSCRIPTION_WARNING';
export const INVALID_EMAIL_AND_PASSWORD = 'INVALID_EMAIL_AND_PASSWORD';
export const INVALID_FORMAT_PHONE_NUMBER_OR_EMAIL = 'INVALID_FORMAT_PHONE_NUMBER_OR_EMAIL';
export const INVALID_FORMAT_EMAIL = 'INVALID_FORMAT_EMAIL';
export const INVALID_FORMAT_PHONE_NUMBER = 'INVALID_FORMAT_PHONE_NUMBER';
export const ERROR_UNKNOWN_EMAIL = 'ERROR_UNKNOWN_EMAIL';
export const ERROR_UNKNOWN_PHONE_NUMBER = 'ERROR_UNKNOWN_PHONE_NUMBER';
export const ERROR_UNKNOWN_PHONE_NUMBER_OR_EMAIL = 'ERROR_UNKNOWN_PHONE_NUMBER_OR_EMAIL';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const PHONE_NUMBER_OR_EMAIL = 'PHONE_NUMBER_OR_EMAIL';
export const AUTH_BY_USERNAME = 'AUTH_BY_USERNAME';
export const AUTH_BY_PHONE_NUMBER = 'AUTH_BY_PHONE_NUMBER';
export const AUTH_BY_EMAIL = 'AUTH_BY_EMAIL';
export const PLAYER_ERROR_NOT_PURCHASED_SUBTEXT = 'PLAYER_ERROR_NOT_PURCHASED_SUBTEXT';
export const ACCEPT = 'ACCEPT';
export const POLICY_ANNOUNCEMENT = 'POLICY_ANNOUNCEMENT';
export const VERIFY_PHONE = 'VERIFY_PHONE';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const ACCOUNT_IS_BLOCKED = 'ACCOUNT_IS_BLOCKED';
export const ACCOUNT_LABEL_USERNAME = 'ACCOUNT_LABEL_USERNAME';
export const SETUP = 'SETUP';
export const ACCOUNT_LABEL_NAME = 'ACCOUNT_LABEL_NAME';
export const REGISTRATION_USED_TO_LOGIN = 'REGISTRATION_USED_TO_LOGIN';
export const REGISTRATION_ENTER_PHONE_OR_EMAIL = 'REGISTRATION_ENTER_PHONE_OR_EMAIL';
export const REGISTRATION_CREATE_PASSWORD_DESCRIPTION = 'REGISTRATION_CREATE_PASSWORD_DESCRIPTION';
export const REGISTRATION_CREATE_PASSWORD = 'REGISTRATION_CREATE_PASSWORD';
export const NOT_REGISTERED_YET = 'NOT_REGISTERED_YET';
export const EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE';
export const DATA_IS_INCORRECT = 'DATA_IS_INCORRECT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const LOGIN = 'LOGIN';
export const REGISTER_NOW = 'REGISTER_NOW';
export const PERSONAL_DATA_IS_SAFE = 'PERSONAL_DATA_IS_SAFE';
export const REGISTRATION_NOTIFY_ME_ABOUT_NEWS_PROMO = 'REGISTRATION_NOTIFY_ME_ABOUT_NEWS_PROMO';
export const REGISTRATION_CONFIRM_EMAIL = 'REGISTRATION_CONFIRM_EMAIL';
export const REGISTRATION_CONFIRM_PHONE = 'REGISTRATION_CONFIRM_PHONE';
export const RESEND_SECURITY_CODE = 'RESEND_SECURITY_CODE';
export const RESEND_SECURITY_CODE_AFTER = 'RESEND_SECURITY_CODE_AFTER';
export const SECURITY_CODE = 'SECURITY_CODE';
export const INVALID_PASSWORD_FORMAT = 'INVALID_PASSWORD_FORMAT';
export const SEASON_NUM = 'SEASON_NUM';
export const EPISODE_NUM = 'EPISODE_NUM';
export const STREAM_IS_NOT_AVAILABLE_ON_DEVICE = 'STREAM_IS_NOT_AVAILABLE_ON_DEVICE';
export const REGISTRATION_TEXT_REGISTRATE = 'REGISTRATION_TEXT_REGISTRATE';
export const REGISTRATION_TEXT_SIGN_IN = 'REGISTRATION_TEXT_SIGN_IN';
export const REGISTRATION_TEXT_HEADING = 'REGISTRATION_TEXT_HEADING';
export const SAFETY = 'SAFETY';
export const PAYMENTS = 'PAYMENTS';
export const MY_VIDEO_LIBRARY = 'MY_VIDEO_LIBRARY';
export const HELP = 'HELP';
export const FAVORITES = 'FAVORITES';
export const REGISTRATION_PASSWORD_RESTRICTION = 'REGISTRATION_PASSWORD_RESTRICTION';
export const I_LOOK = 'I_LOOK';
export const BROWSING_HISTORY = 'BROWSING_HISTORY';
export const PREMIERES = 'PREMIERES';
export const ABOUT_CHANNEL = 'ABOUT_CHANNEL';
export const EDIT = 'EDIT';
export const ENABLE_AUTOMATIC_LOGIN = 'ENABLE_AUTOMATIC_LOGIN';
export const AUTHORIZATION = 'AUTHORIZATION';
export const ACCOUNT_SECURITY_ENTER_PIN_CODE = 'ACCOUNT_SECURITY_ENTER_PIN_CODE';
export const ACCOUNT_SECURITY_ENTER_PIN_TEXT = 'ACCOUNT_SECURITY_ENTER_PIN_TEXT';
export const ACCOUNT_SECURITY_CONFIRM_PIN_TITLE = 'ACCOUNT_SECURITY_CONFIRM_PIN_TITLE';
export const ACCOUNT_SECURITY_CREATE_PIN_TITLE = 'ACCOUNT_SECURITY_CREATE_PIN_TITLE';
export const RESET_PIN_CODE_TEXT = 'RESET_PIN_CODE_TEXT';
export const RESET_PIN_CODE_TITLE = 'RESET_PIN_CODE_TITLE';
export const FORGOT_PIN = 'FORGOT_PIN';
export const ACCOUNT_SECURITY_NEW_PIN_TEXT = 'ACCOUNT_SECURITY_NEW_PIN_TEXT';
export const AUTH_SCREEN_CONFIRM_CALL_DESCR = 'AUTH_SCREEN_CONFIRM_CALL_DESCR';
export const AUTH_SCREEN_CONFIRM_EMAIL_DESCR = 'AUTH_SCREEN_CONFIRM_EMAIL_DESCR';
export const AUTH_SCREEN_CONFIRM_PHONE_DESCR = 'AUTH_SCREEN_CONFIRM_PHONE_DESCR';
export const AUTH_SCREEN_FORGOT_PASS_CALL_FOOTER = 'AUTH_SCREEN_FORGOT_PASS_CALL_FOOTER';
export const AUTH_SCREEN_CONFIRM_CALL_FOOTER = 'AUTH_SCREEN_CONFIRM_CALL_FOOTER';
export const AUTH_SCREEN_RESET_PASS_HEADER = 'AUTH_SCREEN_RESET_PASS_HEADER';
export const AUTH_SCREEN_FORGOT_PASS_NEW = 'AUTH_SCREEN_FORGOT_PASS_NEW';
export const AUTH_SCREEN_FORGOT_PASS_CALL_DESCR = 'AUTH_SCREEN_FORGOT_PASS_CALL_DESCR';
export const AUTH_SCREEN_FORGOT_PASS_PHONE_DESCR = 'AUTH_SCREEN_FORGOT_PASS_PHONE_DESCR';
export const AUTH_SCREEN_FORGOT_PASS_EMAIL_DESCR = 'AUTH_SCREEN_FORGOT_PASS_EMAIL_DESCR';
export const AUTH_SCREEN_CREDENTIALS_DESCR = 'AUTH_SCREEN_CREDENTIALS_DESCR';
export const SERIES_IN_SUBSCRIPTION = 'SERIES_IN_SUBSCRIPTION';
export const MOVIES_IN_SUBSCRIPTION = 'MOVIES_IN_SUBSCRIPTION';
export const CHANNELS_IN_SUBSCRIPTION = 'CHANNELS_IN_SUBSCRIPTION';
export const FIRST = 'FIRST';
export const PROMO_CODE_NO_PRODUCTS = 'PROMO_CODE_NO_PRODUCTS';
export const ALL = 'ALL';
export const SEARCH_SPECIFY_REQUEST = 'SEARCH_SPECIFY_REQUEST';
export const SEARCH_NO_RESULTS = 'SEARCH_NO_RESULTS';
export const FAQ_CONTACTS_SECTION_TITLE = 'FAQ_CONTACTS_SECTION_TITLE';
export const FAQ_PAYMENTS_SECTION_TITLE = 'FAQ_PAYMENTS_SECTION_TITLE';
export const FAQ_CONTENT_SECTION_TITLE = 'FAQ_CONTENT_SECTION_TITLE';
export const FAQ_MAIN_SECTION_TITLE = 'FAQ_MAIN_SECTION_TITLE';
export const PLAYER_POPUP_SUBTITLE_LANGUAGE = 'PLAYER_POPUP_SUBTITLE_LANGUAGE';
export const PLAYER_POPUP_AUDIO_LANGUAGE = 'PLAYER_POPUP_AUDIO_LANGUAGE';
export const PLAYER_POPUP_VIDEO_QUALITY = 'PLAYER_POPUP_VIDEO_QUALITY';
export const PLAYER_POPUP_VIDEO_AUTO_QUALITY = 'PLAYER_POPUP_VIDEO_AUTO_QUALITY';
export const NEXT_EPISODE = 'NEXT_EPISODE';
export const CANCEL = 'CANCEL';
export const PROFILE_TYPE_ADULT = 'PROFILE_TYPE_ADULT';
export const PROFILE_TYPE_KIDS = 'PROFILE_TYPE_KIDS';
export const PROFILE_TYPE = 'PROFILE_TYPE';
export const PROFILE_USER_NAME = 'PROFILE_USER_NAME';
export const PROFILE_EDITOR_NAME_MIN_LENGTH_ERROR = 'PROFILE_EDITOR_NAME_MIN_LENGTH_ERROR';
export const AGREEMENT_BLOCK_POLICY_TEXT = 'AGREEMENT_BLOCK_POLICY_TEXT';
export const AGREEMENT_BLOCK_AND_TEXT = 'AGREEMENT_BLOCK_AND_TEXT';
export const AGREEMENT_BLOCK_TERMS_TEXT = 'AGREEMENT_BLOCK_TERMS_TEXT';
export const AGREEMENT_BLOCK_BEGINNING_TEXT = 'AGREEMENT_BLOCK_BEGINNING_TEXT';
export const RESEND_PASSWORD_COUNTER_TEXT = 'RESEND_PASSWORD_COUNTER_TEXT';
export const PASSWORD_RECOVERY_SMS_OR_EMAIL_DESCR = 'PASSWORD_RECOVERY_SMS_OR_EMAIL_DESCR';
export const ASYNC_SUBSRIPTIONS = 'ASYNC_SUBSRIPTIONS';
export const PROMO_SELECT_SUBSCRIPTION_HEADER_TEXT = 'PROMO_SELECT_SUBSCRIPTION_HEADER_TEXT';
export const PROMO_NAVIGATE_TO_START_PAGE = 'PROMO_NAVIGATE_TO_START_PAGE';
export const NAVIGATE_TO_HOME = 'NAVIGATE_TO_HOME';
export const MENU_PROFILES = 'MENU_PROFILES';
export const MENU_PERSONAL_DATA = 'MENU_PERSONAL_DATA';
export const PROFILES_SELECT_PROFILE_TEXT = 'PROFILES_SELECT_PROFILE_TEXT';
export const PROFILES_SELECTED_PROFILE_TEXT = 'PROFILES_SELECTED_PROFILE_TEXT';
export const PROFILES_MAIN_PROFILE = 'PROFILES_MAIN_PROFILE';
export const ON_AIR_DATE = 'ON_AIR_DATE';
export const RECORD_DATE = 'RECORD_DATE';
export const SIGN_IN_IMPLICIT_DESCR = 'SIGN_IN_IMPLICIT_DESCR';
export const SIGN_IN_IMPLICIT_HEADER = 'SIGN_IN_IMPLICIT_HEADER';
export const REGISTRATION_ENTER_PHONE = 'REGISTRATION_ENTER_PHONE';
export const PROFILES_ADD_TEXT = 'PROFILES_ADD_TEXT';
export const PASSWORD_HAS_SENDED_WITH_PHONE = 'PASSWORD_HAS_SENDED_WITH_PHONE';
export const REGISTER_OR_SIGN_IN_IMPLICIT = 'REGISTER_OR_SIGN_IN_IMPLICIT';
export const TRIAL_PERIOD = 'TRIAL_PERIOD';
export const REGISTRATION_ENTER_EMAIL = 'REGISTRATION_ENTER_EMAIL';
export const SUBSCRIBTION_CANCELLATION_DISCLAMER = 'SUBSCRIBTION_CANCELLATION_DISCLAMER';
export const IMPLICIT_AGREEMENT_BLOCK_BEGINNING_TEXT = 'IMPLICIT_AGREEMENT_BLOCK_BEGINNING_TEXT';
export const EDIT_MODE_DESCRIPTION = 'EDIT_MODE_DESCRIPTION';
export const CANCEL_SELECTION = 'CANCEL_SELECTION';
export const DELETE_ALL_ITEMS = 'DELETE_ALL_ITEMS';
export const DELETE_COUNT = 'DELETE_COUNT';
export const AUDIO_SHOWS_IN_SUBSCRIPTION = 'AUDIO_SHOWS_IN_SUBSCRIPTION';
export const AUDIO_SHOWS = 'AUDIO_SHOWS';
export const AUTH_SCREEN_FORGOT_PASS_PHONE_OR_EMAIL = 'AUTH_SCREEN_FORGOT_PASS_PHONE_OR_EMAIL';
export const MATCH_PAGE_STADIUM = 'MATCH_PAGE_STADIUM';
export const PASSWORD_HAS_SENDED_WITH_EMAIL = 'PASSWORD_HAS_SENDED_WITH_EMAIL';
export const RESTORE_INVALID_CODE = 'RESTORE_INVALID_CODE';
export const LOGIN_OR_REGISTER = 'LOGIN_OR_REGISTER';
export const PERSONAL_UNAUTHORIZED_FAVORITES = 'PERSONAL_UNAUTHORIZED_FAVORITES';
export const PERSONAL_UNAUTHORIZED_WATCHING_HISTORY = 'PERSONAL_UNAUTHORIZED_WATCHING_HISTORY';
export const PERSONAL_UNAUTHORIZED_PURCHASES = 'PERSONAL_UNAUTHORIZED_PURCHASES';
export const PERSONAL_UNAUTHORIZED_CHANNELS = 'PERSONAL_UNAUTHORIZED_CHANNELS';
export const PERSONAL_UNAUTHORIZED_CONTINUE_WATCHING = 'PERSONAL_UNAUTHORIZED_CONTINUE_WATCHING';
export const PROMO_CONGRATULATIONS = 'PROMO_CONGRATULATIONS';
export const PROMO_VOD_DISCOUNT_SUCCESS = 'PROMO_VOD_DISCOUNT_SUCCESS';
export const PROMO_VOD_DISCOUNT_DISCLAMER = 'PROMO_VOD_DISCOUNT_DISCLAMER';
export const BY_WITH_PROMO = 'BY_WITH_PROMO';
export const FOR_PRICE = 'FOR_PRICE';
export const BY_SUBSCRIPTION = 'BY_SUBSCRIPTION';
export const PLEASE_COMPLETE_PURCHASE = 'PLEASE_COMPLETE_PURCHASE';
export const PAYMENT_INSTRUCTIONS_MOBILE = 'PAYMENT_INSTRUCTIONS_MOBILE';
export const CONFIRM = 'CONFIRM';
export const AMEDIATEKA_TERMS = 'AMEDIATEKA_TERMS';
export const START_TERMS = 'START_TERMS';
export const RULES_CONFIRMATION_TEXT = 'RULES_CONFIRMATION_TEXT';
export const SCREEN_RESOLUTION_INFO = 'SCREEN_RESOLUTION_INFO';
export const COUNTRIES = 'COUNTRIES';
export const CAST_MEMBERS_EDITOR = 'CAST_MEMBERS_EDITOR';
export const CAST_MEMBERS_WRITER = 'CAST_MEMBERS_WRITER';
export const CAST_MEMBERS_PRODUCER = 'CAST_MEMBERS_PRODUCER';
export const CAST_MEMBERS_ACTOR = 'CAST_MEMBERS_ACTOR';
export const EXTERNAL_CATALOG = 'EXTERNAL_CATALOG';
export const RIGHT_HOLDER = 'RIGHT_HOLDER';
export const STUDIOS = 'STUDIOS';
export const DURATION = 'DURATION';
export const PRODUCTION_YEAR = 'PRODUCTION_YEAR';
export const GO_TO_VIEW = 'GO_TO_VIEW';
export const SEARCH_SPECIFY_REQUEST_EXPAND = 'SEARCH_SPECIFY_REQUEST_EXPAND';
export const USER_STATS = 'USER_STATS';
export const VIEW_SUMMARY = 'VIEW_SUMMARY';
export const INFORMATION = 'INFORMATION';
export const VIEW_SUMMARY_PROGRAM_EVENT = 'VIEW_SUMMARY_PROGRAM_EVENT';
export const VIEW_SUMMARY_EPISODE = 'VIEW_SUMMARY_EPISODE';
export const VIEW_SUMMARY_CHANNEL = 'VIEW_SUMMARY_CHANNEL';
export const VIEW_SUMMARY_PART = 'VIEW_SUMMARY_PART';
export const VIEW_SUMMARY_MOVIE = 'VIEW_SUMMARY_MOVIE';
export const VIEW_SUMMARY_S = 'VIEW_SUMMARY_S';
export const VIEW_SUMMARY_M = 'VIEW_SUMMARY_M';
export const VIEW_SUMMARY_H = 'VIEW_SUMMARY_H';
export const TV_PROGRAMS = 'TV_PROGRAMS';
export const MOVIES_AND_SERIES = 'MOVIES_AND_SERIES';
export const ACCESS_REASON_RESTRICTED_BY_GEO = 'ACCESS_REASON_RESTRICTED_BY_GEO';
export const NO_CONTENT_WITH_THIS_PERSON = 'NO_CONTENT_WITH_THIS_PERSON';


export default {
INFO_NOT_EXISTS,
MIXED_AUTH_RESEND_SMS,
MIXED_AUTH_RESEND_SMS_TIME,
YESTERDAY,
TOMORROW,
RELEASE_CALENDAR,
MIXED_AUTH_TERMS_ACCEPT_BUTTON,
MIXED_AUTH_INCORRECT_NUMBER,
MIXED_AUTH_PHONE_LOGIN_PAGE_SUBHEADER,
NAV_LEGEND_SEARCH,
MIXED_AUTH_ERROR_4_ADD,
MIXED_AUTH_ERROR_4,
MIXED_AUTH_ERROR_12,
MIXED_AUTH_ERROR_11,
MIXED_AUTH_ERROR_10,
MIXED_AUTH_ERROR_9,
MIXED_AUTH_ERROR_8,
MIXED_AUTH_ERROR_7,
MIXED_AUTH_ERROR_5,
MIXED_AUTH_ERROR_3,
MIXED_AUTH_ERROR_2,
MIXED_AUTH_ERROR_1,
MIXED_AUTH_ERROR_0,
MIXED_AUTH_MAIN_BUTTON,
MIXED_AUTH_MAIN_SCAN,
MIXED_AUTH_MAIN_ENTER_CODE,
MIXED_AUTH_MAIN_TO_APP_LINK,
MIXED_AUTH_MAIN_TO_APP,
MIXED_AUTH_MAIN_TO_SITE_LINK,
MIXED_AUTH_MAIN_TO_SITE,
MIXED_AUTH_MAIN_FROM_CHANNEL,
MIXED_AUTH_MAIN_FROM_PAGE,
MIXED_AUTH_MAIN_FROM_18,
WELLCOME_AUTH_TEXT,
rkn_plate,
CHANNEL_FLIPPER_NO_SCHEDULE,
RESET_ALL_FILTERS,
PURCHASED,
THE_WHOLE_SERIES,
SECURITY_TAB_PIN_LABEL,
CHANGE_PIN,
SECURITY_TAB_PARENT_LABEL,
NEW_PIN_CODE_FLOW_ASK_BUTTON_SETUP,
NEW_PIN_CODE_FLOW_ASK_BUTTON_SKIP,
NEW_PIN_CODE_FLOW_ASK_BUTTON_ON,
NEW_PIN_CODE_FLOW_ASK_MESSAGE,
NEW_PIN_CODE_FLOW_ASK_PARENTAL_CONTROL_HEADER,
NEW_PIN_CODE_FLOW_ASK_PIN_HEADER,
NEW_PIN_CODE_FLOW_SUCCESS_BUTTON,
NEW_PIN_CODE_FLOW_SUCCESS_MESSAGE,
NEW_PIN_CODE_FLOW_PARENTAL_CONTROL_SUCCESS_HEADER,
NEW_PIN_CODE_FLOW_PIN_CODE_SUCCESS_HEADER,
SIGN_IN_PROBLEMS_TEXT,
APPLICATION_LANGUAGE,
CONFIRM_LOGOUT_TEXT,
REGISTER_USERS_CAN_ACCESS,
LOGOUT_FROM_ACCOUNT,
LOGIN_OR_REGISTER_TEXT,
ABOUT_PROGRAM,
ACCOUNT_CONTROL,
ALL_PARTS,
DELETE_PROFILE_CONFIRM,
DELETE,
PROFILES_REMOVE_PROFILE,
PROFILE_NAME,
PROFILES_ALLOWED_CONTENT_FIELD_NAME,
ENTER_PROFILE_NAME,
PROFILES_ALLOWED_CONTENT_LITTLE_KIDS,
PROFILES_ALLOWED_CONTENT_OLDER_KIDS,
PROFILES_ALLOWED_CONTENT_TEENS,
PROFILES_ALLOWED_CONTENT_ADULT,
ACCOUNT_SECURITY_CREATE_PIN_TEXT,
PIN_CODE_CREATE,
PIN_CODE_CANCEL,
ALL_EPISODES,
ALL_SEASONS,
PIN_INVALID,
CONFIRM_PIN_CODE_POPUP_TITLE,
CREATE_PIN_CODE_POPUP_TITLE,
PIN_CODE,
ACCOUNT_LABEL_SECURITY_PIN,
ENTER_PIN_CODE_POPUP_TEXT,
PIN_NOT_EQUAL,
THE_LIMIT_OF_PROFILES,
ADD_A_NEW_PROFILE,
ABOUT_PROGRAM_EVENT,
SOMETHING_WENT_WRONG,
CLOSE_THE_APP,
USER_AGREEMENT,
YOU_CANNOT_ADD_THIS_DEVICE,
YOU_CAN_CHANGE_THE_DEVICE_NAME,
YOU_CAN_CHANGE_THE_DEVICE_NAME_CURRENT,
THIS_IS_THE_CURRENT_DEVICE,
RENT_REMAINING_TIME_WARNING,
PLAYBACK,
DO_YOU_WANT_TO_START_WATCHING,
INAPPROPRIATE_CONTENT_AGE_CONFIRMATION,
I_HAVE_A_PROMOCODE,
HERE_YOU_CAN_ACTIVATE_A_PROMOCODE,
PROMO_CODE_PAGE_HEADER,
PROMO_VOD_DISCLAMER,
ABOUT_THE_SERIES,
ABOUT_THE_FILM,
THE_TERMS_OF_THE_USER_AGREEMENT,
BY_CLICKING_THE_PAYMENT_BUTTON,
I_SEE,
YOUR_PROMO_CODE_IS_ACTIVATED,
CONGRATULATION,
DO_YOU_REALLY_WANT_TO_DELETE,
REGISTRATION_AT_THE_SPECIFIED_EMAIL_ADDRESS,
REMOVE_DEVICE,
CONFIRM_AGREE_TO_THE_TERMS,
qwerty,
PAYMENT_SERVICE_FEE_INFORMATION,
PROMO_CODE_ERROR,
TESTING_PHRAZE,
MENU_ABOUT,
LANGUAGES,
SPBTV,
MENU_SETTINGS,
MENU_EPG,
FILTER_BUTTON_RESET,
MENU_SERIES,
MENU_MY_SPBTV,
FILTER_BUTTON_SUBMIT,
MENU_ON_AIR,
MENU_FOR_KIDS,
MENU_INFO,
MENU_MOVIES,
MENU_CHANNELS,
NAV_LEGEND_CLOSE_FILTER,
NAV_LEGEND_SHOW_FILTER,
USER_INFO,
NAV_LEGEND_HIDE_MENU,
NAV_LEGEND_SHOW_MENU,
GENRES,
EPG_IS_NOT_AVAILABLE,
EPG_LOADING,
SEARCH_ALL_RESULTS,
TYPE_TO_SEARCH,
LOADING,
CHANNEL_INFO,
TV_GUIDE,
SIGN_IN,
NAVIGATION_BACK,
PRIVACY_POLICY,
TERMS_OF_USE,
VIDEO_PLAY,
SIGN_IN_TEXT,
PRODUCT_VERSION,
LOGOUT,
NAV_LEGEND_ON_AIR,
MENU_PROFILE,
DEVICE_ID,
NO_EPG,
PHONE,
PLAYER_ERROR_NOT_AUTH,
PAGE_DOWN,
PLAYER_ERROR_UNAVAILABLE_COUNTRY,
PLAYER_ERROR_NOT_PURCHASED,
PAGE_UP,
PLAYER_ERROR_MAX_CONCURRENT_VIEWS,
CLOSE,
PLAYER_ERROR_STREAM_NOT_FOUND,
EXIT,
GEORESTRICTION_SUBTEXT,
NAV_LEGEND_PLAY_CHANNEL,
GEORESTRICTION_TEXT,
ADS_SKIP,
SEARCH_NOT_FOUND,
VIDEO_PLAY_CONTINUE,
NEW_SEARCH,
YEAR_BIRTH,
BUTTON_RETRY,
CONFIRM_EXIT_TITLE,
CONFIRM_EXIT_TEXT,
NO,
YES,
ADS,
PLAYER_ERROR_PLAYBACK,
FULL_DESCRIPTION,
HIDE_DESCRIPTION,
TRAILER,
EDIT_PROFILE,
SAVE,
YOU_GENDER,
YEAR_LABEL_FAILED,
PLAYER_ERROR_COMMON_STREAM_REQUEST_FAILED,
USER_DEFAULT_NAME,
INTERNET_CONECTION_ERROR,
INAPPROPRIATE_CONTENT_AGE_RESTRICTION,
RELOAD,
CRASH_CLIENT_IS_OBSOLETE,
CRASH_SOMETHING_GOES_WRONG,
LIVE,
START_SPEEDTEST,
RELOAD_SPEEDTEST,
STOP_SPEEDTEST,
LOCATION,
COUNTRY,
SPEEDTEST_READY,
SPEEDTEST_PROCESS,
SERVER_TIME,
SPEEDTEST_INITIALIZE,
YOUR_TIME,
SPEEDTEST_END,
TEST_ID,
MENU_MY_DEVICES,
ENABLE,
DISABLE,
MENU_PERSONAL_INFO,
MENU_PURCHASE_HISTORY,
MENU_SUBSCRIPTIONS,
MENU_MY_CARD,
SHOW_MORE,
FREE,
DAY,
MONTH,
YEAR,
SUBSCRIPTIONS_EMPTY,
PAYMENT_HISTORY_EMPTY,
CARD_NOT_FOUND,
DEVICES_NOT_FOUND,
UNBIND_CARD,
PAY_NOW,
SELECT_PLAN,
CHANGE_LANGUAGE,
SAVE_LANGUAGE_NOTCE,
PLAYER_ERROR_DRM_UNSUPPORTED,
GENDER_MALE,
GENDER_FEMALE,
RECOMMENDED,
PROFILES_TAB_DEVICES,
PROFILES_LINK_PROFILE,
PROFILES_EDIT_PROFILE,
PROFILES_ADD_PROFILE,
PROFILES_KIDS_CHECKER,
PROFILES_MANAGE,
PROFILES_WHO_IS_WATHCING,
PROFILES_ALLOWED_CONTENT_SELECT_VALUE,
PROFILES_DONE,
CONTINUE_WATCHING,
num_season,
CAN_WATCH_VIDEO_ONLY_ON,
AUTH_BY_CODE,
NAV_LEGEND_FILTER,
NAV_LEGEND_MENU,
AUTH_BY_LOGIN_AND_PASSWORD,
AUTH_SUBMIT_LOGIN_AND_PASSWORD,
AUTH_ENTER_PASSWORD,
AUTH_BY_LOGIN_FAILED_ERROR_REQUISITES,
ERROR_HAS_OCCURRED,
WATCH_LIST_REMOVE,
WATCH_LIST_ADD,
AUTH_ENTER_PASSWORD_TO_LOGIN,
AUTH_ENTER_LOGIN,
HEADER_WATCH_LATER,
HEADER_FAVORITE_CHANNELS,
FAVORITE_CHANNEL_DELETE,
FAVORITE_CHANNEL_ADD,
AUTH_SELECT_AUTH_METHOD,
SUBSCRIPTION_HOLD_MESSAGE,
ACCOUNT_TAB_SECURITY,
ACCOUNT_TAB_PAYMENT_HISTORY,
ACCOUNT_TAB_SUBSCRIPTIONS,
ACCOUNT_TAB_CARDS,
ACCOUNT_TAB_SETTINGS,
ACCOUNT_LABEL_CHANGE_PASSWORD,
ACCOUNT_LABEL_PARENTAL_CONTROL,
ACCOUNT_LABEL_EMAIL,
ACCOUNT_LABEL_STREET,
ACCOUNT_LABEL_POSTCODE,
ACCOUNT_LABEL_CITY,
ACCOUNT_LABEL_COUNTRY,
ACCOUNT,
MARKER_FEATURED,
MARKER_FREE,
MARKER_POPULAR,
MARKER_NEW,
PLAYER_ERROR_NETWORK,
ENTER_PROMO_CODE,
PROMO_CODE_ENTER_BUTTON,
PROMO_CODE_PAGE_DESCRIPTION,
PROMO_CODE_ERROR_TRY_IT_ON_A_DIFFERENT_PLATFORM,
PROMO_CODE_ALREADY_SUBSCRIBED,
PRICE_PERIOD,
PROMO_CODE_FREE_PERIOD_TEXT,
PROMO_CODE_FREE_PERIOD_TEXT_MANY,
PROMO_CODE_GIVE_DISCOUNT_FOR_ONE_OF,
PROMO_CODE_GIVE_DISCOUNT,
NO_INTERNET_CONNECTION_AVAILABLE,
PARENTAL_CONTROL_DESCRIPTION,
PARENTAL_CONTROL,
PIN_CODE_DESCRIPTION,
ADS_FROM_TO,
TOO_MANY_REQUESTS,
CONTINUE,
PASSWORD_NOT_EQUALS,
CONFIRM_NEW_PASSWORD,
CREATE_NEW_PASSWORD,
WRONG_PASSWORD,
ENTER_PASSWORD,
ACCOUNT_LABEL_PASSWORD,
MARKER_SUBSCRIPTION,
MARKER_SOON,
AUTH_SEND_CODE,
ON_AIR,
OPEN_DEEPLINK,
ADS_SKIP_AFTER,
CONFIRM_PHONE_BY_CALL,
NOT_CONFIRMED,
USERNAME_ALREADY_IN_USE,
ERROR_REGISTRATION_PASSWORD_LENGTH,
ENTER_REGISTRATION_NAME,
ENTER_REGISTRATION_PASSWORD,
REGISTRATION,
PASSWORD_HAS_SENDED,
TODAY,
EPISODES_OF_PROGRAM,
EPISODES_OF_SERIALS,
SUPPORT,
APPLICATION_LAUNCHING_ERROR,
NAV_TO_TOP,
MENU_CATCHUP,
ROLE_PRODUCER,
ROLE_EDITOR,
ROLE_COMPOSER,
ROLE_WRITER,
ROLE_PRESENTER,
ROLE_DIRECTOR,
ROLE_ACTOR,
CERTIFICATION_RATING,
EVENT_AVAILABLE_ENTRIES,
EVENT_NEAREST_BROADCAST,
EVENT_DESCRIPTION,
WHAT_IS_AN_SUBSCRIPTION,
TRY_FOR_FREE,
TO,
FROM,
SEARCH_BY_DATE,
ALREADY_AVAILABLE,
PRODUCT_SELECT_PLAN_BUTTON,
trial,
ETHER_FROM,
AVAILABLE_UNTIL,
TRIAL_FREE_PERIOD_TEXT,
SEE_ALL_ITEMS,
PAGE_LOADING,
RECOMMENDED_FOR_YOU,
SIGN_IN_TO_PURCHASE,
UNSUBSCRIBE,
SUBSCRIPTION_NO_ACCESS_GRANTED,
PURCHASE_DESCRIPTION,
SUBSCRIPTION_CANCELLATION_DESCRIPTION,
CANCEL_PENDING,
NEXT_BILLING_DATE,
EXPIRES_ON_DATE,
PAYMENT_WAS_SUCCESSFUL,
PAYMENT_IN_PROGRESS,
SUBSCRIPTION_IS_NOT_CANCELABLE,
ALREADY_SUBSCRIBED,
CHOICE_SUBSCRIBTION,
MOBILE_PAYMENT,
PAYMENT_METHOD,
ARE_YOU_WANT_ANSUBSCRIBE,
MY_SUBCRIPTIONS,
AVAILABLE_SUBSCRIPTIONS,
WILL_BE_DEBITED_FROM_YOUR_ACCOUNT,
NO_AVAILABLE_SUBSCRIPTIONS,
NON_TRIAL_TEXT_CONFIRMATION,
SUBSCRIPTION_WAS_CANCELLED,
SUBSCRIPTION_CHOOSING,
SUBSCRIPTION_CANCELLING,
INVALID_EMAIL,
CHOICE_OF_DATE,
EST_DESCRIPTION,
TVOD_DESCRIPTION,
RENT_TITLE,
PURCHASE_TITLE,
RENT_ACTION,
BUY_ACTION,
OR,
PAYMENT_TITLE,
NO_PURCHASES,
EXPIRED_PURCHASES,
ACTIVE_PURCHASES,
PURCHASES,
ACTIVE_RENT_AVAILABLE,
WATCHING_OPTIONS_SELECTION,
TVOD_PURCHASED,
EST_PURCHASED,
SUBSCRIPTION_ITEM_DESCRIPTION,
ADS_ANNOUNCEMENT,
RENEWAL_PROMO_ATTENTION,
SUBCRIBED_TRIAL_PROMO,
PROMO_DISCOUNT_DESCRIPTION,
PROMO_DISCOUNT_ON_PLAN_DESCRIPTION,
YOUR_PROMO_CODE,
PROMO_CODE_ACTIVATED,
SELECT_SUBSCRIPTION_APPLY_PROMO,
ENTER_ANOTHER_PROMO_CODE,
PROMO_AUTORENEW_AGREEMENT,
ACTIVATE_PROMOCODE,
SELECT_PAYMENT_PRODUCT_SUBTITLE,
PROMOCODE_ACTIVATION,
PROMOCODE,
WRONG_PROMO_CODE_ERROR,
TRIAL_PROMO_DESCRIPTION,
CHOOSE_TRIAL_PROMO_PRODUCT,
DISOUNT_PROMO_CODE_DURATION,
PROMO_LABEL,
DURATION_IN_UNIT,
DISABLE_ADS,
CONFLICT_SUBSCRIPTION_WARNING,
INVALID_EMAIL_AND_PASSWORD,
INVALID_FORMAT_PHONE_NUMBER_OR_EMAIL,
INVALID_FORMAT_EMAIL,
INVALID_FORMAT_PHONE_NUMBER,
ERROR_UNKNOWN_EMAIL,
ERROR_UNKNOWN_PHONE_NUMBER,
ERROR_UNKNOWN_PHONE_NUMBER_OR_EMAIL,
PHONE_NUMBER,
PHONE_NUMBER_OR_EMAIL,
AUTH_BY_USERNAME,
AUTH_BY_PHONE_NUMBER,
AUTH_BY_EMAIL,
PLAYER_ERROR_NOT_PURCHASED_SUBTEXT,
ACCEPT,
POLICY_ANNOUNCEMENT,
VERIFY_PHONE,
VERIFY_EMAIL,
ACCOUNT_IS_BLOCKED,
ACCOUNT_LABEL_USERNAME,
SETUP,
ACCOUNT_LABEL_NAME,
REGISTRATION_USED_TO_LOGIN,
REGISTRATION_ENTER_PHONE_OR_EMAIL,
REGISTRATION_CREATE_PASSWORD_DESCRIPTION,
REGISTRATION_CREATE_PASSWORD,
NOT_REGISTERED_YET,
EMAIL_ALREADY_IN_USE,
DATA_IS_INCORRECT,
FORGOT_PASSWORD,
LOGIN,
REGISTER_NOW,
PERSONAL_DATA_IS_SAFE,
REGISTRATION_NOTIFY_ME_ABOUT_NEWS_PROMO,
REGISTRATION_CONFIRM_EMAIL,
REGISTRATION_CONFIRM_PHONE,
RESEND_SECURITY_CODE,
RESEND_SECURITY_CODE_AFTER,
SECURITY_CODE,
INVALID_PASSWORD_FORMAT,
SEASON_NUM,
EPISODE_NUM,
STREAM_IS_NOT_AVAILABLE_ON_DEVICE,
REGISTRATION_TEXT_REGISTRATE,
REGISTRATION_TEXT_SIGN_IN,
REGISTRATION_TEXT_HEADING,
SAFETY,
PAYMENTS,
MY_VIDEO_LIBRARY,
HELP,
FAVORITES,
REGISTRATION_PASSWORD_RESTRICTION,
I_LOOK,
BROWSING_HISTORY,
PREMIERES,
ABOUT_CHANNEL,
EDIT,
ENABLE_AUTOMATIC_LOGIN,
AUTHORIZATION,
ACCOUNT_SECURITY_ENTER_PIN_CODE,
ACCOUNT_SECURITY_ENTER_PIN_TEXT,
ACCOUNT_SECURITY_CONFIRM_PIN_TITLE,
ACCOUNT_SECURITY_CREATE_PIN_TITLE,
RESET_PIN_CODE_TEXT,
RESET_PIN_CODE_TITLE,
FORGOT_PIN,
ACCOUNT_SECURITY_NEW_PIN_TEXT,
AUTH_SCREEN_CONFIRM_CALL_DESCR,
AUTH_SCREEN_CONFIRM_EMAIL_DESCR,
AUTH_SCREEN_CONFIRM_PHONE_DESCR,
AUTH_SCREEN_FORGOT_PASS_CALL_FOOTER,
AUTH_SCREEN_CONFIRM_CALL_FOOTER,
AUTH_SCREEN_RESET_PASS_HEADER,
AUTH_SCREEN_FORGOT_PASS_NEW,
AUTH_SCREEN_FORGOT_PASS_CALL_DESCR,
AUTH_SCREEN_FORGOT_PASS_PHONE_DESCR,
AUTH_SCREEN_FORGOT_PASS_EMAIL_DESCR,
AUTH_SCREEN_CREDENTIALS_DESCR,
SERIES_IN_SUBSCRIPTION,
MOVIES_IN_SUBSCRIPTION,
CHANNELS_IN_SUBSCRIPTION,
FIRST,
PROMO_CODE_NO_PRODUCTS,
ALL,
SEARCH_SPECIFY_REQUEST,
SEARCH_NO_RESULTS,
FAQ_CONTACTS_SECTION_TITLE,
FAQ_PAYMENTS_SECTION_TITLE,
FAQ_CONTENT_SECTION_TITLE,
FAQ_MAIN_SECTION_TITLE,
PLAYER_POPUP_SUBTITLE_LANGUAGE,
PLAYER_POPUP_AUDIO_LANGUAGE,
PLAYER_POPUP_VIDEO_QUALITY,
PLAYER_POPUP_VIDEO_AUTO_QUALITY,
NEXT_EPISODE,
CANCEL,
PROFILE_TYPE_ADULT,
PROFILE_TYPE_KIDS,
PROFILE_TYPE,
PROFILE_USER_NAME,
PROFILE_EDITOR_NAME_MIN_LENGTH_ERROR,
AGREEMENT_BLOCK_POLICY_TEXT,
AGREEMENT_BLOCK_AND_TEXT,
AGREEMENT_BLOCK_TERMS_TEXT,
AGREEMENT_BLOCK_BEGINNING_TEXT,
RESEND_PASSWORD_COUNTER_TEXT,
PASSWORD_RECOVERY_SMS_OR_EMAIL_DESCR,
ASYNC_SUBSRIPTIONS,
PROMO_SELECT_SUBSCRIPTION_HEADER_TEXT,
PROMO_NAVIGATE_TO_START_PAGE,
NAVIGATE_TO_HOME,
MENU_PROFILES,
MENU_PERSONAL_DATA,
PROFILES_SELECT_PROFILE_TEXT,
PROFILES_SELECTED_PROFILE_TEXT,
PROFILES_MAIN_PROFILE,
ON_AIR_DATE,
RECORD_DATE,
SIGN_IN_IMPLICIT_DESCR,
SIGN_IN_IMPLICIT_HEADER,
REGISTRATION_ENTER_PHONE,
PROFILES_ADD_TEXT,
PASSWORD_HAS_SENDED_WITH_PHONE,
REGISTER_OR_SIGN_IN_IMPLICIT,
TRIAL_PERIOD,
REGISTRATION_ENTER_EMAIL,
SUBSCRIBTION_CANCELLATION_DISCLAMER,
IMPLICIT_AGREEMENT_BLOCK_BEGINNING_TEXT,
EDIT_MODE_DESCRIPTION,
CANCEL_SELECTION,
DELETE_ALL_ITEMS,
DELETE_COUNT,
AUDIO_SHOWS_IN_SUBSCRIPTION,
AUDIO_SHOWS,
AUTH_SCREEN_FORGOT_PASS_PHONE_OR_EMAIL,
MATCH_PAGE_STADIUM,
PASSWORD_HAS_SENDED_WITH_EMAIL,
RESTORE_INVALID_CODE,
LOGIN_OR_REGISTER,
PERSONAL_UNAUTHORIZED_FAVORITES,
PERSONAL_UNAUTHORIZED_WATCHING_HISTORY,
PERSONAL_UNAUTHORIZED_PURCHASES,
PERSONAL_UNAUTHORIZED_CHANNELS,
PERSONAL_UNAUTHORIZED_CONTINUE_WATCHING,
PROMO_CONGRATULATIONS,
PROMO_VOD_DISCOUNT_SUCCESS,
PROMO_VOD_DISCOUNT_DISCLAMER,
BY_WITH_PROMO,
FOR_PRICE,
BY_SUBSCRIPTION,
PLEASE_COMPLETE_PURCHASE,
PAYMENT_INSTRUCTIONS_MOBILE,
CONFIRM,
AMEDIATEKA_TERMS,
START_TERMS,
RULES_CONFIRMATION_TEXT,
SCREEN_RESOLUTION_INFO,
COUNTRIES,
CAST_MEMBERS_EDITOR,
CAST_MEMBERS_WRITER,
CAST_MEMBERS_PRODUCER,
CAST_MEMBERS_ACTOR,
EXTERNAL_CATALOG,
RIGHT_HOLDER,
STUDIOS,
DURATION,
PRODUCTION_YEAR,
GO_TO_VIEW,
SEARCH_SPECIFY_REQUEST_EXPAND,
USER_STATS,
VIEW_SUMMARY,
INFORMATION,
VIEW_SUMMARY_PROGRAM_EVENT,
VIEW_SUMMARY_EPISODE,
VIEW_SUMMARY_CHANNEL,
VIEW_SUMMARY_PART,
VIEW_SUMMARY_MOVIE,
VIEW_SUMMARY_S,
VIEW_SUMMARY_M,
VIEW_SUMMARY_H,
TV_PROGRAMS,
MOVIES_AND_SERIES,
ACCESS_REASON_RESTRICTED_BY_GEO,
NO_CONTENT_WITH_THIS_PERSON
}