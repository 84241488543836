import * as React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import PromocodeActivation, { PromocodeActivationPages } from '~components/PromocodeActivation';


const PromocodeActivationScreen: React.FC = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const subscriptionsListUrl = `${path}/subscr-list`;
  const confirmationScreenUrl = `${path}/confirm`;
  const rentPlanScreenUrl = `${path}/rent-plan`;
  const rentPlanConfirmScreenUrl = `${path}/rent-plan-confirm/:resourceType/:id`;
  const successScreenUrl = `${path}/success`;

  const onReset = () => {
    history.replace(path);
  }

  return (
    <Switch>
      <Route exact path={ `${path}` }>
        <PromocodeActivation 
          page={ PromocodeActivationPages.PromocodeInput }
          onSuccess={ () => {
            history.push({
              pathname: subscriptionsListUrl,
              search: history.location.search
          }) } }
        />
      </Route>
      <Route exact path={ subscriptionsListUrl }>
        <PromocodeActivation 
          page={ PromocodeActivationPages.ProductsList }
          onSuccess={ (params) => {
            history.push({
              pathname: params?.isSingleProduct
                ? confirmationScreenUrl
                : rentPlanScreenUrl,
              search: history.location.search
          }) } }
          onReset={ onReset }
        />
      </Route>
      <Route exact path={ confirmationScreenUrl }>
        <PromocodeActivation
          page={ PromocodeActivationPages.SubscriptionConfirm }
          onSuccess={ () => history.replace({
            pathname: successScreenUrl, 
            search: history.location.search
          }) }
          onReset={ onReset }
        />
      </Route>
      <Route exact path={ rentPlanScreenUrl }>
        <PromocodeActivation
          page={ PromocodeActivationPages.RentPlan }
          onSuccess={ () => history.push({
            pathname: rentPlanConfirmScreenUrl, 
            search: history.location.search
          }) }
          onReset={ onReset }
        />
      </Route>
      <Route exact path={ rentPlanConfirmScreenUrl }>
        <PromocodeActivation 
          page={ PromocodeActivationPages.RentPlanConfirm }
          onSuccess={ () => history.replace({
            pathname: successScreenUrl, 
            search: history.location.search
          }) }
          onReset={ onReset }
        />
      </Route>
      <Route path={ successScreenUrl }>
        <PromocodeActivation 
          page={ PromocodeActivationPages.SuccessPage }
          onReset={ onReset }
        />
      </Route>
    </Switch>
  );
};

export { PromocodeActivationScreen };

export default PromocodeActivationScreen;
