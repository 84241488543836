import focuser from '@focuser';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getMeta } from '~components/MediaItemPage/Description';
import SceneMediaItemDescriptionText from '~components/MediaItemPage/Description/description-text';
import SceneMediaItemMeta from '~components/MediaItemPage/Description/meta';
import DescriptionWrapper from '~components/MediaItemPage/Description/wrapper';
import { useMenu } from '~components/Provider/Menu';
import { usePlayerState } from '~components/Provider/Player';
import useLocationSearch from '~hooks/useLocationSearch';
import Movie from '~typings/Movie';
import Series from '~typings/Series';

import ResourceBarItems from './ResourceBarItem';
import * as styles from './styles.module.css';


type Props = Readonly<{
  item: Movie | Series;
}>;


const PromocodeResourcePage: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const menu = useMenu();
  const playerState = usePlayerState();
  const allowNavigation = (!useLocationSearch()?.player && !menu.isPopupOpened && !playerState.item);
  const [focusedBlock, setFocusedBlock] = React.useState<'description' | 'barItem'>('description');
  const matchPage = useRouteMatch('/page/:id');
  const descriptionClick = () => {
    history.push(`${
      matchPage ? `${ matchPage.url }/` : '/'
    }media-item/${ props.item.object }/${ props.item.id }/details`);
  };


  return (
    <focuser.FocusableBlock
      noNeedForceFocus
      className={ styles.container }
      isFocused={ allowNavigation }
      // isPointerUpAvailable= { focusedBlock === 'barItem' }
      // isPointerDownAvailable= { focusedBlock === 'description' }
    >
        <div className={ styles.linesWrapper }>
          <focuser.FocusableBlock
            className={ styles.descriptionWrapper }
            isFocused={ focusedBlock === 'description' }
            onKeyDown={ () => setFocusedBlock('barItem') }
            onForceFocus={ () => setFocusedBlock('description') }
            isLastBlock
            emitForceFocusOnHover
            onClick={ descriptionClick }
          >
            {({ isFocused }) => (
              <DescriptionWrapper
                isFocused={ isFocused }
              >
                <SceneMediaItemMeta meta={ getMeta(props.item) } />
                <SceneMediaItemDescriptionText description={ props.item.description } />
              </DescriptionWrapper>
            )}
          </focuser.FocusableBlock>
          <focuser.FocusableBlock
            className={ styles.mediaItemBarWrapper }
            isFocused={ focusedBlock === 'barItem' }
            onKeyUp={ () => setFocusedBlock('description') }
            onForceFocus={ () => setFocusedBlock('barItem') }
          >
            <ResourceBarItems
              mediaItem={ props.item }
            />
          </focuser.FocusableBlock>
        </div>

    </focuser.FocusableBlock>
  );
};


export default React.memo(PromocodeResourcePage);
