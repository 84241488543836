import { useEffect, useState } from "react";

import { useLastViewedAudioPart } from "~hooks/fetch/useLastViewedPart/useLastViewedPart";
import AudioShow from "~typings/AudioShow";



type State = {
  status: 'initial'
} | {
  status: 'waiting-for-last-part'
} | {
  status: 'ready';
  partIdx: number;
  isNeedToShowInitialFocus: boolean;
}

export const useInitialFocusPart = (audioshow: AudioShow, selectedPartID?: string) => {

  const [state, setState] = useState<State>({ status: 'initial' });

  const { nextPart: lastViewedPart, query } = useLastViewedAudioPart(
    state.status === 'waiting-for-last-part' ? audioshow.id : undefined,
  );

  useEffect(() => {
    /// Обработка начального состояния
    if(state.status !== 'initial'){
      return;
    }

    if(selectedPartID) {
      const { partIdx, isFound } = getIndexesOfPart(audioshow, selectedPartID);
      if(isFound) {
        setState({ status: 'ready', partIdx: partIdx, isNeedToShowInitialFocus: true });
        return;
      }
    }

    setState({ status: 'waiting-for-last-part' });
  }, [selectedPartID, audioshow, state.status]);


  useEffect(() => {
    if(state.status !== 'waiting-for-last-part'){
      return;
    }

    if(query.status === 'loading') {
      return;
    }

    if(query.status === 'idle' || query.status === 'error') {
      setState({ status: 'ready', partIdx: 0, isNeedToShowInitialFocus: false });
      return;
    }

    if(lastViewedPart) {
      const { partIdx, isFound } = getIndexesOfPart(audioshow, lastViewedPart.id);
      if(isFound) {
        setState({ status: 'ready', partIdx: partIdx, isNeedToShowInitialFocus: true });
        return;
      }
    }
    setState({ status: 'ready', partIdx: 0, isNeedToShowInitialFocus: false });
  }, [lastViewedPart, audioshow, state.status, query.status ]);

  return state.status === 'ready' ? state : undefined;
}

const getIndexesOfPart = (audioshow: AudioShow, selectedPartID: string) => {
  let partIdx = 0;
  let isFound = false;

  for (let i = 0; i < audioshow.parts.length; i++) {
    if (audioshow.parts[i].id === selectedPartID) {
      isFound = true;
      partIdx = i;
      break;
    }
    if (isFound) {
      break;
    }
  }

  return {
    partIdx,
    isFound,
  };
};
