import { useMutation } from 'react-query';

import { useClient } from '~global';

const receiveDeviceCode = async (client) => {
  const {
    data: { code },
  } = await client.post('/v1/device/authentication_codes');

  return code;
};

const useDeviceCodeMutation = () => {
  const client = useClient();
  return useMutation(() => receiveDeviceCode(client), { retry: 2, retryDelay: 500 })
}

export { useDeviceCodeMutation };
