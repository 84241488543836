
import Money from '~typings/Money';
import RentPlan from '~typings/RentPlan';

import { AVAILABLE_PAYMENT_METHODS } from './constants';
import { PriceWithPeriod } from './getMinPrice';

const getRentMinPrice = (rents: RentPlan[]): PriceWithPeriod | null => {
  if (!rents.find(rent => rent.phases)) { return null; }

  let priceWithPeriod: PriceWithPeriod | null = null;

  rents.forEach(({ phases, access_period }) => {
    const everGreenPhases = phases.filter(phase => phase.type === 'evergreen');

    everGreenPhases.forEach(({ billing }) => {
      billing.prices.forEach(({ money }) => {
        if (!priceWithPeriod || priceWithPeriod.money.value > money.value) {
          priceWithPeriod = ({ money, period: access_period });
        }
      });
    });
  });

  return priceWithPeriod;
};


export const getAvailableMinPriceFromRents = (rents: RentPlan[]): { money: Money, pricesCount: number} | null => {
  const everGreenPhases = rents
    .flatMap((rent) => rent.phases || [])
    .filter((phase) => phase.type === 'evergreen');

  const prices = everGreenPhases
    .flatMap((phase) => phase.billing.prices)
    .filter((price) => AVAILABLE_PAYMENT_METHODS.includes(price.payment_method_type));

  const minPrice = prices.reduce(
    (acc, price) => {
      if (!acc || acc.value > price.money.value) {
        return price.money;
      }
      return acc;
    },
    null as Money | null,
  );

  if(!minPrice){
    return null;
  }

  return {
    money: minPrice,
    pricesCount: prices.length
  };
};


export default getRentMinPrice;
