import UAParser from 'ua-parser-js';

import Base from '../base';
import { guid } from '../util';
import { LOCAL_STORAGE_KEY_DEVICE_ID } from '../util/constants';

import keyCodes from './key-codes';

export default class DesktopPlatform extends Base {
  constructor(params) {
    super(params);

    const parser = new UAParser();
    const {
      engine: { name: model },
      os: { name: osName, version: osVersion },
      device: { vendor },
    } = parser.getResult();

    const alreadyStoredDeviceId = window.localStorage.getItem(LOCAL_STORAGE_KEY_DEVICE_ID);
    const deviceId = alreadyStoredDeviceId || guid();
    if (!alreadyStoredDeviceId) {
      window.localStorage.setItem(LOCAL_STORAGE_KEY_DEVICE_ID, deviceId);
    }

    this.deviceInfo = {
      osName,
      osVersion,
      model,
      vendor,
      deviceId,
    };
  }

  /**
   * @override
   */
  getKeyCodes() {
    return keyCodes;
  }

  /**
   * @override
   */
  getDeviceInfo() {
    return this.deviceInfo;
  }


  /**
   * @override
   */
  getDeviceFriendlyName() {
    if (!this.deviceInfo.osName) {
      return 'Desktop Smart TV';
    }

    return `Desktop ${this.deviceInfo.osName}`;
  }
}
