enum Role {
  Actor = 'actor',
  Director = 'director',
  Presenter = 'presenter',
  Writer = 'writer',
  Composer = 'composer',
  Editor = 'editor',
  Producer = 'producer',
}

export default Role;
