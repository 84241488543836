import * as React from 'react';

import { spinner } from '../../../../html/common';
import * as styles from './styles.module.css';


const LightLoader: React.FC = () => (
  <div
    className={ styles.lightLoaderWrapper }
  >
    <img
      alt="Loader"
      src={ spinner }
      className={ styles.loader }
    />
  </div>
);


export default LightLoader;
