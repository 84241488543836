import * as React from 'react';

import { DataItem, MediaItemType } from '~typings/DataItem';
import MediaItemDimension from '~typings/MediaItemDimension';
import ResponseMany from '~typings/ResponseMany';

import { getVars } from './component.helpers';
import TiledViewScroll from './component.scrollable';
import TiledItems from './items';


export type Props = Readonly<{
  items: ResponseMany<MediaItemType[]> | null | undefined;
  focusedItemIndex: number;
  dimension: MediaItemDimension;
  allowNavigation: boolean;
  isNeedJustHideChildren?: boolean;
  withCurrentEvent?: boolean;
  withProgress?: boolean;
  showAsSeries?: boolean;
  forceOnClick?: boolean;
  editMode?: boolean;
  slidesCount?: [number, number];
  selectedItems?: number[];
  onClick?: (item?: DataItem) => void;
  disableText?: boolean | undefined;
  showOnlyFetchedItems?: boolean;
}>;


const TiledView = (props: Props) => {
  const { isNeedJustHideChildren, ...restProps } = props;
  const { itemHeight, lineByItemIndex } = React.useMemo(() => (
    getVars(props.dimension, props.slidesCount)
  ), [props.dimension, props.slidesCount]);
  const focusedLine = lineByItemIndex(props.focusedItemIndex);


  return (
    <div style={ {
      visibility: !isNeedJustHideChildren ? 'visible' : 'hidden'} }>
      <TiledViewScroll focusedLine={ focusedLine } step={ itemHeight }>
        <TiledItems { ...restProps } />
      </TiledViewScroll>
    </div>
  );
};


export default TiledView;
