import { isArray } from 'lodash';
import * as React from 'react';

import { useScene } from '~components/Provider/Scene';
import SceneItem from '~components/Scene/typings';
import { useConfig } from '~global';
import useCachedBlocksItems from '~hooks/useBlocks/useCachedBlocksItems';
import usePrevious from '~hooks/usePrevious';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import useNavigation from '~stores/Navigation';
import { getSizeIfAllDownloaded } from '~stores/Navigation/utils';
import { BlockType } from '~typings/Block';
import Channel from '~typings/Channel';
import { MediaItemType } from '~typings/DataItem';
import ItemObject from '~typings/ItemObject';
import Page from '~typings/Page';


type Props = Readonly<{
  page: Page;
  isFetchingDone: boolean;
  focusedBlockID: string | null;
  focusedItemIndex: number;
}>;


const SceneManagerByIndex = (props: Props) => {
  const scene = useScene();
  const { smartTV: { sliderSize } } = useConfig();
  const [max, range] = sliderSize || EMPTY_ARRAY;
  const focusedBlock = useNavigation(state => state.getFocused());
  const blockInFocus = props.page.blocks?.find(block => block.id === props.focusedBlockID);
  const { itemsByBlockID } = useCachedBlocksItems(props.page);
  const focusedBlockTotal = props.focusedBlockID ?
    (itemsByBlockID?.[props.focusedBlockID]?.meta?.pagination?.total ?? 0)
    : 0;
  const total = sliderSize ? getSizeIfAllDownloaded(focusedBlockTotal, max, range) ?? 0 : focusedBlockTotal;
  const blockWithSeeAll = blockInFocus
    && [
      BlockType.CollectionBlock,
      BlockType.CardCollectionBlock,
      BlockType.ContinueWatchingBlock,
      BlockType.FavoriteChannelsBlock,
      BlockType.FavoriteMoviesBlock,
      BlockType.RecommendationsBlock,
    ].includes(blockInFocus.object)
    && (focusedBlockTotal !== total);
  const prevPageID = usePrevious(props.page.id);
  const isCurrentProgramLineBlock = React.useMemo(
    () => props.page.blocks
      ?.find(
        ({ object }) => (object === BlockType.CurrentProgramLineBlock)
      )?.id === props.focusedBlockID,
    [props.page.blocks, props.focusedBlockID],
  );
  const focused: SceneItem | null = React.useMemo(() => {
    if (props.focusedBlockID  && props.isFetchingDone) {
      const data = itemsByBlockID[props.focusedBlockID]?.data || null;

      if (focusedBlock !== null  && props.focusedBlockID && data) {
        if (isArray(itemsByBlockID[props.focusedBlockID]?.data)) {
          if (blockWithSeeAll && (props.focusedItemIndex >= max)) { return null; }

          const res = (data as MediaItemType[])[props.focusedItemIndex] || null;

          if (
            res?.object === ItemObject.MiddleBanner
            || res?.object === ItemObject.BigBanner
          ) { return null; }

          if (
            res?.object === ItemObject.Favorite
            || res?.object === ItemObject.WatchProgressWithResource
          ) {
            return res.card || res.resource;
          }

          return res as SceneItem | null;
        } else {
          return data as Channel;
        }
      }
    }

    return null;
  }, [
    blockWithSeeAll,
    max,
    props.focusedItemIndex,
    props.isFetchingDone,
    isCurrentProgramLineBlock,
    props.focusedBlockID && itemsByBlockID[props.focusedBlockID]?.data,
  ]);

  React.useEffect(() => {
      scene.changeSceneMediaItem(focused, !isCurrentProgramLineBlock, (prevPageID !== props.page.id));
  }, [
    focused,
    isCurrentProgramLineBlock,
    (prevPageID !== props.page.id),
  ]);

  return null;
};


export default React.memo(SceneManagerByIndex);
