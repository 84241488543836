import { FocuserKeyEventDetail } from "@focuser/events";
import React, { useState } from "react";

import { PinCodeManager, ProcedureType } from "~components/PinCodeManager/PinCodeManager";
import PinCodePopup from "~components/PinCodePopup";
import usePlayerStateActions from "~components/Provider/Player/actions";
import { ConfirmationStatus } from "~components/Provider/Player/component.helpers";
import { useAccountMutation } from "~hooks/fetch/useAccount";

import { PinOrParentalWarning$ } from "../windows/PinOrParentalWarning";
import { SuccessWindow$ } from "../windows/Success";

type Props = {
  from: 'parental' | 'pin-code'
}

type State = "ask_1" | "enter_2" | "success_3"

const AskToSetPinOrParental = ({ from }: Props) => {
  const [stage, setStage] = useState<State>("ask_1")
  const { flush, setConfirmationRequired } = usePlayerStateActions();
  const { mutateAsync } = useAccountMutation();


  //stage 1
  const stage1_handleClickLeft = () => {
    setStage("enter_2")
  }

  const stage1_handleClickRight = async () => {
    setConfirmationRequired(ConfirmationStatus.WithoutConfirmation)
    mutateAsync({
        parental_control_flow_passed: true,
      }
    )

  }

  const stage_1handleBack = (event: FocuserKeyEventDetail) => {
    flush()
    event.stop()
    event.stopNativeEvent()
  }
  //

  //stage 2
  const stage2_handleForward = () => {
    setStage("success_3")
    mutateAsync(
      {
        parental_control: true,
        parental_control_flow_passed: true,
      }
    )
  }

  const stage2_handleBack = () => {
    setStage("ask_1")
  }
  //

  //stage3
  const stage3_handleForward = () => {
    setConfirmationRequired(ConfirmationStatus.WithoutConfirmation)
  }
  //

  if (stage === "ask_1") {
    return (
      <PinOrParentalWarning$
        from={ from }
        handleActivatePinOrParental={ stage1_handleClickLeft }
        handleSkipToWatch={ stage1_handleClickRight }
        onBack={ stage_1handleBack }
      />
    )
  }

  if (stage === "enter_2") {
    return (
      <>
        {
          from === 'parental'
            ? <PinCodePopup onSuccess={ stage2_handleForward } onBack={ stage2_handleBack } />
            : <PinCodeManager
              procedureType={ ProcedureType.Creation }
              onDone={ stage2_handleForward }
              onBack={ stage2_handleBack }
            />
        }
      </>
    )
  }

  if (stage === "success_3") {
    return <SuccessWindow$ from={ from } handleEndFlow={ stage3_handleForward } />
  }

  return null
}


export const AskToSetPinOrParental$ = AskToSetPinOrParental