import focuser from '@focuser';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { PinWithKeyboard } from '~components/PinWithKeyboard/PinWithKeyboard';
import { useSecurityPinValidity } from '~hooks/fetch/useAccount';
import { PIN_INVALID } from '~localization';

import { getMessageByError } from './helpers';

type PinCodeFormProps = Readonly<{
  isNeedFlushPinPopupState?: boolean;
  withoutValidation?: boolean;
  onSuccess: (code: string) => void;
  onError: (errorMessage: string, error?: any) => void;
  onProcessingStatus: (isProcessing: boolean) => void;
  intl: InjectedIntl;
}>;

const DEFAULT_CODE_STATE = '';

const PinCodeForm: React.FC<PinCodeFormProps> = ({
  isNeedFlushPinPopupState,
  withoutValidation,
  onSuccess,
  onError,
  onProcessingStatus,
  intl,
}: PinCodeFormProps) => {
  const isMounted = React.useRef(true);
  const [code, setCode] = React.useState<string>(DEFAULT_CODE_STATE);
  const { mutate: validateSecurityPin } = useSecurityPinValidity();

  const handleInput = React.useCallback((value: string) => {
    setCode((c) => (c + value).slice(0, 4));
  }, []);

  const handleBackspace = React.useCallback(() => {
    setCode((c) => c.slice(0, -1));
  }, []);

  const formSubmit = () => {
    if (withoutValidation) {
      onSuccess(code);
      setCode(DEFAULT_CODE_STATE);
      return;
    }

    onProcessingStatus(true);

    validateSecurityPin(
      { pin: code },
      {
        onSuccess(result) {
          if (isMounted.current) {
            if (result.data.valid) {
              onSuccess(code);
            } else {
              setCode(DEFAULT_CODE_STATE);
              onProcessingStatus(false);
              onError(intl.formatMessage({ id: PIN_INVALID }));
            }
          }
        },
        onError(error) {
          if (isMounted.current) {
            const messageText = getMessageByError(error, intl.formatMessage);
            onError(messageText, error);
            setCode(DEFAULT_CODE_STATE);
            onProcessingStatus(false);
          }
        },
      },
    );
  };

  React.useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  React.useEffect(() => {
    if (code.length === 4) formSubmit();
  }, [code.length]);

  React.useEffect(() => {
    if (isNeedFlushPinPopupState) {
      setCode(DEFAULT_CODE_STATE);
    }
  }, [isNeedFlushPinPopupState]);

  return (
    <focuser.FocusableBlock
      isFocused
      noNeedForceFocus
    >
      <PinWithKeyboard
        inputValue={ code }
        onInput={ handleInput }
        onBackspace={ handleBackspace }
      />
    </focuser.FocusableBlock>
  );
};

export default injectIntl(PinCodeForm);
