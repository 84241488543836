// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profileItemsContainer--S7G0D {\r\n  margin-bottom: 5.46875vw;\r\n}\r\n\r\n.focuserButtonArea--AXVqg {\r\n  width: 21.09375vw;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/ProfilesList/ProfilesListWrapper/styles.modules.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".profileItemsContainer {\r\n  margin-bottom: 5.46875vw;\r\n}\r\n\r\n.focuserButtonArea {\r\n  width: 21.09375vw;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var profileItemsContainer = "profileItemsContainer--S7G0D";
export var focuserButtonArea = "focuserButtonArea--AXVqg";
export default ___CSS_LOADER_EXPORT___;
