import { compact } from 'lodash';
import { InjectedIntl } from 'react-intl';

import ImageType from "~typings/ImageType";
import MenuIcon from '~typings/MenuIcon';
import MenuID from '~typings/MenuID';
import MenuItem from '~typings/MenuItem';
import Page from '~typings/Page';
import PageObject from '~typings/PageObject';
import SmartTVConfig from '~typings/SmartTVConfig';


type Props = Readonly<{
  pages: Page[];
  isKids: boolean;
  isAuthorized: boolean | null;
  intl: InjectedIntl;
  profileSection: SmartTVConfig['profileSection'];
  channelsTotalCount?: number;
}>;

const isAllowedMenuItem = (object: PageObject): boolean => (
  [
    PageObject.MainPage,
    PageObject.ContentPage,
    PageObject.BuildInPage,
    PageObject.NavigationPage,
    PageObject.SingleCollectionPage,
    PageObject.CompetitionPage,
    PageObject.PersonalPage,
    PageObject.SearchPage,
  ].indexOf(object) !== -1
);


const convertPagesToMenuItems = (pages: Page[], props: Props, noChannels: boolean): MenuItem[] | undefined => {
  if (!pages) {
    return undefined;
  }

  return compact(pages.map(({ id, name: title, subpages, object, blocks }: Page) => {
    // if (!isAllowedMenuItem(object)) { return undefined; }
    // The page list should not contain the main page. Otherwise, it will be a duplication:
    // the main page is displayed on the first level in the form of an icon.
    if (object === PageObject.MainPage) {
      return null;
    }

    // if (uid === 'subscribe') { return null; }

    // // // if no channels then hide epg
    // if (noChannels && uid === 'epg') { return undefined; }

    const item: MenuItem = { id, title };

    const subMenu = subpages ? convertPagesToMenuItems(subpages, props, noChannels) : null;


    if (subMenu && subMenu.length !== 0) {
      if (blocks && blocks?.length !== 0) {
        item.link = `/page/${ id }`
      }
      else {
        item.link = undefined;
      }
      return ({
        ...item,
        isSubMenuInNavMenu: true,
        subMenu,
      });
    }
    else if (object === PageObject.SubscribePage) {
      item.link = `/products`
    }
    else {
      item.link = `/page/${ id }`;
    }

    return item;
  }));
};

function createMarkup(img) {
  return { __html: img };
}

const getMenuItems = (props: Props): MenuItem[] => {
  const menu: MenuItem[] = [];

  props.pages.map((page) => {
      let template: any = {}

      if (page.images?.length !== 0) {
        const icon = page.images?.find((el) => {
          return el.type === ImageType.IconSmartTV;
        })
        template.image = icon
      }

      ////////////
      if (page.object === PageObject.MainPage) {

        template.id = MenuID.Home;
        template.link = `/page/${ page.id }`;
        template.title = page.name;

        menu.push(template)

        return
      }
      else if (page.subpages && page.subpages.length !== 0) {
        const menuItemsFromPages = convertPagesToMenuItems
        (page.subpages, props, props.channelsTotalCount === 0);

        const link = (page.blocks && page.blocks.length !== 0)
          ? `/page/${ page.id }`
          : undefined;

        template.id = page.id
        template.link = link
        template.isSubMenuInNavMenu = true
        template.subMenu = menuItemsFromPages
        template.title = page.name
        menu.push(template)
        return;
      }
      else if (page.object === PageObject.SearchPage) {
        template.id = MenuID.Search
        template.link = `/search`
        template.title = page.name
        menu.push(template)
        return;
      }
      /////////////


      template.id = page.id
      template.link = `/page/${ page.id }`
      template.title = page.name
      menu.push(template)

    }
  )
  menu.push({
    id: MenuID.Account,
    icon: MenuIcon.Account,
    isSubMenuInNavMenu: true,
    link: `/${MenuID.Account}`,
  });
  return menu;

};


export {
  createMarkup,
  getMenuItems,
  isAllowedMenuItem
};
