/* eslint no-console: 0 */
import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import {
  ALREADY_AVAILABLE,
  NAVIGATION_BACK,
  SUBSCRIBTION_CANCELLATION_DISCLAMER,
} from '~localization';
import Button from '~ui/button';
import { Popup } from '~ui/Popup';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  intl: InjectedIntl;
}>;


const AlreadyAvailableButton: React.FC<Props> = (props) => {
  const [popup, setPopup] = React.useState(false);

  const handleClick = () => {
    setPopup(prev => !prev);
  };

  return (
    <>
      <Button
        isFocused={ props.isFocused }
        className={ cn(styles.button, {
          [styles.isFocused]: props.isFocused,
        }) }
        onClick={ handleClick }
      >
        {
          props.intl.formatMessage({ id: ALREADY_AVAILABLE })
        }
      </Button>
      { popup ? (
        <Popup>
          <div className={ styles.disclamerWrapper }>
            <div>
              {
                props.intl.formatMessage({ id: SUBSCRIBTION_CANCELLATION_DISCLAMER })
              }
            </div>
            <div>
              <Button
                isFocused
                className={ cn(styles.button, styles.isFocused) }
                onClick={ handleClick }
              >
                {
                  props.intl.formatMessage({ id: NAVIGATION_BACK })
                }
              </Button>
            </div>
          </div>
        </Popup>
      ) : null }
    </>
  );
};


export default React.memo(AlreadyAvailableButton);
