import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { I_SEE, THE_LIMIT_OF_PROFILES } from '~lib/localizator/src/dictionaries';

import * as styles from './styles.module.css';

type Props = {
  onClick?: (evt) => void;
  intl: InjectedIntl;
};

const ProfileLimitAlert: React.FC<Props> = (props) => {
  return(
    <div className={ styles.alertWrap }>
      <div className={ styles.alertText }>
        { props.intl.formatMessage({ id: THE_LIMIT_OF_PROFILES }) }
      </div>
      <PrimaryButtonFocuser
        isFocused
        onClick={ props.onClick }
      >
        { props.intl.formatMessage({ id: I_SEE }) }
      </PrimaryButtonFocuser>
    </div>
  )
}

export default injectIntl(ProfileLimitAlert);
