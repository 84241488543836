import useLocationSearch from '~hooks/useLocationSearch';


const useIsPlayerPage = (): boolean => {
  const playerPageHashValue = useLocationSearch()?.player;

  return !!playerPageHashValue;
};


export default useIsPlayerPage;
