import * as React from 'react';

import { usePlatform } from '../Platform';
import {
  isAnimationDisabledByUserAgent,
  MINIMAL_TIZEN_VERSION,
  MINIMAL_WEBOS_VERSION,
  TIZEN_VERSION_REGEXP,
  WEBOS_VERSION_REGEXP,
} from './utils';

type AnimationContext = {
  isAnimationEnabled: boolean;
};

const AnimationContext = React.createContext<AnimationContext>({
  isAnimationEnabled: true
});

export const useAnimationContext = () => React.useContext(AnimationContext);

export const AnimationContextProvider = React.memo(
  ({ children }: { children?: React.ReactNode }): JSX.Element => {
    const { platform } = usePlatform();

    const isAnimationEnabled = React.useMemo(() => {
      const info = platform.getDeviceInfo();
      if (!info.osVersion || !info.osName) {
        // Если мы не знаем версию операционной системы, то проверяем по user агенту
        const isDisabled = isAnimationDisabledByUserAgent();
        if (isDisabled) {
          // Если по userAgent-у анимации не должно быть, то выключаем
          return false;
        }
        return true;
      }

      if(!(info.osName === 'webos' || info.osName === 'tizen')) {
        // Если не webos, и не tizen, то оставляем анимацию включенной
        return true;
      }

      const regexp = info.osName === 'webos' ? WEBOS_VERSION_REGEXP : TIZEN_VERSION_REGEXP;
      const minimalVersion = info.osName === 'webos' ? MINIMAL_WEBOS_VERSION : MINIMAL_TIZEN_VERSION;

      const osVersion = info.osVersion.match(regexp)?.[0];
      // Если не смогли понять версию, то оставляем анимацию включенной
      if (!osVersion) {
        return true;
      }
      const parsedOsVersion = Number(osVersion);

      if (!parsedOsVersion) {
        return true;
      }

      return parsedOsVersion >= minimalVersion;
    }, [platform]);

    return (
      <AnimationContext.Provider value={ { isAnimationEnabled } }>
        {children}
      </AnimationContext.Provider>
    );
  },
);

AnimationContextProvider.displayName = 'AnimationContextProvider';
