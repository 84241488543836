import * as cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import preparingText from "~lib/preparingText";
import Event from '~typings/Event';
import ItemObject from '~typings/ItemObject';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  event: Event | null;
}>;


const EventDetail: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const handleClick = React.useCallback(() => {
    if (props.event) {
      history.push(`/media-item-details/${ItemObject.ProgramEvent}/${props.event.id}`);
    }
  }, [props.event?.id]);

  return React.useMemo(() => (
    <div
      className={ cn(styles.programEventDescription, {
        [styles.focused]: props.isFocused,
        'focusedNavigationNode': props.isFocused,
      }) }
      onClick={ handleClick }
    >
      <div
        className={ cn(styles.programEventDescriptionText, {
          [styles.placeholder]: (!props.isFocused && props.event === null),
        }) }
        dangerouslySetInnerHTML={ { __html: preparingText(props?.event?.description || props.event?.name || '') } }
      />
    </div>
  ), [props.isFocused, props.event?.id]);
};


export default React.memo(EventDetail);
