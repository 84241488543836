import { Moment } from 'moment';
import * as React from 'react';

import Blackout from '~typings/Blackout';
import Channel from '~typings/Channel';
import ProgramEvent from '~typings/Event';
import Event from '~typings/Event';

import DateItem from './DateItem';
import { EventWithDate } from './EPGPageEvents';
import EPGPageEvent from './Event';


type Props = Readonly<{
  channel: Channel;
  blackouts?: Blackout[];
  isFocused: boolean;
  focusedIndex: number;
  activeIndex: number;
  fromShift?: number;
  toShift?: number;
  items: Record<number, EventWithDate>;
  currentEvent: ProgramEvent | null;
  archivedPrograms?: string[] | [] | undefined;
  onClick?: (event: ProgramEvent, isCatchup: boolean, isCurrentEvent: boolean) => void;
}>;


const EPGPageEventsList: React.FC<Props> = (props: Props) => {
  const fromIndex = (props.focusedIndex - 9 - (props.fromShift ?? 0));
  const toIndex = (props.focusedIndex + 8 + (props.toShift ?? 0));

  const itemsForRender: JSX.Element[] = [];

  for (let idx = fromIndex; idx <= toIndex; idx += 1) {
    const item = props.items[idx] as EventWithDate;

    if (typeof item?.date?.format === 'function') {
      itemsForRender.push(
        <DateItem
          key={ (item.date as Moment).toString() }
          idx={ idx }
          kind="Moment"
          item={ item.date as Moment }
        />
      );
    } else if (props.items[idx]) {
      itemsForRender.push(
        <EPGPageEvent
          key={ (item.event as Event).id }
          channel={ props.channel }
          blackouts={ props.blackouts }
          item={ item.event as Event }
          index={ idx }
          focusIndex={ item.index }
          onClick={ props.onClick }
          isCurrentEvent={ (props.currentEvent?.id === props.items[idx]?.event?.id) }
          isActive={ (props.activeIndex === props.items[idx]?.index) }
          isFocused={ (props.isFocused && props.focusedIndex === props.items[idx]?.index) }
          isVisible={ (fromIndex <= (props.items[idx]?.index ?? 0) && (props.items[idx]?.index ?? 0) <= toIndex) }
          archivedPrograms={ props.archivedPrograms }
        />
      );
    }
  }
  
  return (
    <>
      { itemsForRender }
    </>
  );
};


export default EPGPageEventsList
