import * as cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import useSpatialNavigation from '~hooks/useSpatialNavigation';
import Button from '~ui/button';
import { Popup } from '~ui/Popup';

import * as styles from './styles.module.css';
import { INITIAL_STATE, State } from './UnsubscribeProceed';


type Props = {
  state: State;
  setState: (patch: Partial<State> | ((prevState: State) => Partial<State>)) => void;
};

const RetentionFlowPopup: React.FC<Props> = ({ state, setState }) => {
  const history = useHistory();

  const navigationItems = React.useRef([
    { maxIndex: 0 }, // Button
    { maxIndex: 0 }, // Button
  ]);
  const navigationState = useSpatialNavigation({
    allowNavigation: !!state.popup,
    navigationItems: navigationItems.current,
  });
  const focusIndex = navigationState.focusOn;

  if (!state.popup) { return null; }

  return (
    <Popup>
      <div className={ styles.popupContainer }>
        <h1 className={ styles.popupHeading }>
          { state.popup.title }
        </h1>
        <div
          className={ styles.popupContent }
          dangerouslySetInnerHTML={ { __html: state.popup.body } }
          onClick={ (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
          } }
        />
        <div>
          { state.popup.actions.map((item, idx) => {
            let action = () => {};
            if (item.action_type === 'deeplink' && item.url) {
              const deeplink = item.url.slice(item.url.indexOf('//') + 2, item.url.length);

              action = () => history.replace(`/${deeplink}`);
            }
            if (item.action_type === 'confirm') {
              action = () => setState({
                ...INITIAL_STATE,
                param: 'ignore_action',
              });
            }

            return (
              <Button
                key={ item.id || item.url }
                onClick={ action }
                isFocused={ idx === focusIndex }
                className={ cn(styles.button, styles.popupButton, {
                  [styles.isFocused]: idx === focusIndex,
                }) }
              >
                { item.title }
              </Button>
            );
          }) }
        </div>
      </div>
    </Popup>
  );
};


export default RetentionFlowPopup;
