import React from 'react';

import Card from '~typings/Card';
import { CardConfig, CardConfigLayout } from '~typings/ScreenCardConfigs';

import { CardMutableHoc } from './CardMutableHoc/CardMutableHoc';
import { HorizontalCardWrapper } from './Horizontal';
import { NewsCardWrapper } from './News';
import { SquareCardWrapper } from './Square';
import { VerticalCardWrapper } from './Vertical';

interface PropsTS {
  card: Card;
  cardConfig: CardConfig;
  progress?: number;
  isFocused: boolean;
}

export const CardLayer2 = React.memo((props: PropsTS) => {
  const { card, cardConfig, progress, isFocused } = props;

  if (cardConfig.layout === CardConfigLayout.Horizontal) {
    return (
      <CardMutableHoc
        card={ card }
        cardConfigId={ cardConfig.id }
      >
        {({ maybeMutatedCard, mutationState }) => (
          <HorizontalCardWrapper
            card={ maybeMutatedCard }
            cardConfig={ cardConfig }
            isFocused={ isFocused }
            hardcodedProgress={ progress }
            mutationState={ mutationState }
          />
        )}
      </CardMutableHoc>
    );
  }

  if (cardConfig.layout === CardConfigLayout.Vertical) {
    return (
      <CardMutableHoc
        card={ card }
        cardConfigId={ cardConfig.id }
      >
        {({ maybeMutatedCard }) => (
          <VerticalCardWrapper
            card={ maybeMutatedCard }
            cardConfig={ cardConfig }
            isFocused={ isFocused }
          />
        )}
      </CardMutableHoc>
    );
  }

  if (cardConfig.layout === CardConfigLayout.Square) {
    return (
      <CardMutableHoc
        card={ card }
        cardConfigId={ cardConfig.id }
      >
        {({ maybeMutatedCard, mutationState }) => (
          <SquareCardWrapper
            card={ maybeMutatedCard }
            cardConfig={ cardConfig }
            isFocused={ isFocused }
            hardcodedProgress={ progress }
            mutationState={ mutationState }
          />
        )}
      </CardMutableHoc>
    );
  }

  if (
    cardConfig.layout === CardConfigLayout.News ||
    cardConfig.layout === CardConfigLayout.NewsWithoutPicture
  ) {
    return (
      <CardMutableHoc
        card={ card }
        cardConfigId={ cardConfig.id }
      >
        {({ maybeMutatedCard }) => (
          <NewsCardWrapper
            card={ maybeMutatedCard }
            cardConfig={ cardConfig }
            isFocused={ isFocused }
          />
        )}
      </CardMutableHoc>
    );
  }

  return null;
});

CardLayer2.displayName = 'CardLayer2';
