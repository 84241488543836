// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".currentEvent--tS6uv {\n  position: relative;\n  height: 0.3125vw;\n  overflow: hidden;\n  background-color: rgba(255, 255, 255, 0.2);\n  -webkit-border-radius: 0.3125vw;\n          border-radius: 0.3125vw;\n}\n\n.currentEventProgress--BeXcZ {\n  position: absolute;\n  height: 0.3125vw;\n  background-color: rgba(253, 216, 53, 1);\n  -webkit-transition: width 1s;\n  transition: width 1s;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/CurrentEventProgress/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAsC;EACtC,gBAAgB;EAChB,0CAAuB;EACvB,+BAA6C;UAA7C,uBAA6C;AAC/C;;AAEA;EACE,kBAAkB;EAClB,gBAAsC;EACtC,uCAAwB;EACxB,4BAAoB;EAApB,oBAAoB;AACtB","sourcesContent":[".currentEvent {\n  position: relative;\n  height: $(mediaItemBorderRadiusInVW)vw;\n  overflow: hidden;\n  background-color: $w_20;\n  border-radius: $(mediaItemBorderRadiusInVW)vw;\n}\n\n.currentEventProgress {\n  position: absolute;\n  height: $(mediaItemBorderRadiusInVW)vw;\n  background-color: $ac_00;\n  transition: width 1s;\n}\n"],"sourceRoot":""}]);
// Exports
export var currentEvent = "currentEvent--tS6uv";
export var currentEventProgress = "currentEventProgress--BeXcZ";
export default ___CSS_LOADER_EXPORT___;
