import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { useApp } from "~components/Provider/App";
import { getShortDate } from '~lib/localeUtils/dates';
import { NEXT_BILLING_DATE, PRICE_PERIOD, TRIAL_FREE_PERIOD_TEXT } from '~localization';
import { ApiPeriodUnit,PeriodUnit } from '~typings/Period';
import Subscription from "~typings/Subscription";


type Props = Readonly<{
  isTrialPeriod?: boolean;
  price: {
    value?: string;
    duration?: number;
    unit?: ApiPeriodUnit;
  };
  autoReNew?: Subscription['autorenew'];
  intl: InjectedIntl;
}>;


const TrialFreePeriodAndPriceText: React.FC<Props> = (props: Props) => {
  const { language } = useApp();

  return (
    <>
      {
        props.isTrialPeriod &&
          props.intl.formatMessage({ id: TRIAL_FREE_PERIOD_TEXT })
      }
      {<span>&nbsp;</span>}
      {
        props.intl.formatMessage({ id: PRICE_PERIOD }, {
          price: props.price.value || 0,
          durationValue: props.price.duration || 30,
          durationUnit: props.price.unit || PeriodUnit.Days,
        })
      }
      {
        (props.autoReNew && props.autoReNew.autorenewable && props.autoReNew.next_renew_date) &&
          (
            <>
              { '. ' }
              {
                props.intl.formatMessage({ id: NEXT_BILLING_DATE }, {
                  date: getShortDate(props.autoReNew.next_renew_date, language),
                })
              }
            </>
          )
      }
    </>
  );
};


export default injectIntl(TrialFreePeriodAndPriceText);
