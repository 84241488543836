import * as React from 'react';

import SceneItem from '~components/Scene/typings';
import ItemObject from '~typings/ItemObject';
import MediaItemDimension from '~typings/MediaItemDimension';

import { ChannelContent } from './Channel';
import { SeriesMediaItemContent } from './Series';


type Props = Readonly<{
  item?: SceneItem;
  imageURL: string | null;
  dimension: MediaItemDimension;
  showAsSeries?: boolean;
  isFocused: boolean;
}>;


const MediaItemContent: React.FC<Props> = (props: Props) => {
  switch (props?.item?.object) {
    case ItemObject.Channel:
      return (
        <ChannelContent
          channel={ props.item }
          imageURL={ props.imageURL }
          dimension={ props.dimension }
        />
      );
    case ItemObject.Series: {
      return (
        <SeriesMediaItemContent
          showAsSeries
          isFocused={ props.isFocused }
        />
      );
    }
    case ItemObject.Episode: {
      return (
        <SeriesMediaItemContent
          isFocused={ props.isFocused }
          episode={ props.item }
          showAsSeries={ props.showAsSeries }
        />
      );
    }
    default:
      return null;
  }
};


export default React.memo(MediaItemContent);
