import React, { useMemo } from 'react';

import { useAnimationContext } from '~components/Provider/Animation';
import Card from '~typings/Card';
import { CardMutationState } from '~typings/CardMutation';
import { CardConfig } from '~typings/ScreenCardConfigs';
import { HorizontalCard } from '~ui/CardV2/Horizontal';

import { getHorizontalCardImageParams,useCardImage, useDynamicProgress, useGetFieldsFromCard } from '../hooks';


interface PropsTS {
  card: Card;
  cardConfig: CardConfig;
  isFocused: boolean;
  hardcodedProgress?: number;
  mutationState: CardMutationState | null;
}

export function HorizontalCardWrapper(props: PropsTS) {
  const { isFocused, card, cardConfig, hardcodedProgress, mutationState } = props;

  const {
    episodeNumber,
    catalogHeaderExist,
    showBottomTextBlock,
    contentProviderIconUrl,
    label,
    modification,
  } = useGetFieldsFromCard(card, cardConfig);

  const { cardImageType, widthVW, heightVH, type } = getHorizontalCardImageParams(card.image_shape);

  const imageSource = useCardImage({
    cardImages: card.images,
    isCardInappropriate: card.inappropriate,
    cardImageType,
    widthVW,
    heightVH,
  });

  const progress = useDynamicProgress({
    hardcodedProgress,
    cardMutationState: mutationState,
  });

  const image = useMemo(() => (imageSource ? { type, url: imageSource } : null), [imageSource]);

  const { isAnimationEnabled } = useAnimationContext();

  return (
    <HorizontalCard
      isFocused={ isFocused }
      showCatalogHeader={ catalogHeaderExist }
      contentProviderIconUrl={ contentProviderIconUrl }
      episodeNumber={ episodeNumber }
      progress={ progress }
      showBottomTextBlock={ showBottomTextBlock }
      name={ card.name }
      additionalName={ card.additional_name }
      label={ label }
      certificationTag={ card.certification_tags?.[0] }
      modification={ modification }
      image={ image }
      isAnimationEnabled={ isAnimationEnabled }
    />
  );
}
