import * as React from 'react';

import ErrorDialog from '~components/ErrorDialog/ErrorDialog';
import { useConfig } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import Product from '~typings/Product';
import Subscription from '~typings/Subscription';

import SceneProductItem from '../Scene/SceneProductItem';
import ProductPlanRouts from './ProductPlanRouts';
import * as styles from './styles.module.css';


type Props = Readonly<{
  product?: Product;
  subscriptions?: Subscription[];
}>;

const ProductPlanPage: React.FC<Props> = ({ product, subscriptions }) => {
  const { accessToken } = getAuthData();
  const { smartTV } = useConfig();

  if (!smartTV.profileSection.subscriptions) {
    return <ErrorDialog access="cantPurchase" />;
  }

  if (!accessToken) {
    return <ErrorDialog access="notAuthorized" />;
  }

  if (!product) { return null; }

  return (
    <>
      <SceneProductItem
        product={ product }
      />
      <div className={ styles.container }>
        <ProductPlanRouts
          product={ product }
          subscriptions={ subscriptions }
        />
      </div>
    </>
  );
};


export default ProductPlanPage;
