import { useMemo } from 'react';
import { useQueries } from 'react-query';

import { Nullable } from '~lib/type-utils/utils';

const imageKeys = {
  PREFIX: 'utils-image-preload-many',
  withUrl: (url: Nullable<string>) => [imageKeys.PREFIX, url],
};

const fetchImage = (url?: Nullable<string>): Promise<string> => {
  const img = new Image();
  if (!url) {
    return Promise.reject('no url');
  }
  img.src = url;
  return new Promise((res, rej) => {
    img.onload = () => {
      res(url);
    };
    img.onerror = (err) => {
      rej(err);
    };
  });
};

export const usePreloadImagesQuery = ({ urls }: { urls: Nullable<string>[] }) => {
  return useQueries(
    urls.map((url) => ({
      queryKey: imageKeys.withUrl(url),
      queryFn: () => fetchImage(url),
      notifyOnChangeProps: ['data', 'status', 'error'],
    })),
  );
};

export const usePreloadImagesArray = (urls: Nullable<string>[]) => {
  const queries = usePreloadImagesQuery({ urls });

  const images = useMemo(() => queries.map((query) => query.data as string | undefined), [queries]);
  const isLoading = useMemo(() => queries.some((query) => query.status === 'loading'), [queries]);

  return {
    images,
    isLoading,
  };
};
