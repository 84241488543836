import CastMember from '~typings/CastMember'

export const groupCastMembersByRole = (membersArray:CastMember[] = []) => {
  const membersByRole = {};
  membersArray.forEach(member => {
    membersByRole[member.role] = membersByRole[member.role] || [];
    membersByRole[member.role].push(member.person.full_name);
  });

  return membersByRole;
};
