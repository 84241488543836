import React from 'react';
import { InjectedIntl } from 'react-intl';

import usePromocodeMutation, { PromoData } from '~hooks/fetch/usePromocode';
import { PROMO_CODE_ERROR, PROMO_CODE_NO_PRODUCTS } from '~localization';


const usePromocodeHelper = (intl: InjectedIntl) => {
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string|null>(null);
  const promocodeErrorText = intl.formatMessage({ id: PROMO_CODE_ERROR });
  const productsErrorText = intl.formatMessage({ id: PROMO_CODE_NO_PRODUCTS });
  const { mutateAsync, isLoading, data } = usePromocodeMutation();

  const checkAndFetch = async (promocode?: string): Promise<PromoData|null> => {
    if (!promocode) return null;
    if (promocode !== " ") {
      setErrorMessage(promocodeErrorText);
    }
    const result = await mutateAsync(
      {promocode},
      {
        onSuccess: (promoData) => {
          if (promoData.promocode?.available !== true) {
            setErrorMessage(promocodeErrorText);
          } else if (!promoData?.products?.length && !promoData.rentPlans?.length) {
            setErrorMessage(productsErrorText);
          }
        },
      }
    );

    const isSuccess = result.promocode?.available === true
      && !!(result.products?.length || result.rentPlans?.length);
    setIsSuccess(isSuccess);
    
    return result;
  };

  const reset = () => {
    setIsSuccess(false);
    setErrorMessage(null);
  };

  return {
    data,
    checkAndFetch,
    reset,
    isSuccess,
    isLoading,
    errorMessage
  }
};

export default usePromocodeHelper;
