import * as React from 'react';

import { usePreviousMemoized } from '~hooks/usePreviousMemoized';
import Fade from '~ui/Effects/Fade';
import { SliderOffsetableSimple } from '~ui/SliderOffsetableSimple';

// TODO add jsDoc
type Props = Readonly<{
  /**
   * Дочерние элементы
   */
  children: JSX.Element[];
  /**
   * Индекс сфокусированного элемента
   */
  focusedIndex: number;
  /**
   * Сколько должна длится анимация появления
   * @default 150
   */
  fadeDuration?: number;
  /**
   * Сколько должна длится анимация перелистывания
   * @default 400
   */
  slideDuration?: number
  /**
   * Задержка перед началом анимации скрытия
   * @default 0
   */
  fadeHideDelay?: number;
  /**
   * Задержка перед началом анимации появления
   * @default 150
   */
  fadeShowDelay?: number;
  /**
   * Триггеры для обновления слайдера, дополнительно смотреть в `SliderOffsetableSimple`
   */
  updates?: unknown[]
  /**
   * Дополнительный класс для обертки слайдера, дополнительно смотреть в `SliderOffsetableSimple`
   */
  sliderClassName?: string
}>;


const DEFAULT_FADE_DURATION = 150;
const DEFAULT_SLIDE_DURATION = 400;
const DEFAULT_FADE_SHOW_DELAY = 150;
const DEFAULT_FADE_HIDE_DELAY = 0;

export const VerticalFadeScroll: React.FC<Props> = React.memo(({
  children,
  focusedIndex,
  fadeDuration = DEFAULT_FADE_DURATION,
  slideDuration = DEFAULT_SLIDE_DURATION,
  fadeHideDelay = DEFAULT_FADE_HIDE_DELAY,
  fadeShowDelay = DEFAULT_FADE_SHOW_DELAY,
  updates,
  sliderClassName
}: Props) => {
  const prev = usePreviousMemoized(focusedIndex);
  const isScrollingUp = focusedIndex < prev;

  return (
    <SliderOffsetableSimple
      focusedIndex={ focusedIndex }
      animationDurationInMS={ slideDuration }
      sliderType='vertical'
      updates={ updates }
      className={ sliderClassName }
    >
      {
        children.map((child, index: number) => (
          <Fade
          isVisible={ index >= focusedIndex }
          key={ child.key || index }
          duration={ fadeDuration }
          delay={ isScrollingUp ? fadeShowDelay : fadeHideDelay }
        >
          { child }
        </Fade>
        ))
      }
    </SliderOffsetableSimple>
  );
});

VerticalFadeScroll.displayName = 'VerticalFadeScroll';
