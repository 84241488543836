import { find } from 'lodash';

import bringingAudioTrackNameToStandard from "~lib/bringingAudioTrackNameToStandard";
import getSecontsFromPercents from "~lib/player/getSecontsFromPercents";
import sortVideoTracks from '~lib/player/sortVideoTracks';
import AudioTrack from '~typings/AudioTrack';
import { ManifestVideoTrack } from '~typings/Manifest'
import PlayerType from '~typings/PlayerType';
import VideoTrack from '~typings/VideoTrack';

import { createHTMLVideoElement, Events, prepareTimeShiftUrl, resetHTMLVideoElement } from '../common';
import getSourceTypeAttribute from '../getSourceTypeAttribute';
import handleSourceError from '../handleSourceError';
import { HTMLVideoElWithTracks, IPlayer, PlayerEventSubscribe,PlayerLoadParameters, Props } from '../typings';
import WebOSPlayerNativeDRM from './drm';
import onError from './error';


const webosPlayer = ({ videoContainer, event }: Props): IPlayer => {
  let selectedVideoTrack: ManifestVideoTrack | null = null;
  let params: PlayerLoadParameters | null = null;
  let switchToThisTime: number | null = null;
  let videoElement: HTMLVideoElWithTracks | null = null;

  const isTracksFromManifest = () => ((params?.manifest?.video || []).length > 0);
  const handleTrackChanged = () => {
    event.emit(Events.TRACK_CHANGED);
  };

  const dispose = () => {
    try {
      if (videoContainer && videoElement) {
        videoContainer.removeChild(videoElement);
      }
    } catch (ignore) {

    }
  };
  const getCurrentTime = (): number => videoElement?.currentTime || 0;
  const getDuration = (): number => videoElement?.duration || 0;

  async function reset() {
    params = null;
    selectedVideoTrack = null;
    switchToThisTime = null;

    resetHTMLVideoElement(videoContainer, videoElement);
  }

  const handleReadyToPlay = async (url, sourceType, percentsWatched?: number) => {
    videoElement = createHTMLVideoElement(event);
    videoContainer?.appendChild(videoElement);

    const source = document.createElement('source');
    source.setAttribute('src', url);

    if (sourceType) {
      source.setAttribute('type', sourceType);
    }

    source.addEventListener('error', err => handleSourceError(event, err));

    videoElement.appendChild(source);

    if (percentsWatched) {
      // console.log('percentsWatched:', percentsWatched);
      // console.log('getDuration():', getDuration());
      seekTo(getSecontsFromPercents(percentsWatched, getDuration()));
    }
  };

  async function load(parameters: PlayerLoadParameters) {
    await reset();

    params = parameters;

    const playerUrl = prepareTimeShiftUrl(parameters.stream.url, parameters.timeShiftParams);
    const sourceType = getSourceTypeAttribute({ url: parameters.stream.url, drm: parameters.stream.drm });

    try {
      if (parameters.stream.drm.type === 'widevine' || parameters.stream.drm.type === 'playready') {
        const DRMType = parameters.stream.drm.type;
        const licenseServer = parameters.stream.drm.license_server;
        const webosNativeDRM = new WebOSPlayerNativeDRM();

        webosNativeDRM.setDRMConfig(
          DRMType,
          licenseServer,
          parameters.mediaItem.id,
          parameters.clientIP,
          window.location.href,
          parameters.platform.deviceId,
          parameters.stream.player,
          parameters.stream.drm.drm_asset_id,
        );
        webosNativeDRM.setPlay(() => {
          handleReadyToPlay(playerUrl, sourceType, parameters.percentsWatched);
        });

        if (!webosNativeDRM.isDrmClientLoaded) {
          webosNativeDRM.drmClient(DRMType);
        } else if (webosNativeDRM.isDrmClientLoaded && webosNativeDRM.drmClientType !== DRMType) {
          webosNativeDRM.unloadDrmClient().then(() => {
            webosNativeDRM.drmClient(DRMType);
          }).catch(() => {
            onError(event, 'unloadDrmClient');
          });
        } else {
          webosNativeDRM.sendDrm(webosNativeDRM.clientId);
        }
      } else {
        handleReadyToPlay(playerUrl, sourceType, parameters.percentsWatched);
      }
    } catch (error) {
      console.error('catch load:', error);

      onError(event, error);
    }
  }

  const mute = () => {};

  const on: PlayerEventSubscribe = (target, listener) => event.on(target, listener);

  const removeListener: PlayerEventSubscribe = (target, listener) => {
    event.removeListener(target, listener);
  };

  const play = (): void => {
    videoElement?.play();
  };

  const pause = () => {
    videoElement?.pause();
  };

  const seekTo = (timePosition: number) => {
    if (videoElement?.currentTime !== undefined) {
      play();
      videoElement.currentTime = timePosition;
    }
  };

  const stop = () => {
    reset();
  };

  const isAutoQualityEnabled = () => (selectedVideoTrack === null);
  const setAutoQuality = () => {};

  const changeAudioTrack = (audioTrack: AudioTrack) => {
    if (videoElement?.audioTracks) {
      try {
        for (var index = 0; index < videoElement?.audioTracks.length; index += 1) {
          videoElement.audioTracks[index].enabled = (index === audioTrack.index);
        }
        handleTrackChanged();
      } catch (ignore) {
        // ignore
      }
    }
  };
  const changeVideoTrack = (videoTrack: VideoTrack) => {
    try {
      if (isTracksFromManifest()) {
        switchToThisTime = getCurrentTime();

        pause();

        selectedVideoTrack = videoTrack.data as ManifestVideoTrack;

        if (videoElement) {
          videoElement.src = selectedVideoTrack.url;
        }

        seekTo(switchToThisTime);
        switchToThisTime = null;
      }
      if (videoElement?.videoTracks) {
        for (var index = 0; index < videoElement?.videoTracks.length; index += 1) {
          videoElement.videoTracks[index].selected = (index === videoTrack.index);
        }
      }
      handleTrackChanged();
    } catch (ignore) {
      // ignore
    }
  };
  const getAudioTracks = () => {
    const audioTracks: AudioTrack[] = [];

    if (videoElement?.audioTracks && videoElement.videoTracks) {
      try {
        for (var index = 0; index < videoElement.videoTracks.length; index += 1) {
          audioTracks.push({
            index,
            isSelected: videoElement.audioTracks[index].enabled,
            name: bringingAudioTrackNameToStandard(
              videoElement.audioTracks[index].language
              || videoElement.audioTracks[index].label
            ),
          });
        }
      } catch (ignore) {
        // ignore
      }
    }

    return audioTracks;
  };
  const getVideoTracks = () => {
    const videoTracks: VideoTrack[] = [];

    try {
      if (isTracksFromManifest()) {
        (params?.manifest?.video || []).map((video, index) => {
          videoTracks.push({
            index,
            isSelected: (selectedVideoTrack?.url === video.url),
            name: video.name,
            data: video,
          });
        })
      } else if (videoElement?.videoTracks) {
        for (let index = 0; index < videoElement?.videoTracks.length; index += 1) {
          const name = (
            videoElement.videoTracks[index].language || videoElement.videoTracks[index].label
          ).replace('`', '');
          videoTracks.push({
            index,
            isSelected: videoElement.videoTracks[index].selected,
            name,
          });
        }
      }
    } catch (ignore) {
      // ignore
    }

    return sortVideoTracks(videoTracks);
  };
  const getSelectedAudioTrack = () => {
    find(getAudioTracks(), { isSelected: true });
  };
  const getSelectedVideoTrack = () => {
    find(getVideoTracks(), { isSelected: true });
  };

  const getProperties = () => {
    if (!videoElement) { return null; }

    return {
      videoPlaybackQuality: videoElement?.getVideoPlaybackQuality ?
        videoElement.getVideoPlaybackQuality() : null,
    };
  };

  return {
    type: PlayerType.Webos,

    dispose,
    getCurrentTime,
    getDuration,
    load,
    mute,
    on,
    removeListener,
    pause,
    play,
    reset,
    seekTo,
    stop,

    isAutoQualityEnabled,
    setAutoQuality,

    changeAudioTrack,
    changeVideoTrack,
    getAudioTracks,
    getVideoTracks,
    getSelectedAudioTrack,
    getSelectedVideoTrack,

    getProperties,
  };
};


export default webosPlayer;
