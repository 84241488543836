import * as cn from 'classnames';
import { find, findKey, size } from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DELAYED_CHANNEL_TIME_IN_MS } from '~components/EPG/component.helpers';
import EPGPageEventsListScrollWrapper from '~components/EPG/Events/EventsScroll';
import NoEPGText from '~components/EPG/Events/NoEPGText';
import EventsPlaceholders from '~components/EPG/Events/placeholders';
import { useVerticalListNavigation2 } from '~components/EPG/VerticalScroll/useVerticalList';
import SceneManagerFetcher from '~components/Scene/SceneManagerFetcher';
import { getInitialDateIndex } from '~components/ScenePlayer/Player/controls/Popup/EPG/helpers';
import { useAutoInfinityBlackouts } from '~hooks/fetch/useBlackouts/useBlackouts';
import useFetchCurrentEvent from '~hooks/fetch/useCurrentEvent/useCurrentEvent';
import {
  getCurrentEvent,
  useProgramEventsByDate,
  useThreeDaysEvents,
} from '~hooks/fetch/useProgramEvents/useProgramEvents';
import useCurrentEvent from '~hooks/useCurrentEvent';
import useDelayedChange from '~hooks/useDelayedChange';
import { getFocusedLineFromURL, setFocusOn } from '~hooks/useSpatialNavigation/helpers';
import { toPivotDate } from '~lib/eventsHelper';
import findMediaItem from '~lib/findMediaItem';
import { isToday, makeWeekDays } from '~lib/localeUtils/dates';
import { valueInPixelsByWidth } from "~lib/SizesInPX";
import usePointer from '~stores/Pointer';
import Channel from '~typings/Channel';
import Event from '~typings/Event';
import ProgramEvent from '~typings/Event';
import ItemObject from '~typings/ItemObject';
import NavigationDirection from '~typings/NavigationDirection';
import Fade from '~ui/Effects/Fade';

import DateItem from './DateItem';
import EPGPageEventsList from './EventsList';
import * as styles from './styles.module.css';

export type EventWithDate = {
  index: number;
  event: ProgramEvent;
  date?: never;
} | {
  index?: never;
  event?: never;
  date: Moment;
};

const getFocusedEventIndex = (items: Event[]): number => {
  const currentEvent = getCurrentEvent(items);

  if (currentEvent) {
    return Math.max(
      items.findIndex(({ id }) => (id === currentEvent?.id)),
      0,
    );
  }

  return 0;
};

type Props = Readonly<{
  date?: Date;
  isFocused: boolean;
  channelID: string | null;
  isPlayerVersion?: boolean;
  initialFocusedProgramEventID?: string;
  archivedPrograms?: string[] | [] | undefined;
  onFocusedEventChange: (event: Event | null) => void;
  onLeave: (direction: NavigationDirection) => void;
  onClick?: (event: Event, isCatchup: boolean, isCurrentEvent: boolean) => void;
}>;

const EPGPageEvents: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [days] = React.useState(makeWeekDays);
  const todayIndex = getInitialDateIndex(days);
  const [dateIndex, setDateIndex] = React.useState<number>(() => todayIndex);
  const [selectedDate, setSelectedDate] = useState(() => props.date || new Date())
  const [pivotDate, setPivotDate] = useState<Date>(()=>new Date())

  const [initialFocusedIndex, setInitialFocusedIndex] = React.useState<number | null>(0);
  const [focusedIndex, setFocusedIndex] = React.useState<number | null>(() => initialFocusedIndex);
  const [showDate, setShowDate] = React.useState<Moment | null>(null);
  const [archivedPrograms, setArchivedPrograms] = React.useState<string[] | [] | undefined>(undefined)
  const delayedChannelID = useDelayedChange<string | null>(props.channelID, DELAYED_CHANNEL_TIME_IN_MS);
  const isNeedFetchEvents = (props.channelID && props.channelID === delayedChannelID);
  const { isFetching: blackoutsIsFetching, data } = useAutoInfinityBlackouts(
    isNeedFetchEvents ? props.channelID : undefined,
  );
  const blackouts = data?.data;

  const channel = isNeedFetchEvents
    ? (findMediaItem(props.channelID, ItemObject.Channel, true) as Channel | null)
    : null;



  const isLoading = useThreeDaysEvents(
    isNeedFetchEvents && props.channelID ? props.channelID : undefined,
    selectedDate,
  );

  const { parsedData } = useProgramEventsByDate(
    isNeedFetchEvents && props.channelID ? props.channelID : undefined,
    pivotDate,
  );

  const programEvents = isNeedFetchEvents ? parsedData : undefined;



  const { data: upcomingEvents } = useFetchCurrentEvent(
    isNeedFetchEvents && !isLoading && programEvents?.[toPivotDate(new Date)]?.length == 0 ?
      props.channelID : undefined,
    10,
  );
  const items = programEvents?.[toPivotDate(selectedDate)]?.length != 0 ?
    programEvents?.[toPivotDate(selectedDate)]
    : isToday(selectedDate) ? upcomingEvents?.data : [];

  const currentEvent = useCurrentEvent(
    (isNeedFetchEvents && props.channelID) ? props.channelID : undefined,
    isNeedFetchEvents && programEvents?.data?.length == 0 ? items : undefined
  );

  const minIndex = React.useRef<number>(0);
  const maxIndex = React.useRef<number>(0);
  const fromShift = React.useRef<number>(1);
  const toShift = React.useRef<number>(0);
  const itemsWithDates: Record<number, EventWithDate> = React.useMemo(() => {
    const res: Record<number, EventWithDate> = {};

    if (!programEvents) {
      return res;
    }

    if (props.date) {
      items?.forEach((event, index) => {
        res[index] = { event, index };
      });

      minIndex.current = 0;
      maxIndex.current = items?.length ?? 0;
      return res;
    }

    let startIndex = -2;
    let lastIndex = 0;
    toShift.current = 0;
    for (let idx = todayIndex; idx < days.length; idx += 1) {
      const day = days[idx];
      const pivotDate = toPivotDate(day.toDate());
      const eventsForDay = programEvents[pivotDate]?.length ?
        programEvents[pivotDate]
        : isToday(day.toDate()) ?
          upcomingEvents?.data
          : null;

      if (eventsForDay) {
        res[lastIndex - 1] = { date: day };
        eventsForDay?.forEach((event, i) => {
          res[lastIndex + i] = { event, index: lastIndex + i - toShift.current };
        });

        toShift.current += 1;
        lastIndex += eventsForDay.length + (idx === days.length - 1 ? 0 : 1);
      }
    }
    fromShift.current = 1;
    for (let idx = todayIndex - 1; idx >= 0; idx -= 1) {
      const day = days[idx];
      const pivotDate = toPivotDate(day.toDate());
      const eventsForDay = programEvents[pivotDate];

      if (eventsForDay) {
        eventsForDay.reverse()?.forEach((event, i) => {
          res[startIndex - i] = { event, index: startIndex - i + fromShift.current };
        });
        res[startIndex - eventsForDay.length] = { date: day };

        fromShift.current += 1;
        startIndex -= eventsForDay.length + 1;
      }
    }

    minIndex.current = startIndex + fromShift.current + 1;
    maxIndex.current = lastIndex - toShift.current;
    return res;
  }, [props.date, selectedDate, days, programEvents, upcomingEvents, todayIndex]);
  const itemsLength = size(itemsWithDates);

  const getArchivedPrograms = () => {
    const arr: string[] = []

    Object.values(itemsWithDates).forEach(item => {
      const { event } = item
      const isPast = (
        event
        && (new Date(event.end_at) < new Date())
      );

      if (isPast && event) {
        arr.push(event?.id)
      }
    })
    return arr
  }

  React.useEffect(() => {
    if (!props.date && focusedIndex !== null) {
      const fromIndex = (focusedIndex - 5);
      const item = find(itemsWithDates, ['index', fromIndex]);
      setShowDate(item?.event ? moment(new Date(item.event.start_at)) : null);
    }
  }, [!props.date, itemsLength, focusedIndex]);

  const availableDays = Object.keys(programEvents || {});

  const firstDateIndex = React.useMemo(
    () => days.findIndex((day) => (day.diff(
      moment(availableDays?.[0]),
      'days',
    ) === 0)),
    [days, availableDays],
  );
  const lastDateIndex = React.useMemo(
    () => days.findIndex((day) => (day.diff(
      moment(availableDays?.[availableDays.length - 1]),
      'days',
    ) === 0)),
    [days, availableDays],
  );
  React.useEffect(() => {
    if (
      !props.date
      && focusedIndex
      && ((
        firstDateIndex >= 0
        && firstDateIndex !== dateIndex
        && focusedIndex > maxIndex.current - 4
      ) || (
        lastDateIndex < days.length
        && lastDateIndex + 1 !== dateIndex
        && focusedIndex < minIndex.current + 4
      ))
    ) {
      const nextIndex = (focusedIndex ?? 0) > 0 ? lastDateIndex + 1 : firstDateIndex;
      if (nextIndex >= 0 || nextIndex < days.length) {
        setDateIndex(nextIndex);
      }
    }
  }, [!props.date, focusedIndex, dateIndex, firstDateIndex, days.length, lastDateIndex]);

  useVerticalListNavigation2({
    maxIndex: maxIndex.current,
    minIndex: minIndex.current,
    allowNavigation: props.isFocused,
    onIndexChange: setFocusedIndex,
    onLeave: props.onLeave,
    itemsWithDates,
    focusedIndex,
    setPivotDate,
  });

  //
  const item = find(itemsWithDates, ['index', focusedIndex])?.event
  const delayedEvent = useDelayedChange<Event | undefined>(item, 200);
  //


  // show/hide pointer navigation
  const isPointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);

  React.useEffect(() => {
    if (props.date) {
      setSelectedDate(props.date)
    }
  }, [props.date])

  React.useEffect(() => {
    if (props.isFocused && isPointerEnabled && focusedIndex !== null) {
      updateDirections({
        [NavigationDirection.Up]: (focusedIndex >= minIndex.current),
        [NavigationDirection.Down]: (focusedIndex < maxIndex.current),
        [NavigationDirection.Left]: true,
        [NavigationDirection.Right]: true,
      });
    }
  }, [
    focusedIndex,
    props.isFocused,
    isPointerEnabled,
  ]);

  React.useEffect(() => {
    if (!props.isFocused) {
      setInitialFocusedIndex(focusedIndex);
    }
  }, [props.isFocused]);

  React.useEffect(() => {
    if (!props.isFocused) {
      setInitialFocusedIndex(null);
      setFocusedIndex(null);
    }
  }, [props.channelID, selectedDate.toString()]);

  React.useEffect(() => {
    if (
      items !== undefined && items.length !== 0
      // && items[0].channel_id === props.channelID
      && initialFocusedIndex === null
    ) {
      if (props.initialFocusedProgramEventID) {
        const focusedEventIndex = items.findIndex(({ id }) => (id === props.initialFocusedProgramEventID));
        const focusedIndexValue = (focusedEventIndex >= 0)
          ? focusedEventIndex
          : getFocusedEventIndex(items);

        setInitialFocusedIndex(focusedIndexValue);
        setFocusedIndex(focusedIndexValue);
        if (getFocusedLineFromURL(history) !== focusedIndexValue && props.isFocused) {
          setFocusOn(history, focusedIndexValue);
        }
      }
      else {
        let focusedIndexValue = (props.isFocused)
          ? getFocusedLineFromURL(history)
          : getFocusedEventIndex(items);

        if (focusedIndexValue === null) {
          focusedIndexValue = getFocusedEventIndex(items);
        }
        setInitialFocusedIndex(focusedIndexValue);
        setFocusedIndex(focusedIndexValue);
        if (getFocusedLineFromURL(history) !== focusedIndexValue && props.isFocused) {
          setFocusOn(history, focusedIndexValue);
        }
      }
    }
  }, [
    items,
    currentEvent?.id,
    initialFocusedIndex,
    props.isFocused,
    props.initialFocusedProgramEventID,
  ]);

  React.useEffect(() => {
    if (focusedIndex === null) {
      props.onFocusedEventChange(null);
    }
    else {
      props.onFocusedEventChange(
        find(itemsWithDates, ['index', focusedIndex])?.event || null
      );
    }
  }, [focusedIndex, itemsLength]);

  React.useEffect(() => {
    const programs = getArchivedPrograms()
    if (programs.length > 0) {
      setArchivedPrograms(programs)
    }
  }, [history])


  const renderItems = (
    <EPGPageEventsList
      key='EPGPageEventsList'
      channel={ channel as Channel }
      blackouts={ blackouts }
      isFocused={ props.isFocused }
      focusedIndex={ focusedIndex as number }
      activeIndex={ focusedIndex as number }
      items={ itemsWithDates }
      currentEvent={ currentEvent || null }
      fromShift={ fromShift.current }
      toShift={ toShift.current }
      onClick={ props.onClick }
      archivedPrograms={ archivedPrograms }
    />
  );
  const focusedIdx = parseInt(findKey(itemsWithDates, ['index', focusedIndex]) ?? '0');


  return (
    <div className={ cn(styles.eventsList, {
      [styles.eventsListPlayerVersion]: props.isPlayerVersion,
    }) }>
      {
        (!props.isPlayerVersion) &&
          <SceneManagerFetcher
              id={ delayedEvent?.id }
              object={ delayedEvent?.object }
              withInfo={ false }
          />
      }
      <Fade
        key='EPGPageEventsListFade'
        isVisible={ (renderItems !== null) }
        duration={ 300 }
        delay={ 0 }
        className={ cn(styles.eventsListFade, { [styles.showDate]: !!showDate }) }
      >
        <EPGPageEventsListScrollWrapper
          focusedIndex={ isNeedFetchEvents ? focusedIdx : null }
          className={ cn(styles.eventsListScrollWrapper, { [styles.showDate]: !!showDate }) }
          offsetShift={ valueInPixelsByWidth(props.isPlayerVersion ? 20.078 : 24.375) }
        >
          {
            (
              !isNeedFetchEvents
              || !channel
              || blackoutsIsFetching
              || !programEvents
              || focusedIndex === null
            )
              ? null
              : renderItems
          }
        </EPGPageEventsListScrollWrapper>
      </Fade>
      <EventsPlaceholders
        isVisible={ !isNeedFetchEvents || isLoading }
      />
      <NoEPGText
        noEvents={ !!isNeedFetchEvents && !isLoading && itemsLength === 0 }
        offsetShift={ props.isPlayerVersion ? 21.094 : 25.391 }
      />
      {
        showDate ? (
          <DateItem
            kind="Moment"
            item={ showDate }
            idx={ 0 }
          />
        ) : null
      }
    </div>
  );
};


export default React.memo(EPGPageEvents);
