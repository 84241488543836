// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutInfoFields--ItOCr {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n     -moz-box-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n}\n\n.field--UQu1h + .field--UQu1h {\n  /* 16px */\n  margin-top: 1.25vw;\n}\n\n.fieldLabel--MKof2 {\n  color: rgba(162, 162, 173, 1);\n  display: inline;\n}\n\n.fieldValue--GqClT {\n  color: rgba(255, 255, 255, 1);\n  display: inline;\n}\n\n\n.copyright--aZeNI {\n  margin-top: 3.125vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/About/AboutInfoFields.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,uBAA2B;EAA3B,mCAA2B;KAA3B,oBAA2B;UAA3B,2BAA2B;EAC3B,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,6BAAa;EACb,eAAe;AACjB;;AAEA;EACE,6BAAa;EACb,eAAe;AACjB;;;AAGA;EACE,mBAAmB;AACrB","sourcesContent":[".AboutInfoFields {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.field + .field {\n  /* 16px */\n  margin-top: 1.25vw;\n}\n\n.fieldLabel {\n  color: $gw_06;\n  display: inline;\n}\n\n.fieldValue {\n  color: $gw_11;\n  display: inline;\n}\n\n\n.copyright {\n  margin-top: 3.125vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var AboutInfoFields = "AboutInfoFields--ItOCr";
export var field = "field--UQu1h";
export var fieldLabel = "fieldLabel--MKof2";
export var fieldValue = "fieldValue--GqClT";
export var copyright = "copyright--aZeNI";
export default ___CSS_LOADER_EXPORT___;
