// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DropdownSelectButtons--RfFrb {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n     -moz-box-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n}\n\n.button--f0RVb {\n  /* 20px */\n  margin-right: 1.5625vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/DropdownSelectPopup/components/DropdownSelectButtons/DropdownSelectButtons.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,uBAA2B;EAA3B,mCAA2B;KAA3B,oBAA2B;UAA3B,2BAA2B;EAC3B,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,sBAAsB;AACxB","sourcesContent":[".DropdownSelectButtons {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.button {\n  /* 20px */\n  margin-right: 1.5625vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var DropdownSelectButtons = "DropdownSelectButtons--RfFrb";
export var button = "button--f0RVb";
export default ___CSS_LOADER_EXPORT___;
