import create, { SetState } from 'zustand';

import NavigationDirection from '~typings/NavigationDirection';
import PointerNavigation from '~typings/PointerNavigation';


const INITIAL_STATE = {
  directions: {
    [NavigationDirection.Down]: false,
    [NavigationDirection.Left]: false,
    [NavigationDirection.Right]: false,
    [NavigationDirection.Up]: false,
    [PointerNavigation.Menu]: false,
    [PointerNavigation.Back]: false,
  },
  pointerEnabled: false,
}

export interface State {
  directions: Record<NavigationDirection & PointerNavigation, boolean>;
  pointerEnabled: boolean;
  setPointerEnabled: (next: boolean) => void;
  updateDirections: (next: Partial<{
    [key in keyof typeof NavigationDirection | PointerNavigation]: boolean;
  }>) => void;
  flush: (directions?: Array<NavigationDirection | PointerNavigation>) => void;
}

const usePointer = create<State>(
  (set: SetState<State>) => ({
    directions: INITIAL_STATE.directions,
    pointerEnabled: INITIAL_STATE.pointerEnabled,

    setPointerEnabled: (next: boolean) => set((draft: State) => {
      draft.pointerEnabled = next;
    }),
    updateDirections: (next) =>  set((draft: State) => {
      Object.keys(next).forEach((key) => {
        draft.directions[key] = next[key];
      });
    }),
    flush: (directions) => set((draft: State) => {
      (directions || Object.keys(INITIAL_STATE.directions))
        .forEach((key) => {
          draft.directions[key] = false;
        });
    }),
  })
);


export default usePointer;
