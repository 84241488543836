import { createPortal } from 'react-dom';

import usePortal from '~hooks/usePortal';


export interface Props {
  id: string;
  children?: React.ReactNode | React.ReactNode[];
}
/**
 * @example
 * <Portal>
 *   <p>Thinking with portals</p>
 * </Portal>
 */
const Portal: React.FC<Props> = ({ id, children }) => {
  const target = usePortal(id);
  return createPortal(
    children,
    target,
  );
};


export default Portal;
