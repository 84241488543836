import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import MediaItemDetails from '~components/MediaItemDetails';


const MediaItemDetailsScreen: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={ path }>
        <div>404</div>
      </Route>
      <Route path={ `${path}/:itemObject/:itemId` }>
        <MediaItemDetails />
      </Route>
      <Route exact path={ `${path}/:itemObject` }>
        <div>404</div>
      </Route>
    </Switch>
  );
};

export { MediaItemDetailsScreen };

export default MediaItemDetailsScreen;
