import React, { memo } from 'react';

import { useApp } from '~components/Provider/App';
import useIsPlayerPage from "~hooks/useIsPlayerPage";
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Stream from '~typings/Stream';

import PlayerControls from './Player/controls';
import useAudioTracks from "./Player/useAudioTracks";
import useHandleEvents, { PlayerStatus } from './Player/useHandleEvents';
import useVideoTracks from "./Player/useVideoTracks";
import { IPlayer } from './playerLib/typings';
import PlayerLoadScreen from './PlayerLoadScreen';


type Props = Readonly<{
  item: PlayerMediaItem;
  stream: Stream;
  player: IPlayer;
  onCanPlay: () => void;
  onEnded: () => void;
  onError: (error: any) => void;
}>;


// const WAITING_TIMEOUT = 30000;


// eslint-disable-next-line react/display-name
const Player4 = memo((props:Props) => {
  const [isCanPlay, setIsCanPlay] = React.useState<boolean>(false);
  const isPlayerPage = useIsPlayerPage();
  const { isOnLine } = useApp();

  const handleCanPlay = () => {
    setIsCanPlay(true);
    props.onCanPlay();
  };
  const state = useHandleEvents({
    player: props.player,
    onCanPlay: handleCanPlay,
    onEnded: props.onEnded,
    onError: props.onError,
  });

  const handlePlayPauseClick = React.useCallback(() => {
    if (state.status === PlayerStatus.Play) {
      props.player.pause();
      // @ts-ignore
    }
    else {
      // If We Try to Play After Pause Media Item (Live Channel Without Time Shift)
      // We Must Play Without Time Shifting
      // if (isTimeShiftAllowed(props.item) ) {
      //   props.player.seekTo(0);
      // } else {
      props.player.play();
      // @ts-ignore
      // }
    }
  }, [state.status]);
  const handleSeek = React.useCallback((seekTo) => {
    props.player.seekTo(seekTo);
  }, []);

  useAudioTracks({
    state,
    player: props.player,
  });
  useVideoTracks({
    state,
    player: props.player,
  });

  React.useEffect(() => {
    if (isOnLine) {
      props.player.play();
    }
    else {
      props.player.pause();
    }
  }, [isOnLine]);

  return React.useMemo(() => {
    if (!isOnLine) {
      return null;
    }

    if (!isPlayerPage || !isCanPlay) {
      return <PlayerLoadScreen />;
    }

    if (state.status === PlayerStatus.Loading) {
      return null;
    }

    return (
      <PlayerControls
        player={ props.player }
        item={ props.item }
        state={ state }
        stream={ props.stream }
        durationInSec={ props.player.getDuration() }
        getCurrentTimeInSec={ props.player.getCurrentTime }
        onClickPlayPause={ handlePlayPauseClick }
        onSeek={ handleSeek }
      />
    );
  }, [isCanPlay, isOnLine, state.status, props.item.id, isPlayerPage]);
})

export { Player4 }