import { find } from 'lodash';

import bringingAudioTrackNameToStandard from '~lib/bringingAudioTrackNameToStandard';
import getSecontsFromPercents from "~lib/player/getSecontsFromPercents";
import sortVideoTracks from '~lib/player/sortVideoTracks';
import AudioTrack from '~typings/AudioTrack';
import { ManifestVideoTrack } from '~typings/Manifest'
import PlayerType from '~typings/PlayerType';
import VideoTrack from '~typings/VideoTrack';

import { createHTMLVideoElement, Events, prepareTimeShiftUrl, resetHTMLVideoElement } from '../common';
import getSourceTypeAttribute from '../getSourceTypeAttribute';
import handleSourceError from '../handleSourceError';
import { HTMLVideoElWithTracks, IPlayer, PlayerEventSubscribe,PlayerLoadParameters, Props } from '../typings';
import onError from './error';


let selectedVideoTrack: ManifestVideoTrack | null = null;
let params: PlayerLoadParameters | null = null;
let seekToTime: number | null = null;

const HTML5Player = ({ videoContainer, event }: Props): IPlayer => {

  const getVideoTag = (): HTMLVideoElWithTracks | null =>
    (
      videoContainer.getElementsByTagName('video') as HTMLCollectionOf<HTMLVideoElWithTracks>
    )?.[0] || null;

  const isTracksFromManifest = () => ((params?.manifest?.video || []).length > 0);

  const handleTrackChanged = () => {
    event.emit(Events.TRACK_CHANGED);
  };

  const dispose = () => {
    try {
      const videoElement = getVideoTag();

      if (videoContainer && videoElement) {
        videoContainer.removeChild(videoElement);
      }
    } catch (ignore) {

    }
  };

  const getCurrentTime = (): number => getVideoTag()?.currentTime || 0;

  const getDuration = (): number => {
    return getVideoTag()?.duration || 0
  };

  async function reset() {
    event.removeAllListeners();
    params = null;
    selectedVideoTrack = null;

    resetHTMLVideoElement(videoContainer, getVideoTag());
  }

  const handleSeekOnCanPlay = () => {
    const videoElement = getVideoTag();

    if (seekToTime !== null && videoElement?.currentTime !== undefined) {
      pause();
      videoElement?.removeEventListener('canplay', handleSeekOnCanPlay);

      setTimeout(() => {
        if (seekToTime !== null && videoElement?.currentTime !== undefined) {
          videoElement.currentTime = seekToTime;
          play();
          seekToTime = null;
          handleTrackChanged();
        }
      }, 300);
    }
  };

  const handleChangeSource = (trackURL: string) => {
    const videoElement = getVideoTag();
    let isChangedSource = false;

    try {
      if(params && videoElement) {
        const sources = videoElement.getElementsByTagName('source') || [];

        for (let i = 0; i < sources.length; i += 1) {
          const source = sources[i];

          if (source.getAttribute('src') === trackURL) {
            const cloned = videoElement.insertBefore(source.cloneNode(true), sources[0]);
            cloned.addEventListener('error', err => handleSourceError(event, err));
            videoElement.load();
            source.remove();
            isChangedSource = true;
            videoElement.addEventListener('loadedmetadata', handleSeekOnCanPlay);
            break;
          }
        }
      }
    } catch (ignore) {
      // console.log('ignore:', ignore);
    }

    return isChangedSource;
  };

  const handleReadyToPlay = (url, sourceType, percentsWatched) => {
    try {
      const videoElement = createHTMLVideoElement(event);
      videoContainer?.appendChild(videoElement);

      const source = document.createElement('source');
      source.setAttribute('src', url);

      if (sourceType) {
        source.setAttribute('type', sourceType);
      }

      source.addEventListener('error', err => handleSourceError(event, err));

      videoElement?.appendChild(source);

      params?.manifest?.video?.forEach((video) => {
        const source = document.createElement('source');
        source.setAttribute('src', video.url);

        if (sourceType) {
          source.setAttribute('type', sourceType);
        }

        videoElement?.appendChild(source);
      });

      videoElement.addEventListener('loadedmetadata', () => {
        if (percentsWatched) {
          seekTo(getSecontsFromPercents(percentsWatched, getDuration()));

          play();
        }
      });
    } catch (error) {
      onError(event, error);
    }
  };

  async function load(parameters: PlayerLoadParameters) {
    await reset();

    params = parameters;

    const playerUrl = prepareTimeShiftUrl(parameters.stream.url, parameters.timeShiftParams);
    const sourceType = getSourceTypeAttribute({ url: parameters.stream.url, drm: parameters.stream.drm });

    try {
      handleReadyToPlay(playerUrl, sourceType, parameters.percentsWatched);
    } catch (error) {
      console.error('catch load:', error);

      onError(event, error);
    }
  }

  const mute = () => {};

  const on: PlayerEventSubscribe = (target, listener) => {
    event.on(target, listener);
  };

  const removeListener: PlayerEventSubscribe = (target, listener) => {
    event.removeListener(target, listener);
  };

  const play = (): void => {
    getVideoTag()?.play();
  };

  const pause = () => {
    getVideoTag()?.pause();
  };

  const seekTo = (timePosition: number) => {
    const videoElement = getVideoTag();

    if (videoElement?.currentTime !== undefined) {
      play();
      videoElement.currentTime = timePosition;
    }
  };

  const stop = () => {
    reset();
  };

  const isAutoQualityEnabled = () => (selectedVideoTrack === null);

  const switchSrc = (track: ManifestVideoTrack | null): boolean => {
    const videoElement = getVideoTag();
    let isSwitchedSRC = false;

    try {
      if (isTracksFromManifest() && videoElement && params) {
        seekToTime = getCurrentTime();

        const isChangedSource = handleChangeSource(track?.url || params.stream.url);

        if (isChangedSource) {
          isSwitchedSRC = true;
        }
      }
    } catch (ignore) {
      // console.log('switchSrc ignore:', ignore);
    }

    return isSwitchedSRC;
  };

  const setAutoQuality = (enabled) => {
    const videoElement = getVideoTag();

    try {
      if (enabled && isTracksFromManifest() && params && videoElement) {
        const isSwitchedSRC = switchSrc(null);

        if (isSwitchedSRC) {
          selectedVideoTrack = null;
        }
      }
    } catch (ignore) {

    }
  };

  const changeAudioTrack = (audioTrack: AudioTrack) => {
    const videoElement = getVideoTag();

    if (videoElement?.audioTracks) {
      try {
        for (var index = 0; index < videoElement?.audioTracks.length; index += 1) {
          videoElement.audioTracks[index].enabled = (index === audioTrack.index);
        }
        handleTrackChanged();
      } catch (ignore) {
        // ignore
      }
    }
  };

  const changeVideoTrack = (videoTrack: VideoTrack) => {
    try {
      const videoElement = getVideoTag();

      if (videoElement) {
        if (isTracksFromManifest()) {
          const isSwitchedSRC = switchSrc(videoTrack.data as ManifestVideoTrack);

          if (isSwitchedSRC) {
            selectedVideoTrack = videoTrack.data as ManifestVideoTrack;
          }
        } else if (videoElement?.videoTracks) {
          for (var index = 0; index < videoElement?.videoTracks.length; index += 1) {
            videoElement.videoTracks[index].selected = (index === videoTrack.index);
          }
          handleTrackChanged();
        }
      }
    } catch (ignore) {
      // ignore
    }
  };
  const getAudioTracks = () => {
    const videoElement = getVideoTag();
    const audioTracks: AudioTrack[] = [];

    if (videoElement?.audioTracks && videoElement.videoTracks) {
      try {
        for (var index = 0; index < videoElement.videoTracks.length; index += 1) {
          audioTracks.push({
            index,
            isSelected: videoElement.audioTracks[index].enabled,
            name: bringingAudioTrackNameToStandard(
              videoElement.audioTracks[index].language
              || videoElement.audioTracks[index].label
            ),
          });
        }
      } catch (ignore) {
        // ignore
      }
    }

    return audioTracks;
  };

  const getVideoTracks = () => {
    const videoTracks: VideoTrack[] = [];

    try {
      const videoElement = getVideoTag();

      if (isTracksFromManifest()) {
        (params?.manifest?.video || []).map((video, index) => {
          videoTracks.push({
            index,
            isSelected: (selectedVideoTrack?.url === video.url),
            name: video.name,
            data: video,
          });
        })
      } else if (videoElement?.videoTracks) {
        for (let index = 0; index < videoElement?.videoTracks.length; index += 1) {
          const name = (
            videoElement.videoTracks[index].language || videoElement.videoTracks[index].label
          ).replace('`', '');
          videoTracks.push({
            index,
            isSelected: videoElement.videoTracks[index].selected,
            name,
          });
        }
      }
    } catch (ignore) {
        // ignore
      }

    return sortVideoTracks(videoTracks);
  };

  const getSelectedAudioTrack = () => {
    find(getAudioTracks(), { isSelected: true });
  };

  const getSelectedVideoTrack = () => {
    find(getVideoTracks(), { isSelected: true });
  };

  const getProperties = () => {
    const videoElement = getVideoTag();

    if (!videoElement) { return null; }

    return {
      videoPlaybackQuality: videoElement?.getVideoPlaybackQuality ?
        videoElement.getVideoPlaybackQuality() : null,
    };
  };

  return {
    type: PlayerType.HTML5,

    dispose,
    getCurrentTime,
    getDuration,
    load,
    mute,
    on,
    removeListener,
    pause,
    play,
    reset,
    seekTo,
    stop,

    isAutoQualityEnabled,
    setAutoQuality,

    changeAudioTrack,
    changeVideoTrack,
    getAudioTracks,
    getVideoTracks,
    getSelectedAudioTrack,
    getSelectedVideoTrack,

    getProperties,
  };
};


export default HTML5Player;
