import * as React from 'react';

import Loader from '~components/LightLoader/Loader';
import { useApp } from '~components/Provider/App';
import {
  AuthActionType,
  useAuthMutation,
  useDeviceCodeMutation
} from '~hooks/fetch/useAccount';
import { useInterval } from '~hooks/useInterval';
import {
  isClientCodeExpired,
  isClientCodeInvalid,
  isClientCodeNotActivated
} from '~lib/ApiClient/error-checkers';
import { SpinnerSearch } from "~newapp/globalBlocks/spinner";

import * as styles from './styles.module.css';

type Props = Readonly<{
  onSuccess: () => void;
  onError: (errorMessage: string, error?: any) => void;
  className?: string
}>;

const CodeAuthBlock: React.FC<Props> = ({ onSuccess, onError, className }: Props) => {
  const { isAuthorized } = useApp();
  const {
    mutate: getDeviceCode,
    data: deviceCode,
  } = useDeviceCodeMutation();
  const { mutateAsync: signIn } = useAuthMutation();

  const checkDeviceCode = async () => {
    if (!isAuthorized) {
      try {
        await signIn(
          { type: AuthActionType.AuthByDeviceCode, payload: { deviceCode } },
          { onSuccess }
        );
      } catch (error) {
        if (isClientCodeExpired(error)) {
          getDeviceCode();
        }
        else if (isClientCodeInvalid(error)) {
          getDeviceCode();
        }
        else if (!isClientCodeNotActivated(error)) {
          onError('Unknown Error', error); // TODO
        }
      }
    }
  };

  useInterval(checkDeviceCode, deviceCode ? 5000 : null);
  React.useEffect(() => getDeviceCode(), []);


  const loader = () => {
    if (__PROJECT_NAME__ === 'popcorn') {
      return <SpinnerSearch />
    }

    return <Loader />
  }


  return (
    <div className={ (className ?? styles.codeValue) }>
      { deviceCode ?? loader() }
    </div>
  );
};


export default CodeAuthBlock;
