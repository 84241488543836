import * as React from 'react';
import { InjectedIntl,injectIntl } from 'react-intl';

import InputWithKeyboard, { FocusOn } from '~components/InputWithKeyboard';
import usePassword from '~hooks/usePassword';
import { newFieldValue } from '~lib/keyboard';
import { KeyBoardKey, KeyBoardType } from '~typings/Keyboard';
import NavigationDirection from '~typings/NavigationDirection';


type InputFormProps = Readonly<{
  defaultValue?: string;
  isFocused?: boolean;
  keyboardType: KeyBoardType;
  placeholder?: string;

  onDone: (value: string) => void;
  onLeave?: (direction: NavigationDirection) => void;

  intl: InjectedIntl;
}>;

/**
 * @deprecated
 * Это старый компонент, со старым дизайном и старой навигацией.
 * Есть новый - InputFormFocuser
 */
const InputForm: React.FC<InputFormProps> = (props: InputFormProps) => {
  const [ value, setValue ] = React.useState<string>(props.defaultValue ?? '');
  const [isShowingPassword, setIsShowingPassword] = React.useState(
    props.keyboardType !== KeyBoardType.Password
  );

  React.useEffect(() => {
    if (props.defaultValue) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue])

  const escapedValue = usePassword({
    password: value.toString(),
    isShowingPassword,
  });

  const handleValueChange = (key: KeyBoardKey) => {
    setValue(oldValue => newFieldValue(oldValue, key))
  };

  const handleClearClick = () => {
    setValue('');
  };

  const handleEyeClick = () => {
    setIsShowingPassword(!isShowingPassword);
  };

  const handleFormSubmit = () => {
    props.onDone(value);
  };

  return (
    <InputWithKeyboard
      inputValue={ escapedValue }
      focusOn={ props.isFocused ? FocusOn.KeyBoard : null }
      placeholder={ props.placeholder }
      keyBoardType={ props.keyboardType }
      onLeave={ props.onLeave }
      onChange={ handleValueChange }
      onClear={ handleClearClick }
      onSubmit={ handleFormSubmit }
      onEyeClick={ handleEyeClick }
    />
  );
};


export default injectIntl(InputForm);
