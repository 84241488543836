import { parse, stringify } from 'query-string';
import React from 'react';
import { useHistory } from 'react-router-dom';

import useLocationSearch from "~hooks/useLocationSearch";

/**
 * Хук помогает выполнять CRUD действия над параметром из URL
 * @param pathInSearch - имя параметра в URL
 * Тип `T` - тип параметра, который хотим получить
 */
export const useLocationSearchState = <T>({
  pathInSearch
}: {
  pathInSearch: string
}) =>{
  const history = useHistory();
  const locationSearch = useLocationSearch();

  const parsedState: T | undefined = React.useMemo(() => {
    const encodedField = locationSearch[pathInSearch];
    if (!encodedField) {
      return;
    }
    try {
      return JSON.parse(encodedField as string);
    } catch {
      return;
    }
  }, [locationSearch, pathInSearch]);


  const clearState = () => {
    const locationSearchCopy = { ...parse(history.location.search) };
    delete locationSearchCopy[pathInSearch];
    history.replace({
      search: stringify(locationSearchCopy),
    });
  }

  const setNewState = (newState: T) => {
    history.replace({
      search: stringify({
        ...parse(history.location.search),
        [pathInSearch]: JSON.stringify(newState)
      })
    });
  }

  return {
    parsedState,
    clearState,
    setNewState
  }
}
