import { DeafultContext, FocuserContext } from '@focuser/context';
import React from 'react';

import { useEventsBlocker, useIsolationDivUpdater, usePointerEventOnUnmount } from './utils';

export interface Props {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

/**
 * Помогает создать изолированную ветку `focuser`-а.
 */
export const Isolation: React.FC<Props> = React.memo(({ children, className }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  useEventsBlocker({ref});
  useIsolationDivUpdater({ref});
  usePointerEventOnUnmount();

  return (
    <FocuserContext.Provider value={ DeafultContext }>
      <div className={ className } ref={ ref } >
      {children}
      </div>
    </FocuserContext.Provider>
  );
});


Isolation.displayName = 'FocuserIsolation';
