import { useMutation } from 'react-query';

import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';


export type SendPasswordBySmsResult = Readonly<{
  data: null;
  meta: {
    status: number;
  };
}>;


const fetch = async (
  client: ApiClient,
  username: string,
  password: string,
  code?: string
): Promise<SendPasswordBySmsResult> => client.put(
  '/v1/users/passwords.json',
  {
    username,
    password,
    code
  },
);


export const useResetPasswordMutation = () => {
  const client = useClient();

  return useMutation(({
    username,
    password,
    code
  }: {
      username: string,
      password: string,
      code?: string
  }) => (
    fetch(client, username, password, code)
  ));
}


export default useResetPasswordMutation;
