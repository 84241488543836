import focuser, { useFocuserContext } from '@focuser';
import cn from 'classnames';
import { chunk } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { handleClickOnCard } from '~components/Card/Card.common.utils';
import { useAnimationContext } from '~components/Provider/Animation';
import { useInfinityCardColItems } from '~hooks/fetch/useCCI';
import useDeepLink from '~hooks/useDeepLink';
import { usePaginationByItems } from '~hooks/usePagination';
import { RknPlate } from '~newapp/ui/rkn-plate';
import { showRknForLayout } from '~newapp/utils/globals/rkn';
import { CardConfig } from '~typings/ScreenCardConfigs';
import { SelectedFilters } from '~typings/SelectedFilters';
import { SliderVerticableVirtualNotMemo } from '~ui/SliderVerticable';

import {
  FocusedState,
  useFocuserClickHandler,
  useGridNavigation,
} from './CardCollectionGrid.hooks';
import * as styles from './CardCollectionGrid.module.css';
import { chunkByLayout, getHeightByCardConfig, GRID_ANIMATION_MS, maxInDomByLayout } from './CardCollectionGrid.utils';
import { CardCollectionLoading } from './components/CardCollectionLoading';
import { CardCollectionNotFound } from './components/CardCollectionNotFound';
import { CardCollectionRow } from './components/CardCollectionRow';

interface Props {
  /**
   * Идентификатор коллекции
   */
  cardCollectionId: string;
  /**
   * Выбранные фильтры, по которым будут загружаться карточки
   */
  selectedFilters?: SelectedFilters;
  /**
   * cardConfig, по которому нужно отображать карточки. Можно получить из коллекции (useCardCollection)
   */
  cardConfig: CardConfig;
  /**
   * Коллбэк, которые оповещает что навигация изменилась
   * Полезно в случаях когда нужно что-то сделать, например сдивинуть грид наверх, когда он на второй строке
   */
  onNavigationChange?: (focusedState: FocusedState) => void;
  /**
   * Запоминать фокус на карточке при уходе со страницы.
   * Фокус запоминается в URL-е, (подробнее смотреть в useLocationSearchState).
   * Нужно не забыть, что если использованы фильтры, то фильтры тоже нужно запоминать
   */
  rememberFocusOnLeave?: boolean;
  /**
   * Зафиксировать слайдер на первом ряду.
   * Полезно когда грид рендерится где-то в родителе,
   * которому нужно чтобы грид не скроллился пока не выполнено какое-то условие
   */
  forceSliderOnFirstRow?: boolean;
  /**
   * Ref, в котором будет ссылка на грид. Полезно если нужно проскролить родитель до грида
   */
  forwardRef?: React.RefObject<HTMLDivElement>;
  /**
   * Коллбэк, который будет вызван, когда изменяется доступность фокуса на грид.
   * Например когда карточки не найдены, или идет загрузка, коллбэк будет вызван с `false`
   */
  onFocusAvailabilityChange?: (canFocus: boolean) => void;
  /**
   * Коллбэк будет вызван, если грид понял что может восстановить фокус (то есть кэш на месте, карточка доступна).
   * Полезно для случаев, когда нужно в родителе тоже восстановить фокус.
   * Отработает только когда `rememberFocusOnLeave` передан
   */
  beforeFocusRestore?: (focusedStateToBe: FocusedState) => void;
  /**
   * Дополнительный класс
   */
  className?: string;
}


const CardCollectionGrid$: React.FC<Props> = ({
  selectedFilters,
  cardCollectionId,
  cardConfig,
  forceSliderOnFirstRow,
  onNavigationChange,
  rememberFocusOnLeave,
  forwardRef,
  onFocusAvailabilityChange,
  beforeFocusRestore,
  className,
}) => {
  const { query: query, parsedData: data } = useInfinityCardColItems({
    cardCollectionId,
    selectedFilters,
  });

  const isLoading = query.isLoading;
  const isNoResults = !isLoading && !data?.meta.pagination.total;

  const { urlFromDeepLink } = useDeepLink();
  const history = useHistory();

  const { isAnimationEnabled } = useAnimationContext();
  const needToShowRKNPlate = showRknForLayout(cardConfig.layout);

  const cardsByChunks = React.useMemo(() => {
    if (!data) {
      return [];
    }
    return chunk(data.data, chunkByLayout[cardConfig.layout]);
  }, [data?.data, cardConfig.layout]);

  const { focusedState, rememberFocusedStateInUrl, isOnFirstRow, isOnLastRow, ...focuserProps } =
    useGridNavigation({
      cardsByChunks,
      selectedFilters,
      restoreFocusFromUrl: rememberFocusOnLeave,
      beforeFocusRestore,
    });

  const onClick = useFocuserClickHandler({
    focusedState,
    cardsByChunks,
    clickHandler: (card) => {
      if (rememberFocusOnLeave) {
        rememberFocusedStateInUrl(focusedState);
      }
      handleClickOnCard({ history, card, urlFromDeepLink });
    },
  });

  const { isFocused } = useFocuserContext();

  useEffect(() => {
    onNavigationChange?.(focusedState);
  }, [focusedState]);

  usePaginationByItems({
    currentFetchedCount: data?.data.length ?? 0,
    focusedLineIdx: focusedState.rowIdx,
    elementsPerLine: chunkByLayout[cardConfig.layout],
    fetchMoreData: () => {
      if (query.hasNextPage && !query.isFetchingNextPage) {
        query.fetchNextPage();
      }
    },
    minimalLinesOnScreen: 4,
  });

  useEffect(() => {
    if (isNoResults || isLoading) {
      onFocusAvailabilityChange?.(false);
    } else {
      onFocusAvailabilityChange?.(true);
    }
  }, [isNoResults, isLoading]);

  const { itemHeight, itemHeightForSlider } = useMemo(() => {
    const itemHeight = getHeightByCardConfig(cardConfig);
    // 3.125 это margin между строками грида
    const itemHeightForSlider = itemHeight + 3.125;
    return {
      itemHeight,
      itemHeightForSlider,
    };
  }, [cardConfig]);



  if (isLoading) {
    return <CardCollectionLoading />;
  }

  if (isNoResults) {
    return <CardCollectionNotFound />;
  }


  const sliderProps = (() => {
    const isOnFirstOrLastRow = isOnFirstRow || isOnLastRow;

    const focusedIndex = (() => {
      if (forceSliderOnFirstRow) {
        return 0;
      }

      if (!needToShowRKNPlate) {
        return focusedState.rowIdx;
      }

      if (isOnLastRow) {
        if (cardsByChunks.length > 1) {
          return focusedState.rowIdx + 1;
        }
      }

      return focusedState.rowIdx;
    })();

    return {
      transitionGapUpVW: isOnFirstOrLastRow ? 0 : 2.34375,
      transitionGapDownVW: isOnFirstOrLastRow ? 0 : 0.78125,
      focusedIndex: focusedIndex,
      childsCount: needToShowRKNPlate ? cardsByChunks.length + 1 : cardsByChunks.length
    };
  })();


  const renderChild = (idx: number) => {
    const cards = cardsByChunks[idx];
    if (!cards && idx === cardsByChunks.length && needToShowRKNPlate) {
      return <RknPlate />;
    }

    if (!cards) {
      return null;
    }

    return (
      <CardCollectionRow
        key={ idx }
        className={ styles.row }
        cards={ cards }
        cardConfig={ cardConfig }
        focusedIdx={ isFocused && focusedState.rowIdx === idx ? focusedState.columnIdx : null }
        rowIdx={ idx }
        itemHeightVW={ itemHeight }
      />
    );
  };

  return (
    <focuser.FocusableBlock
      isFocused
      { ...focuserProps }
      forwardRef={ forwardRef }
      className={ cn(styles.grid, className) }
      onClick={ onClick }
      noNeedForceFocus
    >
      <SliderVerticableVirtualNotMemo
        wrapperClassName={ styles.sliderWrapper }
        sliderClassName={ styles.slider }
        renderChild={ renderChild }
        itemHeight={ itemHeightForSlider }
        inVW
        maxInDOM={ maxInDomByLayout[cardConfig.layout] }
        animationDurationInMS={ isAnimationEnabled ? GRID_ANIMATION_MS : 0 }
        childsCount={ sliderProps.childsCount }
        focusedIndex={ sliderProps.focusedIndex }
        transitionGapUpVW={ sliderProps.transitionGapUpVW }
        transitionGapDownVW={ sliderProps.transitionGapDownVW }
      />
    </focuser.FocusableBlock>
  );
};

/**
 * Компонент грида, который показывает грид переданной коллекции
 */
export const CardCollectionGrid = React.memo(CardCollectionGrid$);
