import * as React from 'react';
import { Spring } from 'react-spring/renderprops';


type Props = Readonly<{
  index: number;
  isDirectionUp: boolean;
  focusedLine: number;
  prevFocusedLine: number;
  children: any;
  showAlert: boolean;
}>;


const SLIDE_DURATION = 400;

const LineDelayScrollSlide: React.FC<Props> = (props: Props) => {
  const { showAlert } = props
  const movePercent = showAlert ? 130 : 100

  const slideFrom = {
    transform: `translate3d(0, ${ props.index * movePercent }%, 0)`,
    WebkitTransform: `translate3d(0, ${ props.index * movePercent }%, 0)`,
  };
  const slideTo = {
    transform: `translate3d(0, -${ props.focusedLine * movePercent }%, 0)`,
    WebkitTransform: `translate3d(0, -${ props.focusedLine * movePercent }%, 0)`,
  };

  const slideDelay = (props.isDirectionUp)
    ? (props.index > props.focusedLine)
      ? 0
      : (props.index + 1) * 100
    : (props.index - props.prevFocusedLine) * 100;

  return (
    <Spring
      config={ {
        delay: slideDelay,
        duration: SLIDE_DURATION,
      } }
      from={ slideFrom }
      to={ slideTo }
    >
      {
        style => <div style={ style }>{ props.children }</div>
      }
    </Spring>
  );
};


export default React.memo(LineDelayScrollSlide);
