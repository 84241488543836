import * as React from 'react';

import { IconLikeBase } from './likeBase';

const IconLikeFill: React.FC<{ isFocused: boolean }> = ({ isFocused }) => (
  <IconLikeBase
    isFocused={ isFocused }
    isPressed={ true }
  />
);

export default React.memo(IconLikeFill);
