import {
  KeyBoardKey,
  KeyBoardKeyType,
} from '~typings/Keyboard';


const keyboardDefault: KeyBoardKey[][] = [
  [
    { type: KeyBoardKeyType.Char, id: 1, },
    { type: KeyBoardKeyType.Char, id: 2, },
    { type: KeyBoardKeyType.Char, id: 3, },
    { type: KeyBoardKeyType.Char, id: 4, },
    { type: KeyBoardKeyType.Char, id: 5, },
    { type: KeyBoardKeyType.Char, id: 6, },
    { type: KeyBoardKeyType.Char, id: 7, },
    { type: KeyBoardKeyType.Char, id: 8, },
    { type: KeyBoardKeyType.Char, id: 9, },
    { type: KeyBoardKeyType.Char, id: 0, },
    { type: KeyBoardKeyType.Space },
    { type: KeyBoardKeyType.Backspace },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'Q', },
    { type: KeyBoardKeyType.Char, id: 'W', },
    { type: KeyBoardKeyType.Char, id: 'E', },
    { type: KeyBoardKeyType.Char, id: 'R', },
    { type: KeyBoardKeyType.Char, id: 'T', },
    { type: KeyBoardKeyType.Char, id: 'Y', },
    { type: KeyBoardKeyType.Char, id: 'U', },
    { type: KeyBoardKeyType.Char, id: 'I', },
    { type: KeyBoardKeyType.Char, id: 'O', },
    { type: KeyBoardKeyType.Char, id: 'P', },
    { type: KeyBoardKeyType.Char, id: '@', },
    { type: KeyBoardKeyType.SpecialChars, id: '*#$', disabled: true },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'A', },
    { type: KeyBoardKeyType.Char, id: 'S', },
    { type: KeyBoardKeyType.Char, id: 'D', },
    { type: KeyBoardKeyType.Char, id: 'F', },
    { type: KeyBoardKeyType.Char, id: 'G', },
    { type: KeyBoardKeyType.Char, id: 'H', },
    { type: KeyBoardKeyType.Char, id: 'J', },
    { type: KeyBoardKeyType.Char, id: 'K', },
    { type: KeyBoardKeyType.Char, id: 'L', },
    { type: KeyBoardKeyType.Char, id: '_', },
    { type: KeyBoardKeyType.Char, id: '+', },
    { type: KeyBoardKeyType.SwitchLang },
  ],
  [
    { type: KeyBoardKeyType.Char, id: 'Z', },
    { type: KeyBoardKeyType.Char, id: 'X', },
    { type: KeyBoardKeyType.Char, id: 'C', },
    { type: KeyBoardKeyType.Char, id: 'V', },
    { type: KeyBoardKeyType.Char, id: 'B', },
    { type: KeyBoardKeyType.Char, id: 'N', },
    { type: KeyBoardKeyType.Char, id: 'M', },
    { type: KeyBoardKeyType.Char, id: ',', },
    { type: KeyBoardKeyType.Char, id: '.', },
    { type: KeyBoardKeyType.Char, id: '-', },
    { type: KeyBoardKeyType.Char, id: '/', },
    { type: KeyBoardKeyType.Search },
  ],
];


export default keyboardDefault;
