import * as React from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { CurrentEventProgress } from '~components/CurrentEventProgress';
import { useApp } from '~components/Provider/App';
import { getEventStatus } from '~lib/datetime-utils';
import { isCatchupAvailable } from '~lib/eventsHelper';
import { NAV_LEGEND_PLAY_CHANNEL } from '~localization';
import Blackout from '~typings/Blackout';
import Channel from '~typings/Channel';
import Event from '~typings/Event';
import { EventStatus } from '~typings/EventStatus';
import ItemObject from '~typings/ItemObject';
import { PrimitivePlayerAccess } from '~typings/PlayerAccess';
import Button from '~ui/button';
import CatchupIcon from '~ui/Icon/Catchup';

import { getEventStatusText } from '../ProgramHeader/helpers';
import * as styles from './styles.module.css';


type Props = Readonly<{
  event: Event;
  channel: Channel;
  blackouts: Blackout[],
  isFocused: boolean;
  onLeave?: (NavigationDirection: any) => void;
  onWatchBtnClick?: () => void;
  setArchivedEvents?: () => void
  intl: InjectedIntl;
  access?: PrimitivePlayerAccess | null;
}>;

export const ProgramEventStatusBlock: React.FC<Props> = ({
  event,
  channel,
  blackouts,
  isFocused,
  onLeave,
  onWatchBtnClick,
  intl,
  setArchivedEvents,
  access
}: Props) => {
  const { language } = useApp();
  const history = useHistory();
  const eventStatus = getEventStatus(event);
  const statusText = getEventStatusText(event, language, intl);
  const isLive = eventStatus === EventStatus.Now;
  const isPast = eventStatus === EventStatus.InThePast;
  const isCatchup = event && (
    isPast
    && channel.catchup_availability.available
    && isCatchupAvailable(event, channel, blackouts)
  );

  const watchButtonClickHandler = () => {
    if (onWatchBtnClick) return onWatchBtnClick();

    if (isCatchup) {
      setArchivedEvents && setArchivedEvents()
      history.push(`/media-item/${ItemObject.ProgramEvent}/${event.id}/player`);
    } else {
      history.push(`/media-item/${ItemObject.Channel}/${event.channel_id}?player=true`);
    }
  };

  return (
    <>
      <div className={ styles.statusBlock }>
          { isCatchup && (
              <div className={ styles.iconBlock }>
                <CatchupIcon className={ styles.icon } />
              </div>
          ) }

          <div className={ styles.statusText  }>{ statusText }</div>

          { access && access?.allowed && (isLive || isCatchup) && (
              <Button
                onClick={ watchButtonClickHandler }
                isFocused={ isFocused }
                onLeave={ onLeave }
              >
                <FormattedMessage id={ NAV_LEGEND_PLAY_CHANNEL } />
              </Button>
          ) }
      </div>
      {
        isLive && (
          <CurrentEventProgress
            startAt={ event.start_at }
            endAt={ event.end_at }
          />
        )
      }
    </>
  );
};


export default injectIntl(ProgramEventStatusBlock);
