export const checkTimeType = (duration: number) => {
  const seconds = Math.floor(duration)
  const minutes = Math.floor(duration / 60)
  const hours = duration / 3600

  const hasHours = hours >= 1
  const hasMinutes = minutes >= 1
  const hasSeconds = seconds >= 1

  const hoursToShow = Math.floor(hours)
  const minutesToShow = Math.floor(minutes % 60)

  return {
    minutes,
    seconds,
    hasHours,
    hasMinutes,
    hasSeconds,
    hoursToShow,
    minutesToShow
  }
}