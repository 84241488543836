import focuser from '@focuser';
import cn from 'classnames';
import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { Typography } from '~app/ui-kit/Typography';
import { PinCodeManager,ProcedureType } from '~components/PinCodeManager';
import PinCodePopup from '~components/PinCodePopup';
import { useAccount, useAccountMutation } from '~hooks/fetch/useAccount';
import { useToggle } from '~hooks/useToggle';
import {
  CHANGE_PIN,
  PARENTAL_CONTROL,
  PIN_CODE,
  SECURITY_TAB_PARENT_LABEL,
  SECURITY_TAB_PIN_LABEL,
} from '~lib/localizator/src/dictionaries';
import { SpinnerFullScreenIsolated } from '~newapp/globalBlocks/spinner';
import { CheckboxFocuser } from '~newapp/ui-kit/Other/Checkbox';
import { Separator } from '~newapp/ui-kit/Tables_and_blocks/Separator';

import { useInitialPinCodeManagerProcedure, useSecurityNavigation } from './hooks';
import * as styles from './Security.module.css';

export const Security: React.FC = () => {
  const { data: accountInfo } = useAccount();
  const isSecurityPinOn = accountInfo?.security_pin === true;
  const isParentalControlOn = accountInfo?.parental_control === true;

  const { mutateAsync, isLoading } = useAccountMutation();

  const initialProcedure = useInitialPinCodeManagerProcedure();
  const [pinCodeProcedure, startPinCodeProcedure] = useState<ProcedureType | null>(
    initialProcedure,
  );
  const [isPinPopupOpen, { turnOn: openPinPopup, turnOff: closePinPopup }] = useToggle(false);

  const { focusedOn, setFocusOn, ...focuserProps } = useSecurityNavigation({ isSecurityPinOn });

  const onPinCodeCheckboxClick = () => {
    startPinCodeProcedure(isSecurityPinOn ? ProcedureType.Removal : ProcedureType.Creation);
  };

  const onChangePinCodeClick = () => {
    startPinCodeProcedure(ProcedureType.Change);
  };

  const patchParentCtrl = (isOn: boolean) => {
    mutateAsync({
      parental_control: isOn,
    });
  };

  const pinCodeManagerDoneHandler = () => {
    startPinCodeProcedure(null);
  };

  useLayoutEffect(() => {
    if (!isSecurityPinOn && isParentalControlOn) {
      patchParentCtrl(false);
    }
  }, [isSecurityPinOn]);

  if (isPinPopupOpen) {
    return (
      <PinCodePopup
        onSuccess={ () => {
          patchParentCtrl(!isParentalControlOn);
          closePinPopup();
        } }
        onBack={ closePinPopup }
      />
    );
  }

  if (pinCodeProcedure) {
    return (
      <PinCodeManager
        procedureType={ pinCodeProcedure }
        onDone={ pinCodeManagerDoneHandler }
      />
    );
  }

  if (isLoading) {
    return (
      <SpinnerFullScreenIsolated />
    );
  }

  return (
    <focuser.FocusableBlock
      isFocused
      className={ styles.SecurityTab }
      noNeedForceFocus
      { ...focuserProps }
    >
      <div className={ styles.checkboxLine }>
        <CheckboxFocuser
          isFocused={ focusedOn === 'pin-toggle-checkbox' }
          isChecked={ isSecurityPinOn }
          focuserClassName={ styles.checkboxIcon }
          onClick={ onPinCodeCheckboxClick }
          onForceFocus={ () => setFocusOn('pin-toggle-checkbox') }
          emitForceFocusOnHover
        />
        <Typography variant="h5_bold" className={ styles.text }>
          <FormattedMessage id={ PIN_CODE } />
        </Typography>
      </div>
      <Typography
        className={ cn(styles.text) }
        variant="subtitle1_medium"
      >
        <FormattedMessage id={ SECURITY_TAB_PIN_LABEL } />
      </Typography>
      <PrimaryButtonFocuser
        isFocused={ focusedOn === 'pin-change-button' }
        onForceFocus={ () => setFocusOn('pin-change-button') }
        isDisabled={ !isSecurityPinOn }
        focuserClassName={ styles.changePinButton }
        onClick={ onChangePinCodeClick }
        emitForceFocusOnHover
      >
        <FormattedMessage id={ CHANGE_PIN } />
      </PrimaryButtonFocuser>
      <Separator className={ styles.separator } />
      <div className={ styles.checkboxLine }>
        <CheckboxFocuser
          isFocused={ focusedOn === 'parental-toggle-checkbox' }
          onForceFocus={ () => setFocusOn('parental-toggle-checkbox') }
          onClick={ openPinPopup }
          focuserClassName={ styles.checkboxIcon }
          isDisabled={ !isSecurityPinOn }
          isChecked={ isParentalControlOn }
          emitForceFocusOnHover
        />
        <Typography
          className={ cn(styles.text, {
            [styles.textDisabled]: !isSecurityPinOn,
          }) }
          variant="h5_bold"
        >
          <FormattedMessage id={ PARENTAL_CONTROL } />
        </Typography>
      </div>
      <Typography
        className={ cn(styles.text, {
          [styles.textDisabled]: !isSecurityPinOn,
        }) }
        variant="subtitle1_medium"
      >
        <FormattedMessage id={ SECURITY_TAB_PARENT_LABEL } />
      </Typography>
    </focuser.FocusableBlock>
  );
};
