import focuser, { FocuserKeyHandler, useFocuserThrottledHandler } from '@focuser';
import React, { useCallback } from 'react';

import { EpisodeCardFocuser } from '~components/Card/EpisodeCard';
import Episode from '~typings/Episode';
import { CardConfigLayout } from '~typings/ScreenCardConfigs';
import Series from '~typings/Series';
import { getSliderPropsForCards,SliderOffsetableVirtual } from '~ui/SliderOffsetable';

import { useWatchProgressForEpisodes } from './utils';

export interface Props {
  series: Series;
  selectedSeasonIdx: number;
  initialEpisodeIdx: number;
  onEpisodePlay: (episode: Episode) => void;
}

export const EpisodesSlider = React.memo<Props>((props) => {
  const { series, selectedSeasonIdx, initialEpisodeIdx, onEpisodePlay } = props;
  const episodes = (series.seasons[selectedSeasonIdx]?.episodes || []);


  const [focusedIdx, setFocusedIdx] = React.useState(initialEpisodeIdx);
  const { watchProgressForFocusedEpisode } = useWatchProgressForEpisodes(episodes, focusedIdx);


  const onLeft: FocuserKeyHandler = (e) => {
    if (focusedIdx > 0) {
      e.stop();
      setTimeout(() => setFocusedIdx(focusedIdx - 1), 0);
    }
  };
  const onRight: FocuserKeyHandler = (e) => {
    if (focusedIdx < episodes.length - 1) {
      e.stop();
      setTimeout(() => setFocusedIdx(focusedIdx + 1), 0);
    }
  };

  const  onKeyLeftThrottled = useFocuserThrottledHandler(onLeft);
  const  onKeyRightThrottled = useFocuserThrottledHandler(onRight);

  const renderEpisode = useCallback((i: number) => {
    const episode = episodes[i];
    return (
      <EpisodeCardFocuser
        key={ i }
        onClick={ () => onEpisodePlay(episode) }
        episode={ episode }
        episodeProgress={ i === focusedIdx ? watchProgressForFocusedEpisode : undefined }
        seriesCertificationRatings={ series.certification_ratings }
        isFocused={ i === focusedIdx }
        onForceFocus={ () => setFocusedIdx(i) }
        emitForceFocusOnHover
      />
    );
  }, [focusedIdx, episodes, onEpisodePlay, watchProgressForFocusedEpisode]);



  return (
    <focuser.FocusableBlock
      isFocused
      onKeyLeft={ onKeyLeftThrottled }
      onKeyRight={ onKeyRightThrottled }
      isPointerLeftAvailable={ focusedIdx > 0 }
      isPointerRightAvailable={ focusedIdx < episodes.length - 1 }
      noNeedForceFocus
    >
      <SliderOffsetableVirtual
        focusedIndex={ focusedIdx }
        childsCount={ episodes.length }
        renderChild={ renderEpisode }
        { ...getSliderPropsForCards(CardConfigLayout.Horizontal) }
      />
    </focuser.FocusableBlock>
  );
});

EpisodesSlider.displayName = 'EpisodesSlider';
