import PlatformClass from 'spbtv-smarttv-platform/src/desktop';
import keyCodes from 'spbtv-smarttv-platform/src/desktop/key-codes';

import { PlayerTypes } from '~lib/player/typings';
import { PlatformName } from '~typings/PlatformName';
import PlayerType from '~typings/PlayerType';

const useBackgroundGradient = true;
const useCSSAnimations = true;
const useStreamSwitching = true;


const PLAYER_TYPES: Partial<PlayerTypes> = {
  [PlatformName.Desktop]: ({ isHLS, isDash }) => {
    if (isHLS) { return PlayerType.Hls; }

    if (isDash) { return PlayerType.Shaka; }

    return PlayerType.HTML5;
  },
};


export {
  keyCodes,
  PlatformClass,
  PLAYER_TYPES,
  useBackgroundGradient,
  useCSSAnimations,
  useStreamSwitching,
};
