import { compact } from 'lodash';

import { Block, BlockType } from '~typings/Block';


const allowedBlocks = (isAuthorized: boolean, blocks: Block[]): Block[] => {
  // If the visitor is not authorized,
  // then there is no need for a customization block

  const allowed = (!isAuthorized)
    ? blocks.filter(({ object }: Block) => (
      object !== BlockType.FavoriteMoviesBlock
      && object !== BlockType.ContinueWatchingBlock
      && object !== BlockType.FavoriteChannelsBlock
      && object !== BlockType.RecommendationsBlock
    ))
    : blocks;

  // Filter by those blocks that we know
  return (
    allowed
      .filter((block: Block) => (
        (block.object === BlockType.CardCollectionBlock && block.card_collection)
        || (block.object === BlockType.CollectionBlock && block.collection)
        || block.object === BlockType.BannersBlock
        || block.object === BlockType.ContinueWatchingBlock
        || block.object === BlockType.CurrentProgramLineBlock
        || block.object === BlockType.FavoriteAudioShowBlock
        || block.object === BlockType.FavoriteChannelsBlock
        || block.object === BlockType.FavoriteMoviesBlock
        || block.object === BlockType.FavoriteSeriesBlock
        || block.object === BlockType.FavoritesBlock
        || block.object === BlockType.FiltersBlock
        || block.object === BlockType.MiddleBannersBlock
        || block.object === BlockType.RecommendationsBlock
        || block.object === BlockType.BigBannersBlock
        || block.object === BlockType.ExpandableCardColletion
      ))
  );
};


const getPreparedBlockToFetch = (blocksAllowed: Block[], pageId?: string) => (
  compact(blocksAllowed.map((block: Block) => {
    switch (block.object) {
      case BlockType.BannersBlock: {
        return {
          object: BlockType.BannersBlock,
          pageId,
        };
      }
      case BlockType.BigBannersBlock: {
        return {
          object: BlockType.BigBannersBlock,
          blockID: block.id,
        };
      }
      case BlockType.MiddleBannersBlock: {
        return {
          object: BlockType.MiddleBannersBlock,
          blockID: block.id,
        };
      }
      case BlockType.CollectionBlock: {
        return {
          object: BlockType.CollectionBlock,
          collectionID: block.collection?.id,
        };
      }
      case BlockType.CardCollectionBlock: {
        return {
          object: BlockType.CardCollectionBlock,
          cardCollectionID: block.card_collection?.id,
          cardConfigID: block.card_collection?.card_config.id,
        };
      }
      case BlockType.ContinueWatchingBlock: {
        return {
          object: BlockType.ContinueWatchingBlock,
          resource_types: block.resource_types,
        };
      }
      case BlockType.RecommendationsBlock: {
        return {
          object: BlockType.RecommendationsBlock,
          recommendation_context: block.recommendation_context,
          pageId,
        };
      }
      case BlockType.CurrentProgramLineBlock: {
        return {
          object: BlockType.CurrentProgramLineBlock,
          slug: block?.channel_slug,
        };
      }
      case BlockType.FavoriteChannelsBlock: {
        return {
          object: BlockType.FavoriteChannelsBlock,
        };
      }
      case BlockType.FavoriteMoviesBlock: {
        return {
          object: BlockType.FavoriteMoviesBlock,
        };
      }
      case BlockType.FavoriteAudioShowBlock: {
        return {
          object: BlockType.FavoriteAudioShowBlock,
        };
      }
      case BlockType.FavoriteSeriesBlock: {
        return {
          object: BlockType.FavoriteSeriesBlock,
        };
      }
      case BlockType.FavoritesBlock: {
        return {
          object: BlockType.FavoritesBlock,
          resource_types: block.resource_types,
        };
      }
      case BlockType.ExpandableCardColletion: {
        return {
          object: BlockType.ExpandableCardColletion,
          cardCollectionID: block.card_collection?.id,
          cardConfigID: block.card_collection?.card_config.id,
        };
      }
      default:
        return;
    }
  }))
);


export {
  allowedBlocks,
  getPreparedBlockToFetch,
};
