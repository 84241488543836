import { QueryKey, QueryObserverOptions, QueryObserverResult, RefetchOptions, useQuery } from 'react-query';

import { queryClient } from '~global';
import { LIMIT } from '~hooks/fetch/constants';
import { getAllFetchedItems } from '~hooks/fetch/utils';
import ResponseMany from '~typings/ResponseMany';


type Result<Item> = Readonly<{
  data: ResponseMany<Array<Item>> | undefined;
  error: any | undefined;
  status: 'success' | 'error' | 'loading' | 'idle';
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  refetch: (options?: (RefetchOptions | undefined)) => Promise<QueryObserverResult<ResponseMany<Item[]>, any>>;
}>;

type Parameters<Item> = Readonly<{
  queryKey: QueryKey;
  page: number;
  fetch: (queryKey: QueryKey, page: number) => Promise<ResponseMany<Array<Item>>>;
  config?: QueryObserverOptions<ResponseMany<Array<Item>>>;
}>;


function getCachedDataPagedItems<Item>(queryKey: QueryKey): ResponseMany<Array<Item>> | undefined {
  return queryClient.getQueryData(queryKey);
}

function getCachedDataAllPagedItems<Item>(queryKey: QueryKey): ResponseMany<Array<Item>> | undefined {
  return getAllFetchedItems<Item>([queryKey]);
}

function getCachedDataAllPagedItems2<Item>(queryKey: QueryKey): ResponseMany<Array<Item>> | undefined {
  return getAllFetchedItems<Item>(queryKey);
}
function getLastFetchedPagePagedItems<Item>(queryKeyWithoutPage: QueryKey): number {
  const cachedData = getCachedDataAllPagedItems<Item>([queryKeyWithoutPage]);

  if (!cachedData) {
    return 0;
  }

  return (Math.ceil(cachedData.data.length / LIMIT) - 1);
}

function usePagedItems<Item>(parameters: Parameters<Item>): Result<Item> {
  const fetchFunc = () => (
    parameters.fetch([parameters.queryKey, parameters.page], parameters.page)
  );

  const { data, error, status, isSuccess, isLoading, isError, isFetching, refetch } =
    useQuery<ResponseMany<Array<Item>>, any>(parameters.queryKey, fetchFunc, {
    ...parameters.config,
    keepPreviousData: true,
      retry: 3
  });

  return ({
    data,
    error,
    status,
    isSuccess,
    isLoading,
    isError,
    isFetching,
    refetch
  });
}



function usePagedItemsOnClick<Item>(parameters: Parameters<Item>): Result<Item> {
  const fetchFunc = () => (
    parameters.fetch([parameters.queryKey, parameters.page], parameters.page)
  );

  const { data, error, status, isSuccess, isLoading, isError, isFetching, refetch } =
    useQuery<ResponseMany<Array<Item>>, any>(parameters.queryKey, fetchFunc, {
      ...parameters.config,
      refetchOnWindowFocus: false,
      enabled: false,
    });

  return ({
    data,
    error,
    status,
    isSuccess,
    isLoading,
    isError,
    isFetching,
    refetch
  });
}

export {
  getCachedDataAllPagedItems,
  getCachedDataAllPagedItems2,
  getCachedDataPagedItems,
  getLastFetchedPagePagedItems,
  Result,
  usePagedItems,
  usePagedItemsOnClick,
};
