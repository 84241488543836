import * as cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import ChannelDetail from '~components/EPG/EventDetail/Channel';
import EventDetail from '~components/EPG/EventDetail/Event';
import EPGPageNavigation from '~components/EPG/navigation';
import { Y_FIELD_NAME } from '~hooks/useSpatialNavigation';
import * as URLSearchManager from '~lib/URLQueryStringManager';
import usePointer from '~stores/Pointer';
import Event from '~typings/Event';
import NavigationDirection from '~typings/NavigationDirection';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  className?: string;
  event: Event | null;
  onLeave: (direction: NavigationDirection) => void;
}>;


const EPGPageEventDetail: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const initialFocusedIndex = URLSearchManager.getField(history.location.search, Y_FIELD_NAME) || 0;
  const [focusedIndex, setFocusedIndex] = React.useState<number>(
    (initialFocusedIndex > 1) ? 0 : Math.max(initialFocusedIndex, 0)
  );
  // show/hide pointer navigation
  const isPointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);

  React.useEffect(() => {
    if (props.isFocused && isPointerEnabled) {
      updateDirections({
        [NavigationDirection.Up]: (focusedIndex !== 0),
        [NavigationDirection.Down]: (focusedIndex < 1),
        [NavigationDirection.Left]: true,
        [NavigationDirection.Right]: false,
      });
    }
  }, [
    focusedIndex,
    props.isFocused,
    isPointerEnabled,
  ]);

  return (
    <>
      {
         props.isFocused &&
           <EPGPageNavigation
              isFocused
              initialFocusIndex={ focusedIndex }
              total={ 2 }
              onLeave={ props.onLeave }
              onIndexChange={ setFocusedIndex }
           />
      }
      <div className={ cn(styles.wrapper, props.className) }>
        <EventDetail isFocused={ (props.isFocused && focusedIndex === 0) } event={ props.event }/>
        <ChannelDetail isFocused={ (props.isFocused && focusedIndex === 1) } event={ props.event }/>
      </div>
    </>
  );
};


export default React.memo(EPGPageEventDetail);
