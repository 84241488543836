import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { ON_AIR } from '~localization';
import Button from '~ui/button';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  onClick: () => void;
  intl: InjectedIntl;
}>;


const OnAirButton: React.FC<Props> = (props: Props) => {

  const handleGoToAir = () => {
    props.onClick();
  };

  return (
    <Button
      isFocused={ props.isFocused }
      className={ styles.goToAir }
      onClick={ handleGoToAir }
    >
      { props.intl.formatMessage({ id: ON_AIR }) }
    </Button>
  );
};


export default injectIntl(OnAirButton);
