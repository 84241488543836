import type { History } from 'history';

import Page from '~typings/Page';
import { PageObject } from '~typings/PageObject';

const goToDefaultPage = (pages: Page[], history: History): void => {
  const mainPage = pages.find(({ object }) => (object === PageObject.MainPage));
  const mainPageID = mainPage?.id || pages[0]?.id;

  if (mainPageID) {
    history.replace(`/page/${mainPageID}`);
  } else {
    history.replace('/error/no-default-page');
  }
};


export default goToDefaultPage;
