enum Protocol {
  HLS = 'hls',
  MSS = 'mss',
  DASH = 'dash',
  MP4 = 'mp4',
  WVM = 'wvm',
}


export default Protocol;
