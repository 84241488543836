// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--EqFNP {\n  max-width: 32.421875vw;\n  margin: 0 auto;\n  text-align: center;\n  font-size: 1.25vw;\n}\n\n.messageText--OGLrj {\n  margin-bottom: 5.078125vw;\n  font-size: 1.7187500000000002vw;\n}\n\n.button--wZHvq {\n  display: inline-block;\n  margin: 0 0.390625vw;\n  min-width: 12.890625vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/ExitPopup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;EACd,kBAAkB;EAClB,iBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,+BAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":[".container {\n  max-width: 32.421875vw;\n  margin: 0 auto;\n  text-align: center;\n  font-size: $fontBody_vw;\n}\n\n.messageText {\n  margin-bottom: 5.078125vw;\n  font-size: $fontH6_vw;\n}\n\n.button {\n  display: inline-block;\n  margin: 0 0.390625vw;\n  min-width: 12.890625vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "container--EqFNP";
export var messageText = "messageText--OGLrj";
export var button = "button--wZHvq";
export default ___CSS_LOADER_EXPORT___;
