import * as React from 'react';

import { useInterval } from '~hooks/useInterval';

import { Image, ImageProps } from '.';
import { getRandomInterval } from './getRandomInterval';

type AutoUpdatableImageProps = ImageProps & {
  src: string;
  updateTimeout?: number[];
};

const AutoUpdatableImage: React.FC<AutoUpdatableImageProps> = ({
  src,
  updateTimeout = [1000, 3000],
  ...props
}) => {
  const [stateSrc, setSrc] = React.useState<string>(src);

  const handleUpdate = () => {
    setSrc(`${src}?noCache=${Math.random()}`);
  };

  const [min, max] = updateTimeout;
  const updateInterval = getRandomInterval(min < 3000 ? 3000 : min, max);
  useInterval(handleUpdate, updateInterval);

  return <Image src={ stateSrc } { ...props } />;
};

export default AutoUpdatableImage;
