import { intersection } from 'lodash';
import * as moment from 'moment';
import { useEffect,useRef } from 'react';

import {version } from '~lib/env-utils';
import { Events } from '~lib/player/common';
import { IPlayer } from '~lib/player/typings';
import { DeviceInfo } from '~typings/DeviceInfo';
import PlayerEvent from '~typings/PlayerEvent';
import Stream from '~typings/Stream';

import send from './send';

type Props = {
  player: IPlayer | null;
  stream: Stream;
  device: DeviceInfo;
};

/**
 * Analitics v2
 * http://doc.dev.spbtv.com/services/player_analytics/events_api_v2.html
 */

const useAnaliticsV2 = ({ player, stream, device }: Props) => {
  const started = useRef(false);
  const adsStarted = useRef(false);
  const isBuffering = useRef(false);
  const isBufferInitialization = useRef(false);
  const isInitializing = useRef(false);
  const isPaused = useRef(false);
  const isPlaying = useRef(false);
  const buffering = useRef(0);
  const initializingPlayer = useRef(0);
  const initializingBuffer = useRef(0);
  const playing = useRef(0);
  const paused = useRef(0);
  const spentTimer = useRef<number>(moment().valueOf());
  const pingInterval = useRef<any>();

  const resetSpentTimer = () => {
    spentTimer.current = moment().valueOf();
  };
  const resetTimeSpent = () => {
    buffering.current = 0;
    initializingBuffer.current = 0;
    initializingPlayer.current = 0;
    paused.current = 0;
    playing.current = 0;
    resetSpentTimer();
  };
  const mathSpentTime = (param: number) => {
    const result = param + (moment().valueOf() - spentTimer.current);
    resetSpentTimer();
    return result;
  };

  const ping = () => {
    if (!started.current) { return; }

    const {
      url,
      additional_parameters: {
        application_id,
        watch_session_id,
        resource_type,
        resource_uid,
        user_id,
        user_type,
      },
    } = stream.analytics_v2;
    const network = window.navigator.connection;
    const devicePlayerType = 'spbtv_drm_wv';
    const deviceType = 'smarttv';
    const deviceOs = intersection(
      [(device.osName || '').replace(/\s+/g, '').toLowerCase()],
      ['tizen', 'webos']
    ) as PlayerEvent['device_os'][];
    const isAllowedNetworkType = intersection([network?.type], ['wifi', 'ethernet'] as const);
    const networkType = isAllowedNetworkType[0] || 'unknown';
    const bandwidth = (network?.downlink || 0) * 1000000;
    if (isInitializing.current) {
      initializingPlayer.current = mathSpentTime(initializingPlayer.current);
    }
    if (isBufferInitialization.current) {
      initializingBuffer.current = mathSpentTime(initializingBuffer.current);
    }
    if (isPlaying.current) {
      playing.current = mathSpentTime(playing.current);
    }
    if (isPaused.current) {
      paused.current = mathSpentTime(paused.current);
    }
    if (isBuffering.current) {
      buffering.current = mathSpentTime(buffering.current);
    }
    const timeSpent = {
      initializing_player: initializingPlayer.current,
      initializing_buffer: initializingBuffer.current,
      playing: playing.current,
      paused: paused.current,
      buffering: buffering.current,
    };

    const body: PlayerEvent = {
      application_id,
      application_session_id: stream.session_id,
      application_version: version.toString(),
      bandwidth,
      device_id: device.deviceId || '',
      device_os: deviceOs[0] || 'other',
      device_player_type: devicePlayerType,
      device_type: deviceType,
      network_type: networkType,
      resource_type,
      resource_uid,
      time_spent: timeSpent,
      user_id,
      user_type,
      watch_session_id,
    };

    send(url, body);
  };

  const startSession = () => {
    if (started.current) { return; }
    started.current = true;

    resetTimeSpent();
    if (!isInitializing.current) { isInitializing.current = true; }

    pingInterval.current = setInterval(() => {
      ping();
      resetTimeSpent();
    },
      stream.analytics_v2.interval * 1000
    );
  };

  const resetSession = () => {
    clearInterval(pingInterval.current);
    setTimeout(() => {
      started.current = false;
    });
  };

  const handleBufferingStart = () => {
    if (!started.current) { return; }
    if (!isBuffering.current) { isBuffering.current = true; }
    resetSpentTimer();
  };

  const handleBufferingComplete = () => {
    if (!started.current) { return; }
    if (isBuffering.current) {
      buffering.current = mathSpentTime(buffering.current);
      isBuffering.current = false;
    }
  };

  const handleADSStarted = () => {
    if (!started.current) { return; }
    adsStarted.current = true;
    if (isInitializing.current) {
      initializingPlayer.current = mathSpentTime(initializingPlayer.current);
      isInitializing.current = false;
    }
  };

  const handleADSFinished = () => {
    if (!started.current) { return; }
    adsStarted.current = false;
  };

  const handleLoadedMetadata = () => {
    if (!started.current) { return; }
    if (!isBufferInitialization.current) {
      isBufferInitialization.current = true;
    }
  };

  const handleCanPlay = () => {
    if (!started.current) { return; }
    if (isInitializing.current) {
      initializingPlayer.current = mathSpentTime(initializingPlayer.current);
      isInitializing.current = false;
    }
  };

  const handleTimeUpdate = () => {
    if (!started.current && stream.analytics_v2) {
      startSession();

      return;
    }
    if (isBufferInitialization.current) {
      initializingBuffer.current = mathSpentTime(initializingBuffer.current);
      isBufferInitialization.current = false;
    }
    if (isPaused.current) {
      paused.current = mathSpentTime(paused.current);
      isPaused.current = false;
    }
    if (!isPlaying.current) { isPlaying.current = true; }
  };

  const handlePause = () => {
    if (!started.current) { return; }

    if (isPlaying.current) {
      playing.current = mathSpentTime(playing.current);
      isPlaying.current = false;
    }
    if (!isPaused.current) { isPaused.current = true; }
  };

  const handleStop = () => {
    if (!started.current) { return; }

    started.current = false;
    clearInterval(pingInterval.current);
    ping();
    resetTimeSpent();
  };

  const handleWaiting = () => {
    if (!started.current) { return; }
    if (isPlaying.current) {
      playing.current = mathSpentTime(playing.current);
      isPlaying.current = false;
    }
    if (!isPaused.current) { isPaused.current = true; }
  };

  const handleSuspend = () => {
    if (!started.current) { return; }
    if (isPlaying.current) {
      playing.current = mathSpentTime(playing.current);
      isPlaying.current = false;
    }
    if (!isPaused.current) { isPaused.current = true; }
  };

  const handleError = () => {
    if (!started.current) { return; }

    ping();
    resetSession();
  };

  const handleEnded = () => {
    if (!started.current) { return; }

    ping();
    resetSession();
  };

  useEffect(() => {
    if (stream.analytics_v2) {
      startSession();
    }

    return () => {
      resetSession();
    }
  }, [stream]);

  useEffect(() => {
    if (!player) { return; }

    player.on(Events.BUFFERING_START, handleBufferingStart);
    player.on(Events.BUFFERING_COMPLETE, handleBufferingComplete);
    player.on(Events.ADS_STARTED, handleADSStarted);
    player.on(Events.ADS_FINISHED, handleADSFinished);
    player.on(Events.LOADED_METADATA, handleLoadedMetadata);
    player.on(Events.CAN_PLAY, handleCanPlay);
    player.on(Events.TIME_UPDATE, handleTimeUpdate);
    player.on(Events.PAUSE, handlePause);
    player.on(Events.STOP, handleStop);
    player.on(Events.WAITING, handleWaiting);
    player.on(Events.SUSPEND, handleSuspend);
    player.on(Events.ERROR, handleError);
    player.on(Events.ENDED, handleEnded);

    return () => {
      if (!player) { return; }

      player.removeListener(Events.BUFFERING_START, handleBufferingStart);
      player.removeListener(Events.BUFFERING_COMPLETE, handleBufferingComplete);
      player.removeListener(Events.ADS_STARTED, handleADSStarted);
      player.removeListener(Events.ADS_FINISHED, handleADSFinished);
      player.removeListener(Events.LOADED_METADATA, handleLoadedMetadata);
      player.removeListener(Events.CAN_PLAY, handleCanPlay);
      player.removeListener(Events.TIME_UPDATE, handleTimeUpdate);
      player.removeListener(Events.PAUSE, handlePause);
      player.removeListener(Events.STOP, handleStop);
      player.removeListener(Events.WAITING, handleWaiting);
      player.removeListener(Events.SUSPEND, handleSuspend);
      player.removeListener(Events.ERROR, handleError);
      player.removeListener(Events.ENDED, handleEnded);
    };
  }, [player]);
};


export default useAnaliticsV2;
