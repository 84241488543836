import {
  getMediaItemBannerHeight,
  getMediaItemBannerWidth,
  getMediaItemPosterHeight,
  getMediaItemPosterWidth,
  getSpaceBetweenSlides,
  getTiledViewPosterSpaceBetweenRows,
  getTiledViewSpaceBetweenRows,
} from '~lib/SizesInPX';
import Collection from '~typings/Collection';
import CollectionType from '~typings/CollectionType';
import { DataItem } from '~typings/DataItem';
import ImageType from '~typings/ImageType';
import ItemObject from '~typings/ItemObject';
import MediaItemDimension from '~typings/MediaItemDimension';
import ResponseMany from '~typings/ResponseMany';


const getItemWidth = (dimension: MediaItemDimension): number => (
  (dimension === ImageType.POSTER) ?
    getMediaItemPosterWidth() + getSpaceBetweenSlides()
    :
    getMediaItemBannerWidth() + getSpaceBetweenSlides()
);
const getItemHeight = (dimension: MediaItemDimension) => (
  (dimension === ImageType.POSTER) ?
    getMediaItemPosterHeight() + getTiledViewPosterSpaceBetweenRows()
    :
    getMediaItemBannerHeight() + getTiledViewSpaceBetweenRows()
);
const getItemsCountInLine = (dimension: MediaItemDimension, [poster, banner] = [6, 4]) => (
  (dimension === ImageType.POSTER) ?
    poster
    :
    banner
);

const getLine = (itemsCountInLine: number) => (
  (itemIndex: number | undefined): number => (
    (itemIndex !== undefined) ?
      Math.trunc(itemIndex / itemsCountInLine)
      :
      0
  )
);


const getVars = (dimension: MediaItemDimension, slidesCount?: [number, number]) => {
  const itemWidth = getItemWidth(dimension);
  const itemHeight = getItemHeight(dimension);
  const itemsCountInLine = getItemsCountInLine(dimension, slidesCount);
  const lineByItemIndex = getLine(itemsCountInLine);

  return {
    itemWidth,
    itemHeight,
    lineByItemIndex,
    itemsCountInLine,
  } as const;
};

const getTiledDimension = (items: ResponseMany<DataItem[]> | null | undefined): MediaItemDimension => (
  (
    items && items?.data[0]
    && (
      items.data[0]?.object !== ItemObject.Channel &&
      items.data[0]?.object !== ItemObject.News
    )
  ) ?
    ImageType.POSTER
    :
    ImageType.BANNER
);

const getTiledDimensionByCollection = (collection: Collection): MediaItemDimension => (
  (
    collection.object === CollectionType.Channels ||
    collection.object === CollectionType.ChannelsLogo ||
    collection.object === CollectionType.News
  ) ?
    ImageType.BANNER
    :
    ImageType.POSTER
);


export {
  getItemsCountInLine,
  getTiledDimension,
  getTiledDimensionByCollection,
  getVars,
};
