/* eslint-disable max-len */
import React, { useMemo } from "react";
import { InjectedIntl, injectIntl } from "react-intl";

import { useAutoInfinityChannels } from "~hooks/fetch/useChannels";
import { ETHER_FROM } from "~localization";
import Event from "~typings/Event";

import * as css from '../live/styles.module.css'
import { ChannelIcon } from "../live/subComponents/ChannelIcon";


type Props = {
  item: Event
  intl: InjectedIntl
}

const LocalComponent = ({ item, intl }: Props) => {
  const channels = useAutoInfinityChannels();

  const allChannels = channels.data?.data;
  const channel =  useMemo(()=>allChannels?.find(el => el.id === item.channel_id), [allChannels, item.channel_id])
  let resultDate: string | undefined = undefined

  if (!channel) {
    return null
  }

  function formatTwoNums(num: number) {
    if (num < 10) {
      return `0${ num }`
    }
    return `${ num }`
  }

  function isDateValid(itemDate: string): boolean {
    return new Date(itemDate).getTime() === new Date(itemDate).getTime()
  }

  const image = channel.images && channel.images.find(el => el.type === 'logo')

  if (isDateValid(item.start_at) && isDateValid(item.end_at)) {
    const itemStartDate = new Date(Date.parse(item.start_at))
    const itemEndDate = new Date(Date.parse(item.end_at))

    const msg = intl.formatMessage({ id: ETHER_FROM })

    const startDate = formatTwoNums(itemStartDate.getDate())
    const startMonth = formatTwoNums(itemStartDate.getMonth())
    const startYear = itemStartDate.getFullYear()
    const startHours = formatTwoNums(itemStartDate.getHours())
    const startMinutes = formatTwoNums(itemStartDate.getMinutes())
    const endHours = formatTwoNums(itemEndDate.getHours())
    const endMinutes = formatTwoNums(itemEndDate.getMinutes())

    const getYearLine = ` ${ startDate }.${ startMonth }.${ startYear }, `
    const getTimePeriod = `${ startHours }:${ startMinutes } - ${ endHours }:${ endMinutes }`
    resultDate = msg + getYearLine + getTimePeriod
  }

  return (
    <div className={ css.mainWrapper }>
      <div className={ css.liveFirstLine }>
        { image && <ChannelIcon image={ image } /> }
        <div className={ css.itemName }>{ channel.position + ' ' + channel.name }</div>
      </div>
      { item.name && <div className={ css.liveSecondLine }> { item.name }</div> }
      { resultDate && <div className={ css.liveThirdLine }> { resultDate }</div> }
    </div>
  )
}

export const CatchupInfo = injectIntl(LocalComponent)

