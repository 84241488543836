
import cn from 'classnames';
import * as React from 'react';

import { IconBase } from '~ui/Icon';
import CheckIcon from '~ui/Icon/icons/Check';

import * as styles from './styles.module.css';

type Props = Readonly<{
  isFocused?: boolean;
  isChecked?: boolean;
}>;

const EditCheckboxIcon = ({ isFocused, isChecked }: Props) => (
  <div
    className={ cn(styles.wrapper, { [styles.focused]: isFocused }) }
  >
    <div className={ styles.icon }>
      { isChecked ? <IconBase Icon={ CheckIcon } /> : null }
    </div>
  </div>
);

export default EditCheckboxIcon;
