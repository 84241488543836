import { QueryKey, useMutation, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import { invalidateAllContinueWatchingData } from '~hooks/fetch/useContinueWatching/useContinueWatchingV3';
import ApiClient from '~lib/ApiClient';
import { transformArrayToUniqueString } from '~lib/cache-utils';
import invalidateByKeys from '~lib/ReactQuery/invalidateByKeys';
import ItemObject from '~typings/ItemObject';
import { WatchProgress } from '~typings/WatchProgress';

import { fetchOptionsBasic, FetchType, fetchURLs } from '../fetch-parameters';
import { continueWatchingTypeByItemObject } from './useWatchProgress.helpers';




const PREFIX_OF_A_COMPOSITE_KEY = 'watch_progress';


const getKeyWithoutItem = (accessToken: string | null): QueryKey => (
  [PREFIX_OF_A_COMPOSITE_KEY, accessToken]
);

const getKey = (accessToken: string | null, object?: ItemObject, id?: string): QueryKey => (
  [getKeyWithoutItem(accessToken), object, id]
);

const getMultipleKey = (accessToken: string | null, object?: ItemObject, ids?: string[]): QueryKey => (
  [getKeyWithoutItem(accessToken), object, 'multiple', transformArrayToUniqueString(ids || [])]
)

const getCachedWatchProgressForItem = (accessToken: string | null, object: ItemObject, id: string) => {
  const queryKey = getKey(accessToken, object, id);
  const cachedData = queryClient.getQueryData<WatchProgress[]>(queryKey);

  return cachedData?.[0];
};

type FetchParams = Readonly<{
  accessToken: string | null;
  object: ItemObject;
  ids: string[];
  client: ApiClient;
}>;

const fetch = async (params: FetchParams) => {
  if (!params.accessToken || !params.ids) {
    return undefined;
  }

  if(params.ids.length === 1) {
    const cacheData = getCachedWatchProgressForItem(params.accessToken, params.object, params.ids[0]);

    if (cacheData) {
      return cacheData;
    }
  }

  const { data } = await params.client.get(
    fetchURLs[FetchType.WatchProgress],
    {
      ...fetchOptionsBasic[FetchType.WatchProgress],
      'filter[resource_type_in]': continueWatchingTypeByItemObject[params.object],
      'filter[resource_id_in]': params.ids.join(','),
      'access_token': params.accessToken,
    }
  );

  return data;
};


const useWatchProgress = (object?: ItemObject, id?: string) => {
  const { accessToken } = getAuthData();
  const client = useClient();
  const queryKey = getKey(accessToken, object, id);

  return useQuery<WatchProgress[]>({
    queryKey,
    queryFn: () => fetch({
      ids: [id!],
      object: object!,
      client,
      accessToken,
    }),
    enabled: !!(object && id && accessToken),
    cacheTime: 1000 * 60 * 5, // 5 минут
  });
};

const useWatchProgressMultiple = (object?: ItemObject, ids?: string[]) => {
  const { accessToken } = getAuthData();
  const client = useClient();
  const queryKey = getMultipleKey(accessToken, object, ids);

  return useQuery<WatchProgress[]>({
    queryKey,
    queryFn: () => fetch({
      ids: ids!,
      object: object!,
      client,
      accessToken,
    }),
    enabled: !!(object && ids && accessToken && ids.length),
    cacheTime: 1000 * 60 * 5, // 5 минут
  });
};


/* Update WatchProgress List */
const deleteWatchProgress = async (
  client: ApiClient,
  accessToken: string | null,
  resourceIDs: string[],
) => {
  if (!accessToken) {
    return null;
  }

  try {
    if (resourceIDs.length !== 0) {
      await client.patch(`${ fetchURLs[FetchType.WatchProgress] }`, {
        access_token: accessToken,
        resource_id_in: resourceIDs
      });
    }
    else {
      await client.delete(`${ fetchURLs[FetchType.WatchProgress] }`, {
        access_token: accessToken,
      });
    }
  } catch {
    // ignore
  }
};


const useDeleteWatchProgress = () => {
  const { accessToken } = getAuthData();
  const client = useClient();

  return useMutation(
    ({ resourceIDs }: { resourceIDs: string[] }) =>
      deleteWatchProgress(client, accessToken, resourceIDs),
    {
      onSettled: () => {
        invalidateByKeys([[getKeyWithoutItem(accessToken)]]);
        // Очищаем continue watching, чтобы перезагрузить все данные
        // Возвращаем этот промис, чтобы мутация была в состоянии `loading`, пока continue watching не очистится
        return invalidateAllContinueWatchingData();
      },
    },
  );
};


export {
  getCachedWatchProgressForItem,
  getKey,
  getKeyWithoutItem,
  useDeleteWatchProgress,
  useWatchProgress,
  useWatchProgressMultiple,
};
