import serviceInfoByLang from 'customization-project-name/service-info';
import { compact, get } from 'lodash';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import AgreementPopup from '~components/AgreementPopup';
import HypertextBlock from '~components/HypertextBlock';
import { useApp } from '~components/Provider/App';
import {
  AGREEMENT_BLOCK_BEGINNING_TEXT,
  AGREEMENT_BLOCK_POLICY_TEXT,
  AGREEMENT_BLOCK_TERMS_TEXT,
  AMEDIATEKA_TERMS,
  IMPLICIT_AGREEMENT_BLOCK_BEGINNING_TEXT,
  START_TERMS,
} from '~localization';
import NavigationDirection from '~typings/NavigationDirection';

type Props = Readonly<{
  isFocused: boolean;
  onLeave: (dir: NavigationDirection) => void;
  language?: string;
  isImplicitFlow?: boolean;
  intl: InjectedIntl;
}>;

const AgreementBlock: React.FC<Props> = (props: Props) => {
  const { intl: { formatMessage: fMsg } } = props;
  const { language: appLanguage } = useApp();
  const [ popupPage, setPopupPage ] = React.useState<string | null>(null);

  const isTermsExists = !!serviceInfoByLang?.terms?.[props.language ?? appLanguage];
  const isPolicyExists = !!serviceInfoByLang?.policy?.[props.language ?? appLanguage];
  const isAmediaExists = get(serviceInfoByLang, `amediateka.${appLanguage}`);
  const isStartExists = get(serviceInfoByLang, `start.${appLanguage}`);
  const isNoAgreementData = !isTermsExists && !isPolicyExists;

  React.useLayoutEffect(() => {
    if (props.isFocused && isNoAgreementData) {
      props.onLeave(NavigationDirection.Up);
    }
  }, [props.isFocused, isNoAgreementData])

  if (isNoAgreementData) {
    return null;
  }

  const agreementText = [
    fMsg({
      id: props.isImplicitFlow ?
        IMPLICIT_AGREEMENT_BLOCK_BEGINNING_TEXT
        :
        AGREEMENT_BLOCK_BEGINNING_TEXT
    }),
    ( isTermsExists && isPolicyExists ? '\n' : '' ),
    compact([
      isTermsExists ? `[${fMsg({id: AGREEMENT_BLOCK_TERMS_TEXT})}](terms)` : undefined,
      isPolicyExists ? `[${fMsg({id: AGREEMENT_BLOCK_POLICY_TEXT})}](policy)`: undefined,
      isAmediaExists ? `[${fMsg({id: AMEDIATEKA_TERMS})}](amediateka)` : undefined,
      isStartExists ? `[${fMsg({id: START_TERMS})}](start) `: undefined
    ]).join(`, `)
  ].join('');

  const linkClickHandler = (href) => {
    setPopupPage(href);
  };

  return (
    <>
      { !!popupPage && (
        <AgreementPopup
          infoType={ popupPage }
          onBack={ () => setPopupPage(null) }
          language={ props.language }
        />
      ) }

      <HypertextBlock
        onClick={ linkClickHandler }
        isFocused={ props.isFocused && !popupPage }
        onLeave={ props.onLeave }
      >
        { agreementText }
      </HypertextBlock>
    </>
  )
};

export default injectIntl(AgreementBlock);
