import { some } from 'lodash';
import * as moment from 'moment';

import Blackout from '~typings/Blackout';
import CatchupAvailability from '~typings/CatchupAvailability';
import Channel from '~typings/Channel';
import Event from '~typings/Event';


const toPivotDate = (date: Date):string => (
  moment(date).format('YYYY-MM-DD')
);

const addDaysToDate = (date: Date, days: number): Date => (
  moment(date).add(days, 'days').toDate()
);

const progressInPercent = (startAt: string, endAt: string, now: Date = new Date()): number => {
  const start = moment(startAt);
  const end = moment(endAt);
  const duration = moment.duration(end.diff(start)).asMinutes();
  const completed = moment.duration(moment(now).diff(start)).asMinutes();
  return Math.round(completed / duration * 100);
}


const eventInCatchupPeriod = (eventStart, eventEnd, catchupPeriodStart) => (
  eventStart > catchupPeriodStart &&
  eventEnd < moment(new Date())
);

const eventInFuture = (startAt, now: Date = new Date()) => (
  (new Date(startAt)) > now
);


const isEventInCatchupPeriod = (startAt: string, endAt: string, catchupAvailability?: CatchupAvailability): boolean => {
  if (!catchupAvailability || !catchupAvailability.available || !startAt || !endAt) { return false; }

  const unit = catchupAvailability.period.unit;
  const value = catchupAvailability.period.value;
  const nowDate = moment(new Date());

  return eventInCatchupPeriod(moment(startAt), moment(endAt), nowDate.subtract(value, unit));
};


const isCatchupAvailable = (event: Event, channel: Channel, blackouts?: Blackout[]): boolean => {
  if (!isEventInCatchupPeriod(
    event.start_at,
    event.end_at,
    channel.catchup_availability,
  )) { return false; }

  if (!blackouts || blackouts.length === 0) { return true; }

  const eventStart = moment(event.start_at);
  const eventEnd = moment(event.end_at);

  return !some(blackouts, (blackout: Blackout) => {
    const blackoutStart = moment(blackout.start_at);
    const blackoutEnd = moment(blackout.end_at);

    const isStartEventDateInBlackout = eventStart.isBetween(
      blackoutStart, blackoutEnd
    );

    const isEndEventDateInBlackout = eventEnd.isBetween(
      blackoutStart, blackoutEnd
    );

    return (isStartEventDateInBlackout || isEndEventDateInBlackout);
  });
};


enum CatchupStatus {
  Unknown = 'unknown',
  Catchup = 'catchup',
  OnAir = 'on_air',
  Future = 'future',
}

const getCatchupStatus = (event: Event) => {
  let status: CatchupStatus = CatchupStatus.Catchup;
  let progress: number = 0;

  if (event) {
    const prgrs = progressInPercent(event.start_at, event.end_at, new Date());

    if (isEventInCatchupPeriod(
      event.start_at,
      event.end_at,
      event.channel?.catchup_availability,
    )) {
      status = CatchupStatus.Catchup;
    } else if (eventInFuture(event.start_at)) {
      status = CatchupStatus.Future;
    } else if (prgrs > 0 && prgrs < 100) {
      status = CatchupStatus.OnAir;
      progress = prgrs;
    }
  }

  return [
    status,
    progress,
  ] as const;
};


export {
  addDaysToDate,
  CatchupStatus,
  getCatchupStatus,
  isCatchupAvailable,
  progressInPercent,
  toPivotDate,
};
