import cn from 'classnames';
import React from 'react';

import * as styles from './Typography.module.css';

export type TypographyVariant =
  | 'h2_medium'
  | 'h2_bold'
  | 'h3_medium'
  | 'h3_bold'
  | 'h5_medium'
  | 'h5_bold'
  | 'h6_medium'
  | 'h6_bold'
  | 'subtitle1_medium'
  | 'subtitle1_medium_underline'
  | 'subtitle1_bold'
  | 'subtitle2_medium'
  | 'subtitle2_medium_underline'
  | 'subtitle2_medium_uppercase'
  | 'subtitle2_bold'
  | 'body1_medium'
  | 'body1_medium_uppercase'
  | 'body1_bold'
  | 'overline_semibold_uppercase';

export interface TypographyProps {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  variant: TypographyVariant;
  textRef?: React.RefObject<HTMLDivElement>;
}

export const Typography: React.FC<TypographyProps> = ({ className, children, variant, textRef }) => {
  return <div ref={ textRef } className={ cn(styles[variant], className) }>{children}</div>;
};
