import { FocuserClickHandler, useFocuserTimeoutHandler } from '@focuser';
import { FocuserClickEventDetail } from '@focuser/events';
import { useHistory } from 'react-router-dom';

import { getClickedElWithDataIdx } from '~newapp/utils/globals/DataAttributeIdx';
import Channel from '~typings/Channel';

import { ChannelsFocusedState } from '../../EPGPageCommon.types';

const FOCUSER_DELAY_MS = 100;

type UseNavigationProps = {
  channels: Channel[];
  focusedState: ChannelsFocusedState;
  setFocusedState: (state: ChannelsFocusedState) => void;
};
export const useEPGChannelsNavigation = ({
  channels,
  focusedState,
  setFocusedState,
}: UseNavigationProps) => {
  const focusedIdx = focusedState.focusedIdx;

  const onKeyDown = useFocuserTimeoutHandler((event) => {
    if (focusedIdx < channels.length - 1) {
      event.stop();
      setFocusedState({
        focusedIdx: focusedIdx + 1,
        focusedChannel: channels[focusedIdx + 1],
      });
    }
  }, FOCUSER_DELAY_MS);

  const onKeyUp = useFocuserTimeoutHandler((event) => {
    if (focusedIdx > 0) {
      event.stop();
      setFocusedState({
        focusedIdx: focusedIdx - 1,
        focusedChannel: channels[focusedIdx - 1],
      });
    }
  }, FOCUSER_DELAY_MS);

  const isPointerUpAvailable = focusedIdx > 0;
  const isPointerDownAvailable = focusedIdx < channels.length - 1;

  return {
    focusedIdx,
    onKeyDown,
    onKeyUp,
    isPointerUpAvailable,
    isPointerDownAvailable,
  };
};

type UseClickHandlerProps = {
  channels: Channel[];
  beforeLeave: (channel: Channel) => void;
  focusedIdx: number;
};
export const useEPGChannelsClickHandler = ({
  focusedIdx,
  channels,
  beforeLeave,
}: UseClickHandlerProps) => {
  const history = useHistory();

  const handleClickOnChannel = (channel: Channel, e: FocuserClickEventDetail) => {
    e.stop();
    e.stopNativeEvent();
    beforeLeave(channel);
    history.push(`/media-item/${channel.object}/${channel.id}?player=true`);
  };

  const handleClickByRemote: FocuserClickHandler = (e) => {
    const channel = channels[focusedIdx];
    if (!channel) {
      return;
    }
    handleClickOnChannel(channel, e);
  };

  const handleClickByMouse: FocuserClickHandler = (e) => {
    const result = getClickedElWithDataIdx(e.nativeEvent);
    if (!result) {
      return;
    }
    const channel = channels[result.idx];
    if (!channel) {
      return;
    }
    handleClickOnChannel(channel, e);
  };

  const handleFocuserClick: FocuserClickHandler = (evt) => {
    if (evt.isPhysicalMouseClick) {
      handleClickByMouse(evt);
      return;
    }
    handleClickByRemote(evt);
  };

  return handleFocuserClick;
};
