import * as React from 'react';

import usePointer from '~stores/Pointer';
import KeyCodes from '~typings/KeyCodes';
import NavigationDirection from '~typings/NavigationDirection';

import PointerManagerWithHooks from './PointerManagerWithHooks';


export const getDirectionsKeyCode = (keyCodes: KeyCodes) => ({
  [NavigationDirection.Left]: keyCodes.KEY_LEFT!,
  [NavigationDirection.Right]: keyCodes.KEY_RIGHT!,
  [NavigationDirection.Up]: keyCodes.KEY_UP!,
  [NavigationDirection.Down]: keyCodes.KEY_DOWN!,
});


const PointerManager = () => {
  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const setPointerEnabled = usePointer(state => state.setPointerEnabled);

  const handlePointer = React.useCallback(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.add('pointerEnabled');
    }
    setPointerEnabled(true);
  }, []);

  const handleKeyDown = React.useCallback(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('pointerEnabled');
    }
    setPointerEnabled(false);
  }, []);

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      if (pointerEnabled) {
        document.addEventListener('keydown', handleKeyDown, { once: true });

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      } else {
        document.addEventListener('mousemove', handlePointer, { once: true });

        return () => {
          document.removeEventListener('mousemove', handlePointer);
        };
      }
    }
  }, [pointerEnabled, handlePointer, handleKeyDown]);

  if (!pointerEnabled) { return null; }

  return (
    <PointerManagerWithHooks />
  );
};


export default PointerManager;
