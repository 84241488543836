import { has } from 'lodash';


enum Storage {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
}


const windowStorage = (storageType: Storage) => window[storageType];

const reHydrate = (storageType: Storage, storageKey: string, fields: string[]): Object => {
  const storage = windowStorage(storageType);
  const lsData = JSON.parse(storage.getItem(storageKey) || '{}');
  const storageObj = {};

  fields.forEach(key => {
    if (has(lsData, key)) {
      storageObj[key] = lsData[key];
    }
  });

  return storageObj;
};

function persistContext<T>(storageType: Storage, storageKey: string, fields: string[], state: T): void {
  const storage = windowStorage(storageType);
  const storageObj = {};

  fields.forEach(key => {
    storageObj[key] = state[key];
  });

  storage.setItem(storageKey, JSON.stringify(storageObj));
}


export {
  persistContext,
  reHydrate,
  Storage,
};
