import focuser, { useFocuserContext } from '@focuser';
import cn from 'classnames';
import React, { useMemo } from 'react';

import { SecondaryButton } from '~app/ui-kit/SecondaryButton';
import { PageController } from '~newapp/ui-kit/Cards_elements/PageController';
import BannerBig from '~typings/BannerBig';

import * as commonStyles from '../../BigBannerCommon.module.css';
import { BigBannerBackgroundImage } from '../BigBannerBackgroundImage/BigBannerBackgroundImage';
import { BigBannerContentText } from '../BigBannerContentText';
import { BigBannerLoading } from '../BigBannerLoading';
import { useBigBannerContentImages, useBigBannerContentNavigation } from './BigBannerContent.hooks';
import * as styles from './BigBannerContent.module.css';

type MiddleBannerContentProps = {
  bigBanners: BannerBig[];
  onClick: (clickedBanner: BannerBig) => void;
};

/**
 * Компонент отображает информацию больших баннеров, когда они загрузились
 */
export const BigBannerContent$: React.FC<MiddleBannerContentProps> = ({ bigBanners, onClick }) => {
  const { focusedBanner, onKeyLeft, onKeyRight, focusedIdx } = useBigBannerContentNavigation({
    bigBanners,
  });

  const { bigBannerImagePreloaded, isLoading: isLoadingImages } = useBigBannerContentImages({
    bigBanners,
    focusedBanner,
  });

  const { isFocused } = useFocuserContext();

  const alignCn = useMemo(() => {
    if (!focusedBanner) return styles.bigBannerContentAlignCenter;

    const aligns: Record<typeof focusedBanner.text_position, string> = {
      'align-right': styles.bigBannerContentAlignRight,
      'align-left': styles.bigBannerContentAlignLeft,
      'align-center': styles.bigBannerContentAlignCenter,
    };

    return aligns[focusedBanner.text_position] || styles.bigBannerContentAlignCenter;
  }, [focusedBanner?.text_position]);

  if(isLoadingImages) {
    return <BigBannerLoading />
  }

  return (
    <div className={ cn(styles.bigBannerContent, commonStyles.mainWrapperWidthAndHeight, alignCn) }>
      {bigBanners.length > 1 && (
        <PageController
          dotsCount={ bigBanners.length }
          selectedIdx={ focusedIdx }
          isFocused={ isFocused }
          className={ styles.pageController }
        />
      )}
      {focusedBanner.big_screen_text_block.button_text && (
        <SecondaryButton
          className={ styles.button }
          isFocused={ isFocused }
        >
          {focusedBanner.big_screen_text_block.button_text}
        </SecondaryButton>
      )}
      <BigBannerContentText bigBanner={ focusedBanner } />
      <focuser.FocusableBlock
        isFocused
        className={ styles.focuserArea }
        noNeedForceFocus
        isLastBlock
        onClick={ (e) => {
          e.stop();
          onClick(focusedBanner);
        } }
        emitForceFocusOnHover
        onKeyLeft={ onKeyLeft }
        onKeyRight={ onKeyRight }
      />
      <BigBannerBackgroundImage
        imageUrl={ bigBannerImagePreloaded }
        className={ styles.backgroundImage }
      />
    </div>
  );
};

export const BigBannerContent = React.memo(BigBannerContent$);
