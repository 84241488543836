import { useEffect, useRef, useState } from "react";

const useStateWithCallback = <T>(
  initialValue: T
): [
  T,
  (
    newValue: T | ((prevState: T) => T),
    callback?: (newState: T) => void
  ) => void
] => {
  const callbackRef = useRef<((state: T) => void) | null>(null);
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(value);

      callbackRef.current = null;
    }
  }, [value]);

  const setValueWithCallback = (newValue, callback) => {
    callbackRef.current = callback ?? null;

    return setValue(newValue);
  };

  return [value, setValueWithCallback];
};


export default useStateWithCallback;
