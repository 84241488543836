import * as React from 'react';

import IconDisLike from '~components/MediaItemPage/Description/bar/icons/dislike';
import IconDisLikeFill from '~components/MediaItemPage/Description/bar/icons/dislikeFill';
import IconFavorite from '~components/MediaItemPage/Description/bar/icons/favorite';
import IconFavoriteFill from '~components/MediaItemPage/Description/bar/icons/favoriteFill';
import IconLike from '~components/MediaItemPage/Description/bar/icons/like';
import IconLikeFill from '~components/MediaItemPage/Description/bar/icons/likeFill';
import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import {
  ALL_EPISODES,
  ALL_PARTS,
  ALL_SEASONS,
  NAV_LEGEND_PLAY_CHANNEL,
  TRAILER,
  VIDEO_PLAY_CONTINUE,
} from '~localization';


const TEXT_BY_BAR_ID: { [key in BarItem]?: string } = {
  [BarItem.Play]: NAV_LEGEND_PLAY_CHANNEL,
  [BarItem.PlayContinue]: VIDEO_PLAY_CONTINUE,
  [BarItem.Trailer]: TRAILER,
  [BarItem.AllEpisodes]: ALL_EPISODES,
  [BarItem.AllSeasons]: ALL_SEASONS,
  [BarItem.AllParts]: ALL_PARTS,
};

const ICON_BY_BAR_ID: { [key in BarItem]?: React.FC<{isFocused: boolean}> } = {
  [BarItem.WatchLaterAdd]: IconFavorite,
  [BarItem.WatchLaterRemove]: IconFavoriteFill,
  [BarItem.RateUp]: IconLike,
  [BarItem.RatedUp]: IconLikeFill,
  [BarItem.RateDown]: IconDisLike,
  [BarItem.RatedDown]: IconDisLikeFill,
};


export {
  ICON_BY_BAR_ID,
  TEXT_BY_BAR_ID,
};
