import React from 'react';

import * as styles from './icons.module.css';
/* eslint-disable max-len */
export const ToUpperCaseIcon = () => {
  return (
    <svg
      className={ styles.icon }
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6363 11.2426L12.8413 0.353092C12.3693 -0.117697 11.6162 -0.117697 11.1442 0.353092L0.359306 11.2426C0.00784133 11.5906 -0.0925771 12.1228 0.088176 12.5834C0.268929 13.0439 0.71077 13.3407 1.20282 13.3407H4.13504V22.122C4.13504 23.1557 4.95847 23.9846 5.96265 23.9846H18.0329C19.0472 23.9846 19.8606 23.1454 19.8606 22.122V13.3407H22.7928C23.2848 13.3407 23.7166 13.0439 23.9074 12.5834C24.0982 12.1228 23.9878 11.5906 23.6363 11.2426ZM18.0229 10.8844H17.5409V21.6409H6.44466V10.8844H4.13504L11.9978 2.95267L19.8606 10.8844H18.0329H18.0229Z"
        fill="currentColor"
      />
    </svg>
  );
};
