import { MutableRefObject, useEffect, useRef } from 'react';

import { Events } from '~lib/player/common';
import { IPlayer } from '~lib/player/typings';
import { DeviceInfo } from '~typings/DeviceInfo';


type Props = {
  player: IPlayer | null;
  isLive: boolean;
  device: DeviceInfo;
};

type Result = {
  currentTime: MutableRefObject<number>,
  started: MutableRefObject<boolean>,
  startTime: MutableRefObject<number | null>,
  time: MutableRefObject<number>,
  startTimeshift: () => void,
  resetTimeshift: () => void,
};

/**
 * useTimeshift
 * used for shifting player current time on missing seconds
 * for old Webos (version < 3)
 */

const useTimeshift = ({ player, isLive, device }: Props): Result => {
  const isWebOS = (device.osName || '').toLowerCase() === 'webos';
  const osVersionNumbers = (device.osVersion || '').split('.');
  const osVersion = parseInt(osVersionNumbers[0], 10);
  const isOldWebos = (isWebOS && !isNaN(osVersion) && (osVersion < 3));

  const started = useRef(false);
  const startTime = useRef<number | null>(null);
  const time = useRef(0);
  const currentTime = useRef(0);
  const secondsMissing = useRef(0);
  const timeshiftInterval = useRef<any>();

  const incrementMissingSecond = () => {
    secondsMissing.current = secondsMissing.current + 1;
  }
  const startTimeshiftTimer = () => {
    if (!timeshiftInterval.current) {
      timeshiftInterval.current = setInterval(() => {
        incrementMissingSecond();
      }, 1000);
    }
  };
  const stopTimeshiftTimer = () => {
    if (timeshiftInterval.current) {
      clearInterval(timeshiftInterval.current);
    }
  };
  const startTimeshift = () => {
    setTimeout(() => {
      if (!player || started.current) { return; }

      started.current = true;

      if (!startTime.current) {
        startTime.current = Math.floor((Date.now() / 1000) - player.getCurrentTime());
      }
      if (isOldWebos) {
        startTimeshiftTimer();
      }
    }, 100);
  };
  const resetTimeshift = () => {
    stopTimeshiftTimer();

    setTimeout(() => {
      started.current = false;
      startTime.current = null;
      currentTime.current = 0;
      secondsMissing.current = 0;
    });
  };

  const handleTimeUpdate = () => {
    if (!player) { return; }

    if (!started.current) {
      startTimeshift();
    }

    currentTime.current = player.getCurrentTime();
    if (isLive) {
      if (currentTime.current === 0) {
        time.current = Date.now();
      } else if (isOldWebos) {
        time.current = ((startTime.current || 0) + currentTime.current + secondsMissing.current) * 1000;
      } else {
        time.current = ((startTime.current || 0) + currentTime.current) * 1000;
      }
      time.current = Math.min(Date.now(), time.current);
    } else {
      time.current = currentTime.current * 1000;
    }
  };
  const handleError = () => {
    resetTimeshift();
  };
  const handlePause = () => {
    setTimeout(() => {
      if (!started.current) { return; }
      started.current = false;

      stopTimeshiftTimer();
    }, 100);
  };
  const handleStop = () => {
    if (!started.current) { return; }
    resetTimeshift();
  };
  const handleEnded = () => {
    if (!started.current) { return; }
    resetTimeshift();
  };

  useEffect(() => {
    if (!player) { return; }

    player.on(Events.TIME_UPDATE, handleTimeUpdate);
    player.on(Events.ERROR, handleError);
    player.on(Events.PAUSE, handlePause);
    player.on(Events.STOP, handleStop);
    player.on(Events.ENDED, handleEnded);

    return () => {
      if (!player) { return; }

      player.removeListener(Events.TIME_UPDATE, handleTimeUpdate);
      player.removeListener(Events.ERROR, handleError);
      player.removeListener(Events.PAUSE, handlePause);
      player.removeListener(Events.STOP, handleStop);
      player.removeListener(Events.ENDED, handleEnded);
    };
  }, [player]);

  return {
    currentTime,
    started,
    startTime,
    time,
    startTimeshift,
    resetTimeshift,
  };
};


export default useTimeshift;
