import { filter, find } from 'lodash';
import { useMutation, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient, useConfig } from '~global';
import ApiClient from '~lib/ApiClient';
import { makeSplitRequestByLimit } from '~lib/fetch';
import Product from '~typings/Product';
import PromoCode from '~typings/PromoCode';
import RentPlan from '~typings/RentPlan';
import ResponseMany from '~typings/ResponseMany';
import Subscription from '~typings/Subscription';

import { fetchOptionsBasic, FetchType, fetchURLs } from '../fetch-parameters';
import { getAuthData } from '../useAccount';
import { getLimitFromConfig } from '../utils';


type PromocodeResult = ResponseMany<PromoCode>;
type ProductsResult = ResponseMany<Product[]>;

export type PromoData = {
  promocode?: PromoCode,
  products?: Product[];
  rentPlans?: RentPlan[];
}

const getKey = (promocode: string, accessToken: string | null) => [
  'promocode_products',
  promocode,
  accessToken,
];

const fetchPromocode = async (
  promocode: string,
  client: ApiClient,
): Promise<PromocodeResult> => client.get(
  `v1/promo_codes/${promocode}`, {
    access_token: getAuthData().accessToken,
    'expand[promo_code]': 'promo'
  }
);

const fetchProducts = async (
  promoId: string,
  limit: number,
  client: ApiClient
): Promise<ProductsResult> => {
  return await makeSplitRequestByLimit<Product[]>({
    client,
    limit,
    url: fetchURLs[FetchType.Products],
    params: {
      ...fetchOptionsBasic[FetchType.Products],
      'catalog_promo_id': promoId,
      access_token: getAuthData().accessToken,
    }
  })
}

const fetchSubscriptions = async (
  promoId: string,
  accessToken: string | null,
  client: ApiClient
): Promise<ResponseMany<Subscription[]>> => {
  return await client.get(
    fetchURLs[FetchType.Subscription],
    {
      ...fetchOptionsBasic[FetchType.Subscription],
      access_token: accessToken,
      catalog_promo_id: promoId
    }
  );
};

const fetchRentPlans = async (
  promoId: string,
  accessToken: string | null,
  limit: number,
  client: ApiClient
): Promise<ResponseMany<RentPlan[]>> => {
  const params = {
    ...fetchOptionsBasic[FetchType.RentPlans],
    access_token: accessToken,
    'catalog_promo_id': promoId,
  }

  return await makeSplitRequestByLimit({
    client,
    url: fetchURLs[FetchType.RentPlans],
    params,
    limit,
  });
};

const fetchAll = async (
  promocode: string,
  client: ApiClient,
  limit: number,
  cacheData?: PromoData,
): Promise<PromoData> => {
  if (cacheData) { return cacheData; }

  const { accessToken } = getAuthData();

  const promocodeResult = await fetchPromocode(promocode, client);

  if (promocodeResult.data.available !== true) {
    return {
      promocode: promocodeResult.data
    };
  }

  const [productsResult, subscriptions, rentPlans] = await Promise.all([
    fetchProducts(promocodeResult.data.promo.id, limit, client),
    fetchSubscriptions(promocodeResult.data.promo.id, accessToken, client),
    fetchRentPlans(promocodeResult.data.promo.id, accessToken, limit, client),
  ]);

  const withoutActiveSubscr = filter(productsResult.data, product => {
    const withProductId = filter(subscriptions.data, ['plan.product.id', product.id]);
    return !find(withProductId, { access_granted: true });
  });

  return {
    promocode: promocodeResult.data,
    products: withoutActiveSubscr,
    rentPlans: rentPlans?.data,
  };
}

const usePromocodeMutation = () => {
  const client = useClient();
  const limit = getLimitFromConfig(useConfig())

  return useMutation(({
    promocode
  }: {
    promocode: string
  }) => (
    fetchAll(promocode, client, limit)
  ), {
    onSuccess: (data, { promocode }) => {
      const queryKey = getKey(promocode, getAuthData().accessToken,);

      queryClient.setQueryData(queryKey, data);
    },
  });
};


const usePromocode = (promocode: string) => {
  const queryKey = getKey(promocode, getAuthData().accessToken,);
  const client = useClient();
  const cachedData = queryClient.getQueryData<PromoData>(queryKey);
  const limit = getLimitFromConfig(useConfig());
  return useQuery<PromoData>(
    queryKey,
    () => fetchAll(promocode, client, limit, cachedData),
    {
      enabled: Boolean(promocode),
    }
  );
};


export {
  usePromocode,
};

export default usePromocodeMutation;
