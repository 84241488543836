// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blockScrollableY--wkuNW {\n  position: relative;\n}\n\n.blockScrollableY--wkuNW.hide--c1h4f {\n  visibility: hidden;\n}\n\n.rknCustomClass--lmXnL {\n  position: absolute;\n  bottom: -27vw;\n}", "",{"version":3,"sources":["webpack://./src/app/components/BlockPage/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".blockScrollableY {\n  position: relative;\n}\n\n.blockScrollableY.hide {\n  visibility: hidden;\n}\n\n.rknCustomClass {\n  position: absolute;\n  bottom: -27vw;\n}"],"sourceRoot":""}]);
// Exports
export var blockScrollableY = "blockScrollableY--wkuNW";
export var hide = "hide--c1h4f";
export var rknCustomClass = "rknCustomClass--lmXnL";
export default ___CSS_LOADER_EXPORT___;
