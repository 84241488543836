import * as cn from 'classnames';
import * as React from 'react';

import CatchBack from '~components/PageManager/CatchBack';
import AudioTracksPopup from '~components/ScenePlayer/Player/controls/Popup/AudioTracks';
import ChannelsListPopup from '~components/ScenePlayer/Player/controls/Popup/ChannelsList';
import PlayerEPGPopup from '~components/ScenePlayer/Player/controls/Popup/EPG';
import TextTracksPopup from "~components/ScenePlayer/Player/controls/Popup/TextTracks";
import VideoTracksPopup from '~components/ScenePlayer/Player/controls/Popup/VideoTracks';
import { IconBar } from '~components/ScenePlayer/Player/controls/usePlayerControlsNavigation';
import useDelayedChange from '~hooks/useDelayedChange';
import { makeWeekDays } from '~lib/localeUtils/dates';
import { IPlayer } from '~lib/player/typings';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';

import EpisodesListPopup from './EpisodesList';
import ItemDetailsPopup from './ItemDetails';
import * as styles from './styles.module.css';


type Popup = Readonly<{
  player: IPlayer;
  item: PlayerMediaItem;
  popupName: IconBar | null;
  isVisiblePopup: boolean;
  onClose: () => void;
}>;


const PlayerPopup: React.FC<Popup> = (props: Popup) => {
  const isVisiblePopupDelayed = useDelayedChange(props.isVisiblePopup, 300);
  const [days] = React.useState(makeWeekDays);

  const renderPopup = React.useMemo(() => {
    if (!props.isVisiblePopup || !isVisiblePopupDelayed) { return null; }

    switch (props.popupName) {
      case IconBar.EPG: {
        if (props.item.object === ItemObject.Channel) {
          return (
            <PlayerEPGPopup
              days={ days }
              channelID={ props.item.id }
              onClose={ props.onClose }
            />
          );
        }

        if (props.item.object === ItemObject.ProgramEvent) {
          return (
            <PlayerEPGPopup
              days={ days }
              channelID={ props.item.channel_id }
              playingEvent={ props.item }
              onClose={ props.onClose }
            />
          );
        }

        return null;
      }
      case IconBar.EpisodesList: {
        if (props.item.object === ItemObject.Episode) {
          return (
            <EpisodesListPopup
              currentEpisode={ props.item }
              onClose={ props.onClose }
            />
          );
        }

        return null;
      }
      case IconBar.ChannelsList: {
        const channelID = (props.item.object === ItemObject.ProgramEvent) ?
          props.item.channel_id
          :
          props.item.id;

        return (
          <ChannelsListPopup
            channelID={ channelID }
            onClose={ props.onClose }
          />
        );
      }
      case IconBar.Info: {
        return (
          <ItemDetailsPopup
            item={ props.item }
            onClose={ props.onClose }
          />
        )
      }
      case IconBar.TextTracks: {
        return (
          <TextTracksPopup
            player={ props.player }
            onClose={ props.onClose }
          />
        );
      }
      case IconBar.VideoTracks: {
        return (
          <VideoTracksPopup
            player={ props.player }
            onClose={ props.onClose }
          />
        );
      }
      case IconBar.AudioTracks: {
        return (
          <AudioTracksPopup
            player={ props.player }
            onClose={ props.onClose }
          />
        );
      }
      default: return null;
    }
  }, [props.isVisiblePopup, isVisiblePopupDelayed, props.popupName]);

  if (renderPopup === null) { return null; }

  const isRadialBG = (
    props.popupName === IconBar.EPG ||
    props.popupName === IconBar.ChannelsList ||
    props.popupName === IconBar.EpisodesList ||
    props.popupName === IconBar.Info
  );
  const isSolidBG = (
    !isRadialBG && (
      props.popupName === IconBar.AudioTracks
      || props.popupName === IconBar.TextTracks
      || props.popupName === IconBar.VideoTracks
    )
  );

  return (
    <div
      className={ cn(styles.playerPopup, {
        [styles.playerPopupRadialBG]: isRadialBG,
        [styles.playerPopupWithBG]: isSolidBG,
      }) }
    >
      <CatchBack
      action={ props.onClose }
      triggers={ [props.onClose] }
    />
      { renderPopup }
    </div>
  );
}


export default React.memo(PlayerPopup);
