import * as React from 'react';

import { IconLikeBase } from './likeBase';

const IconLike: React.FC<{ isFocused: boolean }> = ({ isFocused }) => (
  <IconLikeBase
    isFocused={ isFocused }
    isPressed={ false }
  />
);

export default React.memo(IconLike);
