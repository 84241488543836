// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flipperWrapper--EDFVS {\n  margin-top: 3.90625vw;\n  margin-left: 6.875000000000001vw;\n}\n\n.wrapperSlider--Xxlld {\n  width: 74.21875vw;\n  height: 51.5625vw;\n}", "",{"version":3,"sources":["webpack://./src/newapp/blocks/playerPopcorn/Player/controls/Popup/ChannelFlipper/ChannelFlipper.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".flipperWrapper {\n  margin-top: 3.90625vw;\n  margin-left: 6.875000000000001vw;\n}\n\n.wrapperSlider {\n  width: 74.21875vw;\n  height: 51.5625vw;\n}"],"sourceRoot":""}]);
// Exports
export var flipperWrapper = "flipperWrapper--EDFVS";
export var wrapperSlider = "wrapperSlider--Xxlld";
export default ___CSS_LOADER_EXPORT___;
