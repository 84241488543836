import { filter, findIndex, isFunction } from 'lodash';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { AuthLinks } from '~components/AuthLinks';
import { AuthLinkType } from '~components/AuthLinks/link';
import FieldSet from '~components/Fieldset/components';
import LightLoader from '~components/LightLoader';
import { PROFILES_PAGE_PATHNAME } from '~components/ProfilesManager/component';
import { ActionType, useAppAction } from '~components/Provider/App';
import { useClient,useConfig } from '~global';
import {
  AuthActionType,
  useAccount,
  useAuthMutation,
  usePasswordCodeMutation,
  useSendSecurityCodeMutation
} from '~hooks/fetch/useAccount';
import { usePasswordBySms } from '~hooks/fetch/useAccount/usePasswordBySms';
import { useProfiles } from '~hooks/fetch/useProfiles';
import { useCountdownCounter } from '~hooks/useCountdownCounter';
import { useIsImplicitFlow } from '~hooks/useIsImplicitFlow';
import { useStateWithCallback } from '~hooks/useStateWithCallback';
import { setIPTVCredentials } from '~lib/ConfigUtils';
import { PASSWORD_HAS_SENDED_WITH_PHONE, RESEND_PASSWORD_COUNTER_TEXT, RESEND_SECURITY_CODE_AFTER } from '~localization';
import { AccountField } from '~typings/AccountInfo';
import NavigationDirection from '~typings/NavigationDirection';

import CodeAuthBlock from './CodeAuthBlock';
import ConfirmCodeForm from './ConfirmCodeForm';
import { getMessageByError } from './helpers';
import PasswordForm from './PasswordForm';
import PhoneCallConfirmBlock, { PhoneConfirmType } from './PhoneCallConfirmBlock';
import PreviewCredentials from './PreviewCredentials';
import * as styles from './styles.module.css';
import { AuthState, AuthType, initAuthState, LayoutType, PageFocusOn } from './types';
import UsernameForm from './UsernameForm';


type AuthStepScreenProps = Readonly<{
	layoutType: LayoutType;
  authType?: AuthType;
  confirmType?: PhoneConfirmType;
	headerText: string;
	descriptionText?: string | ((authState: AuthState) => string | undefined);
	errorText?: string;
  defaultFocus?: PageFocusOn | null;
	links?: Array<AuthLinkType>,
  footerBlock?: (
    React.ReactNode |
    ((
      isFocused: boolean,
      onLeave: (dir: NavigationDirection) => void
    ) => React.ReactNode)
  );
  linkClickHandlers?: { [key in AuthLinkType]?: () => void };
  onSubmit?: (newState: AuthState) => void;
  onError?: (state: AuthState, error: any) => void;
  intl: InjectedIntl;
}>;

const AuthStepScreen: React.FC<AuthStepScreenProps> = ({
  headerText,
  layoutType,
  links,
  footerBlock,
  authType,
  onSubmit,
  onError,
  linkClickHandlers,
  intl,
  ...props
}: AuthStepScreenProps) => {
  const { data: accountInfo } = useAccount();
  const { smartTV } = useConfig();
  const isImplicitFlow = useIsImplicitFlow();
  const { mutate: signIn } = useAuthMutation();
  const client = useClient();
  const history = useHistory();
  const appAction = useAppAction();
  const { mutate: sendSecurityCode } = useSendSecurityCodeMutation();
  const { sendPassCode } = usePasswordCodeMutation();
  const { mutate: sendPasswordBySms } = usePasswordBySms();
  const { data: profiles } = useProfiles();

  const [authState, setAuthState] = useStateWithCallback<AuthState>(initAuthState);

  const descriptionTextFromProps = isFunction(props.descriptionText)
    ? props.descriptionText(authState)
    : props.descriptionText;

  const [descriptionText, setDescriptionText] = React.useState<string | undefined>(
    descriptionTextFromProps
  );
  const [errorText, setErrorText] = React.useState<string | undefined>(props.errorText);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

  const RESEND_DELAY = 300;
  const [resendPasswordCounter, setResetPasswordCounter] = useCountdownCounter();
  const [resendConfirmCodeCounter, setResendConfirmCodeCounter] = useCountdownCounter();

  // Focus

  const isFormFocusable = (
    layoutType !== LayoutType.CodeAuth &&
    layoutType !== LayoutType.BigText
  );
  const isFooterFocusable = isFunction(footerBlock);
  const isLinksDisabled = (
    layoutType === LayoutType.PasswordForm && resendPasswordCounter > 0 ||
    layoutType === LayoutType.ConfirmCodeForm && resendConfirmCodeCounter > 0 ||
    authType === AuthType.PasswordReset
  );
  const isLinksFocusable = (links !== undefined && links?.length !== 0) && !isLinksDisabled;

  const defaultFocus =
    props.defaultFocus !== undefined
      ? props.defaultFocus
      : isFormFocusable
        ? PageFocusOn.Form
        : isLinksFocusable
          ? PageFocusOn.Links
          : isFooterFocusable
            ? PageFocusOn.Footer
            : null;

  const [focusOn, setFocusOn] = React.useState<PageFocusOn | null>(defaultFocus);

  const isFormFocused = focusOn === PageFocusOn.Form;
  const isLinksFocused = focusOn === PageFocusOn.Links;
  const isFooterFocused = focusOn === PageFocusOn.Footer;

  const isSendPasswordBySMSButtonAvailable = (
    isImplicitFlow &&
    findIndex(links, l => l === AuthLinkType.ResendPasswordBySms) !== -1
  );

  const preparedLinks = React.useMemo(() => (
    filter(links, link => (
        isSendPasswordBySMSButtonAvailable
          ? link !== AuthLinkType.ForgotPassword
          : link !== AuthLinkType.ResendPasswordBySms
      )
    )
  ), [links, isSendPasswordBySMSButtonAvailable]);

  // ---

  React.useEffect(() => {
    appAction({
      type: ActionType.SetIsLaunchingDone,
      payload: { isLaunchingDone: true },
    });
  }, []);
  React.useEffect(() => setErrorText(''), [layoutType, authType]);
  React.useEffect(() => { if (isProcessing) setErrorText('') }, [isProcessing]);
  React.useEffect(() => setFocusOn(defaultFocus), [layoutType, defaultFocus]);
  React.useEffect(() => setDescriptionText(descriptionTextFromProps), [descriptionTextFromProps]);
  React.useEffect(() => setErrorText(props.errorText), [props.errorText]);

  React.useEffect(() => {
    const username = accountInfo?.[AccountField.Username];
    if (username) {
      submitScreenState({ username }, true);
    }
  }, [accountInfo?.[AccountField.Username]]);

  React.useEffect(() => {
    if (
      authType === AuthType.PasswordReset
      && layoutType === LayoutType.ConfirmCodeForm
      && resendPasswordCounter === 0
      && !isProcessing
    ) {
      sendPassCode({ username: authState.username }, {
        onSuccess: () => {
          setResendConfirmCodeCounter(300);
          setFocusOn(PageFocusOn.Form);
        },
        onSettled: () => setIsProcessing(false),
        onError: handleCodeError
      });
    }
  }, [
    authState.username,
    authType,
    layoutType,
    resendPasswordCounter,
    isProcessing,
  ]);

  React.useEffect(() => {
    if (authType === AuthType.SignUp) {
      const params = { username: authState.username };
      const options = {
        onSuccess: () => {
          setResendConfirmCodeCounter(RESEND_DELAY);
          setFocusOn(PageFocusOn.Form);
        },
        onSettled: () => setIsProcessing(false),
        // onError: handleCodeError,
      };
      sendSecurityCode(params, options);
    }
  }, [authType, layoutType])

  const handleCodeError = (error) => {
    const messageText = getMessageByError(error, intl.formatMessage);
    setErrorText(String(messageText));
  };

  const errorHandler = (errorMessage, error) => {
    setErrorText(String(errorMessage));
    if (onError) onError(error, authState);
  };

  const formLeaveHandler = (direction) => {
    if (direction === NavigationDirection.Down) {
      if (isLinksFocusable) setFocusOn(PageFocusOn.Links);
      else if (isFooterFocusable) setFocusOn(PageFocusOn.Footer);
    }
  };

  const handleLeaveLinks = React.useCallback((direction) => {
    if (direction === NavigationDirection.Up && isFormFocusable) {
      setFocusOn(PageFocusOn.Form);
    } else if (direction === NavigationDirection.Down && isFooterFocusable) {
      setFocusOn(PageFocusOn.Footer);
    }
  }, [isFormFocusable, isFooterFocusable]);

  const handleLeaveFooter = (direction) => {
    if (direction === NavigationDirection.Up) {
      if (isLinksFocusable) setFocusOn(PageFocusOn.Links);
      else if (isFormFocusable) setFocusOn(PageFocusOn.Form);
    }
  };

  const submitScreenState = (
    patch?: Partial<AuthState>,
    onlyMerge?: boolean | any,
    cb?: (state: AuthState) => void
  ) => {
    setAuthState(oldState => ({
      ...oldState,
      ...patch,
    }), (newState) => {
      if (cb) cb(newState);
      if ((!onlyMerge || onlyMerge?.type === AuthActionType.AuthByDeviceCode) && onSubmit) onSubmit(newState);
    });
  }

  const completedLinkClickHandlers = {
    [AuthLinkType.Register]: () => submitScreenState({ isCredentialsSubmitted: true }, true),
    [AuthLinkType.ResendSecurityCode]: () => {
      const params = { username: authState.username };
      const options = {
        onSuccess: () => {
          setResendConfirmCodeCounter(RESEND_DELAY);
          setFocusOn(PageFocusOn.Form);
        },
        onSettled: () => setIsProcessing(false),
        onError: handleCodeError,
      };

      setIsProcessing(true);

      if (authType === AuthType.SignUp) {
        sendSecurityCode(params, options);
      } else if (authType === AuthType.PasswordReset) {
        sendPassCode(params, options);
      } else {
        setIsProcessing(false);
      }
    },
    [AuthLinkType.ResendPasswordBySms]: () => {
      setIsProcessing(true);
      sendPasswordBySms({ username: authState.username }, {
        onSuccess: () => {
          setResetPasswordCounter(RESEND_DELAY);
          setDescriptionText(intl.formatMessage(
            { id: PASSWORD_HAS_SENDED_WITH_PHONE },
            { phone: authState.username }
          ));
          setFocusOn(PageFocusOn.Form);
        },
        onSettled: () => setIsProcessing(false),
      });
    },
    [AuthLinkType.EnableAutomaticLogIn]: () => {
      appAction({
        type: ActionType.SetEnableAutomaticLogIn,
        payload: { enableAutomaticLogIn: true },
      });

      setIPTVCredentials(false, smartTV, client);

      signIn(
        {
          type: AuthActionType.AuthByHeader,
          payload: { iptvAuthUrl: smartTV.IPTVAPI }
        },
        {
          onSuccess: () => {
            if (accountInfo && !accountInfo.parental_control && profiles && profiles.length === 1) {
              appAction({
                type: ActionType.SetActiveProfileId,
                payload: { activeProfileId: profiles[0].id },
              });
              history.replace('/');
            } else {
              history.replace(PROFILES_PAGE_PATHNAME);
            }
          },
        }
      );
    },
    ...linkClickHandlers,
  };

  // Layouts

  let LayoutComponent;
  switch(layoutType) {
    case LayoutType.CodeAuth:
      LayoutComponent = (
        <CodeAuthBlock
          onSuccess={ submitScreenState }
          onError={ errorHandler }
        />
      );
      break;

    case LayoutType.UsernameForm:
      if (!authType) {throw 'UsernameForm: authType is not defined';}
      if(authType === AuthType.PasswordReset) {
        LayoutComponent = (
          <UsernameForm
            authType={ authType }
            defaultValue={ authState.username }
            onSuccess={ (username, confirmationType) => submitScreenState({ username, confirmationType }) }
            onError={ errorHandler }
            onProcessingStatus={ setIsProcessing }
            onLeave={ formLeaveHandler }
          />
        );
      } else {
        LayoutComponent = (
          <UsernameForm
            authType={ authType }
            defaultValue={ authState.username }
            onSuccess={ (username, confirmationType) => submitScreenState({ username, confirmationType }) }
            onError={ errorHandler }
            onProcessingStatus={ setIsProcessing }
            onLeave={ formLeaveHandler }
            isFocused={ isFormFocused }
          />
        );
      }
      break;

    case LayoutType.PasswordForm:
      if (!authType) { throw 'PasswordForm: authType is not defined'; }
      LayoutComponent = (
        <PasswordForm
          authType={ authType }
          username={ authState.username }
          code={ authState.code }
          onSuccess={ (password) => submitScreenState({ password }) }
          onError={ errorHandler }
          onProcessingStatus={ setIsProcessing }
          onLeave={ formLeaveHandler }
          isFocused={ isFormFocused }
        />
      );
      break;

    case LayoutType.ViewCredentials:
      LayoutComponent = (
        <PreviewCredentials
          className={ styles.previewLayout }
          username={ authState.username }
          password={ authState.password }
          isSubmitted={ authState.isCredentialsSubmitted }
          onSuccess={ submitScreenState }
          onProcessingStatus={ (isProcessing) => {
            if (isProcessing) submitScreenState({ isCredentialsSubmitted: false }, true);
            setIsProcessing(isProcessing);
          } }
          onLeave={ formLeaveHandler }
          isFocused={ isFormFocused }
        />
      );
      break;
    case LayoutType.ConfirmCodeForm:
      if (!authType) { throw 'ConfirmCodeForm: authType is not defined'; }
      LayoutComponent = (
        <ConfirmCodeForm
          authType={ authType }
          username={ authState.username }
          password={ authState.password }
          onProcessingStatus={ setIsProcessing }
          onSuccess={ code => submitScreenState({ code }) }
          onError={ errorHandler }
          onLeave={ formLeaveHandler }
          isFocused={ isFormFocused }
        />
      );
      break;

    case LayoutType.PhoneCallConfirmBlock:
        if (!props.confirmType) { throw 'PhoneCallConfirmBlock: confirmType is not defined'; }
        LayoutComponent = (
          <PhoneCallConfirmBlock
            confirmType={ props.confirmType }
            username={ authState.username }
            password={ authState.password }
            onProcessingStatus={ setIsProcessing }
            onSuccess={ submitScreenState }
            onError={ errorHandler }
          />
        );
        break;
  }

  const counterText = (
    layoutType === LayoutType.PasswordForm && resendPasswordCounter > 0
      ? intl.formatMessage(
        { id: RESEND_PASSWORD_COUNTER_TEXT },
        { sec: resendPasswordCounter }
      )
      : layoutType === LayoutType.ConfirmCodeForm && resendConfirmCodeCounter > 0
        ? intl.formatMessage(
          { id: RESEND_SECURITY_CODE_AFTER },
          { sec: resendConfirmCodeCounter }
        )
        : null
  );

  return (
    <>
      { isProcessing && <LightLoader /> }

      <FieldSet
        headerText={ headerText }
        errorText={ errorText }
        descriptionText={ descriptionText }
        footer={
          <>
            {
              (links && !counterText) && (
                <AuthLinks
                  links={ preparedLinks }
                  isFocused={ isLinksFocused }
                  authType={ authType }
                  onLeave={ handleLeaveLinks }
                  onClick={ completedLinkClickHandlers }
                />

              )
            }
            {
              (footerBlock || counterText) && (
                <div className={ styles.footerText }>
                  <div className={ styles.counter }>{ counterText }</div>
                  { isFunction(footerBlock)
                    ? footerBlock(isFooterFocused, handleLeaveFooter)
                    : footerBlock
                  }
                </div>
              )
            }
          </>
        }
      >
        { LayoutComponent }
      </FieldSet>
    </>
  );
}

export default React.memo(injectIntl(AuthStepScreen));
