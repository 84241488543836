import * as React from 'react';

import { progressInPercent } from "~lib/eventsHelper";

import * as css from './CurrentEventProgress.module.css';


interface Props {
  startAt: string;
  endAt: string;
}

const CurrentEventProgress$ = (props: Props) => {
  const [progress, setProgress] = React.useState<number>(
    (props.startAt && props.endAt)
      ? progressInPercent(props.startAt, props.endAt, new Date())
      : 0
  );
  const timeout = React.useRef<any>(null);

  const handleClearTimeout = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  const handleChangeProgress = (startAt: string, endAt: string) => {
    handleClearTimeout();

    setProgress(progressInPercent(startAt, endAt, new Date()));

    timeout.current = setTimeout(() => {
      handleChangeProgress(startAt, endAt);
    }, 5000);
  }

  React.useEffect(() => {
    if (props.startAt && props.endAt) {
      handleChangeProgress(props.startAt, props.endAt);
    }
    else {
      setProgress(0);
    }
  }, [props.startAt, props.endAt]);

  React.useEffect(() => {
    return () => handleClearTimeout()
  }, []);

  return (
    <div className={ css.progressBarWrapper }>
      { progress !== 0 ? <div className={ css.progressBar } style={ { width: `${ progress }%` } } /> : null }
    </div>
  )
};


export const CurrentEventProgress = React.memo(CurrentEventProgress$)
