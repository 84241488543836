import cn from "classnames";
import { gw_01 } from 'customization-project-name/colors'
import React from "react";

import { Nullable } from "~lib/type-utils/utils";
import { GoTo } from "~newapp/ui-kit/Controls/Buttons/Icon_btn/Focused_cards_btn/Go_to/GoTo";

import * as css from "./Card.module.css";

type Props = {
  isFocused?: boolean
  imageUrl?: Nullable<string>
}

const Card$ = (props: Props) => {
  return (
    <div
      className={ cn(css.card, css.cardRelative) }
      style={ { backgroundSize: 'cover', backgroundImage: `url('${ props.imageUrl }')`, backgroundColor: gw_01 } }>
      <div className={ cn(css.card, css.cardAbsolute, { [css.focusedCard]: props.isFocused }) } />
      { props.isFocused ? <div className={ css.centerIcon }><GoTo /></div> : null }
    </div>
  )
}

export const Card = Card$