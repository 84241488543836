import * as React from 'react';
import { useSpring } from 'react-spring';

import usePrevious from '~hooks/usePrevious';
import { useSetState } from '~hooks/useSetState';
import Episode from '~typings/Episode';


type Props = Readonly<{
  episodes: Episode[];
  focusedIndex: number;
}>;

type State = Readonly<{
  episodes: Episode[];
  focusedIndex: number;
  isEpisodesListWasChanged: boolean;
}>;


const useEpisodesHelpers = (props: Props) => {
  const [state, setState] = useSetState<State>({
    episodes: props.episodes,
    focusedIndex: props.focusedIndex,
    isEpisodesListWasChanged: false,
  });
  const prevEpisodes = usePrevious(props.episodes);
  const prevFocusedIndex = usePrevious(props.focusedIndex);
  const opacity = (isNeedToShow: boolean): number => (isNeedToShow ? 1 : 0);
  const [springProps, setOpacity] = useSpring(() => ({ opacity: opacity(!state.isEpisodesListWasChanged) }));

  // When episodes was changed (user switched selected season)
  // Need Fade Slider
  React.useEffect(() => {
    if (prevEpisodes) {     // if Not First Render
      setState({
        episodes: prevEpisodes,
        // That there would be no reset of an index during animation of a fade
        focusedIndex: prevFocusedIndex,
        isEpisodesListWasChanged: true,
      });

      const timeout = setTimeout(() => {
        setState({ isEpisodesListWasChanged: false });
      }, 300);

      return (() => {
        clearTimeout(timeout);
      });
    }
  }, [props.episodes]);
  React.useEffect(() => {
    setOpacity({ opacity: opacity(!state.isEpisodesListWasChanged) });

    if (!state.isEpisodesListWasChanged) {
      setState({
        episodes: props.episodes,
        focusedIndex: props.focusedIndex,
      });
    }
  }, [state.isEpisodesListWasChanged]);

  const focusedIndex = state.isEpisodesListWasChanged ?
    state.focusedIndex
    :
    props.focusedIndex;

  return ({
    springProps,
    focusedIndex,
    episodes: state.episodes,
  });
};


export default useEpisodesHelpers;
