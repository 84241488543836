/* eslint-disable max-len */
import * as React from 'react';


const IconFavorite: React.FC = () => (
  <svg
    className="playerIcon"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      fill="currentColor"
      points="19.856 11.034 28.289 12.215 22.224 18.187 23.716 26.554 16.159 22.638 8.634 26.619 10.028 18.236 3.898 12.345 12.315 11.082 16.045 3.444 19.856 11.034"
    />
  </svg>
);


export default React.memo(IconFavorite);
