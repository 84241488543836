export function loadScripts(scriptsArray) {
  return Promise.all(
    scriptsArray.map(src => {
      const scriptNode = document.createElement('script');

      scriptNode.setAttribute('type', 'text/javascript');
      scriptNode.setAttribute('language', 'javascript');
      scriptNode.setAttribute('src', src);

      document.body.appendChild(scriptNode);

      return new Promise((resolve, reject) => {
        const handleScriptError = () => {
          window.removeEventListener('error', handleScriptError);

          scriptNode.onload = null;
          scriptNode.onerror = null;

          return reject(new Error(`Platform script loading error: ${src}`));
        };

        window.addEventListener('error', handleScriptError);

        scriptNode.onload = resolve;
        scriptNode.onerror = reject;
      });
    })
  );
}

export function loadObjects(objectsArray) {
  objectsArray.forEach(object => {
    const objectNode = document.createElement('object');

    Object.keys(object).forEach(key => {
      objectNode.setAttribute(key, object[key]);
    });

    document.body.appendChild(objectNode);
  });
}

export function guid(length = 5) {
  const parts = [];

  for (let i = 0; i < length; i += 1) {
    // eslint-disable-next-line no-bitwise
    parts.push((((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1));
  }

  return parts.join('-');
}
