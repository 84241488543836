enum MenuID {
  'Account' = 'account',
  'Search' = 'search',
  'Home' = 'home',
  'PersonalPage' = 'personalPage',
  'Products' = 'products',
  'Auth' = 'auth',
  'TV' = 'tv',
  'Catalog' = 'catalog'
}


export default MenuID;
