import { useRef } from "react";

/**
 *  Хук для хранения максимального значения
 *  То есть если сначала передать в хук 5, потом 10, потом снова 5, то хук вернет 10 (запоминает максимальное значение)
 */
export const useMaxMemoized = (max: number) => {
  const maximalIdxRef = useRef(0);
  maximalIdxRef.current = Math.max(maximalIdxRef.current, max);

  return {
    max: maximalIdxRef.current,
    reset: () => maximalIdxRef.current = 0
  };
};
