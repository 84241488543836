import * as React from 'react';

import { useApp } from '~components/Provider/App';

import GlobalLoader from './component';


type Props = Readonly<{
  children: any;
}>;


const GlobalLoaderWrapper: React.FC<Props> = (props: Props) => {
  const { isLaunchingDone } = useApp();
  const isVisibleLoaderPage = !isLaunchingDone;

  return (
    <GlobalLoader isOverPage isVisible={ isVisibleLoaderPage }>
      <div
        style={ {
          position: 'relative',
          height: '100vh',
          width: '100vw',
          display: (isVisibleLoaderPage) ? 'none' : 'block',
          overflow: "hidden",
        } }
      >
        { props.children }
      </div>
    </GlobalLoader>
  );
};


export default GlobalLoaderWrapper;
