import * as cn from 'classnames';
import * as React from 'react';

import { IconTop } from './icons/IconTop';
import * as styles from './styles.module.css';


type Props = Readonly<{
  isVisible: boolean;
  onClick: () => void;
}>;


const ArrowTop: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={ cn(styles.arrowTop, {
        [styles.arrowTopVisible]: props.isVisible,
      }) }
      onClick={ props.onClick }
    >
      <IconTop />
    </div>
  );
};


export default React.memo(ArrowTop);
