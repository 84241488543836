// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".recommendationsWrapper--PA04_ {\n  will-change: transform;\n  -webkit-transition: transorm 500ms linear;\n  transition: transorm 500ms linear;\n  transform: translate3d(0, 0, 0);\n  -webkit-transform: translate3d(0, 0, 0);\n}\n\n.blockTitle--NmPP8, .blockTitleOnNewcards--lrzuh {\n  color: rgba(255, 255, 255, 1);\n  font-weight: 500;\n}\n\n\n.blockTitleOnNewcards--lrzuh {\n  margin-left: 0.9375vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/Recommendations/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,yCAAwD;EAAxD,iCAAwD;EACxD,+BAA+B;EAC/B,uCAAuC;AACzC;;AAEA;EACE,6BAAa;EACb,gBAAkB;AACpB;;;AAGA;EACE,qBAAqB;AACvB","sourcesContent":[".recommendationsWrapper {\n  will-change: transform;\n  transition: transorm $(switchSliderAnimationMS)ms linear;\n  transform: translate3d(0, 0, 0);\n  -webkit-transform: translate3d(0, 0, 0);\n}\n\n.blockTitle, .blockTitleOnNewcards {\n  color: $gw_11;\n  font-weight: $fw_5;\n}\n\n\n.blockTitleOnNewcards {\n  margin-left: 0.9375vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var recommendationsWrapper = "recommendationsWrapper--PA04_";
export var blockTitle = "blockTitle--NmPP8";
export var blockTitleOnNewcards = "blockTitleOnNewcards--lrzuh";
export default ___CSS_LOADER_EXPORT___;
