

export enum AccountTabId {
  /**
   * Управление аккаунтом
   */
  AccountControl = 'accountControl',
  /**
   * Профили
   */
  Profiles = 'profiles',
  /**
   * Подписки
   */
  Subscriptions = 'subscriptions',
  /**
   * Покупки
   */
  Purchases = 'purchases',
  /**
   * Платежи
   */
  Payments = 'payments',
  /**
   * Безопасность
   */
  Security = 'security',
  /**
   * Мои карты
   */
  Cards = 'cards',
  /**
   * Мои устройства
   */
  Devices = 'devices',
  /**
   * Промокод
   */
  Promocode = 'promocode',
  /**
   * Настройки
   */
  Settings = 'settings',
  /**
   * Помощь
   */
  Help = 'help',
  /**
   * О программе
   */
  About = 'about',
  /**
   * Статистика
   */
  Stats = 'stats',
  /**
   * Войти (только для неавторизованных юзеров)
   */
  Signin = 'signin',
}

export type AccountTab = {
  id: AccountTabId,
  text: string,
}
