const formatTime = (seconds: number): string => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds - (hrs * 3600)) / 60);
  const sec = seconds - (hrs * 3600) - (mins * 60);

  return (
    `${(hrs < 10) ? '0' + hrs : hrs}:${(mins < 10) ? '0' + mins : mins}:${(sec < 10) ? '0' + sec : sec}`
  );
};

const formatTimeLeft = (duration: number, currentTime: number): string => (
  `-${ formatTime(Math.round(duration - currentTime)) }`
);

const formatTimeLeftForPopup = ( currentTime: number): string => (
  `${ formatTime(Math.round(currentTime)) }`
);

const rounded = (number: number): number => {
  return +number.toFixed(2);
}

const getPercents = (duration: number, currentTime: number): number => {
  return rounded(currentTime * 100 / duration);
};


export {
  formatTime,
  formatTimeLeft,
  formatTimeLeftForPopup,
  getPercents,
  rounded};
