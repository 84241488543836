import * as cn from 'classnames'
import { throttle } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ANIMATION_TIME, getProductIndex } from '~components/ProductsList/utils';
import { ActionType, useMenu, useMenuAction } from '~components/Provider/Menu';
import ScrollableSliderBlock from '~components/Slider/scrollable';
import useNavigationByKeys from '~hooks/useNavigation';
import getImageRSC from '~lib/MediaItemImageSRC';
import getProductInSubscriptions, { getActiveSubscriptionWithProduct } from '~lib/product/getProductInSubscriptions';
import { MENU_SUBSCRIPTIONS } from '~localization';
import usePointer from '~stores/Pointer';
import ImageType from '~typings/ImageType';
import NavigationDirection from '~typings/NavigationDirection';
import Product from '~typings/Product';
import ResponseSingle from '~typings/ResponseSingle';
import Subscription from '~typings/Subscription';

import BestPrice from './BestPrice'
import * as styles from './styles.module.css';


type Props = Readonly<{
  products?: ResponseSingle<Product[]>;
  subscriptions?: Subscription[];
}>;


const ProductsList: React.FC<Props> = (props: Props) => {
  const menu = useMenu();
  const menuAction = useMenuAction();
  const isPopupOpened = (menu.isMenuOpened || menu.isPopupOpened);
  const subscriptions = props.subscriptions || [];
  const history = useHistory();
  const products = React.useMemo(() => (props.products?.data || []).filter(
    p => (p?.markers || []).includes('featured')
  ), [props.products?.data]);
  const productCount = products?.length ?? 0;
  const [productIndex, setProductIndex] = React.useState(
    () =>  getProductIndex(history)
  );
  // show/hide pointer navigation
  const isPointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);
  const flush = usePointer(state => state.flush);

  const navigationUpdate = [productIndex];

  const handleUp = () => {
    menuAction({
      type: ActionType.ChangeMenuState,
      isMenuOpened: true,
    });
  };
  const handleRight = () => {
    const newProductIndex = Math.min(
      (productCount - 1),
      (productIndex + 1)
    );

    setProductIndex(newProductIndex);
    history.replace({
      search: `productIndex=${newProductIndex}`,
    });
  };
  const handleLeft = () => {
    const newProductIndex = Math.max(
      0,
      (productIndex - 1)
    );

    setProductIndex(newProductIndex);
    history.replace({
      search: `productIndex=${newProductIndex}`,
    });
  };
  const navigate = (direction) => {
    if (direction === NavigationDirection.Up) {
      handleUp();
    } else if (direction === NavigationDirection.Right) {
      handleRight();
    } else if (direction === NavigationDirection.Left) {
      handleLeft();
    }
  };
  const handleKeyNavigate = React.useCallback(
    throttle(navigate, (ANIMATION_TIME * 2), { trailing: false }),
    navigationUpdate,
  )

  useNavigationByKeys({
    isMounted: !isPopupOpened,
    onNavigation: handleKeyNavigate,
  }, navigationUpdate);

  React.useEffect(() => {
    if (isPointerEnabled && !menu.isPopupOpened) {
      updateDirections({
        [NavigationDirection.Left]: (productIndex !== 0),
        [NavigationDirection.Right]: (productIndex !== (products.length - 1)),
      });
    }
  }, [
    productIndex,
    isPointerEnabled,
    menu.isMenuOpened,
    menu.isPopupOpened,
  ]);

  React.useEffect(() => () => flush([
    NavigationDirection.Left,
    NavigationDirection.Right,
  ]), []);

  return (
    <div className={ styles.container }>
      <h1 className={ styles.title }>
        <FormattedMessage id={ MENU_SUBSCRIPTIONS } />
      </h1>
      <ScrollableSliderBlock
        focusedIndex={ productIndex }
        slideWidth={ 376 }
      >
        <div className={ styles.items }>
          {
            products.map((product, index) => {
              const src = getImageRSC({
                mediaItem: product,
                dimension: ImageType.PROMO_BANNER_WIDE,
                width: 664,
                height: 376,
              });
              const isFocused = (!isPopupOpened && productIndex === index);
              const activeSubscriptionWithProduct = getActiveSubscriptionWithProduct(product.id, subscriptions);
              const subscriptionWithProduct = subscriptions
                  ?.find(({ plan: { product: { id } }}) => (product.id === id));
              const productInSubscriptions = getProductInSubscriptions(product, subscriptions);
              const handleClick = () => {
                history.push(`/products/${product.id}`);
              };

              return (
                <div
                  key={ product.id }
                  className={ cn(styles.product, {
                    'focusedNavigationNode': isFocused,
                    [styles.isFocused]: isFocused,
                  }) }
                  onClick={ handleClick }
                >
                  <div
                    className={ styles.image }
                    style={ {
                      backgroundImage: `url(${src})`,
                    } }
                  />
                  <h2 className={ styles.name }>
                    { product.name }
                  </h2>
                  <h3
                    className={ styles.subtitle }
                  >
                    { product.subscription_page_subtitle }
                  </h3>
                  <ul className={ styles.features }>
                    {
                      product.product_features.slice(0, 3).map((productFeature) => (
                        <li key={ productFeature.name }>{productFeature.name}</li>
                      ))
                    }
                  </ul>
                  <BestPrice
                    product={ productInSubscriptions }
                    productSubscription={ activeSubscriptionWithProduct || subscriptionWithProduct }
                  />
                </div>
              );
            })
          }
        </div>
      </ScrollableSliderBlock>
    </div>
  );
};


export default React.memo(ProductsList);
