import * as moment from 'moment';
import * as React from 'react';

import { getCurrentEvent, useTodayProgramEvents } from '~hooks/fetch/useProgramEvents/useProgramEvents';
import ProgramEvent from '~typings/Event';


const useCurrentEvent = (channelID?: string, events?: ProgramEvent[]): ProgramEvent | null | undefined => {
  const { data, isFetched, isError }
    = useTodayProgramEvents(events?.length != 0 ? channelID : undefined);


  const programEvents = events || data;
  // null - indicates that the current event is in the process of searching
  const [currentEvent, setCurrentEvent] = React.useState<ProgramEvent | null | undefined>(
    getCurrentEvent(programEvents || []) || null
  );
  const timeout = React.useRef<any>(null);

  React.useEffect(() => () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  React.useEffect(() => {
    if (isError) {
      setCurrentEvent(undefined);

      return;
    }

    if (isFetched) {
      if (!programEvents || (programEvents || []).length === 0) {
        setCurrentEvent(undefined);
      } else {
        const possiblyNewValueForCurrentEvent = getCurrentEvent(programEvents);

        if (
          possiblyNewValueForCurrentEvent?.id !== currentEvent?.id &&
          possiblyNewValueForCurrentEvent?.channel_id === channelID
        ) {
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
          setCurrentEvent(possiblyNewValueForCurrentEvent);
        } else {
          setCurrentEvent(possiblyNewValueForCurrentEvent || undefined);
        }
      }
    } else {
      setCurrentEvent(null);
    }
  }, [isFetched, isError, channelID]);

  React.useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (programEvents && currentEvent) {
      const now = moment();
      const end = moment(currentEvent.end_at);
      const timeInMSToCalculateNewCurrentEvent = end.add(15, 'seconds').diff(now, 'milliseconds');

      timeout.current = setTimeout(() => {
        setCurrentEvent(getCurrentEvent(programEvents));
      }, timeInMSToCalculateNewCurrentEvent);
    }
  }, [currentEvent?.id]);

  return currentEvent;
};


export default useCurrentEvent;
