// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sliderWrapper--Ao7gD {\n  max-height: 100%;\n}\n\n\n.slider--NsUpG {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n     -moz-box-pack: start;\n          justify-content: flex-start;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n     -moz-box-orient: vertical;\n     -moz-box-direction: normal;\n          flex-direction: column;\n  will-change: transform;\n  -webkit-transition-property: -webkit-transform;\n  transition-property: -webkit-transform;\n  transition-property: transform;\n  transition-property: transform, -webkit-transform;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/screens/EPGPage/components/EPGEventsList/components/EPGEventsListSlider/EPGEventsListSlider.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;;AAGA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,uBAA2B;EAA3B,mCAA2B;KAA3B,oBAA2B;UAA3B,2BAA2B;EAC3B,4BAAsB;EAAtB,6BAAsB;EAAtB,8BAAsB;KAAtB,yBAAsB;KAAtB,0BAAsB;UAAtB,sBAAsB;EACtB,sBAAsB;EACtB,8CAA8B;EAA9B,sCAA8B;EAA9B,8BAA8B;EAA9B,iDAA8B;AAChC","sourcesContent":[".sliderWrapper {\n  max-height: 100%;\n}\n\n\n.slider {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-direction: column;\n  will-change: transform;\n  transition-property: transform;\n}\n"],"sourceRoot":""}]);
// Exports
export var sliderWrapper = "sliderWrapper--Ao7gD";
export var slider = "slider--NsUpG";
export default ___CSS_LOADER_EXPORT___;
