export const goWithReload = (history, location) => {
  if (__PLATFORM__ === 'tizen') {
    history.push(location);
    document.location.reload();
  } else {
    document.location = location;
  }
};


export default goWithReload;
