/* eslint-disable max-len */
import * as React from 'react';


const IconAudio: React.FC = () => (
  <svg
    className="playerIcon"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >

    <path
      d="M15.792,6.255A2.359,2.359,0,0,1,17.542,7a2.513,2.513,0,0,1,.714,1.8V14.33a2.515,2.515,0,0,1-.714,1.8,2.382,2.382,0,0,1-1.75.734,2.348,2.348,0,0,1-1.75-.734,2.485,2.485,0,0,1-.73-1.8V8.8a2.483,2.483,0,0,1,.73-1.8,2.325,2.325,0,0,1,1.75-.749Zm0,12.165a3.763,3.763,0,0,0,2.814-1.214,3.973,3.973,0,0,0,1.167-2.876V8.8A4,4,0,0,0,18.606,5.91a3.905,3.905,0,0,0-5.629,0A4,4,0,0,0,11.81,8.8V14.33a3.973,3.973,0,0,0,1.167,2.876,3.764,3.764,0,0,0,2.815,1.214Zm5.483-5.573v1.858a5.539,5.539,0,0,1-1.6,3.985,5.26,5.26,0,0,1-3.894,1.648,5.2,5.2,0,0,1-3.865-1.648,5.512,5.512,0,0,1-1.618-3.985V12.847h-1.5v1.858A7.044,7.044,0,0,0,10.6,19.514a6.682,6.682,0,0,0,4.433,2.322v3.326H12.015v1.543h7.539V25.162H16.535V21.836a6.684,6.684,0,0,0,4.434-2.322,7.016,7.016,0,0,0,1.823-4.809V12.847Z"
      fill="currentColor"
    />
  </svg>
);


export default React.memo(IconAudio);
