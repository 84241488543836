import logotype from './logotype.svg';
import spinner from './spinner.gif';
import maskedPageBackground from './mask-page-mode.png';
import maskedMediaItemPageBackground from './mask-media-item-page-mode.png';

const logotypeSplashScreen = false;
const backgroundImageBanner = false;
const backgroundImagePage = maskedPageBackground;
const backgroundImageMediaItemPage = maskedMediaItemPageBackground;
const menuGradient = null;

export {
  logotype,
  logotypeSplashScreen,
  spinner,
  backgroundImageBanner,
  backgroundImagePage,
  backgroundImageMediaItemPage,
  menuGradient,
};
