/* eslint-disable max-len */
import { ac_00, gw_00, gw_02, gw_11 } from "customization-project-name/colors";
import * as React from 'react';

type Props = {
  width?: string
  height?: string
  isFocused: boolean
}

const IconChipsSettings = ({ width = '6.111111111111111vh', height = '6.111111111111111vh', isFocused }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={ isFocused ? ac_00 : gw_02 }/>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M19.7083 11.2338C20.4416 10.9512 21.2083 10.8 21.9977 10.8C22.7912 10.8 23.5618 10.9528 24.2986 11.2383C24.5817 11.3479 24.7937 11.5885 24.8671 11.8831L25.4646 14.2823C26.0169 14.5308 26.5376 14.8367 27.0189 15.1922L29.4307 14.5452C29.7239 14.4666 30.0371 14.5354 30.2703 14.7299C30.8771 15.2359 31.3843 15.8358 31.7691 16.5298C32.1517 17.2202 32.3912 17.9641 32.4996 18.7424C32.5414 19.0429 32.4346 19.3449 32.2132 19.5523L30.4219 21.2303C30.4448 21.484 30.4565 21.7408 30.4565 22.0001C30.4565 22.313 30.4394 22.6223 30.4062 22.927L32.1718 24.6915C32.3865 24.9062 32.4835 25.2118 32.4317 25.5109C32.2968 26.2895 32.0309 27.0287 31.6222 27.7089C31.2157 28.3855 30.6912 28.9648 30.0713 29.4479C29.832 29.6343 29.5171 29.6929 29.2268 29.6048L26.8951 28.8976C26.4623 29.2055 25.999 29.4737 25.5107 29.6969L24.9512 32.0957C24.8823 32.3911 24.6741 32.6346 24.393 32.7486C23.6647 33.044 22.9008 33.2085 22.1116 33.2223C21.3182 33.2361 20.545 33.0968 19.8034 32.8243C19.5184 32.7196 19.3022 32.4828 19.2237 32.1895L18.5664 29.7337C18.0479 29.5034 17.5571 29.2226 17.1002 28.8976L14.7686 29.6048C14.4783 29.6928 14.1633 29.6343 13.924 29.4478C13.3041 28.9648 12.7796 28.3855 12.3731 27.7089C11.9644 27.0287 11.6985 26.2895 11.5637 25.5109C11.5119 25.2118 11.6088 24.9061 11.8235 24.6915L13.5891 22.9269C13.5559 22.6223 13.5389 22.313 13.5389 22.0001C13.5389 21.7261 13.5519 21.455 13.5775 21.1872L11.7835 19.4471C11.5658 19.2358 11.4642 18.932 11.5113 18.6323C11.6333 17.8559 11.8857 17.1163 12.2803 16.4328C12.6771 15.7456 13.1947 15.1546 13.8103 14.6592C14.0468 14.4689 14.3611 14.4055 14.653 14.4893L17.0116 15.1663C17.484 14.8212 17.9937 14.5237 18.5337 14.281L19.1388 11.8769C19.2128 11.5827 19.4252 11.3429 19.7083 11.2338ZM21.9977 18.2667C19.9358 18.2667 18.2643 19.9382 18.2643 22C18.2643 24.0619 19.9358 25.7334 21.9977 25.7334C24.0595 25.7334 25.731 24.0619 25.731 22C25.731 19.9382 24.0595 18.2667 21.9977 18.2667Z" 
        fill={ isFocused ? gw_00 : gw_11 }
      />
    </svg>
  </div>
);


export { IconChipsSettings };
