import { useQuery } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import ResponseSingle from '~typings/ResponseSingle';


export enum ErrorCode {
  InvalidPassword = 'invalid_password',
  MissingNewPassword = 'missing_new_password',
  UserAuthenticationRequired = 'user_authentication_required',
}

type Error = Readonly<{
  code: ErrorCode;
  object: 'error',
  title: string;
}>;


export type Data = Readonly<{
  errors?: Error[];
}>;

interface Parameters {
  access_token: string;
  old_password?: string;
  password?: string;
}

const changePassword = async (client, accessToken, currentPassword?: string, newPassword?: string) => {
  const parameters: Parameters = {
    access_token: accessToken,
  };

  if (currentPassword) {
    parameters.old_password = currentPassword;
  }
  if (newPassword) {
    parameters.password = newPassword;
  }

  return await client.put('/v1/user/password', parameters);
};


const useCheckCurrentPassword = (currentPassword?: string) => {
  const client = useClient();
  const { accessToken } = getAuthData();

  return useQuery<ResponseSingle<Data>>({
    queryKey: 'password',
    queryFn: () => changePassword(client, accessToken, currentPassword),
    enabled: !!currentPassword,
    staleTime: 0,
    cacheTime: 0,
  });
};

const useChangePassword = (currentPassword?: string, newPassword?: string) => {
  const client = useClient();
  const { accessToken } = getAuthData();

  return useQuery<ResponseSingle<Data>>({
    queryKey: 'changePassword',
    queryFn: () => changePassword(client, accessToken, currentPassword, newPassword),
    enabled: (!!currentPassword || !!newPassword),
    staleTime: 0,
    cacheTime: 0,
  });
};


export {
  useChangePassword,
  useCheckCurrentPassword,
};
