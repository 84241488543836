import '~app/index.css';

import Conviva from '@convivainc/conviva-js-coresdk';
import settings from 'customization-project-name/settings.json';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import AccountEditFieldWrapper from '~components/AccountEditField/Wrapper';
import { ActorPageWrapper } from '~components/ActorPage/ActorPageWrapper';
import { DevManager } from '~components/DevManager';
import DevPage from '~components/DevManager/DevPage';
import GeoRestrictListener from '~components/GeoResrtictListener';
import GlobalLoader from '~components/GlobalLoader';
import GlobalLoaderWrapper from '~components/GlobalLoader/wrapper';
import { NetworkManager } from '~components/NetworkManager';
import PageManager from '~components/PageManager/PageManager';
import PreLaunch from '~components/PreLaunch/component';
import { ProfilesManager } from '~components/ProfilesManager';
import RestartAppListener from '~components/RestartAppListener';
import Scene from '~components/Scene/component';
import usePageInfo from "~hooks/usePageInfo";
import { CardCollectionViewPage } from '~newapp/screens/CardCollectionViewPage';
import { COMPONENT_TESTING_PAGE_PATH, ComponentTestingPage } from '~newapp/screens/ComponentTestingPage';
import { AccountScreen } from '~screens/AccountScreen';
import AuthScreen from '~screens/AuthScreen/routes';
import CollectionScreen from '~screens/CollectionScreen/routes';
import { DeviceEditPage } from '~screens/Devices';
import ErrorScreen from '~screens/ErrorScreen/routes';
import LaunchScreen from '~screens/LaunchScreen/LaunchScreen';
import MediaItemDetailsScreen from '~screens/MediaItemDetailsScreen/MediaItemDetailsScreen';
import MediaItemScreen from '~screens/MediaItemScreen/MediaItemScreen';
import { PageScreen } from '~screens/PageScreen/routes';
import PersonalScreen from '~screens/PersonalScreen/routes';
import PlayerScreen from '~screens/PlayerScreen/PlayerScreen';
import PolicyScreen from '~screens/PolicyScreen';
import ProductScreen from '~screens/ProductsScreen/ProductsScreen';
import { ProfilesScreen } from '~screens/ProfilesScreen';
import PromocodeActivationScreen from '~screens/PromocodeActivationScreen/routes';
import SearchScreen from '~screens/SearchScreen/SearchScreen';
import TermsScreen from '~screens/TermsScreen';
import ItemObject from '~typings/ItemObject';
import MenuID from '~typings/MenuID';

const App = () => {
  const pageInfo = usePageInfo();

  React.useEffect(() => {
    if (settings.enableConviva) {
      Conviva.Analytics.init(settings.convivaAnalyticsKey, null, {});
    }
  }, []);
  /* eslint-disable max-len */
  return (
    <div id="root">
      <React.Suspense fallback={ <GlobalLoader isVisible /> }>
        <Switch>
          <Route path={ '/dev' } exact>
            <DevPage />
          </Route>
          <PreLaunch>
            <RestartAppListener />
            <LaunchScreen />
            <GeoRestrictListener />
            <ProfilesManager />
            <NetworkManager />
            <PageManager pageInfo={ pageInfo }>
              <GlobalLoaderWrapper>
                <Scene/>
                <Switch>
                  <Route path={ `/${ MenuID.PersonalPage }` }><PersonalScreen /></Route>
                  <Route path={ `/${ MenuID.Account }` }><AccountScreen /></Route>
                  <Route path={ `/promo` }><PromocodeActivationScreen /></Route>
                  <Route path={ '/account-edit/:fieldName' }><AccountEditFieldWrapper /></Route>
                  <Route path={ '/auth' }><AuthScreen /></Route>
                  <Route path={ '/collections' }><CollectionScreen /></Route>
                  <Route path={ `/${ ItemObject.CardCollection }/:cardCollectionID` }><CardCollectionViewPage /></Route>
                  <Route path={ '/error' }><ErrorScreen /></Route>
                  <Route path={ '/media-item' }><MediaItemScreen /></Route>
                  <Route path={ `/page/:pageId/media-item` }><MediaItemScreen /></Route>
                  <Route path={ `/${ MenuID.PersonalPage }/media-item` }><MediaItemScreen /></Route>
                  <Route path={ '/media-item-details' }><MediaItemDetailsScreen /></Route>
                  <Route path={ '/page/:pageId/media-item-details' }><MediaItemDetailsScreen /></Route>
                  <Route path={ '/page' }><PageScreen pageInfo={ pageInfo } /></Route>
                  <Route path={ '/player' }><PlayerScreen /></Route>
                  <Route path={ '/profiles' }><ProfilesScreen /></Route>
                  <Route path={ '/search' }><SearchScreen /></Route>
                  <Route path={ '/products' }><ProductScreen /></Route>
                  <Route path={ '/terms' }><TermsScreen /></Route>
                  <Route path={ '/policy' }><PolicyScreen /></Route>
                  <Route path={ '/people/:id' }><ActorPageWrapper /></Route>
                  <Route path={ '/device-edit/:deviceId' }><DeviceEditPage/></Route>
                  <Route path={ COMPONENT_TESTING_PAGE_PATH }><ComponentTestingPage/></Route>
                </Switch>
              </GlobalLoaderWrapper>
            </PageManager>
          </PreLaunch>
        </Switch>
        <DevManager />
      </React.Suspense>
    </div>
  );
};


App.displayName = 'App';

const MemoizedApp = React.memo(App);

export { MemoizedApp as App };
