import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ScenePlayer from '~components/ScenePlayer';

const PlayerScreen: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={ path }>
        <div>404</div>
      </Route>
      <Route path={ `${path}/:itemObject/:itemId` }>
        <ScenePlayer />
      </Route>
      <Route exact path={ `${path}/:itemObject` }>
        <div>404</div>
      </Route>
    </Switch>
  );
};

export { PlayerScreen };

export default PlayerScreen;
