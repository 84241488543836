import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { PrimaryButton } from './PrimaryButton';

type Props = {
  isFocused: boolean;
  isDisabled?: boolean;
  children?: React.ReactNode;
  focuserClassName?: string;
  buttonClassName?: string;
} & Omit<FocusableBlockProps, 'className' | 'isLastBlock'>;

export const PrimaryButtonFocuser: React.FC<Props> = ({
  isFocused,
  isDisabled,
  children,
  focuserClassName,
  buttonClassName,
  ...focuserProps
}) => {
  return (
    <focuser.FocusableBlock
      { ...focuserProps }
      isFocused={ isFocused && !isDisabled }
      onClick={ isDisabled ? undefined : focuserProps.onClick }
      emitForceFocusOnHover={ isDisabled ? false : focuserProps.emitForceFocusOnHover }
      className={ focuserClassName }
      isLastBlock
    >
      {({ isFocused }) => (
        <PrimaryButton
          className={ buttonClassName }
          isFocused={ isFocused }
          isDisabled={ isDisabled }
          content={ children }
        />
      )}
    </focuser.FocusableBlock>
  );
};
