import * as cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import CastMember from '~typings/CastMember';

import * as styles from './styles.module.css';

type Props = {
  actor: CastMember;
  isFocused: boolean;
  onClick?: (e?: React.SyntheticEvent) => void;
}

const ActorItem: React.FC<Props> = (props: Props): React.ReactElement | null => {
  const history = useHistory();

  const handleClick = () => {
      history.push(`/people/${props.actor.person.id}`)
  }

  return (
    <div className={ styles.actorItem } onClick={ handleClick }>
        <p className={ styles.actorName }>{ props.actor.person?.full_name }</p>
        { props.actor.person?.images.length > 0 
          ? <img 
              className={ cn(styles.actorImg, {
                [styles.focused]: props.isFocused,
                'focusedNavigationNode': props.isFocused,
              }) } 
              src={ props.actor.person?.images[1]?.url_template.replace('_{width}x{height}{crop}','') } 
            />
          : <div className={ styles.noActorImgWrap }>
              <img className={ cn(styles.noActorImg, {
                [styles.focused]: props.isFocused,
                'focusedNavigationNode': props.isFocused,
                })
                } 
              />
                <span className={ cn(styles.firstLetter, {
                  [styles.focusedText]: props.isFocused
                }) }
                >
                  { props.actor.person?.full_name[0] }
                </span>
            </div>
        }
    </div>  
  )
}

export default ActorItem;