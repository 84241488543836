import * as cn from 'classnames';
import * as React from 'react';

import escapeForRegex from '~lib/escapeForRegex';
import { IconMediaItem } from '~ui/Icon';
import OnwardIcon from '~ui/Icon/icons/Onward';

import * as styles from '../styles.module.css';


type Props = {
  value: string;
  query: string;
  isFocused: boolean;
}

const SuggestionItem = (props: Props) => {
  const { value, query, isFocused } = props
  const regexp = new RegExp(escapeForRegex(query), 'i');
  const result = value.replace(regexp,
    `<span class=${ styles.match }>$&</span>`,
  );

  // const handleOnClick = () => {
  //   setQuery(value.toLowerCase());
  //   URLSearchManager.set({ query: encodeURI(value.toLowerCase()) }, history);
  // }

  return (
    <div
      className={ cn(styles.name) }
    >
      <span dangerouslySetInnerHTML={ { __html: result } } />
      <div style={ { 'visibility': isFocused ? 'visible' : 'hidden' } }>
        <IconMediaItem Icon={ OnwardIcon } classNames={ { wrapper: styles.icon } } />
      </div>
    </div>
  );
};


export const MemoizedSuggestionItem = React.memo(SuggestionItem)
