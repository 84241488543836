import { FocuserClickHandler, FocuserKeyHandler } from '@focuser';
import { FocuserClickEventDetail } from '@focuser/events';
import { useEffect, useMemo } from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { useHistory } from 'react-router-dom';

import usePlayerStateActions from '~components/Provider/Player/actions';
import { InfinityProgramEvents } from '~hooks/fetch/useProgramEvents/useProgramEvents';
import { isCatchupAvailable, toPivotDate } from '~lib/eventsHelper';
import { getClickedElWithDataIdx } from '~newapp/utils/globals/DataAttributeIdx';
import { usePlayerStore } from '~stores/Player';
import Blackout from '~typings/Blackout';
import Channel from '~typings/Channel';
import ProgramEvent from '~typings/Event';
import ItemObject from '~typings/ItemObject';

import { EventsListFocusedState } from '../../EPGPageCommon.types';

type UseEPGEventsListNavigationProps = {
  pages?: InfinityProgramEvents[];
  currentEvent?: ProgramEvent;
  focusedState: EventsListFocusedState | null;
  setFocusedState: (state: EventsListFocusedState) => void;
};

export const useEPGEventsListNavigation = ({
  pages,
  currentEvent,
  focusedState,
  setFocusedState,
}: UseEPGEventsListNavigationProps) => {

  useEffect(() => {
    if (focusedState) {
      return;
    }

    const nextFocusedState = getInitialFocus(pages, currentEvent) || getAnyInitialFocus(pages);
    // Выставляем начальный фокус, если он не установлен
    if(nextFocusedState){
      setFocusedState(nextFocusedState);
    }
  }, [pages, currentEvent]);

  const nextStateOnDown = useMemo(() => {
    if (!focusedState || !pages) {
      return null;
    }
    const { focusedIdxInPage, focusedPage } = focusedState;

    if (!focusedPage) {
      return null;
    }

    const hasNextEventInCurrentDate = focusedIdxInPage < focusedPage.programEvents.length - 1;

    if (hasNextEventInCurrentDate) {
      return {
        focusedPage,
        focusedIdxInPage: focusedIdxInPage + 1,
        focusedEvent: focusedPage.programEvents[focusedIdxInPage + 1],
      };
    }

    const currentPageIdx = pages.findIndex((page) => page.pivotDate === focusedPage.pivotDate);

    if (currentPageIdx === -1) {
      return null;
    }

    const nextPage = pages[currentPageIdx + 1];
    if (!nextPage || nextPage.programEvents.length === 0) {
      return null;
    }

    return {
      focusedPage: nextPage,
      focusedIdxInPage: 0,
      focusedEvent: nextPage.programEvents[0],
    };
  }, [focusedState, pages]);

  const nextStateOnUp = useMemo(() => {
    if (!focusedState || !pages) {
      return null;
    }
    const { focusedIdxInPage, focusedPage } = focusedState;

    if (!focusedPage) {
      return null;
    }

    const hasPrevEventInCurrentDate = focusedIdxInPage > 0;

    if (hasPrevEventInCurrentDate) {
      return {
        focusedPage,
        focusedIdxInPage: focusedIdxInPage - 1,
        focusedEvent: focusedPage.programEvents[focusedIdxInPage - 1],
      };
    }
    const currentPageIdx = pages.findIndex((page) => page.pivotDate === focusedPage.pivotDate);
    if (currentPageIdx === -1) {
      return null;
    }

    const prevPage = pages[currentPageIdx - 1];
    if (!prevPage || prevPage.programEvents.length === 0) {
      return null;
    }

    return {
      focusedPage: prevPage,
      focusedIdxInPage: prevPage.programEvents.length - 1,
      focusedEvent: prevPage.programEvents[prevPage.programEvents.length - 1],
    };
  }, [focusedState, pages]);

  const isPointerDownAvailable = !!nextStateOnDown;
  const isPointerUpAvailable = !!nextStateOnUp;

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (!nextStateOnDown) {
      return;
    }
    e.stop();
    setFocusedState(nextStateOnDown);
  };

  const onKeyUp: FocuserKeyHandler = (e) => {
    if (!nextStateOnUp) {
      return;
    }
    e.stop();
    setFocusedState(nextStateOnUp);
  };

  return {
    focusedState,
    isPointerDownAvailable,
    isPointerUpAvailable,
    onKeyDown,
    onKeyUp,
  };
};

const MINIMAL_PREFETCHED_EVENTS_BOTH_DIRECTIONS = 15;

type UseEPGEventsListPaginationProps = {
  query: UseInfiniteQueryResult<InfinityProgramEvents, unknown>;
  focusedState: EventsListFocusedState | null;
  handleEventsIsReady: () => void;
  isEventsReady: boolean;
};

export const useEPGEventsListPagination = ({
  query,
  focusedState,
  handleEventsIsReady,
  isEventsReady,
}: UseEPGEventsListPaginationProps) => {
  const totalProgramEvents = useMemo(() => {
    if (!query.data?.pages) {
      return 0;
    }
    return query.data.pages.reduce((acc, page) => {
      return acc + page.programEvents.length;
    }, 0);
  }, [query.data?.pages]);

  const currentFocusedIdxInEvents = useMemo(() => {
    if (!focusedState || !query.data?.pages) {
      return null;
    }

    const idxOfCurrentFocusedPage = query.data.pages.findIndex(
      (page) => page.pivotDate === focusedState.focusedPage.pivotDate,
    );

    if (idxOfCurrentFocusedPage === -1) {
      return null;
    }

    const elementsCountBefore = query.data.pages
      .slice(0, idxOfCurrentFocusedPage)
      .reduce((acc, page) => {
        return acc + page.programEvents.length;
      }, 0);

    return elementsCountBefore + focusedState.focusedIdxInPage;
  }, [focusedState, query.data?.pages]);

  useEffect(() => {
    if (query.isFetching) {
      return;
    }

    if (currentFocusedIdxInEvents === null) {
      return;
    }

    const minimalIdx = 0;
    const diffPast = currentFocusedIdxInEvents - minimalIdx;
    if (diffPast <= MINIMAL_PREFETCHED_EVENTS_BOTH_DIRECTIONS && query.hasPreviousPage) {
      query.fetchPreviousPage();
      return;
    }

    const maximalIdx = totalProgramEvents;
    const diffFuture = maximalIdx - currentFocusedIdxInEvents;
    if (diffFuture <= MINIMAL_PREFETCHED_EVENTS_BOTH_DIRECTIONS && query.hasNextPage) {
      query.fetchNextPage();
      return;
    }

    /**
     * Если мы загрузили достаточно событий, или их нет на бэке, то значит
     * наш список событий готов к показу
     */
    if (
      !isEventsReady &&
      !query.isLoading &&
      query.data?.pages.some((p) => p.programEvents.length > 0)
    ) {
      handleEventsIsReady();
    }
  }, [query.isLoading, query.isFetching, currentFocusedIdxInEvents]);
};

/**
 * Функция возвращает начальный фокус по текущему событию (currentEvent)
 */
function getInitialFocus(
  pages: InfinityProgramEvents[] | undefined,
  currentEvent: ProgramEvent | undefined,
): EventsListFocusedState | null {
  if (!pages) {
    return null;
  }
  const todayPivotDate = toPivotDate(new Date());
  const todayPage = pages.find((page) => toPivotDate(page.pivotRawDate) === todayPivotDate);

  if (!todayPage) {
    return null;
  }

  const currentEventIdx = currentEvent
    ? todayPage.programEvents.findIndex((e) => e.id === currentEvent.id)
    : 0;
  const initialEventIdx = Math.max(currentEventIdx, 0);

  return {
    focusedIdxInPage: initialEventIdx,
    focusedPage: todayPage,
    focusedEvent: todayPage.programEvents[initialEventIdx],
  };
}

/**
 * Функция возвращает начальный фокус, если текущего события нет
 */
function getAnyInitialFocus(pages: InfinityProgramEvents[] | undefined): EventsListFocusedState | null {
  if (!pages) {
    return null;
  }

  const anyPage = pages.find(p=>p.programEvents.length > 0);

  if (!anyPage) {
    return null;
  }

  return {
    focusedIdxInPage: 0,
    focusedPage: anyPage,
    focusedEvent: anyPage.programEvents[0],
  };
}

export type ProgramEventDateTitle = {
  pivotDate: string;
  pivotRawDate: Date;
  object: 'programEventDateTitle';
};

export type ProgramEventWithPage = {
  event: ProgramEvent;
  page: InfinityProgramEvents;
  object: 'programEventWithPage';
}


export type SliderRenderArrItem = ProgramEventWithPage | ProgramEventDateTitle;

type UseEPGEventsSliderProps = {
  pages?: InfinityProgramEvents[];
  focusedState: EventsListFocusedState | null;
  renderChild: (child: SliderRenderArrItem, idx: number) => React.ReactNode;
};

/**
 * Хук возвращает данные для слайдера
 * Некоторые из возвращаемых данных также используются для onClick обработчика
 */
export const useEPGEventsSlider = ({
  pages,
  focusedState,
  renderChild,
}: UseEPGEventsSliderProps) => {
  /**
   * Данные для слайдера (тут события и даты этих событий)
   */
  const renderArrForSlider = useMemo(() => {
    if (!pages) {
      return [];
    }

    const result: SliderRenderArrItem[] = [];

    // флаг говорит о том, пропущена ли первая дата.
    // Первую дату нужно пропускать, потому что она не должна быть в слайдере,
    // а должна находиться выше слайдера, в EPGStickyWeekDay
    let isFirstTitleSkipped = false;

    pages.forEach((page) => {
      if (page.programEvents.length === 0) {
        return;
      }
      if (isFirstTitleSkipped) {
        result.push({
          pivotDate: page.pivotDate,
          pivotRawDate: page.pivotRawDate,
          object: 'programEventDateTitle',
        });
      } else {
        isFirstTitleSkipped = true;
      }
      page.programEvents.forEach((event) => {
        result.push({
          event,
          page,
          object: 'programEventWithPage',
        });
      });
    });

    return result;
  }, [pages]);

  const renderSliderChild = (idx: number) => {
    const child = renderArrForSlider[idx];
    if (!child) {
      return null;
    }
    return renderChild(child, idx);
  };

  const focusedIdxForSlider = useMemo(() => {
    if (!focusedState) {
      return null;
    }
    const event = focusedState.focusedPage.programEvents[focusedState.focusedIdxInPage];
    if (!event) {
      return null;
    }
    const idx = renderArrForSlider.findIndex(
      (e) =>
        e.object === 'programEventWithPage'&& e.event.id === event.id && e.event.start_at === event.start_at,
    );

    if (idx === -1) {
      return null;
    }
    return idx;
  }, [focusedState, renderArrForSlider]);

  return {
    renderSliderChild,
    focusedIdxForSlider,
    renderArrForSlider,
    totalCount: renderArrForSlider.length,
  };
};

type UseRenderStateProps = {
  query: UseInfiniteQueryResult<InfinityProgramEvents, unknown>;
  isEventsReady: boolean;
  focusedIdxForSlider: number | null;
};

export const useRenderState = ({
  query,
  isEventsReady,
  focusedIdxForSlider,
}: UseRenderStateProps) => {
  return useMemo(() => {
    const isNoData =
      !query.isLoading && !query.data?.pages.some((page) => page.programEvents.length);
    if (isNoData) {
      return 'no-data';
    }

    const isNotReady = !isEventsReady || typeof focusedIdxForSlider !== 'number';

    if (isNotReady) {
      return 'not-ready';
    }

    return 'ready';
  }, [isEventsReady, focusedIdxForSlider, query.isLoading]);
};

type UseEPGEventsListOnClickProps = {
  focusedIdxForSlider: number | null;
  renderArrForSlider: SliderRenderArrItem[];
  beforeLeave: (params: { event: ProgramEvent; pagePivotDate: string }) => void;
  blackouts?: Blackout[];
  channel: Channel;
  currentEvent: ProgramEvent | undefined;
};

/**
 * Хук возвращает обработчик onClick, который нужно повесить на фокусер
 */
export const useEPGEventsListOnClick = (props: UseEPGEventsListOnClickProps) => {
  const history = useHistory();
  const { setPlayList } = usePlayerStateActions();

  const handleClickOnEvent = (item: ProgramEventWithPage, e: FocuserClickEventDetail) => {
    e.stop();
    e.stopNativeEvent();
    const programEvent = item.event;

    const playerStore = usePlayerStore.getState();

    const isCatchup = isCatchupAvailable(programEvent, props.channel, props.blackouts);

    if (isCatchup) {
      // Начало логики из старого кода, для костыльного плеера:
      const currentDate = new Date();
      const archivedProgramsIds = props.renderArrForSlider
        .filter(
          (e): e is ProgramEventWithPage =>
            e.object === 'programEventWithPage' && new Date(e.event.end_at) < currentDate,
        )
        .map((e) => e.event.id);
      playerStore.setIsLive(false);
      playerStore.setCurrentProgram([programEvent]);
      setPlayList(archivedProgramsIds);
      // Конец логики для костыльного плеера
      props.beforeLeave({ event: programEvent, pagePivotDate: item.page.pivotDate });
      history.push(`/media-item/${ItemObject.ProgramEvent}/${programEvent.id}/player?player=true`);
      return;
    }

    if (props.currentEvent && props.currentEvent.id === programEvent.id) {
      playerStore.setIsLive(true); // строка с логикой из старого кода
      props.beforeLeave({ event: programEvent, pagePivotDate: item.page.pivotDate });
      history.push(`/media-item/${ItemObject.Channel}/${props.channel?.id}?player=true`);
      return;
    }
  };

  const handleClickByRemote: FocuserClickHandler = (e) => {
    if (typeof props.focusedIdxForSlider !== 'number') {
      return;
    }
    const item = props.renderArrForSlider[props.focusedIdxForSlider];
    if (!item || item.object !== 'programEventWithPage') {
      return;
    }
    handleClickOnEvent(item, e);
  };

  const handleClickByMouse: FocuserClickHandler = (e) => {
    const result = getClickedElWithDataIdx(e.nativeEvent);
    if (!result) {
      return;
    }
    const item = props.renderArrForSlider[result.idx];
    if (!item || item.object !== 'programEventWithPage') {
      return;
    }
    handleClickOnEvent(item, e);
  };

  return ((evt) => {
    if (evt.isPhysicalMouseClick) {
      handleClickByMouse(evt);
      return;
    }
    handleClickByRemote(evt);
  }) as FocuserClickHandler;
};
