import { UsernameAvailabilityResult } from '~hooks/fetch/useUsernameAvailability';
import { 
  DATA_IS_INCORRECT,
  EMAIL_ALREADY_IN_USE, 
  ERROR_UNKNOWN_EMAIL,
  ERROR_UNKNOWN_PHONE_NUMBER_OR_EMAIL,
  USERNAME_ALREADY_IN_USE
} from '~localization';

import { AuthType } from '../types';

const userNameErrorProcessing = (data: UsernameAvailabilityResult, authType: AuthType): string => {
  const isSignUp = authType === AuthType.SignUp;
  
  if (data?.data.type === 'email') {
    return isSignUp ? EMAIL_ALREADY_IN_USE : ERROR_UNKNOWN_EMAIL;
  } else if (data?.data.type === 'msisdn') {
    return isSignUp ? USERNAME_ALREADY_IN_USE : ERROR_UNKNOWN_PHONE_NUMBER_OR_EMAIL;
  } else if (data?.data.type === 'unknown') {
      if(data.data.restricted) {
        return data.data.message;
      } else {
        ERROR_UNKNOWN_PHONE_NUMBER_OR_EMAIL;
      }
    // return isSignUp || isSignIn ? data.data.message : ERROR_UNKNOWN_PHONE_NUMBER_OR_EMAIL;
  }

  return DATA_IS_INCORRECT;
};

export {
  userNameErrorProcessing,
};
