import { MediaItemType } from '~typings/DataItem';
import ResponseMany from '~typings/ResponseMany';
import SmartTVConfig from '~typings/SmartTVConfig';

import { BannerItem } from './component';

export type ItemType = ResponseMany<MediaItemType[] | BannerItem[]> | undefined;

export const checkDeepLink = ({
  deeplink,
  sliderSize,
  itemsTotalLength,
  itemsCurrentLength
}: {
  sliderSize?: SmartTVConfig['sliderSize'];
  itemsTotalLength?: number;
  itemsCurrentLength?: number;
  deeplink?: string;
}) => {
  if (!deeplink || !sliderSize || !itemsTotalLength || !itemsCurrentLength) {
    return undefined;
  }

  const [sliderNormalCapacity, sliderExtraCapacity] = sliderSize;

  // Проверяем, загрузили все элементы, или какие-то из них остались на бэкенде
  const isAllItemsDownloaded = itemsTotalLength === itemsCurrentLength;

  // Если есть элементы, которые остались на сервере, в этом случае deeplink показать нужно всегда
  if (!isAllItemsDownloaded) {
    return deeplink;
  }

  // Скачаны все элементы, если они не вмещаются, показываем deeplink
  if (itemsTotalLength > sliderNormalCapacity + sliderExtraCapacity) {
    return deeplink;
  }

  return;
};

export const calculateItemsCountToRender = ({
  isDeeplinkExists,
  sliderSize,
  itemsTotalLength,
  itemsCurrentLength
}: {
  isDeeplinkExists: boolean;
  sliderSize?: SmartTVConfig['sliderSize'];
  itemsTotalLength?: number;
  itemsCurrentLength?: number;
}) => {

  if (!isDeeplinkExists || !sliderSize) {
    // Если нет deeplink-а, значит показываем кол-во элементов которое есть
    // они все умещаются в слайдер
    return itemsCurrentLength || 0;
  }

  const [sliderNormalCapacity, sliderExtraCapacity] = sliderSize;

  // Проверяем, загрузили все элементы, или какие-то из них остались на бэкенде
  const isAllItemsDownloaded = itemsTotalLength === itemsCurrentLength;

  if(isAllItemsDownloaded){
    // Если все элементы загружены, значит они просто не помещаются в слайдер, показываем normalCapacity,
    // остальное будет видно при раскрытии
    return sliderNormalCapacity + 1;
  }

  // Тут случай когда загружены не все элементы
  // Значит часть осталась на сервере, и нам нужно проверить, помещается ли весь загруженный кусок в слайдер
  const downloadedItemsLength = itemsCurrentLength || 0;
  if (downloadedItemsLength > sliderNormalCapacity + sliderExtraCapacity
  ) {
    return sliderNormalCapacity + 1;
  }

  return downloadedItemsLength + 1;
};
