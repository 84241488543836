/* eslint-disable max-len */
import { ac_00, gw_00, gw_02, gw_11 } from "customization-project-name/colors";
import * as React from 'react';

type Props = {
  width?: string
  height?: string
  isFocused: boolean
}

const IconChipsAudio = ({ width = '6.111111111111111vh', height = '6.111111111111111vh', isFocused }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={ isFocused ? ac_00 : gw_02 } />
      <path
        d="M22.0049 10.8C24.3462 10.8 26.2498 12.669 26.2498 14.9679V20.5936C26.2498 22.8924 24.3462 24.7614 22.0049 24.7614C19.6635 24.7614 17.76 22.8924 17.76 20.5936V14.9679C17.76 12.669 19.6635 10.8 22.0049 10.8ZM16.1515 19.089V20.9767C16.1515 24.1353 18.7784 26.7145 21.9954 26.7145C25.2124 26.7145 27.8583 24.1353 27.8583 20.9767V19.089H29.4668V20.9767C29.4668 24.7334 26.5448 27.8546 22.8044 28.2471V31.6207H26.0214V33.2H17.9789V31.6207H21.1863V28.2471C17.4649 27.8546 14.5334 24.7334 14.5334 20.9767V19.089H16.1419H16.1515Z"
        fill={ isFocused ? gw_00 : gw_11 } />
    </svg>
  </div>
);


export { IconChipsAudio };
