import cn from "classnames";
import React, { Dispatch, SetStateAction } from "react";

import { GetIcons } from "~components/SearchPage/keyBoard/icons";
import { KeyBoardKey, KeyBoardLayout, KeyBoardType,KeyIcon, KeyType } from "~components/SearchPage/keyBoard/keyboardLayouts/type0";

import * as css from './search2.module.css'


interface Props {
  buttonIsFocused: boolean
  button: KeyBoardKey
  keyBoardType: KeyBoardType
  query: string
  setQuery: (newQuery: string) => void
  keyBoard: string
  setKeyBoard: Dispatch<SetStateAction<string>>
  handleSearchButtonClick?: () => void
}


function Button({
    buttonIsFocused,
    button,
    keyBoardType,
    query,
    setQuery,
    keyBoard,
    setKeyBoard,
    handleSearchButtonClick,
  }: Props) {

  const disabledSearchButton = button.id === KeyIcon.Search && Boolean(query);

  function handleClick() {
    if (button.type === KeyType.Char) {
      setQuery(query + button.id.toLowerCase())
    }
    else if (button.type === KeyType.Icon) {
      switch (button.id) {
        case KeyIcon.BackSpace:
          return setQuery(query.slice(0, -1))
        case KeyIcon.Space:
          return setQuery(query + ' ')
        case KeyIcon.Search:
          if (handleSearchButtonClick) {
            handleSearchButtonClick()
          }
          return
        case KeyIcon.SwitchLayout:
          setKeyBoard(keyBoardType + button.switchTo)
          return
        default:
          return
      }
    }
  }

  // все клавиши типа 'char' а так же пробел типа 'icon' в раскладке RU - квадратные
  const buttonType = button.type === KeyType.Char
    || (button.type === KeyType.Icon
      && button.id === KeyIcon.Space
      && keyBoard === (KeyBoardType.type0 + KeyBoardLayout.Ru))

  switch (keyBoardType) {
    case KeyBoardType.type0:
      return (
        <div 
          onClick={ handleClick } 
          className={ 
            cn(disabledSearchButton ? css.searchButton : css.type0Basic, buttonType ? css.button0 : css.button1,
            { [css.type0Active]: buttonIsFocused, 'focusedNavigationNode': buttonIsFocused }) 
          }
        >
          {
            button.type === KeyType.Char
              ? button.id
              : <GetIcons 
                  id={ button.id } 
                  buttonIsFocused={ buttonIsFocused } 
                  keyBoard={ keyBoard }
                  switchTo={ button.switchTo } 
                  inputQueryText={ query }
                />
          }
        </div>
      )
    default:
      return null
  }
}

export const MemoizedButton = React.memo(Button)
