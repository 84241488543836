import { SECOND } from '~newapp/utils/globals/time';
import ProgramEvent from '~typings/Event';

export const eventItemState = (item: ProgramEvent) => {
  const end = new Date(item.end_at).getTime();

  const now = new Date().getTime();

  if (now <= end) {
    return 'actual' as const;
  }

  return 'ended' as const;
};

export const getEPGItemProgress = (item: ProgramEvent) => {
  const start = new Date(item.start_at).getTime();
  const end = new Date(item.end_at).getTime();

  const now = new Date().getTime();

  if (now < start || now > end) {
    return;
  }

  const total = end - start;
  const curr = now - start;

  return (curr / total) * 100;
};


export const DEFAULT_INTERVAL_FOR_PROGRESS_LINE = 10 * SECOND;
