import * as React from 'react';

import { MediaItemType } from '~typings/DataItem';


type ItemForRender = Readonly<{
  key: string;
  item: MediaItemType | undefined;
  index: number;
}>;


function useDebounceWithIntermediateValueNull<T>(value: T, delay: number): T | null {
  const [debouncedValue, setDebouncedValue] = React.useState<T | null>(value);

  React.useEffect(() => {
    setDebouncedValue(null);
    
    let handler: any;

    if (value !== null && value !== undefined) {
      handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
    }

    return () => {
      if (handler) clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}


export {
  ItemForRender,
  useDebounceWithIntermediateValueNull,
};
