import { map, toLower, upperFirst } from "lodash";
import { InjectedIntl } from "react-intl";

import { DDListItem } from "~components/InputWithPopup/helpers";
import {
  PROFILE_NAME,
  PROFILES_ALLOWED_CONTENT_ADULT,
  PROFILES_ALLOWED_CONTENT_FIELD_NAME,
  PROFILES_ALLOWED_CONTENT_LITTLE_KIDS,
  PROFILES_ALLOWED_CONTENT_OLDER_KIDS,
  PROFILES_ALLOWED_CONTENT_TEENS
} from "~localization";
import AllowedContent from "~typings/AllowedContent";
import ProfileFormData from "~typings/ProfileFormData";

enum FieldsName {
  Name = 'name',
  Gender = 'gender',
  Kids = 'kids',
  AllowedContent = 'allowed_content'
}

type Field = {
  placeholder: string;
  items?: AgeItem[];
}

type AgeItem = {
  value: AllowedContent;
  text: string;
}

const fieldsConfig: Array<{
  fieldName: string,
  items?: DDListItem[],
  placeholder: string
}> = [
  {
    fieldName: FieldsName.Name,
    placeholder: PROFILE_NAME
  },

  {
    fieldName: FieldsName.AllowedContent,
    placeholder: PROFILES_ALLOWED_CONTENT_FIELD_NAME,
    items: [
      { value: AllowedContent.LittleKids, text: PROFILES_ALLOWED_CONTENT_LITTLE_KIDS },
      { value: AllowedContent.OlderKids, text: PROFILES_ALLOWED_CONTENT_OLDER_KIDS },
      { value: AllowedContent.Teens, text: PROFILES_ALLOWED_CONTENT_TEENS },
      { value: AllowedContent.Adult, text: PROFILES_ALLOWED_CONTENT_ADULT },
    ]
  },
];

const fieldWithProfileName = {
  placeholder: PROFILE_NAME
}

const ageSelectionField = {
  placeholder: PROFILES_ALLOWED_CONTENT_FIELD_NAME,
  items: [
    { value: AllowedContent.LittleKids, text: PROFILES_ALLOWED_CONTENT_LITTLE_KIDS },
    { value: AllowedContent.OlderKids, text: PROFILES_ALLOWED_CONTENT_OLDER_KIDS },
    { value: AllowedContent.Teens, text: PROFILES_ALLOWED_CONTENT_TEENS },
    { value: AllowedContent.Adult, text: PROFILES_ALLOWED_CONTENT_ADULT },
  ]
}

const defaultProfile: ProfileFormData = {
  [FieldsName.Kids]: false,
  [FieldsName.AllowedContent]: AllowedContent.Adult
} as ProfileFormData;

//утилка для того, чтобы текст обмазать intl, для корректного отображения всех переводов
const translatedConfig = (field: Field, intl: InjectedIntl) => {
  return {
    ...field,
    placeholder: intl.formatMessage({ id: field.placeholder }),
    items: field.items ? map(field.items, ({ value, text }) => ({
      value,
      text: upperFirst(toLower(intl.formatMessage({ id: text })))
    })) : undefined
  }
};


export {
  ageSelectionField,
  defaultProfile,
  fieldsConfig,
  FieldsName,
  fieldWithProfileName,
  translatedConfig,
}
