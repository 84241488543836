import * as React from 'react';

import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { useClient } from '~global';
import { useConfig, useNetwork } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import {
  AuthActionType,
  fetchAccountInfo,
  hydrateAuthData,
  useAccount,
  useAuthMutation
} from '~hooks/fetch/useAccount';
import useIsErrorPage from '~hooks/useIsErrorPage';
import usePrevious from '~hooks/usePrevious';
import { setIPTVCredentials } from '~lib/ConfigUtils';
import SmartTVConfig from '~typings/SmartTVConfig';


type Props = Readonly<{
  onPreLaunchChanged: (isPreLaunchDone: boolean) => void;
}>;


// По-сути, компонента необходима для получения данных о возможности
// IPTV авторизации и если это возможно, то авторизации пользователя
// по заголовкам в IPTV сети, установки в client корректных значений
// client_id и client_secret
const PreLaunchIPTVAuth: React.FC<Props> = (props: Props) => {
  const [isPreLaunchDone, setIsPreLaunchDone] = React.useState<boolean>(false);
  const [isIPTVAuthStarted, setIsIPTVAuthStarted] = React.useState<boolean>(false);
  const { isLoading: IPTVAuthIsLoading, mutate: signIn } = useAuthMutation();
  const prevIPTVAuthIsLoading = usePrevious(IPTVAuthIsLoading);
  const isErrorPage = useIsErrorPage();
  const applicationAction = useAppAction();
  const { isIPTVOn, enableAutomaticLogIn, isAuthorized } = useApp();
  const { data: accountInfo } = useAccount();
  const client = useClient();
  const { accessToken } = getAuthData();
  const { smartTV: smartTVConfig } = useConfig();
  const networkInfo = useNetwork();
  const IPTVAuth = async (config: SmartTVConfig): Promise<boolean> => {
    setIsIPTVAuthStarted(true);
    setIPTVCredentials(false, config, client);

    if (config?.IPTVAPI) {
      await signIn({
        type: AuthActionType.AuthByHeader,
        payload: { iptvAuthUrl: config.IPTVAPI }
      });
    }

    return true;
  }
  const IPTVAuthAfter = async (): Promise<boolean> => {
    let isMergedAccount = false;

    if (smartTVConfig?.IPTVAPI) {
      const iptvAccount = await fetchAccountInfo(client);
      isMergedAccount = !!iptvAccount?.username;
    }

    setIPTVCredentials(isMergedAccount, smartTVConfig as SmartTVConfig, client);

    setIsPreLaunchDone(true);
    setIsIPTVAuthStarted(false);
    props.onPreLaunchChanged(true);

    return true;
  }

  // Reset the URL after app reload (dev fix, req for redirection to /profile)
  React.useEffect(hydrateAuthData, []);

  React.useEffect(() => {
    if (
      prevIPTVAuthIsLoading && !IPTVAuthIsLoading
      && isIPTVOn && enableAutomaticLogIn
    ) {
      IPTVAuthAfter();
    }
  }, [
    isIPTVOn,
    enableAutomaticLogIn,
    IPTVAuthIsLoading,
    prevIPTVAuthIsLoading,
  ]);

  React.useEffect(() => {
    if (!accessToken) {
      applicationAction({
        type: ActionType.SetIsAuthorized,
        payload: { isAuthorized: false },
      });
    }
  }, [accessToken]);

  React.useEffect(() => {
      if (smartTVConfig) {
        applicationAction({
          type: ActionType.SetNetworkInfo,
          payload: {
            isIPTVOn: (isIPTVOn === null) ?
              !!smartTVConfig?.IPTVAPI && networkInfo.iptv_supported
              :
              isIPTVOn,
            regionUID: networkInfo.region_uid,
          },
        });
      }

  }, [smartTVConfig]);

  React.useEffect(() => {
    if (isAuthorized !== null && !isPreLaunchDone && isIPTVOn !== null) {
      if (isIPTVOn && enableAutomaticLogIn) {
        if (smartTVConfig && !isIPTVAuthStarted) {
          IPTVAuth(smartTVConfig);
        }
      } else {
        setIsPreLaunchDone(true);
        props.onPreLaunchChanged(true);
      }
    }
  }, [isAuthorized, accountInfo, smartTVConfig, enableAutomaticLogIn, isIPTVOn, isPreLaunchDone, isIPTVAuthStarted]);

  React.useEffect(() => {
    if (isErrorPage && !isPreLaunchDone) {
      setIsPreLaunchDone(true);
      props.onPreLaunchChanged(true);
    }
  }, [isErrorPage]);

  return null;
};


export default PreLaunchIPTVAuth;
