// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup--XJWgS {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  background-color: rgba(23, 23, 27, 1);\n  z-index: 9999;\n}\n\n.semiTransporent--xPI8u {\n  background-color: rgba(23, 23, 27, 0.9);\n}\n", "",{"version":3,"sources":["webpack://./src/app/ui/Popup/styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,qCAAwB;EACxB,aAAa;AACf;;AAEA;EACE,uCAAuB;AACzB","sourcesContent":[".popup {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: $gw_00;\n  z-index: 9999;\n}\n\n.semiTransporent {\n  background-color: $b_90;\n}\n"],"sourceRoot":""}]);
// Exports
export var popup = "popup--XJWgS";
export var semiTransporent = "semiTransporent--xPI8u";
export default ___CSS_LOADER_EXPORT___;
