import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useApp } from '~components/Provider/App';
import { usePagesStore } from '~hooks/fetch/usePages/pagesStore';
import { usePages } from '~hooks/fetch/usePages/usePages';
import goToDefaultPage from '~lib/goToDefaultPage';
import removeMainLoader from '~lib/removeMainLoader';

const LaunchScreen: React.FC = () => {
  const { isLaunchingDone } = useApp();
  const history = useHistory();

  const pages = usePages();
  const setPages = usePagesStore(state => state.setPages);


  useEffect(() => {
    setPages(pages);
  }, [pages]);

  useEffect(() => {
    if (pages.data && history.location.pathname === '/') {
      goToDefaultPage(pages.data, history);
    }
  }, [history.location.pathname, pages.data]);

  useEffect(() => {
    if (isLaunchingDone !== false) {
      removeMainLoader();
    }
  }, [isLaunchingDone]);

  return null;
};

export { LaunchScreen };

export default LaunchScreen;
