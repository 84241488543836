import { FocuserKeyHandler } from '@focuser';
import React from 'react';

import { useProfiles } from "~hooks/fetch/useProfiles";
import Profile from "~typings/Profile";


export const useProfileNavigation = (profilesItems: Profile[], initialIndex?: number) => {
  const [block, setBlock] = React.useState<'profileName' | 'editButton'>(
    typeof initialIndex === 'number' ? 'editButton' : 'profileName'
  );
  const [line, setLine] = React.useState<number>(initialIndex || 0);

  const handleUp: FocuserKeyHandler = (evt) => {
    if(line > 0) {
      setLine(line - 1);
      evt.stop();
    }
    return 
  }

  const handleDown: FocuserKeyHandler = (evt) => {
    if(line < (profilesItems.length - 1)) {
      setLine(line + 1);
      evt.stop();
    }
    
    return 
  }

  const handleRight: FocuserKeyHandler = (evt) => {
    if(block === 'profileName') {
      setBlock('editButton');
      evt.stop();
    }
    return;
  }

  const handleLeft: FocuserKeyHandler = (evt) => {
    if(block === 'editButton') {
      setBlock('profileName');
      evt.stop();
    }
    return;
  }

  const { setFocusOnProfileName, setFocusOnEditButton } = React.useMemo(
    () => ({
      setFocusOnProfileName: () => setBlock('profileName'),
      setFocusOnEditButton: () => setBlock('editButton'),
    }),
    [],
  );

  return {
    block,
    line,
    setColumn: setLine,
    handleUp,
    handleDown,
    handleRight,
    handleLeft,
    setFocusOnProfileName, 
    setFocusOnEditButton,
  }

}

export const useProfilesState = (activeProfileId: string | null) => {
  const profilesItems: Profile[] = useProfiles().data ?? [];
  const currentProfile = profilesItems.find((p) => p.id === activeProfileId) ?? {};
  const isKids = (currentProfile as Profile).kids === true;
  const isAccountCreator = (currentProfile as Profile).account_creator === true;
  const haveEditingRights = isAccountCreator || !isKids;

  return {
    profilesItems,
    isKids,
    haveEditingRights,
  }
}