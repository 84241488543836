import { defaults } from 'lodash';
import { addLocaleData } from 'react-intl';

import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/ru';

import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';

import enMessages from '../../src/app/lib/localizator/src/dictionaries/en.json';
import ruMessages from '../../src/app/lib/localizator/src/dictionaries/ru.json';

addLocaleData(en);
addLocaleData(ru);

const messagesByLocale = {
  en: enMessages,
  ru: defaults(ruMessages, enMessages),
};

export default messagesByLocale;
