/* eslint no-console: 0 */

import * as raf from 'raf';

import * as styles from './debug-panel.module.css';

interface Performance {
  memory?: {
    /** The maximum size of the heap, in bytes, that is available to the context. */
    jsHeapSizeLimit: number;
    /** The total allocated heap size, in bytes. */
    totalJSHeapSize: number;
    /** The currently active segment of JS heap, in bytes. */
    usedJSHeapSize: number;
  };
}

export default class DebugPanel {
  static formatMemory(fileSizeInBytes) {
    const byteUnits = [' kB', ' MB', ' GB'];
    let i = -1;
    let size = fileSizeInBytes;
    do {
      size /= 1024;
      i += 1;
    } while (size > 1024);

    return Math.max(size, 0.1).toFixed(1) + byteUnits[i];
  }

  static crEl(tagName, className = '', innerHtml = ''): HTMLElement {
    const el = document.createElement(tagName);
    el.className = className;
    el.innerHTML = innerHtml;
    return el;
  }

  rootPanel: HTMLElement | undefined;
  logPanel: HTMLElement | undefined;
  infoPanel: HTMLElement | undefined;
  systemPanel: HTMLElement | undefined;
  buttonsPanel: HTMLElement | undefined;

  fpsCanvas: HTMLCanvasElement | undefined;
  fpsCtx: CanvasRenderingContext2D | null | undefined;
  fpsValue: string = '';

  minimized: boolean = false;

  fnBackup:
    | {
        log: any;
        info: any;
        warn: any;
        error: any;
        onerror: any;
      }
    | undefined;

  constructor(
    keyCodes = {
      up: 38,
      down: 40,
      minimize: 37,
      clear: 39,
    },
    container = document.body,
  ) {
    this.attachToConsole();
    this.createUI(container);
    this.addEventListeners(keyCodes);

    this.initUpdateRoutine();

    this.clearLog();

    // console.log('Debug Panel created');
  }

  /* ** Public methods ** */

  destroy() {
    const parentNode = this.rootPanel?.parentNode;
    this.deatachFromConsole();
    window.removeEventListener('keydown', this.onKeyDown);

    if (this.rootPanel && parentNode) {
      parentNode.removeChild(this.rootPanel);
    }

    console.info('Debug Panel destroyed');
  }
  onKeyDown(_ev: KeyboardEvent) {
    throw new Error('Method not implemented.');
  }

  addLog(text, logType = '') {
    if (!this.logPanel) return;
    const line = DebugPanel.crEl('div', styles['log-line'] + ' ' + styles[`log-line-${logType}`]);
    line.innerText = text;
    this.logPanel.appendChild(line);
    this.logPanel.scrollTop = this.logPanel.scrollHeight;
  }

  clearLog() {
    if (this.logPanel) {
      this.logPanel.innerHTML = '';
      this.addLog(`${navigator.userAgent} ${window.innerWidth}x${window.innerHeight}`, 'info');
    }
  }

  /* ** Private methods ** */

  // --- Initialization ---

  private addEventListeners(keyCodes) {
    this.onKeyDown = ({ keyCode }) => {
      switch (keyCode) {
        case keyCodes.up:
          this.onUpBtnClick();
          break;
        case keyCodes.down:
          this.onDownBtnClick();
          break;
        case keyCodes.minimize:
          this.onMinimizeBtnClick();
          break;
        case keyCodes.clear:
          this.onClearBtnClick();
          break;
        default:
      }
    };

    document.documentElement.addEventListener('keydown', this.onKeyDown);
  }

  private attachToConsole() {
    this.fnBackup = {
      log: console.log,
      info: console.info,
      warn: console.warn,
      error: console.error,
      onerror: window.onerror,
    };

    console.log = this.createLog(console.log, 'log');
    console.info = this.createLog(console.info, 'info');
    console.warn = this.createLog(console.warn, 'warn');
    console.error = this.createLog(console.error, 'error');
    window.onerror = this.createLog(null, 'error', true);
  }

  private deatachFromConsole() {
    if (!this.fnBackup) {
      throw new Error('deatachFromConsole fail');
      return;
    }

    console.log = this.fnBackup.log;
    console.info = this.fnBackup.info;
    console.warn = this.fnBackup.warn;
    console.error = this.fnBackup.error;
    window.onerror = this.fnBackup.onerror;
  }

  private createUI(container = document.body) {
    this.rootPanel = DebugPanel.crEl('div', styles['debug-panel']);

    this.logPanel = DebugPanel.crEl('div', styles['log-panel']);

    this.infoPanel = DebugPanel.crEl('div', styles['info-panel']);
    this.systemPanel = DebugPanel.crEl('div', styles['system-panel']);
    this.fpsCanvas = DebugPanel.crEl('canvas', styles['fps-canvas']) as HTMLCanvasElement;

    this.fpsCtx = this.fpsCanvas.getContext('2d');

    if (this.fpsCtx) {
      this.fpsCtx.imageSmoothingEnabled = false;
      this.fpsCanvas.width = 50;
      this.fpsCanvas.height = 25;
      this.fpsCtx.fillStyle = 'red';
    } else {
      throw new Error('CanvasRenderingContext2D Error');
    }

    this.fpsValue = '0.0';

    this.buttonsPanel = DebugPanel.crEl(
      'div',
      styles['buttons-panel'],
      `<span class="${styles['button-icon']} ${styles['red']}"></span>
       <span class="${styles['button-text']}">Up</span>
       <span class="${styles['button-icon']} ${styles['yellow']}"></span>
       <span class="${styles['button-text']}">Down</span>
       <span class="${styles['button-icon']} ${styles['green']}"></span>
       <span class="${styles['button-text']}">Minimize</span>
       <span class="${styles['button-icon']} ${styles['blue']}"></span>
       <span class="${styles['button-text']}">Clear</span>`,
    );

    this.infoPanel.appendChild(this.fpsCanvas);
    this.infoPanel.appendChild(this.systemPanel);

    this.rootPanel.appendChild(this.infoPanel);
    this.rootPanel.appendChild(this.logPanel);
    this.rootPanel.appendChild(this.buttonsPanel);

    container.appendChild(this.rootPanel);
  }

  private stringify(arg) {
    const entries = Object.entries(arg);

    return (
      '{ ' +
      entries
        .map(([key, value]: [string, any]) => {
          const type = typeof value;

          if (Array.isArray(value)) {
            return `${key}: ${value.length ? '[...]' : '[empty]'}`;
          } else {
            if (type === 'object' && type !== null) {
              return `${key}: {...}`;
            } else {
              return `${key}: ${value}`;
            }
          }
        })
        .join(', ') +
      ' }'
    );
  }

  private createLog(oldFn, logType = '', errorFormat = false) {
    return (...args) => {
      if (errorFormat) {
        const [message, url, row] = args;
        this.addLog(`${message} (${url}:${row})`, logType);
      } else {
        this.addLog(
          args.map((arg) => (typeof arg === 'object' ? this.stringify(arg) : arg)).join(' | '),
          logType,
        );
      }

      if (oldFn) oldFn(...args);
    };
  }

  private initUpdateRoutine() {
    const WIDTH = this.fpsCanvas ? this.fpsCanvas.width : 50;
    const HEIGHT = this.fpsCanvas ? this.fpsCanvas.height : 25;

    const PERIOD = 1000;
    const MAX_VALUES = WIDTH ? WIDTH : 50;
    const MAX_FPS = 60;
    const { fpsCtx } = this;
    const self = this;

    let values = new Array(MAX_VALUES);
    let lastTime = 0;
    let count = 0;

    raf(function updating() {
      count += 1;
      const t = new Date().getTime();

      if (t - lastTime > PERIOD) {
        lastTime = t;
        values.push(count / (MAX_FPS * PERIOD * 0.001));
        values = values.slice(-MAX_VALUES);
        count = 0;

        if (fpsCtx) {
          fpsCtx.clearRect(0, 0, WIDTH, HEIGHT);
          for (let i = MAX_VALUES; i > 0; i -= 1) {
            const value = values[i];
            if (value !== undefined) {
              const height = Math.floor(HEIGHT * value);
              fpsCtx.fillRect(i, HEIGHT - height, 1, height);
            }
          }
        }

        self.fpsValue = (values[values.length - 1] * MAX_FPS).toFixed(1);

        self.updateSystemInfo();
      }

      raf(updating);
    });
  }

  // --- Events ---

  private onUpBtnClick() {
    if (this.logPanel && !this.minimized) {
      this.logPanel.scrollTop -= this.logPanel.clientHeight / 2;
    }
  }

  private onDownBtnClick() {
    if (this.logPanel && !this.minimized) {
      this.logPanel.scrollTop += this.logPanel.clientHeight / 2;
    }
  }

  private onClearBtnClick() {
    if (!this.minimized) {
      this.clearLog();
    }
  }

  private onMinimizeBtnClick() {
    if (this.rootPanel) {
      this.rootPanel.classList.toggle(styles['minimized']);
      this.minimized = this.rootPanel.classList.contains(styles['minimized']);
      this.updateSystemInfo();
    }
  }

  // --- FPS ---

  // --- System Info

  private updateSystemInfo() {
    const { systemPanel, minimized, fpsValue } = this;
    let info = '';

    if (!systemPanel) return;

    if (minimized) {
      info = `fps: ${fpsValue}`;
    } else {
      const { totalJSHeapSize, usedJSHeapSize } = (console as Performance).memory ?? {};

      const total = DebugPanel.formatMemory(totalJSHeapSize);
      const used = DebugPanel.formatMemory(usedJSHeapSize);

      info = `FPS: ${fpsValue}\nUsed Heap Size: ${used}\nTotal Heap Size: ${total}`;
    }

    systemPanel.textContent = info;
  }
}
