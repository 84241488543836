import React from "react";

import * as css from './styles.module.css'

interface Props {
  text?: string; // текст который нужно отобразить
}


export function SearchInput({ text }: Props) {
  // инпут для страницы поиска
  return (
    <div className={ css.input1 }>
      <div className={ css.input2 }>
        { text }
      </div>
      <div className={ css.input3 }></div>
    </div>
  )
}
