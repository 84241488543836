import { useLocation, useRouteMatch } from 'react-router-dom';

import { useApp } from '~components/Provider/App';
import { useMenu } from '~components/Provider/Menu';
import { usePageById } from '~hooks/fetch/usePages/usePages';
import useBlocks from '~hooks/useBlocks';
import useIsPlayerPage from '~hooks/useIsPlayerPage';
import useMediaItemPageInfo from '~hooks/useMediaItemPage';
import useSceneMediaItemPageInfo from '~hooks/useSceneMediaItemPage';
import { Block } from '~typings/Block';
import ItemObject from '~typings/ItemObject';
import PageObject from '~typings/PageObject';


export type PageInfo = Readonly<{
  pageID: string | null;
  isAuthPage: boolean;
  isPlayerPage: boolean;
  isProfileSelectPage: boolean;
  isCollectionPage: boolean;
  mediaItemPage: {
    id: string | undefined;
    object: ItemObject | undefined;
  };
  isAllowedMenuRender: boolean;
  blocks: Block[];
  isBlocksFetchingDone: boolean;
  isMediaItemDetailsPage: boolean;
  isBuildInPage: boolean;
  isSearchPage: boolean;
}>;


const usePageInfo = (): PageInfo => {
  const { isOnLine } = useApp();
  const { isExitOpened } = useMenu();
  const location = useLocation();
  const match = useRouteMatch<{ id: string, page: string }>('/:page/:id');
  const pageID = match?.params?.id || match?.params?.page || null;
  const page = usePageById(pageID);
  const { isFetchingDone, blocks } = useBlocks(page);
  const isBuildInPage = page?.object === PageObject.BuildInPage;
  const isPlayerPage = useIsPlayerPage();
  const { mediaItemPage, isMediaItemPageMode } = useMediaItemPageInfo();
  const { isSceneMediaItemPageMode } = useSceneMediaItemPageInfo();
  const firstLevelVal = useRouteMatch<{ page: string }>('/:page')?.params.page;
  const isAuthPage = (match?.params?.page === 'auth' || firstLevelVal === 'auth');
  const isRegistrationPage = (match?.params?.page === 'registration' || firstLevelVal === 'registration');
  const isMediaItemDetailsPage = (location.pathname.includes('media-item-details'));
  const isProductDetailsPage = (match?.params?.page === 'products' && match?.params?.id);
  const isSearchPage = (match?.params?.page === 'search');
  const isProfileSelectPage = firstLevelVal === 'profiles';
  const isPromocodeSelectPage = firstLevelVal === 'promocode';
  const isCollectionPage = firstLevelVal === 'collections';
  const isAllowedMenuRender = (
    isOnLine &&
    !isProfileSelectPage &&
    !isMediaItemPageMode &&
    !isSceneMediaItemPageMode &&
    !isPlayerPage &&
    !isAuthPage &&
    !isRegistrationPage &&
    !isMediaItemDetailsPage &&
    !isProductDetailsPage &&
    !isPromocodeSelectPage &&
    !isCollectionPage &&
    !isExitOpened
  );

  return ({
    pageID,
    isPlayerPage,
    isAuthPage,
    isProfileSelectPage,
    isCollectionPage,
    mediaItemPage,
    isAllowedMenuRender,
    isMediaItemDetailsPage,
    isBlocksFetchingDone: isFetchingDone,
    isBuildInPage,
    blocks,
    isSearchPage,
  });
};


export default usePageInfo;
