import { findIndex, uniqBy, values } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { ActionType as AppActionType,useApp, useAppAction } from '~components/Provider/App';
import { ActionType, useEnv, useEnvAction } from '~components/Provider/Env';
import { NavigationDirection } from '~components/Provider/KeyDownHandler/utils';
import {
  ActionType as PlayerActionType,
  usePlayerAction,
  usePlayerState,
} from '~components/Provider/Player/component.helpers';
import { queryCache } from '~global';
import { logout } from '~hooks/fetch/useAccount';
import useNavigationByKeys from '~hooks/useNavigation';
import goWithReload from '~lib/goWithReload';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import { Apis } from '~typings/Apis';

import AddApi from './AddApi';
import { cleanPathname } from './DevManager.helper';
import EditApi from './EditApi';
import FlagItem from './FlagItem';
import ResetButton from './ResetButton';
import * as styles from './styles.module.css';


enum EditType {
  None = 'none',
  Edit = 'edit',
  Editing = 'editing',
  Delete = 'delete',
}

type Flag = { title: string; active: boolean; }

const FlagSwitcher: React.FC = () => {
  const history = useHistory();
  const env = useEnv();
  const envAction = useEnvAction();
  const { isIPTVOn } = useApp();
  const applicationAction = useAppAction();
  const { logger } = usePlayerState();
  const playerAction = usePlayerAction();
  const apis = values(Apis);
  const customApis = env.apis;
  const allApis = uniqBy([...apis, ...customApis], (x) => cleanPathname(x));
  let flags: Array<Flag> = [
    { title: 'PlayerLogger', active: logger },
    { title: 'IPTV', active: isIPTVOn || false },
  ];

  const allApisLength = allApis.length;
  const itemsLength = allApis.length + flags.length + 1;
  const [selectedIndex, setSelectedIndex] = React.useState<number>(
    Math.max(0, findIndex(allApis, (a) => cleanPathname(a) === cleanPathname(env.api))),
  );
  const [editMode, setEditMode] = React.useState<EditType>(EditType.None);

  const handleKeyNavigate = (direction): void => {
    let dirDiff = 0;

    if (direction === NavigationDirection.Down) {
      dirDiff = 1;
    } else if (direction === NavigationDirection.Up) {
      dirDiff = -1;
    } else if (direction === NavigationDirection.Right) {
      if (selectedIndex >= apis.length) {
        setEditMode((state) => {
          if (state === EditType.None) {
            return EditType.Edit;
          }
          if (state === EditType.Edit) {
            return EditType.Delete;
          }

          return state;
        });
      }
    } else if (direction === NavigationDirection.Left) {
      if (selectedIndex >= apis.length) {
        setEditMode((state) => {
          if (state === EditType.Edit) {
            return EditType.None;
          }
          if (state === EditType.Delete) {
            return EditType.Edit;
          }

          return state;
        });
      }
    }

    if (dirDiff !== 0) {
      setEditMode(EditType.None);
      setSelectedIndex((prevIndex) => {
        return Math.max(0, Math.min(itemsLength, prevIndex + dirDiff));
      });
    }
  };

  useNavigationByKeys(
    {
      isMounted: true,
      onNavigation: handleKeyNavigate,
    },
    [selectedIndex, itemsLength, editMode],
  );

  const handleApiItemClick = (newApi) => {
    if (newApi !== env.api) {
      envAction({
        type: ActionType.SetAPI,
        api: newApi,
      });
    } else {
      logout();
      queryCache.clear();

      setTimeout(() => {
        goWithReload(history, '/');
      }, 100);
    }
  };

  const handleFlagItemClick = (flag) => {
    if (flag.title === 'PlayerLogger') {
      playerAction({ type: PlayerActionType.SetLogger, logger: !logger })
    }
    if (flag.title === 'IPTV') {
      applicationAction({ type: AppActionType.ToggleIPTV });
    }
  };

  return React.useMemo(() => {
    const height = valueInPixelsByWidth(6.25);

    const offsetY = selectedIndex * height;

    return (
      <div style={ { position: 'relative' } }>
        <div
          className={ styles.sliderScrollableWrapper }
          style={ {
            transform: `translate3d(0, -${offsetY}px, 0)`,
            WebkitTransform: `translate3d(0, -${offsetY}px, 0)`,
            transitionDuration: '0.3s',
          } }
        >
          <div style={ { position: 'relative' } }>
            <h3
              className={ styles.heading }
              style={ {
                transform: `translate3d(-100%, ${offsetY}px, 0)`,
                WebkitTransform: `translate3d(-100%, ${offsetY}px, 0)`,
                transitionDuration: '0.3s',
              } }
            >
              {selectedIndex > allApisLength ? 'Flags' : 'APIS'}
            </h3>
            {allApis.map((item: any, index: number) => (
              <FlagItem
                focused={ editMode === EditType.None && selectedIndex === index }
                active={ cleanPathname(item) === cleanPathname(env.api) }
                onClick={ () =>
                  editMode === EditType.None &&
                  handleApiItemClick(item.replace('http://', 'https://'))
                }
                key={ item }
              >
                <EditApi
                  editable={ customApis.includes(item) }
                  setEditMode={ setEditMode }
                  focused={ selectedIndex === index }
                  editMode={ editMode }
                >
                  {cleanPathname(item)}
                </EditApi>
              </FlagItem>
            ))}
            <div>
              <AddApi focused={ selectedIndex === allApisLength } />
            </div>
            {flags.length > 0 &&
              flags.map((flag, index) => (
                <FlagItem
                  type="switch"
                  focused={ selectedIndex === index + allApisLength + 1 }
                  active={ flag.active }
                  onClick={ () => handleFlagItemClick(flag) }
                  key={ flag.title }
                >
                  {flag.title}
                </FlagItem>
              ))}
            <ResetButton focused={ selectedIndex === itemsLength } />
          </div>
        </div>
      </div>
    );
  }, [selectedIndex, itemsLength, env.api, isIPTVOn, editMode, logger]);
};


export { EditType };

export default React.memo(FlagSwitcher);
