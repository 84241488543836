import focuser from '@focuser';
import React from 'react';

import { SpinnerIcon } from '~newapp/globalBlocks/spinner';

import * as styles from './CardCollectionLoading.module.css';

export const CardCollectionLoading$: React.FC = () => (
  <focuser.FocusableBlock
    isFocused
    isLastBlock
    noNeedForceFocus
    className={ styles.centered }
  >
    <SpinnerIcon />
  </focuser.FocusableBlock>
);

export const CardCollectionLoading = React.memo(CardCollectionLoading$)
