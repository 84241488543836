import * as React from 'react';

import getImageSRC from '~lib/MediaItemImageSRC';
import getScreenHeight from '~lib/screen/height';
import getScreenWidth from '~lib/screen/width';
import ImageType from '~typings/ImageType';
import { BannerBigItemProps } from '~ui/MediaItem/component';

import * as styles from './styles.module.css';


const BannerBigItem: React.FC<BannerBigItemProps> = (props: BannerBigItemProps) => {
  const imageComponentWidth = getScreenWidth();
  const is2K = (imageComponentWidth === 1920);
  const isIncludedSmartTVBanner = props.item?.images
    ?.findIndex(
      ({ original_width, original_height }) => ((
        original_width === 1280
        && original_height === 548
      ) || (
        original_width === 1920
        && original_height === 822
      ))
    ) !== -1;

  const width = isIncludedSmartTVBanner ?
    (is2K) ? 1920 : 1280
    :
    getScreenWidth();
  const height = isIncludedSmartTVBanner ?
    (is2K) ? 822 : 548
    :
    getScreenHeight();
  const imageSRC = getImageSRC({
    mediaItem: props.item,
    dimension: ImageType.BANNER_WIDE,
    width,
    height,
    isNeedToCrop: false,
  });
  const style: React.CSSProperties = {
    left: `${props.left}px`,
    backgroundImage: `url(${imageSRC})`,
  };

  return (
    <div style={ style } className={ styles.item }>
      BannerBigItem
    </div>
  );
};


export default BannerBigItem;
