/* eslint-disable react-hooks/rules-of-hooks */ // Линтер думает что тут ошибка
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { CANCEL, FILTER_BUTTON_SUBMIT, RESET_ALL_FILTERS } from '~lib/localizator/src/dictionaries';

import * as styles from './DropdownSelectButtons.module.css';

export interface Props {
  onApply: () => void;
  onCancel: () => void;
  onReset: () => void;
  isResetAvailable: boolean;
}

export const DropdownSelectButtons = React.memo(
  injectIntl<Props>((props) => {
    const { isResetAvailable, onApply, onCancel, onReset, intl } = props;
    const [focusOn, setFocusOn] = useState<'apply' | 'cancel' | 'reset'>('apply');

    useEffect(() => {
      if (!isResetAvailable && focusOn === 'reset') {
        setFocusOn('cancel');
      }
    }, [isResetAvailable]);

    return (
      <div className={ styles.DropdownSelectButtons }>
        <PrimaryButtonFocuser
          onClick={ (e) => {
            onApply();
            e.stop();
          } }
          isFocused={ focusOn === 'apply' }
          emitForceFocusOnHover
          onForceFocus={ () => setFocusOn('apply') }
          onKeyRight={ (e) => {
            setFocusOn('cancel');
            e.stop();
          } }
          isPointerRightAvailable
          focuserClassName={ styles.button }
        >
          {intl.formatMessage({ id: FILTER_BUTTON_SUBMIT })}
        </PrimaryButtonFocuser>
        <PrimaryButtonFocuser
          onClick={ (e) => {
            onCancel();
            e.stop();
          } }
          isFocused={ focusOn === 'cancel' }
          emitForceFocusOnHover
          onForceFocus={ () => setFocusOn('cancel') }
          onKeyLeft={ (e) => {
            setFocusOn('apply');
            e.stop();
          } }
          onKeyRight={ (e) => {
            if (!isResetAvailable) return;
            setFocusOn('reset');
            e.stop();
          } }
          isPointerLeftAvailable
          isPointerRightAvailable={ isResetAvailable }
          focuserClassName={ styles.button }
        >
          {intl.formatMessage({ id: CANCEL })}
        </PrimaryButtonFocuser>
        <PrimaryButtonFocuser
          onClick={ (e) => {
            onReset();
            e.stop();
          } }
          isDisabled={ !isResetAvailable }
          isFocused={ focusOn === 'reset' }
          onForceFocus={ () => setFocusOn('reset') }
          onKeyLeft={ (e) => {
            setFocusOn('cancel');
            e.stop();
          } }
          isPointerLeftAvailable
          emitForceFocusOnHover
          focuserClassName={ styles.button }
        >
          {intl.formatMessage({ id: RESET_ALL_FILTERS })}
        </PrimaryButtonFocuser>
      </div>
    );
  }),
);
