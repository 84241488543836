import * as React from 'react';

import { ActionType,useAppAction } from '~components/Provider/App';
import { ActionType as MenuActionType, useMenuAction } from '~components/Provider/Menu';
import { useConfig } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
// import { PREFIX_OF_A_COMPOSITE_KEY } from '~hooks/fetch/useCollectionItems';
// import {
//   PREFIX_OF_A_COMPOSITE_KEY as currentEventKey,
// } from '~hooks/fetch/useCurrentEvent/useCurrentEvent';
import useBlocksTotals from '~hooks/useBlocks/useBlocksTotals';
import usePrevious from '~hooks/usePrevious';
import useSceneMediaItemPageInfo from '~hooks/useSceneMediaItemPage';
// import deleteCacheByKeys from '~lib/ReactQuery/deleteCacheByKeys';
import useNavigation from '~stores/Navigation';
import { Block } from '~typings/Block';
import Page from '~typings/Page';


type Props = Readonly<{
  page: Page | null;
  isFetchingDone: boolean;
  blocks: Block[];
}>;


const useBlockPageManager = (props: Props) => {
  const appAction = useAppAction();
  const { accessToken } = getAuthData();
  const config = useConfig();
  const { smartTV: { sliderSize } } = config;
  const setFocusedHistory = useNavigation(state => state.setFocusedHistory);
  const currentPage = useNavigation(state => state.currentPage);
  const prevPageID = usePrevious(props.page?.id);
  const menuAction = useMenuAction();
  const { isSceneMediaItemPageMode } = useSceneMediaItemPageInfo();
  const totals = useBlocksTotals();
  const prevTotals = usePrevious(totals);

  React.useEffect(() => {
    if (prevPageID !== props.page?.id && !props.isFetchingDone) {
      if (!isSceneMediaItemPageMode) {
        appAction({
          type: ActionType.SetIsLaunchingDone,
          payload: { isLaunchingDone: false },
        });
      }
      menuAction({
        type: MenuActionType.ChangeMenuAppearing,
        isMenuAppeared: false,
      });
    }
  }, [prevPageID, props.page?.id, props.isFetchingDone, isSceneMediaItemPageMode]);

  React.useEffect(() => {
    if (props.isFetchingDone) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: { isLaunchingDone: true },
      });
    }
  }, [props.isFetchingDone]);

  // If the number of blocks on the page
  // has changed (favorite channels, favorite movies or continue watching),
  // then we need to recalculate the blocks in the navigation history
  React.useEffect(() => {
    if (
      currentPage?.blocks
      && props.isFetchingDone
      && props.blocks.length !== 0
      && (prevPageID === props.page?.id || totals !== prevTotals)
    ) {
      setFocusedHistory(config, accessToken, currentPage.blocks, sliderSize);
    }
  }, [
    prevPageID,
    props.blocks.length,
    props.isFetchingDone,
    currentPage,
    totals,
    prevTotals,
  ]);

  // React.useEffect(() => () => {
  //   if (prevPageID && props.page?.id && prevPageID !== props.page.id) {
  //     deleteCacheByKeys([[[PREFIX_OF_A_COMPOSITE_KEY]], [currentEventKey]]);
  //   }
  // }, [prevPageID, props.page?.id]);

  return null;
};

export default useBlockPageManager;
