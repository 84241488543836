import { QueryKey,useQuery } from 'react-query';

import { useClient } from '~global';
import expand from '~hooks/fetch/expand';
import { getAuthData } from '~hooks/fetch/useAccount';
import Offer from '~typings/Offer';
import ResponseSingle from '~typings/ResponseSingle';

const PREFIX_OF_A_COMPOSITE_KEY = 'subscriptions_plans_offer';

const getKey = (subscriptionID?: string): QueryKey => [PREFIX_OF_A_COMPOSITE_KEY, subscriptionID];

const fetch = async (client, accessToken, subscriptionID) => {
  if (!subscriptionID) { return undefined; }

  return await client.get(
    `/v1/subscriptions/plans/${subscriptionID}/offer`,
    {
      access_token: accessToken,
      'payment_method_type': 'operator',
      'expand[offer]': expand.offer,
    }
  );
};


const useSubscriptionsPlansOffer = (subscriptionID?: string) => {
  const queryKey = getKey(subscriptionID);
  const { accessToken } = getAuthData();

  const client = useClient();

  return useQuery<ResponseSingle<Offer>>({
    queryKey,
    queryFn: () => fetch(client, accessToken, subscriptionID),
    enabled: !!(accessToken && subscriptionID),
    staleTime: 0,
    cacheTime: 0,
  });
};


export {
  getKey,
  PREFIX_OF_A_COMPOSITE_KEY,
};

export default useSubscriptionsPlansOffer;
