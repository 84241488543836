import serviceInfoByLang from 'customization-project-name/service-info';
import * as React from 'react';

import CatchBack from '~components/PageManager/CatchBack';
import { useApp } from '~components/Provider/App';
import ScrollBar from '~components/ScrollBar';
import useScroll from '~hooks/useScroll/useScroll';
import preparingText from '~lib/preparingText';
import { Popup } from '~ui/Popup';

import * as styles from './styles.module.css';


export const TextContent = ({ text }: { text: string }) => {
  const contentBlockRef = React.useRef<HTMLDivElement>(null);
  const {
    isScrollAvailable,
    scrollStyle,
    scrollPos,
  } = useScroll({ contentBlockRef });

  return (
    <>
      <div className={ styles.wrapper }>
        <div
          className={ styles.content }
          style={ scrollStyle }
          dangerouslySetInnerHTML={ {__html: preparingText(text) } }
          ref={ contentBlockRef }
        />
      </div>
      {
        isScrollAvailable ? (
          <ScrollBar
            position={ scrollPos }
            className={ styles.scrollbar }
            isFocused
          />
        ) : null
      }
    </>
  )
};


type Props = {
  onBack?: () => void;
  infoType: string;
  language?: string;
}

const AgreementPopup: React.FC<Props> = ({
  onBack,
  infoType,
  language,
}) => {
  const { language: appLanguage } = useApp();
  const [htmlContent, setHtmlContent] = React.useState<string>('');
  const htmlBundle = serviceInfoByLang?.[infoType]?.[language ?? appLanguage];

  const handleBack = React.useCallback(() => {
    if (onBack) {
      onBack();
    }
  }, []);

  const handleBundleLoad = (htmlContent) => {
    const cleanedHTMLContent = preparingText(htmlContent.default);
    setHtmlContent(cleanedHTMLContent);
  };

  React.useEffect(() => {
    if (htmlBundle) {
      htmlBundle().then(handleBundleLoad);
    } else {
      setHtmlContent(`No Data "${infoType}"`)
    }
  }, []);

  return (
    <Popup>
      <CatchBack
        action={ handleBack }
        triggers={ [onBack] }
      />
      {
        htmlContent ? <TextContent text={ htmlContent } /> : null
      }
    </Popup>
  )
};

/**
 * @deprecated, используй DocViewPopup
 */
export default React.memo(AgreementPopup);
