import focuser, { FocuserKeyHandler } from '@focuser';
import * as React from 'react';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Feature, hasFeature } from '~app/global';
import { Alert2 } from '~components/Alert';
import { getMeta } from '~components/MediaItemPage/Description';
import { MediaItemBarFocuser } from '~components/MediaItemPage/Description/bar/MediaItemBarFocuser';
import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import SceneMediaItemDescriptionText from '~components/MediaItemPage/Description/description-text';
import SceneMediaItemMeta from '~components/MediaItemPage/Description/meta';
import PurchaseInfo from '~components/MediaItemPage/PurchaseInfo/PurchaseInfo';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { PlayerAccessReason } from '~components/Provider/Player/component.helpers';
import { RecommendationsFocuser } from '~components/Recommendations';
import { useFavoriteItem, useToggleFavorite } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { useLastViewedEpisode } from '~hooks/fetch/useLastViewedEpisode/useLastViewedEpisode';
import { Method, useVoteMutation } from '~hooks/fetch/useVote';
import useAccess from '~hooks/useAccess/useAccess';
import { makeReadyChildsMapperHook } from '~lib/focuserUtils/readyChildsMapper';
import Episode from '~typings/Episode';
import FavoriteType from '~typings/FavoriteType';
import ItemObject from '~typings/ItemObject';
import Series from '~typings/Series';
import { VoteAction } from '~typings/Vote';
import { VerticalFadeScroll } from '~ui/VerticalFadeScroll';

import DescriptionWrapper from '../../../Description/wrapper';
import * as styles from './SeriesFrontContent.module.css';


export interface Props {
  item: Series;
  onOpenEpisodes: () => void;
  onEpisodePlay: (episode: Episode) => void;
}

/**
 * Часть страницы сериала, где отображаются Описани/Кнопки/Рекомендации
 */
export const SeriesFrontContent: React.FC<Props> = (props) => {
  const history = useHistory();
  const matchPage = useRouteMatch('/page/:id');

  const { setItem: playerSetItem } = usePlayerStateActions();


  const { nextEpisode: lastViewedEpisode } = useLastViewedEpisode(props.item.id);
  const access = useAccess(
    (lastViewedEpisode || props.item.seasons[0]?.episodes?.[0])?.id,
    ItemObject.Episode
  );

  const { mutate: toggleVote } = useVoteMutation(props.item.object, props.item.id);
  const favoriteItemQuery = useFavoriteItem(FavoriteType.Series, props.item?.id);
  const { mutate: toggleFavorite, isLoading } = useToggleFavorite();

  const isInFavorites = Boolean(favoriteItemQuery.data);
  const seasonsCount = props.item.seasons.length > 1 ? 'many' : 'one';

  const handlePlayClick = () => {
    const firstEpisode = props.item.seasons[0]?.episodes[0];
    if (firstEpisode) {
      props.onEpisodePlay(firstEpisode);
    }
  };

  const handleContinueClick = () => {
    if (lastViewedEpisode){
      props.onEpisodePlay(lastViewedEpisode);
    }
  };

  const handleBarClick = (barItem: BarItem) => {
    if (barItem === BarItem.Play) {
      handlePlayClick();
    } else if (barItem === BarItem.PlayContinue) {
      handleContinueClick();
    } else if (barItem === BarItem.WatchLaterRemove && !isLoading) {
      toggleFavorite({ itemID: props.item.id, isInFavorites, type: FavoriteType.Series });
    } else if (barItem === BarItem.WatchLaterAdd && !isLoading) {
      toggleFavorite({ itemID: props.item.id, isInFavorites, type: FavoriteType.Series });
    } else if (barItem === BarItem.Trailer && props.item.trailer) {
      playerSetItem(props.item.trailer);
    } else if (barItem === BarItem.RatedUp || barItem === BarItem.RatedDown) {
      toggleVote({ method: Method.DELETE });
    } else if (barItem === BarItem.RateUp) {
      toggleVote({ method: Method.POST, mention: VoteAction.Up });
    } else if (barItem === BarItem.RateDown) {
      toggleVote({ method: Method.POST, mention: VoteAction.Down });
    } else if (barItem === BarItem.AllSeasons || barItem === BarItem.AllEpisodes) {
      props.onOpenEpisodes();
    }
  };

  const descriptionClick = () => {
    history.push(`${
      matchPage ? `${ matchPage.url }/` : '/'
    }media-item/${ props.item.object }/${ props.item.id }/details`);
  };


  const [useReadyChildsHook] = useState(()=>makeReadyChildsMapperHook({
    description: true,
    bar: false,
    recs: false,
  }));

  const {
    setIsRecsReady,
    setIsBarReady,
    focusOn,
    setFocusOn,
    nextChildToNavigate,
    prevChildToNavigate,
  } = useReadyChildsHook('description');

  const navigateOnNext: FocuserKeyHandler = (ev) => {
    if (nextChildToNavigate) {
      setFocusOn(nextChildToNavigate);
      ev.stop();
      ev.stopNativeEvent();
    }
  };

  const navigateOnPrev: FocuserKeyHandler = (ev) => {
    if (prevChildToNavigate) {
      setFocusOn(prevChildToNavigate);
      ev.stop();
      ev.stopNativeEvent();
    }
  };

  return (
     <focuser.FocusableBlock
       className={ styles.container }
       isFocused
       onKeyUp={ navigateOnPrev }
       isPointerUpAvailable={ Boolean(prevChildToNavigate) }
       isPointerDownAvailable={ Boolean(nextChildToNavigate) }
       onKeyDown={ navigateOnNext }
       noNeedForceFocus
     >
       <VerticalFadeScroll
         focusedIndex={ focusOn === 'recs' ? 1 : 0 }
         sliderClassName={ styles.verticalSlider }
       >
         <div>
           <focuser.FocusableBlock
             isFocused={ focusOn === 'description' }
             onForceFocus={ () => setFocusOn('description') }
             emitForceFocusOnHover
             isForceFocusEnabledInBranch={ focusOn !== 'recs' }
             onClick={ descriptionClick }
             isLastBlock
             className={ styles.description }
           >
             {({ isFocused }) => (
               <DescriptionWrapper isFocused={ isFocused }>
                 <SceneMediaItemMeta meta={ getMeta(props.item) } />
                 <SceneMediaItemDescriptionText description={ props.item.description } />
               </DescriptionWrapper>
             )}
           </focuser.FocusableBlock>
           {access && (
             <>
               <focuser.FocusableBlock
                 isForceFocusEnabledInBranch={ focusOn !== 'recs' }
                 onForceFocus={ () => setFocusOn('bar') }
                 isFocused={ focusOn === 'bar' }
                 className={ styles.mediaItemBar }
               >
                 <MediaItemBarFocuser
                   onReady={ setIsBarReady }
                   access={ access }
                   item={ props.item }
                   watchProgressItem={ lastViewedEpisode }
                   onClick={ handleBarClick }
                   seasonsCount={ seasonsCount }
                 />
               {access.reason === PlayerAccessReason.Rented && (
                 <PurchaseInfo purchase={ access.reason_object } />
               )}
               </focuser.FocusableBlock>
               <div className={ styles.alert }>
                 {access && !access.allowed && access.reason && <Alert2 reason={ access.reason } />}
               </div>
             </>
           )}
         </div>
         <focuser.FocusableBlock
           className={ hasFeature(Feature.NewCards) ? styles.newCardRecs : styles.recs }
           isFocused={ focusOn === 'recs' }
           onForceFocus={ () => setFocusOn('recs') }
           isForceFocusEnabledInBranch={ focusOn === 'recs' }
         >
           <RecommendationsFocuser
             item={ props.item }
             onReady={ setIsRecsReady }
           />
         </focuser.FocusableBlock>
       </VerticalFadeScroll>
     </focuser.FocusableBlock>
   );
}
