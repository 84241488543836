import * as React from 'react';

import { Alert2 } from "~components/Alert";
import * as css from "~components/MediaItemPage/Description/styles.module.css";
import { useAutoInfinityBlackouts } from '~hooks/fetch/useBlackouts/useBlackouts';
import useChannelBySlug from '~hooks/fetch/useChannel';
import ProgramEvent from '~typings/Event';
import { PrimitivePlayerAccess } from '~typings/PlayerAccess';

import ProgramEventStatusBlock from '../ProgramEventStatusBlock';
import * as styles from './styles.module.css';

type Props = Readonly<{
  event: ProgramEvent;
  isFocused: boolean;
  onWatchBtnClick?: () => void;
  access?: PrimitivePlayerAccess | null;
}>;

const ProgramHeader: React.FC<Props> = (props: Props) => {
  const { event, access } = props;
  const { data: { data: channel } = {} } = useChannelBySlug(event.channel_id);
  const blackouts = useAutoInfinityBlackouts(event.channel_id).data?.data;

  if (!event || !channel || !blackouts) return null;

  return (
    <>
      <div style={ { width: '60.9375vw', marginLeft: '6.875000000000001vw' } }>
        <div className={ styles.channelName }>{ channel.name }</div>
        <div className={ styles.title }> { event.name } </div>
        <div className={ styles.subtitle }>{ event.subtitle }</div>
        <ProgramEventStatusBlock
          access={ access }
          event={ event }
          channel={ channel }
          blackouts={ blackouts }
          isFocused={ props.isFocused }
          onWatchBtnClick={ props.onWatchBtnClick }
        />
      </div>
      <div className={ css.alert }>
        { access && !access.allowed && access.reason && <Alert2 reason={ access.reason } /> }
      </div>
    </>
  )
};

export default ProgramHeader;
