import * as React from 'react';

import { NavigationDirection } from '~components/Provider/KeyDownHandler/utils';
import useNavigationByKeys from '~hooks/useNavigation';


type Props = Readonly<{
  isFocused?: boolean;
  onLeave?: (NavigationDirection) => void;
}>;


const NavigationHelper: React.FC<Props> = ({
  isFocused,
  onLeave,
}: Props) => {
  const handleKeyNavigate = (direction: NavigationDirection) => {
    if (isFocused && onLeave) {
      onLeave(direction);
    }
  };

  useNavigationByKeys(
    {
      isMounted: true,
      onNavigation: handleKeyNavigate,
    },
    [onLeave, isFocused],
  );

  return null;
};


export default NavigationHelper;
