import focuser, { FocuserKeyHandler } from '@focuser';
import React, { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import AudioShow from '~typings/AudioShow';
import AudioShowPart from '~typings/AudioShowPart';

import { PartsSlider } from '../PartsSlider/PartsSlider';
import * as styles from './styles.modules.css';
import { useInitialFocusPart } from './utils';

export interface Props {
  audioshow: AudioShow;
  selectedPartID?: string;
  onBack: () => void;
  onPartPlay: (episode: AudioShowPart) => void;
}

export const AudioShowPartSelect: React.FC<Props> = ({
  audioshow,
  onBack,
  selectedPartID,
  onPartPlay,
}) => {

  const [initialPart, setInitialPart] = React.useState(0);
  const [isInitialFocusLoaded, setIsInitialFocusLoaded] = React.useState(false);

  const initialFocuses = useInitialFocusPart(audioshow, selectedPartID); 

  useEffect(() => {
    if (isInitialFocusLoaded) {
      return;
    }

    if (!initialFocuses) {
      return;
    }

    const { partIdx } = initialFocuses;

    unstable_batchedUpdates(() => {
      setIsInitialFocusLoaded(true);
      setInitialPart(partIdx);
    });
  }, [initialFocuses]);


  const backHandler: FocuserKeyHandler = (e) => {
    e.stop();
    e.stopNativeEvent();
    onBack();
  };

  if (!isInitialFocusLoaded) {
    return null;
  }

  return (
    <focuser.FocusableBlock
      className={ styles.partsSlider }
      isFocused
      isPointerUpAvailable
      onKeyReturn={ backHandler }
      isForceFocusEnabledInBranch = { false }
    >
      <PartsSlider
        audioshow={ audioshow }
        initialPartIdx={ initialPart }
        onPartPlay={ onPartPlay }
      />
    </focuser.FocusableBlock>
  )
}
