import DropdownFilter from '~typings/DropdownFilter';
import QuickFilter from '~typings/QuickFilter';


export type SearchFilters = {
  quickFilters?: QuickFilter[];
  dropdownFilters?: DropdownFilter[];
}


const getQuickFiltersString = (quickFilters: QuickFilter[] | undefined) =>
  (quickFilters || [])
    .map((filter) => filter?.slug).join(',');


const getDropdownFiltersOptions = (dropdownFilters: DropdownFilter[] | undefined) => {
  const res: Record<string, string> = {};

  (dropdownFilters || []).forEach((filter) => {
    res[`filter[${filter.api_param}]`] = filter.options.map(x => x.value).join(',');
  });

  return res;
}


const getFiltersKeyString = (filters: SearchFilters) =>
  `${(filters?.quickFilters || []).map((filter) => filter?.slug).join(',')}${Object.values(
    filters?.dropdownFilters || [],
  )
    .reduce(
      (acc, filter) =>
        acc.concat(`${filter.api_param}:${filter.options.map((x) => x.value).join(',')}`),
      [] as string[],
    )
    .join(',')}`;


export {
  getDropdownFiltersOptions,
  getFiltersKeyString,
  getQuickFiltersString,
};
