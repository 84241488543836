import React from "react";

import { AgeIcon } from "~newapp/ui-kit/Icons/For_ratings/adult";
import { ImdbIcon } from "~newapp/ui-kit/Icons/For_ratings/imdb";
import { KinoPoiskIcon } from "~newapp/ui-kit/Icons/For_ratings/kinopoisk";
import { SpbtvLike } from "~newapp/ui-kit/Icons/For_ratings/spbtv";

import { useGetDataResult } from "../playerPopcorn/Player/controls/ItemInfo/hooks/getDescriptionFields";
import * as css from './styles.module.css'

interface Props {
  data: useGetDataResult,
}

const LocalComponent = ({ data }: Props) => {
  // этот блок не самостоятельный, нужно прокинуть в него готовые данные
  // готовые данные так же используются в других компонентах и генерятся один раз на уровень выше
  // этот блок выдает в линию иконки рейтинга, используется на страницах сериала и фильма и в плеере
  // заверните этот блок во враппер в нужном вам компоненте чтобы получить нужную разметку
  return (
    <>
      {
        !!(data.imdb || data.kinopoisk || data.age) &&
          <div className={ css.mainWrapper }>
            {
              data.imdb &&
                <div className={ css.iconWrapper }>
                    <div className={ css.icon }><KinoPoiskIcon /></div>
                    <div className={ css.iconText }>{ data.imdb }</div>
                </div>
            }
            {
              data.kinopoisk &&
                <div className={ css.iconWrapper }>
                    <div className={ css.icon }><ImdbIcon /></div>
                    <div className={ css.iconText }>{ data.kinopoisk }</div>
                </div>
            }
            {
              data.spbtv &&
                <div className={ css.iconWrapper }>
                    <div className={ css.icon }><SpbtvLike /></div>
                    <div className={ css.iconText }>{ data.spbtv }</div>
                </div>
            }
            {
              data.age &&
                <div className={ css.iconWrapper }>
                    <div className={ css.icon }>
                        <AgeIcon text={ data.age } />
                    </div>
                </div>
            }
          </div>
      }
    </>
  )
}


export const RatingLine = LocalComponent