import focuser, { FocuserKeyHandler } from "@focuser";
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { PROFILE_TYPE_KIDS } from "~lib/localizator/src/dictionaries";
import ProfileFormData from '~typings/ProfileFormData';
import RadioListItem from '~ui/RadioList/RadioListItem';

import { ageSelectionField, fieldWithProfileName, translatedConfig } from '../../helpers';
import * as styles from '../../styles.module.css';
import ProfileField from './ProfileField';

enum FocusOn {
  ChangeNameField = 'change_name_field',
  ChangeAgeField = 'change_age_field',
  KidsProfileCheckbox = 'kids_profile_checkbox',
}

type Props = Readonly<{
  popupTitle: string;
  initialFocus?: 'age' | 'name';
  profileData: ProfileFormData;
  setProfileData: (part: Partial<ProfileFormData>) => void;
  isShowCheckboxButton: boolean;
  onSelectNameField: () => void;
  onSelectAgeField: () => void;
  intl: InjectedIntl;
}>;


const ContentComponent: React.FC<Props> = ({
  popupTitle,
  initialFocus,
  profileData,
  setProfileData,
  isShowCheckboxButton,
  onSelectNameField,
  onSelectAgeField,
  intl
}: Props) => {

  const [focusedField, setFocusedField] = React.useState<FocusOn>(
    initialFocus === 'age' ? FocusOn.ChangeAgeField : FocusOn.ChangeNameField
  );

  const translatedProfileNameField = translatedConfig(fieldWithProfileName, intl);
  const translatedAgeSelectionAge = translatedConfig(ageSelectionField, intl);

  const profileTypeKids = intl.formatMessage({id: PROFILE_TYPE_KIDS});

  const handleKeyDown: FocuserKeyHandler = (evt) => {
    if(focusedField === FocusOn.ChangeNameField) {
      setFocusedField(FocusOn.ChangeAgeField)
      evt.stop()
    }
    if(focusedField === FocusOn.ChangeAgeField) {
      if(isShowCheckboxButton) {
        setFocusedField(FocusOn.KidsProfileCheckbox)
        evt.stop()
        return
      }
    }
  }

  const handleKeyUp: FocuserKeyHandler = (evt) => {
    if(focusedField === FocusOn.ChangeAgeField) {
      setFocusedField(FocusOn.ChangeNameField)
      evt.stop();
    }
    if(focusedField === FocusOn.KidsProfileCheckbox) {
      setFocusedField(FocusOn.ChangeAgeField);
      evt.stop();
    }
  }

  const renderProfileNameField = () => {
    return (
      <focuser.FocusableBlock
        isFocused={ focusedField === FocusOn.ChangeNameField }
        isLastBlock
        onClick={ onSelectNameField }
        onForceFocus={ () => setFocusedField(FocusOn.ChangeNameField) }
        emitForceFocusOnHover
      >
        {({ isFocused }) => (
          <ProfileField
            fieldValue={ profileData.name }
            isFocused={ isFocused }
            placeholder={ translatedProfileNameField.placeholder }
            title={ popupTitle }
            maxLength={ 25 }
            minLength={ 2 }
          />
        )}
      </focuser.FocusableBlock>
    );
  }

  const renderAgeSelectionField = () => {
    return (
      <focuser.FocusableBlock
        isFocused={ focusedField === FocusOn.ChangeAgeField }
        isLastBlock
        onClick={ onSelectAgeField }
        onForceFocus={ () => setFocusedField(FocusOn.ChangeAgeField) }
        emitForceFocusOnHover
      >
        {({ isFocused }) => (
          <ProfileField
            items={ translatedAgeSelectionAge.items }
            fieldValue={ profileData.allowed_content }
            isFocused={ isFocused }
            placeholder={ translatedAgeSelectionAge.placeholder }
            title={ popupTitle }
          />
        )}
      </focuser.FocusableBlock>
    );
  }

  const renderCheckboxButton = () => {
    return (
      <focuser.FocusableBlock
        isFocused={ focusedField === FocusOn.KidsProfileCheckbox }
        onKeyDown={ handleKeyDown }
        onKeyUp={ handleKeyUp }
        isLastBlock
        onClick={ (evt) => {
          setProfileData({kids: !profileData.kids})
          evt.stop()
          evt.stopNativeEvent()
        } }
        onForceFocus={ () => setFocusedField(FocusOn.KidsProfileCheckbox) }
        emitForceFocusOnHover
      >
        {({ isFocused }) => (
          <RadioListItem
            item={ { name: profileTypeKids, isChecked: profileData.kids ? true : false} }
            isFocused={ isFocused }
            className={ styles.checkbox }
          />
        )}
      </focuser.FocusableBlock>
    )
  }

  return (
    <focuser.FocusableBlock
      isFocused
      className={ styles.inputsWrap }
      onKeyDown={ handleKeyDown }
      onKeyUp={ handleKeyUp }
      noNeedForceFocus
    >
      { renderProfileNameField() }
      { renderAgeSelectionField() }
      {isShowCheckboxButton &&
        renderCheckboxButton()
      }
    </focuser.FocusableBlock>
  )
}

export default injectIntl(ContentComponent);
