import * as React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import useRentInvoice from '~hooks/fetch/usePayments/useRentInvoice';
import { usePromocode } from '~hooks/fetch/usePromocode/usePromocodeMutation';
import * as URLSearchManager from '~lib/URLQueryStringManager';
import ItemObject from '~typings/ItemObject';

import InvocePage from './InvocePage';
import * as styles from './styles.module.css';


const PromoRentInvoce = () => {
  const history = useHistory();
  const { itemId } = useParams<{ itemId: string; }>();
  const matchPage = useRouteMatch<{ itemId: string; itemObject: ItemObject }>(
    '/:page/:id/media-item/:itemObject/:itemId/promo'
  );
  const matchMediaPage = useRouteMatch<{ itemId: string; itemObject: ItemObject }>(
    '/media-item/:itemObject/:itemId/promo'
  );
  const match = matchPage || matchMediaPage
  const url = match?.url;

  const { mutate, data: invoice } = useRentInvoice();
  const promocode = URLSearchManager.getField(history.location.search, 'promo_code');
  const planID = URLSearchManager.getField(history.location.search, 'rentPlanId');
  const { data } = usePromocode(promocode);
  const plan = (data?.rentPlans || []).find(plan => plan.id === planID);
  const isFreeAccessPromo = data?.promocode?.promo?.object === 'free_access_promo';

  React.useEffect(() => {
    if (planID && plan) {
      mutate({
        planID,
        resourceID: itemId,
        planType: plan.type,
        paymentMethodType: 'promo_code',
        promocode,
      });
    }
  }, [planID, itemId, plan, promocode, isFreeAccessPromo]);

  return (
    <div className={ styles.scene }>
      <InvocePage
        plan={ plan }
        invoice={ invoice?.data }
        focusOnMenu={ false }
        url={ url }
        isFreeAccessPromo={ isFreeAccessPromo }
      />
    </div>
  );
};


export default PromoRentInvoce;
