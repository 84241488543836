enum PlayerAccessError {
  AgeNeedConfirmation = 'ageNeedConfirmation',
  NotAuthorized = 'notAuthorized',
  ErrorAccessDisallowed = 'errorAccessDisallowed',
  ErrorAccessRequestFailed = 'errorAccessRequestFailed',
  AccessAllowed = 'accessAllowed',
  StreamFetchFailed = 'streamFetchFailed',
  CannotPlayStream = 'PLAYER_ERROR_CANNOT_PLAY_STREAM',
}


export default PlayerAccessError;
