import {useQuery } from 'react-query';

import { ResourceType } from '~components/ActorPage/hooks';
import { queryClient } from '~global';
import { useClient } from '~global';
import Card from '~typings/Card';
import ResponseMany from '~typings/ResponseMany';


const PREFIX_OF_A_COMPOSITE_KEY = 'cards';

const getKey = (personID: string, resourceType) => ( 
  [
    PREFIX_OF_A_COMPOSITE_KEY,
    personID,
    resourceType
  ]
)

const fetch = async (client, cacheData, id, resourceType) => {
  if (cacheData) { return cacheData; }

  const url = `/v3/cards/`
 
  return await client.get(
    url, 
    { 
    'filter[resource_type_in]': resourceType, 
    'filter[person_id_in]': id 
    }
  );
};

export const useCards = (id: string, resourceType: ResourceType[] ) => {
  const client = useClient();
  const resourceString = resourceType.join(',');
  const queryKey = getKey(id, resourceString);
  const cacheData = queryClient.getQueryData(id);


  return useQuery<ResponseMany<Card[]>>({
    queryKey,
    queryFn: () => fetch(client, cacheData, id, resourceString),
  });
};
