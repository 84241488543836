import classnames from 'classnames';
import React from 'react';

import { SliderOffsetableRef, useOffsetChanger, useSliderImperativeHandle, useTransitionStyleChanger } from './hooks';
import * as styles from './SliderOffsetable.module.css';

export type SliderOffsetableProps = {
  /**
   * Индекс сфокусированного элемента
   */
  focusedIndex: number;
  /**
   * Сколько должна длится анимация перелистывания
   * @default 200
   */
  animationDurationInMS?: number;
  /**
   * children-ы, которых нужно отрендерить
   * Передавать нужно массивом, пример:
   * ```
   * <Slider>
   * <div>1</div>
   * <div><h1>2</h1></div>
   * <div>3</div>
   * </Slider>
   * ```
   *
   * Подробнее смотреть в Storybook
   */
  children: React.ReactNode;
  /**
   * Если какое-то действие изменяет ширину слайдов, то через этот массив можно оповестить об изменениях
   * Подробнее смотреть в Storybook
   */
  updates?: unknown[];

  /**
   * Дополнительный класс для обертки слайдера
   */
  wrapperClassName?: string;

  /**
   * Дополнительный класс для слайдера
   */
  sliderClassName?: string;

  sliderOffsetableRef?: SliderOffsetableRef
};


export const SliderOffsetable: React.FC<SliderOffsetableProps> = React.memo(({
  animationDurationInMS = 200,
  focusedIndex,
  children,
  updates,
  sliderClassName,
  wrapperClassName,
  sliderOffsetableRef
}) => {

  const sliderRef = React.useRef<HTMLDivElement>(null);
  const sliderWrapperRef = React.useRef<HTMLDivElement>(null);

  useTransitionStyleChanger({
    animationDurationInMS,
    sliderRef,
  });

  useOffsetChanger({
    focusedIndex: focusedIndex,
    sliderRef,
    sliderWrapperRef,
    updates,
  });

  useSliderImperativeHandle({
    sliderOffsetableRef,
    sliderRef,
    sliderWrapperRef,
  });

  return (
    <div className={ classnames(styles.sliderWrapper, wrapperClassName) } ref={ sliderWrapperRef }>
      <div
        ref={ sliderRef }
        className={ classnames(styles.slider, sliderClassName) }
      >
        {children}
      </div>
    </div>
  );
});

SliderOffsetable.displayName = 'SliderOffsetable';
