import { logotype } from 'customization-project-name/assets';
import * as React from 'react';

import * as styles from './About.module.css';
import { AboutButtons } from './AboutButtons';
import { AboutInfoFields } from './AboutInfoFields';

export const About = () => {
  return (
    <div className={ styles.About }>
      <img
        className={ styles.logo }
        src={ logotype }
        alt="Logotype"
      />
      <AboutInfoFields className={ styles.AboutInfoFields } />
      <AboutButtons />
    </div>
  );
};
