import * as React from 'react';

import IconMediaItem from './IconMediaItem';
import { ArrowRightIcon } from './icons/ArrowRight';


type Props = {
  className?: string;
};

const IconMediaItemPlay: React.FC<Props> = ({ className }) => {
  return (
    <IconMediaItem
      Icon={ ArrowRightIcon }
      className={ className }
    />
  );
};


export default IconMediaItemPlay;
