import * as React from 'react';

import { Popup } from '~ui/Popup';

import AccessErrorDialog from './AccessErrorDialog';
import CantPurchaseErrorDialog from './CantPurchaseErrorDialog';
import SomeErrorDialog from './SomeErrorDialog';
import * as styles from './styles.module.css';

type Props = Readonly<{
  error?: Object | string;
  access?: 'notAuthorized' | 'cantPurchase' | null;
}>;


const PlayerErrors: React.FC<Props> = (props: Props): JSX.Element | null => {
  if (!props.error && !props.access) {
    return null;
  }

  return (
    <Popup semiTransporent>
      <div className={ styles.container }>
        {
          props.access === 'notAuthorized' ? (
            <AccessErrorDialog />
          ) : props.access === 'cantPurchase' ? (
            <CantPurchaseErrorDialog />
          ) : !!props.error ? (
            <SomeErrorDialog error={ props.error } />
          ) : null
        }
      </div>
    </Popup>
  );
};


export default PlayerErrors;
