import { isNumber } from 'lodash';
import create, { GetState, SetState } from 'zustand';

import NavigationDirection from '~typings/NavigationDirection';


const findNavigatebleLineIndex = (
  focusY: number,
  direction: NavigationDirection.Up | NavigationDirection.Down,
  navigationItems: NavigationLine[],
): number | null => {
  const maxLimit = Math.max(navigationItems.length - 1, 0);
  const movedFocusY = (direction === NavigationDirection.Up) ?
    Math.max((focusY - 1), 0)
    :
    Math.min((focusY + 1), maxLimit);

  if (isNumber(navigationItems[movedFocusY]?.maxIndex)) {
    return movedFocusY;
  }

  if (movedFocusY === 0 || movedFocusY === maxLimit) {
    return null;
  }

  return findNavigatebleLineIndex(movedFocusY, direction, navigationItems);
};


export type NavigationLine = {
  maxIndex?: number;
  isTiled?: boolean;
  focusBlock?: string;
};

export type FocusPosition = { focusOn: number, focusedIndex: number[] };

export interface State {
  navigationOnLayer: string | null;
  focusHistory: {
    [key: string]: {
      navigationItems: Array<NavigationLine>;
      focusOn: number; // Y
      focusedIndex: number[]; // Xs
    },
  };

  getNavigationOnLayer: () => State['navigationOnLayer'];
  setNavigationOnLayer: (layerID: string | null) => void;
  getFocusPosition: (layerID: string) => FocusPosition;
  getFocusedIndex: (layerID: string, focusOn: number) => number;
  getFocusedIndexOnLayer: (layerID: string) => number;
  setFocusPosition: (
    layerID: string,
    focusPosition: [(number | null), (number | null)]
  ) => void;
  setFocusedIndex: (layerID: string, focusedIndex: number | null) => void;
  /**
   * делает тоже самое, что и setFocusedIndex, но не проверяет, активен ли изменяемый layer сейчас
   */
  setFocusedIndexInBehind: (layerID: string, focusedIndex: number) => void;
  getNavigationItems: (layerID: string) => Array<NavigationLine>;
  setNavigationItems: (
    layerID: string,
    navigationItems: Array<NavigationLine>
  ) => void;
  verticalNavigation: (
    direction: NavigationDirection.Up | NavigationDirection.Down,
    cb?: (direction: NavigationDirection) => void
  ) => void;
  horizontalNavigation: (
    direction: NavigationDirection.Left | NavigationDirection.Right,
    cb?: (direction: NavigationDirection) => void
  ) => void;
  flush: () => void;
}

const LayerNavigation = create<State>(
  (set: SetState<State>, get: GetState<State>) => ({
    navigationOnLayer: null,
    focusHistory: {},

    getNavigationOnLayer: () => get().navigationOnLayer,
    setNavigationOnLayer: (layerID: string | null) => set((draft: State) => {
      draft.navigationOnLayer = layerID;
    }),
    getFocusPosition: (layerID: string) => ({
      focusOn: get().focusHistory[layerID]?.focusOn ?? 0,
      focusedIndex: get().focusHistory[layerID]?.focusedIndex || [],
    }),
    getFocusedIndex: (layerID: string, focusOn: number) => (
      (get().focusHistory[layerID]?.focusedIndex || [])[focusOn] ?? 0
    ),
    getFocusedIndexOnLayer: (layerID: string) => (
      (get().focusHistory[layerID]?.focusedIndex || [])[get().focusHistory[layerID]?.focusOn ?? 0] ?? 0
    ),
    setFocusPosition: (layerID: string, focusPosition: [(number | null), (number | null)]) => set((draft: State) => {
      if (
        get().navigationOnLayer === layerID
        && get().focusHistory[layerID]?.navigationItems.length > 0
      ) {
        const [focusOn, focusedIndex] = focusPosition;
        if (focusOn !== null) {
          draft.focusHistory[layerID].focusOn = focusOn;
        }
        if (focusedIndex !== null) {
          draft.focusHistory[layerID].focusedIndex = get().focusHistory[layerID].focusedIndex
            .map((f, i) => (focusOn ?? get().focusHistory[layerID].focusOn) === i ? focusedIndex : f);
        }
      }
    }),
    setFocusedIndex: (layerID: string, focusedIndex: number | null) => set((draft: State) => {
      if (
        get().navigationOnLayer === layerID
        && get().focusHistory[layerID]?.navigationItems.length > 0
      ) {
        if (focusedIndex !== null) {
          draft.focusHistory[layerID].focusedIndex = get().focusHistory[layerID].focusedIndex
            .map((f, i) => get().focusHistory[layerID].focusOn === i ? focusedIndex : f);
        }
      }
    }),
    setFocusedIndexInBehind: (layerID: string, focusedIndex: number) => set((draft: State) => {
          draft.focusHistory[layerID].focusedIndex = get().focusHistory[layerID].focusedIndex
            .map((f, i) => get().focusHistory[layerID].focusOn === i ? focusedIndex : f);
    }),
    getNavigationItems: (layerID: string) => get().focusHistory[layerID]?.navigationItems,
    setNavigationItems: (layerID: string | null | undefined, navigationItems: Array<NavigationLine>) => set(
      (draft: State) => {
      if (layerID) {
        const focusOn = get().focusHistory[layerID]?.focusOn ?? 0;
        const navigationItemsBecomeLesser = navigationItems.length < focusOn;

        draft.focusHistory[layerID] = {
          navigationItems,
          focusOn: navigationItemsBecomeLesser ? 0 : focusOn,
          focusedIndex: navigationItems.map((_, i) => ((focusOn === i)
              ? (get().focusHistory[layerID]?.focusedIndex[focusOn] ?? 0)
              : 0
          )),
        };
      }
    }),
    verticalNavigation: (
      direction: NavigationDirection.Up | NavigationDirection.Down,
      cb?: (direction: NavigationDirection) => void
    ) => set((draft: State) => {
      const layerID = get().navigationOnLayer;
      if (!layerID) {
        return;
      }
      const navigationItems = get().focusHistory[layerID].navigationItems || [];
      if (!get().focusHistory[layerID] || navigationItems.length === 0) {
        return;
      }

      const focusOn = get().focusHistory[layerID].focusOn ?? 0;

      if (cb) {
        const nextIndex = (direction === NavigationDirection.Down) ?
          (focusOn + 1)
          :
          (focusOn - 1);
        if (nextIndex > (navigationItems.length - 1)) {
          cb(NavigationDirection.Down);

          return;
        }
        else if (nextIndex < 0) {
          cb(NavigationDirection.Up);

          return;
        }
      }

      const navigatebleLineIndex = findNavigatebleLineIndex(focusOn, direction, navigationItems);

      draft.focusHistory[layerID].focusOn = navigatebleLineIndex ?? focusOn;
    }),
    horizontalNavigation: (
      direction: NavigationDirection.Left | NavigationDirection.Right,
      cb?: (direction: NavigationDirection) => void
    ) => set((draft: State) => {
      const layerID = get().navigationOnLayer;
      if (!layerID) {
        return;
      }
      const navigationItems = get().focusHistory[layerID].navigationItems || [];
      if (!get().focusHistory[layerID] || navigationItems.length === 0) {
        return;
      }

      const focusOn = get().focusHistory[layerID].focusOn ?? 0;
      const focusedIndex = get().focusHistory[layerID].focusedIndex ?? 0;
      const focusX = focusedIndex[focusOn] ?? 0;

      const maxIndex = navigationItems[focusOn]?.maxIndex ?? 0;
      const nextIndex = (direction === NavigationDirection.Right) ?
        (focusX + 1)
        :
        (focusX - 1);

      if (cb) {
        if (nextIndex > maxIndex) {
          cb(NavigationDirection.Right);

          return;
        }
        else if (nextIndex < 0) {
          cb(NavigationDirection.Left);

          return;
        }
      }

      const newFocusX = (direction === NavigationDirection.Right) ?
        Math.min(nextIndex, (maxIndex ?? 0) - 1)
        :
        Math.max(nextIndex, 0);

      draft.focusHistory[layerID].focusedIndex[focusOn] = newFocusX;
    }),
    flush: () => set({
      navigationOnLayer: null,
      focusHistory: {},
    }, true),
  })
);


export default LayerNavigation;
