import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import CollectionPage from '~components/CollectionPage/CollectionPage';
import ContinueWatchingPage from '~components/CollectionPage/ContinueWatchingPage';
import FavoritesPage from '~components/CollectionPage/FavoritesPage';
import RecommendationPage from '~components/CollectionPage/RecommendationPage';
import { BlockType } from '~typings/Block';

const CollectionScreen: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={ path }
      >
        <div>404</div>
      </Route>
      <Route
        path={ `${path}/${BlockType.FavoritesBlock}` }
      >
        <FavoritesPage />
      </Route>
      <Route
        path={ `${path}/${BlockType.ContinueWatchingBlock}` }
      >
        <ContinueWatchingPage />
      </Route>
      <Route
        path={ `${path}/${BlockType.RecommendationsBlock}` }
      >
        <RecommendationPage />
      </Route>
      <Route
        path={ `${path}/:collectionID` }
      >
        <CollectionPage />
      </Route>
    </Switch>
  );
};


CollectionScreen.displayName = 'CollectionScreen';


export {
  CollectionScreen
};

export default CollectionScreen;
