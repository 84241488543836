// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--yWeDo {\n  width: 85.9375vw;\n  height: 56.25vw;\n  margin: 0 auto;\n  color: rgba(243, 243, 247, 1);\n}\n\n.content--vQFHy {\n  padding: 7.8125vw 3.90625vw;\n  color: rgba(243, 243, 247, 1);\n  font-size: 1.1em;\n  line-height: 1.1em;\n  text-align: left;\n  white-space: normal;\n}\n\n.content--vQFHy h1,\n.content--vQFHy h2 {\n  font-size: 1.1em;\n  margin-bottom: 1em;\n}\n\n.content--vQFHy p {\n  margin: 0 0 1em;\n}\n\n.content--vQFHy a {\n  color: green;\n}\n\n.scrollbar--fTGha {\n  position: fixed;\n  top: 3.90625vw;\n  bottom: 3.90625vw;\n  right: 2.03125vw;\n  -webkit-transition-duration: 200ms;\n          transition-duration: 200ms;\n}\n\n.spinner--mM6ji {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/DocViewPopup/DocViewPopup.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,6BAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,6BAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;;EAEE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,kCAA0B;UAA1B,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT","sourcesContent":[".wrapper {\n  width: 85.9375vw;\n  height: 56.25vw;\n  margin: 0 auto;\n  color: $gw_10;\n}\n\n.content {\n  padding: 7.8125vw 3.90625vw;\n  color: $gw_10;\n  font-size: 1.1em;\n  line-height: 1.1em;\n  text-align: left;\n  white-space: normal;\n}\n\n.content h1,\n.content h2 {\n  font-size: 1.1em;\n  margin-bottom: 1em;\n}\n\n.content p {\n  margin: 0 0 1em;\n}\n\n.content a {\n  color: green;\n}\n\n.scrollbar {\n  position: fixed;\n  top: 3.90625vw;\n  bottom: 3.90625vw;\n  right: 2.03125vw;\n  transition-duration: 200ms;\n}\n\n.spinner {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = "wrapper--yWeDo";
export var content = "content--vQFHy";
export var scrollbar = "scrollbar--fTGha";
export var spinner = "spinner--mM6ji";
export default ___CSS_LOADER_EXPORT___;
