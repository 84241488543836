import * as React from 'react';
import { InjectedIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import AccountEditField from '~components/AccountEditField/component';
import { getCurrentStep, getHeaderText, setCurrentStep, Step } from '~components/AccountEditField/Password.helpers';
import FieldSet from '~components/Fieldset/components';
import LightLoader from '~components/LightLoader';
import PasswRecoveryButton from '~components/PasswRecoveryButton';
import { Data, ErrorCode,useChangePassword, useCheckCurrentPassword } from '~hooks/fetch/usePassword';
import {INVALID_PASSWORD_FORMAT,PASSWORD_NOT_EQUALS,TOO_MANY_REQUESTS,WRONG_PASSWORD } from '~localization';
import AccountInfo, { AccountField as Field } from '~typings/AccountInfo';
import NavigationDirection from '~typings/NavigationDirection';

enum FocusOn {
  Input = 'input',
  PasswRecoveryButton = 'passw_recovery_button'
}

type Props = Readonly<{
  accountInfo: AccountInfo;
  intl: InjectedIntl;
}>;


const AccountPasswordChange: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.Input);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string | undefined>(undefined);
  const [currentPassword, setCurrentPassword] = React.useState<string | null>(null);
  const [newPassword, setNewPassword] = React.useState<string | null>(null);
  const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState<string | null>(null);
  const data = useCheckCurrentPassword(
    (currentPassword !== null && getCurrentStep(history) === Step.CurrentPassword) ?
      currentPassword
      :
      undefined
  );
  const isNeedChangePassword = (
    getCurrentStep(history) === Step.ConfirmNewPassword &&
    currentPassword !== null && newPassword !== null && newPassword === newPasswordConfirmation
  );
  const changePasswordData = useChangePassword(
    isNeedChangePassword ? currentPassword as string : undefined,
    isNeedChangePassword ? newPassword as string : undefined,
  );
  const checkCurrentPassword = data.error as Data;
  const handleSubmit = (value: string) => {
    setIsProcessing(true);
    if (!value) {
      setErrorText(props.intl.formatMessage({ id: INVALID_PASSWORD_FORMAT }));
      setIsProcessing(false);
    } else {
      switch (getCurrentStep(history)) {
        case Step.CurrentPassword:
          setCurrentPassword(value);
          break;
        case Step.NewPassword:
          setNewPassword(value);
          break;
        case Step.ConfirmNewPassword:
          setNewPasswordConfirmation(value);
          break;
      }
    }
  };

  React.useEffect(() => {
    setErrorText(undefined);
  }, [getCurrentStep(history)]);

  React.useEffect(() => {
    if (checkCurrentPassword && checkCurrentPassword.errors && checkCurrentPassword.errors.length !== 0) {
      if (checkCurrentPassword.errors[0].code === ErrorCode.InvalidPassword) {
        setErrorText(props.intl.formatMessage({ id: WRONG_PASSWORD }));
        setCurrentPassword(null);
      } else if (checkCurrentPassword.errors[0].code === ErrorCode.MissingNewPassword) {
        setCurrentStep(Step.NewPassword, history);
      }

      setIsProcessing(false);
    }
  }, [checkCurrentPassword]);

  React.useEffect(() => {
    if (getCurrentStep(history) === Step.NewPassword && newPassword !== null) {
      setCurrentStep(Step.ConfirmNewPassword, history);
      setIsProcessing(false);
    }
    if (
      getCurrentStep(history) === Step.ConfirmNewPassword &&
      newPassword !== null && newPasswordConfirmation !== null
    ) {
      if (newPassword !== newPasswordConfirmation) {
        setErrorText(props.intl.formatMessage({ id: PASSWORD_NOT_EQUALS }));
      }
      setIsProcessing(false);
    }
  }, [getCurrentStep(history), newPassword, newPasswordConfirmation]);

  React.useEffect(() => {
    if (changePasswordData.isSuccess) {
      history.goBack();
    }
  }, [changePasswordData.isSuccess]);

  const isNeedClearInputValue = (
    (getCurrentStep(history) === Step.NewPassword && currentPassword !== null && newPassword === null) ||
    (getCurrentStep(history) === Step.ConfirmNewPassword && newPassword !== null && newPasswordConfirmation === null)
  );

  const isStepsChangePassword = (getCurrentStep(history) === Step.NewPassword 
  || getCurrentStep(history) === Step.ConfirmNewPassword);

  const leaveHandler = (dir: NavigationDirection) => {
    if (dir === NavigationDirection.Up) {
      setFocusOn(FocusOn.Input);
    } else if (dir === NavigationDirection.Down) {
      setFocusOn(FocusOn.PasswRecoveryButton);
    } 
    if (getCurrentStep(history) === Step.NewPassword || getCurrentStep(history) === Step.ConfirmNewPassword) {
      if(dir === NavigationDirection.Down) {
        setFocusOn(FocusOn.Input);
      }
    }
  };

  const recoveryButtonErrorHandler = (error) => {
    if (error.errors[0].code === 'too_many_requests') {
      setErrorText(props.intl.formatMessage({ id: TOO_MANY_REQUESTS }));
    }
  };

  const footerElement = (
    !(isStepsChangePassword) ? 
    (<PasswRecoveryButton
      isFocused={ focusOn === FocusOn.PasswRecoveryButton }
      onLeave={ leaveHandler }
      onFocusLost={ () => setFocusOn(FocusOn.Input) }
      setIsProcessing={ setIsProcessing }
      onError={ recoveryButtonErrorHandler }
    />)
    :
    undefined
  );

  return (
    <>
      <FieldSet
        headerText={ getHeaderText(getCurrentStep(history), props.intl) }
        errorText={ errorText }
        footer={ !isNeedClearInputValue ? footerElement : undefined }
      >
          <AccountEditField
            isFocused={ focusOn === FocusOn.Input }
            isNeedClear={ isNeedClearInputValue }
            field={ Field.Password }
            accountInfo={ props.accountInfo }
            onSubmit={ handleSubmit }
            onLeave={ leaveHandler }
            intl={ props.intl }
          />
      </FieldSet>
      { isProcessing && <LightLoader /> }
    </>
  );
};


export default AccountPasswordChange;
