/* eslint no-console: 0 */
import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { UNSUBSCRIBE } from '~localization';
import Button from '~ui/button';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isFocused: boolean;
  subscriptionID: string;
  productID: string;
  intl: InjectedIntl;
}>;


const UnSubscribeButton: React.FC<Props> = (props) => {
  const history = useHistory();

  const handleUnSubscribe = () => {
    history.push(`/products/unsubscribe/${props.subscriptionID}`)
  };

  return (
    <Button
      isFocused={ props.isFocused }
      className={ cn(styles.button, {
        [styles.isFocused]: props.isFocused,
      }) }
      onClick={ handleUnSubscribe }
    >
      {
        props.intl.formatMessage({ id: UNSUBSCRIBE })
      }
    </Button>
  );
};


export default React.memo(UnSubscribeButton);
