import { FBtn, FBtnHandler } from '../types';

const commonDownButtonHandler: FBtnHandler = ({
  isConfirmBtnActive,
  isHiddenBtnActive,
  focuserEvent,
  setFocus,
}) => {
  const stopFocuser = () => {
    focuserEvent.stop();
    focuserEvent.stopNativeEvent();
  };

  if (isHiddenBtnActive) {
    setFocus({
      x: 10,
      y: 3,
    });
    stopFocuser();
    return;
  }

  if (isConfirmBtnActive) {
    setFocus({
      x: 11,
      y: 3,
    });
    stopFocuser();
    return;
  }
};

const backspaceButtonHandler: FBtnHandler = ({
  isConfirmBtnActive,
  isHiddenBtnActive,
  focuserEvent,
  setFocus,
}) => {
  const stopFocuser = () => {
    focuserEvent.stop();
    focuserEvent.stopNativeEvent();
  };

  if (isConfirmBtnActive) {
    setFocus({
      x: 11,
      y: 3,
    });
    stopFocuser();
    return;
  }

  if (isHiddenBtnActive) {
    setFocus({
      x: 10,
      y: 3,
    });
    stopFocuser();
    return;
  }
};

export const KeyboardNumbersLayout: FBtn[] = [
  // Первая линия
  {
    id: '1',
    coord: {
      x: 0,
      y: 0,
    },
    onLeft: 'skip',
    onUp: 'skip',
    onDown: commonDownButtonHandler,
  },
  {
    id: '2',
    coord: {
      x: 1,
      y: 0,
    },
    onUp: 'skip',
    onDown: commonDownButtonHandler,
  },
  {
    id: '3',
    coord: {
      x: 2,
      y: 0,
    },
    onUp: 'skip',
    onDown: commonDownButtonHandler,
  },
  {
    id: '4',
    coord: {
      x: 3,
      y: 0,
    },
    onUp: 'skip',
    onDown: commonDownButtonHandler,
  },
  {
    id: '5',
    coord: {
      x: 4,
      y: 0,
    },
    onUp: 'skip',
    onDown: commonDownButtonHandler,
  },
  {
    id: '6',
    coord: {
      x: 5,
      y: 0,
    },
    onUp: 'skip',
    onDown: commonDownButtonHandler,
  },
  {
    id: '7',
    coord: {
      x: 6,
      y: 0,
    },
    onUp: 'skip',
    onDown: commonDownButtonHandler,
  },
  {
    id: '8',
    coord: {
      x: 7,
      y: 0,
    },
    onUp: 'skip',
    onDown: commonDownButtonHandler,
  },
  {
    id: '9',
    coord: {
      x: 8,
      y: 0,
    },
    onUp: 'skip',
    onDown: commonDownButtonHandler,
  },
  {
    id: '0',
    coord: {
      x: 9,
      y: 0,
    },
    onUp: 'skip',
    onDown: commonDownButtonHandler,
    onRight: {
      x: 12,
      y: 0,
    }
  },
  {
    id: ' ',
    action: 'space',
    coord: {
      x: 10,
      y: 0,
    },
    alwaysDisabled: true,
  },
  {
    id: 'switch_to_symbols',
    action: 'switch_to_symbols',
    coord: {
      x: 11,
      y: 0,
    },
    alwaysDisabled: true,
  },
  {
    id: 'backspace',
    action: 'backspace',
    coord: {
      x: 12,
      y: 0,
    },
    onUp: 'skip',
    onRight: 'skip',
    onLeft: {
      x: 9,
      y: 0,
    },
    onDown: backspaceButtonHandler,
  },

  // Вторая линия
  {
    id: 'q',
    coord: {
      x: 0,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 'w',
    coord: {
      x: 1,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 'e',
    coord: {
      x: 2,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 'r',
    coord: {
      x: 3,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 't',
    coord: {
      x: 4,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 'y',
    coord: {
      x: 5,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 'u',
    coord: {
      x: 6,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 'i',
    coord: {
      x: 7,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 'o',
    coord: {
      x: 8,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 'p',
    coord: {
      x: 9,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: '@',
    coord: {
      x: 10,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 'switch_to_ru',
    action: 'switch_to_ru',
    coord: {
      x: 11,
      y: 1,
    },
    alwaysDisabled: true,
  },
  {
    id: 'switch_to_uppercase',
    action: 'switch_to_uppercase',
    coord: {
      x: 12,
      y: 1,
    },
    alwaysDisabled: true,
  },

  // Третья линия
  {
    id: 'a',
    coord: {
      x: 0,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: 's',
    coord: {
      x: 1,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: 'd',
    coord: {
      x: 2,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: 'f',
    coord: {
      x: 3,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: 'g',
    coord: {
      x: 4,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: 'h',
    coord: {
      x: 5,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: 'j',
    coord: {
      x: 6,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: 'k',
    coord: {
      x: 7,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: 'l',
    coord: {
      x: 8,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: '_',
    coord: {
      x: 9,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: '+',
    coord: {
      x: 10,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: '.ru',
    coord: {
      x: 11,
      y: 2,
    },
    alwaysDisabled: true,
  },
  {
    id: '.com',
    coord: {
      x: 12,
      y: 2,
    },
    alwaysDisabled: true,
  },

  // Четвертая линия
  {
    id: 'z',
    coord: {
      x: 0,
      y: 3,
    },
    alwaysDisabled: true,
  },
  {
    id: 'x',
    coord: {
      x: 1,
      y: 3,
    },
    alwaysDisabled: true,
  },
  {
    id: 'c',
    coord: {
      x: 2,
      y: 3,
    },
    alwaysDisabled: true,
  },
  {
    id: 'v',
    coord: {
      x: 3,
      y: 3,
    },
    alwaysDisabled: true,
  },
  {
    id: 'b',
    coord: {
      x: 4,
      y: 3,
    },
    alwaysDisabled: true,
  },
  {
    id: 'n',
    coord: {
      x: 5,
      y: 3,
    },
    alwaysDisabled: true,
  },
  {
    id: 'm',
    coord: {
      x: 6,
      y: 3,
    },
    alwaysDisabled: true,
  },
  {
    id: ',',
    coord: {
      x: 7,
      y: 3,
    },
    alwaysDisabled: true,
  },
  {
    id: '.',
    coord: {
      x: 8,
      y: 3,
    },
    alwaysDisabled: true,
  },
  {
    id: '-',
    coord: {
      x: 9,
      y: 3,
    },
    alwaysDisabled: true,
  },
  {
    id: 'hide/show',
    action: 'hide/show',
    coord: {
      x: 10,
      y: 3,
    },
    onDown: 'skip',
    onUp: {
      x: 9,
      y: 0,
    },
    onRight: ({ isConfirmBtnActive, focuserEvent, setFocus }) => {
      if (isConfirmBtnActive) {
        setFocus({
          x: 11,
          y: 3,
        });
        focuserEvent.stop();
        focuserEvent.stopNativeEvent();
      }
    },
    onLeft: 'skip',
  },
  {
    id: 'ok_button',
    action: 'confirm',
    coord: {
      x: 11,
      y: 3,
    },
    onRight: 'skip',
    onDown: 'skip',
    onUp: {
      x: 12,
      y: 0,
    },
    isLarge: true,
    onLeft: ({ isHiddenBtnActive, setFocus, focuserEvent }) => {
      if (isHiddenBtnActive) {
        setFocus({
          x: 10,
          y: 3,
        });
        focuserEvent.stop();
        focuserEvent.stopNativeEvent();
        return;
      }
    },
  },
];


Object.freeze(KeyboardNumbersLayout);
