
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { NO_EPG } from '~localization';

import * as styles from './styles.module.css';


type Props = Readonly<{
  noEvents?: boolean;
  offsetShift?: number;
  intl: InjectedIntl;
}>;


const NoEPGText: React.FC<Props> = (props: Props) => {
  if (props.noEvents) {
    const style: React.CSSProperties = {};

    if (props.offsetShift) {
      style.transform = `translate3d(0, ${props.offsetShift}vw, 0)`;
    }

    return (
      <div
        className={ styles.noEPG }
        style={ style }
      >
        { props.intl.formatMessage({ id: NO_EPG }) }
      </div>
    );
  }

  return null;
};


export default React.memo(injectIntl(NoEPGText));
