import focuser, { FocuserKeyHandler } from '@focuser';
import React from 'react';

import { Typography } from '~app/ui-kit/Typography';
import { usePageManagerPointersBlocker } from '~components/PageManager';
import { useDispatchLaunchDone } from '~hooks/useDispatchLaunchDone';
import { useIsMenuOpen, useMenuOpener } from '~hooks/useMenuOpener';
import { useOldNavigationBlocker } from '~hooks/useOldNavigationBlocker';
import { SliderVerticableVirtualNotMemo } from '~ui/SliderVerticable';

import { getTestCases,TestCase } from '../TestableComponentsList';
import * as styles from './ComponentTestingPagePicker.module.css';

export type Props = {
  onSelect: (testCase: TestCase) => void;
};

const ComponentTestingPagePicker$: React.FC<Props> = ({ onSelect }) => {
  const { openMenu } = useMenuOpener();
  const allowNavigation = !useIsMenuOpen();

  const openMenuAndStopPropagation: FocuserKeyHandler = (e) => {
    e.stop();
    e.stopNativeEvent();
    openMenu();
  };

  /**
   * Отключаем legacy навигацию
   */
  useOldNavigationBlocker(allowNavigation);
  usePageManagerPointersBlocker(allowNavigation);
  useDispatchLaunchDone();

  const [testCases] = React.useState(getTestCases);
  const [focusedIdx, setFocusedIdx] = React.useState(0);

  const onKeyDown: FocuserKeyHandler = (e) => {
    if (focusedIdx < testCases.length - 1) {
      setFocusedIdx(focusedIdx + 1);
      e.stop();
    }
  };

  const onKeyUp: FocuserKeyHandler = (e) => {
    if (focusedIdx > 0) {
      setFocusedIdx(focusedIdx - 1);
      e.stop();
    } else {
      openMenuAndStopPropagation(e);
    }
  };

  const isPointerDownAvailable = allowNavigation && focusedIdx < testCases.length - 1;
  const isPointerUpAvailable = allowNavigation && focusedIdx > 0;

  const renderComponentCard = (idx: number) => {
    const componentInfo = testCases[idx];

    if (!componentInfo) {
      return null;
    }

    return (
      <focuser.FocusableBlock
        key={ idx }
        isFocused={ idx === focusedIdx }
        className={ styles.componentCard }
        focusedClassName={ styles.componentCardFocused }
        emitForceFocusOnHover
        onForceFocus={ () => setFocusedIdx(idx) }
        onClick={ () => onSelect(componentInfo) }
      >
        <Typography
          className={ styles.title }
          variant="subtitle1_medium"
        >
          {componentInfo.name}
        </Typography>
        <Typography
          className={ styles.description }
          variant="overline_semibold_uppercase"
        >
          {componentInfo.description}
        </Typography>
      </focuser.FocusableBlock>
    );
  };

  return (
    <focuser.FocusableBlock
      isFocused={ allowNavigation }
      onKeyUp={ onKeyUp }
      onKeyDown={ onKeyDown }
      onKeyReturn={ openMenuAndStopPropagation }
      isPointerDownAvailable={ isPointerDownAvailable }
      isPointerUpAvailable={ isPointerUpAvailable }
      returnButtonType={ 'menu' }
      className={ styles.page }
      noNeedForceFocus
    >
      <Typography variant="h3_bold">Тест кейсы</Typography>
      <SliderVerticableVirtualNotMemo
        renderChild={ renderComponentCard }
        childsCount={ testCases.length }
        focusedIndex={ focusedIdx }
        itemHeight={ 9.0625 }
        wrapperClassName={ styles.sliderWrapper }
        inVW
        maxInDOM={ 12 }
      />
    </focuser.FocusableBlock>
  );
};

export const ComponentTestingPagePicker = React.memo(ComponentTestingPagePicker$);
