import * as cn from 'classnames';
import * as React from 'react';

import { ItemForRender } from '~components/Slider/items/items.helpers';
import useVisibleRange from '~components/Slider/items/useVisibleRange';
import usePrevious from '~hooks/usePrevious';
import {
  getImageSquareWidth,
  getMediaItemBannerWidth,
  getMediaItemPosterWidth,
  getSpaceBetweenSlides,
} from '~lib/SizesInPX';
import uuid from '~lib/uuid';
import { DataItem, MediaItemType } from '~typings/DataItem';
import ImageType from '~typings/ImageType';
import ItemObject from '~typings/ItemObject';
import MediaItemDimension from '~typings/MediaItemDimension';
import NavigationDirection from '~typings/NavigationDirection';
import ResponseMany from '~typings/ResponseMany';
import { MediaItem } from '~ui/MediaItem';
import * as mediaItemStyles from '~ui/MediaItem/styles.module.css';


type Props = Readonly<{
  UUID: string[];
  dimension: MediaItemDimension;
  items: ResponseMany<MediaItemType[]>;
  focusedIndex: number;
  isFocusedBlock: boolean;
  isVisible?: boolean;
  withName?: boolean;
  withGenres?: boolean;
  alwaysShowName?: boolean;
  withProgress?: boolean;
  showAsSeries?: boolean;
  onClick?: (item: DataItem) => void;
  cardConfigID?: string;
  deeplink?: string;
}>;


const SliderItems: React.FC<Props> = (props: Props): any => {
  const [direction, setDirection] = React.useState<NavigationDirection.Left | NavigationDirection.Right | null>(null);
  const prevFocusedIndex = usePrevious<number>(props.focusedIndex);
  const { from, to } = useVisibleRange({
    direction,
    itemsCount: props.UUID.length,
    dimension: props.dimension,
    isFocusedBlock: props.isFocusedBlock,
    focusedIndex: props.focusedIndex,
  });

  React.useEffect(() => {
    if (prevFocusedIndex !== null && prevFocusedIndex !== props.focusedIndex) {
      setDirection((prevFocusedIndex < props.focusedIndex) ? NavigationDirection.Right : NavigationDirection.Left);
    }
  }, [prevFocusedIndex, props.focusedIndex]);

  const itemsForRender: ItemForRender[] = [];

  for (let index = from; index <= to; index += 1) {
    const isDeeplinkItemRoRender = Boolean(props.deeplink) && index === props.UUID.length - 1;
    if (!props.items.data[index] && !isDeeplinkItemRoRender) {
      break
    }
    itemsForRender.push({
      key: props.UUID[index] || uuid(index),
      index,
      item: isDeeplinkItemRoRender
        ? {
          id: props.UUID[index],
          object: ItemObject.SeeAll,
          deeplink: props.deeplink!,
        }
        : props.items.data[index],
    })
    ;
  }

  const width = (
    (props.dimension === ImageType.POSTER || props.dimension === ImageType.LOGO_SMALL)
      ? getMediaItemPosterWidth() + getSpaceBetweenSlides()
      : (props.dimension === ImageType.Card1x1)
        ? getImageSquareWidth() + getSpaceBetweenSlides()
        : getMediaItemBannerWidth() + getSpaceBetweenSlides()
  );
  const mediaItemType = (props.dimension === ImageType.POSTER) ?
    ImageType.POSTER
    : props.dimension === ImageType.LOGO_SMALL ?
      ImageType.LOGO_SMALL
      :
      (props.dimension === ImageType.Card1x1) ? ImageType.Card1x1 : ImageType.BANNER;

  // if (props.isVisible === false) { return null; }

  return (
    itemsForRender.map((render: ItemForRender) => {
      const isFocused = (
        props.isFocusedBlock &&
        props.focusedIndex === render.index
      );

      return (
        <MediaItem
          isSliderView
          key={ `SliderItem-${ render.key }` }
          itemKey={ render.key }
          left={ (render.index * width) }
          className={
            cn(
              mediaItemStyles.mediaItem,
              mediaItemStyles[render?.item?.object || ''],
              mediaItemStyles[mediaItemType],
              {
                'focusedNavigationNode': isFocused,
                [mediaItemStyles.focused]: isFocused,
                [mediaItemStyles.seeAll]: render?.item?.object === ItemObject.SeeAll,
              }
            )
          }
          withProgress={ props.withProgress }
          dimension={ props.dimension }
          isFocused={ isFocused }
          item={ render.item }
          cardConfigID={ props.cardConfigID }
          showAsSeries={ props.showAsSeries }
          onClick={ props.onClick }
          withName={ props.withName }
          withGenres={ props.withGenres }
          alwaysShowName={ props.alwaysShowName }
        />
      );
    })
  );
};


SliderItems.displayName = 'SliderItems';


export default React.memo(SliderItems);
