import * as cn from 'classnames';
import * as React from 'react';

import defaultImage from '~components/ProfileImage/defaultProfileImage.svg';
import { useUpdatableImage } from '~hooks/useUpdatableImage';
import { fillImageTemplateBySizes } from '~lib/image';
import Profile from '~typings/Profile';
import { IconBase } from '~ui/Icon';

import * as styles from './styles.modules.css';

type IconProps = {
  className?: string;
};

type Props = Readonly<{
  isFocused?: boolean;
  profile?: Profile;
  OverflowIcon?: React.FC<IconProps>;
}>;


export const ProfileImageOnProfilesList: React.FC<Props> = (props: Props) => {
  const {
    profile,
    OverflowIcon,
    isFocused,
  } = props;

  const curAvatar = profile?.avatar;
  const imgUrlTemplate = curAvatar?.image?.url_template;

  const urlTemplatePrepared = imgUrlTemplate ?
    fillImageTemplateBySizes(imgUrlTemplate)
    : null;

  const { data, error } = useUpdatableImage({ url: urlTemplatePrepared });

  const finalUrl = React.useMemo(() => {

    if(!urlTemplatePrepared){
      return defaultImage;
    }

    if(error){
      return defaultImage;
    }

    return data;
  }, [data, error, urlTemplatePrepared]);


  return (
    <div className={ styles.wrapper }>
      <div
        style={ finalUrl ? { backgroundImage: `url(${finalUrl})` } : undefined }
        className={ cn(styles.profileImage, {[styles.profileImageFocused]: isFocused}) }
      >
      </div>
      <div className={ cn(styles.border, { [styles.borderFocused]: isFocused }) }></div>
      {
          (!!OverflowIcon) && (
            <IconBase
              Icon={ OverflowIcon }
              classNames={ { wrapper: cn(styles.icon, styles.selectedOverflowIcon) } }
            />
          )
        }
    </div>
  );
};
