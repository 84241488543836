import { compact, findIndex, last } from 'lodash';
import { useCSSAnimations } from "platform/desktop";

import MenuItem from '~typings/MenuItem';
import NavigationDirection from '~typings/NavigationDirection';

type LineFocusInfo = {
  x: number;
  lineIndex: number;
};

type HorizontalDirection = Readonly<NavigationDirection.Left | NavigationDirection.Right>;

type VerticalDirection = Readonly<NavigationDirection.Up | NavigationDirection.Down>;

const isCSSAnimationAvailable = useCSSAnimations && (window as any).Modernizr.csstransforms3d;

const getCurrentFocusedLineInfo = (breadCrumbs: LineFocusInfo[]): LineFocusInfo => (
  last(breadCrumbs as LineFocusInfo[]) as LineFocusInfo
);

// Detect Current Focused Line Items by Index
const getCurrentLine = (
  breadCrumbs: LineFocusInfo[],
  currentLineFocusInfo: LineFocusInfo,
  rootLine: MenuItem[]
): MenuItem[] => {
  try {
    if (currentLineFocusInfo.lineIndex === 0) {
      return rootLine;
    }

    let res: MenuItem[] = rootLine.slice(0);

    for (let i = 0; i < currentLineFocusInfo.lineIndex; i += 1) {
      res = res[breadCrumbs[i].x]?.subMenu || [];
    }

    return res;
  } catch (ignore) {
    return rootLine;
  }
};

type CalculateBreadCrumbsProps = Readonly<{
  direction: HorizontalDirection | VerticalDirection;
  breadCrumbs: LineFocusInfo[];
  currentLineFocusInfo: LineFocusInfo,
  rootLine: MenuItem[];
}>;

const isMenuItemHaveSubMenu = (
  breadCrumbs: LineFocusInfo[],
  currentLineFocusInfo: LineFocusInfo,
  rootLine: MenuItem[]
): boolean => {
  const line = getCurrentLine(breadCrumbs, currentLineFocusInfo, rootLine);
  const focusedMenuItem = line[currentLineFocusInfo.x];

  return (!!focusedMenuItem?.subMenu && !!focusedMenuItem.isSubMenuInNavMenu);
};

const calculateBreadCrumbsWithHorizontalMove = (props: CalculateBreadCrumbsProps): LineFocusInfo[] => {
  const currentLine = getCurrentLine(props.breadCrumbs, props.currentLineFocusInfo, props.rootLine);

  const x = (props.direction === NavigationDirection.Right
      ? props.currentLineFocusInfo.x + 1
      : props.currentLineFocusInfo.x - 1
  ) % currentLine.length;

  const fixedX = x < 0 ? x + currentLine.length : x;

  return props.breadCrumbs.map((bc, i) => i === props.breadCrumbs.length - 1 ? { ...bc, x: fixedX } : bc);
};

const calculateBreadCrumbsWithVerticalMove = (props: CalculateBreadCrumbsProps): LineFocusInfo[] => {
  const newBreadCrumbState: LineFocusInfo[] = props.breadCrumbs.slice(0);

  if (props.direction === NavigationDirection.Down) {
    const isFocusedItemHaveSubMenu = isMenuItemHaveSubMenu(
      props.breadCrumbs,
      props.currentLineFocusInfo,
      props.rootLine,
    );

    if (isFocusedItemHaveSubMenu) {
      newBreadCrumbState.push({
        x: 0,
        lineIndex: props.breadCrumbs.length,
      });
    }
  }
  else if (newBreadCrumbState.length !== 1) {
    newBreadCrumbState.pop();
  }

  return newBreadCrumbState;
};

// Calculate new Value for BreadCrumbs State
const calculateBreadCrumbs = (props: CalculateBreadCrumbsProps): LineFocusInfo[] => {
  if (
    props.direction === NavigationDirection.Up
    || props.direction === NavigationDirection.Down
  ) {
    return calculateBreadCrumbsWithVerticalMove(props);
  }

  return calculateBreadCrumbsWithHorizontalMove(props);
};

const getPagesBreadCrumbs = (id: string, menuItems: MenuItem[], _lineIndex = 0, _breadCrumbs = []) => {
  const index = findIndex(menuItems, ['id', id]);

  if (index !== -1) {
    return [..._breadCrumbs, { x: index, lineIndex: _lineIndex }];
  }
  else if (index === -1 && _breadCrumbs.length !== 0) {
    return null;
  }

  for (let i = 0; i < menuItems.length; i++) {
    const item = menuItems[i];

    if (!item?.subMenu) continue;

    const brdCbs = getPagesBreadCrumbs(id, item.subMenu, _lineIndex + 1, _breadCrumbs);

    if (brdCbs) {
      if (item.isSubMenuInNavMenu) {
        return [{ x: i, lineIndex: _lineIndex }, ...brdCbs];
      }
      else {
        return [{ x: i, lineIndex: _lineIndex }];
      }
    }

    if (brdCbs === null) return [{ x: i, lineIndex: _lineIndex }];
  }
}

const getBreadCrumbsByPageUrl = (
  pathname: string,
  menuItems: MenuItem[],
  mainPageId?: string
): LineFocusInfo[] => {
  const defaultValue = [{ x: 0, lineIndex: 0 }];
  const pathNameItems = compact(pathname.split('/'));
  const rootPathItem = pathNameItems[0];
  const lastPathItem = pathNameItems[(pathNameItems.length - 1)];

  if (pathNameItems && pathNameItems.length) {
    switch (rootPathItem) {
      case 'page': {
        if (mainPageId === pathNameItems[1]) {
          return defaultValue;
        }

        return getPagesBreadCrumbs(pathNameItems[1], menuItems);

        break;
      }
      default:
        return getPagesBreadCrumbs(lastPathItem, menuItems) || defaultValue;
    }
  }

  return defaultValue;
}

const getLineOptions = (
  index: number,
  items: MenuItem[],
  breadCrumbs: LineFocusInfo[],
) => {

  const focusInfo = breadCrumbs[index];
  const subMenu = focusInfo && (items[focusInfo.x]?.isSubMenuInNavMenu === true) ?
    items[focusInfo.x]?.subMenu
    : undefined;

  const isHideOnTop = focusInfo && (focusInfo.lineIndex < (breadCrumbs.length - 1));

  return ({
    subMenu,
    focusInfo: focusInfo || { x: 0, lineIndex: index },
    isHideOnTop,
    isCSSAnimationAvailable,
    isFocused: breadCrumbs.length === (index + 1),
  });
};

export {
  calculateBreadCrumbs,
  getBreadCrumbsByPageUrl,
  getCurrentFocusedLineInfo,
  getCurrentLine,
  getLineOptions,
  HorizontalDirection,
  isMenuItemHaveSubMenu,
  LineFocusInfo,
  VerticalDirection,
};
