import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ScrollableSliderBlock from '~components/Slider/scrollable';
import { getSpaceBetweenSlides } from '~lib/SizesInPX';
import { ROLE_ACTOR } from '~localization';
import CastMember from '~typings/CastMember';

import ActorItem from './ActorItem/components';
import * as styles from './styles.module.css';

type Props = {
  items: CastMember[];
  isFocused: boolean;
  focusedActor: number;
}

/**
 * @deprecated
 * Устаревший компонент, остался только для Event-а (MediaItemDetailsEvent.tsx)
 * Нужно будет заменить на ActorsBlockFocuser, когда будем приводить к дизайну страницу описания для Event-а
 */
const ActorsBlock: React.FC<Props> = (props: Props): React.ReactElement | null => {
  const slideWidth = (153 + getSpaceBetweenSlides());

  return (
    <>
     {props.items.length > 0 ?
      <>
        <p className={ styles.title } >
          <FormattedMessage id={ ROLE_ACTOR } />
        </p>
        <div className={ styles.blockWrap }>
          { props.items.map((actor, i) => {
            return (
              <ScrollableSliderBlock
                focusedIndex={ props.focusedActor }
                slideWidth={ slideWidth }
                key={ i }
              >
                <ActorItem
                  actor={ actor }
                  isFocused={ props.isFocused && props.focusedActor === i }
                  key={ `Actor${i}` }
                />
              </ScrollableSliderBlock>
            )
          })
          }
        </div>
      </>
      :
        null
      }
    </>
  )
}

export default ActorsBlock;
