// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".isolationContainer--tjIhq {\n  width: 100vw;\n  height: 100vh;\n}\n\n.descriptionShowPopup--jaonH {\n  /* 70px 412px 42px 88px */\n  margin: 5.46875vw 32.1875vw 3.28125vw 6.875vw;\n\n  /* 608px */\n  height: 47.5vw;\n}\n\n.title--i90Pv {\n  /* 30px */\n  margin-bottom: 2.34375vw;\n}\n\n.description--bSrdV {\n  color: rgba(223, 223, 228, 1);\n}\n\n.scrollbar--wJyQx {\n  position: fixed;\n  z-index: 2;\n  top: 3.90625vw;\n  bottom: 3.90625vw;\n  right: 2.03125vw;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/components/CardCollectionContent/components/DescriptionShowPopup/DescriptionShowPopup.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,6CAA6C;;EAE7C,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,wBAAwB;AAC1B;;AAEA;EACE,6BAAa;AACf;;AAEA;EACE,eAAe;EACf,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".isolationContainer {\n  width: 100vw;\n  height: 100vh;\n}\n\n.descriptionShowPopup {\n  /* 70px 412px 42px 88px */\n  margin: 5.46875vw 32.1875vw 3.28125vw 6.875vw;\n\n  /* 608px */\n  height: 47.5vw;\n}\n\n.title {\n  /* 30px */\n  margin-bottom: 2.34375vw;\n}\n\n.description {\n  color: $gw_08;\n}\n\n.scrollbar {\n  position: fixed;\n  z-index: 2;\n  top: 3.90625vw;\n  bottom: 3.90625vw;\n  right: 2.03125vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var isolationContainer = "isolationContainer--tjIhq";
export var descriptionShowPopup = "descriptionShowPopup--jaonH";
export var title = "title--i90Pv";
export var description = "description--bSrdV";
export var scrollbar = "scrollbar--wJyQx";
export default ___CSS_LOADER_EXPORT___;
