import * as React from 'react';
import { useParams } from "react-router-dom";

import { useCards } from "~hooks/fetch/useCards/useCards";
import usePersons from "~hooks/fetch/usePersons/usePersons";
import { SpinnerFullScreenIsolated } from '~newapp/globalBlocks/spinner';

import ActorPage from "./ActorPage";
import { ResourceType } from './hooks';


const ActorPageWrapper$: React.FC = () => {
  const params: any = useParams(); 
  const { data: dataPerson, status: statusPerson } = usePersons(params.id);
  const { 
    data: dataMoviesAndSeries, 
    status: statusMoviesAndSeries, 
  } = useCards(params.id, [ResourceType.Movies, ResourceType.Series]);
  const { 
    data: dataProgramEvents, 
    status: statusProgramEvents 
  } = useCards(params.id, [ResourceType.ProgramEvents]);
  const { 
    data: dataAudioShows,
    status: statusAudioShows
  } = useCards(params.id, [ResourceType.AudioShows]);

  if( 
    statusMoviesAndSeries !== 'success'  
    || statusProgramEvents !== 'success'
    || statusAudioShows !== 'success'
  ) {
    return <SpinnerFullScreenIsolated />
  }

  return (
    <>
      {statusPerson === 'success' &&
        <ActorPage 
          dataPerson={ dataPerson.data }
          dataMoviesAndSeries={ dataMoviesAndSeries.data || [] }
          dataProgramEvents={ dataProgramEvents.data }
          dataAudioShows={ dataAudioShows.data }
        />
      }
    </>
  )
}

export const ActorPageWrapper = ActorPageWrapper$;