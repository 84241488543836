import { stringifyUrl } from 'query-string';

import {
  ABOUT_PROGRAM,
  ACCOUNT_CONTROL,
  ACCOUNT_TAB_SETTINGS,
  HELP,
  LOGIN,
  MENU_MY_CARD,
  MENU_PROFILES,
  MENU_SUBSCRIPTIONS,
  PAYMENTS,
  PROFILES_TAB_DEVICES,
  PROMOCODE,
  PURCHASES,
  SAFETY,
  USER_STATS,
} from '~lib/localizator/src/dictionaries';
import MenuID from '~typings/MenuID';

import { AccountTabId } from './types';

export const ACCOUNT_PATH_SCHEME = `/${MenuID.Account}/:tabId`;

export const AccountTabIdMap: Record<AccountTabId, string> = {
  [AccountTabId.AccountControl]: ACCOUNT_CONTROL,
  [AccountTabId.Profiles]: MENU_PROFILES,
  [AccountTabId.Subscriptions]: MENU_SUBSCRIPTIONS,
  [AccountTabId.Payments]: PAYMENTS,
  [AccountTabId.Security]: SAFETY,
  [AccountTabId.Cards]: MENU_MY_CARD,
  [AccountTabId.Devices]: PROFILES_TAB_DEVICES,
  [AccountTabId.Promocode]: PROMOCODE,
  [AccountTabId.Stats]: USER_STATS,
  [AccountTabId.Purchases]: PURCHASES,
  [AccountTabId.Settings]: ACCOUNT_TAB_SETTINGS,
  [AccountTabId.Help]: HELP,
  [AccountTabId.About]: ABOUT_PROGRAM,
  [AccountTabId.Signin]: LOGIN,
};

const getAccountTabPathById = (id: AccountTabId) => {
  return ACCOUNT_PATH_SCHEME.replace(':tabId', id);
};

export const AccountTabToPathMap: Record<AccountTabId, string> = {
  [AccountTabId.AccountControl]: getAccountTabPathById(AccountTabId.AccountControl),
  [AccountTabId.Profiles]: getAccountTabPathById(AccountTabId.Profiles),
  [AccountTabId.Subscriptions]: getAccountTabPathById(AccountTabId.Subscriptions),
  [AccountTabId.Payments]: getAccountTabPathById(AccountTabId.Payments),
  [AccountTabId.Security]: getAccountTabPathById(AccountTabId.Security),
  [AccountTabId.Cards]: getAccountTabPathById(AccountTabId.Cards),
  [AccountTabId.Devices]: getAccountTabPathById(AccountTabId.Devices),
  [AccountTabId.Promocode]: getAccountTabPathById(AccountTabId.Promocode),
  [AccountTabId.Stats]: getAccountTabPathById(AccountTabId.Stats),
  [AccountTabId.Purchases]: getAccountTabPathById(AccountTabId.Purchases),
  [AccountTabId.Settings]: getAccountTabPathById(AccountTabId.Settings),
  [AccountTabId.Help]: getAccountTabPathById(AccountTabId.Help),
  [AccountTabId.About]: getAccountTabPathById(AccountTabId.About),
  [AccountTabId.Signin]: getAccountTabPathById(AccountTabId.Signin),
};

export const SECURITY_CREATE_PIN_SEARCH_FIELD = 'createPin';

export const SECURITY_CREATE_PIN_MODE_PATH = stringifyUrl({
  url: AccountTabToPathMap[AccountTabId.Security],
  query: {
    [SECURITY_CREATE_PIN_SEARCH_FIELD]: true,
  },
});
