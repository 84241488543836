import React from 'react';
import { injectIntl } from 'react-intl';

import { useConfig } from '~app/global';
import { Typography } from '~app/ui-kit/Typography';
import { usePlatform } from '~components/Provider/Platform';
import {
  ACCOUNT_LABEL_EMAIL,
  DEVICE_ID,
  PRODUCT_VERSION,
  SIGN_IN_PROBLEMS_TEXT,
} from '~lib/localizator/src/dictionaries';

import * as styles from './AboutInfoFields.module.css';

export interface Props {
  className?: string;
}

export const AboutInfoFields = injectIntl<Props>(({ intl, className }) => {
  const { projectTitle, supportPhone, supportEmail, projectCopyright } = useConfig(
    (c) => c.smartTV,
  );
  const { platform } = usePlatform();
  const { deviceId, osVersion } = platform.getDeviceInfo();
  const version = window.__PROJECT__?.VERSION || '0.0.1';

  const versionInfo = renderInfoField({
    fieldLabel: intl.formatMessage({ id: PRODUCT_VERSION }),
    fieldValue: version,
  });

  const projectTitleInfo = renderInfoField({
    fieldValue: projectTitle,
  });

  const deviceIdInfo = renderInfoField({
    fieldLabel: intl.formatMessage({ id: DEVICE_ID }),
    fieldValue: deviceId || 'N/A',
  });

  const osVersionInfo = renderInfoField({
    fieldLabel: 'OS version',
    fieldValue: osVersion || 'N/A',
  });

  const supportPhoneInfo = supportPhone
    ? renderInfoField({
        fieldLabel: intl.formatMessage({ id: SIGN_IN_PROBLEMS_TEXT }),
        fieldValue: supportPhone,
      })
    : null;

  const supportEmailInfo = supportEmail
    ? renderInfoField({
        fieldLabel: intl.formatMessage({ id: ACCOUNT_LABEL_EMAIL }),
        fieldValue: supportEmail,
      })
    : null;

  const projectCopyrightInfo = renderInfoField({
    fieldValue: (
      <>
        &#169; {new Date().getFullYear()} {projectCopyright}
      </>
    ),
  });

  return (
    <div className={ className }>
      {versionInfo}
      {projectTitleInfo}
      {deviceIdInfo}
      {osVersionInfo}
      {supportPhoneInfo}
      {supportEmailInfo}
      <div className={ styles.copyright }>
        {projectCopyrightInfo}
      </div>
    </div>
  );
});

const renderInfoField = ({
  fieldLabel,
  fieldValue,
}: {
  fieldLabel?: React.ReactNode;
  fieldValue: React.ReactNode;
}) => {
  return (
    <div className={ styles.field }>
      {fieldLabel && (
        <Typography
          variant="subtitle1_medium"
          className={ styles.fieldLabel }
        >
          {fieldLabel + ':  '}
        </Typography>
      )}
      <Typography
        className={ styles.fieldValue }
        variant="subtitle1_medium"
      >
        {fieldValue}
      </Typography>
    </div>
  );
};
