import * as cn from 'classnames';
import settings from 'customization-project-name/settings.json';
import * as React from 'react';

import { useApp } from "~components/Provider/App";
import { usePlatform } from '~components/Provider/Platform';
import { useNetwork } from '~global';
import useConvivialAnalytics from '~hooks/useConvivaAnalytics';
import { usePlayer } from '~hooks/usePlayer';
import { ManifestParser } from '~lib/player/utils/manifest-parser';
import VideoError from "~lib/player/VideoError";
import deleteCacheByKeys from '~lib/ReactQuery/deleteCacheByKeys';
import usePointer from "~stores/Pointer";
import Manifest from '~typings/Manifest';
import NavigationDirection from "~typings/NavigationDirection";
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Stream from '~typings/Stream';
import TimeShiftAvailability from '~typings/TimeshiftAvailability';

import { usePrepareStreamVariables } from './PlayerContainer.helpers';
import PlayerManager from './PlayerManager';
import * as styles from './styles.module.css';


type Props = Readonly<{
  isShowing: boolean;
  item: PlayerMediaItem;
  stream: Stream;
  manifest: Manifest;
  parsedManifest: ManifestParser | null;
  timeShiftParams: TimeShiftAvailability | null;
  percentsWatched?: number;
  onCanPlay: () => void;
  onEnded: () => void;
  onError: (error: any) => void;
}>;

const PlayerContainer = (props: Props) => {
  const [isStreamLoaded, setIsStreamLoaded] = React.useState<boolean>(false);
  const { platform } = usePlatform();
  const { activeProfileId } = useApp();
  const clientIP = useNetwork().ip;
  const [videoContainerRef, player] = usePlayer({
    manifest: props.manifest,
    mediaItem: props.item,
    stream: props.stream,
  });
  const hidden = false;
  const flush = usePointer(state => state.flush);

  if (settings.enableConviva) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useConvivialAnalytics({
      item: props.item,
      stream: props.stream,
      player: player.current,
      activeProfileId,
    })
  }

  const handleError = (error: VideoError) => {
    setIsStreamLoaded(false);
    props.onError(error);
  };

  const load = async () => {
    flush([
      NavigationDirection.Up,
      NavigationDirection.Right,
      NavigationDirection.Down,
      NavigationDirection.Left,
    ]);
    player.current?.load({
      language,
      manifest: props.manifest,
      parsedManifest: props.parsedManifest,
      stream: props.stream,
      timeShiftParams: props.timeShiftParams,
      container: videoContainerRef.current,
      is4KStream: false,
      isLiveStream: true,
      mediaItem: props.item,
      platform: platform.getDeviceInfo(),
      clientIP,
      percentsWatched: props.percentsWatched,
    });

    setIsStreamLoaded(true);
  };

  const { language } = usePrepareStreamVariables();

  React.useEffect(() => () => {
    player.current?.reset();
    deleteCacheByKeys(['track_list', 'stream']);
  }, [!!player]);
  React.useEffect(() => {
    if (!props.isShowing) {
      player.current?.reset();
    }
  }, [props.isShowing, !!player]);
  React.useEffect(() => {
    if (
      !isStreamLoaded
      && props.stream
      && videoContainerRef.current
      && player.current
      && props.manifest
    ) {
      load();
    }
  }, [
    props.manifest,
    props.stream,
    videoContainerRef.current,
    props.timeShiftParams,
    player.current,
  ]);

  return (
    <div
      className={ cn(styles.playerRoot, {
        [styles.hidden]: hidden,
      }) }
    >
      <div
        className={ styles.videoContainer }
        ref={ videoContainerRef }
      />
      {
        (props.item && isStreamLoaded && player.current) &&
          <PlayerManager
              item={ props.item }
              player={ player.current }
              onCanPlay={ props.onCanPlay }
              onEnded={ props.onEnded }
              onError={ handleError }
              stream={ props.stream }
          />
      }
    </div>
  );
};


export default React.memo(PlayerContainer);
