import cn from 'classnames';
import React, { useMemo } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { Typography } from '~app/ui-kit/Typography';
import ProfileImage from '~components/ProfileImage';
import { useApp } from '~components/Provider/App';
import { useProfiles } from '~hooks/fetch/useProfiles';
import { ACCOUNT } from '~lib/localizator/src/dictionaries';
import { valueInPixelsByWidth } from '~lib/SizesInPX';
import { AccountIcon } from '~newapp/ui-kit/Icons/For_top_menu/AccountIcon';

import * as styles from './AccountTopLine.module.css';

interface Props {
  className?: string;
}

const AccountTopLine: React.FC<Props & InjectedIntlProps> = ({ className, intl }) => {
  const { activeProfileId } = useApp();
  const { data: profiles } = useProfiles();
  const activeProfile = profiles?.find(({ id }) => id === activeProfileId);

  const imageWithOrHeight = useMemo(() => valueInPixelsByWidth(3.90625), []);

  const renderImage = () => {
    if (!activeProfile) {
      return <AccountIcon className={ styles.iconOrImage } />;
    }
    return (
      <ProfileImage
        profile={ activeProfile }
        width={ imageWithOrHeight }
        height={ imageWithOrHeight }
        className={ styles.iconOrImage }
        disableHover
      />
    );
  };

  const renderTitle = () => {
    return (
      <Typography
        variant={ 'h3_medium' }
        className={ styles.title }
      >
        {intl.formatMessage({ id: ACCOUNT })}
      </Typography>
    );
  };

  return (
    <div className={ cn(styles.AccountTopLine, className) }>
      {renderImage()}
      {renderTitle()}
    </div>
  );
};

export const AccountTopLineMemoized = React.memo(injectIntl(AccountTopLine));
