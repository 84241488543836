import * as React from 'react';
import { InjectedIntl } from 'react-intl';
import { animated } from 'react-spring';

import ScrollBar from '~components/ScrollBar';
import { useInfinityCastMembers } from '~hooks/fetch/useCastMembers';
import useChannelBySlug from '~hooks/fetch/useChannel';
import useAccess from '~hooks/useAccess/useAccess';
import useDelayedChange from '~hooks/useDelayedChange';
import { usePaginationOneLine } from '~hooks/usePagination';
import useScroll from '~hooks/useScroll/useScroll';
import { getEventStatus } from '~lib/datetime-utils';
import { isCatchupAvailable } from '~lib/eventsHelper';
import { isChannel } from '~lib/objectType';
import preparingText from '~lib/preparingText';
import { EVENT_AVAILABLE_ENTRIES, EVENT_DESCRIPTION, EVENT_NEAREST_BROADCAST } from '~localization';
import Event from '~typings/Event';
import { EventStatus } from '~typings/EventStatus';
import NavigationDirection from '~typings/NavigationDirection';
import TabsPanel from '~ui/TabsPanel';

import ActorsBlock from './ActorsBlock';
import DetailsList from './DetailsList';
import LabeledText from './DetailsList/LabeledText';
import EventProgramList from './EventProgramList';
import ProgramHeader from './ProgramHeader';
import { fields } from './render.helper';
import * as styles from './styles.module.css';
import { ProgramDetailItem } from './types';

enum Focused {
  WatchButton = 'watch_button',
  TabsPanel = 'tabs_panel',
  TabsConent = 'tabs_content',
  ActorsSlider = 'actors_slider',
}

export enum TabsFocused {
  Description = 0,
  NearestAir,
  Catchups,
}

type Props = Readonly<{
  item: ProgramDetailItem;
  onWatchBtnClick?: () => void;
  intl: InjectedIntl;
}>;

const TABS = [TabsFocused.Description, TabsFocused.NearestAir, TabsFocused.Catchups];

/**
 * TODO: нужен рефактор
 *
 * Можно смотреть в MediaItemDetailsNotEvent, там разделена логика от текущего компонента
 * Нужно использовать новый ActorsBlock (ActorsBlockFocuser)
 */
const MediaItemDetailsEvent: React.FC<Props> = (props: Props) => {
  const access = useAccess(props.item.id,props.item.object);
  const contentBlockRef = React.useRef<HTMLDivElement>(null);
  const { data: { data: channel } = {} } = useChannelBySlug(props.item.channel_id);

  const { query: castMembersQuery, parsedData: castMembers } = useInfinityCastMembers(
    isChannel(props.item) ? undefined : props.item.id,
  );

  const isLive = getEventStatus(props.item) === EventStatus.Now;
  const isCatchup = channel && isCatchupAvailable(props.item, channel);

  const [eventsCount, setEventsCount] = React.useState<number>(0);
  const [focusedEventIndex, setFocusedEventIndex] = React.useState<number>(0);
  const [focused, setFocused] = React.useState<Focused>(
    isLive || isCatchup ? Focused.WatchButton : Focused.TabsPanel,
  );
  const [selectedTab, setSelectedTab] = React.useState<TabsFocused>(TabsFocused.Description);
  const [focusedTab, setFocusedTab] = React.useState<TabsFocused>(selectedTab);

  const actors = React.useMemo(
    () => castMembers.filter((item) => item.role === 'actor'),
    [castMembers],
  );

  const [focusedActor, setFocusedActor] = React.useState(0);

  usePaginationOneLine({
    currentFetchedCount: actors.length,
    focusedElemIdx: focusedActor,
    minimalPrefetchedCount: 10,
    updates: [castMembersQuery],
    fetchMoreData: () => {
      if (!castMembersQuery.isFetchingNextPage && castMembersQuery.hasNextPage) {
        castMembersQuery.fetchNextPage();
      }
    },
  });

  const handleTabsLeave = (direction: NavigationDirection.Up | NavigationDirection.Down) => {
    if (direction === NavigationDirection.Up && (isLive || isCatchup)) {
      if (focused === Focused.ActorsSlider) {
        setFocused(Focused.TabsPanel);
      }
      setFocused(Focused.WatchButton);
    } else if (direction === NavigationDirection.Down) {
      if (
        (selectedTab === TabsFocused.NearestAir || selectedTab === TabsFocused.Catchups) &&
        eventsCount > 0
      ) {
        setFocused(Focused.TabsConent);
      } else {
        if (actors?.length > 0) {
          setFocused(Focused.ActorsSlider);
        }
      }
    } else if (
      direction === NavigationDirection.Up &&
      focused === Focused.ActorsSlider &&
      (isLive || isCatchup)
    ) {
      setFocused(Focused.TabsPanel);
    }
  };
  const handleEventListNavigated = (
    direction: NavigationDirection.Up | NavigationDirection.Down,
  ) => {
    setFocusedEventIndex((currentValue) =>
      direction === NavigationDirection.Up
        ? Math.max(currentValue - 1, 0)
        : Math.min(eventsCount - 1, currentValue + 1),
    );
  };
  const handleActorsSliderLeave = (
    direction: NavigationDirection.Up | NavigationDirection.Down,
  ) => {
    if (direction === NavigationDirection.Up) {
      setFocused(Focused.TabsPanel);
    }
  };
  const handleVerticalNavigation = React.useCallback(
    (direction: NavigationDirection.Up | NavigationDirection.Down) => {
      switch (focused) {
        case Focused.WatchButton: {
          watchBtnLeaveHandler(direction);

          break;
        }
        case Focused.TabsPanel: {
          handleTabsLeave(direction);

          break;
        }
        case Focused.TabsConent: {
          if (focusedEventIndex === 0 && direction === NavigationDirection.Up) {
            setFocused(Focused.TabsPanel);
          } else {
            handleEventListNavigated(direction);
          }

          break;
        }
        case Focused.ActorsSlider: {
          handleActorsSliderLeave(direction);

          break;
        }
      }
    },
    [focused, focusedTab, focusedActor, selectedTab, eventsCount, focusedEventIndex, actors],
  );
  const handleNavigate = React.useCallback(
    (dir: NavigationDirection) => {
      if (dir === NavigationDirection.Down || dir === NavigationDirection.Up) {
        handleVerticalNavigation(dir);
      } else if (focused === Focused.TabsPanel) {
        setFocusedTab((oldVal) =>
          Math.max(
            0,
            Math.min(TABS.length - 1, oldVal + (dir === NavigationDirection.Left ? -1 : +1)),
          ),
        );
      } else if (focused === Focused.ActorsSlider) {
        setFocusedActor((oldVal) =>
          Math.max(
            0,
            Math.min(actors?.length - 1, oldVal + (dir === NavigationDirection.Left ? -1 : +1)),
          ),
        );
      }
    },
    [focused, focusedTab, focusedActor, selectedTab, eventsCount, focusedEventIndex, actors.length],
  );
  const selectedTabDelayed = useDelayedChange(selectedTab, 300);
  const { isScrollAvailable, scrollStyle, scrollPos } = useScroll({
    contentBlockRef,
    onNavigated: handleNavigate,
    heightUpdate: [selectedTabDelayed],
  });

  React.useEffect(() => {
    if (!isLive) {
      setFocused(Focused.TabsPanel);
    }
  }, [isLive]);

  const watchBtnLeaveHandler = (direction: NavigationDirection.Up | NavigationDirection.Down) => {
    if (direction === NavigationDirection.Down) {
      setFocused(Focused.TabsPanel);
    }
  };
  const tabsContentLeaveHandler = (direction: NavigationDirection) => {
    if (direction === NavigationDirection.Up) {
      setFocused(Focused.TabsPanel);
    }
  };

  const {
    description,
    membersByRole,
    genres,
    countries,
    productionYear,
    languages,
    studios,
    durationMin,
    externalCatalog,
  } = React.useMemo(() => fields(props.item, castMembers), [props.item, castMembers]);

  //здесь была логика бекграунда

  const tabsToText = {
    [TabsFocused.Description]: props.intl.formatMessage({ id: EVENT_DESCRIPTION }),
    [TabsFocused.NearestAir]: props.intl.formatMessage({ id: EVENT_NEAREST_BROADCAST }),
    [TabsFocused.Catchups]: props.intl.formatMessage({ id: EVENT_AVAILABLE_ENTRIES }),
  };

  return (
    <div
      className={ styles.pageContainer }
    >
      <animated.div
        className={ styles.container }
        style={ scrollStyle }
        ref={ contentBlockRef }
      >
        {
          <ProgramHeader
            access={ access }
            event={ props.item }
            isFocused={ focused === Focused.WatchButton }
            onWatchBtnClick={ props.onWatchBtnClick }
          />
        }
        <div style={ { width: '60.9375vw', marginLeft: '6.875000000000001vw' } }>
          <TabsPanel
            isFocused={ focused === Focused.TabsPanel }
            selectedTab={ selectedTab }
            focusedTab={ focusedTab }
            onChange={ setSelectedTab }
            tabs={ TABS.map((key) => tabsToText[key]) }
            className={ styles.tabsPanel }
          />

          {selectedTab === TabsFocused.Description && (
            <LabeledText label="">{preparingText(description)}</LabeledText>
          )}

          {!(selectedTab === TabsFocused.NearestAir || selectedTab === TabsFocused.Catchups) && (
            <ActorsBlock
              isFocused={ focused === Focused.ActorsSlider }
              focusedActor={ focusedActor }
              items={ actors }
            />
          )}

          {selectedTab === TabsFocused.Description && (
            <DetailsList
              membersByRole={ membersByRole }
              countries={ countries }
              genres={ genres }
              languages={ languages }
              studios={ studios }
              durationMin={ durationMin }
              productionYear={ productionYear }
              externalCatalog={ externalCatalog }
            />
          )}

          {(selectedTab === TabsFocused.NearestAir || selectedTab === TabsFocused.Catchups) && (
            <EventProgramList
              event={ props.item as Event }
              showCatchups={ selectedTab === TabsFocused.Catchups }
              isFocused={ focused === Focused.TabsConent }
              focusedIndex={ focusedEventIndex }
              onLeave={ tabsContentLeaveHandler }
              onCalculatedEventsCount={ setEventsCount }
            />
          )}
        </div>
      </animated.div>
      {isScrollAvailable ? (
        <ScrollBar
          position={ scrollPos }
          className={ styles.scrollbar }
          isFocused
        />
      ) : null}
    </div>
  );
};

export default React.memo(MediaItemDetailsEvent, (p: Props, n: Props) => p.item.id !== n.item.id);
