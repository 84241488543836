import focuser, { FocuserKeyHandler } from '@focuser';
import * as React from 'react';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Feature, hasFeature } from '~app/global';
import { Alert2 } from '~components/Alert';
import { getMeta } from '~components/MediaItemPage/Description';
import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import SceneMediaItemDescriptionText from '~components/MediaItemPage/Description/description-text';
import SceneMediaItemMeta from '~components/MediaItemPage/Description/meta';
import PurchaseInfo from '~components/MediaItemPage/PurchaseInfo/PurchaseInfo';
import {
  doesNeedShowWarningForRent,
  RentStartWarningStateForMovie,
} from '~components/MediaItemPage/utils';
import { useMenu } from '~components/Provider/Menu';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { PlayerAccessReason, usePlayerState } from '~components/Provider/Player/component.helpers';
import { RecommendationsFocuser } from '~components/Recommendations';
import { useFavoriteItem, useToggleFavorite } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { Method, useVoteMutation } from '~hooks/fetch/useVote';
import useAccess from '~hooks/useAccess/useAccess';
import useLocationSearch from '~hooks/useLocationSearch';
import { makeReadyChildsMapperHook } from '~lib/focuserUtils/readyChildsMapper';
import FavoriteType from '~typings/FavoriteType';
import ItemObject from '~typings/ItemObject';
import Movie from '~typings/Movie';
import { VoteAction } from '~typings/Vote';
import { VerticalFadeScroll } from '~ui/VerticalFadeScroll';

import { MediaItemBarFocuser } from '../Description/bar/MediaItemBarFocuser';
import DescriptionWrapper from '../Description/wrapper';
import { RentActivationWarning } from '../RentActivationWarning';
import * as styles from './Movie.styles.module.css';

type Props = Readonly<{
  item: Movie;
}>;

const MoviePage: React.FC<Props> = (props: Props) => {
  const playerState = usePlayerState();
  const history = useHistory();
  const menu = useMenu();
  const { setItem: playerSetItem } = usePlayerStateActions();

  const [rentActivationWarningState, setRentActivationWarningState] =
    useState<RentStartWarningStateForMovie>({ isOpen: false });

  const favoriteItemQuery = useFavoriteItem(FavoriteType.Movies, props.item.id);
  const isInFavorites = Boolean(favoriteItemQuery.data);
  const { mutate: toggleVote } = useVoteMutation(props.item.object, props.item.id);
  const access = useAccess(props.item.id, ItemObject.Movie);
  const { mutate: toggleFavorite, isLoading } = useToggleFavorite();

  const setItemToPlayer = () => {
    playerSetItem(props.item);
  };

  const handleBarClick = (barItem: BarItem) => {
    if (barItem === BarItem.Play || barItem === BarItem.PlayContinue) {
      if (doesNeedShowWarningForRent(access)) {
        setRentActivationWarningState({
          isOpen: true,
          rentPlan: access.reason_object.rent_plan,
          purchaseExpiresAt: access.reason_object.expires_at,
        });
        return;
      }
      setItemToPlayer();
    } else if (barItem === BarItem.WatchLaterRemove && !isLoading) {
      toggleFavorite({ itemID: props.item.id, isInFavorites, type: FavoriteType.Movies });
    } else if (barItem === BarItem.WatchLaterAdd && !isLoading) {
      toggleFavorite({ itemID: props.item.id, isInFavorites, type: FavoriteType.Movies });
    } else if (barItem === BarItem.Trailer && props.item.trailer) {
      playerSetItem(props.item.trailer);
    } else if (barItem === BarItem.RatedUp || barItem === BarItem.RatedDown) {
      toggleVote({ method: Method.DELETE });
    } else if (barItem === BarItem.RateUp) {
      toggleVote({ method: Method.POST, mention: VoteAction.Up });
    } else if (barItem === BarItem.RateDown) {
      toggleVote({ method: Method.POST, mention: VoteAction.Down });
    }
  };

  const matchPage = useRouteMatch('/page/:id');
  const descriptionClick = () => {
    history.push(
      `${matchPage ? `${matchPage.url}/` : '/'}media-item/${props.item.object}/${
        props.item.id
      }/details`,
    );
  };

  const allowNavigation = Boolean(
    !useLocationSearch()?.player &&
      !menu.isPopupOpened &&
      !playerState.item &&
      !rentActivationWarningState.isOpen,
  );


  const [useReadyChildsHook] = useState(()=>makeReadyChildsMapperHook({
    description: false,
    bar: false,
    recs: false,
  }));

  const {
    setIsRecsReady,
    setIsBarReady,
    setIsDescriptionReady,
    focusOn,
    setFocusOn,
    nextChildToNavigate,
    prevChildToNavigate,
  } = useReadyChildsHook('description');

  const navigateOnNext: FocuserKeyHandler = (ev) => {
    if (nextChildToNavigate) {
      setFocusOn(nextChildToNavigate);
      ev.stop();
      ev.stopNativeEvent();
    }
  };

  const navigateOnPrev: FocuserKeyHandler = (ev) => {
    if (prevChildToNavigate) {
      setFocusOn(prevChildToNavigate);
      ev.stop();
      ev.stopNativeEvent();
    }
  };

  React.useEffect(() => {
    setIsDescriptionReady(Boolean(props.item.description));
  }, [props.item.description]);

  if (rentActivationWarningState.isOpen) {
    return (
      <RentActivationWarning
        resourceType={ ItemObject.Movie }
        onCancel={ () => {
          setRentActivationWarningState({
            isOpen: false,
          });
        } }
        rentPlan={ rentActivationWarningState.rentPlan }
        purchaseExpiresAt={ rentActivationWarningState.purchaseExpiresAt }
        onContinue={ () => {
          setRentActivationWarningState({
            isOpen: false,
          });
          setItemToPlayer();
        } }
      />
    );
  }

  return (
    <focuser.FocusableBlock
      className={ styles.container }
      isFocused={ allowNavigation }
      onKeyUp={ navigateOnPrev }
      isPointerUpAvailable={ Boolean(prevChildToNavigate) }
      isPointerDownAvailable={ Boolean(nextChildToNavigate) }
      onKeyDown={ navigateOnNext }
      noNeedForceFocus
    >
      <VerticalFadeScroll
        focusedIndex={ focusOn === 'recs' ? 1 : 0 }
        sliderClassName={ styles.verticalSlider }
      >
        <div>
          <focuser.FocusableBlock
            isFocused={ focusOn === 'description' }
            onForceFocus={ () => setFocusOn('description') }
            emitForceFocusOnHover
            isForceFocusEnabledInBranch={ focusOn !== 'recs' }
            onClick={ descriptionClick }
            isLastBlock
            className={ styles.description }
          >
            {({ isFocused }) => (
              <DescriptionWrapper isFocused={ isFocused }>
                <SceneMediaItemMeta meta={ getMeta(props.item) } />
                <SceneMediaItemDescriptionText description={ props.item.description } />
              </DescriptionWrapper>
            )}
          </focuser.FocusableBlock>
          {access && (
            <>
              <focuser.FocusableBlock
                isForceFocusEnabledInBranch={ focusOn !== 'recs' }
                onForceFocus={ () => setFocusOn('bar') }
                isFocused={ focusOn === 'bar' }
                className={ styles.mediaItemBar }
              >
                <MediaItemBarFocuser
                  onReady={ setIsBarReady }
                  access={ access }
                  item={ props.item }
                  watchProgressItem={ props.item }
                  onClick={ handleBarClick }
                />
              {access.reason === PlayerAccessReason.Rented && (
                <PurchaseInfo purchase={ access.reason_object } />
              )}
              </focuser.FocusableBlock>
              <div className={ styles.alert }>
                {access && !access.allowed && access.reason && <Alert2 reason={ access.reason } />}
              </div>
            </>
          )}
        </div>

        <focuser.FocusableBlock
          className={ hasFeature(Feature.NewCards) ? styles.newCardRecs : styles.recs }
          isFocused={ focusOn === 'recs' }
          onForceFocus={ () => setFocusOn('recs') }
          isForceFocusEnabledInBranch={ focusOn === 'recs' }
        >
          <RecommendationsFocuser
            item={ props.item }
            onReady={ setIsRecsReady }
          />
        </focuser.FocusableBlock>
      </VerticalFadeScroll>
    </focuser.FocusableBlock>
  );
};

export default React.memo(MoviePage);
