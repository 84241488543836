import { useEffect } from "react";

import { SearchPageBlocks } from "~components/SearchPage/SearchPage";
import { usePageByType } from "~hooks/fetch/usePages/usePages";
import { NewLayerNavigation } from "~stores/LayerNavigation/newLayerNavigation";
import Page from "~typings/Page";
import PageObject from "~typings/PageObject";

export function useSetStore() {
  const searchPage: Page | undefined = usePageByType(PageObject.SearchPage)
  const setFocusHistory = NewLayerNavigation(state => state.setFocusHistory)


  useEffect(() => {
    if (searchPage) {
      setFocusHistory(searchPage.id, SearchPageBlocks.SearchGrid, {
        focusedElementIndex: 0,
        focusedLineIndex: 0,
        totalElements: 0,
        focusedElementPossibleMove: [false, false, false, false]
      })
      setFocusHistory(searchPage.id, SearchPageBlocks.ExpandGrid, {
        focusedElementIndex: 0,
        focusedLineIndex: 0,
        totalElements: 0,
        focusedElementPossibleMove: [false, false, false, false]
      })
      setFocusHistory(searchPage.id, SearchPageBlocks.RecommendGrid, {
        focusedElementIndex: 0,
        focusedLineIndex: 0,
        totalElements: 0,
        focusedElementPossibleMove: [false, false, false, false]
      })
    }
  }, [searchPage])

  return null
}
