import ISO639 from '~lib/iso-639-1';


const bringingAudioTrackNameToStandard = (audioTrackName: string): string => {
  if (audioTrackName === 'blr') {
    return 'be';
  }
  if (audioTrackName === 'kaz') {
    return 'kk';
  }

  let result = audioTrackName;
  const audioTrackNameInLowerCase = audioTrackName.toLowerCase();

  for (let i = 0; i < ISO639.length; i += 1) {
    const lang = ISO639[i];
    if (audioTrackNameInLowerCase.includes(lang)) {
      result = lang;

      break;
    }
  }

  return result;
};


export default bringingAudioTrackNameToStandard;
