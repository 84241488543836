import cn from 'classnames';
import React from 'react';

import { Typography } from '~app/ui-kit/Typography';
import Channel from '~typings/Channel';

import * as styles from './EPGEventsSkeleton.module.css';

export type Props = {
  channel: Channel;
};

const EPGEventsSkeleton$: React.FC<Props> = ({ channel }) => {
  return (
    <div className={ styles.wrapper }>
      <Typography
        variant={ 'h3_bold' }
        className={ styles.channelName }
      >
        {channel.name}
      </Typography>
      <EPGEventsSkeletonBlock className={ cn(styles.block, styles.firstBlock) } />
      <EPGEventsSkeletonBlock className={ styles.block } />
    </div>
  );
};

const EPGEventsSkeletonBlock: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={ className }>
      {Array.from({ length: 10 }).map((_, index) => (
        <div
          className={ cn(styles.skeletonRow, {
            [styles.skeletonRowShort]: index === 0
          }) }
          key={ index }
        />
      ))}
    </div>
  );
};

export const EPGEventsSkeleton = React.memo(EPGEventsSkeleton$);
