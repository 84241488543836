import * as React from 'react';

import SliderItems from '~components/Slider/items/items';
import ScrollableSliderBlock from '~components/Slider/scrollable';
import { Dimension, getDimensionByObject } from '~lib/Dimension';
import { getSpaceBetweenSlides } from '~lib/SizesInPX';
import { DataItem, MediaItemType } from '~typings/DataItem';
import MediaItemDimension from '~typings/MediaItemDimension';
import ResponseMany from '~typings/ResponseMany';


type Props = Readonly<{
  dimension: MediaItemDimension;
  UUID: string[];
  focusedIndex: number;
  isFocusedBlock: boolean;
  items: ResponseMany<MediaItemType[]>;
  withName?: boolean;
  withGenres?: boolean;
  alwaysShowName?: boolean;
  withProgress?: boolean;
  isVisible?: boolean;
  showAsSeries?: boolean;
  onClick?: (item: DataItem) => void;
  cardConfigID?: string;
  deeplink?: string;
}>;


const Items = (props: Props) => {
  const dimension: Dimension = getDimensionByObject(props.dimension);
  const slideWidth = (dimension.width + getSpaceBetweenSlides());

  return (
    <ScrollableSliderBlock
      focusedIndex={ props.focusedIndex }
      slideWidth={ slideWidth }
    >
      <SliderItems
        cardConfigID={ props.cardConfigID }
        UUID={ props.UUID }
        withName={ !!props.withName }
        withGenres={ !!props.withGenres }
        alwaysShowName={ props.alwaysShowName }
        isFocusedBlock={ props.isFocusedBlock }
        isVisible={ props.isVisible }
        items={ props.items }
        focusedIndex={ props.focusedIndex }
        dimension={ props.dimension }
        showAsSeries={ props.showAsSeries }
        onClick={ props.onClick }
        withProgress={ props.withProgress }
        deeplink={ props.deeplink }
      />
    </ScrollableSliderBlock>
  );
};


export default React.memo(Items);
