import React from 'react';

import { SearchScreen } from '~components/SearchPageFocuser';
import { FocuserTestScreen } from '~screens/FocuserTestScreen/FocuserTestScreen';

export type TestCase = {
  name: string;
  description?: string;
  component: React.FC;
};

export const getTestCases = () => {
  const components: TestCase[] = [
    {
      name: 'Фокусер 🚨',
      description: 'Проверка навигации ',
      component: FocuserTestScreen,
    },
    {
      name: 'Тестовой текст ✅',
      component: () => {
        return <div>Тут просто текст</div>;
      },
    },
    {
      name: 'Seach page на фокусере 🔎',
      description: 'Тестовой компонент поиска на фокусере, работает только если есть search_page в /pages',
      component: SearchScreen,
    },
  ];

  return components;
};
