// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup--Wcms5 {\n  /* 45px 88px 62px 88px */\n  padding: 3.515625vw 6.875vw 4.84375vw 6.875vw;\n  background-color: rgba(23, 23, 27, 1);\n  width: 100vw;\n  height: 100vh;\n}\n\n.header--Wzfa1 {\n  /* 80px */\n  margin-bottom: 6.25vw;\n}\n\n.sliderWrapper--Nn616 {\n  /* 1104px */\n  width: 86.25vw;\n}\n\n.sliderWrapperNoAnimation--psMzB {\n  /* 1070px */\n  width: 83.59375vw;\n}\n\n\n.slider--Owo1h {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: start;\n  -webkit-align-items: flex-start;\n     -moz-box-align: start;\n          align-items: flex-start;\n}\n\n.selectColumn--bzioU {\n  /* 40px */\n  margin-right: 3.125vw;\n}\n\n.selectColumn--bzioU:last-child {\n  margin-right: 0;\n}\n\n.buttons--NGkAL {\n  /* 110px */\n  margin-top: 8.59375vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/DropdownSelectPopup/DropdownSelectPopup.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,6CAA6C;EAC7C,qCAAwB;EACxB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;;AAGA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,qBAAqB;AACvB","sourcesContent":[".popup {\n  /* 45px 88px 62px 88px */\n  padding: 3.515625vw 6.875vw 4.84375vw 6.875vw;\n  background-color: $gw_00;\n  width: 100vw;\n  height: 100vh;\n}\n\n.header {\n  /* 80px */\n  margin-bottom: 6.25vw;\n}\n\n.sliderWrapper {\n  /* 1104px */\n  width: 86.25vw;\n}\n\n.sliderWrapperNoAnimation {\n  /* 1070px */\n  width: 83.59375vw;\n}\n\n\n.slider {\n  display: flex;\n  align-items: flex-start;\n}\n\n.selectColumn {\n  /* 40px */\n  margin-right: 3.125vw;\n}\n\n.selectColumn:last-child {\n  margin-right: 0;\n}\n\n.buttons {\n  /* 110px */\n  margin-top: 8.59375vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var popup = "popup--Wcms5";
export var header = "header--Wzfa1";
export var sliderWrapper = "sliderWrapper--Nn616";
export var sliderWrapperNoAnimation = "sliderWrapperNoAnimation--psMzB";
export var slider = "slider--Owo1h";
export var selectColumn = "selectColumn--bzioU";
export var buttons = "buttons--NGkAL";
export default ___CSS_LOADER_EXPORT___;
