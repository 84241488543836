import * as cn from 'classnames';
import * as React from 'react';

import EPGPageChannelsList from '~components/EPG/Channels/Channels';
import EPGPageChannelsListScrollWrapper from '~components/EPG/Channels/ChannelsScroll';
import ChannelsPlaceholder from '~components/EPG/Channels/Placeholder';
import Channel from '~typings/Channel';
import Fade from '~ui/Effects/Fade';

import * as styles from './styles.module.css';


type Props = Readonly<{
  withCurrentEvent?: boolean;
  isNeedFetchEvents?: boolean;
  isFocused: boolean;
  focusedIndex: number;
  items: Channel[];
  total: number;
  onClick?: (channel: Channel) => void;
}>;


/**
 * TODO: Удалить после удаления старого плеера
 * @deprecated
 */
const EPGPageChannelsRender: React.FC<Props> = (props: Props) => {
  const { items, focusedIndex } = props;

  const renderItems = React.useMemo(() => {
    if (!items || items.length == 0) { return null; }

    return (
      <EPGPageChannelsList
        key="EPGPageChannelsList"
        isNeedFetchEvents={ props.isNeedFetchEvents }
        withCurrentEvent={ props.withCurrentEvent }
        isFocused={ props.isFocused }
        focusedIndex={ focusedIndex }
        items={ items }
        total={ props.total }
        onClick={ props.onClick }
      />
    );
  }, [props.isFocused, props.isNeedFetchEvents, focusedIndex, items.length]);

  return React.useMemo(() => {
    return (
      React.createElement(
        'div',
        {
          className: cn(styles.wrapper, {
            [styles.wrapperWithCurrentEvent]: props.withCurrentEvent,
          })
        },
        (items.length === 0) ?
          null
          :
          <Fade
            isVisible={ (renderItems !== null) }
            duration={ 300 }
            delay={ 0 }
          >
            <EPGPageChannelsListScrollWrapper
              focusedIndex={ props.focusedIndex }
            >
              { renderItems }
            </EPGPageChannelsListScrollWrapper>
          </Fade>,
        <ChannelsPlaceholder
          isVisible={ (renderItems === null) }
        />,
      )
    );
  }, [
    focusedIndex,
    items.length,
    props.isFocused,
    props.isNeedFetchEvents,
  ]);
};


export default EPGPageChannelsRender;
