import * as React from 'react';

import { ActionType, useApp, useAppAction } from '~components/Provider/App';


export const ApiClientError: React.FC = () => {
  const appAction = useAppAction();
  const { isLaunchingDone } = useApp();

  React.useEffect(() => {
    if (!isLaunchingDone) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: { isLaunchingDone: true },
      });
    }
  }, []);

  return (
    <div>
      Api Client Error
    </div>
  );
};

export default ApiClientError;
