import { History } from 'history';

import useDeepLink from '~hooks/useDeepLink';
import CardType, { Modification } from '~typings/Card';
import ItemObject from '~typings/ItemObject';
import WatchProgressWithResource from '~typings/WatchProgressWithResource';

export const getProgress = (item: CardType | WatchProgressWithResource) => {
  if (
    item.object === ItemObject.WatchProgressWithResource &&
    item.resource_type === ItemObject.Channel
  ) {
    return item.percents_watched;
  }
};

export type HandleClickOnCardProps = {
  history: History;
  card: CardType;
  urlFromDeepLink: ReturnType<typeof useDeepLink>['urlFromDeepLink'];
};

export const handleClickOnCard = ({ history, card, urlFromDeepLink }: HandleClickOnCardProps) => {
  if (!card?.deeplink) {
    return;
  }

  if (card.modification === Modification.CATCHUP) {
    return history.push(
      `/media-item/${ItemObject.ProgramEvent}/${card.resource_id}/player?player=true`,
    );
  }

  if (card.resource_type === ItemObject.Person) {
    return history.push(`/people/${card.resource_id}`);
  }
  const url = urlFromDeepLink(card.deeplink, card.modification);

  if (url) {
    history.push(url);
  }
};
