import * as React from 'react';



type Props = Readonly<{
  focusedLine: number;
  step: number;
  children: any;
  duration?: number;
}>;

const TiledViewScroll = (props: Props) => {
  const transform = `translate3d(0px, -${(props.focusedLine * props.step)}px, 0)`;

  return (
    <div
      style={ {
        willChange: 'transform',
        width: '89.4vw',
        height: '100vh',
        padding: '1.45vw',
        transform,
        WebkitTransform: transform,
      } }
    >
      { props.children }
    </div>
  );
};


export default React.memo(TiledViewScroll);
