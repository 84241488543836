import * as cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';


type Props = Readonly<{
  progress: number;
  progressClassName?: string;
}>;


const Progress: React.FC<Props> = (props: Props) => {
  const properties = {
    className: cn(styles.currentEventProgress, props.progressClassName),
    style: {
      width: `${props.progress}%`,
    },
  };

  return (
    React.createElement(
      'div',
      properties,
    )
  );
};


export default Progress;
