import { useMutation } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import Invoice from '~typings/Invoice';
import ResponseSingle from '~typings/ResponseSingle';

type Params = Readonly<{
  planID: string,
  promocode?: string;
  paymentMethod?: string;
}>;

const fetch = async (client, accessToken, planID, paymentMethod, promocode ) => {
  if (!accessToken || !planID) { return undefined; }

  const params: any = {
    access_token: accessToken,
    'plan_id': planID,
    'payment_method_type': paymentMethod,
  };
  if (promocode) {
    params['promo_code'] = promocode;
  }

  return await client.post(
    '/v1/payments/invoices',
    params
  );
};


const useInvoice = () => {
  const { accessToken } = getAuthData();

  const client = useClient();

  return useMutation<ResponseSingle<Invoice>, void, Params>(
    ({ planID, paymentMethod, promocode, }: Params) =>
      fetch(client, accessToken, planID, paymentMethod, promocode ),
  );
};


export default useInvoice;
