enum KeyBoardType {
  Default = 'default',
  Number = 'number',
  EmailPhone = 'emailPhone',
  Password = 'password',
  PasswordUpperCase = 'passwordUpperCase',
  SpecialChars = 'specialChars',
  Pin = 'pin',
  Promocode='promocode',
  PromocodeLowerCase='promocodeLowerCase',
  Search='search',
  SearchRU='searchRU',
  SearchENG='searchENG',
  Username='username',
  UsernameRuUp='usernameRuUp',
  UsernameRuLo='usernameRuLo',
  UsernameEngUp='usernameEngUp',
  UsernameEngLo='usernameEngLo',
}

enum KeyBoardKeyType {
  Char = 'char',
  Space = 'space',
  Backspace = 'backspace',
  Switch = 'switchKeyBoard',
  UpperCase = 'upperCase',
  LowerCase = 'lowerCase',
  ShowValueOn = 'showValueOn',
  ShowValueOff = 'showValueOff',
  Close = 'close',
  SpecialChars = 'specialChars',
  EmailPhoneChars = 'emailPhoneChars',
  SwitchLang = 'switchLang',
  Search = 'search',
}

type KeyBoardKeyValue = Readonly<string | number>;

type KeyBoardKey = Readonly<{
  id?: KeyBoardKeyValue;
  type: KeyBoardKeyType;
  disabled?: boolean;
  isActive?: boolean;
}>;

export {
  KeyBoardKey,
  KeyBoardKeyType,
  KeyBoardType,
};
