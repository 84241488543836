import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import AgreementBlock from '~components/AgreementBlock';
import CatchBack from '~components/PageManager/CatchBack';
import { ActionType, useAppAction } from '~components/Provider/App';
import { ACCEPT,POLICY_ANNOUNCEMENT } from '~localization';
import NavigationDirection from '~typings/NavigationDirection';
import Button from '~ui/button';
import { Popup } from '~ui/Popup';

import * as styles from './styles.module.css';

enum FocusOn {
  ConfirmButton,
  AgreementBlock
}

type Props = Readonly<{
  onBack?: () => void;
  onAccept: () => void;
}>;


const PolicyConfirmationPopup: React.FC<Props> =(props: Props) => {
  const appAction = useAppAction();
  const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.ConfirmButton);

  const handleBack = React.useCallback(() => {
    if (props.onBack) {
      props.onBack();
    }
  }, []);

  const acceptHandler = () => {
    appAction({
      type: ActionType.SetAgreementStatus,
      payload: { isAgreementConfirmed: true }
    });

    props.onAccept();
  }

  const leaveButtonHandler = (dir: NavigationDirection) => {
    if (dir === NavigationDirection.Down) {
      setFocusOn(FocusOn.AgreementBlock);
    }
  };

  const leaveAgreementHandler = (dir: NavigationDirection) => {
    if (dir === NavigationDirection.Up) {
      setFocusOn(FocusOn.ConfirmButton);
    }
  };

  return (
    <Popup>
      <CatchBack
        action={ handleBack }
        triggers={ [props.onBack] }
      />

      <div className={ styles.wrapper }>
        <div className={ styles.agreementText }>
          <FormattedMessage id={ POLICY_ANNOUNCEMENT } />
        </div>

        <Button
          isFocused={ focusOn === FocusOn.ConfirmButton }
          onClick={ acceptHandler }
          onLeave={ leaveButtonHandler }
        >
          <FormattedMessage id={ ACCEPT } />
        </Button>

        <div className={ styles.agreementBlock }>
          <AgreementBlock
            isFocused={ focusOn === FocusOn.AgreementBlock }
            onLeave={ leaveAgreementHandler }
          />
        </div>
      </div>
    </Popup>
  );
};


export default PolicyConfirmationPopup;
