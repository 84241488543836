import React, { useState } from "react";

import PinCodePopup from "~components/PinCodePopup";
import usePlayerStateActions from "~components/Provider/Player/actions";
import { ConfirmationStatus } from "~components/Provider/Player/component.helpers";
import { useAccountMutation } from "~hooks/fetch/useAccount";
import { useOldNavigationBlocker } from "~hooks/useOldNavigationBlocker";

import { ExtractFlowStateFromAccount$, PinCodeFlowState } from './components/ExtractFlowStateFromAccount'
import { AskToSetPinOrParental$ } from "./states/AskToSetPinOrParental$";

export type PincodeLocalState = {
  state: PinCodeFlowState | undefined
  extraData?: {
    parentalControlFlowPassed: boolean | undefined
  }
}

const NewPinCodeFlow = () => {
  const [localState, setLocalState]
    = useState<PincodeLocalState>({ state: undefined })
  const { flush, setConfirmationRequired } = usePlayerStateActions();
  const { mutateAsync } = useAccountMutation();

  useOldNavigationBlocker()

  if (!localState.state) {
    return <ExtractFlowStateFromAccount$ setLocalState={ setLocalState } />
  }

  switch (localState.state) {
    case PinCodeFlowState.enterPin: {
      const handleGoBack = () => {
        flush()
      }

      const handleSuccess = () => {
        if (localState.extraData?.parentalControlFlowPassed === false) {
          mutateAsync(
            {
              parental_control_flow_passed: true,
            }
          )
        }
        setConfirmationRequired(ConfirmationStatus.WithoutConfirmation)
      }

      return <PinCodePopup
        onSuccess={ handleSuccess }
        onBack={ handleGoBack }
      />
    }
    case PinCodeFlowState.wrongState:
    case PinCodeFlowState.skipToWatch: {
      setConfirmationRequired(ConfirmationStatus.WithoutConfirmation);
      return null
    }
    case PinCodeFlowState.askToSetParentalControl: {
      return <AskToSetPinOrParental$ from={ 'parental' } />
    }
    case PinCodeFlowState.askToSetPinCode: {
      return <AskToSetPinOrParental$ from={ 'pin-code' } />
    }
  }
}

export const NewPinCodeFlow$ = NewPinCodeFlow