// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controlField--uDl5P {\n  /* 16px */\n  margin-bottom: 1.25vw;\n}\n\n.controlField--uDl5P:last-child {\n  margin-bottom: 0;\n}\n\n.checkboxLine--F6q8H {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  -webkit-box-pack: start;\n  -webkit-justify-content: flex-start;\n     -moz-box-pack: start;\n          justify-content: flex-start;\n\n  /* 40px */\n  margin-top: 3.125vw;\n}\n\n.checkboxButton--yFa1F {\n  /* 20px */\n  margin-right: 1.5625vw;\n}\n\n.logoutButton--RDFGs {\n  /* 70px */\n  margin-top: 5.46875vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/screens/AccountScreen/components/Tabs/Control/Control.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,uBAA2B;EAA3B,mCAA2B;KAA3B,oBAA2B;UAA3B,2BAA2B;;EAE3B,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,qBAAqB;AACvB","sourcesContent":[".controlField {\n  /* 16px */\n  margin-bottom: 1.25vw;\n}\n\n.controlField:last-child {\n  margin-bottom: 0;\n}\n\n.checkboxLine {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n\n  /* 40px */\n  margin-top: 3.125vw;\n}\n\n.checkboxButton {\n  /* 20px */\n  margin-right: 1.5625vw;\n}\n\n.logoutButton {\n  /* 70px */\n  margin-top: 5.46875vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var controlField = "controlField--uDl5P";
export var checkboxLine = "checkboxLine--F6q8H";
export var checkboxButton = "checkboxButton--yFa1F";
export var logoutButton = "logoutButton--RDFGs";
export default ___CSS_LOADER_EXPORT___;
