import * as React from 'react';

import * as styles from './styles.module.css';


type Props = Readonly<{
  channelCode: number | null;
}>;


const RemoteDigitCode: React.FC<Props> = (props: Props) => {
  if (props.channelCode === null) { return null; }

  return (
    <div
      className={ styles.channelCode }
    >
      { props.channelCode }
    </div>
  );
};


export default RemoteDigitCode;
