import * as React from 'react';

import PinCodePopup from '~components/PinCodePopup';
import { PinActionType, useSecurityPinMutation } from '~hooks/fetch/useAccount';
import {
  ACCOUNT_SECURITY_CONFIRM_PIN_TITLE,
  ACCOUNT_SECURITY_CREATE_PIN_TEXT,
  ACCOUNT_SECURITY_CREATE_PIN_TITLE,
  ACCOUNT_SECURITY_ENTER_PIN_CODE,
  ACCOUNT_SECURITY_ENTER_PIN_TEXT,
  ACCOUNT_SECURITY_NEW_PIN_TEXT,
  PIN_NOT_EQUAL,
  SECURITY_CODE,
} from '~localization';

export enum ProcedureType {
  Creation = 'creation',
  Recreation = 'recreation',
  Removal = 'removal',
  Change = 'change',
}

export enum Step {
  Enter = 'enter',
  Create = 'create',
  Recreate = 'recreate',
  Confirm = 'confirm',
}

const Messages: Record<Step, string> = {
  [Step.Enter]: ACCOUNT_SECURITY_ENTER_PIN_CODE,
  [Step.Create]: ACCOUNT_SECURITY_CREATE_PIN_TEXT,
  [Step.Recreate]: ACCOUNT_SECURITY_NEW_PIN_TEXT,
  [Step.Confirm]: ACCOUNT_SECURITY_ENTER_PIN_TEXT,
};

const Headers: Record<Step, string> = {
  [Step.Enter]: SECURITY_CODE,
  [Step.Create]: ACCOUNT_SECURITY_CREATE_PIN_TITLE,
  [Step.Recreate]: ACCOUNT_SECURITY_CREATE_PIN_TITLE,
  [Step.Confirm]: ACCOUNT_SECURITY_CONFIRM_PIN_TITLE,
};

type Props = Readonly<{
  procedureType: ProcedureType | null;
  onDone: () => void;
  onBack?: () => void;
}>;

export const PinCodeManager: React.FC<Props> = ({
                                           procedureType,
                                           onDone,
                                           onBack,
                                         }: Props) => {
  const [step, setStep] = React.useState<Step>(
     Step.Enter
  );

  const [currentPin, setCurrentPin] = React.useState('');
  const [passForConfirm, setPassForConfirm] = React.useState('');
  const [errorMessageId, setErrorMessageId] = React.useState<string | null | undefined>();
  const [isNeedFlushPinPopupState, setIsNeedFlushPinPopupState] = React.useState<boolean>(false);
  const { mutate: securityPinAction } = useSecurityPinMutation();

  const backHandler = React.useCallback(() => {
    if (step === Step.Confirm) {
      setPassForConfirm('');
      setStep(Step.Create);
      if (procedureType === ProcedureType.Creation) {
        setStep(Step.Create);
        return;
      }

      if (procedureType === ProcedureType.Recreation || procedureType === ProcedureType.Change) {
        setStep(Step.Recreate);
        return;
      }

      return;
    }

    if (onBack) {
      onBack();
    } else {
      onDone()
    }
  }, [step, procedureType]);

  React.useEffect(() => {
    switch (procedureType) {
      case ProcedureType.Creation:
        setStep(Step.Create);
        break;
      case ProcedureType.Recreation:
        setStep(Step.Recreate);
        break;
      case ProcedureType.Removal:
      case ProcedureType.Change:
        setStep(Step.Enter);
        break;
    }

    setErrorMessageId(null);
  }, [procedureType]);

  React.useEffect(() => {
    if (isNeedFlushPinPopupState) {
      const timeout = setTimeout(() => {
        setIsNeedFlushPinPopupState(false);
      }, 100);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [isNeedFlushPinPopupState]);

  if (!procedureType) return null;

  const headerId = Headers[step];
  const messageId = Messages[step];

  const successHandler = (code: string, wasDropped?: boolean) => {
    if (wasDropped) {
      return onDone();
    }

    setIsNeedFlushPinPopupState(true);

    switch (procedureType) {
      case ProcedureType.Creation:
        if (step === Step.Create) {
          setErrorMessageId(null);
          setPassForConfirm(code);
          setStep(Step.Confirm);
        } else if (step === Step.Confirm) {
          if (passForConfirm === code) {
            securityPinAction({
              type: PinActionType.Creation,
              payload: { pin: code },
            });
            onDone();
          } else {
            setPassForConfirm('');
            setStep(Step.Create);
            setErrorMessageId(PIN_NOT_EQUAL);
          }
        }
        break;

      case ProcedureType.Recreation:
        if (step === Step.Recreate) {
          setErrorMessageId(null);
          setPassForConfirm(code);
          setStep(Step.Confirm);
        } else if (step === Step.Confirm) {
          if (passForConfirm === code) {
            securityPinAction({
              type: PinActionType.Creation,
              payload: { pin: code },
            });
            onDone();
          } else {
            setPassForConfirm('');
            setStep(Step.Recreate);
            setErrorMessageId(PIN_NOT_EQUAL);
          }
        }
        break;

      case ProcedureType.Removal:
        securityPinAction({
          type: PinActionType.Removal,
          payload: { current_pin: code },
        });
        onDone();
        break;

      case ProcedureType.Change:
        if (step === Step.Enter) {
          setCurrentPin(code);
          setStep(Step.Recreate);
        } else if (step === Step.Recreate) {
          setErrorMessageId(null);
          setPassForConfirm(code);
          setStep(Step.Confirm);
        } else if (step === Step.Confirm) {
          if (passForConfirm === code) {
            securityPinAction({
              type: PinActionType.Change,
              payload: { pin: code, current_pin: currentPin },
            });
            onDone();
          } else {
            setPassForConfirm('');
            setStep(Step.Recreate);
            setErrorMessageId(PIN_NOT_EQUAL);
          }
        }
        break;
    }
  };

  return (
    <>
      {
        <PinCodePopup
          isNeedFlushPinPopupState={ isNeedFlushPinPopupState }
          onSuccess={ successHandler }
          onBack={ backHandler }
          messageId={ messageId }
          headerTextId={ headerId }
          errorId={ errorMessageId }
          withoutValidation={ step !== Step.Enter }
        />
      }
    </>
  );
};
