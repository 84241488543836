import * as cn from 'classnames';
import * as React from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';

import { SELECT_SUBSCRIPTION_APPLY_PROMO } from '~localization';
import { IconTextItem } from '~ui/Icon';
import OnwardIcon from '~ui/Icon/icons/Onward';

import * as styles from '../styles.module.css';

type Props = Readonly<{
  promocode: string;
  onClick: () => void;
  intl: InjectedIntl;
}>;

const PromocodeSubscrField: React.FC<Props> = (props: Props) => {
  return (
    <>
      <div className={ styles.promocodeValue }>
        { props.promocode }
      </div>
      <div
        onClick={ () => props.onClick() }
        className={ cn(styles.dropdownLink, 'focusedNavigationNode') }
      >
        <FormattedMessage id={ SELECT_SUBSCRIPTION_APPLY_PROMO } />
        
        <IconTextItem
          Icon={ OnwardIcon }
          className={ styles.selectSubscrIcon }
          isFocused
        />
      </div>
    </>
  )
};

export default injectIntl(PromocodeSubscrField);
