import * as React from 'react';

import { ArrowRightIcon } from '~ui/Icon/icons/ArrowRight';


type Props = {
  className?: {
    wrapper?: string;
    icon?: string;
  };
};

export const PlayButtonInPurchases: React.FC<Props> = ({ className }) => {
  return (
    <div className={ className?.wrapper }>
      <ArrowRightIcon className={ className?.icon }/>
    </div>
  );
};

