import { FAQ_CONTACTS_SECTION_TITLE,FAQ_CONTENT_SECTION_TITLE, FAQ_MAIN_SECTION_TITLE, FAQ_PAYMENTS_SECTION_TITLE } from "~lib/localizator/src/dictionaries";
import QuestionSection from "~typings/QuestionSection";

export const sectionTitles = {
  [QuestionSection.Main]: FAQ_MAIN_SECTION_TITLE,
  [QuestionSection.Content]: FAQ_CONTENT_SECTION_TITLE,
  [QuestionSection.Payments]: FAQ_PAYMENTS_SECTION_TITLE,
  [QuestionSection.Contacts]: FAQ_CONTACTS_SECTION_TITLE
};


export const sectionsByPriority = [
  QuestionSection.Main,
  QuestionSection.Content,
  QuestionSection.Payments,
  QuestionSection.Contacts,
];
