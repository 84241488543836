import * as React from 'react';


const useCurrentTime = (getCurrentTime: () => number, intervalInMS: number = 1000): number => {
  const timer: any = React.useRef(null);
  const [currentTime, setCurrentTime] = React.useState<number>(0);
  const handleUpdateCurrentTime = () => {
    if (timer.current) { clearTimeout(timer.current); }

    setCurrentTime(getCurrentTime());

    timer.current = setTimeout(handleUpdateCurrentTime, intervalInMS);
  };

  React.useEffect(() => {
    handleUpdateCurrentTime();

    return () => {
      if (timer.current) { clearTimeout(timer.current); }
    };
  }, []);

  return currentTime;
};


export default useCurrentTime;
