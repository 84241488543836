import cn from "classnames";
import * as React from 'react';

import { getLineOptions, LineFocusInfo } from '~components/NavMenu/line/component.helpers';
import { RestLineItem } from "~components/NavMenu/line/restLines/lineItem";
import { useMenu } from "~components/Provider/Menu";
import useDelayedChange from "~hooks/useDelayedChange";
import useNavMenu from '~stores/NavMenu';
import MenuItem from '~typings/MenuItem';

import * as restLineCSS from './styles.module.css'

interface Props {
  isVisible: boolean;
  isAuthorized: boolean | null;
  index: number;
  items: MenuItem[];
  breadCrumbs: LineFocusInfo[];
  onClick?: (coordinates: LineFocusInfo) => void;
}

const Line = (props: Props) => {
    const isHovered = useNavMenu(state => state.isHovered);
    const {
      subMenu,
      focusInfo,
      isFocused,
      isHideOnTop,
      isCSSAnimationAvailable,
    } = getLineOptions(props.index, props.items, props.breadCrumbs);
    const isVisibleSubMenu = useDelayedChange<boolean>(!!subMenu, 100);
    const { isMenuOpened } = useMenu();

    const anotherLine = React.useMemo(() => {
      if (subMenu) {
        return (
          <Line
            isAuthorized={ props.isAuthorized }
            isVisible={ isVisibleSubMenu }
            items={ subMenu }
            index={ props.index + 1 }
            breadCrumbs={ props.breadCrumbs }
          />
        );
      }

      return null;
    }, [
      subMenu,
      props.isAuthorized,
      isVisibleSubMenu,
      props.index,
      props.breadCrumbs,
    ]);

    const lineItems = React.useMemo(() => (
      props.items.map((item: MenuItem, index: number) => (
        <RestLineItem
          isAuthorized={ props.isAuthorized }
          key={ item.id }
          id={ item.id }
          image={ item.image }
          isFocused={ (isMenuOpened && isFocused && !isHovered && index === focusInfo.x) }
          isActive={ isHovered && isFocused && index === focusInfo.x }
          title={ item.title }
          link={ item.link }
          index={ index }
          lineIndex={ props.index }
        />
      ))
    ), [props.isAuthorized, props.items, isHovered, isFocused, focusInfo.x, isMenuOpened]);


    return (
      <>
        <div className={ cn(restLineCSS.lineItemsWrapper, {
          [restLineCSS.navMenuLineWithAnimation]: isCSSAnimationAvailable,
          [restLineCSS.navMenuLineVisible]: props.isVisible,
          [restLineCSS.navMenuLineHideOnTop]: isHideOnTop,
        }) }>
          { lineItems }
        </div>
        { anotherLine }
      </>
    )
  }
;


export const RestLines = React.memo(Line)
