import * as React from 'react';

import { usePlayerStore } from "~stores/Player";
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Stream from '~typings/Stream';

import { PlayerStatus } from '../useHandleEvents';
import PlayPauseButton, { PlayButtonState } from './PlayPauseButton';
import ProgressBar from './ProgressBar';


type Props = Readonly<{
  item: PlayerMediaItem;
  playerState: PlayerStatus;
  isLoading: boolean;
  isFocused: boolean;
  isFocusedGoToAir: boolean;
  durationInSec: number;
  currentTimeInSec: number;
  onClick: () => void;
  onChangeIsVisibleGoToAir: (isVisible: boolean) => void;
  onSeek: (seekTo: number) => void;
  stream?: Stream;
}>;


const PlayPauseProgressBar: React.FC<Props> = (props: Props): JSX.Element | null => {
  const [playButtonState, setPlayButtonState] = React.useState<PlayButtonState>(PlayButtonState.Pause);

  const setPlayPauseButton = usePlayerStore(state => state.setPlayPauseButton)
  const handleKeyPressedToSeek = (seekIntentionDirection) => {
    setPlayButtonState(seekIntentionDirection);
  };
  const handlePlayPauseClicked = React.useCallback(() => {
    if(playButtonState === PlayButtonState.Play) {
      setPlayPauseButton(false)
      props.onClick();
    }
    if(playButtonState === PlayButtonState.Pause) {
      setPlayPauseButton(true)
      props.onClick();
    }

  }, [playButtonState, props.onClick]);

  React.useEffect(() => {
    switch (props.playerState) {
      case PlayerStatus.Play: {
        setPlayButtonState(PlayButtonState.Play);

        break;
      }
      case PlayerStatus.Pause: {
        setPlayButtonState(PlayButtonState.Pause);

        break;
      }
    }
  }, [props.playerState]);

  return (
    <>
      <PlayPauseButton
        state={ playButtonState }
        isFocused={ props.isFocused }
        onClick={ handlePlayPauseClicked }
      />
      <ProgressBar
        item={ props.item }
        isLoading={ props.isLoading }
        isPlaying={ (props.playerState === PlayerStatus.Play) }
        isFocused={ props.isFocused }
        isSeeking={ (props.playerState === PlayerStatus.Seeking) }
        isFocusedGoToAir={ props.isFocusedGoToAir }
        durationInSec={ props.durationInSec }
        currentTimeInSec={ props.currentTimeInSec }
        onChangeIsVisibleGoToAir={ props.onChangeIsVisibleGoToAir }
        onSeek={ props.onSeek }
        onKeyPressedToSeek={ handleKeyPressedToSeek }
        stream={ props.stream }
        status={ props.playerState }
      />
    </>
  );
};


export default React.memo(PlayPauseProgressBar);
