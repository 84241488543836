import cn from 'classnames';
import React from 'react';

import { Nullable } from '~lib/type-utils/utils';

import { CardCommonImage } from '../components/CardCommonImage';
import * as css from './styles.module.css';

interface PropsTS {
  /**
   * Сфокусирована ли карточка (влияет на отображение)
   */
  isFocused: boolean;
  /**
   * Показывать ли блок текста внизу карточки
   */
  showBottomTextBlock?: boolean;
  /**
   * Основной текст, будет отображен в карточке и под карточкой крупным шрифтом
   */
  mainText?: Nullable<string>;
  /**
   * Дополнительный текст, будет отображен в карточке и под карточкой маленьким шрифтом
   */
  additionalText?: Nullable<string>;
  /**
   * Ссылка на изображение
   */
  imageUrl?: Nullable<string>;

  /**
   * Включена ли анимация
   *
   * По умолчанию `true`
   */
  isAnimationEnabled?: boolean;
}

export const NewsCard = React.memo((props: PropsTS)=> {
  const {
    isFocused,
    showBottomTextBlock,
    additionalText,
    mainText,
    imageUrl,
    isAnimationEnabled = true,
  } = props;

  const mainTextLinesCount = (()=> {
    if(additionalText) {
      return isFocused ? 5 : 4
    }

    return 5
  })()

  return (
    <div className={ cn(css.card, {
      [css.cardAnimated]: isAnimationEnabled,
    }) }>
      <div className={ css.imageContainer }>
        <div
          className={ cn(
            css.imageContent,
            isFocused ? css.imageContentFocus : css.imageContentUnfocus,
          ) }
        >
        {imageUrl && <CardCommonImage
            imageUrl={ imageUrl }
          />}
          <div className={ cn(css.textOnImageWrapper, {
            [css.textOnImageWrapperWithImage]: imageUrl,
          }) }>
              {additionalText && <div className={ css.additionalTextOnImage }>
               {additionalText}
            </div>
            }
            {mainText && <div
              style={ {
                WebkitLineClamp: mainTextLinesCount
              } }
            className={ cn(css.mainTextOnImage) }>
              {mainText}
            </div>}

          </div>
        </div>
      </div>
      {showBottomTextBlock && (
        <div className={ css.textContainer }>
          {mainText && (
            <div
              className={ cn(css.textOverflow, css.mainTextBottom, {
                [css.mainTextBottomFocused]: isFocused,
              }) }
            >
              {mainText}
            </div>
          )}
          {additionalText && (
            <div
              className={ cn(css.textOverflow, css.additionalTextBottom, {
                [css.additionalTextBottomFocused]: isFocused,
              }) }
            >
              {additionalText}
            </div>
          )}
        </div>
      )}
    </div>
  );
});

NewsCard.displayName = 'NewsCard';
