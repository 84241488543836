import { useFocuserContext } from '@focuser';
import { FOCUSER_KEY_EVENT_NAME, FocuserKeyEventDetail } from '@focuser/events';
import { useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import usePointer from '~stores/Pointer';
import HotKey from '~typings/HotKey';
import NavigationDirection from '~typings/NavigationDirection';

import { navigationHandler } from '../FButtonNavHandler';
import { getKeyboardXYMapMemoized, lettersKeyToLayoutMap, numbersKeyToLayoutMap } from '../KeyboardLayouts';
import { FBtn, FBtnAction, KeyboardLayout } from '../types';
import { HideBtnState, NaviagableKeyboardAction } from './types';

export type handleBtnClickProps = {
  onInput: (string: string) => void;
  onAction: (action: NaviagableKeyboardAction) => void;
  hideBtnState: string | undefined;
  switchKeyboardLayout: (newLayout: KeyboardLayout) => void;
  currentLayout: string;
  previousLayout: KeyboardLayout | null;
  focusedButton: FBtn;
};
/**
 * Функция обрабатывает клик на конкретную кнопку
 */
export const handleFBtnClick = ({
  onInput,
  onAction,
  hideBtnState,
  switchKeyboardLayout,
  currentLayout,
  previousLayout,
  focusedButton,
}: handleBtnClickProps) => {
  if (!focusedButton.action) {
    onInput(focusedButton.id);
    return;
  }

  const actionMap: Record<FBtnAction, () => void> = {
    space: () => {
      onInput(' ');
    },
    backspace: () => {
      onAction('backspace');
    },
    'hide/show': () => {
      if (!hideBtnState || hideBtnState === 'unnecessary') {
        return;
      }
      if (hideBtnState === 'hidden') {
        onAction('show');
      } else {
        onAction('hide');
      }
    },
    confirm: () => {
      onAction('confirm');
    },
    switch_to_symbols: () => {
      switchKeyboardLayout('symbols');
    },
    switch_to_ru: () => {
      if (currentLayout === 'eng_uppercase') {
        switchKeyboardLayout('ru_uppercase');
        return;
      }
      switchKeyboardLayout('ru_lowercase');
    },
    switch_to_uppercase: () => {
      if (currentLayout === 'eng_lowercase') {
        switchKeyboardLayout('eng_uppercase');
        return;
      }

      if (currentLayout === 'ru_lowercase') {
        switchKeyboardLayout('ru_uppercase');
        return;
      }
    },
    switch_to_lowercase: () => {
      if (currentLayout === 'eng_uppercase') {
        switchKeyboardLayout('eng_lowercase');
        return;
      }

      if (currentLayout === 'ru_uppercase') {
        switchKeyboardLayout('ru_lowercase');
        return;
      }
    },
    switch_to_eng: () => {
      if (currentLayout === 'ru_uppercase') {
        switchKeyboardLayout('eng_uppercase');
        return;
      }

      switchKeyboardLayout('eng_lowercase');
    },
    switch_to_letters: () => {
      switchKeyboardLayout(previousLayout || 'eng_lowercase');
    },
  };

  actionMap[focusedButton.action]();
};

export type NativeKeyboardHandlerProps = {
  currentLayout: KeyboardLayout;
  onInput: (string: string) => void;
  switchKeyboardLayout: (newLayout: KeyboardLayout) => void;
  setFocusedBtn: (button: FBtn) => void;
  isFocused: boolean;
};

export const useNativeKeyboardHandler = (props: NativeKeyboardHandlerProps) => {
  const propsRef = useRef(props);
  propsRef.current = props;

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const { isFocused, currentLayout, onInput, switchKeyboardLayout, setFocusedBtn } = propsRef.current;
      if(!isFocused){
        return;
      }

      if (currentLayout === 'numbers') {
        // В этой раскладке нельзя переключать на другие
        const nextNumberCoords = numbersKeyToLayoutMap[event.key];
        if(!nextNumberCoords){
          return;
        }
        const numberXYMap = getKeyboardXYMapMemoized(currentLayout);
        const nextButton = numberXYMap[nextNumberCoords.x][nextNumberCoords.y];
        setFocusedBtn(nextButton);
        onInput(nextButton.id);
        return;
      }


      const pressedLetterLayouts = lettersKeyToLayoutMap[event.key];

      if (!pressedLetterLayouts) {
        return;
      }

      const pressedLetterCoordsInCurrentLayout = pressedLetterLayouts[currentLayout];

      if (pressedLetterCoordsInCurrentLayout) {
        const currentLayoutMap = getKeyboardXYMapMemoized(currentLayout);
        const { x, y } = pressedLetterCoordsInCurrentLayout;
        const nextButton = currentLayoutMap[x][y];
        setFocusedBtn(nextButton);
        onInput(nextButton.id);
        return;
      }

      const nextLayout = Object.keys(pressedLetterLayouts)?.[0] as KeyboardLayout | undefined;
      if (!nextLayout) {
        return;
      }

      const nextCoord = pressedLetterLayouts[nextLayout];

      if (!nextCoord) {
        return;
      }

      const nextLayoutButtons = getKeyboardXYMapMemoized(nextLayout);

      const nextButton = nextLayoutButtons[nextCoord.x][nextCoord.y];

      unstable_batchedUpdates(() => {
        switchKeyboardLayout(nextLayout);
        setFocusedBtn(nextButton);
      });
      onInput(nextButton.id);
    };

    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, []);
};

export type useFocuserPointerDirectionsProps = {
  focusedButton: FBtn;
  isConfirmBtnActive: boolean;
  isHiddenBtnActive: boolean;
};
export const useFocuserPointerDirections = ({
  focusedButton,
  isConfirmBtnActive,
  isHiddenBtnActive,
}: useFocuserPointerDirectionsProps) => {
  const pointerEnabled = usePointer((state) => state.pointerEnabled);
  const { isPointerEnabled: pointerEnabledInFocuser } = useFocuserContext();

  const [focuserEventEmulation] = useState<FocuserKeyEventDetail>(() => ({
    type: FOCUSER_KEY_EVENT_NAME,
    keyCode: HotKey.VIRTUAL_DOWN,
    stop: () => {},
    stopNativeEvent: () => {},
    nativeEvent: new Event('keydown'),
  }));

  const lastAvailableDirs = useRef<Record<NavigationDirection, boolean>>();

  // Тут мы хакаем навигацию, симулируем переход, чтобы понять, есть ли возможность перехода или нет
  // Так мы узнаем какие стрелки Pointer-а возможны
  const pointerDirections: {
    [key in NavigationDirection]?: boolean;
  } = useMemo(() => {
    if (!pointerEnabled || !pointerEnabledInFocuser) {
      return lastAvailableDirs.current || {};
    }
    const availableDirs: Record<NavigationDirection, boolean> = {
      [NavigationDirection.Up]: false,
      [NavigationDirection.Right]: false,
      [NavigationDirection.Down]: false,
      [NavigationDirection.Left]: false,
    };

    (Object.keys(availableDirs) as NavigationDirection[]).forEach((dir: NavigationDirection) => {
      navigationHandler({
        direction: dir,
        focusedBtn: focusedButton,
        setFocus: () => {
          availableDirs[dir] = true;
        },
        isConfirmBtnActive,
        isHiddenBtnActive,
        focuserEvent: focuserEventEmulation,
      });
    });

    lastAvailableDirs.current = availableDirs;
    return availableDirs;
  }, [focusedButton, isConfirmBtnActive, isHiddenBtnActive, pointerEnabled]);

  return {
    pointerDirections,
  };
};

export const checkCanButtonFocus = ({
  btn,
  isConfirmBtnActive,
  hideBtnState,
}: {
  btn: FBtn,
  isConfirmBtnActive: boolean,
  hideBtnState?: HideBtnState,
})=>{
  if(btn.alwaysDisabled){
    return false;
  }

  if(btn.action === 'confirm' && !isConfirmBtnActive){
    return false;
  }

  if(btn.action === 'hide/show' && (!hideBtnState || hideBtnState === 'unnecessary')){
    return false;
  }

  return true;
}
