import { memoize } from 'lodash';

import { FBtn, KeyboardLayout } from '../types';
import { getKeyboardButtonsByLayout } from './getKeyboardButtonsByLayout';

export type ButtonsXYMap = {
  [x: number]: {
    [y: number]: FBtn;
  };
};


export const getKeyboardXYMapMemoized = memoize((layout: KeyboardLayout): ButtonsXYMap => {
  const btns = getKeyboardButtonsByLayout(layout);
  const buttonsXYMap: ButtonsXYMap = {};

  btns.forEach((btn) => {
    buttonsXYMap[btn.coord.x] = buttonsXYMap[btn.coord.x] || {};
    buttonsXYMap[btn.coord.x][btn.coord.y] = btn;
  });

  return buttonsXYMap;
});
