import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Loader from '~components/LightLoader/Loader';
import * as productsStyles from '~components/ProductPlanPage/styles.module.css';
import * as promocodeStyles from '~components/PromocodeActivation/styles.module.css';
import { removeStreamAccessQueries } from '~hooks/fetch/useStreamAccess';
import usePaymentProceed from '~hooks/usePaymentProceed/usePaymentProceed';
import * as URLSearchManager from '~lib/URLQueryStringManager';
import {
  AUTH_ENTER_PASSWORD,
  ERROR_HAS_OCCURRED,
  GO_TO_VIEW,
  NAVIGATION_BACK,
  PAYMENT_IN_PROGRESS,
  PAYMENT_WAS_SUCCESSFUL,
  PROMO_CONGRATULATIONS,
  PROMO_NAVIGATE_TO_START_PAGE,
} from '~localization';
import { DataItemCollection } from '~typings/DataItem';
import Button from '~ui/button';
import CheckIcon from '~ui/Icon/icons/Check';
import ErrorIcon from '~ui/Icon/icons/ErrorIcon';
import { Popup } from '~ui/Popup';
import { SelectList } from '~ui/SelectList';


enum PromocodeSuccessActions {
  NavigateToMediaItemPage = 'navigate_to_home',
  NavigateToPromocodeInput = 'navigate_to_promocode_input'
}

const actionsList: [PromocodeSuccessActions, string][] = [
  [
    PromocodeSuccessActions.NavigateToMediaItemPage,
    GO_TO_VIEW,
  ],
  [
    PromocodeSuccessActions.NavigateToPromocodeInput,
    PROMO_NAVIGATE_TO_START_PAGE,
  ],
];


type Props = Readonly<{
  mediaItem: DataItemCollection;
  intl: InjectedIntl;
}>;

const PromoPaymentProceed: React.FC<Props> = (props) => {
  const history = useHistory();
  const { isError, isSuccess, error } = usePaymentProceed();
  const rentPlanId = URLSearchManager.getField(history.location.search, 'rentPlanId');
  const promocode = URLSearchManager.getField(history.location.search, 'promo_code');

  const handleBack = () => {
    history.goBack();
  };

  const handleContinue = () => {
    removeStreamAccessQueries();

    history.goBack();
  };

  const navHandler = (id: number) => {
    const [action] = actionsList[id];

    if (action === PromocodeSuccessActions.NavigateToMediaItemPage) {
      history.goBack();
    } else if (action === PromocodeSuccessActions.NavigateToPromocodeInput) {
      history.replace('/promo');
    }
  };

  return (
    <Popup>
      {
        (isSuccess && promocode && rentPlanId) ? (
          <div className={ productsStyles.contentWrapper }>
              <div className={ promocodeStyles.promocodeValue }>
                { promocode }
              </div>
              <div className={ promocodeStyles.confirmSubscrName }>
                { props.intl.formatMessage({ id: PROMO_CONGRATULATIONS }) }
              </div>
              <SelectList
                items={ actionsList.map(([, text]) => props.intl.formatMessage({ id: text })) }
                onSelect={ navHandler }
                className ={ promocodeStyles.confirmButtons }
                isFocused
              />
          </div>
        ) : (
          <div className={ productsStyles.contentWrapper }>
            <div className={ productsStyles.infoIconWrapper }>
              {
                (isError) ? (
                  <ErrorIcon className={ cn(productsStyles.icon, productsStyles.errorIcon) } />
                ) : (isSuccess) ? (
                  <CheckIcon className={ cn(productsStyles.icon, productsStyles.successIcon) } />
                ) : (
                  <Loader />
                )
              }
            </div>
            <div className={ productsStyles.info }>
              {
                (isError) ? error?.message || (
                  props.intl.formatMessage({ id: ERROR_HAS_OCCURRED })
                ) : (isSuccess) ? (
                  props.intl.formatMessage({ id: PAYMENT_WAS_SUCCESSFUL })
                ) : (
                  props.intl.formatMessage({ id: PAYMENT_IN_PROGRESS })
                )
              }
            </div>
            {
              (isError) ? (
                <Button
                  isFocused
                  className={ cn(productsStyles.button, productsStyles.center, productsStyles.isFocused) }
                  onClick={ handleBack }
                >
                  {
                    props.intl.formatMessage({ id: NAVIGATION_BACK })
                  }
                </Button>
              ) : (isSuccess) ? (
                <Button
                  isFocused
                  className={ cn(productsStyles.button, productsStyles.center, productsStyles.isFocused) }
                  onClick={ handleContinue }
                >
                  {
                    props.intl.formatMessage({ id: AUTH_ENTER_PASSWORD })
                  }
                </Button>
              ) : null
            }
          </div>
        )
      }
    </Popup>
  );
};


export default injectIntl(PromoPaymentProceed);
