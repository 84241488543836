/* eslint-disable max-len */
import { ac_00, gw_00, gw_02, gw_04, gw_11 } from 'customization-project-name/colors';
import * as React from 'react';

import { iconLike } from './styles.module.css';

export const IconLikeBase: React.FC<{ isFocused: boolean; isPressed: boolean }> = ({
  isFocused,
  isPressed,
}) => (
  <svg
    className={ iconLike }
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="22"
      cy="22"
      r="22"
      fill={ isFocused ? ac_00 : isPressed ? gw_11 : gw_02 }
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2329 20.0541C32.2142 19.2885 32.1304 18.775 31.6365 18.4669C30.8725 18.0001 28.4499 18.3082 27.4994 18.4669C24.8998 18.9057 24.6948 18.9337 25.1607 17.72C25.2818 17.4025 26.0832 15.6939 26.167 13.5465C26.195 12.9022 25.8595 11.5017 25.8595 11.5017C25.8595 11.5017 25.17 9.40099 23.8189 9.96119C22.2536 14.0133 21.8343 16.1327 20.39 18.1308C18.9551 20.1101 16.8586 20.8758 16.2343 21.7814C15.6007 22.6964 15.5821 25.4881 16.0293 26.8699C16.6163 28.6999 17.0915 30.4552 20.4739 31.0994C21.8902 31.3982 24.406 31.3608 25.4868 31.2581C26.5584 31.1461 28.2076 30.6606 28.9437 29.8763C29.6798 29.092 29.773 28.5225 30.0898 27.8129C30.4066 27.1033 31.5061 25.6841 31.6458 24.5357C31.7856 23.378 31.5061 22.7057 31.6738 22.1082C31.8415 21.5106 32.2981 20.8197 32.2701 20.0541H32.2329ZM15.3491 21.9028C15.3491 21.9028 14.6317 21.5947 13.4297 21.5947C11.9947 21.5947 11.212 22.5657 10.9232 24.2463C10.6344 25.9269 10.9046 27.4861 11.3984 28.2517C11.8363 28.9426 12.4047 29.6149 13.4949 30.091C14.3428 30.4645 16.0945 30.866 16.4113 29.979C15.6287 28.7932 15.3584 28.3918 15.0975 27.4954C14.6689 26.0483 14.6689 24.7318 14.8087 23.7048C14.9485 22.6777 15.3584 21.9028 15.3584 21.9028H15.3491Z"
      fill={ isFocused ? gw_00 : isPressed ? gw_04 : gw_11 }
    />
  </svg>
);
