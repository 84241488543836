import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import PinCodePopup from '~components/PinCodePopup';
import * as productsStyles from '~components/ProductPlanPage/styles.module.css';
import { useAccount } from '~hooks/fetch/useAccount';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import * as URLSearchManager from '~lib/URLQueryStringManager';
import {
  CONFIRM,
  MOBILE_PAYMENT,
  NAVIGATION_BACK,
  PAY_NOW,
  PAYMENT_METHOD,
  PROMOCODE,
  WILL_BE_DEBITED_FROM_YOUR_ACCOUNT,
} from '~localization';
import Invoice from '~typings/Invoice';
import NavigationDirection from '~typings/NavigationDirection';
import Plan from '~typings/Plan';
import RentPlan from '~typings/RentPlan';
import Button from '~ui/button';


type Props = Readonly<{
  plan: RentPlan | Plan | undefined;
  invoice: Invoice | undefined;
  url: string | null | undefined;
  focusOnMenu: boolean;
  onMenu?: (arg?: boolean) => void;
  isFreeAccessPromo?: boolean;
  intl: InjectedIntl;
}>;

const InvoicePage: React.FC<Props> = ({ plan, invoice, url, onMenu, focusOnMenu, isFreeAccessPromo, intl }) => {
  const history = useHistory();
  const { data: accountInfo } = useAccount();
  const [isPinCodePopup, showPinCodePopup] = React.useState<boolean>(false);
  const rentPlanId = URLSearchManager.getField(history.location.search, 'rentPlanId');
  const promocode = URLSearchManager.getField(history.location.search, 'promo_code');

  const navigationItems = React.useRef([
    { maxIndex: 1 }, // Buttons
  ]);
  const navigationState = useSpatialNavigation({
    allowNavigation: !focusOnMenu,
    navigationItems: navigationItems.current,
    onLeave: (direction) => {
      if (direction === NavigationDirection.Up && onMenu) {
        onMenu();
      }
    }
  });
  const focusIndex = navigationState.focusedIndex[0];

  const handleBack = () => {
    history.goBack();
  };

  const closePinCodePopup = () => {
    showPinCodePopup(false);
  }
  
  const handleProceed = () => {
    if (accountInfo?.security_pin) {
      showPinCodePopup(true);
    } else {
      if (isFreeAccessPromo) {
        history.replace(`${url}/payment/${invoice?.id}?promo_code=${promocode}&rentPlanId=${rentPlanId}`);
      } else {
        history.replace(`${url}/payment/${invoice?.id}`);
      }
    }
  };

  const handleValidPinCode = () => {
    if (isFreeAccessPromo) {
      history.replace(`${url}/payment/${invoice?.id}?promo_code=${promocode}&rentPlanId=${
        rentPlanId
      }`);
    } else if (rentPlanId && promocode) {
         history.replace(`${url}/${invoice?.id}?promo_code=${promocode}&rentPlanId=${rentPlanId}`);
    } else {
      history.replace(`${url}/payment/${invoice?.id}`);
    }
  };

  if (!plan) { return null; }

  return (
    <>
      <div className={ productsStyles.buttonWrapper }>{ plan.name }</div>
      <div className={ productsStyles.buttonWrapper }>
        {
          intl.formatMessage({ id: PAYMENT_METHOD })
        }
        { <span>&nbsp;</span> }
        {
          isFreeAccessPromo ?
            intl.formatMessage({ id: PROMOCODE })
            :
            intl.formatMessage({ id: MOBILE_PAYMENT })
        }
      </div>
      <div className={ productsStyles.invoceWrapper }>
        {
          intl.formatMessage({ id: WILL_BE_DEBITED_FROM_YOUR_ACCOUNT }, {
            sum: invoice?.total_amount.formatted,
          })
        }
      </div>
      <div className={ productsStyles.buttonWrapper }>
        <Button
          isFocused={ !focusOnMenu && !isPinCodePopup && focusIndex === 0 }
          className={ cn(productsStyles.button, {
            [productsStyles.isFocused]: !focusOnMenu && !isPinCodePopup && focusIndex === 0,
          }) }
          onClick={ handleProceed }
        >
          {
            invoice?.total_amount.value === 0 ?
              intl.formatMessage({ id: CONFIRM })
              :
              intl.formatMessage({ id: PAY_NOW })
          }
        </Button>
        <Button
          isFocused={ !focusOnMenu && !isPinCodePopup && focusIndex === 1 }
          className={ cn(productsStyles.button, {
            [productsStyles.isFocused]: !focusOnMenu && !isPinCodePopup && focusIndex === 1,
          }) }
          onClick={ handleBack }
        >
          {
            intl.formatMessage({ id: NAVIGATION_BACK })
          }
        </Button>
      </div>
      {
        isPinCodePopup ? (
          <PinCodePopup 
            onSuccess={ handleValidPinCode } 
            onBack={ closePinCodePopup } />
        ) : null
      }
    </>
  );
};


export default injectIntl(InvoicePage);
