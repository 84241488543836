// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".timeRangeWrapper--ZVA9a {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.timeColor--gVYxI {\n  color: rgba(162, 162, 173, 1)\n}", "",{"version":3,"sources":["webpack://./src/newapp/blocks/playerPopcorn/Player/controls/Popup/ChannelFlipper/ChannelBlock/CardInfo/TimeRange.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;;AAEA;EACE;AACF","sourcesContent":[".timeRangeWrapper {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.timeColor {\n  color: $gw_06\n}"],"sourceRoot":""}]);
// Exports
export var timeRangeWrapper = "timeRangeWrapper--ZVA9a";
export var timeColor = "timeColor--gVYxI";
export default ___CSS_LOADER_EXPORT___;
