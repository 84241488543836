export const convertOSName = (osName:string) => {
  switch (osName) {
    case 'ios':
      return 'iOS';
    case 'android':
      return 'Android';
    case 'webos':
      return 'WebOS';
    case 'tizen':
      return 'Tizen';

    default:
      return osName;
  }
}

export const DEVICE_IDX_PATH_IN_SEARCH = 'deviceIdx'
