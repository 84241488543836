/* eslint-disable max-len */
import * as React from 'react';

const IconTVOnAir = () => (
  <svg data-name="Layer 2" viewBox="0 0 47.34 47.34">
    <path
      d="M35 43a1.17 1.17 0 010 2.33H12.33a1.17 1.17 0 010-2.33H35m0-2H12.33a3.17 3.17 0 000 6.33H35A3.17 3.17 0 0035 41zm8.34-39a2 2 0 012 2v29.35a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h39.34m0-2H4a4 4 0 00-4 4v29.35a4 4 0 004 4h39.34a4 4 0 004-4V4a4 4 0 00-4-4zM16 26.57V10.78a1.42 1.42 0 012.09-1.25l14.7 7.87a1.42 1.42 0 010 2.49l-14.7 7.92A1.42 1.42 0 0116 26.57z"
      fill="#fff"
    />
  </svg>
);

export default IconTVOnAir;
