import * as cn from 'classnames';
import { throttle } from 'lodash';
import * as React from 'react';

import { getKeyNames } from '~components/Provider/KeyDownHandler/utils';
import { usePlatform } from '~components/Provider/Platform';
import useHotKeysStore from '~stores/HotKeys';
import usePointer from '~stores/Pointer';
import NavigationDirection from '~typings/NavigationDirection';
import IconArrow from '~ui/Icon/IconArrow';

import { getDirectionsKeyCode } from './PointerManager';
import * as styles from './styles.module.css';


type Props = {
  isLeftEnabled: boolean;
  isRightEnabled: boolean;
  classNames?: {
    left?: string;
    right?: string;
  };
};

const HorizontalPointerNavigation = ({ isLeftEnabled, isRightEnabled, classNames }: Props) => {
  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const applyHotKey  = useHotKeysStore(state => state.apply);
  const { keyCodes } = usePlatform();
  const keyNames = getKeyNames(keyCodes);

  const handleNavigate = React.useCallback(throttle<any>(
    (direction: NavigationDirection, e: any) => {
      const keyName = getDirectionsKeyCode(keyCodes)[direction];
      applyHotKey(keyNames[keyName], e);
    },
    400,
    { leading: false },
  ), [keyNames]);

  if (!pointerEnabled) { return null; }

  return (
    <>
      <div
        className={
          cn(styles.button, {
            [styles.left]: !classNames?.left,
            [classNames!.left as string]: classNames?.left,
            [styles.visible]: isLeftEnabled,
          })
        }
        onClick={ (e) => handleNavigate(NavigationDirection.Left, e) }
      >
        <IconArrow direction={ NavigationDirection.Left } classNames={ { wrapper: styles.icon } } />
      </div>
      <div
        className={
          cn(styles.button, {
            [styles.right]: !classNames?.right,
            [classNames!.right as string]: classNames?.right,
            [styles.visible]: isRightEnabled,
          })
        }
        onClick={ (e) => handleNavigate(NavigationDirection.Right, e) }
      >
        <IconArrow direction={ NavigationDirection.Right } classNames={ { wrapper: styles.icon } } />
      </div>
    </>
  );
};


export default HorizontalPointerNavigation;
