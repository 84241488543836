import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import TrialDescription from '~components/ProductItem/TrialDescription';
import { matchPaymentMethodFromProduct } from '~components/ProductPlanPage/SelectPlan.helpers';
import useSubscriptionsPlansOffer from '~hooks/fetch/useSubscriptions/useSubscriptionsPlansOffer';
import useSubscriptionsProducts from '~hooks/fetch/useSubscriptions/useSubscriptionsProducts';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import EverGreenPhase from '~typings/EvergreenPhase';
import PaymentMethod from '~typings/PaymentMethod';
import Plan from '~typings/Plan';
import Product from '~typings/Product';
import TrialPhase from '~typings/TrialPhase';
import UserPaymentMethod from '~typings/UserPaymentMethod';

import ConflictedSubscriptions from './ConflictedSubsriptions';
import OfferedPriceButton from './OfferedPriceButton';
import PaymentInstruction from './PaymentInstruction';
import PaymentMethodRadioButtons from './PaymentMethodRadioButtons';
import PlansRadioButtons from './PlansRadioButtons';
import * as styles from './styles.module.css';
import WhatInButton from './WhatInButton';



enum FocusOn {
  RadioButtons = 0,
  MethodButtons = 1,
  Buttons = 2,
}

type Props = Readonly<{
  product: Product;
  intl: InjectedIntl;
}>;

const SelectPlan: React.FC<Props> = ({ product, intl }) => {
  const history = useHistory();
  const { planID } = useParams<{ planID: string }>();
  const [selectedPlan, setSelectedPlan] = React.useState<Plan | null>(null);
  const hasAnyMethod = product?.plans?.some(
    (plan) => plan.phases?.length
  ) ?? true;

  const { data: conflictedSubscriptions } = useSubscriptionsProducts(hasAnyMethod ? product?.id : undefined);
  const { data: offer } = useSubscriptionsPlansOffer(hasAnyMethod ? selectedPlan?.id : undefined);

  const [selectedMethod, setSelectedMethod] = React.useState<UserPaymentMethod>()

  React.useEffect(() => {
    if (!selectedPlan && product) {
      if (planID) {
        setSelectedPlan(product.plans.find(x => x.id === planID) || product.plans[0]);
      } else {
        setSelectedPlan(product.plans[0]);
        history.replace(`/products/${product.id}/plan/${product.plans[0]?.id}`);
      }
    }
  }, [product, planID]);

  const navigationItems = React.useRef([
    {}, // RadioButtons
    { maxIndex: 0 }, // MethodButtons
    { maxIndex: 1 }, // Buttons
  ]);

  React.useEffect(() => {
    if (!hasAnyMethod) {
      navigationItems.current = [{ maxIndex: 0 }]; // Back Button
    }
    if (product?.plans.length !== 0) {
      navigationItems.current[FocusOn.RadioButtons] = { maxIndex: product?.plans.length - 1 };
      if (selectedPlan) {
        navigationItems.current[FocusOn.MethodButtons] =
          { maxIndex: selectedPlan ? matchPaymentMethodFromProduct(product?.plans)[selectedPlan.id]?.length - 1 : 0 };
      }
    }
  }, [product?.plans.length, hasAnyMethod, planID, selectedPlan]);

  const navigationState = useSpatialNavigation({
    allowNavigation: !!product,
    navigationItems: navigationItems.current,
  });

  const trialPhase = (offer?.data.following_phases || [])
    .find(x => x.phase.type === 'trial')?.phase as TrialPhase;
  const evergreenPhase = (offer?.data.following_phases || [])
    .find(x => x.phase.type === 'evergreen')?.phase as EverGreenPhase;

  const handleSelectPlan = (plan: Plan) => {
    setSelectedPlan(plan);
    history.replace(`/products/${product.id}/plan/${plan.id}`)
  };

  const handlePurchase = () => {
    switch (selectedMethod?.payment_method_type) {
      case PaymentMethod.Operator:
        history.replace(`/products/${product.id}/plan/${selectedPlan?.id}/invoice`);
        break
      case PaymentMethod.NewCard:
      case PaymentMethod.a1NewCard:
      case PaymentMethod.Existing_card:
        history.replace(`/products/${product.id}/plan/${selectedPlan?.id}/invoice/proceed_card_payment`);
        break
      default:
        break;
    }
  };

  const handleGoToProduct = () => {
    history.push(`/products/${product.id}`);
  };

  return (
    <>
      <h1 className={ styles.name }>
        { product.name }
      </h1>
      {
        matchPaymentMethodFromProduct(product?.plans)[planID] ? (
          <>
            <PlansRadioButtons
              isFocused={ (navigationState.focusOn === FocusOn.RadioButtons) }
              focusIndex={ navigationState.focusedIndex[FocusOn.RadioButtons] }
              plans={ product.plans }
              selectedPlan={ selectedPlan }
              onClick={ handleSelectPlan }
              intl={ intl }
            />
            <PaymentMethodRadioButtons
              paymentMethods={ matchPaymentMethodFromProduct(product?.plans)[planID] }
              selectedMethod={ selectedMethod }
              setSelectedMethod={ setSelectedMethod }
              isFocused={ (navigationState.focusOn === FocusOn.MethodButtons) }
              focusIndex={ navigationState.focusedIndex[FocusOn.MethodButtons] }
              intl={ intl }
            />
            <div className={ styles.buttonWrapper }>
              <OfferedPriceButton
                offer={ offer?.data }
                isFocused={
                  (navigationState.focusOn === FocusOn.Buttons)
                  && (navigationState.focusedIndex[FocusOn.Buttons] === 0)
                }
                isTrial={ !!trialPhase }
                handlePurchase={ handlePurchase }
                intl={ intl }
              />
              <WhatInButton
                isFocused={
                  (navigationState.focusOn === FocusOn.Buttons)
                  && (navigationState.focusedIndex[FocusOn.Buttons] === 1)
                }
                handleGoToProduct={ handleGoToProduct }
                intl={ intl }
              />
            </div>
            <div className={ styles.buttonWrapper }>
              <TrialDescription
                trialPhase={ trialPhase }
                evergreenPhase={ evergreenPhase }
                intl={ intl }
              />
            </div>
            <div className={ styles.buttonWrapper }>
              <ConflictedSubscriptions
                conflictedProducts={ conflictedSubscriptions?.data }
                intl={ intl }
              />
            </div>
          </>
        ) : (
          <PaymentInstruction isFocused={ (navigationState.focusOn === FocusOn.RadioButtons) } />
        )
      }
    </>
  );
};


export default injectIntl(SelectPlan);
