import { useEffect, useMemo } from 'react';
import { InfiniteData, useInfiniteQuery, useQuery } from 'react-query';
import Queue from 'spbtv-promise-queue';

import { queryClient } from '~global';
import { useClient, useNetwork } from '~global';
import expand from '~hooks/fetch/expand';
import ApiClient from '~lib/ApiClient';
import currentByStartAtAndEndAt from '~lib/currentByStartAtAndEndAt';
import { addDaysToDate, toPivotDate } from '~lib/eventsHelper';
import { Nullable } from '~lib/type-utils/utils';
import ProgramEvent from '~typings/Event';

const queue = new Queue(1, Infinity);

type ProgramEventsResponse = { data: ProgramEvent[] };

const programEventsKeys = {
  PREFIX: 'program_events',
  programEventsForProgram: (
    channelID?: string | null,
    programId?: string | null,
    pivotRawDate: Date = new Date(),
  ) => [programEventsKeys.PREFIX, 'for-program', channelID, programId, toPivotDate(pivotRawDate)],
  programEventsForChannelInfinity: (channelID?: string | null) => [
    programEventsKeys.PREFIX,
    'for-channel-infinity',
    channelID,
  ],
  programEventsForChannelNormal: (channelID?: string | null, pivotRawDate: Date = new Date()) => [
    programEventsKeys.PREFIX,
    'for-channel-normal',
    channelID,
    toPivotDate(pivotRawDate),
  ],
};

type FetchParams = {
  channelID: string,
  programId?: Nullable<string>,
  pivotRawDate: Date,
  regionUID?: Nullable<string>,
  client: ApiClient,
}

const fetch = async (
 {
   channelID,
   programId,
   pivotRawDate,
   regionUID,
   client,
  } : FetchParams
): Promise<ProgramEventsResponse> => {
  const url = programId
    ? `/v2/epg/programs/${programId}/program_events.json`
    : '/v3/epg/program_events';

  type FetchOptions = {
    channel_id: string;
    program_id?: string;
    pivot_date: string;
    region_uid?: string;
    'expand[program_event]': string;
  };

  const options: FetchOptions = {
    channel_id: channelID,
    pivot_date: toPivotDate(pivotRawDate),
    'expand[program_event]': expand.program_event!,
  };

  if (programId) {
    options.program_id = programId;
  }

  if (regionUID) {
    options.region_uid = regionUID;
  }

  return queue.add(() => client.get(url, options));
};

/**
 * Функция следит, чтобы 3 дня были загружены, а возвращаемые данные не используются
 * Используется только в старом EPG, нужно удалить
 * @deprecated
 */
export const useThreeDaysEvents = (channelId?: string | null, pivotDate: Date = new Date()) => {
  const yesterday = addDaysToDate(pivotDate, -1);
  const today = pivotDate;
  const tomorrow = addDaysToDate(pivotDate, 1);

  const { query: queryYesterday } = useProgramEventsByDate(channelId, yesterday);
  const { query: queryToday } = useProgramEventsByDate(channelId, today);
  const { query: queryTomorrow } = useProgramEventsByDate(channelId, tomorrow);

  const isLoading = queryYesterday.isLoading && queryToday.isLoading && queryTomorrow.isLoading;

  return isLoading
};

/**
 * Ищет текущий event, среди переданных
 */
export const getCurrentEvent = (programEvents?: ProgramEvent[]): ProgramEvent | undefined => {
  if (!programEvents?.length) {
    return;
  }

  return currentByStartAtAndEndAt(programEvents) as ProgramEvent | undefined;
};


export const getInfinityProgramEventsFromCache = (channelID?: string | null) => {
  const infinityPages = queryClient.getQueryData<InfiniteData<InfinityProgramEvents>>(
    programEventsKeys.programEventsForChannelInfinity(channelID),
  );
  return infinityPages?.pages;
}

/**
 * TODO: Можно отрефакторить, функция нуждается только в event-ах, которые сортированы
 * Ищет среди них тот, который находится в переданной или текущей дате
 */
export const arrBinarySearchCheck = (channelId: string, timeMS: number | null = null) => {
  const infinityPages = getInfinityProgramEventsFromCache(channelId);

  const programEvents = Object.values(infinityPages ?? {})
    .sort((a, b) => a.pivotRawDate.getTime() - b.pivotRawDate.getTime())
    .reduce((acc, x) => [...acc, ...x.programEvents], [] as ProgramEvent[]);


  if (!programEvents.length) return null;

  const searchDate = timeMS ?? new Date().getTime();

  const firstItemStart = new Date(programEvents[0]['start_at']).getTime();
  const lastItemEnd = new Date(programEvents[programEvents.length - 1]['end_at']).getTime();

  if (searchDate < firstItemStart || searchDate > lastItemEnd) return null;

  const arrBinarySearch = (
    arr: ProgramEvent[],
    searchDate: number,
    first: number,
    last: number,
  ) => {
    // функция бинарного поиска, ищет искомую дату в массиве эффективнее чем обычная итерация
    // TODO: добавить тесты для данной функции
    if (last <= 1) {
      return arr[0];
    }

    const arrLength = last - first + 1;
    const arrIsEven = (last - first + 1) % 2 === 0;
    const arrMiddleChar = arrIsEven ? first + arrLength / 2 : first + (arrLength - 1) / 2;
    // ищем средний элемент в массиве и приводим данные о времени к миллисекундам

    const middleStart = new Date(arr[arrMiddleChar]['start_at']).getTime(); // время старта передачи по UTC в ms
    const middleEnd = new Date(arr[arrMiddleChar]['end_at']).getTime(); // время конца передачи по UTC в ms
    const targetDate = searchDate; //текущее время по UTC в ms

    if (targetDate === middleEnd) {
      return arr[arrMiddleChar + 1]
        ? [arr[arrMiddleChar + 1], arr[arrMiddleChar + 2]]
        : [arr[arrMiddleChar], arr[arrMiddleChar + 1]];
    }

    if (targetDate < middleEnd && targetDate >= middleStart) {
      return [arr[arrMiddleChar], arr[arrMiddleChar + 1]];
    }
    if (last - first === 1) {
      return null;
    }
    if (targetDate < middleEnd) {
      return arrBinarySearch(arr, searchDate, first, arrMiddleChar);
    }

    if (targetDate > middleEnd) {
      return arrBinarySearch(arr, searchDate, arrMiddleChar, arr.length - 1);
    }
  };

  return arrBinarySearch(programEvents, searchDate, 0, programEvents.length - 1);
};


/**
 * Возвращает события для канала на сегодня
 */
export const useTodayProgramEvents = (channelId?: string | null) => {
  const { query, parsedData } = useParsedInfinityProgramEvents(channelId);

  const todayEvents = useMemo(() => {
    const todayPivotDate = toPivotDate(new Date());
    return parsedData?.[todayPivotDate];
  }, [parsedData]);

  const fakeQueryResponse = useMemo(() => {
    if (todayEvents) {
      return {
        data: todayEvents,
        isFetched: true,
        isError: false,
        isLoading: false,
      };
    }
    if (query.status === 'error') {
      return {
        data: undefined,
        isFetched: false,
        isError: true,
        isLoading: false,
      };
    }
    return {
      data: undefined,
      isFetched: false,
      isError: false,
      isLoading: true,
    };
  }, [query, todayEvents]);

  return fakeQueryResponse;
};

/**
 * Используется только в старом EPG, нужно удалить
 * @deprecated
 */
export const useProgramEventsByDate = (channelId: Nullable<string>, forRawDate: Date) => {
  const {query, parsedData} = useParsedInfinityProgramEvents(channelId);

  useEffect(() => {
    if (query.isFetching || query.isFetchingNextPage || query.isFetchingPreviousPage) {
      return;
    }

    const firstPivotDate = query.data?.pages[0]?.pivotDate;
    const lastPivotDate = query.data?.pages[query.data?.pages.length - 1]?.pivotDate;

    if (!firstPivotDate || !lastPivotDate) {
      return;
    }

    const firstDayPrepared = new Date(firstPivotDate);
    const lastDayPrepared = new Date(lastPivotDate);
    const currentDayPrepared = new Date(toPivotDate(forRawDate));

    if (currentDayPrepared >= firstDayPrepared && currentDayPrepared <= lastDayPrepared) {
      return;
    }

    if (currentDayPrepared < firstDayPrepared) {
      if (query.hasPreviousPage) {
        query.fetchPreviousPage();
      }
      return;
    }

    if (currentDayPrepared > lastDayPrepared) {
      if (query.hasNextPage) {
        query.fetchNextPage();
      }
      return;
    }
  }, [query, forRawDate]);


  return {
    query,
    parsedData,
  }
};

export type InfinityProgramEvents = {
  pivotRawDate: Date;
  pivotDate: string;
  programEvents: ProgramEvent[];
};

const getPreviousPageParam = ({ pivotRawDate, programEvents }: InfinityProgramEvents) => {
  if (!programEvents.length) {
    return;
  }
  return addDaysToDate(pivotRawDate, -1);
};

const getNextPageParam = ({ pivotRawDate, programEvents }: InfinityProgramEvents) => {
  if (!programEvents.length) {
    return;
  }
  return addDaysToDate(pivotRawDate, 1);
};

export const useInfinityProgramEvents = (channelId: Nullable<string>) => {
  const client = useClient();
  const regionUid = useNetwork((n) => n.region_uid);
  const query = useInfiniteQuery<InfinityProgramEvents>({
    queryKey: programEventsKeys.programEventsForChannelInfinity(channelId),
    queryFn: ({ pageParam: pivotRawDate = new Date() }) =>
      fetch({
        channelID: channelId!,
        pivotRawDate,
        regionUID: regionUid,
        client,
      }).then((res) => ({
        pivotRawDate,
        programEvents: res.data,
        pivotDate: toPivotDate(pivotRawDate),
      })),

    getNextPageParam: getNextPageParam,
    getPreviousPageParam: getPreviousPageParam,
    notifyOnChangeProps: [
      'data',
      'status',
      'hasNextPage',
      'hasPreviousPage',
      'isFetchingNextPage',
      'isFetchingPreviousPage',
      'isFetching',
    ],
    enabled: !!channelId,
  });

  return query;
};

export const useParsedInfinityProgramEvents = (channelId: Nullable<string>) => {
  const query = useInfinityProgramEvents(channelId);

  const parsedData = useMemo(() => {
    return query.data?.pages.reduce(
      (acc, page) => {
        if (!page.programEvents.length) {
          return acc;
        }
        return {
          ...acc,
          [page.pivotDate]: page.programEvents,
        };
      },
      {} as Record<string, ProgramEvent[]>,
    );
  }, [query.data?.pages]);

  return {
    query,
    parsedData,
  };
};



/**
 * Возвращает события для программы на сегодня
 */
export const useTodayEventsForProgram = (channelId: string, programId: string) => {
  const queryKey = programEventsKeys.programEventsForProgram(channelId, programId);
  const client = useClient();
  const network = useNetwork();

  return useQuery<ProgramEventsResponse>({
    queryKey,
    queryFn: () => fetch({
      channelID: channelId,
      programId,
      pivotRawDate: new Date(),
      regionUID: network.region_uid,
      client,
    }),
    enabled: !!channelId && !!programId,
  });
};
