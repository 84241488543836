/* eslint-disable max-len */
import * as React from 'react';

export interface Props {
  className?: string;
}

export const IconUser: React.FC<Props> = ({className}) => {
  return (
    <svg 
      viewBox="0 0 30 30" 
      xmlns="http://www.w3.org/2000/svg"
      className={ className }
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M8.04671 22.1869C9.84629 23.9284 12.298 25 15 25C17.702 25 20.1537 23.9284 21.9533 22.1869C21.5504 18.704 18.591 16 15 16C11.409 16 8.44959 18.704 8.04671 22.1869ZM15 3C8.37258 3 3 8.37258 3 15C3 21.6274 8.37258 27 15 27C21.6274 27 27 21.6274 27 15C27 8.37258 21.6274 3 15 3ZM18 12C18 13.6569 16.6569 15 15 15C13.3431 15 12 13.6569 12 12C12 10.3431 13.3431 9 15 9C16.6569 9 18 10.3431 18 12Z" 
      />
    </svg>
  );
};