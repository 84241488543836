import {useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import expand from '~hooks/fetch/expand';
import Avatar from '~typings/Avatar';
import ResponseMany from '~typings/ResponseMany';


const QUERY_KEY = 'avatars';

type Avatars = ResponseMany<Avatar[]>;

const fetch = async (client, props) => {
  if (props.cacheData) { return props.cacheData; }

  return await client.get(
    '/v1/user/avatars',
    {
      'expand[avatar]': expand.avatar,
    }
  );
};

const useAvatars = () => {
  const client = useClient();
  const cacheData = queryClient.getQueryData(QUERY_KEY);

  return useQuery<Avatars>({
    queryKey: QUERY_KEY,
    queryFn: () => fetch(client, { cacheData }),
  });
};



export default useAvatars;
