import * as cn from "classnames";
import React from 'react'
import { useHistory } from "react-router-dom";

import * as css from '~components/BlockBannersV4/styles.module.css'
import { useScene } from "~components/Provider/Scene";
import { useBannersV4 } from "~hooks/fetch/useBannersV4/useBanners";
import useDeepLink from "~hooks/useDeepLink";
import getImageSRC from "~lib/MediaItemImageSRC";
import useNavigation from "~stores/Navigation";
import BannerMiddle from "~typings/BannerMiddle";
import { Block } from "~typings/Block";
import ImageType from "~typings/ImageType";

import { reduceString } from "../../supportFunctions";
import * as midBannerCss from './styles.module.css'

type Props = Readonly<{
  block: Block;
  blockIndex: number;
  isFocusedBlock: boolean;
  isPopupOpened: boolean;
  pageObject?: any;
}>;


export function MiddleBanner(props: Props) {
  const scene = useScene();
  const history = useHistory();
  const { urlFromDeepLink } = useDeepLink();
  const getFocusedBlockIndex = useNavigation(state => state.getFocusedBlockIndex);

  let textAlign
  let flexAlign

  React.useEffect(() => {
    if (props.isFocusedBlock) {
      scene.changeSceneMediaItem(null, false, true);
    }
  }, [props.isFocusedBlock]);

  const { data: items } = useBannersV4(props.block.id);

  if (!items || !items.data || items.data.length === 0) {
    return null;
  }

  const middleBanner = items.data[0] as BannerMiddle;

  textAlign = middleBanner.text_position

  if (textAlign === "align-center") {
    textAlign = "center"
    flexAlign = "center"
  }
  else if (textAlign === "align-right") {
    textAlign = "right"
    flexAlign = "flex-end"
  }
  else if (textAlign === "align-left") {
    textAlign = "left"
    flexAlign = "flex-start"
  }


  const imageURL = getImageSRC({
    dimension: ImageType.BANNER,
    mediaItem: middleBanner,
    isNeedToCrop: false,
    width: 1920,
    height: 240,
  });

  const handleClick = () => {
    if (middleBanner.deeplink) {
      const URL = urlFromDeepLink(middleBanner.deeplink);

      if (URL) {
        history.push(URL);
      }
    }
  };

  return (
    <>
      <div>
        {
          <>
            <div style={ { backgroundImage: `url('${ imageURL }')`, } }
              className={ cn(midBannerCss.bannerMiddle, {
                [css.focused]: (props.pageObject && getFocusedBlockIndex() === props.blockIndex),
                focusedNavigationNode: (!props.isPopupOpened && props.isFocusedBlock),
              }) }
              onClick={ handleClick }
            />
            <div className={ midBannerCss.innerWrapper1 }>
              <div className={ midBannerCss.innerWrapper2 }>
                <div className={ midBannerCss.innerWrapper3 } style={ { alignItems: flexAlign } }>
                  { // title
                    (middleBanner.big_screen_text_block.title) &&
                    <div className={ midBannerCss.title } style={ {textAlign: textAlign } }>
                      { middleBanner.big_screen_text_block.title.length >= 90
                        ? reduceString(middleBanner.big_screen_text_block.title, 90)
                        : middleBanner.big_screen_text_block.title }
                    </div>
                  }
                  { // subtitle
                    (middleBanner.big_screen_text_block.subtitle) &&
                    <div className={ midBannerCss.subTitle } style={ { textAlign: textAlign } }>
                      { middleBanner.big_screen_text_block.subtitle.length >= 185
                        ? reduceString(middleBanner.big_screen_text_block.subtitle, 185)
                        : middleBanner.big_screen_text_block.subtitle
                      }
                    </div>
                  }
                  { // button
                    middleBanner.big_screen_text_block.button_text &&
                    middleBanner.big_screen_text_block.button_text.length !== 0 &&
                    <div
                      onClick={ handleClick }
                      className={ cn(midBannerCss.button, {
                        'focusedNavigationNode': !props.isPopupOpened && props.isFocusedBlock,
                      }) }
                    >
                      { middleBanner.big_screen_text_block.button_text.length >= 60
                        ? reduceString(middleBanner.big_screen_text_block.button_text, 60)
                        : middleBanner.big_screen_text_block.button_text
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}
