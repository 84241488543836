import focuser, { FocuserKeyHandler } from '@focuser';
import * as React from 'react';
import { useState } from 'react';

import { valueInPixelsByWidth } from '~lib/SizesInPX';

import * as styles from '../styles.module.css';
import { MemoizedSuggestionItem } from './SuggestionItem';

type Props = {
  isLoadingSuggestions: boolean;
  query: string;
  uniqSuggestions: { value: string }[];
  setQuery: (newQuery: string) => void;
};

const SuggestionBlock = (props: Props) => {
  const { isLoadingSuggestions, query, uniqSuggestions, setQuery } = props;

  const [activeSuggestion, setActiveSuggestion] = useState(0);
  React.useEffect(()=>{
    setActiveSuggestion(0);
  }, [uniqSuggestions])

  const handleUpKey: FocuserKeyHandler = ({ stop }) => {
    if (activeSuggestion > 0) {
      setActiveSuggestion(activeSuggestion - 1);
      stop();
    }
  };

  const handleDownKey: FocuserKeyHandler = ({ stop }) => {
    if (activeSuggestion < uniqSuggestions.length - 1) {
      setActiveSuggestion(activeSuggestion + 1);
      stop();
    }
  };

  if (isLoadingSuggestions) {
    return (
      <>
        {' '}
        {new Array(Math.floor(valueInPixelsByWidth(3.515625))).fill(0).map((_, i) => (
          <div
            key={ `suggestion-${i}` }
            className={ styles.placeholder }
          />
        ))}
      </>
    );
  }

  return (
    <focuser.FocusableBlock
      isFocused
      onKeyUp={ handleUpKey }
      onKeyDown={ handleDownKey }
      noNeedForceFocus
      isPointerDownAvailable={ activeSuggestion < uniqSuggestions.length - 1 }
      isPointerUpAvailable={ activeSuggestion > 0 }
    >
      {uniqSuggestions.map(({ value }, idx) => {
        return (
          <focuser.FocusableBlock
            isFocused={ activeSuggestion === idx }
            key={ value }
            onClick={ () => setQuery(value) }
            onForceFocus={ () => setActiveSuggestion(idx) }
          >
            {({ isFocused }) => (
              <MemoizedSuggestionItem
                value={ value }
                query={ query }
                isFocused={ isFocused }
              />
            )}
          </focuser.FocusableBlock>
        );
      })}
    </focuser.FocusableBlock>
  );
};

export { SuggestionBlock };
