import { FormattedMessage } from 'react-intl';

import { ERROR_HAS_OCCURRED } from '~localization';

import { ErrorMessageIds } from './types';

interface IntlFormatMessageFunction {
  (messageDescriptor: FormattedMessage.MessageDescriptor, values?: {[key: string]: any }): string
}

const getMessageByError = (error, intlFormatFn: IntlFormatMessageFunction) => {
  const errorCode = error?.errors?.[0]?.code;
  const messageId = ErrorMessageIds[errorCode] ?? ERROR_HAS_OCCURRED;

  return intlFormatFn({id: messageId});
};

export {
  getMessageByError,
};
