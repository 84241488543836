/* eslint-disable max-len */
import * as React from 'react';


const IconSeries: React.FC = () => (
  <svg
    className="playerIcon"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.772,24.036H6.1V11.124H22.772Zm-1.459-11.5H7.543V22.621h13.77Zm4.778-4.513H7.6V9.442H24.631V22.581h1.46Zm-9.033,9.427L12.817,14.89v5.127Z"
      fill="currentColor"
    />
  </svg>
);


export default React.memo(IconSeries);
