import PlayerAccessError from '~typings/PlayerStatus';

import { Events } from '../common';
import { VastErrors } from '../VastErrors';
import VideoError from '../VideoError';


const onError = (event, type) => {
  if (type === PlayerAccessError.CannotPlayStream) {
    event.emit(
      Events.ERROR,
      PlayerAccessError.CannotPlayStream,
    );

    return;
  }

  const error = { message: type };

  switch (type) {
    case 'PLAYER_ERROR_INVALID_PARAMETER':
    case 'PLAYER_ERROR_INVALID_OPERATION':
    case 'PLAYER_ERROR_SEEK_FAILED':
    case 'PLAYER_ERROR_INVALID_STATE':
    case 'PLAYER_ERROR_GENEREIC':
      event.emit(
        Events.ERROR,
        new VideoError({ ...error, vastError: VastErrors.GENERAL_WRAPPER_ERROR }),
      );
      break;
    case 'PLAYER_ERROR_INVALID_URI':
    case 'PLAYER_ERROR_CONNECTION_FAILED':
      event.emit(
        Events.ERROR,
        new VideoError({ ...error, vastError: VastErrors.TRAFFICKING_ERROR }),
      );
      break;
    case 'PLAYER_ERROR_NO_SUCH_FILE':
      event.emit(
        Events.ERROR,
        new VideoError({ ...error, vastError: VastErrors.FILE_NOT_FOUND }),
      );
      break;
    case 'PLAYER_ERROR_NOT_SUPPORTED_FILE':
      event.emit(
        Events.ERROR,
        new VideoError({ ...error, vastError: VastErrors.NOT_FIND_SUPPORTED_MEDIA_FILE }),
      );
      break;
    default:
      event.emit(
        Events.ERROR,
        new VideoError({ message: `unknown error: ${type}`, vastError: VastErrors.UNDEFINED_ERROR }),
      );
    }
};


export default onError;
