import * as cn from 'classnames';
import { size } from 'lodash';
import * as React from 'react';

import { Block as BlockComponent } from '~components/Block';
import { Feature, hasFeature, useConfig } from '~global';
import { getCachedCardCollection } from "~hooks/fetch/useCardCollection";
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import { blocksDimensions } from '~lib/Dimension';
import { RknPlate } from "~newapp/ui/rkn-plate";
import useNavigation from '~stores/Navigation';
import { getBlockItemsCount } from '~stores/Navigation/utils';
import { Block as IBlock, BlockType } from '~typings/Block';
import Page from '~typings/Page';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isPopupOpened: boolean;
  isSceneMediaItemPageMode?: boolean;
  page: Page;
  blocks: IBlock[];
  focusedBlockID: string | null;
  focusedItemIndex: number;
}>;

const BlockPageItems: React.FC<Props> = (props: Props) => {
  const config = useConfig();
  const { smartTV: { sliderSize } } = config;
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [blocksHeight, setBlocksHeight] = React.useState<number[]>(EMPTY_ARRAY);
  const pageFocusHistory = useNavigation(state => state.getPageFocusHistory());
  const focusedBlockIndex = useNavigation(state => state.getFocusedBlockIndex());
  const getBlockPosition = useNavigation(state => state.getBlockPosition);
  const getIsVisibleBlock = useNavigation(state => state.getIsVisibleBlock);

  //это только для экспанда, экспанд блок всегда последний
  const possibleExpand = props.blocks[props.blocks.length - 1]
  const expandableBlock = possibleExpand.object === BlockType.ExpandableCardColletion
  let cardCollection

  if (expandableBlock && possibleExpand.card_collection) {
    cardCollection = getCachedCardCollection(possibleExpand.card_collection.id)?.data
  }


  const calculateYOffsetInPx = (blocksHeights: number[], index: number): number => {
    let res = 0;
    try {
      for (let i = 0; i < index; i += 1) {
        res += blocksHeights[i];
      }

      return res;
    } catch (ignore) {
      return res;
    }
  };

  const recalculateBlocksHeights = () => {
    try {
      const blocksHeights = blocksDimensions(props.blocks)

      let blocksHeightNewValue = blocksHeights.map((item, index) => {
        return calculateYOffsetInPx(blocksHeights, index)
      })


      if (expandableBlock && cardCollection.description.length >= 290) {
        blocksHeightNewValue.push(blocksHeightNewValue[blocksHeightNewValue.length - 1])
      }

      if (expandableBlock && cardCollection.search_filters_group.items.length > 0) {
        blocksHeightNewValue.push(blocksHeightNewValue[blocksHeightNewValue.length - 1])
      }

      setBlocksHeight(blocksHeightNewValue);
    } catch (ignore) {

    }
  };

  React.useEffect(recalculateBlocksHeights, [props.blocks]);

  const items = React.useMemo(() => (
    props.blocks.map((block: IBlock, index: number) => {
      let isFocusedBlock

      const blockItemsCount = getBlockItemsCount(props.page.id, config, block, sliderSize);

      if (!blockItemsCount) {
        return null;
      }

      if (block.object === BlockType.ExpandableCardColletion
        && `${ block.id }-description-U7sGA1hs2` === props.focusedBlockID
        || `${ block.id }-filters-U7sGA1hs2` === props.focusedBlockID
        || `${ block.id }-grid-U7sGA1hs2` === props.focusedBlockID) {
        //эта проверка нужна только для expandable-block
        isFocusedBlock = true
      }
      else {
        isFocusedBlock = (block.id === props.focusedBlockID);
      }

      const focusedItemIndex = (isFocusedBlock)
        ? props.focusedItemIndex
        : getBlockPosition(block.id)?.index || 0;

      return (
        <BlockComponent
          focusedBlockID={ block.object === BlockType.ExpandableCardColletion ? props.focusedBlockID : null }
          isPopupOpened={ props.isPopupOpened }
          key={ `${ block.id }-${ index }-${ props.page.id }` }
          block={ block }
          page={ props.page }
          isFocusedBlock={ !props.isSceneMediaItemPageMode && isFocusedBlock }
          isVisibleBlock={ !props.isSceneMediaItemPageMode && getIsVisibleBlock(block.id, block.object) }
          focusedItemIndex={ focusedItemIndex }
          blockIndex={ index }
        />
      );
    })
  ), [
    props.isPopupOpened,
    props.focusedBlockID,
    props.focusedItemIndex,
    props.isSceneMediaItemPageMode,
  ]);

  if (size(pageFocusHistory) === 0) {
    return null;
  }


  if (ref.current) {
    const translate3d = focusedBlockIndex === 0
      ? 0
      : blocksHeight[focusedBlockIndex] || blocksHeight[props.blocks.length];

    const transform = `translate3d(0, -${ translate3d }px, 0)`;

    ref.current.style.transform = transform;
    ref.current.style.webkitTransform = transform;
  }

  return (
    <div
      ref={ ref }
      className={ cn(styles.blockScrollableY, {
        [styles.hide]: props.isSceneMediaItemPageMode,
      }) }
    >
      { items }
      {
        (hasFeature(Feature.ShowRknPlate)
          && !props.blocks.some(item => item.object.includes(BlockType.ExpandableCardColletion)))
          ? <RknPlate className={ styles.rknCustomClass } />
          : null
      }
    </div>
  );
};


export default React.memo(BlockPageItems);
