import { useEffect } from 'react';
import create from 'zustand';

type GlobalSceneBlockState = {
  blockedCount: number;
  increment: () => void;
  decrement: () => void;
};

const usePageManagerPointersBlockState = create<GlobalSceneBlockState>((set) => ({
  blockedCount: 0,
  increment: () => set((state) => ({ blockedCount: state.blockedCount + 1 })),
  decrement: () => set((state) => ({ blockedCount: state.blockedCount - 1 })),
}));

export const useIsGlobalSceneBlocked = () => {
  const isBlocked = usePageManagerPointersBlockState((s) => s.blockedCount > 0);
  return isBlocked;
};

/**
 * Хук отключает глобальную сцену, а при размонтировании включает.
 */
export const useGlobalSceneBlocker = (isBlocked = true) => {
  useEffect(() => {
    if (!isBlocked) {
      return;
    }
    usePageManagerPointersBlockState.getState().increment();
    return () => usePageManagerPointersBlockState.getState().decrement();
  }, [isBlocked]);
};
