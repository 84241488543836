import Period, { PeriodUnit } from '~typings/Period';
import TimeshiftAvailability from '~typings/TimeshiftAvailability';

import onError from './shaka/error';
import { PlayerEvent } from './typings';


const Events: PlayerEvent = {
  CAN_PLAY: 'canplay',
  EVENT: 'event',
  ENDED: 'ended',
  ERROR: 'error',
  LOADED_METADATA: 'loadedmetadata',
  PAUSE: 'pause',
  PLAY: 'play',
  PLAYING: 'playing',
  SEEKING: 'seeking',
  SEEKED: 'seeked',
  SUSPEND: 'suspend',
  STOP: 'stop',
  WAITING: 'waiting',
  TIME_UPDATE: 'timeupdate',
  BUFFERING_START: 'bufferingstart',
  BUFFERING_COMPLETE: 'bufferingcomplete',
  TRACK_CHANGED: 'trackChanged',
  ADS_STARTED: 'ads:started',
  ADS_FINISHED: 'ads:finished',
  MID_ROLL_STARTED: 'mid_roll:started',
  LOG: 'log',
  BUFFERING: 'buffering',
  PLAYER_ERROR: 'player error',
};

const getTimeShiftDurationInSeconds = (period: Period): number => {
  const { unit, value } = period;

  if (unit === PeriodUnit.Minutes) { return ((value || 0) * 60); }
  if (unit === PeriodUnit.Hours) { return ((value || 0) * 3600); }
  if (unit === PeriodUnit.Days) { return ((value || 0) * 3600 * 24); }
  if (unit === PeriodUnit.Months) { return ((value || 0) * 3600 * 24 * 30); }

  return 0;
};

/*
    get stream url you can on http://tv-signer.spbtv.com/tv-signer.php
    additional parameters in strem URL
    -- for catchup
    stream_req_time -- Start of stream in microseconds.
    stream_req_duration -- Duration in microseconds
    -- for timeshift
    stream_dvr_window -- Timeshift duration in microseconds
  */
const prepareTimeShiftUrl = (url: string, timeShiftParams: TimeshiftAvailability | null): string => {
  if (timeShiftParams === null) { return url; }

  const { available, period } = timeShiftParams;
  if (!available) { return url; }
  if (url.includes('stream_dvr_window')) { return url; }

  const seconds = getTimeShiftDurationInSeconds(period);
  const mSeconds = (seconds * 1000000);
  const separator = (url.indexOf('?') === -1) ? '?' : '&';

  return `${url}${separator}stream_dvr_window=${mSeconds}`;
};


const createHTMLVideoElement = (event): HTMLVideoElement => {
  const videoElement = document.createElement('video');
  event.setMaxListeners(12);

  videoElement.addEventListener('loadstart', () => event.emit(Events.BUFFERING_START));
  videoElement.addEventListener('loadedmetadata', () => event.emit(Events.LOADED_METADATA));
  videoElement.addEventListener('loadeddata', () => event.emit(Events.BUFFERING_COMPLETE));
  videoElement.addEventListener('canplay', () => event.emit(Events.CAN_PLAY));
  videoElement.addEventListener('timeupdate', () => event.emit(Events.TIME_UPDATE));
  videoElement.addEventListener('ended', () => event.emit(Events.ENDED));
  videoElement.addEventListener('waiting', () => event.emit(Events.WAITING));
  videoElement.addEventListener('playing', () => event.emit(Events.PLAYING));
  videoElement.addEventListener('play', () => event.emit(Events.PLAY));
  videoElement.addEventListener('pause', () => event.emit(Events.PAUSE));
  videoElement.addEventListener('seeking', () => event.emit(Events.SEEKING));
  videoElement.addEventListener('seeked', () => event.emit(Events.SEEKED));
  videoElement.addEventListener('error', (error) => onError(event, error));

  videoElement.style.width = '100%';
  videoElement.style.height = '100%';

  return videoElement;
};

const resetHTMLVideoElement = (videoContainer: HTMLDivElement, videoElement: HTMLVideoElement | null) => {
  try {
    if (videoElement) {
      videoElement.removeAttribute('src');

      while (videoContainer?.firstChild) {
        videoContainer?.removeChild(videoContainer?.firstChild);
      }
    }
  } catch (ignore) {

  }
};


export {
  createHTMLVideoElement,
  Events,
  prepareTimeShiftUrl,
  resetHTMLVideoElement,
};
