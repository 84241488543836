import cn from 'classnames';
import React from 'react';

import * as css from './CardCommonImage.module.css';

export const CardCommonImage: React.FC<{ imageUrl: string }> = ({ imageUrl }) => {
  return (
    <div className={ css.imageWrapper }>
      <div
        className={ cn(css.imageFull, css.imageBackground) }
        style={ { backgroundImage: `url('${imageUrl}')` } }
      />
    </div>
  );
};
