import * as moment from "moment";
import * as React from 'react';

import { PlayButtonState } from '~components/ScenePlayer/Player/controls/PlayPauseButton';
import OnAirButton from '~components/ScenePlayer/Player/controls/ProgressBar/channel/onAirButton';
import { arrBinarySearchCheck } from "~hooks/fetch/useProgramEvents/useProgramEvents";
import { usePlayerStore } from "~stores/Player";
import TimeShiftAvailability from '~typings/TimeshiftAvailability';

import ProgressBar from '../ProgressBarView';
import useTimeShiftState from './time-shift';


type Props = Readonly<{
  channelId: string;
  isLoading: boolean;
  isPlaying: boolean;
  isFocused: boolean;
  isFocusedGoToAir: boolean;
  timeShift: TimeShiftAvailability;
  currentTimeInSec: number;
  durationInSec: number;
  onChangeIsVisibleGoToAir: (isVisible: boolean) => void;
  onSeek: (seekTo: number) => void;
  onKeyPressedToSeek: (seekTo: PlayButtonState.SeekToBackward | PlayButtonState.SeekToForward) => void;
}>;


const ChannelProgressBar: React.FC<Props> = (props: Props) => {
  const [currentTime, setCurrentTime] = React.useState<null | number>(null);
  const [streamStartedTime] = React.useState<Date>(() => new Date());
  const setCurrentProgram = usePlayerStore(state => state.setCurrentProgram)
  const setSearchDate = usePlayerStore(state => state.setSearchDate)

  const {
    setSecondsMissing,
    duration,
    secondsMissing,
  } = useTimeShiftState({
    isPlaying: props.isPlaying,
    timeShift: props.timeShift,
    currentTime: props.currentTimeInSec,
    streamStartedTime: streamStartedTime,
    onSeek: props.onSeek,
  });

  const currentTimeInSec = (duration - secondsMissing);

  const handleSeek = (newTime?: number) => {

    if (newTime) {
      const curDate = new Date().getTime() // настоящее время в данный момент в ms
      const diff = (duration - newTime) * 1000 // временной отрезок от поиска и до конца в таймшифте в ms
      const searchDate = curDate - diff // из текущей даты вычитаем временной отрезок в ms
      const currentProgram = arrBinarySearchCheck(props.channelId, searchDate) || null
      setSearchDate(searchDate)
      setCurrentProgram(currentProgram)
    }

    const leftSecondsBeforeStartWatch = moment(new Date()).diff(moment(streamStartedTime), 'seconds');

    if (newTime === undefined) {
      props.onSeek(duration + leftSecondsBeforeStartWatch);
      setSecondsMissing(0);
    }
    else {
      setSecondsMissing(duration - newTime);

      props.onSeek((currentTime ?? props.currentTimeInSec - duration) + newTime + leftSecondsBeforeStartWatch);
    }
  }

  React.useEffect(() => {
    props.onChangeIsVisibleGoToAir((secondsMissing > 2));
  }, [secondsMissing]);

  React.useEffect(() => {
    if (currentTime === null && props.currentTimeInSec) {
      setCurrentTime(props.currentTimeInSec - duration);
    }
  }, [currentTime, props.currentTimeInSec, duration])

  return (
    <>
      {
        (secondsMissing <= 2)
          ? null
          : <OnAirButton isFocused={ props.isFocusedGoToAir } onClick={ () => handleSeek(duration) } />
      }
      <ProgressBar
        isLive
        isLoading={ props.isLoading }
        isFocused={ props.isFocused }
        currentTimeInSec={ currentTimeInSec }
        durationInSec={ duration }
        onSeek={ handleSeek }
        onKeyPressedToSeek={ props.onKeyPressedToSeek }
      />
    </>
  );
};

export default React.memo(ChannelProgressBar);
