// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".statusBlock--f1egt {\n  color: rgba(202, 202, 208, 1);\n  font-size: 1.40625vw;\n  font-weight: 400;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n  margin-bottom: 0.78125vw;\n  height: 3.125vw;\n}\n\n.iconBlock--RZaTN {\n  width: 1.953125vw;\n  margin-right: 0.78125vw;\n}\n\n.icon--f6C9_ {\n  color: rgba(202, 202, 208, 1);\n}\n\n.statusText--HQr6j {\n  -webkit-box-flex: 1;\n  -webkit-flex-grow: 1;\n     -moz-box-flex: 1;\n          flex-grow: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemDetails/ProgramEventStatusBlock/styles.module.css"],"names":[],"mappings":"AAAA;EACE,6BAAa;EACb,oBAAuB;EACvB,gBAAkB;EAClB,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;EACnB,wBAAwB;EACxB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,6BAAa;AACf;;AAEA;EACE,mBAAY;EAAZ,oBAAY;KAAZ,gBAAY;UAAZ,YAAY;AACd","sourcesContent":[".statusBlock {\n  color: $gw_07;\n  font-size: $fontSub2_vw;\n  font-weight: $fw_4;\n  display: flex;\n  align-items: center;\n  margin-bottom: 0.78125vw;\n  height: 3.125vw;\n}\n\n.iconBlock {\n  width: 1.953125vw;\n  margin-right: 0.78125vw;\n}\n\n.icon {\n  color: $gw_07;\n}\n\n.statusText {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export var statusBlock = "statusBlock--f1egt";
export var iconBlock = "iconBlock--RZaTN";
export var icon = "icon--f6C9_";
export var statusText = "statusText--HQr6j";
export default ___CSS_LOADER_EXPORT___;
