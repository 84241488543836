// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subtitleWrapper--GAaVD {\n  position: absolute;\n  bottom: 2.34375vw;\n  width: 100%;\n  text-align: center;\n}\n\n.subtitleText--yZBeX {\n  display: inline-block;\n  padding: 0.078125vw 0.234375vw;\n  margin: 0.15625vw 0;\n  font-size: 2.734375vw;\n  color: rgba(243, 243, 247, 1);\n  opacity: 0.9;\n  background: rgba(23, 23, 27, 1);\n}\n\n.isVisible--kFq1G {\n  bottom: 10.9375vw;\n}\n", "",{"version":3,"sources":["webpack://./src/newapp/blocks/playerPopcorn/Player/controls/Subtitle/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,8BAA8B;EAC9B,mBAAmB;EACnB,qBAAqB;EACrB,6BAAa;EACb,YAAY;EACZ,+BAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".subtitleWrapper {\n  position: absolute;\n  bottom: 2.34375vw;\n  width: 100%;\n  text-align: center;\n}\n\n.subtitleText {\n  display: inline-block;\n  padding: 0.078125vw 0.234375vw;\n  margin: 0.15625vw 0;\n  font-size: 2.734375vw;\n  color: $gw_10;\n  opacity: 0.9;\n  background: $gw_00;\n}\n\n.isVisible {\n  bottom: 10.9375vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var subtitleWrapper = "subtitleWrapper--GAaVD";
export var subtitleText = "subtitleText--yZBeX";
export var isVisible = "isVisible--kFq1G";
export default ___CSS_LOADER_EXPORT___;
