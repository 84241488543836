import * as cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';


type ImageProps = {
  src: string;
  className?: string;
  style?: React.CSSProperties;
  width?: number;
  height?: number;
  imageHeight?: number;
};

const BlurableBaseImage = (props: ImageProps) => {
  // let height
  // if (props.imageHeight && props.height && props.width) {
  //   height = (props.imageHeight < props.height) ?
  //   props.imageHeight * (props.width / props.height)
  //     :
  //     props.height;
  // }
  const style: React.CSSProperties = {
    ...props.style,
    width: props?.width,
    // height: height,
  };
  return (
    <img
      className={ cn(styles.image, props.className) }
      style={ style }
      src={ props.src }
      alt=""
    />
  );
};


export default React.memo(BlurableBaseImage);
