import { getFullFormattedTime } from '~lib/pluralize/index';
import Plan from '~typings/Plan';
import Product from '~typings/Product';

import {
  CheckboxSubscrOperatorText,
  CheckboxSubscrPromocodeText,
} from './PromocodeSubscrConfirmTypes';

/**
 * Функция собирает цены для отображения рядом с кнопками чекбоксов
 * Мы проходимся по prices в переданной в параметрах promoPhase
 * Это нужно для того, чтобы сформировать 2 массива с ценами для payment_method_type === 'operator' 
 * и для payment_method_type === 'promo_code'
 * Эти массивы с ценами используются при рендере чекбоксов метода оплаты (для подписок)
 */

export function getPhaseTextsForSubscr(product: Product): {
  operator: CheckboxSubscrOperatorText[];
  promoCode: CheckboxSubscrPromocodeText[];
} {
  const operator: CheckboxSubscrOperatorText[] = [];
  const promoCode: CheckboxSubscrPromocodeText[] = [];

  if (Object.keys(product).length > 0) {
    for (const plan of product?.plans) {
      let operatorArrPromo = '';
      let promoCodeArrPromo = '';
      let operatorArrEvergreen = '';
      let promoCodeArrEvergreen = '';
      
      for (const phase of plan?.phases) {
        if (phase.type === 'evergreen') {
          for (const billingPrice of phase?.billing.prices) {
            if (billingPrice.payment_method_type === 'operator') {
              operatorArrEvergreen = `Затем ${billingPrice.money.formatted}
              / ${getFullFormattedTime(phase.access_period.unit, phase.access_period.value || 0)}`;
            }
            if (billingPrice.payment_method_type === 'promo_code') {
              promoCodeArrEvergreen = `${billingPrice.money.formatted}
              / ${getFullFormattedTime(phase.access_period.unit, phase.access_period.value || 0)}`;
            }
          }
        }

        if (phase.type === 'promo') {
          for (const billingPrice of phase?.billing.prices) {
            if (billingPrice.payment_method_type === 'operator') {
              operatorArrPromo = `${billingPrice.money.formatted}
              / ${getFullFormattedTime(phase.access_period.unit, phase.access_period.value || 0)}`;
            }
            if (billingPrice.payment_method_type === 'promo_code') {
              promoCodeArrPromo = `${billingPrice.money.formatted}
              / ${getFullFormattedTime(phase.access_period.unit, phase.access_period.value || 0)}`;
            }
          }
        }
      }
      if (operatorArrPromo !== '') {
        operator.push({
          promo: operatorArrPromo,
          everGreen: operatorArrEvergreen,
          type: 'operator',
          plan
        });
      }

      if (promoCodeArrPromo !== '') {
        promoCode.push({
          promo: promoCodeArrPromo,
          everGreen: promoCodeArrEvergreen,
          type: 'promocode',
          plan
        });
      }
    }
  }

  return { operator, promoCode };
}


export const getPlanType = (plan: Plan): string => {
  if (plan.object === 'plan') {
    return 'SVOD';
  } else if (plan.object === 'tvod_plan') {
    return 'TVOD';
  } else if (plan.object === 'est_plan') {
    return 'EST';
  }
  return '';
};
