import ResponseManyMeta from '~typings/ResponseMeta';


const LIMIT = 50;
const maxSliderSizeDefault = 12

const ALL_ITEMS_LIMIT = 999;

const errorManyMeta = (status?: number): ResponseManyMeta => ({
  status: status || 500,
  pagination: {
    count: 0,
    limit: 0,
    offset: 0,
    total: 0,
  }
});


export {
  ALL_ITEMS_LIMIT,
  errorManyMeta,
  LIMIT,
  maxSliderSizeDefault
};
