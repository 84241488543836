import produce, { Draft } from 'immer';

import Plan from '~typings/Plan';
import Product from '~typings/Product';
import Subscription from '~typings/Subscription';


export const getActiveSubscriptionWithProduct = (
  productID?: string,
  subscriptions: Subscription[] = [],
): Subscription | undefined => (
  subscriptions.find(({ plan: { product: { id } }, status }) => (status === 'active' && productID === id))
);


const getProductInSubscriptions = (product: Product, subscriptions: Subscription[] = []): Product => {
  const subscriptionsWithProduct = subscriptions
    .filter(({ plan: { product: { id } }}) => (product.id === id));

  if (!subscriptionsWithProduct) { return product; }

  const plans: Plan[] = [];

  product.plans.forEach((plan) => {
    const actualPlan = subscriptionsWithProduct.find(x => x.plan.id === plan.id)?.plan;

    plans.push(actualPlan || plan);
  });

  return produce(product, (draft: Draft<Product>) => {
    draft.plans = plans;
  });
};


export default getProductInSubscriptions;
