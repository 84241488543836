enum BarItem {
  Play = 'play',
  PlayContinue = 'play_continue',
  Trailer = 'trailer',
  WatchLaterAdd = 'watch_later_add',
  WatchLaterRemove = 'watch_later_remove',
  RateUp = 'rate_up',
  RatedUp = 'rated_up',
  RateDown = 'rate_down',
  RatedDown = 'rated_down',
  BestPrice = 'best_price',
  PromoPrice = 'promo_price',
  /**
   * Только для сериалов
   */
  AllSeasons = 'all_seasons',
  /**
   * Только для сериалов
   */
  AllEpisodes = 'all_episodes',
    /**
   * Только для аудиошоу
   */
  AllParts = 'all_parts',
}


export {
  BarItem,
};
