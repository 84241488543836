/* eslint-disable max-len */
import * as React from 'react';

type Props = {
  className?: string;
  color: string
}

const ForButtonsPlay = ({ className, color }: Props) => (
  <svg 
    viewBox="0 0 30 30" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className={ className }
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M9.91304 4C9.58783 4 9.26261 4.08609 8.97565 4.2487C8.37304 4.58348 8 5.22435 8 5.91304V24.087C8 24.7757 8.37304 25.4165 8.97565 25.7609C9.26261 25.9235 9.58783 26 9.91304 26C10.2574 26 10.6113 25.9043 10.9174 25.7226L25.8583 16.5591C26.4322 16.2148 26.7765 15.593 26.767 14.9235C26.7574 14.2539 26.413 13.6417 25.8391 13.2878L10.8983 4.27739C10.5922 4.09565 10.2478 4 9.91304 4Z" 
      fill={ color }
    />
  </svg>
);


export { ForButtonsPlay };
