import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { Nullable } from '~lib/type-utils/utils';
import AudioShow from '~typings/AudioShow';
import AudioShowPart from '~typings/AudioShowPart';
import { SquareCard } from '~ui/CardV2/Square';

import { useCardPropsFromPart } from './utils';

export type Props = {
  part: AudioShowPart;
  partProgress?: Nullable<number>;
  audioshowCertificationRatings: AudioShow['certification_ratings'];
  isFocused: boolean;
};

const PartCardBase: React.FC<Props & InjectedIntlProps> = ({
  part,
  partProgress,
  audioshowCertificationRatings,
  isFocused,
  intl,
}) => {
  const cardProps = useCardPropsFromPart({
    part,
    partProgress,
    audioshowCertificationRatings,
    intl,
  });
  
  return <SquareCard { ...cardProps } isFocused={ isFocused } />;
};

export const PartCard = injectIntl(React.memo(PartCardBase));
