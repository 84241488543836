import { findIndex } from 'lodash';
import * as React from 'react';

import { api } from '~lib/env-utils';


export interface State {
  apis: string[];
  api: string;
}

export enum ActionType {
  AddAPI,
  DeleteAPI,
  UpdateAPI,
  SetAPI,
}

export interface Action {
  type: ActionType;
  apis?: string[];
  api?: string;
  key?: string;
}

type Dispatch = (action: Action) => void;


export const EnvContext = React.createContext<State>({} as State);
export const EnvDispatchContext = React.createContext<Dispatch | undefined>(undefined);

export const useEnv = () => {
  const context = React.useContext(EnvContext);

  if (context === undefined) {
    throw new Error('useEnv must be used within a EnvProvider')
  }
  return context;
};
export const useEnvAction = () => {
  const context = React.useContext(EnvDispatchContext);

  if (context === undefined) {
    throw new Error('useEnvAction must be used within a EnvProvider')
  }
  return context;
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.AddAPI: {
      const apis = action.api ? state.apis.concat(action.api) : state.apis;

      return ({
        ...state,
        apis,
      });
    }
    case ActionType.DeleteAPI: {
      const api = action.api as string;
      let apis = state.apis;
      const idxForDelete = findIndex(apis, x => x.includes(api as string));

      if (idxForDelete >= 0) {
        apis = apis.filter(x => !x.includes(api));
      }

      return ({
        ...state,
        apis,
      });
    }
    case ActionType.UpdateAPI: {
      if (action.api && action.key) {
        const apis = state.apis;
        const idxForUpdate = findIndex(apis, x => x.includes(action.key as string));

        if (idxForUpdate >= 0) {
          apis[idxForUpdate] = action.api;
        }

        return ({
          ...state,
          apis,
        });
      }

      return state;
    }
    case ActionType.SetAPI: {
      return ({
        ...state,
        api: (action.api || api),
      });
    }
    default:
      return state;
  }
};
