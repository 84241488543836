export enum ItemObject {
  BuiltInPage = 'built_in_page',
  CompetitionPage = 'competition_page',
  ContentPage = 'content_page',
  CustomPage = 'custom_page',
  MainPage = 'main_page',
  NavigationPage = 'navigation_page',
  SingleCollectionPage = 'single_collection_page',
  WebPage = 'web_page',

  AudioShow = 'audio_show',
  Avatar = 'avatar',
  Banner = 'banner',
  BannerVideo = 'banner_video',
  BigBanner = 'big_banner',
  SmallBanner = 'small_banner',
  BigScreenTextBlock = 'big_screen_text_block',
  Card = 'card',
  CardCollection = 'card_collection',
  CardMutationState = 'state',
  Catchup = 'catchup',
  Channel = 'channel',
  Collections = 'collections',
  CompetitionHighlight = 'competition_highlight',
  CompetitionMatch = 'competition_match',
  CurrentProgramLineBlock = 'current_program_line_block',
  Episode = 'episode',
  Episodes = 'episodes',
  ExtraVideo = 'extra_video',
  Favorite = 'favorite',
  Highlight = 'highlight',
  Match = 'match',
  MiddleBanner = 'middle_banner',
  Movie = 'movie',
  News = 'news',
  Offer = 'offer',
  Part = 'part',
  Plan = 'plan',
  Product = 'product',
  ProductFeature = 'product_feature',
  Program = 'program',
  ProgramEvent = 'program_event',
  RadioStation = 'radio_station',
  RentPlanTVOD = 'tvod_plan',
  RentPlanEST= 'est_plan',
  Season = 'season',
  Series = 'series',
  VideoFile = 'video_file',
  WatchProgressWithResource = 'watch_progress_with_resource',
  CastMember = 'cast_member',
  Person = 'person',

  SeeAll = 'see_all',
}

export default ItemObject;
