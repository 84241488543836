/* eslint-disable */

const serviceInfoByLang = {
  terms: {
    ru: () => import('./terms.ru.html'),
  },
  policy: {
    ru: () => import('./policy.ru.html'),
  },
};

export default serviceInfoByLang;
