import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';

import SceneWithHooks from '~components/Scene/componentWithHooks';

import { useIsGlobalSceneBlocked } from './globalSceneDisabler';


const ALLOWED_PAGES = [
  'page',
  'media-item',
];

const isItAllowedToRenderTheScene = (path?: string): boolean => (
  !!path && ALLOWED_PAGES.indexOf(path) !== -1
);

const Scene: React.FC = () => {
  const path = useRouteMatch<{ path: string }>('/:path')?.params.path;

  const isSceneBlocked = useIsGlobalSceneBlocked()

  if (!isItAllowedToRenderTheScene(path) || isSceneBlocked) { return null; }

  return (
    <SceneWithHooks />
  );
};


export default Scene;
