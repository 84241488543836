import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import { NavigationLine } from '~hooks/useSpatialNavigation';

enum FocusOn {
  Description = 0,
  Bar,
  Recommendations,
}

type NavigationItemsProps = Readonly<{
  barItems: BarItem[];
  description: string;
  recommendationsCount?: number;
}>;

const navigationItems = (props: NavigationItemsProps): NavigationLine[] => {
  const { recommendationsCount = 0 } = props;
  const res: NavigationLine[] = [];

  if (props.description) {
    res.push({ maxIndex: 0 }); // description
  } else {
    res.push({});
  }
  if (props.barItems.length !== 0) {
    res.push({ maxIndex: (props.barItems.length - 1) });
  } else {
    res.push({});
  }
  if (recommendationsCount !== 0) {
    res.push({ maxIndex: (recommendationsCount - 1), focusedIndex: 0 });
  } else {
    res.push({});
  }

  return res;
};


export {
  FocusOn,
  navigationItems,
};
