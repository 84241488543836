import * as React from 'react';

import { useApp } from '~components/Provider/App';
import { getFormatFullDate } from '~lib/localeUtils/dates';
import ItemObject from '~typings/ItemObject';
import News from '~typings/News';

import * as styles from './styles.module.css';

type Props = Readonly<{
  item?: News;
}>;

const MediaItemHeader:React.FC<Props> = ({ item }: Props) => {
  const { language } = useApp();
  const renderDate = React.useMemo(() => {
    if (!item || item?.object !== ItemObject.News) { return null; }

    return (
      <div
        className={ styles.date }
      >
        { getFormatFullDate(item.published_at, language) }
      </div>
    );
  }, [item?.id]);
  const renderName = (
    <div
      className={ styles.name }
    >
      { item?.name ?? '' }
    </div>
  );

  if (!item) return null;

  return (
    <>
      { renderDate }
      { renderName }
    </>
  )
};

export default React.memo(MediaItemHeader);
