import focuser from '@focuser';
import * as cn from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Typography } from '~app/ui-kit/Typography';
import ExpiresTime from '~components/MediaItemPage/PurchaseInfo/ExpiresTime';
import { PURCHASED, THE_WHOLE_SERIES } from '~localization';
import Purchase from '~typings/Purchase';

import { PlayButtonInPurchases } from './PlayButtonInPurcases';
import * as styles from './styles.modules.css';
import { getReadyImage } from './utils';


export type Props = {
  purchase: Purchase;
  isFocused: boolean;
  index: number;
  setLastFocusedIdx: (index: number) => void;
  setFocusedPurchase: React.Dispatch<React.SetStateAction<number>>;
};

export const PurchaseItem: React.FC<Props> = React.memo(({ 
  purchase, 
  isFocused, 
  index, 
  setLastFocusedIdx, 
  setFocusedPurchase 
}) => {
  const history = useHistory();

  const image = getReadyImage(purchase.resource.images);
  
  const handleClick = () => {
    setLastFocusedIdx(index);
    history.push(`/media-item/${ purchase.resource.object }/${ purchase.resource.id }`);
  };
  
  const renderPurchasedContentText = (resourceObject) => {
    if(resourceObject === 'series') {
      return <FormattedMessage id={ THE_WHOLE_SERIES }/>
    } else if (resourceObject === 'season') {
      return <FormattedMessage id={ THE_WHOLE_SERIES }/>
    }

  }


  return (
    <focuser.FocusableBlock
      isFocused={ isFocused }
      className={ styles.item }
      onClick={ handleClick }
      onForceFocus={ () => setFocusedPurchase(index) }
      emitForceFocusOnHover
    >
      <div className={ styles.imageWrapper }>
        <div
          className={ cn(
            isFocused ? styles.imageInFocus : styles.image,
          ) }
          style={ { backgroundImage: `url("${ image }")` } }
        >
          { isFocused
              ? <PlayButtonInPurchases className={ { wrapper: styles.iconWrapper, icon: styles.icon } }/>
              : null
          }
        </div>
      </div>
      <div className={ styles.descriptionWrapper }>
        <div className={ styles.descContentWrapper }>
          <Typography 
            variant={ 'h6_medium' }
            className={ cn(styles.title,
              {[styles.titleFocus]: isFocused})
            }
          >
            { purchase.resource.name }
          </Typography>

          <Typography 
            variant={ 'body1_medium' } 
            className={ styles.subTitle }
          >
            { renderPurchasedContentText(purchase.resource.object) }
          </Typography>

          <Typography  
            variant={ 'body1_medium' } 
            className={ styles.subTitle }
          >
            {
              purchase.object.includes('tvod')
                ? <ExpiresTime reasonObject={ purchase }/>
                : <FormattedMessage id={ PURCHASED }/>
            }
          </Typography>
        </div>
      </div>
    </focuser.FocusableBlock>
  )
});

PurchaseItem.displayName = 'PurchaseItem';
