import React, { memo } from 'react';

import { usePlayerState } from '~components/Provider/Player/component.helpers';
import { usePlayerStore } from "~stores/Player";
import Fade from '~ui/Effects/Fade';

import { Player2 } from "./player2";
import * as styles from './styles.module.css';

/**
 * этот плеер с новой логикой только для попкорна, можно вносить изменения
 */

// eslint-disable-next-line react/display-name
const Player = memo(() => {
  const setSecondsMissing = usePlayerStore(state => state.setMissingSeconds)
  const playerState = usePlayerState();

  React.useEffect(() => {
    setSecondsMissing(0)
  }, [])

  return (
    <Fade
      duration={ 1000 }
      className={ styles.scenePlayerWrapper }
      isVisible={ !!playerState.item }
      willBeNeedRemoveChildAfterFadeOut
    >
      {
        playerState.item
          ? <Player2 playerState={ playerState } />
          : null
      }
    </Fade>
  );
})


export { Player as PopcornPlayer }
