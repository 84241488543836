import { BarItem } from '~components/MediaItemPage/Description/bar/typings';
import { favoriteTypeByItemObject } from '~hooks/fetch/useFavorites/useFavoritesV3.helpers';
import { FavoriteType } from '~typings/FavoriteType';
import ItemObject from '~typings/ItemObject';
import Vote, { VoteAction } from '~typings/Vote';

type Props = {
  hasTrailer: boolean,
  object: ItemObject,
  isInFavorites?: boolean,
  vote?: Vote['action'],
};

const getBarItems = (props: Props): BarItem[] => {
  const barItems: BarItem[] = [];

  if (props.hasTrailer) {
    barItems.push(BarItem.Trailer);
  }

  if (
    Object.keys(FavoriteType)
      .some(key => FavoriteType[key] === favoriteTypeByItemObject[props.object])
  ) {
    barItems.push(
      props.isInFavorites ? BarItem.WatchLaterRemove : BarItem.WatchLaterAdd,
    );
  }

  barItems.push(props.vote === VoteAction.Up ? BarItem.RatedUp : BarItem.RateUp);
  barItems.push(props.vote === VoteAction.Down ? BarItem.RatedDown : BarItem.RateDown);
  
  return barItems;
};


export { getBarItems }