import focuser from '@focuser';
import serviceInfoByLang from 'customization-project-name/service-info';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useAnimationContext } from '~components/Provider/Animation';
import { useApp } from '~components/Provider/App';
import ScrollBar from '~components/ScrollBar';
import { useScrollFocuser } from '~hooks/useScroll/useScrollFocuser';
import preparingText from '~lib/preparingText';
import { SpinnerGlobal } from '~newapp/globalBlocks/spinner';
import { PopupIsolated } from '~ui/Popup';

import * as styles from './DocViewPopup.module.css';


type Props = {
  /**
   * Коллбэк, который вызывается при клике на кнопку "Назад"
   */
  onBack: () => void;
  /**
   * Коллбэк, который вызывается при ошибке
   */
  onError: () => void;
  /**
   * Тип документа, который нужно открыть
   */
  docType: 'terms' | 'policy';
  /**
   * Язык документа, по умолчанию будет передан язык приложения
   */
  language?: string;
};

/**
 * Компонент помогает посмотреть документ
 * Открывает попап с этим документом
 */
export const DocViewPopup: React.FC<Props> = React.memo(
  ({ onBack, onError, docType: infoType, language }) => {
    const { language: appLanguage } = useApp();
    const [htmlContent, setHtmlContent] = useState<string>('');

    useEffect(() => {
      const htmlBundle = serviceInfoByLang?.[infoType]?.[language ?? appLanguage] as
        | (() => Promise<any>)
        | undefined;
      setHtmlContent('');

      if (!htmlBundle) {
        onError();
        return;
      }

      let isMounted = true;

      htmlBundle()
        .then((htmlContent: { default: string }) => {
          if (!isMounted) {
            return;
          }

          setHtmlContent(preparingText(htmlContent.default));
        })
        .catch(()=>{
          if(isMounted){
            onError();
          }
        });

      return () => {
        isMounted = false;
      };
    }, [infoType, language, appLanguage]);

    if (!htmlContent) {
      return (
        <PopupIsolated>
          <focuser.FocusableBlock isFocused onKeyReturn={ onBack } className={ styles.spinner }>
          <SpinnerGlobal />
          </focuser.FocusableBlock >
        </PopupIsolated>
      );
    }

    return (
      <PopupIsolated>
        <TextContent
          text={ htmlContent }
          onBack={ onBack }
        />
      </PopupIsolated>
    );
  },
);

DocViewPopup.displayName = 'DocViewPopup';

const TextContent = ({ text, onBack }: { text: string; onBack: () => void }) => {

const { isAnimationEnabled } = useAnimationContext();
const animationDuration = isAnimationEnabled ? 200 : 0;


  const {
    wrapperRef,
    contentRef,
    isPointerDownAvailable,
    isPointerUpAvailable,
    onKeyDown,
    onKeyUp,
    scrollPercent,
    isScrollAvailable,
  } = useScrollFocuser({
    heightUpdates: [text],
    animationDurationInMS: animationDuration,
  });

  return (
    <focuser.FocusableBlock
      isPointerDownAvailable={ isPointerDownAvailable }
      isPointerUpAvailable={ isPointerUpAvailable }
      onKeyReturn={ onBack }
      isFocused
      noNeedForceFocus
      forwardRef={ wrapperRef }
      onKeyDown={ onKeyDown }
      onKeyUp={ onKeyUp }
      className={ styles.wrapper }
    >
      <div
        className={ styles.content }
        ref={ contentRef }
        dangerouslySetInnerHTML={ { __html: preparingText(text) } }
      />
      {isScrollAvailable ? (
        <ScrollBar
          position={ scrollPercent }
          className={ styles.scrollbar }
          animationDurationInMS={ animationDuration }
          isFocused
        />
      ) : null}
    </focuser.FocusableBlock>
  );
};
