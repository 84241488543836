import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { usePlayerState } from '~components/Provider/Player';
import CheckBoxList from '~components/ScenePlayer/Player/controls/Popup/CheckBoxList';
import { IPlayer } from '~lib/player/typings';
import {
  PLAYER_POPUP_VIDEO_AUTO_QUALITY,
  PLAYER_POPUP_VIDEO_QUALITY,
} from '~localization';


type Props = Readonly<{
  player: IPlayer;
  onClose: () => void;
  intl: InjectedIntl;
}>;


const VideoTracksPopup: React.FC<Props> = (props: Props) => {
  const isAutoQuality = props.player.isAutoQualityEnabled();
  const { videoTracks } = usePlayerState();
  const selectedTrack = videoTracks.find(({ isSelected }) => isSelected);

  const handleChangeVideoTrack = (videoTrackIndex) => {
    try {
      if (videoTrackIndex === 0) {  // auto quality case
        props.player.setAutoQuality(!props.player.isAutoQualityEnabled());
      } else if (videoTracks[videoTrackIndex - 1]) {
        props.player.changeVideoTrack(videoTracks[videoTrackIndex - 1]);
      }
    } catch (ignore) {
      // ignore
    }

    props.onClose();
  };

  const items = React.useMemo(() => {
    const autoQualityText = (isAutoQuality && selectedTrack) ?
      `${props.intl.formatMessage({ id: PLAYER_POPUP_VIDEO_AUTO_QUALITY })} (${selectedTrack.name})`
      :
      props.intl.formatMessage({ id: PLAYER_POPUP_VIDEO_AUTO_QUALITY });
    const result = [
      {
        index: 0.1,
        name: autoQualityText,
        isChecked: isAutoQuality,
      },
    ];

    videoTracks.forEach(({ index, name, isSelected }) => {
      result.push({
        index,
        name,
        isChecked: (!isAutoQuality && !!isSelected),
      });
    });

    return result;
  }, [isAutoQuality, selectedTrack?.index, videoTracks.length]);

  return (
    <CheckBoxList
      header={ props.intl.formatMessage({ id: PLAYER_POPUP_VIDEO_QUALITY }) }
      items={ items }
      onClick={ handleChangeVideoTrack }
    />
  );
};


export default injectIntl(VideoTracksPopup);
