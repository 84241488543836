import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton/PrimaryButtonFocuser';
import { getAuthData } from '~hooks/fetch/useAccount';
import AudioShow from '~typings/AudioShow';
import ItemObject from '~typings/ItemObject';
import PlayerMediaItem from '~typings/PlayerMediaItem';
import Series from '~typings/Series';

import { useBestPriceButtonText } from './BestPriceButton.helpers';

type Props = {
  item: PlayerMediaItem | AudioShow | Series | undefined | null;
  isFocused: boolean;
  intl: InjectedIntl;
};

const BestPriceButton: React.FC<Props> = ({ item, isFocused, intl }) => {
  const history = useHistory();
  const matchPage = useRouteMatch('/page/:id');
  const { accessToken } = getAuthData();

  const { id, object } = React.useMemo(() => {
    if (item?.object === ItemObject.Episode) {
      return {
        id: item?.series?.id,
        object: ItemObject.Series,
      };
    }

    if (item?.object === ItemObject.ProgramEvent) {
      return {
        id: item?.channel_id,
        object: ItemObject.Channel,
      };
    }

    return {
      id: item?.id,
      object: item?.object,
    };
  }, [item?.object, item?.id]);

  const { text, from } = useBestPriceButtonText({
    resourceID: id,
    intl,
  });

  const handleClick = () => {
    if (!item) {
      return;
    }
    // TODO SPBTV-13460-back-button-on-search-results:
    // replace only for channels and events??
    const goTo = accessToken || object !== ItemObject.Channel ? history.push : history.replace;
    const url = matchPage
      ? `${matchPage.url}/media-item/${object}/${id}/products`
      : `/media-item/${object}/${id}/products`;

    if (from === 'rents') {
      goTo(`${url}/rents`);
    } else {
      goTo(`${url}/subscriptions`);
    }
  };

  return (
    <PrimaryButtonFocuser
      isFocused={ isFocused }
      onClick={ handleClick }
      noNeedForceFocus
    >
      {text}
    </PrimaryButtonFocuser>
  );
};

export default injectIntl(BestPriceButton);
