import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import { ResourceType,useInfinityProductsItems } from '~hooks/fetch/useProductsItems/useProductsItems';
import ImageType from '~typings/ImageType';

import Slider from './Slider';
import * as styles from './styles.module.css';


type Props = Readonly<{
  id: string;
  productID: string;
  resourceTypes: ResourceType[];
  intl: InjectedIntl;
}>;

const ProductsItemsSlider: React.FC<Props> = (props: Props) => {
  const { parsedItems } = useInfinityProductsItems({
    productID: props.productID,
    resourceTypes: props.resourceTypes,
  });

  if (!parsedItems) { return null; }

  return (
    <>
      <h2 className={ styles.sliderName }>
        {
          `${props.intl.formatMessage({ id: props.id })}`
        }
      </h2>
      <Slider
        dimension={
          props.resourceTypes.includes(ResourceType.Channels) ?
            ImageType.LOGO_SMALL : ImageType.POSTER
        }
        items={ parsedItems }
        withGenres={ !props.resourceTypes.includes(ResourceType.Channels) }
      />
    </>
  );
};


export default React.memo(ProductsItemsSlider);
