import { gw_04 } from 'customization-project-name/colors'
import * as React from 'react';

type Props = {
  className?: string;
};

/* eslint-disable max-len */
const IphoneIcon: React.FC<Props> = ({ className = '' }) => (
  <svg className={ className } viewBox="0 0 39 70" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.4229 60.235C20.9849 60.235 22.2519 61.4425 22.2519 62.9475C22.2519 64.4525 20.9849 65.66 19.4229 65.66C17.861 65.66 16.6114 64.4525 16.6114 62.9475C16.6114 61.4425 17.8783 60.235 19.4229 60.235ZM33.2205 0C36.1362 0 38.5138 2.2925 38.5138 5.0925V64.9075C38.5138 67.7075 36.1362 70 33.2205 70H5.62542C2.70972 70 0.332031 67.725 0.332031 64.9075V5.0925C0.332031 2.2925 2.70972 0 5.62542 0H33.2378H33.2205ZM7.62129 3.6925C5.7122 3.6925 4.16757 5.18 4.16757 7.0175V53.3575C4.16757 55.195 5.7122 56.6825 7.62129 56.6825H31.2246C33.1337 56.6825 34.6783 55.195 34.6783 53.3575V7.0175C34.6783 5.18 33.1337 3.6925 31.2246 3.6925H7.62129ZM33.2205 1.7675H5.62542C3.71633 1.7675 2.1717 3.255 2.1717 5.0925V64.9075C2.1717 66.745 3.71633 68.2325 5.62542 68.2325H33.2378C35.1469 68.2325 36.6915 66.745 36.6915 64.9075V5.0925C36.6915 3.255 35.1469 1.7675 33.2378 1.7675H33.2205Z" 
      fill={ gw_04 }
    />
  </svg>
);


export default IphoneIcon;