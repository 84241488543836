import { adc_01 } from 'customization-project-name/colors'
import React from "react";

interface Props {
  width?: number;
  height?: number;
}

/* eslint-disable max-len */
export function ErrorIcon2(props: Props) {
  const { width = 2.03125, height = 3.6111111111111107 } = props

  return (
    <svg viewBox="0 0 26 26" width={ `${ width }vw` } height={ `${ height }vh` } fill={ adc_01 }>
      <path
        d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM11.1944 7.58333C11.1944 6.58615 12.0028 5.77778 13 5.77778C13.9972 5.77778 14.8056 6.58615 14.8056 7.58333V14.0833C14.8056 15.0805 13.9972 15.8889 13 15.8889C12.0028 15.8889 11.1944 15.0805 11.1944 14.0833V7.58333ZM14.8056 19.1389C14.8056 20.1361 13.9972 20.9444 13 20.9444C12.0028 20.9444 11.1944 20.1361 11.1944 19.1389C11.1944 18.1417 12.0028 17.3333 13 17.3333C13.9972 17.3333 14.8056 18.1417 14.8056 19.1389Z" />
    </svg>
  )
}