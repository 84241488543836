import * as cn from 'classnames';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { InjectedIntl } from 'react-intl';

import { definePaymentMethodTranslation } from '~components/ProductPlanPage/PaymentMethodRadioButtons.helpers';
import PaymentMethod from '~typings/PaymentMethod';
import Plan from '~typings/Plan';
import UserPaymentMethod from '~typings/UserPaymentMethod';
import userPaymentMethod from '~typings/UserPaymentMethod';
import CardIcon from '~ui/Icon/icons/Card';
import PhoneIcon from '~ui/Icon/icons/Phone';
import { RadioButton } from '~ui/RadioButton';

import * as styles from './styles.module.css';


type Props = {
  isFocused: boolean;
  focusIndex: number;
  selectedMethod?: UserPaymentMethod | null;
  onClick?: (id: Plan) => void;
  intl: InjectedIntl;
  setSelectedMethod?: (pm: UserPaymentMethod | undefined) => void | null
  paymentMethods?: userPaymentMethod[]
};

const PaymentMethodRadioButtons: React.FC<Props> = (props) => {
  const {
    isFocused,
    focusIndex,
    intl,
    setSelectedMethod,
    selectedMethod
  } = props;

  const { paymentMethods } = props

  const allowedMethods =  useMemo(() => {
    return paymentMethods?.filter(pm => !pm.payment_method_type.endsWith('existing_card'))
  }, [paymentMethods])

  useEffect(() => {
    if (setSelectedMethod) {
      setSelectedMethod(allowedMethods?.[0]);
    }
  }, [setSelectedMethod, paymentMethods])

  return (
    <div className={ cn(styles.buttonWrapper, styles.paymentMethodWrapper) }>
      {
        allowedMethods ? allowedMethods.map((pm, idx) => (
            <div
              key={ idx }
              className={ styles.radioButton }
            >
              <RadioButton
                onClick={ () => setSelectedMethod ? setSelectedMethod(pm) : null }
                isFocused={ isFocused && focusIndex === idx }
                isChecked={ selectedMethod?.id === pm.id }
              >
                <span className={ styles.iconWrapper }>
                  <Icon type={ pm.payment_method_type }/>
                </span>
                <span className={ styles.paymentMethod }>
                  {
                    definePaymentMethodTranslation(intl, pm)
                  }
                </span>
              </RadioButton>
            </div>
        )) : null
      }
    </div>
  );
};

const Icon = ({ type }) => {
  switch (type) {
    case PaymentMethod.Operator:
      return <PhoneIcon className={ styles.icon } />;
    case PaymentMethod.NewCard:
    case PaymentMethod.a1NewCard:
    case PaymentMethod.a1Existing_card:
    case PaymentMethod.Existing_card:
      return <CardIcon className={ styles.icon } />;
    default:
      return null;
  }
};


export default React.memo(PaymentMethodRadioButtons);
