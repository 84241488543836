import cn from 'classnames';
import React from 'react';

import { Typography } from '~app/ui-kit/Typography';
import { CatchUpIcon } from '~newapp/ui-kit/Icons/For_buttons/catchUpIcon';
import { ProgressBar } from '~newapp/ui-kit/Other/ProgressBar';
import { DATA_ATTR_IDX, DataIdxAttrType } from '~newapp/utils/globals/DataAttributeIdx';

import * as s from './EPG_item.module.css';

type State = 'ended' | 'catchup' | 'actual';

export type Props = {
  className?: string;
  HHmm: string;
  title: string;
  state?: State;
  progress?: number | undefined;
  isFocused: boolean;
  dataIdx?: DataIdxAttrType
};

export const EPG_item = (props: Props): JSX.Element => {
  const { className, isFocused, HHmm, title, state = 'actual', progress } = props;

  const dynamicClasses = [
    s[`epgItem__${state}`],
    {
      [s.epgItem__focused]: isFocused,
    },
  ];

  const dataIdx = ()=> {
    if (typeof props.dataIdx !== 'number') return;
    return {
      [DATA_ATTR_IDX]: props.dataIdx,
    };
  }

  return (
    <div className={ cn(className, s.epgItem, ...dynamicClasses) } { ...dataIdx() }>
      <div className={ s.flex }>
        <Typography
          className={ s.eventTime }
          variant="subtitle1_medium"
        >
          {HHmm}
        </Typography>
        <div className={ s.flex }>
          {isCatchUp(state) && <CatchUpIcon className={ s.catchupIcon } />}
          <Typography
            className={ s.eventTitle }
            variant="subtitle1_medium"
          >
            {title}
          </Typography>
        </div>
      </div>
      {isProgress(progress) && (
        <ProgressBar
          className={ s.eventProgress }
          progress={ progress }
        />
      )}
    </div>
  );
};

const isCatchUp = (state: State): state is 'catchup' => {
  return state === 'catchup';
};

const isProgress = (progress: number | undefined): progress is number => {
  return typeof progress === 'number';
};
