import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import TrialFreePeriodAndPriceText from "~components/ProductItem/Texts/TrialFreePeriodAndPriceText";
import UnSubscribeButton from '~components/ProductItem/UnSubscribeButton';
import useSpatialNavigation from '~hooks/useSpatialNavigation';
import {
  ALREADY_SUBSCRIBED,
  NAVIGATION_BACK,
  SUBSCRIPTION_CANCELLING,
} from '~localization';
import Product from '~typings/Product';
import Subscription from '~typings/Subscription';
import Button from '~ui/button';

import * as styles from './styles.module.css';

type Props = Readonly<{
  product: Product;
  subscriptions?: Subscription[];
  intl: InjectedIntl;
}>;

const Unsubscribe: React.FC<Props> = (props) => {
  const history = useHistory();

  const navigationItems = React.useRef([
    { maxIndex: 1 }, // Buttons
  ]);
  const navigationState = useSpatialNavigation({
    allowNavigation: true,
    navigationItems: navigationItems.current,
  });
  const focusIndex = navigationState.focusedIndex[0];

  const subscription = (props.subscriptions || []).find(x => x.status === 'active');
  const isCancellable = subscription?.cancellable;
  const isTrialPhase = subscription?.phase?.type === 'trial';

  const handleBack = () => {
    history.goBack();
  };

  return subscription ? (
    <>
      <div className={ styles.info }>
        {
          props.intl.formatMessage({ id: SUBSCRIPTION_CANCELLING })
        }
      </div>
      <div className={ styles.buttonWrapper }>
        {
          isTrialPhase ? (
            <TrialFreePeriodAndPriceText
              isTrialPeriod={ !!(subscription?.phase?.type === 'trial') }
              price={ {
                value: subscription?.plan.eligible_phase?.billing.price.formatted,
                duration: (subscription?.plan.eligible_phase?.access_period.value || 0),
                unit: (subscription?.plan.eligible_phase?.access_period.unit || 'days'),
              } }
              autoReNew={ subscription?.autorenew }
            />
          ) : null
        }
      </div>
      <div className={ styles.buttonWrapper }>
        {
          isCancellable ? (
            <UnSubscribeButton
              productID={ props.product.id }
              subscriptionID={ subscription.id }
              isFocused={ focusIndex === 0 }
              intl={ props.intl }
            />
          ) : (
            <Button
              isFocused={ false }
              className={ cn(styles.button, {
                [styles.isFocused]: false,
              }) }
              isDisabled
            >
              {
                props.intl.formatMessage({ id: ALREADY_SUBSCRIBED })
              }
            </Button>
          )
        }
        <Button
          isFocused={ !isCancellable || focusIndex === 1 }
          className={ cn(styles.button, {
            [styles.isFocused]: !isCancellable || focusIndex === 1,
          }) }
          onClick={ handleBack }
        >
          {
            props.intl.formatMessage({ id: NAVIGATION_BACK })
          }
        </Button>
      </div>
    </>
  ) : null;
};


export default injectIntl(Unsubscribe);
