import focuser from '@focuser';
import { FocuserClickHandler, FocuserKeyOrClickHandler } from '@focuser/events';
import * as React from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';

import { PrimaryButtonFocuser } from '~app/ui-kit/PrimaryButton';
import { KeyboardFieldSet } from '~components/KeyboardFieldSet';
import {
  ACCOUNT_SECURITY_CREATE_PIN_TEXT,
  ACCOUNT_SECURITY_CREATE_PIN_TITLE,
  PIN_CODE_CANCEL,
  PIN_CODE_CREATE,
} from '~lib/localizator/src/dictionaries';

import * as styles from './PinCodeQuestion.module.css';

export type Props = {
  onBack: () => void;
  onSkip: () => void;
  onConfirm: () => void;
  intl: InjectedIntl;
};

enum FocusOn {
  NotNowButton = 'notNowButton',
  CreateButton = 'createButton',
}

export const PinCodeQuestion: React.FC<Props> = ({
  onBack,
  onSkip,
  intl,
  onConfirm: createButtonHandler,
}) => {
  const [focusOn, setFocusOn] = React.useState<FocusOn>(FocusOn.CreateButton);

  const handleBack: FocuserKeyOrClickHandler = (evt) => {
    onBack?.();
    evt.stop();
    evt.stopNativeEvent();
  };

  const handleSkip: FocuserClickHandler = (evt) => {
    evt.stop();
    evt.stopNativeEvent();
    onSkip();
  }

  // Buttons

  const setCreateButton = () => {
    setFocusOn(FocusOn.CreateButton);
  };

  const setNotNowButton = () => {
    setFocusOn(FocusOn.NotNowButton);
  };

  // Text

  const headerText = intl.formatMessage({
    id: ACCOUNT_SECURITY_CREATE_PIN_TITLE,
  });

  return (
    <focuser.FocusableBlock
      isFocused
      onKeyLeft={ setNotNowButton }
      onKeyRight={ setCreateButton }
      onKeyReturn={ handleBack }
      noNeedForceFocus
    >
      <KeyboardFieldSet headerText={ headerText }>
        <div className={ styles.contentWrap }>
          <div className={ styles.text }>
            <FormattedMessage id={ ACCOUNT_SECURITY_CREATE_PIN_TEXT } />
          </div>
          <div className={ styles.buttons }>
            <PrimaryButtonFocuser
              buttonClassName={ styles.button }
              isFocused={ focusOn === FocusOn.NotNowButton }
              onClick={ handleSkip }
              onForceFocus={ setNotNowButton }
              emitForceFocusOnHover
            >
              <FormattedMessage id={ PIN_CODE_CANCEL } />
            </PrimaryButtonFocuser>

            <PrimaryButtonFocuser
              buttonClassName={ styles.button }
              isFocused={ focusOn === FocusOn.CreateButton }
              onForceFocus={ setCreateButton }
              emitForceFocusOnHover
              onClick={ (evt) => {
                createButtonHandler();
                evt.stop();
                evt.stopNativeEvent();
              } }
            >
              <FormattedMessage id={ PIN_CODE_CREATE } />
            </PrimaryButtonFocuser>
          </div>
        </div>
      </KeyboardFieldSet>
    </focuser.FocusableBlock>
  );
};

export default injectIntl(PinCodeQuestion);
