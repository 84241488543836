/* eslint-disable max-len */
import React from 'react';

export interface Props {
  className?: string;
}

export const ResetIcon: React.FC<Props> = ({
  className
}) => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={ className }
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4167 8.39889C25.1943 7.62135 25.1943 6.3607 24.4167 5.58316C23.6392 4.80561 22.3785 4.80561 21.601 5.58316L14.9999 12.1843L8.39904 5.58345C7.62149 4.80591 6.36085 4.80591 5.5833 5.58345C4.80576 6.36099 4.80576 7.62164 5.5833 8.39918L12.1841 15L5.58316 21.601C4.80561 22.3785 4.80561 23.6392 5.58316 24.4167C6.3607 25.1943 7.62134 25.1943 8.39889 24.4167L14.9999 17.8157L21.6011 24.417C22.3787 25.1946 23.6393 25.1946 24.4169 24.417C25.1944 23.6395 25.1944 22.3788 24.4169 21.6013L17.8156 15L24.4167 8.39889Z"
        fill="currentColor"
      />
    </svg>
  );
};
