import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { useScene } from '~components/Provider/Scene';
import Event from '~typings/Event';


type Props = Readonly<{
  item: Event;
}>;


const ProgramEventPlayer: React.FC<Props> = (props: Props) => {
  const scene = useScene();
  const state = usePlayerState();
  const { setItem } = usePlayerStateActions();
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname.includes('products')) { return; }
    scene.changeSceneMediaItem(null, false, true);
  }, [pathname, props.item.id]);

  React.useEffect(() => {
    if (props.item.id !== state.item?.id) {

      setItem(props.item);
    }
  }, [props.item.id]);

  return null;
};


export default React.memo(ProgramEventPlayer);
