// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--pe30q {\n  width: 60.938vw;\n  text-align: center;\n  margin-bottom: 4.09vw;\n  font-size: 1.875vw;\n  font-weight: 500;\n  color: rgba(255, 255, 255, 1);\n}\n\n.contentWrap--UwvDe {\n  margin-top: 4.516vw;\n}\n\n.button--kVwfX {\n  min-width: 12.734vw;\n  margin: 0 0.781vw;\n}\n\n.buttons--ihTW6 {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/PinCodeManager/PinCodeQuestion.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,qBAAqB;EACrB,kBAAqB;EACrB,gBAAkB;EAClB,6BAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;AACzB","sourcesContent":[".text {\n  width: 60.938vw;\n  text-align: center;\n  margin-bottom: 4.09vw;\n  font-size: $fontH5_vw;\n  font-weight: $fw_5;\n  color: $gw_11;\n}\n\n.contentWrap {\n  margin-top: 4.516vw;\n}\n\n.button {\n  min-width: 12.734vw;\n  margin: 0 0.781vw;\n}\n\n.buttons {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var text = "text--pe30q";
export var contentWrap = "contentWrap--UwvDe";
export var button = "button--kVwfX";
export var buttons = "buttons--ihTW6";
export default ___CSS_LOADER_EXPORT___;
