import cn from "classnames";
import React, { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import { Typography } from "~app/ui-kit/Typography";
import useFetchCurrentEvent from "~hooks/fetch/useCurrentEvent/useCurrentEvent";
import { CHANNEL_FLIPPER_NO_SCHEDULE } from "~localization";
import type Channel from "~typings/Channel";

import * as css from './CardInfo.module.css'
import { CurrentEventProgress } from "./CurrentEventProgress";
import { TimeRange } from "./TimeRange";

type Props = {
  channel: Channel;
}

const CardInfo$ = (props: Props) => {
  const { data, refetch } = useFetchCurrentEvent(props.channel.id)

  const startAt = data?.data[0]?.start_at
  const endAt = data?.data[0]?.end_at

  const channelPosition = props.channel?.position
  const channelName = props.channel?.name
  const eventName = data?.data[0]?.name
  const eventExist = data && data.data.length !== 0

  useEffect(() => {
    if (!data?.data.length) {
      return
    }

    const futureDate = new Date(data.data[0].end_at).getTime()
    const currentDate = new Date().getTime()

    const timeout = setTimeout(() => {
      refetch()
    }, futureDate - currentDate)

    return () => clearTimeout(timeout)
  }, [data?.data])

  return (
    <div className={ cn(css.mainWrapper, {
      [css.mainWrapperNoEvent]: !eventExist
    }) }>
      <div className={ css.headerAndDescWrapper }>
        <div className={ css.header }>
          { channelPosition ? <Typography variant={ 'h5_bold' }>{ channelPosition }&ensp;</Typography> : null }
          { channelName ? <Typography variant={ 'h5_bold' }>{ channelName }</Typography> : null }
        </div>
        <div className={ css.description }>
          {
            eventName
              ? <Typography variant={ "subtitle1_medium" }>{ eventName }</Typography>
              : <FormattedMessage id={ CHANNEL_FLIPPER_NO_SCHEDULE } />
          }
        </div>
      </div>
      {
        eventExist
          ? <div className={ css.progressAndTimeWrapper }>
            <TimeRange startAt={ startAt! } endAt={ endAt! } />
            <CurrentEventProgress startAt={ startAt! } endAt={ endAt! } />
          </div>
          : null
      }
    </div>
  )
}

export const CardInfo = React.memo(injectIntl(CardInfo$))