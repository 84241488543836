import React from 'react'
import { InjectedIntl, injectIntl } from "react-intl";

import { EPISODE_NUM, SEASON_NUM } from "~localization";
import { RatingLine } from "~newapp/blocks/ratingLine";
import PlayerMediaItem from "~typings/PlayerMediaItem";

import { getDescriptionFields } from "../hooks/getDescriptionFields";
import * as css from './styles.module.css'
import { SeasonOrGenres } from "./subComponents/SeasonOrGenres";
import { VodTitle } from "./subComponents/VodTitle";

type Props = {
  item: PlayerMediaItem
  intl: InjectedIntl;
}

const LocalComponent = (props: Props) => {
  const data = getDescriptionFields(props.item)
  const seasonNum = props.intl.formatMessage({ id: SEASON_NUM }, { num: data.seasonNum })
  const episodeNum = props.intl.formatMessage({ id: EPISODE_NUM }, { num: data.episodeNum })
  const seasonEpisodeNum = [seasonNum, episodeNum].join(', ')
  const isSeries = Boolean(data.seasonNum && data.episodeNum)

  return (
    <div className={ css.mainWrapper }>
      <div className={ css.vodFirstLine }>
        <RatingLine data={ data } />
      </div>
      <VodTitle data={ data } />
      <SeasonOrGenres isSeries={ isSeries } seasonEpisodeNum={ seasonEpisodeNum } genres={ data.genres } />
    </div>
  )
}

export const VodInfo = injectIntl(LocalComponent)