import * as React from 'react';


type Props = {
  className?: string;
};

/* eslint-disable max-len */
const ExclamationMarkIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg className={ className } xmlns="http://www.w3.org/2000/svg">
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM34.4447 23.3336C34.4447 20.2653 36.932 17.778 40.0002 17.778C43.0685 17.778 45.5558 20.2653 45.5558 23.3336V43.3335C45.5558 46.4018 43.0685 48.8891 40.0002 48.8891C36.932 48.8891 34.4447 46.4018 34.4447 43.3335V23.3336ZM45.5554 58.8886C45.5554 61.9568 43.0681 64.4441 39.9999 64.4441C36.9316 64.4441 34.4443 61.9568 34.4443 58.8886C34.4443 55.8203 36.9316 53.333 39.9999 53.333C43.0681 53.333 45.5554 55.8203 45.5554 58.8886Z" 
        fill="currentColor"
      />
    </svg>
  );
};


export default ExclamationMarkIcon;
