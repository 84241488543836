import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';

import { PrimaryButton } from '~app/ui-kit/PrimaryButton';
import { usePlatform } from '~components/Provider/Platform';
import { RELOAD, SOMETHING_WENT_WRONG } from '~lib/localizator/src/dictionaries';
import HotKey from '~typings/HotKey';
import ErrorIcon from '~ui/ErrorText/icon';

import * as styles from './ErrorMessage.module.css';

export const ErrorMessage = injectIntl(({ intl }) => {
  const { keyCodes } = usePlatform();

  const reloadApp = () => {
    window.location.replace('/');
  };

  useEffect(() => {
    const handler = (ev: KeyboardEvent) => {
      const keyCode = ev.keyCode;
      if (keyCode === keyCodes[HotKey.KEY_ENTER]) {
        reloadApp();
      }
    };
    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [keyCodes]);

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.content }>
        <ErrorIcon className={ styles.icon } />
        <div className={ styles.text }>{intl.formatMessage({ id: SOMETHING_WENT_WRONG })}</div>
        <div onClick={ reloadApp }>
          <PrimaryButton
            isFocused
            content={ intl.formatMessage({ id: RELOAD }) }
          />
        </div>
      </div>
    </div>
  );
});
