import cn from 'classnames';
import React, { useMemo } from 'react';

import { DATA_ATTR_IDX, DataIdxAttrType } from '~newapp/utils/globals/DataAttributeIdx';

import * as styles from './Frame_channels_cards_16_9.module.css';

export type Props = {
  isFocused: boolean;
  className?: string;
  url?: string;
  dataIdx?: DataIdxAttrType;
};

const Frame_channels_cards_16_9$ = (props: Props): JSX.Element => {
  const { isFocused, className, url } = props;

  const dataAttribute = useMemo(() => {
    if (typeof props.dataIdx !== 'number') return;
    return {
      [DATA_ATTR_IDX]: props.dataIdx,
    };
  }, [props.dataIdx]);

  return (
    <div
      className={ cn(className, styles.channelCard) }
      { ...dataAttribute }
    >
      <div
        className={ cn(styles.channelCardInner, {
          [styles.channelCardInnerFocused]: isFocused,
        }) }
        style={ {
          backgroundImage: `url(${url})`,
        } }
      />
    </div>
  );
};

export const Frame_channels_cards_16_9 = React.memo(Frame_channels_cards_16_9$);
