// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cardRelative--B6saF {\n  position: relative;\n}\n\n.cardAbsolute--RDCx1 {\n  position: absolute;\n}\n\n.card--wXawC {\n  width: 17.1875vw;\n  height: 9.765625vw;\n  -webkit-border-radius: 0.46875vw;\n          border-radius: 0.46875vw;\n}\n\n.focusedCard--ECu4w {\n  border: 0.15625vw solid rgba(255, 255, 255, 1);\n}\n\n.centerIcon--AD8UQ {\n  width: 100%;\n  height: 100%;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n          align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/newapp/blocks/playerPopcorn/Player/controls/Popup/ChannelFlipper/ChannelBlock/Card/Card.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gCAAwB;UAAxB,wBAAwB;AAC1B;;AAEA;EACE,8CAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oBAAa;EAAb,qBAAa;EAAb,iBAAa;EAAb,aAAa;EACb,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;EACvB,yBAAmB;EAAnB,2BAAmB;KAAnB,sBAAmB;UAAnB,mBAAmB;AACrB","sourcesContent":[".cardRelative {\n  position: relative;\n}\n\n.cardAbsolute {\n  position: absolute;\n}\n\n.card {\n  width: 17.1875vw;\n  height: 9.765625vw;\n  border-radius: 0.46875vw;\n}\n\n.focusedCard {\n  border: 0.15625vw solid $gw_11;\n}\n\n.centerIcon {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export var cardRelative = "cardRelative--B6saF";
export var cardAbsolute = "cardAbsolute--RDCx1";
export var card = "card--wXawC";
export var focusedCard = "focusedCard--ECu4w";
export var centerIcon = "centerIcon--AD8UQ";
export default ___CSS_LOADER_EXPORT___;
