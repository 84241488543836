import focuser from '@focuser';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';

import { useMenu } from '~components/Provider/Menu';
import { SidebarWithTabs } from '~components/SidebarWithTabs';
import { useDispatchLaunchDone } from '~hooks/useDispatchLaunchDone';
import { useMenuOpener } from '~hooks/useMenuOpener';
import { useOldNavigationBlocker } from '~hooks/useOldNavigationBlocker';
import { SpinnerGlobal } from '~newapp/globalBlocks/spinner';

import * as styles from './AccountScreen.module.css';
import { AccountTabSwitcher } from './components/AccountTabSwitcher';
import { AccountTopLine } from './components/AccountTopLine';
import { AccountTabToPathMap } from './constants';
import {
  useAccountNavigation,
  useActiveTabId,
  useAvailableTabs,
} from './hooks';
import { AccountTabId } from './types';

type Props = Readonly<{
  intl: InjectedIntl;
}>;

const AccountScreen: React.FC<Props> = (props) => {
  const history = useHistory();
  const menu = useMenu();
  const isNavigationAvailable = !menu.isMenuOpened;
  useDispatchLaunchDone();
  useOldNavigationBlocker(isNavigationAvailable);

  const { openMenu } = useMenuOpener();

  const {availableTabs, isTabsLoading} = useAvailableTabs({
    intl: props.intl,
  });

  const activeTabId = useActiveTabId(availableTabs);

  const handleTabChange = React.useCallback((id: AccountTabId) => {
    history.push(AccountTabToPathMap[id]);
  }, []);


  const { focusOn, setFocusOnSidebar, setFocusOnTabs } = useAccountNavigation({
    activeTabId,
    isTabsLoading,
  });


  if(isTabsLoading){
    return <SpinnerGlobal />
  }

  if (!activeTabId) {
    const nextPath = availableTabs[0]?.id ? AccountTabToPathMap[availableTabs[0].id] : '/';
    return <Redirect to={ nextPath } />;
  }

  return (
    <focuser.FocusableBlock
      isFocused={ isNavigationAvailable }
      isForceFocusEnabledInBranch={ isNavigationAvailable }
      className={ styles.container }
      onKeyLeft={ setFocusOnSidebar }
      onKeyRight={ setFocusOnTabs }
      onKeyUp={ openMenu }
      onKeyReturn={ openMenu }
      noNeedForceFocus
    >
      <AccountTopLine className={ styles.topLine } />
      <div className={ styles.sidebarAndTabWrapper }>
        <focuser.FocusableBlock
          isFocused={ focusOn === 'sidebar' }
          onForceFocus={ setFocusOnSidebar }
          isPointerRightAvailable
          className={ styles.sidebar }
        >
          <SidebarWithTabs
            tabs={ availableTabs }
            selectedTabId={ activeTabId }
            onTabSelect={ handleTabChange }
          />
        </focuser.FocusableBlock>
        <focuser.FocusableBlock
          isFocused={ focusOn === 'tabs' }
          onForceFocus={ setFocusOnTabs }
          isPointerLeftAvailable
        >
          <AccountTabSwitcher activeTabId={ activeTabId } />
        </focuser.FocusableBlock>
      </div>
      {/* <SideBarLayout
          menuItems={menuItems}
          menuFocusedIndex={menuFocusedIndex}
          navigationItemsContent={navigationItemsContent}
        >
          {({
            isFocusOnSideBar,
            focusedLineIndex = 0,
            focusedItemIndex = 0,
            handleKeyNavigate,
          }) => {
            const isFocusedOnContent = !isFocusOnSideBar && !isPopupOpened;

            return (
              <div className={css.authWrapperVoka}>
                <Switch>
                  <Route path={`${path}/${AccountTabId.Payments}`}>
                    <PaymentsPage
                      isFocused={isFocusedOnContent}
                      focusedLineIndex={focusedLineIndex}
                      focusedItemIndex={focusedItemIndex}
                      onNavigationItemsReady={handleNavigationItemsReady}
                    />
                  </Route>
                  <Route path={`${path}/${AccountTabId.Security}`}>
                    <SafetyPage
                      isFocused={isFocusedOnContent}
                      focusedLineIndex={focusedLineIndex}
                      focusedItemIndex={focusedItemIndex}
                      onNavigationItemsReady={handleNavigationItemsReady}
                    />
                  </Route>
                  <Route path={`${path}/${AccountTabId.Cards}`}>
                    <CardsPage
                      isFocused={isFocusedOnContent}
                      focusedLineIndex={focusedLineIndex}
                      focusedItemIndex={focusedItemIndex}
                      onNavigationItemsReady={handleNavigationItemsReady}
                    />
                  </Route>
                  <Route path={`${path}/${AccountTabId.Promocode}`}>
                    <PromoCodePage
                      isFocused={isFocusedOnContent}
                      focusedLineIndex={focusedLineIndex}
                      focusedItemIndex={focusedItemIndex}
                      onNavigationItemsReady={handleNavigationItemsReady}
                    />
                  </Route>
                  <Route path={`${path}/${AccountTabId.Stats}`}>
                    <UserStats onNavigationItemsReady={handleNavigationItemsReady} />
                  </Route>
                </Switch>
              </div>
            );
          }}
        </SideBarLayout> */}
    </focuser.FocusableBlock>
  );
};

export const AccountScreenMemoized = React.memo(injectIntl(AccountScreen));
