/* eslint-disable max-len */
import { ac_00, gw_00, gw_02, gw_11 } from 'customization-project-name/colors';
import * as React from 'react';


type Props = {
  width?: string
  height?: string
  isFocused: boolean
}

const IconChipsChannels = ({ width = '6.111111111111111vh', height = '6.111111111111111vh', isFocused }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={ isFocused ? ac_00 : gw_02 } />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M33.2001 16.8586V25.4699C33.2001 27.0102 32.4744 28.3579 31.399 29.0755V17.2524C31.399 15.7122 30.1399 14.452 28.6011 14.452H13.1432C13.7902 13.3668 14.8831 12.6667 16.1159 12.6667H29.5541C31.5563 12.6667 33.2001 14.5395 33.2001 16.8586ZM29.4405 29.2855V18.4513C29.4405 17.3224 28.5137 16.3948 27.3858 16.3948H12.8547C11.7268 16.3948 10.8 17.3224 10.8 18.4513V29.2768C10.8 30.4057 11.7268 31.3334 12.8547 31.3334H27.4033C28.5312 31.3334 29.458 30.4057 29.458 29.2768H29.4492L29.4405 29.2855Z"
            fill={ isFocused ? gw_00 : gw_11 } />
    </svg>
  </div>
);

export { IconChipsChannels }
