import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Y_FIELD_NAME } from '~hooks/useSpatialNavigation';
import * as URLSearchManager from '~lib/URLQueryStringManager';


type Props = {
  link?: string;
  index?: number;
  isFocusOnSideBar?: boolean;
  immediate?: boolean;
};

const useHandleItemClick = () => {
  const history = useHistory();

  const handleClick = useCallback(({
    link,
    index = 0,
    isFocusOnSideBar = false,
    immediate = false,
  }: Props = {}) => {
    history.replace(`${link || history.location.pathname}?isFocusOnSideBar=${isFocusOnSideBar}`);

    if (immediate) {
      URLSearchManager.set({ [Y_FIELD_NAME]: index }, history);
    } else {
      setTimeout(() => {
        URLSearchManager.set({ [Y_FIELD_NAME]: index }, history);
      });
    }
  }, [history.location.pathname]);

  return [handleClick] as const;
};


export default useHandleItemClick;
