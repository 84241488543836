import Focuser from '@focuser';
import * as cn from 'classnames';
import * as moment from 'moment';
import * as React from 'react';

import { Typography } from '~app/ui-kit/Typography';
import { Separator } from '~newapp/ui-kit/Tables_and_blocks/Separator';
import Payment from '~typings/Payment';

import * as styles from './styles.module.css';


export type Props = {
  payment: Payment;
  isFocused: boolean;
  index: number;
  setFocusedPurchase: React.Dispatch<React.SetStateAction<number>>;
};

export const PaymentItem: React.FC<Props> = React.memo(({
  payment,
  isFocused,
  index,
  setFocusedPurchase
}) => {
  return (
    <Focuser.FocusableBlock
      isFocused={ isFocused }
      className={ cn(styles.item, {
        [styles.isFocused]: isFocused,
      }) }
      onForceFocus={ () => setFocusedPurchase(index) }
      emitForceFocusOnHover
    >
      <div className={ styles.contentWrap }>
        <Typography variant='subtitle1_medium' className={ cn(styles.time, styles.text) }>
          { moment(payment.updated_at).format('DD.MM.YYYY') }
        </Typography>

        <Typography variant='subtitle1_medium' className={ cn(styles.content, styles.text) }>
          { payment.description }
        </Typography>

        <Typography variant='subtitle1_medium' className={ cn(styles.price, styles.text) }>
          { payment.amount.formatted }
        </Typography>
      </div>
      <Separator/>
      </Focuser.FocusableBlock>
  )
});

PaymentItem.displayName = 'PaymentItem';
