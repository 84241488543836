import { find, findIndex, intersection } from 'lodash';
import { Player, polyfill } from 'shaka-player/dist/shaka-player.compiled';

import bringingAudioTrackNameToStandard from '~lib/bringingAudioTrackNameToStandard';
import { Events } from '~lib/player/common';
import getSecontsFromPercents from "~lib/player/getSecontsFromPercents";
import sortVideoTracks from '~lib/player/sortVideoTracks';
import AudioTrack from '~typings/AudioTrack';
import PlayerType from '~typings/PlayerType';
import VideoTrack from '~typings/VideoTrack';

import { createHTMLVideoElement, prepareTimeShiftUrl, resetHTMLVideoElement } from '../common';
import { IPlayer, PlayerEventSubscribe, PlayerLoadParameters, Props } from '../typings';
import getDRM from './drm';
import onError from './error';


polyfill.installAll();


const shakaPlayer = ({ videoContainer, event }: Props): IPlayer => {
  let shakaPlayer: Player | null = null;
  let selectedVideoTrack: any | null = null;
  let videoElement: HTMLVideoElement | null = null;
  let decodedFramesDIFF: number;
  let playTimeDIFF: number;
  let playTime: number;
  let decodedFrames: number;
  let fps: number;
  let tick: number = 0;


  const handleBindEvents = () => {
    // if (videoElement) {
    //   try {
    //     videoElement.addEventListener('loadedmetadata', () => {
    //       const activeAudioTrack = find(getTracks(), { active: true });
    //       const tracks = getTracks()
    //         .filter(({ language }) => (language === activeAudioTrack.language));
    //       const middleQualityTrackIndex = Math.ceil((tracks.length - 1) / 2);
    //
    //       shakaPlayer.configure({ abr: { enabled: false } });
    //       shakaPlayer.selectVariantTrack(tracks[middleQualityTrackIndex], false);
    //       shakaPlayer.configure({ abr: { enabled: true } });
    //     });
    //   } catch (ignore) {
    //     // ignore
    //   }
    // }

    if(!shakaPlayer){
      return;
    }

    shakaPlayer.addEventListener('adaptation', () => {
      //   console.log(shakaPlayer.getStats());
      event.emit(Events.TRACK_CHANGED);
    });
    shakaPlayer.addEventListener('buffering', ({ buffering }: any) => {
      event.emit(Events.BUFFERING, buffering);
    });
    shakaPlayer.addEventListener('error', () => {
      event.emit(Events.PLAYER_ERROR);
    });
    shakaPlayer.addEventListener('expirationupdated', ({ type }) => {
      event.emit(Events.EVENT, { type });
    });
    shakaPlayer.addEventListener('drmsessionupdate', ({ type }) => {
      event.emit(Events.EVENT, { type });
    });
    shakaPlayer.addEventListener('loading', ({ type }) => {
      event.emit(Events.EVENT, { type });
    });
    shakaPlayer.addEventListener('manifestparsed', ({ type }) => {
      event.emit(Events.EVENT, { type });
    });
    shakaPlayer.addEventListener('onstatechange', ({ type }) => {
      event.emit(Events.EVENT, { type });
    });
    shakaPlayer.addEventListener('onstateidle', ({ type, state }: any) => {
      event.emit(Events.EVENT, { type, state });
    });
    shakaPlayer.addEventListener('streaming', ({ type }) => {
      event.emit(Events.EVENT, { type });
    });
    shakaPlayer.addEventListener('textchanged', ({ type }) => {
      event.emit(Events.EVENT, { type });
    });
    shakaPlayer.addEventListener('trackschanged', ({ type }) => {
      event.emit(Events.EVENT, { type });
    });
    shakaPlayer.addEventListener('unloading', ({ type }) => {
      event.emit(Events.EVENT, { type });
    });
  };

  const dispose = () => {
  };
  const getCurrentTime = (): number => videoElement?.currentTime || 0;
  const getDuration = (): number => videoElement?.duration || 0;

  async function reset() {
    if (shakaPlayer) {
      await (shakaPlayer as Player).destroy();
      shakaPlayer = null;
    }

    resetHTMLVideoElement(videoContainer, videoElement);
    videoElement = null;
  }

  async function load(parameters: PlayerLoadParameters) {
    await reset();

    const playerUrl = prepareTimeShiftUrl(parameters.stream.url, parameters.timeShiftParams);

    videoElement = createHTMLVideoElement(event);
    videoContainer?.appendChild(videoElement);

    shakaPlayer = new Player(videoElement);
    shakaPlayer.configure({
      preferredAudioLanguage: parameters.language,
      drm: getDRM(parameters.stream.drm, parameters.stream.player),
      abr: {
        enabled: true,
      },
    });
    //subscribeOnEvents(shakaPlayer, event);

    try {
      await shakaPlayer.load(playerUrl);
      if (parameters.percentsWatched) {
        seekTo(getSecontsFromPercents(parameters.percentsWatched, getDuration()));
      }

      handleBindEvents();
    } catch (error) {
      /* eslint-disable no-console */
      console.error('Shaka player load error');
      console.error(error);
      /* eslint-enable no-console */
      onError(event, error);
    }
  }

  const mute = () => {
  };
  const on: PlayerEventSubscribe = (target, listener) => event.on(target, listener);

  const removeListener: PlayerEventSubscribe = (target, listener) => {
    event.removeListener(target, listener);
  };

  const play = (): void => {
    videoElement?.play();
  };

  const pause = () => {
    videoElement?.pause();
  };

  const seekTo = (timePosition: number) => {
    if (videoElement?.currentTime !== undefined) {
      play();
      videoElement.currentTime = timePosition;
    }
  };

  const stop = () => {
    reset();
  };

  const isAutoQualityEnabled = () => {
    try {
      return shakaPlayer!.getConfiguration().abr.enabled;
    } catch (ignore) {
      // ignore
    }

    return true;
  };
  const setAutoQuality = (enabled) => {
    try {
      shakaPlayer!.configure({ abr: { enabled } });

      if (enabled) {
        selectedVideoTrack = null;
      }

      event.emit(Events.TRACK_CHANGED);
    } catch (ignore) {
      // ignore
    }
  };

  const changeAudioTrack = (track: AudioTrack) => {
    try {
      if (track?.data?.language) {
        shakaPlayer!.selectAudioLanguage(track.data.language, track.data.role);
      }
      const tracks = getVideoTracks();
      const videoTrack = (
        selectedVideoTrack !== null &&
        tracks.find(({ data: { width = 0 } }) => (selectedVideoTrack.width === width))
      );

      if (videoTrack) {
        changeVideoTrack(videoTrack);
      }
      else {
        setAutoQuality(true);
      }

      event.emit(Events.TRACK_CHANGED);
    } catch (ignore) {
      // ignore
    }
  };
  const changeVideoTrack = (track: VideoTrack) => {
    try {
      setAutoQuality(false);
      shakaPlayer!.selectVariantTrack(track.data, true);
      selectedVideoTrack = track.data;

      event.emit(Events.TRACK_CHANGED);
    } catch (ignore) {
      // ignore
    }
  };
  const getTracks = () => shakaPlayer!.getVariantTracks();
  const getAudioTracks = () => {
    const audioTracks: AudioTrack[] = [];

    try {
      const allTracks = getTracks();
      const activeTrack = find(allTracks, { active: true });
      const audioTracksWithRoles = shakaPlayer!.getAudioLanguagesAndRoles() || [];

      audioTracksWithRoles.forEach((track) => {
        let name = bringingAudioTrackNameToStandard(track.language);

        const isCorrectRole = ((activeTrack!.audioRoles || []).length !== 0) ?
          activeTrack!.audioRoles!.includes(track.role)
          :
          true;

        audioTracks.push({
          name,
          index: findIndex(
            audioTracksWithRoles,
            { language: track.language, role: track.role },
          ),
          data: track,
          isSelected: (activeTrack!.language === track.language && isCorrectRole),
        });
      });
    } catch (ignore) {
      // ignore
    }

    return audioTracks;
  };
  const getVideoTracks = () => {
    try {
      const allTracks = getTracks();
      const videoTracks: VideoTrack[] = [];
      const activeTrack = find(allTracks, { active: true });
      if (selectedVideoTrack === null && !isAutoQualityEnabled()) {
        selectedVideoTrack = activeTrack;
      }
      const tracks = allTracks
        .filter(({ language }) => (language === activeTrack!.language));

      for (let i = 0; i < tracks.length; i += 1) {
        const track = tracks[i];
        const isCorrectRole = ((activeTrack!.audioRoles || []).length !== 0) ?
          intersection(activeTrack!.audioRoles!, track.audioRoles!).length > 0
          :
          true;

        if (
          isCorrectRole &&
          videoTracks.findIndex(({ data: { width = 0 } }) => (width === track.width)) === -1
        ) {
          videoTracks.push({
            index: track.videoId!,
            name: track.width!.toString(),
            data: track,
            isSelected: track.active,
          });
        }
      }

      return sortVideoTracks(videoTracks);
    } catch (ignore) {
      return [];
    }
  };
  const getSelectedAudioTrack = () => {
    const activeAudioTrack = find(getTracks(), { active: true });

    return find(getAudioTracks(), { name: activeAudioTrack!.language });
  };
  const getSelectedVideoTrack = () => {
    // тянем битрейт из плеера(в байтах)
    // плеер не отдает фпс, но у него есть хартбит каждые 10 секунд и он отдает время воспроизведения (playTime) и
    // количество отрендеренных кадров (decodedFrames). из этих данных можем посчитать средний фпс за последние
    // 10 секунд. хартбит неточный и различается до 0.2 в плюс или минус т.е. может быть 10.2 сек или 9.8 сек.
    // за это время набегают лишние кадры или теряются и невозможно точно посчитать ФПС, поэтому просто округляем.
    // так же пишем тик, потому что первый стабильный фпс начинается с 3 тика

    const stats = shakaPlayer!.getStats()
    decodedFramesDIFF = stats.decodedFrames - decodedFrames
    playTimeDIFF = stats.playTime - playTime

    fps = Math.round((decodedFramesDIFF / playTimeDIFF))

    decodedFrames = stats.decodedFrames
    playTime = stats.playTime
    tick += 1

    const data = {
      bitrate: stats.streamBandwidth,
      fps,
      tick
    }
    return data
  };
  const getProperties = () => {
    return {
      stats: shakaPlayer?.getStats(),
    };
  };

  return {
    type: PlayerType.Shaka,

    dispose,
    getCurrentTime,
    getDuration,
    load,
    mute,
    on,
    removeListener,
    pause,
    play,
    reset,
    seekTo,
    stop,

    isAutoQualityEnabled,
    setAutoQuality,

    changeAudioTrack,
    changeVideoTrack,
    getAudioTracks,
    getVideoTracks,
    getSelectedAudioTrack,
    getSelectedVideoTrack,

    getProperties,
  };
};


export default shakaPlayer;