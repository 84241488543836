import * as React from 'react';

import useDelayedChange from '~hooks/useDelayedChange';
import uuid from '~lib/uuid';
import Fade from '~ui/Effects/Fade';

import * as styles from './styles.module.css';


type Props = Readonly<{
  isVisible: boolean;
}>;


const EventsPlaceholders: React.FC<Props> = (props: Props) => {
  const UUID = React.useRef(
    (new Array(13))
      .fill(0)
      .map((item, index) => uuid(index))
  ).current;
  const isVisibleDelay = useDelayedChange(props.isVisible, 500);

  if (!isVisibleDelay && !props.isVisible) { return null; }

  return (
    <Fade
      className={ styles.placeholdersList }
      isVisible={ props.isVisible }
      duration={ 500 }
      delay={ (props.isVisible) ? 0 : 300 }
    >
      {
        UUID.map(id => (
          <div
            key={ id }
            className={ styles.placeholder }
          />
        ))
      }
    </Fade>
  );
};


export default EventsPlaceholders;
