import * as cn from 'classnames';
import * as React from 'react';

import { NavigationHelper } from '~ui/NavigationHelper';

import InputIcon from './InputIcon';
import * as styles from './styles.module.css';


export enum ShowCursor {
  Never,
  OnlyWhenInFocus,
  Always
}

type Props = Readonly<{
  value?: string | null;
  placeholder?: string;
  placeholderAsLabel?: boolean;
  isManualNavigation?: boolean;
  isFocused?: boolean;
  showCursor?: ShowCursor;
  Icon?: React.FC<{ className?: string }>;
  hideUnfocudedIcon?: boolean;
  className?: string;
  align?: 'left' | 'center';
  sSize?: boolean;
  isLongField?: boolean;
  onClick?: () => void;
  onLeave?: (NavigationDirection) => void;
}>;


const Input: React.FC<Props> = ({
  value,
  placeholder,
  placeholderAsLabel,
  isFocused,
  isManualNavigation,
  showCursor = ShowCursor.OnlyWhenInFocus,
  className,
  align = 'center',
  Icon,
  hideUnfocudedIcon,
  onClick,
  onLeave,
  sSize,
  isLongField,
}: Props) => {
  const isValueEmpty = !(value && value.length);
  const isShowIcon = (!!Icon && (isFocused || !hideUnfocudedIcon) && !isValueEmpty);

  const textFieldValue = isValueEmpty ? placeholder : value;
  const cursorElement = React.useMemo(() => {
    if (showCursor === ShowCursor.Never) { return null; }

    return (
      <div
        className={ cn(
          styles.cursor,
          {[ styles.hideCursor ]: showCursor === ShowCursor.OnlyWhenInFocus && !isFocused }
        ) }
      />
    );
  }, [showCursor, isFocused]);
  const inputArea = 
    (
      <div className={ cn(styles.valueWrapper, {
        [styles.alignLeft]: (align === 'left'),
        [styles.isShowIcon]: isShowIcon,
      }) }>
        <div className={ styles.value }>
          { textFieldValue }
          { cursorElement }
        </div>
      </div>
    ) 
  const iconElement = React.useMemo(() => {
    if (!isShowIcon) { return null; }

    return (
      <InputIcon Icon={ Icon } isFocused={ !!isFocused } />
    );
  }, [isShowIcon, isFocused]);

  const navHelperElement = React.useMemo(() => {
    if (!isManualNavigation && isFocused) {
      return (
        <NavigationHelper
          isFocused={ isFocused }
          onLeave={ onLeave }
        />
      );
    }

    return null;
  }, [isManualNavigation, isFocused, onLeave]);

  return (
    <div
      onClick={ onClick }
      className={ cn(
        styles.input, className, {
        [styles.sSize]: sSize,
        [styles.empty]: !value,
        [styles.placeholderAsLabel]: placeholderAsLabel,
        [styles.isLongField]: isLongField,
        [styles.focused]: isFocused,
        [styles.placeholderInValue]: (isValueEmpty && (placeholder && placeholder.length)),
        'focusedNavigationNode': isFocused,
      })
      }
    >
      { navHelperElement }
      { inputArea }
      { iconElement }
    </div>
  );
};


export default React.memo(Input);
