import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';

import useNavigationByKeys from '~hooks/useNavigation';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import getScreenHeight from '~lib/screen/height';
import usePointer from '~stores/Pointer';
import NavigationDirection from '~typings/NavigationDirection';

import useWeel from './useWeel';

type Props = Readonly<{
  contentBlockRef: RefObject<HTMLDivElement>;
  screenHeight?: number;
  scrollStep?: number;
  onNavigated?: (direction: NavigationDirection) => void;
  heightUpdate?: any[];
}>;

const SCREEN_HEIGHT = getScreenHeight();
const DEFAULT_SCROLL_STEP = SCREEN_HEIGHT / 7;

/**
 * @deprecated
 * используй useScrollFocuser
 */
const useScroll = (props: Props) => {
  const {
    contentBlockRef,
    screenHeight = SCREEN_HEIGHT,
    scrollStep = DEFAULT_SCROLL_STEP,
    onNavigated,
    heightUpdate = EMPTY_ARRAY,
  } = props;
  const [offset, setOffset] = useState<number>(0);

  const maxOffset = useMemo(() => {
    return (contentBlockRef.current?.getBoundingClientRect().height ?? screenHeight) - screenHeight;
  }, [contentBlockRef.current?.getBoundingClientRect().height, screenHeight, ...heightUpdate]);

  const scrollStyle = useMemo(() => {
    return {
      transform: `translate3d(0, -${offset}px, 0)`,
      WebkitTransform: `translate3d(0, -${offset}px, 0)`,
    };
  }, [offset]);


  const scrollPos = useMemo(() => {
    if (!maxOffset) return 0;
    return offset / maxOffset;
  }, [offset, maxOffset]);

  const handleKeyNavigate = useCallback((dir: NavigationDirection) => {
    const delta =
      dir === NavigationDirection.Down
        ? +1
        : dir === NavigationDirection.Up
          ? -1
          : 0;

    setOffset(oldOffset => {
      const newOffset = oldOffset + delta * scrollStep;

      return Math.min(Math.max(0, newOffset), maxOffset);
    });

    if (onNavigated) {
      onNavigated(dir);
    }
  }, [maxOffset, onNavigated, scrollStep]);

  useNavigationByKeys({
    isMounted: true,
    onNavigation: handleKeyNavigate,
  }, [maxOffset > 0, handleKeyNavigate, onNavigated]);

  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const updateDirections = usePointer(state => state.updateDirections);
  const flush = usePointer(state => state.flush);

  useEffect(() => {
    if (pointerEnabled) {
      updateDirections({
        [NavigationDirection.Up]: scrollPos > 0,
        [NavigationDirection.Down]: 0 < maxOffset && maxOffset > offset,
      });

      return () => {
        flush([
          NavigationDirection.Up,
          NavigationDirection.Down,
        ]);
      }
    }
  }, [
    scrollPos,
    offset,
    maxOffset,
    pointerEnabled,
    updateDirections,
  ]);

  useEffect(() => () => {
    flush([
      NavigationDirection.Up,
      NavigationDirection.Down,
    ]);
  }, []);

  useWeel((ev) => {
    handleKeyNavigate(ev.deltaY > 0 ? NavigationDirection.Down : NavigationDirection.Up);
  }, [maxOffset > 0, handleKeyNavigate]);

  return {
    isScrollAvailable: maxOffset > 0,
    scrollStyle,
    scrollPos,
    handleKeyNavigate,
  } as const;
};

export default useScroll;
