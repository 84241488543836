import * as cn from 'classnames';
import * as React from 'react';

import Stream from '~typings/Stream';

import ChapterPopup from './ChapterPopup';
import * as styles from './styles.module.css';
import { formatTimeLeft, getPercents } from './utils';


type Props = Readonly<{
  rewindTime: number;
  isLoading: boolean;
  isFocused: boolean;
  durationInSec: number;
  currentTimeInSec: number;
  percentsWatched: number;
  stream?: Stream;
  audioshowObject: boolean;
}>;


const RewindPointerRender: React.FC<Props> = (props: Props) => {
  const rewindPointerTextRef = React.useRef<HTMLDivElement | null>(null);

  ///// логика смещеная поп-апа
  ///// и функция преобразующая прогресс просмотра в секундах в проценты
  const rewindInPercents = React.useMemo(() => (
    (props.rewindTime !== null) ?
      getPercents(props.durationInSec, props.rewindTime)
      :
      props.percentsWatched
  ), [props.durationInSec, props.percentsWatched, props.rewindTime]);
  const movingPopup = `translate(${rewindInPercents / 0.38}%)`;
  /////

  const seekToTimeText = formatTimeLeft(props.durationInSec, props.rewindTime);
  const rewindPointerWrapperTransform = `translate3d(${rewindInPercents}%, 0, 0)`;
  const stylePointerTransform = `translate3d(-${rewindInPercents}%, 0, 0)`;

  const chapters = props.stream?.chapters.filter((chapter) => chapter.kind === 'chapter');

  const [currentChapter, setCurrentChapter] = React.useState(null) as any;

  React.useEffect(() => {
    if(chapters) {
      const updatedChapter = chapters.find((chapter) => chapter.begin_timestamp <= props.rewindTime
      && chapter.end_timestamp >= props.rewindTime);

      setCurrentChapter(updatedChapter);
    }
  }, [props.rewindTime])

  React.useEffect(() => {
    if (rewindPointerTextRef.current) {
      rewindPointerTextRef.current.innerText = seekToTimeText;
    }
  }, [seekToTimeText, rewindPointerTextRef.current]);


  return (
    <>
      <div
        className={ styles.rewindPointerWrapper }
        style={ {
          transform: rewindPointerWrapperTransform,
          WebkitTransform: rewindPointerWrapperTransform,
        } }
      >
        <div className={ cn(styles.rewindPointer,
          {[styles.rewindPointerHidden]: (props.rewindTime === null)}
        ) }
          style={ {
            transform: stylePointerTransform,
            WebkitTransform: stylePointerTransform,
          } }
        >
          {!currentChapter &&
            <div ref={ rewindPointerTextRef } className={ styles.rewindPointerText }/>
          }
        </div>
      </div>

      {currentChapter &&
        <ChapterPopup
          currentChapter={ currentChapter }
          movingPopupStyle={ movingPopup }
          currentTimeInSec={ props.currentTimeInSec }
          durationInSec={ props.durationInSec }
          rewindTime={ props.rewindTime }
          audioshowObject={ props.audioshowObject }
        />
      }
    </>
  );
};


export default React.memo(RewindPointerRender);
