import { useMutation } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import ApiClient from '~lib/ApiClient';

const fetch = async (
  client: ApiClient,
  accessToken: string | null,
  deviceToken: string | null,
  deviceName: string,
) => {
  const params = {
    name: deviceName,
    access_token: accessToken,
    device_token: deviceToken,
  };
  return await client.post('/v1/user/devices', params);
};

export const useLinkDevice = () => {
  const client = useClient();
  const { accessToken } = getAuthData();

  return useMutation(
    ({ deviceToken, deviceName }: { deviceToken: string | null; deviceName: string }) =>
      fetch(client, accessToken, deviceToken, deviceName),
  );
};
