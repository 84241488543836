// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--HH88b {\r\n  width: 100vw;\r\n  margin-top: 45.83333333333333vh;\r\n}\r\n\r\n.alert--WgCF1 {\r\n  width: 100vw;\r\n}\r\n\r\n.verticalSlider--tL9Bl {\r\n  -webkit-box-align: start;\r\n  -webkit-align-items: flex-start;\r\n     -moz-box-align: start;\r\n          align-items: flex-start;\r\n}\r\n\r\n.mediaItemBar--_1kha {\r\n  margin-left: 6.875vw;\r\n}\r\n\r\n.description--aQoLF {\r\n  margin-left: 5.3125vw;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemPage/AudioShow/AudioShowFrontContent/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,wBAAuB;EAAvB,+BAAuB;KAAvB,qBAAuB;UAAvB,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".container {\r\n  width: 100vw;\r\n  margin-top: 45.83333333333333vh;\r\n}\r\n\r\n.alert {\r\n  width: 100vw;\r\n}\r\n\r\n.verticalSlider {\r\n  align-items: flex-start;\r\n}\r\n\r\n.mediaItemBar {\r\n  margin-left: 6.875vw;\r\n}\r\n\r\n.description {\r\n  margin-left: 5.3125vw;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var container = "container--HH88b";
export var alert = "alert--WgCF1";
export var verticalSlider = "verticalSlider--tL9Bl";
export var mediaItemBar = "mediaItemBar--_1kha";
export var description = "description--aQoLF";
export default ___CSS_LOADER_EXPORT___;
