import focuser from '@focuser';
import * as React from 'react';
import { animated } from 'react-spring';

import ScrollBar from '~components/ScrollBar';
import { useInfinityCastMembers } from '~hooks/fetch/useCastMembers';
import useScroll from '~hooks/useScroll/useScroll';
import { isChannel } from '~lib/objectType';
import preparingText from '~lib/preparingText';
import NavigationDirection from '~typings/NavigationDirection';

import { ActorsBlockFocuser } from './ActorsBlockFocuser';
import DefaultHeader from './DefaultHeader';
import DetailsList from './DetailsList';
import LabeledText from './DetailsList/LabeledText';
import { fields } from './render.helper';
import * as styles from './styles.module.css';
import { CommonDetailItem } from './types';

type Props = Readonly<{
  item: CommonDetailItem;
}>;

type focusPosition = 'before-actors' | 'in-actors' | 'after-actors';

const MediaItemDetailsCommon: React.FC<Props> = (props: Props) => {
  const contentBlockRef = React.useRef<HTMLDivElement>(null);
  const [focusPosition, setFocusPosition] = React.useState<focusPosition>('before-actors');
  const { parsedData: castMembers } = useInfinityCastMembers(
    isChannel(props.item) ? undefined : props.item.id,
  );

  const handleNavigate = React.useCallback((dir: NavigationDirection) => {
    switch (dir) {
      case NavigationDirection.Up: {
        setFocusPosition((oldFocusPosition) => {
          switch (oldFocusPosition) {
            case 'after-actors':
              return 'in-actors';
            case 'in-actors':
              return 'before-actors'
            default:
              return oldFocusPosition;
          }
        });
        break;
      }
      case NavigationDirection.Down: {
        setFocusPosition((oldFocusPosition) => {
          switch (oldFocusPosition) {
            case 'before-actors':
              return 'in-actors';
            case 'in-actors':
              return 'after-actors';
            default:
              return oldFocusPosition;
          }
        });
        break;
      }
    }
  }, []);

  const { isScrollAvailable, scrollStyle, scrollPos } = useScroll({
    contentBlockRef,
    onNavigated: handleNavigate,
  });

  const {
    originalName,
    description,
    membersByRole,
    genres,
    countries,
    productionYear,
    languages,
    studios,
    durationMin,
    externalCatalog,
  } = React.useMemo(() => fields(props.item, castMembers), [props.item, castMembers]);

  const style: React.CSSProperties = {
    backgroundSize: 'contain',
  };

  //здесь была логика бекграунда

  return (
    <div
      className={ styles.pageContainer }
      style={ style }
    >
      <animated.div
        className={ styles.container }
        style={ scrollStyle }
        ref={ contentBlockRef }
      >
        <div style={ { width: '60.9375vw', marginLeft: '6.875vw' } }>
          <DefaultHeader
            item={ props.item }
            originalName={ originalName }
          />
        </div>
        <div style={ { width: '60.9375vw', marginLeft: '6.875000000000001vw' } }>
          <LabeledText label="">{preparingText(description)}</LabeledText>
          <focuser.FocusableBlock
            isFocused={ focusPosition === 'in-actors' }
            isForceFocusEnabledInBranch={ false }
          >
            <ActorsBlockFocuser item={ props.item } />
          </focuser.FocusableBlock>

          <DetailsList
            membersByRole={ membersByRole }
            countries={ countries }
            genres={ genres }
            languages={ languages }
            studios={ studios }
            durationMin={ durationMin }
            productionYear={ productionYear }
            externalCatalog={ externalCatalog }
          />
        </div>
      </animated.div>
      {isScrollAvailable ? (
        <ScrollBar
          position={ scrollPos }
          className={ styles.scrollbar }
          isFocused
        />
      ) : null}
    </div>
  );
};

export default React.memo(
  MediaItemDetailsCommon,
  (p: Props, n: Props) => p.item.id !== n.item.id,
);
