import * as React from 'react';
import { InjectedIntl } from 'react-intl';

import {
  ALREADY_SUBSCRIBED,
  DISABLE,
  ENABLE,
} from '~localization';

interface ButtonSelectPlanProps {
  isProductInSubscriptions?: boolean;
  isCancellable?: boolean;
  intl: InjectedIntl;
}

export const ButtonSelectPlanText = React.memo((props: ButtonSelectPlanProps) => {
  return (
    <>
      {props.isProductInSubscriptions
        ? props.isCancellable
          ? props.intl.formatMessage({ id: DISABLE })
          : props.intl.formatMessage({ id: ALREADY_SUBSCRIBED })
        : props.intl.formatMessage({ id: ENABLE })}
    </>
  );
});

ButtonSelectPlanText.displayName = 'ButtonSelectPlanText';
