import { Moment } from 'moment';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import EPGPageEventDetail from '~components/EPG/EventDetail/component';
import EPGPageEvents from '~components/EPG/Events/EPGPageEvents';
import useDelayedChange from '~hooks/useDelayedChange';
import useMediaItemPageInfo from '~hooks/useMediaItemPage';
import * as URLSearchManager from '~lib/URLQueryStringManager';
import URLVariableState from "~lib/URLVariableState";
import { usePlayerStore } from "~stores/Player";
import ProgramEvent from '~typings/Event';
import ItemObject from '~typings/ItemObject';
import NavigationDirection from '~typings/NavigationDirection';

import { getInitialDateIndex } from './helpers';
import * as styles from './styles.module.css';
import EPGWeekDays from './WeekDays';


enum Focus {
  Dates = 'dates',
  Events = 'events',
  Description = 'description',
}

type Props = Readonly<{
  days: Moment[];
  channelID: string;
  playingEvent?: ProgramEvent;
  onClose: () => void;
}>;
const FOCUSED_LAYER_URL_NAME = 'focusOn';


const PlayerEPGPopup: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [dateIndex, setDateIndex] = React.useState<number>(getInitialDateIndex(props.days, props.playingEvent));
  const focusURL = React.useRef(URLVariableState<Focus | null>(history, 'focusOn', Focus.Events)).current;
  const focusOn = URLSearchManager.getField(history.location.search, FOCUSED_LAYER_URL_NAME) || Focus.Events;
  const { mediaItemPage } = useMediaItemPageInfo();
  const [focusedEvent, setFocusedEvent] = React.useState<ProgramEvent | null>(null);
  const detailedInfoEvent = useDelayedChange<ProgramEvent | null>(focusedEvent, 400);
  const setSecondsMissing = usePlayerStore(state => state.setMissingSeconds)

  const selectedDate = React.useMemo(() => (
    props.days[dateIndex].toDate()
  ), [dateIndex]);

  const handleDayChanged = (index: number) => {
    setDateIndex(index);
    focusURL.set(Focus.Dates);
  };
  const handleProgramEventClicked = React.useCallback((programEvent: ProgramEvent, isCatchup, isCurrentEvent) => {
    setSecondsMissing(0)
    if (isCatchup) {
      history.replace(`/media-item/${programEvent.object}/${programEvent.id}/player?player=true`);

      props.onClose();
    } else if (isCurrentEvent && mediaItemPage.object !== ItemObject.Channel) {
      history.replace(`/media-item/${ItemObject.Channel}/${programEvent.channel_id}?player=true`);

      props.onClose();
    }
  }, [props.onClose]);

  const handleEventsLeave = React.useCallback((direction) => {
    if (direction === NavigationDirection.Left) {
      URLSearchManager.set({ [FOCUSED_LAYER_URL_NAME]: Focus.Dates }, history);
    } else if (direction === NavigationDirection.Right) {
      URLSearchManager.set({ [FOCUSED_LAYER_URL_NAME]: Focus.Description }, history);
    }
  }, []);
  const handleDaysLeave = React.useCallback((direction) => {
    if (direction === NavigationDirection.Right && focusedEvent) {
      URLSearchManager.set({ [FOCUSED_LAYER_URL_NAME]: Focus.Events }, history);
    }
  }, [!!focusedEvent]);
  const handleEPGPageEventDetailLeave = React.useCallback((direction) => {
    if (direction === NavigationDirection.Left) {
      URLSearchManager.set({ [FOCUSED_LAYER_URL_NAME]: Focus.Events }, history);
    }
  }, []);

  React.useEffect(() => () => {
    URLSearchManager.set({ [FOCUSED_LAYER_URL_NAME]: null }, history);
  }, []);

  const renderEventDetail = React.useMemo(() => (
    <EPGPageEventDetail
      isFocused={ (focusOn === Focus.Description) }
      className={ styles.EPGEventDetail }
      event={ detailedInfoEvent }
      onLeave={ handleEPGPageEventDetailLeave }
    />
  ), [detailedInfoEvent?.id, focusOn]);

  return (
    <>
      <EPGWeekDays
        isFocused={ (focusOn === Focus.Dates) }
        activeIndex={ dateIndex }
        items={ props.days }
        onSelect={ handleDayChanged }
        onLeave={ handleDaysLeave }
      />
      <EPGPageEvents
        isPlayerVersion
        isFocused={ (focusOn === Focus.Events) }
        date={ selectedDate }
        channelID={ props.channelID }
        initialFocusedProgramEventID={ props.playingEvent?.id }
        onLeave={ handleEventsLeave }
        onFocusedEventChange={ setFocusedEvent }
        onClick={ handleProgramEventClicked }
      />
      { renderEventDetail }
    </>
  );
};


export default React.memo(PlayerEPGPopup);
