/* eslint-disable max-len */
import { gw_07 } from 'customization-project-name/colors'
import * as React from 'react';

type Props = {
  side?: number; //in vh
}

export const Ok = ({ side }: Props) => {
  const width = side ? `${side}vh` : '4.166666666666666vh'
  const height = side ? `${side}vh`: '4.166666666666666vh'
  //нам нужно сохранить пропорции поэтому нет смысла принимать два размера
  //vh потому что у нас вся навигация и дизайн пляшет от высоты
  //если ставить в vw и изменится ширина то высота иконки тоже изменится при неизменной высоте экрана
  //и тогда сломается остальной дизайн и навигация
  //дубль размера чтоб не запутываться

  return (
    <div style={ { width, height } }>
      <svg viewBox="0 0 30 30" fill={ gw_07 } xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15 27C21.6274 27 27 21.6274 27 15C27 8.37258 21.6274 3 15 3C8.37258 3 3 8.37258 3 15C3 21.6274 8.37258 27 15 27ZM20.9469 10.6278C20.3971 10.0942 19.4848 10.1032 18.947 10.6476L14.1621 16.8088L11.0351 13.7765C10.492 13.2563 9.60071 13.259 9.06127 13.7825L8.98966 13.852C8.46851 14.3578 8.47064 15.1626 8.99448 15.666L13.659 20.192C13.9334 20.4557 14.3858 20.4512 14.6543 20.1821L21.0261 12.4839C21.5308 11.978 21.524 11.1879 21.0107 10.6897L20.9469 10.6278Z" />
      </svg>
    </div>
  )
}


