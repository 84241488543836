import * as cn from 'classnames';
import * as React from 'react';

import { ActionType, useEnvAction } from '~components/Provider/Env';
import EditSmall from '~ui/Icon/icons/EditSmall';
import RemoveIcon from '~ui/Icon/icons/RemoveIcon';

import { cleanPathname } from './DevManager.helper';
import { EditType } from './FlagSwitcher';
import * as styles from './styles.module.css'

type EditApiProps = {
  editMode: EditType;
  focused: boolean;
  setEditMode: (arg: EditType) => void;
  children: string;
  editable: boolean;
}


const EditApi: React.FC<EditApiProps> = ({ focused, editMode, setEditMode, editable, children }) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>(children);
  const envAction = useEnvAction();

  React.useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        ref.current?.focus();
      }, 400);
    }
  }, [ref.current]);

  React.useEffect(() => {
    if (edit && editMode !== EditType.Editing) {
      setEdit(false);
    }
  }, [editMode, edit]);

  const handleUpdate = ({ target }) => {
    setValue(target.value);
  };

  const handleClick = () => {
    if (editMode === EditType.Edit) {
      setEdit(true);
      setEditMode(EditType.Editing);
    } else if (editMode === EditType.Delete) {
      envAction({
        type: ActionType.DeleteAPI,
        api: value,
      });
      setEditMode(EditType.None);
    }
  };

  const handleSubmit = () => {
    if (editMode === EditType.Editing) {
      envAction({
        type: ActionType.UpdateAPI,
        key: children,
        api: `https://${cleanPathname(value)}`,
      });
      setEdit(false);
      setEditMode(EditType.None);
    }
  };

  return (
    <span
      onClick={ handleClick }
      className={ cn({
        focusedNavigationNode: focused && editMode !== EditType.None && !edit,
      }) }
    >
      <span
        style={ { opacity: (focused && !edit && editMode !== EditType.None) ? 0.5 : 1 } }
      >
        {
          edit ? (
            <form
              className={ styles.form }
              onSubmit={ handleSubmit }
            >
              <input
                className={ styles.edit }
                ref={ ref }
                type="text"
                value={ value }
                onChange={ handleUpdate }
              />
            </form>
          ) :  <span className={ styles.itemName }>{children}</span>
        }
      </span>
      {
        focused && !edit && editable && (
          <span style={ {
            position: 'absolute',
            left: '100%',
            paddingLeft: '10px',
            whiteSpace: 'nowrap',
            filter: `${edit ? 'grayscale(100%)' : null} `
          } }>
            <span
              style={ { opacity: (editMode === EditType.Edit) ? 1 : 0.2, color: '#4287f5' } }
            ><EditSmall/></span>
            <span
              style={ { opacity: (editMode === EditType.Delete) ? 1 : 0.2, color: 'tomato', marginLeft: '10px' } }
            ><RemoveIcon/></span>
          </span>
        )
      }
    </span>
  );
};


export default EditApi;
