/* eslint-disable max-len */
import * as React from 'react';

import { IconDisLikeBase } from './dislikeBase';

const IconDisLike: React.FC<{ isFocused: boolean }> = ({ isFocused }) => (
  <IconDisLikeBase
    isFocused={ isFocused }
    isPressed={ false }
  />
);

export default React.memo(IconDisLike);
