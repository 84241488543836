import * as mpdParser from 'mpd-parser';


const m3u8Parser = require('m3u8-parser');


interface ManifestParserPlayList {
  attributes: {
    RESOLUTION: {
      width: number | null;
      height: number | null;
    };
    BANDWIDTH: number | null;
  };
}

interface ManifestParser {
  allowCache?: boolean;
  endList?: boolean;
  mediaSequence?: number;
  discontinuitySequence?: number;
  playlistType?: string;
  custom?: any;
  playlists: ManifestParserPlayList[];
  mediaGroups?: any;
  dateTimeString?: string;
  dateTimeObject?: string;
  targetDuration?: number;
  totalDuration?: number;
  discontinuityStarts?: any;
  segments?: any[];
}


const parseManifestM3u8 = (manifestText: string): ManifestParser => {
  const parser = new m3u8Parser.Parser();
  parser.push(manifestText);
  parser.end();

  const parsedManifest = parser.manifest as ManifestParser;

  return parsedManifest;
};

const parseManifestMpd = (manifestText: string, url?: string): ManifestParser => {
  const parsedManifest = mpdParser.parse(manifestText, { manifestUri: url }) as ManifestParser;

  return parsedManifest;
};

export {
  ManifestParser,
  ManifestParserPlayList,
  parseManifestM3u8,
  parseManifestMpd,
};
