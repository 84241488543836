import React, { useEffect } from 'react';

import { useAutoInfinityBlackouts } from '~hooks/fetch/useBlackouts/useBlackouts';
import Channel from '~typings/Channel';

import { EPGEventsList } from '../../EPGEventsList';
import { EPGEventsSkeleton } from '../EPGEventsSkeleton';

const DELAY_BEFORE_LIST_SHOW = 400;


type EPGEventsListLoaderProps = Omit<React.ComponentProps<typeof EPGEventsList>, 'blackouts'>;

const EPGEventsListLoader$: React.FC<EPGEventsListLoaderProps> = (props) => {
  const [passedChannel, setPassedChannel] = React.useState<Channel | null>(props.channel);
  const isChannelDelayPassed = passedChannel === props.channel;

  useEffect(() => {
    const timeout = setTimeout(() => setPassedChannel(props.channel), DELAY_BEFORE_LIST_SHOW);
    return () => {
      clearTimeout(timeout);
      setPassedChannel(null);
    };
  }, [props.channel]);

  // Прокидываем null, если задержка не прошла, чтобы запрос на blackouts начался после задержки
  const blackoutsQuery = useAutoInfinityBlackouts(isChannelDelayPassed ? props.channel.id : null);

  if (!isChannelDelayPassed || blackoutsQuery.isFetching) {
    return <EPGEventsSkeleton channel={ props.channel } />;
  }

  return (
    <EPGEventsList
      { ...props }
      key={ props.channel.id }
      blackouts={ blackoutsQuery.data?.data }
    />
  );
};

/**
 * Компонент обертка, который рендерит EPGEventsList, но с задержкой на `DELAY_BEFORE_LIST_SHOW` мс
 * Еще загружает blackouts, которые нужны в EPGEventsList
 */
export const EPGEventsListLoader = EPGEventsListLoader$;
