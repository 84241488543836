import { Events } from './common';
import { VastErrors } from './VastErrors';
import VideoError from './VideoError';


const handleSourceError = (event, err) => {
  console.error(err);
  
  const message = 'Source error event';
  const nativeError = new Error(message);
  event.emit(Events.ERROR, new VideoError({
    nativeError,
    message,
    vastError: VastErrors.TRAFFICKING_ERROR,
  }));
};


export default handleSourceError;
