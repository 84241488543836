import {
  allFocuserEventsArray,
  AnyFocuserEvent,
  FOCUSER_POINTER_EVENT_NAME,
} from '@focuser/events';
import { simulatePointerEventOnInnerMost } from '@focuser/KeyDownHandler/utils';
import { useEffect } from 'react';

const ISOLATED_DIVS: HTMLDivElement[] = [];

export const useIsolationDivUpdater = ({ ref }: { ref: React.RefObject<HTMLDivElement> }) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const div = ref.current;

    ISOLATED_DIVS.push(div);

    return () => {
      const index = ISOLATED_DIVS.indexOf(div);
      if (index !== -1) {
        ISOLATED_DIVS.splice(index, 1);
      }
    };
  }, []);
};

export const useEventsBlocker = ({ ref }: { ref: React.RefObject<HTMLDivElement> }) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const handler = (event: Event) => {
      const customEvent = event as CustomEvent<AnyFocuserEvent>;
      const focuserEvent = customEvent.detail;

      if (focuserEvent.type === FOCUSER_POINTER_EVENT_NAME) {
        focuserEvent.isBlockedByIsolation = true;
        return;
      }

      focuserEvent.stop();
    };

    allFocuserEventsArray.forEach((eventName) => {
      ref.current!.addEventListener(eventName, handler);
    });
  }, []);
};

export const usePointerEventOnUnmount = ()=> {
  useEffect(() => {
    return () => {
      simulatePointerEventOnInnerMost();
    };
  }, []);
}


export const getRootIsolatedDiv = (): HTMLDivElement | undefined => {
  return ISOLATED_DIVS[ISOLATED_DIVS.length - 1];
};
