import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import BannerBig from '~typings/BannerBig';
import Card from '~typings/Card';
import { DataItem, MediaItemType } from '~typings/DataItem';
import Favorite from '~typings/Favorite';
import ItemObject from '~typings/ItemObject';
import MediaItemDimension from '~typings/MediaItemDimension';
import SeeAll from '~typings/SeeAll';
import WatchProgressWithResource from '~typings/WatchProgressWithResource';
import BannerBigItem from '~ui/BannerBigItem';
import CardItem from '~ui/Card';
import MediaItem from '~ui/MediaItem/MediaItem';


export type Props = {
  itemKey: string;
  className?: string;
  top?: number;
  left?: number;
  dimension: MediaItemDimension;
  isFocused: boolean;
  withName?: boolean;
  showAsSeries?: boolean;
  withGenres?: boolean;
  alwaysShowName?: boolean;
  withCurrentEvent?: boolean;
  withProgress?: boolean;
  percentWatched?: number;
  forceOnClick?: boolean;
  editMode?: boolean;
  isChecked?: boolean;
  isSliderView?: boolean;
  onClick?: (item: DataItem) => void;

  intl: InjectedIntl;
};

export interface MediaItemProps extends Props {
  item?: DataItem | SeeAll;
}

export interface WatchProgressItemProps extends Props {
  item: WatchProgressWithResource | undefined;
}

export interface FavoriteItemProps extends Props {
  item: Favorite | undefined;
}

export interface CardItemProps extends Props {
  item: Card | undefined;
  cardConfigID?: string;
}

export interface BannerBigItemProps extends Props {
  item: BannerBig | undefined;
}

interface MediaItemWrapperProps extends Props {
  item: MediaItemType | undefined;
  cardConfigID?: string;
}


const MediaItemWrapper: React.FC<MediaItemWrapperProps> = (props: MediaItemWrapperProps) => {
  switch (props.item?.object) {
    case ItemObject.WatchProgressWithResource: {
      const { item, ...restProps } = props as WatchProgressItemProps;

      return (
        <CardItem
          { ...restProps }
          item={ item?.card }
          percentWatched={ item?.percents_watched }
          withProgress
        />
      );
    }
    case ItemObject.Favorite: {
      const { item, ...restProps } = props as FavoriteItemProps;

      return item?.card ? (
        <CardItem
          { ...restProps }
          item={ item.card }
        />
      ) : (
        <MediaItem
          { ...restProps }
          item={ item?.resource }
        />
      );
    }
    case ItemObject.Card: {
      return (
        <CardItem { ...props as CardItemProps } />
      );
    }
    case ItemObject.BigBanner: {
      return (
        <BannerBigItem { ...props as BannerBigItemProps } />
      );
    }

    default: {
      return (
        <MediaItem { ...props as MediaItemProps } />
      );
    }
  }
};


export default React.memo(injectIntl(MediaItemWrapper));
