import * as cn from 'classnames';
import * as React from 'react';

import CastMember from '~typings/CastMember';

import * as styles from './styles.module.css';

type Props = {
  actor: CastMember;
  isFocused: boolean;
};

const ActorItem: React.FC<Props> = (props: Props): React.ReactElement | null => {
  const actorImage = React.useMemo(() => {
    if (props.actor.person?.images.length > 0) {
      return props.actor.person.images[1].url_template.replace('_{width}x{height}{crop}', '');
    }
    return null;
  }, [props.actor]);

  return (
    <div
      className={ cn(styles.actorItem) }
    >
      <div className={ styles.imgContainer }>
      {actorImage ? (
        <img
          className={ cn(styles.actorImg, {
            [styles.focusedImage]: props.isFocused,
          }) }
          src={ actorImage }
        />
      ) : (
        <div
        className={ cn(styles.noActorImgWrap, {
          [styles.noActorImgWrapFocused]: props.isFocused,
        }) }
        >
          <img
            className={ cn(styles.noActorImg, {
              [styles.focusedImage]: props.isFocused,
            }) }
          />
          <span
            className={ cn(styles.firstLetter, {
              [styles.focusedText]: props.isFocused,
            }) }
          >
            {props.actor.person?.full_name[0]}
          </span>
        </div>
      )}
      </div>
      <p className={ styles.actorName }>{props.actor.person?.full_name}</p>
    </div>
  );
};

export default ActorItem;
