import cn from 'classnames';
import React from 'react';

import * as styles from './PageController.module.css';

export type Props = {
  /**
   * Кол-во точек, которое нужно показывать
   */
  dotsCount: number;
  /**
   * Индекс выбранной точки
   */
  selectedIdx: number;
  /**
   * Сфокусирован ли компонент
   */
  isFocused: boolean;

  /**
   * Дополнительный класс
   */
  className?: string;
};

const PageController$: React.FC<Props> = ({ dotsCount, selectedIdx, isFocused, className }) => {
  return (
    <div className={ cn(styles.dots, className) }>
      {new Array(dotsCount).fill(null).map((_, idx) => {
        return (
          <div
            key={ idx }
            className={ cn(
              styles.dot,
              { [styles.dotSelected]: selectedIdx === idx },
              { [styles.dotFocused]: selectedIdx === idx && isFocused },
            ) }
          ></div>
        );
      })}
    </div>
  );
};

export const PageController = React.memo(PageController$);
