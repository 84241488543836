import { ac_00, gw_00 } from "customization-project-name/colors";
import React from "react";
/* eslint-disable max-len */

type Props = {
  width?: string
  height?: string
}

const GoTo$ = ({ width = '4.6875vw', height = '4.6875vw' }: Props) => {
  return (
    <div style={ { width, height } }>
      <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill={ ac_00 } />
        <path
          d="M25.6353 22.2332C24.7575 21.4491 24.7431 20.0803 25.6044 19.2781L25.635 19.2495C26.394 18.5425 27.5675 18.5331 28.3378 19.2278L39.1939 29.0193C39.628 29.4108 39.6352 30.0896 39.2094 30.4902L28.3401 40.7169C27.5729 41.4388 26.3772 41.4418 25.6064 40.7238L25.5609 40.6814C24.7156 39.894 24.7113 38.5562 25.5515 37.7634L34.0471 29.7464L25.6353 22.2332Z"
          fill={ gw_00 } />
      </svg>
    </div>
  )

}

export const GoTo = GoTo$