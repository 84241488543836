import { QueryKey,useQuery } from 'react-query';

import { useClient } from '~global';
import { getAuthData } from '~hooks/fetch/useAccount';
import BestPrice from '~typings/BestPrice';
import ResponseSingle from '~typings/ResponseSingle';

const PREFIX_OF_A_COMPOSITE_KEY = 'best_price';

const getKey = (resourceID?: string): QueryKey => [PREFIX_OF_A_COMPOSITE_KEY, resourceID];


const fetch = async (client, accessToken, resourceID, planType) => {
  if (!resourceID || !planType) { return undefined; }

  return await client.get(
    '/v3/purchases/best_price',
    {
      access_token: accessToken,
      'filter[plan_type_in]': planType,
      'filter[resource_type_in]': 'movies,series',
      'filter[resource_id_eq]': resourceID,
    }
  );
};


const useBestPrice = (resourceID?: string, planType?: string) => {
  const queryKey = getKey(resourceID);
  const { accessToken } = getAuthData();

  const client = useClient();

  return useQuery<ResponseSingle<BestPrice[]>>({
    queryKey,
    queryFn: () => fetch(client, accessToken, resourceID, planType),
    enabled: !!(accessToken && resourceID),
    staleTime: 0,
    cacheTime: 0,
  });
};


export {
  getKey,
  PREFIX_OF_A_COMPOSITE_KEY,
};

export default useBestPrice;
