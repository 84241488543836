import * as URLSearchManager from '~lib/URLQueryStringManager';


const URLVariableState = <T>(history, variableName, defaultValue: T) => {
  const value = (): T => {
    const res = URLSearchManager.getField(history.location.search, variableName);

    if (res === undefined) { return defaultValue; }

    return res;
  }
  const set = (value: T) => {
    URLSearchManager.set({ [variableName]: value }, history);
  };

  if (value() === null) {
    set(defaultValue);
  }

  return {
    value,
    set,
  };
};


export default URLVariableState;
