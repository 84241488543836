import * as React from 'react';

import useBannerRotation from "~components/BlockPage/navigation.helper";
import { ActionType as MenuActionType, useMenuAction } from '~components/Provider/Menu';
import { getVars } from "~components/TiledView/component.helpers";
import useNavigationByKeys from '~hooks/useNavigation';
import useNavigation from '~stores/Navigation';
import { BlockType } from "~typings/Block";
import NavigationDirection from '~typings/NavigationDirection';


export type Props = {
  mainPageID: string | null;
  pageId: string;
  isMediaItemPage: boolean;
  isAllowedMenuRender: boolean;
  isAllowedNavigation: boolean;
  children: (index: number, focusedBlockID: string | null) => JSX.Element | null;
  initialFocusedIndex?: number;
  onChangeIndex?: (index: number) => void;
  handleLeave?: () => void;
};


const switchSliderAnimationMS = 260;
const changeSliderIndexAnimationMS = 60;

const BlockPageNavigation: React.FC<Props> = (props: Props) => {
  const menuAction = useMenuAction(); //управляем верхним меню, реактовый редакс

  const currentPage = useNavigation(state => state.currentPage);//page object
  const focusedBlockID = useNavigation(state => state.getFocusedBlockID()); //string
  const getBlockPosition = useNavigation(state => state.getBlockPosition); //fn
  const syncFocusedBlockIndex = useNavigation(state => state.syncFocusedBlockIndex); //fn
  const getPrevBlock = useNavigation(state => state.getPrevFocusedBlockID); //fn
  const setPrevBlock = useNavigation(state => state.setPrevBlock); //fn
  const getNextBlock = useNavigation(state => state.getNextFocusedBlockID); //fn
  const setNextBlock = useNavigation(state => state.setNextBlock); //fn
  const getFocusedBlockIndex = useNavigation(state => state.getFocusedBlockIndex); //fn
  const isNavigationNeedToSync = useNavigation(state => state.isNavigationNeedToSync); //bool
  const setIsNavigationNeedToSync = useNavigation(state => state.setIsNavigationNeedToSync); //fn
  const focused = useNavigation(state => state.getFocused()); //{index: 0, itemsCount: 58}
  const focusedItemsCount = focused?.itemsCount || 0; //кол-во горизонтальных элементов в блоке
  const [isNavigationInProcess, setIsNavigationInProcess] = React.useState(false);
  const [index, setIndex] = React.useState<number>(()=> getBlockPosition(focusedBlockID || '')?.index ?? 0);
  const focusedBlockIndex = React.useRef<number>(0);
  const setIndexTimeout = React.useRef<any>(null);
  const timeout = React.useRef<any | null>(null);
  const focusedBlockTotal = focusedBlockID ? (getBlockPosition(focusedBlockID)?.itemsCount ?? 0) : 0;
  const focusedBlock = currentPage?.blocks?.find(({ id }) => (id === focusedBlockID));
  const isMounted = props.isAllowedNavigation && !isNavigationInProcess


  // привязываем нажатие кнопок к функциям ↓ ↓ ↓ ↓ ↓
  const handleKeyNavigate = (direction): void => {
    switch (direction) {
      case NavigationDirection.Up: {
        handleUpAction()
        break;
      }
      case NavigationDirection.Right: {
        handleRightAction()
        break;
      }
      case NavigationDirection.Down: {
        handleDownAction()
        break;
      }
      case NavigationDirection.Left: {
        handleLeftAction();
        break;
      }
    }
  };

  useNavigationByKeys({
    isMounted,
    onNavigation: handleKeyNavigate,
  }, [focusedItemsCount]);

  // управляем верхним меню ↓ ↓ ↓ ↓ ↓
  const handleChangeMenuAppearing = (isMenuAppeared: boolean): void => {
    menuAction({
      isMenuAppeared,
      type: MenuActionType.ChangeMenuAppearing,
    });
  };

  // установка номера элемента который в ФОКУСЕ. работает при кнопках вверх/вниз/влево/вправо ↓ ↓ ↓ ↓ ↓
  const handleChangeFocusedIndex = (i: number): void => {
    if (focusedBlockIndex.current !== i) {
      clearTimeout(setIndexTimeout.current);

      setIndexTimeout.current = setTimeout(() => {
        focusedBlockIndex.current = i;
        setIndex(i);
      }, 10);
    }
  };


  // установка номера элемента который в ФОКУСЕ по blockID. работает при кнопках вверх/вниз ↓ ↓ ↓ ↓ ↓
  const handleChangeFocusedIndexByBlockID = (blockID?: string): void => {
    handleChangeFocusedIndex(
      blockID
        ? Math.min((getBlockPosition(blockID)?.itemsCount ?? 0), (getBlockPosition(blockID)?.index ?? 0))
        : 0
    );
  };


  // хук для ротации баннеров  ↓ ↓ ↓ ↓ ↓
  useBannerRotation({
    isAllowRotate: (
      isMounted
      && focusedBlock?.object === BlockType.BannersBlock
      || focusedBlock?.object === BlockType.BigBannersBlock
    ),
    index,
    onChangeIndex: handleChangeFocusedIndex,
  });


  // очистка работает один раз вначале  ↓ ↓ ↓ ↓ ↓
  const clear = () => {
    setIsNavigationInProcess(false);

    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };


  // это только для grid  ↓ ↓ ↓ ↓ ↓
  const { lineByItemIndex, itemsCountInLine } = React.useMemo(() => {
    return focused?.isGrid && focused?.dimension ? getVars(focused?.dimension) : {} as ReturnType<typeof getVars>
  }, [focused?.isGrid])

  const totalLines = React.useMemo(() => {
    return focused?.isGrid && itemsCountInLine ? Math.ceil(focusedItemsCount / itemsCountInLine) - 1 : 0
  }, [focused?.isGrid, focusedItemsCount, itemsCountInLine])

  const focusedLine = React.useMemo(() => {
    return focused?.isGrid && lineByItemIndex ? lineByItemIndex(index) : 0
  }, [focused?.isGrid, lineByItemIndex, index]);

  const handleOpenMenu = React.useCallback(() => {
    if (!props.isAllowedMenuRender) {
      return null;
    }
    switch (getFocusedBlockIndex()) {
      case 0:
        // Если это грид, то открываем меню только когда на первой линии находимся
        if(focused?.isGrid && focusedLine > 0){
          break;
        }
        menuAction({
          type: MenuActionType.ChangeMenuState,
          isMenuOpened: true,
        });
        break;
      case 1:
        handleChangeMenuAppearing(props.pageId === props.mainPageID);
        break;
      default:
        break;
    }
  }, [props.isAllowedMenuRender, focused?.isGrid, focusedLine]);


  // работает при кнопках вверх/вниз/влево/вправо  ↓ ↓ ↓ ↓ ↓
  const handleNavigationInProgress = (ms: number): void => {
    setIsNavigationInProcess(true);
    timeout.current = setTimeout(setIsNavigationInProcess, ms, false);
  };


  // синхронизация ??  ↓ ↓ ↓ ↓ ↓
  const syncIndex = () => {
    if (focused && (getBlockPosition(focusedBlockID || '')?.index || 0) !== focusedBlockIndex.current) {
      syncFocusedBlockIndex(focusedBlockIndex.current);
    }
  };


  // функция при нажатии влево ↓ ↓ ↓ ↓ ↓
  const handleLeftAction = (): void => {
    handleNavigationInProgress(changeSliderIndexAnimationMS);
    if (focused?.isGrid && ((index % itemsCountInLine) === 0)) {

    }
    else {
      handleChangeFocusedIndex(Math.max((focusedBlockIndex.current - 1), 0));
    }
  };


  // функция при нажатии вправо ↓ ↓ ↓ ↓ ↓
  const handleRightAction = (): void => {
    if (focused?.isGrid && ((index + 1) >= focusedItemsCount || ((index + 1) % itemsCountInLine) === 0)) {

    }
    else {
      handleNavigationInProgress(changeSliderIndexAnimationMS);
      const newIndex = (focusedBlockIndex.current + 1);

      // console.log('itemsCount:', itemsCount, 'focusedBlockID:', focusedBlockID);
      // if (focusedBlockID) {
      //   console.log('getBlockPosition(focusedBlockID)?.itemsCount:', getBlockPosition(focusedBlockID)?.itemsCount);
      // }
      const newFocusedIndex = (focusedItemsCount !== null)
        ? Math.min(newIndex, (focusedItemsCount - 1))
        : newIndex;

      handleChangeFocusedIndex(newFocusedIndex);
    }
  };


  // функция при нажатии вверх ↓ ↓ ↓ ↓ ↓
  const handleUpAction = (): void => {
    handleNavigationInProgress(switchSliderAnimationMS);
    handleOpenMenu();

    const prevBlockID = getPrevBlock() || undefined;

    if (focused?.isGrid && focusedLine > 0) {

      const newFocusedIndex = Math.max(
        (index - itemsCountInLine),
        0
      );

      handleChangeFocusedIndex(newFocusedIndex)
    }
    else if (prevBlockID) {
      syncIndex();
      setPrevBlock(prevBlockID);

      handleChangeFocusedIndexByBlockID(prevBlockID);
    }
  };


  // функция при нажатии вниз ↓ ↓ ↓ ↓ ↓
  const handleDownAction = (): void => {

    handleNavigationInProgress(switchSliderAnimationMS);

    if (getFocusedBlockIndex() === 0) {
      handleChangeMenuAppearing(false);
    }

    const nextBlockID = getNextBlock() || undefined;

    if (focused?.isGrid && totalLines > focusedLine) {
      const newFocusedIndex = Math.min(
        (index + itemsCountInLine),
        (focusedItemsCount - 1)
      );

      handleChangeFocusedIndex(newFocusedIndex)
    }
    else if (nextBlockID) {
      syncIndex();
      setNextBlock(nextBlockID);

      handleChangeFocusedIndexByBlockID(nextBlockID);
    }
  };


  React.useEffect(() => {
    if (isNavigationNeedToSync && focused && index !== focused.index) {
      setIsNavigationNeedToSync(false);
      handleChangeFocusedIndex(focused.index);
    }
  }, [index, focused?.index, isNavigationNeedToSync]);

  React.useEffect(() => {
    const tm = setTimeout(syncIndex, 300);

    return (
      () => {
        clearTimeout(tm);
      }
    );
  }, [props.isAllowedNavigation]);

  React.useEffect(() => {
    handleChangeFocusedIndex(
      focusedBlockID
        ? Math.min((focusedBlockTotal - 1), (getBlockPosition(focusedBlockID)?.index ?? 0))
        : 0
    );
  }, [focusedBlockID]);

  React.useEffect(() => clear, []);

  React.useEffect(() => {
    return (() => {
      handleChangeMenuAppearing(false);

      syncIndex();
    });
  }, []);

  return props.children(index, focusedBlockID);
};


export default BlockPageNavigation;
