/* eslint-disable max-len */
import { ac_00, gw_00, gw_04, gw_11 } from "customization-project-name/colors";
import * as React from 'react';

type Props = {
  width?: string
  height?: string
  isFocused: boolean
}

const IconChipsFavourite: React.FC<Props> = ({ width = '6.111111111111111vh', height = '6.111111111111111vh', isFocused }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={ isFocused ? ac_00 : gw_11 }/>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M14.5314 31.3334L14.5312 13.6001C14.5312 12.5691 15.367 11.7334 16.3979 11.7334L27.5979 11.7334C28.6289 11.7334 29.4646 12.5691 29.4646 13.6L29.4647 31.3334C29.4647 31.6979 29.2525 32.0291 28.9212 32.1814C28.59 32.3337 28.2005 32.2792 27.9238 32.0419L21.998 26.96L16.0723 32.0419C15.7956 32.2792 15.406 32.3337 15.0748 32.1814C14.7436 32.0291 14.5314 31.6979 14.5314 31.3334Z" 
        fill={ isFocused ? gw_00 : gw_04 }
      />
    </svg>
  </div>
);


export { IconChipsFavourite };
