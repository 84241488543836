import * as cn from 'classnames';
import * as React from 'react';

import EPGPageChannel from '~components/EPG/Channels/Channel';
import uuid from '~lib/uuid';
import Channel from '~typings/Channel';

import * as styles from './styles.module.css';


type Props = Readonly<{
  withCurrentEvent?: boolean;
  isNeedFetchEvents?: boolean;
  isFocused: boolean;
  focusedIndex: number;
  items: Channel[];
  total: number;
  onClick?: (channel: Channel) => void;
}>;

type ItemForRender = Readonly<{
  item: Channel;
  properties: object;
  idx: number;
}>;


const epgItemHeight = 8.125;


const EPGPageChannelsList: React.FC<Props> = (props: Props) => {
  const UUID = React.useMemo(
    () =>  (new Array(props.total || 0))
      .fill(0)
      .map((_, index) => uuid(index)),
    [props.total],
  );

  const fromIndex = props.total < 12 ?
    Math.max(props.focusedIndex - 5, 0)
    : (props.focusedIndex - 5);
  const toIndex = props.total < 12 ?
    Math.min(props.focusedIndex + 4, props.total - 1)
    : (props.focusedIndex + 4);
  
  const itemsForRender: ItemForRender[] = React.useMemo(() => {
    const res: ItemForRender[] = [];
  
    const top = (index: number): number => {
      if (fromIndex < 0 && index - UUID.length >= fromIndex) {
        return (index - UUID.length) * epgItemHeight;
      } else {
        return index * epgItemHeight;
      }
    };

    for (let idx = fromIndex; idx <= toIndex; idx += 1) {
      const index = (idx >= 0) ?
        (idx)
        :
        (UUID.length + idx);
      const rightIdx = (UUID.length + (idx % UUID.length)) % UUID.length;

      res.push({
        properties: {
          key: UUID[rightIdx],
          className: cn(styles.channelItemWrapper, {
            'focused': props.focusedIndex === idx,
          }),
          style: {
            top: `${top(index)}vw`,
          }
        },
        idx,
        item: props.items[rightIdx],
      });
    }

    return res;
  }, [UUID.length, props.items, fromIndex, toIndex]);

  const renderItem = ({ properties, idx, item }) => {
    return (
      React.createElement(
        'div',
        properties,
        <EPGPageChannel
          isActive={ props.isFocused && props.focusedIndex === idx }
          channel={ item }
          onClick={ props.onClick }
          withCurrentEvent={ props.withCurrentEvent }
          isNeedFetchEvents={ props.isNeedFetchEvents }
        />,
      )
    );
  };

  return (
    <>
      {
        itemsForRender.map(renderItem)
      }
    </>
  );
};


export default React.memo(EPGPageChannelsList);
