import { DRMType } from '~typings/Drm';


type Args = {
  url: string;
  drm: {
    type: DRMType;
  };
  clientId?: string | null;
};

type Options = {
  option: {
    drm: {
      type: DRMType;
      clientId?: string | null;
    };
  };
  mediaTransportType: 'WIDEVINE' | 'URI';
};


const getSourceTypeAttribute = ({ url, drm, clientId = null }: Args) => {
  const options: Options = {
    // mediaTransportType: 'URI',
    option: {},
  } as Options;

  if (drm && drm.type === DRMType.PlayReady) {
    options.option.drm = { type: DRMType.PlayReady };
  }
  if (drm && drm.type === DRMType.WideVine) {
    options.option.drm = { type: DRMType.WideVine };
    options.mediaTransportType = 'WIDEVINE';
  }
  if (clientId) {
    options.option.drm.clientId = clientId;
  }

  const mediaOption = escape(JSON.stringify(options));

  if (/\.mpd/.test(url)) {
    return `application/dash+xml;mediaOption=${mediaOption}`;
  }

  if (/\/manifest/i.test(url)) {
    return `application/vnd.ms-sstr+xml;mediaOption=${mediaOption}`;
  }

  if (/\.mp4/.test(url)) {
    return `video/mp4;mediaOption=${mediaOption}`;
  }

  if (/\.m3u8/i.test(url)) {
    return 'application/vnd.apple.mpegurl';
  }

  return null;
};


export default getSourceTypeAttribute;
