import useCachedBlocksItems from './useCachedBlocksItems';


const useBlocksTotals = (): number => {
  const { itemsByBlockID } = useCachedBlocksItems();

  let totals = 0;

  Object.keys(itemsByBlockID).forEach((blockID) => {
    const meta = itemsByBlockID?.[blockID]?.meta

    if (meta?.pagination?.total) {
      totals += meta.pagination.total;
    }
  });

  return totals;
};


export default useBlocksTotals;
