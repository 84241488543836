import { QueryKey, useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import QuickFilter from '~typings/QuickFilter';


const queryKey: QueryKey = ['search_quick_filters'];

const getQuickFilters = async (client) => {
  const cacheData = queryClient.getQueryData<QuickFilter[]>(queryKey);

  if (cacheData?.length) { return cacheData; }

  let url = `/v1/search_filters.json`;
  const options = {};

  const { data } = await client.get(url, options);

  return data;
};

function useQuickFilters(enabled: boolean) {
  const client = useClient();

  return useQuery<QuickFilter[]>({
    queryKey,
    queryFn: () => getQuickFilters(client),
    enabled,
  });
}


export default useQuickFilters;
