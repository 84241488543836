import * as React from 'react';

import { ExpandedReasonObj } from '~typings/PlayerAccess';

import ExpiresTime from './ExpiresTime';
import * as styles from './styles.module.css';


type Props = {
  purchase: ExpandedReasonObj;
};

const PurchaseInfo: React.FC<Props> = ({ purchase }) => {
  if(!purchase) { return null; }

  return (
    <div className={ styles.wrapper }>
      <ExpiresTime reasonObject={ purchase } />
    </div>
  );
};


export default React.memo(PurchaseInfo);
