/* eslint-disable max-len */
import { gw_07 } from 'customization-project-name/colors'
import * as React from 'react';

type Props = {
  width?: string
  height?: string
}

const SpbtvLike = ({ width = '1.7968749999999998vw', height = '2.7777777777777777vh' }: Props) => (
  <div style={ { width, height } }>
    <svg viewBox="0 0 23 20" fill={ gw_07 } xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.87619 11.2122C4.87619 11.2122 4.10226 10.9229 2.81959 10.9229C1.27713 10.9229 0.436903 11.8229 0.12706 13.3896C-0.182783 14.9509 0.108118 16.4109 0.63174 17.1229C1.10259 17.7656 1.71416 18.3896 2.87777 18.8376C3.79106 19.1896 5.66771 19.5576 6.00597 18.7323C5.16709 17.6323 4.87619 17.2523 4.5907 16.4176C4.20077 15.2838 4.09493 14.0738 4.28221 12.8909C4.4324 11.9349 4.87619 11.2109 4.87619 11.2109V11.2122ZM23 9.48685C22.977 8.77617 22.8877 8.29483 22.3573 8.01216C21.5347 7.57349 18.9409 7.86149 17.914 8.01216C15.1213 8.4215 14.9008 8.44283 15.4027 7.31348C15.534 7.02014 16.3891 5.42279 16.4784 3.43076C16.5055 2.83209 16.1442 1.5214 16.1442 1.5214C16.1442 1.5214 15.4027 -0.438624 13.9523 0.0907167C12.2691 3.86144 11.8199 5.84146 10.2653 7.70015C8.72691 9.54018 6.47141 10.2602 5.80031 11.1029C5.12515 11.9509 5.10214 14.5589 5.57706 15.8416C6.20892 17.5496 6.72037 19.179 10.3438 19.7803C11.8632 20.0603 14.5693 20.0283 15.7221 19.927C16.8735 19.827 18.6405 19.367 19.428 18.639C20.2155 17.911 20.3169 17.3763 20.6552 16.7149C20.9935 16.0523 22.1733 14.7362 22.3262 13.6602C22.4804 12.5842 22.1733 11.9562 22.356 11.3975C22.5386 10.8375 23.023 10.1962 22.9986 9.48685H23Z" />
    </svg>
  </div>
);


export { SpbtvLike };
