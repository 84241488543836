import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Loader from '~components/LightLoader/Loader';
import * as productsStyles from '~components/ProductPlanPage/styles.module.css';
import { removeStreamAccessQueries} from '~hooks/fetch/useStreamAccess';
import usePaymentProceed from '~hooks/usePaymentProceed/usePaymentProceed';
import {
  AUTH_ENTER_PASSWORD,
  ERROR_HAS_OCCURRED,
  NAVIGATION_BACK,
  PAYMENT_IN_PROGRESS,
  PAYMENT_WAS_SUCCESSFUL,
} from '~localization';
import ItemObject from '~typings/ItemObject';
import Button from '~ui/button';
import CheckIcon from '~ui/Icon/icons/Check';
import ErrorIcon from '~ui/Icon/icons/ErrorIcon';
import { Popup } from '~ui/Popup';

type Props = Readonly<{
  intl: InjectedIntl;
}>;

const PaymentProceed: React.FC<Props> = (props) => {
  const history = useHistory();
  const { isError, isSuccess, error } = usePaymentProceed();

  const matchPage = useRouteMatch<{ itemId: string; itemObject: ItemObject }>(
    '/:page/:id/media-item/:itemObject/:itemId/products'
  );
  const matchMediaPage = useRouteMatch<{ itemId: string; itemObject: ItemObject }>(
    '/media-item/:itemObject/:itemId/products'
  );
  const match = matchPage || matchMediaPage;

  const handleBack = () => {
    history.goBack();
  };

  const handleContinue = () => {
    removeStreamAccessQueries();
    if (match?.params.itemObject === ItemObject.Channel) {
      history.replace(`/media-item/${ItemObject.Channel}/${match?.params.itemId}/player?player=true`);
    } else {
      history.goBack();
    }
  };

  return (
    <Popup>
      <div className={ productsStyles.contentWrapper }>
        <div className={ productsStyles.infoIconWrapper }>
          {
            (isError) ? (
              <ErrorIcon className={ cn(productsStyles.icon, productsStyles.errorIcon) } />
            ) : (isSuccess) ? (
              <CheckIcon className={ cn(productsStyles.icon, productsStyles.successIcon) } />
            ) : (
              <Loader />
            )
          }
        </div>
        <div className={ productsStyles.info }>
          {
            (isError) ? error?.message || (
            props.intl.formatMessage({ id: ERROR_HAS_OCCURRED })
          ) : (isSuccess) ? (
              props.intl.formatMessage({ id: PAYMENT_WAS_SUCCESSFUL })
            ) : (
              props.intl.formatMessage({ id: PAYMENT_IN_PROGRESS })
            )
          }
        </div>
        {
          (isError) ? (
            <Button
              isFocused
              className={ cn(productsStyles.button, productsStyles.center, productsStyles.isFocused) }
              onClick={ handleBack }
            >
              {
                props.intl.formatMessage({ id: NAVIGATION_BACK })
              }
            </Button>
          ) : (isSuccess) ? (
            <Button
              isFocused
              className={ cn(productsStyles.button, productsStyles.center, productsStyles.isFocused) }
              onClick={ handleContinue }
            >
              {
                props.intl.formatMessage({ id: AUTH_ENTER_PASSWORD })
              }
            </Button>
          ) : null
        }
      </div>
    </Popup>
  );
};


export default injectIntl(PaymentProceed);
