import { useAutoInfinityChannels } from '~hooks/fetch/useChannels';

export const useAllChannels = () => {
  const { isLoading, data } = useAutoInfinityChannels();

  if (isLoading || !data?.data.length) {
    return {
      isLoading: true,
      channels: null,
    } as const;
  }

  return {
    isLoading: false,
    channels: data.data,
  } as const;
};
