import { getCachedWatchProgressForItem } from '~hooks/fetch/useWatchProgress/useWatchProgress';
import ItemObject from '~typings/ItemObject';
import { WatchProgress } from '~typings/WatchProgress';


type Props = Readonly<{
  accessToken: string | null;
  id?: string;
  object?: ItemObject;
}>;


const getWatchProgress = (props: Props): WatchProgress | null => {
  if ((props.object !== ItemObject.Movie && props.object !== ItemObject.Episode) || !props.id) { return null; }

  const cachedWatchProgress = getCachedWatchProgressForItem(props.accessToken, props.object, props.id);

  return cachedWatchProgress || null;
};


export default getWatchProgress;
