import * as cn from 'classnames';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import * as blockStyles from '~components/Block/styles.module.css';
import * as styles from '~components/BlockCollection/styles.module.css';
import { BlockTitle } from '~components/BlockTitle';
import { Filters } from '~components/Filters';
import Loader from '~components/LightLoader/Loader';
import CatchBack from '~components/PageManager/CatchBack';
import { ActionType, useApp, useAppAction } from '~components/Provider/App';
import { useMenu } from '~components/Provider/Menu';
import { useScene } from '~components/Provider/Scene';
import TiledView from '~components/TiledView/component';
import useIsPlayerPage from '~hooks/useIsPlayerPage';
import useSceneMediaItemPageInfo from '~hooks/useSceneMediaItemPage';
import { setPopupState } from '~lib/PopupState';
import { FILTER_BUTTON_RESET, SEARCH_NO_RESULTS } from '~localization';
import useFilters from '~stores/Filters';
import useNavigationOnLayer, { FocusPosition, NavigationLine } from '~stores/LayerNavigation';
import { MediaItemType } from '~typings/DataItem';
import DropdownFilter from '~typings/DropdownFilter';
import ImageType from '~typings/ImageType';
import MediaItemDimension from '~typings/MediaItemDimension';
import NavigationDirection from '~typings/NavigationDirection';
import QuickFilter from '~typings/QuickFilter';
import ResponseMany from '~typings/ResponseMany';
import Button from '~ui/button';
import { PageNavigation } from '~ui/PageNavigation/PageNavigation';

import * as css from './styles.module.css'


type Props = {
  layerID: string;
  title: ReactNode;
  dimension?: MediaItemDimension;
  items: ResponseMany<MediaItemType[]> | null | undefined;
  onMediaItemClick?: (item: any) => void;
  withProgress?: boolean;
  showAsSeries?: boolean;
  isLoading?: boolean;
  filters?: (QuickFilter | DropdownFilter)[];
  description?: string;
  slidesCountPerLine?: number;
  showOnlyFetchedItems?: boolean;
};

export default function CollectionPageWrapper(props: Props) {
  const setFocusedIndex = useNavigationOnLayer(state => state.setFocusedIndex);
  const focusedIndex = useNavigationOnLayer(state => state.getFocusedIndexOnLayer(props.layerID));
  const setFocusPosition = useNavigationOnLayer(state => state.setFocusPosition);
  const verticalNavigation = useNavigationOnLayer(state => state.verticalNavigation);
  const focusOn = useNavigationOnLayer(state => (state.focusHistory[props.layerID] || {})?.focusOn ?? 0);
  const [showPopUp, setShowPopUp] = useState(false)


  const history = useHistory();
  const scene = useScene();
  const {isLaunchingDone} = useApp();
  const appAction = useAppAction();
  const isPlayerPage = useIsPlayerPage();
  const {isSceneMediaItemPageMode} = useSceneMediaItemPageInfo();
  const menu = useMenu();
  const allowNavigation = !menu.isMenuOpened && !menu.isPopupOpened && !isSceneMediaItemPageMode && !isPlayerPage;
  const dimension = props.dimension || ImageType.BANNER;

  const hasFilters = (props.filters?.length || 0) > 0;
  const resetSelectedFilters = useFilters(state => state.resetFilters);

  const slidesCountForTiledView: [number, number] | undefined = useMemo(
    () =>
      props.slidesCountPerLine
        ? ([props.slidesCountPerLine, props.slidesCountPerLine])
        : undefined,
    [props.slidesCountPerLine],
  );

  const total = props.items?.data.length ?? props.items?.meta.pagination.total;

  const navigationItems = useMemo(() => {
    const res: NavigationLine[] = [];

    if (props.description && props.description.length >= 290) {
      res.push({maxIndex: 1})
    }

    if (props.filters && props.filters.length > 0) {
      res.push({maxIndex: props.filters.length});
    }
    res.push({maxIndex: total ?? 0, isTiled: true});

    return res;
  }, [props.filters?.length, total]);


  function reduceDescription(description: string | undefined) {
    if(description === undefined) {
      return undefined
    }
    if (description.length >= 290) {
      const arrayWithStrings = description.slice(0, 290).split(' ')
      arrayWithStrings.pop()
      arrayWithStrings.push('...')
      return arrayWithStrings.join(' ')
    }
    return description
  }

  const description = reduceDescription(props.description)


  useEffect(() => {
    if (hasFilters && focusOn === 0) {
      setFocusPosition(props.layerID, [1, 0]);
    }
  }, [hasFilters]);

  useEffect(() => {
    scene.changeSceneMediaItem(null, false, true);

    if (total === undefined) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: {isLaunchingDone: false},
      });
    }
  }, []);

  useEffect(() => {
    if (!isLaunchingDone && props.items?.data.length) {
      appAction({
        type: ActionType.SetIsLaunchingDone,
        payload: {isLaunchingDone: true},
      });
    }
  }, [props.items?.data.length]);

  //следующие переменные нужны для работы навигации. при добавлении нового элемента придется рефакторить
  const longDesCheck = props.description && props.description.length >= 290
  const tiledCheck = longDesCheck && hasFilters
  const tiledFocusPosition: number = tiledCheck
    ? 2
    : longDesCheck || hasFilters
      ? 1
      : 0

  const renderContent = (focusPosition: FocusPosition) => {


    return <>
      { showPopUp &&
        <div className={ css.popup } onClick={ () => {
          setShowPopUp(!showPopUp)
        } }>
          <div className={ css.popupWrapper }>
            <div className={ css.popupTitle }>{ props.title }</div>
            <div className={ css.popupDesc }>{ props.description }</div>
          </div>
        </div>
      }
      <div className={ css.container }>
        <BlockTitle isFocusedBlock isVisible isFixed={ false } isFadeOut={ false } noPortal={ true }>
          { props.title }
        </BlockTitle>
        { props.description &&
          <div className={ cn(css.descWrapper,
            {
              [css.focusOnDesc]: longDesCheck ? focusPosition.focusOn === 0 : false,
              'focusedNavigationNode': longDesCheck ? focusPosition.focusOn === 0 : false
            }) }
               onClick={ () => longDesCheck && setShowPopUp(!showPopUp) }>
            <div className={ css.desc }>{ props.description.length >= 290 ? description : props.description }</div>
          </div> }
        <div className={ cn(css.blockWrapper, blockStyles.visible) }>
          {
            hasFilters
            && <div className={ css.filters }>
              <Filters
                ID={ props.layerID }
                isFocused={ allowNavigation && focusPosition.focusOn === (longDesCheck ? 1 : 0) }
                focusedIndex={ focusPosition.focusedIndex[(longDesCheck ? 1 : 0)] }
                filterGroup={ props.filters }
              />
            </div>
          }
          {
            props.isLoading
              ? <div className={ styles.loaderWrapper }><Loader/></div>
              : total === 0
                ? <div className={ styles.noResults }>
                  <div><FormattedMessage id={ SEARCH_NO_RESULTS }/></div>
                  <div>
                    <Button
                      isFocused={ allowNavigation && focusPosition.focusOn === tiledFocusPosition }
                      className={ styles.button }
                      onClick={ resetSelectedFilters }
                    >
                      <FormattedMessage id={ FILTER_BUTTON_RESET }/>
                    </Button>
                  </div>
                </div>
                : <div className={ css.blocks }>
                  <TiledView
                    items={ props.items }
                    focusedItemIndex={ focusPosition.focusedIndex[tiledFocusPosition] }
                    dimension={ dimension }
                    allowNavigation={ allowNavigation && focusPosition.focusOn === tiledFocusPosition }
                    isNeedJustHideChildren={ false }
                    withProgress={ props.withProgress }
                    showAsSeries={ props.showAsSeries }
                    slidesCount={ slidesCountForTiledView }
                    showOnlyFetchedItems={ props.showOnlyFetchedItems }
                  />
                </div>
          }
        </div>
      </div>
    </>
  }

  const handleBack = useCallback(() => {
    if (isPlayerPage || isSceneMediaItemPageMode) {
      history.goBack();
      return;
    }

    if (menu.isPopupOpened) {
      setPopupState(history, null);
      return;
    }

    if (allowNavigation) {
      if (props.filters  && focusOn === tiledFocusPosition) {
        setFocusPosition(props.layerID, [focusOn, 0]);
        setTimeout(() => void verticalNavigation(NavigationDirection.Up));
      } else if (focusedIndex > 0) {
        setFocusedIndex(props.layerID, 0);
      } else {
        resetSelectedFilters();
        history.goBack();
      }
    }
  }, [
    allowNavigation,
    focusedIndex,
    focusOn,
    isPlayerPage,
    isSceneMediaItemPageMode,
    menu.isPopupOpened,
    props.filters,
    props.layerID,
  ]);

  return (
    <>
      <CatchBack action={ handleBack } triggers={ [handleBack] }/>
      <PageNavigation
        layerID={ props.layerID }
        dimension={ dimension }
        navigationItems={ navigationItems }
        isAllowedNavigation={ allowNavigation && !!props.items }
        isTiledView
      >
        { renderContent }
      </PageNavigation>
    </>
  );
};
