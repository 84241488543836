import { debounce,orderBy } from 'lodash';
import * as React from 'react';

import { usePlayerState } from '~components/Provider/Player';
import usePlayerStateActions from '~components/Provider/Player/actions';
import { useAutoInfinityChannels } from '~hooks/fetch/useChannels';
import usePressingDigitOnRemote from '~hooks/usePressingDigitOnRemote';


type Props = Readonly<{
  isMounted: boolean;
  onClosePopup: () => void;
}>;


const TIMEOUT_BEFORE_SWITCH_CHANNEL_IN_MS = 2000;


const useSwitchChannelByRemoteDigitCode = (props: Props): number | null => {
  const { setItem } = usePlayerStateActions();
  const [channelCode, setChannelCode] = React.useState<number | null>(null);
  const currentChannelID = usePlayerState().item?.id;
  const { data } = useAutoInfinityChannels();
  const channels = React.useMemo(
    () => orderBy(data?.data || [], ['position'], ['asc']),
    [data?.data],
  );

  const handleDigitPressed = React.useCallback((digit) => {
    setChannelCode(parseInt(`${channelCode || ''}${digit}`, 10));
  }, [channelCode]);
  const handleChangeChannelByChannelCode = (position: number | null) => {
    if (position !== null) {
      const nextChannel = channels.find((channel) => (channel.position === position));

      if (nextChannel && nextChannel.id !== currentChannelID) {
        setItem(nextChannel);
        props.onClosePopup();
      }

      setChannelCode(null);
    }
  };
  const delayedChangeChannelByChannelCode = React.useCallback(debounce(
    handleChangeChannelByChannelCode,
    TIMEOUT_BEFORE_SWITCH_CHANNEL_IN_MS,
  ), [channels.length, currentChannelID]);


  usePressingDigitOnRemote({
    isMounted: props.isMounted,
    onDigitPressed: handleDigitPressed,
    update: [channelCode],
  });
  React.useEffect(() => {
    if (channelCode !== null) {
      delayedChangeChannelByChannelCode(channelCode)
    }
  }, [channelCode]);

  return channelCode;
};


export default useSwitchChannelByRemoteDigitCode;
