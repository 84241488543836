import { find } from 'lodash';

import SceneItem from '~components/Scene/typings';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import BannerBig from '~typings/BannerBig';
import BannerMiddle from '~typings/BannerMiddle';
import CertificationRating from '~typings/CertificationRating';
import ItemObject from '~typings/ItemObject';


export const getCertificationRating = (
  item: SceneItem | BannerBig | BannerMiddle,
  ratingSystem: string
): CertificationRating | null => find(
  item.object === ItemObject.Episode
    ? item.series?.certification_ratings
    : (item as any)?.certification_ratings ?? []
    ?? EMPTY_ARRAY,
  { system: ratingSystem }
) ?? null;
