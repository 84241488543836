import cn from 'classnames';
import * as React from 'react';

import * as styles from './styles.module.css';

type Props = Readonly<{
  Icon?: React.FC<{ className?: string }>,
  isFocused: boolean;
}>;

const InputIcon: React.FC<Props> = ({ Icon, isFocused }: Props) => Icon ? (
  <div className={ cn(
    styles.iconButton, {
    [styles.focused]: isFocused,
  })
  }>
    <Icon className={ styles.icon } />
  </div>
) : null;

export default InputIcon;
