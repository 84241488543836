import * as React from 'react';


type Props = {
  className?: string;
};

/* eslint-disable max-len */
const PhoneIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg className={ className } viewBox="0 0 160.43 159.4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M119.69,8a2.21,2.21,0,0,1,1.31.37V151a2.21,2.21,0,0,1-1.31.37H40.75a2.27,2.27,0,0,1-1.32-.37V8.37A2.27,2.27,0,0,1,40.75,8h78.94m0-8H40.75c-5.14,0-9.32,3.59-9.32,8V151.4c0,4.41,4.18,8,9.32,8h78.94c5.13,0,9.31-3.59,9.31-8V8c0-4.41-4.18-8-9.31-8Zm-8.36,18H49.1V122.34h62.23ZM80.22,130.22a7.17,7.17,0,1,0,7.18,7.16,7.18,7.18,0,0,0-7.18-7.16Z"
        fill="currentColor"
      />
    </svg>
  );
};


export default PhoneIcon;
