import { useMemo } from 'react';

import { BlockType } from '~typings/Block';
import Page from '~typings/Page';

/**
 * Возвращает блоки из `page`-а
 */
export const useSCCPBlocks = (page: Page) => {
  return useMemo(() => {
    const cardCollectionBlock = page.blocks?.find(
      (el) => el.object === BlockType.CardCollectionBlock,
    )?.card_collection;

    const bigBannerBlock = page.blocks?.find((el) => el.object === BlockType.BigBannersBlock);
    const middleBannerBlock = page.blocks?.find((el) => el.object === BlockType.MiddleBannersBlock);

    const filters = page.blocks?.find((el) => el.object === BlockType.FiltersBlock)
      ?.search_filters_group?.items;

    return {
      cardCollectionBlock,
      bigBannerBlock,
      middleBannerBlock,
      filters,
    };
  }, [page]);
};
