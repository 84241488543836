const keyCodes = {
  KEY_0: 48,
  KEY_9: 57,
  KEY_8: 56,
  KEY_7: 55,
  KEY_6: 54,
  KEY_5: 53,
  KEY_4: 52,
  KEY_3: 51,
  KEY_2: 50,
  KEY_1: 49,
  KEY_INFO: 73,
  KEY_RETURN: 8,
  KEY_ENTER: 13,

  KEY_LEFT: 37,
  KEY_UP: 38,
  KEY_RIGHT: 39,
  KEY_DOWN: 40,
  KEY_SEARCH: 83,
  KEY_MUTE: 77,

  KEY_VOL_DOWN: 68,
  KEY_VOL_UP: 85,

  KEY_STOP: 90,
  KEY_PLAY: 88,
  KEY_PAUSE: 80,

  KEY_EXIT: 81,

  KEY_RED: 82,
  KEY_GREEN: 71,
  KEY_YELLOW: 89,
  KEY_BLUE: 66,

  KEY_CH_UP: 84,
  KEY_CH_DOWN: 72,

  KEY_NEXT: 190,
  KEY_PREV: 188,
};

export default keyCodes;
