import { isArray } from 'lodash';
import { QueryKey, useQuery } from 'react-query';
import { QueryState } from 'react-query/types/core/query';

import { queryCache, queryClient } from '~global';
import { useClient } from '~global';
import { FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import ApiClient from '~lib/ApiClient';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import currentByStartAtAndEndAt from '~lib/currentByStartAtAndEndAt';
import { isInTheFuture } from '~lib/datetime-utils';
import CardMutation from '~typings/CardMutation';
import ResponseMany from '~typings/ResponseMany';


type CardMutationMany = ResponseMany<CardMutation>;

const PREFIX_OF_A_COMPOSITE_KEY = 'card_mutations';

const getKey = (cardIDs?: string[] | string, cardConfigID?: string): QueryKey => ([
  PREFIX_OF_A_COMPOSITE_KEY,
  (typeof cardIDs === 'string') ? cardIDs : (cardIDs || EMPTY_ARRAY).join(','),
  cardConfigID,
]);


const getCachedCardsMutations = (cardIDs: string[] = [], cardConfigID?: string) => (
  queryClient.getQueryData<CardMutationMany>(getKey(cardIDs, cardConfigID))
);

const getCachedCardMutations = (cardID?: string, cardConfigID?: string) => (
  queryClient.getQueryData<CardMutationMany>(getKey(cardID, cardConfigID))
);

const getCachedCardMutation = (cardID?: string, cardConfigID?: string): CardMutation | undefined => {
  if (!cardID) { return undefined; }

  // if (cardConfigID) {
  //   const cachedData = queryClient.getQueryData<ResponseMany<CardMutation>>(getKey(cardID, cardConfigID));
  //
  //   if (cachedData && cachedData?.data?.[0]) {
  //     return cachedData.data[0];
  //   }
  // } else {
    const queriesCardsMutations = queryCache.findAll([PREFIX_OF_A_COMPOSITE_KEY]);

    for (let i = 0; i < queriesCardsMutations.length; i += 1) {
      const cached = queriesCardsMutations[i].state as QueryState<CardMutationMany, any>;
      const item = ((cached?.data?.data || EMPTY_ARRAY) as CardMutation[])
        .find((item) => {
          if (cardConfigID) {
            const queryKeyardConfigID = queriesCardsMutations[i]?.queryKey[2];
            return (queryKeyardConfigID === cardConfigID && item.card_id === cardID);
          }
          return (item.card_id === cardID);
        });

      if (item) { return item; }
    }
  // }

  return undefined;
};


const removeCardMutations = (cardIDs?: string[] | string, cardConfigID?: string) => {
  if (cardIDs) {
    queryClient.removeQueries(getKey(cardIDs, cardConfigID));
  }
};


const useCardsMutations = (cardIDs: string[] = [], cardConfigID?: string) => {
  const queryKey = getKey(cardIDs, cardConfigID);
  const client = useClient();
  const fetchIDs: string[] = [];
  cardIDs.forEach((cardID) => {
    const cache = getCachedCardMutation(cardID, cardConfigID);

    if (!cache) {
      fetchIDs.push(cardID);
    }
  });

  return useQuery<CardMutationMany>({
    queryKey,
    queryFn: () => fetch(client, fetchIDs, cardConfigID),
    enabled: cardIDs.length !== 0,
  });
};

const fetch = async (client: ApiClient, cardID?: string | string[], cardConfigID?: string) => {
  const quarter = 1000 * 60 * 15;
  const date = Math.round(Date.now() / quarter) * quarter;
  const from = new Date(date).toISOString();
  // const hourInMS = 3600000;
  const halfHourInMS = 1800000;
  const to = new Date(date + halfHourInMS).toISOString();
  const query: any = {
    // 'filter[id_in]': cardID,
    'filter[from]': from,
    'filter[to]': to,
  };

  if (cardID) {
    query['filter[id_in]'] = (isArray(cardID)) ? cardID.join(',') : cardID;
  }

  if (cardConfigID) {
    query.card_config_id = cardConfigID;
  }

  return await client.get(
    fetchURLs[FetchType.CardMutations],
    query,
  );
};


const useCardMutations = (cardID?: string, cardConfigID?: string, canDeleteCache?: boolean) => {
  const queryKey = getKey(cardID, cardConfigID);
  const client = useClient();
  if (cardID && canDeleteCache) {
    const cacheData = getCachedCardMutation(cardID, cardConfigID);

    try {
      if (
        cacheData && !isInTheFuture(cacheData.states[0].end_at) &&
        !currentByStartAtAndEndAt(cacheData.states)
      ) {
        queryClient.removeQueries(queryKey);
      }
    } catch (ignore) {
      // ignore
    }
  }

  return useQuery<CardMutationMany>({
    queryKey,
    queryFn: () => fetch(client, cardID, cardConfigID),
    enabled: !!cardID,
  });
};


export {
  getCachedCardMutation,
  getCachedCardMutations,
  getCachedCardsMutations,
  getKey,
  removeCardMutations,
  useCardMutations,
};

export default useCardsMutations;
