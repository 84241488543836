import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import LightLoader from '~components/LightLoader';
import { ActionType as MenuActionType, useMenuAction } from '~components/Provider/Menu';
import TiledView from '~components/TiledView/component';
import { SEARCH_NOT_FOUND } from '~localization';
import { NavigationLine } from '~stores/LayerNavigation';
import useSideBarNavigation, { FOCUS_ON } from '~stores/SideBarNavigation';
import { DataItem, DataItemWithCard } from '~typings/DataItem';
import ImageType from '~typings/ImageType';
import ItemObject from '~typings/ItemObject';
import NavigationDirection from '~typings/NavigationDirection';
import ResponseMany from '~typings/ResponseMany';
import { PageNavigation } from '~ui/PageNavigation/PageNavigation';

import EditItemsMenu, { Delete } from '../EditItemsMenu/EditItemsMenu';
import { useAllowNavigation } from '../SideBarLayout/useAllowNavigation';
import * as styles from './styles.module.css';


export const PERSONAL_PAGE_ID = 'personal_page';

type Props = {
  layerID: string;
  items: ResponseMany<DataItemWithCard[]> | undefined;
  isLoading: boolean;
  onDelete: (arg: Delete, selectedItems: number[]) => void;
  onNavigationItemsReady: () => void;
}

const SLIDES_COUNT: [number, number] = [4, 3];

const PersonalPageEditWrapper = (props: Props) => {
  const focusOn = useSideBarNavigation(st => st.getFocusOn());
  const setFocusOn = useSideBarNavigation(st => st.setFocusOn);
  const editMode = useSideBarNavigation(st => st.getEditMode());
  const setEditMode = useSideBarNavigation(st => st.setEditMode);
  const { isMenuOpened, allowNavigation, } = useAllowNavigation();
  const menuAction = useMenuAction();
  const [selectedItems, setSelectedItems] = React.useState<number[]>([]);

  const navigationItems: NavigationLine[] = [
    { maxIndex: props.items?.data.length, isTiled: true, }
  ];

  const handleClick = React.useCallback((item?: DataItem) => {
    if (item?.object === ItemObject.Card) {
      const index = (props.items?.data || []).findIndex(itm => itm.card?.resource_id === item.resource_id);

      setSelectedItems(prev => prev.includes(index) ?
        prev.filter(x => x !== index)
        : index >= 0 ?
          [...prev, index]
          : prev
      );
    }
  }, [props.items?.data]);

  const handleDelete = React.useCallback((arg: Delete) => {
    props.onDelete(arg, selectedItems);
    setSelectedItems([]);
  }, [selectedItems]);

  const handleLeave = React.useCallback((direction: NavigationDirection) => {
    switch (direction) {
      case NavigationDirection.Up: {
        if (!editMode) {
          menuAction({
            type: MenuActionType.ChangeMenuState,
            isMenuOpened: !isMenuOpened,
          });
        }
        break;
      }
      case NavigationDirection.Left: {
        if (!editMode) {
          setFocusOn(FOCUS_ON.LEFT_MENU);
        }
        break;
      }
      case NavigationDirection.Right: {
        setFocusOn(FOCUS_ON.RIGHT_MENU);
        break;
      }
      default:
        break;
    }
  }, [isMenuOpened, editMode, props.items?.meta.pagination.total]);

  React.useEffect(() => {
    if (props.items?.meta.pagination.total) {
      props.onNavigationItemsReady();
    }

    if (!props.isLoading && props.items?.meta.pagination.total === 0) {
      setEditMode(false);
    }
  }, [props.layerID, props.isLoading, props.items?.meta.pagination.total]);


  return (
    <>
      {
        props.items?.meta.pagination.total !== 0
          ? <EditItemsMenu
            selectedItemsCount={ selectedItems.length }
            resetSelection={ () => setSelectedItems([]) }
            onDelete={ handleDelete }
          />
          : null
      }
      <PageNavigation
        layerID={ props.layerID }
        isAllowedNavigation={ allowNavigation && focusOn === FOCUS_ON.CONTENT }
        navigationItems={ navigationItems }
        dimension={ ImageType.BANNER }
        slidesCount={ SLIDES_COUNT }
        onLeave={ handleLeave }
        isTiledView
      >
        {
          (focusPosition) => (
            <>
              {
                (props.items?.meta.pagination.total !== 0)
                  ? (<TiledView
                    focusedItemIndex={ focusPosition.focusedIndex[0] }
                    allowNavigation={ allowNavigation && focusOn === FOCUS_ON.CONTENT }
                    dimension={ ImageType.BANNER }
                    items={ props.items }
                    slidesCount={ SLIDES_COUNT }
                    editMode={ editMode }
                    selectedItems={ selectedItems }
                    onClick={ handleClick }
                    showOnlyFetchedItems
                  />)
                  : null
              }
              {
                props.isLoading || !props.items
                  ? <LightLoader/>
                  : props.items.meta.pagination.total === 0
                    ? <div className={ styles.searchNotFoundWrapper }>
                        <FormattedMessage id={ SEARCH_NOT_FOUND }/>
                      </div>
                    : null
              }
            </>
          )
        }
      </PageNavigation>
    </>
  );
};


export default React.memo(PersonalPageEditWrapper);
