import cn from "classnames";
import { uniqBy } from 'lodash';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import shallow from 'zustand/shallow';

import { BlockTitle } from "~components/BlockTitle";
import { Grid } from "~components/Grid/grid";
import CatchBack from '~components/PageManager/CatchBack';
import { ActionType as MenuActionType, useMenu, useMenuAction } from '~components/Provider/Menu';
import { KeyBoard } from "~components/SearchPage/keyBoard/keyboard";
import { SearchFilters } from "~components/SearchPage/SearchFilters";
import { SearchInput } from "~components/SearchPage/SearchInput";
import { SuggestionBlock } from '~components/SearchPage/suggestions/SuggestionBlock';
import useCardCollection from "~hooks/fetch/useCardCollection";
import { useInfinityCardColItems } from "~hooks/fetch/useCardCollectionsItems";
import { useInfinityRecommendations } from "~hooks/fetch/useRecommendations/useRecommendations";
import { useInfinitySearch, useSearchFiltersGroupItems, useSuggestions } from "~hooks/fetch/useSearch";
import { getCardCollectionDimension } from "~lib/Dimension";
import { setPopupState } from '~lib/PopupState';
import * as URLSearchManager from '~lib/URLQueryStringManager';
import URLVariableState from '~lib/URLVariableState';
import { RECOMMENDED_FOR_YOU, SEARCH_NO_RESULTS, SEARCH_SPECIFY_REQUEST_EXPAND } from '~localization';
import { SpinnerSearch } from "~newapp/globalBlocks/spinner";
import useFilters from '~stores/Filters';
import { NewLayerNavigation } from "~stores/LayerNavigation/newLayerNavigation";
import usePointer from "~stores/Pointer";
import ImageType from "~typings/ImageType";
import NavigationDirection from "~typings/NavigationDirection";
import Page from "~typings/Page";

import { KeyBoardLayout, KeyBoardType } from "./keyBoard/keyboardLayouts/type0";
import * as styles from './styles.module.css';

export const ID = 'search';

type Props = {
  intl: InjectedIntl;
  page: Page;
};

export type DIR = [boolean, boolean, boolean, boolean]

interface currentNavBlockTS {
  current: null | SearchPageBlocks,
  leftPrevious: null | SearchPageBlocks,
  rightPrevious: null | SearchPageBlocks
}

export enum SearchPageBlocks {
  SearchGrid = 'searchGrid',
  ExpandGrid = 'expandGrid',
  RecommendGrid = 'recommendGrid',
  SearchFilters = 'searchFilters',
  Keyboard = 'keyboard',
  Suggestions = 'suggestions',
}

const SearchPage = (props: Props) => {
  const { page } = props
  const history = useHistory();
  const menuAction = useMenuAction();
  const { isMenuOpened, isPopupOpened } = useMenu();

  //HELPER VARS
  const searchTypes = page.resource_types.join()
  const isNeedToFetchExpandBlock = Boolean(page.blocks?.[0]);
  const expandBlockId = page.blocks?.[0]?.card_collection?.id || '';
  const isAllowedNavigation = !isMenuOpened && !isPopupOpened
  //

  // STATE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentNavBlock, setCurrentNavBlock] = useState<currentNavBlockTS>({
    current: SearchPageBlocks.Keyboard,
    leftPrevious: null,
    rightPrevious: null
  })
  const [keyboardDirections, setKeyboardDirections] = useState<DIR>([true, true, true, false])

  const [expandDirections, setExpandDirections] = useState<DIR>([false, false, false, false])

  const [recommendDirections, setRecommendDirections] = useState<DIR>([false, false, false, false])

  const [searchDirections, setSearchDirections] = useState<DIR>([false, false, false, false])

  const [suggestionPosition, setSuggestionPosition] = useState(0)
  const [filtersPosition, setFiltersPosition] = useState(0)

  const [query, setQuery] = React.useState<string>(() =>
    decodeURI(URLVariableState<string>(history, 'query', '').value()));
  const setFocusHistory = NewLayerNavigation(state => state.setFocusHistory)
  const resetSelectedFilters = useFilters(state => state.resetFilters);

  const [isSearchFetchEnabled, setIsSearchFetchEnabled] = useState(false);

  const updateQueryFromKeyboard = (newQuery: string) => {
    setQuery(newQuery);
    setIsSearchFetchEnabled(false);
    resetSelectedFilters();
    setSuggestionPosition(0);
  };

  const updateQueryFromSuggestion = (newQuery: string) => {
    setCurrentNavBlock({ ...currentNavBlock, current: SearchPageBlocks.Keyboard });
    setQuery(newQuery);
    resetSelectedFilters();
    setSuggestionPosition(0);
    setIsSearchFetchEnabled(true);
    setFocusHistory(props.page.id, SearchPageBlocks.SearchGrid, {
      focusedElementIndex: 0,
      focusedLineIndex: 0,
      focusedElementPossibleMove: [false, false, false, false],
    });
  };


  const selectedFilters = useFilters(state => ({
    quickFilters: state.quickFilters?.[ID],
    dropdownFilters: state.dropdownFilters?.[ID],
  }), shallow);
  const updateDirections = usePointer(state => state.updateDirections);
  //


  //EXPAND
  const { data: expandBlockData } = useCardCollection(expandBlockId, isNeedToFetchExpandBlock);
  const { query: cardColItemsQuery, parsedData: expandBlockDataCCI, isExist: expandBlockExist } =
    useInfinityCardColItems({
      cardCollectionID: expandBlockId,
      enabled: isNeedToFetchExpandBlock
    });

  const fetchMoreCardColItems = () => {
    if (cardColItemsQuery.hasNextPage && !cardColItemsQuery.isFetchingNextPage) {
      cardColItemsQuery.fetchNextPage()
    }
  }

  const expandBlockDimension = expandBlockData?.data
    ? getCardCollectionDimension(expandBlockData.data)
    : ImageType.BANNER;
  const expandBlockItemsInLine = expandBlockDimension === ImageType.BANNER ? 4 : 6
  //

  // Рекомендации загружаем только тогда, когда нет expand блока
  const needToFetchRecommendations = !isNeedToFetchExpandBlock || (!cardColItemsQuery.isLoading && !expandBlockExist);

  //RECOMMEND
  const {
    query: recommendQuery,
    parsedData: recommendData,
    isExist: isRecommendationsExist,
  } = useInfinityRecommendations({
    enabled: needToFetchRecommendations
  });

  const fetchMoreRecommendations = () => {
    if (recommendQuery.hasNextPage && !recommendQuery.isFetchingNextPage) {
      recommendQuery.fetchNextPage()
    }
  }
  //


  //FILTERS
  const { data: searchFilters } = useSearchFiltersGroupItems();
  const isFiltersActive = (selectedFilters.quickFilters && selectedFilters.quickFilters?.length > 0)
    || (selectedFilters.dropdownFilters && selectedFilters.dropdownFilters?.length > 0)
  const filtersExist = searchFilters && searchFilters.length > 0
  //


  //SUGGESTIONS
  const { data: suggestions, isLoading: isLoadingSuggestions } = useSuggestions(query, searchTypes);
  const uniqSuggestions = useMemo(
    () => {
      if (query.length === 0) {
        return []
      }
      return uniqBy<{ value: string }>(suggestions, 'value')
        .filter(s => (s.value.toLowerCase() !== query.toLowerCase()))
        .slice(0, 4)
    },
    [suggestions, query]
  );
  //


  //SEARCH RESULT
  const {
    query: infinitySearchReactQuery,
    parsedData: infinitySearchParsedData
  } = useInfinitySearch({
    searchQuery: query,
    filters: selectedFilters,
    searchPageResourceTypes: searchTypes,
    enabled: isSearchFetchEnabled
  });

  // Если поиск отключен, то не показываем searchItems
  const searchItems = isSearchFetchEnabled ? infinitySearchParsedData : undefined;

  const {
    isLoading: isLoadingSearchResult,
  } = infinitySearchReactQuery;

  const fetchMoreSearchItems = () => {
    if (infinitySearchReactQuery.hasNextPage && !infinitySearchReactQuery.isFetchingNextPage) {
      infinitySearchReactQuery.fetchNextPage();
    }
  };
  //


  // HELPER VARS
  const focusOnRightBlock = currentNavBlock.current === SearchPageBlocks.SearchFilters
    || currentNavBlock.current === SearchPageBlocks.ExpandGrid
    || currentNavBlock.current === SearchPageBlocks.RecommendGrid
    || currentNavBlock.current === SearchPageBlocks.SearchGrid

  const rightBlockExist =
    searchItems === undefined
      ? expandBlockExist
        ? true
        : isRecommendationsExist
      : searchItems.data.length === 0
        ? (isFiltersActive || expandBlockExist || isRecommendationsExist)
        : true

  const notLoading = !isLoadingSearchResult && !recommendQuery.isLoading && !cardColItemsQuery.isLoading
  //


  //FUNCTIONS
  const handleBack = () => {
    if (isPopupOpened) {
      setPopupState(history, null);
      return;
    }
    else {
      menuAction({
        type: MenuActionType.ChangeMenuState,
        isMenuOpened: true,
      });
    }
  }

  function switchNavigationBlock(toDirection: NavigationDirection, fromBlock: SearchPageBlocks) {
    if (fromBlock === SearchPageBlocks.Keyboard && toDirection === NavigationDirection.Down) {
      if (uniqSuggestions.length !== 0) {
        setCurrentNavBlock({ ...currentNavBlock, current: SearchPageBlocks.Suggestions });
      }
      return;
    }


    if (fromBlock === SearchPageBlocks.Keyboard && toDirection === NavigationDirection.Right) {
      if (rightBlockExist && notLoading) {
        switchToRightBlock()
      }
      return;
    }

    if (fromBlock === SearchPageBlocks.Keyboard && toDirection === NavigationDirection.Up) {
      handleBack();
      return;
    }

    if (fromBlock === SearchPageBlocks.Suggestions && toDirection === NavigationDirection.Up) {
      setCurrentNavBlock({ ...currentNavBlock, current: SearchPageBlocks.Keyboard });
      return;
    }


    if (fromBlock === SearchPageBlocks.Suggestions && toDirection === NavigationDirection.Right) {
      if (rightBlockExist && notLoading) {
        switchToRightBlock()
      }
      return;
    }

    if (fromBlock === SearchPageBlocks.SearchFilters && toDirection === NavigationDirection.Up) {
      handleBack()
      return
    }

    if (fromBlock === SearchPageBlocks.SearchFilters && toDirection === NavigationDirection.Down) {
      if (searchItems !== undefined && searchItems.data.length === 0) {
        if (expandBlockExist) {
          setCurrentNavBlock({ ...currentNavBlock, current: SearchPageBlocks.ExpandGrid })
        }
        else if (isRecommendationsExist) {
          setCurrentNavBlock({ ...currentNavBlock, current: SearchPageBlocks.RecommendGrid })
        }
      }
      else {
        setCurrentNavBlock({ ...currentNavBlock, current: SearchPageBlocks.SearchGrid })
      }

      return;
    }

    if (fromBlock === SearchPageBlocks.SearchFilters && toDirection === NavigationDirection.Left) {
      switchToLeftBlock()
    }

    if (
      [
        SearchPageBlocks.SearchGrid,
        SearchPageBlocks.ExpandGrid,
        SearchPageBlocks.RecommendGrid,
      ].includes(fromBlock) &&
      toDirection === NavigationDirection.Up
    ) {
      if (searchItems && (isFiltersActive || filtersExist)) {
        setCurrentNavBlock({ ...currentNavBlock, current: SearchPageBlocks.SearchFilters });
      }
      else {
        handleBack();
        return;
      }
    }

    if (
      [
        SearchPageBlocks.SearchGrid,
        SearchPageBlocks.ExpandGrid,
        SearchPageBlocks.RecommendGrid,
      ].includes(fromBlock) &&
      toDirection === NavigationDirection.Left
    ) {
      switchToLeftBlock();
    }
  }

  function switchToRightBlock() {
    if (!searchItems) {
      if (expandBlockExist) {
        setCurrentNavBlock({
          ...currentNavBlock,
          leftPrevious: currentNavBlock.current,
          current: SearchPageBlocks.ExpandGrid
        })
      }
      else if (isRecommendationsExist) {
        setCurrentNavBlock({
          ...currentNavBlock,
          leftPrevious: currentNavBlock.current,
          current: SearchPageBlocks.RecommendGrid
        })
      }
    }
    else if (searchItems.data.length !== 0) {
      if (isFiltersActive || filtersExist) {
        setCurrentNavBlock({
          ...currentNavBlock,
          leftPrevious: currentNavBlock.current,
          current: SearchPageBlocks.SearchFilters
        })
      }
      else {
        setCurrentNavBlock({
          ...currentNavBlock,
          leftPrevious: currentNavBlock.current,
          current: SearchPageBlocks.SearchGrid
        })
      }
    }
    else if (searchItems.data.length === 0) {
      if (isFiltersActive) {
        setCurrentNavBlock({
          ...currentNavBlock,
          leftPrevious: currentNavBlock.current,
          current: SearchPageBlocks.SearchFilters
        })
      }
      else {
        if (expandBlockExist) {
          setCurrentNavBlock({
            ...currentNavBlock,
            leftPrevious: currentNavBlock.current,
            current: SearchPageBlocks.ExpandGrid
          })
        }
        else if (isRecommendationsExist) {
          setCurrentNavBlock({
            ...currentNavBlock,
            leftPrevious: currentNavBlock.current,
            current: SearchPageBlocks.RecommendGrid
          })
        }
      }
    }
  }

  function switchToLeftBlock() {
    if (currentNavBlock.leftPrevious) {
      setCurrentNavBlock({ ...currentNavBlock, current: currentNavBlock.leftPrevious })
    }
    else {
      setCurrentNavBlock({ ...currentNavBlock, current: SearchPageBlocks.Keyboard })
    }
  }

  function enableSearchFetchAndResetGrid() {
    if (!query.length) {
      return;
    }
    setIsSearchFetchEnabled(true);
    setFocusHistory(props.page.id, SearchPageBlocks.SearchGrid, {
      focusedElementIndex: 0,
      focusedLineIndex: 0,
      focusedElementPossibleMove: [false, false, false, false],
    });
  }

  useEffect(() => {
    if (isFiltersActive) {
      enableSearchFetchAndResetGrid()
    }
    else {
      setFocusHistory(page.id, SearchPageBlocks.SearchGrid, {
        focusedElementIndex: 0,
        focusedLineIndex: 0,
        totalElements: searchItems?.meta.pagination.total || 0,
      })
    }
  }, [selectedFilters])


  useEffect(() => {
    const historyQuery = decodeURI(
      URLSearchManager.getField(history.location.search, 'query')
    );
    if (historyQuery.toLowerCase() !== query.toLowerCase()) {
      URLSearchManager.set({ query: encodeURI(query.toLowerCase()) }, history);
    }
  }, [query]);

  useEffect(() => {
    // здесь логика установки UI стрелочек для pointer navigation
    // дочерний компонент передает сюда данные о своих возможных внутренних навигациях
    // на основе этой даты, здесь учитывая другие блоки устанавливаем pointer navigation
    if (currentNavBlock.current === SearchPageBlocks.Keyboard) {
      if (!keyboardDirections[0] && !keyboardDirections[1]) {
        if (rightBlockExist) {
          let test = [...keyboardDirections]
          test[1] = true
          test[0] = true
          updateDirections({
            [NavigationDirection.Up]: test[0],
            [NavigationDirection.Right]: test[1],
            [NavigationDirection.Down]: test[2],
            [NavigationDirection.Left]: test[3]
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: keyboardDirections[1],
            [NavigationDirection.Down]: keyboardDirections[2],
            [NavigationDirection.Left]: keyboardDirections[3]
          })
        }

      }
      else if ((!keyboardDirections[0] && !keyboardDirections[3]) || !keyboardDirections[0]) {
        let test = [...keyboardDirections]
        test[0] = true
        updateDirections({
          [NavigationDirection.Up]: test[0],
          [NavigationDirection.Right]: test[1],
          [NavigationDirection.Down]: test[2],
          [NavigationDirection.Left]: test[3]
        })
      }
      else if (!keyboardDirections[1] && !keyboardDirections[2]) {
        if (uniqSuggestions.length !== 0) {
          if (rightBlockExist) {
            let test = [...keyboardDirections]
            test[1] = true
            test[2] = true
            updateDirections({
              [NavigationDirection.Up]: test[0],
              [NavigationDirection.Right]: test[1],
              [NavigationDirection.Down]: test[2],
              [NavigationDirection.Left]: test[3]
            })
          }
          else {
            let test = [...keyboardDirections]
            test[2] = true
            updateDirections({
              [NavigationDirection.Up]: test[0],
              [NavigationDirection.Right]: test[1],
              [NavigationDirection.Down]: test[2],
              [NavigationDirection.Left]: test[3]
            })
          }
        }
        else {
          if (rightBlockExist) {
            let test = [...keyboardDirections]
            test[1] = true
            updateDirections({
              [NavigationDirection.Up]: test[0],
              [NavigationDirection.Right]: test[1],
              [NavigationDirection.Down]: test[2],
              [NavigationDirection.Left]: test[3]
            })
          }
          else {
            updateDirections({
              [NavigationDirection.Up]: keyboardDirections[0],
              [NavigationDirection.Right]: keyboardDirections[1],
              [NavigationDirection.Down]: keyboardDirections[2],
              [NavigationDirection.Left]: keyboardDirections[3]
            })
          }
        }
      }
      else if (!keyboardDirections[1]) {
        if (rightBlockExist) {
          let test = [...keyboardDirections]
          test[1] = true
          updateDirections({
            [NavigationDirection.Up]: test[0],
            [NavigationDirection.Right]: test[1],
            [NavigationDirection.Down]: test[2],
            [NavigationDirection.Left]: test[3]
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: keyboardDirections[0],
            [NavigationDirection.Right]: keyboardDirections[1],
            [NavigationDirection.Down]: keyboardDirections[2],
            [NavigationDirection.Left]: keyboardDirections[3]
          })
        }
      }
      else if (!keyboardDirections[2] && !keyboardDirections[3]) {
        if (uniqSuggestions.length > 0) {
          let test = [...keyboardDirections]
          test[2] = true
          updateDirections({
            [NavigationDirection.Up]: test[0],
            [NavigationDirection.Right]: test[1],
            [NavigationDirection.Down]: test[2],
            [NavigationDirection.Left]: test[3]
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: keyboardDirections[0],
            [NavigationDirection.Right]: keyboardDirections[1],
            [NavigationDirection.Down]: keyboardDirections[2],
            [NavigationDirection.Left]: keyboardDirections[3]
          })
        }
      }
      else if (!keyboardDirections[2]) {
        if (uniqSuggestions.length > 0) {
          let test = [...keyboardDirections]
          test[2] = true
          updateDirections({
            [NavigationDirection.Up]: test[0],
            [NavigationDirection.Right]: test[1],
            [NavigationDirection.Down]: test[2],
            [NavigationDirection.Left]: test[3]
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: keyboardDirections[0],
            [NavigationDirection.Right]: keyboardDirections[1],
            [NavigationDirection.Down]: keyboardDirections[2],
            [NavigationDirection.Left]: keyboardDirections[3]
          })
        }
      }
      else {
        updateDirections({
          [NavigationDirection.Up]: keyboardDirections[0],
          [NavigationDirection.Right]: keyboardDirections[1],
          [NavigationDirection.Down]: keyboardDirections[2],
          [NavigationDirection.Left]: keyboardDirections[3]
        })
      }
    }

    else if (currentNavBlock.current === SearchPageBlocks.Suggestions) {
      if (suggestionPosition === uniqSuggestions.length - 1) {
        if (rightBlockExist) {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: true,
            [NavigationDirection.Down]: false,
            [NavigationDirection.Left]: false
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: false,
            [NavigationDirection.Down]: false,
            [NavigationDirection.Left]: false
          })
        }
      }
      else {
        if (rightBlockExist) {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: true,
            [NavigationDirection.Down]: true,
            [NavigationDirection.Left]: false
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: false,
            [NavigationDirection.Down]: true,
            [NavigationDirection.Left]: false
          })
        }
      }
    }

    else if (currentNavBlock.current === SearchPageBlocks.SearchFilters) {
      if ((isFiltersActive && searchFilters && filtersExist && filtersPosition === searchFilters.length)
        || (!isFiltersActive && searchFilters && filtersExist && filtersPosition === searchFilters.length - 1)) {
        if (isRecommendationsExist || expandBlockExist || searchItems?.data.length !== 0) {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: false,
            [NavigationDirection.Down]: true,
            [NavigationDirection.Left]: true
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: false,
            [NavigationDirection.Down]: false,
            [NavigationDirection.Left]: true
          })
        }
      }
      else {
        if (isRecommendationsExist || expandBlockExist || searchItems?.data.length !== 0) {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: true,
            [NavigationDirection.Down]: true,
            [NavigationDirection.Left]: true
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: true,
            [NavigationDirection.Down]: false,
            [NavigationDirection.Left]: true
          })
        }
      }
    }

    else if (currentNavBlock.current === SearchPageBlocks.ExpandGrid) {
      if (!expandDirections[0] && !expandDirections[3]) {
        let expand = [...expandDirections]

        if (isFiltersActive) {
          expand[0] = true
          expand[3] = true
          updateDirections({
            [NavigationDirection.Up]: expand[0],
            [NavigationDirection.Right]: expand[1],
            [NavigationDirection.Down]: expand[2],
            [NavigationDirection.Left]: expand[3]
          })
        }
        else {
          let expand = [...expandDirections]
          expand[3] = true
          expand[0] = true
          updateDirections({
            [NavigationDirection.Up]: expand[0],
            [NavigationDirection.Right]: expand[1],
            [NavigationDirection.Down]: expand[2],
            [NavigationDirection.Left]: expand[3]
          })
        }

      }
      else if (!expandDirections[3]) {
        let expand = [...expandDirections]
        expand[3] = true
        updateDirections({
          [NavigationDirection.Up]: expand[0],
          [NavigationDirection.Right]: expand[1],
          [NavigationDirection.Down]: expand[2],
          [NavigationDirection.Left]: expand[3]
        })
      }
      else if (!expandDirections[0]) {
        let expand = [...expandDirections]
        if (isFiltersActive) {
          expand[0] = true
          updateDirections({
            [NavigationDirection.Up]: expand[0],
            [NavigationDirection.Right]: expand[1],
            [NavigationDirection.Down]: expand[2],
            [NavigationDirection.Left]: expand[3]
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: expandDirections[1],
            [NavigationDirection.Down]: expandDirections[2],
            [NavigationDirection.Left]: expandDirections[3]
          })
        }
      }
      else {
        updateDirections({
          [NavigationDirection.Up]: expandDirections[0],
          [NavigationDirection.Right]: expandDirections[1],
          [NavigationDirection.Down]: expandDirections[2],
          [NavigationDirection.Left]: expandDirections[3]
        })
      }
    }

    else if (currentNavBlock.current === SearchPageBlocks.RecommendGrid) {
      if (!recommendDirections[0] && !recommendDirections[3]) {
        let recommend = [...recommendDirections]

        if (isFiltersActive) {
          recommend[0] = true
          recommend[3] = true
          updateDirections({
            [NavigationDirection.Up]: recommend[0],
            [NavigationDirection.Right]: recommend[1],
            [NavigationDirection.Down]: recommend[2],
            [NavigationDirection.Left]: recommend[3]
          })
        }
        else {
          let recommend = [...recommendDirections]
          recommend[3] = true
          recommend[0] = true
          updateDirections({
            [NavigationDirection.Up]: recommend[0],
            [NavigationDirection.Right]: recommend[1],
            [NavigationDirection.Down]: recommend[2],
            [NavigationDirection.Left]: recommend[3]
          })
        }

      }
      else if (!recommendDirections[3]) {
        let recommend = [...recommendDirections]
        recommend[3] = true
        updateDirections({
          [NavigationDirection.Up]: recommend[0],
          [NavigationDirection.Right]: recommend[1],
          [NavigationDirection.Down]: recommend[2],
          [NavigationDirection.Left]: recommend[3]
        })
      }
      else if (!recommendDirections[0]) {
        let recommend = [...recommendDirections]
        if (isFiltersActive) {
          recommend[0] = true
          updateDirections({
            [NavigationDirection.Up]: recommend[0],
            [NavigationDirection.Right]: recommend[1],
            [NavigationDirection.Down]: recommend[2],
            [NavigationDirection.Left]: recommend[3]
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: true,
            [NavigationDirection.Right]: recommendDirections[1],
            [NavigationDirection.Down]: recommendDirections[2],
            [NavigationDirection.Left]: recommendDirections[3]
          })
        }
      }
      else {
        updateDirections({
          [NavigationDirection.Up]: recommendDirections[0],
          [NavigationDirection.Right]: recommendDirections[1],
          [NavigationDirection.Down]: recommendDirections[2],
          [NavigationDirection.Left]: recommendDirections[3]
        })
      }
    }

    else if (currentNavBlock.current === SearchPageBlocks.SearchGrid) {
      if (!searchDirections[0] && !searchDirections[3]) {
        let search = [...searchDirections]

        if (isFiltersActive || filtersExist) {
          search[0] = true
          search[3] = true
          updateDirections({
            [NavigationDirection.Up]: search[0],
            [NavigationDirection.Right]: search[1],
            [NavigationDirection.Down]: search[2],
            [NavigationDirection.Left]: search[3]
          })
        }
        else {
          let search = [...searchDirections]
          search[3] = true
          updateDirections({
            [NavigationDirection.Up]: search[0],
            [NavigationDirection.Right]: search[1],
            [NavigationDirection.Down]: search[2],
            [NavigationDirection.Left]: search[3]
          })
        }

      }
      else if (!searchDirections[3]) {
        let search = [...searchDirections]
        search[3] = true
        updateDirections({
          [NavigationDirection.Up]: search[0],
          [NavigationDirection.Right]: search[1],
          [NavigationDirection.Down]: search[2],
          [NavigationDirection.Left]: search[3]
        })
      }
      else if (!searchDirections[0]) {
        let search = [...searchDirections]
        if (isFiltersActive || filtersExist) {
          search[0] = true
          updateDirections({
            [NavigationDirection.Up]: search[0],
            [NavigationDirection.Right]: search[1],
            [NavigationDirection.Down]: search[2],
            [NavigationDirection.Left]: search[3]
          })
        }
        else {
          updateDirections({
            [NavigationDirection.Up]: searchDirections[0],
            [NavigationDirection.Right]: searchDirections[1],
            [NavigationDirection.Down]: searchDirections[2],
            [NavigationDirection.Left]: searchDirections[3]
          })
        }
      }
      else {
        updateDirections({
          [NavigationDirection.Up]: searchDirections[0],
          [NavigationDirection.Right]: searchDirections[1],
          [NavigationDirection.Down]: searchDirections[2],
          [NavigationDirection.Left]: searchDirections[3]
        })
      }
    }
  }, [currentNavBlock,
    keyboardDirections,
    uniqSuggestions,
    suggestionPosition,
    filtersPosition,
    expandDirections,
    recommendDirections,
    searchDirections])

  const renderKeyboardWithSuggestions = () => (
    <>
      <KeyBoard
        keyBoardType={ KeyBoardType.type0 }
        keyBoardLayout={ KeyBoardLayout.Ru }
        isFocused={ isAllowedNavigation && currentNavBlock.current === SearchPageBlocks.Keyboard }
        query={ query }
        setQuery={ updateQueryFromKeyboard }
        switchNavigationBlock={ switchNavigationBlock }
        setKeyboardDirections={ setKeyboardDirections }
        handleSearchButtonClick={ enableSearchFetchAndResetGrid }
      />
      <div className={ styles.suggestionsMargin }>
        <SuggestionBlock
          isFocused={
            isAllowedNavigation && currentNavBlock.current === SearchPageBlocks.Suggestions
          }
          isLoadingSuggestions={ isLoadingSuggestions }
          query={ query }
          uniqSuggestions={ uniqSuggestions }
          setQuery={ updateQueryFromSuggestion }
          switchNavigationBlock={ switchNavigationBlock }
          setSuggestionPosition={ setSuggestionPosition }
        />
      </div>
    </>

  );
  const renderExpandBlock = () => (
    <div>
      <BlockTitle
        searchPageExpand={ true }
        isVisible
        isFadeOut={ false }
      >
        { expandBlockData?.data.name }
      </BlockTitle>
      <div className={ styles.grid2 }>
        <Grid
          items={ expandBlockDataCCI }
          itemsInLine={ expandBlockItemsInLine }
          dimension={ expandBlockDimension }
          allowNavigation={
            isAllowedNavigation && currentNavBlock.current === SearchPageBlocks.ExpandGrid
          }
          isFocused={ isAllowedNavigation && currentNavBlock.current === SearchPageBlocks.ExpandGrid }
          switchNavigationBlock={ switchNavigationBlock }
          setGridDirections={ setExpandDirections }
          onNeedMoreData={ fetchMoreCardColItems }
          pageId={ page.id }
          gridId={ SearchPageBlocks.ExpandGrid }
        />
      </div>
    </div>
  );
  const renderRecommendations = () => (

    <div>
      <BlockTitle
        searchPageExpand={ true }
        isVisible
        isFadeOut={ false }
      >
        <FormattedMessage id={ RECOMMENDED_FOR_YOU } />
      </BlockTitle>
      <div className={ styles.grid2 }>
        <Grid
          items={ recommendData }
          itemsInLine={ 4 }
          dimension={ ImageType.BANNER }
          allowNavigation={ isAllowedNavigation && currentNavBlock.current === SearchPageBlocks.RecommendGrid }
          isFocused={ isAllowedNavigation && currentNavBlock.current === SearchPageBlocks.RecommendGrid }
          switchNavigationBlock={ switchNavigationBlock }
          setGridDirections={ setRecommendDirections }
          onNeedMoreData={ fetchMoreRecommendations }
          pageId={ page.id }
          gridId={ SearchPageBlocks.RecommendGrid }
        />
      </div>
    </div>
  );
  const renderSearchResultWithFilters = () => (
    <>
      { filtersExist && (
        <div className={ styles.marginFilters2 }>
          <SearchFilters
            searchFilters={ searchFilters }
            isFocused={
              isAllowedNavigation && currentNavBlock.current === SearchPageBlocks.SearchFilters
            }
            isFiltersActive={ isFiltersActive }
            switchNavigationBlock={ switchNavigationBlock }
            setFiltersPosition={ setFiltersPosition }
          />
        </div>
      ) }
      <div className={ styles.sliderWrapper }>
        <div className={ styles.grid2 }>
          <Grid
            items={ searchItems }
            itemsInLine={ 4 }
            dimension={ ImageType.BANNER }
            allowNavigation={
              isAllowedNavigation && currentNavBlock.current === SearchPageBlocks.SearchGrid
            }
            isFocused={
              isAllowedNavigation && currentNavBlock.current === SearchPageBlocks.SearchGrid
            }
            switchNavigationBlock={ switchNavigationBlock }
            setGridDirections={ setSearchDirections }
            onNeedMoreData={ fetchMoreSearchItems }
            pageId={ page.id }
            gridId={ SearchPageBlocks.SearchGrid }
          />
        </div>
      </div>
    </>
  );
  const renderExpandBlockOrRecommendations = () => {
    if (expandBlockExist) {
      return renderExpandBlock();
    }

    if (isRecommendationsExist) {
      return renderRecommendations();
    }

    return null;
  };
  const renderLoader = () => (
    <div className={ !focusOnRightBlock ? styles.loaderWrapper : styles.loaderWrapperFull }>
      <SpinnerSearch />
    </div>
  );
  const renderFiltersAndNoResults = () => (
    <>
      { isFiltersActive && (
        <div className={ styles.marginFilters }>
          <SearchFilters
            searchFilters={ searchFilters }
            isFocused={
              isAllowedNavigation && currentNavBlock.current === SearchPageBlocks.SearchFilters
            }
            isFiltersActive={ isFiltersActive }
            switchNavigationBlock={ switchNavigationBlock }
            setFiltersPosition={ setFiltersPosition }
          />
        </div>
      ) }
      <div className={ styles.noResults }>
        <div>{ props.intl.formatMessage({ id: SEARCH_NO_RESULTS }) }</div>
        <div>
          { props.intl.formatMessage(
            { id: SEARCH_SPECIFY_REQUEST_EXPAND },
            {
              collectionName: expandBlockData?.data.name,
            },
          ) }
        </div>
      </div>
    </>
  );
  const renderRightBlockContent = () => {
    if (isLoadingSearchResult) {
      return renderLoader();
    }

    if (searchItems === undefined) {
      return renderExpandBlockOrRecommendations();
    }

    if (searchItems.data.length === 0) {
      return (
        <>
          { renderFiltersAndNoResults() }
          { renderExpandBlockOrRecommendations() }
        </>
      );
    }


    return (
      renderSearchResultWithFilters()
    );
  };

  return (
    <>
      <CatchBack
        action={ handleBack }
        triggers={ [handleBack] }
      />
      <div className={ styles.inputWithContent }>
        <div className={ styles.inputWrapper }>
          <SearchInput text={ query } />
        </div>
        <div className={ styles.containerRow }>
          <div className={ cn(styles.leftBlock, focusOnRightBlock ? styles.animLeft : styles.animDefault) }>
            { renderKeyboardWithSuggestions() }
          </div>
          <div className={ cn(styles.rightBlock, focusOnRightBlock ? styles.animRight : styles.animDefault) }>
            <div className={ styles.container }>{ renderRightBlockContent() }</div>
          </div>
        </div>
      </div>
    </>
  );
};


export default injectIntl(SearchPage);
