import { ApiPeriodUnit } from "~typings/Period";

/**
 * Функция @pluralize принимает в себя число и строки. 
 * Нужна для того, чтобы корректно определить склонение, в зависимости от приходящего числа
 * @param {number} i - число дней | часов | минут
 * @param {string} str - строка со склонением
 * 
 * Пример: pluralize(10, 'день', 'дня', 'дней')
 * Вернёт: 10 дней
*/
function pluralize (i:number, str1:string, str2:string, str3:string) {
  switch (plural(i)) {
    case 0:
      return `${i} ${str1}`;
    case 1:
      return `${i} ${str2}`;
    default:
      return `${i} ${str3}`;
  }
}

function plural (a) {
  if (a % 10 === 1 && a % 100 !== 11) {
    return 0;
  } else if (a % 10 >= 2 && a % 10 <= 4 && (a % 100 < 10 || a % 100 >= 20)) {
    return 1;
  } else {
    return 2;
  }
}

/** 
 * Функция @getFullFormattedTime принимает в себя строку с типом времени и число с кол-вом времени
 * @param {string} time - тип времени minutes | hours и тд
 * @param {number} number - число дней | часов | минут
 * 
 * Пример: getFullFormattedTime('days', 3)
 * Вернёт: 3 дня
*/
export const getFullFormattedTime = (
  time: ApiPeriodUnit | undefined, 
  number: number
) => {
  if(time === 'days') {
    return pluralize (number, 'день', 'дня', 'дней');
  } else if (time === 'months') {
    return pluralize (number, 'месяц', 'месяца', 'месяцев');
  } else if (time === 'hours') {
    return pluralize (number, 'час', 'часа', 'часов');
  } else if (time === 'minutes') {
    return pluralize (number, 'минуту', 'минуты', 'минут');
  } else if (time === 'unlimited') {
    return 'неограниченное кол-во времени'
  } 
  return null;
}

export const getFormattedTimeExceptions = (
  time: ApiPeriodUnit | undefined, 
  number: number
) => {
  if(time === 'days') {
    return pluralize (number, 'дня', 'дней', 'дней');
  } else if (time === 'months') {
    return pluralize (number, 'месяца', 'месяцев', 'месяцев');
  } else if (time === 'hours') {
    return pluralize (number, 'часа', 'часов', 'часов');
  } else if (time === 'minutes') {
    return pluralize (number, 'минуты', 'минут', 'минут');
  } 
  return null;
}
