import { useMutation } from 'react-query';

import { ActionType, useAppAction } from '~components/Provider/App';
import { queryClient } from '~global';
import { useClient,useConfig } from '~global';
import { useNetwork } from '~global';
import { PROFILES_KEY } from '~hooks/fetch/useProfiles';
import useUserType, { UserType } from '~hooks/useUserType';
import useNavigation from '~stores/Navigation';

import { ACCOUNT_KEY, AUTH_DATA_KEY } from './auth.utils';
import { clearAuthData, getAuthData,persistAuthData } from './authData';

export enum AuthActionType {
  AuthByPassword,
  AuthSameAccount,
  AuthByHeader,
  AuthByDeviceCode,
  AuthRefreshTokens,
}

export interface AuthAction {
  type: AuthActionType;
  payload: any;
}

const authByPassword = async(
  client,
  username,
  password,
  isIPTVNetwork,
  clientIdIPTV,
  clientSecretIPTV,
  iptvAuthUrl,
  userType,
) => {
  try {
    client.lockFetching();

    let host = client.host;

    if (
      userType && userType === UserType.IPTV && isIPTVNetwork && clientIdIPTV && clientSecretIPTV &&
      iptvAuthUrl && iptvAuthUrl.length !== 0
    ) {
      host = iptvAuthUrl.replace('http://', '').replace('https://', '');
    }

    const requestParams = client.enhanceRequestParams({
      url: {
        host,
        pathname: '/oauth/token',
      },
      settings: {
        method: 'POST',
        body: {
          grant_type: 'password',
          username,
          password,
        },
      },
    });

    const { access_token, refresh_token } = await client.fetchIgnoringLock(requestParams);

    if (client.unlockFetching) {
      client.unlockFetching();
    }

    return {
      accessToken: access_token,
      refreshToken: refresh_token
    };
  } catch (error) {
    if (client.unlockFetching) {
      client.unlockFetching();
    }
    throw error;
  }
};

const authByHeader = async (client, iptvAuthUrl: string) => {
  try {
    client.lockFetching();

    const host = iptvAuthUrl.replace('http://', '').replace('https://', '');
    const requestParams = client.enhanceRequestParams({
      url: {
        host,
        pathname: '/oauth/token',
      },
      settings: {
        method: 'POST',
        body: {
          grant_type: 'assertion',
          assertion_type: 'external_uid',
        },
      },
    });
    const { access_token, refresh_token } = await client.fetchIgnoringLock(requestParams);

    if (client.unlockFetching) {
      client.unlockFetching();
    }

    return {
      accessToken: access_token,
      refreshToken: refresh_token
    };
  } catch (error) {
    if (client.unlockFetching) {
      client.unlockFetching();
    }
    throw error;
  }
};

const authSameAccountUser = async (client, userId, accessToken) => {
  try {
    client.lockFetching();

    const requestParams = client.enhanceRequestParams({
      url: {
        pathname: '/oauth/token',
      },
      settings: {
        method: 'POST',
        body: {
          grant_type: 'assertion',
          assertion_type: 'same_account',
          user_id: userId,
          access_token: accessToken,
          assertion: accessToken,
        },
      },
    });
    const { access_token, refresh_token } = await client.fetchIgnoringLock(requestParams);

    if (client.unlockFetching) {
      client.unlockFetching();
    }

    return {
      accessToken: access_token,
      refreshToken: refresh_token,
    };
  } catch (error) {
    if (client.unlockFetching) {
      client.unlockFetching();
    }
    throw error;
  }
};

const authByDeviceCode = async (client, deviceCode) => {
  const { access_token, refresh_token } = await client.post('/oauth/token', {
    grant_type: 'assertion',
    assertion_type: 'device_authentication_code',
    assertion: deviceCode,
  });

  return {
    accessToken: access_token,
    refreshToken: refresh_token
  };
};

const refreshTokens = async (client, error, _originalRequestParams) => {
  const { accessToken, refreshToken } = getAuthData();

  if (!accessToken) return;

  // token isn't looks like expired and does not need to be refreshed
  if (!refreshToken) {
    throw error;
  }

  client.lockFetching();

  let newAccessToken;
  let newRefreshToken;

  client.setQueryParam('access_token');

  const url = { pathname: '/oauth/token' };
  const settings = {
    method: 'POST',
    body: { grant_type: 'refresh_token', refresh_token: refreshToken },
  };

  const requestParams = client.enhanceRequestParams({ url, settings });
  const result = await client.fetchIgnoringLock(requestParams);

  newAccessToken = result.access_token;
  newRefreshToken = result.refresh_token;

  client.setQueryParam('access_token', newAccessToken);

  client.unlockFetching();

  return {
    accessToken: newAccessToken,
    refreshToken: newRefreshToken,
  };
};

export const setRefreshedTokens = (accessToken: string, refreshToken: string) => {
  queryClient.setQueryData(
    [ACCOUNT_KEY, AUTH_DATA_KEY],
    {
      accessToken,
      refreshToken,
    }
  );

  persistAuthData();
};

// ***

export const useAuthMutation = () => {
  const client = useClient();
  const isIPTVNetwork = useNetwork().iptv_supported;
  const {
    smartTV: {
      clientIdIPTV,
      clientSecretIPTV,
      IPTVAPI,
    },
  } = useConfig();
  const applicationAction = useAppAction();
  const { accessToken } = getAuthData();
  const userType = useUserType();
  const navigationStateFlush = useNavigation(state => state.flush);

  return useMutation((action : AuthAction) => {
    const { payload } = action;
    switch(action.type) {
      case AuthActionType.AuthByPassword:
        return (
          authByPassword(
            client,
            payload.username,
            payload.password,
            isIPTVNetwork,
            clientIdIPTV,
            clientSecretIPTV,
            IPTVAPI,
            userType,
          )
        );
      case AuthActionType.AuthSameAccount:
        navigationStateFlush();

        return authSameAccountUser(client, payload.userId, accessToken);
      case AuthActionType.AuthByHeader:
        return authByHeader(client, payload.iptvAuthUrl);
      case AuthActionType.AuthRefreshTokens:
        return refreshTokens(
          payload.client,
          payload.error,
          payload.requestParams,
        );
      case AuthActionType.AuthByDeviceCode:
        return authByDeviceCode(client, payload.deviceCode);
      default:
        return Promise.reject(new Error('Invalid Auth Action'));
    }
  }, {
    onSuccess: data => {
      // @ts-ignore
      setRefreshedTokens(data?.accessToken, data?.refreshToken);

      queryClient.invalidateQueries([ACCOUNT_KEY, 'account_info'], undefined,{
        throwOnError: true,
      });
      queryClient.invalidateQueries(PROFILES_KEY, undefined, {
        throwOnError: true,
      });

      applicationAction({
        type: ActionType.SetIsAuthorized,
        payload: { isAuthorized: true },
      });
    },
    onError: (_error, _action) => {
      // console.log(_error); //eslint-disable-line

      clearAuthData();

      applicationAction({
        type: ActionType.SetIsAuthorized,
        payload: { isAuthorized: false },
      });
    }
  });
}
