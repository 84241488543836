import { imageByType } from '~lib/image';
import { isCardChannel, isChannel } from '~lib/objectType';
import Card from '~typings/Card';
import ImageType from '~typings/ImageType';
import MediaItemDimension from '~typings/MediaItemDimension';


type CardItemOptionsResult = Readonly<{
  isChannelItem: boolean;
  isChannelCardWide: boolean;
}>;


const cardItemOptions = (dimension: MediaItemDimension, item?: Card): CardItemOptionsResult => {
  const isChannelItem = (isChannel(item) || isCardChannel(item));
  const isChannelCardWide = (
    isChannelItem
    && dimension === ImageType.CHANNEL_CARD_WIDE
  );

  return ({
    isChannelItem,
    isChannelCardWide,
  });
};


const isCardImageUpdatable = (item?: Card): boolean => {
  const image4x3 = imageByType(item?.images || [], [ImageType.Card4x3, ImageType.Card16x9]);

  return !!(image4x3 && image4x3.update_interval);
};


const getRandomInt = (min, max): number => (
  Math.floor(
  Math.random() * (Math.floor(max) - Math.ceil(min) + 1)
  ) + Math.ceil(min)
);

const getRandomUpdateInterval = (updateInterval: number): number => {
  const salt = [-3000, -2000, -1000, 0, 1000, 2000, 3000]
  const randomInt = getRandomInt(0, 6);

  return (
    updateInterval +
    salt[randomInt]
  );
};


export {
  getRandomUpdateInterval,
  isCardImageUpdatable,
}

export default cardItemOptions;
