import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { PlayerAccessReason } from "~components/Provider/Player/component.helpers";
import {
  NAVIGATION_BACK,
  PLAYER_ERROR_COMMON_STREAM_REQUEST_FAILED,
  PLAYER_ERROR_PLAYBACK,
  STREAM_IS_NOT_AVAILABLE_ON_DEVICE,
  SUBSCRIPTION_NO_ACCESS_GRANTED
} from '~localization';
import PlayerAccessError from "~typings/PlayerStatus";
import Button from '~ui/button';
import { ErrorIcon2 } from "~ui/Icon/icons/ErrorIcon2";

import * as styles from './styles.module.css';


type Props = Readonly<{
  error: PlayerAccessError | Object | string;
  intl: InjectedIntl;
}>;


const ErrorDialog: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const handleBack = React.useCallback(() => {
    history.goBack();
  }, []);


  const renderError = React.useMemo(() => {
    if (typeof props.error === 'object') {
      if (get(props.error, 'vastError', false)) {
        return (
          <>
            { props.intl.formatMessage({ id: PLAYER_ERROR_PLAYBACK }) }
            { ` (error: ${ get(props.error, 'vastError', null) })` }
          </>
        );
      }

      return props.intl.formatMessage({ id: PLAYER_ERROR_PLAYBACK });
    }
    else if (props.error === PlayerAccessError.StreamFetchFailed) {
      return props.intl.formatMessage({ id: PLAYER_ERROR_COMMON_STREAM_REQUEST_FAILED });
    }
    else if (props.error === PlayerAccessError.CannotPlayStream) {
      return props.intl.formatMessage({ id: STREAM_IS_NOT_AVAILABLE_ON_DEVICE });
    }
    else if (props.error === PlayerAccessReason.SubOnHold) {
      return <div style={ {
        width: '60.9375vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
      } }>
        <div style={ { marginBottom: '4.861111111111112vh' } }>
          <ErrorIcon2 width={ 6.25 } height={ 11.11111111111111 } />
        </div>
        <FormattedMessage id={ SUBSCRIPTION_NO_ACCESS_GRANTED } />
      </div>
    }

    return props.error;
  }, [props.error]);

  return (
    <>
      <div className={ styles.messageText }>
        { renderError }
      </div>
      <Button isFocused onClick={ handleBack }>
        <FormattedMessage id={ NAVIGATION_BACK } />
      </Button>
    </>
  )
};

export default React.memo(injectIntl(ErrorDialog));
