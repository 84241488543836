import * as React from 'react';

import * as styles from './styles.module.css';


const Placeholder: React.FC = () => {

  return <div className={ styles.placeholder } />;
};


export default Placeholder;
