import * as React from 'react';

import { useApp } from '~components/Provider/App';
import { usePlatform } from '~components/Provider/Platform';

import { handleStopClick,onKeyDown, revertKeyCodes } from './utils';



export const KeyDownHandler = () => {
  const { isOnLine } = useApp();
  const prevKeyName = React.useRef<string | null>(null);
  const { keyCodes } = usePlatform();

  const keyNames = React.useMemo(() => revertKeyCodes(keyCodes || {}), [keyCodes]);
  const handleKeyUp = () => {
    prevKeyName.current = null;
  };

  React.useEffect(() => {
  const handleKeyDown = (keyDownEvent: KeyboardEvent) =>
    onKeyDown({
      keyNames,
      prevKeyName,
      keyDownEvent,
    });

    if (isOnLine) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
    } else {
      window.addEventListener('click', handleStopClick, true);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('click', handleStopClick, true);
    };
  }, [isOnLine, keyNames]);

  return null;
};
