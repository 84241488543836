import { useEffect,useRef, useState } from 'react';


// const cache: { [key: string]: boolean } = {};
// const isInCache = (src: string): boolean => cache[src] || false;
// const updateCache = (src: string): void => {
//   cache[src] = true;
// };

// eslint-disable-next-line
export const defaultImage = '';


type ImageState = Array<{
  src: string;
  isLoaded: boolean;
  height?: number;
}>;

const useImage = ({
  src,
  onError,
  onLoad,
}: {
  src: string | null | undefined,
  onError?: () => void,
  onLoad?: () => void,
}) => {
  const timeout = useRef<any>(null);
  let img = useRef<HTMLImageElement | null>(null).current;
  const [queue, setState] = useState<ImageState>([]);

  const handleImageLoad = (imgSrc: string | null, height?: number): void => {
    if (onLoad) { onLoad() }

    // if (src && !isInCache(src) && imgSrc) {
    //   updateCache(imgSrc);
    // }

    setState((prevState) =>
      prevState.map((item, idx) => {
        if (idx === prevState.length - 1) {
          return {
            ...item,
            height,
            isLoaded: true,
          };
        }
        return item;
      }),
    );
  };

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  useEffect(() => {
    setState((prevState) =>
      [
        ...prevState,
        {
          src: src || defaultImage,
          isLoaded: false,
        },
      ].slice(-1),
    );

    timeout.current = setTimeout(() => {
      // if (src && isInCache(src)) {
      //   handleImageLoad(src);
      // } else {
        img = new window.Image();
        if (img) {
          if (src) {
            img.src = src;

            if (img.complete) {
              handleImageLoad(src, img.height);
            } else {
              img.onload = () => handleImageLoad(src, img?.height);
              img.onerror = () => {
                if (onError) { onError() }
                handleImageLoad(defaultImage);
              }
            }
          } else {
            handleImageLoad(null);
          }
        }
      // }
    }, 16);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [src]);

  useEffect(() => {
    return () => {
      if (img) {
        img.onload = null;
        img.onerror = null;
        img = null;
      }
    };
  }, []);

  return queue;
};


export default useImage;
