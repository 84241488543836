import { useQuery } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';
import ResponseSingle from '~typings/ResponseSingle';

import { useAuthData } from '../useAccount';


type Data = Readonly<{
  available: boolean;
  object: 'country_availability';
}>;

const queryKey = 'country_availability';

const availabilityKeys = {
  PREFIX: 'country_availability',
  withToken: (accessToken: string | null) => [queryKey, accessToken],
  /**
   * Этот ключ используется для того,
   * чтобы если уже получили ответ, что страна доступна, не делать перезапросы
   *
   * Бывают случаи, когда страна недоступна для неавторизованного пользователя,
   * но доступна для авторизованного
   *
   */
  currentDevice: () => [queryKey, 'currentDevice'],
}

type Result = ResponseSingle<Data>;

const fetch = async (client: ApiClient, accessToken: string | null): Promise<Result> => {
  if (accessToken) {
    // Если страна доступна для этого устройства, то возвращаем ответ
    const cachedData = queryClient.getQueryData<ResponseSingle<Data>>(
      availabilityKeys.currentDevice(),
    );
    const isAvailable = !!cachedData?.data.available;
    if (isAvailable) {
      return cachedData;
    }
  }
  const params = {};

  if (accessToken) {
    params['access_token'] = accessToken;
  }


  const response = await client.get<Result>('/v1/country_availability', params);

  if(response.data.available){
    // Заполяем доступ для этого устройства, если страна доступна
    queryClient.setQueryData(availabilityKeys.currentDevice(), response);
  }

  return response;
};

export const useCountryAvailable = () => {
  const client = useClient();
  const { accessToken } = useAuthData();

  return useQuery<Result>({
    queryKey: availabilityKeys.withToken(accessToken),
    queryFn: () => fetch(client, accessToken),
  });
};
