import classnames from 'classnames';
import React from 'react';

import { useOffsetChanger } from './hooks';
import * as styles from './SliderOffsetableSimple.module.css';

export type SliderOffsetableProps = {
  /**
   * Индекс сфокусированного элемента
   */
  focusedIndex: number;
  /**
   * Сколько должна длится анимация перелистывания
   * @default 200
   */
  animationDurationInMS?: number;
  /**
   * children-ы, которых нужно отрендерить
   * Передавать нужно массивом, пример:
   * ```
   * <Slider>
   * <div>1</div>
   * <div><h1>2</h1></div>
   * <div>3</div>
   * </Slider>
   * ```
   *
   * Подробнее смотреть в Storybook
   */
  children: JSX.Element | JSX.Element[];
  /**
   * Если какое-то действие изменяет ширину слайдов, то через этот массив можно оповестить об изменениях
   * Подробнее смотреть в Storybook
   */
  updates?: unknown[];

  /**
   * Дополнительный класс для обертки слайдера
   */
  className?: string;

  /**
   * Тип слайдера
   */
  sliderType: 'vertical' | 'horizontal';
};

export const SliderOffsetableSimple: React.FC<SliderOffsetableProps> = React.memo(({
  animationDurationInMS = 200,
  focusedIndex,
  children,
  updates,
  className,
  sliderType
}) => {
  const sliderRef = React.useRef<HTMLDivElement>(null);

  useOffsetChanger({
    focusedIndex: focusedIndex,
    animationDurationInMS,
    sliderRef,
    updates,
    sliderType
  });

  return (
    <div className={ classnames(styles.sliderWrapper) }>
      <div
        ref={ sliderRef }
        className={ classnames(styles.slider, { [styles.sliderVertical]: sliderType === 'vertical' }, className) }
      >
        {children}
      </div>
    </div>
  );
});

SliderOffsetableSimple.displayName = 'SliderOffsetableSimple';
