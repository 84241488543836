import * as React from 'react';

import { usePlatform } from '~components/Provider/Platform';
import { PlayButtonState } from '~components/ScenePlayer/Player/controls/PlayPauseButton';
import HotKey from '~typings/HotKey';

import useSeek from './useSeek';


type Props = Readonly<{
  duration: number;
  currentTime: number;
  isAllowSeek: boolean;
  onSeek: (seekToTimeInMS: number) => void;
  onKeyPressedToSeek: (seekTo: PlayButtonState.SeekToBackward | PlayButtonState.SeekToForward) => void;
}>;


const useNavKeys = (props: Props) => {
  const [isPressedOK, setIsPressedOK] = React.useState(false);
  const { keyCodes } = usePlatform();
  const isKeyRight = React.useCallback((keyCode: number): boolean => (
    keyCode === keyCodes[HotKey.KEY_RIGHT]
    || keyCode === keyCodes[HotKey.KEY_NEXT]
  ), []);
  const isKeyLeft = React.useCallback((keyCode: number): boolean => (
    keyCode === keyCodes[HotKey.KEY_LEFT]
    || keyCode === keyCodes[HotKey.KEY_PREV]
  ), []);
  const isKeyOK = React.useCallback((keyCode: number): boolean => (
    keyCode === keyCodes[HotKey.KEY_ENTER]
  ), []);
  const {
    isRewindMode,
    rewindTime,
    handleKeyLeft,
    handleKeyRight,
  } = useSeek({
    duration: props.duration,
    currentTime: props.currentTime,
  });
  const handleKeyDown = (e) => {
    if (isKeyRight(e.keyCode)) {
      e.preventDefault();
      e.stopPropagation();

      props.onKeyPressedToSeek(PlayButtonState.SeekToForward);
      handleKeyRight();
    } else if (isKeyLeft(e.keyCode)) {


      props.onKeyPressedToSeek(PlayButtonState.SeekToBackward);
      handleKeyLeft();
    } else if (isKeyOK(e.keyCode) && isRewindMode) {
      e.preventDefault();
      e.stopPropagation();

      setIsPressedOK(true);
    }
  };

  // Handlers for Seeking
  React.useEffect(() => {
    if (props.isAllowSeek) {
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [
    props.isAllowSeek,
    isRewindMode,       // for Set setIsPressedOK(true);
  ]);
  // The user has finished setting the rewind time. Need to rewind stream
  React.useEffect(() => {
    if (
      (!isRewindMode || isPressedOK) &&
      rewindTime !== null
    ) {
      if (props.currentTime !== rewindTime) {
        props.onSeek(rewindTime);
        setIsPressedOK(false);
      }
    }
  }, [props.onSeek, isRewindMode, isPressedOK, rewindTime]);

  return React.useMemo(() => rewindTime, [rewindTime]);
};


export default useNavKeys;
