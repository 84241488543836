import * as React from 'react';


type Props = {
  className?: string;
};

/* eslint-disable max-len */
const AudioIcon: React.FC<Props> = ({className = ''}) => {
  return (
    <svg
      className={ className }
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 34 34"
      xmlSpace="preserve"
    >
      <path
        d="M30 17c1.1.6 2 1.4 2.8 2.4.2-.8.2-1.6.2-2.4 0-9.4-7.2-17-16-17S1 7.6 1 17c0 .8 0 1.6.1 2.4.8-1 1.8-1.8 2.8-2.4C4 9.3 9.8 3 17 3s13 6.3 13 14z"
      />
      <path
        d="M34 26c0 4.4-3.6 8-8 8-4 0-4-16 0-16 4.4 0 8 3.6 8 8zM0 26c0 4.4 3.6 8 8 8 4 0 4-16 0-16-4.4 0-8 3.6-8 8z"
      />
    </svg>
  );
};


export default AudioIcon;
