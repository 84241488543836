import * as cn from 'classnames';
import * as React from 'react';

import { Typography } from '../Typography';
import * as styles from './styles.module.css';

type Props = {
  isFocused: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
  className?: string;
};

export const SecondaryButton: React.FC<Props> = (props: Props) => {

  return (
    <div className={ cn( styles.button, props.className, {
      [styles.focused]: !props.isDisabled && props.isFocused,
      [styles.disabled]: props.isDisabled,
      [styles.buttonFocusable]: !props.isDisabled
    }) }>
      <Typography variant='body1_medium_uppercase' className={ styles.text }>
      { props.children }
      </Typography>
    </div>
  )
}
