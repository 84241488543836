import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { EpisodeCard, Props as EpisodeCardProps } from './EpisodeCard';
import * as styles from './EpisodeCard.module.css';


export type Props =  EpisodeCardProps & Omit<FocusableBlockProps, 'isLastBlock'>;

export const EpisodeCardFocuser: React.FC<Props> = ({
  episode,
  seriesCertificationRatings,
  episodeProgress,
  ...focuserProps
}) => {
  return (
    <focuser.FocusableBlock
      isLastBlock
      { ...focuserProps }
      className={ styles.episodeCardFocuser }
    >
      {({ isFocused }) => (
        <EpisodeCard
          episode={ episode }
          episodeProgress={ episodeProgress }
          seriesCertificationRatings={ seriesCertificationRatings }
          isFocused={ isFocused }
        />
      )}
    </focuser.FocusableBlock>
  );
};
