import { useMutation } from 'react-query';

import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';


export type PhoneCallPasswordResetMutationResult = Readonly<{
  data?: {
    allowed: boolean;
    object: 'password_reset_without_confirmation_status';
  };
  meta: {
    status: number;
  };
}>;

const fetch = async (
  client: ApiClient,
  username: string,
): Promise<PhoneCallPasswordResetMutationResult> => client.get(
  '/v1/users/password_reset_without_confirmation_status.json',
  {
    username,
  },
);

export const usePhoneCallPasswordResetMutation = () => {
  const client = useClient();

  return useMutation(({ username }: { username: string }) => (
    fetch(client, username)
  ));
}

export default usePhoneCallPasswordResetMutation;
