import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';


const useIsErrorPage = (): boolean => {
  const match = useRouteMatch<{ errorType: string, error: string }>('/:error/:errorType');
  const isErrorPage = (
    match?.params.error === 'error'
  );

  return React.useMemo(() => isErrorPage, [isErrorPage]);
};


export default useIsErrorPage;
