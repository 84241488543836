import * as cn from 'classnames';
import * as React from 'react';

import { CheckboxButton } from '~ui/CheckboxButton';

import * as styles from './styles.module.css';


export type PlayerPopupCheckBoxListItem = Readonly<{
  name: string;
  isChecked: boolean;
}>;

type Props = Readonly<{
  item: PlayerPopupCheckBoxListItem;
  isFocused: boolean;
  className?: string;
}>;


const RadioListItem: React.FC<Props> = (props: Props) => (
  <CheckboxButton
    isChecked={ props.item.isChecked }
    isFocused={ props.isFocused }
    className={ cn(styles.item, props.className) }
  >
    { props.item.name }
  </CheckboxButton>
);


export default React.memo(RadioListItem);
