import { Feature, FeatureFlags } from '~typings/FeatureFlags';

let featureFlags: FeatureFlags = {};
let isInited = false;

export const initFeatureFlags = (flags?: FeatureFlags) => {
  featureFlags = flags || {};
  isInited = true;
};

export const hasFeature = (feature: Feature) => {
  // TODO: Переделать NODE_ENV на другую переменную, когда будет
  if (process.env.NODE_ENV === 'development') {
    if (!isInited) {
      console.error('Фича флаги не должны использоваться до инициализации');
    }
  }
  return featureFlags[feature] === true;
};

export const hasNotFeature = (feature: Feature) => {
  return !hasFeature(feature);
};

export const hasFeatures = (...features: Feature[]) => {
  return features.every((feature) => hasFeature(feature));
};
