const send = (url: string, body?: object) => {
  return new Promise<void>((_resolve) => {
    const timeout = setTimeout(_resolve, 600);
    const resolve = () => {
      clearTimeout(timeout);
      _resolve();
    };

    if (!body) {
      return fetch(url).then(resolve).catch(resolve);
    }

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then(resolve).catch(resolve);
  });
};

export default send;
