import * as React from 'react';
import { useParams } from 'react-router-dom';

import useRentInvoice from '~hooks/fetch/usePayments/useRentInvoice';
import RentPlan from '~typings/RentPlan';

import InvocePage from './InvocePage';


type Props = Readonly<{
  rentPlans: RentPlan[] | undefined;
  url: string | null | undefined;
  focusOnMenu: boolean;
  onMenu: (arg?: boolean) => void;
}>;

const RentInvoce: React.FC<Props> = ({ rentPlans, url, onMenu, focusOnMenu }) => {
  const {
    itemId,
    planID,
  } = useParams<{ itemId: string; planID: string }>();
  const { mutate, data: invoice } = useRentInvoice();
  const plan = (rentPlans || []).find(plan => plan.id === planID);

  React.useEffect(() => {
    if (planID && plan) {
      mutate({
        planID,
        resourceID: itemId,
        planType: plan.type,
        //пока сделан хардкод, т.к пока контент можно купить только через мобильного оператора
        //т.е paymentMethod === 'operator'
        //когда добавится покупка через банковскую карту - нужно будет дополнить/переделать условие
        paymentMethodType: 'operator',
      });
    }
  }, [planID, itemId, plan]);

  return (
    <InvocePage
      plan={ plan }
      invoice={ invoice?.data }
      onMenu={ onMenu }
      focusOnMenu={ focusOnMenu }
      url={ url }
    />
  );
};


export default RentInvoce;
