// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".channelName--mHvxd {\n  font-size: 1.40625vw;\n  color: rgba(202, 202, 208, 1);\n  margin-bottom: 1.953125vw;\n}\n\n.title--bOpxs {\n  font-size: 1.875vw;\n  font-weight: bold;\n  color: rgba(243, 243, 247, 1);\n}\n\n.subtitle--V76pD {\n  font-size: 1.5625vw;\n  color: rgba(202, 202, 208, 1);\n  margin-bottom: 2.34375vw;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/MediaItemDetails/ProgramHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAuB;EACvB,6BAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,kBAAqB;EACrB,iBAAmB;EACnB,6BAAa;AACf;;AAEA;EACE,mBAAuB;EACvB,6BAAa;EACb,wBAAwB;AAC1B","sourcesContent":[".channelName {\n  font-size: $fontSub2_vw;\n  color: $gw_07;\n  margin-bottom: 1.953125vw;\n}\n\n.title {\n  font-size: $fontH5_vw;\n  font-weight: $fw_00;\n  color: $gw_10;\n}\n\n.subtitle {\n  font-size: $fontSub1_vw;\n  color: $gw_07;\n  margin-bottom: 2.34375vw;\n}\n"],"sourceRoot":""}]);
// Exports
export var channelName = "channelName--mHvxd";
export var title = "title--bOpxs";
export var subtitle = "subtitle--V76pD";
export default ___CSS_LOADER_EXPORT___;
