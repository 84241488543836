import { History } from 'history';
import { findIndex } from 'lodash';

import { getFocusedLineFromURL } from '~hooks/useSpatialNavigation/helpers';
import EMPTY_ARRAY from '~lib/constants/emptyArray';
import Channel from '~typings/Channel';

/**
 * TODO: Удалить после удаления старого плеера
 * @deprecated
 */
const getInitialFocusedIndex = (
  channelID: string | null,
  isFocused: boolean,
  history: History,
  channels?: Channel[],
): number => {
  if (channelID !== null) {
    return findIndex((channels || EMPTY_ARRAY), { id: channelID }) || 0;
  }

  const focusedChannelIndexFromURL = getFocusedLineFromURL(history);

  if (isFocused && focusedChannelIndexFromURL !== null) {
    return focusedChannelIndexFromURL;
  }

  return 0;
};

export {
  getInitialFocusedIndex,
};
