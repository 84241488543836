import * as React from 'react';

import { getItemsCountInLine } from '~components/TiledView/component.helpers';
import { useDeleteMultipleFavorite, useFavoritesInfinity } from '~hooks/fetch/useFavorites/useFavoritesV3';
import { usePaginationByItems } from '~hooks/usePagination';
import useNavigationOnLayer from '~stores/LayerNavigation';
import useNavigation, { pageWeComeFromTS } from "~stores/Navigation";
import useSideBarNavigation, { FOCUS_ON } from "~stores/SideBarNavigation";
import FavoriteType from '~typings/FavoriteType';
import ImageType from '~typings/ImageType';

import { Delete } from '../EditItemsMenu/EditItemsMenu';
import PersonalPageEditWrapper, { PERSONAL_PAGE_ID } from '../Wrapper/Wrapper';


type Props = {
  types: FavoriteType[];
  onNavigationItemsReady: (isReady: boolean) => void;
}

const FavoritesPage = (props: Props) => {
  const pageID = `favorite-${ props.types.join('_') }`;
  const layerID = `${ PERSONAL_PAGE_ID }-${ pageID }`;
  const slidesCount: [number, number] = [4, 3];
  const itemsCountInLine = getItemsCountInLine(ImageType.BANNER, slidesCount);
  const focusOn = useNavigationOnLayer(state => (state.focusHistory[layerID] || {})?.focusOn ?? 0);
  const focusedItemIndex = useNavigationOnLayer(state => state.getFocusedIndex(layerID, focusOn));
  const dropFocusedItemIndex =  useNavigationOnLayer(state => ()=> state.setFocusedIndexInBehind(layerID, 0));
  const { query, parsedData: items} = useFavoritesInfinity({
    types: props.types
  });
  const { isLoading }= query;
  const { mutate, isLoading: isLoadingMutation } = useDeleteMultipleFavorite();
  const setFocusOnLeftMenu = useSideBarNavigation(st => ()=>st.setFocusOn(FOCUS_ON.LEFT_MENU));
  useSideBarNavigation(st => st.getFocusOn()); //is needed for rerender to fix nav bug
  const setPageWeComeFrom = useNavigation(state => state.setPageWeComeFrom)

  usePaginationByItems({
    currentFetchedCount: items?.data.length || 0,
    focusedLineIdx: Math.floor(focusedItemIndex / itemsCountInLine),
    elementsPerLine: itemsCountInLine,
    minimalLinesOnScreen: 3,
    updates: [isLoadingMutation],
    fetchMoreData: ()=> {
      if(query.hasNextPage && !query.isFetchingNextPage && !isLoadingMutation){
        query.fetchNextPage();
      }
    }
  });

  const setNavigatinItemsReadyToTrue = React.useCallback(()=>{
    props.onNavigationItemsReady(true);
}, [props.onNavigationItemsReady]);

  const handleDelete = React.useCallback((arg: Delete, selectedItems: number[]) => {
    if(arg === Delete.Selected){
      const deleteItemIds = selectedItems.map(idx=>items?.data[idx].card?.resource_id).filter((v): v is string=>!!(v));
      if(deleteItemIds?.length){
        mutate(deleteItemIds, {
          onSettled: dropFocusedItemIndex,
          onSuccess: ()=>{
            if(selectedItems.length === items?.data.length){
              // Удалили все, нужно переключиться на левый блок
              setFocusOnLeftMenu();
              props.onNavigationItemsReady(false);
            }
          }
        });
      }
      return;
    }

    const deleteItemIds = items?.data?.map(item=>item.card?.resource_id).filter((v): v is string=>!!(v));

    if(deleteItemIds?.length){
      mutate(deleteItemIds, {
        onSettled: dropFocusedItemIndex,
        onSuccess: ()=>{ setFocusOnLeftMenu(); props.onNavigationItemsReady(false);}
      });
    }
  }, [items?.data, dropFocusedItemIndex, setFocusOnLeftMenu, props.onNavigationItemsReady]);

  React.useEffect(() => {
    setPageWeComeFrom(pageWeComeFromTS.favorites);
  }, []);

  return (
    <PersonalPageEditWrapper
      layerID={ layerID }
      items={ items }
      isLoading={ isLoading || isLoadingMutation }
      onDelete={ handleDelete }
      onNavigationItemsReady={ setNavigatinItemsReadyToTrue }
    />
  );
};


export default React.memo(FavoritesPage);
