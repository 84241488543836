import React, { useEffect, useMemo, useReducer } from 'react';

import { isCatchupAvailable } from '~lib/eventsHelper';
import { EPGItem } from '~newapp/ui-kit/Table_items/EPG_item';
import { DataIdxAttrType } from '~newapp/utils/globals/DataAttributeIdx';
import { getLocalHHmm,SECOND } from '~newapp/utils/globals/time';
import Blackout from '~typings/Blackout';
import Channel from '~typings/Channel';
import ProgramEvent from '~typings/Event';

import { DEFAULT_INTERVAL_FOR_PROGRESS_LINE, eventItemState, getEPGItemProgress } from './EPGItemWrapper.utils';

export type Props = {
  event: ProgramEvent;
  channel: Channel;
  blackouts?: Blackout[];
  isFocused: boolean;
  className?: string;
  dataIdx: DataIdxAttrType
};

const EPGItemWrapper$: React.FC<Props> = ({
  event,
  isFocused,
  channel,
  blackouts,
  dataIdx,
  className,
}) => {

  const [forceUpdateCounter, forceUpdate] = useReducer((c) => c + 1, 0);
  const nextProps = useMemo(() => {
    const HHmm = getLocalHHmm(new Date(event.start_at));
    const prestate = eventItemState(event);
    const isCatchup = prestate === 'ended' && isCatchupAvailable(event, channel, blackouts);

    const state = isCatchup ? 'catchup' : prestate;

    const progress = getEPGItemProgress(event);
    return {
      HHmm,
      state,
      progress,
    } as const;
  }, [event, blackouts, channel, forceUpdateCounter]);


  useEffect(() => {
    if(nextProps.state !== 'actual') {
      return;
    }
    const isEPGItemInProgress = typeof nextProps.progress === 'number';

    if(isEPGItemInProgress) {
      const msToEnd = new Date(event.end_at).getTime() - new Date().getTime();
      const timeoutTimeToEnd = Math.max(msToEnd + 2 * SECOND, 0);
      const timeout = setTimeout(() => {
        forceUpdate();
      }, Math.min(timeoutTimeToEnd, DEFAULT_INTERVAL_FOR_PROGRESS_LINE));

      return () => clearTimeout(timeout);
    }

    const msToStart = new Date(event.start_at).getTime() - new Date().getTime();
    const timeoutTime = Math.max(msToStart + 2 * SECOND, 0);
    const timeout = setTimeout(() => {
      forceUpdate();
    }, timeoutTime);
    return () => clearTimeout(timeout);
  }, [event, forceUpdateCounter]);

  return (
    <EPGItem
      HHmm={ nextProps.HHmm }
      state={ nextProps.state }
      progress={ nextProps.progress }
      isFocused={ isFocused }
      title={ event.name }
      className={ className }
      dataIdx={ dataIdx }
    />
  );
};

/**
 * Компонент отображает событие в EPG, и обновляет progress bar в зависимости от времени
 * Если это текущее событие, то раз в `DEFAULT_INTERVAL_FOR_PROGRESS_LINE` мс обновляем progress bar
 */
export const EPGItemWrapper = React.memo(EPGItemWrapper$);
