import * as React from 'react';

import { isCardProgramEvent, isProgramEvent } from '~lib/objectType';
import Card from '~typings/Card';
import Event from '~typings/Event';

import ProgramEventInfoCard from './ProgramEventInfoCard';
import ProgramEventInfoEvent from './ProgramEventInfoEvent';



type Props = Readonly<{
  event: Event | Card;
}>;


const ProgramEventInfo: React.FC<Props> = ({ event }: Props) => {
  if (isProgramEvent(event)) {
    return <ProgramEventInfoEvent event={ event as Event } />
  }
  if (isCardProgramEvent(event)) {
    return <ProgramEventInfoCard event={ event as Card } />
  }
  return null;
};


export default ProgramEventInfo;
