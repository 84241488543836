// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".imageWrapper--nZ88c {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n\n.image--kAhC4 {\n  display: block;\n  height: 100%;\n  margin: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/app/components/ScenePlayer/Player/controls/Scene/AudioShow/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,WAAW;EACX,YAAY;AACd;;;AAGA;EACE,cAAc;EACd,YAAY;EACZ,YAAY;AACd","sourcesContent":[".imageWrapper {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n\n.image {\n  display: block;\n  height: 100%;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export var imageWrapper = "imageWrapper--nZ88c";
export var image = "image--kAhC4";
export default ___CSS_LOADER_EXPORT___;
