import focuser, { FocusableBlockProps } from '@focuser';
import React from 'react';

import { Props as TextEditInputProps } from './TextEditInput';
import { TextEditInput } from './TextEditInput';

type Props = {
  focuserClassName?: string;
  inputClassName?: TextEditInputProps['className'];
} & Omit<FocusableBlockProps, 'className' | 'isLastBlock'> &
  Omit<TextEditInputProps, 'className'>;

export const TextEditInputFocuser: React.FC<Props> = ({
  isFocused,
  focuserClassName,
  inputClassName,
  value,
  align,
  placeholder,
  ...focuserProps
}) => {
  return (
    <focuser.FocusableBlock
      { ...focuserProps }
      isFocused={ isFocused }
      className={ focuserClassName }
      isLastBlock
    >
      {({ isFocused }) => (
        <TextEditInput
          className={ inputClassName }
          isFocused={ isFocused }
          value={ value }
          align={ align }
          placeholder={ placeholder }
        />
      )}
    </focuser.FocusableBlock>
  );
};
