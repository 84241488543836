import { pickBy } from 'lodash';
import { useMutation } from 'react-query';

import { queryClient } from '~global';
import { useClient } from '~global';
import ApiClient from '~lib/ApiClient';
import AccountInfo from '~typings/AccountInfo';

import { ACCOUNT_KEY } from './auth.utils';
import { getAuthData } from './authData';

const queryKey = [ACCOUNT_KEY, 'account_info'];

export enum SecurityPinErrors {
  ResetPinInvalidPassword = 'invalid_password',
}

export enum PinActionType {
  Creation,
  Removal,
  Change
}
export interface SecurityPinAction {
  type: PinActionType;
  payload: any;
}

export type SecurityPinValidityResult = Readonly<{
  data: {
    valid: boolean;
    object: 'security_pin_validity'
  },
  meta: any,
}>;


const fetch = async (
  client: ApiClient,
  pin: string,
): Promise<SecurityPinValidityResult> => client.get(
  '/v1/account/security_pin/validity',
  {
    pin,
    access_token: getAuthData().accessToken
  },
);

export const useSecurityPinValidity = () => {
  const client = useClient();

  return useMutation(({ pin }: { pin: string }) => (
    fetch(client, pin)
  ));
};

const modificateSecurityCode = async (
  actionType: PinActionType,
  client: ApiClient,
  params: {
    pin?: string,
    current_pin?: string,
    password?: string,
  }
) => {
  const url = '/v1/account/security_pin';
  const requestParams = {
    ...pickBy(params, v => v !== undefined),
    access_token: getAuthData().accessToken,
  };

  switch(actionType) {
    case PinActionType.Creation:
      return client.post(url, requestParams);
    case PinActionType.Removal:
      return client.delete(url, requestParams);
    case PinActionType.Change:
      return client.put(url, requestParams);
    default:
      return Promise.reject(new Error('Unknown action type'));
  }
};

export const useSecurityPinMutation = () => {
  const client = useClient();

  return useMutation(
    (pinAction: SecurityPinAction) =>
      modificateSecurityCode(pinAction.type, client, pinAction.payload),
      {
        onMutate({ type }) {
          queryClient.cancelQueries(queryKey);

          const prevAccountData = queryClient.getQueryData<AccountInfo>(queryKey);

          switch (type) {
            case PinActionType.Creation:
              queryClient.setQueryData(queryKey, { ...prevAccountData, security_pin: true });
              break;
            case PinActionType.Removal:
              queryClient.setQueryData(queryKey, { ...prevAccountData, security_pin: false });
              break;
          }

          return () => queryClient.setQueryData(queryKey, prevAccountData);
        },
        onSettled: () => {
          queryClient.invalidateQueries(queryKey, {
            refetchActive: true,
            refetchInactive: true,
          });
        },
      }
    );
};
