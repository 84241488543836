import { QueryKey,useQuery } from 'react-query';

import { useClient } from '~global';
import { fetchOptionsBasic, FetchType, fetchURLs } from '~hooks/fetch/fetch-parameters';
import { getAuthData } from '~hooks/fetch/useAccount';
import Plan from '~typings/Plan';
import ResponseMany from '~typings/ResponseMany';


const PREFIX_OF_A_COMPOSITE_KEY = 'subscriptions_products';

const getKey = (productID?: string): QueryKey => [PREFIX_OF_A_COMPOSITE_KEY, productID];

const fetch = async (client, accessToken, productID) => {
  if (!productID) { return undefined; }

  return await client.get(
    fetchURLs[FetchType.Subscription],
    {
      access_token: accessToken,
      ...fetchOptionsBasic[FetchType.Subscription],
      'filter[conflict_with_product_id]': productID,
    }
  );
};


const useSubscriptionsProducts = (productID?: string) => {
  const queryKey = getKey(productID);
  const { accessToken } = getAuthData();

  const client = useClient();

  return useQuery<ResponseMany<Plan[]>>({
    queryKey,
    queryFn: () => fetch(client, accessToken, productID),
    enabled: !!(accessToken && productID),
    staleTime: 0,
    cacheTime: 0,
  });
};


export {
  getKey,
  PREFIX_OF_A_COMPOSITE_KEY,
};

export default useSubscriptionsProducts;
