import * as cn from 'classnames';
import * as React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { getMenuItems } from '~components/NavMenu/component.helpers';
import { LinesWrapper } from '~components/NavMenu/line';
import { useApp } from '~components/Provider/App';
import { ActionType, useMenu, useMenuAction } from '~components/Provider/Menu';
import { useConfig } from '~global';
import { useAutoInfinityChannels } from '~hooks/fetch/useChannels';
import { useLoadedPages } from '~hooks/fetch/usePages/usePages';
import { useProfiles } from '~hooks/fetch/useProfiles';
import usePageInfo from "~hooks/usePageInfo";
import useNavMenu from '~stores/NavMenu';
import usePointer from '~stores/Pointer';

import * as styles from './styles.module.css';

interface Props {
  intl: InjectedIntl;
}

const TopMenu = (props: Props) => {
  const { isMenuOpened, isMenuAppeared, isPopupOpened } = useMenu();
  const dispatch = useMenuAction();
  const { isAllowedMenuRender } = usePageInfo();
  const { smartTV: { profileSection } } = useConfig();
  const { isAuthorized, activeProfileId, isLaunchingDone } = useApp();
  const { data: profiles = [] } = useProfiles();
  const isKids = !!profiles.find(({ id }) => (id === activeProfileId))?.kids;
  const pages = useLoadedPages();
  const channels = useAutoInfinityChannels();

  const menuItems = React.useMemo(() => getMenuItems({
    pages,
    isKids,
    isAuthorized,
    intl: props.intl,
    profileSection: profileSection,
    channelsTotalCount: channels.data?.meta.pagination.total
  }), [isAuthorized, pages, isKids, profileSection, channels.data?.meta.pagination.total]);

  const pointerEnabled = usePointer(state => state.pointerEnabled);
  const setHovered = useNavMenu(state => state.setHovered);

  React.useEffect(() => {
    if (!pointerEnabled) {
      setHovered(false);
    }
  }, [pointerEnabled]);

  const handleClose = React.useCallback(() => {
    dispatch({
      type: ActionType.ChangeMenuState,
      isMenuOpened: false,
    });

    setHovered(false);
  }, []);

  const renderLines = React.useMemo(() => {
    return <LinesWrapper intl={ props.intl } isAuthorized={ isAuthorized } items={ menuItems } />
  }, [isAuthorized, isMenuOpened, menuItems]);

  return React.useMemo(() => {
    if (!isLaunchingDone) {
      return null;
    }

    return (
      <>
        {
          (isMenuOpened && isAllowedMenuRender) ?
            <div className={ styles.closer } onClick={ handleClose } />
            : null
        }
        <div
          id="nav-menu"
          className={ cn(styles.navMenuWrapper, {
            [styles.navMenuWrapperAppeared]: (
              isMenuAppeared || (isMenuOpened && isAllowedMenuRender)
            ) && !isPopupOpened,
          }) }
        >
          <div
            className={ cn(styles.menuGradient, {
              [styles.menuOpened]: (isMenuOpened && isAllowedMenuRender) && !isPopupOpened,
            }) }
            onClick={ handleClose }
          />
          { renderLines }
        </div>
      </>
    );
  }, [
    isLaunchingDone,
    isMenuOpened,
    isAllowedMenuRender,
    isPopupOpened,
    isMenuAppeared,
    menuItems,
    isAuthorized,
  ]);
};

export const NavMenu = injectIntl(TopMenu);
