import React from 'react';
import { FormattedMessage } from "react-intl";

import { Typography } from '~app/ui-kit/Typography';
import {
  MENU_SERIES,
  VIEW_SUMMARY_CHANNEL,
  VIEW_SUMMARY_H,
  VIEW_SUMMARY_M,
  VIEW_SUMMARY_MOVIE,
  VIEW_SUMMARY_PART,
  VIEW_SUMMARY_PROGRAM_EVENT,
  VIEW_SUMMARY_S,
} from '~localization';

import { PreparedPart } from '../types';
import { ProgressLine } from './ProgressLine/ProgressLine';
import * as styles from './styles.modules.css';
import { checkTimeType } from './utils';


type Props = {
  item: PreparedPart;
  allSeconds: number;
}

export const StatsItem: React.FC<Props> = ({ item, allSeconds }) => {

  const percentageWatch = React.useMemo(() => {
    if(allSeconds === 0) {
      return 0;
    }
    return ((100 / allSeconds) * item.stats.duration);
  }, [allSeconds, item.stats.duration]) 

  const renderStatsName = () => {
    switch (item.title) {
      case 'movie':
        return <FormattedMessage id={ VIEW_SUMMARY_MOVIE } />
      case 'part':
        return <FormattedMessage id={ VIEW_SUMMARY_PART } />
      case 'channel':
        return <FormattedMessage id={ VIEW_SUMMARY_CHANNEL } />
      case 'episode':
        return <FormattedMessage id={ MENU_SERIES } />
      case 'program_event':
        return <FormattedMessage id={ VIEW_SUMMARY_PROGRAM_EVENT } />
      default:
        break
    }
  }

  const renderContentViewTime = () => {
    const { 
      minutes,
      seconds,
      hasHours,
      hasMinutes,
      hasSeconds,
      hoursToShow,
      minutesToShow
    } = checkTimeType(item.stats.duration);

    if(hasHours) {
      return (
        <>
          <div className={ styles.hours }>{ hoursToShow } <FormattedMessage id={ VIEW_SUMMARY_H } /></div>
          <div>{ minutesToShow } <FormattedMessage id={ VIEW_SUMMARY_M } /></div>
        </>
      )
    }

    if(hasMinutes) {
      return (
        <div>{ minutes } <FormattedMessage id={ VIEW_SUMMARY_M } /></div>
      )
    }

    if(hasSeconds) {
      return (
        <div>{ seconds } <FormattedMessage id={ VIEW_SUMMARY_S } /></div>
      )
    }

    return (
      <div>{ 0 } <FormattedMessage id={ VIEW_SUMMARY_H } /></div>
    )
  }
  
  return (
    <div className={ styles.itemWrap }>
      <div className={ styles.itemContent }>
        <Typography variant='subtitle1_medium' className={ styles.itemName }>
          { renderStatsName() }
        </Typography>
        <Typography variant='subtitle1_medium' className={ styles.itemTime }>
          { renderContentViewTime() }
        </Typography>
      </div>
      <ProgressLine percentageWatch={ percentageWatch }/>
    </div>
  )
}