import { FocuserKeyHandler } from '@focuser';
import React, { useCallback } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import useCardCollection from '~hooks/fetch/useCardCollection';
import { CardCollectionContent } from '~newapp/components/CardCollectionContent';
import { SpinnerFullScreenIsolated } from '~newapp/globalBlocks/spinner';

export const CardCollectionViewPage$ = () => {
  const history = useHistory();

  const handleBack: FocuserKeyHandler = useCallback((e) => {
    e.stop();
    e.stopNativeEvent();
    history.goBack();
  }, []);

  const { cardCollectionID } = useParams<{ cardCollectionID: string }>();

  const query = useCardCollection(cardCollectionID);

  if (!cardCollectionID) {
    return <Redirect to="/" />;
  }

  if (query.status !== 'success') {
    return (
      <SpinnerFullScreenIsolated
        returnButtonType="back"
        onKeyReturn={ handleBack }
      />
    );
  }


  const cardCollection = query.data.data;
  const filters = cardCollection.search_filters_group?.items;


  return (
    <CardCollectionContent
      returnButtonType="back"
      onKeyReturn={ handleBack }
      cardCollection={ cardCollection }
      filters={ filters }
    />
  );

};

export const CardCollectionViewPage = React.memo(CardCollectionViewPage$);
